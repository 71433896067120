const EinsparungsModellConstants = [
  {
    value: 'NONE',
    name: '1:1 Tausch'
  },
  {
    value: 'VA',
    name: '1:1 Tausch mit VA'
  },
  {
    value: 'AN',
    name: 'AN Nettovorteil in €'
  },
  {
    value: 'AN_RELATIVE',
    name: 'AN Nettovorteil in %'
  },
  {
    value: 'AG',
    name: 'AG Lohnkostenersparnis in €'
  },
  {
    value: 'AN_AG_RELATIVE',
    name: '50/50'
  }
];

export default EinsparungsModellConstants;
