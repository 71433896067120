import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vn-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {

  public colorClasses!: string[];
  public chipLabels!: string[];

  @Input()
  public set label(label: string | string[]) {
    if (Array.isArray(label)) {
      this.chipLabels = label;
    } else {
      this.chipLabels = [label];
    }
  }

  @Input()
  public set color(color: string | string[]) {
    if (Array.isArray(color)) {
      this.colorClasses = color.map((_color) => `${_color}-state`);
    } else {
      this.colorClasses = [`${color}-state`];
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  public isIcon(label: string) {
    return label.startsWith('$');
  }

  public label2icon(label: string) {
    return label.slice(1);
  }

}
