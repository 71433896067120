import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[vnRandomAnimate]'
})
export class RandomAnimateDirective {

  @Input()
  set randomAnimation(ms: number) {
    this.dynamicTimeout = Math.random() * 50 + ms;

    this.setAndRemoveClass(this.dynamicClassName)

  };

  private nativeElement: any;
  private staticClassName = 'random-animate';
  private dynamicClassName = 'random-animate-enter'
  private dynamicTimeout = Math.random() * 400 + 300;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.nativeElement = this.elementRef.nativeElement;

    this.setClass(this.staticClassName)
    this.setAndRemoveClass(this.dynamicClassName)
  }

  setClass(className: string) {
    this.renderer.addClass(this.nativeElement, className);
  }

  setAndRemoveClass(className: string) {
    this.setClass(className);

    setTimeout(
      () => {
        this.renderer.removeClass(this.nativeElement, className);
      },
      this.dynamicTimeout
    );
  }
}
