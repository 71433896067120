import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from "../../../../shared/services/loading.service";
import {DTicketOrderFilters, DticketOrderService} from "../../../services/dticket-order.service";
import {DTicketOrder} from "../../../models/dticket/dticket-order.model";
import {MatDialog} from "@angular/material/dialog";
import {AlertService} from '../../../../shared/services/alert.service';
import {ConfirmationDialogComponent} from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {take} from "rxjs/operators";
import {
  DticketOrderVesputiPdfDialogComponent, UserPreferencesDialogCloseData
} from "../../../dialogs/dticket-order-user-preferences-dialog/dticket-vesputi-pdf-component/dticket-order-vesputi-pdf-dialog.component";
import {CurrencyPipe} from "@angular/common";
import {
  DticketOrderActivateDialogComponent
} from "../../../dialogs/dticket-order-user-preferences-dialog/dticket-order-activate-component/dticket-order-activate-dialog.component";
import {result} from "lodash";

@Component({
  selector: 'vn-dticket-order-history',
  templateUrl: './dticket-order-history.component.html',
  styleUrls: ['./dticket-order-history.component.scss']
})
export class DticketOrderHistoryComponent implements OnInit {

  dticketOrders: DTicketOrder[] = [];
  lastDticketOrderWithUserPreferences!: DTicketOrder;
  filterEmployeeOrders: Partial<DTicketOrderFilters> = {employeeId: this.made.user.valuenet_id};
  @ViewChild('menuItem1') menuItem1!: any;

  constructor(
    public loader: LoadingService,
    private dticketOrderService: DticketOrderService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private notificationService: AlertService,
    @Inject('Made') private made: any,
    @Inject('EmployeeService') private employeeService: any,
    @Inject('authenticationService') private authenticationService: any,
    @Inject('userService') private userService: any,
    private currencyPipe: CurrencyPipe
  ) {
  }

  ngOnInit(): void {
    this.loader.executePromisesInOrderAndShowLoading<DTicketOrder[] | DTicketOrder>([
      this.DTicketordersRequestFunc(),
      this.getLastDticketWithUserPreferencesFunc(this.made.user.valuenet_id), // possible bug if impersonate ?
    ]).then(r => {
      this.dticketOrders = r[0] as DTicketOrder[];
      this.lastDticketOrderWithUserPreferences = r[1] as DTicketOrder;
    })
  }

  private DTicketordersRequestFunc() {
    return () => this.dticketOrderService.getDTicketOrders(this.filterEmployeeOrders)
  }

  private cancelDTicketOrderRequestFunc(id: string) {
    return () => this.dticketOrderService.employeeCancelDTicketOrder(id)
  }

  private getLastDticketWithUserPreferencesFunc(employeeId: number) {
    return () => this.dticketOrderService.getDTicketUserPreferencesForEmployee(employeeId);
  }

  menuOpened() {
    if (this.menuItem1) {
      this.menuItem1._getHostElement().blur();
    }
  }

  async generateQRCode(id: string) {
    await this.loader.executePromisesInOrderAndShowLoading<string>([() => this.dticketOrderService.getVesputiQRCodeUrl(id)])
      .then((res) => {
        const qrCodeUrl = res[0]
        window.open(qrCodeUrl, "_blank");
      })
  }

  displayOrderFlatTax(item: DTicketOrder) {
    if (item.isThereATaxPayer()) {
      return this.currencyPipe.transform(item.flatTax, 'EUR', 'symbol', '1.2-2');
    } else {
      return '-';
    }
  }

  async cancelDTicketOrder(id: string) {

    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Wollen Sie das DeutschlandTicket wirklich abbestellen?",
        yesButton: "Bestätigen"
      }
    }).afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result && result.decision === 'yes') {
          this.ngZone.run(async () => {
            const r = await this.loader.executePromisesInOrderAndShowLoading<boolean | DTicketOrder[] | DTicketOrder>([
              this.cancelDTicketOrderRequestFunc(id),
              this.DTicketordersRequestFunc(),
              this.getLastDticketWithUserPreferencesFunc(this.made.user.valuenet_id)
            ]);
            this.dticketOrders = r[1] as DTicketOrder[];
            this.lastDticketOrderWithUserPreferences = r[2] as DTicketOrder;
          });
        }
      })
  }

  isLoading() {
    return this.loader.loading$;
  }

  canRestoreTicket(dticket: DTicketOrder) {
    return dticket.canRestoreTicket;
  }

  async restoreTicket(id: string) {
    try {
      const dticket = await this.dticketOrderService.getDTicketWithUserPreferencesById(id);
      await this.openActionDialog(DticketOrderActivateDialogComponent, dticket, "Ihr Ticket wurde erfolgreich wiederhergestellt. Bitte folgen Sie den Anweisungen in der Email.");
    } catch (e) {
      this.notificationService.error("Ihr Ticket konnte nicht wiederhergestellt werden.");
    }
  }

  async openPreferencesDialog() {
    await this.openActionDialog(DticketOrderVesputiPdfDialogComponent, this.lastDticketOrderWithUserPreferences);
  }

  async openActionDialog(dialogComponent: any, dticket: DTicketOrder, message: string|null = null) {
    const empData = await this.employeeService.get(this.lastDticketOrderWithUserPreferences.employeeId);
    this.dialog.open(dialogComponent, {
      panelClass: "userPreferencesDialogResponsive",
      data: {
        dticketOrder: dticket,
        employeeData: {
          'firstName': empData.firstname,
          'lastName': empData.lastname,
          'birthDate': empData.birthdate,
          'employeeId': this.lastDticketOrderWithUserPreferences.employeeId
        },
      }
    }).afterClosed()
      .pipe(take(1))
      .subscribe(async (updateOrderAfterAction: boolean) => {
        if (updateOrderAfterAction) {
          const result = await this.loader.executePromisesInOrderAndShowLoading<DTicketOrder | DTicketOrder[]>([
            this.getLastDticketWithUserPreferencesFunc(dticket.employeeId),
            this.DTicketordersRequestFunc(),
          ]);
          this.lastDticketOrderWithUserPreferences = result[0] as DTicketOrder;
          this.dticketOrders = result[1] as DTicketOrder[];

          if (message) {
            this.notificationService.message(message);
          }
        }
      });
  }
}
