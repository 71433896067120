import bonago_scheme_configure_dialog_html from './scheme/configure/bonago_scheme_configure_dialog.pug';
import success_notification_dialog_html from '../bonago/notifications/success_notification.pug';

const $inject = [
  '$scope',
  'ngDialog',
  'dialogService',
  'BenefitBonagoService',
  '$timeout'
];
export default class AdministrationBonagoController {
  constructor(
    $scope,
    ngDialog,
    dialogService,
    BenefitBonagoService,
    $timeout
  ) {
    Object.assign(this, {
      $scope,
      ngDialog,
      dialogService,
      BenefitBonagoService,
      $timeout
    });

    this.loading = {
      main: false,
      reading_file: false,
      schemes: false
    };

    this.loading.main = true;
    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });

  }

  async init() {
    await this.loadSchemes();
  }

  async deleteBonagoScheme(scheme) {
    this.loading.schemes = true;
    await this.BenefitBonagoService.deleteBonagoScheme(scheme);

    await this.loadSchemes();

    this.$timeout(() => {
      this.loading.schemes = false;
    });
  }

  async loadSchemes() {
    this.loading.schemes = true;

    let bonago_schemes = await this.BenefitBonagoService.getAvailableSchemes();
    this.$timeout(() => {
      this.bonago_schemes = bonago_schemes;
      this.loading.schemes = false;
    });
  }

  configureBonagoScheme(scheme) {
    this.dialogService.ngDialog.openConfirm({
      template: bonago_scheme_configure_dialog_html(),
      plain: true,
      controller: 'BonagoSchemeConfigureDialogController',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      data: {
        scheme: scheme
      },
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      return this.loadSchemes();
    });
  }

  readBonagoImport() {
    this.loading.reading_file = true;
    return this.BenefitBonagoService.readImportDocument(this.bonago_import_file)
      .then((result) => {
        const {updatedNumber, failedUsers} = result;
        this.notifyOfSuccessfulProcessing(updatedNumber, failedUsers);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.bonago_import_file = null;
        this.loading.reading_file = false;
      });
  }

  canRead() {
    return !!this.bonago_import_file && !this.loading.reading_file;
  }

  notifyOfSuccessfulProcessing(successful_updates, failedUsers) {
    this.dialogService.ngDialog.openConfirm({
      template: success_notification_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      data: {
        successful_updates: successful_updates,
        failedUsers: failedUsers
      },
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    });
  }
}
AdministrationBonagoController.$inject = $inject;
