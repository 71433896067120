import {Inject, Injectable} from '@angular/core';
import {DTicketConfig} from "../models/dticket/dticket-config.model";
import {DTicketOrder} from "../models/dticket/dticket-order.model";
import {formatDateToYYYYMMDD} from "../../shared/common";

export interface DTicketOrderFilters {
    dticketId: string | null,
    employeeId: string | null,
    customerId: string | null,
    beginDate: Date | null,
    checkoutDate: Date | null,
    ticketId: string | null
}

export interface LegacyEmployeeData {
    customer_id: number;
    emails: string[];
    firstname: string;
    groups: any[];
    home_url: string;
    lastname: string;
    originalId: string | null
    session: string
    state: string
    title: string
    toc: number
    valuenet_id: number
    _id: string
}

export interface EmployeeUpdateData {
    firstName: string,
    lastName: string,
    employeeId: number,
    birthDate: string | null
}

@Injectable({
    providedIn: 'root'
})
export class DticketOrderService {

    constructor(
        @Inject('Made') private made: any,
        @Inject('customerService') private customerService: any,
        @Inject('EmployeeService') private employeeService: any,
    ) {
    }

    updateDticketOrderConfig(customer_id: number, config: DTicketConfig): Promise<boolean> {
        return this.made.request('rpc://valuenetdb/d_ticket/update_dticket_config', {
            'customer_id': customer_id,
            'dticket_config': {
                ...config
            }
        });
    }

    approverApproveDticket(dTicketId: number, subsidy: number) {
        return this.made.request('rpc://valuenetdb/d_ticket/approver_approve', {
            'dticket_id': dTicketId,
            subsidy
        });
    }

    approverValidations(newSubsidy: number, oldSubsidy: number) {
        return this.made.request('rpc://valuenetdb/d_ticket/approver_validations', {
            subsidy: newSubsidy,
            employer_subsidy: oldSubsidy,
        });
    }

    approverDeclineDticket(dTicketId: number, declineReason: string) {
        return this.made.request('rpc://valuenetdb/d_ticket/approver_decline', {
            'dticket_id': dTicketId,
            'decline_reason': declineReason
        })
    }

    getDticketOrderConfig(customer_id: number): Promise<DTicketConfig> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_dticket_config', {customer_id})
            .then((data: {
                change_of_subsidy: boolean,
                euro_subsidy: number,
                orders_approval: boolean,
                percentage_subsidy: number
            }) => {
                return new DTicketConfig(
                    data['orders_approval'],
                    data['percentage_subsidy'].toString(),
                    data['change_of_subsidy'],
                    data['euro_subsidy'].toString(),
                )
            })
    }

    generateContractPreview(employeeId: number): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/generate_dticket_contract', {
            'resource_id': employeeId,
            'contract_type': 'pre-checkout'
        });
    }

    generateCheckedOutContractPreview(dticketOrderId: string, isYouSignV3=false): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/generate_dticket_contract', {
            'resource_id': dticketOrderId,
            'contract_type': 'post-checkout',
            'you_sign_v3': isYouSignV3
        });
    }

    checkout(employeeId: number): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/checkout', {
            'employee_id': employeeId
        });
    }

    calculatePercentageSubsidy(price: number): Promise<number> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_percentage_subsidy', {
            'price': price
        });
    }

    calculateTicketPriceWithDiscount(): Promise<number> {
        return this.made.request('rpc://valuenetdb/d_ticket/calc_ticket_price_with_discount');
    }

    getCheckoutData(employeeId: number): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_checkout_calculation', {
            'employee_id': employeeId
        });
    }

    hasActiveDTicket(employeeId: number): Promise<boolean> {
        return this.made.request('rpc://valuenetdb/d_ticket/has_active_dticket', {
            'employee_id': employeeId
        });
    }

    getActiveDticket(employeeId: number): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_active_dticket', {
            'employee_id': employeeId
        });
    }

    getBeginDateDticket(): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_dticket_begin_date', {});
    }

    employeeCancelDTicketOrder(dticketOrderId: string): Promise<boolean> {
        return this.made.request('rpc://valuenetdb/d_ticket/employee_cancel_dticket_order', {
            'dticket_order_id': dticketOrderId
        });
    }

    getVesputiQRCodeUrl(dticketOrderId: string): Promise<string> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_mobility_box_qr_code_url_for_dticket', {
            'dticket_id': dticketOrderId
        });
    }

    getDTicketUserPreferencesForEmployee(empId: number): Promise<DTicketOrder> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_last_dticket_with_user_preferences_for_employee', {
            'employee_id': empId
        }).then((data: any) => {
            return DTicketOrder.getInstanceFromVNMiddelware(data)
        })
    }
    getDTicketWithUserPreferencesById(id: string): Promise<DTicketOrder> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_last_dticket_with_user_preferences', {
            'dticket_id': id
        }).then((data: any) => {
            return DTicketOrder.getInstanceFromVNMiddelware(data)
        })
    }

    restoreDTicket(id: string, issuerId: string|null): Promise<DTicketOrder|null> {
        return this.made.request('rpc://valuenetdb/d_ticket/dticket_restore_and_activate', {
            'dticket_id': id,
            'issuer_id': issuerId
        }).then((data: any) => {
            if(data) {
              return DTicketOrder.getInstanceFromVNMiddelware(data)
            } else {
              return null;
            }
        })
    }


    getDTicketOrderById(id: string): Promise<DTicketOrder> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_dticket_order_by_id', {
            'id': id
        }).then((data: any) => {
            return DTicketOrder.getInstanceFromVNMiddelware(data)
        });
    }

    getDTicketOrders(filters: Partial<DTicketOrderFilters> = {
        employeeId: null,
        customerId: null,
        beginDate: null,
        checkoutDate: null,
        ticketId: null
    }): Promise<DTicketOrder[]> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_dticket_orders', {
            'dticket_order_filter': {
                begin_date: filters.beginDate ? formatDateToYYYYMMDD(filters.beginDate) : null,
                checkout_date: filters.checkoutDate ? formatDateToYYYYMMDD(filters.checkoutDate) : null,
                customer_id: filters.customerId ? filters.customerId : null,
                employee_id: filters.employeeId ? filters.employeeId : null,
                mobilitybox_ticket_id: filters.ticketId ? filters.ticketId : null
            }
        }).then((data: any[]) => {
            return data.map(vnMiddlewareData => DTicketOrder.getInstanceFromVNMiddelware(vnMiddlewareData));
        });
    }

    getDTicketOrdersForAdministration(filters: Partial<DTicketOrderFilters> = {
        dticketId: null,
        employeeId: null,
        customerId: null,
        beginDate: null,
        checkoutDate: null,
        ticketId: null
    }): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/search_dticket_orders', {
            'dticket_order_filter': {
                id: filters.dticketId ? filters.dticketId : null,
                begin_date: filters.beginDate ? formatDateToYYYYMMDD(filters.beginDate) : null,
                checkout_date: filters.checkoutDate ? formatDateToYYYYMMDD(filters.checkoutDate) : null,
                customer_id: filters.customerId ? filters.customerId : null,
                employee_id: filters.employeeId ? filters.employeeId : null,
                mobilitybox_ticket_id: filters.ticketId ? filters.ticketId : null
            }
        });
    }

    getDTicketOrdersReadyToSign(employeeId: any): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/get_dticket_orders_ready_to_sign', {
            'employee_id': employeeId
        });
    }

    processUserPDFPreferences(employeeId: number, isPDFTicket: boolean): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/process_user_preferences_for_dticket', {
            'employee_id': employeeId,
            'is_pdf_ticket': isPDFTicket
        });
    }

    processUserPreferencesData(data: EmployeeUpdateData): Promise<Partial<LegacyEmployeeData>> {
        return this.employeeService.get(data.employeeId).then((employee: any) => {
            const employeeUpdateData = {
                //required legacy data
                'login': employee.login,
                'testclient': employee.testclient,
                'employeenumber': employee.employeenumber,
                'loc_id': employee.loc_id,
                'customer_id': employee.customer_id,
                'sex': employee.sex,
                //data to update
                'birthdate': data.birthDate,
                'firstname': data.firstName,
                'lastname': data.lastName
            }

            return this.employeeService.update({
                id: data.employeeId,
                ...employeeUpdateData
            }, false);
        });
    }

    sendVesputiRequest(dticketOrderId: string): Promise<any> {
        return this.made.request('rpc://valuenetdb/d_ticket/create_pdf_ticket_with_make_webhook', {
            'dticket_order_id': dticketOrderId
        });
    }


}
