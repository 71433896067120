<h2 mat-dialog-title>
    Bestellung zurücknehmen
</h2>

<mat-dialog-content>
    Möchten Sie die Bestellung für <strong>{{this.period}}</strong> zurücknehmen? Nach dem Klick auf "Bestätigen" wird
    die Bestellung wieder als "Entwurf" gespeichert.
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            [mat-dialog-close]="true">
        Abbrechen
    </button>

    <button class='revert-button'
            data-cy="vn.directBonus.customerAdministration.revertConfirmDialog.buttonSubmit"
            mat-stroked-button
            (click)="revertOrder()">
        Zurücknehmen
    </button>
</mat-dialog-actions>
