import {
  EMPLOYEE_STATUSES,
  NAME_EMPLOYEE_STATUSES_MAP,
  ID_EMPLOYEE_STATUSES_MAP,
  EMPLOYEE_STATUS_FLOWS,
  NAME_EMPLOYEE_STATUS_FLOWS,
  ID_EMPLOYEE_STATUS_FLOWS
} from "../../../services/employeeService";

const $inject = [
  '$scope',
  'NotificationService',
  'Made',
  'userService',
  'authenticationService',
  'lodash',
  '$timeout'
];
export default class ChangeEmployeeStatusDialogControler {
  constructor(
    $scope,
    NotificationService,
    Made,
    userService,
    authenticationService,
    lodash,
    $timeout
  ) {
    Object.assign(this, {
      $scope,
      NotificationService,
      userService,
      Made,
      authenticationService,
      lodash,
      $timeout
    });

    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');

    this.initData();

    this.$scope.flow = {};
  }

  isLoading() {
    return this.loading.updating;
  }

  initData() {
    let status = this.getStatus(this.$scope.ngDialogData.employee_access.neo_status);

    this.$scope.status = {
      current: status,
      details: this.$scope.ngDialogData.employee_access.neo_status,
      reserve_3: this.$scope.ngDialogData.employee_access.neo_comment
    };

    let current_status_id = this.lodash.get(this.$scope.status, 'current.id')

    if (current_status_id) {
      this.allowed_flows = this.$scope.status.current.flows.map(id => ID_EMPLOYEE_STATUS_FLOWS[id]);
    }

    if (!current_status_id || this.allowed_flows.length === 0) {
      this.allowed_flows = this.getAllNewStateFlows();
    }

    this.loading = {
      updating: false
    };
  }

  getAllNewStateFlows() {
    return EMPLOYEE_STATUS_FLOWS.filter(flow => flow.is_new_status);
  }

  getStatus(neo_status) {
    if (!neo_status) {
      neo_status = '';
    }

    let status_split = neo_status.split(' ');
    let status_name = status_split.shift();
    let status_date = status_split.shift();

    let multiname_status = (status_name || '').split('_');
    let is_korrectur = multiname_status.indexOf('korrektur') > -1;
    let is_kontrolle = multiname_status.indexOf('kontrolle') > -1;

    let status;
    if (is_kontrolle) {
      status = {...NAME_EMPLOYEE_STATUSES_MAP[status_name.toLowerCase()], ...{old_date: status_date}};
    } else {
      status = {...NAME_EMPLOYEE_STATUSES_MAP[status_name], ...{old_date: status_date}};
    }
    return status;
  }

  selectNewFlow(id) {
    this.selected_flow = ID_EMPLOYEE_STATUS_FLOWS[id];
    this.$scope.flow = {};
  }

  async changeStatus() {
    this.$timeout(()=>{
      this.loading.updating = true;
    });

    if (this.selected_flow && this.selected_flow.id) {
      let payload = this.$scope.flow;
      payload.flow_id = this.selected_flow.id;
      try {
        await this.userService.updateNeoStatus(this.$scope.ngDialogData.employee.id, payload, this.Made.user.valuenet_id)
        this.NotificationService.message('Status wurde aktualisiert');
        this.$scope.confirm(0);
      } catch (e) {
        this.NotificationService.error('Fehlgeschlagen');
      }

    } else {
      this.$scope.confirm(0);
    }
    this.$timeout(()=>{
      this.loading.updating = false;
    });
  }
}
ChangeEmployeeStatusDialogControler.$inject = $inject;
