// @deprecated
const $inject = [
  'quizFactory', 'SchemaService'
];
export default class basicInfoQuiz {
  constructor(quizFactory, SchemaService) {

    this.quizFactory = quizFactory;
    this.SchemaService = SchemaService;
    this.schema = {};

    this.SchemaService
      .get('rpc://vbm/neo/calculate')
      .then((calculate_new) => {
        this.schema = calculate_new;
        this.steps[0].schema = calculate_new.parameters;
        this.steps[1].schema = calculate_new.parameters;
        this.steps[2].schema = calculate_new.parameters;
        this.steps[3].schema = calculate_new.parameters;
      });


    this.description = 'Damit wir Ihnen eine optimale Empfehlung geben können, beantworten Sie uns bitte ein paar Fragen';
    this.steps = [{
      id: 'question1',
      title: 'Ihr aktuelles Bruttogehalt?',
      items: [{
        key: 'brutto',
        title: ''
      }],
      schema: this.schema.parameters
    }, {
      id: 'question2',
      title: 'Bitte geben Sie Ihre Steuerklasse an.',
      items: [{
        'key': 'pap_stkl',
        'type': 'select',
        'title': '',
        // 'fieldHtmlClass': 'tax',
        'titleMap': [{
          'value': 1,
          'name': 'I'
        }, {
          'value': 2,
          'name': 'II'
        }, {
          'value': 3,
          'name': 'III'
        }, {
          'value': 4,
          'name': 'IV'
        }, {
          'value': 5,
          'name': 'V'
        }, {
          'value': 6,
          'name': 'VI'
        }]
      }]
    }, {
      id: 'question3',
      title: 'Zahlen Sie Kirchensteuer?',
      items: [{
        key: 'pap_r',
        title: 'Ja'
      }]
    }, {
      id: 'question4',
      title: 'Welchen Kinderfreibetrag nutzen Sie?',
      items: [{
        key: 'pap_zkf',
        title: ''
      }]
    }];

    return new quizFactory(this.steps, this.description);

  }
}
basicInfoQuiz.$inject = $inject;
