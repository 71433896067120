import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { NeoCalculationBookmarkService } from "./services/neo-calculation-bookmark.service";
import { NeoCalculationBookmarkMapper } from "./models/neo-calculation-bookmark.model";
import { NeoCalculationBookmarkDialogComponent } from "./components/neo-calculation-bookmark-dialog/neo-calculation-bookmark-dialog.component";

const mappers = [
  NeoCalculationBookmarkMapper,
];

const services = [
  NeoCalculationBookmarkService,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    NeoCalculationBookmarkDialogComponent,
  ],
  providers: [
    ...services,
    ...mappers
  ]
})
class NeoCalculationBookmarkModule { }

export { NeoCalculationBookmarkModule };
