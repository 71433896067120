const $inject = [
  '$state'
];
export default class CalculationComponentCheckoutBackLink {

  constructor($state) {
    Object.assign(this, {
      $state
    });
  }

  handleClick() {
    if (this.onClick) {
      this.onClick();
    } else {
      this.$state.go(this.toState || this.toStateStr);
    }
  }
}
CalculationComponentCheckoutBackLink.$inject = $inject;

import html from "./CalculationComponentCheckoutBackLink.pug";

angular
  .module( 'VSPApp' )
  .component( 'checkoutBackLink', {
    template: html(),
    bindings: {
      toState: '<',
      toStateStr: '@',
      onClick: '&'
    },
    controller: CalculationComponentCheckoutBackLink,
    controllerAs: '$ctrl'
  });
