const $inject = [
  '$scope', '$state', 'userService', 'NotificationService', 'authenticationService'
];
export default class StatusLogController {

  constructor($scope, $state, userService, NotificationService, authenticationService) {
    this.$scope = $scope;
    this.$state = $state;
    this.userService = userService;
    this.NotificationService = NotificationService;
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.employee = $scope.ngDialogData.employee;

    this.getStatusLog(this.employee.id);
  }

  getStatusLog(employee_id) {
    this.loading = true;
    this.userService.getNeoLogs(employee_id).then((logs) => {
      this.status_logs = logs;
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.NotificationService.error();
    });
  }

  close() {
    this.$scope.confirm();
  }
}
StatusLogController.$inject = $inject;
