var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, headerAttributes, id, title, validationId) {
      


































pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};



















































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}
pug_mixins["fieldset"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
fieldsetCounter++;
title = attributes.title || ''
delete attributes.title
headerAttributes = {}
if (!attributes.noCollapse) {
headerAttributes['ng-click'] = 'fieldset'+ fieldsetCounter +'.closed = !fieldset'+ fieldsetCounter +'.closed'
}
if (attributes.closed) {
headerAttributes['ng-init'] = 'fieldset'+ fieldsetCounter +'.closed =' + attributes.closed
delete attributes.closed
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "bigbox"},attributes]), true)) + "\u003E\u003Cdiv" + (pug.attrs(pug.merge([{"class": "header"},headerAttributes]), true)) + "\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (!attributes.noCollapse) {
pug_html = pug_html + "\u003Cspan" + (" class=\"openable\""+pug.attr("ng-class", '{selected: !fieldset'+ fieldsetCounter +'.closed}', true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"main\""+pug.attr("ng-hide", 'fieldset'+ fieldsetCounter +'.closed', true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"cockpit_arbeitnmv-wrapper\" overlay=\"$ctrl.loading.search || $ctrl.loading.main\" ng-if=\"$ctrl.filters\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_mixins["fieldset"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.filters.employeeId","title": "ValueNet ID","type": "number"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.filters.customerId","title": "Customer ID","type": "number"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.filters.bonusId","title": "Bonus ID","type": "text"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4 filter-input\"\u003E\u003Cdiv\u003E\u003Clabel class=\"control-label\"\u003EStatus\u003C\u002Flabel\u003E\u003Cui-select ng-model=\"$ctrl.filters.states.selected\" theme=\"select2\" title=\"Status\" search-enabled=\"false\" close-on-select=\"false\" style=\"width:100%\" multiple\u003E\u003Cui-select-match placeholder=\"Status wählen...\"\u003E{{$item.display}}\u003C\u002Fui-select-match\u003E\u003Cui-select-choices repeat=\"state.id as state in $ctrl.filters.states.options\"\u003E\u003Cspan ng-bind=\"state.display\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fui-select-choices\u003E\u003C\u002Fui-select\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4 filter-input\"\u003E\u003Cdiv\u003E\u003Clabel class=\"control-label\"\u003ECashbacktyp\u003C\u002Flabel\u003E\u003Cui-select ng-model=\"$ctrl.filters.components.selected\" theme=\"select2\" title=\"Cashbacktyp\" search-enabled=\"false\" close-on-select=\"false\" style=\"width:100%\" multiple\u003E\u003Cui-select-match placeholder=\"Cashback Typ wählen\"\u003E{{$item.display}}\u003C\u002Fui-select-match\u003E\u003Cui-select-choices repeat=\"component.id as component in $ctrl.filters.components.options\"\u003E\u003Cspan ng-bind=\"component.display\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fui-select-choices\u003E\u003C\u002Fui-select\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4 filter-input\"\u003E\u003Cdiv\u003E\u003Cdatepicker class=\"date\" date-format=\"dd.MM.yyyy\" selector=\"entrydateInput\" date-refocus=\"false\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.filters.createdAt","title": "Erstellt am","attributes": {"vn-date-helper":true,"vn-date":"true","vn-date-reformat":"true","vn-date-use-utc":"true","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","ng-change":"$ctrl.getEndDate()"},"fieldHtmlClass": "entrydateInput"}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cbutton class=\"button button-primary\" ng-click=\"$ctrl.search();\"\u003ESuchen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "overflow-show"}
});
pug_html = pug_html + "\u003Cdiv ng-repeat=\"customer in $ctrl.cashbacks_by_customer\" overlay=\"$ctrl.loading.search || $ctrl.loading.main\"\u003E\u003Cdiv class=\"list-box__row\"\u003E\u003Cdiv class=\"list-box__item\"\u003E\u003Cspan class=\"list-box__itemTitle\"\u003E\u003Cspan class=\"user-name\" style=\"font-weight: bold\"\u003E{{ customer.customer_id }}, {{ customer.customer_name }}\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-repeat=\"bonus in customer.bonuses\" overlay=\"$ctrl.loading.search || $ctrl.loading.main\"\u003E\u003Cdiv class=\"mpp_basket_list_container\"\u003E\u003Cdiv class=\"list-box__row bigbox\" ng-class=\"{'toggable-content-open': bonus.toggleContentShowable }\" toggable-content=\"bonus.toggleContentShowable\"\u003E\u003Ctoggable-content-toggler ng-init=\"bonus.toggleContentShowable\"\u003E\u003Cdiv class=\"list-box__item\"\u003E\u003Cspan class=\"list-box__itemTitle\" ng-click=\"togglerClicked()\"\u003E\u003Cdiv class=\"header\" style=\"display: inline-block;width: 100%;\" ng-style=\"bonus.state == 'pending' &amp;&amp; {'color': '#fcb103'}\"\u003E{{ bonus.employee_id }}, {{ bonus.employee_first_name }} {{ bonus.employee_last_name }} - {{ $ctrl.ComponentConstants[bonus.component]['title'] }} - {{ bonus.created_at*1000 | date: 'dd.MM.yyyy'}}\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"list-box__item__content\" toggable-content-content ng-if=\"bonus.toggleContentShowable\"\u003E\u003Cdiv class=\"cockpit_toggable-content\"\u003E\u003Cdiv class=\"main\"\u003E\u003Cadministration-cashback-review  [bonus-id]=\"bonus.bonus_id\"\u003E\u003C\u002Fadministration-cashback-review\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftoggable-content-toggler\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "form-padding","id": "cashbackFilters"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "headerAttributes" in locals_for_with ?
        locals_for_with.headerAttributes :
        typeof headerAttributes !== 'undefined' ? headerAttributes : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;