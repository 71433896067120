<h2 mat-dialog-title>
    Bestellung für Bulk-Aktionen wiedereinschließen
</h2>

<mat-dialog-content>
    <form [formGroup]='dialogForm'>
        <p>
            Sie schalten die Warteschleife für diese Bestellung aus.
        </p>

        <p>
            Fügen Sie bitte in das untere Eingabefeld die Nummer des auftragsbezogenen OTRS-Tickets ein:
        </p>

        <div class='row filters'>
            <vn-input formControlName='ticketUrl'
                      key="vn.directBonus.backofficeAdministration.putOffHoldDialog.inputOTRSTicket"
                      label="OTRS-Ticket"
                      placeholder="Ticket ID">
                <mat-error *ngIf="ticketUrl.hasError('required')">
                    Eingabe fehlt.
                </mat-error>
            </vn-input>
        </div>

        <p>
            Sie könnten auch eine Begründung dafür hinterlassen:
        </p>

        <div class='row filters'>
            <vn-input formControlName='comment'
                      key="vn.directBonus.backofficeAdministration.putOffHoldDialog.inputComment"
                      label="Kommentar"
                      placeholder="-">
            </vn-input>
        </div>

        Nach dem Klick auf “Bestätigen” wird die Bestellung in eine weitere Sammelbearbeitung einbezogen.
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='create-button'
            data-cy='vn.directBonus.backofficeAdministration.putOffHoldDialog.buttonSubmit'
            mat-stroked-button
            [disabled]="!dialogForm.valid"
            (click)="putOrderOffHold()">
        Bestätigen
    </button>
</mat-dialog-actions>