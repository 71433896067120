const $inject = ['Made'];

export default class mailService {

  constructor(Made) {

    Object.assign(this, {
      Made
    });

  }

  send(tos, subject, templatename, context = {}) {

    if ('string' === typeof tos) {
      tos = [
        tos
      ];
    }

    return this.Made.request('rpc://mail/send_template', {
      tos,
      subject,
      templatename,
      context
    });
  }

  updateContact(valuenet_id, newsletter_accepted = null) {
    return this.Made.request('rpc://utility/newslettertogo/update_contact', {
      'valuenet_id': valuenet_id,
      'newsletter_accepted': newsletter_accepted
    });
  }

  updateContactsCustomersData(customer_id, neo_project_id = null) {
    return this.Made.request('rpc://utility/newslettertogo/update_contacts_customer_data', {
      'customer_id': parseInt(customer_id),
      'neo_project_id': neo_project_id
    });
  }

  deleteContact(valuenet_id) {
    return this.Made.request('rpc://utility/newslettertogo/update_contact', {
      'valuenet_id': valuenet_id,
      'newsletter_accepted': false
    });
  }

  getNewslettertogoContact(valuenet_id) {
    return this.Made.request('rpc://utility/newslettertogo/get_newslettertogo_contact', {
      'valuenet_id': valuenet_id
    });
  }

  getNewslettertogoPreference(valuenet_id) {
    return this.Made.request('rpc://utility/newslettertogo/get_newslettertogo_preference', {
      'valuenet_id': valuenet_id
    });
  }

  sendTestMailjetEmail() {
    return this.Made.request('rpc://utility/mailjet/send_yearly_emails')
  }

  sendBOFileRequestInitEmail(valuenet_id, bo_request_data, ticket_number) {
    return this.Made.request('rpc://utility/mail_controller/send_bo_file_request_init_email', {
      'valuenet_id': valuenet_id,
      'bo_request_data': bo_request_data,
      'ticket_number': ticket_number
    });
  }
}
mailService.$inject = $inject;
