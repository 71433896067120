<table mat-table
       [dataSource]="tableData"
       class="mat-elevation-z8"
       multiTemplateDataRows
       matSort>

    <!-- ===================== Filters header ===================== -->
    <tr mat-header-row
        *matHeaderRowDef="filtersHeaderColumns">
    </tr>

    <ng-container matColumnDef="filtersHeader">
        <th mat-header-cell
            [attr.colspan]="mainTableColumns.length-1"
            *matHeaderCellDef>
            <ng-content select="form">
            </ng-content>
        </th>
    </ng-container>

    <ng-container matColumnDef="topLevelActionsMenu">
        <th mat-header-cell
            [attr.colspan]="1"
            *matHeaderCellDef>
            <ng-content select="button">
            </ng-content>

            <ng-content select="mat-menu">
            </ng-content>
        </th>
    </ng-container>

    <!-- ===================== Main table ===================== -->
    <!-- header row -->
    <tr mat-header-row
        *matHeaderRowDef="mainTableColumns"
        sticky="true">
    </tr>

    <!-- content rows -->
    <tr mat-row
        *matRowDef="let data; columns: mainTableColumns;"
        [ngClass]="getTableRowClass(data)"
        class="customer-order-table-row"
        (click)="onTableRowClick(data)">
    </tr>

    <!-- expanded rows -->
    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="customer-order-employee-table-row">
    </tr>

    <!-- expanded column -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell
            *matCellDef="let data"
            [attr.colspan]="mainTableColumns.length">
            <div class="customer-order-employee-table-row-expanded"
                 [@rowExpand]="data == expandedRow ? 'expanded' : 'collapsed'">
                <vn-bonus-order-employees-table
                    *ngIf="data == expandedRow"
                    [customerOrderData]="data"
                    (tableDataChange)="onTableDataChange()">
                </vn-bonus-order-employees-table>
            </div>
        </td>
    </ng-container>

    <!-- orderId column -->
    <ng-container matColumnDef="orderId">
        <th mat-header-cell
            *matHeaderCellDef>
            Bestellung
        </th>

        <td mat-cell
            *matCellDef="let data">
            {{ data.order.orderRefId }}
        </td>
    </ng-container>

    <!-- customerId column -->
    <ng-container matColumnDef="customerId">
        <th mat-header-cell
            *matHeaderCellDef>
            Customer ID
        </th>

        <td mat-cell
            *matCellDef="let data">
            {{ data.order.customerId }}
        </td>
    </ng-container>

    <!-- customerName column -->
    <ng-container matColumnDef="customerName">
        <th mat-header-cell
            *matHeaderCellDef>
            Kunde
        </th>

        <td mat-cell
            *matCellDef="let data">
            {{ data.customerName }}
        </td>
    </ng-container>

    <!-- orderType column -->
    <ng-container matColumnDef="orderType">
        <th mat-header-cell
            *matHeaderCellDef>
            Typ
        </th>

        <td mat-cell
            *matCellDef="let data">
            Bonus
        </td>
    </ng-container>

    <!-- orderPeriod column -->
    <ng-container matColumnDef="orderPeriod">
        <th mat-header-cell
            *matHeaderCellDef>
            Periode
        </th>

        <td mat-cell
            *matCellDef="let data">
            {{ getOrderPeriod(data.order) }}
        </td>
    </ng-container>

    <!-- orderAmount column -->
    <ng-container matColumnDef="orderAmount">
        <th mat-header-cell
            *matHeaderCellDef>
            Betrag
        </th>

        <td mat-cell
            [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderTableRow.textOrderSum.' + data.order.bonusProjectId"
            *matCellDef="let data; let idx = index">
            {{ data.orderSum }} EUR
        </td>
    </ng-container>

    <!-- orderState column -->
    <ng-container matColumnDef="orderState">
        <th mat-header-cell
            *matHeaderCellDef>
            Status
        </th>

        <td mat-cell
            *matCellDef="let data">
<!--            <p>-->
<!--              {{'vn.directBonus.backofficeAdministration.customerOrderTableRow.chipOrderState.' + data.order.bonusProjectId + '-' + data.order.forMonth}}-->
<!--            </p>-->
<!--            <p>-->
<!--              {{getOrderStateColors(data)}} | {{getOrderStateLabels(data)}}-->
<!--            </p>-->
            <vn-state [color]="getOrderStateColors(data)"
                      [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderTableRow.chipOrderState.' + data.order.bonusProjectId + '-' + data.order.forMonth"
                      [label]="getOrderStateLabels(data)">
            </vn-state>
        </td>
    </ng-container>

    <!-- actionsMenu column -->
    <ng-container matColumnDef="actionsMenu">
        <th mat-header-cell
            *matHeaderCellDef>
            Aktion
        </th>

        <td mat-cell
            *matCellDef="let data">
            <button mat-icon-button
                    [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderTableRow.buttonActionsMenu.' + data.order.bonusProjectId"
                    class="menu-button"
                    [matMenuTriggerFor]="menu"
                    *ngIf="hasContextMenu(data.allowedOperations)"
                    (click)='menuClick($event)'>
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonCreateBonus"
                        *ngIf="data.allowedOperations.canCreateNewBonus"
                        (click)="createNewBonus(data.order)">
                    <mat-icon>
                        bookmark_add
                    </mat-icon>

                    <span>
                        Bonus erstellen
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonPutOnHold"
                        *ngIf="data.allowedOperations.canPutOrderOnHold"
                        (click)="putOrderOnHold(data.order.orderId)">
                    <mat-icon>
                        history
                    </mat-icon>

                    <span>
                        Warten an
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonPutOffHold"
                        *ngIf="data.allowedOperations.canPutOrderOffHold"
                        (click)="putOrderOffHold(data.order.orderId)">
                    <mat-icon>
                        history_toggle_off
                    </mat-icon>

                    <span>
                        Warten aus
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonMarkForInvoice"
                        *ngIf="data.allowedOperations.canStartMarkOrderForInvoice"
                        (click)="startMarkOrderForInvoice(data.order.orderId)">
                    <mat-icon>
                        get_app
                    </mat-icon>

                    <span>
                        Faktura
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonMarkAsPaid"
                        *ngIf="data.allowedOperations.canMarkOrderAsPaid"
                        (click)="markOrderAsPaid(data.order)">
                    <mat-icon>
                        attach_money
                    </mat-icon>

                    <span>
                        Bestellung bezahlen
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonMarkAsUnpaid"
                        *ngIf="data.allowedOperations.canMarkOrderAsUnpaid"
                        (click)="markOrderAsUnpaid(data.order.orderId)">
                    <mat-icon>
                        money_off
                    </mat-icon>

                    <span>
                        Bestellung unbezahlt
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonCompleteOrder"
                        *ngIf="data.allowedOperations.canMarkOrderAsDone"
                        (click)="markOrderAsDone(data.order.orderId)">
                    <mat-icon>
                        done
                    </mat-icon>

                    <span>
                        Ausführen
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonChangePaymentType"
                        *ngIf="data.allowedOperations.canChangeOrderPaymentType"
                        (click)="changePaymentType(data.order.orderId)">
                    <mat-icon>
                        credit_card
                    </mat-icon>

                    <span>
                        Zahlungsart ändern
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonCancelOrder"
                        *ngIf="data.allowedOperations.canMarkOrderAsCancelled"
                        (click)="cancelOrder(data.order)">
                    <mat-icon>
                        disabled_by_default
                    </mat-icon>

                    <span>
                        Bestellung stornieren
                    </span>
                </button>

                <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.customerOrderActionsMenu.buttonExtendDeadline"
                        *ngIf="data.allowedOperations.canExtendOrderDeadline"
                        (click)="extendDeadline(data.order)">
                    <mat-icon>
                        event
                    </mat-icon>

                    <span>
                        Neue Frist
                    </span>
                </button>
            </mat-menu>
        </td>
    </ng-container>
</table>
