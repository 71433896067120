angular
  .module('VSPApp')
  .directive('vnEquals', ['$timeout', function ($timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        vnEquals: '='
      },
      link: function ($scope, elem, attrs, ngModel) {

        $scope.$watch('vnEquals', (value) => {
          if (!!value) {
            ngModel.$setViewValue(value);
            ngModel.$processModelValue();
            // ngModel.$render();
            // $timeout(()=>{}, 0);
          }
        });
      }
    };
  }]);
