import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EMPTY } from 'rxjs';
import { CarLeasingErrorHandler } from 'src/app/car-leasing/services/errors/car-leasing-error-handler';
import { ProjectActionsDialogData } from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DirectBonusBulkCreateUsersErrorsComponent } from 'src/app/shared/services/custom-components/direct-bonus-bulk-create-users-errors.component';
import { CustomerBulkUploadDialogComponent } from '../customer-bulk-upload-dialog/customer-bulk-upload-dialog.component';

@Component({
  selector: 'vn-customer-bulk-add-users-dialog',
  templateUrl: './customer-bulk-add-users-dialog.component.html',
  styleUrls: ['./customer-bulk-add-users-dialog.component.scss']
})
export class CustomerBulkAddUsersDialogComponent implements OnInit {

  public get customerId(): number {
    return this.dialogData.customerId!;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CustomerBulkUploadDialogComponent>,
    private directBonusService: DirectBonusService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
    private notificationService: AlertService,
    private carLeasingErrorHandler: CarLeasingErrorHandler,
    @Inject('fileService') private fileService: any,
  ) { }

  ngOnInit(): void {
  }

  public downloadTableAsFile() {
    const columns = ['Vorname', 'Nachname', 'Email', 'Personalnummer', 'Strasse', 'PLZ', 'Ort', 'Standort ID', 'Projekt ID', 'Kundennummer'];
    const data = [{
      'Vorname': null,
      'Nachname': null,
      'Email': null,
      'Personalnummer': null,
      'Strasse': null,
      'PLZ': null,
      'Ort': null,
      'Standort ID': null,
      'Projekt ID': null,
      'Kundennummer': this.customerId
    }];

    this.fileService.saveFile(data, columns, 'mitarbeiterdaten_vorlage.csv')
  }

  public bulkAddUsers(
    files: File[] // should only contain one item
  ) {
    const {
      [0]: csvTableFile
    } = files;

    if (!csvTableFile) {
      throw new Error("Invalid file selection.")
    }

    const isValidFile = !!csvTableFile.name.match(/\.([^\.]+)$/)?.find(el => [".csv", ".text"].includes(el));

    if (!isValidFile) {
      alert('Only .csv and .txt file formats are supported.');
      return;
    }

    const {
      customerId,
    } = this.dialogData;

    this.directBonusService.bulkAddUsers(
      customerId!,
      csvTableFile
    ).subscribe(
      (result) => {
        if (result.data) {
          console.log(result.data);
          const columns = ['Vorname', 'Nachname', 'Email', 'Personalnummer', 'Strasse', 'PLZ', 'Ort', 'Standort ID', 'Projekt ID', 'Kundennummer', 'Aktion', 'Kommentar', 'Aktivierungscode', 'Zugewiesene Projekt ID'];
          this.fileService.saveFile(result.data, columns, 'mitarbeiterdaten_vorlage_result.csv')
        }
        if (result.rejected > 0) {
          this.notificationService.error("Bitte überprüfen Sie die Ergebnisdatei. Mehrere Elemente wurden abgelehnt.");
        } else {
          this.notificationService.message("Bitte sehen Sie sich die Ergebnisdatei an.");
        }
      },
      (error) => {
        this.notificationService.error(this.carLeasingErrorHandler.handle(error).getMessage())
      }
    );
  }
}
