import dialog_html from '../../../modules/neo/berater/target/dialog.pug'

const $inject = [
	"$scope",
	"$state",
	"Made",
	"StatisticService",
	"BrandingService",
	"FeedbackService",
	"advisorService",
	"authenticationService",
	"employeeService",
	"customerService",
	"dialogService",
	"ngDialog",
	"vbmData",
	"UserNotificationService",
	"$timeout"
];

export default class DashboardBeraterController {

  constructor (
    $scope,
    $state,
    Made,
    StatisticService,
    BrandingService,
    FeedbackService,
    advisorService,
    authenticationService,
    employeeService,
    customerService,
    dialogService,
    ngDialog,
    vbmData,
    UserNotificationService,
    $timeout
  ) {

    this.$scope = $scope;
    this.$state = $state;
    this.Made = Made;
    this.StatisticService = StatisticService;
    this.BrandingService = BrandingService;
    this.FeedbackService = FeedbackService;
    this.advisorService = advisorService;
    this.employeeService = employeeService;
    this.customerService = customerService;
    this.authenticationService = authenticationService;
    this.dialogService = dialogService;
    this.ngDialog = ngDialog;
    this.vbmData = vbmData;
    this.UserNotificationService = UserNotificationService;
    this.$timeout = $timeout;

    this.user = Made.user;
    this.isDashboardAdvisor = true;
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmEmployeeAdministrator = authenticationService.iAm('sa_employee_administration');
    this.iAmOTRSAgent = authenticationService.iAm('otrs_agent');
    this.iAmAsanaAgent = authenticationService.iAm('asana_agent');
    this.customerId = null;

    this.loading = {
      main: false
    };

    this.loading.main = true;
    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });

  }

  async init () {

    let promises = [];

    promises.push(
      this.customerService
      .getConfiguration()
      .then(configuration => {
        this.$scope.configuration = configuration;
      })
    );

    return Promise.all(promises);
  }

  getFeedback () {

    return this.FeedbackService
      .list(this.customerId)
      .then(list => {
        this.feedbackList = list;
        return this.FeedbackService.aggregate(list);
      })
      .then(feedback => this.FeedbackService.expand(feedback));
  }

  arbeitnehmerAnlegen () {
    this.dialogService.employeeCreate();
  }

  showFeedback (feedback) {
    this.dialogService.feedback(feedback);
  }

  chooseTargetDialog () {
    this.ngDialog.open({
      template: dialog_html(),
      plain: true,
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      controller: 'NeoBeraterTargetDialogController',
      data: {
        changeEmployee: () => this.choseEmployee()
      }
    });
  }

  choseEmployee () {
    this.$state.go('inApp.neo.side.berater', {
      employeeId: this.vbmData.employeeId
    });
  }

}

DashboardBeraterController.$inject = $inject;
