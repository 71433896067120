import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  constructor() {
  }

  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }

  async executePromisesInOrderAndShowLoading<T>(promises: (() => Promise<T>)[]): Promise<T[]> {
    const results: T[] = [];

    this.show();
    for (const promise of promises) {
      const result = await promise();
      results.push(result);
    }
    this.hide();

    return results;
  }


}
