angular
  .module('VSPApp')
  .controller('CheckoutController', [
    '$scope',
    '$location',
    '$window',
    'Made',
    'vbmData',
    'userService',
    'dialogService',
    function  (
      $scope,
      $location,
      $window,
      Made,
      vbmData,
      userService,
      dialogService
    ) {

      $scope.globalCheckoutCtrl = {
        step: 1,
        steps: 5
      };

      if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
        vbmData.employeeId = parseInt($location.search().employeeId);
      }

      //userService.getIntegrityStatus(true).then(userinfo => {
      //  if (userinfo && userinfo === 'data') {
      //    dialogService.employeeIntegrity(userinfo);
      //  }
      //});

      $scope.employeeId = vbmData.employeeId || Made.user.valuenet_id;

      $scope.goBack = function () {
        $window.history.back();
      };

    }]);

