import { currency } from './shared';

export default class EBike {

  static calculate( data, modifier ) {

    data.ag_kosten = 0;

    data.value     = currency( modifier.leasing * data.verkaufspreis );
    data.ag_kosten = currency( Math.max( modifier.insurance.factor * data.verkaufspreis, modifier.insurance.minimum ) );

    data.ag_kosten += data.value;
    data.ag_kosten = currency( data.ag_kosten );
  }
}
