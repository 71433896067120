import { Component, Inject, Input, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import {LoadingService} from "../../../../shared/services/loading.service";


@Component({
  selector: 'vn-direct-bonus-unpaid-dialog',
  templateUrl: './direct-bonus-unpaid-dialog.component.html',
  styleUrls: ['./direct-bonus-unpaid-dialog.component.scss']
})
export class DirectBonusUnpaidDialogComponent implements OnInit {
  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  private get comment(): string {
    return this.dialogInputForm.get('comment')?.value;
  }

  private get OTRSTicket(): string {
    return this.OTRSTicketForm.get('OTRSTicket')?.value;
  }

  public dialogInputForm!: FormGroup;
  public OTRSTicketForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectBonusUnpaidDialogComponent>,
    private directBonusService: DirectBonusService,
    private notificationService: AlertService,
    private loader: LoadingService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData
  ) {
  }

  ngOnInit(): void {
    this.initForms();
  }

  private initForms() {
    this.dialogInputForm = this.formBuilder.group({
      comment: this.formBuilder.control('', [Validators.required])
    });

    this.OTRSTicketForm = this.formBuilder.group({
      OTRSTicket: this.formBuilder.control('', [Validators.required])
    });
  }

  public canMarkOrderAsUnpaid() {
    return this.dialogInputForm.valid && this.OTRSTicketForm.valid;
  }

  public markOrderAsUnpaid() {
    this.loader.show();
    return this.directBonusService.markOrderAsUnpaid(this.orderId, this.comment, this.OTRSTicket)
      .subscribe(
        (result) => {
          this.loader.hide();
          if (result) {
            this.notificationService.message('Sie haben diese Bestellung als nicht bezahlt markiert. Das vorherige Zahlungsdatum ist aus der Bestellung entfernt. Die Bestellung hat jetzt den Status “Fakturiert”.')

            this.dialogRef.close(true);
          }
        },
        (error) => {
          this.loader.hide();
          // TODO: Do proer error handling with backend errors;
          this.notificationService.error('Fehler');
        }
      );
  }
}


