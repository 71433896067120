const $inject = [
  '$scope',
  'ShopConstants',
  'customerService',
  'PopupService',
  'PopupVideoKeys'
];

export default class shopPreController {

  constructor(
    $scope,
    ShopConstants,
    customerService,
    PopupService,
    PopupVideoKeys
  ) {
    this.$scope = $scope;
    this.ShopConstants = ShopConstants;
    this.customerService = customerService;
    this.PopupService = PopupService;
    this.$scope.shopDetailsArray = [];

    this.$scope.clicker = {};

    this.PopupService.processModuleVideo(
      PopupVideoKeys.MODULE_SHOP,
      this.$scope.clicker
    );

    this.customerService
      .isTestmode()
      .then(testmode => this.$scope.testmode = testmode);

    this.customerService
      .getConfiguration()
      .then(configuration => {
        this.$scope.blacklist = [];

        angular.forEach(configuration.shop, (module, key) => {
          if (!module.enabled) this.$scope.blacklist.push(key);
        });

        angular.forEach(this.ShopConstants, (module, index) => {
          if (-1 === this.$scope.blacklist.indexOf(index)) this.$scope.shopDetailsArray.push(module);
        });


      });

  }
}

shopPreController.$inject = $inject;
