const $inject = [];
export default class BonusCalculationService {

  constructor() {}


  applyStSvBrutto( bonus_parameter, vbmResult ) {

    bonus_parameter.st_brutto = vbmResult.final.tax.brutto_steuer;
    bonus_parameter.sv_brutto = vbmResult.final.tax.brutto_sv;

  }



  defaultParameters() {
    let d = new Date();

    return {
      neo_mode:                   'BONUS',
      einmalzahlung_bisher:       0,
      brutto_delta:               0,
      sv_brutto_bisher:           0,
      entgeltverzicht_additional: 0,
      st_brutto_bisher:           0,
      neo_value:                  0,
      jahr:                       d.getFullYear(),
      monat_bisher:               (d.getMonth() + 12) % 12,
      einmalzahlung:              0,
      st_brutto:                  0,
      sv_brutto:                  0,
      mode:                       'APPROX',
      monat:                      Math.max( d.getMonth() + 1, 4 ),
      neo_components:             {}
    };
  }


  generateDefaultParameters( employeeId ) {

    let bonus_parameter = this.defaultParameters();

    bonus_parameter.employee_id    = employeeId;
    bonus_parameter.neo_components = {
      sachbezug: {
        pst:                 'AG',
        enabled:             false,
        value:               0,
        bonus_monthly_value: 50,
        sachbezugwahl:       2
      },
      essenscheck: {
        enabled:                 false,
        anzahl_tage:             0,
        bonus_anzahl_gutscheine: 0,
        is_sachbezug:            true,
        pst:                     'AG',
        value:                   0
      },
      VMA_STSVFREI: {
        enabled:             false,
        anzahl_tage:         0,
        bonus_anzahl_tage:   0,
        anzahl_std_abwesend: 1,
        bonus_type:          1
      },
      VMA_PST: {
        enabled:             false,
        anzahl_tage:         0,
        bonus_anzahl_tage:   0,
        pst:                 'AG',
        anzahl_std_abwesend: 1,
        bonus_type:          1
      },
      werbeflaeche: {
        enabled:             false,
        bonus_monthly_value: 21,
        bonus_type:          1
      },
      besonderer_anlass: {
        enabled:    false,
        bonus_type: 2
      },
      erholungsbeihilfe: {
        enabled:             false,
        bonus_monthly_value: 13,
        bonus_type:          2,
        value:               156
      },
      internet: {
        enabled:             false,
        pst:                 'AG',
        value:               0,
        bonus_monthly_value: 50,
        bonus_type:          1
      },
      entfernung: {
        enabled:     false,
        value:       0,
        anzahl_tage: 0,
        anzahl_km:   0,
        cent_pro_km: 0.3,
        bonus_type:  2
      },
      kinder: {
        enabled:             false,
        value:               0,
        bonus_monthly_value: 0,
        bonus_type:          2
      },
      bgmbudget: {
        enabled:             false,
        value:               0,
        bonus_monthly_value: 0,
        bonus_type:          2
      },      
      jobticket: {
        enabled:             false,
        value:               0,
        bonus_monthly_value: 0,
        bonus_type:          2
      },
      egeschenk: {
        enabled:             false,
        value:               0,
        bonus_monthly_value: 0,
        bonus_type:          2
      }, 
    };

    return bonus_parameter;
  }


  generateParametersFromProject(
    employeeId, project, calculationResult
  ) {

    let d             = new Date(),
      bonus_parameter = this.generateDefaultParameters( employeeId );

    bonus_parameter.project_id = project._id;

    bonus_parameter.einmalzahlung = project.configuration.amount;
    bonus_parameter.jahr          = parseInt( project.configuration.startMonth.split( '.' )[ 1 ] );
    bonus_parameter.monat         = parseInt( project.configuration.startMonth.split( '.' )[ 0 ] );

    if ( project.configuration.components.sachbezug ) {
      bonus_parameter.neo_components.sachbezug.enabled = true;
      bonus_parameter.neo_components.sachbezug.value   = project.configuration.components.sachbezug.value;
    }

    this.applyStSvBrutto( bonus_parameter, calculationResult );

    bonus_parameter.sv_brutto_bisher = bonus_parameter.sv_brutto * (d.getMonth() + 1);
    bonus_parameter.st_brutto_bisher = bonus_parameter.st_brutto * (d.getMonth() + 1);

    return bonus_parameter;
  }


  calculateBonusRest( parameters ) {
    let rest = parameters.einmalzahlung || 0;

    for (let componentId in parameters.neo_components) {
      if ( parameters.neo_components[ componentId ].enabled ) {
        rest -= parameters.neo_components[ componentId ].value || 0;
      }
    }

    return rest;
  }

}

BonusCalculationService.$inject = $inject;