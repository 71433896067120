export const DIRECT_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS = {
  budget: [
    {
      label: 'Sachbezug',
      prop: 'sachbezug',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-employeeBenefit',
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-employeeBenefit',
        }
      ]
    },
    {
      label: 'Besondere Anlässe p.a.',
      prop: 'besonderer_anlass',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-specialOccasion',
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-specialOccasion',
        }
      ]
    }
  ],
  metadata: [
    {
      id: 0,
      type: 'integer_input',
      parent: 'approvals_config',
      key: 'approval_deadline_month_day',
      title: 'Fälligkeitstag',
      value: {default: 23},
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputDeadlineDayOfMonth',
      settings: {
        is_required: true,
        is_editable: false,
        currency: {to_format: false},
        min: 1,
        max: 31
      }
      // handled in the controller
      // total bullshit , whole logic around this way of dynamic fields should be reconsidered and refactor its a fucking mess cant be refactored
      // its not flexible
      // if this field depends on a logic of another its hell
    },
    {
      id: 1,
      type: 'checkbox',
      parent: 'approvals_config',
      key: 'automatic_order_confirmation',
      title: 'Autobestätigung aktivieren',
      value: {default: false},
      cyData: 'vn.cockpit.bonus.createProjectDialog.directBonusAutomaticConfirmation',
      settings: {
        is_required: true,
      }
    },
    {
      id: 2,
      type: 'string_input',
      parent: 'approvals_config',
      key: 'automatic_order_confirmation_day',
      title: 'Fälligkeitstag für Autobestätigung',
      value: {
        default: null
      },
      settings: {
        is_required: false,
        is_visible: () => {

        },
        visible_by: 'approvals_config.automatic_order_confirmation'
        // currency: { to_format: false },
        // min: 1,
        // max: 31
      }
    },
    {
      id: 3,
      type: 'string_input',
      parent: 'approvals_config',
      key: 'order_confirmation_reminder_day',
      title: 'Erinnerung am',
      value: {
        default: null
      },
      cyData: 'vn.cockpit.bonus.createProjectDialog.orderConfirmationReminderDay',
      settings: {
        is_required: false,
        // currency: {to_format: false},
        // min: 1,
        // max: 31
      }
    }
  ]
};
