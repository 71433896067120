var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};



















































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Ch1\u003EKontaktdetails\u003C\u002Fh1\u003E\u003Cbr\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-3\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Aktuell Private Email","key": "$ctrl.contactDetails.emailPrivate","attributes": {"ng-disabled":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-9\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Neu Private Email","key": "$ctrl.privateEmailModel.value","type": "email","id": "{{$ctrl.privateEmailModel.control_name}}","validationId": "$ctrl.privateEmailModel.control_name","attributes": {"ng-change":"$ctrl.markPrivateEmailForCheck()"},"validationMessages": {"email":"Bitte geben Sie eine gültige E-Mail Adresse an","invalid":"Validierung der E-Mail-Adresse ist fehlgeschlagen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-3\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Aktuell Work Email","key": "$ctrl.contactDetails.emailWork","attributes": {"ng-disabled":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-9\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Neu Work Email","id": "{{$ctrl.workEmailModel.control_name}}","validationId": "$ctrl.workEmailModel.control_name","key": "$ctrl.workEmailModel.value","type": "email","attributes": {"ng-change":"$ctrl.markWorkEmailForCheck()"},"validationMessages": {"email":"Bitte geben Sie eine gültige E-Mail Adresse an","invalid":"Validierung der E-Mail-Adresse ist fehlgeschlagen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-3\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Aktuell Arbeit","key": "$ctrl.contactDetails.mobileWork","attributes": {"ng-disabled":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-2\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.workPhoneModel.countryCode","title": "Land","attributes": {"ng-options":"country.code as (country.name + ' ' + country.code) for country in $ctrl.countries","ng-change":"$ctrl.markWorkPhoneForCheck()","ng-required":"$ctrl.isWorkPhoneCountryRequired()"},"validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-7\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "{{$ctrl.workPhoneModel.control_name}}","validationId": "$ctrl.workPhoneModel.control_name","key": "$ctrl.workPhoneModel.number","title": "Neu Telefonnnummer Arbeit","attributes": {"ng-pattern":"$ctrl.phoneValidationRegex","ng-change":"$ctrl.markWorkPhoneForCheck()"},"validationMessages": {"required":"Es wird eine Eingabe benötigt.","pattern":"Nur \"Ziffern\" erlaubt.","invalid":"Validierung der Telefonnummer ist fehlgeschlagen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-3\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Aktuell Privat","key": "$ctrl.contactDetails.mobilePrivate","attributes": {"ng-disabled":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-2\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.privatePhoneModel.countryCode","title": "Land","required": pug.escape(true),"attributes": {"ng-options":"country.code as (country.name + ' ' + country.code) for country in $ctrl.countries","ng-required":"$ctrl.isPrivatePhoneCountryRequired()","ng-change":"$ctrl.markPrivatePhoneForCheck()"},"validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-7\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "{{$ctrl.privatePhoneModel.control_name}}","validationId": "$ctrl.privatePhoneModel.control_name","key": "$ctrl.privatePhoneModel.number","title": "Neu Telefonnnummer Privat","attributes": {"ng-pattern":"$ctrl.phoneValidationRegex","ng-change":"$ctrl.markPrivatePhoneForCheck()"},"validationMessages": {"pattern":"Nur \"Ziffern\" erlaubt.","invalid":"Validierung der Telefonnummer ist fehlgeschlagen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-3\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Aktuell Festnetz Private","key": "$ctrl.contactDetails.telephonePrivate","attributes": {"ng-disabled":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-2\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.festnetzPrivatePhoneModel.countryCode","title": "Land","required": pug.escape(true),"attributes": {"ng-options":"country.code as (country.name + ' ' + country.code) for country in $ctrl.countries","ng-required":"$ctrl.isFestnetzPrivatePhoneCountryRequired()","ng-change":"$ctrl.markFestnetzPrivatePhoneForCheck()"},"validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-7\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "{{$ctrl.festnetzPrivatePhoneModel.control_name}}","validationId": "$ctrl.festnetzPrivatePhoneModel.control_name","key": "$ctrl.festnetzPrivatePhoneModel.number","title": "Neu Festnetz Private","attributes": {"ng-pattern":"$ctrl.phoneValidationRegex","ng-change":"$ctrl.markFestnetzPrivatePhoneForCheck()"},"validationMessages": {"pattern":"Nur \"Ziffern\" erlaubt.","invalid":"Validierung der Telefonnummer ist fehlgeschlagen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-3\"\u003E";
pug_mixins["input"].call({
attributes: {"title": "Aktuell Festnetz Arbeit","key": "$ctrl.contactDetails.telephoneWork","attributes": {"ng-disabled":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-2\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.festnetzWorkPhoneModel.countryCode","title": "Land","required": pug.escape(true),"attributes": {"ng-options":"country.code as (country.name + ' ' + country.code) for country in $ctrl.countries","ng-required":"$ctrl.isFestnetzWorkPhoneCountryRequired()","ng-change":"$ctrl.markFestnetzWorkPhoneForCheck()"},"validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-7\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "{{$ctrl.festnetzWorkPhoneModel.control_name}}","validationId": "$ctrl.festnetzWorkPhoneModel.control_name","key": "$ctrl.festnetzWorkPhoneModel.number","title": "Neu Festnetz Arbeit","attributes": {"ng-pattern":"$ctrl.phoneValidationRegex","ng-change":"$ctrl.markFestnetzWorkPhoneForCheck()"},"validationMessages": {"pattern":"Nur \"Ziffern\" erlaubt.","invalid":"Validierung der Telefonnummer ist fehlgeschlagen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"colums\"\u003E\u003Cbutton class=\"button right button-primary\" ng-disabled=\"!$ctrl.canUpdatePhones()\" ng-click=\"$ctrl.updatePhones()\" overlay=\"$ctrl.isLoading()\"\u003ESpeichern\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"name": "$ctrl.forms.contactDetails"}
});
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;