const VorsorgeConstants = {
  betriebliche_altersvorsorge: {
    breakTitle: 'Betriebliche Altersvorsorge',
    title: 'Betriebliche Altersvorsorge',
    helper: 'betriebliche_altersfürsorge',
    image: '/assets/img/vorsorge/Altersvorsorge-img.png',
    videoUrl: '',
    embedUrl: '',
    id: 'bav',
    href: '/verguetungbav',
    longTitle: 'Interessieren Sie sich für eine BAV?',
    description: 'Die gesetzliche Rentenversicherung wird Ihnen in Zukunft nur noch eine Grundversorgung bieten. Ohne zusätzliche Altersversorgung drohen Ihnen erhebliche finanzielle Einbußen im Alter. Durch unsere Nettobausteine können sie eine zusätzliche Altersvorsorge aufbauen - und das ohne auf Nettogehalt verzichten zu müssen.',
    description2: 'Lorum ipsum...',
    bulletPoints: [],
    icon: 'homework'
  },
  berufsunfähigkeit: {
    breakTitle: 'Berufsunfähigkeits-versicherung',
    title: 'Berufsunfähigkeitsversicherung',
    helper: 'berufsunfähigkeit',
    image: '/assets/img/vorsorge/Berufsunfaehigkeit-img.png',
    videoUrl: 'https://player.vimeo.com/video/132422677?dnt=1',
    embedUrl: 'https://player.vimeo.com/video/132422677?dnt=1',
    id: 2,
    href: '/verguetungbuv',
    longTitle: 'Sie interessieren sich für eine BU?',
    description: 'Die staatliche Absicherung im Falle einer Berufsunfähigkeit ist sehr gering. Andererseits ist das Risiko aus gesundheitlich Gründen berufsunfähig zu werden sehr hoch. Deswegen sind entsprechende Versicherungen auch so teuer. Durch unsere Nettobausteine können Sie diese Policen kostenlos abschließen.',
    description2: 'Lorum ipsum...',
    bulletPoints: [],
    disabled: true,
    icon: 'settings2'
  },
  betriebliche_krankenversicherung: {
    breakTitle: 'Betriebliche Krankenversicherung',
    title: 'Betriebliche Krankenversicherung',
    helper: 'betriebliche_krankenversicherung',
    image: '/assets/img/vorsorge/BKV-img.png',
    videoUrl: 'https://player.vimeo.com/video/126800362?dnt=1',
    embedUrl: 'https://player.vimeo.com/video/126800362?dnt=1',
    id: 3,
    href: '/verguetungbav',
    longTitle: 'Eine steuerfreie BKV',
    description: 'Die betriebliche Krankenversicherung (bKV) ist eine private Krankenzusatzversicherung, die für Sie durch Ihren Arbeitgeber abgeschlossen wird. Ihr Arbeitgeber finanziert auch die Beiträge hierzu. Sie profitieren von umfangreichen Gesundheitsleistungen - ohne Gesundheitsprüfung und Wartezeiten! Selbst Vorerkrankungen sind abgesichert. Das ist ein hoher Mehrwert im Vergleich zu eventuell privaten Zusatzversicherungen.',
    description2: 'Über das vorhandene Netzwerk von Spezialisten und Partneranwälten helfen wir Ihnen, Ihre Rechte gegenüber den Anbietern durchzusetzen.',
    bulletPoints: [],
    icon: 'garden'
  }
};

export default VorsorgeConstants;
