const $inject = [
  "Made",
  "$scope",
  "$state",
  "$q",
  "$timeout",
  "customerService",
  "vbmData",
  "employeeService",
  "NotificationService",
  "authenticationService",
  "advisorService",
  "fileService",
  "cmsService"
];

export default class CmsCreateController {
  constructor(Made,
              $scope,
              $state,
              $q,
              $timeout,
              customerService,
              vbmData,
              employeeService,
              NotificationService,
              authenticationService,
              advisorService,
              fileService,
              cmsService) {

    Object.assign(this, {
      cmsService,
      $scope,
      $timeout,
      Made,
      $q,
      vbmData,
      $state,
      employeeService,
      NotificationService,
      authenticationService,
      advisorService,
      customerService,
      fileService
    });

    this.projects = [];
    this.locations = [];

    this.cmsContent = {
      'title': '',
      'category': '',
      'content': '',
      'is_html': false,
      'stage': null,
      'teaser': null,
      'download': null,
      'button': {
        'text': '',
        'target': ''
      },
      'userlane': {
        'text': '',
        'userlaneId': ''
      },
      'startdate': '',
      'enddate': '',

      'deleted': false,

      'filterType': '1',
      'customer': null,
      'locations': [],
      'projects': [],

      'pinned': false
    };

    this.custId = $state.params.custId;
    this.categories = [];

    this.cmsService.loadCategoryList(this.custId).then(categories => {
      this.categories = categories;
    });
    this.cmsService.globalCms.detailId = 0;
    this.initCmsContent($state.params.cmsId);


    this.$scope.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.$scope.filters = {
      customer: {
        filter: '',
        instance: null,
        customerList: null
      },
      template: {
        filter: '',
        instance: null,
        templateList: null
      }
    };
    this.$scope.loadingCustomers = true;
    if (this.$scope.iAmSpecialAdvisor) {
      this.customerService.getByCustomerId(1).then(customer => {
        $timeout(() => {
          this.$scope.filters.customer.customerList = [customer];
          this.$scope.filters.customer.instance = customer
          this.$scope.loadingCustomers = false;
        });
      });
    } else {
      this.$scope.loadingCustomers = false;
    }


    // watch for selection on customer
    this.$scope.$watch('filters.customer.instance', (newValue, oldValue) => {
      if (!angular.equals(newValue, oldValue)) {
        // reset old selected templatе
        this.$scope.filters.template.instance = null;

        // fetch new templates
        this.cmsService.loadEditorList(this.$scope.filters.customer.instance.id).then(cmsItems => {
          $timeout(() => {
            this.$scope.filters.template.templateList = cmsItems || null;
          }, 0);
        })
      }
    });
  }

  openDocument(document){
    this.fileService.openDocument(document._id);
  }

  loadTemplate() {
    if (this.$scope.filters.customer.instance && this.$scope.filters.template.instance) {
      this.cmsService.loadById(this.$scope.filters.template.instance._id).then((article) => {
        this.$timeout(() => {
          // remove old category
          delete article.category;
          delete article.customer;
          delete article._id;

          // override current cmsContent
          this.cmsContent = Object.assign(this.cmsContent, article);
        }, 0);
      });
    }
  }

  getPlatformHint() {
    let hint = '';
    switch (this.cmsContent.videoPlatform) {
      case 'youtube':
        hint = 'https://youtu.be/';
        break;
      case 'vimeo':
        hint = 'https://vimeo.com/';
        break;
    }
    return hint;
  }

  async loadLocations() {
    this.customerService.getLocations(this.cmsContent.customer).then(locations => {
      this.locations = [];
      if (!locations) {
        return;
      }

      for (let i = 0; i < locations.length; i++) {
        this.locations.push({
          'id': locations[i].id,
          'name': [locations[i].name, locations[i].strasse, locations[i].plz, locations[i].ort].join(', '),
          'selected': this.cmsContent.locations.indexOf(locations[i].id) !== -1
        })
      }
    });
  }

  async initCmsContent(cmsId) {
    if (this.cmsContent.customer === null) {
      this.cmsContent.customer = await this.employeeService.getCustomerId(this.vbmData.employeeId);
    }

    if (this.cmsContent.customer != this.custId && typeof this.custId !== 'undefined') {
      this.cmsContent.customer = parseInt(this.custId);
    }

    if (cmsId) {
      this.cmsContent = await this.cmsService.loadById(cmsId);
      this.formatDatesAfterLoad();
      this.updateFilterOptions(false);
    }

    this.cmsService.globalCms.loading = false;
    this.$scope.$applyAsync();
  }

  formatDatesAfterLoad() {
    if (this.cmsContent.startdate && this.cmsContent.startdate !== '') {
      this.cmsContent.startdate = this.timestampToDatestring(this.cmsContent.startdate);
    }
    if (this.cmsContent.enddate && this.cmsContent.enddate !== '') {
      this.cmsContent.enddate = this.timestampToDatestring(this.cmsContent.enddate);
    }
  }

  timestampToDatestring(date) {
    let dbDate = new Date(date * 1000);
    return dbDate.getDate() + '.' + (dbDate.getMonth() + 1) + '.' + dbDate.getFullYear();
  }

  formatDatesForSave() {
    if (this.cmsContent.startdate && this.cmsContent.startdate !== '') {
      this.cmsContent.startdate = this.datestringToTimestamp(this.cmsContent.startdate);
    }
    if (this.cmsContent.enddate && this.cmsContent.enddate !== '') {
      this.cmsContent.enddate = this.datestringToTimestamp(this.cmsContent.enddate);
    }
  }

  datestringToTimestamp(date) {
    let split = date.split('.'),
      day = split[0],
      month = split[1],
      year = split[2];

    return Math.round(new Date(year, month - 1, day).getTime() / 1000);
  }

  async upload(filedata) {
    if (!filedata) {
      return filedata;
    }

    if (!filedata._id) {
      let result = await this.Made.upload(filedata, [
        'cms'
      ]);

      filedata = result.data;
      filedata.name = result.data.filename;
      filedata.size = result.data.chunk_size;
    }

    return filedata;
  }

  async uploadMultiple(filelist) {
    if (!filelist) {
      return filelist;
    }

    let newFileList = [];
    for (let file of filelist) {
      newFileList.push(await this.upload(file));
    }

    return newFileList;
  }

  async submit() {
    this.cmsService.globalCms.loading = true;
    this.cmsContent.stage = await this.upload(this.cmsContent.stage);
    this.cmsContent.teaser = await this.upload(this.cmsContent.teaser);
    this.cmsContent.download = await this.uploadMultiple(this.cmsContent.download);

    this.cmsContent.locations = [];
    for (let i = 0; i < this.locations.length; i++) {
      if (this.locations[i].selected) {
        this.cmsContent.locations.push(this.locations[i].id);
      }
    }

    this.formatDatesForSave();
    let serviceResult = await this.cmsService.save(this.cmsContent);

    if (this.cmsContent._id) {
      this.$state.reload();
    } else {
      this.$state.go('inApp.cms.edit', {'cmsId': serviceResult.id, 'custId': this.custId});
    }

    this.NotificationService.message('Speichern erfolgreich.');

    this.cmsService.globalCms.loading = false;
  }

  updateFilterOptions(forceReset = true) {
    if (forceReset) {
      this.cmsContent.locations = [];
      this.cmsContent.projects = [];
    }

    switch (this.cmsContent.filterType) {
      case '2': // locations
        this.loadLocations();
        break;
    }

    return true
  }
}

CmsCreateController.$inject = $inject;
