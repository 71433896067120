import { BonusComponentController } from './BonusComponentController';
import { MONTHS_IN_YEAR } from '../../../services/BonusService'
import {TAX_TYPE_BY_NAME} from "../../../services/bonus/tax_type";

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export class BonusComponentControllerTelecommunication extends BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {
    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true) {
    return super.addComponentToEmployeeBonusComponents(false).then(async (result) => {
      if (!result) {
        return;
      }

      if (!this.model.sliders) {

        let sliders = {
          advantage: angular.copy(this.SliderOptionsConstants),
          months: angular.copy(this.SliderOptionsConstants)
        };

        // if it was set by assistant but you enter for the first time
        if (this.model.max_advantage_value === undefined) {
          let remaining_amount = await this.calculateRemainingWithoutCurrentBonus();
          this.max_advantage_value = (await this.BonusService.getMaxValues(
            this.bonus_component_name,
            this.moment().year())
          )[this.bonus_component_name];

          this.max_advantage_value = Math.min(this.max_advantage_value || Number.MAX_SAFE_INTEGER, this.component_budget_config.range.max_eq, remaining_amount);

        } else {
          this.max_advantage_value = this.model.max_advantage_value;
        }

        let advantage_slider_config = this.BonusService.createSliderConfig(this.max_advantage_value, 0);
        Object.assign(sliders.advantage, advantage_slider_config);
        let advantage = Math.min(this.component_budget_config.value, this.max_advantage_value);

        if (this.model.max_months === undefined) {
          let available_months = Math.floor(this.employee_bonus_checkout_config.remaining_amount / this.component_budget_config.value);
          this.max_months = Math.max(Math.min(this.bonus_project_duration_in_months, available_months), 0);
        } else {
          this.max_months = this.model.max_months;
        }

        Object.assign(sliders.months, {
          to: this.max_months,
          step: 1,
          from: 0
        });

        this.model.sliders = sliders;

        this.setModelValues({
          advantage: advantage,
          months: 0
        });

        // everything has been set by the optimization
        if (!this.model.is_optimized) {
          await this.calculate();
        }

      } else {
        // recalculate modelLabels which were deleted before save
        // this.BonusService.recalculateSlidersModelLabels(this.model.sliders);

        this.setModelValues({
          advantage: this.component_budget_config.value,
          months: 1
        });

        this.setMaxMonths(false);
      }

      this.calculateMonthsDifference();

      if (this.model.advantage && this.model.advantage === 0) {
        this.model.advantage = 1;
        // this.wizardCanceled();
      }

      this.advantage_watcher = this.$scope.$watch(() => { return this.model.advantage; }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            // this.calculate();
            this.setMaxMonths();
            this.calculateValue();
            this.calculateMonthsDifference();
          }, 0);
        }
      });

      this.months_watcher = this.$scope.$watch(() => { return this.model.months; }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            // this.calculate();
            this.calculateValue();
            this.calculateMonthsDifference();
          }, 0);
        }
      });

      this.$scope.$on('$destroy', () => {
        this.advantage_watcher();
        this.months_watcher();
        // this.remaining_amount();
      });

      if (move_to_next_step) {
        this.WizardHandler.wizard().next();
      }
    });
  }

  async init() {
    this.TAX_TYPE_BY_NAME = TAX_TYPE_BY_NAME;
  }

  setMaxMonths(to_reset_current = true) {
    let remaining_amount = this.calculateRemainingWithoutCurrentBonus();
    let max_months = Math.floor(remaining_amount / Math.max(this.calculatePerMonthValue(true), 1));
    max_months = Math.max(Math.min(max_months, this.bonus_project_duration_in_months, MONTHS_IN_YEAR, this.max_available_months), 0);

    if (max_months === 0) {
      // this.wizardCanceled();
      max_months = 1;
    }

    if (this.model.sliders.months.to !== max_months) {
      // if (this.model.months > max_months) {
      //   this.model.months = max_months;
      // }
      this.model.sliders.months.to = max_months;
    }

    if (to_reset_current) {
      this.model.months = 0;
    }
  }

  calculatePerMonthValue(return_with_tax = false) {
    let per_month_value = this.model.advantage;

    if (this.is_additional_tax_enabled(TAX_TYPE_BY_NAME['monthly']['id'])) {
      let tax_result = this.get_additional_tax_value(per_month_value, TAX_TYPE_BY_NAME['monthly']['id']);
      let per_month_tax_value = this.lodash.get(tax_result, 'overall', 0);

      // ASSUME ALL TAX_PROVIDERS HAVE SAME PAYER
      let tax_provider = Object.keys(this.component_budget_config.additional_costs)[0];
      this.model.tax_payer = this.component_budget_config.additional_costs[tax_provider].payer;

      this.model.per_month_tax_value = per_month_tax_value;
      this.model.per_month_value_netto = per_month_value;
      // per_month_value += per_month_tax_value;
      delete tax_result.overall;
      this.model.tax_result = this.lodash.merge(this.model.tax_result || {}, tax_result);
    }

    this.model.per_month_value = per_month_value;
    if (return_with_tax) {
      return this.model.per_month_value + (this.model.per_month_tax_value || 0);
    }
    return this.model.per_month_value;
  }

  calculate() {
    this.setMaxMonths();
    this.calculateValue();
  }

  is_additional_tax_enabled(tax_type_id) {
    const omg = this.BonusService.is_additional_tax_enabled(this.component_budget_config, tax_type_id);
    return this.BonusService.is_additional_tax_enabled(this.component_budget_config, tax_type_id);
  }

  get_additional_tax_value(tax_base, tax_type_id) {
    let tax_result = this.BonusService.get_additional_tax_value(this.component_budget_config, {
      tax_base: tax_base,
      tax_type_id: tax_type_id,
      employee_bonus_project_configuration: this.employee_bonus_project_configuration,
      checkout_type: this.checkout_type,
      bonus: this.bonus
    });
    return tax_result;
  }

  toAllowComponentAddition() {
    let super_to_allow = super.toAllowComponentAddition();
    let is_value_valid = this.is_additional_tax_enabled() ? this.model.netto_value > 0 : this.model.value > 0;
    return super_to_allow && is_value_valid;
  }

  calculateValue() {
    this.model.value = this.calculatePerMonthValue(true) * this.model.months;

    if (this.is_additional_tax_enabled()) {
      this.model.netto_value = !angular.isUndefined(this.model.per_month_value_netto) ? this.model.per_month_value_netto * this.model.months : this.model.value;

      let tax_result = this.get_additional_tax_value(this.model.netto_value, TAX_TYPE_BY_NAME['yearly']['id']);
      let fixed_tax = this.lodash.get(tax_result, 'overall', 0);
      this.model.fixed_tax = fixed_tax;

      delete tax_result.overall;
      this.model.tax_result = this.lodash.merge(this.model.tax_result || {}, tax_result);
      let variable_tax = (this.model.per_month_tax_value || 0) * this.model.months;
      this.model.tax_value = variable_tax + (this.model.fixed_tax || 0);
      this.model.variable_tax = variable_tax;
      this.model.value = this.model.tax_value === 0 ? this.model.value : this.model.value + (this.model.fixed_tax || 0);
    }

    this.model.duration_months = this.model.months;
  }
}

BonusComponentControllerTelecommunication.$inject = $inject;
