const vorsorgeIcon = ["VorsorgeConstants", function (VorsorgeConstants) {
  return function (input) {

    if ('object' === typeof input) {
      input = input.id;
    }

    if (VorsorgeConstants[input]) {
      return VorsorgeConstants[input].icon;
    } else {
      console.log('vorsorgeIcon filter - unknown: ', input);
      return 'unknown';
    }
  };
}]

const vorsorgeTitle = ["VorsorgeConstants", function (VorsorgeConstants) {
  return function (input) {

    if ('object' === typeof input) {
      input = input.id;
    }

    if (VorsorgeConstants[input]) {
      return VorsorgeConstants[input].title;
    } else {
      console.log('vorsorgeTitle filter - unknown: ', input);
      return 'unknown';
    }
  };
}]

export { vorsorgeIcon, vorsorgeTitle }
