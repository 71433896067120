<section class='car-catalog'>
    <h1>
        Aktuell verfügbare Modelle
    </h1>

    <div class='row filters'>
        <form [formGroup]='filtersGroup'>
            <vn-select formControlName='brand'
                       label="Marke"
                       placeholder='Alle'
                       [options]='filterOptions?.brand'>
            </vn-select>

            <vn-select formControlName='gearshift'
                       label="Schaltung"
                       placeholder='Alle'
                       [options]='filterOptions?.gearshift'>
            </vn-select>

            <vn-select formControlName='cartype'
                       label="Fahrzeugtyp"
                       placeholder='Alle'
                       [options]='filterOptions?.cartype'>
            </vn-select>

            <vn-select formControlName='fuel'
                       label="Kraftstoff"
                       placeholder='Alle'
                       [options]='filterOptions?.fuel'>
            </vn-select>

            <vn-select formControlName='duration'
                       label="Laufzeit"
                       placeholder='Alle'
                       [options]='filterOptions?.duration'>
            </vn-select>

            <vn-date-range formControlName='availability'
                           label='Verfügbarkeit'
                           placeholder='Zeitinterval auswählen'>
            </vn-date-range>
        </form>
    </div>

    <div class='row'>
        <div class='xsmall-12 columns scrollable'>
            <div class='row bausteineRow'
                 vnLoader
                 [showLoader]='(isLoading$() | async)!'>
                <div *ngFor="let car of cars$ | async"
                     vnRandomAnimate
                     class='xsmall-12 small-6 large-4 columns'>
                    <div class='bausteinWrapper'
                         data-cy='neo.preCheckout.carLeasing.carCatalog.cardCarModel'
                         (click)='navigateToCar(car.id)'>
                        <vn-car-leasing-card [car]=car
                                             >
                            >
                        </vn-car-leasing-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
