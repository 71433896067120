const PST_ENABLED_COMPONENTS = {
  sachbezug: false,
  essenscheckSachbezug: false,
  essenscheck: true,
  VMA_STSVFREI: false,
  VMA_PST: true,
  handy: false,
  festnetz: false,
  werbeflaeche: false,
  dienstwagen_miete: false,
  dienstwagen_auslage: false,
  Sonstiges: false,
  pc: false,
  EBike: false,
  Bikelesasing: false,
  erholungsbeihilfe: true,
  personal_rabatt: false,
  internet: true,
  entfernung: true,
  kinder: false,
};

export const DEFAULT_CALCULATION_INFORMATION = {
  'name': '',
  'is_hidden': false,
  'is_reporting': false,
};

const $inject = ['$q', 'Made', 'SchemaService', 'fileService'];
export default class vbmService {

  constructor($q, Made, SchemaService, fileService) {

    this.$q = $q;
    this.Made = Made;
    this.SchemaService = SchemaService;
    this.availableComponents = [];
    this.fileService = fileService;
  }

  getData(employee_id = this.Made.user.valuenet_id, final = false, preCalculationId = false) {
    if (preCalculationId) {
      return this.Made.request('rpc://vbm/neo/getCalculationByID', {
        employee_id: parseInt(employee_id),
        calcid: parseInt(preCalculationId)
      });
    }

    return this.getLatestCalculation(employee_id, final);
  }

  getLatestCalculation(employee_id = this.Made.user.valuenet_id, final = false) {
    return this.Made.request('rpc://vbm/neo/get_latest_calculation', {
      employee_id: parseInt(employee_id),
      final: final
    });
  }

  //FIXME: force backend
  calculateNewDataFix(data) {
    let params = {
      metadata: data.metadata,
      parameters: data.parameters,
      neo_components: data.neo_components,
      insurances: data.insurances
    };

    if (isNaN(params.metadata.id) || null === params.metadata.id) {
      params.metadata.id = 0;
    }

    if (null === params.metadata.info) {
      params.metadata.info = '';
    }
    if (null === params.parameters.einsparungs_wert) {
      params.parameters.einsparungs_wert = 0;
    }

    params.neo_components.pc = this.checkPCMisConfig(params.neo_components.pc);

    return params;
  }

  checkPCMisConfig(pc) {
    if (pc.useInternalMPP) {
      if (pc.leasinglimit === null) {
        pc.leasinglimit = 0;
      }
      if (pc.boni_nr === null) {
        pc.boni_nr = 0;
      }
    }

    return pc;
  }

  getResult(data, save, override) {
    let params = this.calculateNewDataFix(data);

    if (save) {
      params.fix = ('string' === typeof save) ? save : 'session';
    }

    if (override) {
      params.override = true;
    }

    return this.Made
      .request('rpc://vbm/neo/calculate', params)
      .then(response => {

        if (response.invalid) {
          throw response;
        } else {
          return response;
        }

      });
  }

  applyConfig(data) {

    let params = this.calculateNewDataFix(data);

    // console.log("APPLY CONFIG params - ", params);
    return this.Made
      .request('rpc://vbm/neo/setConfigToCalculation', params)
      .then(response => {

        if (response.invalid) {
          throw response;
        } else {
          return response;
        }

      });
  }

  getDistance(employee_id = this.Made.user.valuenet_id) {
    return this.Made
      .request('rpc://vbm/distance_home_to_work', {
        employee_id
      })
      .then(response => Math.floor(response.rows[0].elements[0].distance.value / 1000));
  }

  getDocument(calcai, employee_id = this.Made.user.valuenet_id, report_id = 0) {

    report_id = parseInt(report_id);

    calcai.evbegin = this.sanitizeEVBegin(calcai.evbegin);

    calcai.vertragsabschluss = calcai.vertragsabschluss || this.fakeVertragsabschluss();

    return this.Made
      .request('rpc://vbm/neo/get_document_url', {
        report_id,
        employee_id,
        calcai
      }).then(file_id => {
        return this.fileService.getDocumentUrl(file_id);
      });
  }

  fakeVertragsabschluss() {

    let now = new Date();

    return now.getDate() + '.' + now.getMonth() + '.' + now.getFullYear();
  }

  sanitizeEVBegin(evbegin) {

    if (!evbegin) {
      let now = new Date();
      evbegin = new Date();

      evbegin.setDate(1);
      evbegin.setMonth(evbegin.getMonth() + 1);
      if (now.getDate() > 10) {
        evbegin.setMonth(evbegin.getMonth() + 1);
      }

      evbegin = evbegin.getDate() + '.' + evbegin.getMonth() + '.' + evbegin.getFullYear();
    }

    let date = /(\d{1,2})\.(\d{1,2})\.(\d{4})/.exec(evbegin);

    if (date) {
      if (1 === date[1].length) {
        date[1] = '0' + date[1];
      }
      if (1 === date[2].length) {
        date[2] = '0' + date[2];
      }
      evbegin = date[1] + '.' + date[2] + '.' + date[3];
    }

    return evbegin;
  }

  getAvailableNeoComponents() {

    if (this.availableComponents.length) {
      return this.$q.when(this.availableComponents);
    }

    return this.Made
      .request('rpc://vbm/neo/available_neo_components')
      .then(response => {

        this.availableComponents = Object.keys(response.properties);
        this.fullComponents = {
          neo_components: response
        };

        return this.availableComponents;
      });
  }

  //
  // calculateZielausgleich( data ) {
  //
  //   let params = {
  //     metadata:       data.metadata,
  //     parameters:     data.parameters,
  //     neo_components: data.neo_components,
  //     insurances:     data.insurances,
  //     mode:           data.parameters.einsparungs_modell
  //   };
  //
  //   if ( [
  //       'AN',
  //       'AG'
  //     ].includes( data.parameters.einsparungs_modell ) ) {
  //
  //     params.value = data.parameters.einsparungs_wert;
  //
  //   } else if ( [
  //       'AN_RELATIVE',
  //       'AG_RELATIVE',
  //       'AN_AG_RELATIVE'
  //     ].includes( data.parameters.einsparungs_modell ) ) {
  //
  //     params.value = data.parameters.einsparungs_prozent;
  //   }
  //
  //
  //   if ( isNaN( params.metadata.id ) || null === params.metadata.id ) params.metadata.id = 0;
  //
  //   return this.Made
  //     .request( 'rpc://vbm/zielausgleich/calculate', params );
  // }

  getMPPLink(customerId) {
    return this.Made
      .request('rpc://vbm/get_mpp_link', {
        custid: parseInt(customerId)
      });
  }

  sendLastCalculationEmail(employee_id = this.Made.user.valuenet_id, tos = {
    berater: true,
    mitarbeiter: true,
    kontrolle: true
  }, checkout_advisor_id = 0, is_direct_contract) {
    return this.Made
      .request('rpc://vbm/neo/send_last_calculation_email', {
        employee_id,
        host: location.origin,
        tos: tos,
        checkout_advisor_id: checkout_advisor_id,
        is_direct_contract: is_direct_contract
      });
  }

  getPstEnabledComponents() {
    return this.$q.when(PST_ENABLED_COMPONENTS);
  }

  hasFinalCalculation(employeeId = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://valuenetdb/calculation/hasFinalCalculation', {
      employee_id: parseInt(employeeId)
    });
  }

  yearsToPension(employee) {
    var now = new Date();

    return employee.data.metadata.renteneintritt -
      now.getFullYear() +
      parseInt(employee.data.parameters.geburtsdatum.split('.')[2]);
  }


  approximateVA(employee, nettoabzug = false, employerFunded = false) {

    let params = this.calculateNewDataFix(employee.data);

    params.years_to_pension = this.yearsToPension(employee);
    params.pension_gap = employee.result.final.gap.rente;
    params.pension_interest = employee.data.parameters.va_prozent;
    params.nettoabzug = nettoabzug;
    params.employerFunded = employerFunded;

    return this.Made.request('rpc://vbm/approximateVA2', params);
  }

  getSingleResult(data, component) {
    for (let comp in data.neo_components) {
      if (data.neo_components[comp].enabled && comp !== component) {
        data.neo_components[comp].enabled = false;
      }
    }

    data.neo_components[component].enabled = true;

    data.insurances.va.beitrag = 0;
    data.insurances.va.zuschuss_ag = 0;
    data.insurances.bav.beitrag = 0;
    data.insurances.bav.zuschuss_ag = 0;

    if (data.parameters.einsparungs_modell !== 'NONE') {
      data.parameters.einsparungs_modell = 'NONE';
      data.parameters.einsparungs_prozent = 0;
      data.parameters.einsparungs_wert = 0;
    }

    data.parameters.entgeltverzicht_additional = 0;

    //console.log('welche kompnent geht gerade raus: '+JSON.stringify(data.neo_components));

    return this.getResult(data, false, false);
  }

  getCalcVersicherer(employeeId = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://valuenetdb/calculation/getFinalInsurance', {
      employee_id: parseInt(employeeId)
    });
  }

  generateSalaryComparisonData(protocol_data) {
    return this.Made.request('rpc://utility/pdfgeneratorapi/generate_salary_comparison_document', {
      protocol_data
    });
  }

  generateBalanceData(protocol_data) {
    return this.Made.request('rpc://utility/pdfgeneratorapi/generate_balance_document', {
      protocol_data
    });
  }
}
vbmService.$inject = $inject;
