<h2 mat-dialog-title>
    Bestellung unbezahlt
</h2>

<mat-dialog-content>
    <p>
        Sie markieren diese Bestellung als nicht bezahlt. Die Bestellung wird in den
        Status “Fakturiert” wechseln. Bitte hinterlassen Sie eine Begründung dafür:
    </p>

    <form [formGroup]="dialogInputForm">
        <vn-input formControlName='comment'
                  key="vn.directBonus.backofficeAdministration.markAsUnpaidDialog.inputReason"
                  label="Kommentar">
        </vn-input>
    </form>

    <div>
        <p>
            Fügen Sie bitte in das untere Eingabefeld auch die Nummer des auftragsbezogenen OTRS-Tickets ein:
        </p>
    </div>

    <form [formGroup]="OTRSTicketForm">
        <vn-input formControlName='OTRSTicket'
                  key="vn.directBonus.backofficeAdministration.markAsUnpaidDialog.inputOTRSTicket"
                  label="OTRS Ticket">
        </vn-input>
    </form>

    <div>
        <p>
            Nach dem Klick auf “Bestätigen“ wird auch das vorherige Zahlungsdatum aus der Bestellung entfernt.
            Die hinterlegte Zahlungsart wird nicht verändert.
        </p>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='create-button'
            data-cy='vn.directBonus.backofficeAdministration.markAsUnpaidDialog.buttonSubmit'
            [disabled]="!canMarkOrderAsUnpaid()"
            mat-stroked-button
            (click)="markOrderAsUnpaid()">
        Bestätigen
    </button>
</mat-dialog-actions>