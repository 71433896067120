angular
  .module('VSPApp')
  .service('quizFactory', ['$q', function ($q) {

    var quiz = function (steps, description, model) {
      this.steps = steps || [];
      this.description = description || '';
      this.state = undefined;
      this.model = model || {};
    };

    quiz.prototype.start = function () {
      var keys = Object.keys(this.steps);

      if (keys && keys.length)
        return this.step(keys[0]);
      this.skip();
    };

    quiz.prototype.step = function (index) {
      var that = this,
        next = this.steps[index],
        promise = $q.when(index);

      if (!next)
        return this.skip();
      this.loading = true;

      if (next.enter) promise = promise.then(next.enter);

      promise = promise
        .then(function (newIndex) {
          console.log('.-....', newIndex, next);
          if (index !== newIndex)
            return that.step(newIndex);
          that.state = index;

          return next;
        });

      promise.finally(() => {
        this.loading = false;
      });

      return promise;
    };

    quiz.prototype.goTo = function (index) {
      var that = this,
        current = this.steps[this.state],
        promise = $q.when(index);

      // console.log('quiz goto ---', current);

      if (current.exit) promise = promise.then(function (next) {
        return current.exit(next);
      });
      if (this.update) this.update();

      return promise
        .then(function (index) {
          console.log('goto', index);
          return that.step(index);
        });
    };

    quiz.prototype.nextStep = function () {
      var current = this.steps[this.state];

      console.log(current, this);

      if (current && current.next)
        return this.goTo(current.next);
      if (current)
        return this.goTo(parseInt(this.state) + 1);
      this.goTo(0);
    };

    quiz.prototype.skip = function () {
      if (this.exit) {
        $q
          .when(this.exit())
          .finally(() => {
            this.loading = false;
            this.state = undefined;
          });
      } else {
        this.state = undefined;
      }
    };

    return quiz;
  }]);
