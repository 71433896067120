<table mat-table
       [dataSource]="tableData"
       class="mat-elevation-z4">
    <!-- Header row -->
    <tr mat-header-row
        *matHeaderRowDef="displayedColumns">
    </tr>

    <!-- Voucher rows -->
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;">
    </tr>

    <!-- Summary row -->
    <tr mat-footer-row
        *matFooterRowDef="displayedColumns">
    </tr>

    <!-- Voucher column -->
    <ng-container matColumnDef="itemInfo">
        <th mat-header-cell
            *matHeaderCellDef>
            Gutschein
        </th>

        <td mat-cell
            *matCellDef="let basketItem">
            {{ basketItem.voucherCheckoutInformation }}
        </td>

        <td mat-footer-cell
            *matFooterCellDef>
            Abruf Gesamt
        </td>
    </ng-container>

    <!-- Voucher cost column -->
    <ng-container matColumnDef="itemValue">
        <th mat-header-cell
            class="right-align"
            *matHeaderCellDef>
            Betrag
        </th>

        <td mat-cell
            *matCellDef="let basketItem">
            {{ basketItem.itemValue }} €
        </td>

        <td mat-footer-cell
            *matFooterCellDef>
        </td>
    </ng-container>

    <!-- Total cost column -->
    <ng-container matColumnDef="totalValue">
        <th mat-header-cell
            class="right-align"
            *matHeaderCellDef>
            Gesamt
        </th>

        <td mat-cell
            *matCellDef="let basketItem">
            {{ basketItem.totalValue }} €
        </td>

        <td mat-footer-cell
            *matFooterCellDef>
            {{ basketSum$ | async}} €
        </td>
    </ng-container>
</table>