<h2 mat-dialog-title>
    <span>
        Aufladungen {{this.monthStr}} {{this.year}}
    </span>

    <br>

    <h3>
        {{this.employeeName}} {{this.employeeId}}
    </h3>
</h2>

<div *ngIf="!hasAvailableForCheckoutComponents">
 Die Bausteine werden bereits verwendet.
</div>

<div *ngIf='initialComponentName'>
    <mat-dialog-content>
        <form [formGroup]='formGroup'>
            <p>
                Sie fügen den folgenden Bonus der Bestellung hinzu:
            </p>

            <vn-select formControlName='componentName'
                       label="Komponentenauswahl"
                       key="vn.directBonus.customerAdministration.bonusCreateDialog.selectBonusComponent"
                       [withPlaceholder]="false"
                       [initValue]="initialComponentName"
                       [options]='formOptions.componentName'>
            </vn-select>

            <p data-cy="vn.directBonus.customerAdministration.bonusCreateDialog.textCurrentAmount">
                <span id="bonusPrice">
                    Aktueller Komponentenmonatsbetrag: <strong>{{currentBonusValue$ | async}}</strong>
                    <small *ngIf="currentBonusIsRemoved$ | async">Der Bonus wurde erfolgreich gelöscht</small>
                </span>
                <span id="remove-btn" title="Click and hold me for 1sec" *ngIf="currentBonusIsRemovable$ | async">
                    <button mat-icon-button
                    mat-raised-button
                    data-cy="vn.directBonus.customerAdministration.bonusCreateDialog.buttonRemoveBonus"
                        (mousedown)="removingBonusTimer('start')"
                        (mouseup)="removingBonusTimer('stop')">
                        <mat-icon>bookmark_remove</mat-icon>
                    </button>
                    <span id="filler" [ngClass]="getRemovingBonusClass()"></span>
                </span>
            </p>

            <p>
                Aktueller Grund: <strong>{{currentBonusReason$ | async }}</strong>
            </p>

            <p>
                Aktuell genutzte Komponente: <strong>{{usedComponents.join(',') }}</strong>
            </p>

            <vn-input formControlName='bonusValue'
                      key="vn.directBonus.customerAdministration.bonusCreateDialog.inputBonusAmount"
                      label="Bonuswert"
                      type="number"
                      [placeholder]='componentStandardValue'>
                <mat-error *ngIf="bonusValue.hasError('required')">
                    Eingabe fehlt.
                </mat-error>

                <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('min')">
                    Betrag darf nicht negativ sein.
                </mat-error>

                <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('max')">
                    Betrag darf nicht größer {{this.componentMaxValue}} sein.
                </mat-error>

                <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('pattern')">
                    Bitte geben Sie Euro-Beträge im Format 12345,67 an.
                </mat-error>
            </vn-input>

            <vn-input formControlName='reason'
                      key="vn.directBonus.customerAdministration.bonusCreateDialog.inputBonusReason"
                      label="Grund"
                      placeholder='-'>
            </vn-input>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button
                mat-dialog-close>
            Abbrechen
        </button>

        <button class='edit-button'
                data-cy="vn.directBonus.customerAdministration.bonusCreateDialog.buttonSaveBonus"
                mat-stroked-button
                [disabled]="!formGroup.valid"
                (click)="saveBonus()">
            Speichern
        </button>
    </mat-dialog-actions>
</div>
