angular
  .module('VSPApp')
  .controller('CockpitProjekteEmployeeFormController', [
    '$scope',
    'Made',
    'vbmService',
    'ComponentConstants',
    'projectService',
    'paymentService',
    'dialogService',
    function (
      $scope,
      Made,
      vbmService,
      ComponentConstants,
      projectService,
      paymentService,
      dialogService
    ) {
      var calcDefValues = {
        bonus: {
          netto: 0,
          netto_optimal: 0,
          sv_ag: 0,
          sv_ag_optimal: 0
        },
        final: {
          tax: {
            brutto_gesamt: 0,
            net_netto: 0
          }
        }
      };

      $scope.calculation = {};
      $scope.loading = false;
      $scope.possibleBausteine = ComponentConstants;
      $scope.selectedModules = [];
      // $scope.payment = angular.copy( $scope.selectedPayment );
      $scope.payment = $scope.selectedPayment;

      // Data container for the form
      $scope.projectData = {
        // kinds: [],
        types: [],
        alv_ktg_compensations: [],
        supply_compensations: [],
        targets: []
      };

      // Interpolate custom form mechanizm from properties
      $scope.projectPropHelpers.supply_compensation_enabled = $scope.payment.supply_compensation_id > 1;
      $scope.projectPropHelpers.alv_ktg_compensation_enabled = $scope.payment.alv_ktg_compensation_id > 1;

      // Options for baustein overlay
      $scope.popupOptions = {
        items: {
          type: 'inline',
          src: '#bausteineSelect'
        },
        prependTo: '.cockpit-main',
        showCloseBtn: true,
      };

      // Request form options datas
      projectService
        .types()
        .then(function (types) {
          $scope.projectData.types = types;
        });

      projectService
        .alvKtgCompensations()
        .then(function (comps) {
          $scope.projectData.alv_ktg_compensations = comps;
        });

      projectService
        .supplyCompensations()
        .then(function (comps) {
          $scope.projectData.supply_compensations = comps;
        });

      projectService
        .targets()
        .then(function (targets) {
          $scope.projectData.targets = targets;
        });

      calculate();

      /**
       * Calculate neo components value
       * @param  {Object} components neo_components
       * @return {Number}            neo_components sum
       */
      function summarizeNeoValue(components) {
        var sum = 0;
        //console.debug( 'components', components )
        for (var i = 0; i < components.length; i++) {
          //if ( component.enabled ) {
          //console.debug( 'component', components[i] )
          sum += components[i].value;
          //}
        }

        return sum;
      }

      /**
       * Re/Calculate employee bonus/raise
       */
      function calculate() {
        // Get Brutto/Netto
        vbmService
          .getData($scope.payment.receiver_id)
          .then(function (last_calc_result) {
            // console.debug( 'last_calc_result', last_calc_result )
            vbmService
              .getResult(last_calc_result, false)
              .then(function (result) {
                // console.debug('result',result)
                $scope.calculation = angular.extend(calcDefValues, result);
                // Get Nettoauszahlung and AG Kosten
                var neo_value = summarizeNeoValue($scope.payment.params.neo_components);

                console.debug('$scope.payment', $scope.payment);
                // Send result and neo_value to /vbm/bonus/calculate
                var date = new Date($scope.payment.time);

                Made
                  .request('rpc://vbm/bonus/calculate', {
                    bonus_parameter: {
                      employee_id: $scope.payment.receiver_id,
                      neo_value: neo_value,
                      jahr: date.getFullYear(),
                      monat: date.getMonth() + 1,
                      monat_bisher: last_calc_result.parameters.bisher_monat > 11 ? 1 : last_calc_result.parameters.bisher_monat,
                      neo_mode: $scope.payment.params.neo_mode,
                      mode: 'APPROX',
                      neo_components: {},
                      sv_brutto: $scope.calculation.final.tax.brutto_gesamt,
                      st_brutto: $scope.calculation.final.tax.brutto_gesamt,
                      einmalzahlung: +$scope.payment.amount
                    }
                  })
                  .then(calculation => $scope.calculation.bonus = calculation);
              });
          });
      }

      $scope.previewCalculation = calculate;

      $scope.activatePayment = function () {

        paymentService
          .activate($scope.payment.id)
          .then(function (payment) {
            if (payment) {
              $scope.payment.activated = true;
            }
          });
      };

      $scope.enterBrutto = function () {
        dialogService
          .employeeBasics($scope.payment.receiver_id)
          .then(() => calculate());
      };
    }]);
