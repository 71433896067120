import InfoValidationController from './info_validation/InfoValidationController';
import { YearlyUserValidationController } from './user_validation/YearlyUserValidationController';
import { YearlyContoller } from './YearlyContoller';
import { YearlyErrorController } from './error/yearlyErrorController';

angular
  .module( 'VSPApp' )
  .controller ('YearlyController', YearlyContoller)
  .controller( 'InfoValidationController', InfoValidationController )
  .controller( 'YearlyUserValidationController', YearlyUserValidationController )
  .controller( 'YearlyErrorController', YearlyErrorController)
;
