angular
  .module('VSPApp')
  .controller('kinderAssistantController', [
    '$scope',
    'kinderAssistantService',
    function  (
      $scope,
      kinderAssistantService
    ) {

      $scope.employee = kinderAssistantService.get($scope.ngDialogData.employeeId);
      $scope.selected = 0;
      $scope.total = 0;

      $scope.addChild = () => {
        $scope.selected = kinderAssistantService.addChild($scope.ngDialogData.employeeId) - 1;
      };

      $scope.removeChild = index => {
        if ($scope.employee.kids.length <= 1) {
          return;
        }

        if ($scope.selected >= index) $scope.selected--;
        if ($scope.selected < 0) $scope.selected = 0;

        $scope.employee.kids.splice(index, 1);
      };

      $scope.$watch('employee', () => {

        $scope.total = 0;

        if ($scope.assistantForm) $scope.employee.kids[$scope.selected].valid = $scope.assistantForm.$valid;

        $scope.employee.kids.forEach(kid => {
          if (kid.valid) {
            kinderAssistantService.calculateKid(kid);
            $scope.total += kid.result;
          }
        });

      }, true);

    }]);
