var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      












































pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};































































pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"checkout_zusatzvereinbarung-wrapper\" overlay=\"loading\"\u003E\u003Cdiv class=\"bigbox centered\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EVielen Dank\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cp ng-hide=\"error\"\u003EVielen Dank für Ihre Angaben. Zum Abschluss des Vorgangs klicken Sie bitte unten auf \"Optimierung Beantragen\".\n\u003Cbr\u003E\n\u003Cbr\u003E\n\u003Cdiv ng-if=\"checkoutHasMissingDocuments\"\u003E\nSie haben angegeben, dass Sie Nachweise nachreichen möchten. Bitte beachten Sie, dass ValueNet die Kontrolle Ihrer Optimierung erst bei vollständiger Einreichung der Nachweise beginnen kann. Durch Hochladen von Nachweisen nach dem {{documentsSubmissionDate | date }} wird sich der gewünschte Beginn entsprechend verschieben.\n\u003C\u002Fdiv\u003E\n\u003Cbr\u003E\n\u003Cbr\u003E\n\u003Cdiv ng-if=\"!checkoutHasMissingDocuments\"\u003E\nValueNet prüft Ihre Angaben und Nachweise. Anschließend erhalten Sie via E-Mail und Portalbenachrichtigung Informationen zu den nächsten Schritten.\nSobald alle Angaben korrekt sind, stellt ValueNet Ihnen die notwendigen Unterlagen per digitalem Signaturvorgang bereit.\n\u003Cbr\u003E\n\u003Cbr\u003E\n\u003C\u002Fdiv\u003E\nGegebenenfalls sind noch Anpassungen erforderlich. Diese stimmt ValueNet im Prüfungsprozess mit Ihnen ab.\nValueNet leitet dann in Abstimmung mit Ihrem Arbeitgeber alle nachfolgenden Schritte in die Wege.\n\u003C\u002Fp\u003E\u003Cbr\u003E\u003Cdiv class=\"row\" ng-if=\"iAmSpecialAdvisor &amp;&amp; notMe\"\u003E\u003Cdiv class=\"small-9 columns component-col\"\u003E\u003Clabel for=\"switchChangeStatus\" tabindex=\"1\"\u003E\u003Cspan\u003EStatus auf Kontrolle setzen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"small-3 columns text-right\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchChangeStatus","key": "employee_status.to_change","labelHtmlClass": "switchbox-float-label"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv ng-if=\"(iAmSpecialAdvisor &amp;&amp; notMe) || iAmProxy \"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-12\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_mixins["input"].call({
attributes: {"key": "checkout_session.advisor_checkout_comment","title": "Berater Kommentar","required": pug.escape(true)}
});
},
attributes: {"name": "forms.advisorCheckoutCommentForm"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cp class=\"error\" ng-show=\"error\"\u003EDie Vorbereitung der Optimierung schlug fehl. Bitte kontaktieren Sie Ihren Berater.\u003C\u002Fp\u003E\u003Cp class=\"error\" ng-show=\"mppTypeError\"\u003EDas Abschlussverfahren für PC-Leasing wurde geändert. Bitte kehren Sie zurück in die Bausteinauswahl und entfernen Sie rechts den Baustein PC-Leasing. Gehen Sie sodann in den PC-Leasing Shop und starten den Abruf für PC-Leasing direkt dort. Vielen Dank.\u003C\u002Fp\u003E\u003Cp class=\"error\" ng-show=\"has_overlapping_components_with_bonus_result.overall\"\u003EDie folgenden Bausteine werden bereits verwendet: {{ has_overlapping_components_with_bonus_result.validations.components }}.\u003C\u002Fp\u003E\u003Cdiv ng-if=\"!error\"\u003E\u003Ca class=\"button\" ng-click=\"getEVGContractDocument()\" ng-disabled=\"loading_agreement\"\u003E{{ DocumentConstants[vereinbarung || 6].name }} herunterladen\u003C\u002Fa\u003E\u003C!--=' '--\u003E\u003C!--a.button(ng-click='openComparison()' ng-disabled=\"loading_comparison\") {{ DocumentConstants[99].name }} herunterladen--\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group schema-form-submit continue-margin-top\"\u003E\u003Cbutton class=\"btn btn-primary\" ng-click=\"proceed()\" ng-disabled=\"canProceed()\" data-cy=\"neo.checkout.basicComponents.summary.buttonFinishCheckout\"\u003EOptimierung Beantragen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"(iAmSpecialAdvisor || iAmAdvisor) &amp;&amp; notMe\"\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group schema-form-submit continue-margin-top\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5 right\"\u003E\u003Cdiv class=\"columns xsmall-12 small-4 right\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchFinalizeOptimisation","key": "finalizeOptimization.enabled","title": "Datensatz finalisieren"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-7 left\"\u003E\u003Cdiv class=\"columns xsmall-12\"\u003E\u003Cdiv\u003EMail an\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns xsmall-12 small-4\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "mail.berater","title": "Berater"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns xsmall-12 small-4\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "mail.mitarbeiter","title": "Mitarbeiter"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns xsmall-12 small-4\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "mail.kontrolle","title": "Kontrolle"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;