const $inject = [
  '$scope',
  'Made',
  'vbmData',
  'employeeService',
  'componentsService',
  'benefitAccountService',
  '$timeout',
  '$q'
];

export default class MeineakteUebersichtController {
  constructor(
    $scope,
    Made,
    vbmData,
    employeeService,
    componentsService,
    benefitAccountService,
    $timeout,
    $q
  ) {
    Object.assign(this, {
      $scope,
      Made,
      vbmData,
      employeeService,
      componentsService,
      benefitAccountService,
      $timeout,
      $q
    });

    this.$scope.employeeId = this.Made.user.valuenet_id;

    this.init();

    this.benefitAccountService.getBalance(this.$scope.employeeId).then((balance) => this.$scope.balance = balance.balance);
  }

  async init() {
    await this.vbmData.getResult(this.$scope.employeeId, new Date(), true);
    this.$scope.employee = this.vbmData.employees[this.$scope.employeeId];

    let employee = await this.employeeService.get(this.$scope.employeeId);
    this.$scope.employee_information = employee;
    this.vbmData.employees[this.$scope.employeeId].data.person = employee;


    await this.componentsService.getComponents();
    let components = await this.componentsService.applyConfiguration(this.$scope.employee_information.customer_id);
    this.$scope.availableComponentsCount = components.length;

  }
}

MeineakteUebersichtController.$inject = $inject;

angular
  .module('VSPApp')
  .controller('MeineakteUebersichtController', MeineakteUebersichtController);

