<mat-chip-list>
    <ng-container *ngFor="let chipLabel of chipLabels; let idx = index">
        <ng-container *ngIf="isIcon(chipLabel); else textLabel">
            <mat-chip [ngClass]="colorClasses[idx]">
                <mat-icon>
                    {{ label2icon(chipLabel) }}
                </mat-icon>
            </mat-chip>
        </ng-container>

        <ng-template #textLabel>
            <mat-chip [ngClass]="colorClasses[idx]">
                {{ chipLabel }}
            </mat-chip>
        </ng-template>
    </ng-container>
</mat-chip-list>