var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;


































pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-12 columns\"\u003E\u003Cdiv class=\"toggable-content\" toggable-content=\"toggableStatus\" ng-class=\"{&quot;toggable-content-open&quot;: !toggableStatus}\"\u003E\u003Cdiv class=\"toggable-content-toggler\" toggable-content-toggler\u003E\u003Cdiv ng-click=\"toggableContentCtrl.togglerClicked()\"\u003E\u003Cdiv class=\"toggable-state-feedback\"\u003E\u003C\u002Fdiv\u003E\u003Cspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"toggable-content-content\" toggable-content-content\u003E\u003Cdl class=\"tabs\" ng-click=\"elementClicked($event)\"\u003E\u003Cdd ui-sref-active=\"active\"\u003E\u003Ca ui-sref=\"inApp.administration.mpp.edit\"\u003EEdit\u003C\u002Fa\u003E\u003C\u002Fdd\u003E\u003Cdd ui-sref-active=\"active\"\u003E\u003Ca ui-sref=\"inApp.administration.mpp.calculate\"\u003ECalculation\u003C\u002Fa\u003E\u003C\u002Fdd\u003E\u003Cdd ui-sref-active=\"active\"\u003E\u003Ca ui-sref=\"inApp.administration.mpp.approvals\"\u003EApprovals\u003C\u002Fa\u003E\u003C\u002Fdd\u003E\u003Cdd ui-sref-active=\"active\"\u003E\u003Ca ui-sref=\"inApp.administration.mpp.import-leasingdata\"\u003EImport MLF Bestandsliste\u003C\u002Fa\u003E\u003C\u002Fdd\u003E\u003C\u002Fdl\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row tabs-content\"\u003E\u003Cdiv class=\"xsmall-12\"\u003E\u003Cdiv class=\"content\"\u003E\u003Cdiv id=\"meineakte_main\" ui-view=\"administrationMain\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;