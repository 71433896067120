var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (containerAttributes, fieldsetCounter, fileInputAttributes, globals, id) {
      










































































































































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};






































































pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_mixins["dropzone"] = pug_interp = function(model, single, id, tags, employeeId, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
containerAttributes = {}
fileInputAttributes = {}
if (single) {
containerAttributes = { 'made-dropzone-single' : 'true' }
fileInputAttributes = { 'made-file-single' : 'true' }
}
else {
fileInputAttributes = { multiple : 'multiple' }
}
if (!id) {
id = 'uploader'
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "dropzone-container","made-dropzone": pug.escape(model)},containerAttributes,attributes]), true)) + "\u003E\u003Cdiv class=\"dropzone-inner-container\"\u003E";
if (employeeId) {
pug_html = pug_html + "\u003Cfile-chooser" + (pug.attr("files", model, true, true)+pug.attr("filter-tags", tags, true, true)+pug.attr("employee-id", employeeId, true, true)) + "\u003E\u003C\u002Ffile-chooser\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + ("\u003Cdiv class=\"drag-and-drop-label\"\u003E\u003Cimg src=\"\u002Fassets\u002Fimg\u002Fsvg\u002Ficon-download.svg\"\u003E" + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp)));
if (single) {
pug_html = pug_html + "\u003Cspan\u003EDatei hierherziehen\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003EDateien hierherziehen\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E\u003Cdiv class=\"file-input\"\u003E\u003Cinput" + (pug.attrs(pug.merge([{"id": pug.escape(id),"type": "file","made-file-input": pug.escape(model),"style": "visibility:hidden","data-cy": pug.escape(cyData)},fileInputAttributes]), true)) + "\u003E";
if (single) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDatei auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
else {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDateien auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"files\"\u003E";
if (block) {
block && block();
}
else
if (single) {
pug_html = pug_html + "\u003Cspan" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)+pug.attr("ng-click", model + '=undefined', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("ng-if", model +'._id', true, true)+pug.attr("ng-href", '/gridfs?id={{'+ model +'._id}}', true, true)+" target=\"_blank\" rel=\"noreferrer\"") + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"files\""+pug.attr("ng-repeat", '(index, file) in ' + model, true, true)) + "\u003E\u003Cspan ng-if=\"!file._id\"\u003E{{ file.name }} ({{ file.size | filesize }})\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-click", model + '.splice(index, 1)', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca ng-if=\"file._id\" ng-href=\"\u002Fgridfs?id={{ file._id }}\" target=\"_blank\" rel=\"noreferrer\"\u003E{{ file.filename }} ({{ file.meta.created *1000 | date }}, {{ file.length | filesize }})\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"cockpit_allgemein-wrapper\"\u003E\u003Cdiv class=\"cockpit-panel\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EUpload Codes\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E\u003Cdiv class=\"row\" overlay=\"$ctrl.loading.reading_file\"\u003E\u003Cdiv class=\"columns small-12\"\u003E";
pug_mixins["dropzone"](
            '$ctrl.bonago_import_file',
            true,
            undefined,
            undefined,
            undefined,
            'benefit.bonago.administration.employeeCodes.fileUVCodes'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-6 right\"\u003E\u003Cbutton class=\"button button-default right\" ng-click=\"$ctrl.readBonagoImport()\" ng-disabled=\"!$ctrl.canRead()\" data-cy=\"benefit.bonago.administration.employeeCodes.buttonLoadCodes\"\u003EÜbermitteln\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003ESchemas Einstellungen\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cbutton class=\"button button-primary right\" ng-click=\"$ctrl.configureBonagoScheme()\" data-cy=\"benefit.bonago.administration.createSchemeDialog.buttonOpenDialog\"\u003ESchema Hinzufügen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cdiv class=\"list-box\" overlay=\"$ctrl.loading.schemes\"\u003E\u003Cdiv class=\"list-box__row\" ng-class=\"{'toggable-content-open': toggleContentShowable}\" ng-repeat=\"bonago_scheme in $ctrl.bonago_schemes track by $index\" toggable-content=\"toggableStatus\"\u003E\u003Ctoggable-content-toggler\u003E\u003Cdiv class=\"list-box__item\"\u003E\u003Cspan class=\"list-box__itemTitle\"\u003E\u003Cspan class=\"user-name\"\u003E{{ bonago_scheme.name}} - {{ bonago_scheme.display}}\u003C\u002Fspan\u003E\u003Cbutton class=\"button button-primary isOutline small right\" ng-click=\"$ctrl.configureBonagoScheme(bonago_scheme)\"\u003EKonfigurieren\u003C\u002Fbutton\u003E\u003Cbutton class=\"button button-primary isOutline small right\" ng-click=\"$ctrl.deleteBonagoScheme(bonago_scheme)\"\u003ELöschen\u003C\u002Fbutton\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftoggable-content-toggler\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "containerAttributes" in locals_for_with ?
        locals_for_with.containerAttributes :
        typeof containerAttributes !== 'undefined' ? containerAttributes : undefined, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "fileInputAttributes" in locals_for_with ?
        locals_for_with.fileInputAttributes :
        typeof fileInputAttributes !== 'undefined' ? fileInputAttributes : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined));
    ;;return pug_html;};
module.exports = template;