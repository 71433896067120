const BikeConstants = {
  onTop: false,
  foerderungAg: 0,
  traegerVersicherung: 'ag',
  traegerVersicherungPremiumPlus: 'keine',
  inspektion: 'keine',
  ausfallversicherungTod: true,
  ausfallversicherungKuendigung: true,
  rechtschutzHaftpflicht: false,
  bruttoNettoRate: 'brutto',
  agCode: '',
  bonitaetsrahmen: 0,
  minbestellwert: 546,
  maxwert: 15000,
  leasingfaktor: 0.034,
  kauffaktor: 0.18,
};

export default BikeConstants;
