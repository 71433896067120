const MenuConstants = {
  bonus_dashboard: {
    title: 'Bonus',
    default_title: 'Bonus',
    state: 'inApp.bonus.dashboard.projects_list',
    icon: 'euro',
    order: 1,
    cyData: 'vn.navigation.header.modulesMenu.linkBonusDashboard'
  },
  steuerfreie_extras: {
    title: 'Steuerfreie Extras',
    default_title: 'Steuerfreie Extras',
    state: 'inApp.neo.side.verguetung',
    path: 'verguetung',
    icon: 'euro',
    order: 2,
    cyData: 'vn.navigation.header.modulesMenu.linkNeoDashboard'
  },
  versicherungen: {
    title: 'Versicherungen',
    state: 'inApp.versicherung',
    path: 'versicherung',
    icon: 'versicherungen',
    order: 3
  },
  vorsorge: {
    title: 'Vorsorge',
    state: 'inApp.vorsorge.home',
    path: 'vorsorge/home',
    icon: 'vorsorge',
    order: 4
  },
  cashback: {
    title: 'Rechtliche Hilfe',
    state: 'inApp.cashback.home',
    path: 'cashback/home',
    icon: 'vorsorge',
    order: 5
  },
  shop: {
    title: 'Rabatte',
    state: 'inApp.shop.pre',
    path: 'shop/pre',
    icon: 'shopping',
    order: 6
  },
  benefits: {
    title: 'Vorteilskonto',
    state: 'inApp.directBonusBenefitsAccount',
    // state: 'inApp.benefit.bonago.side.home',
    path: 'benefit/home',
    icon: 'shopping',
    order: 7,
    cyData: 'vn.navigation.header.modulesMenu.linkBenefitDashboard'
  },
  cms: {
    title: 'Schwarzes Brett',
    default_title: 'Schwarzes Brett',
    state: 'inApp.cms.list',
    path: 'cms/list',
    icon: 'support',
    order: 8
  },
  feedback: {
    title: 'Feedback',
    state: 'inApp.feedback',
    path: 'feedback',
    icon: 'support',
    order: 9
  },
  incent: {
    title: 'Rabatt-Welt',
    icon: 'euro',
    order: 12,
    cyData: 'vn.navigation.header.modulesMenu.linkIncentDashboard'
  }
};

export default MenuConstants;
