import {APPROVAL_PROCESS_STATES_BY_NAME} from '../../services/administrationService';

const $inject = [
  '$scope',
  'administrationService',
  'NotificationService',
  'CarLeasingApiService'
];

export default class DeclineApprovalProcessController {
  constructor(
    $scope,
    administrationService,
    NotificationService,
    CarLeasingApiService
  ) {
    Object.assign(this, {
      $scope,
      administrationService,
      NotificationService,
      CarLeasingApiService
    });

    this.APPROVAL_PROCESS_STATES_BY_NAME = APPROVAL_PROCESS_STATES_BY_NAME;
    this.approval_process = this.$scope.ngDialogData.approval_process;
    this.employee_id = this.approval_process.employee_id;

    this.model = {
      log_comment: ''
    };
  }

  declineApproval() {
    let operation;

    if (!this.approval_process.car_leasing) {
      if (this.approval_process.state_id === this.APPROVAL_PROCESS_STATES_BY_NAME['waiting_external_approval']['id']) {
        operation = this.administrationService.approvalExternalDecline({
          approval_id: this.approval_process._id,
          log_comment: this.model.log_comment
        });
      } else if (this.approval_process.state_id === this.APPROVAL_PROCESS_STATES_BY_NAME['external_declined']['id']) {
        operation = this.administrationService.approvalInternalDecline({
          process_id: this.approval_process._id,
          log_comment: this.model.log_comment
        });
      }
    } else {
      operation = this.CarLeasingApiService.rejectApproval(
        this.approval_process.car_leasing.id,
        this.model.log_comment
      );
    }

    return operation.then(result => {
      this.NotificationService.message('Speichern erfolgreich.');
      this.$scope.confirm();
    })
      .catch(e => {
        console.error(e);
        this.NotificationService.error('Fehlgeschlagen.');
      })
  }

  close() {
    this.$scope.closeThisDialog();
  }

}

DeclineApprovalProcessController.$inject = $inject;
