const $inject = [
  '$scope',
  'BonusService',
  'fileService',
  '$timeout'
];
export default class TelecommunicationAdministrationController {
  constructor (
    $scope,
    BonusService,
    fileService,
    $timeout
  ) {
    Object.assign(this, {
      $scope,
      BonusService,
      fileService,
      $timeout
    });

    this.forms = {};
    this.bonus_report_model = {};
    this.loading = false;
  }

  async generateBonusReport(){
    this.loading = true;
    let telecommunication_bonuses_report = await this.BonusService.get_telecommunication_bonuses_report(this.bonus_report_model.bonus_start_date);
    let keys = ['customer_id', 'customer_name','valuenet_id', 'firstname', 'lastname', 'street', 'zip', 'city', 'gesperrt', 'component', 'begin_date', 'end_date'];
    if (telecommunication_bonuses_report && telecommunication_bonuses_report.length > 0) {
      this.fileService.saveFile(telecommunication_bonuses_report, keys);
    }
    this.$timeout(()=>{
      this.loading = false;
    });
  }

}
TelecommunicationAdministrationController.$inject = $inject;
