const TIME_REGEX = /^([01]?[1-9]|2[0-4]):([0-5]?[0-9])$/;
const $inject = [
  'TimetrackingService', 'Made', 'employeeService'
];
export default class TimetrackingController {

  constructor( TimetrackingService, Made, employeeService ) {

    this.TimetrackingService = TimetrackingService;

    this.employee = {};
    this.data     = [
      {}
    ];

    if ( Made.user && Made.user.valuenet_id ) {

      this.employee.empid = Made.user.valuenet_id;
      this.employee.email = Made.user.emails[ 0 ];

      employeeService
        .get( Made.user.valuenet_id )
        .then( employee => {

          this.employee.firstname = employee.firstname;
          this.employee.lastname  = employee.lastname;
        } );
    }
  }


  save() {

    this.working = true;
    this.error   = false;

    this.TimetrackingService
      .save( this.employee, this.data )
      .then( () => this.success = true, () => this.error = true )
      .finally( () => this.working = false );
  }

  getDuration( start, end ) {

    if (
      ('string' === typeof start) && ('string' === typeof end) &&
      TIME_REGEX.test( start ) && TIME_REGEX.test( end )
    ) {

      let [, h1, m1] = start.match( TIME_REGEX ).map( x => parseInt( x ) ),
        [, h2, m2]   = end.match( TIME_REGEX ).map( x => parseInt( x ) ),
        diff         = h2 * 60 + m2 - h1 * 60 - m1,
        h            = Math.floor( diff / 60 ),
        m            = Math.abs( diff % 60 );

      h = h < 10 && h > 0 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;

      return h + ':' + m;
    }

    return '';
  }

}
TimetrackingController.$inject = $inject;
