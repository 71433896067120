import { currency } from './shared';

export default class entfernung {

  static calculate( data ) {
    data.value_calc = currency( data.cent_pro_km * data.anzahl_km * (data.anzahl_tage || 0) );
  }


  static calculateDaysFromValue( data, modifier, value ) {

    let days = 0,
      div    = data.cent_pro_km * data.anzahl_km;

    if ( div ) {
      days = value / div;
    }

    return Math.max( Math.floor( days ), 0 );
  }
}
