const $inject = [
  '$q',
  '$location',
  '$scope',
  'StatesDEConstants',
  'SVCalculationConstants',
  'SteuerklassenConstants',
  'KVTypConstants',
  'BAVTypeConstants',
  'BAVParagraphConstants',
  'EinsparungsModellConstants',
  'ALVktgConstants',
  'VATypeConstants',
  'debounceFactory',
  'dialogService',
  'NotificationService',
  'authenticationService',
  'vbmData',
  'employeeService',
  'BonusService',
  'neoModifierService',
  'neoCalculateService',
  'BonusCalculationService'
];
export default class BonusBeraterController {

  constructor(
    $q,
    $location,
    $scope,
    StatesDEConstants,
    SVCalculationConstants,
    SteuerklassenConstants,
    KVTypConstants,
    BAVTypeConstants,
    BAVParagraphConstants,
    EinsparungsModellConstants,
    ALVktgConstants,
    VATypeConstants,
    debounceFactory,
    dialogService,
    NotificationService,
    authenticationService,
    vbmData,
    employeeService,
    BonusService,
    neoModifierService,
    neoCalculateService,
    BonusCalculationService
  ) {

    this.StatesDEConstants = StatesDEConstants;
    this.SVCalculationConstants = SVCalculationConstants;
    this.SteuerklassenConstants = SteuerklassenConstants;
    this.KVTypConstants = KVTypConstants;
    this.BAVTypeConstants = BAVTypeConstants;
    this.BAVParagraphConstants = BAVParagraphConstants;
    this.EinsparungsModellConstants = EinsparungsModellConstants;
    this.ALVktgConstants = ALVktgConstants;
    this.VATypeConstants = VATypeConstants;

    this.$q = $q;
    this.$scope = $scope;
    this.NotificationService = NotificationService;
    this.dialogService = dialogService;
    this.employeeService = employeeService;
    this.BonusService = BonusService;
    this.BonusCalculationService = BonusCalculationService;
    this.vbmData = vbmData;
    this.neoModifierService = neoModifierService;
    this.neoCalculateService = neoCalculateService;


    if (('string' === typeof $location.search().bonusId) && !isNaN($location.search().bonusId)) {
      this.bonusId = parseInt($location.search().bonusId);
    } else {
      if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
        vbmData.employeeId = parseInt($location.search().employeeId);
      }
      if (('string' === typeof $location.search().projectId) && $location.search().projectId) {
        this.projectId = $location.search().projectId;
      }
    }

    this.employee = {};
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');

    this.paymentSchedules = {};
    this.maxEssenschecksDays = 0;
    this.maxVMA_PSTDays = 0;
    this.maxVMA_STSVFREIDays = 0;
    this.maxwerbeflaecheMonths = 0;
    this.maxEntfernungDays = 0;
    this.bonusRest = 0;

    this.saving = true;
    this.compute = debounceFactory(() => this.calculate(), 300);
    this
      .getDataAndEmployee()
      .then(() => {

        this.saving = false;

        $scope.$watch('bonus.bonus_parameter', () => {

          this
            .schedule()
            .then(() => this.compute());

        }, true);

      }, (e) => {
        console.log('could not load employee and data', e);
      });

    this.tabs = {
      neo: {
        active: true
      }
    };
  }


  async getEmployee() {

    let data = this.vbmData.employees[this.vbmData.employeeId].data,
      employee = await this.employeeService.get(this.vbmData.employeeId, Date.now());

    data.person = employee;
    data.parameters.geburtsdatum = employee.birthdate;

    this.$scope.$applyAsync();
  }


  async getData() {

    let d = new Date();

    if (this.bonusId) {
      await this.loadBonus();
      this.vbmData.employeeId = parseInt(this.bonus_parameter.employee_id);
      await this.vbmData.getResult(this.vbmData.employeeId, new Date());
      this.employee = this.vbmData.employees[this.vbmData.employeeId];
      this.BonusCalculationService.applyStSvBrutto(this.bonus_parameter, this.employee.result);
    } else {

      await this.vbmData.getResult(this.vbmData.employeeId, new Date());
      this.employee = this.vbmData.employees[this.vbmData.employeeId];

      if (this.projectId) {
        this.project = await this.BonusService.loadProject(this.projectId);

        this.bonus_parameter = this.BonusCalculationService.generateParametersFromProject(
          this.vbmData.employeeId,
          this.project,
          this.employee.result
        );
      } else {
        this.bonus_parameter = this.BonusCalculationService.generateDefaultParameters(this.vbmData.employeeId);

        this.BonusCalculationService.applyStSvBrutto(this.bonus_parameter, this.employee.result);

        this.bonus_parameter.sv_brutto_bisher = this.bonus_parameter.sv_brutto * (d.getMonth() + 1);
        this.bonus_parameter.st_brutto_bisher = this.bonus_parameter.st_brutto * (d.getMonth() + 1);
      }
    }

    this.bonusRest = this.BonusCalculationService.calculateBonusRest(this.bonus_parameter);

    this.$scope.$applyAsync();
  }


  getDataAndEmployee() {
    return this.$q.when(
      this
        .getData()
        .then(() => this.getEmployee())
    );
  }


  editEmployee() {
    this.dialogService
      .employeeCreate(this.vbmData.employeeId)
      .then(() => this.getEmployee());
  }


  editEmployeeContactDetails() {
    this.dialogService
      .employeeContactDetails(this.employee.data.person)
      .then(() => this.getEmployee());
  }


  calculate() {
    return this.BonusService
      .calculate(this.bonus_parameter)
      .then(result => this.result = result);
  }


  async schedule() {

    await this.neoModifierService.calculateModifiedValues(
      this.bonus_parameter.neo_components,
      this.bonus_parameter.jahr,
      this.vbmData.employeeId
    );

    this.bonusRest = this.BonusCalculationService.calculateBonusRest(this.bonus_parameter);


    let modifierEssenscheck = this.neoModifierService.get(
      this.vbmData.employeeId,
      'essenscheck',
      this.bonus_parameter.jahr
    );

    this.maxEssenschecksDays = this.neoCalculateService.calculators.essenscheck.calculateDaysFromValue(
      this.bonus_parameter.neo_components.essenscheck,
      modifierEssenscheck,
      this.bonusRest + (this.bonus_parameter.neo_components.essenscheck.enabled ? this.bonus_parameter.neo_components.essenscheck.value || 0 : 0)
    );


    let modifierVMA_STSVFREI = this.neoModifierService.get(
      this.vbmData.employeeId,
      'VMA_STSVFREI',
      this.bonus_parameter.jahr
    );

    this.maxVMA_STSVFREIDays = this.neoCalculateService.calculators.VMA_STSVFREI.calculateDaysFromValue(
      this.bonus_parameter.neo_components.VMA_STSVFREI,
      modifierVMA_STSVFREI,
      this.bonusRest + (this.bonus_parameter.neo_components.VMA_STSVFREI.enabled ? this.bonus_parameter.neo_components.VMA_STSVFREI.value || 0 : 0)
    );

    let modifierVMA_PST = this.neoModifierService.get(
      this.vbmData.employeeId,
      'VMA_PST',
      this.bonus_parameter.jahr
    );

    this.maxVMA_PSTDays = this.neoCalculateService.calculators.VMA_PST.calculateDaysFromValue(
      this.bonus_parameter.neo_components.VMA_PST,
      modifierVMA_PST,
      this.bonusRest + (this.bonus_parameter.neo_components.VMA_PST.enabled ? this.bonus_parameter.neo_components.VMA_PST.value || 0 : 0)
    );

    let modifierwerbeflaeche = this.neoModifierService.get(
      this.vbmData.employeeId,
      'werbeflaeche',
      this.bonus_parameter.jahr
    );

    this.maxwerbeflaecheMonths = this.neoCalculateService.calculators.werbeflaeche.calculateMonthsFromValue(
      this.bonus_parameter.neo_components.werbeflaeche,
      modifierwerbeflaeche,
      this.bonusRest + (this.bonus_parameter.neo_components.werbeflaeche.enabled ? this.bonus_parameter.neo_components.werbeflaeche.value || 0 : 0)
    );


    let modifierinternet = this.neoModifierService.get(
      this.vbmData.employeeId,
      'internet',
      this.bonus_parameter.jahr
    );

    this.maxinternetMonths = this.neoCalculateService.calculators.internet.calculateMonthsFromValue(
      this.bonus_parameter.neo_components.internet,
      modifierinternet,
      this.bonusRest + (this.bonus_parameter.neo_components.internet.enabled ? this.bonus_parameter.neo_components.internet.value || 0 : 0)
    );

    let modifierentfernung = this.neoModifierService.get(
      this.vbmData.employeeId,
      'entfernung',
      this.bonus_parameter.jahr
    );

    this.maxEntfernungDays = this.neoCalculateService.calculators.entfernung.calculateDaysFromValue(
      this.bonus_parameter.neo_components.entfernung,
      modifierentfernung,
      this.bonusRest + (this.bonus_parameter.neo_components.entfernung.enabled ? this.bonus_parameter.neo_components.entfernung.value || 0 : 0)
    );


    if (this.bonus_parameter.neo_components.sachbezug.value) {

      let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
        sachbezug = this.bonus_parameter.neo_components.sachbezug,
        value = sachbezug.value;

      this.paymentSchedules.sachbezug = [];

      for (let i = 0; i < 12; i++) {
        let date = new Date(startDate.getTime()),
          amount = Math.min(sachbezug.bonus_monthly_value, value),
          payment = {
            date,
            amount
          };

        value -= amount;
        startDate.setMonth(startDate.getMonth() + 1);

        if (!amount) {
          break;
        }

        this.paymentSchedules.sachbezug.push(payment);
      }

    }

    if (this.bonus_parameter.neo_components.internet.value) {

      let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
        internet = this.bonus_parameter.neo_components.internet,
        value = internet.value;

      this.paymentSchedules.internet = [];

      for (let i = 0; i < 12; i++) {
        let date = new Date(startDate.getTime()),
          amount = Math.min(internet.bonus_monthly_value, value),
          payment = {
            date,
            amount
          };

        value -= amount;
        startDate.setMonth(startDate.getMonth() + 1);

        if (!amount) {
          break;
        }

        this.paymentSchedules.internet.push(payment);
      }

    }

    if (this.bonus_parameter.neo_components.essenscheck.anzahl_gutscheine) {

      let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
        essenscheck = this.bonus_parameter.neo_components.essenscheck,
        anzahl_gutscheine = essenscheck.anzahl_gutscheine;

      this.paymentSchedules.essenscheck = [];

      for (let i = 0; i < 12; i++) {
        let date = new Date(startDate.getTime()),
          coupons = Math.min(essenscheck.bonus_anzahl_gutscheine, anzahl_gutscheine),
          payment = {
            date,
            coupons,
            amount: this.neoCalculateService.calculators.essenscheck.valueFromAnzahlGutscheine(
              coupons, essenscheck.is_sachbezug, modifierEssenscheck
            )
          };

        anzahl_gutscheine -= coupons;
        startDate.setMonth(startDate.getMonth() + 1);

        if (!coupons) {
          break;
        }

        this.paymentSchedules.essenscheck.push(payment);
      }

    }

    if (this.bonus_parameter.neo_components.VMA_STSVFREI.enabled) {

      if (1 == this.bonus_parameter.neo_components.VMA_STSVFREI.bonus_type) {

        let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
          VMA_STSVFREI = this.bonus_parameter.neo_components.VMA_STSVFREI,
          anzahl_tage = VMA_STSVFREI.anzahl_tage;

        this.paymentSchedules.VMA_STSVFREI = [];

        for (let i = 0; i < 12; i++) {
          let date = new Date(startDate.getTime()),
            days = Math.min(VMA_STSVFREI.bonus_anzahl_tage, anzahl_tage),
            payment = {
              date,
              days,
              amount: this.neoCalculateService.calculators.VMA_STSVFREI.valueFromAnzahlTage(
                days, VMA_STSVFREI.anzahl_std_abwesend, modifierVMA_STSVFREI
              )
            };

          anzahl_tage -= days;
          startDate.setMonth(startDate.getMonth() + 1);

          if (!days) {
            break;
          }

          this.paymentSchedules.VMA_STSVFREI.push(payment);
        }

      } else if (2 == this.bonus_parameter.neo_components.VMA_STSVFREI.bonus_type) {

        let VMA_STSVFREI = this.bonus_parameter.neo_components.VMA_STSVFREI;

        VMA_STSVFREI.anzahl_tage = Math.min(VMA_STSVFREI.bonus_anzahl_tage * this.bonus_parameter.monat, this.maxVMA_STSVFREIDays);


        this.paymentSchedules.VMA_STSVFREI = [];

      }
    }


    if (this.bonus_parameter.neo_components.VMA_PST.enabled) {

      if (1 == this.bonus_parameter.neo_components.VMA_PST.bonus_type) {

        let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
          VMA_PST = this.bonus_parameter.neo_components.VMA_PST,
          anzahl_tage = VMA_PST.anzahl_tage;

        this.paymentSchedules.VMA_PST = [];

        for (let i = 0; i < 12; i++) {
          let date = new Date(startDate.getTime()),
            days = Math.min(VMA_PST.bonus_anzahl_tage, anzahl_tage),
            payment = {
              date,
              days,
              amount: this.neoCalculateService.calculators.VMA_PST.valueFromAnzahlTage(
                days, VMA_PST.anzahl_std_abwesend, modifierVMA_PST
              )
            };

          anzahl_tage -= days;
          startDate.setMonth(startDate.getMonth() + 1);

          if (!days) {
            break;
          }

          this.paymentSchedules.VMA_PST.push(payment);
        }

      } else if (2 == this.bonus_parameter.neo_components.VMA_PST.bonus_type) {

        let VMA_PST = this.bonus_parameter.neo_components.VMA_PST;

        VMA_PST.anzahl_tage = Math.min(VMA_PST.bonus_anzahl_tage * this.bonus_parameter.monat, this.maxVMA_PSTDays);


        this.paymentSchedules.VMA_PST = [];

      }
    }


    if (this.bonus_parameter.neo_components.werbeflaeche.enabled) {

      if (1 == this.bonus_parameter.neo_components.werbeflaeche.bonus_type) {

        let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
          werbeflaeche = this.bonus_parameter.neo_components.werbeflaeche,
          value = werbeflaeche.value;

        this.paymentSchedules.werbeflaeche = [];

        for (let i = 0; i < 12; i++) {
          let date = new Date(startDate.getTime()),
            amount = Math.min(werbeflaeche.bonus_monthly_value, value),
            payment = {
              date,
              amount
            };

          value -= amount;
          startDate.setMonth(startDate.getMonth() + 1);

          if (!amount) {
            break;
          }

          this.paymentSchedules.werbeflaeche.push(payment);
        }

      } else if (2 == this.bonus_parameter.neo_components.werbeflaeche.bonus_type) {


        let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
          werbeflaeche = this.bonus_parameter.neo_components.werbeflaeche;

        werbeflaeche.value = this.maxwerbeflaecheMonths * werbeflaeche.bonus_monthly_value;

        this.paymentSchedules.werbeflaeche = [];
        this.paymentSchedules.werbeflaeche.push({
          date: startDate,
          amount: werbeflaeche.value
        });

      }
    } else {
      this.bonus_parameter.neo_components.werbeflaeche.value = 0;
      this.paymentSchedules.werbeflaeche = [];
    }


    if (this.bonus_parameter.neo_components.erholungsbeihilfe.enabled) {
      let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
        erholungsbeihilfe = this.bonus_parameter.neo_components.erholungsbeihilfe;

      erholungsbeihilfe.value = 12 * erholungsbeihilfe.bonus_monthly_value;

      this.paymentSchedules.erholungsbeihilfe = [];
      this.paymentSchedules.erholungsbeihilfe.push({
        date: startDate,
        amount: erholungsbeihilfe.value
      });
    } else {
      this.bonus_parameter.neo_components.erholungsbeihilfe.value = 12 *
        this.bonus_parameter.neo_components.erholungsbeihilfe.bonus_monthly_value;
      this.paymentSchedules.erholungsbeihilfe = [];
    }


    if (this.bonus_parameter.neo_components.internet.enabled) {

      if (1 == this.bonus_parameter.neo_components.internet.bonus_type) {

        let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
          internet = this.bonus_parameter.neo_components.internet,
          value = internet.value;

        this.paymentSchedules.internet = [];

        for (let i = 0; i < 12; i++) {
          let date = new Date(startDate.getTime()),
            amount = Math.min(internet.bonus_monthly_value, value),
            payment = {
              date,
              amount
            };

          value -= amount;
          startDate.setMonth(startDate.getMonth() + 1);

          if (!amount) {
            break;
          }

          this.paymentSchedules.internet.push(payment);
        }

      } else if (2 == this.bonus_parameter.neo_components.internet.bonus_type) {


        let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
          internet = this.bonus_parameter.neo_components.internet;

        internet.value = this.maxinternetMonths * internet.bonus_monthly_value;

        this.paymentSchedules.internet = [];
        this.paymentSchedules.internet.push({
          date: startDate,
          amount: internet.value
        });

      }
    } else {
      this.bonus_parameter.neo_components.internet.value = 0;
      this.paymentSchedules.internet = [];
    }


    if (this.bonus_parameter.neo_components.kinder.enabled) {
      let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
        kinder = this.bonus_parameter.neo_components.kinder;

      kinder.value = this.bonus_parameter.monat * kinder.bonus_monthly_value;

      this.paymentSchedules.kinder = [];
      this.paymentSchedules.kinder.push({
        date: startDate,
        amount: kinder.value
      });
    } else {
      this.bonus_parameter.neo_components.kinder.value = 0;
      this.paymentSchedules.kinder = [];
    }

    if (this.bonus_parameter.neo_components.entfernung.enabled) {
      let startDate = new Date(this.bonus_parameter.jahr, this.bonus_parameter.monat - 1),
        entfernung = this.bonus_parameter.neo_components.entfernung;


      this.paymentSchedules.entfernung = [];
      this.paymentSchedules.entfernung.push({
        date: startDate,
        amount: entfernung.value
      });
    } else {
      this.bonus_parameter.neo_components.entfernung.anzahl_tage = 0;
      this.bonus_parameter.neo_components.entfernung.value = 0;
      this.paymentSchedules.entfernung = [];
    }


    if (this.bonus_parameter.neo_components.besonderer_anlass.enabled) {
      let besonderer_anlass = this.bonus_parameter.neo_components.besonderer_anlass;

      this.paymentSchedules.besonderer_anlass = [];


      for (let i of [
        1,
        2,
        3
      ]) {
        if (besonderer_anlass['anlass' + i]) {
          let date = besonderer_anlass['datum' + i] || '0.13',
            month = parseInt(date.split('.')[1]) || 13,
            scheduled = new Date(this.bonus_parameter.jahr, month - 1);

          if (month < this.bonus_parameter.monat) {
            scheduled.setFullYear(scheduled.getFullYear() + 1);
          }

          this.paymentSchedules.besonderer_anlass.push({
            date: scheduled,
            amount: besonderer_anlass['betrag' + i]
          });
        }
      }

    } else {
      this.bonus_parameter.neo_components.besonderer_anlass.value = 0;
      this.paymentSchedules.besonderer_anlass = [];
    }


  }


  changedBonus() {
  }


  save() {

    this.saving = true;

    return this.BonusService
      .save(this.bonus_parameter, this.paymentSchedules)
      .then(() => this.NotificationService.message('Gespeichert.'),
        () => this.NotificationService.error('Das Speichern schlug fehl.'))
      .finally(() => this.saving = false);
  }


  loadBonus() {

    return this.BonusService
      .load(this.bonusId)
      .then(bonus_parameter => this.bonus_parameter = bonus_parameter);
  }


  changeBesondererAnlassAnlass(anlass) {

    let besonderer_anlass = this.bonus_parameter.neo_components.besonderer_anlass,
      birthdate = this.employee.data.person.birthdate,
      entrydate = this.employee.data.person.entrydate;

    if (besonderer_anlass['betrag' + anlass] == null || besonderer_anlass['betrag' + anlass] <= 0) {
      besonderer_anlass['betrag' + anlass] = 60;
    }

    switch (besonderer_anlass['anlass' + anlass]) {
      case 'Geburtstag':
        besonderer_anlass['datum' + anlass] = birthdate.replace(/\d{2,4}$/, '');
        break;
      case 'Firmenjubiläum':
        besonderer_anlass['datum' + anlass] = entrydate.replace(/\d{2,4}$/, '');
        break;
      case '':
        besonderer_anlass['datum' + anlass] = '';
        besonderer_anlass['betrag' + anlass] = 0;
        break;
    }


  }

}
BonusBeraterController.$inject = $inject;
