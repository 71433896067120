var pug = require("!../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};



















































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel__title\"\u003EAuftragsdaten\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\" overlay=\"$ctrl.loading.main\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + " \u003Cdiv class=\"row\"\u003E\u003Cdiv\u003EBitte geben Sie folgende Daten an.\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-6 filter-input\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "add_old_baskets_order_id","key": "$ctrl.models.order_id","title": "Auftragsnummer","required": pug.escape(true),"validationMessages": {"required":"Pflichtfeld","unique":"{{ $ctrl.orderIdExistsMessage }}"},"attributes": {"ng-change":"$ctrl.handleOrderIdChange()"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cbutton class=\"btn btn-primary left\" ng-click=\"$ctrl.close()\"\u003EZurück\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-primary right\" ng-disabled=\"$ctrl.forms.add_old_baskets_order_id_form.$invalid\" ng-click=\"$ctrl.addOrderIdToOldBaskets()\"\u003ESpeichern\u003C\u002Fbutton\u003E\u003Cdiv class=\"clearfix\"\u003E \u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "row","name": "$ctrl.forms.add_old_baskets_order_id_form","id": "addOldBasketsOrderIdForm"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;