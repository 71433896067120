import BenefitConstants from './BenefitConstants';
import CashbackConstants from './CashbackConstants';
import ComponentConstants from './ComponentConstants';
import ComponentIdConstants from './ComponentIdConstants';
import ComponentWizardConstants from './ComponentWizardConstants';
import LandingConstants from './LandingConstants';
import MenuConstants from './MenuConstants';
import ShopConstants from './ShopConstants';
import ShopCategoryConstants from './ShopCategoryConstants';
import vbmScaffoldConstant from './vbmScaffoldConstant';
import VorsorgeConstants from './VorsorgeConstants';
import CockpitPagesConstants from './CockpitPagesConstants';
import SliderOptionsConstants from './SliderOptionsConstants';
import UsageConstants from './UsageConstants';
import FeedbackConstants from './FeedbackConstants';
import VersicherungConstants from './VersicherungConstants';
import MppConstants from './MppConstants';
import DocumentConstants from './DocumentConstants';
import BikeConstants from './BikeConstants';
import { API_END_POINTS } from './api_constants';

import SteuerklassenConstants from './calculation/SteuerklassenConstants';
import StatesDEConstants from './calculation/StatesDEConstants';
import SVCalculationConstants from './calculation/SVCalculationConstants';
import KVTypConstants from './calculation/KVTypConstants';
import BAVTypeConstants from './calculation/BAVTypeConstants';
import BAVParagraphConstants from './calculation/BAVParagraphConstants';
import EinsparungsModellConstants from './calculation/EinsparungsModellConstants';
import ALVktgConstants from './calculation/ALVktgConstants';
import VATypeConstants from './calculation/VATypeConstants';
import CalculationResultNameConstants from './calculation/CalculationResultNameConstants';
import CalculationResultConstants from './calculation/CalculationResultConstants';
import PopupVideoKeys from './PopupVideoKeys';
import UsercentricsConstants from './UsercentricsConstants';

angular
  .module( 'VSPApp' )
  .constant( 'BenefitConstants', BenefitConstants )
  .constant( 'CashbackConstants', CashbackConstants )
  .constant( 'ComponentConstants', ComponentConstants )
  .constant( 'ComponentIdConstants', ComponentIdConstants )
  .constant( 'ComponentWizardConstants', ComponentWizardConstants )
  .constant( 'ShopConstants', ShopConstants )
  .constant( 'ShopCategoryConstants', ShopCategoryConstants )
  .constant( 'LandingConstants', LandingConstants )
  .constant( 'MenuConstants', MenuConstants )
  .constant( 'vbmScaffoldConstant', vbmScaffoldConstant )
  .constant( 'VorsorgeConstants', VorsorgeConstants )
  .constant( 'CockpitPagesConstants', CockpitPagesConstants )
  .constant( 'SliderOptionsConstants', SliderOptionsConstants )
  .constant( 'UsageConstants', UsageConstants )
  .constant( 'FeedbackConstants', FeedbackConstants )
  .constant( 'VersicherungConstants', VersicherungConstants )
  .constant( 'MppConstants', MppConstants )
  .constant( 'DocumentConstants', DocumentConstants )
  .constant( 'SteuerklassenConstants', SteuerklassenConstants )
  .constant( 'StatesDEConstants', StatesDEConstants )
  .constant( 'SVCalculationConstants', SVCalculationConstants )
  .constant( 'KVTypConstants', KVTypConstants )
  .constant( 'BAVTypeConstants', BAVTypeConstants )
  .constant( 'BAVParagraphConstants', BAVParagraphConstants )
  .constant( 'EinsparungsModellConstants', EinsparungsModellConstants )
  .constant( 'ALVktgConstants', ALVktgConstants )
  .constant( 'VATypeConstants', VATypeConstants )
  .constant( 'CalculationResultNameConstants', CalculationResultNameConstants )
  .constant( 'CalculationResultConstants', CalculationResultConstants )
  .constant( 'BikeConstants', BikeConstants )
  .constant( 'PopupVideoKeys', PopupVideoKeys )
  .constant( 'UsercentricsConstants', UsercentricsConstants )
  .constant( 'API_END_POINTS', API_END_POINTS );
