const $inject = [
  '$state',
  'CockpitPagesConstants',
  'authenticationService'
];
export default class CockpitController {
  constructor(
    $state,
    CockpitPagesConstants,
    authenticationService
  ) {
    this.cockpitPages = CockpitPagesConstants;
    this.$state = $state;
    this.authenticationService = authenticationService;

    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmDatevAdmin = authenticationService.iAm('datev_admin');
  }

  hasAccess(cockpitPage) {
    if (cockpitPage.acl && cockpitPage.acl.role) {
      return this.authenticationService.iAm(cockpitPage.acl.role);
    }

    return true;
  }

  goToPage(cockpitPage) {
    this.$state.go(`inApp.cockpit.${cockpitPage.id}`, this.$state.params);
  }
}
CockpitController.$inject = $inject;
