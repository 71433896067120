import {DEFAULT_BONUS_PROJECT_STATE} from "./bonus_project_states";
import {DEFAULT_BONUS_TYPE} from "./bonus_type";
import {DEFAULT_BONUS_EXPIRATION_ACTION} from "./bonus_expiration_action";
import {DEFAULT_BONUS_PROJECT_BUDGET} from "./default_bonus_project_budget";

/**
 * Configuration of the BonusProject.
 * */
export const DEFAULT_BONUS_PROJECT = {
  name: '',
  description: '',
  project_type: undefined,
  project_state: DEFAULT_BONUS_PROJECT_STATE['id'],
  customer_id: undefined,
  quiz: {
    is_enabled: false,
  },
  documentation: {
    is_enabled: false
  },
  project_approved: {
    is_enabled: false
  },
  approvals_config: {},
  dates: {
    bonus_project_start_date: undefined,
    bonus_project_end_date: undefined,
    user_decision_date: undefined,
    bonus_publish_date: undefined
  },
  bonus_config: {
    bonus_type: DEFAULT_BONUS_TYPE['id'],
    expiration_action: DEFAULT_BONUS_EXPIRATION_ACTION['id']
  },
  transfer: {},
  config_fields: {},
  budget_config: angular.copy(DEFAULT_BONUS_PROJECT_BUDGET),
  for_employees: {},
  settings: {
    is_visible_to_user_after_end: true
  }
};
