const menuIcon = ["MenuConstants", function (MenuConstants) {
  return function (input, customMenuConstants) {

    let id = ('object' === typeof input) ? input.id : input;

    // https://vsp.atlassian.net/browse/VN-4013
    // https://vsp.atlassian.net/browse/VN-4014 -> HACK -> REFACTOR
    if (!!customMenuConstants && customMenuConstants[id]) {
      return customMenuConstants[id].icon;
    } else if (MenuConstants[id]) {
      return MenuConstants[id].icon;
    } else if ('object' === typeof input) {
      return input.data.icon;
    } else {
      console.log('menu filter - unknown module: ', input);
      return 'unknown';
    }
  };
}]

const menuTitle = ["MenuConstants", function (MenuConstants) {
  let filter = function (input, customMenuConstants) {
    let id = ('object' === typeof input) ? input.id : input;
    // -> HACK -> REFACTOR
    if (!!customMenuConstants && customMenuConstants[id]) {
      return customMenuConstants[id].title;
    } else if (MenuConstants[id]) {
      return MenuConstants[id].title;
    } else if ('object' === typeof input) {
      return input.data.title;
    } else {
      return 'unknown';
    }
  };
  filter.$stateful = true;
  return filter;
}]

const menuCYData = ["MenuConstants", function (MenuConstants) {
  let filter = function (input, customMenuConstants) {
    let id = ('object' === typeof input) ? input.id : input;
    // -> HACK -> REFACTOR
    if (!!customMenuConstants && customMenuConstants[id]) {
      return customMenuConstants[id].cyData;
    } else if (MenuConstants[id]) {
      return MenuConstants[id].cyData;
    } else if ('object' === typeof input) {
      return input.data.cyData;
    } else {
      return 'unknown';
    }
  };
  filter.$stateful = true;
  return filter;
}]

export { menuIcon, menuTitle, menuCYData }
