const COMPONENT_ROUTES = {
  sachbezug: 'sachbezug',
  essenscheck: 'essenscheck',
  internet: false,
  kinder: 'kinder',
  entfernung: 'entfernung',
  dienstwagen_miete: false,
  dienstwagen_auslage: false,
  werbeflaeche: false,
  handy: false,
  personal_rabatt: false,
  erholungsbeihilfe: false,
  VMA_PST: false,
  // berufskleidung: false,
  bav: false,
  festnetz: false,
  EBike: false,
  Bikeleasing: false,
  pc: 'pc',
  VMA_STSVFREI: false,
  car_leasing: 'car_leasing',
  dticket: 'dticket'
};

const NEW_ROUTES = {
  pc: 'pc'
};

import neo_html from "../../../modules/neo/neo.pug";
import header_main_html from "../../../helpers/header.main.pug";
import side_html from "../../../modules/side/side.pug";
import berater_html from "../../../modules/neo/berater/berater.pug";
import neo_side_html from "../../../modules/neo/side/side.pug";
import verguetung_html from "../../../modules/neo/verguetung/verguetung.pug";
import standalone_component_checkout_html
  from "../../../modules/neo/standalone_component_checkout/standalone_component_checkout.pug";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    let authorized = ['authenticationService', function (authenticationService) {
      authenticationService.iMustBe([
        'gadvisor',
        'advisor'
      ]);
    }];

    $stateProvider
      .state('inApp.neo', {
        views: {
          main: {
            template: neo_html,
            controller: 'NeoController'
          },
          header: {
            template: header_main_html
          }
        }
      })
      .state('inApp.neo.side', {
        views: {
          neoMain: {
            template: side_html
          }
        }
      })
      .state('inApp.neo.side.berater', {
        url: '/berater?employeeId',
        params: {
          calculation_id: null
        },
        reloadOnSearch: false,
        views: {
          sideMain: {
            template: berater_html,
            controller: 'BeraterController',
            controllerAs: 'berater'
          },
          side: {
            template: neo_side_html
          }
        },
        resolve: {
          authorized: authorized
        }
      })
      //.state( 'inApp.neo.side.bonusBerater', {
      //url:            '/bonus-berater?employeeId&projectId&amount&bonusId',
      //reloadOnSearch: false,
      //views:          {
      //  sideMain: {
      //    templateUrl:  '/modules/neo/bonus-berater/bonus-berater.html',
      //    controller:   'BonusBeraterController',
      //    controllerAs: 'bonus'
      //  },
      //  side: {
      //    templateUrl: '/modules/neo/bonus-berater/sidebar/bonus-sidebar.html'
      //  }
      //},
      //resolve: {
      //  authorized: authorized
      //}
      //} )
      .state('inApp.neo.side.verguetung', {
        url: '/verguetung:bonus',
        params: {
          bonus: null,
          parent_state: 'neo',
          calculation_id: null
        },
        views: {
          sideMain: {
            template: verguetung_html,
            controller: 'VerguetungController',
            controllerAs: '$ctrl'
          },
          side: {
            template: neo_side_html
          }
        }
      })
      .state('inApp.neo.side.checkout_component', {
        url: '/checkout',
        params: {
          checkout_basket: undefined
        },
        views: {
          sideMain: {
            template: standalone_component_checkout_html,
            controller: 'StandaloneComponentCheckoutController',
            controllerAs: '$ctrl'
          }
        }
      })
    ;

    for (let component in NEW_ROUTES) {
      let controller_name = 'NeoComponentController' + (NEW_ROUTES[component] || '');

      $stateProvider.state(`inApp.neo.side.moduleNew${component}`, {
        url: `/component/${component}`,
        params: {
          moduleId: component,
          neo_component: null,
          to_add_to_checkout_basket: undefined
        },
        views: {
          sideMain: {
            template: require(`../../../modules/neo/standalone_components/views/${component}.pug`),
            controller: controller_name,
            controllerAs: '$ctrl'
          },
          side: {
            template: neo_side_html
          }
        }
      });
    }

    for (let component in COMPONENT_ROUTES) {
      $stateProvider.state(`inApp.neo.side.module${component}`, {
        url: `/module/${component}`,
        params: {
          moduleId: component,
          path: '/verguetung'
        },
        views: {
          sideMain: {
            template: require(`../../../modules/neo/components/${component}/${component}.pug`),
            controller: 'ComponentController' + (COMPONENT_ROUTES[component] ? COMPONENT_ROUTES[component] : ''),
            controllerAs: 'component'
          },
          side: {
            template: neo_side_html
          }
        }
      });
    }

  }]);
