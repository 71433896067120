<ng-container *ngIf="retailer$ | async as retailer">
    <mat-card class="benefit-card">
        <mat-card-content>
            <img mat-card-image
                 [src]="retailer.assets.Logo2URL?.url">

            <div [innerHTML]="retailer.information?.RetailerDescription">
            </div>

            <div [innerHTML]="retailer.information?.RedemptionInstructions">
            </div>
        </mat-card-content>

        <div class='maps-container'
             *ngIf="showMaps$">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let address of addresses$ | async"
                                     [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div [outerHTML]="address.street">
                            </div>
                        </mat-panel-title>

                        <mat-panel-description>
                            <div [outerHTML]="address.city">
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <a (click)="goToOverviewMap(address)">
                        <img [src]="generateStaticMapUrl(address)">
                    </a>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <mat-card-actions>
            <div>
                <form [formGroup]="formGroup">
                    <vn-select formControlName='voucherAmount'
                               placeholder="Gutscheinwert wählen"
                               [options]='(formOptions.voucherAmount | async)!'>
                        <mat-error *ngIf="showErrors && voucherAmount.hasError('required')">
                            Bitte Gutscheinwert angeben.
                            ` </mat-error>
                    </vn-select>

                    <vn-select formControlName='voucherQuantity'
                               width="200px"
                               placeholder="Anzahl wählen"
                               [options]='formOptions?.voucherQuantity'>
                        <mat-error *ngIf="showErrors && voucherQuantity.hasError('required')">
                            Bitte Anzahl angeben.
                        </mat-error>
                    </vn-select>
                </form>
            </div>

            <div>
                <button (click)="addToBasket(retailer)"
                        mat-raised-button>
                    Zum Warenkorb hinzufügen
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</ng-container>