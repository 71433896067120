import { Component, OnInit } from '@angular/core';
import { BenefitsBonagoBasketService } from 'src/app/direct-bonus/services/benefits-bonago-basket.service';
import { TocDialogData, TocCheckboxDialogComponent } from 'src/app/shared/components/form-mixins/toc-checkbox-dialog/toc-checkbox-dialog.component';

@Component({
  selector: 'vn-benefits-home',
  templateUrl: './benefits-home.component.html',
  styleUrls: ['./benefits-home.component.scss']
})
export class BenefitsHomeComponent implements OnInit {

  public userBalance$ = this.basketService.userBalance$$;

  constructor(
    private basketService: BenefitsBonagoBasketService,
  ) { }

  ngOnInit(): void {

  }
}
