<div>
    <vn-matblur-loading></vn-matblur-loading>
    <form [formGroup]='filtersGroup' (keydown.enter)="$event.preventDefault()">
        <vn-input formControlName='filterEmployeeIdPersonalNumber'
            label="ValueNetID/Personalnummer"
            type="number"
            placeholder='Nummer'
            (keydown.enter)="loadEmployees()">
        </vn-input>
        
        <vn-input formControlName='filterEmployeeName'
            label="Vorname Name"
            placeholder='Name'
            (keydown.enter)="loadEmployees()">
        </vn-input>

        <button mat-icon-button 
            mat-raised-button
            data-cy="vn.directBonus.backofficeAdministration.directBonusTableHeader.buttonApplyFilters"
            [disabled]="isListFiltered"
            (click)="loadEmployees()">
            <mat-icon>filter_list</mat-icon>
        </button>

        <button mat-icon-button 
            mat-raised-button
            data-cy="vn.directBonus.backofficeAdministration.directBonusTableHeader.buttonClearFilters"
            [disabled]="!isListFiltered"
            (click)="clearFilters()">
            <mat-icon>filter_list_off</mat-icon>
        </button>
        
    </form>

    <table mat-table [dataSource]="customerOrderBonusData$" class="mat-elevation-z8" matSort>

        <!-- header row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns" sticky="true">
        </tr>

        <!-- content rows -->
        <tr mat-row *matRowDef="let data; columns: displayedColumns;" class="customer-order-table-row">
        </tr>

        <!-- employeeId column -->
        <ng-container matColumnDef="employeeId">
            <th mat-header-cell *matHeaderCellDef>
                ValueNet ID
            </th>

            <td mat-cell *matCellDef="let data">
                {{ data.bonus.employeeId }}
            </td>
        </ng-container>

        <!-- employeeName column -->
        <ng-container matColumnDef="employeeName">
            <th mat-header-cell *matHeaderCellDef>
                Name
            </th>

            <td mat-cell *matCellDef="let data">
                {{ data.employeeName }}
            </td>
        </ng-container>

        <!-- employeeName column -->
        <ng-container matColumnDef="componentType">
            <th mat-header-cell *matHeaderCellDef>
                Bonus
            </th>

            <td mat-cell *matCellDef="let data">
                {{ getComponentName(data.bonus.componentName) }}
            </td>
        </ng-container>

        <!-- orderPeriod column -->
        <ng-container matColumnDef="orderPeriod">
            <th mat-header-cell *matHeaderCellDef>
                Zeitraum
            </th>

            <td mat-cell *matCellDef="let data">
                {{ orderPeriod }}
            </td>
        </ng-container>

        <!-- orderAmount column -->
        <ng-container matColumnDef="employeeAmount">
            <th mat-header-cell *matHeaderCellDef>
                Betrag
            </th>

            <td mat-cell
                [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderEmployeesTableRow.textOrderSum.' + data.bonus.employeeId + '-' + data.bonus.componentName"
                *matCellDef="let data">
                {{ data.bonus.value }} EUR
            </td>
        </ng-container>

        <!-- actionsMenu column -->
        <ng-container matColumnDef="actionsMenu">
            <th mat-header-cell *matHeaderCellDef>
                Aktion
            </th>

            <td mat-cell *matCellDef="let data">
                <button mat-icon-button
                    [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderEmployeesTableRow.buttonActionsMenu.' + data.bonus.employeeId + '-' + data.bonus.componentName"
                    class="menu-button" [matMenuTriggerFor]="menu"
                    *ngIf="hasBonusContextMenu(data.bonusAllowedOperations)" (click)='menuClick($event)'>
                    <mat-icon>
                        more_vert
                    </mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                    <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.orderEmployeeBonusActionsMenu.buttonEditBonus"
                        *ngIf="data.bonusAllowedOperations.canEditBonusValue" (click)="editBonusValue(data)">
                        <mat-icon>
                            more_vert
                        </mat-icon>
                        <span>
                            Bonusbetrag ändern
                        </span>
                    </button>

                    <button mat-menu-item
                        data-cy="vn.directBonus.backofficeAdministration.orderEmployeeBonusActionsMenu.buttonRemoveBonus"
                        *ngIf="data.bonusAllowedOperations.canRemoveBonusFromOrder"
                        (click)="removeBonusFromOrder(data)">
                        <mat-icon>
                            bookmark_remove
                        </mat-icon>

                        <span>
                            Bonus entfernen
                        </span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>
    </table>
</div>
