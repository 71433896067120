<h5>
  Vorzeitiges Ende zurücknehmen
</h5>

<mat-dialog-content>
  <form [formGroup]='form'>
    <vn-input formControlName='comment'
              required
              label="Kommentar">
    </vn-input>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
          mat-dialog-close>
    Abbrechen
  </button>

  <button mat-stroked-button
          [disabled]="form.invalid"
          (click)="resetPreend()">
    Bestätigen
  </button>
</mat-dialog-actions>
