export const BIRTDAY_ERROR_MESSAGE = 'Leider stimmt Ihre Eingabe nicht mit den im System hinterlegten Daten überein. Bitte überprüfen Sie Ihre Eingabe. Sollten die Probleme weiter fortbestehen, so wenden Sie sich bitte an unseren Anwendungssupport unter 089-416176530.';

const $inject = [
  '$scope',
  'authenticationService',
  'yearlyService',
  'NotificationService',
  '$state',
  '$timeout'
];
export class YearlyUserValidationController {
  constructor ($scope, authenticationService, yearlyService, NotificationService, $state, $timeout){

    Object.assign(this, {
      $scope,
      authenticationService,
      yearlyService,
      NotificationService,
      $state,
      $timeout
    });

    this.init();
  }

  init() {
    this.forms = {};
    this.validation_code = {};

    if (this.$state.params.code) {
      this.prefillCode(this.$state.params.code);
    }

  }

  prefillCode(code) {
    const parts = code.split('-');
    if (parts.length == 3) {
      this.validation_code.enteredCodePart1 = parts[0];
      this.validation_code.enteredCodePart2 = parts[1];
      this.validation_code.enteredCodeChecksum = parts[2];
    }
    // $timeout required because this.forms.validationCodeForm is not defined initially and onEnterCode fails
    this.$timeout(() => this.onEnterCode());
  }

  async validateUser() {
    let validation_credentials = {
      code: this.enteredCode,
      birth_date: this.birth_date
    };

    let isUserValid = await this.yearlyService.validateUser(validation_credentials);
    if (!isUserValid) {
      this.$timeout(() => {
        this.wrong_birthday_error = {
          has_error: true,
          message: BIRTDAY_ERROR_MESSAGE
        };
      }, 0);
    } else {
      this.$state.go('blank.yearly.landing');
    }
  }

  onEnterCode() {
    if (this.validation_code.enteredCodePart1 && this.validation_code.enteredCodePart2 && this.validation_code.enteredCodeChecksum) {
      this.enteredCode = this.validation_code.enteredCodePart1 + this.validation_code.enteredCodePart2;
      this.isChecksumCorrect = this.computeCheckSum(this.enteredCode) === parseInt(this.validation_code.enteredCodeChecksum);
      this.forms.validationCodeForm.checksum.$setValidity('checkSumIncorrect', this.isChecksumCorrect);
    } else {
      this.forms.validationCodeForm.checksum.$setValidity('checkSumIncorrect', true);
    }
  }

  computeCheckSum(code) {
    let sum = 0;
    const codeString = code.split('');

    codeString.forEach(codeChar => sum += codeChar.charCodeAt(0));

    return Math.ceil((sum * 2.35) % 9);
  }
}

YearlyUserValidationController.$inject = $inject;
