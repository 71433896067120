import angular from "angular";
import {environment} from "../../environments/environment";

const emitter = new WeakMap();

class Emitter {

  constructor() {
    emitter.set( this, {
      events: {}
    } );

    this.eventLength = 0;
  }

  on( event, callback ) {
    if ( typeof callback !== 'function' ) {
      throw new TypeError( 'Listener must be a function' );
    }

    this.events[ event ] = this.events[ event ] || [];
    this.events[ event ].push( callback );

    this.eventLength++;

    return () => this.off( event, callback );
  }

  off( event, callback ) {
    if ( typeof callback !== 'function' ) {
      throw new TypeError( 'Listener must be a function' );
    }

    if ( typeof this.events[ event ] === 'undefined' ) {
      throw new Error( `Event not found - the event you provided is: ${event}` );
    }

    const listeners = this.events[ event ];

    listeners.forEach( (v, i) => {
      if ( v === callback ) {
        listeners.splice( i, 1 );
      }
    } );

    if ( listeners.length === 0 ) {
      delete this.events[ event ];

      this.eventLength--;
    }

    return this;
  }

  trigger( event, ...args ) {
    if ( typeof event === 'undefined' ) {
      throw new Error( 'You must provide an event to trigger.' );
    }

    let listeners = this.events[ event ];

    if ( typeof listeners !== 'undefined' ) {
      listeners = listeners.slice( 0 );

      listeners.forEach( (v) => {
        v.apply( this, args );
      } );
    }

    return this;
  }

  get events() {
    return emitter.get( this ).events;
  }
}

/**
 * generate uuid4
 * @return {string} uuid of form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
 */
function uuid4() {
  let uuid = '',
    ii;
  for (ii = 0; ii < 32; ii += 1) {
    switch (ii) {
      case 8:
      case 20:
        uuid += '-';
        uuid += (Math.random() * 16 | 0).toString( 16 );
        break;
      case 12:
        uuid += '-';
        uuid += '4';
        break;
      case 16:
        uuid += '-';
        uuid += (Math.random() * 4 | 8).toString( 16 );
        break;
      default:
        uuid += (Math.random() * 16 | 0).toString( 16 );
    }
  }
  return uuid;
}

function madeDropzone() {

  return {
    restrict: 'A',
    scope: {
      madeDropzone: '=',
      madeDropzoneSingle: '@'
    },
    link: function ( scope, element ) {


      let onDragOver = event => {
          event.preventDefault();
          element.addClass( 'dragOver' );
        },

        onDragEnd = event => {
          event.preventDefault();
          element.removeClass( 'dragOver' );
        },

        onDrop = event => {

          onDragEnd( event );

          if (!scope.madeDropzone && !scope.madeDropzoneSingle) {
            scope.madeDropzone = [];
          }

          if ( event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length ) {

            if ( scope.madeDropzoneSingle ) {

              scope.madeDropzone = event.dataTransfer.files[ 0 ];
            } else {

              for (let i = 0; i < event.dataTransfer.files.length; i++) {
                scope.madeDropzone.push( event.dataTransfer.files[ i ] );

              }
            }
          }

          scope.$applyAsync();
        };

      element
        .on( 'dragover', onDragOver )
        .on( 'dragleave', onDragEnd )
        .on( 'drop', onDrop );
    }
  };
}


function madeFileInput() {

  return {
    restrict: 'A',
    scope: {
      madeFileInput: '=',
      madeFileSingle: '@',
      madeFileOnChange: '&'
    },
    link: function ( scope, element ) {


      element.on( 'change', function () {

        if (!scope.madeFileInput && !scope.madeFileSingle) {
          scope.madeFileInput = [];
        }

        if ( element[ 0 ].files.length ) {

          if ( scope.madeFileSingle ) {

            scope.madeFileInput = element[ 0 ].files[ 0 ];

          } else {

            for (let i = 0; i < element[ 0 ].files.length; i++) {
              scope.madeFileInput.push( element[ 0 ].files[ i ] );
            }

          }
        }

        scope.$applyAsync();

        if (scope.madeFileOnChange) {
          scope.madeFileOnChange();
        }

      } );
    }
  };
}

const $injectMadeConfigProvider= [];
class madeConfigProvider {

  constructor() {


    this.url = {
      protocol: 'ws://',
      host: environment.madeHostUrl,
      path: '/ws',
      gridProtocol: 'http://'
    };

    if ( 'https:' === window.location.protocol ) {
      this.url.protocol = 'wss://';
      this.url.gridProtocol = 'https://';
    }

    this.store = window.localStorage;

  }


  $get() {
    return this;
  }

}
madeConfigProvider.$inject = $injectMadeConfigProvider;
const COOKIE_MADE_USER = 'made-user';

const $injectMade = ['$q', '$http', 'madeConfig', '$cookies'];
class Made extends Emitter {
  constructor(
    $q,
    $http,
    madeConfig,
    $cookies
  ) {
    'ngInject';

    super();

    this.$q = $q;
    this.$http = $http;
    this.madeConfig = madeConfig;

    this.contexts = {};
    this.initialTimeout =
      madeConfig.reconnectTimeout || Math.round(Math.random() * 500 + 500);
    this.reconnectTimeout = this.initialTimeout;
    this.wss = false;
    this.store = madeConfig.store;
    this.cookieStore = $cookies;
    this.user = {};
    this.debug = !environment.production;

    this.setupSocket();

    this.getUser();

    if (this.debug) {
      this.registerMessages();
    }
  }
  getUser(){
    const user_info =  this.cookieStore.getObject(COOKIE_MADE_USER);
    if(user_info) {
      this.user = user_info;
//      this.saveUser(user_info);
    }

    return this.user;
  }

  saveUser(user) {
    this.user = user;
    this.cookieStore.putObject(COOKIE_MADE_USER, this.user);
  }

  // if angular cookie provider cause issues use this
  // getCookie(name) {
  //   const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  //   const cookie =  v ? JSON.parse(decodeURIComponent(v[2])) : null;
  //   return cookie;
  // }

  // setCookie(name, value, days=1) {
  //   const d = new Date;
  //   d.setTime(d.getTime() + 24*60*60*1000*days);
  //   document.cookie = name + "=" + encodeURI(JSON.stringify(value)) + ";path=/;expires=" + d.toGMTString();
  // }

  // deleteCookie(name) { this.setCookie(name, '', -1); }

  proxy(user, redirectTo='/dashboard') {
    this.saveUser(user);
    window.location.href = redirectTo;
  }

  setupSocket() {
    this.wss = new WebSocket(
      this.madeConfig.url.protocol +
      this.madeConfig.url.host +
      this.madeConfig.url.path
    );

    this.wss.onopen = (open) => {
      this.reconnectTimeout = this.initialTimeout;
      this.trigger('socket:open', open);
    };

    this.wss.onerror = (error) => this.trigger('socket:error', error);

    this.wss.onmessage = (message) => this.receiveMessage(message);

    this.wss.onclose = (close) => {
      this.trigger('socket:close', close);

      setTimeout(() => this.setupSocket(), this.reconnectTimeout);

      if (this.reconnectTimeout < 1000 * 60 * 2) {
        this.reconnectTimeout *= 2;
      }
    };
  }

  receiveMessage(message) {
    message = JSON.parse(message.data);

    if ('answer' !== message.action) {
      if (this.debug) {
        console.log('made-js dropping message that’s not an answer', message);
      }
      return;
    }

    if (!(message.context in this.contexts)) {
      if (this.debug) {
        console.error('made-js error: message for unknown context', message);
      }
      return;
    }

    if (message.success) {
      this.trigger('message:success', message);
      this.contexts[message.context].resolve(message);
    } else {
      this.trigger('message:error', message);
      this.contexts[message.context].reject(message);
    }

    delete this.contexts[message.context];
  }

  message(action, data) {
    return {
      user: {
        _id: this.user._id,
        session: this.user.session,
      },
      context: 0,
      action: action,
      data: data,
      error: null,
      success: true,
    };
  }

  whenConnected(callback) {
    let wait = () => {
      this.trigger('connection:waiting');
      setTimeout(() => {
        this.whenConnected(callback);
      }, 750);
    };

    if (this.wss) {
      switch (this.wss.readyState) {
        case WebSocket.OPEN:
          callback();
          break;
        case WebSocket.CONNECTING:
          wait();
          break;
        default:
          this.trigger('connection:closed');
          this.setupSocket();
          wait();
      }
    } else {
      wait();
    }
  }

  send(action, data) {
    let deferred = this.$q.defer(),
      context = uuid4();

    this.contexts[context] = deferred;

    this.sendWithContext(action, data, context);

    return deferred.promise;
  }

  sendWithContext(action, data, context) {
    let msg = this.message(action, data);

    msg.context = context;

    if ('request' === msg.action) {
      this.contexts[context].uri = msg.data.uri;
    } else if ('schema' === msg.action) {
      this.contexts[context].uri = msg.data;
      this.contexts[context].action = msg.action;
    }

    this.trigger('message:send', msg);

    this.whenConnected(() => this.wss.send(angular.toJson(msg)));
  }

  request(uri, kwargs = {}, refine = 'data') {
    let promise = this.send('request', {
      uri,
      kwargs,
    });

    if (refine) {
      promise = promise.then((response) => response[refine]);
    }

    return promise;
  }

  schema(url, refine = 'data') {
    let promise = this.send('schema', url);

    if (refine) {
      promise = promise.then((response) => response[refine]);
    }

    return promise;
  }

  getDocument(params) {

    if ( !params['type'] ) {
      throw 'Should specify type';
    }

    let request = {
      method: 'GET',
      responseType: 'blob',
      url: `${this.madeConfig.url.gridProtocol}${this.madeConfig.url.host}/documents_download_request`,
      params: params,
    };

    return this.$http(request);
  }

  upload(file, tags = [], userId = this.user._id, extra_payload=null) {
    let formData = new FormData(),
      request = {
        method: 'POST',
        url:
          this.madeConfig.url.gridProtocol +
          this.madeConfig.url.host +
          '/gridfs',
        data: formData,
        headers: {
          'Content-Type': undefined,
        },
      };

    formData.append('user', userId);
    formData.append('session', this.user.session);

    if (file['_id']) {
      formData.append('file_id', file['_id']);
    } else {
      formData.append('file', file);
      formData.append('filename', file.name);
    }

    for (let i = 0; i < tags.length; i++) {
      formData.append('tag', tags[i]);
    }

    if (extra_payload) {
      formData.append('extra_payload', JSON.stringify(extra_payload));
    }

    return this.$http(request);
  }

  isLoggedIn() {
    return Object.keys(this.user).length > 0;
  }


  logout() {
    this.request('rpc://crm/user/logout');

    this.user = {};
    this.cookieStore.remove(COOKIE_MADE_USER);
    this.trigger('logout');
  }

  registerMessages() {
    this.on('socket:open', () => {
      console.log('socket open')
    });

    this.on('socket:error', (error) => {
      console.error('socket error', error)
    });

    this.on('socket:close', () => {
      console.log('socket close')
    });

    this.on('connection:waiting', () =>
      console.log('made-js - waiting for connection')
    );

    this.on('connection:closed', () =>
      console.log('made-js: tried sending over closed socket!')
    );

    this.on('message:success', (message) => {
      if ('schema' === this.contexts[message.context].action) {
        console.log(
          '++ received schema ++',
          this.contexts[message.context].uri,
          message.data.schema
        );
      } else {
        console.log(
          '++ received ++',
          this.contexts[message.context].uri,
          message.data,
          message.error
        );
      }
    });

    this.on('message:error', (message) =>
      console.error(
        '-- received --' + (message.error ? 'ERROR' : ''),
        this.contexts[message.context].uri,
        message
      )
    );

    this.on('message:send', (message) => {
      if ('request' === message.action) {
        console.log('-- sending --', message.data.uri, message.data.kwargs);
      } else if ('schema' === message.action) {
        console.log('-- schema --', message.data);
      } else {
        console.log('-- sending --', message.action);
      }
    });
  }
}

Made.$inject = $injectMade;

class DictionaryCache {

  constructor( $q, scaffold = {}, store = {} ) {

    this.$q       = $q;
    this.scaffold = scaffold;
    this.store    = store;

  }


  get( id, stale = 0 ) {

    if ( this.store[ id ] && this.store[ id ].stale >= stale ) {
      return this.$q.when( this.store[ id ].data );
    }

    if ( this.store[ id ] && this.store[ id ].promise ) {
      return this.store[ id ].promise;
    }
  }


  getData( id, stale = 0 ) {

    if ( this.store[ id ] && this.store[ id ].stale >= stale ) {
      return this.store[ id ].data;
    }
  }


  set( id, data, promise ) {

    if ( 'undefined' !== typeof data ) {
      return this.$q.when( this.setData( id, data ) );
    }

    if ( 'undefined' !== typeof promise ) {
      return this.setPromise( id, promise );
    }
  }


  setData( id, data ) {

    if ( !this.store[ id ] ) {
      this.store[ id ] = angular.copy( this.scaffold );
    }

    this.store[ id ].data  = data;
    this.store[ id ].stale = Date.now();
    delete this.store[ id ].promise;

    return this.store[ id ].data;
  }

  setPromise( id, promise ) {

    if ( !this.store[ id ] ) {
      this.store[ id ] = angular.copy( this.scaffold );
    }

    this.store[ id ].promise = promise.then( data => this.setData( id, data ) );
    this.store[ id ].promise.finally( () => {
      delete this.store[ id ].promise;
    } );

    return this.store[ id ].promise;
  }

}


class ArrayCache {

  constructor( $q, scaffold = {}, store = [] ) {

    this.$q       = $q;
    this.scaffold = scaffold;
    this.store    = store;

  }


  find( id ) {

    return this.store.find( item => {

      for (let key in id) {
        if ( item.data[ key ] !== id[ key ] ) {
          return false;
        }
      }

      return true;
    } );
  }

  findOrCreate( id ) {

    let cached = this.find( id );

    if ( !cached ) {

      cached      = angular.copy( this.scaffold );
      cached.data = angular.merge( cached.data || {}, id );
      this.store.push( cached );

    }

    return cached;
  }


  get( id, stale = 0 ) {

    let cached = this.find( id );

    if ( cached && cached.stale >= stale ) {
      return this.$q.when( cached.data );
    }

    if ( cached && cached.promise ) {
      return cached.promise;
    }
  }


  getData( id, stale = 0 ) {

    let cached = this.find( id );

    if ( cached && cached.stale >= stale ) {
      return cached.data;
    }
  }


  set( id, data, promise ) {

    let cached = this.findOrCreate( id );

    if ( 'undefined' !== typeof data ) {
      return this.$q.when( this.setData( id, data, cached ) );
    }

    if ( 'undefined' !== typeof promise ) {
      return this.setPromise( id, promise, cached );
    }
  }


  setData( id, data, cached ) {

    if ( !cached ) {
      cached = this.findOrCreate( id );
    }

    cached.data  = data;
    cached.stale = Date.now();
    delete cached.promise;

    return cached.data;
  }

  setPromise( id, promise, cached ) {

    if ( !cached ) {
      cached = this.findOrCreate( id );
    }

    cached.promise = promise.then( data => this.setData( id, data, cached ) );
    cached.promise.finally( () => {
      delete cached.promise;
    } );

    return cached.promise;
  }


}

const $injectCacheService = ['$q'];
class CacheService {

  constructor( $q ) {
    'ngInject';

    this.$q     = $q;
    this.stores = {};

  }


  getDictionaryStore( name, scaffold = {}, store = {} ) {

    if ( !this.stores[ name ] ) {
      this.stores[ name ] = new DictionaryCache( this.$q, scaffold, store );
    }

    return this.stores[ name ];
  }

  getArrayStore( name, scaffold = {}, store = [] ) {

    if ( !this.stores[ name ] ) {
      this.stores[ name ] = new ArrayCache( this.$q, scaffold, store );
    }

    return this.stores[ name ];
  }

}
CacheService.$inject = $injectCacheService;

angular
  .module( 'made-js', ['ngCookies'] )
  .config(['$cookiesProvider', function($cookiesProvider) {
    $cookiesProvider.defaults.secure = !window.location.hostname.includes('localhost');
    $cookiesProvider.defaults.path = '/';
    // const hostParts = location.host.split('.')
    // hostParts.pop();
    // $cookiesProvider.defaults.domain = `.${hostParts[hostParts.length-1]}`;
    // $cookiesProvider.defaults.domain = '.localhost'
    console.log("$cookiesProvider.defaults", $cookiesProvider.defaults);
  }])
  .provider( 'madeConfig', madeConfigProvider )
  .service( 'Made', Made )
  .service( 'CacheService', CacheService)
  .directive( 'madeFileInput', madeFileInput )
  .directive( 'madeDropzone', madeDropzone );
