<div class="dticketDialogContent">
  <div class="row">
    <div class="dialogTitle">
      <h2 style="font-size: 25px">
        <i class="font-icon icon-document"></i>DeutschlandTicket JOB
      </h2>
    </div>
  </div>

  <br/>
  <br/>

  <div class="row">
    <form [formGroup]="formGroup">
      <vn-select
        formControlName='flatTaxType'
        label="Kostenmodelle"
        key="flatTaxTypeOptions"
        [withPlaceholder]="false"
        [initValue]='initTaxTypeOption'
        [options]='taxTypeOptions'>
      </vn-select>
    </form>

    <br/>
    <br/>

    <button type="button"
      (click)="close()">Schließen
    </button>
  </div>
</div>
