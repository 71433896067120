angular
  .module('VSPApp')
  .directive('vnModelMatch', [function () {

    return {
      restrict: 'A',
      scope: {
        vnModelMatch: '='
      },
      require: 'ngModel',
      link: function (scope, ele, attr, ngModel) {
        let checker = () => ngModel.$modelValue == scope.vnModelMatch;
        scope.$watch(checker, valid => ngModel.$setValidity('modelMatch', valid));
      }
    };
  }]);
