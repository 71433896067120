import mitarbeiter_dialog_html from './mitarbeiter/dialog.pug';
import side_month_dialog_html from './side/month.pug';
import zentrale_freigabe_dialog_html from './zentrale/freigabe.pug';

angular
  .module('VSPApp')
  .service('nameFilter', ['vcpModel', function (vcpModel) {

    var nameFilter = {};

    nameFilter.name = '';

    nameFilter.trigger = function () {
      if (this.name.trim()) {
        vcpModel.search(this.name);
      } else {
        vcpModel.getData();
      }
    };

    return nameFilter;

  }]);

angular
  .module('VSPApp')
  .controller('VCPSideController', [
    '$scope', 'ngDialog', 'Made', 'nameFilter', 'vcpModel', 'VCPBonusService', 'CardService', 'MonthsService',
      function ($scope, ngDialog, Made, nameFilter, vcpModel, VCPBonusService, CardService, MonthsService) {
      var year = (new Date()).getFullYear();

      $scope.vcpModel = vcpModel;
      $scope.bonusService = VCPBonusService;
      $scope.nameFilter = nameFilter;
      $scope.filterString = '';

      $scope.bonusMonth = MonthsService.getMonthName(MonthsService.month + 1);
      $scope.bonusYear = year !== MonthsService.year ? MonthsService.year : '';

      if (-1 === Made.user.groups.indexOf('vcp_central')) {
        // $scope.notCentral = true;
      }

      $scope.arbeitnehmerFreischalten = () => {
        ngDialog.open({
          template: mitarbeiter_dialog_html(),
          plain: true,
          className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
          controller: 'vcpMitarbeiterController'
        });
      };

      $scope.chooseMonth = () => {
        ngDialog.openConfirm({
            template: side_month_dialog_html(),
            plain: true,
            className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
            controller: 'ChooseMonthDialogController',
            showClose: true
          })
          .then(function () {
            $scope.bonusMonth = MonthsService.getMonthName(MonthsService.month + 1);
            $scope.bonusYear = year !== MonthsService.year ? MonthsService.year : '';
            vcpModel.getData();
          });
      };

      $scope.freigabeErteilen = () => {
        ngDialog.openConfirm({
            className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
            showClose: true,
            template: zentrale_freigabe_dialog_html(),
            plain: true,
            data: {
              bonusService: VCPBonusService,
              vcpModel: vcpModel
            }
          })
          .then(function () {
            $scope._freigabeErteilen();
          });
      };


      $scope._freigabeErteilen = function () {
        if (!vcpModel._currentCity) return;

        vcpModel.working++;

        VCPBonusService
          .acceptByLocation(vcpModel._currentCity.id, MonthsService.year, MonthsService.month)
          .then(function () {
            VCPBonusService.getOverview();
            vcpModel.working--;
            return vcpModel.getData();
          });
      };

      $scope.edenredBestellen = function () {
        CardService.getCreateCardList();
      };

      $scope.edenredAufladen = function () {
        CardService.getChargeCardList();
      };

      $scope.abrechnen = function () {
        VCPBonusService.clearBoni();
      };
    }]);
