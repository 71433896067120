import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectBonusFormData, DirectBonusProjectConfiguration } from '../../../models/direct-bonus-data.model';
import { ProjectActionsDialogData } from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import {LoadingService} from "../../../../shared/services/loading.service";

// ===============================================================

interface FormChoices {
  bonusValue: string;
}

// ===============================================================

@Component({
  selector: 'vn-direct-bonus-edit-dialog',
  templateUrl: './direct-bonus-edit-dialog.component.html',
  styleUrls: ['./direct-bonus-edit-dialog.component.scss']
})
export class DirectBonusEditDialogComponent implements OnInit {

  // TODO make observable!!
  public componentMaxValue!: number;

  public formGroup!: FormGroup;

  public get bonusId() {
    return this.dialogData.bonusId;
  }

  public get period(): string {
    return this.dialogData.period!;
  }

  public get employeeId(): number {
    return this.dialogData.employeeId!;
  }

  public get bonusProjectId(): string {
    return this.dialogData.bonusProjectId!;
  }

  public get employeeName(): string {
    return this.dialogData.employeeName!;
  }

  public get bonusValue(): FormControl {
    return this.formGroup.get('bonusValue') as FormControl;
  }

  public get bonusValueStr() {
    return `${this.dialogData.bonusValue} €`;
  }

  public get componentType() {
    return this.dialogData.componentType!;
  }

  constructor(
    private formBuilder: FormBuilder,
    public directBonusService: DirectBonusService,
    private loader: LoadingService,
    private dialogRef: MatDialogRef<DirectBonusEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
  ) {
  }

  ngOnInit(): void {
    this.loader.show();
    this.directBonusService
      .getBonusProjectConfiguration(this.bonusProjectId)
      .subscribe(
        (bonusProjectConfiguration: DirectBonusProjectConfiguration) => {
          const componentConfig = bonusProjectConfiguration[this.componentType];

          this.componentMaxValue = componentConfig.maxValue;

          this.loader.hide();

          this.initFormGroup();
        }
      );
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      bonusValue: this.formBuilder.control(undefined, [
        Validators.required,
        Validators.pattern(/^\-?[0-9]+(?:\.[0-9]{2})?$/),
        Validators.min(0),
        Validators.max(this.componentMaxValue),
      ]),
    });
  }

  public editBonusValue() {
    const {
      bonusValue,
    }: FormChoices = this.formGroup.value;

    const newBonusData: DirectBonusFormData = {
      bonusId: this.bonusId,
      formValue: parseInt(bonusValue),
      employeeId: this.employeeId,
      componentName: this.componentType,
      period: this.period
    };

    this.dialogRef.close(newBonusData);
  }
}
