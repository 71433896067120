import ComponentController from '../ComponentController';

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'vbmService',
  'userService',
  'bikeleasingService',
  'mppService'
];
export default class ComponentControllerentfernung extends ComponentController {

  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    vbmService,
    userService,
    bikeleasingService,
    mppService
  ) {

    super(
      $q,
      $injector,
      $scope,
      $state,
      $transitions,
      $location,
      Made,
      debounceFactory,
      vbmData,
      neoModifierService,
      customerService,
      PlausibilityService,
      dialogService,
      SliderOptionsConstants,
      componentsService,
      NotificationService,
      vbmService,
      userService,
      bikeleasingService,
      mppService
    );

    this.vbmService = vbmService;

  }

  getDistance() {

    this.vbmService
      .getDistance(this.vbmData.employeeId)
      .then(distance => this.model.anzahl_km = distance);
  }
}
ComponentControllerentfernung.$inject = $inject;
