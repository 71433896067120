import {
  DEFAULT_POSTCODE_OBJECT,
  DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS
} from '../../../../../services/mapsService';
import MarkerClusterer from '@googlemaps/markerclustererplus';

const $inject = [
  '$scope',
  'MapsService',
  'BenefitBonagoService',
  '$timeout',
  '$location',
  '$state'
];
export default class BenefitBonagoOverviewMapController {
  constructor(
    $scope,
    MapsService,
    BenefitBonagoService,
    $timeout,
    $location,
    $state
  ) {
    Object.assign(this, {
      $scope,
      MapsService,
      BenefitBonagoService,
      $timeout,
      $location,
      $state
    });

    this.DEFAULT_POSTCODE_OBJECT = DEFAULT_POSTCODE_OBJECT;
    this.DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS = DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS;
    this.loading = {
      main: false
    };
    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.default_postcode_obj = angular.copy(this.DEFAULT_POSTCODE_OBJECT);
    this.default_postcode_obj.componentRestrictions.postalCode = this.BenefitBonagoService.user_scheme.default_postcode;
    let map_center;
    let zoom = 11;
    // when transitioning here from the details view, we want to center on a specific marker;
    if (this.$state.params.lat && this.$state.params.lng) {
      map_center = {lat: this.$state.params.lat, lng: this.$state.params.lng}
      zoom = 16;
    } else {
      map_center = await this.MapsService.getAddressFromPostcode(this.default_postcode_obj);
    }

    let map_options = {
      mapId: this.MapsService.getOverviewMapId(),
      center: map_center,
      zoom: zoom
    };

    this.retailers = await this.BenefitBonagoService.getRetailersBasic({
      category_id: this.DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS,
      with_information: true
    });
    this.map = new google.maps.Map(document.getElementById('retailers_full_map'), map_options);
    let postcode_input = document.getElementById('postcode_input');
    let autocomplete_input = document.getElementById('retailer_search');
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(postcode_input);
    let markers = [];

    let autocomplete = new google.maps.places.Autocomplete(autocomplete_input);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.map.setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
    });

    this.retailers.forEach(retailer => {
      if (retailer.addresses && retailer.addresses.length) {
        retailer.addresses
          .forEach(address => {

            let home_url = this.$location.protocol() + '://' + location.host;
            let icon_url = `${home_url}/assets/img/maps/default_map_icon_image.png`;
            // create and add marker to map
            let marker = new google.maps.Marker({
              position: new google.maps.LatLng(address.lat, address.lng),
              map: this.map,
              icon: {
                url: icon_url,
                scaledSize: new google.maps.Size(30, 34), // scaled size
                origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(16, 34) // anchor
              }
            });


            let infowindow = new google.maps.InfoWindow({
              content: this.MapsService.infoBoxTemplate['retailer']({
                name: retailer.name,
                id: retailer.id,
                ...address
              })
            });

            marker.addListener('click', function () {
              infowindow.open(this.map, marker);
            });

            markers.push(marker);

          });
      }
    });

    let markerCluster = new MarkerClusterer(this.map, markers, this.MapsService.mcOptions);

    this.$timeout(() => {
      this.loading.main = false;
    });
  }
}
BenefitBonagoOverviewMapController.$inject = $inject;
angular
  .module('VSPApp')
  .controller('BenefitBonagoOverviewMapController', BenefitBonagoOverviewMapController);
