import {DEFAULT_BIKELEASING_CHECKOUT_CONFIG} from '../../../services/administrationService';

const $inject = [
  '$scope',
  '$q',
  'lodash',
  'dialogService',
  'moment',
  'Made',
  '$timeout',
  '$window',
  'employeeService',
  'BonusService',
  'administrationService',
  'customerService',
  'NotificationService',
  'BIKELEASING_CONTRACT_REGEX'
];
export default class BonusBikeleasingControlProcessController {
  constructor(
    $scope,
    $q,
    lodash,
    dialogService,
    moment,
    Made,
    $timeout,
    $window,
    employeeService,
    BonusService,
    administrationService,
    customerService,
    NotificationService,
    BIKELEASING_CONTRACT_REGEX
  ) {

    Object.assign(this, {
      $scope,
      $q,
      lodash,
      dialogService,
      moment,
      Made,
      $timeout,
      $window,
      employeeService,
      BonusService,
      administrationService,
      customerService,
      NotificationService,
      BIKELEASING_CONTRACT_REGEX
    });

    this.loading = true;
    this.forms = {};

    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.customer_id = this.$scope.ngDialogData.customer_id;
    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;
    this.forms = {};
    this.bikeleasing_checkout_config = DEFAULT_BIKELEASING_CHECKOUT_CONFIG;

    let promises = [];

    promises.push(
      this.BonusService.isOverspendingAllowed(this.employee_id).then(is_overspending_allowed => {
        this.is_overspending_allowed = is_overspending_allowed;
      })
    );

    // promises.push(
    //   this.BonusService.calculateBikeleasingLimits(this.employee_id, this.bonus_project_id, this.customer_id)
    //     .then(limits => this.limits = limits)
    // );

    promises.push(
      this.administrationService.getEmployeePersonalInfo(this.employee_id)
        .then(personal_info => {
          this.personal_info = personal_info;
        })
    );

    this.$q.all(promises).then(() => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    }).catch((err) => {
      console.log(err);
    });

  }

  calculateLimits() {
    this.limits = null;
    this.BonusService.calculateBikeleasingLimits(this.employee_id, this.bonus_project_id, this.customer_id, this.bikeleasing_checkout_config.begin_date)
      .then(limits => {
        this.$timeout(() => {
          this.limits = limits;
        });
      });
  }

  accept() {
    this.loading = true;
    this.BonusService.giveBikeLeasing(this.employee_id, this.bonus_project_id, this.bikeleasing_checkout_config).then(() => {
      this.close();
    }).catch((e) => {
      console.error(e);
      this.loading = false;
      this.NotificationService.error('Cant give BIKE');
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  decline() {
    this.close();
  }
}
BonusBikeleasingControlProcessController.$inject = $inject;
