angular
  .module('VSPApp')
  .directive('randomAnimate', ['$timeout', function ($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, elem) {
        var animClass = 'random-animate';
        var enterClass = 'random-animate-enter';

        elem.addClass(animClass + ' ' + enterClass);

        $timeout(function () {
          elem.removeClass(enterClass);
        }, (Math.random() * 400) + 300);
      }
    };
  }]);
