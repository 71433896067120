const $inject = [
  "cmsService", 
  "$scope", 
  "$state", 
  "fileService", 
  "$timeout"
];

export default class CmsEntryListController {

    constructor(cmsService, $scope, $state, fileService, $timeout) {
        Object.assign(this, {
            cmsService,
            $scope,
            $state,
            fileService,
            $timeout
        });

        this.cmsCtrl = $scope.$parent.$parent.$parent;
        this.custId = $state.params.custId;
        this.items = [];
        this.cmsService.globalCms.detailId = 0;
        this.cmsService.globalCms.loading = true;
        this.loadList().then(()=>{
          this.$timeout(()=>{});
        });
    }

    loadList() {
        return this.cmsService.loadUserCategoryList(this.custId).then( async items => {
            if(items.length === 1) {
                this.cmsService.globalCms.loading = false;
                this.$state.go('inApp.cms.itemlist', {'categoryId': items[0]._id, 'custId': this.custId});
                return;
            }

            this.items = items;
            this.styles = {};
            for (let item of this.items){
              if (item.image && item.image._id){
                let url = await this.fileService.getDocumentUrl(item.image._id);
                this.styles[item.image._id] = {
                  'background-image' : 'url(' + url  + ')'
                };
              }
            }

            this.cmsService.globalCms.loading = false;
        });
    }
}

CmsEntryListController.$inject = $inject;