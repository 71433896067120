angular
  .module('VSPApp')
  .directive('toggableContentToggler', [
    function () {
      return {
        require: '^toggableContent',
        restrict: 'EA',
        template: '<ng-transclude></ng-transclude>',
        replace: true,
        transclude: true,
        link: function ($scope, iElm, iAttrs, ctrl) {
          ctrl.setToggler($scope, iElm);

          $scope.togglerClicked = ctrl.togglerClicked;
        }
      };
    }
  ]);
