const $inject = [
  '$scope',
  'NotificationService',
  'Made',
  'authenticationService',
  'UserGroupsService',
  'ACLService',
  '$q',
  '$timeout',
  'lodash'
];
export default class UserGroupsAdministrationDialogController {
  constructor(
    $scope,
    NotificationService,
    Made,
    authenticationService,
    UserGroupsService,
    ACLService,
    $q,
    $timeout,
    lodash
  ) {
    Object.assign(this, {
      $scope,
      NotificationService,
      Made,
      authenticationService,
      UserGroupsService,
      ACLService,
      $q,
      $timeout,
      lodash
    });

    this.employee = this.$scope.ngDialogData.employee_data;
    this.employee_id = this.$scope.ngDialogData.employee_id;

    this.loading = true;
    this.init().then(() => {
      this.$timeout(() => {
        this.loading = false;
      });
    });

  }

  init() {
    let promises = [];
    promises.push(this.initPermisssionTypes());
    promises.push(this.initAllUserGroups());
    promises.push(this.amIEmployer());
    promises.push(this.getACLUserGroups());
    promises.push(this.getEmployeeCustomerSpecificGroups());

    return this.$q.all(promises);
  }

  amIEmployer() {
    return this.ACLService.amIEmployer(this.employee.customer_id)
      .then((res) => {
        this.iAmEmployer = res;
      })
      .catch((err) => {
        console.err(err);
        this.iAmEmployer = false;
      });
  }

  initAllUserGroups() {
    return this.UserGroupsService.getUserGroups(this.employee_id).then(
      (res) => {
        this.user_groups = res;
      }).catch((err) => {
      console.error(err);
      this.user_groups = [];
    });
  }

  canEditGroup(group) {
    if (group.permissions.includes(this.PERMISSION_TYPES_BY_NAME['edit_group'].id)) {
      return true;
    }
    return false;
  }

  initPermisssionTypes() {
    return this.UserGroupsService.getPermissionTypes().then(
      (res) => {
        this.PERMISSION_TYPES_BY_NAME = res.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
      }
    ).catch((err) => {
      console.error(err);
      this.PERMISSION_TYPES = {};
    });
  }

  getACLUserGroups() {
    return this.ACLService.getACLUserGroups()
      .then((customer_specific_groups) => {
        this.customer_specific_groups = customer_specific_groups.reduce((acc, user) => {
          acc[user['name']] = user;
          return acc;
        }, {});
      });
  }

  getEmployeeCustomerSpecificGroups() {
    return this.ACLService.getEmployeeCustomerSpecificGroups(this.employee_id)
      .then((customer_specific_groups) => {
        return this.employee_customer_specific_groups = customer_specific_groups;
      })
      .catch((err) => {
        this.employee_customer_specific_groups = {};
        console.error(err);
      });
  }


  updateUserGroups() {
    this.UserGroupsService.updateUserGroups(this.employee_id, this.user_groups).then(
      (res) => {
        if (res) {
          this.NotificationService.message("Gruppenzuordnung erfolgreich");
        } else {
          this.NotificationService.error("Gruppenzuordnung fehlgeschlagen");
        }
        this.$scope.confirm(0);
      }).catch((err) => {
      console.error(err);
      this.NotificationService.error("Gruppenzuordnung fehlgeschlagen");
      this.$scope.confirm(0);
    });
  }
}
UserGroupsAdministrationDialogController.$inject = $inject;
