angular
  .module('VSPApp')
  .directive('vnNumberToBool', [function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers.push((viewValue) => {
          return !!parseInt(viewValue);
        });
        ngModel.$formatters.push((modelValue) => {
          return modelValue ? '1' : '0';
        });
      }
    };
  }]);
