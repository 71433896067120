const $inject = [
  '$scope',
  'mppService',
  'Made',
  'NotificationService',
  'fileService',
  '$q'
];
export default class UploadOrderProcessDocumentsDialogController {
  constructor(
    $scope,
    mppService,
    Made,
    NotificationService,
    fileService,
    $q
  ) {
    Object.assign(this, {
      $scope,
      mppService,
      Made,
      NotificationService,
      fileService,
      $q
    })

    this.loading = {
      main: true,
      uploading_documents: false
    };

    $scope.formatDate = (date, tstamp = false) => {
      let dbDate = new Date(tstamp ? date * 1000 : date);

      return dbDate.getDate() + '.' + (dbDate.getMonth() + 1) + '.' + dbDate.getFullYear();
    };

    this.init();


  }

  init() {
    this.forms = {
      dropzone: {}
    };

    this.$scope.models = {
      documents: []
    };

    this.basket = this.$scope.ngDialogData.basket;

    this.loading.main = false;


  }

  uploadDocuments() {
    this.loading.uploading_documents = true;
    let promises = [];
    for (let i = 0; i < this.$scope.models.documents.length; i++) {
      promises.push(this.Made.upload(this.$scope.models.documents[i], this.$scope.order_process_file_tags, this.basket.employeeId));
    }
    return this.$q.all(promises)
      .then(() => {
        this.$scope.models.documents = [];
        this.NotificationService.message('Die Dateien wurden hochgeladen.');
        this.loading.uploading_documents = false;
        this.$scope.confirm();
      })
      .catch(e => {
        this.NotificationService.message('Fehler.');
        this.$scope.closeThisDialog();
      });
  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }

  async initOrderProcessFileTags(basket) {
    this.$scope.order_process_file_tags = await this.mppService.initOrderProcessFileTags({basket_id: basket._id});
  }

}
UploadOrderProcessDocumentsDialogController.$inject = $inject;
