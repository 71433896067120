/** object */
export const API_END_POINTS = {
  YOUSIGN: {
    START_DIGITAL_SIGNITURE_PROCESS: 'rpc://utility/digitalsigniture/start_process',
    CAN_USE_DS: 'rpc://utility/digitalsigniture/can_use_digital_signiture',
    BO_REQUESTED_FILES: 'rpc://utility/digitalsigniture/bo_requested_files',
    UPDATE_YOUSIGN_REQUEST: 'rpc://utility/digitalsigniture/update_yousign_request',
    PROCESS_PENDING_YOUSIGN_REQUEST: 'rpc://utility/digitalsigniture/process_pending_yousign_request'
  }
};
