<div 
  vnLoader
  [showLoader]='(isLoading$() | async)!'
  class="leasingdata-container"
>
  <h3>
    Aktualisierung Leasingvertragsnummern durch Bestandsliste MLF
  </h3>
  
  <vn-dropzone label="Bestandsliste MLF auswählen"
      (onUpload)="leasingDataImport($event)">
  </vn-dropzone>
</div>
