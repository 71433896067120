import {BONUS_INSURANCE_PAYERS, GAP_INSURANCE_PAYERS} from '../../../services/customerService';
import {PcDigitalSigningComponent} from '../../../services/digital-signing/components/pc-component';
import {PC_RATE_TYPES} from "../../../services/bonus/pc_rate_type";

const $inject = [
  '$scope',
  '$timeout',
  'customerService',
  '$state',
  'authenticationService',
  'NotificationService',
  'VSP_CONSTANTS',
  'NeoComponentsService'
];
export default class CockpitPCController {
  constructor(
    $scope,
    $timeout,
    customerService,
    $state,
    authenticationService,
    NotificationService,
    VSP_CONSTANTS,
    NeoComponentsService
  ) {

    Object.assign(this, {
      $scope,
      customerService,
      $timeout,
      $state,
      authenticationService,
      NotificationService,
      VSP_CONSTANTS,
      NeoComponentsService
    });

    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.iAmAdvisor = this.authenticationService.iAm('gadvisor') || this.authenticationService.iAm('advisor');

    this.working = true;
    this.customer_id = this.$state.params.customerId;
    this.BONUS_INSURANCE_PAYERS = BONUS_INSURANCE_PAYERS;
    this.GAP_INSURANCE_PAYERS = GAP_INSURANCE_PAYERS;
    this.PC_RATE_TYPES = PC_RATE_TYPES;
    this.MPP_TYPE = VSP_CONSTANTS.MPP_TYPE;
    this.forms = {};

    this.init().then(() => {
      this.$timeout(() => {
        this.working = false;
      }, 0);
    });

  // customerService
  //   .getConfiguration($state.params.customerId)
  //   .then(configuration => this.configuration = configuration)
  //   .then(() => this.working = false);

  }

  async init() {
    let promises = [];

    promises.push(
      this.customerService.getConfiguration(this.customer_id, Date.now()).then(configuration => {
        this.customer_configuration = configuration;
      })
    );

    promises.push(
      this.customerService.get(this.customer_id, Date.now()).then(customer => {
        this.customer = customer;
      })
    );

    return Promise.all(promises);
  }

  updateDigitalSignitureConfiguration() {
    if (this.customer_configuration.neo_config.pc.mpp_type === this.MPP_TYPE.CALCULATION_BASED['id']) {

      let pcConfig = new PcDigitalSigningComponent();

      this.customer_configuration.digital_signiture_config.enabled_components = {
        ...this.customer_configuration.digital_signiture_config.enabled_components,
        ...pcConfig.toMongo(),
      };
    }
  }

  canEdit() {
    return this.iAmSpecialAdvisor || this.iAmAdvisor;
  }

  canSave() {
    return this.canEdit() && (!this.working || !this.forms.pc_leasing_config.$valid || !this.configuration.fixated);
  }

  save() {
    this.working = true;

    this.updateDigitalSignitureConfiguration();

    this.customerService.setConfiguration(this.customer_id, this.customer_configuration).then(() => {
      this.NotificationService.message('Speichern erfolgreich.');
    }).catch(() => {
      this.NotificationService.error('Speichern fehlgeschlagen.');
    }).finally(() => {
      this.$timeout(() => {
        this.working = false;
      });
    });
  }
}
CockpitPCController.$inject = $inject;
