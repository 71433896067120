import create_bonus_project_dialog_html from '../../../modules/cockpit/bonus/create/create_bonus_project_dialog.pug'
import {BONUS_PROJECT_STATES_BY_ID} from "../../../services/bonus/bonus_project_states";

const $inject = [
  'customerService', 'authenticationService', 'dialogService', '$timeout', '$state', 'BonusService', '$q', 'NotificationService'
];
export default class CockpitBonusController {

  constructor(customerService, authenticationService, dialogService, $timeout, $state, BonusService, $q, NotificationService) {
    Object.assign(this, {
      customerService,
      authenticationService,
      dialogService,
      $timeout,
      $state,
      BonusService,
      $q,
      NotificationService
    });

    this.customer_id = parseInt($state.params.customerId);
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.BONUS_PROJECT_STATES_BY_ID = BONUS_PROJECT_STATES_BY_ID;

    this.init();

  }

  async init() {
    this.loading = true;
    let customer_promise = this.customerService.get(this.customer_id).then((customer) => this.customer = customer);
    let bonus_projects_promise = this.BonusService.getBonusProject(this.customer_id).then(bonus_projects => this.bonus_projects = bonus_projects);

    return this.$q.all([customer_promise, bonus_projects_promise]).then(() => {
      this.$timeout(() => {
        this.loading = false;
      });
    });
  }

  canHideBonusProject(bonus_project) {
    return this.canControlBonusProject() && this.BonusService.hasBonusProjectEnded(bonus_project);
  }

  canConfigureBonusProject(bonus_project) {
    return this.canControlBonusProject() && !this.BonusService.hasBonusProjectEnded(bonus_project);
  }

  canControlBonusProject() {
    return this.iAmSpecialAdvisor || this.iAmAdvisor;
  }

  async toggleProjectVisibilityAfterEnd(bonus_project) {
    this.$timeout(()=>{
      this.loading = true;
    });

    await this.BonusService.updateBonusProject(bonus_project);

    this.$timeout(()=>{
      this.loading = false;
    });
  }

  configureBonusProject(bonus_project) {

    let data = {
      customer_id: this.customer_id,
      bonus_project_id: bonus_project ? bonus_project['_id'] : null
    };

    let dialog = this.dialogService.ngDialog.open({
      template: create_bonus_project_dialog_html(),
      plain: true,
      controller: 'CreateBonusProjectController',
      controllerAs: '$ctrl',
      data: data,
      width: 1200,
      closeByDocument: false,
      closeByEscape: false,
      closeByNavigation: true
    });

    dialog.closePromise.then(() => {
      this.init();
    });
  }

  exportBonusesForBonusProject(bonus_project_id, bonus_project_type) {
    return this.BonusService.storeBonusesForPojectInMYSQL({bonus_project_id: bonus_project_id, bonus_project_type: bonus_project_type})
      .then(() => {
        this.NotificationService.message(`Successfully stored data for bonus project ${bonus_project_id} in MySQL`);
      })
      .catch( e => {
        this.NotificationService.error(`Was unable to store bonuses for bonus project ${bonus_project_id}`);
      })
  }
}
CockpitBonusController.$inject = $inject;
