<div>
  <vn-matblur-loading></vn-matblur-loading>

  <div class="row ng-scope">
    <div class="xsmall-12 medium-12 columns">
      <dl class="tabs">
        <dd [ngClass]="{'active': selectedTab === 'tab1'}">
          <a href="#" (click)="selectTab('tab1')">Angaben</a>
        </dd>
        <dd [ngClass]="{'active': selectedTab === 'tab2'}">
          <a href="#" (click)="selectTab('tab2')">Verträge</a>
        </dd>
      </dl>
    </div>
  </div>

  <div [ngSwitch]="selectedTab">
    <div *ngSwitchCase="'tab1'" class="tab-content">

      <div class="summary-box isTransparent">
        <div class="row">
          <div class="xsmall-12 medium-6 large-6 columns">
        <span class="summary-box__title">
          <strong>Firma:</strong>
          <span class="ng-binding"> {{customerName}} </span>
        </span>
          </div>
          <div class="xsmall-12 medium-6 large-6 columns text-right pull-right">
            <button
              class="button button-primary isOutline"
              (click)="saveConfiguration()"
              [disabled]="!isFormValid()"
            >Speichern
            </button>
          </div>
        </div>
      </div>

      <div class="cockpit-panel">
        <div class="cockpit-panel__title">
          Intern
        </div>
        <div class="cockpit-panel__body" *ngIf="!(isLoading() | async)">

          <form [formGroup]='formGroup'>
            <div class="row add-margin-bottom">
              <div class="columns small-5 add-margin-right">
                <label for="dTicketSubsidyEuro">Zuschuss durch AG €</label>
                <input
                  formControlName='euroSubsidy'
                  id="dTicketSubsidyEuro"
                  type="number"
                />
                  <mat-error *ngIf="euroSubsidy.hasError('required')">
                    Eingabe fehlt.
                  </mat-error>

                  <mat-error *ngIf="euroSubsidy.hasError('max')">
                    Bitte Eingabe prüfen.
                  </mat-error>

                  <mat-error *ngIf="euroSubsidy.hasError('min')">
                    Bitte Eingabe prüfen.
                  </mat-error>


              </div>

              <div class="columns small-5">
                <label for="dTicketSubsidyPercentage">
                  Zuschuss durch AG %
                </label>
                <input
                  id="dTicketSubsidyPercentage"
                  formControlName='percentageSubsidy'
                />
              </div>
            </div>
            <div class="row">
              <mat-slide-toggle
                formControlName="ordersApproval"
              >
                Freigabe durch AG aktivieren
              </mat-slide-toggle>
              <mat-slide-toggle
                formControlName="changeOfSubsidy"
              >
                Individuelle Anpassung des Zuschusses bei Freigabe aktivieren
              </mat-slide-toggle>
            </div>

          </form>

        </div>
        <br>
        <br>
        <div *ngIf="isCustomerConfigurationFixated" style="background-color: red" class="cockpit-panel__title">
          Die Projektkonfiguration ist gesperrt.
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'tab2'" class="tab-content">
      <div class="row tabs-content">
        <div class="xsmall-12 medium-12 large-12 columns">
          <div class="content">
            <h3>Diese Funktionalität ist derzeit nicht aktiv.</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
