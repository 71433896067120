import berater_target_dialog_html from '../berater/target/dialog.pug';
import protocol_dialog_html from '../report.pug';
import {CarLeasingSide} from '../../../../app/car-leasing/models/car-leasing-side.model'
import {
  NeoCalculationBookmarkDialogComponent
} from "../../../../app/neo-calculation-bookmark/components/neo-calculation-bookmark-dialog/neo-calculation-bookmark-dialog.component";
import {DticketOrderSide} from "../../../../app/dticket-order/models/dticket/dticket-order-side.model";

angular
  .module('VSPApp')
  .controller('SideController', [
    '$q',
    '$scope',
    '$state',
    '$window',
    'Made',
    'DocumentConstants',
    'CalculationResultNameConstants',
    'CalculationResultConstants',
    '$location',
    'vbmData',
    'vbmService',
    'SpecsService',
    'ngDialog',
    'authenticationService',
    'customerService',
    'advisorService',
    'userService',
    'basicInfoQuiz',
    'PopupService',
    'PopupVideoKeys',
    'employeeService',
    'dialogService',
    'neoModifierService',
    'PlausibilityService',
    'NotificationService',
    'CheckoutService',
    'mppService',
    'bikeleasingService',
    'lodash',
    'NeoComponentsService',
    'CarLeasingApiService',
    'MatDialogService',
    'DticketOrderService',
    function (
      $q,
      $scope,
      $state,
      $window,
      Made,
      DocumentConstants,
      CalculationResultNameConstants,
      CalculationResultConstants,
      $location,
      vbmData,
      vbmService,
      SpecsService,
      ngDialog,
      authenticationService,
      customerService,
      advisorService,
      userService,
      basicInfoQuiz,
      PopupService,
      PopupVideoKeys,
      employeeService,
      dialogService,
      neoModifierService,
      PlausibilityService,
      NotificationService,
      CheckoutService,
      mppService,
      bikeleasingService,
      lodash,
      NeoComponentsService,
      CarLeasingApiService,
      MatDialogService,
      DticketOrderService
    ) {

      $scope.$state = $state;

      $scope.vbmData = vbmData;
      $scope.advisorService = advisorService;
      $scope.DocumentConstants = DocumentConstants;
      $scope.CalculationResultConstants = CalculationResultConstants;
      $scope.CalculationResultNameConstants = CalculationResultNameConstants;
      $scope.employees = employeeService.employees;

      $scope.inCheckout = false;

      if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
        vbmData.employeeId = parseInt($location.search().employeeId);
        employeeService.get(vbmData.employeeId);
        vbmData.prepare(vbmData.employeeId);
      } else {
        vbmData.employeeId = vbmData.employeeId || (Made.user.valuenet_id || 0);
      }

      $scope.employee = vbmData.employees[vbmData.employeeId];
      $scope.notMe = Made.user.valuenet_id !== vbmData.employeeId;


      $scope.mobileOpen = false;
      $scope.meinBruttoOpen = false;

      $scope.alvKtg = {
        'GELDWERTER_VORTEIL': 'geldw. V.',
        'NETTOABZUG': 'Nettoabz.'
      };

      $scope.stateVerguetung = $state.current.name === 'inApp.neo.side.verguetung';
      $scope.stateModule = $state.current.name === 'inApp.neo.side.module';

      $scope.documents = {};

      $scope.clicker = {};

      $scope.neo_bikeleasings = [];
      if (authenticationService.iAm('gadvisor') || authenticationService.iAm('advisor')) {
        $scope.iAmAdvisor = true;
      }

      $scope.iAmSpecialAdvisor = authenticationService.iAm('special_advisor')

      $scope.clicker = {};

      if ($scope.stateVerguetung) {
        PopupService.processModuleVideo(
          PopupVideoKeys.MODULE_NEO,
          $scope.clicker
        );
      }

      let check = () => $q
        .all($scope.employee.dataPromise)
        .then(() => {
          return $q.when($scope.employee.dataPromise);
        })
        .then(() => {
          return $q.when($scope.employee.result.promise);
        })
        .then(() => customerService.getConfiguration())
        .then(configuration => {

          let brutto = lodash.get($scope.employee, 'data.parameters.brutto', undefined);

          for (let k in configuration.vorsorge) {
            if (configuration.vorsorge[k].title === 'betriebliche_altersvorsorge') {
              let bbg_rente = lodash.get($scope.employee, 'result.after.gap.calculation_information.bbg_rente', 0);
              $scope.zuschussCalc = configuration.vorsorge[k].zuschuss || (bbg_rente > (brutto * 100));

              $scope.bavAG = configuration.vorsorge[k].value || 0;
            }
          }

          if ($scope.zuschussCalc) {
            let needed = true;
            for (let obj of $scope.$$watchers) {
              if (obj.last === $scope.employee.data.insurances.bav.beitrag && obj.eq) {
                needed = false;
                break;
              }
            }
            if (needed) {
              $scope.$watch('employee.data.insurances.bav.beitrag', ((newVal, oldVal) => {
                if (newVal !== oldVal && $state.current.name !== 'inApp.neo.side.berater') {
                  $scope.employee.data.insurances.bav.zuschuss_ag = vbmData.getBAVZuschuss($scope.employee.data.insurances.bav.beitrag, ($scope.bavAG / 100));
                }
              }), true);
            }
          }

          // if ( brutto <= 0 ) {
          //   dialogService.employeeBasics();
          // }
        });
      check();

      customerService
        .getNeoProjectByEmployeeId(vbmData.employeeId)
        .then(neoProject => {
          $scope.neoProject = neoProject;
          $scope.mpp = neoProject.neo_components.pc;

          $scope.infomode = neoProject.infomode || false;
          $scope.noSelfCheckout = neoProject.noSelfCheckout || false;
        });

      bikeleasingService.getActiveNeoBikeleasing({employee_id: vbmData.employeeId}).then(neo_bikeleasings => {
        $scope.neo_bikeleasings = lodash.get(neo_bikeleasings, vbmData.employeeId + '.bikes');
      });

      Promise.all([
        NeoComponentsService.getUsedPCComponent({employee_id: vbmData.employeeId}), // bug ? -> where is the check if the pc is approved/enabled
        CarLeasingApiService.getEmployeeBaskets(vbmData.employeeId),
        DticketOrderService.getActiveDticket(vbmData.employeeId)
      ]).then(([pcBaskets, carBaskets, dTicketOrder]) => {
        const hasActiveDTicket = !!dTicketOrder
        let carLeasingBaskets = carBaskets.map((b) => {

          const isCarDelivered = (
            b.isFirstCheckApproved() &&
            b.isContractProcessApproved() &&
            b.isApprovalStateApproved()
          );
          return new CarLeasingSide(isCarDelivered, !isCarDelivered, b.checkoutConfiguration.per_month_value)
        });
        let dTicketSide = hasActiveDTicket ? [new DticketOrderSide(dTicketOrder.employee_contribution)] : [];
        $scope.hasActiveDTicket = !!hasActiveDTicket
        $scope.neo_components = pcBaskets.concat(carLeasingBaskets).concat(dTicketSide);
      })


      mppService.getOrderStatusesByName().then(statuses => {
        $scope.MPP_ORDER_STATUSES_BY_NAME = statuses;
      });


      let calcModifiedValues = () => neoModifierService.calculateModifiedValues(
        $scope.employee.data.neo_components,
        $scope.employee.data.parameters.jahr,
        vbmData.employeeId
      );


      let hasFinalCalculation = () => {
        vbmService
          .hasFinalCalculation(vbmData.employeeId)
          .then(result => $scope.hasFinalCalculation = result);
      };

      hasFinalCalculation();

      let checkSession = async () => {
        CheckoutService
          .checkSession(vbmData.employeeId)
          .then(checkoutSession => {
            if (checkoutSession) {
              $scope.inCheckout = true;
            }
          });
      };

      if ($state.current.name === 'inApp.neo.side.berater') {
        checkSession();
      }

      $scope.leaveSession = async () => {
        await checkSession();
        if ($scope.inCheckout) {
          CheckoutService
            .leaveSession(vbmData.employeeId)
            .then(
              () => location.reload()
            );
        } else {
          location.reload();
        }
      };


      $scope.changeEmployee = function () {
        $scope.employee = vbmData.employees[vbmData.employeeId];
        $scope.notMe = Made.user.valuenet_id != vbmData.employeeId;
        $scope.hasFinalCalculation = false;
        hasFinalCalculation();
        checkSession();
        check();
      };

      $scope.resetTarget = function () {

        vbmData.employeeId = Made.user.valuenet_id;

        vbmData.getResult();
        if (vbmData.trigger) {
          vbmData.trigger();
        }

        vbmData.employeeId = Made.user.valuenet_id;
        $scope.employee = vbmData.employees[vbmData.employeeId];
        $scope.notMe = false;
        $scope.hasFinalCalculation = false;

        hasFinalCalculation();
        checkSession();
        $location.search('employeeId', vbmData.employeeId);
        check();
      };

      $scope.chooseTargetDialog = () => {
        ngDialog.open({
          template: berater_target_dialog_html(),
          plain: true,
          className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
          controller: 'NeoBeraterTargetDialogController',
          data: {
            changeEmployee: $scope.changeEmployee
          }
        });
      };

      $scope.goToCustomer = () => {
        $state.go('inApp.cockpit.arbeitnehmerverwaltung', {customerId: $scope.employee.data.person.customer_id});
      };

      $scope.hasComponents = () => (vbmData.hasComponents() || vbmData.hasBAV());

      $scope.isSelfservice = () => $scope.stateVerguetung || $scope.stateModule || /^inApp.neo.side.module/.test($state.current.name);

      $scope.is1to1Selfservice = () => {
        if (!$scope.isSelfservice()) {
          return false;
        }
        if ($scope.employee.data && $scope.employee.data && $scope.employee.data.parameters) {
          return 'NONE' == $scope.employee.data.parameters.einsparungs_modell;
        }
      };

      $scope.hasNeoBikeleasings = () => {
        return $scope.neo_bikeleasings && $scope.neo_bikeleasings.length > 0;
      };

      $scope.hasNeoComponents = () => {
        return $scope.neo_components && $scope.neo_components.length > 0;
      };

      $scope.hasActiveBaskets = () => {
        return $scope.active_baskets && $scope.active_baskets.length > 0;
      };

      $scope.is1to1orPositive = () => {
        if (!$scope.isSelfservice()) {
          return true;
        }
        let e = $scope.employee;
        if (e.data && e.data.parameters) {
          if ('NONE' === e.data.parameters.einsparungs_modell) {
            return true;
          } else {
            return e.result.final && e.result.final.isEntgeltverzichtet && (e.result.final.tax.net_netto + e.result.final.tax.net_jahr > e.result.before.tax.net_netto);
          }
        }
        return false;
      };


      $scope.calculateZielausgleich = () => {
        $scope.zielausgleichCalculating = true;
        $scope.zielausgleichError = false;
        calcModifiedValues()
          .then(() => vbmData.calculateAdditionalEntgeltverzicht())
          .then(() => vbmData.save(vbmData.employeeId), error => {
            if (
              'object' === typeof error.error &&
              'object' === typeof error.error.message &&
              1000 === error.error.message.error_code
            ) {
              $scope.zielausgleichError = true;
            }
            NotificationService.default.checkoutSession(error);
          }).finally(() => {
          $scope.zielausgleichCalculating = false;
          $scope.employee.result.final.isEntgeltverzichtet = true;
        });
      };

      $scope.getDocument = reportId => {

        $scope.documentLoading = true;

        delete $scope.documents[reportId];

        vbmData
          .getDocument(reportId)
          .then(url => {
            $scope.documents[reportId] = url;
            window.open(url, '_blank');
          })
          .finally(() => {
            $scope.documentLoading = false;
          });
      };

      $scope.removeComponent = async key => {

        if (key !== 'pc') {
          vbmData.employees[vbmData.employeeId].data.neo_components[key].enabled = false;

        } else {
          let result = {
            'multiple': false
          };
          let customerConfig = await customerService.getConfigurationByEmployeeId(vbmData.employeeId);
          if (customerConfig.neo_config.pc.useInternalMPP) {
            result = await mppService.hasMultipleBaskets(vbmData.employeeId).then(result => {
              return result;
            });
          }

          if (result.multiple) {
            vbmData.employees[vbmData.employeeId].data.neo_components['pc'].value = result.total - result.current;
          } else {
            vbmData.employees[vbmData.employeeId].data.neo_components[key].enabled = false;
          }
        }

        if ($scope.stateVerguetung || $scope.stateModule) {
          $scope.workingComponents = true;

          $scope
            .save()
            .finally(() => $scope.workingComponents = false);
        }

      };

      $scope.removeBAV = () => {

        $scope.employee.data.insurances.bav.beitrag = 0;

        if ($scope.stateVerguetung || $scope.stateModule) {
          $scope.save();
        }
      };

      $scope.enterBrutto = () => dialogService.employeeBasics();

      $scope.startQuiz = () => basicInfoQuiz.start();


      $scope.save = () => {
        $scope.saving = true;

        return calcModifiedValues()
          .finally(() => {

            $scope.employee.plausibilites = PlausibilityService.check($scope.employee);

            return vbmData
              .save(vbmData.employeeId)
              .then(() => {
              }, error => NotificationService.default.checkoutSession(error));
          })
          .finally(() => {
            $scope.saving = false;
            $scope.employee.plausibilites = PlausibilityService.check($scope.employee);
          });
      };

      $scope.downloadBalance = () => {
        console.log($scope.employee);
        return vbmService.generateBalanceData($scope.employee).then(
          (res) => {
            $window.open(res, '_blank');
          }).catch((err) => {
          console.error(err);
        });
      }

      $scope.downloadProtocol = () => {
        console.log($scope.employee);
        return vbmService.generateSalaryComparisonData($scope.employee).then(
          (res) => {
            $window.open(res, '_blank');
          }).catch((err) => {
          console.error(err);
        });
      }

      $scope.showProtocol = () => {
        ngDialog.open({
          template: protocol_dialog_html(),
          plain: true,
          className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
          data: $scope.employee.result
        });
      };

      $scope.arbeitnehmerAnlegen = () => dialogService.employeeCreate();

      $scope.getLatestFinal = () => vbmData.trigger(true);

      $scope.checkout = async () => {
        let canCheckout = true,
          compMpp = vbmData.employees[vbmData.employeeId].data.neo_components['pc'];

        let customerConfig = await customerService.getConfigurationByEmployeeId(vbmData.employeeId);
        $scope.mpp = customerConfig.neo_config.pc;

        // Step 2 MPP - wenn limits gepflegt werden
        if (compMpp.enabled && $scope.mpp.useInternalMPP) {
          await mppService.getCart(vbmData.employeeId).then(cart => {
            canCheckout = $scope.mpp.leasinglimit > ($scope.mpp.usedlimit + cart.sum);
            $scope.limitexceeded = canCheckout;
          });
        }

        // await vbmData.isCalculationFinalizationAllowed({
        //   employee_id: vbmData.employeeId,
        //   // calculation_id: vbmData.employees[vbmData.employeeId].data.metadata.id
        // }).then((validation_result) => {
        //     canCheckout = validation_result['success'];
        //     if(!canCheckout) {
        //         AlertService.error('Der Warenkorb enth�lt kein mobiles Endger�t'); //@TODO Toni to gave us german message
        //     }
        // });

        if (canCheckout) {
          if ($scope.notMe) {
            $state.go('inApp.neo.side.checkout.zusammenfassung', {
              employeeId: vbmData.employeeId
            });
          } else {
            $state.go('inApp.neo.side.checkout.zusammenfassung');
          }
        }
      };

      $scope.contact = () => dialogService.contactAdvisor();

      $scope.enterProxyMode = () => {
        const context_path = '/berater?employeeId=' + $scope.employee.data.person.id;
        dialogService.question(`Achtung, der Nutzer wird über diesen Vorgang informiert.
        Wollen Sie wirklich in ${$scope.employee.data.person.lastname}, ${$scope.employee.data.person.firstname}
        (${$scope.employee.data.person.id}) schlüpfen?`)
          .then(() => userService.enterProxy($scope.employee.data.person.id, context_path), angular.noop)
          .catch(() => NotificationService.error('Bitte stellen Sie sicher, dass der Nutzer ins Portal eingeladen wurde.'));
      }

      $scope.calcOver = () => vbmService.applyConfig($scope.employee.data).then(result => {
        vbmData.setAlternateData(vbmData.employeeId, result.params, $scope.neoProject);
        NotificationService
          .message(
            'Die Projekteinstellungen wurden übernommen.'
          );

      });

      $scope.saveCalculation = () => {
        MatDialogService.open(
          NeoCalculationBookmarkDialogComponent
        )
      };

      $scope.openSavedCalculations = () => {
        $state.go('inApp.meineakte.calculations');
      };
    }]);
