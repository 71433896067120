import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EMPTY } from 'rxjs';
import { ProjectActionsDialogData } from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import {LoadingService} from "../../../../shared/services/loading.service";

@Component({
  selector: 'vn-direct-bonus-delete-dialog',
  templateUrl: './direct-bonus-delete-dialog.component.html',
  styleUrls: ['./direct-bonus-delete-dialog.component.scss']
})
export class DirectBonusDeleteDialogComponent implements OnInit {

  public get bonusId(): string {
    return this.dialogData.bonusId!;
  }

  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public get period(): string {
    return this.dialogData.period!;
  }

  public get employeeId(): number {
    return this.dialogData.employeeId!;
  }

  public get componentType() {
    return this.dialogData.componentType!;
  }

  public get employeeName(): string {
    return this.dialogData.employeeName!;
  }

  constructor(
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private loader: LoadingService,
    public dialogRef: MatDialogRef<DirectBonusDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
  ) {
  }

  ngOnInit(): void { }

  public removeBonusFromOrder() {
    this.loader.show();
    return this.directBonusService.removeBonusFromOrder(this.bonusId, this.orderId)
      .subscribe(
        (result) => {
          this.loader.hide()
          if (result) {
            this.alertService.message('Der Bonus wurde erfolgreich gelöscht.');

            this.dialogRef.close(true);
          }
        },
        (error) => {
          this.loader.hide();
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);

          return EMPTY;
        }
      )
  }
}
