
const $inject = [
  '$state', '$transitions', 'authenticationService'
]
export default class mailChangeController {

  constructor( $state, $transitions, authenticationService ) {

    Object.assign( this, {
      $state,
      $transitions,
      authenticationService
    } );

    authenticationService.activateNewMail($state.params.token)
      .then(() => {
      }, response => {
        let message = response.error.message;

        this.noUserError = false;
        this.expiredError = false;
        this.saveError = false;

        if (message.includes('No user with')) {
          this.noUserError = true;
        } else if ( message.includes( 'Token expired' ) ) {
          this.expiredError = true;
        } else {
          this.saveError = true;
        }
      });
  }

  logout() {
    this.authenticationService.logout();
    this.$state.go('blank.cidaas_login');
  }
}
mailChangeController.$inject = $inject;

