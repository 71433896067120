import {DEFAULT_BONUS_DURATION} from "./bonus_duration";
import {DEFAULT_BONUS_TYPE} from "./bonus_type";
import {DEFAULT_BONUS_EXPIRATION_ACTION} from "./bonus_expiration_action";

/**
 * Bonus configuration
 * */
export const DEFAULT_BONUS_CONFIG = {
  bonus_project: undefined,
  duration: DEFAULT_BONUS_DURATION['id'],
  employees: undefined,
  budget: undefined,
  dates: {
    bonus_start_date: undefined,
    bonus_end_date: undefined,
    user_decision_date: undefined,
    bonus_publish_date: undefined
  },
  bonus_type: DEFAULT_BONUS_TYPE['id'],
  expiration_action: DEFAULT_BONUS_EXPIRATION_ACTION['id']
};
