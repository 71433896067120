import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { UIRouterUpgradeModule } from "@uirouter/angular-hybrid";
import { directBonusStatesModule } from "./direct-bonus.states";

import { DirectBonusService } from "./services/direct-bonus.service";
import { DirectBonusProjectOverviewMapper } from "./models/direct-bonus-project-overview.model";
import { DirectProjectOverviewBonusTableDataRowMapper } from "./models/direct-bonus-project-overview-table-data.model";
import { CustomerOrderMapper, CustomerOrderTableDataBOMapper, CustomerOrderTableDataMapper } from "./models/direct-bonus-customer-order.model";
import { DirectBonusMapper, DirectBonusProjectComponentConfigurationMapper } from "./models/direct-bonus-data.model";

import { BackofficeAdministrationComponent } from './components/backoffice/backoffice-administration/backoffice-administration.component';
import { BonusOrderTableComponent } from './components/backoffice/bonus-order-table/bonus-order-table.component';
import { BonusOrderEmployeesTableComponent } from './components/backoffice/bonus-order-employees-table/bonus-order-employees-table.component';
import { DirectBonusPayDialogComponent } from './components/backoffice/direct-bonus-pay-dialog/direct-bonus-pay-dialog.component';
import { DirectBonusUnpaidDialogComponent } from './components/backoffice/direct-bonus-unpaid-dialog/direct-bonus-unpaid-dialog.component';
import { DirectBonusInvoiceDialogComponent } from './components/backoffice/direct-bonus-invoice-dialog/direct-bonus-invoice-dialog.component';
import { DirectBonusCancelDialogComponent } from './components/backoffice/direct-bonus-cancel-dialog/direct-bonus-cancel-dialog.component';
import { CustomerAdministrationNewComponent } from './components/customer/customer-administration-new/customer-administration-new.component';
import { CustomerProjectOverviewTableComponent } from "./components/customer/customer-project-overview-table/customer-project-overview-table.component";
import { CustomerDownloadTableDialogComponent } from "./components/customer/customer-download-table-dialog/customer-download-table-dialog.component";
import { CustomerBulkUploadDialogComponent } from "./components/customer/customer-bulk-upload-dialog/customer-bulk-upload-dialog.component";
import { DirectBonusConfirmDialogComponent } from "./components/customer/direct-bonus-confirm-dialog/direct-bonus-confirm-dialog.component";
import { DirectBonusRevertDialogComponent } from './components/customer/direct-bonus-revert-dialog/direct-bonus-revert-dialog.component';
import { DirectBonusDoneDialogComponent } from './components/backoffice/direct-bonus-done-dialog/direct-bonus-done-dialog.component';
import { DirectBonusEditDialogComponent } from './components/backoffice/direct-bonus-edit-dialog/direct-bonus-edit-dialog.component';
import { DirectBonusDeleteDialogComponent } from './components/backoffice/direct-bonus-delete-dialog/direct-bonus-delete-dialog.component';
import { DirectBonusCreateDialogComponent } from "./components/customer/direct-bonus-create-dialog/direct-bonus-create-dialog.component";
import { DirectBonusOnHoldDialogComponent } from "./components/backoffice/direct-bonus-on-hold-dialog/direct-bonus-on-hold-dialog.component";
import { DirectBonusOffHoldDialogComponent } from "./components/backoffice/direct-bonus-off-hold-dialog/direct-bonus-off-hold-dialog.component";
import { BenefitsAccountComponent } from './components/benefits/benefits-account/benefits-account.component';
import { BenefitCardComponent } from './components/benefits/benefit-card/benefit-card.component';
import { BenefitsCatalogComponent } from './components/benefits/benefits-catalog/benefits-catalog.component';
import { BenefitDetailsComponent } from './components/benefits/benefit-details/benefit-details.component';
import { BenefitBasketSheetComponent } from './components/benefits/benefit-basket-sheet/benefit-basket-sheet.component';
import { BenefitsMapComponent } from './components/benefits/benefits-map/benefits-map.component';
import { BenefitsBonagoService } from "./services/benefits-bonago.service";
import { BenefitsBonagoMapper } from "./services/benefits-bonago.mapper";
import { BenefitsBonagoBasketService } from "./services/benefits-bonago-basket.service";
import { BenefitsToolbarComponent } from './components/benefits/benefits-toolbar/benefits-toolbar.component';
import { BenefitMapService } from "./services/benefit-map.service";
import { BenefitsHomeComponent } from './components/benefits/benefits-home/benefits-home.component';
import { BenefitsBonagoController } from "./services/benefits-bonago.controller";
import { BenefitCheckoutComponent } from './components/benefits/benefit-checkout/benefit-checkout.component';
import { BenefitCheckoutTableComponent } from './components/benefits/benefit-checkout-table/benefit-checkout-table.component';
import { DirectBonusAddDialogComponent } from "./components/backoffice/direct-bonus-add-dialog/direct-bonus-add-dialog.component";
import { DirectBonusBulkInvoiceDialogComponent } from "./components/backoffice/direct-bonus-bulk-invoice-dialog/direct-bonus-bulk-invoice-dialog.component";
import { BonusOrderPaymentTypeDialogComponent } from './components/backoffice/bonus-order-payment-type-dialog/bonus-order-payment-type-dialog.component';
import { BonusOrderExtendDeadlineDialogComponent } from './components/backoffice/bonus-order-extend-deadline-dialog/bonus-order-extend-deadline-dialog.component';
import { CustomerBulkAddUsersDialogComponent } from './components/customer/customer-bulk-add-users-dialog/customer-bulk-add-users-dialog.component';
import { DirectBonusBulkCreateUsersErrorsComponent } from "../shared/services/custom-components/direct-bonus-bulk-create-users-errors.component";
import { DirectBonusMapperService } from './services/direct-bonus.mapper';
import { BonagoTocComponent } from './components/benefits/bonago-toc/bonago-toc.component';
import { DirectBonusBulkPaidDialogComponent } from "./components/backoffice/direct-bonus-bulk-paid-dialog/direct-bonus-bulk-paid-dialog.component";
import { DirectBonusBulkDoneDialogComponent } from './components/backoffice/direct-bonus-bulk-done-dialog/direct-bonus-bulk-done-dialog.component';
import { BenefitToolbarEventService } from "./services/benefit-toolbar-event.service";
import { DirectBonusCustomerOverviewComponent } from './components/customer/direct-bonus-customer-overview/direct-bonus-customer-overview.component';
import { DirectBonusCustomerBonusTableComponent } from './components/customer/direct-bonus-customer-bonus-table/direct-bonus-customer-bonus-table.component';
import { DirectBonusCustomerBonusEmployeeTableComponent } from './components/customer/direct-bonus-customer-bonus-employee-table/direct-bonus-customer-bonus-employee-table.component';
import { CustomerCreateNewOrderDialogComponent } from './components/customer/customer-create-new-order-dialog/customer-create-new-order-dialog.component';
import { CustomerCreateExtraOrderDialogComponent } from './components/customer/customer-create-extra-order-dialog/customer-create-extra-order-dialog.component';
import { DirectBonusErrorHandler } from "./services/errors/direct-bonus-error-handler";
import { FormElementsModule } from "../form-elements/form-element.module";


const mappers = [
  DirectBonusProjectOverviewMapper,
  DirectProjectOverviewBonusTableDataRowMapper,
  CustomerOrderMapper,
  CustomerOrderTableDataBOMapper,
  CustomerOrderTableDataMapper,
  DirectBonusMapper,
  DirectBonusProjectComponentConfigurationMapper,
];

const services = [
  DirectBonusService,
  BenefitsBonagoService,
  BenefitsBonagoBasketService,
  BenefitsBonagoMapper,
  BenefitsBonagoController,
  BenefitMapService,
  BenefitToolbarEventService,
  DirectBonusMapperService,
  DirectBonusErrorHandler
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormElementsModule,
    UIRouterUpgradeModule.forChild(directBonusStatesModule),
  ],
  declarations: [
    CustomerAdministrationNewComponent,
    DirectBonusCreateDialogComponent,
    DirectBonusConfirmDialogComponent,
    CustomerProjectOverviewTableComponent,
    BackofficeAdministrationComponent,
    BonusOrderTableComponent,
    BonusOrderEmployeesTableComponent,
    DirectBonusRevertDialogComponent,
    CustomerBulkUploadDialogComponent,
    DirectBonusBulkInvoiceDialogComponent,
    DirectBonusBulkPaidDialogComponent,
    BonusOrderPaymentTypeDialogComponent,
    CustomerDownloadTableDialogComponent,
    DirectBonusPayDialogComponent,
    DirectBonusUnpaidDialogComponent,
    DirectBonusInvoiceDialogComponent,
    DirectBonusCancelDialogComponent,
    DirectBonusDoneDialogComponent,
    DirectBonusEditDialogComponent,
    DirectBonusDeleteDialogComponent,
    DirectBonusAddDialogComponent,
    DirectBonusOnHoldDialogComponent,
    DirectBonusOffHoldDialogComponent,
    BenefitsAccountComponent,
    BenefitCardComponent,
    BenefitsCatalogComponent,
    BenefitDetailsComponent,
    BenefitBasketSheetComponent,
    BenefitsMapComponent,
    BenefitsToolbarComponent,
    BenefitsHomeComponent,
    BenefitCheckoutComponent,
    BenefitCheckoutTableComponent,
    BonusOrderExtendDeadlineDialogComponent,
    BonagoTocComponent,
    CustomerBulkAddUsersDialogComponent,
    DirectBonusBulkCreateUsersErrorsComponent,
    DirectBonusBulkDoneDialogComponent,
    DirectBonusCustomerBonusTableComponent,
    DirectBonusCustomerBonusEmployeeTableComponent,
    CustomerCreateNewOrderDialogComponent,
    DirectBonusCustomerOverviewComponent,
    CustomerCreateExtraOrderDialogComponent
  ],
  exports: [

  ],
  providers: [
    ...services,
    ...mappers
  ]
})
class DirectBonusModule { }

export { DirectBonusModule };

/////////////////////////////// CONSTANTS \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

// ===============================================================

export interface MonthDisplay {
  id: number;
  label: string;
  displayColumnName: string
}

export const DIRECT_BONUS_TABLE_MONTHS: MonthDisplay[] = [
  { 'id': 1, 'label': 'Jan', 'displayColumnName': 'monthJan' },
  { 'id': 2, 'label': 'Feb', 'displayColumnName': 'monthFeb' },
  { 'id': 3, 'label': 'Mär', 'displayColumnName': 'monthMar' },
  { 'id': 4, 'label': 'Apr', 'displayColumnName': 'monthAp' },
  { 'id': 5, 'label': 'Mai', 'displayColumnName': 'monthMay' },
  { 'id': 6, 'label': 'Jun', 'displayColumnName': 'monthJun' },
  { 'id': 7, 'label': 'Jul', 'displayColumnName': 'monthJul' },
  { 'id': 8, 'label': 'Aug', 'displayColumnName': 'monthAug' },
  { 'id': 9, 'label': 'Sep', 'displayColumnName': 'monthSep' },
  { 'id': 10, 'label': 'Okt', 'displayColumnName': 'monthOct' },
  { 'id': 11, 'label': 'Nov', 'displayColumnName': 'monthNov' },
  { 'id': 12, 'label': 'Dez', 'displayColumnName': 'monthDec' }
]

// ===============================================================

/////////////////////////////// END OF CONSTANTS \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
