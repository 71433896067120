const $inject = [
  '$scope',
  'BenefitBonagoService',
  '$timeout'
];

class BonagoKategorieMenuController {
  constructor(
    $scope,
    BenefitBonagoService,
    $timeout
  ) {
    Object.assign(this, {
      $scope,
      BenefitBonagoService,
      $timeout
    });

    this.loading = {
      main: false,
      categories: false
    };

    this.init();
  }

  $onDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async setCategories() {
    this.$timeout(() => {
      this.loading.categories = true;
    });


    return this.BenefitBonagoService
      .getCategoriesBySchemaId()
      .then((categories) => {
        this.categories = categories;
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.categories = false;
        });
      });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    // link controller to change of scheme
    this.subscriptions = [
      this.BenefitBonagoService.communication.subscribe({
        next: async (msg) => {
          if (msg === 'user_selected_scheme_update') {
            await this.setCategories();
          }
        }
      })
    ];

    // set the categories
    await this.setCategories();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

}

BonagoKategorieMenuController.$inject = $inject;
angular
  .module('VSPApp')
  .controller('bonagoKategorieMenuController', BonagoKategorieMenuController);
