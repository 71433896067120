export class BasketArticle {

  constructor(id, name, grossPrice, netPrice) {
    this._articleNo = id;
    this._name = name;
    this._grossPrice = grossPrice;
    this._netPrice = netPrice;
  }

  /**
   * @returns BasketArticle
   */
  copy() {
    return new BasketArticle(this.articleNo, this.name, this.grossPrice, this.netPrice);
  }
  
  // #COMMENT_LEX: This information should be provided by the backend as part of the article data.
  static isMainArticleNo(articleNo) {
    //VN-1583
    for (let code of [ '10', '11', '15', '1K','1A', '1C', '1H', 'A4']) {
      if (articleNo.startsWith(code)) {
        return true;
      }
    }
    return false;
  }

  get articleNo() {
    return this._articleNo;
  }
  
  /**
   * @deprecated use articleNo instead
   */
  get id() {
    return this._articleNo;
  }

  get name() {
    return this._name;
  }

  get grossPrice() {
    return this._grossPrice;
  }

  get netPrice() {
    return this._netPrice;
  }

  get isMainArticle() {
    return BasketArticle.isMainArticleNo(this.articleNo);
  }

  /**
   * @param {BasketArticle} other
   * @returns {boolean}
   */
  equals(other) {
    return this.articleNo === other.articleNo
      && this.name === other.name
      && this.grossPrice === other.grossPrice
      && this.netPrice === other.netPrice;
  }
  
}
