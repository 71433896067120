import {EINMAL_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS} from "./bonus/einmal_bonus_project_component_config_fields";
import {DEFAULT_FID_EMPLOYEE_BBG, FID_EMPLOYEE_BBG_BY_VALUE} from "./bonus/fid_employee_bbg";
import {BONUS_PROJECT_TYPES_BY_NAME} from "./bonus/bonus_project_types";
import {TAX_PAYERS_BY_NAME} from "./bonus/tax_payer";
import {FIDUCIA_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS} from "./bonus/fiducia_bonus_project_compoent_config_fields";
import {DIRECT_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS} from "./bonus/direct_bonus_project_component_config_fields";
import {BGM_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS} from "./bonus/bgm_bonus_project_component_config_fields";
import {BONUS_PAYMENT_TYPES_BY_NAME} from "./bonus/bonus_payment_type";
import {BONUS_STATES_BY_NAME} from "./bonus/bonus_state";
import {DEFAULT_BONUS_TYPE} from "./bonus/bonus_type";
import {DEFAULT_BONUS_EXPIRATION_ACTION} from "./bonus/bonus_expiration_action";
import {
  DEFAULT_DIRECT_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION,
  DEFAULT_FIDUCIA_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION,
  DEFAULT_ONE_TIME_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION
} from "./bonus/employee_information_configuration";
import {DEFAULT_BONUS_CONFIG} from "./bonus/default_bonus_config";
import {CHECKOUT_TYPES, CHECKOUT_TYPES_BY_NAME} from "./bonus/checkout_types";
import {DEFAULT_FID_EMPLOYEE_TYPE, FID_EMPLOYEE_TYPE_BY_NAME} from "./bonus/fid_employee_type";

export const MONTHS_IN_YEAR = 12;
export const WORK_DAYS_IN_WEEK = 5;

export const DEFAULT_KIND = {
  birthdate: undefined,
  cost: 0
};

export const BONUS_COMPONENTS_WITHOUT_ADDITIONAL_SELECTION = ['VWL', 'Bikeleasing', 'bike_1', 'wertguthaben'];

export const BONUSES_WITH_EDITABLE_BEGIN_DATE = ['Bikeleasing', 'bike_1', 'pc', 'pc_1'];

export const DEFAULT_BONUS_PAYMENT_TYPE = BONUS_PAYMENT_TYPES_BY_NAME['budget'];

export const BONUSES_WITH_CONTRACT_END_DATE = ['Bikeleasing', 'bike_1', 'pc', 'pc_1'];

export const BIKELEASING_BONUS_NAMES = ['Bikeleasing', 'bike_1'];
export const PC_BONUS_COMPONENT_NAMES = ['pc', 'pc_1'];

export const BONUS_CHECKOUT_TOC_CONFIG = {
  sachbezug_profit: {
    componentDisplay: "Fitnesskonto",
    metadata: [{
      data: "Anrede, Vorname, Name, E-Mail-Adresse",
      processor: "proFIT GmbH — fit statt fertig, Freiheitstr. 11, 40699 Erkrath",
      reason: "Anbieter Fitnesskonto"
    }]
  },
  sachbezug_pluxee: {
    componentDisplay: "Benefits Card",
    metadata: [{
      data: "Anrede, Vorname, Name, Adressdaten, E-Mail-Adresse",
      processor: "Pluxee Deutschland GmbH, Lyoner Straße 9, 60528 Frankfurt am Main",
      reason: "Bereitstellung Benefits Card"
    }]
  },
  sachbezug:
    {
      componentDisplay: "Vorteilskonto",
      metadata: [{
        data: "Vorname, Name",
        processor: "BONAGO Incentive Marketing Group GmbH, Werinherstraße 91, 81541 München",
        reason: "Betreiber Vorteilskonto"
      }]
    },
  essensschecks: {
    componentDisplay: "Essensschecks",
    metadata: [{
      data: "Vorname, Name, E-Mail-Adresse",
      processor: "Pluxee Deutschland GmbH, Lyoner Straße 9, 60528 Frankfurt am Main",
      reason: "Anbieter Essensschecks"
    }]
  },
  // essensschecks_digital: {
  //   componentDisplay: "Digitale Essensschecks",
  //   metadata: [{
  //     data: "Vorname, Name, E-Mail-Adresse",
  //     processor: "Hrmony GmbH, Alexandrinenstraße 2-3, 10969 Berlin",
  //     reason: "Anbieter Fitneskonto"
  //   }]
  // },
  pc_leasing: {
    componentDisplay: "PC Leasing",
    metadata: [{
      data: "Vorname, Name, Adressdaten, Bestelldaten",
      processor: "Cyberport GmbH, Am Brauhaus 5, 01099 Dresden",
      reason: "Distributor"
    }, {
      data: "Vorname, Name, Adressdaten, Bestelldaten",
      processor: "MLF Mercator-Leasing GmbH & Co. Finanz-KG, Londonstr. 1, 97424 Schweinfurt",
      reason: "Leasinggeber"
    }]
  },
  //[handy, festnetz]
  telekom: {
    componentDisplay: "Telekommunikation",
    metadata: [{
      data: "Vorname, Name, Adressdaten",
      processor: "Deutsche Post AG, Charles-de-Gaulle-Straße 20, 53113 Bonn",
      reason: "Auslieferung Hardware"
    }]
  },
  dticket: {
    componentDisplay: "DeutschlandTicket JOB",
    metadata: [{
      data: "Vorname, Name, Geburtsdatum",
      processor: "Vesputi GmbH, Spinnereistraße 7, 04179 Leipzig",
      reason: "Legitimation"
    }]
  },
}

export const CASHBACK_COMPONENT_NAMES = ['jobticket', 'kinder', 'bgmbudget', 'egeschenk'];

export const BONUS_COMPONENTS_WITHOUT_CONTRACT_PREVIEW = ['Freistellungstag']

export const DEFAULT_BONUS_PROJECT_HEADLINE = 'Informieren Sie sich über die möglichen Wahlleistungen.';

const $inject = [
  'Made',
  'moment',
  '$q',
  'lodash',
  'customerService',
  '$filter',
  'authenticationService',
  'employeeService',
  '$timeout',
  'userService',
  'CommonService'
];
export default class BonusService {

  constructor(
    Made,
    moment,
    $q,
    lodash,
    customerService,
    $filter,
    authenticationService,
    employeeService,
    $timeout,
    userService,
    CommonService,
  ) {
    Object.assign(this, {
      Made,
      moment,
      $q,
      lodash,
      customerService,
      authenticationService,
      $filter,
      employeeService,
      userService,
      $timeout,
      CommonService,
    });

    // set in the bonusSideController and is used to sync all components with the calculation of the rest_bonus
    this.calculation_promise = null;

    // cache
    this.components = [];
    this.managements = [];
    this.sachbezug_wert = undefined;

  }

  async fix_future_data() {
    return this.Made.request('rpc://valuenetdb/bonus/project/fix_future_data');
  }

  async checkRestbonusesForFid(options) {
    let params = {
      for_upcoming: options.for_upcoming
    };
    return this.Made.request('rpc://valuenetdb/bonus/project/check_restbonuses_for_fid', params);
  }

  async setIsAllowedToSetCustomCheckoutDate(options) {
    let params = {
      bonus_project_id: options.bonus_project_id
    };

    return this.Made.request('rpc://valuenetdb/bonus/project/is_allowed_to_set_custom_checkout_date', params)
      .then((is_allowed) => {
        this.can_set_custom_checkout_date = is_allowed;
      });
  }

  set checkout_date(date) {
    this.$timeout(() => {
      this._checkout_date = date;
    });
  }

  get checkout_date() {
    return this._checkout_date;
  }

  getUserChosenBonusPaymentType(type) {
    return BONUS_PAYMENT_TYPES_BY_NAME[type];
  }

  get_telecommunication_bonuses_report(from_begin_date) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_telecommunication_bonuses_report', {bonus_start_date: from_begin_date});
  }

  is_ehe_allowed(steuer_klass) {
    return steuer_klass && (steuer_klass === 3 || steuer_klass === 4 || steuer_klass === 5);
  }

  resetCheckout(employee, bonus_project_id) {
    if (employee.bonus) {
      if (employee.bonus[bonus_project_id]) {
        employee.bonus[bonus_project_id].components = {};
      }
    }
  }

  calculateBudgetDisplay(employee_bonus_budget, display, bonus_payment_type_id) {
    if (!employee_bonus_budget) {
      return 0;
    }

    let sum = 0;
    display.for_bonuses_in_states.forEach(bonus_state_id => {
      sum += employee_bonus_budget[bonus_state_id][bonus_payment_type_id];
    });
    return sum;
  }

  async createCheckoutConfig(value, display, component_name, bonus_id, bonus_state, checkout_type = CHECKOUT_TYPES_BY_NAME['current'], bonus_start_date) {
    let checkout_config = {
      value: value,
      display: display,
      component_name: component_name,
      bonus_id: bonus_id,
      bonus_state: bonus_state,
      checkout_type: CHECKOUT_TYPES_BY_NAME['current']['name'],
      bonus_start_date: bonus_start_date
    };

    if (checkout_type.name === CHECKOUT_TYPES_BY_NAME['future']['name']) {
      Object.assign(checkout_config, {
        bonus_state: BONUS_STATES_BY_NAME['available']['id'],
        bonus_id: await this.getObjectId(),
        connected_bonus_id: checkout_config['bonus_id'],
        checkout_type: CHECKOUT_TYPES_BY_NAME['future']['name']
      });
    }

    if (this.can_set_custom_checkout_date) {
      Object.assign(checkout_config, {
        bonus_checkout_date: this.checkout_date
      });
    }

    return checkout_config;
  }

  addComponentToCheckout(employee, bonus_project_id, bonus_component_name, checkout_config, override_checkout = false, checkout_type = CHECKOUT_TYPES_BY_NAME['current']) {
    this.createBonusProjectCheckoutConfigurations(employee, bonus_project_id);

    let checkout_type_key = checkout_type['key'];

    if (!checkout_type_key) {
      throw 'wrong checkout type'
    }

    if (!employee.bonus[bonus_project_id][checkout_type_key][bonus_component_name] || override_checkout) {
      employee.bonus[bonus_project_id][checkout_type_key][bonus_component_name] = checkout_config;
    }

    if (checkout_type_key === CHECKOUT_TYPES_BY_NAME['previous']['key']) {
      let previous_checkouts_list_key = "previous_checkouts_by_bonus_id";

      if (!employee.bonus[bonus_project_id][previous_checkouts_list_key]) {
        employee.bonus[bonus_project_id][previous_checkouts_list_key] = {};
      }

      employee.bonus[bonus_project_id][previous_checkouts_list_key][checkout_config['bonus_id']] = checkout_config
    }

    return employee.bonus[bonus_project_id];
  }

  getComponentsCheckout(employee, bonus_project_id) {
    if (employee.bonus && employee.bonus[bonus_project_id]) {
      return employee.bonus[bonus_project_id].components;
    }

    return {};
  }

  getBonusValuesByEmployeeIds(employeeIds, bonusProjectId) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_employees_used_bonus', {
      bonus_project_id: bonusProjectId,
      employee_ids: employeeIds
    });
  }

  getRestBonus(employee_id, bonus_project_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_restbonus', {
      employee_id: employee_id,
      bonus_project_id: bonus_project_id
    });
  }
  getUsedAndRestBonusUsage(bonus_project_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_used_and_rest_bonus_usage', {
      bonus_project_id: bonus_project_id
    });
  }

  getComponentSum(employee_id, bonus_project_id, in_states_ids, managed_by_ids) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_components_sum', {
      employee_id: employee_id,
      bonus_project_id: bonus_project_id,
      in_states_ids: in_states_ids,
      managed_by_ids: managed_by_ids
    });
  }

  createSliderConfig(to, from, config = {}) {
    let step = to < 1 ? 0.01 : 1;
    let round = to < 1 ? 2 : 0;
    let labels = this.generateSliderLabels(to, from, {});
    return Object.assign(config, {
      to: to,
      from: from,
      step: step,
      round: round,
      modelLabels: labels
    });
  }

  recalculateSlidersModelLabels(sliders) {
    for (const slider_name in sliders) {
      let slider_config = sliders[slider_name];
      if (angular.isUndefined(slider_config['modelLabels'])) {
        let to = slider_config['to'];
        let from = slider_config['from'];
        let slider_model_labels = this.generateSliderLabels(to, from, {});
        slider_config['modelLabels'] = slider_model_labels;
      }
    }
  }

  generateSliderLabels(to, from, labelsObject, step = 0.01) {
    for (let i = from; i <= to; i = i + step) {
      i = this.roundNumber(i);
      // if ( i === to || i === from ) {
      //   labelsObject[i]=this.$filter('currency')(i);
      // } else {
      labelsObject[i] = i;
      // }
    }

    return labelsObject;
  }

  roundNumber(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  async calculatePCLeasing(employee_id, bonus_project, bonus_id, customer_id, checkout_date, checkout_type_name) {

    //those are required from BE , we should make those checks passed from outside not from inside the function
    if (!bonus_project || !bonus_id) {
      return;
    }

    let bonus_project_id = typeof bonus_project === 'object' && bonus_project !== null ? bonus_project._id : bonus_project;

    return this.Made.request('rpc://valuenetdb/bonus/project/calculate_pc_leasing_limits', {
      employee_id: employee_id,
      bonus_project_id: bonus_project_id,
      bonus_id: bonus_id,
      customer_id: customer_id,
      checkout_date: checkout_date,
      checkout_type_name: checkout_type_name
    });
  }

  async getOverlappingComponents(valuenet_id, employee_checkout_config, bonus_project_id) {
    let config = angular.copy(employee_checkout_config);
    delete config.optimization;
    return this.Made.request('rpc://valuenetdb/bonus/project/get_overlapping_components', {
      valuenet_id: valuenet_id,
      employee_checkout_config: config,
      bonus_project_id: bonus_project_id
    });
  }

  async completeCheckout(valuenet_id, employee_checkout_config, bonus_project_id) {
    let config = angular.copy(employee_checkout_config);
    delete config.optimization;
    return this.Made.request('rpc://valuenetdb/bonus/project/complete_checkout', {
      valuenet_id: valuenet_id,
      employee_checkout_config: config,
      bonus_project_id: bonus_project_id
    });
  }

  async getComponentsRequiringExtraDocuments() {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_components_requiring_extra_documents');
  }

  async isBonusComponentManagedByValuenet(component_name) {
    let valuenet_management = await this.getManagementByName('valuenet');
    let bonus_component = await this.getComponentByName(component_name);

    return bonus_component.managed_by === valuenet_management.id;
  }

  async getMaxValues(component_name, year) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_max_values', {
      component_name,
      year
    });
  }

  async getEmployeeFidInfo(valuenet_id, bonus_project_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_employee_fid_info', {
      valuenet_id: parseInt(valuenet_id)
    });
  }

  async checkoutBonusMppCart(options) {
    let params = {
      employee_id: options.employee_id,
      bonus_project_id: options.bonus_project_id,
      bonus_id: options.bonus_id,
      cart_id: options.cart_id,
      cart: options.cart,
      is_test: options.is_test,
      user_bonus_payment_type_choice_id: options.user_bonus_payment_type_choice_id,
      start_date: options.start_date,
      checkout_date: options.checkout_date,
      checkout_type_name: options.checkout_type_name
    };

    return this.Made.request('rpc://valuenetdb/bonus/project/checkout_cart_for_bonus', params);
  }

  getUserFidIsLeader() {
    let user = this.Made.user;
    return this.lodash.get(user, 'fid_info.employee.is_leader', false);
  }

  getUserFidInfoType() {
    let user = this.Made.user;
    let default_employee_type_name = DEFAULT_FID_EMPLOYEE_TYPE.name;
    let user_fid_type_name = this.lodash.get(user, 'fid_info.employee.type', default_employee_type_name);
    return FID_EMPLOYEE_TYPE_BY_NAME[user_fid_type_name];
  }

  unixToDate(unix_timestamp) {
    return this.moment.unix(unix_timestamp).toDate();
  }

  getComponentDisplayStartDate(bonus, offset = null, units = null) {
    if (!bonus) {
      return;
    }

    let component_start_date = this.unixToDate(bonus.dates.contract_start_date || bonus.dates.bonus_start_date);

    let bonus_component_name = this.getBonusComponentName(bonus);
    let component_bonus_start_date_string = this.lodash.get(bonus, `component.${bonus_component_name}.configuration.dates.bonus_start_date`);
    if (component_bonus_start_date_string) {
      component_start_date = this.moment.utc(component_bonus_start_date_string).toDate();
    }

    if (offset !== null && units !== null) {
      component_start_date = this.moment(component_start_date).add(offset, units).toDate();
    }

    return component_start_date;
  }

  getComponentDecisionDate(bonus, offset = null, units = null) {
    if (!bonus) {
      return;
    }

    let bonus_component_name = this.getBonusComponentName(bonus);

    let component_decision_date_string = this.lodash.get(bonus, `component.${bonus_component_name}.configuration.dates.user_decision_date`);

    if (component_decision_date_string) {
      let decision_date = this.moment.utc(component_decision_date_string);

      if (offset !== null && units !== null) {
        decision_date.add(offset, units);
      }

      return decision_date.toDate();
    }
  }

  getComponentDisplayCheckoutDate(bonus) {
    if (!bonus) {
      return;
    }

    let component_checkout_date = this.unixToDate(bonus.dates.checkout_date);

    let component_bonus_custom_checkout_date = this.lodash.get(bonus, 'checkout_config.bonus_checkout_date');
    if (component_bonus_custom_checkout_date) {
      if (angular.isNumber(component_bonus_custom_checkout_date)) {
        component_bonus_custom_checkout_date = this.moment.unix(component_bonus_custom_checkout_date).toDate();
      } else {
        component_bonus_custom_checkout_date = this.moment(component_bonus_custom_checkout_date).toDate();
      }
    }

    let result = {
      'checkout_date': component_checkout_date,
      'custom_checkout_date': component_bonus_custom_checkout_date
    };

    return result;
  }

  getComponentDisplayEndDate(bonus, offset = null, units = null) {
    if (!bonus) {
      return;
    }

    let component_end_date = this.unixToDate(bonus.dates.bonus_end_date);

    let bonus_component_name = this.getBonusComponentName(bonus);
    // check if the component is with contract end date
    if (BONUSES_WITH_CONTRACT_END_DATE.includes(bonus_component_name) && bonus.state !== BONUS_STATES_BY_NAME['cancelled']['id']) {
      component_end_date = this.unixToDate(bonus.dates.contract_end_date);
    }

    // check if the component has specific end date
    let component_bonus_end_date_string = this.lodash.get(bonus, `component.${bonus_component_name}.configuration.dates.bonus_end_date`);
    if (component_bonus_end_date_string) {
      component_end_date = this.moment.utc(component_bonus_end_date_string).toDate();
    }

    if (offset !== null && units !== null) {
      component_end_date = this.moment(component_end_date).add(offset, units).toDate();
    }

    // return general
    return component_end_date;
  }

  giveBikeLeasing(employee_id, bonus_project_id, bikeleasing_checkout_config) {
    return this.Made.request('rpc://valuenetdb/bonus/project/give_bikeleasing', {
      employee_id: employee_id,
      bonus_project_id: bonus_project_id,
      begin_date: bikeleasing_checkout_config.begin_date,
      monthly_amount: bikeleasing_checkout_config.monthly_amount,
      full_amount: bikeleasing_checkout_config.full_amount,
      validation_url: bikeleasing_checkout_config.validation_url,
      validation_code: bikeleasing_checkout_config.validation_code,
      ticket_number: bikeleasing_checkout_config.ticket_number,
      tax_base_amount: bikeleasing_checkout_config.tax_base_amount,
      contract_id: bikeleasing_checkout_config.contract_id
    }).then(bonus_id => {
      let FILE_TAGS = [
        'bonus',
        'bonus_project_id-' + bonus_project_id,
        'done',
        'doc_type-bikeleasing_contract',
        'bonus_component_name-Bikeleasing',
        'bonus_id-' + bonus_id,
        'backoffice_upload_timestamp-' + new Date().getTime() / 1000
      ];

      let contract = bikeleasing_checkout_config.file;
      return this.Made.upload(contract, FILE_TAGS, employee_id);
    });
  }

  isOverspendingAllowed(employee_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/is_overspending_allowed', {
      employee_id: employee_id
    });
  }

  getUserFidInfoBBG(employee_id) {
    return this.getEmployeeFidInfo(employee_id).then((fid_info) => {
      let user_fid_bbg_value = this.lodash.get(fid_info, 'employee.bgg', DEFAULT_FID_EMPLOYEE_BBG['value']);
      return FID_EMPLOYEE_BBG_BY_VALUE[user_fid_bbg_value];
    });
  }

  isBBGRelevantForComponent(bonus_component_name) {
    return BIKELEASING_BONUS_NAMES.includes(bonus_component_name) || PC_BONUS_COMPONENT_NAMES.includes(bonus_component_name);
  }

  is_additional_tax_enabled(component_budget_config, tax_type_id, payer_id = TAX_PAYERS_BY_NAME['employee']['id']) {

    let is_additional_tax_enabled = false;

    if (!component_budget_config) {
      return is_additional_tax_enabled;
    }

    for (const additional_tax_provider in component_budget_config.additional_costs) {
      if (!!tax_type_id && component_budget_config.additional_costs[additional_tax_provider].type !== tax_type_id) {
        continue;
      }

      if (!!payer_id && component_budget_config.additional_costs[additional_tax_provider].payer !== payer_id) {
        continue;
      }

      is_additional_tax_enabled = is_additional_tax_enabled || component_budget_config.additional_costs[additional_tax_provider].is_enabled;
    }
    return is_additional_tax_enabled;
  }

  async getSachbezugWert() {

    if (this.sachbezug_wert) {
      return this.$q.when(this.sachbezug_wert);
    }

    if (this.sachbezugWertPromise) {
      return this.sachbezugWertPromise;
    }

    this.sachbezugWertPromise = this.Made
      .request('rpc://valuenetdb/calculation/get_sachbezug_wert')
      .then((sachbezug_wert) => {
        this.sachbezug_wert = this.roundNumber(sachbezug_wert / 100);

        return this.sachbezug_wert;
      });

    this.sachbezugWertPromise.finally(() => {
      delete this.sachbezugWertPromise;
    });

    return this.sachbezugWertPromise;
  }

  get_additional_tax_value(component_budget_config, options = {}) {

    options = Object.assign({
      payer_id: undefined,
      tax_base_with_tax_value: undefined,
      tax_type_id: undefined,
      tax_base: undefined,
      employee_bonus_project_configuration: undefined,
      checkout_type: CHECKOUT_TYPES_BY_NAME['current'],
      bonus: undefined
    }, options);

    let result = {
      overall: 0
    };

    if (!component_budget_config) {
      return result;
    }

    for (const additional_tax_provider in component_budget_config.additional_costs) {
      let additional_tax_configuration = component_budget_config.additional_costs[additional_tax_provider];

      if (!angular.isUndefined(options.tax_type_id) && additional_tax_configuration.type !== options.tax_type_id) {
        continue;
      }

      if (this.lodash.get(options, 'payer_id', false) && component_budget_config.additional_costs[additional_tax_provider].payer !== options.payer_id) {
        continue;
      }

      if (additional_tax_configuration.is_enabled) {
        if (additional_tax_provider === 'valuenet_tax') {
          let tax_value = additional_tax_configuration.value
          let is_future_checkout = options.checkout_type['name'] === CHECKOUT_TYPES_BY_NAME['future']['name']
          let is_previous_checkout = this.lodash.get(options.bonus, 'validations.component.is_allowed_to_checkout.validations.is_bonus_contract_active_result.validations.is_bonus_expired');
          if (is_future_checkout || is_previous_checkout) {
            tax_value = 0
          }

          result[additional_tax_provider] = {
            tax_type_id: additional_tax_configuration.type,
            overall: tax_value
          };
          result.overall = result.overall + result[additional_tax_provider].overall;
        } else if (additional_tax_provider === 'flat_tax') {
          let additional_tax_percent = additional_tax_configuration.value; // enf - 15, essens+erh+internet - 25
          let church_tax_percent = this.employeeService.getKist();
          let is_church_tax_enabled = this.lodash.get(options, 'employee_bonus_project_configuration.employee_configuration.is_church_tax_enabled', false)
          let social_tax_percent = 0.055;

          let tax_base = options.tax_base;
          if (!angular.isUndefined(options.tax_base_with_tax_value)) {
            let total_percent = 1 / (1 + additional_tax_percent + (social_tax_percent * additional_tax_percent) + (is_church_tax_enabled ? (additional_tax_percent * church_tax_percent) : 0));
            tax_base = this.roundNumber(options.tax_base_with_tax_value * total_percent);
          }

          let additional_tax_value = this.roundNumber(tax_base * additional_tax_percent);
          let church_tax_value = is_church_tax_enabled ? this.roundNumber(additional_tax_value * church_tax_percent) : 0;
          let social_tax_value = this.roundNumber(additional_tax_value * social_tax_percent);
          result[additional_tax_provider] = {
            tax_base: tax_base,
            tax_type_id: additional_tax_configuration.type,
            additional_tax_value: additional_tax_value,
            church_tax_value: church_tax_value,
            social_tax_value: social_tax_value,
            overall: additional_tax_value + church_tax_value + social_tax_value
          };
          result.overall = result.overall + result[additional_tax_provider]['overall'];
        }
      }
    }
    return result;
  }

  async areComponentsUsed(employeeId, componentIds, bonusProjectId) {
    return this.Made.request('rpc://valuenetdb/bonus/project/are_components_used', {
      employee_id: employeeId,
      component_ids: componentIds,
      bonus_project_id: bonusProjectId
    });
  }

  async getBonusAvailableStartDate(bonus) {
    const bonus_next_available_checkout_date = this.lodash.get(bonus, 'validations.component.validations.bonus_next_available_checkout_date');
    if (bonus_next_available_checkout_date) {
      return this.moment.utc(bonus_next_available_checkout_date * 1000).toDate();
    }

    let parameters = Object.assign({}, {
      bonus_id: bonus._id,
      checkout_date: this.checkout_date
    });

    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_start_date', parameters)
      .then(bonus_start_date => {
        let result = this.moment.utc(bonus_start_date * 1000).toDate();
        return result;
      });
  }

  hasBonusProjectEnded(bonus_project) {
    return this.moment.utc(bonus_project.dates.bonus_project_end_date) < this.moment.utc();
  }

  toShowToUser(bonus_project) {
    if (this.hasBonusProjectEnded(bonus_project)) {
      return this.lodash.get(bonus_project, 'settings.is_visible_to_user_after_end', true);
    }
    return true;
  }

  /***
   * Calculates how many months are left till the end of the bonus_project.
   */
  calculateBonusProjectDurationInMonths(bonus_project) {
    // get the more recent date from bonus_start_date and today
    let today = this.moment(this.checkout_date) || this.moment();
    let bonus_project_start_date = this.moment(bonus_project.dates.bonus_project_start_date);
    let bonus_project_end_date = this.moment(bonus_project.dates.bonus_project_end_date);
    let bonus_project_has_started = false;

    // check if the bonus project has started
    if (this.moment(bonus_project.dates.bonus_project_start_date).isBefore(today)) {
      // bonus_project_start_date = today;
      bonus_project_has_started = true;
    }

    // calculate the difference
    let bonus_project_duration_in_months = bonus_project_end_date.diff(bonus_project_start_date, 'months');

    // if it has not started take into account an extra month in the beginning
    if (!bonus_project_has_started) {
      bonus_project_duration_in_months += 1;
    }

    return bonus_project_duration_in_months;
  }

  getBonusComponentName(bonus) {
    if (bonus && bonus.component) {
      return Object.keys(bonus.component)[0];
    }
  }

  getFidEmployeeTypeByName() {
    return FID_EMPLOYEE_TYPE_BY_NAME;
  }

  hasPreviousCheckouts(bonus) {
    let previous_checkouts = this.getPreviousCheckouts(bonus);
    return Object.keys(previous_checkouts).length;
  }

  getPreviousCheckouts(bonus) {
    if (bonus && bonus.previous_checkouts) {
      return bonus.previous_checkouts;
    }
    return {};
  }

  hasFutureCheckouts(bonus) {
    let future_checkouts = this.getFutureCheckouts(bonus);
    return Object.keys(future_checkouts).length;
  }

  getSachbezugCheckouts(bonus) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_sachbezug_checkouts', {bonus});
  }

  getFutureCheckouts(bonus) {
    if (bonus && bonus.future_checkouts) {
      return bonus.future_checkouts;
    }
    return {};
  }

  getAllCheckouts(bonus) {
    let previous_checkouts = Object.values(this.getPreviousCheckouts(bonus));
    let current_checkout = bonus;
    let future_checkouts = Object.values(this.getFutureCheckouts(bonus));

    return [
      ...previous_checkouts,
      current_checkout,
      ...future_checkouts
    ]
  }

  isDoingFutureCheckout(bonus) {
    let is_component_active = this.isComponentActive(bonus);
    return is_component_active;
  }

  isComponentExpired(bonus) {
    const is_component_expired = this.lodash.get(bonus, 'validations.component.is_allowed_to_checkout.validations.is_bonus_contract_active_result.validations.is_bonus_expired', false);
    const is_component_in_active_state = this.lodash.get(bonus, 'validations.component.is_allowed_to_checkout.validations.is_bonus_contract_active_result.validations.is_bonus_in_used_state', false);
    return is_component_expired && is_component_in_active_state;
  }

  isComponentActive(bonus) {
    const is_component_active = this.lodash.get(bonus, 'validations.component.is_allowed_to_checkout.validations.is_bonus_contract_active_result.overall', false);
    return is_component_active;
  }

  calculateFutureCheckoutsBudgetBonusesSum(bonuses) {
    if (!bonuses) {
      return 0;
    }

    bonuses = bonuses.filter(bonus => {
      return !!bonus.checkout_config;
    });

    return bonuses.reduce((acc, bonus) => {
      if (this.hasFutureCheckouts(bonus)) {
        const future_checkouts = this.getFutureCheckouts(bonus);
        for (const future_checkout_id in future_checkouts) {
          const future_checkout = future_checkouts[future_checkout_id];
          if (this.isPayedByBudget(future_checkout.checkout_config)) {
            acc += future_checkout.bonus_value;
          }
        }
      }

      return acc;
    }, 0);
  }

  isComponentEnded(bonus) {
    return this.isComponentExpired(bonus) || this.isComponentCancelled(bonus);
  }

  calculatePreviousCheckoutsBudgetBonusesSum(bonuses) {
    if (!bonuses) {
      return 0;
    }

    bonuses = bonuses.filter(bonus => {
      return !!bonus.checkout_config;
    });

    return bonuses.reduce((acc, bonus) => {
      if (this.isComponentExpired(bonus) || this.isComponentCancelled(bonus)) {
        if (this.isPayedByBudget(bonus.checkout_config)) {
          acc += bonus.bonus_value;
        }
      }

      if (this.hasPreviousCheckouts(bonus)) {
        const previous_checkouts = this.getPreviousCheckouts(bonus);
        for (const previous_checkout_id in previous_checkouts) {
          const previous_checkout = previous_checkouts[previous_checkout_id];
          if (this.isPayedByBudget(previous_checkout.checkout_config)) {
            acc += previous_checkout.bonus_value;
          }
        }
      }

      return acc;
    }, 0);
  }

  calculateBudgetBonusesSum(bonus_components = {}, filter = () => true) {
    let bonus_sum = 0;
    Object.keys(bonus_components)
      .forEach(component_key => {
        let bonus_component = bonus_components[component_key];
        if (this.isPayedByBudget(bonus_component)) {
          if (filter(bonus_component)) {
            bonus_sum += bonus_component.value;
          }
        }
      });
    return bonus_sum;
  }

  calculatePreviousBudgetBonusesSum(bonus_components = {}, filter = () => true) {
    let bonus_sum = 0;
    Object.keys(bonus_components)
      .forEach(component_key => {
        let bonus_component = bonus_components[component_key];
        if (this.isPayedByBudget(bonus_component)) {
          if (filter(bonus_component)) {
            bonus_sum += bonus_component.value;
          }
        }
      });
    return bonus_sum;
  }

  calculateExchangeBonusesSum(bonus_components = {}, filter = () => true) {
    let bonus_sum = 0;
    Object.keys(bonus_components)
      .forEach(component_key => {
        let bonus_component = bonus_components[component_key];
        if (this.isPayedByExchange(bonus_component)) {
          if (filter(bonus_component)) {
            bonus_sum += bonus_component.value;
          }
        }
      });
    return bonus_sum;
  }

  calculateRestBonus(bonus_project_employee_checkout_config, bonus_amount = 0) {
    let budget_bonuses_sum = 0;
    let printed_component = false;
    let printed_bonus = false;
    for (const checkout_type of CHECKOUT_TYPES) {
      if (checkout_type.key !== 'previous_checkouts') {
        let components = bonus_project_employee_checkout_config[checkout_type.key];
        budget_bonuses_sum += this.calculateBudgetBonusesSum(components);
        if (!printed_component) {
          printed_component = true;
        }
      } else {
        let bonuses = bonus_project_employee_checkout_config['previous_checkouts_by_bonus_id'];
        budget_bonuses_sum += this.calculateBudgetBonusesSum(bonuses);
        if (!printed_bonus) {
          printed_bonus = true;
        }
      }
    }

    return bonus_amount - budget_bonuses_sum;
  }


  isPayedByBudget(bonus_checkout_config) {
    return this.lodash.get(bonus_checkout_config, 'bonus_payment_type', DEFAULT_BONUS_PAYMENT_TYPE['id']) === BONUS_PAYMENT_TYPES_BY_NAME['budget']['id'];
  }

  isPayedByExchange(bonus_component_config) {
    return this.lodash.get(bonus_component_config, 'bonus_payment_type', DEFAULT_BONUS_PAYMENT_TYPE['id']) === BONUS_PAYMENT_TYPES_BY_NAME['exchange']['id'];
  }

  calculateRemainingWithoutCurrentBonus(component_name, bonus_project_checkout_configurations, budget, checkout_type) {
    let rest_bonus = this.calculateRestBonus(bonus_project_checkout_configurations, budget);
    let rest_without_current = rest_bonus + bonus_project_checkout_configurations[checkout_type.key][component_name].value;
    return this.roundNumber(rest_without_current);
  }

  // TODO: rename this to createBonusCheckoutObject
  createBonusProjectCheckoutConfigurations(employee, bonus_project_id) {
    if (!employee.bonus) {
      employee.bonus = {};
    }
    if (!employee.bonus[bonus_project_id]) {
      employee.bonus[bonus_project_id] = {
        session: this.createBonusCheckoutSession(),
      };

      CHECKOUT_TYPES.forEach(checkout_type => {
        Object.assign(employee.bonus[bonus_project_id], {
          [checkout_type.key]: {}
        })
      });

    }
  }

  /**
   * Creates a bonus checkout session. The ID and the completed_at are the same to allow easy showing in MeineAkte
   */
  createBonusCheckoutSession(options = {}) {
    let utc_now = this.moment.utc();
    return this.lodash.merge({}, {created_at: utc_now.unix(), completed_at: null, id: null}, options);
  }

  /**
   * Used to migrate old bonuses and attach a session to them
   * @param bonus_id
   * @param checkout_session
   * @returns {Promise<void>}
   */
  async attachSessionToBonuses(bonus_ids, checkout_session) {
    return this.Made.request('rpc://valuenetdb/bonus/project/attach_checkout_session_to_bonuses', {
      bonus_ids: bonus_ids, checkout_session: checkout_session
    });
  }

  /**
   * Used to migrate old bonus documents to new version
   * @param file_mongo_id
   * @param bonus_project_id
   * @param bonus_ids
   * @param checkout_session_id
   */
  updateBonusDocumentTags(file_mongo_id, bonus_project_id, bonus_ids, checkout_session_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/update_bonus_document_tags', {
      file_mongo_id: file_mongo_id,
      bonus_project_id: bonus_project_id,
      bonus_ids: bonus_ids,
      checkout_session_id: checkout_session_id
    });
  }

  async getFilesForBonuses(bonus_tags) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_files_for_bonuses', {
      bonus_tags
    });
  }

  async getObjectId() {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_object_id');
  }

  async test_sftp() {
    return this.Made.request('rpc://valuenetdb/fid_file_transfer/transfer_approved_bonuses_files')
  }

  async validate_pdfa() {
    return this.Made.request('rpc://valuenetdb/fid_file_transfer/validate_pdfa')
  }

  async uploadDocuments(components_requiring_documents, bonus_project_id, checkout_session_id) {
    let promises = [];
    let madeUserId = this.Made.user._id;
    let BONUS_TAGS = ['bonus', 'bonus_project_id-' + bonus_project_id, 'done', 'checkout_session_id-' + checkout_session_id, 'doc_type-checkout_user_upload_for_bonus'];
    angular.forEach(components_requiring_documents, (component_config, component_key) => {
      let component_tag = 'bonus_component_name-' + component_key;
      let bonus_id_tags = component_config.for_bonuses.map((bonus_id) => {
        return 'bonus_id-' + bonus_id;
      });
      let tags = BONUS_TAGS.concat(bonus_id_tags).concat(component_tag);
      angular.forEach(component_config.documents, (document) => {
        promises.push(this.Made.upload(document, tags, madeUserId));
      });
    });

    return this.$q.all(promises);
  }

  async getCheckoutValidations(valuenet_id, bonus_project_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_checkout_validations', {
      valuenet_id: valuenet_id,
      bonus_project_id: bonus_project_id
    });
  }

  async getBonusesForEmployeeAdministration(options) {
    let params = {
      customer_id: options.customer_id ? parseInt(options.customer_id) : undefined,
      bonus_project_id: options.bonus_project_id,
      employee_id: options.employee_id ? parseInt(options.employee_id) : undefined
    };

    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonuses_for_administration', params);
  }

  async getBonusesForEmployee(options) {
    let params = {
      bonus_project_id: options.bonus_project_id,
      employee_id: options.employee_id,
      bonus_id: options.bonus_id,
      bonus_ids: options.bonus_ids,
      bonus_state: options.bonus_state,
      bonus_states: options.bonus_states,
      to_attach_administration_validations: options.to_attach_administration_validations,
      to_attach_checkout_validations: options.to_attach_checkout_validations,
      basket_id: options.basket_id,
      to_check_previous_checkouts: options.to_check_previous_checkouts,
      to_check_future_checkouts: options.to_check_future_checkouts,
      to_standalone_other_bonuses: options.to_standalone_other_bonuses,
      cashback_components_only: options.cashback_components_only
    };
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonuses', params);
  }

  async getBonusComponentNameByBonusId(bonus_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_component_name', {
      bonus_id: bonus_id
    });
  }

  async getBonusesForBonusHistoryLog(options) {
    let params = {
      employee_id: options.employee_id,
      bonus_project_id: options.bonus_project_id
    };
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonuses_for_history_dialog', params);
  }

  async getBonusProjectsDisplayForUser(employee_id = this.Made.user.valuenet_id, for_administration_view = false) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_projects_display_for_user', {
      employee_id: employee_id,
      for_administration_view: for_administration_view
    });
  }

  async updateBonusProject(bonus_project, update_bonuses = false) {
    return this.Made.request('rpc://valuenetdb/bonus/project/update_project', {
      bonus_project: bonus_project,
      update_bonuses: update_bonuses
    });
  }

  async updateBonusProjectNotifications(bonus_project) {
    return this.Made.request('rpc://valuenetdb/bonus/project/update_bonus_project_notifications', {
      bonus_project: bonus_project
    });
  }

  async canPublishProject(options) {
    let params = {
      bonus_project_id: options.bonus_project_id
    };

    return this.Made.request('rpc://valuenetdb/bonus/project/can_publish_project', params);
  }

  async getActiveBonusProjects(options) {
    let params = {
      customer_id: options.customer_id,
      employee_id: options.employee_id,
      bonus_project_type: options.bonus_project_type
    };

    return this.Made.request('rpc://valuenetdb/bonus/project/get_active_bonus_projects', params);
  }

  async getBonusProject(customer_id, project_id) {
    customer_id = parseInt(customer_id);
    return this.Made.request('rpc://valuenetdb/bonus/project/get_project', {
      customer_id: customer_id,
      project_id: project_id
    });
  }

  async createBonusProject(customer_id, bonus_project) {
    customer_id = parseInt(customer_id);
    bonus_project.customer_id = customer_id;
    return this.Made.request('rpc://valuenetdb/bonus/project/create_project', {
      bonus_project: bonus_project,
      customer_id: customer_id
    });
  }

  async storeBonusesForPojectInMYSQL(options) {
    let parameters = Object.assign({}, {
      'bonus_project_id': options.bonus_project_id,
      'bonus_project_type': options.bonus_project_type
    });

    return this.Made.request('rpc://valuenetdb/bonus/project/export_bonus_project_information_to_MySQL', parameters);
  }

  async revokeBonusProject(customer_id, bonus_project) {
    customer_id = parseInt(customer_id);
    let bonus_project_id = bonus_project;
    if (typeof bonus_project === 'object') {
      bonus_project_id = bonus_project['_id'];
    }

    return this.Made.request('rpc://valuenetdb/bonus/project/revoke_project', {
      customer_id: customer_id,
      bonus_project_id: bonus_project_id
    });
  }

  async confirmBonusProject(customer_id, bonus_project) {
    customer_id = parseInt(customer_id);
    let bonus_project_id = bonus_project;
    if (typeof bonus_project === 'object') {
      if (!bonus_project['_id']) {
        bonus_project_id = (await this.createBonusProject(customer_id, bonus_project))['bonus_project_id'];
      } else {
        bonus_project_id = (await this.updateBonusProject(bonus_project))['bonus_project_id'];
      }
    }

    return this.Made.request('rpc://valuenetdb/bonus/project/confirm_project', {
      customer_id: customer_id,
      bonus_project_id: bonus_project_id
    });
  }

  async publishBonusProject(customer_id, bonus_project) {
    customer_id = parseInt(customer_id);
    let bonus_project_id = bonus_project;
    if (typeof bonus_project === 'object') {
      bonus_project_id = bonus_project['_id'];
    }

    return this.Made.request('rpc://valuenetdb/bonus/project/publish_project', {

      customer_id: customer_id,
      bonus_project_id: bonus_project_id
    });
  }

  async getBonusProjectComponentConfig(bonus_project_type_id) {
    let component_config = null;
    switch (bonus_project_type_id) {
      case BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id']:
        component_config = angular.copy(DIRECT_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS);
        break;
      case BONUS_PROJECT_TYPES_BY_NAME['one_time_bonus']['id']:
        component_config = angular.copy(EINMAL_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS);
        break;
      case BONUS_PROJECT_TYPES_BY_NAME['fiducia_bonus']['id']:
        component_config = angular.copy(FIDUCIA_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS);
        break;
      case BONUS_PROJECT_TYPES_BY_NAME['bgm_bonus']['id']:
        component_config = angular.copy(BGM_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS);
        break;
    }

    // ensure turned of components are not available
    let available_bonus_components = await this.getAvailableBonusComponents();
    let available_bonus_components_names = available_bonus_components.reduce((acc, bonus_component) => {
      acc.add(bonus_component.name);
      return acc;
    }, new Set());
    let component_config_names = component_config.budget.reduce((acc, component_config) => {
      acc.add(component_config.prop);
      return acc;
    }, new Set());
    let turned_off_components = new Set([...component_config_names].filter(component_name => !available_bonus_components_names.has(component_name)));

    component_config.budget = component_config.budget.filter(component => {
      return !turned_off_components.has(component.prop);
    });

    return component_config;
  }

  getBonusProjectEmployeesConfig(bonusProjectTypeId) {
    let employeesConfig = null;
    switch (bonusProjectTypeId) {
      case BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id']:
        employeesConfig = angular.copy(DEFAULT_DIRECT_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION);
        break;
      case BONUS_PROJECT_TYPES_BY_NAME['one_time_bonus']['id']:
        employeesConfig = angular.copy(DEFAULT_ONE_TIME_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION);
        break;
      case BONUS_PROJECT_TYPES_BY_NAME['bgm_bonus']['id']:
        employeesConfig = angular.copy(DEFAULT_ONE_TIME_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION);
        break;
      case BONUS_PROJECT_TYPES_BY_NAME['fiducia_bonus']['id']:
        employeesConfig = angular.copy(DEFAULT_FIDUCIA_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION);
        break;
    }

    return employeesConfig;
  }

  getDefaultBonusConfig() {
    return angular.copy(DEFAULT_BONUS_CONFIG);
  }

  async get_blocked_components(valuenet_ids, period_start_date, period_end_date) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_blocked_components', {
      valuenet_ids,
      period_start_date,
      period_end_date
    });
  }

  async giveBonus(bonus_config) {
    return this.Made.request('rpc://valuenetdb/bonus/project/give_bonus', {
      bonus_config: bonus_config
    });
  }

  getEmployeeBonusConfig(valuenet_id, employee_bonus_project_settings) {
    return {
      valuenet_id: parseInt(valuenet_id),
      bonus: {
        amount: employee_bonus_project_settings.bonus_amount,
        expiration_action: DEFAULT_BONUS_EXPIRATION_ACTION['id'],
        bonus_type: DEFAULT_BONUS_TYPE['id'],
      },
      employee_configuration: {
        is_church_tax_enabled: !!employee_bonus_project_settings.is_church_tax_enabled,
        steuer_klasse: employee_bonus_project_settings.steuer_klasse
      }
    };
  }

  getDate(instance) {
    if (instance) {
      return this.moment(instance).toDate();
    }
  }

  async getManagementByName(management_name) {
    return this.getAvailableBonusComponentManagement().then(() => {
      return this.managements_by_name[management_name];
    });
  }

  getBikeleasingComponentForApproval(employee_id, bonus_project_id, bonus_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bikeleasing_component_for_approval', {
      employee_id: employee_id,
      bonus_project_id: bonus_project_id,
      bonus_id: bonus_id
    });
  }

  boApproveBikeleasing(employee_id, bonus_project_id, bonus_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/bo_bonus_approve_bikeleasing', {
      employee_id: employee_id,
      bonus_project_id: bonus_project_id,
      bonus_id: bonus_id
    });
  }

  updateBonusFromAmendment(bonus_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/update_bonus_from_document_upload', {
      bonus_id: bonus_id
    });
  }

  async getAvailableBonusComponentManagement() {

    if (Object.keys(this.managements).length) {
      return this.$q.when(this.managements);
    }

    if (this.availableBonusManagementPromise) {
      return this.availableBonusManagementPromise;
    }

    this.availableBonusManagementPromise = this.Made
      .request('rpc://valuenetdb/bonus/project/get_components_available_management')
      .then(managements => {
        this.managements = managements;
        // this.components = components;
        this.managements_by_name = this.managements.reduce((acc, managements) => {
          acc[managements.name] = managements;
          return acc;
        }, {});
        return this.managements;
      });

    this.availableBonusManagementPromise.finally(() => {
      delete this.availableBonusManagementPromise;
    });

    return this.availableBonusManagementPromise;
    // return this.Made.request('rpc://valuenetdb/bonus/project/get_components_available_management');
  }

  getBonusComponentsRequiringExtraDocuments() {
    return this.getAvailableBonusComponents().then(() => {
      return this.components.filter(component => component.settings.requires_extra_documents);
    });
  }

  isComponentCheckoutAllowed(bonus) {
    if (!this.components || !bonus) {
      return true;
    }

    let overall = this.lodash.get(bonus, 'validations.can_checkout_out', true);

    return overall;
  }

  isBonusDefaultStartDateCheckoutAvailable(bonus) {
    return this.lodash.get(bonus, 'validations.component.is_allowed_to_checkout.validations.is_bonus_allowed_to_checkout_with_default_start_date', true);
  }

  getComponentByName(component_name) {
    return this.getAvailableBonusComponents().then(() => {
      return this.component_by_name[component_name];
    });
  }

  getAvailableBonusComponents(from_cache = true) {
    if (Object.keys(this.components).length && from_cache) {
      return this.$q.when(this.components);
    }

    if (this.availableBonusComponentsPromise && from_cache) {
      return this.availableBonusComponentsPromise;
    }

    this.availableBonusComponentsPromise = this.Made
      .request('rpc://valuenetdb/bonus/project/get_available_bonus_components')
      .then(components => {
        this.components = components;
        this.component_by_name = this.components.reduce((acc, component) => {
          acc[component.name] = component;
          return acc;
        }, {});
        return this.components;
      });

    this.availableBonusComponentsPromise.finally(() => {
      delete this.availableBonusComponentsPromise;
    });

    return this.availableBonusComponentsPromise;
  }

  getBonusProjectRemainingMonths() {
    return 12;
  }


  // Returns an object in the form
  // {
  //   bonus_amount_total: int,
  //   bonus_amount_approved: int,
  //   bonus_amount_checkedout: int,
  // }
  getEmployeeBonusBudget(employee_id, bonus_project_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_budget', {
      employee_id: employee_id,
      bonus_project_id
    });
  }

  administrationBonusApprove(bonus_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/administration_bonus_approve', {
      bonus_id: bonus_id,
    });
  }

  administrationBonusDecline(bonus_id, comment) {
    return this.Made.request('rpc://valuenetdb/bonus/project/administration_bonus_decline', {
      bonus_id: bonus_id,
      comment: comment
    });
  }

  administrationBonusAmmend(bonus_id, comment) {
    return this.Made.request('rpc://valuenetdb/bonus/project/administration_bonus_ammend', {
      bonus_id: bonus_id,
      comment: comment
    });
  }

  administrationBonusCancel(options) {
    let parameters = {
      bonus_id: options.bonus_id,
      basket_id: options.basket_id,
      comment: options.comment,
      new_end_date: options.new_end_date,
      ticket_number: options.ticket_number,
      force_buying_process: options.force_buying_process
    };

    return this.Made.request('rpc://valuenetdb/bonus/project/administration_bonus_cancel', parameters);
  }

  preEndBonusPC(options) {
    let parameters = {
      bonus_id: options.bonus_id,
      new_end_date: options.new_end_date,
      comment: options.comment,
    };

    return this.Made.request('rpc://valuenetdb/bonus/project/preend_bonus_pc', parameters);
  }

  updateBonusStartDate(bonus_id, new_start_date, comment, allow_change_to_same_start_date) {
    return this.Made.request('rpc://valuenetdb/bonus/project/update_bonus_start_date', {
      bonus_id: bonus_id,
      new_start_date: new_start_date,
      comment: comment,
      allow_change_to_same_start_date: allow_change_to_same_start_date
    });
  }

  getBonusControlProcessNotifications(employee_id, type = null) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_control_process_notifications', {
      employee_id: employee_id,
      type: type
    });
  }

  confirmBikeleasingPickup(options) {
    let parameters = {
      bonus_id: options.bonus_id,
      custom_pickup_date: options.pickup_date
    };
    return this.Made.request('rpc://valuenetdb/bonus/project/confirm_bikeleasing_pickup', parameters);
  }

  async calculateBikeleasingLimits(employee_id, bonus_project_id, customer_id, start_date) {
    return this.Made.request('rpc://valuenetdb/bonus/project/calculate_bike_leasing_limits', {
      employee_id: employee_id,
      bonus_project_id: bonus_project_id,
      customer_id: customer_id,
      start_date: start_date
    });
  }

  generateBonusCheckoutContractByBonusId(employee_id, bonus_id, bonus_ids, checkout_configurations_by_bonus_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/generate_bonus_contract_by_bonus_id', {
      employee_id: employee_id,
      bonus_id: bonus_id,
      bonus_ids: bonus_ids,
      checkout_configurations_by_bonus_id: checkout_configurations_by_bonus_id
    });
  }

  generateBonusCheckoutContractByBonusIdSmartAnchors(employee_id, bonus_id, smartAnchors) {
    return this.Made.request('rpc://valuenetdb/bonus/project/generate_bonus_contract_by_bonus_id', {
      employee_id: employee_id,
      bonus_id: bonus_id,
      smart_anchors: smartAnchors
    });
  }

  getBonusesRequiringDocuments(employee_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonuses_requiring_documents', {
      employee_id: employee_id
    });
  }

  isComponentCancelled(bonus_component) {
    return bonus_component && bonus_component.state && bonus_component.state === BONUS_STATES_BY_NAME['cancelled']['id'];
  }

  isBonusYearly(bonus) {
    let component_name = this.getBonusComponentName(bonus);
    return ['erholungsbeihilfe', 'Freistellungstag', 'VWL', 'wertguthaben', 'kinder', 'bgmbudget', 'jobticket', 'egeschenk'].includes(component_name);
  }

  isBonusCashback(bonus) {
    let componentName = this.getBonusComponentName(bonus);
    return ['jobticket', 'kinder', 'bgmbudget', 'egeschenk'].includes(componentName)
  }

  isComponentDeclined(bonus) {
    return bonus && bonus.state === BONUS_STATES_BY_NAME['declined']['id'];
  }

  isComponentUsed(bonus_component, to_include_cancelled = true) {
    let available_statuses = [
      BONUS_STATES_BY_NAME['available']['id'],
      BONUS_STATES_BY_NAME['declined']['id']
    ];

    if (to_include_cancelled) {
      available_statuses.push(BONUS_STATES_BY_NAME['cancelled']['id'])
    }

    let is_component_used = false;
    if (bonus_component) {
      is_component_used = !available_statuses.includes(bonus_component.state);

      if (
        !is_component_used &&
        bonus_component.state === BONUS_STATES_BY_NAME['cancelled']['id']
      ) {
        is_component_used = false
      }
    }

    return is_component_used;
  }

  isComponentApproved(bonus_state) {
    if (bonus_state) {
      return BONUS_STATES_BY_NAME['approved']['id'] === bonus_state;
    }
    return false;
  }

  hasMissingBonusDocuments(employee_id) {
    return this.getBonusesRequiringDocuments(employee_id).then(
      (res) => {
        return (res && res.length > 0);
      }).catch((err) => {
      console.error(err);
      return false;
    });
  }

  hideBonusApprovedNotificationForBonuses(bonus_ids) {
    return this.Made.request('rpc://valuenetdb/bonus/project/hide_bonus_approved_notification_for_bonuses', {
      bonus_ids: bonus_ids
    });
  }

  hideBonusDeclinedNotificationForBonuses(bonus_ids) {
    return this.Made.request('rpc://valuenetdb/bonus/project/hide_bonus_declined_notification_for_bonuses', {
      bonus_ids: bonus_ids
    });
  }

  getBonusHistory(options) {
    let params = {
      bonus_id: options.bonus_id,
      employee_id: options.employee_id,
      only_last: options.only_last,
      show_changer: options.show_changer,
      with_bonus_project_info: options.with_bonus_project_info,
      with_parent: options.with_parent,
      reason_id: options.reason_id
    };
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_history', params);
  }

  getBonusChangeReasons() {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_change_reasons');
  }

  getBikeleasingConfirmationNotification() {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_BONUS_BIKELEASING_CONFIRMATION_NOTIFICATION');
  }

  getBonusComponentsMap() {
    return this.getAvailableBonusComponents().then((res) => {
      let bonus_component_by_name = res.reduce((acc, field) => {
        acc[field.name] = field;
        return acc;
      }, {});

      return bonus_component_by_name;
    });
  }

  migrateCurrentBonusApprovals() {
    return this.Made.request('rpc://valuenetdb/bonus/project/migrate_current_bonus_approvals');
  }

  updateDeclinedBonusBaskets() {
    return this.Made.request('rpc://valuenetdb/bonus/project/update_declined_bonus_baskets');
  }

  validateApprovedBonusBaskets() {
    return this.Made.request('rpc://valuenetdb/bonus/project/validate_approved_bonus_baskets');
  }

  fixFutureCheckoutBaskets() {
    return this.Made.request('rpc://valuenetdb/bonus/project/fix_future_checkout_baskets')
  }

  getAvailableCashbackComponents(options) {
    let params = {
      employee_id: options.employee_id,
    }

    if (options.component_type) {
      params.component_type = options.component_type
    }

    return this.Made.request('rpc://valuenetdb/bonus/project/get_available_cashback_components', params)
  }

  getAvailableElectronicPresentComponentsAndBudget(employee_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_available_electronic_present_component_and_budget', {
      'employee_id': employee_id
    })
  }

  isCashbackRequestAvailable(employeeId, bonusProjectId) {
    return this.Made.request('rpc://valuenetdb/bonus/project/is_cashback_request_allowed_for_bonus_project', {
      employee_id: employeeId,
      bonus_project_id: bonusProjectId
    })
  }

  getComponentRest(options) {
    let params = {
      bonus_id: options.bonus_id
    }
    return this.Made.request('rpc://valuenetdb/bonus/project/get_component_rest', params);
  }

  createCashbackRequest(requestData) {

    console.log("REQUEST DATA - ", requestData)
    // Destructure the properties we need from the requestData object
    const {
      component,
      bonusData,
      requestedValue,
      receiptDate,
      firstValidDate,
      receiptValidPeriod,
      files,
      employeeData
    } = requestData;

    const employeeId = parseInt(employeeData.id)

    // Create an empty array to store the file IDs
    const fileIds = [];
    // Loop over the files array and upload each file using the Made.upload() method
    // Add the resulting file ID to the filesId array
    let promises = [];
    for (const file of files) {
      promises.push(this.Made.upload(file, {}, employeeId).then(res => fileIds.push(res.data._id)));
    }

    return this.$q.all(promises).then(() => {
      // Construct the API request payload using the data we extracted earlier
      const url = 'rpc://valuenetdb/cashback/create_cashback_request';
      const payload = {
        cashback_request: {
          component,
          employee_id: employeeId,
          bonus_project_id: bonusData.bonusProjectId,
          requested_value: requestedValue,
          receipt_date: receiptDate,
          receipt_valid_from: firstValidDate,
          receipt_valid_period: receiptValidPeriod,
          file_ids: fileIds,
          file_url: requestData.fileUrl
        }
      };



      // Send the API request using the Made.request() method and return the result
      return this.Made.request(url, payload);
    });
  }

  createEgeschenkCashbackRequest(requestData, basketId) {
    // Destructure the properties we need from the requestData object
    const {
      component,
      bonusData,
      requestedValue,
      receiptDate,
      firstValidDate,
      receiptValidPeriod,
      files,
      employeeData
    } = requestData;

    const employeeId = parseInt(employeeData.id)

    // Create an empty array to store the file IDs
    const fileIds = [];
    // Loop over the files array and upload each file using the Made.upload() method
    // Add the resulting file ID to the filesId array
    let promises = [];
    for (const file of files) {
      promises.push(this.Made.upload(file, {}, employeeId).then(res => fileIds.push(res.data._id)));
    }
    return this.$q.all(promises).then(() => {
      // Construct the API request payload using the data we extracted earlier
      const url = 'rpc://valuenetdb/cashback/create_egeschenk_cashback_request';
      const payload = {
        cashback_request: {
          component,
          employee_id: employeeId,
          bonus_project_id: bonusData.bonusProjectId,
          requested_value: requestedValue,
          receipt_date: receiptDate,
          receipt_valid_from: firstValidDate,
          receipt_valid_period: receiptValidPeriod,
          file_ids: fileIds,
          file_url: requestData.fileUrl
        },
        basket_id: basketId
      };

      // Send the API request using the Made.request() method and return the result
      return this.Made.request(url, payload);
    });
  }

  searchCashbacks(filters) {
    return this.Made.request('rpc://valuenetdb/cashback/search_cashbacks', {filters: filters});
  }

  getCashbackNotifications(employeeId) {
    return this.Made.request('rpc://valuenetdb/notification/get_cashback_notifications', {
      'employee_id': employeeId
    });
  }

  expireBikeleasingBonuses() {
    return this.Made.request('rpc://valuenetdb/bonus/project/expire_bikeleasing_bonuses');
  }

  getBAVAllowedMonthlyBudget(employee_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bav_allowed_monthly_budget', {
      'employee_id': employee_id
    });
  }

  activateFutureCheckoutsExternal() {
    return this.Made.request('rpc://valuenetdb/bonus/project/activate_future_checkouts_external');
  }

  exportBonusProjects() {
    return this.Made.request('rpc://valuenetdb/bonus/project/export_bonus_projects_external');
  }
}

BonusService.$inject = $inject;
