import {BasketItem} from './basket-item';
import {ShopApiService} from "../services/backend/shop-api.service";
import {BasketCalculation} from "./basket-calculation";

export class Basket {

  // #COMMENT_LEX: The basket has still far too many fields. does it have to contain the calculation?
  // Which fields are actually used?
  // It is also not clear if the basket should contain the dates.
  // And does it really need the employee id? when is it read from the basket?
  /**
   * @param id
   * @param employee_id
   * @param {Array<BasketItem>} items
   * @param {BasketCalculation} calculation
   * @param contract_start_date
   * @param contract_end_date
   * @param contract_preend_date
   * @param {boolean} isPreOrder
   * @param {boolean} isClosed
   * @param handlingFeeBrutto
   * @param orderProcess
   * @param contractId
   * @param confirmation
   */
  constructor(
    id,
    employee_id,
    items,
    calculation,
    contract_start_date,
    contract_end_date,
    contract_preend_date,
    isPreOrder,
    isClosed,
    handlingFeeBrutto,
    orderProcess,
    contractId,
    confirmation,
    isYouSignV3 = false
    ) {
    this._id = id;
    this._employee_id = employee_id;
    this._items = items;
    this._calculation = calculation;
    this._contract_start_date = contract_start_date;
    this._contract_end_date = contract_end_date;
    this._isPreOrder = isPreOrder;
    this._isClosed = isClosed;
    this._handlingFeeBrutto = handlingFeeBrutto;
    this._orderProcess = orderProcess;
    this._contractId = contractId;
    this._confirmation = confirmation;
    this.isYouSignV3 = isYouSignV3
  }

  get id() {
    return this._id;
  }

  get calculation() {
    return this._calculation;
  }

  /**
   * #COMMENT_LEX: This method is required to get a copy of the basket before a refresh to see if it has changed.
   * Not the ideal solution but better than refresh via the model class itself. If the basket had fewer fields this
   * would be less painful.
   * @returns {Basket}
   */
  copy() {
    let copiedItems = [];
    this.items.forEach(i => {
      copiedItems.push(new BasketItem(i.article.copy(), i.quantity));
    });
    return new Basket(
      this.id,
      this.employee_id,
      copiedItems,
      this.calculation, // #COMMENT_LEX: we use the same instance since it is not mutable. should not be here in the first place
      this.contract_start_date,
      this.contract_end_date,
      this.contract_preend_date,
      this._isPreOrder,
      this._isClosed
    )
  }

  clear() {
    while (!this.isEmpty) {
      this.removeItemAt(0);
    }
  }

  /**
   * @param {Basket} other
   * @returns boolean
   */
  // #COMMENT_LEX: This used to be part of a refreshArticlesData() method that talked to the backend and returned
  // a bool indicating whether there was a change (checking validations properties on articles/basket).
  // This is now separated: The basket can be refreshed via a service method (not on the basket itself!) and then it
  // can simply be compared to the original basket.
  hasEqualItemsAs(other) {
    if (this.items.length != other.items.length) {
      return false;
    }
    else {
      let equal = true;
      this.items.forEach((i, index) => {
        let otherItem = other.items[index];
        if (i.quantity != otherItem.quantity || !i.article.equals(otherItem.article)) {
          equal = false;
        }
      });
      return equal;
    }
  }

  removeItemAt(article_index) {
    if (article_index >= 0 && article_index < this._items.length) {
      this._items.splice(article_index, 1);
    }
  }

  get contract_start_date() {
    return this._contract_start_date;
  }

  get contract_end_date() {
    return this._contract_preend_date ? this._contract_preend_date : this._contract_end_date;
  }

  /**
   * @param {BasketArticle} article
   * @param {int} quantity
   */
  addArticle(article, quantity) {
    let index = this.items.findIndex(i => i.article.articleNo === article.articleNo);
    if (index >= 0) {
      let newQuantity = quantity + this.items[index].quantity;
      let newItem = new BasketItem(article, newQuantity);
      // #COMMENT_LEX: open state does not belong here but into the sidebar.
      newItem.open = this.items[index].open;
      this._items.splice(index, 1, newItem);
    }
    else {
      this._items.push(new BasketItem(article, quantity));
    }
  }

  /**
   * @param {BasketItem} item
   */
  updateItem(item) {
    let index = this.items.findIndex(i => i.article.articleNo === item.article.articleNo);
    if (index >= 0) {
      this._items.splice(index, 1, item);
    }
  }

  get isEmpty() {
    return this.items.length === 0;
  }

  get leasing_rate() {
    return this.calculation.leasing;
  }

  get containsMainArticle() {
    return this.items.findIndex(i => i.article.isMainArticle) >= 0;
  }

  get employee_id() {
    return this._employee_id;
  }

  // #TODO_LEX: Is this method actually used?
  /**
   * @param {ShopApiService} shopApiService
   * @returns {Promise<void>}
   */
  async calculateValue(shopApiService) {
    this._calculation = await shopApiService.getBasketCalculationValues(this);
  }

  /** @deprecated use items instead */
  get articles() {
    return this._items;
  }

  /**
   * @returns Array<BasketItem>
   */
  get items() {
    return this._items;
  }

  get isPreOrder() {
    return this._isPreOrder;
  }

  get isClosed() {
    return this._isClosed;
  }

  get handlingFeeBrutto() {
    return this._handlingFeeBrutto;
  }

  // #COMMENT_LEX: The following fields should be rather called directly on the calculation. If they are used at all.

  get total_brutto() {
    return this.value_brutto > 0 ? this.calculation.brutto_base : 0;
  }

  get value_brutto() {
    return this.calculation.brutto_sum;
  }

  get value_netto() {
    return this.calculation.netto_sum;
  }

}
