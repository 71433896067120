const $inject = ['SchemaService']

export default class ArbeitnehmerSpec {
  constructor(SchemaService) {
    this.SchemaService = SchemaService;

    this.parts = {
      'sex,employeenumber,email': {
        type: 'section',
        htmlClass: 'display_1_1_1 ',
        items: [{
          key: 'employee.sex',
          title: 'Anrede',
          type: 'select',
          titleMap: [{
            value: true,
            name: 'Herr'
          }, {
            value: false,
            name: 'Frau'
          }],
          validationMessage: 'Geben Sie ein Geschlecht an'
        }, {
          key: 'employee.employeenumber',
          title: 'Personalnummer',
          validationMessage: 'Geben Sie eine eindeutige Personalnummer ein'
        }, {
          key: 'employee.email',
          title: 'E-Mail',
          validationMessage: 'Geben Sie eine eindeutige E-Mail Adresse ein'
        }]
      },
      'lastname,firstname': {
        type: 'section',
        htmlClass: 'display_1_1',
        items: [{
          key: 'employee.lastname',
          title: 'Nachname',
          validationMessage: 'Geben Sie einen Nachnamen an'
        }, {
          key: 'employee.firstname',
          title: 'Vorname',
          validationMessage: 'Geben Sie einen Vornamen an'
        }]
      },
      'birthdate,entrydate': {
        type: 'section',
        htmlClass: 'display_1_1',
        items: [{
          key: 'employee.birthdate',
          title: 'Geburtsdatum',
          validationMessage: 'Geben Sie ein Geburtsdatum in der Form tt.mm.jjjj an',
          onChange: 'checkDate_YYYYMMDD()'
        }, {
          key: 'employee.entrydate',
          title: 'Eintrittsdatum',
          validationMessage: 'Geben Sie ein Eintrittsdatum in der Form tt.mm.jjjj an'
        }]
      }
    };

    this.form = [this.parts['sex,employeenumber'],
      this.parts['lastname,firstname'],
      this.parts['birthdate,entrydate'], {
        type: 'section',
        htmlClass: 'display_1',
        items: [{
          key: 'employee.street',
          title: 'Straße'
        }]
      }, {
        type: 'section',
        htmlClass: 'display_1_1',
        items: [{
          key: 'employee.zip',
          title: 'Postleitzahl'
        }, {
          key: 'employee.city',
          title: 'Stadt'
        }]
      }, {
        type: 'select',
        title: 'Arbeitgeber',
        key: 'employee.customer_id',
        titleMap: [],
        onChange: 'updateCustomer(modelValue,form)'
      }, {
        title: 'Standort',
        key: 'employee.loc_id',
        type: 'select'
      }, {
        key: 'employee.email',
        title: 'E-Mail Adresse'
      }
    ];
  }

  schema() {
    return this.SchemaService
      .get('rpc://valuenetdb/create_user')
      .then(schema => ({
        type: 'object',
        properties: schema
      }));
  }
}

ArbeitnehmerSpec.$inject = $inject
