import {Injectable} from '@angular/core';
import * as uuid from 'uuid';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class Made {
  private _madeConfig = {
    url: {
      protocol: 'ws://',
      host: environment.madeHostUrl,
      path: '/ws',
      gridProtocol: 'http://'
    },
    reconnectTimeout: Math.round(Math.random() * 500 + 500),
    debug: !environment.production
  };
  private is_localhost: boolean;
  private wss!: WebSocket;
  private contexts: any = {};

  constructor() {
    // update madeConfig
    if ('https:' === window.location.protocol) {
      this._madeConfig.url.protocol = 'wss://';
      this._madeConfig.url.gridProtocol = 'https://';
    }

    this.is_localhost = window.location.hostname.includes('localhost');

    this._setupSocket();

  }

  private _setupSocket() {
    this.wss = new WebSocket(
      this._madeConfig.url.protocol +
      this._madeConfig.url.host +
      this._madeConfig.url.path
    );

    this.wss.onopen = () => console.log('Socket OPEN');

    this.wss.onerror = (error) => console.log('Socket ERROR ', error);

    this.wss.onmessage = (message) => this._receiveMessage(message);

    this.wss.onclose = () => console.log('Socket CLOSE');
  }

  private _receiveMessage(message: any) {
    message = JSON.parse(message.data);

    this.contexts[message.context].resolve(message);
  }

  private async _send(action: string, data: object) {
    const context_id = uuid.v4();
    const message = {
      user: {
        _id: undefined,
        session: undefined,
      },
      context: context_id,
      action: action,
      data: data,
      error: null,
      success: true,
    };

    const promise = new Promise((resolve, reject)=>{
      this.contexts[context_id] = {
        resolve, reject
      };
    });

    this._whenConnected(() => this.wss.send(JSON.stringify(message)));

    return promise;
  }

  private _whenConnected( callback: Function) {
    let wait = () => {
      setTimeout(() => {
        this._whenConnected(callback);
      }, 750);
    };

    if (this.wss) {
      switch (this.wss.readyState) {
        case WebSocket.OPEN:
          callback();
          break;
        case WebSocket.CONNECTING:
          wait();
          break;
        default:
          this._setupSocket();
          wait();
      }
    } else {
      wait();
    }
  }

  async request(uri:string, kwargs: object = {}, refine='data'){
    let promise = this._send('request', {
      uri,
      kwargs,
    });

    if (refine) {
      promise = promise.then((response: any) => response[refine]);
    }

    return promise;
  }
}
