import { BonusComponentController } from './BonusComponentController';
import {TAX_PAYERS_BY_NAME} from "../../../services/bonus/tax_payer";

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export default class BonusComponentControllerErholungsbeihilfe extends BonusComponentController {

  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {

    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    Object.assign(this, {
      SteuerklassenConstants
    });

    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }

  hasPredefinedSteuerKlasse() {
    return !!this.predefined_steuer_class;
  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true) {
    return super.addComponentToEmployeeBonusComponents(false).then(async (result) => {
      if (!result) {
        return;
      }

      // because of a bug it is possible that predefined_steuer_class is FALSE
      let predefined_steuer_class = this.lodash.get(this.bonus_project.for_employees[this.employee_id], 'employee_configuration.steuer_klasse', undefined);
      this.predefined_steuer_class = predefined_steuer_class ? predefined_steuer_class : undefined;

      this.setModelValues({
        an_enabled: true,
        ehe_enabled: false,
        an: 0,
        ehe: 0,
        steuer_class: this.predefined_steuer_class
      });

      if (!this.model.sliders) {

        // was set by assistant
        if (!this.model.is_optimized) {
          this.fillValuesAndCalculate();
        }

        // create sliders
        let sliders = {
          an: angular.copy(this.SliderOptionsConstants),
          ehe: angular.copy(this.SliderOptionsConstants),
        };

        // set AN slider
        let max_an = this.calculate_max_an();
        let an_slider_config = this.BonusService.createSliderConfig(max_an, 0);
        Object.assign(sliders.an, an_slider_config);

        // set EHE slider
        let max_ehe = this.calculate_max_ehe();
        let ehe_slider_config = this.BonusService.createSliderConfig(max_ehe, 0);
        Object.assign(sliders.ehe, ehe_slider_config);

        // attach sliders
        this.model.sliders = sliders;
      } else {
        // recalculate modelLabels which were deleted before save
        // this.BonusService.recalculateSlidersModelLabels(this.model.sliders);

        let new_max_an = this.calculate_max_an();
        let slider_to = this.model.sliders.an.to;
        this.model.sliders.an.to = new_max_an;
        if (this.model.sliders.an.to > slider_to) {
          let new_max_ehe = this.calculate_max_ehe();
          this.model.sliders.ehe.to = new_max_ehe;
          this.model.ehe = new_max_ehe;
        }
      }

      this.watcher_AN = this.$scope.$watch(() => { return this.model.an; }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            this.reset_ehe();
            this.calculateValue();
          }, 0);
        }
      });

      this.watcher_EHE = this.$scope.$watch(() => { return this.model.ehe; }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            this.calculateValue();
          }, 0);
        }
      });

      this.$scope.$on('$destroy', () => {
        this.watcher_EHE();
        this.watcher_AN();
      });

      if (move_to_next_step) {
        this.WizardHandler.wizard().next();
      }
    });
  }

  hasValidSteuerklasse() {
    return this.model && !angular.isUndefined(this.model.steuer_class);
  }

  fillValuesAndCalculate() {
    this.model.an = this.calculate_max_an();
    this.model.ehe = this.calculate_max_ehe();

    if (this.model.sliders) {
      this.model.sliders.an.to = this.model.an;
      this.model.sliders.ehe.to = this.model.ehe;
    }
    this.calculateValue();
  }

  reset_ehe() {
    this.model.ehe = this.calculate_max_ehe();
  }

  async init() {

  }

  is_ehe_allowed() {
    return this.BonusService.is_ehe_allowed(this.model.steuer_class) && this.model.an === this.get_configuration_max_an() && this.model.ehe > 1; // min is slider step
    // return this.model && (this.model.steuer_class === 3 || this.model.steuer_class === 4 || this.model.steuer_class === 5);
  }

  get_configuration_max_an() {
    return this.bonus.component[this.bonus_component_name].configuration.an_max_yearly_payment;
  }

  get_configuration_max_ehe() {
    return this.bonus.component[this.bonus_component_name].configuration.ehe_max_yearly_payment;
  }

  is_additional_tax_enabled() {
    return this.BonusService.is_additional_tax_enabled(this.component_budget_config);
  }

  get_additional_tax_value(tax_base, tax_base_with_tax_value) {
    let tax_result = this.BonusService.get_additional_tax_value(this.component_budget_config, {
      tax_base: tax_base,
      tax_base_with_tax_value: tax_base_with_tax_value,
      employee_bonus_project_configuration: this.employee_bonus_project_configuration,
      checkout_type: this.checkout_type,
        bonus: this.bonus
    });
    return tax_result;
  }


  calculate_max_an() {
    let remaining_amount = this.calculateRemainingWithoutCurrentBonus();
    let configuration_max_an = this.get_configuration_max_an();
    let max_an = Math.min(configuration_max_an, remaining_amount);

    if (this.is_additional_tax_enabled()) {
      let tax_base_with_tax_value = remaining_amount;
      let tax_result;
      // the remaining amount with additional value for tax is greater then the maximum an value, so use the maximum an as tax_base
      if (tax_base_with_tax_value > configuration_max_an){
        tax_result = this.get_additional_tax_value(configuration_max_an, undefined);
        // tax_base_with_tax_value is not greater then the max_an, so we need to calculate the maximum based on that limitations
      } else {
        tax_result = this.get_additional_tax_value(undefined, tax_base_with_tax_value);
      }

      if ((tax_result['flat_tax'].tax_base + tax_result.overall) > remaining_amount) {
        tax_result = this.get_additional_tax_value(undefined, remaining_amount);
      }

      // let tax_base_with_tax_value = remaining_amount;
      // let tax_result = this.get_additional_tax_value(undefined, tax_base_with_tax_value);
      max_an = Math.min(tax_result['flat_tax'].tax_base, max_an);
      this.model.tax_result = this.lodash.merge(this.model.tax_result || {}, { max_an_tax: tax_result.overall });
    }

    return parseFloat(max_an.toFixed(2));
  }

  calculate_max_ehe() {
    let remaining_amount = this.calculateRemainingWithoutCurrentBonus();
    remaining_amount -= this.model.an + this.lodash.get(this.model, 'tax_result.max_an_tax', 0);
    let configuration_max_ehe = this.get_configuration_max_ehe();
    let max_ehe = Math.min(configuration_max_ehe, remaining_amount);

    if (this.is_additional_tax_enabled()) {
      let tax_base_with_tax_value = remaining_amount;
      let tax_result;
      // the remaining amount with additional value for tax is greater then the maximum an value, so use the maximum an as tax_base
      if (tax_base_with_tax_value > configuration_max_ehe){
        tax_result = this.get_additional_tax_value(configuration_max_ehe, undefined);
        // tax_base_with_tax_value is not greater then the max_an, so we need to calculate the maximum based on that limitations
      } else {
        tax_result = this.get_additional_tax_value(undefined, tax_base_with_tax_value);
      }

      if ((tax_result['flat_tax'].tax_base + tax_result.overall) > remaining_amount) {
        tax_result = this.get_additional_tax_value(undefined, remaining_amount);
      }
      // ;
      // let tax_result = this.get_additional_tax_value(undefined, tax_base_with_tax_value);
      max_ehe = Math.min(tax_result['flat_tax'].tax_base, max_ehe);
      this.model.tax_result = this.lodash.merge(this.model.tax_result || {}, { max_ehe_tax: tax_result.overall });
    }
    return parseFloat(max_ehe.toFixed(2));
  }

  getPeriodStartDate() {
    return this.getComponentDisplayStartDate(this.bonus,-3, 'months');
  }

  getPeriodEndDate() {
    let start_date = this.getComponentDisplayStartDate(this.bonus,3, 'month');
    return this.moment(start_date).endOf('month').toDate();
  }

  enable_an() {
    this.model.an_enabled = true;
    this.model.an = this.calculate_max_an();
  }

  disable_ehe() {
    this.model.ehe_enabled = false;
  }

  enable_ehe() {
    this.model.ehe_enabled = true;
  }

  calculateValue() {
    // this.model is the Component settings that were used to create the latest calculations
    // this.model is the specific Component which is being loaded from rechner/neo.py as values

    // must choose Steuerklasse
    if (typeof this.model.steuer_class == 'undefined') {
      this.model.value = 0;
      return;
    }

    if (!this.model.an_enabled && !this.model.ehe_enabled) {
      this.enable_an();
    }

    if (!this.is_ehe_allowed()) {
      this.disable_ehe();
    } else {
      this.enable_ehe();
    }

    //
    let an = this.model.an_enabled ? this.model.an : 0,
      ehe = this.model.ehe_enabled ? this.model.ehe : 0;

    let component_value = this.BonusService.roundNumber(an + ehe);

    if ( this.is_additional_tax_enabled() ) {

      this.model.netto_value = component_value;
      let tax_result = this.get_additional_tax_value(component_value);
      let variable_tax = tax_result.overall;

      // ASSUME ALL TAX_PROVIDERS HAVE SAME PAYER
      let tax_provider = Object.keys(this.component_budget_config.additional_costs)[0];
      this.model.tax_payer = this.component_budget_config.additional_costs[tax_provider].payer;

      this.model.tax_value = tax_result.overall;
      this.model.variable_tax = variable_tax;
      this.model.tax_result = this.lodash.merge(this.model.tax_result || {}, tax_result);


      if (this.model.tax_payer === TAX_PAYERS_BY_NAME['employee']['id']) {
        this.model.value = this.model.netto_value + this.model.tax_value;
      } else {
        this.model.value = this.model.netto_value
      }
    } else {
      this.model.value = component_value;
      this.model.netto_value = component_value;
    }
    return this.model.value;
  }

}

BonusComponentControllerErholungsbeihilfe.$inject = $inject;
