angular
  .module('VSPApp')
  .controller('MeineakteSteuerfreiController', [
    '$scope',
    'Made',
    'vbmData',
    'employeeService',
    'componentsService',
    'customerService',
    'dialogService',
    'vbmService',
    '$timeout',
    function (
      $scope,
      Made,
      vbmData,
      employeeService,
      componentsService,
      customerService,
      dialogService,
      vbmService,
      $timeout
    ) {

      $scope.employeeId = vbmData.employeeId = Made.user.valuenet_id;

      employeeService
        .get($scope.employeeId)
        .then(employee => vbmData.employees[$scope.employeeId].data.person = employee);


      componentsService
        .getComponents()
        .then(() => componentsService.applyConfiguration())
        .then(components => {
          $scope.components = components;
        });

      function getData() {
        $scope.latest_final_calculation = null;
        return vbmData
          .getResult($scope.employeeId, undefined, true)
          .then(() => {
            $scope.employee = vbmData.employees[$scope.employeeId];
          })
          // .then(()=>{
          //   return vbmService.getLatestCalculation(undefined , true).then((calculation)=>{
          //     $scope.latest_final_calculation = calculation;
          //   });
          // })
          .then(() => {
            return customerService.getNeoProjectByEmployeeId();
          })
          .then(neo_project => {

            $scope.unusedComponents = componentsService
              .getUnusedComponentsOfEmployee($scope.employee)
              .filter(component => (
                neo_project.neo_components[component.key] &&
                neo_project.neo_components[component.key].selfservice
              ));

          });
      }


      getData().then(()=>{
        $timeout(()=>{})
      });

      $scope.enterBrutto = () => dialogService
        .employeeBasics()
        .then(() => getData());

    }]);
