import DatevCalculatorInput from './DatevCalculatorInput';

const {
  BigDecimal
} = require('bigdecimal');

import SteuerklassenConstants from '../../constants/calculation/SteuerklassenConstants';
import DatevCalculatorOutput from './DatevCalculatorOutput';
import {
  BUDGET_TYPES, BUDGET_TYPES_BY_NAME, CALCULATION_TYPES, CALCULATION_TYPES_BY_VALUE,
  CHURCH_TAX_TYPES,
  HEALTH_INSURANCE,
  PENSION_INSURANCE,
  RAISE_TYPES
} from '../../modules/datev/default_data/data';

export const DIRECT_BUDGET_FIELDS = [
  {label: 'st/sv freie Leistung', prop: 'additional'},
  {label: 'Sachbezug 50 EUR', prop: 'sachbezug'},
  {label: 'Essensschecks PST, Stück', prop: 'essensschecks_pst'},
  {label: 'Essensschecks frei, Stück', prop: 'essensschecks_frei'},
  {label: 'PC-Leasing, Wert', prop: 'pc_leasing'},
  {label: 'Telekommunikation, EUR', prop: 'telekommunikation'},
  {label: 'Internetpauschale PST, EUR', prop: 'internetpauschale'},
  {label: 'Entfernungspauschale PST, EUR', prop: 'entfernungspauschale'},
  {label: 'Erholungsbeihilfe PST, EUR p.a.', prop: 'erholungsbeihilfe'}
];

export const GOAL_BUDGET_FIELDS = angular.copy(DIRECT_BUDGET_FIELDS);

export const DIRECT_BUDGET_FIELDS_BY_NAME = DIRECT_BUDGET_FIELDS.reduce((acc, budget_field) => {
  acc[budget_field.prop] = budget_field;
  return acc;
}, {});

export const DEFAULT_DIRECT_BUDGET = {
  additional: {value: 100, range: {min: 0.01, decimalsCount: 2}, active: false},
  sachbezug: {value: 50, range: {min: 0.01, max_eq: 50, decimalsCount: 2}, active: false},
  essensschecks_pst: {
    value: 15,
    range: {min_eq: 1, max_eq: 15, decimalsCount: 2},
    attributes: {currency: {to_format: false}},
    per_item_price: 6.57,
    per_item_price_settings: {is_editable: false, is_visible: true, range: {min: 0, max_eq: 6.57}},
    on_activation: {'disable': 'essensschecks_frei'},
    active: false,
    ag_cost: {[CHURCH_TAX_TYPES[0].id]: 0.26375, [CHURCH_TAX_TYPES[1].id]: 0.2837, [CHURCH_TAX_TYPES[2].id]: 0.2862},
    multiplier: 3.47
  },
  essensschecks_frei: {
    value: 15,
    range: {min_eq: 1, max_eq: 15, decimalsCount: 2},
    attributes: {currency: {to_format: false}},
    per_item_price: 3.1,
    per_item_price_settings: {is_editable: false, is_visible: true, range: {min: 0, max_eq: 3.1}},
    on_activation: {'disable': 'essensschecks_pst'},
    active: false
  },
  pc_leasing: {
    value: 500,
    range: {min: 349, max: 10001, decimalsCount: 2},
    per_item_price: 0.0477,
    per_item_price_settings: {is_visible: false},
    round: true,
    active: false
  },
  telekommunikation: {value: 39.90, range: {decimalsCount: 2}, active: false},
  internetpauschale: {
    value: 30,
    range: {max_eq: 50, decimalsCount: 2},
    active: false,
    ag_cost: {[CHURCH_TAX_TYPES[0].id]: 0.26375, [CHURCH_TAX_TYPES[1].id]: 0.2837, [CHURCH_TAX_TYPES[2].id]: 0.2862}
  },
  entfernungspauschale: {
    value: 45,
    range: {max_eq: 375, decimalsCount: 2},
    active: false,
    ag_cost: {[CHURCH_TAX_TYPES[0].id]: 0.15825, [CHURCH_TAX_TYPES[1].id]: 0.17025, [CHURCH_TAX_TYPES[2].id]: 0.1704}
  },
  erholungsbeihilfe: {
    value: 156,
    range: {max_eq: 968, decimalsCount: 2},
    per_item_price: 1 / 12,
    per_item_price_settings: {is_visible: false},
    active: false,
    ag_cost: {[CHURCH_TAX_TYPES[0].id]: 0.0220, [CHURCH_TAX_TYPES[1].id]: 0.0236, [CHURCH_TAX_TYPES[2].id]: 0.0239}
  }, // VN-744
  total: 0,
  ag_cost_total: 0
};

export const DEFAULT_GOAL_BUDGET = angular.copy(DEFAULT_DIRECT_BUDGET);

const $inject = []
export default class DatevCalculatorService {
  constructor(props = {}) {

    this.input = null;
    this.output = null;
    this.values = {

      ALTE: new BigDecimal(0),

      ANP: new BigDecimal(0),

      ANTEIL1: new BigDecimal(0),

      BMG: new BigDecimal(0),

      BBGKVPV: new BigDecimal(0),

      BBGRV: new BigDecimal(0),

      DIFF: new BigDecimal(0),

      EFA: new BigDecimal(0),

      FVB: new BigDecimal(0),


      FVBSO: new BigDecimal(0),

      FVBZ: new BigDecimal(0),

      FVBZSO: new BigDecimal(0),

      GFB: new BigDecimal(0),

      HBALTE: new BigDecimal(0),

      HFVB: new BigDecimal(0),

      HFVBZ: new BigDecimal(0),

      HFVBZSO: new BigDecimal(0),

      J: 0,

      JBMG: new BigDecimal(0),

      JLFREIB: new BigDecimal(0),

      JLHINZU: new BigDecimal(0),

      JW: new BigDecimal(0),

      K: 0,

      KENNVMT: 0,

      KFB: new BigDecimal(0),

      KVSATZAG: new BigDecimal(0),

      KVSATZAN: new BigDecimal(0),

      KZTAB: 0,

      LSTJAHR: new BigDecimal(0),

      LST1: new BigDecimal(0),

      LST2: new BigDecimal(0),

      LST3: new BigDecimal(0),

      LSTOSO: new BigDecimal(0),

      LSTSO: new BigDecimal(0),

      MIST: new BigDecimal(0),

      PVSATZAG: new BigDecimal(0),

      PVSATZAN: new BigDecimal(0),

      RVSATZAN: new BigDecimal(0),

      RW: new BigDecimal(0),

      SAP: new BigDecimal(0),

      SOLZFREI: new BigDecimal(0),

      SOLZJ: new BigDecimal(0),

      SOLZMIN: new BigDecimal(0),

      SOLZSBMG: new BigDecimal(0),

      SOLZSZVE: new BigDecimal(0),

      SOLZVBMG: new BigDecimal(0),

      ST: new BigDecimal(0),

      ST1: new BigDecimal(0),

      ST2: new BigDecimal(0),

      STOVMT: new BigDecimal(0),

      TBSVORV: new BigDecimal(0),

      VBEZB: new BigDecimal(0),

      VBEZBSO: new BigDecimal(0),

      VHB: new BigDecimal(0),

      VSP: new BigDecimal(0),

      VSPN: new BigDecimal(0),

      VSP1: new BigDecimal(0),

      VSP2: new BigDecimal(0),

      VSP3: new BigDecimal(0),

      W1STKL5: new BigDecimal(0),

      W2STKL5: new BigDecimal(0),

      W3STKL5: new BigDecimal(0),

      VSPMAX1: new BigDecimal(0),

      VSPMAX2: new BigDecimal(0),

      VSPO: new BigDecimal(0),

      VSPREST: new BigDecimal(0),

      VSPVOR: new BigDecimal(0),

      X: new BigDecimal(0),

      Y: new BigDecimal(0),

      ZRE4: new BigDecimal(0),

      ZRE4J: new BigDecimal(0),

      ZRE4VP: new BigDecimal(0),

      ZTABFB: new BigDecimal(0),

      ZVBEZ: new BigDecimal(0),

      ZVBEZJ: new BigDecimal(0),

      ZVE: new BigDecimal(0),

      ZX: new BigDecimal(0),

      ZZX: new BigDecimal(0),

      HOCH: new BigDecimal(0),

      VERGL: new BigDecimal(0),

      VKV: new BigDecimal(0),

      TAB1: [
        new BigDecimal('0.0'),
        new BigDecimal('0.4'),
        new BigDecimal('0.384'),
        new BigDecimal('0.368'),
        new BigDecimal('0.352'),
        new BigDecimal('0.336'),
        new BigDecimal('0.32'),
        new BigDecimal('0.304'),
        new BigDecimal('0.288'),
        new BigDecimal('0.272'),
        new BigDecimal('0.256'),
        new BigDecimal('0.24'),
        new BigDecimal('0.224'),
        new BigDecimal('0.208'),
        new BigDecimal('0.192'),
        new BigDecimal('0.176'),
        new BigDecimal('0.16'),
        new BigDecimal('0.152'),
        new BigDecimal('0.144'),
        new BigDecimal('0.136'),
        new BigDecimal('0.128'),
        new BigDecimal('0.12'),
        new BigDecimal('0.112'),
        new BigDecimal('0.104'),
        new BigDecimal('0.096'),
        new BigDecimal('0.088'),
        new BigDecimal('0.08'),
        new BigDecimal('0.072'),
        new BigDecimal('0.064'),
        new BigDecimal('0.056'),
        new BigDecimal('0.048'),
        new BigDecimal('0.04'),
        new BigDecimal('0.032'),
        new BigDecimal('0.024'),
        new BigDecimal('0.016'),
        new BigDecimal('0.008'),
        new BigDecimal('0.0')
      ],
      TAB2: [
        new BigDecimal('0'),
        new BigDecimal('3000'),
        new BigDecimal('2880'),
        new BigDecimal('2760'),
        new BigDecimal('2640'),
        new BigDecimal('2520'),
        new BigDecimal('2400'),
        new BigDecimal('2280'),
        new BigDecimal('2160'),
        new BigDecimal('2040'),
        new BigDecimal('1920'),
        new BigDecimal('1800'),
        new BigDecimal('1680'),
        new BigDecimal('1560'),
        new BigDecimal('1440'),
        new BigDecimal('1320'),
        new BigDecimal('1200'),
        new BigDecimal('1140'),
        new BigDecimal('1080'),
        new BigDecimal('1020'),
        new BigDecimal('960'),
        new BigDecimal('900'),
        new BigDecimal('840'),
        new BigDecimal('780'),
        new BigDecimal('720'),
        new BigDecimal('660'),
        new BigDecimal('600'),
        new BigDecimal('540'),
        new BigDecimal('480'),
        new BigDecimal('420'),
        new BigDecimal('360'),
        new BigDecimal('300'),
        new BigDecimal('240'),
        new BigDecimal('180'),
        new BigDecimal('120'),
        new BigDecimal('60'),
        new BigDecimal('0')
      ],
      TAB3: [
        new BigDecimal('0'),
        new BigDecimal('900'),
        new BigDecimal('864'),
        new BigDecimal('828'),
        new BigDecimal('792'),
        new BigDecimal('756'),
        new BigDecimal('720'),
        new BigDecimal('684'),
        new BigDecimal('648'),
        new BigDecimal('612'),
        new BigDecimal('576'),
        new BigDecimal('540'),
        new BigDecimal('504'),
        new BigDecimal('468'),
        new BigDecimal('432'),
        new BigDecimal('396'),
        new BigDecimal('360'),
        new BigDecimal('342'),
        new BigDecimal('324'),
        new BigDecimal('306'),
        new BigDecimal('288'),
        new BigDecimal('270'),
        new BigDecimal('252'),
        new BigDecimal('234'),
        new BigDecimal('216'),
        new BigDecimal('198'),
        new BigDecimal('180'),
        new BigDecimal('162'),
        new BigDecimal('144'),
        new BigDecimal('126'),
        new BigDecimal('108'),
        new BigDecimal('90'),
        new BigDecimal('72'),
        new BigDecimal('54'),
        new BigDecimal('36'),
        new BigDecimal('18'),
        new BigDecimal('0')
      ],
      TAB4: [
        new BigDecimal('0.0'),
        new BigDecimal('0.4'),
        new BigDecimal('0.384'),
        new BigDecimal('0.368'),
        new BigDecimal('0.352'),
        new BigDecimal('0.336'),
        new BigDecimal('0.32'),
        new BigDecimal('0.304'),
        new BigDecimal('0.288'),
        new BigDecimal('0.272'),
        new BigDecimal('0.256'),
        new BigDecimal('0.24'),
        new BigDecimal('0.224'),
        new BigDecimal('0.208'),
        new BigDecimal('0.192'),
        new BigDecimal('0.176'),
        new BigDecimal('0.16'),
        new BigDecimal('0.152'),
        new BigDecimal('0.144'),
        new BigDecimal('0.136'),
        new BigDecimal('0.128'),
        new BigDecimal('0.12'),
        new BigDecimal('0.112'),
        new BigDecimal('0.104'),
        new BigDecimal('0.096'),
        new BigDecimal('0.088'),
        new BigDecimal('0.08'),
        new BigDecimal('0.072'),
        new BigDecimal('0.064'),
        new BigDecimal('0.056'),
        new BigDecimal('0.048'),
        new BigDecimal('0.04'),
        new BigDecimal('0.032'),
        new BigDecimal('0.024'),
        new BigDecimal('0.016'),
        new BigDecimal('0.008'),
        new BigDecimal('0.0')
      ],
      TAB5: [
        new BigDecimal('0'),
        new BigDecimal('1900'),
        new BigDecimal('1824'),
        new BigDecimal('1748'),
        new BigDecimal('1672'),
        new BigDecimal('1596'),
        new BigDecimal('1520'),
        new BigDecimal('1444'),
        new BigDecimal('1368'),
        new BigDecimal('1292'),
        new BigDecimal('1216'),
        new BigDecimal('1140'),
        new BigDecimal('1064'),
        new BigDecimal('988'),
        new BigDecimal('912'),
        new BigDecimal('836'),
        new BigDecimal('760'),
        new BigDecimal('722'),
        new BigDecimal('684'),
        new BigDecimal('646'),
        new BigDecimal('608'),
        new BigDecimal('570'),
        new BigDecimal('532'),
        new BigDecimal('494'),
        new BigDecimal('456'),
        new BigDecimal('418'),
        new BigDecimal('380'),
        new BigDecimal('342'),
        new BigDecimal('304'),
        new BigDecimal('266'),
        new BigDecimal('228'),
        new BigDecimal('190'),
        new BigDecimal('152'),
        new BigDecimal('114'),
        new BigDecimal('76'),
        new BigDecimal('38'),
        new BigDecimal('0')
      ],

      ZAHL1: new BigDecimal(BigDecimal.ONE().toString()),

      ZAHL2: new BigDecimal(2),

      ZAHL5: new BigDecimal(5),

      ZAHL7: new BigDecimal(7),

      ZAHL12: new BigDecimal(12),

      ZAHL100: new BigDecimal(100),

      ZAHL360: new BigDecimal(360),

      ZAHL500: new BigDecimal(500),

      ZAHL700: new BigDecimal(700),

      ZAHL1000: new BigDecimal(1000),

      ZAHL1200: new BigDecimal(1200),

      ZAHL10000: new BigDecimal(10000),
    };


    Object.keys(props).map(key => {
      if (this[key] instanceof BigDecimal) {
        this[key] = new BigDecimal(props[key]);
      } else {
        this[key] = props[key];
      }
    });

  }

  calculateForPotential(potential) {
    let bruttoEUR = potential.brutto;
    let bruttoCENT = bruttoEUR * 100;
    let annualsEUR = potential.annuals;
    let annualsCENT = annualsEUR * 100;
    let calculatorInput = {
      RE4: bruttoCENT,
      STKL: potential.pap_stkl,
      R: potential.pap_r ? 1 : 0,
      PKV: potential.health_insurance,
      KRV: potential.pension_insurance,
      KVZ: potential.additional_rate_health_insurance * 100,
      PKPV: potential.monthly_contribution * 100,
      PVS: potential.care_insurance === 0 || potential.care_insurance === 1 ? 0 : 1,
      PVZ: potential.care_insurance === 0 || potential.care_insurance === 2 ? 0 : 1,
      LZZ: 2,
      f: potential.pap_stkl !== SteuerklassenConstants[3].value ? potential.factor : 1,
      af: potential.pap_stkl !== SteuerklassenConstants[3].value ? 1 : 0,
      LZZFREIB: annualsCENT,
      ZKF: potential.child_tax_credit
    };

    // set up the benefits_amount
    let benefits_amount = 0;

    if (potential.budget === BUDGET_TYPES[1].value) { // Budgetberechnung
      if (potential.raise.type === RAISE_TYPES[1].value) {
        benefits_amount = potential.raise.value * 100;
      } else if (potential.raise.type === RAISE_TYPES[0].value) {
        benefits_amount = potential.raise.value * bruttoCENT;
      }
    } else if (potential.budget === BUDGET_TYPES_BY_NAME['gehaltsextras'].value) { // gehaltsextras
      benefits_amount = 100 * Object.values(potential.direct).reduce((acc, direct_option) => {
        if (direct_option.active) {
          acc += direct_option.result;
        }
        return acc;
      }, 0);
    } else if (potential.budget === BUDGET_TYPES_BY_NAME['neueinstellung'].value) {
      benefits_amount = 100 * Object.values(potential.goal_direct).reduce((acc, direct_option) => {
        if (direct_option.active) {
          acc += direct_option.result;
        }
        return acc;
      }, 0);
    } else {
      throw new Error('Not Implemented');
    }

    let CALC_INPUT_BEFORE = new DatevCalculatorInput(calculatorInput);

    let CALC_INPUT_NORMAL = new DatevCalculatorInput(
      Object.assign({}, calculatorInput, {
        RE4: calculatorInput.RE4 + benefits_amount
      })
    );

    let PAP_CALC_RESULT_BEFORE = this.calculate(CALC_INPUT_BEFORE);
    let PAP_CALC_RESULT_NORMAL = this.calculate(CALC_INPUT_NORMAL);

    let SOCIAL_CALC_RESULT_BEFORE = this.calculateSocial(CALC_INPUT_BEFORE, PAP_CALC_RESULT_BEFORE, 0, potential);
    let SOCIAL_CALC_RESULT_NORMAL = this.calculateSocial(CALC_INPUT_NORMAL, PAP_CALC_RESULT_NORMAL, 0, potential);

    if (potential.calculation_settings.type === CALCULATION_TYPES_BY_VALUE['standard']['value']) {

    }

    let CALC_INPUT_OPTIMIZED = undefined;
    let SOCIAL_CALC_RESULT_OPTIMIZED = undefined;
    let PAP_CALC_RESULT_OPTIMIZED = undefined;
    let agCostCalculation = undefined;
    let optimizedVerandurengunAg = undefined;
    let optimizedVerandurengunAn = undefined;
    let is_goal_calculation = potential.calculation_settings.type === CALCULATION_TYPES_BY_VALUE['goal']['value'];
    let allowed_error = potential.calculation_settings.allowed_error_eur * 100;
    let goal = potential.calculation_settings.goal * 100;
    let is_goal_reached = false;
    let optimized_benefits_amount = benefits_amount;

    do {
      CALC_INPUT_OPTIMIZED = new DatevCalculatorInput(calculatorInput);
      PAP_CALC_RESULT_OPTIMIZED = this.calculate(CALC_INPUT_OPTIMIZED);

      // calculate optimized
      let verandurengunAn = null;
      let verandurengunAg = null;

      if (potential.budget === BUDGET_TYPES[1].value) {
        verandurengunAg = Math.abs(SOCIAL_CALC_RESULT_BEFORE.AGKOSTEN - SOCIAL_CALC_RESULT_NORMAL.AGKOSTEN);
        verandurengunAn = Math.abs(SOCIAL_CALC_RESULT_NORMAL.NET - SOCIAL_CALC_RESULT_BEFORE.NET);
        optimized_benefits_amount = verandurengunAn + (verandurengunAg - verandurengunAn) * (potential.advantage / 100);
      }

      SOCIAL_CALC_RESULT_OPTIMIZED = this.calculateSocial(CALC_INPUT_OPTIMIZED, PAP_CALC_RESULT_OPTIMIZED, optimized_benefits_amount, potential);

      agCostCalculation = SOCIAL_CALC_RESULT_OPTIMIZED.AGKOSTEN + (potential.budget === BUDGET_TYPES[0].value ? potential.direct.ag_cost_total * 100 : 0);

      optimizedVerandurengunAg = SOCIAL_CALC_RESULT_NORMAL.AGKOSTEN - agCostCalculation;
      optimizedVerandurengunAn = Math.abs(SOCIAL_CALC_RESULT_NORMAL.NET - SOCIAL_CALC_RESULT_OPTIMIZED.NET);

      if (is_goal_calculation) {
        let employee_costs_difference_with_before = agCostCalculation - SOCIAL_CALC_RESULT_BEFORE.AGKOSTEN;
        let goal_error = employee_costs_difference_with_before - goal;
        let abs_goal_error = Math.abs(goal_error);
        is_goal_reached = abs_goal_error < allowed_error;

        if (!is_goal_reached) {

          let reduction_direction = 1; // 1 -> decrease, -1 -> increase
          // if there a net positive effect for the employer we can reduce the brutto salary even more
          if (goal_error < 0) {
            reduction_direction = -1;
          }

          // amount with which we will reduce the salary
          let reduction_amount = 1;
          if (goal_error / 1000 > 1) {
            reduction_amount = 1000;
          } else if (goal_error / 100 > 1) {
            reduction_amount = 100;
          } else if (goal_error / 10 > 1) {
            reduction_amount = 10;
          }

          calculatorInput = Object.assign({}, calculatorInput, {
            RE4: calculatorInput.RE4 - reduction_amount * reduction_direction
          });
        }
      }

    } while (is_goal_calculation && !is_goal_reached && calculatorInput.RE4 > 0);

    if (is_goal_calculation && !is_goal_reached) {
      throw new Error('could not reach goal');
    }


    // // data for display
    let tableData = {
      calculation_settings: {
        is_goal_calculation: is_goal_calculation,
        benefits_amount: benefits_amount,
        optimized_benefits_amount: optimized_benefits_amount
      },
      before: {
        brutto: CALC_INPUT_BEFORE.RE4,
        raise_value: 0,
        benefit: SOCIAL_CALC_RESULT_BEFORE.BENEFIT,
        lst: PAP_CALC_RESULT_BEFORE.LSTLZZ,
        kst: PAP_CALC_RESULT_BEFORE.BK * SOCIAL_CALC_RESULT_BEFORE.KIST_PERC,
        SolZ: PAP_CALC_RESULT_BEFORE.SOLZLZZ,
        sv: SOCIAL_CALC_RESULT_BEFORE.SVAN,
        ag_cost: SOCIAL_CALC_RESULT_BEFORE.AGKOSTEN,
        netto: SOCIAL_CALC_RESULT_BEFORE.NET,
      },
      normal: {
        brutto: CALC_INPUT_NORMAL.RE4,
        raise_value: CALC_INPUT_NORMAL.RE4 - CALC_INPUT_BEFORE.RE4,
        benefit: SOCIAL_CALC_RESULT_NORMAL.BENEFIT,
        lst: PAP_CALC_RESULT_NORMAL.LSTLZZ,
        kst: PAP_CALC_RESULT_NORMAL.BK * SOCIAL_CALC_RESULT_NORMAL.KIST_PERC,
        SolZ: PAP_CALC_RESULT_NORMAL.SOLZLZZ,
        sv: SOCIAL_CALC_RESULT_NORMAL.SVAN,
        ag_cost: SOCIAL_CALC_RESULT_NORMAL.AGKOSTEN,
        netto: SOCIAL_CALC_RESULT_NORMAL.NET,
        change_ag: SOCIAL_CALC_RESULT_BEFORE.AGKOSTEN - SOCIAL_CALC_RESULT_NORMAL.AGKOSTEN,
        change_an: SOCIAL_CALC_RESULT_NORMAL.NET - SOCIAL_CALC_RESULT_BEFORE.NET
      },
      optimized: {
        brutto: CALC_INPUT_OPTIMIZED.RE4,
        raise_value: 0,
        benefit: SOCIAL_CALC_RESULT_OPTIMIZED.BENEFIT,
        lst: PAP_CALC_RESULT_OPTIMIZED.LSTLZZ,
        kst: PAP_CALC_RESULT_OPTIMIZED.BK * SOCIAL_CALC_RESULT_OPTIMIZED.KIST_PERC,
        SolZ: PAP_CALC_RESULT_OPTIMIZED.SOLZLZZ,
        sv: SOCIAL_CALC_RESULT_OPTIMIZED.SVAN,
        netto: SOCIAL_CALC_RESULT_OPTIMIZED.NET,
        ag_cost: agCostCalculation, // add here the sum of all ag_costs from the directBudget fields
        change_ag: SOCIAL_CALC_RESULT_BEFORE.AGKOSTEN - SOCIAL_CALC_RESULT_OPTIMIZED.AGKOSTEN,
        change_an: SOCIAL_CALC_RESULT_OPTIMIZED.NET - SOCIAL_CALC_RESULT_BEFORE.NET,
        taxes: (SOCIAL_CALC_RESULT_OPTIMIZED.AGKOSTEN - agCostCalculation), // VN-544 -> VN-744
        direct: potential.direct,
        goal_direct: potential.goal_direct,
        verandurengunAn: optimizedVerandurengunAn,
        verandurengunAg: optimizedVerandurengunAg
      },
    };

    return tableData;

  }


  calculate(input = {}) {
    this.output = new DatevCalculatorOutput();
    this.input = input;

    this.mpara();
    this.mre4jl();
    this.values.VBEZBSO = BigDecimal.ZERO();
    this.values.KENNVMT = 0;
    this.mre4();
    this.mre4abz();
    this.mberech();
    this.msonst();
    this.mvmt();

    return this.output;
  }

  calculateSocial(inputCalc, pap_result, BENEFIT, potential) {
    // from input
    BENEFIT = parseFloat(BENEFIT);
    let RE4 = parseFloat(inputCalc.RE4);
    let KVSATZAG = (14.6 + parseFloat(inputCalc.KVZ)) / (2 * 100); // divide by 100 because the KVZ is * 100 in the inputCalc because of the PAP calc
    let KVSATZAN = (14.6 + parseFloat(inputCalc.KVZ)) / (2 * 100);
    let RVSATZAN = 9.3 / 100;
    let RVSATZAG = 9.3 / 100;
    let PVSATZAN = 1.525 / 100;
    let PVZUSATZ = parseFloat(inputCalc.PVZ) === 1 ? 0.25 / 100 : 0;
    let PVSATZAG = 1.525 / 100;
    let AVSATZAG = 1.20 / 100;
    let AVSATZAN = 1.20 / 100;
    let KIST_PERC = potential.pap_r;

    // base 1

    let base = {
      BENEFIT: BENEFIT,
      SVAN_KV: 0,
      SVAN_RV: 0,
      SVAG_KV: 0,
      SVAG_RV: 0,
      SVAN: 0,
      SVAG: 0
    };

    let svkv_brutto_limit = 483750;
    let svkv_brutto = RE4;
    if (svkv_brutto > svkv_brutto_limit) {
      svkv_brutto = svkv_brutto_limit;
    }
    base.SVAN_KV = svkv_brutto * (KVSATZAN + PVSATZAN + PVZUSATZ);
    base.SVAG_KV = svkv_brutto * (KVSATZAG + PVSATZAG);

    if (potential.health_insurance === HEALTH_INSURANCE[1].value) {
      base.SVAN_KV = 0;
      base.SVAG_KV = 0;
    }

    let svrv_brutto_limit = potential.pension_insurance === PENSION_INSURANCE[0].value ? 710000 : 670000;
    let svrv_brutto = RE4;
    if (svrv_brutto > svrv_brutto_limit) {
      svrv_brutto = svrv_brutto_limit;
    }
    base.SVAN_RV = svrv_brutto * (RVSATZAN + AVSATZAN);
    base.SVAG_RV = svrv_brutto * (RVSATZAG + AVSATZAG);


    base.SVAN = base.SVAN_KV + base.SVAN_RV;
    base.SVAG = base.SVAG_KV + base.SVAG_RV;


    base.NET = RE4 - parseFloat(pap_result.LSTLZZ) - parseFloat(pap_result.BK) * KIST_PERC - parseFloat(pap_result.SOLZLZZ) - base.SVAN + base.BENEFIT;
    base.AGKOSTEN = RE4 + base.SVAG + base.BENEFIT;
    base.KIST_PERC = KIST_PERC;

    return base;
  }

  mpara() {
    if (this.input.KRV < 2) {
      if (this.input.KRV === 0) {
    //NEU 2022
    this.values.BBGRV = new BigDecimal('84600');
      } else {
        this.values.BBGRV = new BigDecimal('81000');
      }

      this.values.RVSATZAN = new BigDecimal('0.093');
    //NEU 2022
    this.values.TBSVORV = new BigDecimal('0.88');
    }

    this.values.BBGKVPV = new BigDecimal('58050');

    this.values.KVSATZAN = this.input.KVZ.divide(this.values.ZAHL2)

      .divide(this.values.ZAHL100)
      .add(new BigDecimal('0.07'));

    this.values.KVSATZAG = new BigDecimal('0.07')
      .add(new BigDecimal('0.0065'));

    if (this.input.PVS === 1) {
      this.values.PVSATZAN = new BigDecimal('0.02025');
      this.values.PVSATZAG = new BigDecimal('0.01025');
    } else {
      this.values.PVSATZAN = new BigDecimal('0.01525');
      this.values.PVSATZAG = new BigDecimal('0.01525');
    }

    if (this.input.PVZ === 1) {
      this.values.PVSATZAN = this.values.PVSATZAN
    //NEU 2022
        .add(new BigDecimal('0.0035'));
    }
    // Beginn NEU 2022
    this.values.W1STKL5 = new BigDecimal('11793');
    this.values.W2STKL5 = new BigDecimal('29298');
    this.values.W3STKL5 = new BigDecimal('222260');
    this.values.GFB = new BigDecimal('10347');
    // Ende NEU 2022
    this.values.SOLZFREI = new BigDecimal('16956');
  }

  mre4jl() {
    if (this.input.LZZ === 1) {
      this.values.ZRE4J = this.input.RE4.divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
      this.values.ZVBEZJ = this.input.VBEZ.divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
      this.values.JLFREIB = this.input.LZZFREIB.divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
      this.values.JLHINZU = this.input.LZZHINZU.divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
    } else {
      if (this.input.LZZ === 2) {
        this.values.ZRE4J = this.input.RE4.multiply(this.values.ZAHL12)
          .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);

        this.values.ZVBEZJ = this.input.VBEZ.multiply(this.values.ZAHL12)
          .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);

        this.values.JLFREIB = this.input.LZZFREIB.multiply(this.values.ZAHL12)
          .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);

        this.values.JLHINZU = this.input.LZZHINZU.multiply(this.values.ZAHL12)
          .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
      } else {
        if (this.input.LZZ === 3) {
          this.values.ZRE4J = this.input.RE4
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL700, 2, BigDecimal.ROUND_DOWN);

          this.values.ZVBEZJ = this.input.VBEZ
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL700, 2, BigDecimal.ROUND_DOWN);

          this.values.JLFREIB = this.input.LZZFREIB
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL700, 2, BigDecimal.ROUND_DOWN);

          this.values.JLHINZU = this.input.LZZHINZU
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL700, 2, BigDecimal.ROUND_DOWN);
        } else {
          this.values.ZRE4J = this.input.RE4
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);

          this.values.ZVBEZJ = this.input.VBEZ
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);

          this.values.JLFREIB = this.input.LZZFREIB
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);

          this.values.JLHINZU = this.input.LZZHINZU
            .multiply(this.values.ZAHL360)
            .divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
        }
      }
    }

    if (this.input.af === 0) {
      this.input.f = 1.0;
    }
  }

  mre4() {
    if (this.values.ZVBEZJ.compareTo(BigDecimal.ZERO()) === 0) {
      this.values.FVBZ = BigDecimal.ZERO();
      this.values.FVB = BigDecimal.ZERO();
      this.values.FVBZSO = BigDecimal.ZERO();
      this.values.FVBSO = BigDecimal.ZERO();
    } else {
      if (this.input.VJAHR < 2006) {
        this.values.J = 1;
      } else {
        if (this.input.VJAHR < 2040) {
          this.values.J = this.input.VJAHR - 2004;
        } else {
          this.values.J = 36;
        }
      }

      if (this.input.LZZ === 1) {
        this.values.VBEZB = this.input.VBEZM
          .multiply(new BigDecimal(this.input.ZMVB))
          .add(this.input.VBEZS);

        this.values.HFVB = this.values.TAB2[this.values.J]
          .divide(this.values.ZAHL12)
          .multiply(new BigDecimal(this.input.ZMVB));

        this.values.FVBZ = this.values.TAB3[this.values.J]
          .divide(this.values.ZAHL12)
          .multiply(new BigDecimal(this.input.ZMVB))
          .setScale(0, BigDecimal.ROUND_UP);
      } else {
        this.values.VBEZB = this.input.VBEZM
          .multiply(this.values.ZAHL12)
          .add(this.input.VBEZS)
          .setScale(2, BigDecimal.ROUND_DOWN);

        this.values.HFVB = this.values.TAB2[this.values.J];
        this.values.FVBZ = this.values.TAB3[this.values.J];
      }

      this.values.FVB = this.values.VBEZB
        .multiply(this.values.TAB1[this.values.J])
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_UP);

      if (this.values.FVB.compareTo(this.values.HFVB) === 1) {
        this.values.FVB = this.values.HFVB;
      }

      if (this.values.FVB.compareTo(this.values.ZVBEZJ) === 1) {
        this.values.FVB = this.values.ZVBEZJ;
      }

      this.values.FVBSO = this.values.FVB
        .add(this.values.VBEZBSO.multiply(this.values.TAB1[this.values.J]))
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_UP);

      if (this.values.FVBSO.compareTo(this.values.TAB2[this.values.J]) === 1) {
        this.values.FVBSO = this.values.TAB2[this.values.J];
      }

      this.values.HFVBZSO = this.values.VBEZB
        .add(this.values.VBEZBSO)
        .divide(this.values.ZAHL100)
        .subtract(this.values.FVBSO)
        .setScale(2, BigDecimal.ROUND_DOWN);

      this.values.FVBZSO = this.values.FVBZ
        .add(this.values.VBEZBSO)
        .divide(this.values.ZAHL100)
        .setScale(0, BigDecimal.ROUND_UP);

      if (this.values.FVBZSO.compareTo(this.values.HFVBZSO) === 1) {
        this.values.FVBZSO = this.values.HFVBZSO
          .setScale(0, BigDecimal.ROUND_UP);
      }

      if (this.values.FVBZSO.compareTo(this.values.TAB3[this.values.J]) === 1) {
        this.values.FVBZSO = this.values.TAB3[this.values.J];
      }

      this.values.HFVBZ = this.values.VBEZB
        .divide(this.values.ZAHL100)
        .subtract(this.values.FVB)
        .setScale(2, BigDecimal.ROUND_DOWN);

      if (this.values.FVBZ.compareTo(this.values.HFVBZ) === 1) {
        this.values.FVBZ = this.values.HFVBZ
          .setScale(0, BigDecimal.ROUND_UP);
      }
    }

    this.mre4alte();
  }


  mre4alte() {
    if (this.input.ALTER1 === 0) {
      this.values.ALTE = BigDecimal.ZERO();
    } else {
      if (this.input.AJAHR < 2006) {
        this.values.K = 1;
      } else {
        if (this.input.AJAHR < 2040) {
          this.values.K = this.input.AJAHR - 2004;
        } else {
          this.values.K = 36;
        }
      }

      this.values.BMG = this.values.ZRE4J.subtract(this.values.ZVBEZJ);
      this.values.ALTE = this.values.BMG
        .multiply(this.values.TAB4[this.values.K])
        .setScale(0, BigDecimal.ROUND_UP);
      this.values.HBALTE = this.values.TAB5[this.values.K];

      if (this.values.ALTE.compareTo(this.values.HBALTE) === 1) {
        this.values.ALTE = this.values.HBALTE;
      }
    }
  }



  mre4abz() {
    this.values.ZRE4 = this.values.ZRE4J
      .subtract(this.values.FVB)
      .subtract(this.values.ALTE)
      .subtract(this.values.JLFREIB)
      .add(this.values.JLHINZU)
      .setScale(2, BigDecimal.ROUND_DOWN);

    if (this.values.ZRE4.compareTo(BigDecimal.ZERO()) === -1) {
      this.values.ZRE4 = BigDecimal.ZERO();
    }

    this.values.ZRE4VP = this.values.ZRE4J;

    if (this.values.KENNVMT === 2) {
      this.values.ZRE4VP = this.values.ZRE4VP
        .subtract(this.input.ENTSCH.divide(this.values.ZAHL100))
        .setScale(2, BigDecimal.ROUND_DOWN);
    }

    this.values.ZVBEZ = this.values.ZVBEZJ
      .subtract(this.values.FVB)
      .setScale(2, BigDecimal.ROUND_DOWN);

    if (this.values.ZVBEZ.compareTo(BigDecimal.ZERO()) === -1) {
      this.values.ZVBEZ = BigDecimal.ZERO();
    }
  }


  mberech() {
    this.mztabfb();
    this.output.VFRB = this.values.ANP
      .add(this.values.FVB.add(this.values.FVBZ))
      .multiply(this.values.ZAHL100)
      .setScale(0, BigDecimal.ROUND_DOWN);

    this.mlstjahr();

    this.output.WVFRB = this.values.ZVE
      .subtract(this.values.GFB)
      .multiply(this.values.ZAHL100)
      .setScale(0, BigDecimal.ROUND_DOWN);

    if (this.output.WVFRB.compareTo(BigDecimal.ZERO()) === -1) {
      this.output.WVFRB = new BigDecimal('0');
    }

    this.values.LSTJAHR = this.values.ST
      .multiply(new BigDecimal(this.input.f))
      .setScale(0, BigDecimal.ROUND_DOWN);

    this.uplstlzz();
    this.upvkvlzz();

    if (this.input.ZKF.compareTo(BigDecimal.ZERO()) === 1) {
      this.values.ZTABFB = this.values.ZTABFB.add(this.values.KFB);
      this.mre4abz();
      this.mlstjahr();
      this.values.JBMG = this.values.ST
        .multiply(new BigDecimal(this.input.f))
        .setScale(0, BigDecimal.ROUND_DOWN);
    } else {
      this.values.JBMG = this.values.LSTJAHR;
    }

    this.msolz();
  }

  mztabfb() {
    this.values.ANP = BigDecimal.ZERO();

    if (this.values.ZVBEZ.compareTo(BigDecimal.ZERO()) >= 0 && this.values.ZVBEZ.compareTo(this.values.FVBZ) === -1) {
      this.values.FVBZ = new BigDecimal(this.values.ZVBEZ.longValue());
    }

    if (this.input.STKL < 6) {
      if (this.values.ZVBEZ.compareTo(BigDecimal.ZERO()) === 1) {
        if (this.values.ZVBEZ.subtract(this.values.FVBZ).compareTo(new BigDecimal('102')) === -1) {
          this.values.ANP = this.values.ZVBEZ
            .subtract(this.values.FVBZ)
            .setScale(0, BigDecimal.ROUND_UP);
        } else {
          this.values.ANP = new BigDecimal('102');
        }
      }
    } else {
      this.values.FVBZ = new BigDecimal('0');
      this.values.FVBZSO = new BigDecimal('0');
    }


    if (this.input.STKL < 6) {
      if (this.values.ZRE4.compareTo(this.values.ZVBEZ) === 1) {
        if (this.values.ZRE4.subtract(this.values.ZVBEZ).compareTo(this.values.ZAHL1200) === -1) {
          this.values.ANP = this.values.ANP
            .add(this.values.ZRE4)
            .subtract(this.values.ZVBEZ)
            .setScale(0, BigDecimal.ROUND_UP);
        } else {
          this.values.ANP = this.values.ANP.add(this.values.ZAHL1200);
        }
      }
    }

    this.values.KZTAB = 1;

    if (this.input.STKL === 1) {
      this.values.SAP = new BigDecimal('36');
      this.values.KFB = this.input.ZKF
        .multiply(new BigDecimal('8388'))
        .setScale(0, BigDecimal.ROUND_DOWN);
    } else {
      if (this.input.STKL === 2) {
        // NEU 2022
        this.values.EFA = new BigDecimal('4008');
        this.values.SAP = new BigDecimal('36');
        this.values.KFB = this.input.ZKF
          .multiply(new BigDecimal('8388'))
          .setScale(0, BigDecimal.ROUND_DOWN);
      } else {
        if (this.input.STKL === 3) {
          this.values.KZTAB = 2;
          this.values.SAP = new BigDecimal('36');
          this.values.KFB = this.input.ZKF
            .multiply(new BigDecimal('8388'))
            .setScale(0, BigDecimal.ROUND_DOWN);
        } else {
          if (this.input.STKL === 4) {
            this.values.SAP = new BigDecimal('36');
            this.values.KFB = this.input.ZKF
              .multiply(new BigDecimal('4194'))
              .setScale(0, BigDecimal.ROUND_DOWN);
          } else {
            if (this.input.STKL === 5) {
              this.values.SAP = new BigDecimal('36');
              this.values.KFB = BigDecimal.ZERO();
            } else {
              this.values.KFB = BigDecimal.ZERO();
            }
          }
        }
      }
    }

    this.values.ZTABFB = this.values.EFA
      .add(this.values.ANP)
      .add(this.values.SAP)
      .add(this.values.FVBZ)
      .setScale(2, BigDecimal.ROUND_DOWN);

  }

  mlstjahr() {
    this.upevp();

    if (this.values.KENNVMT !== 1) {
      this.values.ZVE = this.values.ZRE4
        .subtract(this.values.ZTABFB)
        .subtract(this.values.VSP)
        .setScale(2, BigDecimal.ROUND_DOWN);

      this.upmlst();
    } else {
      this.values.ZVE = this.values.ZRE4
        .subtract(this.values.ZTABFB)
        .subtract(this.values.VSP)
        .subtract(this.input.VMT)
        .divide(this.values.ZAHL100)
        .subtract(this.input.VKAPA)
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);

      if (this.values.ZVE.compareTo(BigDecimal.ZERO()) === -1) {
        this.values.ZVE = this.values.ZVE
          .add(this.input.VMT.divide(this.values.ZAHL100))
          .add(this.input.VKAPA.divide(this.values.ZAHL100))
          .divide(this.values.ZAHL5)
          .setScale(2, BigDecimal.ROUND_DOWN);

        this.upmlst();

        this.values.ST = this.values.ST
          .multiply(this.values.ZAHL5)
          .setScale(0, BigDecimal.ROUND_DOWN);
      } else {
        this.upmlst();
        this.values.STOVMT = this.values.ST;
        this.values.ZVE = this.values.ZVE
          .add(this.input.VMT.add(this.input.VKAPA))
          .divide(this.values.ZAHL500)
          .setScale(2, BigDecimal.ROUND_DOWN);

        this.upmlst();
        this.values.ST = this.values.ST
          .subtract(this.values.STOVMT)
          .multiply(this.values.ZAHL5)
          .add(this.values.STOVMT)
          .setScale(0, BigDecimal.ROUND_DOWN);
      }
    }
  }

  upvkvlzz() {

    this.upvkv();
    this.values.JW = this.values.VKV;
    this.upanteil();
    this.output.VKVLZZ = this.values.ANTEIL1;
  }


  upvkv() {
    if (this.input.PKV > 0) {
      if (this.values.VSP2.compareTo(this.values.VSP3) === 1) {
        this.values.VKV = this.values.VSP2.multiply(this.values.ZAHL100);
      } else {
        this.values.VKV = this.values.VSP3.multiply(this.values.ZAHL100);
      }
    } else {
      this.values.VKV = BigDecimal.ZERO();
    }
  }

  uplstlzz() {
    this.values.JW = this.values.LSTJAHR.multiply(this.values.ZAHL100);
    this.upanteil();
    this.output.LSTLZZ = this.values.ANTEIL1;
  }


  upmlst() {
    if (this.values.ZVE.compareTo(this.values.ZAHL1) === -1) {
      this.values.ZVE = BigDecimal.ZERO();
      this.values.X = BigDecimal.ZERO();
    } else {
      this.values.X = this.values.ZVE
        .divide(new BigDecimal(this.values.KZTAB))
        .setScale(0, BigDecimal.ROUND_DOWN);
    }

    if (this.input.STKL < 5) {
      this.uptab22();
    } else {
      this.mst56();
    }
  }


  upevp() {
    if (this.input.KRV > 1) {
      this.values.VSP1 = BigDecimal.ZERO();
    } else {

      if (this.values.ZRE4VP.compareTo(this.values.BBGRV) === 1) {
        this.values.ZRE4VP = this.values.BBGRV;
      }

      this.values.VSP1 = this.values.TBSVORV.multiply(this.values.ZRE4VP)
        .setScale(2, BigDecimal.ROUND_DOWN);

      this.values.VSP1 = this.values.VSP1.multiply(this.values.RVSATZAN)
        .setScale(2, BigDecimal.ROUND_DOWN);
    }

    this.values.VSP2 = this.values.ZRE4VP
      .multiply(new BigDecimal('0.12'))
      .setScale(2, BigDecimal.ROUND_DOWN);

    if (this.input.STKL === 3) {
      this.values.VHB = new BigDecimal('3000');
    } else {
      this.values.VHB = new BigDecimal('1900');
    }

    if (this.values.VSP2.compareTo(this.values.VHB) === 1) {
      this.values.VSP2 = this.values.VHB;
    }

    this.values.VSPN = this.values.VSP1
      .add(this.values.VSP2)
      .setScale(0, BigDecimal.ROUND_UP);

    this.mvsp();

    if (this.values.VSPN.compareTo(this.values.VSP) === 1) {
      this.values.VSP = this.values.VSPN.setScale(2, BigDecimal.ROUND_DOWN);
    }
  }


  mvsp() {
    if (this.values.ZRE4VP.compareTo(this.values.BBGKVPV) === 1) {
      this.values.ZRE4VP = this.values.BBGKVPV;
    }

    if (this.input.PKV > 0) {
      if (this.input.STKL === 6) {
        this.values.VSP3 = BigDecimal.ZERO();
      } else {
        this.values.VSP3 = this.input.PKPV
          .multiply(this.values.ZAHL12)
          .divide(this.values.ZAHL100);

        if (this.input.PKV === 2) {
          this.values.VSP3 = this.values.VSP3
            .subtract(this.values.ZRE4VP.multiply(this.values.KVSATZAG.add(this.values.PVSATZAG)))
            .setScale(2, BigDecimal.ROUND_DOWN);
        }
      }
    } else {
      this.values.VSP3 = this.values.ZRE4VP
        .multiply(this.values.KVSATZAN.add(this.values.PVSATZAN))
        .setScale(2, BigDecimal.ROUND_DOWN);
    }

    this.values.VSP = this.values.VSP3
      .add(this.values.VSP1)
      .setScale(0, BigDecimal.ROUND_UP);
  }


  mst56() {
    this.values.ZZX = this.values.X;

    if (this.values.ZZX.compareTo(this.values.W2STKL5) === 1) {
      this.values.ZX = this.values.W2STKL5;
      this.up56();

      if (this.values.ZZX.compareTo(this.values.W3STKL5) === 1) {
        this.values.ST = this.values.ST
          .add(this.values.W3STKL5.subtract(this.values.W2STKL5).multiply(new BigDecimal('0.42')))
          .setScale(0, BigDecimal.ROUND_DOWN);

        this.values.ST = this.values.ST
          .add(this.values.ZZX.subtract(this.values.W3STKL5).multiply(new BigDecimal('0.45')))
          .setScale(0, BigDecimal.ROUND_DOWN);
      } else {
        this.values.ST = this.values.ST
          .add(this.values.ZZX.subtract(this.values.W2STKL5).multiply(new BigDecimal('0.42')))
          .setScale(0, BigDecimal.ROUND_DOWN);
      }
    } else {
      this.values.ZX = this.values.ZZX;
      this.up56();

      if (this.values.ZZX.compareTo(this.values.W1STKL5) === 1) {
        this.values.VERGL = this.values.ST;
        this.values.ZX = this.values.W1STKL5;
        this.up56();
        this.values.HOCH = this.values.ST
          .add(this.values.ZZX.subtract(this.values.W1STKL5).multiply(new BigDecimal('0.42')))
          .setScale(0, BigDecimal.ROUND_DOWN);

        if (this.values.HOCH.compareTo(this.values.VERGL) === -1) {
          this.values.ST = this.values.HOCH;
        } else {
          this.values.ST = this.values.VERGL;
        }
      }
    }
  }

  up56() {
    this.values.X = this.values.ZX
      .multiply(new BigDecimal('1.25'))
      .setScale(2, BigDecimal.ROUND_DOWN);

    this.uptab22();
    this.values.ST1 = this.values.ST;
    this.values.X = this.values.ZX
      .multiply(new BigDecimal('0.75'))
      .setScale(2, BigDecimal.ROUND_DOWN);

    this.uptab22();
    this.values.ST2 = this.values.ST;
    this.values.DIFF = this.values.ST1
      .subtract(this.values.ST2)
      .multiply(this.values.ZAHL2);

    this.values.MIST = this.values.ZX
      .multiply(new BigDecimal('0.14'))
      .setScale(0, BigDecimal.ROUND_DOWN);

    if (this.values.MIST.compareTo(this.values.DIFF) === 1) {
      this.values.ST = this.values.MIST;
    } else {
      this.values.ST = this.values.DIFF;
    }
  }

  msolz() {
    this.values.SOLZFREI = this.values.SOLZFREI
      .multiply(new BigDecimal(this.values.KZTAB));

    if (this.values.JBMG.compareTo(this.values.SOLZFREI) === 1) {
      this.values.SOLZJ = this.values.JBMG
        .multiply(new BigDecimal('5.5'))
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);
      this.values.SOLZMIN = this.values.JBMG
        .subtract(this.values.SOLZFREI)
        .multiply(new BigDecimal('20'))
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);

      if (this.values.SOLZMIN.compareTo(this.values.SOLZJ) === -1) {
        this.values.SOLZJ = this.values.SOLZMIN;
      }

      this.values.JW = this.values.SOLZJ
        .multiply(this.values.ZAHL100)
        .setScale(0, BigDecimal.ROUND_DOWN);

      this.upanteil();
      this.output.SOLZLZZ = this.values.ANTEIL1;
    } else {
      this.output.SOLZLZZ = BigDecimal.ZERO();
    }

    if (this.input.R > 0) {
      this.values.JW = this.values.JBMG.multiply(this.values.ZAHL100);
      this.upanteil();
      this.output.BK = this.values.ANTEIL1;
    } else {
      this.output.BK = BigDecimal.ZERO();
    }
  }

  upanteil() {
    if (this.input.LZZ === 1) {
      this.values.ANTEIL1 = this.values.JW;
    } else {
      if (this.input.LZZ === 2) {
        this.values.ANTEIL1 = this.values.JW
          .divide(this.values.ZAHL12, 0, BigDecimal.ROUND_DOWN);
      } else {
        if (this.input.LZZ === 3) {
          this.values.ANTEIL1 = this.values.JW
            .multiply(this.values.ZAHL7)
            .divide(this.values.ZAHL360, 0, BigDecimal.ROUND_DOWN);
        } else {
          this.values.ANTEIL1 = this.values.JW
            .divide(this.values.ZAHL360, 0, BigDecimal.ROUND_DOWN);
        }
      }
    }
  }

  msonst() {
    this.input.LZZ = 1;
    if (this.input.ZMVB === 0) {
      this.input.ZMVB = 12;
    }
    if (this.input.SONSTB.compareTo(BigDecimal.ZERO()) === 0) {
      this.output.VKVSONST = BigDecimal.ZERO();
      this.values.LSTSO = BigDecimal.ZERO();
      this.output.STS = BigDecimal.ZERO();
      this.output.SOLZS = BigDecimal.ZERO();
      this.output.BKS = BigDecimal.ZERO();
    } else {
      this.mosonst();
      this.upvkv();
      this.output.VKVSONST = this.values.VKV;
      this.values.ZRE4J = this.input.JRE4
        .add(this.input.SONSTB)
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);
      this.values.ZVBEZJ = this.input.JVBEZ
        .add(this.input.VBS)
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);

      this.values.VBEZBSO = this.input.STERBE;
      this.mre4sonst();
      this.mlstjahr();
      this.output.WVFRBM = this.values.ZVE
        .subtract(this.values.GFB)
        .multiply(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);

      if (this.output.WVFRBM.compareTo(BigDecimal.ZERO()) === -1) {
        this.output.WVFRBM = BigDecimal.ZERO();
      }
      this.upvkv();
      this.output.VKVSONST = this.values.VKV.subtract(this.output.VKVSONST);
      this.values.LSTSO = this.values.ST.multiply(this.values.ZAHL100);

      this.output.STS = this.values.LSTSO
        .subtract(this.values.LSTOSO)
        .multiply(new BigDecimal(this.input.f))
        .divide(this.values.ZAHL100, 0, BigDecimal.ROUND_DOWN).multiply(this.values.ZAHL100);

      if (this.output.STS.compareTo(BigDecimal.ZERO()) === -1) {
        this.output.STS = BigDecimal.ZERO();
      }

      this.output.SOLZS = this.output.STS.multiply(new BigDecimal('5.5')).divide(this.values.ZAHL100, 0, BigDecimal.ROUND_DOWN);
      if (this.input.R > 0) {
        this.output.BKS = this.output.STS;
      } else {
        this.output.BKS = BigDecimal.ZERO();
      }
    }
  }


  mvmt() {
    if (this.input.VKAPA.compareTo(BigDecimal.ZERO()) === -1) {
      this.input.VKAPA = BigDecimal.ZERO();
    }

    if (this.input.VMT.add(this.input.VKAPA).compareTo(BigDecimal.ZERO()) === 1) {
      if (this.values.LSTOSO.compareTo(BigDecimal.ZERO()) === 0) {
        this.mosonst();
        this.values.LST1 = this.values.LSTOSO;
      } else {
        this.values.LST1 = this.values.LSTSO;
      }

      this.values.VBEZBSO = this.input.STERBE.add(this.input.VKAPA);
      this.values.ZRE4J = this.input.JRE4
        .add(this.input.SONSTB)
        .add(this.input.VMT)
        .add(this.input.VKAPA)
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);

      this.values.ZVBEZJ = this.input.JVBEZ
        .add(this.input.VBS)
        .add(this.input.VKAPA)
        .divide(this.values.ZAHL100)
        .setScale(2, BigDecimal.ROUND_DOWN);

      this.values.KENNVMT = 2;

      this.mre4sonst();
      this.mlstjahr();
      this.values.LST3 = this.values.ST.multiply(this.values.ZAHL100);
      this.mre4abz();
      this.values.ZRE4VP = this.values.ZRE4VP
        .subtract(this.input.JRE4ENT.divide(this.values.ZAHL100))
        .subtract(this.input.SONSTENT.divide(this.values.ZAHL100));

      this.values.KENNVMT = 1;

      this.mlstjahr();

      this.values.LST2 = this.values.ST.multiply(this.values.ZAHL100);
      this.output.STV = this.values.LST2.subtract(this.values.LST1);
      this.values.LST3 = this.values.LST3.subtract(this.values.LST1);

      if (this.values.LST3.compareTo(this.output.STV) === -1) {
        this.output.STV = this.values.LST3;
      }

      if (this.output.STV.compareTo(BigDecimal.ZERO()) === -1) {
        this.output.STV = BigDecimal.ZERO();
      } else {
        this.output.STV = this.output.STV
          .multiply(new BigDecimal(this.input.f))
          .divide(this.values.ZAHL100, 0, BigDecimal.ROUND_DOWN)
          .multiply(this.values.ZAHL100);
      }

      //this.output.SOLZV = this.output.STV.multiply(new BigDecimal('5.5')).divide(this.values.ZAHL100).setScale(0, BigDecimal.ROUND_DOWN);

      this.SOLZVBMG = this.output.STV.divide(this.values.ZAHL100).add(this.JBMG)
      if (this.SOLZSBMG > this.SOLZFREI) {
        this.output.SOLZS = this.output.STS.multiply(new BigDecimal('5.5')).divide(this.values.ZAHL100, 0, BigDecimal.ROUND_DOWN)
      } else {
        this.output.SOLZS = BigDecimal.ZERO()
      }
      if (this.input.R > 0) {
        this.output.BKV = this.output.STV;
      } else {
        this.output.BKV = BigDecimal.ZERO();
      }
    } else {
      this.output.STV = BigDecimal.ZERO();
      this.output.SOLZV = BigDecimal.ZERO();
      this.output.BKV = BigDecimal.ZERO();
    }


  }


  mosonst() {
    this.values.ZRE4J = this.input.JRE4.divide(this.values.ZAHL100).setScale(2, BigDecimal.ROUND_DOWN);
    this.values.ZVBEZJ = this.input.JVBEZ.divide(this.values.ZAHL100).setScale(2, BigDecimal.ROUND_DOWN);
    this.values.JLFREIB = this.input.JFREIB.divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
    this.values.JLHINZU = this.input.JHINZU.divide(this.values.ZAHL100, 2, BigDecimal.ROUND_DOWN);
    this.mre4();
    this.mre4abz();
    this.values.ZRE4VP = this.values.ZRE4VP.subtract(this.input.JRE4ENT.divide(this.values.ZAHL100));
    this.mztabfb();
    this.output.VFRBS1 = this.values.ANP.add(this.values.FVB.add(this.values.FVBZ)).multiply(this.values.ZAHL100).setScale(2, BigDecimal.ROUND_DOWN);
    this.mlstjahr();
    this.output.WVFRBO = this.values.ZVE
      .subtract(this.values.GFB)
      .multiply(this.values.ZAHL100)
      .setScale(2, BigDecimal.ROUND_DOWN);

    if (this.output.WVFRBO.compareTo(BigDecimal.ZERO()) === -1) {
      this.output.WVFRBO = BigDecimal.ZERO();
    }
    this.values.LSTOSO = this.values.ST.multiply(this.values.ZAHL100);
  }

  mre4sonst() {
    this.mre4();

    this.values.FVB = this.values.FVBSO;
    this.mre4abz();
    this.values.ZRE4VP = this.values.ZRE4VP
      .subtract(this.input.JRE4ENT.divide(this.values.ZAHL100))
      .subtract(this.input.SONSTENT.divide(this.values.ZAHL100));
    this.values.FVBZ = this.values.FVBZSO;
    this.mztabfb();
    this.output.VFRBS2 = this.values.ANP
      .add(this.values.FVB)
      .add(this.values.FVBZ)
      .multiply(this.values.ZAHL100)
      .subtract(this.output.VFRBS1);
  }

  uptab22() {
    if (this.values.X.compareTo(this.values.GFB.add(this.values.ZAHL1)) === -1) {
      this.values.ST = BigDecimal.ZERO();
    } else {
      if (this.values.X.compareTo(new BigDecimal('14927')) === -1) {
        this.values.Y = this.values.X
          .subtract(this.values.GFB)
          .divide(this.values.ZAHL10000, 6, BigDecimal.ROUND_DOWN);

        this.values.RW = this.values.Y
          .multiply(new BigDecimal('1088.70'));

        this.values.RW = this.values.RW
          .add(new BigDecimal('1400'));

        this.values.ST = this.values.RW
          .multiply(this.values.Y)
          .setScale(0, BigDecimal.ROUND_DOWN);
      } else {
        if (this.values.X.compareTo(new BigDecimal('58597')) === -1) {
          this.values.Y = this.values.X
            .subtract(new BigDecimal('14926'))
            .divide(this.values.ZAHL10000, 6, BigDecimal.ROUND_DOWN);

          this.values.RW = this.values.Y
            .multiply(new BigDecimal('206.43'));

          this.values.RW = this.values.RW
            .add(new BigDecimal('2397'));

          this.values.RW = this.values.RW
            .multiply(this.values.Y);

          this.values.ST = this.values.RW
            .add(new BigDecimal('869.32'))
            .setScale(0, BigDecimal.ROUND_DOWN);

        } else {
          if (this.values.X.compareTo(new BigDecimal('277826')) === -1) {
            this.values.ST = this.values.X
              .multiply(new BigDecimal('0.42'))
              .subtract(new BigDecimal('9336.45'))
              .setScale(0, BigDecimal.ROUND_DOWN);
          } else {
            this.values.ST = this.values.X
              .multiply(new BigDecimal('0.45'))
              .subtract(new BigDecimal('17671.2'))
              .setScale(0, BigDecimal.ROUND_DOWN);
          }
        }
      }
    }

    this.values.ST = this.values.ST.multiply(new BigDecimal(this.values.KZTAB));
  }

}
DatevCalculatorService.$inject = $inject;
