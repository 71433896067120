import assign_approval_permissions_dialog_html from '../../../modules/cockpit/approvers/assign_approval_permissions_dialog.pug';


const $inject = [
  '$scope',
  '$timeout',
  'customerService',
  '$state',
  '$q',
  'dialogService',
  'UserGroupsService',
  'ACLService',
  'lodash'
];

export default class CockpitUserGroupsController {
  constructor(
    $scope,
    $timeout,
    customerService,
    $state,
    $q,
    dialogService,
    UserGroupsService,
    ACLService,
    lodash) {
    Object.assign(this, {
      $scope,
      $timeout,
      customerService,
      $state,
      $q,
      dialogService,
      UserGroupsService,
      ACLService,
      lodash
    });

    this.customer_id = this.$state.params.customerId;

    this.acl_module_selected = 'user_groups';
    this.process = undefined;
    this.process_component = '*';

    this.init();
  }

  changeUserGroup() {
    this.$timeout(() => {
      this.loading = true;
    }, 0);

    let promises = [];

    promises.push(
      this.getCustomerGroups()
        .then(() => {
          this.ACLService.getACLModules(this.acl_module_selected).then(
            (acl_module) => {
              this.acl_module = acl_module;
              return this.ACLService.getACLModuleActions(this.acl_module.name).then(
                (permission_actions) => {
                  this.permission_actions_by_name = permission_actions.reduce((acc, field) => {
                    acc[field.name] = field;
                    return acc;
                  }, {});

                  return;
                }).catch((err) => {
                console.error(err);
              });
            }).catch((err) => {
            console.error(err);
          });
        })
    );

    return this.$q.all(promises)
      .then(() => {
        this.$timeout(() => {
          this.loading = false;
        }, 0);
        return;
      });
  }

  async init() {
    this.$timeout(() => {
      this.loading = true;
    }, 0);

    let promises = [];

    promises.push(
      this.ACLService.getACLUserGroups()
        .then((user_groups) => {
          this.user_groups = user_groups;
          this.process = this.user_groups[0];
          return this.changeUserGroup();
        })
        .catch((err) => {
          console.error(err);
        })
    );

    return this.$q.all(promises)
      .then(() => {
        this.$timeout(() => {
          this.loading = false;
        }, 0);
        return;
      });
  }

  getCustomerGroups() {
    this.$timeout(() => {
      this.loading = true;
    });
    return this.ACLService.getCustomerGroups(this.customer_id, this.process.name).then(
      (users) => {
        this.users = users;
        this.$timeout(() => {
          this.loading = false;
        });
        return;
      }).catch((err) => {
      console.error(err);

    });
  }

  getPermissionDisplayByName(action_name) {
    if (action_name) {
      return this.permission_actions_by_name && this.permission_actions_by_name[action_name] && this.permission_actions_by_name[action_name].display;
    } else {
      return '';
    }
  }

  openUserGroupPermissions(employee_id = null, employee = null) {
    this.dialogService.ngDialog.openConfirm({
      template: assign_approval_permissions_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'AssignApprovalPermissionsDialogController',
      controllerAs: '$ctrl',
      data: {
        employee_id: employee_id,
        customer_id: this.customer_id,
        acl_module: this.acl_module_selected,
        acl_module_process: this.process.name,
        process_resource: this.process_component,
        employee: employee
      },
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then((result) => {
      if (result) {
        this.getCustomerGroups();
      }
    });
  }

  save() {
    this.loading = true;
  }
}
CockpitUserGroupsController.$inject = $inject;
