import datev_potential_list_html from "./templates/datevPotentialList.pug"

angular
  .module('VSPApp')
  .directive('datevPotentialList', ['$compile', '$templateCache',function  ($compile, $templateCache)  {
    return {
      restrict: 'EA',
      template: datev_potential_list_html,
      replace: true,
      scope: {
        edit: '&',
        remove: '&',
        potentials: '<',
      },
      link: function ($scope) {
        $scope.parseTitle = (potential) => {
          return `
            ${potential.desc} |
            ${potential.brutto} EUR |
            StKI. ${potential.pap_stkl} |
            ${(potential.raise * 100).toFixed(2)}% |
            ${potential.employee} MA
          `;
        };

        $scope.findInsuranceItem = (id, collection) => {
          return collection.find(item => item.value === id).display;
        };
      }
    };
  }]);
