<h1>Bestellung anlegen</h1>

<mat-dialog-content>
  <p>Sie fügen eine Bestellung für die folgende Periode hinzu</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
          mat-dialog-close>
      Abbrechen
  </button>

  <button mat-flat-button
    (click)="createNewExtraOrder()"
  >
    Speichern
  </button>
</mat-dialog-actions>
