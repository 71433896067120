angular
  .module('VSPApp')
  .directive('convertToNumber', [function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {

        ngModel.$parsers.push(val => parseFloat(val) || 0);

        ngModel.$formatters.push(val => '' + val);

      }
    };
  }]);
