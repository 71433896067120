const UsageConstants = {
  barauszahlung: {
    title: 'Barauszahlung'
  },
  valuecard: {
    title: 'ValueCard'
  },
  vorteilskonto: {
    title:    'Vorteilskonto',
    disabled: true
  },
  sachbezug: {
    title: 'Sachbezug'
  },
  erholungscard: {
    title: 'ErholungsCard'
  },
  maku: {
    title: 'KurzurlaubCard (MaKu)'
  },
  digital: {
    title: 'Digitale Essensmarken'
  },
  paper: {
    title: 'Essensschecks'
  }
};

export default UsageConstants;
