import initiate_yearly_dialog_html from '../../../modules/administration/yearly/initiate_yearly_dialog.pug'

const $inject = [
  '$scope', '$timeout', '$window', 'yearlyService', 'administrationService', 'fileService', 'NotificationService', 'dialogService'
];

export class AdministrationYearlyController {
  constructor($scope, $timeout, $window, yearlyService, administrationService, fileService, NotificationService, dialogService) {
    Object.assign(this, {
      $scope,
      $window,
      $timeout,
      yearlyService,
      administrationService,
      fileService,
      NotificationService,
      dialogService
    });
    this.loading = false;
    // this.is_yearly_documents_gathering_active = false;
    this.isYearlyDocumentsGatheringActive();
  }


  isYearlyDocumentsGatheringActive() {
    this.loading = true;
    this.yearlyService.getLatestYearlySession().then((active_yearly_session) => {
      this.$timeout(() => {
        this.active_yearly_session = active_yearly_session;
        this.is_yearly_documents_gathering_active = !!this.active_yearly_session;
        this.loading = false;
      }, 0);
    }).catch((err) => {
      this.NotificationService.error('Error');
    });
  }

  startYearlyThings() {
    this.dialogService.ngDialog.openConfirm({
      template: initiate_yearly_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'InitiateYearlyDialogController',
      data: {},
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.NotificationService.message('Die Jahresabfrage hat begonnen.');
      this.isYearlyDocumentsGatheringActive();
    }).catch(() => {
      this.NotificationService.message('Error');
    });
  }

  stopYearlyThings() {
    this.loading = true;
    this.yearlyService.stopYearlyDocumentGathering().then((res) => {
      this.$timeout(() => {
        this.NotificationService.message('Die Jahresabfrage wurde gestoppt.');
        this.is_yearly_documents_gathering_active = false;
        this.loading = false;
      }, 0);
    }).catch((err) => {
      this.NotificationService.message('Error');
      this.loading = false;
    });
  }

  async generateCsvValidationCodes() {
    this.$timeout(()=>{
      this.loading = true;
    });

    let users_with_validation_codes = await this.yearlyService.getUsersWithValidationCode();
    let users_without_validation_codes = await this.yearlyService.getUsersWithoutValidationCodes();

    this.fileService.saveFile(users_with_validation_codes, ['EMP_NAME', 'CALC_ID', 'EMP_ID', 'EMP_BIRTHDAY', 'yearly_session_id', 'validation_code', 'made_user'], 'users_with_validation_codes.csv');
    this.fileService.saveFile(users_without_validation_codes, ['EMP_NAME', 'CALC_ID', 'EMP_ID', 'EMP_BIRTHDAY', 'yearly_session_id', 'validation_code', 'made_user'], 'users_without_validation_codes.csv');

    this.$timeout(() => {
      this.loading = false;
    });
  }

  async generatePDFValidationCodes() {
    this.loading = true;
    await this.administrationService.createValidationCodesDocument().then((result) => {
      // this.$window.open(res.response, '_blank');
      this.$timeout(() => {
        this.validation_code_pdfs = result;
        this.loading = false;
      }, 0);
    }).catch((err) => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
      console.log(err);
    });
  }
}

AdministrationYearlyController.$inject = $inject;
