<h2 mat-dialog-title>
    Neue Frist
</h2>

<mat-dialog-content>
    <form [formGroup]="formGroup">
        <p>
            Die dem Kunden gewährte Frist für diese Bestellung ist abgelaufen. Der Kunde hatte den Entwurf dieser
            Bestellung vor Fristablauf weder aktiv bestätigt noch eine automatische Auslösung für seine Bestellungen bei
            Fristablauf aktiviert.
        </p>

        <p>
            Geben Sie bitte eine neue Frist für diese Bestellung ein.Die neue Frist darf spätestens bis einschließlich
            28. Tag des Monats für diese Bestellung gesetzt werden:
        </p>

        <vn-date formControlName='newDeadline'
                 label="Neue Frist"
                 key="vn.directBonus.backofficeAdministration.extendDeadlineDialog.inputNewDeadline"
                 format="DD.MM.YYYY"
                 placeholder="DD.MM.JJJJ">

            <mat-error *ngIf="deadlineDateControl.hasError('required')">
                Eingabe fehlt.
            </mat-error>
            <mat-error
                       *ngIf="!deadlineDateControl.hasError('required') && deadlineDateControl.hasError('deadlineInvalid')">
                Das Datum muss in der Zukunft in diesem Monat liegen (heute inkl.)
            </mat-error>
        </vn-date>

        <p>
            Fügen Sie bitte in das untere Eingabefeld auch die Nummer des auftragsbezogenen OTRS-Tickets ein:
        </p>

        <vn-input formControlName="ticketUrl"
                  placeholder=""
                  key="vn.directBonus.backofficeAdministration.extendDeadlineDialog.inputOTRSTicket"
                  label="OTRS-Ticket">
            <mat-error *ngIf="ticketUrlControl.hasError('required')">
                Eingabe fehlt.
            </mat-error>
        </vn-input>

        <p>
            Nach dem Klick auf "Bestätigen" wird die Bestellung wieder als "Entwurf" mit Ihrer neu gesetzten Frist
            gespeichert.
        </p>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='save-button'
            data-cy='vn.directBonus.backofficeAdministration.extendDeadlineDialog.buttonSubmit'
            mat-stroked-button
            [disabled]="!formGroup.valid"
            (click)="extendDeadline()">
        Bestätigen
    </button>
</mat-dialog-actions>