import ComponentController from '../ComponentController';
import {MPP_LINK_CONSTANTS_BY_NAME} from '../../../../services/mppService';

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'vbmService',
  'userService',
  'bikeleasingService'
]
export default class ComponentControllerpc extends ComponentController {

  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    vbmService,
    userService,
    bikeleasingService
  ) {

    super(
      $q,
      $injector,
      $scope,
      $state,
      $transitions,
      $location,
      Made,
      debounceFactory,
      vbmData,
      neoModifierService,
      customerService,
      PlausibilityService,
      dialogService,
      SliderOptionsConstants,
      componentsService,
      NotificationService,
      vbmService,
      userService,
      bikeleasingService
    );

    this.vbmService = this.$injector.get('vbmService');
    this.employeeService = this.$injector.get('employeeService');
    this.mppService = this.$injector.get('mppService');
    this.$scope = $scope;
    this.blank = '_blank';
    this.is_pc = true;
    this.MPP_LINK_CONSTANTS_BY_NAME = MPP_LINK_CONSTANTS_BY_NAME;

    let key = 123456789;

    customerService
      .getNeoProjectByEmployeeId(this.$scope.employeeId)
      .then(configuration => {
        this.infomode = configuration.infomode || false;
        return configuration.testmode;
      })
      .then(testmode => {
        if (testmode) {
          return key;
        }

        return customerService
          .getConfigurationByEmployeeId(this.$scope.employeeId)
          .then(customerConfig => {
            this.mpp = customerConfig.neo_config.pc;
            if (customerConfig.neo_config.pc.useInternalMPP) {
              this.blank = '_self';
              return key = 0;
            } else {
              return this.employeeService
                .getCustomerId()
                .then(customerId => this.vbmService.getMPPLink(customerId));
            }
          });

      })
      .then(key => this.mppLink = key === 0 ? this.MPP_LINK_CONSTANTS_BY_NAME['internal']['link_string'] : this.MPP_LINK_CONSTANTS_BY_NAME['external']['link_string'] + key);

    this.mppService.getInfoButtonState($scope.employeeId).then(state => this.orderButtonState = state);
  }

  goToMPP() {
    if (this.mppLink) {
      if (this.mpp.useInternalMPP) {
        this.$state.go('inApp.mpp.side.products.landing', {neo_data: {is_selfservice: true}});
      } else {
        window.open(this.mppLink, this.blank);
      }
    }
  }


  sendInfomodeOrder() {
    this.orderBusy = true;

    this.mppService.sendInfomodeOrder(this.$scope.employeeId)
      .then(() => {
        this.orderButtonState = true;
        this.orderBusy = false;
      })
      .finally(() => {
        this.dialogService.question('Vielen Dank. Wir haben Ihre Vorab-Bestellung erhalten. Sie erhalten eine Zusammenfassung als Mail.\n' +
          'Wir kommen in Kürze auf Sie zu.', '', 'OK');
      });
  }
}
ComponentControllerpc.$inject = $inject;
