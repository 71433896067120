export const INSURANCE_PAYERS = [
  {
    'id': 0,
    'name': 'employee',
    'display': 'Arbeitgeber'
  },
  {
    'id': 1,
    'name': 'employer',
    'display': 'Umwandlung AN'
  }
];

export const INSURANCE_PAYERS_BY_NAME = INSURANCE_PAYERS.reduce((acc, payer) => {
  acc[payer['name']] = payer;
  return acc;
}, {});

export const DEFAULT_INSURANCE_PAYER = INSURANCE_PAYERS_BY_NAME['employer'];
