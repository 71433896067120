angular
  .module('VSPApp')
  .service('CardService', ['Made', 'MonthsService', function (Made, MonthsService) {

    var cardService = {};

    cardService.getCreateCardList = (year = MonthsService.year, month = MonthsService.month + 1) => Made
      .request('rpc://vcp/createOrderValueCards', {
        year: '' + year,
        month: '' + month
      })
      .then(response => {

        var file = File(Made, response);

        return file
          .readAll()
          .then(file.save);
      });

    cardService.getChargeCardList = (year = MonthsService.year, month = MonthsService.month + 1) => Made
      .request('rpc://vcp/createLoadingValueCards', {
        year: '' + year,
        month: '' + month
      })
      .then(response => {

        var file = File(Made, response);

        return file
          .readAll()
          .then(file.save);
      });

    return cardService;
  }]);
