import {
  NeoCalculationBookmarkDialogComponent
} from "../../../../app/neo-calculation-bookmark/components/neo-calculation-bookmark-dialog/neo-calculation-bookmark-dialog.component";

const $inject = [
  '$scope',
  '$q',
  'lodash',
  'dialogService',
  'moment',
  'Made',
  '$timeout',
  'employeeService',
  'customerService',
  'NotificationService',
  'vbmService',
  'authenticationService',
  '$state',
  'vbmData',
  'MatDialogService',
  'NeoCalculationBookmarkService'
];

export default class CalculationsComponentController {
  constructor(
    $scope,
    $q,
    lodash,
    dialogService,
    moment,
    Made,
    $timeout,
    employeeService,
    customerService,
    NotificationService,
    vbmService,
    authenticationService,
    $state,
    vbmData,
    MatDialogService,
    NeoCalculationBookmarkService
  ) {
    Object.assign(this, {
      $scope,
      $q,
      lodash,
      dialogService,
      moment,
      Made,
      $timeout,
      employeeService,
      customerService,
      NotificationService,
      vbmService,
      authenticationService,
      $state,
      vbmData,
      MatDialogService,
      NeoCalculationBookmarkService
    });

    this.employee_calculations = {};
    this.employeeId = this.Made.user.valuenet_id;
    this.iAmAdvisor = this.authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');

    this.$onInit = () => {
      this.employeeId = (this.options && this.options.valuenet_id) || this.employeeId;
      this.getCalculations(this.employeeId)
    };
  }

  async getCalculations() {
    this.$timeout(()=>{this.loading = true;});
    this.employee_calculations = await this.NeoCalculationBookmarkService.getNeoCalculationBookmarksByEmployeeId(this.employeeId);
    this.$timeout(()=>{this.loading = false;});
  }

  editCalculation(calculation_id) {
    this.MatDialogService.open(
      NeoCalculationBookmarkDialogComponent,
      {
        data: {
          calculationId: calculation_id
        }
      }
    )
      .afterClosed()
      .subscribe((res) => {
        this.getCalculations();
      });
  }

  findAndLoadCalculation(calculation_id) {
    if (this.options && this.options.as_advisor) {
      this.vbmService.getData(this.employeeId, false, calculation_id).then(
        (data) => {
          if (Object.keys(data).length > 0) {
            this.loadCalculation(calculation_id);
          } else {
            this.NotificationService.error('Fehlgeschlagen');
          }
        }).catch((err) => {
        console.error(err);
        this.NotificationService.error('Fehlgeschlagen');
      });
    }
  }

  loadCalculation(calculation_id) {
    if (this.options && this.options.is_berater_view) {
      this.$state.go('inApp.neo.side.berater', {
        employeeId: this.employeeId,
        calculation_id: calculation_id
      }, {reload: true});
    } else {
      this.$state.go('inApp.neo.side.verguetung', {calculation_id: calculation_id});
    }
  }

  isCalculationEditable(bookmark) {
    if (!(this.iAmSpecialAdvisor || this.iAmAdvisor) && bookmark.createdBy !== bookmark.employeeId) {
      return false;
    }
    return true;
  }

  deleteCalculation(calculation) {
    this.dialogService
      .frageDialog(`Sie möchten die markierte Optimierung "${calculation.name}" wirklich löschen?`, '', {
        approve: 'Bestätigen',
        decline: 'Abbrechen'
      })
      .then(
        () => {
          return this.NeoCalculationBookmarkService.deleteNeoCalculationBookmark(calculation.id).then(
            () => {
              this.NotificationService.message('Markierung wurde gelöscht');
              return this.getCalculations();
            }
          ).catch((err) => {
            this.NotificationService.error('Fehlgeschlagen');
          });
        },
        () => {
          return false;
        }
      );
  }

}

CalculationsComponentController.$inject = $inject;
