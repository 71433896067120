import shopPreController from './pre/shop.pre.controller';

import DetailsController from './produkte/details/details.controller';
import HomeController from './produkte/home/home.controller';
import KategorieController from './produkte/kategorie/kategorie.controller';
import './produkte/templates/kategorie_menu.controller';

angular
  .module( 'VSPApp' )
  .controller( 'shopPreController', shopPreController)
  .controller('DetailsController', DetailsController)
  .controller('HomeController', HomeController)
  .controller('KategorieController', KategorieController);