// shared component
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingService} from "../../../../shared/services/loading.service";
import {MatDialog} from "@angular/material/dialog";
import {take} from "rxjs/operators";
import {ConfirmationDialogComponent} from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {DticketOrderService, EmployeeUpdateData, LegacyEmployeeData} from "../../../services/dticket-order.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'vn-dticket-order-shared-employee-data',
  templateUrl: './dticket-order-shared-employee-data.component.html',
  styleUrls: ['./dticket-order-shared-employee-data.component.scss']
})
export class DticketOrderSharedEmployeeDataComponent implements OnInit {
  @Input() employee!: EmployeeUpdateData;
  @Input() disableFields: boolean = false;
  @Input() dialogConfirmButtonText!: string;
  @Output() formSaved: EventEmitter<LegacyEmployeeData|null> = new EventEmitter<LegacyEmployeeData|null>();

  public formGroup!: FormGroup;
  protected changedData: any = {};

  constructor(
    protected formBuilder: FormBuilder,
    protected loader: LoadingService,
    protected dialog: MatDialog,
    protected dticketService: DticketOrderService,
    protected datePipe: DatePipe
  ) {
  }

  private onCreateGroupFormValueChange() {
    const initialValue = this.formGroup.value;
    this.formGroup.valueChanges.subscribe((value) => {
      for (const key in initialValue) {
        if (!this.changedData[key]) {
          this.changedData[key] = {};
        }
        this.changedData[key] = this.formGroup.value[key] !== initialValue[key];
      }
    });
  }

  public get firstName(): FormControl {
    return this.formGroup.get('firstName') as FormControl;
  }

  public get birthDate(): FormControl {
    return this.formGroup.get('birthDate') as FormControl;
  }

  public get lastName(): FormControl {
    return this.formGroup.get('lastName') as FormControl;
  }

  async save() {
    if (!!(
      this.changedData?.birthDate ||
      this.changedData?.firstName ||
      this.changedData?.lastName
    )) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: "Sie haben Ihre persönlichen Daten geändert. Sind Sie sicher, dass diese geändert werden sollen?"
        }
      }).afterClosed()
        .pipe(take(1))
        .subscribe(async (result: any) => {

          if (
            result && result.decision === 'yes'
          ) {
            this.loader.show();

            let formData = this.formGroup.getRawValue();
            formData['birthDate'] = this.datePipe.transform(formData.birthDate, 'dd.MM.YYYY');
            const employeeUpdate = {
              ...this.employee,
              ...formData,
            };
            const res = await this.dticketService.processUserPreferencesData(employeeUpdate) as LegacyEmployeeData;

            this.formSaved.emit(res);
            this.loader.hide();
          }
        });
    } else {
      this.formSaved.emit(null);
    }


  }

  ngOnInit(): void {
    if (!this.employee) {
      throw new Error('Employee data is required for this component.');
    }

    let defaultDate = new Date();
    if (this.employee.birthDate) {
      const newDateString = this.employee.birthDate.replace(/(\d+[.])(\d+[.])/, '$2$1');
      defaultDate = new Date(newDateString);
    }

    const defaultFormConfig = {
      firstName: [
        {
          value: this.employee.firstName,
          disabled: this.disableFields
        },
        [
          Validators.required,
        ]
      ],
      lastName: [
        {
          value: this.employee.lastName,
          disabled: this.disableFields
        },
        [
          Validators.required,
        ]
      ],
      birthDate: new FormControl({
        value: defaultDate,
        disabled: this.disableFields
      }, [
        Validators.required,
      ]),
    };

    this.formGroup = this.formBuilder.group(defaultFormConfig);
    this.onCreateGroupFormValueChange();
  }
}
