import MppController from './MppController';
import MppListingController from './listing/MppListingController';
import MppBarController from './main/MppBarController';
import MppSideController from './side/MppSideController';
import MppDetailController from './detail/MppDetailController';

angular
  .module('VSPApp')
  .controller('MppController', MppController)
  //.controller('MppTestController', MppTestController)
  .controller('MppListingController', MppListingController)
  .controller('MppBarController', MppBarController)
  .controller('MppSideController', MppSideController)
  .controller('MppDetailController', MppDetailController);
