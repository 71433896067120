const $inject = [
  '$q',
  'Made',
  'customerService'
];

export default class advisorService {

  constructor( $q, Made, customerService ) {

    Object.assign( this, {
      $q,
      Made,
      customerService
    } );

    this.advisors = {};
    this.me = {};

    this.scaffold = {
      employees: {},
      customers: []
    };
  }

  getEmployeesByCustomer( customerId, advisorId = this.Made.user.valuenet_id ) {


    advisorId = this.prepare( advisorId );

    return this.Made
      .request( 'rpc://valuenetdb/advisor/employees_for_customer', {
        advisor_id: parseInt( advisorId ),
        customer_id: parseInt( customerId )
      } )
      .then( response => this.advisors[ advisorId ].employees[ customerId ] = response );
  }

  getCustomers( advisorId ) {

    advisorId = this.prepare( advisorId );

    return this.customerService
      .getCustomers( advisorId )
      .then(response => this.advisors[ advisorId ].customers = response);
  }

  prepare( id = this.Made.user.valuenet_id ) {
    if ( !this.advisors[ id ] ) {
      this.advisors[ id ] = angular.copy( this.scaffold );
    }
    if ( id === this.Made.user.valuenet_id ) {
      this.me = this.advisors[ id ];
    }
    return id;
  }


  notifyAdvisorAboutUploads( file_ids, employee_id = this.Made.user.valuenet_id, host = location.origin ) {

    return this.Made
      .request( 'rpc://valuenetdb/employee/notify_advisor_about_uploads', {
        employee_id: parseInt( employee_id ),
        file_ids,
        host
      } )
      .then( response => this.pstEnabledComponents = response );
  }

  getAdvisorsByCustomer( customer_id ) {

    return this.Made
      .request( 'rpc://valuenetdb/customer/advisors', {
        customer_id: parseInt( customer_id )
      } );
  }

  list() {
    return this.Made.request( 'rpc://valuenetdb/advisor/list' );
  }

  setAdvisorForCustomer( advisorId, customerId, is_default = false ) {

    return this.Made.request( 'rpc://valuenetdb/advisor/employee_advises', {
      employee_id: parseInt( advisorId ),
      customer_id: parseInt( customerId ),
      is_default
    } );
  }

  setDefault( advisorId, customerId ) {

    return this.Made.request( 'rpc://valuenetdb/advisor/set_default', {
      employee_id: parseInt( advisorId ),
      customer_id: parseInt( customerId )
    } );
  }

  removeAdvisorFromCustomer( advisorId, customerId ) {

    return this.Made.request( 'rpc://valuenetdb/advisor/remove_advisor', {
      employee_id: parseInt( advisorId ),
      customer_id: parseInt( customerId )
    } );
  }

}

advisorService.$inject = $inject;