<h2 mat-dialog-title>
  MPP Status Log
</h2>

<div mat-dialog-content vnLoader [showLoader]='(isLoading$() | async)!'>

  <table mat-table [dataSource]="logsTableData" style="width: 100%">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No logs.
      </td>
    </tr>

    <!-- header row -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns" sticky="true">
    </tr>

    <!-- content rows -->
    <tr mat-row *matRowDef="let data; columns: displayedColumns;" class="customer-order-table-row" >
    </tr>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        Datum
      </th>

      <td mat-cell *matCellDef="let data">
        {{ data.date * 1000 | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="newStatus">
      <th mat-header-cell *matHeaderCellDef>
        Neuer Status (Neo)
      </th>

      <td mat-cell *matCellDef="let data">
        {{ data.newStatus }}
      </td>
    </ng-container>

    <ng-container matColumnDef="oldStatus">
      <th mat-header-cell *matHeaderCellDef>
        Alter Status (Neo)
      </th>

      <td mat-cell *matCellDef="let data">
        {{ data.oldStatus }}
      </td>
    </ng-container>

    <ng-container matColumnDef="changerName">
      <th mat-header-cell *matHeaderCellDef>
        Bearbeiter
      </th>

      <td mat-cell *matCellDef="let data">
        {{ data.changerName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>
        Kommentar
      </th>

      <td mat-cell *matCellDef="let data">
        {{ data.comment }}
      </td>
    </ng-container>
  </table>
</div>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    Abbrechen
  </button>
</mat-dialog-actions>

