// should be fixed, WE WANT TO USE CAMELCASE IN JS

import {DrivingLicenseFileType} from "./driving-license-file-type.model";

export interface Dashboard {
  id: string;
  pic_id: string;
  title: string;
  video: string;
  frame: string;
  is_used: boolean;
}

// should be fixed, WE WANT TO USE CAMELCASE IN JS

export interface Side {
  title: string;
  is_order_delivered: boolean;
  is_order_waiting: boolean;
  brutto_value_month: string;
  getContractEndDate(format: string): string;
  getContractStartDate(format: string): string;
}

export interface TicketCreation {
  ticketNumber: string,
  ticketUrl: string
}

export interface FinnAutoCarPicture {
  filename: string,
  id: string,
  size: number,
  url: string,
  thumbnails: {
    full: {
      height: number,
      url: string,
      width: number
    },
    large: {
      height: number,
      url: string,
      width: number
    },
    small: {
      height: number,
      url: string,
      width: number
    },
    type: string,
    url: string,
  }
}
// @TODO -> Hadi & Kari
// implement the correct types for the data we are going to need extracted from the API
// ->  https://api-fleet.finn.auto/cars/bmw-x5-1226-mineralweissmetallic
// follow the example below

export interface FinnAutoCarBrand {
  helper_brand_logo: FinnAutoCarPicture,
  id: string,
  picture: any
}

export interface FinnAutoCarConfig {
  link: string,
  name: string
}

export interface FinnAutoCarEquipment {
  comfort: string,
  exterior: string,
  interior: string,
  multimedia: string,
  safety: string
}

export interface DrivingLicenseFile {
  type: DrivingLicenseFileType,
  file: object
};

export interface DeliveryData {
  name: string,
  lastname: string,
  city: string,
  street: string,
  zip: string,
  phone: string,
  comment: string,
  preferredDeliveryDate: string
}

export interface CarColor {
  id: string;
  car_id: string;
  color_hex: string;
  availability: number;
}
