import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, map, share, shareReplay, switchAll, switchMap, tap } from 'rxjs/operators';
import { BonagoBasketListItem, BonagoCategory, BonagoCustomerChecks, BonagoRetailer, BonagoRetailerBasic, BonagoScheme, BonagoUserChecks, EmployeeAddress } from '../models/benefit-bonago.model';
import { BenefitsBonagoMapper } from './benefits-bonago.mapper';

@Injectable()
export class BenefitsBonagoController {

  private readonly URL_PREFIX = 'rpc://shop/bonago/';

  public $selectedSchemeId = new BehaviorSubject<number>(0);
  public $selectedCategoryId = new BehaviorSubject<number>(0);
  public $userChecks = new BehaviorSubject<Partial<BonagoUserChecks>>({});
  public $userBalance = new BehaviorSubject<number>(0);

  constructor(
    @Inject('Made') private made: any,
    @Inject('customerService') private customerService: any,
    @Inject('EmployeeService') private employeeService: any,
    private mapper: BenefitsBonagoMapper,
  ) { }

  public getUserChecks$(): Observable<BonagoUserChecks> {
    let getUserChecks$ = from(
      this.made.request(this.URL_PREFIX + 'get_user_checks')
    );

    return getUserChecks$.pipe(
      map(userChecks => this.mapper.toBonagoUserChecks(userChecks)),
      shareReplay()
    );
  }

  public $$getUserChecks = this.getUserChecks$();

  public getCustomerChecks$(): Observable<BonagoCustomerChecks> {
    let getCustomerChecks$ = from(
      this.made.request(this.URL_PREFIX + 'get_customer_checks')
    );

    return getCustomerChecks$.pipe(
      map(customerChecks => this.mapper.toBonagoCustomerChecks(customerChecks)),
      shareReplay()
    );
  }

  public $$getCustomerChecks = this.getCustomerChecks$();

  public getAvailableBonagoSchemes$(): Observable<BonagoScheme[]> {
    let getAvailableBonagoSchemes$ = from(
      this.made.request(this.URL_PREFIX + 'get_available_schemes')
    );

    return getAvailableBonagoSchemes$.pipe(
      map(availableSchemes => this.mapper.toBonagoSchemes(availableSchemes as any[])),
      shareReplay()
    );
  }

  public $$getAvailableBonagoSchemes = this.getAvailableBonagoSchemes$();

  public getAvalableBonagoSchemesMap$(): Observable<Map<number, BonagoScheme>> {
    return this.$$getAvailableBonagoSchemes.pipe(
      map(availableSchemes => availableSchemes.reduce(
        (schemesMap, scheme) => {
          schemesMap.set(scheme.schemeId, scheme);

          return schemesMap;
        },
        new Map<number, BonagoScheme>()
      )),
      shareReplay()
    );
  }

  public $$getAvalableBonagoSchemesMap = this.getAvalableBonagoSchemesMap$();

  public getCustomerBonagoSchemes$(): Observable<BonagoScheme[]> {
    let customerConfiguration$ = from(
      this.customerService.getConfiguration(undefined, new Date())
    );

    return this.$$getAvalableBonagoSchemesMap.pipe(
      switchMap(
        availableSchemesMap => customerConfiguration$.pipe(
          map(
            (customerConfiguration: any) => (customerConfiguration['bonago']['schemes'] as any[]).map(
              (schemeId: number) => availableSchemesMap.get(schemeId)!
            )
          ),
        )),
      shareReplay()
    );
  }

  public $$getCustomerBonagoSchemes = this.getCustomerBonagoSchemes$();

  public getCustomerBonagoSchemesMap$(): Observable<Map<number, BonagoScheme>> {
    return this.$$getCustomerBonagoSchemes.pipe(
      map(customerSchemes => customerSchemes.reduce(
        (schemesMap, scheme) => {
          schemesMap.set(scheme.schemeId, scheme);

          return schemesMap;
        },
        new Map<number, BonagoScheme>()
      )),
      shareReplay()
    );
  }

  public getUserBonagoScheme$() {
    return combineLatest([
      this.getCustomerBonagoSchemes$(),
      this.getAvalableBonagoSchemesMap$(),
      this.$$getUserChecks,
    ]).pipe(
      switchMap(([
        customerSchemes,
        availableSchemes,
        {
          userCodeSchemeId,
          hasBonagoEmployeeBenefit,
          ...userChecks
        }
      ]) => this.$selectedSchemeId.asObservable().pipe(
        // distinctUntilChanged(),
        map(selectedSchemeId => {

          if (!customerSchemes.length) {
            let userCodeScheme = availableSchemes.get(userCodeSchemeId) as BonagoScheme;

            this.$userChecks.next({
              hasValidSchemeId: true
            });

            return userCodeScheme;
          }

          let userScheme = customerSchemes.find(
            scheme => scheme.schemeId === userCodeSchemeId
          );

          if (!userScheme) {
            this.$userChecks.next({
              hasValidSchemeId: false
            });
          } else {
            this.$userChecks.next({
              hasValidSchemeId: true
            });
            return userScheme;
          }

          let selectedScheme = customerSchemes.find(
            scheme => scheme.schemeId === selectedSchemeId
          );

          if (!selectedScheme) {
            return customerSchemes[0];
          } else {
            return selectedScheme;
          }
        })
      )),
    );
  }

  public $$getUserBonagoScheme = this.getUserBonagoScheme$();

  public getUserBalance$(): Observable<number> {
    let getUserBalance$ = from(
      this.made.request(this.URL_PREFIX + 'get_user_balance', {
        'valuenet_id': this.made.user.valuenet_id,
      })
    );

    return getUserBalance$.pipe(
      map(balance => balance as number)
    );
  }

  public getUserBalance$$ = this.$userBalance.pipe(
    switchMap(oldBalance => this.getUserBalance$()),
    shareReplay()
  );

  public getBonagoCategories$(): Observable<BonagoCategory[]> {
    let getBonagoCategories$ = (schemeId: number) => from(
      this.made.request(this.URL_PREFIX + 'get_categories_by_scheme_id', {
        'scheme_id': schemeId,
      })
    );

    return this.$$getUserBonagoScheme.pipe(
      switchMap(scheme => getBonagoCategories$(scheme.schemeId)),
      map(categories => this.mapper.toBonagoCategories(categories as any[])),
      shareReplay()
    );
  }

  public $$getBonagoCategories = this.getBonagoCategories$();

  public getBonagoCategoriesMap$(): Observable<Map<number, BonagoCategory>> {
    return this.$$getBonagoCategories.pipe(
      map(bonagoCategories => bonagoCategories.reduce(
        (categoriesMap, category) => {
          categoriesMap.set(category.id, category);

          return categoriesMap;
        },
        new Map<number, BonagoCategory>()
      )),
      shareReplay()
    );
  }

  public $$getBonagoCategoriesMap = this.getBonagoCategoriesMap$();

  public getBonagoRetailersBasic$(withAdditionalInfo = true, allCategories = false): Observable<BonagoRetailerBasic[]> {
    let getBonagoRetailersBasic$ = (schemeId: number, categoryId: number) => from(
      this.made.request(this.URL_PREFIX + 'get_retailers_basic', {
        'scheme_id': schemeId,
        'category_id': allCategories ? undefined : categoryId,
        'with_information': withAdditionalInfo
      })
    );

    if (allCategories) {
      return this.$$getUserBonagoScheme.pipe(
        switchMap(scheme => getBonagoRetailersBasic$(scheme.schemeId, 0)),
        map(retailers => this.mapper.toBonagoRetailersBasic(retailers as any[])),
        shareReplay()
      )
    } else {
      return this.$selectedCategoryId.pipe(
        distinctUntilChanged(),
        switchMap(categoryId => this.$$getUserBonagoScheme.pipe(
          switchMap(scheme => getBonagoRetailersBasic$(scheme.schemeId, categoryId)),
        )),
        map(retailers => this.mapper.toBonagoRetailersBasic(retailers as any[])),
        shareReplay()
      );
    }
  }

  public $$getBonagoRetailersBasic = this.getBonagoRetailersBasic$();

  public $$getAllBonagoRetailersBasic = this.getBonagoRetailersBasic$(true, true);

  public getEmployeeAddress$(): Observable<EmployeeAddress> {
    let getEmployeeAddress$ = from(
      this.employeeService.getIntegrityData()
    );

    return getEmployeeAddress$.pipe(
      map((rawData: any) => this.mapper.toEmployeeAddress(rawData)),
      shareReplay()
    );
  }

  public $$getEmployeeAddress = this.getEmployeeAddress$();

  public getBonagoRetailer$(retailerId: number): Observable<BonagoRetailer> {
    let getBonagoRetailer$ = (schemeId: number) => from(
      this.made.request(this.URL_PREFIX + 'get_retailer', {
        'retailer_id': retailerId,
        'scheme_id': schemeId,
      })
    );

    return this.$$getUserBonagoScheme.pipe(
      switchMap(scheme => getBonagoRetailer$(scheme.schemeId)),
      map(retailer => this.mapper.toBonagoRetailer(retailer))
    );
  }

  public completeBonagoCheckout$(
    basketItems: BonagoBasketListItem[],
    employeeAddress: EmployeeAddress
  ): Observable<BonagoRetailer> {

    const mappedItems = basketItems.reduce(
      (acc, item) => ({
        ...acc,
        [item.voucherId]: item.quantity
      }),
      {}
    );

    const mappedAddress = {
      email: employeeAddress.email,
      ort: employeeAddress.city,
      plz: employeeAddress.postcode,
      strasse: employeeAddress.street
    }

    let completeBonagoCheckout$ = from(
      this.made.request(this.URL_PREFIX + 'complete_checkout', {
        'items': mappedItems,
        'delivery_information': mappedAddress,
      })
    );

    return completeBonagoCheckout$.pipe(
      map(retailer => retailer as any)
    );
  }

  public getVouchersInStockNumber$(vouchersList: string[]): Observable<string[]> {
    let getVouchersInStockNumber$ = from(
      this.made.request(this.URL_PREFIX + 'get_vouchers_in_stock_number', {
        'vouchers_list': vouchersList
      })
    );

    return getVouchersInStockNumber$.pipe(
      map(voucher => voucher as any)
    );
  }

  public async hasBonagoCode(employeeId: number): Promise<boolean>{
    return this.made.request(this.URL_PREFIX + 'has_bonago_code', {
        'employee_id': employeeId
      });
  }
}
