import {CarLeasingBasket} from "../../../../app/car-leasing/models/car-leasing-basket.model";
import {CarLeasingTicketCreation} from '../../../../app/car-leasing/models/car-leasing-ticket-creation.model'

const $inject = [
  '$scope',
  '$timeout',
  'CarLeasingApiService',
]

export default class CarLeasingTicketDialogController {
  constructor(
    $scope,
    $timeout,
    CarLeasingApiService,
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      CarLeasingApiService
    });

    this.loading = {
      main: false,
      saving: false,
    };

    this.forms = {
      addTicketForm: {}
    };

    this.ticket = null;

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    /** @var CarLeasingBasket */
    this.basket = this.$scope.ngDialogData.basket;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async saveTicket() {
    this.$timeout(()=>{
      this.loading.saving = true;
    });

    let ticketCreation = new CarLeasingTicketCreation(this.ticket.ticketNumber, this.ticket.ticketUrl)

    let result = null;
    try {
      result = await this.CarLeasingApiService.ticketCreation(this.basket.id, ticketCreation)
    } catch (e) {
      let error = e.error
    } finally {

      this.$timeout(()=>{
        this.loading.saving = false;
      });
    }

    if (result) {
      this.$scope.confirm();
    }

  }
}

CarLeasingTicketDialogController.$inject = $inject
