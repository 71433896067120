<h2 mat-dialog-title>
    <span>
        Bonus entfernen
    </span>

    <h3>
        {{this.employeeName}} {{this.employeeId}}
    </h3>

    <label>
        {{ componentType }} {{this.period}}
    </label>
</h2>

<mat-dialog-content>
    <p>
        Sind Sie sicher, dass Sie diesen Bonus entfernen möchten?
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='delete-button'
            data-cy="vn.directBonus.backofficeAdministration.removeBonusDialog.buttonRemoveBonus"
            mat-stroked-button
            (click)="removeBonusFromOrder()">
        Bestätigen
    </button>
</mat-dialog-actions>