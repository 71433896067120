import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import { CustomerOrder, CustomerOrderTableData } from '../../../models/direct-bonus-customer-order.model';
import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ExtendDeadlineDialogData,
  ProjectActionsDialogData
} from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { map, switchMap } from 'rxjs/operators';
import { DirectBonusFormData } from 'src/app/direct-bonus/models/direct-bonus-data.model';
import { BehaviorSubject, EMPTY} from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DirectBonusMapperService } from 'src/app/direct-bonus/services/direct-bonus.mapper';
import { BonusOrderExtendDeadlineDialogComponent } from '../../backoffice/bonus-order-extend-deadline-dialog/bonus-order-extend-deadline-dialog.component';
import { DirectBonusAddDialogComponent } from '../../backoffice/direct-bonus-add-dialog/direct-bonus-add-dialog.component';
import { CustomerBulkUploadDialogComponent, DownloadTableProps } from '../customer-bulk-upload-dialog/customer-bulk-upload-dialog.component';
import { DirectBonusProjectOverview } from 'src/app/direct-bonus/models/direct-bonus-project-overview.model';
import { DirectBonusRevertDialogComponent } from '../direct-bonus-revert-dialog/direct-bonus-revert-dialog.component';
import { DirectBonusConfirmDialogComponent } from '../direct-bonus-confirm-dialog/direct-bonus-confirm-dialog.component';
import { CustomerAdministrationOrderAllowedOperations } from 'src/app/direct-bonus/models/direct-bonus-allowed-operations.model';

@Component({
  selector: 'vn-direct-bonus-customer-bonus-table',
  templateUrl: './direct-bonus-customer-bonus-table.component.html',
  styleUrls: ['./direct-bonus-customer-bonus-table.component.scss'],
  animations: [
    trigger('rowExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DirectBonusCustomerBonusTableComponent implements OnInit {

  private downloadFlag = false;
  private CSVDownloadData: DownloadTableProps | null = null;
  private $selectedDirectBonusProjectOverview: BehaviorSubject<DirectBonusProjectOverview | undefined> =
  new BehaviorSubject(undefined as DirectBonusProjectOverview | undefined);
  private readonly currentYear = moment().year();
  private $selectedYear: BehaviorSubject<number> = new BehaviorSubject(this.currentYear);

  public filtersHeaderColumns: string[] = [
    'filtersHeader'
  ];

  public mainTableColumns: string[] = [
    'orderId',
    'orderType',
    'orderPeriod',
    'orderAmount',
    'orderState',
    'actionsMenu'
  ];

  public expandedRow!: CustomerOrderTableData | null;

  @Input()
  tableData!: CustomerOrderTableData[];

  @Output()
  tableDataChange = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private mapper: DirectBonusMapperService,
  ) {

  }

  ngOnInit(): void {
    this.tableData = [];
  }

  public getOrderPeriod(order: CustomerOrder): string {
    const orderPeriodMoment = moment({
      month: order.forMonth - 1,
      year: order.forYear
    });

    const orderPeriod = orderPeriodMoment.format('MM.YYYY');
    return orderPeriod;
  }

  public getOrderStateColors(data: CustomerOrderTableData): string[] {
    const colors = [];
    const state = "" + data.order.state.value

    if (data.order.paymentType === 'sepa') {
      colors.push('grey');
    }

    switch (state) {
      case "1":
        colors.push('yellow'); // draft
        break;
      case "2":
      case "3":
      case "4":
        colors.push('blue');
        break;
      case "5":
        colors.push('red');
        break;
      case "6":
        colors.push('green');
        break;
      default:
        colors.push('black');
    }

    if (data.order.isOnHold) {
      colors.push('black');
    }

    return colors;
  }

  public getOrderStateLabels(data: CustomerOrderTableData): string[] {
    const labels = [];

    if (data.order.paymentType === 'sepa') {
      labels.push('S€PA');
    }

    labels.push(data.order.state.name);

    if (data.order.isOnHold) {
      labels.push('$report_gmailerrorred');
    }

    return labels;
  }

  public getTableRowClass(data: CustomerOrderTableData) {
    return {
      'customer-order-table-row-expanded': this.expandedRow === data
    };
  }

  public onTableRowClick(data: CustomerOrderTableData) {
    this.expandedRow = this.expandedRow === data ? null : data;
  }

  public menuClick($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public extendDeadline(order: CustomerOrder) {
    const dialogWidth = '420px';
    const dialogData: ExtendDeadlineDialogData = {
      orderId: order.orderId,
      deadlineDate: order.deadlineDate
    };

    this.dialog.open(
      BonusOrderExtendDeadlineDialogComponent, {
      width: dialogWidth,
      data: dialogData
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public onTableDataChange() {
    this.tableDataChange.emit();
  }

  public createNewBonus(order: CustomerOrder) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      bonusProjectId: order.bonusProjectId,
      customerId: order.customerId,
      orderId: order.orderId,
      month: order.forMonth,
      year: order.forYear,
      period: this.getOrderPeriod(order)
    };

    this.dialog.open(
      DirectBonusAddDialogComponent,
      {
        width: dialogWidth,
        data: dialogData
      }
    ).afterClosed()
      .pipe(
        switchMap((newBonusData: DirectBonusFormData | null) => {
          if (newBonusData) {
            return this.directBonusService.customerAddBonusToExtraOrder(newBonusData).pipe(
              map(
                result => ({
                  result,
                  newBonusData
                })
              )
            );
          }

          return EMPTY;
        })
      )
      .subscribe(
        ({
          result,
          newBonusData
        }) => {
          if (result) {
            const componentDisplayValue = this.mapper.getDirectBonusComponentDisplayValue(newBonusData.componentName!);
            const message = `Sie haben den folgenden Bonus der Bestellung im ${newBonusData.period} hinzugefügt:
            ${newBonusData.employeeId} - ${componentDisplayValue} - ${newBonusData.formValue} €`;
            this.alertService.message(message);

            this.tableDataChange.emit();
          }
        },
        () => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }

  public openBulkUploadDialog(order: CustomerOrder) {
    const {
      bonusProjectId,
      customerId
    } = order;

    const dialogWidth = '700px';
    const dialogData: ProjectActionsDialogData = {
      bonusProjectId,
      customerId
    };

    const dialogRef = this.dialog.open(
      CustomerBulkUploadDialogComponent, {
      width: dialogWidth,
      data: dialogData
    });

    const downloadTableSubscription = dialogRef.componentInstance.downloadTable.subscribe(
      (data: DownloadTableProps) => {
        this.downloadFlag = true;
        this.CSVDownloadData = data;
        this.$selectedYear.next(data.selectedYear);
      }
    );

    dialogRef.afterClosed()
      .subscribe(
        (result: boolean) => {
          if (result) {
            downloadTableSubscription.unsubscribe();
            this.onTableDataChange();
          }
        }
      );
  }

  public revertBonusOrder(order: CustomerOrder) {
    const period = moment(order.deadlineDate).format('MM.YYYY');
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId: order.orderId!,
      period
    };

    this.dialog.open(
      DirectBonusRevertDialogComponent, {
        width: dialogWidth,
        data: dialogData
    }).afterClosed()
      .subscribe(
        (cancelClicked) => {
          if (!cancelClicked) {
            const message = `Sie haben die Boni für ${period} erfolgreich zurückgesetzt.
            Sie können jetzt die Boni aus dieser Bestellung wieder selbständig bearbeiten.`;
            this.alertService.message(message);
            this.onTableDataChange();
          }
        },
        () => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }

  public confirmBonusOrder(order: CustomerOrder) {

    const period = moment(order.deadlineDate).format('MM.YYYY');
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId: order.orderId!,
      period
    };

    this.dialog.open(
      DirectBonusConfirmDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            const message = `Sie haben die Boni für ${period} erfolgreich bestellt.
            Sie können jetzt die Boni aus dieser Bestellung nicht mehr selbständig bearbeiten.`;
            this.alertService.message(message);
            this.onTableDataChange();
          }
        },
        () => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }

  // TODO
  public hasContextMenu(allowedOperations: CustomerAdministrationOrderAllowedOperations) {
    return allowedOperations.canConfirmOrder ||
      allowedOperations.canCreateBonus ||
      allowedOperations.canRevertConfirmedOrder;
  }


}
