import BonusController from './bonusController';
import bonusSideController from'./bonus_side/bonusSideController';
import { BonusComponentController } from './components/BonusComponentController';
import { BonusCheckoutController } from './checkout/BonusCheckoutController';
import bonusAssistantDialogController from './assistant/assistant_dialogue/bonusAssistantDialogController';
import BonusComponentControllerEntfernung from './components/BonusComponentControllerEntfernung';
import BonusComponentControllerEssenscheck from './components/BonusComponentControllerEssenschecks';
import { BonusComponentControllerSachbezug } from './components/BonusComponentControllerSachbezug';
import { BonusComponentControllerSachbezugProfit } from './components/BonusComponentControllerSachbezugProfit';
import { BonusComponentControllerSachbezugPluxee } from './components/BonusComponentControllerSachbezugPluxee';
import { BonusDashboardController } from './bonus_dashboard/BonusDashboardController';
import BonusComponentControllerErholungsbeihilfe from './components/BonusComponentControllerErholungsbeihilfe';
import { BonusComponentControllerDienstwagen } from './components/BonusComponentControllerDienstwagen';
import { BonusComponentControllerPersonalRabatt } from './components/BonusComponentControllerPersonalRabatt';
import { BonusComponentControllerVMA } from './components/BonusComponentControllerVMA';
import { BonusComponentControllerTelecommunication } from './components/BonusComponentControllerTelecommunication';
import { BonusComponentControllerFreistellungstag } from './components/BonusComponentControllerFreistellungstag';
import { BonusComponentControllerBikeleasing } from './components/BonusComponentControllerBikeleasing';
import { BonusComponentControllerElectronicPresent} from "./components/BonusComponentControllerElectronicPresent";
import { BonusListController } from './bonus_list/BonusListController';
import { BonusComponentControllerPC } from './components/BonusComponentControllerPC'
import { BonusComponentControllerBAV } from './components/BonusComponentControllerBAV'
import FidApprovalController from './fid_approval/FidApprovalController'
import SetCheckoutDateDialogController from './dialogs/set_checkout_date/SetCheckoutDateDialog.controller'

angular
  .module( 'VSPApp' )
  .controller( 'BonusDashboardController', BonusDashboardController)
  .controller( 'BonusComponentController', BonusComponentController)
  .controller( 'BonusComponentControllerErholungsbeihilfe', BonusComponentControllerErholungsbeihilfe)
  .controller( 'BonusComponentControllerDienstwagen', BonusComponentControllerDienstwagen)
  .controller( 'BonusCheckoutController', BonusCheckoutController)
  .controller( 'BonusComponentControllerVMA', BonusComponentControllerVMA)
  .controller( 'BonusController', BonusController)
  .controller( 'bonusSideController', bonusSideController)
  .controller( 'bonusAssistantDialogController', bonusAssistantDialogController )
  .controller( 'BonusComponentControllerEntfernung', BonusComponentControllerEntfernung )
  .controller( 'BonusComponentControllerEssenscheck', BonusComponentControllerEssenscheck )
  .controller( 'BonusComponentControllerPersonalRabatt', BonusComponentControllerPersonalRabatt)
  .controller( 'BonusComponentControllerSachbezug', BonusComponentControllerSachbezug)
  .controller( 'BonusComponentControllerSachbezugPluxee', BonusComponentControllerSachbezugPluxee)
  .controller( 'BonusComponentControllerSachbezugProfit', BonusComponentControllerSachbezugProfit)
  .controller( 'BonusComponentControllerTelecommunication', BonusComponentControllerTelecommunication)
  .controller( 'BonusComponentControllerFreistellungstag', BonusComponentControllerFreistellungstag)
  .controller( 'BonusComponentControllerBikeleasing', BonusComponentControllerBikeleasing)
  .controller( 'BonusComponentControllerElectronicPresent', BonusComponentControllerElectronicPresent)
  .controller( 'BonusListController', BonusListController)
  .controller( 'BonusComponentControllerPC', BonusComponentControllerPC)
  .controller( 'BonusComponentControllerBAV', BonusComponentControllerBAV)
  .controller( 'FidApprovalController', FidApprovalController)
  .controller( 'SetCheckoutDateDialogController', SetCheckoutDateDialogController)
  ;

