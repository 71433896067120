angular
  .module('VSPApp')
  .directive('printView', [function ()  {
    return {
      restrict: 'A',
      scope: {
        isPrintModeEnabled: '='
      },
      link: function ($scope, elm, attrs) {
        let printSection = document.getElementById('printSection');

        // if there is no printing section, create one
        if (!printSection) {
          printSection = document.createElement('div');
          printSection.id = 'printSection';
          document.body.appendChild(printSection);
        }

        elm.on('click', () => {
          const elementToPrint = document.getElementById(attrs.printElementId);

          if (elementToPrint) {
            onPrintElement(elementToPrint);
          }
        });

        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print');

          mediaQueryList.addListener(function (mql) {
            if (!mql.matches) {
              onAfterPrint();
            }
          });
        }

        window.onafterprint = onAfterPrint;

        const onAfterPrint = () => {
          // clean the print section before adding new content
          document.body.classList.remove('printMode');
          printSection.innerHTML = '';
        }

        const onPrintElement = element => {
          // clones the element you want to print
          const elementClone = element.cloneNode(true);
          document.body.classList.add('printMode');
          printSection.innerHTML = '';
          printSection.appendChild(elementClone);
          window.print();
        }
      }
    };
  }]);
