import { TicketCreation } from "src/app/car-leasing/models/car-leasing.interfaces";

export class CarLeasingTicketCreation implements TicketCreation {
  public ticketNumber: string;
  public ticketUrl: string;

  constructor(ticketNumber: string, ticketUrl: string){
    this.ticketNumber = ticketNumber;
    this.ticketUrl = ticketUrl;
  }
}
