import { Component, Inject, OnInit } from '@angular/core';
import { map, switchMap, filter, tap } from 'rxjs/operators';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';

@Component({
  selector: 'vn-benefits-catalog',
  templateUrl: './benefits-catalog.component.html',
  styleUrls: ['./benefits-catalog.component.scss']
})
export class BenefitsCatalogComponent implements OnInit {

  public retailers$ = this.bonagoService.bonagoRetailersBasic$.pipe(
    switchMap(retailers => this.bonagoService.retailerFilters$.pipe(
      map(filters => !filters.rawName ? retailers : retailers.filter(
        (retailer => retailer.rawName.toLowerCase().includes(filters.rawName!.toLocaleLowerCase()))
      ))
    ))
  );

  public selectedCategoryName$ = this.bonagoService.selectedCategory$.pipe(
    map(category => category && category.name),
  );

  public reactiveCols!: number;
  public reactiveColSpan!: number;

  constructor(
    @Inject('$state') private $state: any,
    private bonagoService: BenefitsBonagoService,
  ) { }

  ngOnInit(): void {
    this.reactiveCols = this.calculateCols(window.innerWidth);

    const {
      categoryId
    } = this.$state.params;

    this.bonagoService.selectCategoryId(parseInt(categoryId))
  }

  public onResize($event: any) {
    this.reactiveCols = this.calculateCols($event.target.innerWidth);
  }

  private calculateCols(width: number) {
    if (width <= 700) {
      return 1
    } else if (width <= 1025) {
      return 2
    } else if (width <= 1350) {
      return 3
    } else {
      return 4
    }
  }
}
