import {ERHOLUNGSBEIHILFE_OPTIONS} from "./erholungsbeihilfe_options";
import {FREISTELLUNGSTAG_OPTIONS} from "./freistellungstag_options";
import {BONUS_PROJECT_STATES_BY_NAME} from "./bonus_project_states";

export const FIDUCIA_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS = {
  budget: [
    {
      label: 'Sachbezug',
      prop: 'sachbezug',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Essensschecks PST, Stück',
      prop: 'essensschecks_pst',
      is_visible: false,
      settings: [
        {
          label: 'Standardstückzahl',
          key: 'value',
          attributes: {currency: {to_format: false}},
          id: 0
        },
        {
          label: 'Preis per Stück',
          key: 'per_item_price',
          id: 1
        },
        {
          label: 'Maximale Stückzahl',
          key: 'max_eq',
          parent: '.range',
          attributes: {currency: {to_format: false}},
          id: 2
        }
      ]
    },
    {
      label: 'Essensschecks frei, Stück',
      prop: 'essensschecks_frei',
      is_visible: false,
      settings: [
        {
          label: 'Standardstückzahl',
          key: 'value',
          attributes: {currency: {to_format: false}},
          id: 0
        },
        {
          label: 'Preis per Stück',
          key: 'per_item_price',
          id: 1
        },
        {
          label: 'Maximale Stückzahl',
          key: 'max_eq',
          parent: '.range',
          attributes: {currency: {to_format: false}},
          id: 2
        }
      ]
    },
    {
      label: 'Mobiltelefon',
      prop: 'handy',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-mobilePhone'
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-mobilePhone'
        }
      ]
    },
    {
      label: 'PC-Leasing, Wert',
      prop: 'pc',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-pcLeasing'
        },
        // {
        //   label: 'Maximalwert',
        //   key: 'max_eq',
        //   parent: '.range',
        //   id: 1
        // },
        // {
        //   label: 'Payer',
        //   key: 'insurance_paid_by',
        //   parent: '.configuration',
        //   enabled: false,
        //   type: 'select',
        //   options: { key: 'INSURANCE_PAYERS' },
        //   settings: { is_visible: true, is_editable: false }
        // },
        // {
        //   label: 'Rate',
        //   key: 'rate',
        //   parent: '.configuration',
        //   enabled: false,
        //   type: 'select',
        //   options: { key: 'PC_RATE_TYPES' },
        //   settings: { is_visible: true, is_editable: false }
        // }
      ]
    },
    {
      label: 'PC-Leasing, Wert',
      prop: 'pc_1',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-pcLeasing2'
        }
      ]
    },
    {
      label: 'Internetpauschale PST, EUR',
      prop: 'internet',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Entfernungspauschale PST, EUR',
      prop: 'entfernung',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Erholungsbeihilfe PST, EUR p.a.',
      prop: 'erholungsbeihilfe',
      is_visible: true,
      settings: [...ERHOLUNGSBEIHILFE_OPTIONS.map((option, index) => {
        return {
          label: option.label,
          key: option.id,
          parent: '.options',
          id: index,
          cyData: option.cyData
        };
      }),
        {
          label: 'Start date',
          type: 'datetime',
          key: 'bonus_start_date',
          parent: '.configuration.dates',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputBeginDate-vacationGrant'
        },
        {
          label: 'End date',
          type: 'datetime',
          key: 'bonus_end_date',
          parent: '.configuration.dates',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length + 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputEndDate-vacationGrant'
        },
        {
          label: 'Decision date',
          type: 'datetime',
          key: 'user_decision_date',
          parent: '.configuration.dates',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length + 2,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputDecisionDate-vacationGrant'
        }
      ]
    },
    {
      label: 'Kindergartenzuschuss',
      prop: 'kinder',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Jobrad',
      prop: 'EBike',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        // {
        //   label: 'Maximalwert',
        //   key: 'max_eq',
        //   parent: '.range'
        // }
      ]
    },
    {
      label: 'Dienstwagengarage (Eigentum)',
      prop: 'dienstwagen_miete',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Dienstwagengarage (gemietet)',
      prop: 'dienstwagen_auslage',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Personalrabatt',
      prop: 'personal_rabatt',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Verpflegungsmehraufwand (st- u. sv-frei)',
      prop: 'VMA_STSVFREI',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Verpflegungsmehraufwand (pSt.)',
      prop: 'VMA_PST',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Festnetztelefon',
      prop: 'festnetz',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-landlinePhone'
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-landlinePhone'
        }
      ]
    },
    {
      label: 'Bikeleasing',
      prop: 'Bikeleasing',
      is_visible: true,
      settings: [
        // {
        //   label: 'Standardwert',
        //   key: 'value',
        //   id: 0
        // },
        // {
        //   label: 'Maximalwert',
        //   key: 'max_eq',
        //   parent: '.range'
        // }
      ]
    },
    {
      label: 'Bikeleasing',
      prop: 'bike_1',
      is_visible: true,
      settings: [
        // {
        //   label: 'Standardwert',
        //   key: 'value',
        //   id: 0
        // },
        // {
        //   label: 'Maximalwert',
        //   key: 'max_eq',
        //   parent: '.range'
        // }
      ]
    },
    {
      label: 'VWL',
      prop: 'VWL',
      is_visible: true,
      // settings: [
      //   {
      //     label: 'Standardwert',
      //     key: 'value'
      //   }
      // ]
    },
    {
      label: 'BAV',
      prop: 'BAV',
      is_visible: true,
      settings: []
    },
    {
      label: 'Freistellungstag',
      prop: 'Freistellungstag',
      is_visible: true,
      settings: FREISTELLUNGSTAG_OPTIONS.map(option => {
        return {
          label: option.label,
          key: option.id,
          parent: '.options',
          id: option.id,
          cyData: option.cyData
        };
      })
    },
    {
      label: 'Wertguthaben',
      prop: 'wertguthaben',
      is_visible: true,
      settings: [
        {
          label: 'Sichtbar von',
          key: 'start_date',
          parent: '.configuration.dashboard.visible',
          id: 0,
          type: 'datetime',
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputVisibleFrom-assetsVoucher'
        },
        {
          label: 'Sichtbar bis',
          key: 'end_date',
          parent: '.configuration.dashboard.visible',
          id: 1,
          type: 'datetime',
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputVisibleUntil-assetsVoucher'
        }
      ]
    }
  ],
  metadata: [
    {
      id: 0,
      type: 'checkbox',
      parent: 'documentation',
      key: 'is_enabled',
      title: 'Dokumentation erforderlich',
      value: {default: true},
      settings: {
        is_editable: false
      }
    },
    {
      id: 1,
      type: 'checkbox',
      parent: 'quiz',
      key: 'is_enabled',
      cyData: 'vn.cockpit.bonus.createProjectDialog.checkWithQuiz',
      title: 'Quiz',
      value: {default: false},
      settings: {
        is_editable: true
      }
    },
    {
      id: 2,
      type: 'select',
      parent: 'bonus_config',
      key: 'expiration_action',
      title: 'Bonus verfällt',
      options: {key: 'BONUS_EXPIRATION_ACTIONS'},
      settings: {
        is_editable: false
      }
    },
    {
      id: 3,
      type: 'select',
      parent: 'bonus_config',
      key: 'bonus_type',
      title: 'Bonus Freiwillig',
      options: {key: 'BONUS_TYPES'},
      settings: {
        is_editable: false
      }
    },
    {
      id: 4,
      type: 'datetime',
      parent: 'dates',
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputProjectBeginDate',
      key: 'bonus_project_start_date',
      title: 'Beginn'
    },
    {
      id: 5,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_project_end_date',
      title: 'Ende',
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputProjectEndDate',
      settings: {
        date: {
          min: {
            key: 'bonus_project.dates.bonus_project_start_date'
          }
        }
      }
    },
    {
      id: 6,
      type: 'datetime',
      parent: 'dates',
      key: 'user_decision_date',
      title: 'Entscheidung Nutzung bis',
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputUserDecisionDate',
      settings: {
        is_visible: false,
        is_editable: false,
        equals: {
          key: 'dates.bonus_project_start_date'
        }
      }
    },
    {
      id: 7,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_publish_date',
      title: 'Bonus Veröffentlichung zum',
      settings: {
        is_visible: false,
        is_editable: false,
        equals: {
          key: 'dates.bonus_project_start_date'
        }
      }
    },
    {
      id: 8,
      type: 'checkbox',
      parent: 'transfer',
      key: 'is_enabled',
      title: 'Transfer',
      cyData: 'vn.cockpit.bonus.createProjectDialog.checkWithTransfer',
      value: {default: false},
      settings: {
        is_editable: true,
        editable_only_in_state_id: BONUS_PROJECT_STATES_BY_NAME['draft']['id'],
        from_bonus_project_id: undefined
      }
    },
    {
      id: 9,
      type: 'string_input',
      parent: 'bonus_config',
      key: 'individual_headline',
      title: 'Individual Headline',
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputIndividualHeadline',
      settings: {
        is_visible: true,
        is_editable: true
      }
    },
    {
      id: 10,
      type: 'checkbox',
      parent: 'project_approved',
      key: 'is_enabled',
      title: 'Projekt Freigegeben',
      value: {default: false},
      cyData: 'vn.cockpit.bonus.createProjectDialog.checkWithReleased',
      settings: {
        is_visible: true,
        is_editable: val => val
      }
    },
  ],
  notifications: [
    {
      id: 0,
      key: 'welcome_notification',
      parent: 'notifications',
      bonus_notification_type: 'welcome_notification',
      bonus_project_type: 4,
      title: 'Welcome Notification',
      text: 'Ihnen steht noch ein verfügbares Budget von ${restbonus} zur Verfügung.',
      bonus_type: 'fiducia_bonus'
    },
    {
      id: 1,
      key: 'standard_notification',
      parent: 'notifications',
      bonus_notification_type: 'standard_notification',
      bonus_project_type: 4,
      title: 'Standard Notification',
      text: 'Ihnen steht noch ein verfügbares Budget von ${restbonus} zur Verfügung.',
      bonus_type: 'fiducia_bonus'
    }
  ]
};
