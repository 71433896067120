import { Inject, Injectable } from '@angular/core';
import { FoodVoucherRetailer } from '../models/food-voucher.model';


@Injectable()
export class FoodVoucherRetailersMapService {

  public readonly overviewMapId = 'ee695372458ffb1a';

  constructor(
    @Inject('Made') private made: any,
    @Inject('EmployeeService') private employeeService: any,
    @Inject('customerService') private customerService: any
  ) {}

  private async request(localPath: string, parameters: object = {}) {
    return this.made.request('rpc://valuenetdb/food_voucher/' + localPath, parameters);
  }

  public async getRetailers(customerId: Number, zip_code: string = ''): Promise<FoodVoucherRetailer[]> {
    return await this.request(
      'get_retailers', {
        customer_id: customerId,
        zip_code_query: zip_code
      }
    ).then((retailers: any[]) => {
      return retailers.map(retailer => (this._mapToRetailer(retailer))
      );
    });
  }

  public async getRetailersWithinRangeForLatLng(latLng: {lat: Number, lng: Number}) {
    const { lat, lng } = latLng;

    return await this.request(
      'get_retailers_for_lat_lng',
      {lat, lng}
    ).then((retailers: any[]) => {
      return retailers.map(retailer => (this._mapToRetailer(retailer))
      );
    });
  }

  private _mapToRetailer(retailer: any) : FoodVoucherRetailer {
    return {
      lat: retailer['lat'],
      lng: retailer['lng'],
      retailerName: retailer['retailer_name'],
      address: retailer['address'],
      streetNumber: retailer['street_number'],
      zipCode: retailer['zip_code'],
      city: retailer['city'],
      categoryCode: retailer['category_code'],
      categoryName: retailer['category_name'],
      subCategory: retailer['sub_category']
    }
  }

}
