const BenefitConstants = {
  bonago: {
    title: 'Gutscheine',
    icon: 'bonago',
    link: '/benefit/bonago/home',
    image: '/assets/img/benefit/bonago.png',
    imageIsLargeIcon: true,
    has_errors: false,
    required: {}
  },
  amazon: {
    title: 'Amazon',
    icon: 'amazon',
    image: '/assets/img/benefit/amazon.png',
    imageIsLargeIcon: true,
    disturbe: 'Coming soon',
    has_errors: false,
    required: {}
  },
  travel: {
    title: 'Reisen',
    icon: 'travel',
    image: '/assets/img/benefit/travel.png',
    imageIsLargeIcon: true,
    has_errors: false,
    required: {}
  },
  mpp: {
    title: 'MPP',
    icon: 'mpp',
    link: '/mpp',
    image: '/assets/img/benefit/mpp.png',
    imageIsLargeIcon: true,
    has_errors: false,
    required: {}
  }
};

export default BenefitConstants;
