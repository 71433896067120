import './neo.controller';

import './berater';

import './checkout';

import './components';

import './side/side.controller';

import './verguetung/verguetung.controller';

import './standalone_components';

import BonusBeraterController from './bonus-berater/BonusBeraterController';
import {StandaloneComponentCheckoutController} from './standalone_component_checkout/StandaloneComponentCheckoutController';

angular
  .module( 'VSPApp' )
  .controller( 'BonusBeraterController', BonusBeraterController )
  .controller( 'StandaloneComponentCheckoutController', StandaloneComponentCheckoutController );
