/* eslint-disable no-console */
import { DEFAULT_YEARLY_INFO_VALIDATION_WIZARD_STEPS, DEFAULT_YEARLY_INFO_VALIDATION_ANSWERS, DEFAULT_YEARLY_ADVISOR_DETAILS } from '../../../services/yearlyService';

const answer_reducer = (answers, step) => {
  step.options.forEach(option => {
    answers[option.parent] = {};
    answers[option.parent][option.key] = option.default;
  });
  return answers;
};


const COMPONENTS_REQUIRING_YEARLY_UPDATES = {
  'internet': {},
  'handy': { extra_sentence: true },
  'festnetz': { extra_sentence: true },
  'entfernung': { extra_sentence: true }
};
const COMPONENTS_REQUIRING_YEARLY_UPDATES_NAMES = Object.keys(COMPONENTS_REQUIRING_YEARLY_UPDATES);

const $inject = [
  '$scope',
  'employeeService',
  'yearlyService',
  'vbmData',
  '$timeout',
  'SteuerklassenConstants',
  'WizardHandler',
  'vbmService',
  'ComponentConstants',
  'SliderOptionsConstants',
  '$interpolate',
  'NotificationService',
  'UserNotificationService',
  '$state',
  '$window',
  'customerService',
  'dialogService',
  'lodash',
  'ByteplantService'
];

export default class InfoValidationController {
  constructor(
    $scope,
    employeeService,
    yearlyService,
    vbmData,
    $timeout,
    SteuerklassenConstants,
    WizardHandler,
    vbmService,
    ComponentConstants,
    SliderOptionsConstants,
    $interpolate,
    NotificationService,
    UserNotificationService,
    $state,
    $window,
    customerService,
    dialogService,
    lodash,
    ByteplantService
  ) {
    Object.assign(this, {
      $scope,
      employeeService,
      vbmData,
      SteuerklassenConstants,
      WizardHandler,
      vbmService,
      $timeout,
      ComponentConstants,
      yearlyService,
      SliderOptionsConstants,
      $interpolate,
      NotificationService,
      UserNotificationService,
      $state,
      $window,
      customerService,
      dialogService,
      lodash,
      ByteplantService
    });

    this.loading = {
      initial: true,
      steps: true,
      saving: false
    };

    this.stkl_constants_by_value = this.SteuerklassenConstants.reduce((acc, stkl) => {
      acc[stkl.value] = stkl;
      return acc;
    }, {});

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.initial = false;
      }, 0);
    });

    this.$scope.$watch(() => {
      return this.answers.updated.components.entfernung;
    }, (newValue, oldValue) => {
      if (!angular.equals(newValue, oldValue)) {
        this.$timeout(() => {
          this.calculateEntfernungPerMonthValue();
          this.calculateEntfernungYearlyValue();
        }, 0);
      }
    }, true);

    this.$scope.$watch(() => {
      return this.answers.updated.personal_info.pap_stkl.value
    }, (newValue, oldValue) => {
      if (!angular.equals(newValue, oldValue)) {
        this.$timeout(() => {
          this.answers.updated.personal_info.pap_stkl.display = this.stkl_constants_by_value[newValue].name;
        });
      }
    }, true);

    this.stkl_constants_by_id = this.SteuerklassenConstants.reduce((acc, stkl) => {
      acc[stkl.id] = stkl;
      return acc;
    }, {});

    this.create_made_user = false;
  }

  resetAddress() {
    this.answers.updated.personal_info.address = angular.copy(this.answers.current.personal_info.address);
    delete this.answers.updated.personal_info.address.has_answered;
    delete this.answers.updated.personal_info.address.needs_update;
  }

  resetSteuerKlasse() {
    this.answers.updated.personal_info.pap_stkl = angular.copy(this.answers.current.personal_info.pap_stkl);
    delete this.answers.updated.personal_info.pap_stkl.has_answered;
    delete this.answers.updated.personal_info.pap_stkl.needs_update;
  }

  areDocumentsValid(step) {
    if (step.documents_upload.required) {
      return this.answers.updated.components[step.component].documents && this.answers.updated.components[step.component].documents.length > 0;
    } else {
      return true;
    }
  }

  getSliderOptions() {
    return angular.copy(this.slider_options);
  }

  async init() {
    this.forms = {};
    this.answers = angular.copy(DEFAULT_YEARLY_INFO_VALIDATION_ANSWERS);
    this.slider_options = angular.copy(this.SliderOptionsConstants);
    this.toc_accepted = false;
    Object.assign(this.slider_options, {
      to: 100,
      step: 1,
      from: 0
    });

    this.validation_user = await this.yearlyService.getCurrentUser();
    if (!this.validation_user) {
      throw new Error('NO VALIDATION USER');
    }

    this.customer_configuration = await this.customerService.getConfigurationByEmployeeId(this.validation_user.EMP_ID);

    this.employee_id = this.validation_user.EMP_ID;
    this.calculation_id = this.validation_user.CALC_ID;
    this.has_correct_email = this.validation_user.made_user && this.validation_user.made_user.emails.length > 0 && this.validation_user.made_user.emails[0] !== '';

    // set custom email contact details
    this.$scope.advisorDetails = DEFAULT_YEARLY_ADVISOR_DETAILS;
    // get employee
    this.employee = await this.employeeService.get(this.employee_id);

    this.answers.current.personal_info.address = angular.copy(this.employee.address);
    this.answers.updated.personal_info.address = angular.copy(this.answers.current.personal_info.address);

    this.answers.current.personal_info.birthdate = { birthdate: this.employee.birthdate };
    this.answers.updated.personal_info.birthdate = angular.copy(this.answers.current.personal_info.birthdate);

    // get latest finalized calculation
    this.calculation = await this.yearlyService.getCalculationById(this.calculation_id, this.employee_id);

    // get tax info
    this.answers.current.personal_info.pap_stkl = {
      value: this.calculation.parameters.pap_stkl,
      display: this.SteuerklassenConstants.filter(type => type.value === this.calculation.parameters.pap_stkl)[0]['name']
    };
    this.answers.updated.personal_info.pap_stkl = angular.copy(this.answers.current.personal_info.pap_stkl);

    for (let component_name in this.calculation.neo_components) {
      if (angular.equals(this.calculation.neo_components[component_name].enabled, true) && COMPONENTS_REQUIRING_YEARLY_UPDATES_NAMES.includes(component_name)) {
        this.answers.current.components[component_name] = angular.copy(this.calculation.neo_components[component_name]);

        this.answers.updated.components[component_name] = angular.copy(this.answers.current.components[component_name]);

      }
    }
  }

  calculateEntfernungPerMonthValue() {
    let model = this.answers.updated.components.entfernung;
    this.answers.updated.components.entfernung.monthly_value = (model.anzahl_km * model.anzahl_tage * model.cent_pro_km) || 0;
    this.answers.updated.components.entfernung.value = this.answers.updated.components.entfernung.monthly_value;
    return this.answers.updated.components.entfernung.monthly_value;
  }

  calculateEntfernungYearlyValue() {
    return this.answers.updated.components.entfernung.yearly_value = this.calculateEntfernungPerMonthValue() * 12;
  }

  componentsNeedUpdate() {
    let needs_update;
    for (let component_name in this.answers.current.components) {
      if (this.answers.current.components[component_name].needs_update) {
        needs_update = true;
      }
    }
    return needs_update;
  }

  componentsConfirmed() {
    let confirmed;
    for (let component_name in this.answers.current.components) {
      if (!this.answers.current.components[component_name].needs_update) {
        confirmed = true;
      }
    }
    return confirmed;
  }
  personalInfoNeedsUpdate() {
    let needs_update;
    for (let personal_info_prop in this.answers.current.personal_info) {
      if (this.answers.current.personal_info[personal_info_prop].needs_update) {
        needs_update = true;
      }
    }
    return needs_update;
  }

  personalInfoConfirmed() {
    let confirmed;
    for (let personal_info_prop in this.answers.current.personal_info) {
      if (!this.answers.current.personal_info[personal_info_prop].needs_update) {
        confirmed = true;
      }
    }
    return confirmed;
  }

  checkHasAnswered(root) {
    return Object.keys(root).reduce((acc, property) => {
      acc = acc && !!root[property]['has_answered'];
      return acc;
    }, true);
  }

  acceptComponentUpdate() {
    this.WizardHandler.wizard().next();
  }

  prepareOverview() {
    this.loading.overview = true;
    // check for updated component data and prepare
    this.updated_components = {};
    for (let component_name in this.answers.current.components) {
      if (this.answers.current.components[component_name].needs_update) {
        this.updated_components[component_name] = {};
        this.updated_components[component_name]['current'] = this.answers.current.components[component_name];
        this.updated_components[component_name]['updated'] = this.answers.updated.components[component_name];
      }
    }
    this.unchanged_components = {};
    for (let component_name in this.answers.current.components) {
      if (!this.answers.current.components[component_name].needs_update) {
        this.unchanged_components[component_name] = {};
        this.unchanged_components[component_name]['current'] = this.answers.current.components[component_name];
      }
    }

    // check for updated personal data and prepare
    this.updated_personal_info = {};
    for (let personal_info_prop in this.answers.current.personal_info) {
      if (this.answers.current.personal_info[personal_info_prop].needs_update) {
        this.updated_personal_info[personal_info_prop] = {};
        this.updated_personal_info[personal_info_prop]['current'] = this.answers.current.personal_info[personal_info_prop];
        this.updated_personal_info[personal_info_prop]['updated'] = this.answers.updated.personal_info[personal_info_prop];
      }
    }
    this.unchanged_personal_info = {};
    for (let personal_info_prop in this.answers.current.personal_info) {
      if (!this.answers.current.personal_info[personal_info_prop].needs_update) {
        this.unchanged_personal_info[personal_info_prop] = {};
        this.unchanged_personal_info[personal_info_prop]['current'] = this.answers.current.personal_info[personal_info_prop];
      }
    }
    this.loading.overview = false;
  }

  hasAnsweredPersonalQuestions() {
    let personal_info = this.answers.current.personal_info;
    return this.checkHasAnswered(personal_info);
  }

  hasAnsweredComponents() {
    return this.checkHasAnswered(this.answers.current.components);
  }

  createOverviewDocument() {
    this.loading.overview_document = true;

    this.yearlyService.getOverviewDocument(this.employee_id, this.answers).then((res) => {
      this.$window.open(res.response, '_blank');
    }).then(() => {
      this.$timeout(() => {
        this.loading.overview_document = false;
      });
    });
  }

  goToStart() {
    this.$state.go('landing');
  }

  canCreateMade() {
    return !this.validation_user.made_user;
  }

  getDomainErrorMessage() {
    if (this.customer_configuration) {
      this.employer_domain_config = this.customer_configuration['domains_config'];
      return this.customerService.getCustomerDomainErrorMessage(this.employer_domain_config);
    }

    return '';
  }

  canFinishYearlyValidation() {
    let can_not_finish_yearly = !this.forms.email_form.$valid &&
      !this.has_correct_email ||
      (this.create_made_user && !this.toc_accepted);
    return !can_not_finish_yearly;
  }

  finishYearlyValidation() {
    this.$timeout(() => {
      this.loading.saving = true;
    });

    return this.yearlyService.updateYearlyData(
      this.employee,
      this.validation_user.yearly_session_id,
      this.answers,
      this.create_made_user
    ).then(() => {
      this.NotificationService.message('Ihre Eingaben wurden gespeichert.');
      this.yearlyService.unsetCurrentUser();
      if (this.validation_user.made_user) {
        this.$state.go('inApp.dashboardArbeitnehmer', { reload: true });
      } else {
        this.goToStart();
      }
    }, (err) => {

      if (err.error.message.includes('EMAIL_NOT_UNIQUE')) {
        this.forms.email_form.email.$setValidity('unique', false);
      } else if (err.error.message.includes('EMAIL_DOMAIN_NOT_VALID')) {
        this.forms.email_form.email.$setValidity('domain', false);
      } else if (err.error.message.includes('EmailValidationFail')){
        this.forms.email_form.email.$setValidity('invalid', false);
      }

      this.NotificationService.error('Ihre Eingaben konnten nicht gespeichert werden.');
    }).finally(() => {
      this.$timeout(() => {
        this.loading.saving = false;
      })
    });
  }

  markPersonalNoChange(field) {
    this.answers.current.personal_info[field]['needs_update'] = false;
    this.answers.current.personal_info[field]['has_answered'] = true;
    if (field === 'address') {
      this.resetAddress();
    }

    if (field === 'pap_stkl') {
      this.resetSteuerKlasse();
    }
  }

  markPersonalChange(field) {
    this.answers.current.personal_info[field]['needs_update'] = true;
    this.answers.current.personal_info[field]['has_answered'] = true;
  }

  markComponentNoChange(component_name) {
    this.answers.current.components[component_name]['needs_update'] = false;
    this.answers.current.components[component_name]['has_answered'] = true;
  }

  markComponentChange(component_name) {
    this.answers.current.components[component_name]['needs_update'] = true;
    this.answers.current.components[component_name]['has_answered'] = true;
  }

  setSteps() {
    this.loading.steps = true;
    // filter steps
    let steps = DEFAULT_YEARLY_INFO_VALIDATION_WIZARD_STEPS.filter(step => this.isStepRequired(step));

    // attach steps
    this.steps = steps;

    // tell the view steps are ready
    this.$timeout(() => {
      this.loading.steps = false;
    }).then(() => {
      return this.$timeout(() => {
        this.WizardHandler.wizard().next();
      });
    });
  }

  next() {
    this.WizardHandler.wizard().next();
  }

  isAdditionalIntro() {
    return 'entfernung' in this.answers.current.components
      || 'handy' in this.answers.current.components
      || 'festnetz' in this.answers.current.components;
  }

  isStepRequired(step) {
    let component_name = step.component;
    let is_step_required = this.lodash.get(this.answers, `current.components[${component_name}].needs_update`, false);
    return is_step_required;
  }


  toggleRead(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.toc_accepted === false) {
      this.showToc();
    } else {
      this.toc_accepted = !this.toc_accepted;
    }
  }

  showToc() {

    this.customerService.getConfigurationByEmployeeId(this.employee_id)
      .then(configuration => {
        this.dialogService.registrationToc(configuration).then((result) => {
          if (result) {
            this.readToc = true;
            this.toc_accepted = true;
          }
        });
      });
  }

  resetEmailErrors() {
    this.forms.email_form.email.$setValidity('unique', true);
    this.forms.email_form.email.$setValidity('domain', true);
    this.forms.email_form.email.$setValidity('invalid', true);
  }

  handleEmailInputChange() {
    this.resetEmailErrors();
  }

}

InfoValidationController.$inject = $inject;
