<div class='form-group'>
    <mat-slide-toggle [checked]="value"
                      [attr.data-cy]='switchKey'
                      (change)="toggle($event.checked)">
    </mat-slide-toggle>

    <span class="company-email-label">
        {{ noticeLabel }}
    </span>
</div>
