var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_html = pug_html + "\u003Cdiv class=\"cockpit_statistics-wrapper\"\u003E\u003Csection id=\"dashboard-box\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"medium-12 large-12 columns\" overlay=\"$ctrl.loading.main\"\u003E\u003Ctabset\u003E\u003Ctab class=\"bigtab xsmall-12 medium-3 columns\" heading=\"Analytics\"\u003E\u003Csection class=\"tabpage-content transparent\"\u003E";
pug_mixins["circle"] = pug_interp = function(max, current, title, theme){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!theme) {
theme = 'light'
}
pug_html = pug_html + "\u003Cdiv class=\"circletext\"\u003E\u003Cdiv" + (" class=\"circle\""+pug.attr("style", pug.style('background:{{$ctrl.BrandingService.circleProgress.'+theme+'.bgColor}};'), true, true)) + "\u003E\u003Cround-progress" + (pug.attrs(pug.merge([{"max": pug.escape(max),"current": pug.escape(current),"color": pug.escape('{{$ctrl.BrandingService.circleProgress.'+theme+'.color}}'),"bgcolor": pug.escape('{{$ctrl.BrandingService.circleProgress.'+theme+'.bgColor}}'),"radius": "60","stroke": "4","responsive": "true"},attributes]), true)) + "\u003E\u003C\u002Fround-progress\u003E\u003Cspan" + (" class=\"circle-text\""+pug.attr("style", pug.style('color:{{$ctrl.BrandingService.circleProgress.'+theme+'.color}};'), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"bigbox transparent\"\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\" ng-if=\"$parent.tabs[0].active\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\" ng-if=\"!$ctrl.customerId\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\" title=\"{{ $ctrl.general.customers.count }}\"\u003EAnzahl Unternehmen\u003C\u002Fspan\u003E";
}
}, 
            '$ctrl.general.customers.count',
            '$ctrl.general.customers.count',
            '{{ $ctrl.general.customers.count }}',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\" ng-if=\"$ctrl.isDashboardAdvisor &amp;&amp; !$ctrl.customerId\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E{{ $ctrl.general.customers.active }} von {{ $ctrl.general.customers.count }}\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\"\u003Eaktive Portale\u003C\u002Fspan\u003E";
}
}, 
            '$ctrl.general.customers.count',
            '$ctrl.general.customers.active',
            '{{ $ctrl.general.customers.active / $ctrl.general.customers.count * 100 | number:1 }} %',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\" ng-if=\"$ctrl.isDashboardAdvisor &amp;&amp; !$ctrl.customerId\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E{{ $ctrl.general.customers.testmode }} von {{ $ctrl.general.customers.active }}\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\"\u003ETestportale\u003C\u002Fspan\u003E";
}
}, 
            '$ctrl.general.customers.active',
            '$ctrl.general.customers.testmode',
            '{{ $ctrl.general.customers.testmode / $ctrl.general.customers.active * 100 | number:1 }} %',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\" title=\"{{ $ctrl.general.employees.count }}\"\u003EArbeitnehmer\u003C\u002Fspan\u003E";
}
}, 
            '$ctrl.general.employees.count',
            '$ctrl.general.employees.count',
            '{{ $ctrl.general.employees.count }}',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E{{ $ctrl.general.employees.neo }} von {{ $ctrl.general.employees.count }}\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\"\u003ETeilnehmerquote\u003C\u002Fspan\u003E";
}
}, 
            '$ctrl.general.employees.count',
            '$ctrl.general.employees.neo',
            '{{ $ctrl.general.employees.neo / $ctrl.general.employees.count * 100 | number:1 }} %',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\" ng-if=\"$ctrl.isDashboardAdvisor\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E{{ $ctrl.general.employees.active }} von {{ $ctrl.general.employees.count }}\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\"\u003Eaktive Nutzer\u003C\u002Fspan\u003E";
}
}, 
            '$ctrl.general.employees.count',
            '$ctrl.general.employees.active',
            '{{ $ctrl.general.employees.active / $ctrl.general.employees.count * 100 | number:1 }} %',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E\u003C\u002Ftab\u003E\u003Ctab class=\"bigtab xsmall-12 medium-3 columns\" heading=\"Benefitnutzung\"\u003E\u003Csection class=\"tabpage-content transparent\"\u003E" + (null == (pug_interp = require("../shared/tabs/_components.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fsection\u003E\u003C\u002Ftab\u003E\u003C\u002Ftabset\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;