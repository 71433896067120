import ComponentController from '../ComponentController';

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'vbmService',
  'userService',
  'bikeleasingService',
  'ComponentWizardConstants'
];
export default class ComponentControllerdticket extends ComponentController {

  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    vbmService,
    userService,
    bikeleasingService,
    ComponentWizardConstants
  ) {

    super(
      $q,
      $injector,
      $scope,
      $state,
      $transitions,
      $location,
      Made,
      debounceFactory,
      vbmData,
      neoModifierService,
      customerService,
      PlausibilityService,
      dialogService,
      SliderOptionsConstants,
      componentsService,
      NotificationService,
      vbmService,
      userService,
      bikeleasingService,
      ComponentWizardConstants
    );

    this.loading = true;

    this.employeeId = this.Made.user.valuenet_id;
    this.moduleId = this.$state.params.moduleId;

    // hack because of problem with inheritance
    this.$timeout = this.$injector.get('$timeout');
    this.employeeService = this.$injector.get('employeeService');
    this.VSP_CONSTANTS = this.$injector.get('VSP_CONSTANTS');
    this.$interpolate = this.$injector.get('$interpolate');
    this.DticketOrderService = this.$injector.get('DticketOrderService');

    this.dTicketDashboardWizardIntro = ComponentWizardConstants.dticket.items[0];
    this.canCheckoutDTicket = false;
    this.whitelistDisabled = true;

    this.init().then(() => {
      this.$timeout(() => {
        this.loading = false;
      });
    });
  }

  async init() {
    this.neoProject = await this.customerService.getNeoProjectByEmployeeId(this.employeeId)
    const module = this.neoProject.neo_components[ this.moduleId ]

    this.hasActiveDTicket = await this.DticketOrderService.hasActiveDTicket(this.employeeId);
    const beginDate = await this.DticketOrderService.getBeginDateDticket();
    if (beginDate) {
      this.beginDateDticket = this.formatDate(beginDate);
    }
    this.hasSetupForDTicket = this.neoProject.project && this.neoProject.project.project_approved && module && module.enabled;

    let whitelist = await this.customerService.getWhitelistByEmployeeId(this.employeeId)
    this.whitelistDisabled = whitelist.disabled;
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  // setSalaryCheckout(vbmData) {
    // super.setSalaryCheckout(vbmData);


    // https://vsp.atlassian.net/browse/P92VN-1178
    // if settings are alright for dticket we can checkout without salary
    // this.disableCheckoutIfNoSalary = !this.hasSetupForDTicket;
    //
    // this.setSalaryError();
  // }

  toggleStep(step) {
    return this.currentStep = step;
  }

  backToDashboard() {
    this.$state.go('inApp.neo.side.verguetung');
  }

  goToDTicketCheckout() {
    if (this.hasSetupForDTicket && !this.hasActiveDTicket && !this.whitelistDisabled) {
      this.$state.go('inApp.dticket_checkout');
    }
  }

}
ComponentControllerdticket.$inject = $inject;
