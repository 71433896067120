import {DEFAULT_MPP_ARTICLE, PC_YEAR_CONTRACT_DURATION} from '../../../services/mppService';

const $inject = [
  '$state',
  '$scope',
  '$timeout',
  'NotificationService',
  'mppService',
  'lodash',
];
export default class MppBasketForm {

  constructor(
    $state,
    $scope,
    $timeout,
    NotificationService,
    mppService,
    lodash,
  ) {
    Object.assign(this, {
      $state,
      $scope,
      $timeout,
      NotificationService,
      mppService,
      lodash,
    });

    this.$scope.leasingRegex = /^\d+\/\d+$/;

    this.$onInit = () => {
      this.loading = false;
      this.withArticles = false;

      if (this.basket) {
        this.basketModel = this.basket;

        if (this.basketModel.articles && this.basketModel.articles.length) {
          this.withArticles = true;
        }

      } else {
        this.basketModel = {
          employeeId: this.employeeId,
          leasing: undefined,
          cart_leasing_month_netto: undefined,
          sum: undefined,
          order_id: undefined,
          contract_id: undefined,
          beginDate: undefined,
          endDate: undefined,
          closed: true,
          locked: true,
          articles: [],
          create_order_process: true
        };

        this.withArticles = true;
      }

      if (!this.basketModel.employeeId) {
        throw "Invalid basket";
      }
      this.basketModel['employeeId'] = +this.basketModel['employeeId'];
    };
  }

  addArticle() {
    if (!this.basketModel.articles) {
      this.basketModel.articles = [];
    }
    this.basketModel.articles.push(angular.copy(DEFAULT_MPP_ARTICLE));
  }

  removeArticle(index) {
    this.basketModel.articles.splice(index, 1);
  }

  async calculateLeasing() {
    this.$timeout(() => {
      this.loading = true;
    });

    if (this.basketModel.sum && this.basketModel.sum > 0) {
      // This has to be changed in the future dependimg on the customer configiration
      // leasing has to be set cart_leasing_month_netto or cart_leasing_month_brutto depending give configuration
      let fields = await this.mppService.getBasketPrecalculatedValues({
        employee_id: this.basketModel.employeeId,
        netto: this.basketModel.sum,
        with_handling_fee: false
      });

      this.basketModel = Object.assign(this.basketModel,  fields);

    } else {
      this.basketModel.leasing = undefined;
      this.basketModel.cart_leasing_month_netto = undefined;
    }

    this.$timeout(() => {
      this.loading = false;
    });

  }

  canSave() {
    let has_added_baskets = this.withArticles ? this.basketModel.articles && this.basketModel.articles.length > 0: true;

    return this.basketModelForm.$valid && has_added_baskets;
  }

  async getEndDate() {
    let begin_date = this.basketModel.beginDate;
    this.$timeout(()=>{
      this.loading = true;
    });

    return this.mppService.calculateBeginEndDate({
      begin_date: begin_date,
      to_format: true
    }).then((result)=>{
      this.basketModel.endDate = result.end_date;

      this.$timeout(()=>{
        this.loading = false;
      });
    });
  }

  getBasketFields() {
    let omittedFields = [];

    if (this.withArticles) {
      omittedFields.push('leasing');
      omittedFields.push('cart_leasing_month_netto');
      omittedFields.push('sum');
    } else {
      omittedFields.push('articles');
    }

    return this.lodash.omit(this.basketModel, omittedFields);
  }

  async save() {
    await this.dispatchSavedEvent({
      'basket': this.getBasketFields(),
      'with_articles': this.withArticles
    });

  }

  async dispatchSavedEvent(data) {
    if (this.onSave) {
      await this.onSave({
        'savedEvent': data
      });
    }
  }

}
MppBasketForm.$inject = $inject;

import basket_form_html from './basket_form.pug';

angular
  .module('VSPApp')
  .controller('MppBasketForm', MppBasketForm)
  .component('mppBasketFormComponent', {
    controller: 'MppBasketForm',
    controllerAs: '$ctrl',
    template: basket_form_html(),
    bindings: {
      basket: '<',
      employeeId: '<',
      onSave: '&',
    }
  });
