const $inject = [
  '$state',
  '$scope',
  'NotificationService',
  'DigitalSigningService'
];
export default class DigitalSigningSuccessController {
  constructor(
    $state,
    $scope,
    NotificationService,
    DigitalSigningService
  ) {

    this.$state = $state;
    this.$scope = $scope;
    this.digitalSigningService = DigitalSigningService;
    this.NotificationService = NotificationService;
    this.NotificationService.message('Sie haben das Dokument erfolgreich digital signiert.');

  }

}

DigitalSigningSuccessController.$inject = $inject;
