import './car.model';
import './car-leasing-basket.model';
import './car-leasing-checkout-configuration.model';
import './car-leasing-dashboard.model';
import './car-leasing-ticket-creation.model';
import './car-leasing-side.model';
import './car-leasing.interfaces';
import './driving-license-file-type.model';
import './first-check-state.model';
import './car-leasing-customer-configuration.model';
