const DEBUGMODE = 'VSPDEBUG';
const $injectconfig = [
  '$compileProvider',
  '$qProvider',
  '$locationProvider',
  '$urlRouterProvider',
  '$sceDelegateProvider',
  'madeConfigProvider'
];
export default function config(
  $compileProvider,
  $qProvider,
  $locationProvider,
  $urlRouterProvider,
  $sceDelegateProvider,
  madeConfigProvider
) {

  $locationProvider.html5Mode( {
    enabled:      true,
    rewriteLinks: true
  } );

  let brandingLanding = ['perashr', 'bonago-portal'];
  let subdomain = location.hostname.split('.')[0];

  if ( /.+?\..+?\.de$/.test( location.hostname ) && brandingLanding.indexOf(subdomain) === -1) {
    $urlRouterProvider.otherwise( '/cidaas_login' );
  } else if ( subdomain == 'gehaltsextras' ) {
    // for creating app.js to production
    $urlRouterProvider.otherwise( '/' );

    // for development
    // $urlRouterProvider.otherwise( '/datev' );
  } else {
    $urlRouterProvider.otherwise( '/landing' );
  }

  $sceDelegateProvider.resourceUrlWhitelist( [
    'self',
    'https://player.vimeo.com/**',
    'https://www.youtube.com/**'
  ] );

  $qProvider.errorOnUnhandledRejections( false );


  $compileProvider.debugInfoEnabled( DEBUGMODE === 'VSPDEBUG' );
  madeConfigProvider.debug = DEBUGMODE === 'VSPDEBUG';

}
config.$inject = $injectconfig;
