import yearly_html from '../../modules/yearly/yearly.pug';
import yearly_user_validation_html from '../../modules/yearly/user_validation/yearly_user_validation.pug';
import header_html from '../../modules/yearly/header.pug';
import info_validation_html from '../../modules/yearly/info_validation/info_validation.pug';
import yearly_error_html from '../../modules/yearly/error/yearly_error.pug';

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('blank.yearly', {
        url: '/jahresabfrage',
        template: yearly_html,
        controller: 'YearlyController',
        controllerAs: '$ctrl'
      });

    $stateProvider
      .state('blank.yearly.user_validation', {
        url: '/validation?code',
        views: {
          main: {
            template: yearly_user_validation_html,
            controller: 'YearlyUserValidationController',
            controllerAs: '$ctrl',
          },
          header: {
            template: header_html
          }
        }
      });

    $stateProvider
      .state('blank.yearly.landing', {
        resolve: {
          authorized: ['$q', '$timeout', 'yearlyService', ($q, $timeout, yearlyService) => {
            let deferred = $q.defer();
            $timeout(() => {
              yearlyService.isYearlyDocumentsGatheringAvailable().then((res) => {
                if (res === true) {
                  deferred.resolve();
                } else {
                  deferred.reject({ redirectTo: res.state, params: { yearly_error_id: res.id } });
                }
              });
            });
            return deferred.promise;
          }]
        },
        url: '/landing',
        views: {
          main: {
            template: info_validation_html,
            controller: 'InfoValidationController',
            controllerAs: '$ctrl',
          },
          header: {
            template: header_html
          }
        }
      });

    $stateProvider
      .state('blank.yearly.error', {
        url: '/error',
        params: {
          yearly_error_id: {
            value: 0,
            squash: true
          }
        },
        views: {
          main: {
            template: yearly_error_html,
            controller: 'YearlyErrorController',
            controllerAs: '$ctrl',
          },
          header: {
            template: header_html
          }
        }
      });
  }]);
