import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'vn-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent
    }
  ]
})
export class CheckboxComponent {

  @Input()
  public title!: string;
  
  @Input()
  public switchKey!: string;

  @Output()
  value$ = new EventEmitter<boolean>();

  @ViewChild(MatSlideToggle)
  slider!: MatSlideToggle;

  private formControlValue!: boolean;
  public get value() {
    return this.formControlValue;
  }

  private touched = false;

  public onTouched = () => { };
  public onChange = (_: boolean) => { };

  constructor() {}

  public toggle(value: boolean) {
    this.value$.emit(value);
  }

  public writeValue(value: boolean) {
    this.formControlValue = value;
  }

  public registerOnChange(onChange: (_: boolean) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
