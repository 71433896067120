const $inject = [
  'SchemaService',
  'ArbeitnehmerSpec'
]

export default class ArbeitnehmerVCPSpec {
  constructor(SchemaService, ArbeitnehmerSpec) {
    this.SchemaService = SchemaService;
    this.ArbeitnehmerSpec = ArbeitnehmerSpec;

    this.parts = {
      'birthdate,entrydate': {
        type: 'section',
        htmlClass: 'display_1_1',
        items: [{
          key: 'employee.birthdate',
          title: 'Geburtsdatum',
          validationMessage: 'Geben Sie ein Geburtsdatum in der Form tt.mm.jjjj an'
        }, {
          key: 'employee.entrydate',
          title: 'Eintrittsdatum',
          validationMessage: 'Geben Sie ein Eintrittsdatum in der Form tt.mm.jjjj an'
        }]
      }
    };

    this.form = [
      this.ArbeitnehmerSpec.parts['sex,employeenumber,email'],
      this.ArbeitnehmerSpec.parts['lastname,firstname'], {
        type: 'adressAB',
        items: [{
          type: 'section',
          htmlClass: 'display_4_1',
          items: [{
            key: 'employee.street',
            title: 'Straße'
          }]
        }, {
          type: 'section',
          htmlClass: 'display_1_1',
          items: [{
            key: 'employee.zip',
            title: 'Postleitzahl'
          }, {
            key: 'employee.city',
            title: 'Stadt'
          }]
        }, {
          type: 'locationselect',
          title: 'Standort',
          key: 'employee.loc_id',
          titleMap: []
        }]
      }
    ];
  }


  schema() {
    return this.SchemaService
      .get('rpc://valuenetdb/employee/create')
      .then(schema => ({
        type: 'object',
        properties: {
          'employee': schema.user
        }
      }));
  };
}

ArbeitnehmerVCPSpec.$inject = $inject
