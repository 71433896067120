var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id) {
      pug_mixins["summary-box"] = pug_interp = function(selectedCompany, save, disabled, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"summary-box isTransparent\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns\"\u003E";
if (selectedCompany) {
pug_html = pug_html + "\u003Cspan class=\"summary-box__title\"\u003E\u003Cstrong\u003EFirma:\u003C\u002Fstrong\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' '+ selectedCompany) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"hide-for-small-only\"\u003E&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns text-right pull-right\"\u003E";
block && block();
if (save) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"button button-primary isOutline\""+pug.attr("ng-click", save, true, true)+pug.attr("ng-disabled", disabled, true, true)+pug.attr("data-cy", cyData, true, true)) + "\u003ESpeichern\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};


























































































































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};




















































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};






































































pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"cockpit_bonus-wrapper\"\u003E";
pug_mixins["summary-box"].call({
block: function(){
pug_html = pug_html + "\u003Cbutton class=\"button button-primary isOutline\" ng-if=\"BonusCtrl.iAmSpecialAdvisor || BonusCtrl.iAmAdvisor\" ng-click=\"BonusCtrl.configureBonusProject()\" data-cy=\"vn.cockpit.bonus.customerToolbar.buttonCreateProject\"\u003ENeues Projekt anlegen\u003C\u002Fbutton\u003E";
}
}, '{{ BonusCtrl.customer.name }}');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"list-box\" overlay=\"BonusCtrl.loading\"\u003E\u003Cdiv class=\"list-box__row\" ng-class=\"{'toggable-content-open': toggleContentShowable}\" ng-repeat=\"project in BonusCtrl.bonus_projects track by $index\" toggable-content=\"toggableStatus\"\u003E\u003Ctoggable-content-toggler\u003E\u003Cdiv class=\"list-box__item\"\u003E\u003Cspan class=\"list-box__itemTitle\"\u003E\u003Cspan class=\"user-name\" data-cy=\"vn.cockpit.bonus.{{project._id}}.textProjectName\"\u003E{{ project.name}}\u003C\u002Fspan\u003E\u003Cspan class=\"status-container\"\u003E\u003Cspan class=\"access-state\" ng-class=\"BonusCtrl.BONUS_PROJECT_STATES_BY_ID[project.project_state].color\"\u003E&#x25cf;\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003Cbutton class=\"button button-primary right\" ng-click=\"BonusCtrl.exportBonusesForBonusProject(project._id, project.project_type)\" ng-if=\"BonusCtrl.canControlBonusProject(project) \" data-cy=\"vn.cockpit.bonus.{{project._id}}.buttonExportProject\"\u003EExportieren\u003C\u002Fbutton\u003E\u003Cbutton class=\"button button-primary right\" ng-click=\"BonusCtrl.configureBonusProject(project)\" ng-if=\"BonusCtrl.canConfigureBonusProject(project)\" data-cy=\"vn.cockpit.bonus.{{project._id}}.buttonConfigureProject\"\u003EKonfigurieren\u003C\u002Fbutton\u003E";
pug_mixins["checkbox"].call({
attributes: {"id": "isVisible-{{project._id}}","title": "Sichtbar nach Projektende","key": "project.settings.is_visible_to_user_after_end","groupAttributes": {"style":"display:inline","class":"right","ng-if":"BonusCtrl.canHideBonusProject(project)"},"attributes": {"ng-change":"BonusCtrl.toggleProjectVisibilityAfterEnd(project)"}}
});
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftoggable-content-toggler\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined));
    ;;return pug_html;};
module.exports = template;