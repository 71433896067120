import CockpitController from './CockpitController';

import CockpitKundenauswahlController from './kundenauswahl/CockpitKundenauswahlController';
import CockpitKundenauswahlTestmodeDialogController from './kundenauswahl/testmode/CockpitKundenauswahlTestmodeDialogController';


import CockpitAllgemeinController from './allgemein/CockpitAllgemeinController';

import CockpitEmployeeAdministrationController from './arbeitnehmerverwaltung/CockpitEmployeeAdministrationController';
import FilteredUsersDialogController from './arbeitnehmerverwaltung/FilteredUsersDialogController';
import ChangeEmployeeStatusDialogController from './arbeitnehmerverwaltung/ChangeEmployeeStatusDialogControler';

import CockpitCashbackController from './cashback/CockpitCashbackController';


import './projekte/cockpit_projekte.controller';
import './projekte/cockpit_projekte_employee_form.controller';
import './projekte/cockpit_projekte_form.controller';

import CockpitShopController from './shop/CockpitShopController';


import CockpitSteuerfreiController from './steuerfrei/CockpitSteuerfreiController';
import CockpitBonusController from './bonus/CockpitBonusController';
import './steuerfrei/cockpit.components.dialog.controller';

import CockpitBikeController from './bike/CockpitBikeController';
import CockpitBikeContractsController from './bike/contracts/CockpitBikeContractsController';

import CockpitVersicherungenController from './versicherungen/CockpitVersicherungenController';

import CockpitVorsorgeController from './vorsorge/CockpitVorsorgeController';

import CockpitVorteilsweltController from './vorteilswelt/CockpitVorteilsweltController';
import RegCodeDialogController from './steuerfrei/reg_code.controller';
import './advisors';
import './customers';
import BonusDialogController from './arbeitnehmerverwaltung/bonus/bonus_dialog.controller';
import CreateBonusProjectDialogController from './bonus/create/create_bonus_project_dialog.controller';
import confirmationInfoDialogController from './bonus/create/confirmation_info_dialog.controller';
import CockpitPCController from './pc/CockpitPCController';
import UserGroupsAdministrationDialogController from './arbeitnehmerverwaltung/UserGroupsAdministrationDialogController';
import CockpitBonagoController from './bonago/cockpit_bonago_controller';
import CockpitDocumentTemplatesController from './document_templates/CockpitDocumentTemplatesController';
import CockpitApproversController from './approvers/CockpitApproversController';
import AssignApprovalPermissionsDialogController from './approvers/AssignApprovalPermissionsDialogController';
import CockpitDocumentsController from './documents/CockpitDocumentsController';
import DigitalSignitureController from './digital_signiture/DigitalSignitureController';
import CockpitStatisticsController from './statistics/CockpitStatisticsController';
import CockpitUserGroupsController from './user_groups/CockpitUserGroupsController';
import TransferInfoDialogController from "./bonus/create/transfer_info_dialog.controller";
import CockpitCarLeasingController
  from "./car-leasing/cockpit-car-leasing.controller";



angular
  .module( 'VSPApp' )
  .controller( 'CockpitController', CockpitController )
  .controller( 'CockpitKundenauswahlController', CockpitKundenauswahlController )
  .controller( 'CockpitAllgemeinController', CockpitAllgemeinController )
  .controller( 'CockpitBonusController', CockpitBonusController )
  .controller( 'CreateBonusProjectController', CreateBonusProjectDialogController)
  .controller( 'CockpitSteuerfreiController', CockpitSteuerfreiController )
  .controller( 'CockpitVersicherungenController', CockpitVersicherungenController )
  .controller( 'CockpitVorsorgeController', CockpitVorsorgeController )
  .controller( 'CockpitCashbackController', CockpitCashbackController )
  .controller( 'CockpitShopController', CockpitShopController )
  .controller( 'CockpitVorteilsweltController', CockpitVorteilsweltController )
  .controller( 'CockpitEmployeeAdministrationController', CockpitEmployeeAdministrationController )
  .controller( 'FilteredUsersDialogController', FilteredUsersDialogController )
  .controller( 'ChangeEmployeeStatusDialogController', ChangeEmployeeStatusDialogController)
  .controller( 'RegCodeDialogController', RegCodeDialogController)
  .controller( 'CockpitBikeController', CockpitBikeController)
  .controller( 'CockpitBikeContractsController', CockpitBikeContractsController)
  .controller( 'BonusDialogController', BonusDialogController)
  .controller( 'confirmationInfoDialogController', confirmationInfoDialogController )
  .controller( 'CockpitPCController', CockpitPCController)
  .controller( 'UserGroupsAdministrationDialogController', UserGroupsAdministrationDialogController)
  .controller( 'CockpitBonagoController', CockpitBonagoController)
  .controller( 'CockpitDocumentTemplatesController', CockpitDocumentTemplatesController)
  .controller( 'CockpitApproversController', CockpitApproversController)
  .controller( 'AssignApprovalPermissionsDialogController', AssignApprovalPermissionsDialogController)
  .controller( 'CockpitDocumentsController', CockpitDocumentsController)
  .controller( 'CockpitStatisticsController', CockpitStatisticsController)
  .controller( 'CockpitUserGroupsController', CockpitUserGroupsController)
  .controller('TransferInfoDialogController', TransferInfoDialogController)
  .controller('DigitalSignitureController', DigitalSignitureController)
  .controller('CockpitCarLeasingController', CockpitCarLeasingController)
  .controller('CockpitKundenauswahlTestmodeDialogController', CockpitKundenauswahlTestmodeDialogController)
  ;
