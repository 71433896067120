var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id) {
      







































































































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};






































































pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel__title\"\u003EFreigabe\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\" overlay=\"$ctrl.loading\"\u003E\u003Cdiv class=\"row\"\u003E\u003Ch4\u003EÜbersicht:\u003C\u002Fh4\u003E\u003Cdiv\u003EBruttowert Fahrradleasing: {{$ctrl.bikeleasing_component.checkout_config.full_amount | currency}}\u003C\u002Fdiv\u003E\u003Cdiv\u003EMonatliche Rate: {{$ctrl.bikeleasing_component.checkout_config.per_month_value | currency}}\u003C\u002Fdiv\u003E\u003Cdiv\u003EBeginn: {{$ctrl.bikeleasing_component.dates.bonus_start_date*1000 | date}}\u003C\u002Fdiv\u003E\u003Cdiv\u003EFreigabeCode: {{$ctrl.bikeleasing_component.checkout_config.validation_code}}\u003C\u002Fdiv\u003E\u003Cdiv\u003EFreigabeURL:\u003Ca href=\"{{$ctrl.bikeleasing_component.checkout_config.validation_url}}\" target=\"_blank\"\u003ELink zum Bikeleasing Portal\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary\" type=\"button\" ng-click=\"$ctrl.approveBikeleasingComponent()\" data-cy=\"vn.administration.bikeLeasing.finalApprovalDialog.buttonApprove\"\u003EWahlleistung final freigeben\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary right\" type=\"button\" ng-click=\"$ctrl.close()\" data-cy=\"vn.administration.bikeLeasing.finalApprovalDialog.buttonDecline\"\u003EAbbrechen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined));
    ;;return pug_html;};
module.exports = template;