import {
  APPROVAL_PROCESS_TYPES_BY_NAME,
  APPROVAL_PROCESS_STATES_BY_NAME, APPROVAL_PROCESS_ACTIVE_STATE_IDS,
} from '../../../../services/administrationService';
import ComponentConstants from "../../../../constants/ComponentConstants";

const $inject = [
  '$q',
  '$state',
  '$scope',
  '$location',
  'Made',
  'CheckoutService',
  'userService',
  'vbmService',
  'vbmData',
  'customerService',
  'DocumentConstants',
  'employeeService',
  'dialogService',
  'authenticationService',
  'mppService',
  'PopupService',
  'PopupVideoKeys',
  'administrationService',
  '$timeout',
  'lodash',
  'VSP_CONSTANTS',
  'ComponentConstants'
];

export default class CheckoutZusammenfassungController {

  constructor(
    $q,
    $state,
    $scope,
    $location,
    Made,
    CheckoutService,
    userService,
    vbmService,
    vbmData,
    customerService,
    DocumentConstants,
    employeeService,
    dialogService,
    authenticationService,
    mppService,
    PopupService,
    PopupVideoKeys,
    administrationService,
    $timeout,
    lodash,
    VSP_CONSTANTS,
    ComponentConstants
  ) {

    this.$q = $q;
    this.$state = $state;
    this.Made = Made;
    this.DocumentConstants = DocumentConstants;
    this.CheckoutService = CheckoutService;
    this.vbmService = vbmService;
    this.vbmData = vbmData;
    this.dialogService = dialogService;
    this.userService = userService;
    this.mppService = mppService;
    this.employeeService = employeeService;
    this.customerService = customerService;
    this.administrationService = administrationService;
    this.$timeout = $timeout;
    this.lodash = lodash;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
    this.ComponentConstants = ComponentConstants

    this.APPROVAL_PROCESS_STATES_BY_NAME = APPROVAL_PROCESS_STATES_BY_NAME;
    this.APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES_BY_NAME;
    this.APPROVAL_PROCESS_ACTIVE_STATE_IDS = APPROVAL_PROCESS_ACTIVE_STATE_IDS;

    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');

    $scope.clicker = {};

    PopupService.processModuleVideo(
      PopupVideoKeys.OPTIMISATION_PROCESS,
      $scope.clicker
    );

    let promises = {};

    $scope.globalCheckoutCtrl.step = 1;

    employeeService.get(vbmData.employeeId);

    vbmData.prepare();

    mppService
      .getUnvalidatedMppOrderStatuses()
      .then(statuses => {
        this.$timeout(() => {
          this.UNVALIDATED_MPP_ORDER_STATUSES = statuses
        }, 0);
      });


    userService
      .getIntegrityStatus(true, vbmData.employeeId)
      .then(status => {
        if (status !== false) {
          dialogService.employeeIntegrity(status, vbmData.employeeId, true).then(() => $location.reload());
        }
      });

    promises.person = employeeService
      .get(vbmData.employeeId)
      .then(employee => {
        vbmData.employees[vbmData.employeeId].data.person = employee;
        vbmData.employees[vbmData.employeeId].data.parameters.geburtsdatum = employee.birthdate;
        return employee;
      });

    promises.project = promises.person
      .then(employee => customerService.getNeoProjectByEmployeeId(employee.id))
      .then(neoProject => this.neoProject = neoProject);

    promises.checkoutSession = promises.project
      .then(() => CheckoutService.checkSession(vbmData.employeeId))
      .then(session => {
        if (session) {
          this.preCalculationId = session.preCalculationId;
          return session.preCalculationId;
        }
        return false;
      });

    promises.a = promises.checkoutSession
      .then(preCalculationId => vbmData.getResult(vbmData.employeeId, new Date(), false, preCalculationId))
      .then(result => {
        this.yearsToPension = vbmService.yearsToPension(vbmData.employees[vbmData.employeeId]);

        this.preCalculationId = vbmData.employees[vbmData.employeeId].data.metadata.id;

        result.advantagePerYear = (result.final.tax.net_netto - result.before.tax.net_netto + result.final.tax.net_jahr) * 12;
        result.pension = (result.final.gap.rente / this.yearsToPension);
        result.alos = result.final.gap.alos;
        result.ktg = result.final.gap.ktg / 30;

        this.avbB = (result.pension == 0);
        this.avbC = (result.alos == 0 && result.ktg == 0);
        this.avbD = this.avbB && this.avbC;

        this.results.a = result;
        return result;
      });

    promises[2] = customerService
      .isTestmode()
      .then(testmode => this.testmode = testmode);


    promises[3] = CheckoutService
      .employeeBlocked(vbmData.employeeId)
      .then(blocked => this.employeeBlocked = blocked);

    promises[4] = customerService
      .isNoSelfCheckout(vbmData.employeeId)
      .then(noSelfCheckout => this.noSelfserviceCheckout = noSelfCheckout);

    this.documentLoading = true;
    this.employee = vbmData.employees[vbmData.employeeId];
    this.isOneToOne = false;

    promises.isOneToOne = promises.a.then(() => {
      this.isOneToOne = this.employee.data.parameters.einsparungs_modell === 'NONE';

      if (this.isOneToOne) {
        promises.b = promises.a
          .then(() => vbmService.approximateVA(
            vbmData.employees[vbmData.employeeId],
            false,
            this.neoProject.project.versorgungsausgleichAGfinanziert,
          ))
          .then(resultVA => {
            let employee = this.parseVA(vbmData, resultVA);

            this.employees.b = employee;

            return vbmService.getResult(employee.data);
          })
          .then(result => {
            result.advantagePerYear = (result.final.tax.net_netto - result.before.tax.net_netto + result.final.tax.net_jahr) * 12;
            result.pension = result && 0;
            result.alos = result.final.gap.alos;
            result.ktg = result.final.gap.ktg / 30;
            this.results.b = result;
          });

        promises.c = promises.a
          .then(() => {

            let employee = angular.copy(vbmData.employees[vbmData.employeeId]);

            if (this.neoProject.project.compensationALV && this.neoProject.project.compensationKTG) {
              employee.data.metadata.behandlung_alvktg = 'OHNE_BERUECKSICHTIGUNG';
            } else {
              employee.data.metadata.behandlung_alvktg = 'NETTOABZUG';
            }

            this.employees.c = employee;

            return vbmService.getResult(employee.data);
          })
          .then(result => {
            result.advantagePerYear = (result.final.tax.net_netto - result.before.tax.net_netto + result.final.tax.net_jahr) * 12;
            result.pension = result.final.gap.rente / this.yearsToPension;
            result.alos = result && 0;
            result.ktg = result && 0;
            this.results.c = result;
          });

        promises.d = promises.a
          .then(() => vbmService.approximateVA(
            vbmData.employees[vbmData.employeeId],
            true,
            this.neoProject.project.versorgungsausgleichAGfinanziert,
          ))
          .then(resultVA => {

            let employee = this.parseVA(vbmData, resultVA);

            if (this.neoProject.project.compensationALV && this.neoProject.project.compensationKTG) {
              employee.data.metadata.behandlung_alvktg = 'OHNE_BERUECKSICHTIGUNG';
            } else {
              employee.data.metadata.behandlung_alvktg = 'NETTOABZUG';
            }

            this.employees.d = employee;

            return vbmService.getResult(employee.data);
          })
          .then(result => {
            result.advantagePerYear = (result.final.tax.net_netto - result.before.tax.net_netto + result.final.tax.net_jahr) * 12;
            result.pension = result && 0;
            result.alos = result && 0;
            result.ktg = result && 0;
            this.results.d = result;
          });

      }
    });

    promises.adjustment = CheckoutService
      .getCheckoutAdjustment(vbmData.employeeId)
      .then(adjustment => {

        this.adjustment = adjustment;

        if (!this.adjustment.coverageChoice) {
          this.adjustment.coverageChoice = 'A';
        }

      });

    promises.user = userService
      .getMadeUserByValuenetId(vbmData.employeeId, Date.now())
      .then(user => this.madeUser = user);

    promises.newMpp = promises.a.then(() => {
      if (vbmData.employees[vbmData.employeeId].data.neo_components.pc.enabled && vbmData.employees[vbmData.employeeId].data.neo_components.pc.useInternalMPP) {
        return this.hasCurrentMpp();
      }
    });

    promises.is_calculation_finalization_allowed_promise = promises.a.then(() => {
      return vbmData.isCalculationFinalizationAllowed({
        employee_id: vbmData.employeeId,
        calculation_id: vbmData.employees[vbmData.employeeId].data.metadata.id
      }).then(result => {
        this.$timeout(() => {
          let backend_success = result['overall'];
          let backend_has_handy_with_mpp_active = this.lodash.get(result['calculation'], 'neo_components.handy.mpp_combined');
          let backend_calculation_pc_enabled = this.lodash.get(result['calculation'], 'neo_components.pc.enabled');
          let backend_calculation_pc_value = this.lodash.get(result['calculation'], 'neo_components.pc.value');
          let has_telephone_in_old_basket = this.lodash.get(result, 'validations.is_handy_and_mpp_valid.validations.has_mpp_telephone.validations.has_telephone_in_old_basket', false);
          let has_telephone_in_basket = this.lodash.get(result, 'validations.is_handy_and_mpp_valid.validations.has_mpp_telephone.overall', false);
          let frontend_has_handy_with_mpp_active = this.lodash.get(vbmData.employees[vbmData.employeeId], 'data.neo_components.handy.mpp_combined');
          let frontend_calculation_handy_enabled = this.lodash.get(vbmData.employees[vbmData.employeeId], 'data.neo_components.handy.enabled');
          let frontend_calculation_pc_enabled = this.lodash.get(vbmData.employees[vbmData.employeeId], 'data.neo_components.pc.enabled');
          let frontend_calculation_pc_value = this.lodash.get(vbmData.employees[vbmData.employeeId], 'data.neo_components.pc.value');

          let is_backend_pc_value_equal_to_frontend_pc_value = backend_calculation_pc_value === frontend_calculation_pc_value;
          let is_backend_pc_state_equal_to_frontend_pc_state = backend_calculation_pc_enabled === frontend_calculation_pc_enabled;

          let has_advisor_added_pc = (!backend_calculation_pc_enabled && frontend_calculation_pc_enabled) || // advisor has enabled the PC
            (backend_calculation_pc_enabled && frontend_calculation_pc_enabled && frontend_calculation_pc_value > backend_calculation_pc_value); // there was a PC and advisor increased the value

          let has_advisor_not_touched_the_pc = (is_backend_pc_state_equal_to_frontend_pc_state && backend_calculation_pc_enabled === false) // the PC was turned off and it is still off
            || (is_backend_pc_state_equal_to_frontend_pc_state && backend_calculation_pc_enabled === true && is_backend_pc_value_equal_to_frontend_pc_value); // the PC was ON and the values match

          let has_advisor_removed_pc = !(has_advisor_added_pc || has_advisor_not_touched_the_pc); // when there was PC in the checkout and then the advisor removed it

          if (frontend_calculation_handy_enabled && frontend_has_handy_with_mpp_active) {
            this.is_calculation_finalization_allowed = has_telephone_in_old_basket;
          } else {
            this.is_calculation_finalization_allowed = true;
          }

          this.has_overlapping_components_with_bonus_result = result.validations.has_overlapping_components_with_bonus;
          this.has_overlapping_components_with_bonus_result.validations.components = this.has_overlapping_components_with_bonus_result.validations.components.map((c) => this.ComponentConstants[c]['title']).join(',');

          this.can_start_checkout = false;
          let is_project_approved = this.lodash.get(this.neoProject, 'project.project_approved', false);
          let is_test_user = this.lodash.get(this.employee, 'data.person.testclient', false);
          if (is_project_approved || is_test_user) {
            this.can_start_checkout = true;
          }


          // this.is_calculation_finalization_allowed = (has_advisor_added_pc && has_telephone_in_basket) || // best case
          //   (has_advisor_not_touched_the_pc && has_telephone_in_basket) || // we already have a PC with a telephone
          //   (has_advisor_removed_pc && has_telephone_in_old_basket ) ; // the advisor removed the PC but the phone is in an old basket
        });
      });
    });

    promises.hasOpenApprovals = administrationService.getApprovalProcesess({
      employee_ids: [vbmData.employeeId],
      type_ids: [this.APPROVAL_PROCESS_TYPES_BY_NAME['neo_pc']['id']],
      state_id: this.APPROVAL_PROCESS_ACTIVE_STATE_IDS
    })
      .then(approvals => {
        this.hasOpenApproval = approvals.length > 0;
      });


    promises.isOneToOne
      .then(() => $q.all(promises))
      .then(() => {
        let currentChoice = this.adjustment.coverageChoice;
        if (currentChoice != 'A') {
          if (this.avbB && currentChoice == 'B') {
            this.adjustment.coverageChoice = 'A';
          }
          if (this.avbC && currentChoice == 'C') {
            this.adjustment.coverageChoice = 'A';
          }
          if (this.avbD && currentChoice == 'D') {
            this.adjustment.coverageChoice = 'A';
          }
        }
      })
      .finally(() => {
        this.$timeout(() => {
          this.documentLoading = false;
        });
      });

    this.employees = {};
    this.results = {};
    this.testmode = true;
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.override_blocked_checkout = this.iAmAdvisor || this.iAmGAdvisor || this.iAmSpecialAdvisor;
    this.yearsToPension = 50;

  }

  canCheckout() {
    let is_advisor_checkout_allowed = this.iAmAdvisor && !this.employeeBlocked;
    let is_employee_checkout_allowed = !this.iAmAdvisor && !this.testmode && !this.noSelfserviceCheckout && !this.employeeBlocked;
    let is_general_checkout_allowed = this.madeUser && !this.mppError && !this.custMPP && this.is_calculation_finalization_allowed && !this.mppTypeError;

    return (this.iAmSpecialAdvisor || is_advisor_checkout_allowed || is_employee_checkout_allowed) && is_general_checkout_allowed && this.can_start_checkout && !this.has_overlapping_components_with_bonus_result.overall;
  }

  parseVA(vbmData, resultVA) {

    let vaBeitrag = parseFloat(resultVA['va']) / 100;
    let vaZuschussAG = parseFloat(resultVA['zuschuss_ag']) / 100;
    let employee = angular.copy(vbmData.employees[vbmData.employeeId]);

    employee.data.insurances.va.beitrag = vaBeitrag;
    employee.data.insurances.va.typ = 'DIREKT';
    employee.data.insurances.va.zuschuss_ag = vaZuschussAG;

    if (this.neoProject.project.versorgungsausgleichAGfinanziert) {
      employee.data.insurances.va.zuschuss_ag = employee.data.insurances.va.beitrag;
    }
    return employee;
  }

  hasComponents() {
    return this.vbmData.hasComponents();
  }

  proceed() {

    this.documentLoading = true;

    this
      .applyChoice(this.adjustment.coverageChoice)
      .then(() => this.nextPage())
      .finally(() => this.documentLoading = false);

  }

  nextPage() {
    let page = 'dokumente';

    if (this.Made.user.valuenet_id != this.vbmData.employeeId) {
      this.$state.go('inApp.neo.side.checkout.' + page, {
        employeeId: this.vbmData.employeeId,
      });
    } else {
      this.$state.go('inApp.neo.side.checkout.' + page);
    }

  }

  getDocument(reportId) {

    this.documentLoading = true;

    this.vbmData
      .getDocument(reportId)
      .then(url => window.open(url, '_blank'))
      .finally(() => this.documentLoading = false);
  }

  applyChoice(choice) {

    if (this.isOneToOne) {
      switch (choice) {

        case 'A':
          this.employee.data.metadata.behandlung_alvktg = 'OHNE_BERUECKSICHTIGUNG';
          this.employee.data.insurances.va.typ = 'OHNEBERUECKSICHTIGUNG';
          break;

        case 'B':
          this.employee.data.metadata.behandlung_alvktg = 'OHNE_BERUECKSICHTIGUNG';
          this.employee.data.insurances.va.beitrag = this.employees.b.data.insurances.va.beitrag;
          this.employee.data.insurances.va.zuschuss_ag = this.employees.b.data.insurances.va.zuschuss_ag;
          this.employee.data.insurances.va.typ = 'DIREKT';
          break;

        case 'C':
          this.employee.data.insurances.va.typ = 'OHNEBERUECKSICHTIGUNG';
          if (this.neoProject.project.compensationALV && this.neoProject.project.compensationKTG) {
            this.employee.data.metadata.behandlung_alvktg = 'OHNE_BERUECKSICHTIGUNG';
          } else {
            this.employee.data.metadata.behandlung_alvktg = 'NETTOABZUG';
          }
          break;

        case 'D':
          this.employee.data.insurances.va.beitrag = this.employees.b.data.insurances.va.beitrag;
          this.employee.data.insurances.va.zuschuss_ag = this.employees.b.data.insurances.va.zuschuss_ag;
          this.employee.data.insurances.va.typ = 'DIREKT';
          if (this.neoProject.project.compensationALV && this.neoProject.project.compensationKTG) {
            this.employee.data.metadata.behandlung_alvktg = 'OHNE_BERUECKSICHTIGUNG';
          } else {
            this.employee.data.metadata.behandlung_alvktg = 'NETTOABZUG';
          }
          break;
      }
    }

    return this.$q.all([
      this.CheckoutService.enterSession(this.vbmData.employeeId, this.preCalculationId),
      this.vbmData.save(undefined, undefined, true),
      this.CheckoutService.setCheckoutAdjustment(
        this.vbmData.employeeId,
        this.preCalculationId,
        this.adjustment,
      ),
    ]);
  }

  arbeitnehmerFreischalten() {
    this.dialogService
      .employeeInvite(this.vbmData.employeeId)
      .then(() => location.reload());
  }

  async hasCurrentMpp() {
    let cart = await this.mppService.getCart(this.vbmData.employeeId);
    this.mppBasket = cart;

    // checkCustMPP
    let canCheckout = true;
    let compMpp = this.vbmData.employees[this.vbmData.employeeId].data.neo_components['pc'];

    let customerConfiguration = await this.customerService.getConfigurationByEmployeeId(this.vbmData.employeeId);
    let neoProject = await this.customerService.getNeoProjectByEmployeeId(this.vbmData.employeeId);
    let customerMPP = customerConfiguration.neo_config.pc;
    this.infomode = neoProject.infomode || false;

    // Step 2 MPP - wenn limits gepflegt werden
    if (compMpp.enabled && customerMPP.useInternalMPP) {
      // TODO: there is a method now to check this mppService.checkCustomerLimit
      canCheckout = customerMPP.leasinglimit > (customerMPP.usedlimit + cart.sum);
    }

    this.custMPP = !canCheckout;

    this.visibleMpp = this.CheckoutService.willCheckoutBasket(cart);
    if (!this.visibleMpp) {
      return;
    }

    // check PC type
    if (customerMPP.mpp_type === this.VSP_CONSTANTS.MPP_TYPE.COMPONENT_BASED['id'] && this.visibleMpp) {
      this.mppTypeError = true;
    }

    // some baskets' items have already been validated by our PC procivder, Cyberport. They do not need to be validated again
    if (!cart.order_process || (cart.order_process && this.UNVALIDATED_MPP_ORDER_STATUSES.includes(cart.order_process.state_id))) {
      // check article availability
      let are_articles_available = await this.mppService.checkCart(this.vbmData.employeeId);
      if (!are_articles_available) {
        this.mppError = true;
      }


      // 7 Tage = 604800
      // 30 Tage = 2592000
      let startDate = this.mppBasket.created,
        current = parseInt(new Date().getTime() / 1000);

      if (this.mppBasket.updated) {
        startDate = this.mppBasket.updated;
      }

      if ((current - startDate) > 259200 && !this.mppBasket.locked) {
        // validate article prices
        let are_article_prices_valid_result = await this.mppService.checkBasket(this.vbmData.employeeId)
        if (are_article_prices_valid_result.updated) {
          this.mppBasket = are_article_prices_valid_result.basket;
          this.mppError = true;

          if (are_article_prices_valid_result.valid) {
            for (let i = 0; i < are_article_prices_valid_result.basket.articles.length; i++) {
              let newArticle = are_article_prices_valid_result.basket.articles[i];
              this.mppBasket.articles[i].newprice = newArticle.price;
            }
          }
        }
      }
    } else {
      this.mppError = false;
    }
  }

  sendMPPInfomodeOrder() {
    this.orderBusy = true;

    this.mppService.sendInfomodeOrder(this.vbmData.employeeId)
      .then(() => {
        this.orderButtonState = true;
        this.orderBusy = false;
      })
      .finally(() => {
        this.dialogService.question('Vielen Dank. Wir haben Ihre Vorab-Bestellung erhalten. Sie erhalten eine Zusammenfassung als Mail.\n' +
          'Wir kommen in Kürze auf Sie zu.', '', 'OK');
      });
  }
}
CheckoutZusammenfassungController.$inject = $inject;
