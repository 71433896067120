import {Inject, Injectable} from '@angular/core';
import {NeoCalculationBookmark, NeoCalculationBookmarkMapper} from '../models/neo-calculation-bookmark.model';


@Injectable()
export class NeoCalculationBookmarkService {

  constructor(
      @Inject('Made') private made: any,
      private neoCalculationBookmarkMapper: NeoCalculationBookmarkMapper,
    ) { }

  public async request(path: string, params: {}) {
    const urlPrefix = 'rpc://valuenetdb/neo_calculation_bookmark/';
    return this.made.request(urlPrefix + path, params);
  }

  public async getNeoCalculationBookmarkByCalculationId(
    calculationId: number,
  ): Promise<NeoCalculationBookmark | null> {
    const path = 'get_neo_calculation_bookmark_by_calculation_id';
    const params = {
      calculation_id: calculationId,
    };
    const calculationBookmark: {} = await this.request(path, params);
    if (calculationBookmark) {
      return this.neoCalculationBookmarkMapper.fromApi(calculationBookmark);
    }
    return null;
  }

  public async getNeoCalculationBookmarksByEmployeeId(
    employeeId: number,
  ): Promise<Array<NeoCalculationBookmark>> {
    const path = 'get_neo_calculation_bookmarks_by_employee_id';
    const params = {
      employee_id: employeeId,
    };
    const calculationBookmarks: [] = await this.request(path, params);
    let _calculationBookmarks = [];
    for (const calculationBookmark of calculationBookmarks) {
      _calculationBookmarks.push(this.neoCalculationBookmarkMapper.fromApi(calculationBookmark));
    }
    return _calculationBookmarks;
  }

  public async createNeoCalculationBookmark(
    employeeId: number,
    calculationId: number,
    name: string,
    isHidden: boolean,
    isReporting: boolean,
  ): Promise<NeoCalculationBookmark> {
    const path = 'create_neo_calculation_bookmark';
    const params = {
      employee_id: employeeId,
      calculation_id: calculationId,
      name: name,
      is_hidden: isHidden,
      is_reporting: isReporting,
    };
    const calculationBookmark: {} = await this.request(path, params);
    return this.neoCalculationBookmarkMapper.fromApi(calculationBookmark);
  }

  public async updateNeoCalculationBookmark(
    id: string,
    name: string,
    isHidden: boolean,
    isReporting: boolean,
  ): Promise<NeoCalculationBookmark> {
    const path = 'update_neo_calculation_bookmark';
    const params = {
      id: id,
      name: name,
      is_hidden: isHidden,
      is_reporting: isReporting,
    };
    const calculationBookmark: {} = await this.request(path, params);
    return this.neoCalculationBookmarkMapper.fromApi(calculationBookmark);
  }

  public async deleteNeoCalculationBookmark(
    bookmarkId: string,
  ): Promise<boolean> {
    const path = 'delete_neo_calculation_bookmark';
    const params = {
      id: bookmarkId,
    };
    return await this.request(path, params);
  }

}
