export const CHECKOUT_TYPES = [
  {'id': 0, 'name': 'current', 'key': 'components'},
  {'id': 1, 'name': 'future', 'key': 'future_checkouts'},
  {'id': 2, 'name': 'previous', 'key': 'previous_checkouts'},
  {'id': 3, 'name': 'ended', 'key': 'ended'} // cancelled or expired
];
export const CHECKOUT_TYPES_BY_NAME = CHECKOUT_TYPES.reduce((acc, checkout_type) => {
  acc[checkout_type['name']] = checkout_type;
  return acc;
}, {});
