const BAVParagraphConstants = [
  {
    value: 'KEINE',
    name: 'Keine'
  },
  {
    value: 'P363',
    name: '§ 363'
  },
  {
    value: 'P40B',
    name: '§ 40b'
  }
];

export default BAVParagraphConstants;
