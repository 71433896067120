import FileChooserDialog_html from '../../components/file_chooser/FileChooserDialog.pug'

const $inject = [
  '$scope', '$timeout', 'dialogService'
];

export class FileChooser {
  constructor($scope, $timeout, dialogService) {
    Object.assign(this, {
      $scope,
      $timeout,
      dialogService
    });

    this.$onInit = async () => {
      this.loading = true;

      if (!this.files) {
        throw new Error(`Can't work without files`);
      }

      // if (!this.tags) {
      //   throw new Error(`Can't work without file_tags`);
      // }

      this.$timeout(() => {
        this.loading = false;
      });
    };
  }

  openDialog() {
    this.dialogService.ngDialog.openConfirm({
      template: FileChooserDialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'FileChooserDialogController',
      data: {
        outbound_files: this.files,
        filter_tags: this.filterTags,
        employee_id: this.employeeId
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
//      console.log('FileChooser dialog close ', this.files);
      this.$timeout(() => {
      });
    }).catch(() => {
    });
  }
}

FileChooser.$inject = $inject;

import file_chooser_html from "./FileChooser.pug";

angular
  .module('VSPApp')
  .controller('FileChooser', FileChooser)
  .component('fileChooser', {
    controller: 'FileChooser',
    controllerAs: '$ctrl',
    template: file_chooser_html(),
    bindings: {
      files: '=',
      filterTags: '=',
      employeeId: '<'
    }
  });
