angular
  .module('VSPApp')
  .service('VCPBonusService', ['$q', 'Made', 'MonthsService', function ($q, Made, MonthsService) {

    var bonusService = {};

    bonusService.getOverview = () => Made
      .request('rpc://vcp/bonus_overview', {
        month: '' + MonthsService.year,
        year: '' + (MonthsService.month + 1)
      })
      .then(result => bonusService.overview = result);


    bonusService.acceptByUser = function (empId, accepted, reason, month, year) {

      var params = {
        empid: empId,
        accepted: accepted
      };

      if (reason) params.reason = reason;
      if (month) params.month = '' + month;
      if (year) params.year = '' + year;

      return Made
        .request('rpc://vcp/employee/bonus/set_by_user', params);
    };

    bonusService.acceptById = function (id, accepted, reason, noAccepter) {

      var params = {
        id: id,
        accepted: accepted
      };

      if (reason) params.reason = reason;

      if (noAccepter) params.no_accepter = noAccepter;

      return Made
        .request('rpc://vcp/employee/bonus/set_by_id', params);
    };

    bonusService.acceptByIds = function (ids) {

      var promises = [];

      for (var i = ids.length - 1; i >= 0; i--) {
        promises.push(bonusService.acceptById(ids[i], true));
      }

      return $q.all(promises);
    };

    bonusService.acceptByLocation = function (locationId, year, month) {

      var params = {
        locid: parseInt(locationId)
      };

      if (year) params.year = year;
      if (month) params.month = month + 1;

      return Made
        .request('rpc://vcp/employee/bonus/accept_by_location', params);
    };

    bonusService.requestByUser = function (personId, amount, reason, month, year) {
      var params = {
        receiver: personId,
        amount: amount
      };

      if (reason) params.reason = reason;
      if (month) params.month = month;
      if (year) params.year = year;

      return Made
        .request('rpc://vcp/employee/bonus/payment', params);
    };

    bonusService.getOpenAmount = function (person) {

      var amount = 0;

      if (!person.history)
        return amount;
      for (var i = person.history.length - 1; i >= 0; i--) {
        if (
          (
            (person.history[i].year < MonthsService.year) ||
            ((person.history[i].year == MonthsService.year) && (person.history[i].month - 1 <= MonthsService.month))
          ) &&
          ((null === person.history[i].accepter) || !person.history[i].accepted) &&
          !person.history[i].cleared
        ) {
          amount += person.history[i].amount;
        }
      }

      return amount;
    };

    bonusService.getApprovedAmount = function (person) {

      var amount = 0;

      for (var i = person.history.length - 1; i >= 0; i--) {
        if (person.history[i].accepter) {
          amount += person.history[i].amount;
        }
      }

      return amount;
    };

    bonusService.hasUnapproved = function (employees) {

      if ('object' !== typeof employees || !employees.length)
        return false;
      var i,
        j;

      for (i = employees.length - 1; i >= 0; i--) {
        if (employees[i].history) {
          for (j = employees[i].history.length - 1; j >= 0; j--) {
            if (!employees[i].history[j].accepted) {
              employees[i].hasUnapproved = true;
              break;
            }
          }
        }
      }
    };

    bonusService.delete = (id) => {

      if ('object' === typeof id) id = id.id;

      return Made
        .request('rpc://vcp/employee/bonus/delete', {
          id
        });
    };

    bonusService.clearBoni = (year = MonthsService.year, month = MonthsService.month) => Made
      .request('rpc://vcp/clearBoni', {
        year: '' + year,
        month: '' + (month + 1)
      });


    bonusService.reasonsPromise = Made
      .request('rpc://vcp/employee/bonus/reasons')
      .then(function (response) {
        bonusService.reasons = response;
        return bonusService.reasons;
      });

    return bonusService;
  }]);
