export default ['$timeout', function ( $timeout ) {

  return function ( fn, delay, scope ) {

    let timer = null;

    delay = delay || 250;

    return function () {

      let context = scope || this,
        args      = arguments;

      $timeout.cancel( timer );

      timer = $timeout( function () {
        fn.apply( context, args );
      }, delay );

    };
  };
}]
