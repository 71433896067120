const $inject = [
  '$scope',
  'mppService',
  '$timeout',
  'NotificationService',
  'lodash',
  'NeoComponentsService'
];
export default class MigrateBasketController {
  constructor(
    $scope,
    mppService,
    $timeout,
    NotificationService,
    lodash,
    NeoComponentsService
  ) {
    Object.assign(this, {
      $scope,
      mppService,
      $timeout,
      NotificationService,
      lodash,
      NeoComponentsService
    });
    this.loading = {
      main: false,
      migrating: false
    };
    this.basket = this.$scope.ngDialogData.basket;
  }

  async migrateBasket() {
    this.$timeout(() => {
      this.loading.migrating = true;
    });

    await this.NeoComponentsService.migrateBasket({basket_id: this.basket._id});

    this.$timeout(() => {
      this.loading.migrating = false;
    });

    this.$scope.confirm();
  }

  close() {
    this.$scope.closeThisDialog(false);
  }
}
MigrateBasketController.$inject = $inject;
