<div class="direct-bonus-customer-overview">
    <vn-matblur-loading></vn-matblur-loading>
    <vn-direct-bonus-customer-bonus-table [tableData]='(dataStream$ | async)!' (tableDataChange)="onTableDataChange()">
        <form [formGroup]='filtersGroup' (keydown.enter)="$event.preventDefault()">
            <vn-select formControlName='orderState'
                       label="Bestellstatus"
                       placeholder='Alle'
                       [options]='filterOptions?.orderState'
                       (keydown.enter)="loadDataStream()">
            </vn-select>

            <vn-date formControlName='orderPeriod'
                     label="Periode"
                     format="MM.YYYY"
                     placeholder="MM.JJJJ"
                     (keydown.enter)="loadDataStream()"
                     [initDate]="getToday()"
                     [ignoreDay]=true
                     [ignoreYear]=true>
            </vn-date>

            <div class="spacer"></div>

            <button mat-icon-button
                mat-raised-button
                [disabled]="!canSearch"
                (click)="loadDataStream()">
                <mat-icon>filter_list</mat-icon>
            </button>

            <button mat-icon-button
                mat-raised-button
                [disabled]="!isListFiltered"
                (click)="clearFilters()">
                <mat-icon>filter_list_off</mat-icon>
            </button>
        </form>

    </vn-direct-bonus-customer-bonus-table> <!-- We should clone this "vn-bonus-order-table" component with new action buttons (Edit bonus amount, Delete bonus) Krasi-->
</div>
