<h2 mat-dialog-title>
    Neue Berechnung
</h2>

<mat-dialog-content>
    <h3>
        Neue Berechnung für Mitarbeiter markieren
    </h3>
    <form [formGroup]="formGroup">
        <vn-input formControlName="name"
                    key="name"
                    placeholder="calculation bookmark name TRANSLATE"
                    label="Beschreibung">
        </vn-input>
        <div *ngIf="allowToMarkCalculationBookmark()">
            <div class="form-group">
                <label for="isHidden">
                    unsichtbar für Arbeitnehmer
                </label>
                <mat-slide-toggle id="withIsHiddenToggle"
                                  formControlName="isHidden">
                </mat-slide-toggle>
            </div>
            <div class="form-group">
                <label for="isReporting">
                    zur Berichterstattung
                </label>
                <mat-slide-toggle id="withIsReportingToggle"
                                  formControlName="isReporting">
                </mat-slide-toggle>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button [disabled]="!formGroup.valid"
            mat-stroked-button
            (click)="createNeoCalculationBookmark()"
            *ngIf="allowToCreateCalculationBookmark()">
        CREATE #TRANSLATE
    </button>

    <button [disabled]="!formGroup.valid"
            mat-stroked-button
            (click)="updateNeoCalculationBookmark()"
            *ngIf="allowToUpdateCalculationBookmark()">
        UPDATE #TRANSLATE
    </button>

    <button mat-stroked-button
            (click)="deleteNeoCalculationBookmark()">
        DELETE #WILL REMOVE AFTER TEST
    </button>

    <button mat-stroked-button
            (click)="getNeoCalculationBookmarksByEmployeeId()">
        GET ALL #WILL REMOVE AFTER TEST
    </button>
</mat-dialog-actions>