const $inject = [
  "$scope"
];
export default class RegCodeDialogController {
  constructor($scope) {

    $scope.copyToClipboard = () => {
      const el = document.createElement('textarea');

      el.value = $scope.ngDialogData.reg_link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    };
  }
}
RegCodeDialogController.$inject = $inject;
