angular
  .module('VSPApp')
  .directive('vnSimpleCarousel', ['$window', function ($window) {
    return {
      retrict: 'EA',
      link: function ($scope, container) {
        var left = angular.element(container[0].querySelector('.toLeft'));
        var right = angular.element(container[0].querySelector('.toRight'));
        var canvas = angular.element(container[0].querySelector('.carouselCanvas'));
        var moveLength = 0;
        var position = 0;
        var canvasWidth,
          itemCount,
          itemWidth;

        // Reset Canvas position
        updateCanvasPos();

        // Bind handlers
        // console.log( left, right, $scope, container );
        left.on('click', moveLeft);
        right.on('click', moveRight);
        angular.element($window).on('resize', calcMoveLength);

        function updateCanvasPos() {
          left.toggleClass('hidden', position >= 0);
          right.toggleClass('hidden', position <= -canvasWidth + moveLength);

          canvas.css('transform', 'translateX(' + position + 'px)');
        }

        function calcMoveLength() {
          var item,
            itemFits;

          // Calc the appropriate sizes, this point the content is rendered.
          item = canvas.children()[0];
          itemWidth = itemWidth || item.clientWidth;
          itemFits = container[0].clientWidth / itemWidth;
          canvasWidth = canvasWidth || canvas[0].clientWidth;
          itemCount = itemCount || canvas.children().length;


          moveLength = itemFits * itemWidth;
        }

        function moveLeft() {
          position += moveLength;

          if (position > 0) {
            position = 0;
          }

          updateCanvasPos();
        }

        function moveRight() {
          if (moveLength === 0) {
            calcMoveLength();
          }

          position -= moveLength;

          if (position <= -canvasWidth + moveLength) {
            position = -canvasWidth + moveLength;
          }

          updateCanvasPos();
        }
      }
    };
  }])
