const $inject = [
    '$scope',
    '$state',
    'datevService',
    'Made',
    'NotificationService',
    'fileService'
];
export default class AdministrationDatevCreateController {
    constructor(
        $scope,
        $state,
        datevService,
        Made,
        NotificationService,
        fileService
    ) {
        Object.assign(this, {
            $scope,
            $state,
            datevService,
            Made,
            NotificationService,
            fileService
        });

        this.$scope.article = {
            title: '',
            content: '',
            stage: null,
            teaser: null,
            showArticle: false
        };

        this.onFetchArticleData($state.params.articleId);
    }

    openDocument(document) {
        this.fileService.openDocument(document._id);
    }

    onFetchArticleData(id) {
        if (id) {
            this.datevService.fetchArticleById(id).then(article => {
                this.$scope.article = article;
            });
        }
    }

    async onUpload(filedata) {
        if (!filedata) {
            return filedata;
        }

        if (!filedata._id) {
            let result = await this.Made.upload(filedata, [
                'cms'
            ]);

            filedata = result.data;
            filedata.name = result.data.filename;
            filedata.size = result.data.chunk_size;
        }

        return filedata;
    }

    onSaveArticle() {
        return this.datevService.saveArticle(this.$scope.article);
    }

    async onSubmit() {
        this.datevService.configs.loading = true;
        this.$scope.article.stage = await this.onUpload(this.$scope.article.stage);
        this.$scope.article.teaser = await this.onUpload(this.$scope.article.teaser);

        const savedArticle = await this.onSaveArticle();

        if (this.$scope.article._id) {
            this.$state.reload();
        } else {
            this.$state.go('inApp.cockpit.datev.edit', {
                articleId: savedArticle.upserted
            });
        }

        this.NotificationService.message('Speichern erfolgreich.');

        this.datevService.configs.loading = false;
    }
}
AdministrationDatevCreateController.$inject = $inject;
