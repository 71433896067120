const $inject = [
  '$scope',
  '$q',
  'lodash',
  'dialogService',
  'moment',
  'Made',
  '$timeout',
  '$window',
  'employeeService',
  'administrationService',
  'yearlyService'
];
export default class InitiateYearlyDialogController {
  constructor(
    $scope,
    $q,
    lodash,
    dialogService,
    moment,
    Made,
    $timeout,
    $window,
    employeeService,
    administrationService,
    yearlyService
  ) {

    Object.assign(this, {
      $scope,
      $q,
      lodash,
      dialogService,
      moment,
      Made,
      $timeout,
      $window,
      employeeService,
      administrationService,
      yearlyService
    });

    this.loading = true;
    this.forms = {};
    this.yearly_config = this.yearlyService.getYearlySessionConfig();
    this.yearly_session = {
      dates: {}
    };
    this.loading = false;
  }

  initiateYearly() {
    this.loading = true;
    this.yearlyService.initiateYearlyDocumentGathering(this.yearly_session).then(
      (res) => {
        this.$timeout(() => {
          this.$scope.confirm();
          this.loading = false;
        }, 0);
      }).catch((err) => {
      console.error(err);
      this.loading = false;
      this.$scope.closeThisDialog();
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }
}
InitiateYearlyDialogController.$inject = $inject;
