import { Inject, Injectable } from '@angular/core';

import { CarLeasingBasket } from "../models/car-leasing-basket.model";
import { CarLeasingCheckoutConfiguration } from '../models/car-leasing-checkout-configuration.model';
import { DeliveryData, DrivingLicenseFile } from '../models/car-leasing.interfaces';
import { DrivingLicenseFileType } from "../models/driving-license-file-type.model";
import { Car } from "../models/car.model";
import { BackendCarLeasingBasketMapper } from "./mappers/backend-car-leasing-basket-mapper.service";
import { FinnApiCarMapper } from "./mappers/finn-api-car-mapper.service";
import { BackendCarLeasingCheckoutConfigurationMapper } from "./mappers/backend-car-leasing-checkout-configuration-mapper.service";
import { BackendUserCarSettingsSelectionMapper } from './mappers/backend-user-car-settings-selection-mapper.service';
import { BackendDeliveryDataMapper } from "./mappers/backend-delivery-data-mapper.service";
import { BackendCarLeasingCarAdvantageResultMapper } from "./mappers/backend-car-leasing-car-advantage-result-mapper.service";
import { CarLeasingAdvantageCalculationResult } from "../models/car-leasing-advantage-calculation-result.model";
import { UserCarSettingsSelection } from '../models/user-car-settings-selection.model';
import { CarLeasingCustomerConfiguration } from '../models/car-leasing-customer-configuration.model';

export type CarCostByDuration = {
  [duration: number]: number;
};

export type CarCostParamsByCarId = {
  [carId: string]: {
    price: number;
    duration: number;
    fuelType: string;
  };
};

export type CarCatalogCostByCarId = {
  [carId: string]: number;
};

interface ApprovalEmployeeDataUpdate {
  cost: string;
  personalNumber: string;
  organisation: string;
}

export const CAR_LEASING_APPROVAL_STATES = [
  {id: 1, display: 'Freigabestatus | not started'},
  {id: 2, display: 'Freigabestatus | Freigabeanfrage'},
  {id: 3, display: 'Freigabestatus | Freigabe durch Kunde'},
  {id: 4, display: 'Freigabestatus | Ablehnung durch Kunde'}
];

export const CAR_LEASING_CONTRACT_PROCESS_STATES = [
  {id: 5, display: 'contract | Warten auf Vertrag'},
  {id: 6, display: 'contract | Manuell signiert'},
  {id: 7, display: 'contract | Digital signiert'},
  {id: 8, display: 'contract | Warten auf Freigabe durch Kunde'}
];

export const CAR_LEASING_FIRST_CHECK_STATES = [
  {id: 9, display: 'first check | Warten auf First Check'},
  {id: 10, display: 'first check | approved'},
  {id: 11, display: 'first check | rejected'}
];

export const CAR_LEASING_IS_CANCELLED = [
  {id: 12, display: 'is cancelled | cancelled'},
  {id: 13, display: 'is cancelled | not cancelled'},
];

import { CarLeasingTicketCreation } from '../models/car-leasing-ticket-creation.model';
import { CarLeasingErrorHandler } from './errors/car-leasing-error-handler';
import {AlertService} from "../../shared/services/alert.service";

@Injectable()
export class CarLeasingApiService {

  public constructor(
    @Inject('Made') private made: any,
    private notificationService: AlertService,
    private carLeasingErrorHandler: CarLeasingErrorHandler,
    private basketMapper: BackendCarLeasingBasketMapper,
    private carMapper: FinnApiCarMapper,
    private checkoutConfigMapper: BackendCarLeasingCheckoutConfigurationMapper,
    private settingsSelectionMapper: BackendUserCarSettingsSelectionMapper,
    private deliveryDataMapper: BackendDeliveryDataMapper,
    private carAdvantageResultMapper: BackendCarLeasingCarAdvantageResultMapper
  ) {
  }

  private async request(localPath: string, parameters: object = {}) {
    return this.made.request('rpc://valuenetdb/car_leasing/' + localPath, parameters);
  }

  async getCars(): Promise<Car[]> {
    let finnCars = await this.request('get_cars', { employee_id: this.made.user.valuenet_id });
    return finnCars.map(this.carMapper.fromApi)
  }

  async getCarById(id: string): Promise<Car> {
    let car = await this.request('get_car_by_id', { id: id })
    return this.carMapper.fromApi(car)
  }

  async approveFirstCheck(basketId: string) {
    return this.request('approve_first_check', { 'basket_id': basketId });
  }

  async rejectFirstCheck(basketId: string, comment: string) {
    return this.request('reject_first_check', {
      'basket_id': basketId,
      'comment': comment
    });
  }

  async rejectApproval(basketId: string, comment: string) {
    return this.request('reject_approval', {
      'basket_id': basketId,
      'comment': comment
    });
  }

  async approveApproval(
    basketId: string,
    distance: number | null,
    hasCompanyCar:  boolean | null,
    employeeId: number,
    employeeUpdatedData: Partial<ApprovalEmployeeDataUpdate>
  ) {

    return this.request('approve_approval', {
      'basket_id': basketId,
      'distance': distance,
      'has_company_car': hasCompanyCar,
      'employee_id': employeeId,
      'employee_updated_data': {
        'organisation': employeeUpdatedData.organisation,
        'cost': employeeUpdatedData.cost,
        'personalNumber': employeeUpdatedData.personalNumber
      }
    });
  }

  async requestNewContract(basketId: string, comment: string) {
    return this.request('request_new_contract', {
      'basket_id': basketId,
      'comment': comment
    });
  }

  async approveContractProcess(basketId: string) {
    return this.request('approve_contract_process', {
      'basket_id': basketId,
    });
  }

  async requestNewDocuments(basketId: string, comments_by_slot: object) {
    return this.request('request_new_documents_for_first_check', {
      'basket_id': basketId,
      'comments_by_slot': comments_by_slot
    });
  }

  async getBaskets(
    employeeId: number,
    customerId: number,
    finnOrderId: string,
    preferredDeliveryDate: string,
    orderStates: number[],
  ): Promise<CarLeasingBasket[]> {
    let baskets = await this.request(
      'get_baskets',
      {
        'filters': {
          'employee_id': employeeId,
          'customer_id': customerId,
          'finn_order_id': finnOrderId,
          'preferred_delivery_date': preferredDeliveryDate,
          'order_states': orderStates
        }
      }
    );
    return baskets.map(this.basketMapper.fromApi);
  }

  async getBasketsForAdministration(
    employeeId: number,
    customerId: number,
    finnOrderId: string,
    preferredDeliveryDate: string,
    orderStates: number[],
  ): Promise<any[]> {
    let baskets = await this.request(
      'get_baskets_for_administration',
      {
        'filters': {
          'employee_id': employeeId,
          'customer_id': customerId,
          'finn_order_id': finnOrderId,
          'preferred_delivery_date': preferredDeliveryDate,
          'order_states': orderStates
        }
      }
    );
    return baskets.map((basket: any) => {
      let contractProcessData = basket['control_process_data']
      let otrs = null;

      if (basket['otrs']) {
        otrs = new CarLeasingTicketCreation(basket['otrs']['ticket_number'], basket['otrs']['ticket_url'])
      }

      const administrationBasket = new CarLeasingBasket(
        basket['id'],
        basket['employee_id'],
        basket['customer_id'],
        BackendCarLeasingBasketMapper.firstCheckStateFromApi(basket['first_check_state']),
        basket['order_id'],
        BackendCarLeasingBasketMapper.firstCheckFilesFromApi(basket['first_check_files']),
        basket['checkout_date'],
        basket['preferred_delivery_date'],
        basket['is_ready_to_sign'],
        {
          fileId: contractProcessData['file_id'],
          state: BackendCarLeasingBasketMapper.contractProcessStateFromApi(
            contractProcessData['state'],
            contractProcessData['comment'],
          ),
          comment: contractProcessData['comment'],
          process_type: contractProcessData['process_type']
        },
        basket['contract_signing_process'],
        otrs,
        basket['checkout_configuration'],
        basket['approval_state'],
        basket['delivery_phone'],
        basket['is_cancelled']
      );

      administrationBasket.setCustomerData(basket['customer']);
      administrationBasket.setEmployeeData(basket['employee']);

      if (basket['is_yousign_v3']) {
      administrationBasket.setIsYouSignV3(basket['is_yousign_v3']);
    } else {
      console.log(`Missing YouSign Version for basket: ${basket['id']}`)
    }

      return administrationBasket;
    });
  }

  async ticketCreation(basketId: number, ticketCreation: CarLeasingTicketCreation) {
    return this.request('save_ticket_creation', {
      'basket_id': basketId,
      'ticket_creation': {
        'ticket_number': ticketCreation.ticketNumber,
        'ticket_url': ticketCreation.ticketUrl,
      }
    })
  }

  async createBasket(
    employeeId: number,
    carLeasingCheckoutConfiguration: CarLeasingCheckoutConfiguration,
    drivingFiles: DrivingLicenseFile[],
    deliveryData: DeliveryData,
    userSelection: UserCarSettingsSelection
  ): Promise<null|string> {
    let fileIdsByType = await this.uploadCarleasingDrivingLicenseFiles(employeeId, drivingFiles);
    let getString = (t: DrivingLicenseFileType) => CarLeasingApiService.getApiStringForDrivingLicenseFileType(t)

    let basketId = null;

    try {
      basketId = await this.request('create_basket', {
        'employee_id': employeeId,
        'checkout_configuration': this.checkoutConfigMapper.toApi(carLeasingCheckoutConfiguration),
        'driving_license_files': {
          [getString(DrivingLicenseFileType.Front)]: fileIdsByType[DrivingLicenseFileType.Front],
          [getString(DrivingLicenseFileType.Back)]: fileIdsByType[DrivingLicenseFileType.Back],
          [getString(DrivingLicenseFileType.Selfie)]: fileIdsByType[DrivingLicenseFileType.Selfie],
        },
        'delivery_data': this.deliveryDataMapper.toApi(deliveryData),
        'user_selection': this.settingsSelectionMapper.toApi(userSelection)
      });
    } catch (e) {
      this.notificationService.error(this.carLeasingErrorHandler.handle(e).getMessage())
    }

    return Promise.resolve(basketId);
  }

  async checkout(employeeId: number, basketId: string) : Promise<null|string> {
    let responseBasketId = null;
    try {
      responseBasketId = await this.request('checkout', {'employee_id': employeeId, 'basket_id': basketId});
    } catch (e) {
      this.notificationService.error(this.carLeasingErrorHandler.handle(e).getMessage())
    }
    return Promise.resolve(responseBasketId);
  }

  async cancelBasket(
    basketId: number,
    cancellationComment: string,
  ) {
    return this.request('cancel_basket', {
      'basket_id': basketId,
      'cancellation_comment': cancellationComment,
    });
  }

  async addFinnData(
    basketId: string,
    contactId: string,
    orderId: string,
  ) {
    return this.request('add_finn_data', {
      'basket_id': basketId,
      'order_id': orderId,
      'contact_id': contactId,
    });
  }

  async sendFinnDataRequest(
    basketId: string,
  ) {
    const basket = await this.request('finn_data_request', {
      'basket_id': basketId,
    });
    return this.basketMapper.fromApi(basket);
  }


  async uploadCarleasingDrivingLicenseFiles(
    employeeId: number,
    files: DrivingLicenseFile[]
  ): Promise<{ [slot in DrivingLicenseFileType]: string }> {
    let fileIds: any = {};

    for (const file of files) {
      fileIds[file.type] = await this.uploadDrivingLicenceFile(
        file.file,
        ['driving_license', CarLeasingApiService.getApiStringForDrivingLicenseFileType(file.type)],
        employeeId
      );
    }

    return fileIds;
  }

  async uploadRequestedCarLeasingFiles(
    employeeId: number,
    filesBySlot: object,
    basketId: string
  ): Promise<{ [slot: string]: string }> {
    let fileIds: any = {};
    let additionalTags = ['driving_license_reupload', `car_leasing_basket_id-${basketId}`];
    for (const [slot, file] of Object.entries(filesBySlot)) {
      fileIds[slot] = await this.uploadDrivingLicenceFile(file as object, additionalTags, employeeId);
    }
    return fileIds;
  }

  async uploadDrivingLicenceFile(file: object, additionalTags: string[], employeeId: number): Promise<string> {
    let extraPayload = {
      'valuenet': {
        'employee_id': employeeId
      }
    }
    let tags = ['neo', 'car_leasing'].concat(additionalTags);
    let response = await this.made.upload(file, tags, this.made.user._id, extraPayload);
    return response['data']['_id'];
  }

  async triggerReuploadEvent(basketId: string, filesBySlot: object) {
    return this.request('update_basket_with_requested_files_upload_event', {
      'basket_id': basketId,
      'files_by_slot': filesBySlot,
    });
  }

  async carLeasingEmployeeUploadContract(basketId: string, file: File) {
    let tags = [
      'neo',
      'car_leasing',
      'car_leasing_employee_sign_contract_manually',
      `car_leasing_basket_id-${basketId}`
    ];
    let response = await this.made.upload(file, tags, this.made.user._id);
    return response['data']['_id'];
  }

  async carLeasingPreContractUpload(basketId: string, file: File) :Promise<any> {
    let tags :string[] = [
      'neo',
      'car_leasing',
      'car_leasing_pre_contract_sign',
      `car_leasing_basket_id-${basketId}`
    ];
    // @ts-ignore
    file.name = `car_leasing_pre_contract-${basketId}`;
    let response = await this.made.upload(file, tags, this.made.user._id);
    return response['data']['_id'];
  }

  async triggerContractSigningProcess(basketId: string, fileId: string) {
    return this.request('trigger_contract_signing_process', {
      'basket_id': basketId,
      'file_id': fileId,
    });
  }

  private static getApiStringForDrivingLicenseFileType(type: DrivingLicenseFileType): string {
    let stringsByType = {
      [DrivingLicenseFileType.Front]: 'front',
      [DrivingLicenseFileType.Back]: 'back',
      [DrivingLicenseFileType.Selfie]: 'selfie',
    }
    let typeAsString = stringsByType[type];

    if (!typeAsString) {
      throw Error("Unknown driving license file type");
    }

    return typeAsString;
  }

  // TODO: hack to get CockpitCarLeasing to work
  async getCarLeasingCustomerConfig(customerId: number, returnAsObject=false) {
    const rawCustomerConfig = await this.request('get_customer_config', {
      'customer_id': customerId
    });

    if (!returnAsObject) {
      return CarLeasingCustomerConfiguration.fromApiMapper(rawCustomerConfig);
    }

    return rawCustomerConfig;
  }

  async getDefaultCustomerConfig() {
    return await this.request('get_default_customer_config', {});
  }

  async getEmployeeBaskets(employeeId: number): Promise<CarLeasingBasket[]> {
    let baskets: [any] = await this.request('get_baskets_for_employee', { employee_id: employeeId })
    return baskets.map(b => this.basketMapper.fromApi(b));
  }

  async setCustomerConfig(customerId: number, config: object) {
    await this.request('save_customer_config', {
      'customer_id': customerId,
      'config': config
    });
    return true;
  }

  async getCustomerConfig(customerId: number) {
    return await this.request('get_customer_config', {
      'customer_id': customerId
    });
  }

  async getBasketsAwaitingDrivingLicenceUpload(employee_id: number): Promise<CarLeasingBasket[]> {
    let baskets = await this.getEmployeeBaskets(employee_id);
    return baskets.filter(b => b.isWaitingForFirstCheckUpload());
  }

  async hasMissingDocuments(employee_id: number): Promise<boolean> {
    let baskets = await this.getEmployeeBaskets(employee_id);
    return !!baskets.filter(b => b.isWaitingForFirstCheckUpload() || b.isReadyToSign).length;
  }

  async getBasketsReadyToSign(employee_id: number): Promise<CarLeasingBasket[]> {
    let baskets = await this.getEmployeeBaskets(employee_id);
    console.log("baskets", baskets);
    return baskets.filter(b => b.isReadyToSign);
  }
  async generateContract(basket_id: number, is_precontract: false) {
    return this.made.request('rpc://utility/pdfgeneratorapi/generate_car_contract_for_signing', {
      'basket_id': basket_id,
      'is_precontract': is_precontract
    })
  }

  async calculateCarLeasingAdvantage(
    employeeId: number,
    userCarSettingsSelection: UserCarSettingsSelection
  ): Promise<CarLeasingAdvantageCalculationResult> {
    let calculationResult: CarLeasingAdvantageCalculationResult = await this.request('calculate_car_leasing_advantage', {
      'employee_id': employeeId,
      'user_car_settings_selection': this.settingsSelectionMapper.toApi(userCarSettingsSelection)
    });

    return calculationResult
  }


  async calculateCatalogCosts(
    carCostParams: CarCostParamsByCarId,
    customerId: number
  ): Promise<CarCatalogCostByCarId> {
    return this.request('calculate_catalog_costs', {
      'car_cost_params': carCostParams,
      'customer_id': customerId,
    });
  }

  async calculateCarCosts(
    carPrices: CarCostByDuration,
    carFuelType: string,
    customerId: number
  ): Promise<CarCostByDuration> {
    return this.request('calculate_car_costs', {
      'car_prices': carPrices,
      'customer_id': customerId,
      'car_fuel_type': carFuelType
    });
  }

  async calculateCarCost(
    userCarSettingsSelection: UserCarSettingsSelection,
    customerId: number
  ): Promise<number> {
    return this.request('calculate_car_cost', {
      'user_car_settings_selection': this.settingsSelectionMapper.toApi(userCarSettingsSelection),
      'customer_id': customerId
    })
  }

  async preCheckoutValidationError(
    employeeId: number,
    customerId: number,
    carLeasingCheckoutConfiguration: CarLeasingCheckoutConfiguration
  ) :Promise<string> {

    try {

      await this.request('pre_checkout_validations', {
        'employee_id': employeeId,
        'customer_id': customerId,
        'checkout_config': this.checkoutConfigMapper.toApi(carLeasingCheckoutConfiguration)
      })

    } catch (e) {
      return Promise.resolve(this.carLeasingErrorHandler.handle(e).getMessage());
    }
    return Promise.resolve('');
  }

  async getCarLeasingAdvantageConfig() {
    const config = await this.request('get_car_leasing_advantage_config');
    return config;
  }

  async validateCheckoutAddresses(employeeId: number, deliveryAddress: any) {
    return await this.request('validate_adresses_during_checkout', {
      employee_id: employeeId,
      delivery_address: deliveryAddress
    });
  }
}
