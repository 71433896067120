import {BONUS_COMPONENTS_WITHOUT_ADDITIONAL_SELECTION, CASHBACK_COMPONENT_NAMES} from '../../../services/BonusService';
import {CHECKOUT_TYPES_BY_NAME} from "../../../services/bonus/checkout_types";
import {CashbackReceiptUploadDialogComponent} from "../../../../app/cashback/dialogs/cashback-receipt-upload-dialog/cashback-receipt-upload-dialog.component";

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter',
  'MatDialogService'
];

export class BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter,
    MatDialogService
  ) {
    Object.assign(this, {
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter,
      MatDialogService
    });

    this.loading = {
      parent: true,
      child: false
    };

    this.baseInitPromise = new Promise((resolve, reject) => {
      let ctrl = this;

      ctrl.baseInit().finally(() => {
        ctrl.$timeout(() => {
          ctrl.loading.parent = false;
        }, 0);
        resolve();
      });
    });
  }

  step2SelectionPossible() {
    return !BONUS_COMPONENTS_WITHOUT_ADDITIONAL_SELECTION.includes(this.bonus_component_name);
  }

  componentIsYearly(bonus=this.bonus) {
    return this.BonusService.isBonusYearly(bonus);
  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true){
    if(!this.isComponentCheckoutAllowed()){
      this.navigateBackToDashboard();
      return false;
    }

    // if the component is not managed by VN it should not be added
    let valuenet_management = await this.BonusService.getManagementByName('valuenet');

    if (this.bonus_config.managed_by !== valuenet_management.id ) {
      this.WizardHandler.wizard().next();
      return false;
    }

    // check if the component should be automatically added to the checkout
    if (this.component_budget_config.configuration.checkout.auto_add) {
      let checkout_config = await this.BonusService.createCheckoutConfig(
        this.component_budget_config.value,
        this.bonus_config.display.title,
        this.bonus_component_name,
        this.bonus_id,
        this.bonus.state,
        this.checkout_type,
        this.available_bonus_start_date
      );

      this.employee_bonus_checkout_config = this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, this.bonus_component_name, checkout_config, undefined, this.checkout_type);

      this.bonus_project_checkout_configurations = this.employee.bonus[this.bonus_project_id];

      this.model = this.bonus_project_checkout_configurations[this.checkout_type.key][this.bonus_component_name];
    }

    if (move_to_next_step) {
      this.WizardHandler.wizard().next();
    }

    return true;
  }

  async continueToCashbackRequestStep(move_to_next_step = true) {
    this.WizardHandler.wizard().next();
    return true;
  }

  canContinue(){
    return this.isProjectApproved() && (this.isComponentCheckoutAllowed() );
  }

  isProjectApproved() {
    return this.bonus_project.project_approved.is_enabled || this.employee.testclient
  }

  isBonusCancelled(bonus=this.bonus) {
    return this.BonusService.isComponentCancelled(bonus);
  }

  isBonusInUse(bonus = this.bonus) {
    // let available_statuses = [BONUS_STATES_BY_NAME['available']['id'], BONUS_STATES_BY_NAME['declined']['id']];
    // return !available_statuses.includes(this.bonus && this.bonus.state);
    return this.BonusService.isComponentUsed(bonus);
  }

  isBonusExpired(){
    // let available_statuses = [BONUS_STATES_BY_NAME['available']['id'], BONUS_STATES_BY_NAME['declined']['id']];
    // return !available_statuses.includes(this.bonus && this.bonus.state);
    return this.BonusService.isComponentExpired(this.bonus);
  }

  isComponentCheckoutAllowed() {
    return this.BonusService.isComponentCheckoutAllowed(this.bonus) && !this.disableCheckoutIfMultipleSachbezugCheckouts;
  }

  wizardFinished() {
    this.navigateBackToDashboard();
  }

  navigateBackToDashboard() {
    this.$state.go('inApp.bonus.dashboard.main', { 'bonusProjectId': this.bonus_project_id });
  }

  getComponentDisplayStartDate(bonus=this.bonus, offset, units) {
    return this.BonusService.getComponentDisplayStartDate(bonus, offset, units);
  }

  getComponentDisplayEndDate(bonus=this.bonus, offset, units) {
    return this.BonusService.getComponentDisplayEndDate(bonus, offset, units);
  }

  getComponentDecisionDate(offset, units) {
    return this.BonusService.getComponentDecisionDate(this.bonus, offset, units);
  }

  wizardCanceled(){
    try {
      delete this.bonus_project_checkout_configurations[this.checkout_type.key][this.bonus_component_name];
    } catch (e) {
      console.error(e);
    }
    this.navigateBackToDashboard();
  }

  waitForCalculation() {
    // directly attached the calculationPromise
    return this.wzData.calculation_promise;
  }

  setModelValues(model_default_values) {
    let model_init_values = angular.copy(this.model);
    Object.assign(this.model, model_default_values, model_init_values);
  }

  toAllowComponentAddition(){
    if (!this.bonus_project_checkout_configurations || !this.model) {
      return false;
    }


    return this.model.value !== 0 && this.bonus_project_checkout_configurations.remaining_amount >= 0 && this.isComponentCheckoutAllowed();
  }

  calculateRemainingWithoutCurrentBonus(){

    if ( !this.employee.bonus ) {
      return this.bonus_project.for_employees[this.employee_id].bonus.amount;
    }

    return this.BonusService.calculateRemainingWithoutCurrentBonus(
      this.bonus_component_name,
      this.bonus_project_checkout_configurations,
      this.bonus_project.for_employees[this.employee_id].bonus.amount,
      this.checkout_type
    );
  }

  getNettoValue() {
    return this.bonus.checkout_config.netto_value || this.bonus.checkout_config.value || 0;
  }

  getVariableTax() {
    return this.bonus.checkout_config.variable_tax || 0;
  }

  async baseInit(){
    this.employee_id = this.Made.user.valuenet_id;
    this.bonus_project_id = this.$state.params.bonusProjectId;
    this.bonus_id = this.$state.params.bonusId;
    this.dateFormat = 'MMMM yyyy';
    this.iAmFiduciaUser = this.BonusService.authenticationService.iAm('fg_user');

    // without the bonus_id I can't save
    if (!this.bonus_id){
      this.navigateBackToDashboard();
      return;
    }

    this.bonus_component_name = this.$state.params.bonusComponentName;
    this.$scope.path = `/bonus_dashboard/${this.bonus_project_id}`;
    this.$scope.isBonus = true;
    this.isBonus = true;
    this.forms = {};
    this.allSachbezugCheckouts = {}
    this.all_active_checkouts = [];
    this.customer_id = await this.customerService.getCustomerId();
    this.bonus_project = this.$state.params.bonusProject;
    this.employee_bonus_project_configuration = this.bonus_project.for_employees[this.employee_id];
    this.component_budget_config = this.bonus_project.budget_config[this.bonus_component_name];

    this.bonus_project_duration_in_months = this.BonusService.calculateBonusProjectDurationInMonths(this.bonus_project);

    this.employee = this.vbmData.employees[ this.employee_id ];
    this.disableCheckoutIfMultipleSachbezugCheckouts = false;
    let currentCheckouts = {};

    if (this.employee.bonus && this.employee.bonus[this.bonus_project_id]) {
      currentCheckouts = {...this.employee.bonus[this.bonus_project_id][CHECKOUT_TYPES_BY_NAME['current']['key']]};
    }
    const sachbezugComponentsLabelByName = {
      'sachbezug': 'Sachbezug Vorteilskonto',
      'sachbezug_profit': 'Sachbezug  Fitnesskonto',
      'sachbezug_pluxee': 'Sachbezug Benefits Card',
    };

    let unfinished_sachbezug_checkout_key = null;
    for (const [key, checkedOutBonus] of Object.entries(currentCheckouts)) {
      if (key.includes('sachbezug')) {
        let is_finalized = this.BonusService.isComponentApproved(checkedOutBonus.bonus_state)
        if (!is_finalized) {
          unfinished_sachbezug_checkout_key = key;
        }
      }
    }

    if (this.bonus_component_name.includes('sachbezug') && unfinished_sachbezug_checkout_key && this.bonus_component_name !== unfinished_sachbezug_checkout_key) {
      this.disableCheckoutIfMultipleSachbezugCheckouts = true;
      this.sachbezugCheckedoutComponent = sachbezugComponentsLabelByName[unfinished_sachbezug_checkout_key];
    }

    // Get testclient property
    const employeeData = await this.employeeService.get(this.employee_id, Date.now());
    this.employee.testclient = employeeData.testclient;

    this.bonus_config = await this.BonusService.getComponentByName(this.bonus_component_name);
    this.bonuses = this.$state.params.bonuses;
    this.bonuses_by_id = this.$state.params.bonusesById;
    this.bonus = this.bonuses_by_id[this.bonus_id];
    this.available_bonus_start_date = null;

    if (['sachbezug', 'sachbezug_profit', 'sachbezug_pluxee'].includes(this.bonus_component_name)) {
      const res = await this.BonusService.getSachbezugCheckouts(this.bonus);

      for (const bonusCheckout of res) {
        const otherCheckouts = this.BonusService.getAllCheckouts(bonusCheckout).filter(bonus => {
          return this.isBonusInUse(bonus) && !this.componentIsYearly(bonus) && !this.isBonusCancelled();
        });
        this.allSachbezugCheckouts[this.BonusService.getBonusComponentName(bonusCheckout)] = otherCheckouts;

        const bonusSachbezugAvailableStartDate = await this.BonusService.getBonusAvailableStartDate(bonusCheckout);
        if (bonusSachbezugAvailableStartDate >= this.available_bonus_start_date) {
          this.available_bonus_start_date = bonusSachbezugAvailableStartDate;
        }
        if (otherCheckouts.length > 0) {
          for (const otherCheckout of otherCheckouts) {
            const bonusSachbezugAvailableStartDateIfOtherCheckouts = await this.BonusService.getBonusAvailableStartDate(otherCheckout);
            if (bonusSachbezugAvailableStartDateIfOtherCheckouts >= this.available_bonus_start_date) {
              this.available_bonus_start_date = bonusSachbezugAvailableStartDateIfOtherCheckouts;
            }
          }
        }
      };
    } else {
      const allCheckouts = this.BonusService.getAllCheckouts(this.bonus);
      this.all_active_checkouts = allCheckouts.filter(bonus => {
        return this.isBonusInUse(bonus) && !this.componentIsYearly(bonus) && !this.isBonusCancelled();
      });
    }

    if (!this.available_bonus_start_date) {
      this.available_bonus_start_date = await this.BonusService.getBonusAvailableStartDate(this.bonus);
    }

    this.bonus_project_end_date = this.moment.utc(this.bonus_project.dates.bonus_project_end_date);
    let bonus_project_end_date_for_diff = this.bonus_project_end_date.add(1, 'days');
    this.max_available_months = bonus_project_end_date_for_diff.diff(this.moment.utc(this.available_bonus_start_date), 'months');

    this.isBonusDefaultStartDateCheckoutAvailable = this.BonusService.isBonusDefaultStartDateCheckoutAvailable(this.bonus);

    this.is_doing_future_checkout = this.BonusService.isDoingFutureCheckout(this.bonus);
    this.checkout_type = this.is_doing_future_checkout ? CHECKOUT_TYPES_BY_NAME['future'] : CHECKOUT_TYPES_BY_NAME['current'];

    this.is_component_cashback = CASHBACK_COMPONENT_NAMES.includes(this.bonus_component_name);
    this.is_cashback_request_available = await this.BonusService.isCashbackRequestAvailable(this.employee_id, this.bonus_project_id);

    this.calculation_promise = new Promise((resolve, reject)=>{
      let watcher = this.$scope.$watch(()=>{return this.BonusService.calculation_promise;}, (newValue, oldValue)=>{
        if (newValue){
          newValue.then(()=>{
            watcher();
            resolve(true);
          });
        }
      });
    });

    this.model = {
      available_bonus_start_date: this.available_bonus_start_date
    };
  }

  calculateMonthsDifference() {
    this.bonus_start_moment = this.moment.unix(this.bonus.dates.bonus_start_date);
    let available_bonus_start_date_moment = this.moment(this.available_bonus_start_date);

    let bonus_start;
    let month_duration;

    if (this.BonusService.can_set_custom_checkout_date) {
      bonus_start = available_bonus_start_date_moment;
    } else if (available_bonus_start_date_moment.isBefore(this.bonus_start_moment) && !available_bonus_start_date_moment.isSame(this.bonus_start_moment, 'month')) {
      bonus_start = this.bonus_start_moment;
    } else {
      bonus_start = available_bonus_start_date_moment;
    }

    this.bonus_start_month_year = bonus_start.toDate();
    this.model.bonus_start_month_year = this.bonus_start_month_year;

    if (this.model.months > 0) {
      month_duration = this.model.months - 1; // to display the inclusive end date
    } else {
      month_duration = 0;
    }

    this.bonus_end_with_added_months = bonus_start.add(month_duration, 'months');
    this.bonus_end_month_year = this.bonus_end_with_added_months.toDate();
    this.model.bonus_end_month_year = this.bonus_end_month_year;
    this.bonus_start_moment = this.bonus_start_moment.toDate();
  }

  openCashbackRequestDialog(bonusType = '') {
    this.MatDialogService.open(
      CashbackReceiptUploadDialogComponent,
      {
        width: 500,
        disableClose: true,
        data: bonusType,
      }
    )
  }


}

BonusComponentController.$inject = $inject;
