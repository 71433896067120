const $inject = [
  '$q',
  '$scope',
  '$state',
  '$timeout',
  '$transitions',
  '$http',
  'MenuConstants',
  'authenticationService',
  'customerService',
  'dialogService',
  'advisorService',
  'employeeService',
  'NotificationService',
  'fileService',
  'administrationService',
  'Made'
];
export default class DocumentTemplatesComponentController {

  constructor(
    $q,
    $scope,
    $state,
    $timeout,
    $transitions,
    $http,
    MenuConstants,
    authenticationService,
    customerService,
    dialogService,
    advisorService,
    employeeService,
    NotificationService,
    fileService,
    administrationService,
    Made
  ) {

    Object.assign(this, {
      $q,
      $scope,
      $state,
      $timeout,
      $transitions,
      $http,
      MenuConstants,
      authenticationService,
      customerService,
      dialogService,
      advisorService,
      employeeService,
      NotificationService,
      fileService,
      administrationService,
      Made
    });

    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');

    this.$onInit = () => {
      this.customer_id = this.options && this.options.customer_id !== null ? this.options.customer_id : null;
    };

    this.loading = true;
    this.init().then(() => {
      this.$timeout(() => {
        this.loading = false;
      });
    });
  }

  async init() {
    await this.getTemplateTypes();
    await this.selectTemplateType(this.template_types[0].name);

    this.customer_configuration = {}
    if (!this.customer_id) {
      this.customer_configuration = await this.customerService.getConfiguration(this.customer_id, Date.now())
    }
  }

  async selectTemplateType(template_type_name) {
    this.template_type_selected = template_type_name;
    this.getTemplates().then(() => {
      this.$timeout(() => {});
    });
  }

  async getTemplateTypes() {
    return this.administrationService.getDocumentTemplateType().then(
      (template_types) => {
        this.template_types = template_types;
      }).catch((err) => {
      console.error(err);
      this.NotificationService.error('Fehlgeschlagen');
      this.template_types = [];
    });
  }

  getTemplates() {
    this.loading = true;
    return this.administrationService.getDocumentTemplates(this.template_type_selected, this.customer_id).then(
      (document_templates) => {
        this.document_templates = document_templates;
        this.loading = false;
      }).catch((err) => {
      this.NotificationService.error('Fehlgeschlagen');
      this.document_templates = [];
      this.loading = false;
      console.error(err);
    });
  }

  saveDocumentTemplate(template) {
    this.loading = true;
    return this.administrationService.saveDocumentTemplate(template, this.customer_id).then(
      (response) => {
        if (response) {
          this.NotificationService.message('Speichern erfolgreich');
        } else {
          this.NotificationService.error('Fehlgeschlagen');
        }
        this.loading = false;
      }).catch((err) => {
      console.error(err);
      this.NotificationService.error('Fehlgeschlagen');
      this.loading = false;
    });
  }

  chooseTemplatesType(template_type) {
    this.template_type_selected = template_type;
    this.$scope.loading = true;
    this.administrationService.getComponentTemplates(template_type).then(
      (res) => {
        this.unsetLoading();
        this.component_templates = res;
      }).catch((err) => {
      this.unsetLoading();
      this.component_templates = [];
    });
  }
}
DocumentTemplatesComponentController.$inject = $inject;
