import moment from 'moment';

const $inject = [
  '$scope',
  '$state',
  'employeeService',
  'Made',
  'WizardHandler',
  '$timeout',
  'NotificationService',
  'lodash',
  'authenticationService',
  'customerService',
  'DticketOrderService'
];

export class DTicketCheckoutController {
  constructor(
    $scope,
    $state,
    employeeService,
    Made,
    WizardHandler,
    $timeout,
    NotificationService,
    lodash,
    authenticationService,
    customerService,
    DticketOrderService
  ) {
    Object.assign(this, {
      $scope,
      $state,
      employeeService,
      Made,
      WizardHandler,
      $timeout,
      NotificationService,
      lodash,
      authenticationService,
      customerService,
      DticketOrderService
    });
    this.forms = {
      EmployeeAddressForm: null
    };
  }

  async $onInit() {
    this.__loading(true);

    this.ticketDefaultPrice = 49;
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.employeeId = this.Made.user.valuenet_id;
    this.isTestClient = this.Made.user.test_client;

    this.employee = await this.employeeService.get(this.employeeId, Date.now());
    this.checkoutInformation = await this.DticketOrderService.getCheckoutData(this.employeeId);
    this.hasActiveDTicket = await this.DticketOrderService.hasActiveDTicket(this.employeeId);

    this.employeeIntegrityData = await this.employeeService.getIntegrityData(this.employeeId)
      .then(integrityData => {
        return integrityData.data
    });

    this.employeeAddress = { ...this.employee.address };

    this.step = this.WizardHandler.wizard().currentStepNumber;
    this.steps = this.WizardHandler.wizard().totalStepCount;

    this.__loading(false);
  }

  navigateToDashboard(reload = true) {
    this.$state.go('inApp.neo.side.verguetung', { reload: reload });
  }

  async proceedToGDPR() {
    this.__loading(true);
    let address = {
      ort: this.employeeAddress.city,
      plz: this.employeeAddress.zip,
      strasse: this.employeeAddress.street
    };

    let integrityData = {
      'data': {
        'geburtsdatum': this.employeeIntegrityData.geburtsdatum
      }
    }
    try {
      let address_date_update_res = await this.employeeService.updateAddressData({ address: address }, this.employeeId)
      let birthday_update_res = await this.employeeService.updateBirthdateData(integrityData);
      this.proceed();
    } catch (e) {
      this.NotificationService.error('Fehlgeschlagen.');
      let error = e.error
    }

    this.__loading(false);
  }

  async proceedToContractOverview() {
    this.__loading(true);
    try {
      this.contract_url = await this.DticketOrderService.generateContractPreview(this.employeeId);
      this.__loading(false);
      this.proceed()
    } catch (e) {
      this.NotificationService.error('Fehlgeschlagen.');
      let error = e.error
    }
    this.__loading(false);
  }

  proceed() {
    this.WizardHandler.wizard().next();
  }

  async wizardFinished() {
    this.__loading(true);
    try {
      let checkout_response = await this.DticketOrderService.checkout(this.employeeId);
      this.NotificationService.message('Ihre Bestellung wurde erfolgreich ausgeführt.');
    } catch (e) {
      this.NotificationService.error('Fehlgeschlagen.');
      console.log('Error while checking out', e);
    }
    this.__loading(false);
    this.navigateToDashboard();
  }

  wizardCanceled() {
    this.navigateToDashboard();
  }

  __loading(val) {
    this.$timeout(() => {
      this.loading = val;
    });
  }
}

DTicketCheckoutController.$inject = $inject;
