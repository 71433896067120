import * as moment from "moment";
import * as _ from 'lodash';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CarLeasingCheckoutConfiguration } from '../../models/car-leasing-checkout-configuration.model';
import { Car } from '../../models/car.model';
import { CarLeasingCustomerConfiguration } from '../../models/car-leasing-customer-configuration.model';
import { CarCostByDuration, CarLeasingApiService } from '../../services/car-leasing-api.service';
import { CarLeasingCatalogService } from '../../services/car-leasing-catalog.service';
import { UserCarSettingsSelection } from '../../models/user-car-settings-selection.model';
import { CarLeasingAdvantageCalculationResult } from '../../models/car-leasing-advantage-calculation-result.model';
import { BaseComponent } from "src/app/shared/models/base.component";
import { CarColor } from "../../models/car-leasing.interfaces";

interface CarDisplaySettings {
  offset: number;
  slide: number;
}

@Component({
  selector: 'vn-car-leasing-details',
  templateUrl: './car-leasing-details.component.html',
  styleUrls: ['./car-leasing-details.component.scss']
})
export class CarLeasingDetailsComponent extends BaseComponent implements OnInit {

  public title!: string;

  public employeeId!: number;
  public customerId!: number;
  public carId!: string;

  public car!: Car;
  public customerConfiguration!: CarLeasingCustomerConfiguration
  public checkoutConfiguration!: CarLeasingCheckoutConfiguration

  private displaySettings!: CarDisplaySettings;
  public carAdvantageResult: CarLeasingAdvantageCalculationResult|null = null;

  public form!: FormGroup;

  public collabsibleData: { [key: string]: any } = {};

  public selectedDurationOptionIdx!: number;
  public selectedDistanceOptionIdx!: number;
  public validationError: string = '';

  private carCostPerDuration!: CarCostByDuration;
  private hasCompanyCar: boolean | null = null;

  public availableExtraKmOption: { km: number, price: number }[] = [];
  public carDescription!: string;
  public carAvailableTerms: number[] = [];
  public _carColorList: any;

  public ALVKTGvalue = 25;


  // ===================== INITIALIZATION =====================

  constructor(
    @Inject('$state') private $state: any,
    @Inject('EmployeeService') private employeeService: any,
    @Inject('VbmDataService') private vbmDataService: any,
    @Inject('CommonService') private commonService: any,
    private catalogService: CarLeasingCatalogService,
    private apiService: CarLeasingApiService,
    private formBuilder: FormBuilder
  ) {
    super();

    this.initForm();
    this.initDisplay();
  }

  async ngOnInit() {
    this.setLoading$(true);

    await this.init();
    this.initCarData();

    this.setLoading$(false);
  }

  private async init() {
    this.carId = this.$state.params.carId;
    this.employeeId = this.vbmDataService.employeeId;
    this.customerId = await this.employeeService.getCustomerId();

    this.car = await this.catalogService.getCarById(this.carId);
    this.customerConfiguration = await this.apiService.getCarLeasingCustomerConfig(this.customerId);
    this.checkoutConfiguration = new CarLeasingCheckoutConfiguration(
      this.car,
      this.customerConfiguration
    );

    this.availableExtraKmOption = this.car?.getExtraKms();
    this.carDescription = this.car.description ? this.car.description
        .replace(/&auml;/g, 'ä')
        .replace(/&Auml;/g, 'Ä')
        .replace(/&ouml;/g, 'ö')
        .replace(/&Ouml;/g, 'Ö')
        .replace(/&uuml;/g, 'ü')
        .replace(/&Uuml;/g, 'Ü')
        .replace(/&szlig;/g, 'ß')
      : '';

    this.carAvailableTerms = this.car.available_terms.filter(term => this.customerConfiguration.durationOptions.includes(term));

    const finnCarCostsPerDuration: CarCostByDuration =
      this.carAvailableTerms.reduce(
        (acc, contractDuration) => {
          acc[contractDuration] = this.car.getBasePrice(contractDuration)
          return acc;
        },
        {} as CarCostByDuration
      );

    this.carCostPerDuration = await this.apiService.calculateCarCosts(
      finnCarCostsPerDuration,
      this.checkoutConfiguration.carFuelType,
      this.customerId
    );
  }

  // ===================== FORM =====================

  private initForm() {
    this.form = this.formBuilder.group({
      distanceWorkHome: this.formBuilder.control(null),
      additionalCosts: this.formBuilder.array([], []),
      hasCompanyCar: this.formBuilder.control(true),
    });

    this.addFormRow();
  }

  public get additionalCosts(): FormArray {
    return this.form.get('additionalCosts') as FormArray;
  }

  public get distanceWorkHome(): FormControl {
    return this.form.get('distanceWorkHome') as FormControl;
  }

  public addFormRow() {
    const newFormRow: FormGroup = this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required]),
      value: this.formBuilder.control(null, [Validators.required])
    });

    this.additionalCosts.push(newFormRow);
  }

  public deleteFormRow(idx: number) {
    this.additionalCosts.removeAt(idx);
  }

  public showValidationMessage(controlGroup: AbstractControl, controlName: string) {
    const controlElement = controlGroup.get(controlName) as FormControl;
    return controlElement.touched && controlElement.invalid //&& (controlElement.dirty || controlElement.touched);
  }

  // ===================== OPTIONS =====================

  // ===================== CALCULATION =====================

  public canCheckout() {
    return this.canCalculate() && !!this.carAdvantageResult;
  }

  public canCalculate() {

      if (this.hasCompanyCar === true) {
        return true;
      }
      return this.distanceWorkHome.value && this.distanceWorkHome.value > 0;
  }

  public async selectDurationOption(idx: number, duration: number) {
    this.selectedDurationOptionIdx = idx;
    this.checkoutConfiguration.contractDuration = duration;
    this.checkoutConfiguration.perMonthValue = this.carCost;


    this.resetAdvantageResult();
  }

  public async selectDistanceOption(idx: number, distance: number) {
    this.selectedDistanceOptionIdx = idx;
    this.checkoutConfiguration.contractDistance = distance;
    this.checkoutConfiguration.perMonthValue = this.carCost;

    this.resetAdvantageResult()
  }

  public async selectDurationOptionAndValidate(idx: number, duration: number) {
    this.selectDurationOption(idx, duration);
  }

  public async selectDistanceOptionAndValidate(idx: number, distance: number) {
    this.selectDistanceOption(idx, distance);
  }

  public async checkForValidationError() {
    this.setLoading$(true);

    this.validationError = await this.apiService.preCheckoutValidationError(
      this.employeeId,
      this.customerId,
      this.checkoutConfiguration
    );

    this.setLoading$(false);

  }

  public getCarCostByDuration(duration: number) {
    if (!this.carCostPerDuration) {
      return 0;
    }

    return this.carCostPerDuration[duration];
  }

  public get carCost() {
    if (!this.carCostPerDuration || !this.checkoutConfiguration) {
      return 0
    }

    return this.carCostPerDuration[this.checkoutConfiguration.contractDuration] + this.checkoutConfiguration.extraKm.price;
  }

  public resetValidationErrors() {
    this.validationError = '';
  }

  public async calculateCarAdvantage() {

    this.resetValidationErrors();

    if (!this.checkoutConfiguration.perMonthValue) {
      return;
    }

    this.checkoutConfiguration.workHomeDistance = this.distanceWorkHome.value;
    this.checkoutConfiguration.hasCompanyCar = !!this.hasCompanyCar;

    let settingsSelection = new UserCarSettingsSelection(
      this.checkoutConfiguration.contractDuration,
      this.checkoutConfiguration.carFuelType,
      this.checkoutConfiguration.finnPerMonthValue,
      this.checkoutConfiguration.hasCompanyCar,
      this.distanceWorkHome.value,
      this.checkoutConfiguration.extraKm.km,
      this.checkoutConfiguration.extraKm.price,
      this.checkoutConfiguration.carCosumption,
      this.checkoutConfiguration.msrp,
      this.checkoutConfiguration.additionalPricePerKm
    );

    this.setLoading$(true);

    this.carAdvantageResult = await this.apiService.calculateCarLeasingAdvantage(
      this.employeeId,
      settingsSelection
    );

    this.setLoading$(false);
  }

  private calculateAdditionalCostsSum() {
    if (!this.additionalCosts.valid) {
      return 0;
    }

    let sum = 0;
    this.additionalCosts.controls.reduce(
      (sum, control) => sum + control.get('value')!.value,
      0
    )
    return sum;
  }

  public get fullPersonalCost() {
    if (!this.carAdvantageResult)
      return;

    return this.carAdvantageResult.personalCostOfCar -
      this.carAdvantageResult.fuelCostPerMonth -
      this.calculateAdditionalCostsSum();
  }

  public get fullCarLeasingCost() {
    if (!this.carAdvantageResult)
      return;
    return this.carAdvantageResult.reductionFromNetto -
      this.ALVKTGvalue +
      this.carAdvantageResult.carFuelBonus -
      this.carAdvantageResult.fuelCostPerMonth
  }

  // ===================== DISPLAY =====================

  public get carAvailableFrom() {
    return this.checkoutConfiguration && this.checkoutConfiguration.availableFrom ? `${moment(this.checkoutConfiguration.availableFrom).format('DD.MM.YYYY')}.` : 'N/A';
  }

  public get carColorList() {
    if (!this.car) {
      return [];
    }

    if (!this._carColorList) {
      const apiColorList = (this.car?.color_list as CarColor[]);
      const filteredColorList = [...new Map(apiColorList.filter(item => item.availability > 0).map(item => [item.id, item])).values()];
      this._carColorList = filteredColorList
    }


    return this._carColorList;
  }

  public get collapsibleKeys() {
    return Object.keys(this.collabsibleData);
  }

  public getCollapsibleTitle(key: string) {
    const collabsibleData = this.collabsibleData[key]
    if (collabsibleData.title) {
      return collabsibleData.title;
    }
    return '';
  }

  public getCollapsibleData(key: string) {
    const collabsibleData = this.collabsibleData[key]
    if (collabsibleData.data) {
      return collabsibleData.data.split(',');
    }
    return '';
  }

  public get environmentCollapsibleData() {
    if (this.collabsibleData.environment) {
      return this.collabsibleData.environment;
    }
    return '';
  }

  public getCollapsibleClass(key: string) {
    const collabsibleData = this.collabsibleData[key];
    const classObj = {
      open: false
    }

    if (collabsibleData.open) {
      classObj.open = collabsibleData.open as boolean;
    }

    return classObj;
  }

  public getColorClass(color: CarColor) {
    return {
      active: color.color_hex === this.car.color.color_hex
    }
  }

  public getColorStyle(color: CarColor) {
    return {
      'background-color': color.color_hex
    }
  }

  private initDisplay() {
    this.displaySettings = {
      offset: 0,
      slide: 0,
    };
  }

  private initCarData() {
    this.title = this.carId;

    this.collabsibleData = {
      comfort: {
        title: 'komfort',
        open: false,
        data: this.car.equipment.comfort
      },
      exterior: {
        title: 'exterieur',
        open: false,
        data: this.car.equipment.exterior
      },
      interior: {
        title: 'interieur',
        open: false,
        data: this.car.equipment.interior
      },
      multimedia: {
        title: 'multimedia',
        open: false,
        data: this.car.equipment.multimedia
      },
      safety: {
        title: 'sicherheit',
        open: false,
        data: this.car.equipment.safety
      },
    }
  }

  public toggleCollapse(key: string) {
    const isOpen = this.collabsibleData[key].open as boolean;
    this.collabsibleData[key].open = !isOpen;
  }

  public changeColor(carId: string) {
    if (carId === this.car.id) {
      return;
    }
    this.setLoading$(true);

    this.$state.transitionTo(
      this.$state.$current,
      { carId },
      { reload: false }
    );

    this.setLoading$(false);
  }

  public slideImg(dir = 1, num = -1) {
    let len = this.car.pictures.length - 1;

    if (num == -1) num = this.displaySettings.slide + dir;
    if (num > len) num = 0;
    if (num < 0) num = len;

    this.displaySettings.slide = num;
    this.displaySettings.offset = Math.floor(num / 5) * 330;
  }

  public getThumbnailsStyle() {
    return {
      transform: `translateY(-${this.displaySettings.offset}px`
    }
  }

  public getThumbnailsClass(idx: number) {
    return {
      active: idx === this.displaySettings.slide
    }
  }

  public getSlideImgStyle() {
    return {
      transform: `translateX(-${this.displaySettings.slide * 100}%`
    }
  }

  // collapsibles:

  public getDurationOptionClass(idx: number) {
    return {
      active: this.selectedDurationOptionIdx === idx
    }
  }

  public getDistanceOptionClass(idx: number) {
    return {
      active: this.selectedDistanceOptionIdx === idx
    }
  }

  public async checkout() {

    await this.checkForValidationError();

    if (this.validationError) {
      return;
    }

    let userSelection = new UserCarSettingsSelection(
      this.checkoutConfiguration.contractDuration,
      this.checkoutConfiguration.carFuelType,
      this.checkoutConfiguration.finnPerMonthValue,
      this.checkoutConfiguration.hasCompanyCar,
      this.distanceWorkHome.value,
      this.checkoutConfiguration.extraKm.km,
      this.checkoutConfiguration.extraKm.price,
      this.checkoutConfiguration.carCosumption,
      this.checkoutConfiguration.msrp,
      this.checkoutConfiguration.additionalPricePerKm
    );

    this.$state.go(
      'inApp.carleasing_checkout',
      {
        carLeasingCheckoutConfiguration: this.checkoutConfiguration,
        userSelection: userSelection,
        carAdvantageResult: this.carAdvantageResult
      }
    )
  }

  public toggleHasCompanyCar(status: string) {
    if (status === 'ja') {
      this.distanceWorkHome.setValue(null);
      this.hasCompanyCar = true;
    } else {
      this.hasCompanyCar = false;
    }

    this.resetAdvantageResult()
  }

  public getHasCompanyCarClass(status: string) {
    let className = '';
    if ((this.hasCompanyCar == true && status === 'ja') ||
      (this.hasCompanyCar == false && status === 'nein')
    ) {
      className = 'active'
    }
    return className;
  }

  public canGoNextLevel(newLevel: number) {
    switch (newLevel) {
      case 2:
        return this.selectedDurationOptionIdx >= 0;
      case 5:
      case 3:
        return this.selectedDistanceOptionIdx >= 0 &&
          this.selectedDurationOptionIdx >= 0
      case 4:
        return this.hasCompanyCar == false;
    }

    return false;
  }

  public higherAgeNeeded() {
    return this.checkoutConfiguration.power > 150;
  }

  public resetAdvantageResult() {
    if (this.carAdvantageResult) {
      this.carAdvantageResult = null;
    }
  }
}
