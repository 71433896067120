const $inject = [
  '$state',
  '$location',
  'moment',
  'CarLeasingCatalogService',
  'lodash',
];

export class CarLeasingCatalogController {
  constructor(
    $state,
    $location,
    moment,
    CarLeasingCatalogService,
    lodash
  ) {

    // Object.assign(this, {
    //   $state,
    //   $location,
    //   moment,
    //   lodash,
    // });

    // this.filteredCars = [];
    // this.filterOptions = {};
    // this.carFilters = {};

    // this.filterPlaceholder = 'Alle';
    // this.catalog = CarLeasingCatalogService;
    // this.loading = false;
  }

  // async $onInit() {
  //   let carsPromise = this.catalog.getCars().then(cars => {
  //     this.cars = cars;
  //     this.filteredCars = [...this.cars];
  //     this.initFilters();
  //   });
  //   this.loading = true;
  //   await Promise.all([carsPromise]);
  //   this.loading = false;
  // }

  // transformDate(carDate) {
  //   return this.moment(carDate, 'YYYY-MM-DD').format('DD.MM.YY');
  // }

  // // ===================== FILTERS =====================

  // _userBrandFilter(car, brand) {
  //   return !brand || car.brand.id === brand;
  // }

  // _userGearshiftFilter(car, gearshift) {
  //   return !gearshift || car.gearshift === gearshift;
  // }

  // _userCartypeFilter(car, cartype){
  //   return !cartype || car.cartype === cartype;
  // }
  // _userFuelFilter(car, fuel) {
  //   return !fuel || car.fuel === fuel;
  // }

  // _userDurationFilter(car, duration) {
  //   return !duration || car.available_terms.includes(duration);
  // }

  // _userAvailabilityFilter(car, available_from, available_to) {
  //   return this.moment(car['available_from'], 'YYYY-MM-DD').isBetween(
  //     this.moment(available_from, 'DD.MM.YYYY').subtract(1, 'days'),
  //     this.moment(available_to, 'DD.MM.YYYY')
  //   ) || this.moment(car['available_to'], 'YYYY-MM-DD').isBetween(
  //     this.moment(available_from, 'DD.MM.YYYY'),
  //     this.moment(available_to, 'DD.MM.YYYY').add(1, 'days')
  //   )
  // }

  // initFilters() {
  //   const filterOptions = {
  //     'brand': [this.filterPlaceholder],
  //     'gearshift': [this.filterPlaceholder],
  //     'cartype': [this.filterPlaceholder],
  //     'fuel': [this.filterPlaceholder],
  //     'duration': [this.filterPlaceholder],
  //     'availableFrom': [],
  //     'availableTo': []
  //   }

  //   for (const car of this.cars) {
  //     filterOptions['brand'] = [...filterOptions['brand'], car.brand.id];
  //     filterOptions['gearshift'] = [...filterOptions['gearshift'], car.gearshift];
  //     filterOptions['cartype'] = [...filterOptions['cartype'], car.cartype];
  //     filterOptions['fuel'] = [...filterOptions['fuel'], car.fuel];
  //     filterOptions['duration'] = [...filterOptions['duration'], ...car.available_terms];
  //     filterOptions['availableFrom'] = [...filterOptions['availableFrom'], this.moment(car.available_from)];
  //     filterOptions['availableTo'] = [...filterOptions['availableTo'], this.moment(car.available_to)];
  //   }

  //   filterOptions['brand'] = this.lodash.uniq(filterOptions['brand']);
  //   filterOptions['gearshift'] = this.lodash.uniq(filterOptions['gearshift']);
  //   filterOptions['cartype'] = this.lodash.uniq(filterOptions['cartype']);
  //   filterOptions['fuel'] = this.lodash.uniq(filterOptions['fuel']);
  //   filterOptions['duration'] = this.lodash.sortBy(this.lodash.uniq(filterOptions['duration']));
  //   filterOptions['availableFrom'] = this.moment.min(filterOptions['availableFrom']).format('DD.MM.YYYY');
  //   filterOptions['availableTo'] = this.moment.max(filterOptions['availableTo']).format('DD.MM.YYYY');

  //   this.filterOptions = filterOptions;

  //   const params = this.$location.search();
  //   for (const param in params) {
  //     this.filterCars(param, param === 'duration' ? parseInt(params[param]) : params[param])
  //   }

  // }

  // filterCars(filterKey, filterValue) {

  //   if (filterKey === 'availableFrom' || filterKey === 'availableTo') {
  //     filterValue = this.moment(filterValue, 'DD.MM.YYYY')
  //   }

  //   this.carFilters = {
  //     ...this.carFilters,
  //     [filterKey]: filterValue === this.filterPlaceholder ? undefined : filterValue
  //   };

  //   this.filteredCars = this.cars
  //     .filter(
  //       car => {

  //         return this._userBrandFilter(car, this.carFilters.brand) &&
  //           this._userGearshiftFilter(car, this.carFilters.gearshift) &&
  //           this._userCartypeFilter(car, this.carFilters.cartype) &&
  //           this._userFuelFilter(car, this.carFilters.fuel) &&
  //           this._userDurationFilter(car, this.carFilters.duration) &&
  //           this._userAvailabilityFilter(car, this.carFilters.availableFrom, this.carFilters.availableTo)
  //       }
  //     )
  //     .sort(
  //       (car1, car2) => car1.getCataloguePrice() > car2.getCataloguePrice() ? 1 : -1
  //     );

  //   this.$location.search(this.carFilters)
  // }

  // navigateToCar(car) {
  //   this.$state.go('inApp.car-leasing.details', {
  //     carId: car.id
  //   });
  // }

  // handleUserInfoFormSubmit() {
  //   this.loading.updating_address = true;
  //   let address = {
  //     ort: this.employee_address.city,
  //     plz: this.employee_address.zip,
  //     strasse: this.employee_address.street
  //   };

  //   this.employeeService.updateAddressData({ address: address }, this.employee_id).then((res) => {
  //     this.loading.updating_address = false;
  //     this.WizardHandler.wizard().next();
  //   }).catch((err) => {
  //     console.log(err);
  //   });

  // }

}

CarLeasingCatalogController.$inject = $inject
