<mat-toolbar>
    <ng-content select="button">
    </ng-content>

    <ng-container *ngIf="showBasket();else input">
        <button mat-raised-button
                vnRandomAnimate
                data-cy='benefit.bonago.preCheckout.toolbar.buttonOpenBasket'
                [randomAnimation]="0"
                class='basket-button'
                (click)='openBottomSheet()'>
            <span>
                {{basketSum$ | async}},00 €
            </span>

            <mat-icon>
                shopping_cart
            </mat-icon>
        </button>
    </ng-container>

    <ng-template #input>
        <form [formGroup]='toolbarForm'
              vnRandomAnimate
              [randomAnimation]="0"
              class="search-bar">
            <vn-input formControlName='inputValue'
                      key="benefit.bonago.preCheckout.toolbar.inputSearch"
                      [rounded]='true'
                      [autocompleteOptions]="retailerNames!"
                      placeholder='Suche...'>
            </vn-input>
        </form>
    </ng-template>

    <button mat-mini-fab
            class='search-button'
            data-cy='benefit.bonago.preCheckout.toolbar.buttonOpenSearch'
            (click)='toggleActiveMode()'>
        <mat-icon>
            search
        </mat-icon>
    </button>
</mat-toolbar>
