import { map, tap } from "rxjs/operators";
import { fromEventPattern, Observable } from "rxjs";
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { FoodVoucherRetailer } from "../../models/food-voucher.model";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { LoadingService } from "src/app/shared/services/loading.service";
import { InputComponent } from "../../../shared/components/form-mixins/input/input.component";
import { FoodVoucherRetailersMapService } from "../../services/food-voucher-retailers-map.service";

interface FoodFoucherMarkerData {
  marker: google.maps.Marker;
  retailer: FoodVoucherRetailer;
}

interface CustomerAddress {
  street: string;
  zip: string;
  city: string;
  name:string;
}

interface FoodVoucherMapDialogData {
  customerAddress: CustomerAddress;
  customerId: Number;
}


@Component({
  selector: 'vn-food-voucher-retailers-map',
  templateUrl: './food-voucher-retailers-map.component.html',
  styleUrls: ['./food-voucher-retailers-map.component.scss']
})
export class FoodVoucherRetailersMapComponent implements OnInit {

  public mapForm!: FormGroup;
  public consentServiceName: string = 'GOOGLE_MAPS';
  public consentServiceAccepted: boolean = false;
  public mapOptions!: google.maps.MapOptions;
  public onPlaceChanged$!: Observable<google.maps.LatLngLiteral>;
  public retailerMarkers!: FoodFoucherMarkerData[];
  public markerClustererImagePath = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
  public latLong: any;


  private autocomplete!: google.maps.places.Autocomplete;

  public get zipCode(): FormControl {
    return this.mapForm.get('zipCode') as FormControl;
  }

  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  public infoContent = '';

  @ViewChild(InputComponent)
  set searchField(inputComponent: InputComponent) {
    if (!inputComponent || !google.maps.places) {
      return;
    }

    this.autocomplete = new google.maps.places.Autocomplete(inputComponent.getInputElement())
    this.autocomplete.addListener('place_changed',() => {
      this.ngZone.run(async () => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        this.latLong = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }

        this.loader.show();
        const data = await this.retailersMap.getRetailersWithinRangeForLatLng(this.latLong);
        if(data.length) {
          this.setRetailerMarkers(data);
        }
        this.loader.hide();
      });
    });
  }

  constructor(
    @Inject('$state') private $state: any,
    @Inject('$location') private $location: any,
    @Inject(MAT_DIALOG_DATA) private dialogData: FoodVoucherMapDialogData,
    public dialogRef: MatDialogRef<FoodVoucherRetailersMapComponent>,
    private loader: LoadingService,
    private formBuilder: FormBuilder,
    private retailersMap: FoodVoucherRetailersMapService,
    private ngZone: NgZone
  ) {
    this.initMapForm();
  }

  async ngOnInit(): Promise<void> {
    this.loader.show();

    await this.initMapOptions();

    const retailerAddresses = await this.retailersMap.getRetailers(this.dialogData.customerId);
    this.setRetailerMarkers(retailerAddresses);

    this.loader.hide();
  }

  private initMapForm() {
    this.mapForm = this.formBuilder.group({
      zipCode: this.formBuilder.control(undefined, []),
    });
  }

  private async initMapOptions() {
    const geocoder = new google.maps.Geocoder();
    const geocodeResponse = await geocoder.geocode({
      address: this.dialogData.customerAddress.street
    });

    if (geocodeResponse.results) {
      if (!geocodeResponse.results.length) {
        throw new Error(
          'Could not load location for given address: ' + this.dialogData.customerAddress.street
        );
      }

      this.mapOptions = {
        mapId: this.retailersMap.overviewMapId,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        center: geocodeResponse.results[0].geometry.location,
        zoom: 15
      };
    }
  }

  private setRetailerMarkers(retailers: FoodVoucherRetailer[]) {
    this.retailerMarkers = retailers.map(retailer => ({
      marker: new google.maps.Marker({
        position: new google.maps.LatLng(retailer.lat, retailer.lng),
        icon: {
          url: `${this.$location.protocol() + '://' + location.host}/assets/img/maps/default_map_icon_image.png`,
          scaledSize: new google.maps.Size(30, 34), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(16, 34) // anchor
        },
      }),
      retailer
    }));
  }

  public openInfo(marker: MapMarker, data: FoodFoucherMarkerData) {
    this.infoContent = `
    <div class="retailer-template-content">
      <span class="info-box-heading">Name: ${data.retailer.retailerName}</span>
      <span class="info-text">
        <strong>Strasse:</strong> ${data.retailer.address} ${data.retailer.streetNumber}
      </span>
      <span class="info-text">
        <strong>Ort:</strong> ${data.retailer.city}
      </span>
      <span class="info-text">
        <strong>PLZ:</strong> ${data.retailer.zipCode}
      </span>
    </div>`;
    this.infoWindow.open(marker);
  }

  public handleStatus(status: boolean): void {
    this.consentServiceAccepted = status
  }

  public triggerAction(action: string): void {
    if (action === 'accepted') {
      this.dialogRef.close(true);
    }
  }

}
