angular
  .module('VSPApp')
  .directive('sidebarScroll', ['$window', '$timeout', function ($window, $timeout) {
    return {
      restrict: 'A',
      link: function ($scope, elem) {

        var watchTarget = angular.element(window),
          scrollContent = angular.element(document.querySelector('#root-view')),
          scrolling = false;

        watchTarget.on('scroll', () => {
          if (scrolling || $scope.mobileOpen) {
            return;
          }

          scrolling = true;
          elem.addClass('fade-out');

          $timeout(() => {
            scrolling = false;
            elem.removeClass('fade-out');
          }, 500);
        });

        elem.on('touchstart touchmove touchcancel touchend', () => {
          scrollContent.css('height', ($scope.mobileOpen) ? '100%' : 'auto');
        });
      }
    };
  }]);
