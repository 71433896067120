import {SUPPORT_REQUEST_TAG} from './SupportService';

const $inject = [
  '$q',
  'Made',
  'moment',
  'lodash',
  'DocumentConstants',
  'BonusService'
];
export default class FilenameService {

  constructor($q, Made, moment, lodash, DocumentConstants, BonusService) {
    Object.assign(this, {
      $q,
      Made,
      moment,
      lodash,
      DocumentConstants,
      BonusService
    });

    this._getBonusComponentsByName();
  }

  _getBonusComponentsByName() {
    return this.BonusService.getBonusComponentsMap().then(
      (bonus_components_by_name) => {
        this.BONUS_COMPONENTS_BY_NAME = bonus_components_by_name;
      }).catch((err) => {
        console.error(err);
      });
  }

  getFileName(file) {
    let filename_formatted = file.filename;
    if (file.meta.tags && file.meta.tags.length > 0) {
      if (this.lodash.includes(file.meta.tags, 'bonus')) {
        let checkout_id_tag = file.meta.tags.filter(tag => tag.startsWith('checkout_session_id'));
        let component_tag = file.meta.tags.filter(tag => tag.startsWith('bonus_component_name'));
        let backoffice_upload_timestamp = file.meta.tags.filter(tag => tag.startsWith('backoffice_upload_timestamp'));
        let has_checkout = checkout_id_tag && checkout_id_tag.length > 0;
        let has_component_name = component_tag && component_tag.length > 0;
        let has_backoffice_upload_timestamp = backoffice_upload_timestamp && backoffice_upload_timestamp.length > 0;

        if (has_checkout && has_component_name) {
          let checkout_id = checkout_id_tag[0].split('-')[1];
          let checkout_date = this.moment.unix(checkout_id);
          let component_name = component_tag[0].split('-')[1];
          let filename = checkout_date.year() + '-' + this.BONUS_COMPONENTS_BY_NAME[component_name].display.title + ' - Unterlagen vom ' + checkout_date.format('DD-MM-YYYY');
          filename_formatted = filename;
        }

        if (has_checkout && !has_component_name) {
          let checkout_id = checkout_id_tag[0].split('-')[1];
          let checkout_date = this.moment.unix(checkout_id);
          let filename = checkout_date.year() + ' - Unterlagen vom ' + checkout_date.format('DD-MM-YYYY');
          filename_formatted = filename;
        }


        if (has_backoffice_upload_timestamp && has_component_name) {
          let backoffice_upload = backoffice_upload_timestamp[0].split('-')[1];
          let upload_date = this.moment.unix(backoffice_upload);
          let component_name =  component_tag[0].split('-')[1];
          let filename = upload_date.year() + '-' + this.BONUS_COMPONENTS_BY_NAME[component_name].display.title + ' - Unterlagen vom ' + upload_date.format('DD-MM-YYYY');
          filename_formatted = filename;
        }
      } else if (this.lodash.includes(file.meta.tags, 'calculation')) {
        let createdDate = new Date(file.meta.created * 1000),
        day = (createdDate.getDate() >= 10 ? '' : '0') + createdDate.getDate(),
        month = (createdDate.getMonth() >= 9 ? '' : '0') + (createdDate.getMonth() + 1),
        year = createdDate.getFullYear(),
        hours = (createdDate.getHours() >= 10 ? '' : '0') + createdDate.getHours(),
        minutes = (createdDate.getMinutes() >= 10 ? '' : '0') + createdDate.getMinutes();

        filename_formatted = this.DocumentConstants[file.meta.tags[1]] && `${this.DocumentConstants[file.meta.tags[1]].name}_${day}.${month}.${year}_${hours}.${minutes}`;
      } else if (this.lodash.includes(file.meta.tags, SUPPORT_REQUEST_TAG.main_tag)) {
        let upload_timestamp = file.meta.tags.filter(tag => tag.startsWith(SUPPORT_REQUEST_TAG.session_tag));
        let timestamp = upload_timestamp[0].split('-')[1];
        let upload_date = this.moment.unix(timestamp).format('DD-MM-YYYY HH:m');
        filename_formatted = upload_date + ' - ' +  filename_formatted;
      }
    }
    return filename_formatted;
  }
}

FilenameService.$inject = $inject;
