const VersicherungConstants = {
  privathaftplicht: {
    title: 'Privathaftplicht',
    link: 'ergebnis/phv',
    image: '/assets/img/versicherung/privathaftfpflicht.svg',
    imageIsIcon: true
  },
  hausrat: {
    title: 'Hausrat',
    link: 'ergebnis/hr',
    image: '/assets/img/versicherung/hausrat.svg',
    imageIsIcon: true
  },
  hundehalterhaftpflicht: {
    title: 'Hundehalterhaftpflicht',
    link: 'ergebnis/hundehalter',
    image: '/assets/img/versicherung/hundehalterhaftpflicht.svg',
    imageIsIcon: true
  },
  pferdehalterhaftpflicht: {
    title: 'Pferdehalterhaftpflicht',
    link: 'ergebnis/pferdehalter',
    image: '/assets/img/versicherung/pferdehalterhaftpflicht.svg',
    imageIsIcon: true
  }
};

export default VersicherungConstants;
