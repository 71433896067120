var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (JSON, sliderOptions) {
      





































































pug_mixins["slider"] = pug_interp = function(data, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!data) { data={}; }
if (undefined === data.htmlClass) { data.htmlClass = 'currency'; }
if (undefined === data.value) { data.value = '0'; }
if (false === data.value) { delete data.value; }
if (!data.from ) { data.from = 0; }
if (undefined === data.if) { data.if = 'false'; }

// sliderOptions.steps? to define where the steps are ended?
// Formula to calculate split?

sliderOptions = '';

if (undefined !== data.from) { sliderOptions += 'component.sliderOptions.from = ' + JSON.stringify(data.from) +';'; }
if (undefined !== data.to) { sliderOptions += 'component.sliderOptions.to = component.sliderOptions.to || ' + JSON.stringify(data.to) +';'; }
if (undefined !== data.value) { sliderOptions += 'component.sliderOptions.value = ' + JSON.stringify(data.value) +';'; }

pug_html = pug_html + "\u003Cspan" + (" style=\"display:none\""+pug.attr("ng-init", sliderOptions, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([data.htmlClass], [true]),"id": "mySlider1","ng-if": "component.model","ng-model": pug.escape(data.model || 'component.model.value'),"type": "number","slider": pug.escape(true),"options": pug.escape(data.sliderOptions || data.options || 'component.sliderOptions'),"data-cy": pug.escape(cyData)},attributes]), true)) + "\u003E";
};


















pug_html = pug_html + "\u003Cdiv ng-if=\"component.loading.main\"\u003ELOADING\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"!component.loading.main\" id=\"essenscheck_container\"\u003E\u003Ccheckout-back-link on-click=\"component.backToDashboard()\"\u003E\u003C\u002Fcheckout-back-link\u003E\u003Ccheckout-intro-step title=\"component.display.intro.title\" video=\"component.display.intro.video\" img=\"component.display.intro.img\" subtitle=\"component.display.intro.subtitle\" subsubtitle=\"component.display.intro.subsubtitle\" inner-html=\"component.display.intro.inner_html\" is-open=\"'intro' === component.box.step\" is-disabled=\"!component.essenscheckType || component.is_already_used || component.disableCheckoutIfNoSalary\" on-click-header=\"component.toggleStep('intro')\" on-click-next=\"component.toggleStep('values')\"\u003E\u003Cinner-html bind-html-compile=\"component.display.intro.inner_html\"\u003E\u003C\u002Finner-html\u003E\u003Cinner-html ng-if=\"component.salaryError\" bind-html-compile=\"component.salaryError\"\u003E\u003C\u002Finner-html\u003E\u003C\u002Fcheckout-intro-step\u003E\u003Ccheckout-values-step is-open=\"'values' === component.box.step\" title=\"component.display.values.title\" description=\"component.display.values.description\" can-click-next=\"component.canExitValuesScreen()\" on-click-next=\"component.toggleStep('checkout')\" on-click-header=\"component.toggleStep('values')\"\u003E\u003Cadvantage-form\u003E\u003Cdiv ng-if=\"component.sliderReady\"\u003E";
pug_mixins["slider"](
          {
            model:     'component.model.anzahl_tage',
            htmlClass: false
          },
          'neo.preCheckout.foodVoucher.wizardConfig.slider'
        );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"component.essenscheckType == component.VSP_CONSTANTS.ESSENSCHECK_TYPE.PAPER.id\"\u003E\u003Ca class=\"button button-primary\" href=\"https:\u002F\u002Fapp.sodexo.de\u002Fmysodexo?productId=RK0001\" target=\"_blank\"\u003EAkzeptanzstellen\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cspan bind-html-compile=\"component.display.values.inner_html\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fadvantage-form\u003E\u003C\u002Fcheckout-values-step\u003E\u003Ccheckout-summary-step is-open=\"'checkout' === component.box.step\" can-see-next=\"component.isAllowedCheckout()\" can-click-next=\"component.canCheckout()\" on-click-next=\"component.startCheckout()\" on-back-click=\"component.backToDashboard()\" img=\"component.display.checkout.img\" video=\"component.display.checkout.video\"\u003E\u003Ccheckout-description bind-html-compile=\"component.display.checkout.inner_html || component.display.checkout.description\"\u003E\u003C\u002Fcheckout-description\u003E\u003C\u002Fcheckout-summary-step\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "JSON" in locals_for_with ?
        locals_for_with.JSON :
        typeof JSON !== 'undefined' ? JSON : undefined, "sliderOptions" in locals_for_with ?
        locals_for_with.sliderOptions :
        typeof sliderOptions !== 'undefined' ? sliderOptions : undefined));
    ;;return pug_html;};
module.exports = template;