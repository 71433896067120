<h1 class='title'>
    {{selectedCategoryName$ | async}}
</h1>

<mat-grid-list [cols]="reactiveCols"
               (window:resize)="onResize($event)"
               gutterSize="0px"
               rowHeight="245px">
    <mat-grid-tile *ngFor="let retailer of retailers$ | async"
                   vnRandomAnimate>
        <vn-benefit-card [retailer]="retailer">
        </vn-benefit-card>
    </mat-grid-tile>
</mat-grid-list>