import {Inject, Injectable} from '@angular/core';

const POPUPS = {
  TOC: 'tocState',
  USERLANE: 'userlaneState'
}
const TOC_STATES = {
  ACCEPTED: 'accepted'
}
const VIDEO_STATES = {
  WATCHED: 'watched'
}
const USERLANE_ATTRS = {
  STORAGE_KEY: 'userlane-assistant-context-32390',
  BLOCK_STATE: 'shouldWait'
}

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    @Inject('Made') private made: any,
    @Inject('filmService') private filmService: any,
    @Inject('customerService') private customerService: any
  ) { }

  public async processDashboardVideo(
    key: string,
    clicker: object
  ): Promise<void> {

    // TODO: enable video after discussion
    this.allowLoadingUserlane(); return;

    const isDashboardVideoEnabled =
      await this.customerService.isDashboardVideoEnabled();

    if (isDashboardVideoEnabled) {
      if (this.canWatchVideo(key)) {
        this.filmService.autoOpen(key, clicker);
        this.setOnetimeVideoWatched(key);
      }
    } else {
      this.allowLoadingUserlane();
    }
  }

  public processModuleVideo(
    key: string,
    clicker: object
  ): void {

    // TODO: enable video after discussion
    return;

    if (this.canWatchVideo(key)) {
      this.filmService.autoOpen(key, clicker);
      this.setOnetimeVideoWatched(key);
    }
  }

  private canWatchVideo(key: string): boolean {

    return this.getTocAccepted() && !this.getOnetimeVideoWatched(key);
  }

  public canLoadUserlane(): boolean {

    return this.getTocAccepted() && this.isUserlaneAllowed();
  }

  private getTocAccepted(): boolean {

    let isTocAccepted =
      localStorage.getItem(POPUPS.TOC) === TOC_STATES.ACCEPTED;

    if (!isTocAccepted) {
      isTocAccepted = !!this.made.user.toc;
    }

    return isTocAccepted;
  }

  public setTocAccepted(): void {

    localStorage.setItem(POPUPS.TOC, TOC_STATES.ACCEPTED);

    window.location.reload();
  }

  private getOnetimeVideoWatched(key: string): boolean {

    let isOnetimeVideoWatched =
      localStorage.getItem(key) === VIDEO_STATES.WATCHED;

    if (!isOnetimeVideoWatched) {
      if (!!this.made.user.valuenet &&
        !!this.made.user.valuenet.onetime) {
        isOnetimeVideoWatched = !!this.made.user.valuenet.onetime[key]
      }
    }

    return isOnetimeVideoWatched;
  }

  private setOnetimeVideoWatched(key: string): void {

    localStorage.setItem(key, VIDEO_STATES.WATCHED);
  }

  private getUserlaneState(): string {

    return String(localStorage.getItem(POPUPS.USERLANE));
  }

  private setUserlaneState(state: string): void {

    localStorage.setItem(POPUPS.USERLANE, state);
  }

  public allowLoadingUserlane(): void {

    localStorage.removeItem(POPUPS.USERLANE);
  }

  public disallowLoadingUserlane(): void {

    this.setUserlaneState(USERLANE_ATTRS.BLOCK_STATE);
  }

  private isUserlaneAllowed(): boolean {

    return this.getUserlaneState() !== USERLANE_ATTRS.BLOCK_STATE;
  }
}
