import support_html from '../../modules/support/support.pug';
import header_main_html from '../../helpers/header.main.pug';

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('inApp.support', {
        views: {
          main: {
            template: support_html,
            controller: 'SupportController'
          },
          header: {
            template: header_main_html
          }
        }
      })
      ;
  }]);
