var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_mixins["summary-box"] = pug_interp = function(selectedCompany, save, disabled, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"summary-box isTransparent\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns\"\u003E";
if (selectedCompany) {
pug_html = pug_html + "\u003Cspan class=\"summary-box__title\"\u003E\u003Cstrong\u003EFirma:\u003C\u002Fstrong\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' '+ selectedCompany) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"hide-for-small-only\"\u003E&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns text-right pull-right\"\u003E";
block && block();
if (save) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"button button-primary isOutline\""+pug.attr("ng-click", save, true, true)+pug.attr("ng-disabled", disabled, true, true)+pug.attr("data-cy", cyData, true, true)) + "\u003ESpeichern\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};



















globals = {}
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-12 columns\"\u003E\u003Cdl class=\"tabs\"\u003E\u003Cdd ui-sref-active=\"active\"\u003E\u003Ca ui-sref=\"inApp.cockpit.bike({customerId: BikeCtrl.customerId})\"\u003EAngaben\u003C\u002Fa\u003E\u003C\u002Fdd\u003E\u003Cdd ui-sref-active=\"active\"\u003E\u003Ca ui-sref=\"inApp.cockpit.bikeContracts({customerId: BikeCtrl.customerId})\"\u003EVerträge\u003C\u002Fa\u003E\u003C\u002Fdd\u003E\u003C\u002Fdl\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row tabs-content\"\u003E";
pug_mixins["form"].call({
block: function(){
pug_mixins["summary-box"]('{{ BikeCtrl.customer.name }}', 'BikeCtrl.save()', 'BikeCtrl.working || !bike.$valid || BikeCtrl.customerConfig.fixated');
pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel\" overlay=\"BikeCtrl.working\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EAllgemein\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E\u003C!-- ATTENTION: If changes have to made to operate this field do this also in frontend\u002Fsrc\u002Fmodules\u002Fneo\u002Fberater\u002Fmodules\u002Fcomponents\u002Fbike\u002F_allgemein.pug--\u003E";
pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index9 = 0, $$l = $$obj.length; pug_index9 < $$l; pug_index9++) {
        var option = $$obj[pug_index9];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index9 in $$obj) {
      $$l++;
      var option = $$obj[pug_index9];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E";
pug_mixins["checkbox"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.onTop","title": "on Top","labelHtmlClass": "switchbox-float-label"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E\u003Cdiv class=\"form-group\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.foerderungAg","title": "Förderung durch AG €","required": pug.escape(true),"validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen","vnMin":"Der minimale Wert beträgt {{ 0 | currency }}","vnMax":"Der maximale Wert beträgt {{ 500 | currency }}"},"attributes": {"vn-currency":"true","vn-max":500,"vn-min":0}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.traegerVersicherung","title": "Versicherung premium trägt","titleMap": [{"value":"ag","name":"Arbeitgeber"},{"value":"umwandlung_an","name":"Umwandlung AN"}],"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.traegerVersicherungPremiumPlus","title": "Erweiterung Versicherung premium+","titleMap": [{"value":"keine","name":"keine"},{"value":"ag","name":"Arbeitgeber"},{"value":"an_frei","name":"Arbeitnehmer Freiwillig"}],"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.inspektion","title": "Inspektion","titleMap": [{"value":"keine","name":"keine"},{"value":"ag","name":"Arbeitgeber"},{"value":"an_frei","name":"Arbeitnehmer freiwillig"}],"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Cdiv class=\"small-9 columns component-col\"\u003E\u003Clabel for=\"ausfallversicherungTod\"\u003E\u003Cspan class=\"component-name\"\u003ERatenausfall Premium (Krankheit & Tod)\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"small-3 columns text-right\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "ausfallversicherungTod","key": "BikeCtrl.customerConfig.neo_config.bike.ausfallversicherungTod","labelHtmlClass": "switchbox-float-label","ng-change": "BikeCtrl.updateRatenausfallPremium()"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Cdiv class=\"small-9 columns component-col\"\u003E\u003Clabel for=\"ausfallversicherungKuendigung\"\u003E\u003Cspan class=\"component-name\"\u003ERatenausfall Premium plus (Kündigung & Elternzeit)\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"small-3 columns text-right\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"readonly": "!BikeCtrl.customerConfig.neo_config.bike.ausfallversicherungTod","id": "ausfallversicherungKuendigung","key": "BikeCtrl.customerConfig.neo_config.bike.ausfallversicherungKuendigung","labelHtmlClass": "switchbox-float-label"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Cdiv class=\"small-9 columns component-col\"\u003E\u003Clabel for=\"rechtschutzHaftpflicht\"\u003E\u003Cspan class=\"component-name\"\u003ERechtschutz & Haftpflicht\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"small-3 columns text-right\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"readonly": "!BikeCtrl.customerConfig.neo_config.bike.ausfallversicherungTod","id": "rechtschutzHaftpflicht","key": "BikeCtrl.customerConfig.neo_config.bike.rechtschutzHaftpflicht","labelHtmlClass": "switchbox-float-label"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.bruttoNettoRate","title": "Brutto oder Nettorate in der Umwandlung","titleMap": [{"value":"brutto","name":"Brutto (nicht vorsteuerabzugsberechtigt)"},{"value":"netto","name":"Netto (vorsteuerabzugsberechtigt)"}],"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns-small-12\"\u003E\u003Cp\u003E\u003Cstrong\u003EInformationen zum Absicherungsumfang finden Sie hier\u003C\u002Fstrong\u003E\u003Cbr\u003E\u003Ca href=\"https:\u002F\u002Fwww.bikeleasing-service.de\u002Fvertragswerk\u002Fversicherungshandbuch\" target=\"_blank\"\u003EVersicherungshandbuch Bikeleasing\u003C\u002Fa\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel\" overlay=\"BikeCtrl.working\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EIntern\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E";
pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index15 = 0, $$l = $$obj.length; pug_index15 < $$l; pug_index15++) {
        var option = $$obj[pug_index15];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index15 in $$obj) {
      $$l++;
      var option = $$obj[pug_index15];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.agCode","title": "Bikeleasing Firmen Code","readonly": "!BikeCtrl.iAmSpecialAdvisor","validationMessages": {"pattern":"Bitte Eingabe prüfen"},"attributes": {"minlength":4,"maxlength":4,"ng-pattern":"\u002F^[A-Z0-9]{4}$\u002F","oninput":"this.value = this.value.toUpperCase()"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.bonitaetsrahmen","title": "Bonitätsrahmen","required": pug.escape(true),"readonly": "!BikeCtrl.iAmSpecialAdvisor","validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen","vnMin":"Der minimale Wert beträgt {{ 0 | currency }}"},"attributes": {"vn-currency":"true","vn-min":"0"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.minbestellwert","title": "Mindestbestellwert","required": pug.escape(true),"readonly": "!BikeCtrl.iAmSpecialAdvisor","validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen","vnMin":"Der minimale Wert beträgt {{ 0 | currency }}","vnMax":"Der maximale Wert beträgt {{ 1500 | currency }}"},"attributes": {"vn-currency":"true","vn-max":"1500","vn-min":"0"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.maxwert","title": "Maximalwert","required": pug.escape(true),"readonly": "!BikeCtrl.iAmSpecialAdvisor","validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen","vnMin":"Der minimale Wert beträgt {{ 0 | currency }}","vnMax":"Der maximale Wert beträgt {{ 15000 | currency }}"},"attributes": {"vn-currency":"true","vn-max":"15000","vn-min":"0"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.leasingfaktor","title": "Leasingfaktor","required": pug.escape(true),"readonly": "!BikeCtrl.iAmSpecialAdvisor","validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen","vnMin":"Der minimale Wert beträgt 0,00 %","vnMax":"Der maximale Wert beträgt 100,00 %"},"attributes": {"vn-percent":true,"vn-max":"1","vn-min":"0"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "BikeCtrl.customerConfig.neo_config.bike.kauffaktor","title": "Kauffaktor","required": pug.escape(true),"readonly": "!BikeCtrl.iAmSpecialAdvisor","validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen","vnMin":"Der minimale Wert beträgt 0,00 %","vnMax":"Der maximale Wert beträgt 100,00 %"},"attributes": {"vn-percent":true,"vn-max":"1","vn-min":"0"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"name": "bike","id": "bikeForm"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;