const $inject = [
  '$scope',
  '$state',
  '$timeout',
  'authenticationService',
  'NotificationService',
  'administrationService',
  'Made'
];
export default class CockpitDocumentTemplatesController {

  constructor(
    $scope,
    $state,
    $timeout,
    authenticationService,
    NotificationService,
    administrationService,
    Made
  ) {

    Object.assign(this, {
      $scope,
      $state,
      $timeout,
      authenticationService,
      NotificationService,
      administrationService,
      Made
    });

    this.customer_id = parseInt($state.params.customerId);
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
  }
}
CockpitDocumentTemplatesController.$inject = $inject;
