angular
  .module('VSPApp')
  .directive('scrolledHeader', ['$timeout', '$rootScope', '$transitions', function ($timeout, $rootScope, $transitions) {
    return {
      restrict: 'A',
      link: function ($scope) {

        var header = angular.element(document.querySelector('header.sticky')),
          headerHeight = header[0].offsetHeight,
          body = angular.element(document.querySelector('.main-section'))[0];

        $timeout(function () {
          angular
            .element(document.querySelector('.main-section'))
            .on('scroll', handleScroll);
        }, 500);

        $scope.$on('$destroy', function () {
          angular
            .element(document.querySelector('.main-section'))
            .off('scroll', handleScroll);
        });

        $transitions.onSuccess({}, function () {

          $timeout(function () {
            angular
              .element(document.querySelector('.main-section'))
              .off('scroll', handleScroll);

            angular
              .element(document.querySelector('.main-section'))
              .on('scroll', handleScroll);
          }, 500);
        });

        // $rootScope.$on( '$stateChangeSuccess', function () {
        //
        //   $timeout( function () {
        //     angular
        //       .element( document.querySelector( '.main-section' ) )
        //       .off( 'scroll', handleScroll );
        //
        //     angular
        //       .element( document.querySelector( '.main-section' ) )
        //       .on( 'scroll', handleScroll );
        //   }, 500 );
        // } );

        function handleScroll() {
          body = angular.element(document.querySelector('.main-section'))[0];
          if (body.scrollTop > headerHeight) {
            header.addClass('darkened');
          } else {
            header.removeClass('darkened');
          }
        }
      }
    };
  }]);
