angular
  .module('VSPApp')
  .directive('vnReverseSign', [function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      priority: 1,
      link: function (scope, ele, attr, ngModel) {

        ngModel.$parsers.push((viewValue) => {


          let modelValue = String(viewValue);

          if (0 === modelValue.length) {
            modelValue = 0;
          } else {
            modelValue = parseFloat(modelValue);
          }

          return -modelValue;
        });

        ngModel.$formatters.push((value) => {
          return isNaN(value) ? '' : -value;
        });

        ngModel.$validators.numberOrEmpty = (modelValue) => {
          return !isNaN(modelValue);
        };
      }
    };
  }]);
