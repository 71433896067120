import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  DticketOrderSteuerfreiDialogComponent
} from './dialogs/dticket-order-steuerfrei-dialog/dticket-order-steuerfrei-dialog.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {FormElementsModule} from "../form-elements/form-element.module";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {DticketOrderService} from "./services/dticket-order.service";
import {
  DticketOrderConfigurationComponent
} from "./components/cockpit/dticket-order-configuration/dticket-order-configuration.component";
import {
  DTicketSearchEmployeesComponent
} from "./components/administration/dticket-search-employees/dticket-search-employees.component";
import { DticketOrderHistoryComponent } from './components/meineakte/dticket-order-history/dticket-order-history.component';
import { DticketOrderVesputiPdfDialogComponent } from './dialogs/dticket-order-user-preferences-dialog/dticket-vesputi-pdf-component/dticket-order-vesputi-pdf-dialog.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { DticketOrderActivateDialogComponent } from './dialogs/dticket-order-user-preferences-dialog/dticket-order-activate-component/dticket-order-activate-dialog.component';
import { DticketOrderSharedEmployeeDataComponent } from './dialogs/dticket-order-user-preferences-dialog/dticket-order-shared-employee-data/dticket-order-shared-employee-data.component';

const services = [
  DticketOrderService,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FormElementsModule,
    UIRouterUpgradeModule.forChild(),
    MatCheckboxModule,
  ],
  exports: [],

  declarations: [
    DTicketSearchEmployeesComponent,
    DticketOrderConfigurationComponent,
    DticketOrderSteuerfreiDialogComponent,
    DticketOrderHistoryComponent,
    DticketOrderVesputiPdfDialogComponent,
    DticketOrderActivateDialogComponent,
    DticketOrderSharedEmployeeDataComponent
  ],
  providers: [
    ...services
  ]
})
export class DticketOrderModule {}
