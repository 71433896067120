<mat-card>
  <mat-card-content *ngIf="data">
    <div>
        <span>Restbetrag Bonus:</span>
        <span>{{ data.projectRest | currency : "EUR" }}</span>
    </div>
    <div>
        <span>Bonus Wert:</span>
        <span>{{ getSumApprovedAmounts() | currency : "EUR" }}</span>
    </div>
    <div>
        <span>Restbetrag Komponente:</span>
        <span>{{ data.componentRest | currency : "EUR" }}</span>
    </div>

    <ul>
      <li *ngFor="let request of data.cashbackRequests" class="{{ request.state }}" >
        <div class="title" [ngClass]="{'open': request.id === requestId}" (click)="toggleRow(request.id)">
            <span>{{ mapComponentName(data.bonusType) }} - {{ request.receiptDate | date }}</span>
            <span class="status">{{ cashbackStatusLabels[request.state] }}</span>
        </div>
        <div class="content" [ngClass]="{'open': request.id === requestId}">
            <div class="actions" *ngIf="isRequestEditable(request)">
              <button mat-raised-button
                    class="approve"
                    (click)="navigateTo('mpp', 66279)"
                    *ngIf="data.bonusType == 'egeschenk'"
              >
                Zu PC
              </button>
              <button mat-raised-button
                      class="approve"
                      (click)="editRequest(request)"
                      *ngIf="!request.is_editing"
              >
                Edit
              </button> 
            </div>
            <div class="info">
              <div>
                <span>Belegdatum:</span>
                <span>{{ request.receiptDate | date }}</span>
              </div>
              <div>
                <span>Gültig ab:</span>
                <span>{{ request.receiptValidFrom | date }}</span>
              </div>
              <div>
                <span>Gültigkeitsdauer:</span>
                <span>{{ getPeriod(request) }}</span>
              </div>
              <div>
                <span>Belegwert:</span>
                <strong>{{ request.requestedValue | currency: 'EUR'}}</strong>
              </div>
              <div></div>
              <div *ngIf="request.approvedValue">
                <span>Akzeptiert:</span>
                <strong>{{ request.approvedValue | currency: 'EUR'}}</strong>
              </div>
            </div>
            <div class="files">
                <strong>Belege:</strong>
                <div *ngIf="request.files.length > 0">
                  <div *ngFor="let file of request.files"
                    class="file"
                    (click)="openFile(file.id)"
                  >
                      <mat-icon>insert_drive_file</mat-icon>
                      <p>{{ file.name }}</p>
                  </div>
                </div>
                <p *ngIf="request.files.length == 0">
                  Datei nicht gefunden - vielleicht in Akte gelöscht
                </p>
            </div>

            <form *ngIf="request.state === status.pending || request.is_editing" [formGroup]="formGroup">
                <vn-input formControlName='approvedValue'
                  placeholder='Nummer'
                  label="Akzeptiert"
                  [require]=true
                  type="number"
                >
                  <mat-error *ngIf="formGroup.get('approvedValue')?.hasError('required')">
                    Eingabe fehlt.
                  </mat-error>
                  <mat-error *ngIf="formGroup.get('approvedValue')?.hasError('min')">
                    Belegwert darf nicht negativ sein.
                  </mat-error>
                  <mat-error *ngIf="formGroup.get('approvedValue')?.hasError('max')">
                    Keine Erstattung möglich, da das verfügbare Bonus Budget nicht ausreicht.
                  </mat-error>
                </vn-input>
                <vn-input formControlName='invoiceNumber'
                  label="Rechnungsnummer">
                </vn-input>
                <vn-input formControlName='invoiceIssuer'
                  [autocompleteOptions]="invoiceIssuers!"
                  [autoCompleteMinChar]="3"
                  [autoCompleteMaxList]="10"
                  label="Rechnungssteller">
                </vn-input>
                <vn-input formControlName='comment'
                  placeholder='Text...'
                  label="Kommentar"
                  [multiLine]=true>
                </vn-input>
                <button mat-raised-button
                  class="approve"
                  (click)="approveRequest()"
                  [disabled]="!ableToApprove()"
                >Freigeben</button>
                <button mat-raised-button
                  class="decline"
                  (click)="declineRequest()"
                  [disabled]="!ableToDecline()"
                >Ablehnen</button>
                <button mat-raised-button
                  class="cancel"
                  (click)="cancelEditRequest(request)"
                  *ngIf="request.is_editing"
                >
                  Abbrechen
                </button>
            </form>
            <div class="reason"
              *ngIf="request.state !== status.pending && request.comment"
            >
                <strong>Kommentar:</strong>
                <div>{{ request.comment }}</div>
            </div>
        </div>
      </li>
    </ul>
  </mat-card-content>
</mat-card>
