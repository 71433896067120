/* eslint-disable no-console */
import {BONUS_STATES_BY_ID, BONUS_STATES_BY_NAME} from "../../../services/bonus/bonus_state";
const $inject = [
  '$scope',
  '$q',
  'lodash',
  'dialogService',
  'moment',
  'Made',
  '$timeout',
  '$window',
  'employeeService',
  'BonusService',
  'administrationService',
  'fileService',
  'mppService',
  'NotificationService',
  '$filter',
  'VSP_CONSTANTS',
  'NeoComponentsService',
  'PDFJS',
  '$http'
];
export default class BonusControlProcessController {
  constructor(
    $scope,
    $q,
    lodash,
    dialogService,
    moment,
    Made,
    $timeout,
    $window,
    employeeService,
    BonusService,
    administrationService,
    fileService,
    mppService,
    NotificationService,
    $filter,
    VSP_CONSTANTS,
    NeoComponentsService,
    PDFJS,
    $http
  ) {

    Object.assign(this, {
      $scope,
      $q,
      lodash,
      dialogService,
      moment,
      Made,
      $timeout,
      $window,
      employeeService,
      BonusService,
      administrationService,
      fileService,
      mppService,
      NotificationService,
      $filter,
      VSP_CONSTANTS,
      NeoComponentsService,
      PDFJS,
      $http
    });

    this.loading = {
      main: true,
      accepting: false
    };

    this.BONUS_STATES_BY_ID = BONUS_STATES_BY_ID;
    this.BONUS_STATES_BY_NAME = BONUS_STATES_BY_NAME;
    this.MPP_ORDER_STATUSES_BY_ID = this.VSP_CONSTANTS.MPP_ORDER_STATUS_BY_ID;
    this.MPP_ORDER_STATUSES_BY_NAME = this.VSP_CONSTANTS.MPP_ORDER_STATUS_BY_NAME;
    this.PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

    this.neo_basket = this.$scope.ngDialogData.neo_basket;
    if (this.neo_basket) {
      this.employee_id = this.neo_basket.employeeId;
    } else {
      this.employee_id = this.$scope.ngDialogData.employee_id;
      this.bonus_id = this.$scope.ngDialogData.bonus_id;
    }

    this.forms = {};
    this.control_checkbox = {
      'approved': false,
      'amendment': false
    };

    this.view_model = {
      customer_id: undefined,
      customer_address_name: undefined,
      employee_id: this.employee_id,
      employee_first_name: undefined,
      employee_last_name: undefined,
      component_name: undefined,
      component_status: undefined,
      contract_value: undefined,
      dates: undefined,
      contract_full_value: undefined,
      documents: [],
      order_process_exists: false,
      is_order_process_in_valid_state: false,
      order_process_status_name: undefined,
      sent_for_delivery_date: undefined,
    };

    let operation = {};
    if (this.neo_basket) {
      operation = this.loadForNeoComponent();
    } else {
      operation = this.loadForBonus();
    }

    operation.finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  _setViewModel() {
    this.view_model.customer_id = this.customer_id;
    this.view_model.customer_address_name = this.employee.employer_address.name;
    this.view_model.employee_first_name = this.employee.firstname;
    this.view_model.employee_last_name = this.employee.lastname;
    this.view_model.order_process_exists = this.order_process_exists;
    if (this.order_process_exists) {
      this.view_model.order_process_status_name = this.MPP_ORDER_STATUSES_BY_ID[this.order_process_state_id]['name'];
    }
    this.view_model.is_order_process_in_valid_state = this.is_order_process_in_valid_state;
  }

  setViewModelForBonus() {
    this._setViewModel();
    this.view_model.component_name = this.BONUS_COMPONENTS_BY_NAME[this.BonusService.getBonusComponentName(this.bonus)].display.title;
    this.view_model.component_status_display = this.BONUS_STATES_BY_ID[this.bonus.state]['display'];
    this.view_model.contract_value = angular.isNumber(this.bonus.checkout_config.per_month_value) ? this.$filter('currency')(this.bonus.checkout_config.per_month_value) : '-';
    this.view_model.dates = this.$filter('date')(this.getComponentDisplayStartDate(this.bonus), 'dd.MM.yyyy') + ' - ' + this.$filter('date')(this.getComponentDisplayEndDate(this.bonus), 'dd.MM.yyyy');
    this.view_model.contract_full_value = this.$filter('currency')(this.bonus.checkout_config.value);
    this.sent_for_delivery_date = this.getDefaultSentForDeliveryDate();
    this.today = this.moment().utc().set({ millisecond: 0, second: 0, minute: 0, hour: 0 });
  }

  async setViewModelForNeoComponent() {
    this._setViewModel();
    this.view_model.component_name = this.neo_component.title;
    this.view_model.component_status_display = this.neo_component.state.display;
    this.view_model.contract_value = this.$filter('currency')(this.neo_component.per_month_value);
    if (this.neo_component.getContractStartDate()) {
      this.view_model.dates = this.neo_component.getContractStartDate('DD.MM.YYYY') + ' - ' + this.neo_component.getContractEndDate('DD.MM.YYYY');
    } else {
      this.view_model.dates = '-';
    }
    this.view_model.contract_full_value = this.$filter('currency')(this.neo_component.total_brutto);
    this.sent_for_delivery_date = this.getDefaultSentForDeliveryDate();
    this.today = this.moment().utc().set({ millisecond: 0, second: 0, minute: 0, hour: 0 });
    this.view_model.documents = await this.neo_component.getComponentFiles();
  }

  async loadForNeoComponent() {
    await this.loadPCComponent();
    await this.getPersonalInfo();
    await this.getOrderProcessInfo();
    await this.setViewModelForNeoComponent();

  }

  async loadForBonus() {
    await this.getBonus();
    if (![this.BONUS_STATES_BY_NAME['control_start']['id'], this.BONUS_STATES_BY_NAME['first_check']['id']].includes(this.bonus.state)) {
      this.$scope.confirm();
      return;
    }

    await this.getBonusComponentsMap();
    await this.getEmployeeBonusFiles([`bonus_id-${this.bonus._id}`], this.employee_id);
    await this.getPersonalInfo();
    await this.getOrderProcessInfo();
    await this.generateContract();
    this.setViewModelForBonus();
  }

  async getOrderProcessInfo() {
    this.order_process_exists = false;
    this.is_order_process_in_valid_state = false;
    if (this.lodash.get(this.bonus, 'checkout_config.cart._id')) {
      this.basket = await this.mppService.getCartById(this.bonus.checkout_config.cart._id);
    } else if (this.neo_component) {
      this.basket = this.neo_component.basket;
    }

    if (this.basket) {
      this.order_process_exists = !!this.lodash.get(this.basket, 'order_process');
      this.order_process_state_id = this.lodash.get(this.basket, 'order_process.state_id');
      this.is_order_process_in_valid_state = this.order_process_state_id === this.MPP_ORDER_STATUSES_BY_NAME['order_created_in_retailer']['id'];
    }
  }

  async loadPCComponent() {
    let pc_components = await this.NeoComponentsService.getPCComponent({ basket_id: this.neo_basket._id });
    this.neo_component = pc_components[0];
  }

  async getPersonalInfo() {
    this.employee = await this.administrationService.getEmployeePersonalInfo(this.employee_id);
    this.customer_id = this.employee.customer_id;
  }

  getBonus() {
    // validations are not needed
    return this.BonusService.getBonusesForEmployee({
      customer_id: this.customer_id,
      employee_id: this.employee_id,
      bonus_id: this.bonus_id,
      to_check_future_checkouts: true,
      to_check_previous_checkouts: true,
      to_standalone_other_bonuses: true
    }).then(res => {
      this.bonus = res;
    });
  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }

  calculateBudgetDisplay(employee_bonus_budget, display, bonus_payment_type_id) {
    return this.BonusService.calculateBudgetDisplay(employee_bonus_budget, display, bonus_payment_type_id);
  }

  getEmployeeBonusFiles(bonus_tags) {
    return this.BonusService.getFilesForBonuses(bonus_tags).then(data => {
      let files = data.filter(file => !file.meta.tags.includes('audit_trail'));
      this.lastUploadedBonusDocument = files.slice(-1)[0];
      this.bonus_files = files;
      this.view_model.documents = files;
    });
  }

  getComponentDisplayStartDate(bonus) {
    if (bonus) {
      return this.BonusService.getComponentDisplayStartDate(bonus);
    }
  }

  getComponentDisplayEndDate(bonus) {
    if (bonus) {
      return this.BonusService.getComponentDisplayEndDate(bonus);
    }
  }

  async accept() {

    if (!this.canAccept()) {
      this.NotificationService.error('Can not APPROVE this bonus');
      return;
    }

    this.$timeout(() => {
      this.loading.accepting = true;
    });

    let promises = [];

    if (this.control_checkbox.approved) {
      if (this.neo_component) {
        promises.push(
          this.neo_component.cp_approve({ sent_for_delivery_date: this.sent_for_delivery_date })
        );

      } else {
        if (this.order_process_exists) {
          promises.push(
            this.mppService.markForDelivery({ 'basket_id': this.basket._id, 'sent_for_delivery_date': this.sent_for_delivery_date })
          );
        }

        promises.push(
          this.BonusService.administrationBonusApprove(this.bonus_id)
        );
      }

    } else if (this.control_checkbox.amendment) {
      if (this.neo_component) {
        promises.push(
          this.neo_component.cp_amend({ comment: this.forms.commentForm.comment })
        );
      } else {
        promises.push(
          this.BonusService.administrationBonusAmmend(this.bonus_id, this.forms.commentForm.comment)
        );
      }
    }

    this.$q.all(promises).then(() => {
      this.$timeout(() => {
        this.loading.accepting = false;
      });
      this.$scope.confirm({});
    });
  }

  canAccept() {
    if (this.order_process_exists) {
      return this.isFormValid() && (
        (!this.is_order_process_in_valid_state && !this.control_checkbox.approved) ||
        (this.is_order_process_in_valid_state && this.sent_for_delivery_date)
      );
    }

    return this.isFormValid();
  }

  async close() {
    this.$scope.confirm();
  }

  getBonusComponentsMap() {
    return this.BonusService.getAvailableBonusComponents().then(
      (res) => {
        this.BONUS_COMPONENTS_BY_NAME = res.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
      });
  }

  toggleBonusDecision(action) {
    if (action === 'approve') {
      this.control_checkbox.approved = true;
      this.control_checkbox.amendment = false;
    } else {
      this.control_checkbox.approved = false;
      this.control_checkbox.amendment = true;
    }
  }

  async generateContract() {

    this.$timeout(() => {
      this.loading.main = true;
    });

    if (this.lastUploadedBonusDocument) {
      // console.log("mongo id", this.lastUploadedBonusDocument._id);

      let contractUrl = await this.fileService.getDocumentUrl(this.lastUploadedBonusDocument._id);
      // console.log("URL", contractUrl);
      // this.contract = await this.fileService.getDocumentFromUrl({
      //   file_url: contractUrl
      // });


      let request = {
        method: 'GET',
        responseType: 'blob',
        url: contractUrl,
      };

      let res = await this.$http(request);
      console.log("RES", res);

      this.loadingTask = this.PDFJS.getDocument({
        data: new Uint8Array(await res.data.arrayBuffer())
      });
      await this.showPDF();
    }

    this.$timeout(() => {
      this.loading.main = false;
    });

  }

  async openContract() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    let res = await this.getDocument();
    this.$window.open(res.response, '_blank');
    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async getDocument() {
    if (this.bonus) {
      return await this.BonusService.generateBonusCheckoutContractByBonusId(this.employee_id, this.bonus_id);
    }

    return await this.neo_component.generateContract();
  }
  isFormValid() {
    if (this.control_checkbox.approved === true) {
      return true;
    } else if (this.control_checkbox.amendment === true && this.forms.commentForm.$valid) {
      return true;
    } else {
      return false;
    }
  }

  getDefaultSentForDeliveryDate() {
    return this.mppService.getDefaultSentForDeliveryDate(0);
  }

  async showPDF() {

    let that = this;

    this.loadingTask.promise.then(function (pdf) {
      that.$timeout(() => {
        let viewer = document.getElementById('pdf-viewer');


        for (let page = 1; page <= pdf.numPages; page++) {
          pdf.getPage(page).then(function (page) {

            let scale = 1.1;
            let viewport = page.getViewport({ scale: scale });

            // Prepare canvas using PDF page dimensions
            let canvas = document.createElement('canvas');
            canvas.className = 'pdf-page-canvas';
            viewer.appendChild(canvas);

            let context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            let renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            let renderTask = page.render(renderContext);

            renderTask.promise.then(function () {
              console.log('Page rendered');
            });
          });
        }
      });

    }, (reason) => {
      console.log("REASON", reason)
      // PDF loading error
      // rarely to happen, but if it we still have the digital signiture
      this.NotificationService.error("Something went wrong during PDF visualization");
    })
  }
}
BonusControlProcessController.$inject = $inject;
