angular
  .module('VSPApp')
  .controller('vcpMitarbeiterController', [
    '$scope',
    'vcpModel',
    'SpecsService',
    'employeeService',
    '$timeout',
    function (
      $scope,
      vcpModel,
      SpecsService,
      employeeService,
      $timeout
    ) {
      SpecsService
        .schema('arbeitnehmerVCP')
        .then(schema => {
          $scope.schema = schema
        })
        .finally(()=>{
          $timeout(()=>{});
        });

      var selectObj = [];

      vcpModel.cities.forEach(function (city) {
        selectObj.push({
          id: parseInt(city.id),
          name: city.name + ' - ' + city.strasse + ' ' + city.plz + ' ' + city.ort
        });
      });

      $scope.form = SpecsService.form('arbeitnehmerVCP');
      $scope.form[2].items[2].titleMap = selectObj;

      $scope.mitarbeiter = {
        employee: {
          birthdate: '01.01.1970',
          entrydate: '01.01.2015'
        }
      };


      $scope.submit = function () {

        $scope.error = false;

        $scope.$broadcast('schemaFormValidate');

        var city = vcpModel.cityById($scope.mitarbeiter.employee.loc_id);

        if (!city) {
          $scope.error = 'Die Location konnte keiner Stadt zugeordnet werden. Bitte wählen Sie eine gültige Location';
          return false;
        }

        var employee = angular.extend({
          sex: false,
          loc_id: parseInt(city.id),
          customer_id: parseInt(city.custid),
          zip: city.plz,
          city: city.ort,
          street: city.strasse,
          street_nr: '',
          email: null,
          additional: ''
        }, $scope.mitarbeiter.employee);

        employeeService
          .createVCP(employee)
          .then(function () {

            vcpModel.getData(city);
            $scope.closeThisDialog(2);

          }, function (error) {
            console.log(error);
            $scope.error = 'Der Arbeitnehmer konnte nicht gespeichert werden.';
          });


        return true;
      };
    }]);
