angular
  .module('VSPApp')
  .config(['BrandingServiceProvider', (BrandingServiceProvider) => {
    // SCSS primary color, rgb(0,59,85)
    var primaryColor = '#003B55';
    // SCSS secondary color, rgb(4,150,216)
    var secondaryColor = '#0496D8';

    BrandingServiceProvider
      .add('edeka', {
        chart: {
          tooltipFillColor: primaryColor,
          colours: [
            { // chart first color
              fillColor: 'rgba(0,59,85,1)', // needs to be defined in rgba(color, 1) form or library opacity takes over
              strokeColor: primaryColor,
              pointColor: primaryColor,
              pointStrokeColor: '#fff',
              pointHighlightFill: '#fff',
              pointHighlightStroke: primaryColor
            },
            { // chart second color
              fillColor: 'rgba(4,150,216,1)',
              strokeColor: secondaryColor,
              pointColor: secondaryColor,
              pointStrokeColor: '#fff',
              pointHighlightFill: '#fff',
              pointHighlightStroke: secondaryColor
            }
          ]
        },
        circleProgress: {
          light: {
            color: '#7DDA4E',
            bgColor: '#406B7A',
            textColor: '#7DDA4E', // not used, maybe in the future
            containerBgColor: '#2A586C' // not used, maybe in the future
          },
          dark: {
            color: '#FBAA1E',
            bgColor: '#0E4359',
            textColor: '#FBAA1E', // not used, maybe in the future
            containerBgColor: '#2A586C' // not used, maybe in the future
          }
        }
      });
  }]);