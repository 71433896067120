import NeoComponentController from './NeoComponentController';

const $inject = [
  '$injector',
  '$scope',
  'WizardHandler',
  '$state',
  '$timeout',
  'vbmData',
  'moment',
  'lodash',
  'NeoComponentsService',
  'employeeService'
];
export default class NeoComponentControllerPC extends NeoComponentController {
  constructor(
    $injector,
    $scope,
    WizardHandler,
    $state,
    $timeout,
    vbmData,
    moment,
    lodash,
    NeoComponentsService,
    employeeService
  ) {
    super(
      $injector,
      $scope,
      WizardHandler,
      $state,
      $timeout,
      vbmData,
      moment,
      lodash,
      NeoComponentsService,
    );

    this.isLoaded = false;

    this.employeeService = employeeService;

    this.childInit = this.baseInitPromise.then(() => {
      return this.init();
    }).then(() => {
      return this.$timeout(() => {
        this.loading.child = false;

      }, 0);
    }).then(() => {
      this.isLoaded = true;
      return this.onLoadComplete();
    });
  }

  async onLoadComplete() {
    if (this.to_add_to_checkout_basket) {
      // add the component to the checkout
      this.checkout_basket.addComponent(this.neo_component);

      // complete the wizard
      this.WizardHandler.wizard().finish();
    }
  }

  async init() {
    this.to_add_to_checkout_basket = this.$state.params.to_add_to_checkout_basket;
    this.can_do_checkout = await this.neo_component.canDoCheckout();

    const vbmData = await this.vbmData.getResult(this.employee_id);

    let hasSalarySet = false;
    if (vbmData.before && vbmData.before.tax && vbmData.before.tax.brutto_gesamt !== 0) {
      hasSalarySet = true;
    }

    if (this.userService.iAmProxy()) {
      this.doesUserHasSalary = true;
    } else {
      this.doesUserHasSalary = hasSalarySet;
    }

  }

  enterShop() {
    this.mpp_data = {
      neo_component: this.neo_component
    };

    console.log("ENTER SHOP - ", this.mpp_data)

    this.$state.go('inApp.mpp.side.products.landing', {
      neo_data: this.mpp_data
    });
  }

  canEnterStepTwo() {
    return this.can_do_checkout && this.neo_component.is_available;
  }

  enterStepTwo() {
    this.enterShop();
  }
}
NeoComponentControllerPC.$inject = $inject;
