const $inject = [
  'Made',
  '$q',
  '$document',
  '$window',
  '$state',
  'csv'
]
export default class fileService {

  constructor(Made, $q, $document, $window, $state, csv, lodash) {

    this.Made = Made;
    this.$q = $q;
    this.$document = $document[0];
    this.$window = $window;
    this.csv = csv;
    this.lodash = lodash;
    this.$state = $state;

  }

  getUserBonusUploadedFiles(params) {
    return this.Made.request('rpc://valuenetdb/user/files/get_user_bonus_uploaded_files', params);
  }

  getUserFiles(userId = this.Made.user._id, extras) {
    extras = {...{session_id: '', valuenet_id: undefined, tags: undefined, for_neo_component: undefined}, ...extras};
    return this.Made.request('rpc://valuenetdb/user/files/getUploaded', {
      userId: userId.toString(),
      valuenet_id: extras.valuenet_id,
      session_id: extras.session_id.toString(),
      tags: extras.tags,
      for_neo_component: extras.for_neo_component
    });
  }

  getBikeleasingApprovalDoc(params) {
    return this.Made.request('rpc://valuenetdb/user/files/get_neo_bikeleasing_approval_doc', params);
  }

  getDocumentUrl(file_mongo_id) {
    return this.getAccessTokenForFile(file_mongo_id).then((token) => {
      let url = this.$state.href('blank.file', {id: file_mongo_id, token: token}, {absolute: true});
      return url;
    });
  }

  openDocument(file_mongo_id) {
    return this.getDocumentUrl(file_mongo_id).then(url => {
      // XXX HACK, should be fixed , port ...
      this.$window.open(url.replace('4200', '8080'), '_blank');
    });
  }

  getAccessTokenForFile(file_mongo_id) {
    return this.Made.request('rpc://valuenetdb/user/access/get_file_access_token', {
      file_mongo_id: file_mongo_id
    });
  }

  filter(files, tags) {

    return files.filter(file => tags.every(tag => file.meta.tags && (-1 !== file.meta.tags.indexOf(tag))));
  }

  getFilesByTags(tags, id = this.Made.user._id) {

    return this
      .getUserFiles(id)
      .then(files => this.filter(files, tags));
  }

  deleteFiles(idList) {
    return this.Made.request('rpc://valuenetdb/user/files/delete', {
      fileIdList: idList
    });
  }

  readFile(file, format = 'binary') {
    // Check for the various File API support.
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      // Great success! All the File APIs are supported.
    } else {
      alert('The File APIs are not fully supported in this browser.');
      return null;
    }

    let deferred = this.$q.defer();
    let reader = new FileReader();
    reader.onload = function (loadEvent) {
      deferred.resolve(loadEvent.target.result);
    };

    if (format == 'binary') {
      reader.readAsBinaryString(file);
    }

    if (format == 'string') {
      reader.readAsText(file);
    }

    if (format == 'data_array') {
      reader.readAsText(file);
      return deferred.promise.then((string_data) => {
        return this.csv.parse(string_data, ';');
      });
    }

    return deferred.promise;
  }

  getCarLeasingAuditTrailFile(basketId) {
    return this.Made.request('rpc://valuenetdb/user/files/get_files', {
      files_query: {
        'meta.tags': {
          '$all': ['audit_trail', 'car_leasing_basket_id-' + basketId]
        }
      }
    });
  }

  getDTicketAuditTrailFile(dTicketId) {
    return this.Made.request('rpc://valuenetdb/user/files/get_files', {
      files_query: {
        'meta.tags': {
          '$all': ['audit_trail', 'dticket_order_id-' + dTicketId]
        }
      }
    });
  }

  saveFile(content, keys, filename = 'filename.csv', type = 'csv') {
    // TODO: handle other format types
    // TODO: handle other content types
    // TODO: decide if template vars are attached to controller or to scope and stick with it

    // example: JSON
    // var blob = new Blob([angular.toJson(content, true)], {type: 'application/json'});

    let file = null;

    if (type === 'csv') {
      file = this.exportToCSV(content, keys);
      this.downloadFile(file, filename);
    }
  }

  /**
   * Used to transform an array of objects to csv string
   *
   * NOTE: use saveFile instead of this method
   */

  exportToCSV(contentItems, keys) {
    if (!contentItems.length) {
      return;
    }

    // let csvContent = "data:text/csv;charset=utf-8,";
    let csvContent = '';

    // headers
    keys = angular.isArray(keys) ? keys : Object.keys(contentItems[0]);
    csvContent += this.objectToCSVRow(keys);

    contentItems.forEach(contentItem => {
      csvContent += this.objectToCSVRow(contentItem, keys);
    });

    return csvContent;
  }

  isAuditTrail(file_tags) {
    return file_tags && file_tags.indexOf('audit_trail') !== -1;
  }


  isDigitallySignedDocument(file_tags) {
    if (file_tags.indexOf('yousign') !== -1 && !this.isAuditTrail(file_tags)) {
      return true;
    }
    return false;
  }

  openMatchingYouSignAuditTrail(procedure_id_tag, file_objects_array) {
    let audit_trail = file_objects_array.filter(file => this.isAuditTrail(file.meta.tags) && file.meta.tags.indexOf(procedure_id_tag) !== -1);
    if (audit_trail.length) {
      this.openDocument(audit_trail[0]['_id']);
    }
  }

  getProcedureIDFileTag(document) {
    return document.meta.tags.filter(tag => tag.includes('procedure_id')).toString();
  }

  downloadFile(file, filename, type = 'text/csv') {
    let blob = new Blob([file], {type: type});
    let blobUri = this.$window.URL.createObjectURL(blob);

    const link = this.$document.createElement('a');
    link.download = filename;
    link.href = blobUri;
    this.$document.getElementsByTagName('body')[0].appendChild(link);
    link.click();
    link.remove();
    this.$window.URL.revokeObjectURL(blobUri);
  }

  objectToCSVRow(dataObject, keys) {
    let dataArray = new Array;
    // if we are giving the header row only
    if (angular.isUndefined(keys) && angular.isArray(dataObject)) {
      keys = dataObject;
      dataObject = keys.reduce((acc, key) => {
        acc[key] = key;
        return acc;
      }, {});
    }
    // iterate over the keys to set them up
    for (let o of keys) {
      let innerValue = null;
      if (dataObject[o] !== null) {
        if (typeof dataObject[o] === 'object') {
          innerValue = JSON.stringify(dataObject[o]);
        } else if (typeof dataObject[o] === 'undefined') {
          innerValue = '';
        } else {
          innerValue = dataObject[o].toString();
        }
      }
      dataArray.push(innerValue);
    }

    return dataArray
      .map(d => d ? `"${d.replace(/"/g, '""')}"`: null)
      .join(';') + '\r\n';
  }
}

fileService.$inject = $inject;
