var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (cityClass) {
      pug_html = pug_html + "\u003Cdiv overlay=\"vcpModel.working\"\u003E\u003Cul class=\"biglist\" ng-if=\"vcpModel.clusterWithAntraege.length == 1\"\u003E\u003Cli ng-repeat=\"city in vcpModel.cities | irrelevantLocations as filteredLocations track by city.id\"\u003E" + (null == (pug_interp = require("./_city.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Cul class=\"biglist\" ng-if=\"vcpModel.clusterWithAntraege.length &gt; 1\"\u003E\u003Cli ng-repeat=\"cluster in vcpModel.clusterWithAntraege track by cluster.name\"\u003E\u003Cdiv class=\"solid-list clearfix\" ng-class=\"{freigegeben: cluster.sumFreigegeben == cluster.sumAntraege}\"\u003E\u003Ca ng-click=\"toggleCluster(cluster)\"\u003E\u003Cimg class=\"expander\" ng-src=\"\u002Fassets\u002Fimg\u002Ficon-{{ currentCluster == cluster.name ? &quot;up&quot;:&quot;down&quot;}}_white.png\"\u003E\u003Cspan class=\"city\"\u003E{{ cluster.name }}\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Cspan class=\"right stats1\"\u003E{{ cluster.numAntraege }} {{ 1 == cluster.numAntraege ? 'Antrag' : 'Anträge' }} - (\u003Cspan class=\"green\"\u003E{{ cluster.sumFreigegeben \u002F100  | currency }}\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = ' / ') ? "" : pug_interp)) + "\u003Cspan class=\"red\"\u003E{{ (cluster.sumAbgelehnt) \u002F100  | currency }}\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = ' / ') ? "" : pug_interp)) + "\u003Cspan\u003E{{ cluster.sumAntraege \u002F100  | currency }}\u003C\u002Fspan\u003E)\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cul class=\"biglist\" ng-show=\"currentCluster == cluster.name\"\u003E\u003Cli ng-repeat=\"city in cluster.locations track by city.id\"\u003E";
cityClass = 'white'
pug_html = pug_html + (null == (pug_interp = require("./_city.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "cityClass" in locals_for_with ?
        locals_for_with.cityClass :
        typeof cityClass !== 'undefined' ? cityClass : undefined));
    ;;return pug_html;};
module.exports = template;