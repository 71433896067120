const $inject = [
  '$scope',
  '$transitions',
  'StatesDEConstants',
  'SVCalculationConstants',
  'SteuerklassenConstants',
  'KVTypConstants',
  'BAVTypeConstants',
  'BAVParagraphConstants',
  'EinsparungsModellConstants',
  'ALVktgConstants',
  'VATypeConstants',
  'Made',
  'vbmData',
  'NotificationService',
  'employeeService'
];
export default class employeeBasicsController {

  constructor(
    $scope,
    $transitions,
    StatesDEConstants,
    SVCalculationConstants,
    SteuerklassenConstants,
    KVTypConstants,
    BAVTypeConstants,
    BAVParagraphConstants,
    EinsparungsModellConstants,
    ALVktgConstants,
    VATypeConstants,
    Made,
    vbmData,
    NotificationService,
    employeeService
  ) {

    this.$scope = $scope;
    this.$transitions = $transitions;
    this.StatesDEConstants = StatesDEConstants;
    this.SVCalculationConstants = SVCalculationConstants;
    this.SteuerklassenConstants = SteuerklassenConstants;
    this.KVTypConstants = KVTypConstants;
    this.BAVTypeConstants = BAVTypeConstants;
    this.BAVParagraphConstants = BAVParagraphConstants;
    this.EinsparungsModellConstants = EinsparungsModellConstants;
    this.ALVktgConstants = ALVktgConstants;
    this.VATypeConstants = VATypeConstants;
    this.Made = Made;
    this.vbmData = vbmData;
    this.NotificationService = NotificationService;
    this.employeeService = employeeService;

    this.employeeId = ($scope.ngDialogData && $scope.ngDialogData.employeeId) ||
      vbmData.employeeId ||
      Made.user.valuenet_id;


    this.employee = vbmData.employees[this.employeeId];

    if (!this.employee) {

      vbmData
        .getResult(this.employeeId)
        .then(() => {
          this.employee = vbmData.employees[this.employeeId];
        });
    }

    this.$transitions.onStart({},
      () => {
        $scope.closeThisDialog();
        return true;
      }
    );
    // $scope.$on( '$stateChangeStart', () => {
    //   $scope.closeThisDialog();
    //   return true;
    // } );

  }

  save(form) {

    if (!form.$valid) {
      return false;
    }

    this.saving = true;
    this.employee.data.parameters.brutto = parseFloat(this.employee.data.parameters.brutto)
    this.employeeService.get(this.employeeId, Date.now())
      .then((employee) => {
        this.vbmData
        .save(this.employeeId, 'base')
        .then(() => this.$scope.confirm(), error => this.NotificationService.default.checkoutSession(error))
          .finally(() => {
            this.saving = false;
            this.feedback = true;
            location.reload();
          });
      });
  }

}
employeeBasicsController.$inject = $inject;
