const $inject = [
  '$state',
  '$scope',
  '$transitions',
  'MppConstants',
  'mppService',
  'dialogService',
  'customerService',
  'NotificationService',
  'authenticationService'
];
export default class MPPAdministrationController {

  constructor(
    $state,
    $scope,
    $transitions,
    MppConstants,
    mppService,
    dialogService,
    customerService,
    NotificationService,
    authenticationService
  ) {

    Object.assign(this, {
      $state,
      $scope,
      $transitions,
      MppConstants,
      mppService,
      customerService,
      NotificationService
    });

    this.mppService = mppService;
    this.dialogService = dialogService;
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');

  }

  definalizeMpp(cart) {
    this.dialogService.cancelCheckoutMPP(cart.locked, cart.employeeId, cart._id);
  }

  getTrackingURL(tracking_id_object) {
    return this.mppService.getTrackingURL(tracking_id_object);
  }

  //This is duplicated in mppService and has to be refactored
  getAbsoluteUrl(url) {
    let protocols = ['https://', 'http://'];
    let abs_url_prefix = '//';

    let abs_url_response = '';

    if (typeof (url) == 'string') {
      protocols.forEach((protocol) => {
        if (url.startsWith(protocol)) {
          abs_url_response = url;
        }
      });

      if (!abs_url_response) {
        abs_url_response = abs_url_prefix + url;
      }
    }

    return abs_url_response;
  }

  storno(cart) {
    this.mppService.stornoBasket(cart).then(basket => cart.state = basket.state);
  }

  deleteCart(cart, ctrl) {
    this.dialogService
      .frageDialog('Durch das Zurücksetzen wird der gesamte Warenkorbinhalt entfernt. Fortfahren?', 'Warenkorb zurücksetzen')
      .then(
        () => {
          this.mppService.cockpitDelete(cart.employeeId, cart._id).then(result => {

            if (!result) {
              this.NotificationService.error('Fehlgeschlagen.');
            }
            this.$state.reload();
          });
        },
        () => {
        }
      );
  }


}
MPPAdministrationController.$inject = $inject;
