angular.module('VSPApp').controller('ListController', ['$scope', 'vcpModel',function ($scope, vcpModel) {

  // A-Z: true
  // Z-A: false
  $scope.sortASC = true;
  $scope.sortBy = 'name';

  // true: Aktiviert
  // false: Deaktiviert
  $scope.filter = {};

  $scope.getSortString = function (sortBy, asc) {
    if (sortBy === 'name' && asc) {
      return 'Name: A - Z';
    } else if (sortBy === 'name' && !asc) {
      return 'Name: Z - A';
    } else if (sortBy === 'id' && asc) {
      return 'Id: 0-9';
    } else if (sortBy === 'id' && !asc) {
      return 'Id: 9-0';
    }
  };

  $scope.vcpModel = vcpModel;

}]);
