const $inject = ['$scope'];
export default class registrationTocController {

  constructor($scope) {

    $scope.close = () => {
      $scope.confirm(true);
    };

  }
}
registrationTocController.$inject = $inject;
