import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { TocCheckboxDialogComponent, TocDialogData } from '../toc-checkbox-dialog/toc-checkbox-dialog.component';

@Component({
  selector: 'vn-toc-checkbox',
  templateUrl: './toc-checkbox.component.html',
  styleUrls: ['./toc-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TocCheckboxComponent
    }
  ]
})
export class TocCheckboxComponent implements OnInit {

  @Input()
  public switchKey!: string;

  @Input()
  public buttonKey!: string;

  @Input()
  public tocLabel = '-';

  @Input()
  public tocTitle = '-';

  @Input()
  public tocText = '-';

  @Input()
  public isDisabled = false;

  @Input()
  public readonlyDialog = false;


  @ViewChild(MatSlideToggle)
  slider!: MatSlideToggle;

  private formControlValue!: boolean;
  public get value() {
    return this.formControlValue;
  }

  private formDisabled = false;
  private touched = false;

  public onTouched = () => { };
  public onChange = (_: boolean) => { };

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  public toggle(value: boolean) {
    if (value) {
      this.openTocDialog();
    } else {
      this.writeValue(undefined!);
      this.onChange(undefined!);
    }
  }

  public openTocDialog() {
    const dialogWidth = '60%';
    const dialogData: TocDialogData = {
      text: this.tocText,
      title: this.tocTitle,
      buttonKey: this.buttonKey,
      readonlyDialog: this.readonlyDialog
    };

    this.dialog.open(
      TocCheckboxDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.writeValue(true);
            this.onChange(true);
          } else {
            this.slider.toggle();
          }
        }
      );
  }

  public writeValue(value: boolean) {
    this.formControlValue = value;
  }

  public registerOnChange(onChange: (_: boolean) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // != <tag [disabled]='true'> -> use public isDisable instead
  // excludes formControl from fromGroup.valueChanges$
  // called with new FormControl({value: 123, disabled: true}, [Validators...])
  public setDisabledState(formDisabled: boolean) {
    this.formDisabled = formDisabled;
  }

}
