import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';



@Component({
  selector: 'vn-reset-pc-order-confirm-dialog',
  templateUrl: './reset-pc-order-confirm-dialog.component.html',
  styleUrls: ['./reset-pc-order-confirm-dialog.component.scss']
})
export class ResetPcOrderConfirmDialogComponent extends BaseComponent implements OnInit {

  public basket: any;
  public form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ResetPcOrderConfirmDialogComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private dialogData: {basket: any},
    @Inject('mppService') private mppService: any,
  ) {
    super()
  }

  ngOnInit(): void {
    this.setLoading$(true);
    this.basket = this.dialogData.basket;
    this.initForm();
    this.setLoading$(false);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      comment: this.formBuilder.control(undefined, [])
    });
  }

  async resetOrder() {
    this.setLoading$(true);

    const basket = this.basket;
    const comment = this.form.controls['comment'].value;

    await this.mppService.resetOrder(basket, comment)
    .then(() => {
      this.alertService.message('Die Nutzerentscheidung wurde zurückgesetzt.');
    }).catch(() => {
      this.alertService.error('Fehlgeschlagen.');
    });
    
    this.dialogRef.close(true);
    this.setLoading$(false);
  }
}
