const $inject = [];
export default class CalculationComponentCheckoutStepIntro {
  constructor() {
    this.$onInit = () => {};
  }

  handleClickNext() {
    this.onClickNext();
  }

  handleClickHeader() {
    this.onClickHeader();
  }
}
CalculationComponentCheckoutStepIntro.$inject = $inject;

import html from "./CalculationComponentCheckoutStepIntro.pug";

angular
  .module( 'VSPApp' )
  .component( 'checkoutIntroStep', {
    template: html(),
    transclude: {
      'innerHtml': '?innerHtml',
    },
    bindings: {
      title: '<',
      video: '<',
      img: '<',
      subtitle: '<',
      subsubtitle: '<',
      onClickHeader: '&',
      onClickNext: '&',
      isOpen : '<',
      isDisabled: '<'
    },
    controller: CalculationComponentCheckoutStepIntro,
    controllerAs: '$ctrl'
  });

