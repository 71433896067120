import { Component, forwardRef, Inject, Input, OnInit, Output, AfterViewInit, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'vn-phone-validator',
  templateUrl: './phone-validator.component.html',
  styleUrls: ['./phone-validator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhoneValidatorComponent)
    }
  ],
})
export class PhoneValidatorComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  constructor(
    @Inject('ByteplantService') private byteplantService: any,
    @Inject('EmployeeService') private employeeService: any,
    @Inject('VSP_CONSTANTS') private VSP_CONSTANTS: any,
  ) { }

  @Input()
  id: string | undefined;

  @Input()
  title: string | undefined;
  
  @Input()
  placeHolder: string = ' ';
  
  @Input()
  initPhoneValue: string = '';

  @Input()
  dataCy: string | undefined;
  public dataCyInput: string | undefined;
  public dataCyButton: string | undefined;

  @Input()
  isDisabled: boolean = false;

  @Input()
  isRequired: boolean = false;

  @Output()
  isValid: boolean = false;
  @Output() 
  isPhoneValid$ = new EventEmitter<boolean>();

  public isProved: boolean = false;

  public errorMessage: string | undefined;

  private employee: any;
  
  public countriesPhoneCode: any;
  public country: string = 'Germany';

  async ngOnInit(): Promise<void> { 
    this.employee = await this.employeeService.get();
  }
  
  ngAfterViewInit(): void {
    this.initCypressAttributes();
    this.countriesPhoneCode = this.VSP_CONSTANTS.COUNTRIES_PHONE_CODE;
    this.resetCountryCode(this.country);
    this.initiationValue();
  }
  
  private formControlValue!: string;
  public get value() {
    return this.formControlValue;
  }

  public onTouched = () => { };
  public onChange = (_: string) => {};

  public writeValue(value: string) {
    if (!this.isDisabled && value) {
      this.formControlValue = value;
      this.onChange(this.formControlValue)
    }
  }

  public registerOnChange(onChange: (_: string) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }
  
  initiationValue() {
    if(this.initPhoneValue) {
      this.writeValue(this.initPhoneValue);
    }
  }

  initCypressAttributes() {
    this.dataCyInput = this.dataCy + '.PhoneProveInput'
    this.dataCyButton = this.dataCy + '.PhoneProveButton'
  }

  hideError() {
    this.errorMessage = '';
  }

  showError(message: string) {
    this.errorMessage = message;
  }

  async checkPhoneNumberValidity() {
    this.isValid = false;
    const phoneNumber = this.value;
    
    if(!this.employeeService.checkIfItsTestEmployee(this.employee)) {

      const isPhoneNumberValid = await this.byteplantService.isPhoneNumberValid(phoneNumber);
      this.isValid = !!isPhoneNumberValid;
      if(!this.isValid) {
        this.showError("Bitte geben Sie eine gültige Telefonnummer an.");
        this.isPhoneValid$.emit(false);
        this.isProved = false;
        return false;
      }
    }

    this.isValid = true;
    this.hideError();
    this.isPhoneValid$.emit(true);
    this.isProved = true;
    return true;
  }

  handlePhoneNumberChanges(phoneNumber: string) {
    const countryName = this.country;
    const countryCode = this.countriesPhoneCode[countryName].code + '-';
    
    this.writeValue(phoneNumber);
    this.isProved = false;
    this.isDisabled = true;
    let reset = false;

    if (phoneNumber == undefined || phoneNumber.indexOf(countryCode) != 0) {
      reset = true;
      this.showError(`Die Telefonnummer muss mit ${countryCode} beginnen`);
    } else
    if(countryCode == phoneNumber) {
      reset = true;
      this.showError('Die Telefonnummer ist nicht valid');
    } else
    if(!this.isSyntaxValid(phoneNumber)) {
      reset = true;
      this.showError('Nur Ziffer erlaubt');
    }
    
    this.isDisabled = false;
    this.isPhoneValid$.emit(false);

    if(reset) {
      this.isValid = false;
      this.resetCountryCode(countryName);
    } else {
      this.isValid = true;
      this.hideError();
    }
  }

  resetCountryCode(country: string) {
    this.country = country;
    let countryCode = this.countriesPhoneCode[country].code + '-';
    this.writeValue(countryCode);
  }

  isSyntaxValid(phoneNumber: string) {
    const regex = new RegExp(/^[+]\d+[-]\d+$/);
    return regex.test(phoneNumber);
  }


  isAllowToProve() {
    return !this.isDisabled && this.isValid
  }

}
