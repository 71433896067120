<div class='form-group'>
    <label [for]='key'>
        {{ label }}
    </label>

    <ng-content select='mat-error'>

    </ng-content>

    <input #shownInput class='form-control'
           [name]='key'
           [attr.data-cy]='key'
           [disabled]='isDisabled'
           [placeholder]='placeholder'
           [value]="value || initValue"
           (click)='toggleDatePicker()'
           (input)="changeDate($event.target.value)">
</div>

<div class='hidden'>
    <input matInput
           [min]="minDate"
           [max]="maxDate"
           [matDatepicker]="picker"
           (dateInput)='pickDate($event.value)'>

    <mat-datepicker-toggle [for]="picker"
                           [disableRipple]='true'>
    </mat-datepicker-toggle>

    <mat-datepicker #picker
                    touchUi
                    [startView]="startView"
                    (monthSelected)="monthSelected($event, picker)">
    </mat-datepicker>
</div>
