import {bausteinIcon, bausteinTitle} from './component';
import {menuIcon, menuTitle, menuCYData} from './menu';
import {vorsorgeIcon, vorsorgeTitle} from './vorsorge';
import {groupByMembership} from './groupByMembership';
import {usageTitle} from './usage';
import {filesize} from './filesize';
import {range} from './range';
import {offset} from './offset';
import {trustHtml} from './trustHtml';
import {trustSrc} from './trustSrc';
import {encodeURI} from './encodeURI';
import {formatNumber} from './format-number';
import {formatTimeDifference} from './formatTimeDifference';
import {timeUntilNow} from './timeUntilNow';

angular
  .module('VSPApp')
  .filter('bausteinIcon', bausteinIcon)
  .filter('bausteinTitle', bausteinTitle)
  .filter('menuIcon', menuIcon)
  .filter('menuTitle', menuTitle)
  .filter('menuCYData', menuCYData)
  .filter('vorsorgeIcon', vorsorgeIcon)
  .filter('vorsorgeTitle', vorsorgeTitle)
  .filter('groupByMembership', groupByMembership)
  .filter('usageTitle', usageTitle)
  .filter('filesize', filesize)
  .filter('range', range)
  .filter('offset', offset)
  .filter('trustHtml', trustHtml)
  .filter('trustSrc', trustSrc)
  .filter('encodeURI', encodeURI)
  .filter('formatNumber', formatNumber)
  .filter('formatTimeDifference', formatTimeDifference)
  .filter('timeUntilNow', timeUntilNow)
;
