<div class="customer-administration-new">
    <h1 class="row title">
        Verfügbare Kunden
    </h1>

    <mat-selection-list class="row" [multiple]="false">
        <mat-list-option *ngFor="let directBonusProjectOverview of (directBonusProjectOverviews$ | async)"
            [value]="directBonusProjectOverview.bonusProjectId"
            [attr.data-cy]="'vn.directBonus.customerAdministration.customerProjectOverviewList.itemDirectBonusProject.' + directBonusProjectOverview.bonusProjectId">
            <div class="flex-container" (click)="selectBonusProjectId(directBonusProjectOverview)">
                <span>
                    {{ directBonusProjectOverview.customerId }} |
                    {{ directBonusProjectOverview.customerName }}
                </span>

                <div class="spacer"></div>

                <span class='additional-text'>
                    {{getAdditionalText(directBonusProjectOverview)}}
                </span>

                <vn-state [color]="getOrderStateColors(directBonusProjectOverview)"
                    [attr.data-cy]="'vn.directBonus.customerAdministration.customerProjectOverviewList.chipOrderState.' + directBonusProjectOverview.bonusProjectId"
                    [label]="directBonusProjectOverview.orderMonthDisplay">
                </vn-state>

                <!-- actions menu column -->
                <ng-container>
                    <button mat-icon-button (click)="menuClick($event)"
                        [attr.data-cy]="'vn.directBonus.customerAdministration.customerProjectOverviewList.buttonActionsMenu.' + directBonusProjectOverview.bonusProjectId"
                        [matMenuTriggerFor]="menu">
                        <mat-icon>
                            more_vert
                        </mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                            (click)="openCreateExtraOrderDialog(directBonusProjectOverview)"
                            *ngIf="directBonusProjectOverview.allowedOperations.canCreateExtraOrder">
                            <mat-icon class="white-icon">library_add</mat-icon>
                            <span>Neuen Zusatzauftrag erstellen</span>
                        </button>

                        <button mat-menu-item
                            (click)="openDownloadTableDialog(directBonusProjectOverview)">
                            <mat-icon class="white-icon">download</mat-icon>
                            <span>Historie herunterladen</span>
                        </button>

                        <button mat-menu-item
                            *ngIf="directBonusProjectOverview.allowedOperations.canBulkAddUsers"
                            (click)="openBulkAddUsersDialog(directBonusProjectOverview)">
                            <mat-icon class="white-icon">group_add</mat-icon>
                            <span>Mitarbeiter importieren</span>
                        </button>

                        <button mat-menu-item
                            *ngIf="directBonusProjectOverview.allowedOperations.canDownloadInvitationData"
                            (click)="downloadInvitationData(directBonusProjectOverview)">
                            <mat-icon class="white-icon">sim_card_download</mat-icon>
                            <span>Einladungsbrief herunterladen</span>
                        </button>

                        <button mat-menu-item
                            data-cy="vn.directBonus.customerAdministration.customerProjectActionsMenu.buttonBulkAddBonus"
                            (click)="openBulkUploadBonusDialog(directBonusProjectOverview)">
                            <mat-icon class="white-icon">
                                upload
                            </mat-icon>
                            <span>
                                Aufladungen importieren
                            </span>
                        </button>

                    </mat-menu>
                </ng-container>
            </div>
            <vn-direct-bonus-customer-overview
                *ngIf="isCustomerOverviewOpen(directBonusProjectOverview.customerId)"
                [directBonusProjectOverview]="directBonusProjectOverview"
                (tableDataChange)="onTableDataChange()"
            >
            </vn-direct-bonus-customer-overview>
        </mat-list-option>
    </mat-selection-list>

    <div *ngIf="selectedDirectBonusProjectId$ | async as bonusProjectId" class="direct-bonus-table row">
        
        <mat-divider></mat-divider>

        <div class='row filters flex-container'>
            <form [formGroup]='filtersGroup' (keydown.enter)="$event.preventDefault()">
                <vn-input formControlName='firstLastName'
                    label="Vor-/Nachname"
                    placeholder='Name'
                    (keydown.enter)="loadDataStream()"
                    (keyup)="filtersChanged()">
                </vn-input>

                <vn-input formControlName='valuenetIdPersonalNumber'
                    label="ValuenetId/Personalnummer"
                    placeholder='Nummer'
                    (keydown.enter)="loadDataStream()"
                    (keyup)="filtersChanged()">
                </vn-input>

                <vn-select formControlName='componentSelection'
                    #componentSelection
                    label="Komponentenauswahl"
                    placeholder='Alle'
                    [options]='filterOptions?.componentSelection'
                    (keydown.enter)="loadDataStream()"
                    (change)="filtersChanged()">
                </vn-select>

                <mat-form-field>
                    <mat-label>Status Mitarbeiter</mat-label>
                    <mat-select formControlName='employeeStatusSelection'
                        #employeeStatusSelection
                        multiple>
                        <mat-option *ngFor="let status of filterOptions.employeeStatuses"
                            [value]="status.id"
                            (click)="filtersChanged()">
                            {{status.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <div class='form-group'>
                    <label for='employeesWithBonusesFilter'>
                        Bonus ja/nein
                    </label>
                    <select
                        class='form-control vn-select'
                        formControlName="employeesWithBonuses"
                        name="employeesWithBonusesFilter"
                        (change)="employeesWithBonusesChanged($event.target.value)"
                    >
                        <option *ngFor="let item of filterOptions.employeesWithBonuses" [value]="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            
            </form>

            <div class="spacer"></div>

            <button mat-icon-button mat-raised-button
                data-cy="vn.directBonus.customerAdministration.customerProjectActionsMenu.buttonApplyFilters"
                [disabled]="isListFiltered" (click)="loadDataStream()">
                <mat-icon>filter_list</mat-icon>
            </button>

            <button mat-icon-button mat-raised-button
                data-cy="vn.directBonus.customerAdministration.customerProjectActionsMenu.buttonClearFilters"
                [disabled]="!isListFiltered" (click)="clearFilters()">
                <mat-icon>filter_list_off</mat-icon>
            </button>

        </div>

        <a class="previous-year" (click)="changeSelectedYear(false)">
            &lt; {{ previousYear$ | async }}
        </a>

        <a class="next-year" (click)="changeSelectedYear(true)">
            {{ nextYear$ | async }} &gt;
        </a>

        <vn-customer-project-overview-table [bonusProjectId]="bonusProjectId" [customerId]="customerId"
            [year]="(selectedYear$ | async)!" [componentSelection]="componentSelection.selectionChange$ | async"
            [tableData]='(dataStream$ | async)!' (tableDataChange)="onTableDataChange()">
        </vn-customer-project-overview-table>
    </div>
</div>
