const $inject = [
  "$q",
  "Made",
  "customerService"
];

export default class potentialCalculatorService {

  constructor($q, Made, customerService) {

    Object.assign(this, {
      $q,
      Made,
      customerService
    });

    this.payments = {};
  }

  calculatePotentialFromConfiguration(configuration, brutto) {

    let potential = 0,
      steuerfrei = 0;

    if (!configuration.modules || !configuration.modules.steuerfreie_extras || configuration.modules.steuerfreie_extras.enabled) {
      steuerfrei += brutto * 0.4;
    }
    if (!configuration.modules || !configuration.modules.versicherungen || configuration.modules.versicherungen.enabled) {
      potential += 500;
    }
    if (!configuration.modules || !configuration.modules.vorsorge || configuration.modules.vorsorge.enabled) {
      potential += 1000;
    }
    if (!configuration.modules || !configuration.modules.cashback || configuration.modules.cashback.enabled) {
      potential += 2000;
    }
    if (!configuration.modules || !configuration.modules.shop || configuration.modules.shop.enabled) {
      potential += 250;
    }
    if (!configuration.modules || !configuration.modules.benefits || configuration.modules.benefits.enabled) {
      steuerfrei += steuerfrei * 0.05;
    }

    return potential + steuerfrei;
  }

  calculatePotential(brutto) {

    return this.customerService
      .getConfiguration()
      .then(configuration => this.calculatePotentialFromConfiguration(configuration, brutto));
  }

  getPotentialLinkFromConfiguration(configuration) {

    if (configuration.modules) {
      if (!configuration.modules.steuerfreie_extras || configuration.modules.steuerfreie_extras.enabled) {
        return 'inApp.neo.side.verguetung';
      }
      if (!configuration.modules.versicherungen || configuration.modules.versicherungen.enabled) {
        return 'inApp.versicherung';
      }
      if (!configuration.modules.vorsorge || configuration.modules.vorsorge.enabled) {
        return 'inApp.vorsorge.home';
      }
      if (!configuration.modules.cashback || configuration.modules.cashback.enabled) {
        return 'inApp.cashback.home';
      }
      if (!configuration.modules.shop || configuration.modules.shop.enabled) {
        return 'inApp.shop.pre';
      }
      if (!configuration.modules.benefits || configuration.modules.benefits.enabled) {
        return 'inApp.benefit.home';
      }
    }

    return 'inApp.neo.side.verguetung';
  }

  getPotentialLink() {

    return this.customerService
      .getConfiguration()
      .then(configuration => this.getPotentialLinkFromConfiguration(configuration));
  }

}

potentialCalculatorService.$inject = $inject;