import * as moment from 'moment';
import { Inject, Injectable } from "@angular/core";
import { DirectBonus, DirectBonusMapper } from "./direct-bonus-data.model";
import { BaseMapper } from "./direct-bonus-project-overview.model";
import { BackofficeAdministrationAllowedOperations, BackofficeAdministrationBonusAllowedOperations, CustomerAdministrationOrderAllowedOperations } from "./direct-bonus-allowed-operations.model";

export type CustomerOrderStateName = 'Entwurf' | 'Bestellt' | 'Fakturiert' | 'Bezahlt' | 'Ausgeführt' | 'Fertig' | 'CHANGE ME' | 'Abgelaufen' | 'Storniert';
export type CustomerOrderStateValue = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

export interface CustomerOrderState {
  name: CustomerOrderStateName,
  value: CustomerOrderStateValue,
}

export class CustomerOrder {
  constructor(
    public orderId: string,
    public orderRefId: string,
    public bonusProjectId: string,
    public customerId: number,
    public paymentType: string,
    public state: CustomerOrderState,
    public forYear: number,
    public forMonth: number,
    public isOnHold: boolean,
    public deadlineDate: Date,
  ) { }

  public isCustomerApproved()
  {
    return (
      this.state.name === 'Entwurf' ||
      this.state.name === 'Bestellt'
    )
  }

  public isStateInDraft() {
    return this.state.name === 'Entwurf'
  }
}

export interface ProblematicOrderForInvoice {customerId : number, customerName: string, orderId: string  }

export interface CustomerOrderTableBonusData {
  bonus: DirectBonus,
  employeeName: string,
  bonusAllowedOperations: BackofficeAdministrationBonusAllowedOperations,
}

export class CustomerOrderTableData {
  constructor(
    public orderSum: number,
    public order: CustomerOrder,
    public customerName: string,
    public allowedOperations: CustomerAdministrationOrderAllowedOperations,
  ) { }
}

export class CustomerOrderTableDataBO {
  constructor(
    public orderSum: number,
    public order: CustomerOrder,
    public customerName: string,
    public allowedOperations: BackofficeAdministrationAllowedOperations,
  ) { }
}

export interface CustomerOrderFilterChoices {
  customerId: number | null;
  customerName?: string | null;
  forYear: number | null;
  forMonth: number | null;
  orderState: string | number | null;
  withOnHold?: boolean;
  isMainOrder?: boolean;
}

export class CustomerOrderFiltersMapper implements BaseMapper {
  public toApi(filters: CustomerOrderFilterChoices) : any {
    return {
      'customer_id': filters.customerId,
      'state': filters.orderState,
      'for_year': filters.forYear,
      'for_month': filters.forMonth,
      'is_on_hold': filters.withOnHold ? null : false,
      'is_main_order': filters.isMainOrder || false,
    }
  }
}

@Injectable()
export class CustomerOrderMapper implements BaseMapper {
  constructor(
  ) { }

  public fromApi(o: any): CustomerOrder {
    return new CustomerOrder(
      o['orderId'],
      o['orderRefId'],
      o['bonusProjectId'],
      o['customerId'],
      o['orderPaymentType'],
      {
        name: o['stateDisplay'],
        value: o['state']
      },
      o['forYear'],
      o['forMonth'],
      o['isOnHold'],
      moment.unix(o['deadlineDate']).toDate()
    );
  }

  public toApi(id: string) {
    return {
      id
    }
  }
}

@Injectable()
export class CustomerOrderTableDataBOMapper implements BaseMapper {
  constructor(
  ) { }

  public fromApi(o: any): CustomerOrderTableDataBO {

    const customerOrderMapper = new CustomerOrderMapper();
    const directBonusMapper = new DirectBonusMapper();

    const canStartMarkOrderForInvoice = o['order_allowed_operations']['can_bo_start_mark_for_invoice'];
    const canMarkOrderForInvoice = o['order_allowed_operations']['can_bo_mark_order_for_invoice'];
    const canMarkOrderAsPaid = o['order_allowed_operations']['can_bo_mark_order_as_paid'];
    const canMarkOrderAsUnpaid = o['order_allowed_operations']['can_bo_return_order_to_invoice'];
    const canMarkOrderAsDone = o['order_allowed_operations']['can_bo_mark_order_as_done'];
    const canMarkOrderAsCancelled = o['order_allowed_operations']['can_bo_mark_order_as_cancelled'];
    const canCreateNewBonus = o['order_allowed_operations']['can_bo_create_new_bonus'];
    const canPutOrderOnHold = o['order_allowed_operations']['can_bo_put_order_on_hold'];
    const canPutOrderOffHold = o['order_allowed_operations']['can_bo_put_order_off_hold'];
    const canChangeOrderPaymentType = o['order_allowed_operations']['can_bo_change_order_payment_type'];
    const canExtendOrderDeadline = o['order_allowed_operations']['can_bo_extend_order_deadline'];

    return new CustomerOrderTableDataBO(
      o['order_sum'],
      customerOrderMapper.fromApi(o['order']),
      o['customer_name'],
      {
        canMarkOrderForInvoice,
        canStartMarkOrderForInvoice,
        canMarkOrderAsPaid,
        canMarkOrderAsUnpaid,
        canMarkOrderAsDone,
        canMarkOrderAsCancelled,
        canPutOrderOnHold,
        canPutOrderOffHold,
        canCreateNewBonus,
        canChangeOrderPaymentType,
        canExtendOrderDeadline
      }
    );
  }
}

@Injectable()
export class CustomerOrderTableDataMapper implements BaseMapper {
  constructor(
  ) { }

  public fromApi(o: any): CustomerOrderTableData {

    const customerOrderMapper = new CustomerOrderMapper();
    const canConfirmOrder = o['order_customer_allowed_operations']['can_customer_confirm_order'];
    const canRevertConfirmedOrder = o['order_customer_allowed_operations']['can_customer_revert_confirmed_order'];
    const canCreateBonus = o['order_customer_allowed_operations']['can_create_bonus'];
    

    return new CustomerOrderTableData(
      o['order_sum'],
      customerOrderMapper.fromApi(o['order']),
      o['customer_name'],
      {
        canCreateBonus,
        canConfirmOrder,
        canRevertConfirmedOrder,
      }
    );
  }
}
