angular
  .module('VSPApp')
  .controller('NeoController', [
    '$scope',
    '$state',
    function (
      $scope,
      $state
    ) {

      $scope.documents = function () {
        $state.go('inApp.neo.side.checkout.extrainfo');
      };
    }]);
