import {Injectable} from '@angular/core';
import {Made} from "./made/Made";

interface ObjectInterface {
  [key: string]: any
}

interface HtmlTagAttrInterface {
  html?: string,
  type?: string,
  id?: string,
  src?: string,
  async?: string,
  defer?: string,
  rel?: string,
  href?: string,
  as?: string,
}

const userCentrics = {
  links: [
    {
      rel: "preconnect",
      href: "//app.usercentrics.eu"
    },
    {
        rel: "preconnect",
        href: "//api.usercentrics.eu"
    },
    {
        rel: "preconnect",
        href: "//privacy-proxy.usercentrics.eu"
    },
    {
        rel: "preload",
        href: "//app.usercentrics.eu/browser-ui/latest/loader.js",
        as: "script"
    },
    {
        rel: "preload",
        href: "//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js",
        as: "script"
    },
  ],
  scripts: [
    {
      id: "usercentrics-cmp",
      "data-settings-id": "eWrKKyYNW",
      src: "https://app.usercentrics.eu/browser-ui/latest/loader.js",
      async: ""
    },
    {
      type: "application/javascript",
      src: "https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
    }
  ]
}

const userLane = {
  scripts: [
    {
      type: "text/plain",
      "data-usercentrics": "Userlane",
      html: `const VALUENET_AUTH_ID = 32390;
      // load Userlane
      (function (i, s, o, g, r, a, m) {
        i['UserlaneCommandObject'] = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments)
        };
        a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
      })
      (window, document, 'script', 'https://cdn.userlane.com/userlane.js', 'Userlane');`
    }
  ]
}

const googleMaps = {
  scripts: [
    {
      type: "text/plain",
      "data-usercentrics": "Userlane",
      html: `
      let scriptMarker = document.createElement('script');
      scriptMarker.src = 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js'
      document.getElementsByTagName('head')[0].appendChild(scriptMarker);
      `
    },
    {
      defer: "",
      type: "text/plain",
      "data-usercentrics": "Google Maps",
      html: `
      let scriptApi = document.createElement('script');
      scriptApi.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBzm2AJUd6IhAh34SSFlqhsvwy5OdHH7Uc&map_ids=ee695372458ffb1a&libraries=places&callback=Function.prototype'
      document.getElementsByTagName('head')[0].appendChild(scriptApi);
      `
    }
  ]
}

const otrs = {
  scripts: [
    {
      type: "text/plain",
      'data-usercentrics': "OTRS",
      html: `
            window.otrsChatConfig = {
              conversationsTitleText: "Unterhaltung(en)",
              noChatConversationsText: "Sie nehmen derzeit an keinem Chat teil.",
              newConversationLinkText: "Neue Unterhaltung",
              incomingRequestsTitleText: "Eingehende Chat-Anfrage ",
              outgoingRequestsTitleText: "Ausgehende Chat-Anfrage ",
              activeChatsTitleText: "Aktive Chats",
              closedChatsTitleText: "Geschlossene Chats",
              createChatTitle: "Haben Sie weitere Fragen? Chatten Sie mit uns!",
              noAvailableAgentsTitle: "Bitte kontaktieren Sie den Support über das Nutzermenü oben rechts unterhalb Ihres Namens Support kontaktieren.",
              publicUserNameText: "Bitte geben Sie Ihren Namen ein.",
              publicUserNamePlaceholder: "Max Mustermann",
              chatChannelsLabelText: "Bitte wählen Sie einen Kanal.",
              chatConversationText: "Chat-Unterhaltung",
              chatRemovedText: "Dieser Chat wurde entfernt.",
              chatCloseText: "Klicken Sie hier, um den Chat zu schließen.",
              chatClosedText: "Dieser Chat ist geschlossen.",
              chatNextStepsText: "Klicken Sie hier für weitere Schritte.",
              noAnswerText: "Bitte kontaktieren Sie den Support über das Nutzermenü oben rechts unterhalb Ihres Namens Support kontaktieren.",
              requestPendingText: "Bitte haben Sie einen Moment Geduld, bis einer unserer Agenten Ihre Chat-Anfrage beantworten kann. Danke für Ihr Verständnis.",
              tryAgainLaterText: "Bitte versuchen Sie es später erneut.",
              closeModalTitle: "Chat schließen",
              closeModalText: "Sind Sie sicher, dass Sie diesen Chat schließen möchten?",
              closeModalYes: "Ja",
              closeModalNo: "Nein",
              writeMessageText: "Schreibe eine Nachricht.",
              sendEmailSummaryText: "Eine Zusammenfassung dieser Unterhaltung an mich senden.",
              sendEmailPlaceholderText: "E-Mail angeben",
              sendEmailButtonText: "Senden",
              chatPrimaryColor: "#002eff",
              defaultChatChannelId: "5"
            };
            `
    }
  ]
}

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  public USERLANE_DISABLED: any;
  public VIDEOS_DISABLED: any;
  public IS_DEV: any;
  public IS_STAGE: any;
  public IS_TEST_ENV: any;
  public VSP_CONSTANTS!: ObjectInterface;
  public BUILD_VERSION: any;

  constructor(private Made: Made) {
  }

  async deferredBootstrap() {
    // get userlane settings
    this.USERLANE_DISABLED = await this.Made.request('rpc://utility/common/userlane_disabled');

    // get videos settings
    this.VIDEOS_DISABLED = await this.Made.request('rpc://utility/common/videos_disabled');

    this.IS_DEV = await this.Made.request('rpc://utility/common/is_dev_env');
    this.IS_STAGE = await this.Made.request('rpc://utility/common/is_stage_env');
    this.IS_TEST_ENV = await this.Made.request('rpc://utility/common/is_test_env');
    this.BUILD_VERSION = await this.Made.request('rpc://utility/common/build_version')

    if(!this.IS_TEST_ENV) {
      // then init some external service's scripts
      this.initExternalServiceScripts();
    }

    // get VSP_CONSTANTS
    let constantsPromises = [
      this.Made.request('rpc://vbm/get_constants'),
      this.Made.request('rpc://valuenetdb/get_constants'),
      this.Made.request('rpc://administration/get_constants'),
    ];

    let filterOptionsPromises = [
      this.Made.request('rpc://vbm/get_filters'),
    ];

    let defaultsPromises = [
      this.Made.request('rpc://valuenetdb/get_defaults')
    ]

    let VSP_CONSTANTS: ObjectInterface = {};

    let defaultsPromise = Promise.all(defaultsPromises).then((defaults)=> {
      Object.assign(VSP_CONSTANTS, ...defaults );
    });

    let constantsPromise = Promise.all(constantsPromises).then(([vbm, valuenetdb, administration]) => {
      const constants: ObjectInterface = {
        vbm, valuenetdb, administration
      }

      for (let module_name in constants) {
        for (let constant_name in constants[module_name]) {
          constants[module_name][constant_name + '_BY_ID'] = Object.keys(constants[module_name][constant_name]).reduce((acc: ObjectInterface, value: string) => {
            acc[constants[module_name][constant_name][value]['id']] = constants[module_name][constant_name][value];
            return acc;
          }, {});

          constants[module_name][constant_name + '_BY_NAME'] = Object.keys(constants[module_name][constant_name]).reduce((acc: ObjectInterface, value: string) => {
            acc[constants[module_name][constant_name][value]['name']] = constants[module_name][constant_name][value];
            return acc;
          }, {});
        }
        Object.assign(VSP_CONSTANTS, constants[module_name]);
      }
    });

    let filtersPromise = Promise.all(filterOptionsPromises).then(([vbm]) => {
      const filters: ObjectInterface = {
        'vbm': vbm
      };

      for (let module_name in filters) {
        Object.assign(VSP_CONSTANTS, filters[module_name]);
      }
    });
    await Promise.all([constantsPromise, filtersPromise, defaultsPromise])
    this.VSP_CONSTANTS = VSP_CONSTANTS;
  }

  initExternalServiceScripts(){
    // usercentrics links
    for(const i in userCentrics.links) {
      this.renderHtmlTag('link', userCentrics.links[i]);
    }

    // usercentrics scripts
    for(const i in userCentrics.scripts) {
      this.renderHtmlTag('script', userCentrics.scripts[i]);
    }

    // userlanes scripts
    for(const i in userLane.scripts) {
      this.renderHtmlTag('script', userLane.scripts[i]);
    }

    // google maps scripts
    for(const i in googleMaps.scripts) {
      this.renderHtmlTag('script', googleMaps.scripts[i]);
    }

    // otrs scripts
    for(const i in otrs.scripts) {
      this.renderHtmlTag('script', otrs.scripts[i]);
    }

  }

  renderHtmlTag(type: string, content: HtmlTagAttrInterface) {
    const elm = document.createElement(type);
    for(const [name, value] of Object.entries(content)) {
      if(name && value) {
        if(name === 'html') {
          elm.innerHTML = value;
        } else {
          elm.setAttribute(name, value);
        }
      }
    }
    document.head.appendChild(elm);
  }

}
