import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import {
  FoodVoucherRetailersMapComponent
} from "./dialogs/food-voucher-retailers-map/food-voucher-retailers-map.component";
import { FoodVoucherRetailersMapService } from "./services/food-voucher-retailers-map.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    FoodVoucherRetailersMapComponent
  ],
  providers: [
    FoodVoucherRetailersMapService
  ]
})
class FoodVouchersModule {}

export { FoodVouchersModule };
