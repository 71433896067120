import ComponentController from '../ComponentController';
import {
  FoodVoucherRetailersMapComponent
} from "../../../../../app/food-vouchers/dialogs/food-voucher-retailers-map/food-voucher-retailers-map.component";

const PAPER_DISPLAY_INFO = {
  intro: {
    process: 'NEO',
    title: 'Essensscheck',
    video: 'https://player.vimeo.com/video/397980875?dnt=1',
    subtitle: 'Sparen Sie Steuern beim Essen!',
    subsubtitle: `Mit dem Essensscheck gewähren wir Ihnen jeden Tag einen bargeldlosen Essenszuschuss. Und zwar brutto für netto, Sie zahlen dafür weder Steuern noch Sozialabgaben.\nVon Pizza bis Schweinebraten, von Fast Food bis Salat - mit dem Essensscheck steht Ihnen auch der Lebensmitteleinkauf in 70% aller Supermärkte offen.`,
    inner_html: `<p class="error" ng-show='component.is_already_used'> Sie nutzen bereits diesen Baustein!</p>`
  },
  values: {
    process: 'NEO',
    title: 'An wie vielen Tagen pro Woche arbeiten Sie?',
    description: 'Sie erhalten 3 Essensschecks pro Wochenarbeitstag. Sie können diesen Baustein nutzen, wenn Sie im Kalenderjahr keine ODER weniger als 36 Tage eine Auswärtstätigkeit (Abwesenheit größer 8 Stunden) ausüben.'
  },
  checkout: {
    video: 'https://player.vimeo.com/video/397982616?dnt=1',
    description: 'Sie haben {{ (component.model.enabled ? component.model.value : 0) | currency : "EUR" }} Essensschecks gewählt. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
  }
};

const DIGITAL_DISPLAY_INFO = {
  intro: {
    process: 'NEO',
    title: 'digitale Essensmarken',
    video: 'https://player.vimeo.com/video/699393186?dnt=1',
    subtitle: 'Sparen Sie bis zu 50% beim Essengehen und bei Lebensmitteleinkäufen!',
    inner_html: `
      <p>
        Mit den digitalen Essensmarken erhalten Sie einen steuerfreien Zuschuss für Ihre Mahlzeiten und Lebensmitteleinkäufe. Ob im Supermarkt oder in der Bäckerei, ob im Restaurant oder bei der Lieferdienstbestellung … überall in Deutschland, wo Sie gerne Essen gehen und Lebensmittel einkaufen, können Sie jetzt die Belege direkt über Ihren Arbeitgeber abrechnen.</p> <p>Einfach Kassenbon fotografieren, abschicken und sich auf die Kostenerstattung freuen!</p><p>Die Erstattung erfolgt für Sie ohne weiteren Aufwand, bequem über Ihre nächste Lohnabrechnung. Weitere Informationen zur Nutzung der digitalen Essensmarken finden Sie unter <a ui-sref="inApp.cms.list">Wissenswertes.</a>
      </p>
      <p class="error" ng-show='component.is_already_used'> Sie nutzen bereits diesen Baustein!</p>
    `
  },
  values: {
    process: 'NEO',
    title: 'An wie vielen Tagen pro Woche arbeiten Sie?',
    inner_html: '<p>Als Faustregel gilt: <b> Anzahl Ihrer Wochenarbeitstage x 3 = Anzahl der möglichen Erstattungen je Monat!</b></p><p>Wenn Sie an 5 Tagen pro Woche arbeiten, können Sie für 15 Tage im Monat eine Erstattung erhalten.</p><p>Die Belege können in beliebiger Höhe eingereicht werden. Der erstattungsfähige Wert wird automatisch ermittelt.</p><p>Sie können diesen Baustein nutzen, wenn Sie im Kalenderjahr weniger als 36 Tage eine Auswärtstätigkeit (Abwesenheit mind. 8 Stunden) ausüben.</p>'
  },
  checkout: {
    img: '/assets/img/dem_step3.png',
    inner_html: '<p>Sie haben <b> {{ (component.model.enabled ? component.model.value : 0) | currency: "EUR" }} </b>digitale Essensschecks gewählt.</p><p>Weitere Informationen zur Nutzung der digitalen Essensmarken finden Sie unter <a ui-sref="inApp.cms.list">Wissenswertes.</a></p>'
  }
};

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'vbmService',
  'userService',
  'bikeleasingService',
  'MatDialogService'
]

export default class ComponentControlleressenscheck extends ComponentController {

  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    vbmService,
    userService,
    bikeleasingService,
    MatDialogService
  ) {

    super(
      $q,
      $injector,
      $scope,
      $state,
      $transitions,
      $location,
      Made,
      debounceFactory,
      vbmData,
      neoModifierService,
      customerService,
      PlausibilityService,
      dialogService,
      SliderOptionsConstants,
      componentsService,
      NotificationService,
      vbmService,
      userService,
      bikeleasingService,
      MatDialogService
    );

    this.loading = {
      main: true
    };

    // hack because of problem with inheritance
    this.$timeout = this.$injector.get('$timeout');
    this.employeeService = this.$injector.get('employeeService');
    this.VSP_CONSTANTS = this.$injector.get('VSP_CONSTANTS');
    this.$interpolate = this.$injector.get('$interpolate');
    this.CheckoutService = this.$injector.get('CheckoutService');
    this.MatDialogService = this.$injector.get('MatDialogService');

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  backToDashboard() {
    this.$state.go('inApp.neo.side.verguetung');
  }

  canCheckout() {
    return this.vbmData.hasComponents() || this.vbmData.hasBAV();
  }

  isAllowedCheckout() {
    return !this.infomode && !this.noSelfCheckout && this.vbmData.employees[this.employeeId].data.parameters.einsparungs_modell === "NONE";
  }

  startCheckout() {
    if (this.canCheckout()) {
      this.$state.go('inApp.neo.side.checkout.zusammenfassung');
    }
  }

  getSummaryDescription() {
    return this.$interpolate(this.display.checkout.inner_html)(this.$scope);
  }

  chooseType(val) {
    const intType = parseInt(val);
    this.model.type = intType;

    if (intType === this.VSP_CONSTANTS.ESSENSCHECK_TYPE.PAPER.id) {
      this.display = {
        ...this.display,
        values: PAPER_DISPLAY_INFO.values,
        checkout: PAPER_DISPLAY_INFO.checkout
      };
    } else if (intType === this.VSP_CONSTANTS.ESSENSCHECK_TYPE.DIGITAL.id) {
      this.display = {
        ...this.display,
        values: DIGITAL_DISPLAY_INFO.values,
        checkout: DIGITAL_DISPLAY_INFO.checkout
      };
    }

  }

  async init() {
    await this.getDataPromise;

    this.user = await this.employeeService.get(this.vbmData.employeeId);
    this.maxValue = 0;
    if (typeof this.customerConfiguration.neo_components[this.moduleId].max_self_value !== 'undefined' || this.customerConfiguration.neo_components[this.moduleId].max_self_value > 0) {
      this.maxValue = this.customerConfiguration.neo_components[this.moduleId].max_self_value;
    }

    this.sliderOptions = angular.copy(this.sliderOptions);
    this.sliderOptions.to = this.maxValue;

    const neoChoicesResult = await this.CheckoutService.getNeoChoicesDefaultFromEmployee(this.vbmData.employees[this.vbmData.employeeId], this.moduleId);
    const essenscheckNeoChoices = neoChoicesResult.choicesDefault.essenscheck;

    this.is_allowed_paper = essenscheckNeoChoices.list.includes('paper');
    this.is_allowed_digital = essenscheckNeoChoices.list.includes('digital');

    this.is_paper_type = this.vbmData.employees[this.vbmData.employeeId].data.neo_components.essenscheck.type === this.VSP_CONSTANTS.ESSENSCHECK_TYPE.PAPER.id;
    this.is_digital_type = this.vbmData.employees[this.vbmData.employeeId].data.neo_components.essenscheck.type === this.VSP_CONSTANTS.ESSENSCHECK_TYPE.DIGITAL.id;
    this.essenscheckType = this.vbmData.employees[this.vbmData.employeeId].data.neo_components.essenscheck.type;

    if (this.is_allowed_paper && this.is_allowed_digital) {
      this.essenscheckType = null;
      this.display = {
        intro: {
          process: 'NEO',
          title: 'Papier Essensschecks & digitale Essensmarken',
          inner_html: `
            <h5>
              Bitte auswählen
            </h5>
            <table id="essenchecks-types">
              <tr>
                <td>
                  <div class="iframe-row">
                    <iframe
                      src="https://player.vimeo.com/video/397980875?dnt=1"
                      style="width: 200px; height: 280px"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                    ></iframe>
                  </div>
                </td>
                <td>
                  <div class="iframe-row">
                    <iframe
                      src="https://player.vimeo.com/video/699393186?dnt=1"
                      style="width: 200px; height: 280px"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                    ></iframe>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>
                    Papier Essensschecks
                  </strong>
                </td>
                <td>
                  <strong>
                    Digitale Essensmarken
                  </strong>
                </td>
              </tr>

              <tr>
                <td>
                  <p align="justify">Mit dem Papier-Essensscheck gewähren wir Ihnen jeden Tag einen bargeldlosen Essenszuschuss. Und zwar brutto für netto, Sie zahlen dafür weder Steuern noch Sozialabgaben.</p>
                  <p align="justify">Von Pizza bis Schweinebraten, von Fast Food bis Salat - mit dem Essensscheck steht Ihnen auch der Lebensmitteleinkauf in 70% aller Supermärkte offen.</p>
                  <p align="justify">Sie erhalten monatlich eine definierte Anzahl an Gutscheinen per Post übersandt und können diese bei einer Vielzahl an Partnern einlösen.</p>
                </td>
                <td>
                  <p align="justify">Mit den digitalen Essensmarken erhalten Sie einen steuerfreien Zuschuss für Ihre Mahlzeiten und Lebensmitteleinkäufe. Ob im Supermarkt oder in der Bäckerei, ob im Restaurant oder bei der Lieferdienstbestellung … überall in Deutschland, wo Sie gerne Essen gehen und Lebensmittel einkaufen, können Sie jetzt die Belege direkt über Ihren Arbeitgeber abrechnen.</p><p>Einfach Kassenbon fotografieren, abschicken und sich auf die Kostenerstattung freuen!</p><p>Die Erstattung erfolgt für Sie ohne weiteren Aufwand, bequem über Ihre nächste Lohnabrechnung. Weitere Informationen zur Nutzung der digitalen Essensmarken finden Sie unter <a ui-sref="inApp.cms.list">Wissenswertes.</a></p>
                </td>
              </tr>

            </table>

            <div class="essenscheck-options">
              <label>
                <input type="radio" ng-model="component.essenscheckType" value="${this.VSP_CONSTANTS.ESSENSCHECK_TYPE.PAPER.id}" ng-change="component.chooseType(component.essenscheckType)" />
                Papier-Essensscheck auswählen
              </label>
              <br/>
              <label>
                <input type="radio" ng-model="component.essenscheckType" value="${this.VSP_CONSTANTS.ESSENSCHECK_TYPE.DIGITAL.id}" ng-change="component.chooseType(component.essenscheckType)" />
                Digitale Essensmarken auswählen
              </label>
              <br/>
            </div>
            <p class="error" ng-show='component.is_already_used'> Sie nutzen bereits diesen Baustein!</p>
          `
        }
      }
    } else if (this.is_digital_type) {
      this.display = DIGITAL_DISPLAY_INFO;
    } else if (this.is_paper_type) {
      this.display = PAPER_DISPLAY_INFO;
    }

    if (this.model.anzahl_tage > this.maxValue) {
      this.model.anzahl_tage = this.maxValue;
      this.compute();
    }
  }

  openDialog() {
    const dialogData = {
      customerAddress: this.user.customer_address,
      customerId: this.user.customer_id
    };
    this.MatDialogService.open(FoodVoucherRetailersMapComponent, {
      data: dialogData
    });
  }
}

ComponentControlleressenscheck.$inject = $inject;
