angular
  .module('VSPApp')
  .controller('ZentraleController', [
    '$scope', '$q', 'nameFilter', 'vcpModel', 'Made', 'ngDialog', 'MonthsService', 'VCPBonusService',
    function ($scope, $q, nameFilter, vcpModel, Made, ngDialog, MonthsService, VCPBonusService) {

      MonthsService.resetMonth();

      vcpModel.getData = function () {

        vcpModel.working++;
        vcpModel
          .initialize(
            MonthsService.year,
            MonthsService.month,
            true
          )
          .then(function () {
            return vcpModel.getCluster();
          })
          .then(function () {
            vcpModel.fillCluster();
            vcpModel.getClusterWithAntraege(true);

            vcpModel.working--;
          });

        VCPBonusService.getOverview();
      };

      vcpModel.getData();


      $scope.currentCity = '';
      $scope.currentPerson = '';

      $scope.vcpModel = vcpModel;
      $scope.nameFilter = nameFilter;

      $scope.months = MonthsService.months;
      $scope.getMonthName = MonthsService.getMonthName;
      $scope.getOpenAmount = VCPBonusService.getOpenAmount;

      var reasonDialog = function () {

        var deferred = $q.defer();

        ngDialog
          .open({
            template: '/modules/vcp/mitarbeiter/reason.html',
            className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
            controller: 'ReasonDialogController'
          })
          .closePromise
          .then(function (reason) {

            if (('object' !== typeof reason.value) || !reason.value.hasOwnProperty('accepted')) {
              deferred.reject();
              return;
            }

            deferred.resolve(reason.value.reason);
          });

        return deferred.promise;
      };

      $scope.toggleCity = function (city) {
        if ($scope.currentCity === city.name) {
          $scope.currentCity = '';
          vcpModel._currentCity = undefined;
        } else {
          $scope.currentCity = city.name;
          vcpModel._currentCity = city;
          vcpModel.getCityEmployeesWithBonus(city, false, false, true, 'without-accepted');
        }
      };

      $scope.toggleCluster = function (cluster) {
        if ($scope.currentCluster === cluster.name) {
          $scope.currentCluster = '';
        } else {
          $scope.currentCluster = cluster.name;
        }
      };

      $scope.bonusChanged = function (person, bonus) {

        if (false === bonus.accepted) {
          reasonDialog()
            .then(function (reason) {
              return VCPBonusService
                .acceptById(bonus.id, false, reason)
                .then(function () {
                  VCPBonusService.getOverview();
                  vcpModel.calculateAntraegeSum();
                });
            }, function () {
              bonus.accepted = true;
            })
            .then(vcpModel.getData)
            .then(function () {
              vcpModel.getCityEmployeesWithBonus(vcpModel._currentCity, false, false, true, 'without-accepted');
            });
        } else {
          return VCPBonusService
            .acceptById(bonus.id, true, '', true)
            .then(function () {
              VCPBonusService.getOverview();
              vcpModel.calculateAntraegeSum();
            })
            .then(vcpModel.getData)
            .then(function () {
              vcpModel.getCityEmployeesWithBonus(vcpModel._currentCity, false, false, true, 'without-accepted');
            });

        }
      };

    }]);
