const ShopConstants = {
  mvp: {
    title: 'Online-Rabatte',
    icon: '/assets/img/benefit/shop.png',
    // link: '/shop/produkte/home',
    imageIsLargeIcon: true,
  },
  amazon: {
    title: 'Amazon Gutscheine',
    icon: '/assets/img/benefit/amazon.png',
    subtitle: '(bis zu 5% Rabatt)',
    imageIsLargeIcon: true,
  },
  cars: {
    title: 'Autos',
    icon: '/assets/img/benefit/cars.png',
    subtitle: '(bis zu 35% Rabatt)',
    link: 'http://www.gruppenkonditionen.de/Welcome.aspx?token=30aa5096-af0c-4fb9-99ac-5d4324d788ae',
    target: '_blank',
    imageIsLargeIcon: true
  },
  handy: {
    title: 'Handy',
    subtitle: '(bis zu 40% Rabatt)',
    icon: '/assets/img/benefit/handy.png',
    link: 'https://www.firmenvorteil.com/valuenet',
    target: '_blank',
    description: 'Bitte nutzen Sie zum Login in den Shop <br>E-Mail: versicherung@sbscom.de <br>Passwort: !uuQKlv~q+9D',
    imageIsLargeIcon: true,
  }
};

export default ShopConstants;
