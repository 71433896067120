import dialog_html from "../modules/dialog/dialog.pug";
import employee_edit_dialog_html from "../modules/arbeitnehmer/edit/employeeEditDialog.pug";
import arbeitnehmer_freischalten_dialog_html
  from "../modules/arbeitnehmer/freischalten/arbeitnehmer.freischalten.dialog.pug";
import invite_many_dialog_html from "../modules/arbeitnehmer/inviteMany/inviteManyDialog.pug";
import employee_basics_html from "../modules/arbeitnehmer/basics/employeeBasics.pug";
import employee_address_html from "../modules/arbeitnehmer/address/employee.address.pug";
import employee_birthdate_html from '../modules/arbeitnehmer/birthdate/employee.birthdate.pug';
import employee_entrydate_html from '../modules/arbeitnehmer/birthdate/employee.entrydate.pug';
import contact_details_dialog_html from '../modules/arbeitnehmer/contactDetails/contactDetailsDialog.pug';
import frage_dialog_html from '../dialogs/frageDialog/frageDialog.pug';
import feedback_dialog_html from '../modules/feedback/feedback.dialog.pug';
import assistant_html from '../modules/neo/components/kinder/assistant/assistant.pug'
import choose_advisor_html from '../modules/cockpit/advisors/chooseAdvisor.pug';
import advise_customer_html from '../modules/cockpit/customers/adviseCustomer.pug';
import contact_advisor_dialog_html from '../dialogs/contactAdvisor/contactAdvisorDialog.pug';
import change_mail_html from '../dialogs/changeMail/changeMail.pug';
import employee_integrity_html from '../modules/arbeitnehmer/integrity/employeeIntegrity.pug';
import resume_checkout_html from '../dialogs/resumeCheckout/resumeCheckout.pug';
import cancelCheckoutMPP_html from '../dialogs/cancelCheckoutMPP/cancelCheckoutMPP.pug';
import toc_html from '../modules/authentication/registration/toc/toc.pug';
import user_notification_html from '../dialogs/userNotification/userNotification.pug';
import status_log_html from '../dialogs/statusLog/statusLog.pug'
import mobile_device_name_html from '../modules/meineakte/appverwaltung/mobileDeviceName.pug';

const $inject = [
  'ngDialog',
  'CheckoutService'
];

export default class dialogService {

  constructor(ngDialog, CheckoutService) {

    this.ngDialog = ngDialog;

    this.defaults = {
      error: {
        title: 'Fehler',
        text: 'Es ist ein Fehler aufgetreten',
        button: 'Schließen'
      }
    };

    this.errors = {
      'maerzklausel': {
        text: 'Die Einmalzahlung überschreitet die anteilige Beitragsbemessungsgrenze (Märzklausel). Die Einmalzahlung muss im Vorjahr verbeitragt werden.'
      }
    };
  }

  error(error = {}) {

    if ('string' === typeof error) {
      error = this.errors[error] || {};
    }

    return this.ngDialog.open({
      template: dialog_html(),
      plain: true,
      className: 'ngdialog-theme-vsp',
      controller: 'dialogController',
      data: {
        content: angular.extend({}, this.defaults.error, error)
      }
    });
  }

  employeeCreate(employeeId, customerId, needMade) {

    if (typeof needMade === 'undefined') {
      needMade = false;
    }

    return this.ngDialog.openConfirm({
      template: employee_edit_dialog_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'employeeEditController',
      controllerAs: 'employeeEditController',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        employeeId,
        customerId,
        needMade
      }
    });
  }

  employeeInvite(employeeId, reInvite) {

    return this.ngDialog.openConfirm({
      template: arbeitnehmer_freischalten_dialog_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'ArbeitnehmerFreischaltenController',
      controllerAs: 'arbeitnehmerFreischalten',
      data: {
        employeeId,
        reInvite
      }
    });
  }

  // inviteMany(employeeIds) {
  //
  //   return this.ngDialog.openConfirm({
  //     template: invite_many_dialog_html(),
  //     plain: true,
  //     className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
  //     controller: 'EmployeeInviteManyController',
  //     controllerAs: 'inviteMany',
  //     data: {
  //       employeeIds
  //     }
  //   });
  // }

  employeeBasics(employeeId) {

    return this.ngDialog.openConfirm({
      template: employee_basics_html(),
      plain: true,
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      controller: 'employeeBasicsController',
      controllerAs: 'employeeBasics',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        employeeId
      }
    });
  }

  employeeAddress(employeeId) {

    return this.ngDialog.openConfirm({
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      showClose: true,
      template: employee_address_html(),
      controller: 'employeeAddressController',
      controllerAs: 'employeeAddress',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        employeeId: employeeId
      }
    });
  }

  employeeBirthdate(employeeId) {

    return this.ngDialog.openConfirm({
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      showClose: true,
      template: employee_birthdate_html(),
      controller: 'employeeBirthdateController',
      controllerAs: 'employeeBirthdate',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        employeeId
      }
    });
  }

  employeeEntrydate(employeeId) {

    return this.ngDialog.openConfirm({
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      showClose: true,
      template: employee_entrydate_html(),
      controller: 'employeeEntrydateController',
      controllerAs: 'employeeEntrydate',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        employeeId
      }
    });
  }

  employeeContactDetails(employee) {

    return this.ngDialog.openConfirm({
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      showClose: true,
      template: contact_details_dialog_html(),
      controller: 'employeeContactDetailsController',
      controllerAs: '$ctrl',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        employee
      }
    });
  }


  question(text, title = '', button = 'Ja') {

    return this.ngDialog.openConfirm({
      template: dialog_html(),
      className: 'ngdialog-theme-vsp question-dialog',
      plain: true,
      controller: 'dialogController',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        content: {
          text,
          title,
          button
        }
      }
    });
  }

  frageDialog(text, title = '', buttons = {approve: 'Ja', decline: 'Nein'}) {

    return this.ngDialog.openConfirm({
      template: frage_dialog_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'frageDialogController',
      controllerAs: 'frageDialog',
      closeByDocument: false,
      closeByEscape: false,
      data: {
        content: {
          text,
          title,
          buttons
        }
      }
    });
  }

  feedback(feedback) {

    return this.ngDialog.open({
      template: feedback_dialog_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'FeedbackDialogController',
      data: {
        feedback
      }
    });
  }

  kinderAssistant(employeeId) {

    return this.ngDialog.openConfirm({
      template: assistant_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'kinderAssistantController',
      data: {
        employeeId
      }
    });
  }

  chooseAdvisor() {

    return this.ngDialog.openConfirm({
      template: choose_advisor_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'chooseAdvisorDialogController',
      controllerAs: 'chooseAdvisor'
    });
  }

  adviseCustomer() {

    return this.ngDialog.openConfirm({
      template: advise_customer_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'adviseCustomerDialogController',
      controllerAs: 'adviseCustomer'
    });
  }

  contactAdvisor() {

    return this.ngDialog.openConfirm({
      template: contact_advisor_dialog_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'contactAdvisorController',
      controllerAs: 'contactAdvisor'
    });
  }

  changeMail() {

    return this.ngDialog.openConfirm({
      template: change_mail_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'changeMailController',
      controllerAs: '$ctrl'
    });
  }

  employeeIntegrity(status, employeeId = 0, isCheckout = false) {

    return this.ngDialog.openConfirm({
      template: employee_integrity_html(),
      className: 'ngdialog-theme-vsp employee-integrity',
      plain: true,
      controller: 'employeeIntegrityController',
      controllerAs: 'employeeIntegrity',
      closeByDocument: false,
      showClose: false,
      closeByEscape: false,
      data: {
        status,
        employeeId,
        isCheckout
      }
    });
  }


  resumeCheckout() {

    return this.ngDialog.openConfirm({
      template: resume_checkout_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'resumeCheckoutController',
      controllerAs: 'resumeCheckout',
      closeByDocument: false,
      showClose: false,
      closeByEscape: false,
      closeByNavigation: true
    });
  }

  cancelCheckoutMPP(finalized, employeeId, basketId) {

    return this.ngDialog.openConfirm({
      template: cancelCheckoutMPP_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'cancelCheckoutMPPController',
      controllerAs: 'cancelCheckoutMPP',
      closeByDocument: false,
      showClose: false,
      closeByEscape: false,
      closeByNavigation: true,
      // Eigener MPP Wert, ob Dateien gelöscht werden müssten
      data: {
        finalized,
        employeeId,
        basketId
      }
    });
  }

  registrationToc(customerConfiguration) {

    return this.ngDialog.openConfirm({
      template: toc_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'registrationTocController',
      controllerAs: 'registrationToc',
      data: {
        customerConfiguration: customerConfiguration
      }
    });
  }

  openUserNotification(notification) {

    return this.ngDialog.openConfirm({
      template: user_notification_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'userNotificationController',
      controllerAs: 'userNotificationCtrl',
      data: {
        notification: notification
      },
      closeByDocument: false,
      showClose: false,
      closeByEscape: false,
      closeByNavigation: true
    });
  }

  openStatusLog(employee) {

    return this.ngDialog.openConfirm({
      template: status_log_html(),
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      plain: true,
      controller: 'StatusLogController',
      controllerAs: 'StatusLogCtrl',
      data: {
        employee: employee
      },
      width: 1600,
      closeByDocument: false,
      showClose: false,
      closeByEscape: false,
      closeByNavigation: true
    });
  }

  updateMobileDeviceName(device) {

    return this.ngDialog.open({
      template: mobile_device_name_html(),
      className: 'ngdialog-theme-vsp',
      plain: true,
      controller: 'MobileDeviceNameDialogController',
      controllerAs: '$ctrl',
      data: {
        device
      },
      width: 1120,
      showClose: true,
      closeByEscape: true,
      closeByNavigation: true
    });
  }
}

dialogService.$inject = $inject;
