import employeeEditController from './edit/employeeEditController';
import employeeAddressController from './address/employeeAddressController';
import ArbeitnehmerFreischaltenController from './freischalten/ArbeitnehmerFreischaltenController';
import employeeBasicsController from './basics/employeeBasicsController';
import employeeContactDetailsController from './contactDetails/contactDetailsController';
import EmployeeInviteManyController from './inviteMany/EmployeeInviteManyController';
import employeeIntegrityController from './integrity/employeeIntegrityController';
import employeeBirthdateController from './birthdate/employeeBirthdateController';
import employeeEntrydateController from './birthdate/employeeEntrydateController';


angular
  .module( 'VSPApp' )
  .controller( 'employeeEditController', employeeEditController )
  .controller( 'employeeAddressController', employeeAddressController )
  .controller( 'ArbeitnehmerFreischaltenController', ArbeitnehmerFreischaltenController )
  .controller( 'employeeBasicsController', employeeBasicsController )
  .controller( 'employeeContactDetailsController', employeeContactDetailsController )
  // .controller( 'EmployeeInviteManyController', EmployeeInviteManyController )
  .controller( 'employeeIntegrityController', employeeIntegrityController )
  .controller( 'employeeBirthdateController', employeeBirthdateController )
  .controller( 'employeeEntrydateController', employeeEntrydateController );
