export const BONUS_STATES = [
  {
    id: 1,
    name: 'available',
    display: 'Bonus verfügbar',
    description: ''
  },
  {
    id: 2,
    name: 'checkout_finished',
    display: 'Warten auf Vertrag',
    description: ''
  },
  {
    id: 3,
    name: 'approved',
    display: 'Freigegeben',
    description: ''
  },
  {
    id: 4,
    name: 'control_start',
    display: 'Kontrolle',
    description: ''
  },
  {
    id: 5,
    name: 'amendment',
    display: 'Nachbearbeitung',
    description: ''
  },
  {
    id: 6,
    name: 'declined',
    display: 'Abgelehnt',
    description: ''
  },
  {
    id: 7,
    name: 'awaiting_approval_process',
    display: 'Freigabenanfrage',
    description: ''
  },
  {
    id: 8,
    name: 'approval_approved',
    display: 'Anfrage Freigegeben',
    description: ''
  },
  {
    id: 9,
    name: 'approval_declined',
    display: 'Anfrage Abgelehnt',
    description: ''
  },
  {
    id: 10,
    name: 'first_check',
    display: 'Überprüfung Nachweise',
    description: ''
  },
  {
    id: 11,
    name: 'amendment_first_check',
    display: 'Korrektur Nachweise',
    description: ''
  },
  {
    'id': 12,
    'name': 'user_decision_process',
    'display': 'Nutzerentscheidung',
    'description': ''
  },
  {
    'id': 13,
    'name': 'cancelled',
    'display': 'Vorzeitiges Ende',
    'description': ''
  },
  {
    'id': 14,
    'name': 'awaiting_order_confirmation',
    'display': 'Warten auf Auftragsbestätigung',
    'description': ''
  }
];
export const BONUS_STATES_BY_ID = BONUS_STATES.reduce((acc, state) => {
  acc[state.id] = state;
  return acc;
}, {});
export const BONUS_STATES_BY_NAME = BONUS_STATES.reduce((acc, state) => {
  acc[state.name] = state;
  return acc;
}, {});
