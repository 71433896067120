const $inject = ["$location"];

export const DEFAULT_POSTCODE_OBJECT = {
  componentRestrictions: {
    country: 'DE',
    postalCode: ''
  }
};

export const DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS = 51;

const OVERVIEW_MAP_ID = 'ee695372458ffb1a';

const DETAILS_MAP_ID = 'ce01075e52ab3128';

export default class MapsService {

  constructor(
    $location
  ) {
    this.infoBoxTemplate = {
      'retailer': (data) => `
      <div class="retailer-template-content">
        <span class="info-box-heading">${data.name}</span>
        <span class="info-text">${data.street}</span>
        <span class="info-text">${data.postcode} ${data.city}</span>
        <span class="info-text"><a href="${data.url}">${data.url}</a></span>
        <span class="info-text go-to-retailer">
          <a href="/benefit/bonago/details/${data.id}">Go to retailer</a>
        </span>
      </div>`,
    };

    this.cluster_url = `${$location.protocol()}://${location.host}/assets/img/maps/m1.png`

    this.mcOptions = {
      styles: [
        {
          textColor: 'white',
          url: this.cluster_url,
          height: 50,
          width: 50,
          anchorText: [20, 1]
        }
      ]
    }
  }

  getOverviewMapId() {
    return OVERVIEW_MAP_ID;
  }

  getDetailsMapId() {
    return DETAILS_MAP_ID;
  }

  getPostcodeFromRetailer(retailer) {
    return retailer.english_description.address[1];
  }

  getLatLngFromRetailer(retailer) {
    return new google.maps.LatLng(retailer.english_description.address[5], retailer.english_description.address[4]);
  }


  async getAddressFromPostcode(componentRestrictions) {

    return new Promise((resolve, reject) => {
      this.geocoder = new google.maps.Geocoder();
      this.geocoder.geocode(componentRestrictions, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          resolve(results[0].geometry.location);
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
          reject(status);
        }
      });
    });
  }

}

MapsService.$inject = $inject;