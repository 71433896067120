const $inject = [
  'Made',
  'mppService',
  '$scope',
  'MppConstants',
  'VSP_CONSTANTS',
  'NeoComponentsService',
  '$timeout'
];

export default class MeineakteMppController {
  constructor(
    Made,
    mppService,
    $scope,
    MppConstants,
    VSP_CONSTANTS,
    NeoComponentsService,
    $timeout
  ) {
    Object.assign(this, {
      Made,
      mppService,
      $scope,
      MppConstants,
      VSP_CONSTANTS,
      NeoComponentsService,
      $timeout
    });

    $scope.status = MppConstants.status;
    this.MPP_ORDER_STATUSES_BY_ID = this.VSP_CONSTANTS.MPP_ORDER_STATUS_BY_ID;


    $scope.formatDate = (date, tstamp = false) => {
      let dbDate = new Date(tstamp ? date * 1000 : date);
      return dbDate.getDate() + '.' + (dbDate.getMonth() + 1) + '.' + dbDate.getFullYear();
    };

    $scope.getOrderStatusById = (state_id) => {
      return this.MPP_ORDER_STATUSES_BY_ID[state_id].display;
    };

    this.errors = {};
    this.loading = {
      main: true
    };

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.getPcNeoComponents();
    this.getPcBonusComponents();
  }

  async getPcNeoComponents() {
    const employeeId = this.Made.user.valuenet_id;
    const used_neo_pc_components = await this.NeoComponentsService.getUsedPCComponent({ employee_id: employeeId });
    const expired_neo_pc_components = await this.NeoComponentsService.getExpiredPCComponent({ employee_id: employeeId });

    this.neo_pc_components = [
      ...used_neo_pc_components,
      ...expired_neo_pc_components
    ];
  }

  async getPcBonusComponents() {
    this.bonus_pc_components = await this.mppService.getUsedPcBonusComponents(this.Made.user.valuenet_id);
  }
}

MeineakteMppController.$inject = $inject;
