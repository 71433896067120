const MppConstants = {
  calculation: {
    leasing: 0.0477,
    shipping: 4.2,
    factor: 1.1
  },
  minimum: 476,
  sorter : {
    type: 'select',
    name: 'Sortieren',
    value: 'Popularitaet::true',
    defaultValue: 'Popularitaet::true',
    options: [
      { sort: 'Popularitaet::false', name: 'Popularität aufsteigend' },
      { sort: 'Popularitaet::true', name: 'Popularität absteigend' },
      { sort: 'Preis_Brutto::false', name: 'Preis aufsteigend' },
      { sort: 'Preis_Brutto::true', name: 'Preis absteigend' },
      { sort: 'Artikelname::false', name: 'Name aufsteigend' },
      { sort: 'Artikelname::true', name: 'Name absteigend' },
    ]
  },
  requiredGroups: [
    '10', '11', '15', '1K', // Desktop-PCs
    '1A', '1C',             // Notebooks
    '1H',                   // Tablets
    'A4', 'A5'              // Smartphone
  ],
  mobileRequiredGroups: [
    'A4', 'A5'              // Smartphone
  ],
  status: {
    created: 'Nicht übernommen',
    saved: 'Für Baustein übernommen',
    checkout: 'Geschlossen für Beantragung',
    deactivated: 'In Bearbeitung',
    ordered: 'Bestellung übermittelt',
    shipped: 'In Auslieferung',
    ready: 'Vollständig',
    deleted: 'Abgelaufen',
    storno: 'Storniert',
    attention: 'Ungültig',
  },
  bonusMppCookieKey: 'MPP_COOKIE'
};

export default MppConstants;
