const $inject = [
  '$scope',
  'administrationService',
  '$timeout'
];
export default class  AdministrationMppCalculationController {
  constructor (
    $scope,
    administrationService,
    $timeout
  ) {
    Object.assign(this, {
      $scope,
      administrationService,
      $timeout
    });

    this.forms = {};
    this.used_limits_model = {};
    this.loading = false;
  }

  async recalculateUsedLimit(){
    if (this.used_limits_model.customer_ids){
      let customer_ids = this.used_limits_model.customer_ids.split(';').map(c_id => parseInt(c_id)).filter(c_id => Number.isInteger(c_id));
      this.loading = true;
      await this.administrationService.checkAndUpdateUsedLimitMultiple(customer_ids);
      this.$timeout(()=>{
        this.loading = false;
      }, 0);
    }
  }

}
AdministrationMppCalculationController.$inject = $inject;
