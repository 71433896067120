const $inject = [
  '$scope',
  '$q',
  'Made',
  'vbmData',
  'customerService',
  'employeeService',
  'authenticationService',
  'BonusService',
  '$state',
  '$timeout',
  'administrationService',
  'NeoComponentsService',
  'DigitalSigningService',
  'CarLeasingApiService',
  'BenefitsBonagoController',
  'DticketOrderService'
];

export default class MeineakteController {
  constructor(
    $scope,
    $q,
    Made,
    vbmData,
    customerService,
    employeeService,
    authenticationService,
    BonusService,
    $state,
    $timeout,
    administrationService,
    NeoComponentsService,
    DigitalSigningService,
    CarLeasingApiService,
    BenefitsBonagoController,
    DticketOrderService
  ) {
    Object.assign(this, {
      $scope,
      $q,
      Made,
      vbmData,
      customerService,
      employeeService,
      authenticationService,
      BonusService,
      $state,
      $timeout,
      administrationService,
      NeoComponentsService,
      DigitalSigningService,
      CarLeasingApiService,
      BenefitsBonagoController,
      DticketOrderService
    });

    this.loading = {
      main: false
    };
    this.toggableStatus = true;
    this.employeeId = this.vbmData.employeeId = this.Made.user.valuenet_id;
    this.customer_id = this.Made.user.customer_id;
    this.iAmFGuser = this.authenticationService.iAm('fg_user');

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    let result = await this.$q.all({
      has_missing_documents: this.hasMissingDocuments(),
      configuration: this.customerService.getConfiguration(this.customer_id),
      has_open_user_decisions: this.hasOpenDecisionProcesses(),
      hasMakuDecision: this.hasMakuDecisionProcess(),
      hasBonagoCode: this.BenefitsBonagoController.hasBonagoCode(this.employeeId),
      dticket_orders: this.DticketOrderService.getDTicketOrders({employeeId: this.employeeId}),
      mobile_devices: this.employeeService.getEmployeeMobileDevices()
    });

    this.carsWithDocumentRequests = result.carsWithDocumentRequests;
    this.has_missing_documents = result.has_missing_documents;
    this.newMpp = result.configuration.neo_config.pc.useInternalMPP;
    this.has_open_user_decisions = result.has_open_user_decisions;
    this.hasMakuDecision = result.hasMakuDecision;
    this.steuerfreieExtrasDisabled = !!(result.configuration.modules.steuerfreie_extras && result.configuration.modules.steuerfreie_extras.enabled);
    this.hasBonagoCode = result.hasBonagoCode;
    this.hasDticket = result.dticket_orders.length > 0;

    this.hasMobileDevice = result.mobile_devices.length > 0;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  hasMissingDocuments() {
    return this.$q.all({
      has_car_leasing_documents_requests: this.CarLeasingApiService.getBasketsAwaitingDrivingLicenceUpload(this.employeeId),
      has_car_ready_to_sign: this.CarLeasingApiService.getBasketsReadyToSign(this.employeeId),
      has_missing_evg_documents: this.employeeService.hasMissingDocuments(),
      has_missing_bonus_documents: this.BonusService.hasMissingBonusDocuments(this.employeeId),
      has_missing_neo_component_documents: this.NeoComponentsService.getNeoComponentsRequiringDocuments({ employee_id: this.employeeId }),
      has_missing_bo_requested_documents: this.DigitalSigningService.getRequestsForSigning(this.employeeId),
      has_dticket_order_documents_to_sign: this.DticketOrderService.getDTicketOrdersReadyToSign(this.employeeId)
    }).then((res) => {
      return (
          res.has_missing_evg_documents ||
          res.has_missing_bonus_documents ||
          res.has_missing_neo_component_documents.length > 0 ||
          res.has_missing_bo_requested_documents.length > 0 ||
          res.has_car_leasing_documents_requests.length > 0 ||
          res.has_car_ready_to_sign.length > 0 ||
          res.has_dticket_order_documents_to_sign.length > 0
        )
    });
  }

  async hasOpenDecisionProcesses() {
    return this.administrationService.getOpenDecisionProcessesForEmployee({ employee_id: this.employeeId }).then(processes => {
      return processes.length > 0;
    });
  }

  async hasMakuDecisionProcess() {
    return this.administrationService.getOpenMakuUserDecisionProcessForEmployee(this.employeeId).then(res => !!res);
  }

  goToTab(tab) {
    let destination_state = 'inApp.meineakte.' + tab;
    if (this.$state.current.name !== destination_state) {
      this.$state.go(destination_state, { nonUrlParam: { has_missing_documents: this.has_missing_documents } });
    }
  }

  stateIncludes(state) {
    return this.$state.includes('inApp.meineakte.' + state);
  }
}

MeineakteController.$inject = $inject;
