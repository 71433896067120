const $inject = [
  "$q",
  "Made"
];

export default class UtilityService {

  constructor($q, Made) {
    this.$q = $q;
    this.Made = Made;
  }

  migrateNltgContacts() {
    return this.Made.request('rpc://utility/newslettertogo/migrate_newslettertogo_contacts');
  }

  createInitialNewsletterContactsByToc() {
    return this.Made.request('rpc://utility/newslettertogo/sync_internal_contacts');
  }

  createInitialCustomersUsersByNLTG() {
    return this.Made.request('rpc://utility/newslettertogo/sync_customer_contacts');
  }
}

UtilityService.$inject = $inject;