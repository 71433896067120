export const DEFAULT_DIRECT_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION = {
  isRequiredOnProjectCreation: false,
  isExtraInfoRequired: false
};
export const DEFAULT_FIDUCIA_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION = {
  isRequiredOnProjectCreation: false,
  isExtraInfoRequired: false
};
export const DEFAULT_ONE_TIME_BONUS_PROJECT_EMPLOYEES_INFORMATION_CONFIGURATION = {
  isRequiredOnProjectCreation: true,
  isExtraInfoRequired: true
};
