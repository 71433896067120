<a>
    <div class='car-card'>
        <div class='tag'>
            <span *ngIf="isElectric()"
                  class='green'>
                Elektro
            </span>

            <span *ngIf="isHybrid()"
                  class='blue'>
                Hybrid
            </span>

            <span *ngIf="hasQuickDelivery()">
                Schnelle Lieferung
            </span>
        </div>

        <div class='color'>
            <ng-container *ngFor="let color of car?.color_list; index as idx">
                <span *ngIf="idx < 3"
                      [ngStyle]="getColorStyle(color)">
                </span>
            </ng-container>

            <div *ngIf="colorList.length > 3">
                +{{ colorList.length - 3 }}
            </div>
        </div>

        <div class='img'
             [ngStyle]="getBackgroundImageStyle()">
        </div>

        <div class='details'>
            <strong>
                {{ car?.brand?.id }} {{ car?.model }}
            </strong>

            <div>
                {{ car?.equipment_line }}
            </div>

            <div class='available'>
                <svg viewBox="0 0 18 18"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9"
                            cy="9"
                            r="8.5"
                            stroke="currentColor">
                    </circle>

                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.393 11.234L5.159 9l-.76.755 2.994 2.995 6.427-6.43-.755-.755-5.673 5.668z"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="0.5">
                    </path>
                </svg>

                <span>
                    Verfügbar ab {{ transformDate(car?.available_from) }}
                </span>
            </div>

            <ul>
                <li>
                    {{ car?.cartype }}
                </li>

                <li>
                    {{ car?.gearshift }}
                </li>

                <li>
                    {{ car?.fuel }}
                </li>

                <li>
                    {{ car?.config_drive }}
                </li>
            </ul>
        </div>
    </div>
</a>