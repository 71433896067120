<h2 mat-dialog-title>
    Bestellung bezahlen
</h2>

<mat-dialog-content>
    <div>
        <p>
            Sie markieren die ausgewählte(n) Bestellung(en) als bezahlt.
            Geben Sie bitte ein Zahldatum für diese Bestellung(en) ein
        </p>
    </div>

    <form [formGroup]='filtersGroup'>
        <vn-date formControlName='paymentDate'
                 label='Datum'
                 key="vn.directBonus.backofficeAdministration.markAsPaidDialog.inputPaidDate"
                 placeholder='Datum auswählen'
                 vnDateMin='todayDate'>
        </vn-date>
    </form>

    <mat-divider></mat-divider>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='create-button'
            data-cy='vn.directBonus.backofficeAdministration.markAsPaidDialog.buttonSubmit'
            [disabled]="!canMarkOrderAsPaid()"
            mat-stroked-button
            (click)="markOrderAsPaid()">
        Bestätigen
    </button>
</mat-dialog-actions>