const $inject = [
  '$scope',
  'BenefitConstants',
  'customerService',
  'PopupService',
  'PopupVideoKeys',
  '$timeout',
  'BenefitBonagoService',
  '$window'
];

class benefitHomeController {

  constructor(
    $scope,
    BenefitConstants,
    customerService,
    PopupService,
    PopupVideoKeys,
    $timeout,
    BenefitBonagoService,
    $window
  ) {
    Object.assign(this, {
      $scope,
      BenefitConstants,
      customerService,
      PopupService,
      PopupVideoKeys,
      $timeout,
      BenefitBonagoService,
      $window
    });

    this.loading = {
      main: true
    };

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  goToPage(link, target, allowed) {
    if (allowed) {
      this.$window.open(link, target);
    }
  }

  async init() {
    this.$scope.benefitDetailsArray = [];

    this.$scope.clicker = {};

    this.PopupService.processModuleVideo(
      this.PopupVideoKeys.MODULE_BENEFIT,
      this.$scope.clicker
    );

    await this.BenefitBonagoService.getCustomerChecks().then(result => {
      this.customer_has_scheme_id = result.customer_has_scheme_id;
      this.customer_has_allowed_bonago = result.customer_has_allowed_bonago;
    });

    await this.customerService
      .getConfiguration()
      .then(configuration => {
        let blacklist = [];

        angular.forEach(configuration.benefit, (module, key) => {
          if (!module.enabled) blacklist.push(key);
        });

        angular.forEach(this.BenefitConstants, (module, index) => {
          if (-1 === blacklist.indexOf(index)) {
            if (index === 'mpp') {
              if (configuration.neo_components.pc.useInternalMPP) {
                this.$scope.benefitDetailsArray.push(module);
              }
            } else if (index === 'bonago') {

              module.required.customer_has_scheme_id = this.customer_has_scheme_id;
              module.required.customer_has_allowed_bonago = this.customer_has_allowed_bonago;

              module.has_errors = Object.values(module.required).reduce((acc, required) => {
                if (required === false) {
                  acc = true;
                }
                return acc;
              }, false);

              this.$scope.benefitDetailsArray.push(module);
            } else {
              this.$scope.benefitDetailsArray.push(module);
            }
          }
        });

      });
  }
}

benefitHomeController.$inject = $inject;
angular
  .module('VSPApp')
  .controller('benefitHomeController', benefitHomeController);
