import {USER_DECISION_PROCESS_TYPES_BY_ID} from "../../../services/bonus/user_decision_process_type";

const $inject = [
  '$state',
  '$scope',
  'Made',
  'dialogService',
  'lodash',
  '$timeout',
  'mppService',
  'administrationService',
  'employeeService',
  'customerService'
];
export default class MPPBuyingDecisionDialogController {

  constructor(
    $state,
    $scope,
    Made,
    dialogService,
    lodash,
    $timeout,
    mppService,
    administrationService,
    employeeService,
    customerService
  ) {
    Object.assign(this, {
      $state,
      $scope,
      Made,
      dialogService,
      lodash,
      $timeout,
      mppService,
      administrationService,
      employeeService,
      customerService
    });

    this.loading = {
      main: false
    };

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    this.dialog_step = 0
    this.USER_DECISION_PROCESS_TYPES_BY_ID = USER_DECISION_PROCESS_TYPES_BY_ID;
    this.employee = await this.employeeService.get(this.Made.user.valuenet_id, Date.now());

    this.process = this.$scope.ngDialogData.process;
    this.selected_option = this.$scope.ngDialogData.selected_option;
    this.confirmation_checkbox = false;
    this.return_checkbox = false;

    this.display_data = this.USER_DECISION_PROCESS_TYPES_BY_ID[this.process['type_id']]['available_options'][this.selected_option]['dialog_texts'];

    this.customer_configration = await this.customerService.getConfigurationByEmployeeId(this.employee.id);
    this.endprice_percentage = this.lodash.get(this.customer_configration, 'neo_config.pc.endprice_percentage');

    this.basket = this.process.entity;
    this.buying_price = (this.basket.netto_sum * this.endprice_percentage);
    this.buying_price_brutto = (this.buying_price * 1.19);

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    if (this.is_decision_confirmed) {
      this.$scope.confirm(true);
    } else {
      this.$scope.closeThisDialog();
    }
  }

  async confirm() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    let decision_process_res = await this.administrationService.decisionProcessChooseOption({process_id: this.process['_id'], choice_value_id: this.selected_option});
    this.invoice_url = decision_process_res['data']['invoice_file_url']

    this.is_decision_confirmed = true;

    this.$timeout(()=>{
      this.loading.main = false;
    });
  }
}
MPPBuyingDecisionDialogController.$inject = $inject;
