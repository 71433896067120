import {
  NEO_COMPONENTS_WITH_DOCUMENTS,
  COMPONENTS_WITH_DOCUMENTS_BY_ID,
  BONUS_COMPONENTS_WITH_DOCUMENTS,
  BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID
} from '../../../services/customerService'

export const DOCUMENTS_REQUESTED_FILENAME = 'documents_requested.zip';

const $inject = [
  '$scope',
  '$state',
  '$timeout',
  'authenticationService',
  'NotificationService',
  'administrationService',
  'Made',
  'customerService',
  'fileService',
  'moment',
  'yearlyService',
  'VSP_CONSTANTS',
  'lodash'
];
export default class CockpitDocumentsController {

  constructor(
    $scope,
    $state,
    $timeout,
    authenticationService,
    NotificationService,
    administrationService,
    Made,
    customerService,
    fileService,
    moment,
    yearlyService,
    VSP_CONSTANTS,
    lodash
  ) {

    Object.assign(this, {
      $scope,
      $state,
      $timeout,
      authenticationService,
      NotificationService,
      administrationService,
      Made,
      customerService,
      fileService,
      moment,
      yearlyService,
      VSP_CONSTANTS,
      lodash
    });

    this.documents_requested = false;
    this.customer_id = parseInt($state.params.customerId);

    this.COMPONENTS_WITH_DOCUMENTS_BY_ID = COMPONENTS_WITH_DOCUMENTS_BY_ID;
    this.BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID = BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID;
    this.secondary_document_types = undefined;

    this.model = {
      document_type_selected: undefined,
      secondary_document_type_selected: undefined,
      yearly_session_id: undefined
    };

    this.forms = {
      documents_request_form: {}
    };

    this._loading = {
      getting_active_document_requests: false,
      create_documents_zip: false,
      requesting_documents: false,
      downloading_document: false
    };

    this.DOCUMENTS_DOWNLOAD_REQUEST_STATE_BY_ID = this.lodash.cloneDeep(this.VSP_CONSTANTS.DOCUMENT_REQUEST_STATES_BY_ID);
    this.primary_document_types = this.lodash.cloneDeep(this.VSP_CONSTANTS.DOCUMENT_TYPES);
    this.DOCUMENT_TYPES_BY_NAME = this.lodash.cloneDeep(this.VSP_CONSTANTS.DOCUMENT_TYPES_BY_NAME);

    this.$onInit = () => {
      this.init();
    };
  }

  get loading() {
    let loading = Object.values(this._loading).reduce((acc, loading_flag) => {
      acc = acc || loading_flag;
      return acc;
    }, false);

    return loading;
  }

  async requestDocuments() {
    this.$timeout(() => {
      this._loading.requesting_documents = true;
    });

    this.documents_requested = true;
    if (this.model.document_type_selected) {
      await this.administrationService.requestCustomerDocuments({
        'customer_id': this.customer_id,
        'document_type_name': this.model.document_type_selected,
        'secondary_document_type': this.model.secondary_document_type_selected,
        'yearly_session_id': this.model.yearly_session_id
      }).then(() => {
        this.NotificationService.message('Documents Requested');
      }).catch(() => {
        this.NotificationService.error('Fehler');
      });

      await this.init();

      this.$timeout(() => {
        this._loading.requesting_documents = false;
      });
    }
  }

  getComponentsForDocumentType() {
    this._loading.getting_components_for_document_type = true;

    this.model.secondary_document_type_selected = undefined;

    if (this.model.document_type_selected === this.DOCUMENT_TYPES_BY_NAME['neo']['name']) {
      this.secondary_document_types = NEO_COMPONENTS_WITH_DOCUMENTS;
    } else if (this.model.document_type_selected === this.DOCUMENT_TYPES_BY_NAME['bonus']['name']) {
      this.secondary_document_types = BONUS_COMPONENTS_WITH_DOCUMENTS;
    } else if (this.model.document_type_selected === this.DOCUMENT_TYPES_BY_NAME['yearly']['name']) {
      this.model.yearly_session_id = undefined;
      this.secondary_document_types = undefined;
    } else {
      this.secondary_document_types = undefined;
    }

    this._loading.getting_components_for_document_type = false;
  }

  async init() {
    this.$timeout(() => {
      this._loading.getting_active_document_requests = true;
    });

    await this.getActiveDocumentsRequests();
    this.yearly_sessions = await this.yearlyService.get_sessions({customer_id: this.customer_id});

    this.$timeout(() => {
      this._loading.getting_active_document_requests = false;
    });

  }

  async getActiveDocumentsRequests() {
    this.$timeout(() => {
      this._loading.getting_active_document_requests = true;
    });

    this.document_requests = await this.administrationService.getActiveDocumentRequests(this.customer_id);

    this.$timeout(() => {
      this._loading.getting_active_document_requests = false;
    });
  }

  canRequestDocuments() {
    let can_request_decuments = !this.documents_requested && this.forms.documents_request_form.$valid;

    return can_request_decuments;
  }

  async downloadDocuments(documents_download_request) {
    let request_datetime_formatted = this.moment(documents_download_request.created_at * 1000).format('YYYYMMDD_HHmmss');
    let filename = documents_download_request.customer_id + '-' + documents_download_request.document_type + (documents_download_request.secondary_document_type ? ('-' + documents_download_request.secondary_document_type) : '') + '-' + request_datetime_formatted + '.zip';

    let params = {
      type: 'from_download_request',
      user: this.Made.user._id,
      customer_id: this.customer_id,
      documents_download_request_id: documents_download_request._id
    };

    let res = await this.Made.getDocument(params).catch((err) => {
      this.NotificationService.error('Fehler.');
    });

    if (res) {
      this.fileService.downloadFile(res.data, filename, 'application/zip');
    }

    this.$timeout(() => {
      this._loading.downloading_document = false;
    });
  }

  async createDocumentZip(document_request) {
    this.$timeout(() => {
      this._loading.create_documents_zip = true;
    });

    await this.Made.request('rpc://administration/documents_download_requests/create_documents_zip_external', {documents_download_request_id: document_request['_id']});

    await this.getActiveDocumentsRequests();

    this.$timeout(() => {
      this._loading.create_documents_zip = false;
    });
  }

  getYearlySessionDisplayNameByDates(yearly_session_dates) {
    return this.moment(yearly_session_dates.yearly_session_start_date).format('DD.MM.YYYY') + ' - ' + this.moment(yearly_session_dates.yearly_session_end_date).format('DD.MM.YYYY');
  }
}
CockpitDocumentsController.$inject = $inject;
