const vbmScaffoldConstant = {
  componentSum: 0,
  availableComponents: [],
  components: [],
  unknownComponents: [],
  result: {},
  resultVA: 0,
  resultZielausgleich: 0,
  neoAgreement: {},
  finalDocuments: {},
  data: {
    metadata: {},
    parameters: {},
    neo_components: {},
    insurances: {},
    calcai: {},
    zielausgleich: {},
    va: {
      pension_interest: 0.015
    }
  },
  conditions: {}
};

export default vbmScaffoldConstant;
