angular
  .module('VSPApp')
  .directive('vnAutofocus', ['$timeout', function ($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element) {

        $timeout(() => $element[0].focus());

      }
    };
  }]);
