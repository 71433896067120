import MenuConstants from '../constants/MenuConstants';
import { BonagoScheme } from '../modules/benefit/bonago/benefit_bonago.service';

export const DEFAULT_SALARY_STATEMENT_CONFIG = {
  is_enabled: false
};

export const DEFAULT_DASHBOARD_HEADLINE = {
  is_enabled: false,
  dashboardHeadline: 'Willkommen im ValueNet Portal',
  dashboardSubHeadline: 'Das Portal für Arbeitnehmer-Zusatzleistungen.',
};

export const DEFAULT_CMS_CONFIG = {
  is_enabled: false,
  menu_title: MenuConstants.cms.default_title
};

export const DEFAULT_STEUERFREIE_MENU_CONFIG = {
  is_enabled: false,
  menu_title: MenuConstants.steuerfreie_extras.default_title
};

export const DEFAULT_BONUS_DASHBOARD_CONFIG = {
  is_enabled: false,
  menu_title: MenuConstants.bonus_dashboard.default_title
};

export const DEFAULT_GDPR_CONFIG = {
  is_enabled: false,
  text: ''
};

export const DEFAULT_DASHBOARD_CONFIG = {
  welcome_block_config: {
    is_enabled: false,
    welcome_block_content: ''
  },
  information_config: {
    is_enabled: true,
    text: ''
  },
  dashboard_video_config: {
    is_enabled: true
  },
  dashboard_headline_config: {
    is_enabled: false,
    default_dashboard_headline: 'Willkommen im ValueNet Portal',
    default_dashboard_subheadline: 'Das Portal für Arbeitnehmer-Zusatzleistungen.',
    dashboard_headline: '',
    dashboard_subheadline: ''
  }
};

DEFAULT_DASHBOARD_CONFIG.dashboard_headline_config.dashboard_headline = DEFAULT_DASHBOARD_CONFIG.dashboard_headline_config.default_dashboard_headline;
DEFAULT_DASHBOARD_CONFIG.dashboard_headline_config.dashboard_subheadline = DEFAULT_DASHBOARD_CONFIG.dashboard_headline_config.default_dashboard_subheadline;

export const DEFAULT_DOCUMENTS_PARTNER_CONFIG = {
  is_enabled: false
};

export const PROJECT_TAGS = [
  {
    name: 'NEO Dokument',
    value: 'neo'
  }, {
    name: 'Gehaltsabrechnung',
    value: 'salaryStatement'
  }, {
    name: 'Bonus Dokument',
    value: 'bonus'
  }, {
    name: 'BGM Nachweise',
    value: 'bgmCertificate'
  }, {
    name: 'Versicherungsunterlagen',
    value: 'insuranceDocument'
  }
];

export const PROJECT_TAGS_BY_NAME = PROJECT_TAGS.reduce((acc, tag) => {
  acc[tag.value] = tag;
  return acc;
}, {});

export const NEO_COMPONENTS_WITH_DOCUMENTS = [
  // {
  //   id:'salaryStatement',
  //   display: 'Gehaltsabrechnung',
  //   text: 'Zur Validierung Ihrer Berechnung und Teilnahmeberechtigung ist die letzte Gehaltsabrechnung erforderlich.'
  // },
  {
    id: 'handy',
    display: 'Rechnung Mobiltelefon',
    text: 'Es sind die letzten drei Monatsrechnungen erforderlich. Bitte achten Sie auf die Vollständigkeit der Belege, damit der angesetzte Betrag nachvollziehbar ist.'
  },
  {
    id: 'festnetz',
    display: 'Rechnung Festnetztelefon',
    text: 'Es sind die letzten drei Monatsrechnungen erforderlich. Bitte achten Sie auf die Vollständigkeit der Belege, damit der angesetzte Betrag nachvollziehbar ist.'
  },
  {
    id: 'dienstwagen_auslage',
    display: 'Garagenmietvertrag',
    text: 'Bitte fügen Sie uns den Mietvertrag Ihres Stellplatzes bei. Sie können ihn scannen oder abfotografieren.'
  },
  {
    id: 'evg',
    display: 'EVG'
  },
  {
    id: 'evg_cancel',
    display: 'EVG Aufhebung'
  },
  {
    id: 'evg_change',
    display: 'EVG Änderung'
  },
  {
    id: 'evg_final',
    display: 'EVG Final'
  },
  {
    id: 'evg_amendment_signed',
    display: 'EVG Anpassung unterschrieben'
  },
  {
    id: 'evg_amendment_unsigned',
    display: 'EVG Anpassung zur Unterschrift'
  },
  {
    id: 'evg_contract',
    display: 'EVG Contract'
  },
  {
    id: 'kinder',
    display: 'Kindergartennachweis',
    text: 'Bitte geben Sie uns einen Nachweis Ihrer Betreuungskosten. Sie können sie scannen oder abfotografieren.'
  },
  {
    id: 'internet',
    display: 'Internetbestätigung'
  },
  {
    id: 'EBike',
    display: 'Fahrradunterlagen(EBike)',
    text: 'Bitte laden Sie hier das Angebot Ihres Fachhändlers hoch.'
  },
  {
    id: 'Bikeleasing',
    display: 'Fahrradunterlagen(Bikeleasing)',
    text: 'Bitte laden Sie hier das Angebot Ihres Fachhändlers hoch.'
  },
  {
    id: 'pc',
    display: 'PC Leasing'
  },
  {
    id: 'dticket',
    display: 'DeutschlandTicket JOB',
    text: 'Bitte laden Sie hier das Angebot Ihres Fachhändlers hoch.'
  },
  {
    id: 'other',
    display: 'Sonstiges',
    text: 'Bitte laden Sie hier das Angebot Ihres Fachhändlers hoch.'
  },
];
export const COMPONENTS_WITH_DOCUMENTS_BY_ID = NEO_COMPONENTS_WITH_DOCUMENTS.reduce((acc, component) => {
  acc[component.id] = component;
  return acc;
}, {});

export const BONUS_COMPONENTS_WITH_DOCUMENTS = [
  {
    id: 'handy',
    display: 'Mobiltelefon',
    required: true,
  }, {
    id: 'festnetz',
    display: 'Festnetztelefon',
    required: true,
  }, {
    id: 'kinder',
    display: 'Kindergarten',
    required: true,
  }, {
    id: 'dienstwagen_auslage',
    display: 'Dienstwagengarage (gemietet)',
    required: true,
  }, {
    id: 'EBike',
    display: 'EBike',
    required: true,
  },
  {
    id: 'pc',
    display: 'PC-Leasing',
    required: true,
  },
  {
    id: 'Bikeleasing',
    display: 'Bikeleasing',
    required: true,
  },
  {
    id: 'erholungsbeihilfe',
    display: 'Erholungsbeihilfe',
    required: true,
  },
  {
    id: 'bonus_contract',
    display: 'Bonusverträge',
  },
  {
    id: 'other',
    display: 'Sonstiges'
  }
];

export const BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID = BONUS_COMPONENTS_WITH_DOCUMENTS.reduce((acc, component) => {
  acc[component.id] = component;
  return acc;
}, {});

const INSURANCE_PAYERS = [
  {
    'id': 0,
    'name': 'employer',
    'value': 'ag',
    'display': 'AG'
  },
  {
    'id': 1,
    'name': 'employee_volunteer',
    'value': 'an_frei',
    'display': 'Arbeitnehmer Freiwillig'
  },
  {
    'id': 2,
    'name': 'employee_conversion',
    'value': 'umwandlung_an',
    'display': 'Umwandlung AN'
  },
  {
    'id': 3,
    'name': 'employee',
    'value': 'an',
    'display': 'AN'
  },
  {
    'id': 4,
    'name': 'no_one',
    'value': 'keine',
    'display': 'keine'
  }
];

const INSURANCE_PAYERS_BY_NAME = INSURANCE_PAYERS.reduce((acc, payer) => {
  acc[payer['name']] = payer;
  return acc;
}, {});

export const BONUS_INSURANCE_PAYERS = [
  INSURANCE_PAYERS_BY_NAME['no_one'],
  INSURANCE_PAYERS_BY_NAME['employer'],
  INSURANCE_PAYERS_BY_NAME['employee']
];

export const GAP_INSURANCE_PAYERS = [
  INSURANCE_PAYERS_BY_NAME['no_one'],
  INSURANCE_PAYERS_BY_NAME['employer'],
  INSURANCE_PAYERS_BY_NAME['employee']
];
const $inject = [
  '$q',
  'Made',
  'CacheService',
  'employeeService',
  'BikeConstants',
  'MenuConstants',
  'lodash',
  '$location',
  'API_END_POINTS',
  'VSP_CONSTANTS'
]
export default class customerService {

  constructor (
    $q,
    Made,
    CacheService,
    employeeService,
    BikeConstants,
    MenuConstants,
    lodash,
    $location,
    API_END_POINTS,
    VSP_CONSTANTS
  ) {

    Object.assign(this, {
      $q,
      Made,
      employeeService,
      BikeConstants,
      MenuConstants,
      lodash,
      $location,
      API_END_POINTS,
      VSP_CONSTANTS
    });

    this.customerCache = CacheService.getDictionaryStore('customers');
    this.configurationCache = CacheService.getDictionaryStore('customerConfigurations');
    this.employeeCache = CacheService.getDictionaryStore('customerEmployees');
    this.customers = this.customerCache.store;
    this.configurations = this.configurationCache.store;
    this.employees = this.employeeCache.store;

  }

  getCustomerDomainErrorMessage (domains_config) {
    if (!domains_config) {
      return '';
    }

    return this.lodash.get(domains_config, 'error_message', `Folgende Domains sind erlaubt: ${domains_config.whitelisted_domains_list}`);
  }

  _isEmployeeEmailDomainValid(employee_id, email_address) {
    return this.Made.request('rpc://valuenetdb/employee/is_email_domain_valid', {
      email: email_address,
      employee_id: parseInt(employee_id)
    });
  }

  // TODO
  // This function has been used in various places so I could not edit it.
  // Then I wrote the function above
  isEmployeeEmailDomainValid (email, domains_config) {
    if (!email) {
      return false;
    }

    if (!domains_config) {
      return true;
    }

    if (!domains_config.is_enabled) {
      return true;
    }

    const domains =  domains_config.whitelisted_domains_list.split(',');
    for (let domain of domains) {
      let regex = null;
      if (domains_config.validation_strategy === this.VSP_CONSTANTS.DOMAINS_VALIDATION_STRATEGY_BY_NAME['domain']['id']) {
        domain = domain.replace(/\./g, '\\.');
        regex = new RegExp('@' + domain.trim() + '$');
      } else if (domains_config.validation_strategy === this.VSP_CONSTANTS.DOMAINS_VALIDATION_STRATEGY_BY_NAME['regex']['id']) {
        regex = new RegExp(domain);
      }

      if (regex && regex.test(email)) {
        return true
      }
    }

    return false;
  }

  isEmployeePrivateEmailDomainValid (email, domains_config) {
    if (!email) {
      return false;
    }

    if (!domains_config) {
      return true;
    }

    if (!domains_config.is_enabled || !domains_config.emails_list) {
      return true;
    }

    const domains =  domains_config.emails_list.split(',');
    for (let domain of domains) {
      let regex = null;
        domain = domain.replace(/\./g, '\\.');
        regex = new RegExp('@' + domain.trim() + '$');

      if (regex && regex.test(email)) {
        return false;
      }
    }

    return true;
  }

  getCustomerId () {
    return this.employeeService.getCustomerId();
  }

  get (id, stale = 0) {
    if ('undefined' === typeof id) {

      return this.employeeService
        .getCustomerId()
        .then(id => this.getByCustomerId(id, stale));
    }

    return this.getByCustomerId(id, stale);
  }

  getCustomersByEmployerId (employer_id) {
    return this.Made.request('rpc://valuenetdb/customer/get', {
      employer_id: parseInt(employer_id)
    });
  }

  getByCustomerId (customerId, stale = 0) {
    let customer = this.customerCache.get(customerId, stale);

    if (customer) {
      return customer;
    }

    customer = this.Made.request('rpc://valuenetdb/customer/get', {
      customer_id: parseInt(customerId)
    });

    customer.then(customer => {
      customer = this._setExtraConfigurations(customer);
      customer = this._transformToClass(customer);
      return customer;
    });

    return this.customerCache.set(customerId, undefined, customer);
  }

  getConfigurationByEmployeeId (id, stale = 0) {
    return this.employeeService
      .getCustomerId(id)
      .then(customerId => this.getConfigurationByCustomerId(customerId, stale));
  }

  getConfiguration (id, stale = 0) {
    if ('undefined' === typeof id) {

      return this.employeeService
        .getCustomerId()
        .then(customerId => this.getConfigurationByCustomerId(customerId, stale));
    }

    return this.getConfigurationByCustomerId(id, stale);
  }

  getCustomerSpecificUserlaneTags() {
    return this.getConfiguration()
      .then((customer_configuration) => {
        let customerUserlaneTags = this.lodash.get(customer_configuration, 'contract_details.userlane_tags', '');
        return customerUserlaneTags.split(',')
    });
  }

  getConfigurationByCustomerId (id, stale = 0) {

    let configuration = this.configurationCache.get(id, stale);

    if (configuration) {
      return configuration;
    }

    configuration = this.Made.request('rpc://valuenetdb/customer/configuration/get', {
      customer_id: parseInt(id)
    });

    configuration.then(configuration => {
      configuration = this._setExtraConfigurations(configuration);
      configuration = this._transformToClass(configuration);
      return configuration;
    });

    return this.configurationCache.set(id, undefined, configuration);
  }

  _transformToClass (customer) {
    return customer;
  }

  getOrganizationConfigForEmployer (customer) {
    if (customer) {
      return customer.organization_structure;
    }
    return this.VSP_CONSTANTS.DEFAULT_ORGANIZATION_STRUCTURE_CONFIG;
  }

  _setExtraConfigurations (configuration) {
    configuration.GDPR_config = this.lodash.merge(angular.copy(DEFAULT_GDPR_CONFIG), configuration.GDPR_config);

    configuration.dashboard_config = this.lodash.merge(angular.copy(DEFAULT_DASHBOARD_CONFIG), configuration.dashboard_config);

    configuration.cms_config = this.lodash.merge(angular.copy(DEFAULT_CMS_CONFIG), configuration.cms_config);

    configuration.salary_statement_config = this.lodash.merge(angular.copy(DEFAULT_SALARY_STATEMENT_CONFIG), configuration.salary_statement_config);

    configuration.steuerfrei_menu_config = this.lodash.merge(angular.copy(DEFAULT_STEUERFREIE_MENU_CONFIG), configuration.steuerfrei_menu_config);

    configuration.bonus_dashboard_config = this.lodash.merge(angular.copy(DEFAULT_BONUS_DASHBOARD_CONFIG), configuration.bonus_dashboard_config);

    // moved to the BE
    configuration.direct_contract_config = this.lodash.merge(angular.copy(DEFAULT_DOCUMENTS_PARTNER_CONFIG), configuration.direct_contract_config);

    // check if customer had prior configurations
    if ('welcome_block' in configuration) {
      configuration.dashboard_config.welcome_block_config.is_enabled = configuration.welcome_block.welcome_block_enabled;
      configuration.dashboard_config.welcome_block_config.text = configuration.welcome_block.welcome_block_content;
      delete configuration.welcome_block;
    }

    if ('dashboard_headline' in configuration) {
      configuration.dashboard_config.dashboard_headline_config.is_enabled = configuration.dashboard_headline.is_enabled;
      configuration.dashboard_config.dashboard_headline_config.dashboard_headline = configuration.dashboard_headline.dashboardHeadline;
      configuration.dashboard_config.dashboard_headline_config.dashboard_subheadline = configuration.dashboard_headline.dashboardSubHeadline;
      delete configuration.dashboard_headline;
    }

    if ('dashboard_video_disabled' in configuration) {
      configuration.dashboard_config.dashboard_video_config.is_enabled = configuration.dashboard_video_disabled;
      delete configuration.dashboard_video_disabled;
    }

    if (configuration.cms_config.is_enabled) {
      this.MenuConstants.cms.title = configuration.cms_config.menu_title;
    }

    if (configuration.steuerfrei_menu_config.is_enabled) {
      this.MenuConstants.steuerfreie_extras.title = configuration.steuerfrei_menu_config.menu_title;
    }

    if (configuration.bonus_dashboard_config.is_enabled) {
      this.MenuConstants.bonus_dashboard.title = configuration.bonus_dashboard_config.menu_title;
    }

    if ('documents_partner' in configuration) {
      configuration.direct_contract_config.is_enabled = configuration.documents_partner;
      delete configuration.documents_partner;
    }

    return configuration;
  }

  getPNPatterById(customer_id) {
    return this.Made.request('rpc://valuenetdb/customer/configuration/getPattern', {
      customer_id
    }).then(configuration => configuration.personal_number_config);
  }

  setConfiguration (id, configuration) {

    let promise = this.Made
      .request('rpc://valuenetdb/customer/configuration/update', {
        customer_id: parseInt(id),
        config: configuration
      })
      .then(() => configuration);

    this.configurationCache.set(id, undefined, promise);

    return promise;
  }

  setFixated (id, configuration, fixated) {

    return this.Made
      .request('rpc://valuenetdb/customer/configuration/updateFixation', {
        customer_id: parseInt(id),
        fixated: fixated
      })
      .then(() => configuration);

  }

  getCustomers(advisor_id = this.Made.user.valuenet_id) {

    let customers = this.Made
      .request('rpc://valuenetdb/advisor/customers', {
        advisor_id: parseInt(advisor_id)
      });

    customers = customers.then(customers => {
      let controller = this;
      return customers.map(customer => {
        customer = controller._setExtraConfigurations(customer);
        customer = controller._transformToClass(customer);
        return customer;
      });
    });

    customers.then(customers => customers.forEach(customer => this.customerCache.set(customer.id, customer)));

    return customers;
  }

  searchCustomers (query, advisor_id = this.Made.user.valuenet_id) {

    let promise = this.Made
      .request('rpc://valuenetdb/advisor/customers', {
        advisor_id: parseInt(advisor_id),
        query
      });

    promise.then(customers => customers.forEach(customer => this.customerCache.set(customer.id, customer)));

    return promise;
  }

  getLocations (customer_id) {
    return this.Made
      .request('rpc://valuenetdb/customer/locations', {
        customer_id: parseInt(customer_id)
      });
  }


  getEmployees (id, stale = 0, with_personal_number, with_neo_components, with_bonus_projects, employee_ids) {

    let employees = this.employeeCache.get(id, stale);

    if (employees) {
      return employees;
    }

    let options = {
      customer_id: parseInt(id)
    };

    if (with_personal_number != null) {
      options['with_personal_number'] = with_personal_number;
    }

    if (with_neo_components != null) {
      options['with_neo_components'] = with_neo_components;
    }

    if (with_bonus_projects != null) {
      options['with_bonus_projects'] = with_bonus_projects;
    }

    if (employee_ids != null && Array.isArray(employee_ids)) {
      options['employee_ids'] = employee_ids;
    }

    employees = this.Made.request('rpc://valuenetdb/customer/employees', options);

    return this.employeeCache.set(id, undefined, employees);
  }

  getEmployeesWithBikeleasing (id) {
    return this.Made.request('rpc://valuenetdb/customer/getEmployeesWithBikeleasing', {
      customer_id: parseInt(id)
    });
  }

  async getEmployeesWithPCLeasing(customer_id) {
    return this.Made.request('rpc://valuenetdb/customer/getEmployeesWithPCLeasing', {
      customer_id: parseInt(customer_id)
    });
  }

  validateEmployeesPNP (id) {
    return this.Made.request('rpc://valuenetdb/customer/validateEmployeesPNP', {
      customer_id: parseInt(id)
    });
  }

  getProjects (id) {

    return this.Made.request('rpc://valuenetdb/project/projects_per_customer', {
      customer_id: parseInt(id)
    });
  }

  updateNeoConfigShopInfo (id) {

    return this.Made.request('rpc://valuenetdb/customer/configuration/updateNeoConfigShopInfo', {
      customer_id: parseInt(id)
    });
  }

  // //TODO: get neo projects via neoProjectId
  // getNeoProject(customerId, stale = 0) {

  //   return this
  //     .getConfiguration(customerId, stale)
  //     .then(configuration => ({
  //       project: configuration.neo_project,
  //       components: configuration.neo_components
  //     }));
  // }

  addProject (project) {

    return this.Made
      .request('rpc://valuenetdb/project/create', {
        project
      });
  }

  setTestmode (customerId, testmode) {

    return this.Made
      .request('rpc://valuenetdb/customer/configuration/updateTestmode', {
        customer_id: parseInt(customerId),
        testmode: !!testmode
      })
      .then(() => this.configurations[customerId].testmode = !!testmode);
  }

  setDeactivationState (customerId, deactivated) {

    return this.Made
      .request('rpc://valuenetdb/customer/configuration/setDeactivated', {
        customer_id: parseInt(customerId),
        deactivated: !!deactivated
      })
      .then(() => this.configurations[customerId].deactivated = !!deactivated);
  }

  isTestmode (customerId) {

    return this
      .getConfiguration(customerId)
      .then(configuration => configuration.testmode);
  }

  isFixated(customerId) {
    return this
      .getConfiguration(customerId)
      .then(configuration => configuration.fixated);
  }

  isInfomode (employeeId) {
    return this
      .getNeoProjectByEmployeeId(employeeId)
      .then(project => project.infomode || false);
  }

  isNoSelfCheckout (employeeId) {
    return this
      .getNeoProjectByEmployeeId(employeeId)
      .then(project => project.noSelfCheckout || false);
  }

  isInfomodeOrNoSelfCheckout (employeeId) {
    return this
      .isInfomode(employeeId)
      .then(infomode => infomode || this.isNoSelfCheckout(employeeId));
  }

  listUnadvised (name_query) {

    return this.Made
      .request('rpc://valuenetdb/customer/list_unadvised', {
        name_query
      })
      .then(customers => {

        let result = [];

        for (let i = 0; i < customers.length; i++) {

          result.push(this.customerCache.setData(customers[i].id, customers[i]));

        }

        return result;
      });
  }

  listInsurances () {
    return this.Made.request('rpc://valuenetdb/customer/listInsurances');
  }

  async getInsurancesByCustomerId (customerId) {

    let [insurances, configuration] = [
      await this.listInsurances(),
      await this.getConfiguration(customerId),
    ];

    let insuranceFilter = configuration.insurances ? configuration.insurances : {};

    //insurances = insurances.filter( insurance => (insuranceFilter[ insurance.id ] && insuranceFilter[ insurance.id ].enabled) );
    let availableInsurances = [];
    for (let i = 0; i < insurances.length; i++) {
      let insurance = insurances[i];

      if (insuranceFilter[insurance.id] && insuranceFilter[insurance.id].enabled) {
        availableInsurances.push(insurance);
      }
    }

    return {
      default: configuration.insuranceDefault,
      insurances: availableInsurances
    };
  }

  getBrandingList () {
    return this.Made.request('rpc://valuenetdb/customer/branding/list');
  }

  async getBranding (customerId) {
    if (angular.isUndefined(customerId)) {
      let employeeId = this.Made.user.valuenet_id;
      customerId = parseInt(await this.employeeService.getCustomerId(employeeId));
    }

    return this.Made.request('rpc://valuenetdb/customer/branding/get', {
      customerId: +customerId
    }).then(branding => {
      return branding && branding != '' && branding != 'null' ? branding : undefined;
    });
  }

  setBranding (customerId, branding) {
    return this.Made.request('rpc://valuenetdb/customer/branding/update', {
      customerId: +customerId,
      branding: '' + branding
    });
  }

  getAdvisorsCheckout (customerId) {
    return this.Made.request('rpc://valuenetdb/customer/getAdvisor', {
      customer_id: +customerId
    });
  }

  createCode (customerId, neoProjectId) {
    return this.Made.request('rpc://valuenetdb/customer/configuration/createRegisterCode', {
      customer_id: customerId,
      neoProject_id: neoProjectId
    });
  }

  newNeoProject (customerId) {
    customerId = parseInt(customerId);
    return this.Made.request('rpc://valuenetdb/customer/configuration/newNeoProject', {
      customerId
    });
  }

  listNeoProjects (customerId) {
    customerId = parseInt(customerId);
    return this.Made.request('rpc://valuenetdb/customer/configuration/listNeoProjects', {
      customerId
    });
  }

  async getNeoProjectByEmployeeId (employeeId = this.Made.user.valuenet_id) {
    let customerId = parseInt(await this.employeeService.getCustomerId(employeeId))
    let neoProjectId = (await this.employeeService.get(employeeId)).neoProjectId;
    return await this.getNeoProject(customerId, neoProjectId);
  }

  async getNeoProject (customerId, neoProjectId) {
    customerId = parseInt(customerId ||
      await this.employeeService.getCustomerId()
    );

    neoProjectId = neoProjectId || this.Made.user.neoProjectId;
    if (!neoProjectId) {
      neoProjectId = (await this.employeeService.get(this.Made.user.valuenet_id)).neoProjectId;
    }

    return await this.Made.request('rpc://valuenetdb/customer/configuration/getNeoProject', {
      customerId,
      neoProjectId
    });
  }

  updateNeoProject (customerId, neoProject) {
    customerId = parseInt(customerId);
    return this.Made.request('rpc://valuenetdb/customer/configuration/updateNeoProject', {
      customerId,
      neoProject
    });
  }

  listNeoProjectParticipants (customerId, neoProjectId) {
    customerId = parseInt(customerId);
    return this.Made.request('rpc://valuenetdb/customer/configuration/listNeoProjectParticipants', {
      customerId,
      neoProjectId
    });
  }

  saveNeoProjectParticipants (customerId, neoProjectId, neoProjectParticipantValuenetIds) {
    customerId = parseInt(customerId);
    return this.Made.request('rpc://valuenetdb/customer/configuration/saveNeoProjectParticipants', {
      customerId,
      neoProjectId,
      neoProjectParticipantValuenetIds
    });
  }

  async getWhitelistByEmployeeId (employeeId = this.Made.user.valuenet_id) {
    let employee_id = parseInt(employeeId);
    return this.Made.request('rpc://valuenetdb/customer/whitelist/get_whitelist_by_employee_id', { employee_id });
  }

  checkMPPStatus (customerId) {
    return this.Made.request('rpc://valuenetdb/customer/configuration/getShopProject', {
      'customer_id': customerId
    });
  }

  getNeoConfigBike (employeeId) {
    return this.getConfigurationByEmployeeId(employeeId).then(config => {

      let bikeConfig = this.BikeConstants;

      if (config.neo_config.bike) {
        bikeConfig = { ...bikeConfig, ...config.neo_config.bike };
      }

      return bikeConfig;
    });
  }

  fixAllUsers (customerId) {
    return this.Made.request('rpc://valuenetdb/employee/fixCustomerUsers', {
      'customer_id': parseInt(customerId)
    });
  }

  fixUser (customerId, employeeId) {
    return this.Made.request('rpc://valuenetdb/employee/fixCustomerUsers', {
      'customer_id': parseInt(customerId),
      'employee_id': parseInt(employeeId)
    });
  }

  getUsersWithActivationCode (customerId, withAddress=true) {
    return this.Made.request('rpc://valuenetdb/employee/getUsersWithActivationCode', {
      'customer_id': parseInt(customerId),
      'with_address': withAddress
    });
  }

  getUsersWithActivationCodeForCustomer(customerId) {
    return this.Made.request('rpc://valuenetdb/employee/get_users_with_activation_flow_for_customer', {
      'customer_id': parseInt(customerId),
    });
  }

  getCustomerLocations (customerId) {
    return this.Made.request('rpc://valuenetdb/customer/locations', {
      'customer_id': parseInt(customerId)
    });
  }

  getEmployeesWithInvalidEmails (customerId){
    return this.Made.request('rpc://valuenetdb/customer/validateEmployeesEmailDomain', {
      customer_id: parseInt(customerId)
    });
  }

  getReminders () {
    return this.Made.request('rpc://valuenetdb/employee/REMINDERS');
  }

  async getCustomerNameByCustomerId (customerId) {
    return this.Made.request('rpc://valuenetdb/customer/getCustomerNameByCustomerId', {
      customer_id: parseInt(customerId)
    });
  }

  signDocumentDigitally ($params) {
    return this.Made.request(this.API_END_POINTS.YOUSIGN.START_DIGITAL_SIGNITURE_PROCESS, $params);
  }

  createEmployeesList(customerId) {
    return this.Made.request('rpc://valuenetdb/customer/create_employees_list', {
      customer_id: parseInt(customerId)
    });
  }

  generateEmployeesListPdf(employees, template_id) {
    return this.Made.request('rpc://utility/pdfgeneratorapi/generate_activation_flow_pdf', {
      template_id: template_id,
      employees: employees
    })
  }

  async isDashboardVideoEnabled() {
    return await this.getConfigurationByEmployeeId(this.Made.user.valuenet_id).then(
      (configuration) => {
        return configuration.dashboard_config.dashboard_video_config.is_enabled
      }
    );
  }
}

customerService.$inject = $inject;
