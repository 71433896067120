import { CustomerAdministrationNewComponent } from "../../../app/direct-bonus/components/customer/customer-administration-new/customer-administration-new.component";
import { BackofficeAdministrationComponent } from "../../../app/direct-bonus/components/backoffice/backoffice-administration/backoffice-administration.component";
import { BenefitsAccountComponent } from "../../../app/direct-bonus/components/benefits/benefits-account/benefits-account.component";


angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    const amIauthorized = ['$q', 'authenticationService', 'ACLService', ($q, authenticationService, ACLService) => {
      let deferred = $q.defer();
      const iAmSpecialAdvisor = authenticationService.iAm([
        'special_advisor'
      ]);

      ACLService.amIBonusIssuer().then((iAmBonusIssuer) => {
        if (iAmSpecialAdvisor || iAmBonusIssuer) {
          deferred.resolve();
        } else {
          deferred.reject({ redirectTo:  'inApp.dashboardArbeitnehmer'});
        }
      });

      return deferred.promise;
    }];

    $stateProvider
      .state('inApp.directBonusCustomerAdministration', {
        url: '/direct-bonus/customer-administration',
        resolve: {
          authorized: amIauthorized
        },
        views: {
          main: {
            component: CustomerAdministrationNewComponent
          },
          header: {
            template: require('../../helpers/header.main.pug')
          }
        }
      })
      .state('inApp.directBonusBackofficeAdministration', {
        url: '/direct-bonus/backoffice-administration',
        views: {
          main: {
            component: BackofficeAdministrationComponent
          },
          header: {
            template: require('../../helpers/header.main.pug')
          }
        }
      })
      .state('inApp.directBonusBenefitsAccount', {
        url: '/direct-bonus/benefits-account',
        views: {
          main: {
            component: BenefitsAccountComponent
          },
          header: {
            template: require('../../helpers/header.main.pug')
          }
        }
      });
  }]);
