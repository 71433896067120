import {PCComponentModel} from '../../models/pc-component-model';
import {CheckoutBasket} from '../../models/checkout-basket';

let NEO_COMPONENTS = [PCComponentModel];

const $inject = [
  'Made', 'VSP_CONSTANTS', '$injector', '$q'
];

export default class NeoComponentsService {
  /**
   * @param Made
   * @param VSP_CONSTANTS
   * @param $injector
   * @param $q
   */
  constructor(Made, VSP_CONSTANTS, $injector, $q) {
    Object.assign(this, {
      Made,
      VSP_CONSTANTS,
      $injector,
      $q
    });

    this._checkout_basket_per_employee = {};
  }

  async migrateBasket(options) {
    let params = {
      basket_id: options.basket_id
    };

    return this.Made.request('rpc://vbm/neo/pc/migrate', params);
  }

  async getNeoComponentsRequiringDocuments(options) {
    let promises = [];

    for (let neo_component of NEO_COMPONENTS) {
      promises.push(
        neo_component.getAllWithMissingDocuments(this.$injector, {
          employee_id: options.employee_id
        })
      );
    }

    return this.$q.all(promises).then(result => {
      return result.flat();
    });
  }

  _findComponent(component_id) {
    let component;

    for (let NEO_COMPONENT of NEO_COMPONENTS) {
      if (NEO_COMPONENT.ID === component_id) {
        component = NEO_COMPONENT;
      }
    }

    return component;
  }

  async getAvailableComponent(component_id, employee_id) {
    let component = this._findComponent(component_id);

    if (component) {
      return await component.getAvailable(this.$injector, {employee_id: employee_id});
    }
  }

  async createComponent(component_id, employee_id) {
    let component = this._findComponent(component_id);

    if (component) {
      return await component.createNew(this.$injector, {employee_id: employee_id});
    }
  }

  async completeCheckout(checkout_basket){
    let promises = [];
    for (let component of checkout_basket.components) {
      let checkout_promise = component.doCheckout();
      promises.push(checkout_promise);
      const contact_information_promise = component.insertContactInformation(checkout_basket.contact_information);
      promises.push(contact_information_promise);
    }

    return Promise.all(promises).then(()=>{
      checkout_basket.reset();
    });
  }

  async getCheckoutBasket(options) {
    let employee_id = options.employee_id;

    return new CheckoutBasket({
      employee_id: options.employee_id
    });
  }

  createPCComponent(options) {
    // console.log('getting constants createPC');
    // debugger;
    // this.Made.request('rpc://vbm/get_constants');
    // this.$injector.get('Made').request('rpc://vbm/get_constants');

    return new PCComponentModel({
      employee_id: options.employee_id,
      customer_component_configuration: options.customer_configuration.neo_config.pc,
      neo_project_component_configuration: options.neo_project.neo_components.pc,
    }, this.VSP_CONSTANTS, this.$injector);
  }

  async getExpiredPCComponent(options) {
    let params = {
      employee_id: options.employee_id
    };

    let pc_components = await this.Made.request('rpc://vbm/neo/pc/getExpired', params);

    pc_components = pc_components.map( pc_component => new PCComponentModel(pc_component, this.VSP_CONSTANTS, this.$injector) );

    return pc_components;
  }

  async getUsedPCComponent(options) {
    let params = {
      employee_id: options.employee_id
    };

    let pc_components = await this.Made.request('rpc://vbm/neo/pc/getUsed', params);
    pc_components = pc_components.map( pc_component => new PCComponentModel(pc_component, this.VSP_CONSTANTS, this.$injector) );

    return pc_components;
  }

  async getActivePCComponent(options){
    let pc_components = await this.Made.request('rpc://vbm/neo/pc/getActive', {
      employee_id: options.employee_id
    });
    return this._makePCComponents(pc_components);
  }

  async getPCComponent(options) {
    let pc_components = await this.Made.request('rpc://vbm/neo/pc/get', {
      employee_id: options.employee_id,
      basket_id: options.basket_id
    }).then(result => result['data']);
    return this._makePCComponents(pc_components);
  }

  _makePCComponents(components) {
    return components.map(c => new PCComponentModel(
      c,
      this.VSP_CONSTANTS,
      this.$injector
    ));
  }

  async hasCustomerActivatedPC(options) {
    let params = {
      employee_id: options.employee_id
    };

    return this.Made.request('rpc://vbm/neo/pc/hasCustomerActivatedPC', params);
  }

  async hasEmployeeActiveSelfServiceComponent(employeeId) {
    const components = await this.Made.request(
      'rpc://vbm/neo/get_employee_neo_project_external',
      {employee_id: employeeId}
    );
    const neoComponents = components.neo_components;
    const selfServiceComponents = Object.keys(neoComponents).filter(name => {
      if (name == 'pc') {
        return neoComponents[name].enabled;
      }
      return neoComponents[name].selfservice;
    });
    return !!selfServiceComponents.length;
  }

}
NeoComponentsService.$inject = $inject;
