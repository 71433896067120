angular
  .module('VSPApp')
  .directive('inverseBoolean', [function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {

        ngModel.$parsers.push(val => {
          if (val) {
            return !val;
          }
        });

        ngModel.$formatters.push(val => '' + val);

      }
    };
  }]);
