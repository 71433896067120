var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel__title\"\u003E{{$ctrl.acl_module.display}} - {{$ctrl.acl_module_processes_by_name[$ctrl.acl_module_process_name].display}} - {{$ctrl.acl_module_process_components_by_name[$ctrl.process_resource_name].display}}\u003Cdiv class=\"cockpit-panel__body employee-filters-containter\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-4 filter-input\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_mixins["input"].call({
attributes: {"key": "$ctrl.filter.employee_id","data-cy": "vn.cockpit.userGroups.assignGroupDialog.inputEmployeeId","title": "ValueNetID","attributes": {"ng-change":"$ctrl.filterEmployees()","ng-model-options":"{ debounce: 1000 }"}}
});
},
attributes: {"name": "$ctrl.forms.employee_form"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__title\" ng-if=\"$ctrl.employee\"\u003E\u003Cdiv class=\"cockpit-panel__body employee-filters-containter\"\u003E\u003Cdiv class=\"row\" overlay=\"$ctrl.loading.filter\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv\u003EErgebnis: \u003C\u002Fdiv\u003E\u003Cdiv\u003E{{$ctrl.employee.id }} | {{$ctrl.employee.firstname + ' ' + $ctrl.employee.lastname}} - {{ $ctrl.employee.customer_id }}, {{ $ctrl.employee.employer_address.name}}\u003C\u002Fdiv\u003E\u003Cdiv\u003ELogin E-Mail: {{ $ctrl.employee.email }}\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"$ctrl.employee.email !== $ctrl.models.email_settings.current_to_email &amp;&amp; $ctrl.acl_module_name === 'approval'\"\u003Eabweichende Benachrichtigungsemail: {{ $ctrl.models.email_settings.current_to_email }}\u003C\u002Fdiv\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\" ng-repeat=\"(name, permission) in $ctrl.permission_actions_by_name\"\u003E\u003Cdiv class=\"column small-6\"\u003E";
pug_mixins["checkbox"].call({
attributes: {"data-cy": "vn.cockpit.userGroups.assignGroupDialog.checkUserPermissions","id": "approveCheckbox + {{permission.id}}","key": "$ctrl.permission_actions_by_name[name].selected","title": "{{permission.display}}"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"name": "$ctrl.forms.permissions_form"}
});
pug_html = pug_html + "\u003Cbr\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary\" type=\"button\" overlay=\"$ctrl.loading.permissions\" ng-click=\"$ctrl.savePermissions()\" data-cy=\"vn.cockpit.userGroups.assignGroupDialog.buttonSaveChanges\"\u003ESpeichern\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary right\" type=\"button\" ng-click=\"$ctrl.close()\"\u003EAbbrechen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__title\" ng-if=\"$ctrl.employee &amp;&amp; $ctrl.acl_module_name === 'approval'\"\u003E\u003Cdiv class=\"cockpit-panel__body employee-filters-containter\"\u003E     \u003Cdiv class=\"row\" overlay=\"$ctrl.loading.filter\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Cdiv\u003EE-Mail Einstellungen\u003C\u002Fdiv\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-12\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"title": "E-Mail-Benachrichtigung aktivieren","key": "$ctrl.models.email_settings.to_receive_approval_communication"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-10\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.models.email_settings.to_email","type": "email","title": "verwendete E-Mail Adresse für Benachrichtigung","validationMessages": {"required":"Geben Sie eine E-Mail Adresse an, an die die Benachrichtigung geschickt werden soll.","email":"Bitte geben Sie eine gültige E-Mail Adresse ein."},"attributes": {"ng-required":"$ctrl.models.email_settings.to_receive_approval_communication","ng-disabled":"!$ctrl.models.email_settings.to_receive_approval_communication ||                                   $ctrl.email.checking","ng-keyup":"$ctrl.email.valid = false","ng-class":"$ctrl.email.valid ? 'valid' : 'notvalid'"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-2\"\u003E\u003Cbutton class=\"button button-primary validator\" type=\"button\" ng-disabled=\"$ctrl.email.valid ||                               $ctrl.email.checking ||                               !$ctrl.canSaveEmailPreferences()\" ng-click=\"$ctrl.checkEmailValidity()\"\u003EPrüfen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E";
},
attributes: {"name": "$ctrl.forms.email_preferences_form"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary\" type=\"button\" ng-disabled=\"!$ctrl.canSaveEmailPreferences() || !$ctrl.email.valid\" ng-click=\"$ctrl.saveEmailPreferences()\" overlay=\"$ctrl.loading.email_preferences\"\u003EE-Mail aktualisieren\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary right\" type=\"button\" ng-click=\"$ctrl.close()\"\u003EAbbrechen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;