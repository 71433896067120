const VATypeConstants = [
  {
    value: 'OHNEBERUECKSICHTIGUNG',
    name: 'ohne Berücksichtigung'
  },
  {
    value: 'PENSIONSKASSE',
    name: 'Pensionskasse'
  },
  {
    value: 'DIREKT',
    name: 'Direktversicherung'
  },
  {
    value: 'UKASSE',
    name: 'U-Kasse'
  }
];

export default VATypeConstants;
