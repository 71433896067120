const $inject = [
  '$scope',
  '$timeout',
  'NotificationService',
  'DigitalSigningService'
]

export default class CancelBoRequestDialogController {
  constructor(
    $scope,
    $timeout,
    NotificationService,
    DigitalSigningService,
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      NotificationService,
      DigitalSigningService,
    });

    this.loading = {
      main: false,
      cancellation: false,
    };

    this.forms = {
      cancellationCommentForm: {}
    };

    this.model = {
      cancellationComment: ''
    }

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.file = this.$scope.ngDialogData.file;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async cancelFile() {
    this.$timeout(() => {
      this.loading.cancellation = true;
    });
    let res = null;
    try {

      res = this.DigitalSigningService.cancelBoDigitalSigningRequest(
        this.file._id,
        this.model.cancellationComment
      )
      this.NotificationService.message('File cancelled.');
      console.log("RES", res);

    } catch (e) {
      this.NotificationService.error('Fehlgeschlagen');
    } finally {
      this.$timeout(() => {
        this.loading.cancellation = false;
      });
    }

    if (res) {
      this.$scope.confirm(true);
    }
  }

}

CancelBoRequestDialogController.$inject = $inject
