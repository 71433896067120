const ShopCategoryConstants = {
  dictionary: {
    270: 'icon-food',
    282: 'icon-fitness',
    289: 'icon-hobby',
    319: 'icon-home',
    349: 'icon-mode',
    405: 'icon-dailydeals',
    447: 'icon-tv'
  },
  categories: {
    dailyDeals: '405',
    techOffers: '447'
  },
  carousel: [
    {
      name: 'Mode & Beauty',
      id: 349,
      img: 'shop_mode.png',
    },
    {
      name: 'Fitness',
      id: 282,
      img: 'shop_sport.png',
    },
    {
      name: 'Elektronik & Technik',
      id: 447,
      img: 'shop_elektronik.png',
    },
    {
      name: 'Essen & Trinken',
      id: 270,
      img: 'shop_essen.png'
    }
  ]
};

export default ShopCategoryConstants;
