const $inject = [
    "cmsService",
    "$state",
    "$scope",
    "Made",
    "NotificationService",
    "fileService"
];

export default class CategoryCreateController {

    constructor(
        cmsService,
        $state,
        $scope,
        Made,
        NotificationService,
        fileService
    ) {
        Object.assign(this, {
            cmsService,
            $state,
            $scope,
            Made,
            NotificationService,
            fileService
        });

        this.custId = $state.params.custId;

        this.category = {
            title: ''
        };

        if ($state.params.categoryId) {
            this.cmsService.globalCms.loading = true;
            cmsService.getCategory($state.params.categoryId, this.custId).then(category => {
                if (category) {
                    this.category = category;
                    this.$scope.$applyAsync();
                }
                this.cmsService.globalCms.loading = false;
            });
        }
        this.cmsService.globalCms.loading = false;
    }

    openDocument(document) {
        this.fileService.openDocument(document._id);
    }

    async submit() {
        this.cmsService.globalCms.loading = true;
        this.category.image = await this.upload(this.category.image);

        this.cmsService
            .saveCategory(this.category, this.custId)
            .then((newCategory) => {
                this.cmsService.globalCms.loading = false;
                if (!newCategory) {
                    this.$scope.cmsform['create.category.title'].$setValidity('unique', false);
                    this.NotificationService.error('Speichern nicht erfolgreich.');
                    return false;
                } else {
                    this.NotificationService.message('Die Kategorie wurde erfolgreich gespeichert.');
                    this.$state.go('inApp.cms.categoryedit', { 'categoryId': newCategory, 'custId': this.custId });
                    return true;
                }
            });
    }

    async upload(filedata) {
        if (!filedata) {
            return filedata;
        }

        if (!filedata._id) {
            let result = await this.Made.upload(filedata, [
                'cmsCategory'
            ]);

            filedata = result.data;
            filedata.name = result.data.filename;
            filedata.size = result.data.chunk_size;
        }

        return filedata;
    }


    resetUniqueEmployeeId() {
        this.$scope.cmsform['create.category.title'].$setValidity('unique', true);
    }
}

CategoryCreateController.$inject = $inject;