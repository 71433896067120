const $inject = [
  'PDFJS',
  '$http',
  'fileService',
  'NotificationService',
  '$timeout',
  'Made',
  '$scope',
  '$rootScope',
  'lodash'
];

export class ContractPreviewComponentController {
  constructor(
    PDFJS,
    $http,
    fileService,
    NotificationService,
    $timeout,
    Made,
    $scope,
    $rootScope,
    lodash
  ) {
    Object.assign(this, {
      PDFJS,
      $http,
      fileService,
      NotificationService,
      $timeout,
      Made,
      $scope,
      $rootScope,
      lodash
    });

    this.$onInit = async () => {
      this.loading = {
        main: true
      };
      this.contract_url = this.contracturl;
      this.canDownload = !!this.canDownload;
      this.isMadeFile = !!this.isMadeFile;
      this.PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");
      await this.generateContract();

      this.loading = {
        main: false
      };
    };

  }


  async generateContract() {
    let result = null;
    if (this.contract_url) {

      if (this.isMadeFile) {
        let request = {
          method: 'GET',
          responseType: 'blob',
          url: this.contract_url
        };

        result = await this.$http(request);
      } else {
        result = await this.Made.getDocument({
          file_url: this.contract_url,
          type: 'from_url'
        })
      }

    }

    try {

      if (!result) {
        throw Error('Something went wrong with contract generation.');
      }
      this.contractBLOB = result.data;

      this.loadingTask = this.PDFJS.getDocument({
        data: new Uint8Array(await result.data.arrayBuffer())
      });

      await this.showPDF2();
    } catch (e) {
      alert(e.message);
      this.$rootScope.$broadcast('contract-preview-error', e);
    }
  }

  downloadPDF() {
    this.fileService.downloadFile(this.contractBLOB, 'contract.pdf', 'application/pdf');
  }

  async showPDF2() {
    let pdf = await this.loadingTask.promise;
    let viewer = document.getElementById('pdf-viewer');

    for (let pageIndex = 1; pageIndex <= pdf.numPages; pageIndex++) {
      let page = await pdf.getPage(pageIndex);
      let scale = 1;
      let viewport = page.getViewport({ scale: scale });
      let canvas = document.createElement('canvas');
      canvas.className = 'pdf-page-canvas';
      viewer.appendChild(canvas);
      let context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      let renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      await page.render(renderContext).promise;
    }

    this.$timeout(() => { });
  }
}

ContractPreviewComponentController.$inject = $inject;

import contract_preview_component_html from "./contract_preview_component.pug";

angular
  .module('VSPApp')
  .controller('ContractPreviewComponentController', ContractPreviewComponentController)
  .component('contractPreview', {
    controller: 'ContractPreviewComponentController',
    controllerAs: 'ContractPreviewCtrl',
    template: contract_preview_component_html(),
    bindings: {
      contracturl: '<',
      isMadeFile: '<',
      canDownload: '<'
    }
  });
