export class DTicketConfig {

  constructor(
    public ordersApproval: boolean,
    public percentageSubsidy: string,
    public changeOfSubsidy: boolean,
    public euroSubsidy: string,
  ) {

  }

  static getInstanceFromObject(data: {
    ordersApproval: boolean,
    percentageSubsidy: string,
    changeOfSubsidy: boolean,
    euroSubsidy: string
  }) {
    return new this(
      data.ordersApproval,
      data.percentageSubsidy,
      data.changeOfSubsidy,
      data.euroSubsidy
    );
  }

}
