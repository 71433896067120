import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DirectBonusService } from "../../../services/direct-bonus.service";
import { ExtendDeadlineDialogData } from "../../../models/direct-bonus-table-dialog-data.model";
import * as moment from "moment";
import { AlertService } from 'src/app/shared/services/alert.service';


const newDeadlineDateValidator: () => ValidatorFn = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value

    if (!value) {
      return null
    }

    const endOfYesterday = moment().subtract(1, 'days').endOf('day');
    const endOfCurrentMonth = moment().endOf('month');

    const deadlineDate = moment(value);

    const deadlineValid = endOfYesterday.isBefore(deadlineDate) && endOfCurrentMonth.isAfter(deadlineDate);

    return !deadlineValid ? { deadlineInvalid: true } : null;
  }
}

interface FormChoices {
  ticketUrl: string;
  newDeadline: Date;
}

@Component({
  selector: 'vn-bonus-order-extend-deadline-dialog',
  templateUrl: './bonus-order-extend-deadline-dialog.component.html',
  styleUrls: ['./bonus-order-extend-deadline-dialog.component.scss']
})
export class BonusOrderExtendDeadlineDialogComponent implements OnInit {

  public formGroup!: FormGroup;

  public get ticketUrlControl(): FormControl {
    return this.formGroup.get('ticketUrl') as FormControl;
  }

  public get deadlineDateControl(): FormControl {
    return this.formGroup.get('newDeadline') as FormControl;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BonusOrderExtendDeadlineDialogComponent>,
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public dialogData: ExtendDeadlineDialogData,
  ) { }

  ngOnInit(): void {
    this.initForms();
  }

  private initForms() {
    this.formGroup = this.formBuilder.group({
      ticketUrl: this.formBuilder.control(undefined, [
        Validators.required,
        Validators.maxLength(160)
      ]),
      newDeadline: this.formBuilder.control(undefined, [
        Validators.required,
        newDeadlineDateValidator()
      ]),
    });
  }

  public extendDeadline() {
    const {
      ticketUrl,
      newDeadline
    } = this.formGroup.value as FormChoices;

    this.directBonusService.extendDeadline(
      this.dialogData.orderId,
      ticketUrl,
      newDeadline
    ).subscribe(
      () => {
        this.alertService.message(`Sie haben dem Kunden eine neue Frist für Bearbeitung und Bestätigung der Bestellung gesetzt. Die neue Frist ist:${moment(newDeadline).format('MM.DD.YYYY')}. Die Bestellung hat jetzt den Status “Entwurf”.`);
        this.dialogRef.close(true);
      },
      (error: Error) => {
        this.alertService.error('Es ist ein Fehler aufgetreten.')
      }
    )
  }

}
