const $inject = [
  '$state',
  '$scope',
  'Made',
  'BonusService',
  'employeeService',
  '$timeout',
  'NotificationService',
  'mppService',
  'dialogService',
  'lodash',
  '$q',
  'administrationService',
  'MppConstants',
  'authenticationService',
  'fileService',
  'VSP_CONSTANTS',
  '$injector',
  'NeoComponentsService',
  '$window',
  'moment'
];
export default class PreendPCDialogController {

  constructor(
    $state,
    $scope,
    Made,
    BonusService,
    employeeService,
    $timeout,
    NotificationService,
    mppService,
    dialogService,
    lodash,
    $q,
    administrationService,
    MppConstants,
    authenticationService,
    fileService,
    VSP_CONSTANTS,
    $injector,
    NeoComponentsService,
    $window,
    moment
  ) {
    Object.assign(this, {
      $state,
      $scope,
      Made,
      BonusService,
      employeeService,
      $timeout,
      NotificationService,
      mppService,
      dialogService,
      lodash,
      $q,
      administrationService,
      MppConstants,
      authenticationService,
      fileService,
      VSP_CONSTANTS,
      $injector,
      NeoComponentsService,
      $window,
      moment
    });

    this.loading = {
      main: false
    };

    this.forms = {
      CancelPCForm: {}
    };

    this.init().finally(()=>{
      this.$timeout(()=>{
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    this.basket = angular.copy(this.$scope.ngDialogData.basket);
    this.pc_component = this.$scope.ngDialogData.pc_component;

    this.preend_date_validation_dates = {
      'min_date': this.basket['beginDate'],
      'max_date': this.basket['endDate']
    };

    this.is_bonus_basket = this.basket && !!this.basket.bonus;

    this.preend_information = {};

    this.$timeout(()=>{
      this.loading.main = false;
    });
    return;
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async cancelPC() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    let is_cancellation_successful = false;

    if (this.is_bonus_basket) {
      is_cancellation_successful = await this.BonusService.administrationBonusCancel({
        basket_id: this.basket._id,
        new_end_date: this.preend_information.preend_date,
        comment: this.preend_information.comment,
        ticket_number: this.preend_information.ticket_number,
        force_buying_process: this.preend_information.force_buying_process
      }, );
    } else {
      is_cancellation_successful = await this.pc_component.preend_pc(
        this.preend_information
      )
    }

    this.$timeout(()=>{
      this.loading.main = false;
    });
    this.$scope.closeThisDialog();
  }
}
PreendPCDialogController.$inject = $inject;
