import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'vn-email-and-phone-validator',
  templateUrl: './email-and-phone-validator.component.html',
  styleUrls: ['./email-and-phone-validator.component.scss']
})
export class EmailAndPhoneValidatorComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder
  ) { }

  @Input() initEmailValue: string = '';
  @Input() initPhoneValue: string = '';

  @Input() isEmailPrivate: boolean = false;

  @Input() isEmailHide: boolean = false;
  @Input() isPhoneHide: boolean = false;

  @Input() emailTitle: string = '';
  @Input() phoneTitle: string = '';

  @Input() cyData: string = '';

  @Input() isCompanyEmailAllowed: boolean = false;

  @Output() emailAddress$ = new EventEmitter<string | null>();
  @Output() phoneNumber$ = new EventEmitter<string | null>();

  public contactsValidatorForm!: FormGroup;

  ngOnInit(): void {
    this.initContactsValidatorForm();
  }

  public initContactsValidatorForm() {
    this.contactsValidatorForm = this.formBuilder.group({
      emailAddress: this.formBuilder.control('', []),
      phoneNumber: this.formBuilder.control('', [])
    });
  }

  private get emailAddress() {
    return this.contactsValidatorForm.get('emailAddress')?.value;
  }

  private get phoneNumber() {
    return this.contactsValidatorForm.get('phoneNumber')?.value;
  }

  public emitEmailValidationResult(isValid: boolean) {
    if(isValid) {
      this.emailAddress$.emit(this.emailAddress);
    } else {
      this.emailAddress$.emit(null);
    }
  }

  public emitPhoneValidationResult(isValid: boolean) {
    if(isValid) {
      this.phoneNumber$.emit(this.phoneNumber);
    } else {
      this.phoneNumber$.emit(null);
    }
  }

}
