const $inject = [
  '$scope',
  '$timeout',
  'mppService',
  'fileService'
];
export default class delivered_controller {
  constructor(
    $scope,
    $timeout,
    mppService,
    fileService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      mppService,
      fileService
    });

    this.loading = {
      main: false,
      saving: false,
    };

    this.init().finally(()=>{
      this.$timeout(()=>{
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    this.basket = this.$scope.ngDialogData.basket;
    this.basket_id = this.basket['_id'];

    await this.getBasketInvoices();

    this.$timeout(()=>{
      this.loading.main = false;
    });
  }

  async getBasketInvoices() {
    this.basket_invoices = [];
    return this.mppService.getBasketInvoices({basket_id: this.basket['_id']})
      .then((basket_invoices) => {
        this.basket_invoices = basket_invoices;
      });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async sendOrderToLeasingCompany() {
    this.$timeout(()=>{
      this.loading.saving = true;
    });
    await this.mppService.sendOrderToLeasingCompany({basket_id: this.basket_id});
    this.$scope.confirm();

    this.$timeout(()=>{
      this.loading.saving = false;
    });
  }

  openDocument(file) {
    this.fileService.openDocument(file._id);
  }

}
delivered_controller.$inject = $inject;
