import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoadingService} from "../../../shared/services/loading.service";

interface DticketStreufreiData {
  component_id: string,
  neo_project: any
}

interface DTicketNEOProjectConfig {
  selfservice: boolean,
  enabled: boolean,
  flat_tax_type: string,
  cost_model: string
}

enum FlatTaxType {
  EMPLOYER_FLAT_TAX = "employer_flat_tax",
  EMPLOYEE_FLAT_TAX = "employee_flat_tax",
  NET_PAYMENT = "net_payment",
}

enum CostModelType {
  AN = "an",
  AG = "ag",
  NO_TAX = 'no_tax',
}

interface FlatTaxToCostModelMapping {
  [FlatTaxType.EMPLOYER_FLAT_TAX]: CostModelType.AG;
  [FlatTaxType.EMPLOYEE_FLAT_TAX]: CostModelType.AN;
  [FlatTaxType.NET_PAYMENT]: CostModelType.NO_TAX;
}


interface DTicketTaxTypeOptions {
  name: string;
  value: FlatTaxType.EMPLOYER_FLAT_TAX | FlatTaxType.EMPLOYEE_FLAT_TAX | FlatTaxType.NET_PAYMENT;
}
@Component({
  selector: 'vn-dticket-order-steuerfrei-dialog',
  templateUrl: './dticket-order-steuerfrei-dialog.component.html',
  styleUrls: ['./dticket-order-steuerfrei-dialog.component.scss']
})
export class DticketOrderSteuerfreiDialogComponent implements OnInit {

  formGroup!: FormGroup;
  neo_project: any;
  component_id: string
  taxTypeOptions: DTicketTaxTypeOptions[];
  initTaxTypeOption: FlatTaxType;
  dTicketCurrentConfig: DTicketNEOProjectConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DticketStreufreiData,
    public dialogRef: MatDialogRef<DticketOrderSteuerfreiDialogComponent>,
    private formBuilder: FormBuilder,
    private loader: LoadingService,
    private ngZone: NgZone
  ) {
    this.component_id = this.dialogData.component_id;
    this.neo_project = this.dialogData.neo_project;
    this.dTicketCurrentConfig = this.neo_project.neo_components[this.component_id]

    this.taxTypeOptions = [
      {
        name: 'Entgeltumwandlung - AG trägt Pauschalsteuer',
        value: FlatTaxType.EMPLOYER_FLAT_TAX,
      },
      {
        name: 'Entgeltumwandlung - AN trägt Pauschalsteuer',
        value: FlatTaxType.EMPLOYEE_FLAT_TAX,
      },
      {
        name: 'Netto-Abzug - keine Pauschalsteuer',
        value: FlatTaxType.NET_PAYMENT,
      }
    ];

    this.initTaxTypeOption = this.taxTypeOptions.filter((option: DTicketTaxTypeOptions) => {
      return option.value === this.dTicketCurrentConfig.flat_tax_type
    })[0].value;
  }

  ngOnInit(): void {

    this.formGroup = this.formBuilder.group({
      flatTaxType: this.formBuilder.control("AN", []),
      costModels: this.formBuilder.control("exchange with employer + flat tax", [])
    });
  }

  close() {
    const { flatTaxType } : { flatTaxType: FlatTaxType }= this.formGroup.value;

    const costModelOptionsMapping: FlatTaxToCostModelMapping = {
      [FlatTaxType.EMPLOYEE_FLAT_TAX]: CostModelType.AN,
      [FlatTaxType.EMPLOYER_FLAT_TAX]: CostModelType.AG,
      [FlatTaxType.NET_PAYMENT]: CostModelType.NO_TAX
    }

    const updatedComponentConfig: DTicketNEOProjectConfig = {
      ...this.neo_project.neo_components[this.component_id],
      flat_tax_type: flatTaxType,
      cost_model: costModelOptionsMapping[flatTaxType]
    }

    this.neo_project.neo_components[this.component_id] = updatedComponentConfig;

    this.dialogRef.close();
  }

}
