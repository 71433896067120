import { APPROVAL_PROCESS_STATES_BY_NAME } from './administrationService';

export const NEO_BIKELEASING_STATES = [
  {
    'id': 0,
    'name': 'created',
    'display': 'Created',
    'description': 'Bikeleasing is created',
    'settings': {
      is_present_in_filter: true
    }
  },
  {
    'id': 1,
    'name': 'approval_process',
    'display': 'Freigabeanfrage',
    'description': 'Bikleasing is in Approval Process',
    'settings': {
      is_present_in_filter: true
    }
  },
  {
    'id': 2,
    'name': 'approved',
    'display': 'Freigegeben',
    'description': 'Bikeleasing is Approved',
    'settings': {
      is_present_in_filter: true
    }
  },
  {
    'id': 3,
    'name': 'declined',
    'display': 'Abgelehnt',
    'description': 'Bikeleasing is Declined',
    'settings': {
      is_present_in_filter: true
    }
  },
];
export const NEO_BIKELEASING_STATES_BY_ID = NEO_BIKELEASING_STATES.reduce((acc, state) => {
  acc[state['id']] = state;
  return acc;
}, {});
export const NEO_BIKELEASING_STATES_BY_NAME = NEO_BIKELEASING_STATES.reduce((acc, state) => {
  acc[state['name']] = state;
  return acc;
}, {});

export const NEO_BIKELEASING_FILTER_CONFIGURATION = {
  bikeleasing_state: {
    selected: [],
    options: [...NEO_BIKELEASING_STATES
      .filter(state => {
        return state['settings']['is_present_in_filter'];
      })
    ]
  }
};


export const NEO_BIKELEASING_FLOWS = [
  {
    'id': 0,
    'name': 'approve',
    'display': 'Approv Flow',
    'transitions': {
      [NEO_BIKELEASING_STATES_BY_NAME['approval_process']['id']]: {
        'reason': 'bikeleasing_approved',
        'to': NEO_BIKELEASING_STATES_BY_NAME['approved']['id'],
        'validations': [
          {
            'id': 0,
            'name': 'is_customer_approved',
            'type': 'process_validation',
            'process': 'approval_process',
            'process_in_states': [APPROVAL_PROCESS_STATES_BY_NAME['external_approved']['id']]
          }
        ]
      }
    }
  },
  {
    'id': 1,
    'name': 'decline',
    'display': 'Decline Flow',
    'reason': 'bikeleasing_declined',
    'transitions': {
      [NEO_BIKELEASING_STATES_BY_NAME['approval_process']['id']]: {
        'to': NEO_BIKELEASING_STATES_BY_NAME['declined']['id']
      },
      [NEO_BIKELEASING_STATES_BY_NAME['approved']['id']]: {
        'to': NEO_BIKELEASING_STATES_BY_NAME['declined']['id']
      },
    }
  },
  {
    'id': 2,
    'name': 'start_approval_process',
    'display': 'Start approval process',
    'reason': 'start_approval_process',
    'transitions': {
      [NEO_BIKELEASING_STATES_BY_NAME['created']['id']]: {

        'to': NEO_BIKELEASING_STATES_BY_NAME['approval_process']['id']
      }
    }
  },
  {
    'id': 3,
    'name': 'confirm_pickup',
    'display': 'Confirm PickUp',
    'reason': 'pick_up_confirmation',
    'transitions': {
      [NEO_BIKELEASING_STATES_BY_NAME['approved']['id']]: {
        'to': NEO_BIKELEASING_STATES_BY_NAME['approved']['id']
      }
    }
  }
];

export const NEO_BIKELEASING_FLOWS_BY_NAME = NEO_BIKELEASING_FLOWS.reduce((acc, flow) => {
  acc[flow['name']] = flow;
  return acc;
}, {});


const $inject = [
  'Made',
  'vbmService',
  'CommonService'
];
export default class bikeleasingService {

  constructor (Made, vbmService, CommonService) {

    Object.assign(this, {
      Made,
      vbmService,
      CommonService
    });

    this.bikeConfig = {};
  }

  async getDetailedResult (bikeConfig, input, data) {
    this.bikeConfig = bikeConfig;
    const durationLeasing = 36;

    let bikeleasingValue = await this.getUmwandlung(bikeConfig, input);
    data.neo_components.Bikeleasing.value = bikeleasingValue ? bikeleasingValue : 0;
    data.neo_components.Bikeleasing.enabled = true;
    data.neo_components.Bikeleasing.listenpreis = input.listenpreis;
    data.neo_components.Bikeleasing.verkaufspreis = input.verkaufspreis;
    data.neo_components.Bikeleasing.ontop = bikeConfig.onTop;

    let result = await this.vbmService.getSingleResult(data, 'Bikeleasing');

    let premPlus = false;
    let inspector = false;

    if (this.bikeConfig.traegerVersicherungPremiumPlus === 'ag' || input.versicherungPremAN) {
      premPlus = true;
    }
    if (this.bikeConfig.inspektion === 'ag' || input.inspection) {
      inspector = true;
    }

    let versicherungAN = await this.getVersicherungAN(input.verkaufspreis, true);
    let versicherungPremiumAN = await this.getVersicherungPremiumAN(input.verkaufspreis, premPlus);
    let inspektionAN = this.getInspektionAN(inspector);
    let kaufpreis = input.verkaufspreis;
    let listenpreis = input.listenpreis;

    let details = {
      direct: {
        kauf: 0,
        gesamt: 0
      },
      comp: {
        umwandlung: 0,
        rate: 0,
        aufwand: 0,
        kauf: 0,
        gesamt: 0,
        vorteilE: 0,
        vorteilP: 0
      }
    };

    details.comp.umwandlung = data.neo_components.Bikeleasing.value;

    let rate = details.comp.rate = result.before.tax.net_netto - result.final.tax.net_auszahlung;
    details.comp.aufwand = rate * durationLeasing;
    details.direct.kauf = kaufpreis;
    details.comp.kauf = listenpreis * this.bikeConfig.kauffaktor;
    details.direct.gesamt = kaufpreis + versicherungAN * durationLeasing + versicherungPremiumAN * durationLeasing + inspektionAN * durationLeasing;
    details.comp.gesamt = (listenpreis * this.bikeConfig.kauffaktor) + (rate * durationLeasing);
    details.comp.vorteilE = details.direct.gesamt - details.comp.gesamt;
    details.comp.vorteilP = ('' + Math.round((1 - (details.comp.gesamt / details.direct.gesamt)) * 10000) / 100).replace('.', ',');

    return details;
  }

  /**
   * Calculates the rates for the employee
   * */
  async getUmwandlung (bikeConfig, input) {

    let basisrate = await this.getBasisrate(input.verkaufspreis);
    if (this.bikeConfig.onTop) {
      basisrate = 0;
    }
    let versicherungAN = await this.getVersicherungAN(input.verkaufspreis, this.bikeConfig.traegerVersicherung == 'umwandlung_an');
    let versicherungPremiumAN = await this.getVersicherungPremiumAN(input.verkaufspreis, input.versicherungPremAN);
    let inspektionAN = this.getInspektionAN(input.inspection);

    return Math.max(Math.round((basisrate + versicherungAN + versicherungPremiumAN + inspektionAN - this.bikeConfig.foerderungAg) * 100) / 100, 0);

  }

  async getBasisrate (verkaufspreis) {
    if (this.bikeConfig.bruttoNettoRate == 'brutto') {
      return Math.round((verkaufspreis * this.bikeConfig.leasingfaktor) * 100) / 100;
    }

    let netto_price = await this.CommonService.remove_vat(verkaufspreis);
    return Math.round(netto_price * this.bikeConfig.leasingfaktor * 100) / 100;
  }

  async getVersicherungAN (verkaufspreis, active) {
    if (!active) {
      return 0;
    }

    let insuranceRate = this.getBikeInsuranceRate(verkaufspreis);

    if (this.bikeConfig.bruttoNettoRate == 'brutto') {
      return this.CommonService.add_vat(insuranceRate);
    }

    return insuranceRate;

  }

  async getVersicherungPremiumAN (verkaufspreis, active) {
    if (!active) {
      return 0;
    }

    let insuranceRate = this.getBikeInsuranceRate(verkaufspreis);

    if (this.bikeConfig.bruttoNettoRate == 'brutto') {
      return this.CommonService.add_vat(insuranceRate);
    }

    return insuranceRate;

  }

  getInspektionAN (active) {
    if (!active) {
      return 0;
    }

    if (this.bikeConfig.bruttoNettoRate == 'brutto') {
      return 4.76;
    }

    return 4;
  }

  getBikeInsuranceRate (value) {
    let rating = {
      1500: 5.9,
      3000: 7.9,
      4000: 9.9,
      5000: 11.9,
      6000: 13.9,
      7000: 15.9,
      8000: 16.9,
      9000: 17.9,
      10000: 18.9,
      11000: 19.9,
      12000: 20.9,
      13000: 21.9,
      14000: 22.9,
      15000: 23.9,
    };

    for (let rate in rating) {
      if (/^[0-9]+$/.test(rate)) {
        if (value <= rate) {
          return rating[rate];
        }
      }
    }

    return 5.9;
  }

  giveNeoBikeLeasing (employee_id, bikeleasing_checkout_config) {
    employee_id = parseInt(employee_id);
    // ToDo: Move to approval service
    return this.Made.request('rpc://valuenetdb/neo_bikeleasing/create', {
      employee_id: employee_id,
      begin_date: bikeleasing_checkout_config.begin_date,
      per_month_value: bikeleasing_checkout_config.per_month_value,
      full_amount: bikeleasing_checkout_config.full_amount,
      validation_url: bikeleasing_checkout_config.validation_url,
      validation_code: bikeleasing_checkout_config.validation_code,
      ticket_number: bikeleasing_checkout_config.ticket_number,
      tax_base_amount: bikeleasing_checkout_config.tax_base_amount,
      contract_id: bikeleasing_checkout_config.contract_id
    })
      .then(bikleasing => {
        let FILE_TAGS = [
          'neo',
          'bike_approval',
          'Bikeleasing',
          bikleasing._id,
          'backoffice_upload_timestamp-' + new Date().getTime() / 1000
        ];

        let contract = bikeleasing_checkout_config.file;
        return this.Made.upload(contract, FILE_TAGS, employee_id);
      });
  }

  updateNeoBikeleasing (options) {
    let params = {
      bikeleasing_id: options.bikeleasing_id,
      log_comment: options.log_comment,
      begin_date: options.updated_begin_date
    };

    return this.Made.request('rpc://valuenetdb/neo_bikeleasing/update', params);
  }

  approveNeoBikeleasing (options) {
    let params = {
      bikeleasing_id: options.bikeleasing_id
    };

    return this.Made.request('rpc://valuenetdb/neo_bikeleasing/approve', params);
  }

  declineNeoBikeleasing (options) {
    let params = {
      bikeleasing_id: options.bikeleasing_id,
      log_comment: options.log_comment
    };

    return this.Made.request('rpc://valuenetdb/neo_bikeleasing/decline', params);
  }

  confirmPickup(options){
    let params = {
      bikeleasing_id: options.bikeleasing_id,
      pickup_date: options.pickup_date
    };

    return this.Made.request('rpc://valuenetdb/neo_bikeleasing/confirm_pickup', params);

  }

  getActiveNeoBikeleasing(options) {
    let params = {
      employee_id: options.employee_id
    };
    return this.Made.request('rpc://valuenetdb/neo_bikeleasing/get_active', params);
  }

  getNeoBikeleasing(options) {
    let params = {
      employee_id: options.employee_id,
      employee_ids: options.employee_ids,
      neo_bikeleasing_id: options.neo_bikeleasing_id,
      to_attach_validations: options.to_attach_validations,
    };

    return this.Made.request('rpc://valuenetdb/neo_bikeleasing/get', params);

  }

}

bikeleasingService.$inject = $inject;
