const $inject = [
  '$scope', 'mppService', '$timeout', 'employeeService', 'lodash'
];
export class BasketOverviewCheckout {
  constructor ($scope, mppService, $timeout, employeeService, lodash) {
    Object.assign(this, {
      $scope,
      mppService,
      $timeout,
      employeeService,
      lodash
    });

    this.$onInit = async () => {
      this.loading = true;
      this.basket_id = this.basketId;

      this.settings = {
        to_validate : this.lodash.get(this.options, 'to_validate', true)
      };

      if (!this.basket && this.basket_id){
        this.basket = await this.mppService.getCartById(this.basket_id);
      } else {
        this.basket_id = this.basket._id;
      }

      if (this.settings.to_validate) {
        if (!this.basket_id) {
          throw new Error('Can\'t work without basket object passed to component.');
        }

        if (!this.errors){
          throw new Error('Can\'t work without errors object passed to component.');
        }

        this.customer_id = parseInt(await this.employeeService.getCustomerId());

        await this.validateBasket();
      }

      this.$timeout(()=>{
        this.loading = false;
      });
    };
  }

  async validateBasket(){
    this.errors.mppError = await this.mppService.validateBasketById(this.basket_id);
    this.errors.mppPriceError = await this.mppService.validateBasketArticlePrices(this.basket_id);
    this.errors.custMPP = await this.mppService.validateCustomerMPPLimit(this.customer_id, this.basket_id);
  }
}
BasketOverviewCheckout.$inject = $inject;

import basket_overview_checkout_html from './BasketOverviewCheckout.pug';

angular
  .module( 'VSPApp' )
  .controller('BasketOverviewCheckout', BasketOverviewCheckout)
  .component('basketOverviewCheckoutComponent', {
    controller: 'BasketOverviewCheckout',
    controllerAs: '$ctrl',
    template: basket_overview_checkout_html(),
    bindings: {
      errors: '=',
      basket: '<',
      basketId: '<',
      options: '<'
    }
  });
