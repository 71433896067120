const FeedbackConstants = {
  modules: [
    {
      id: 'neo',
      name: 'Steuerfreie Extras'
    },
    {
      id: 'insurances',
      name: 'Versicherungen'
    },
    {
      id: 'vorsorge',
      name: 'Vorsorge'
    },
    {
      id: 'cashback',
      name: 'Rechtliche Hilfe'
    },
    {
      id: 'shop',
      name: 'Rabatte'
    },
    {
      id: 'benefit',
      name: 'Steuerfrei Shoppen'
    }
  ],
  moduleQuestions: [
    'Würden Sie das Modul $module nutzen?',
    'Denken Sie unsere Mitarbeiter würden das Modul $module nutzen?'
  ],
  generalQuestions: [
    'Können wir mit dem Portal die Zufriedenheit unserer Mitarbeiter verbessern?',
    'Wollen wir die „Steuerfreien Extras“ für Bonuszahlungen nutzen?',
    'Wollen wir die „Steuerfreien Extras“ für Gehaltserhöhungen nutzen?',
    'Wollen wir die „Steuerfreien Extras“ für Neueinstellungen nutzen?',
    'Würde das Portal uns helfen neue Fachkräfte zu finden?',
    'Würde das Portal uns helfen Mitarbeiter an unser Unternehmen zu binden?',
    'Sollen wir das Portal in unserem Corporate Design beauftragen?',
    'Sollen wir die mobile App (Smartphone & Tablets) in unserem Corporate Design beauftragen?',
    'Waren alle Module/Bausteine gut erklärt und einfach zu bedienen?'
  ],
  textQuestions: [
    'Welche Module haben Ihnen am besten gefallen und warum?',
    'Welche Module haben Ihnen nicht gefallen und warum?',
    'Was gefällt Ihnen am Portal am besten und warum?',
    'Was gefällt Ihnen nicht am Portal und warum?',
    'Weitere Kommentare und Anregungen.'
  ]
};

export default FeedbackConstants;
