import {BONUS_STATES_BY_NAME} from "./bonus_state";

export const DEFAULT_BONUS_BUDGET_DISPLAY = [
  // these will not include ALL of the different bonus states but only those that must be displayed in Bonus Administration and Bonus Control Process;
  {label: 'Überprüfung Nachweise', for_bonuses_in_states: [BONUS_STATES_BY_NAME['first_check']['id']], id: 10},
  {label: 'Warten auf Vertrag', for_bonuses_in_states: [BONUS_STATES_BY_NAME['checkout_finished']['id']], id: 1},
  {label: 'Kontrolle', for_bonuses_in_states: [BONUS_STATES_BY_NAME['control_start']['id']], id: 2},
  {label: 'Freigegeben', for_bonuses_in_states: [BONUS_STATES_BY_NAME['approved']['id']], id: 3},
  {label: 'FGI Abgelehnt', for_bonuses_in_states: [BONUS_STATES_BY_NAME['approval_declined']['id']], id: 7},
  {label: 'Abgelehnt', for_bonuses_in_states: [BONUS_STATES_BY_NAME['declined']['id']], id: 8},
  {label: 'Nachbearbeitung Vertrag', for_bonuses_in_states: [BONUS_STATES_BY_NAME['amendment']['id']], id: 4},
  {
    label: 'Nachbearbeitung Dokumente',
    for_bonuses_in_states: [BONUS_STATES_BY_NAME['amendment_first_check']['id']],
    id: 11
  },
  {label: 'Freigabenanfrage', for_bonuses_in_states: [BONUS_STATES_BY_NAME['awaiting_approval_process']['id']], id: 5},
  {
    label: 'Freigabe Nutzerentscheidung',
    for_bonuses_in_states: [BONUS_STATES_BY_NAME['user_decision_process']['id']],
    id: 12
  },
  {label: 'FGI Freigegeben', for_bonuses_in_states: [BONUS_STATES_BY_NAME['approval_approved']['id']], id: 6},
  {
    label: 'Awaiting Order Process',
    for_bonuses_in_states: [BONUS_STATES_BY_NAME['awaiting_order_confirmation']['id']],
    id: 13
  },
];
