angular
  .module('VSPApp')
  .controller('CashbackHomeController', [
    "$scope",
    "CashbackConstants",
    "customerService",
    function (
      $scope,
      CashbackConstants,
      customerService
    ) {

      $scope.detailsArray = [];

      customerService
        .getConfiguration()
        .then(configuration => {

          var blacklist = [];

          angular.forEach(configuration.cashback, function (module, key) {
            if (!module.enabled) blacklist.push(key);
          });

          angular.forEach(CashbackConstants, function (module, index) {
            if (-1 === blacklist.indexOf(index)) $scope.detailsArray.push(module);
          });
        });

    }]);
