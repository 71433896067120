const SVCalculationConstants = [
  {
    value: 'OST',
    name: 'Ost'
  },
  {
    value: 'WEST',
    name: 'West'
  },
  {
    value: 'OST_SACHSEN',
    name: 'Sachsen'
  }
];

export default SVCalculationConstants;
