import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import * as moment from 'moment';

// ===============================================================

interface FilterOptions {
  year: string[];
}

interface FilterChoices {
  year: number;
}

// ===============================================================

@Component({
  selector: 'vn-customer-download-table-dialog',
  templateUrl: './customer-download-table-dialog.component.html',
  styleUrls: ['./customer-download-table-dialog.component.scss']
})
export class CustomerDownloadTableDialogComponent implements OnInit {
  public readonly currentYear = moment().year();

  public get bonusProjectId(): string {
    return this.dialogData.bonusProjectId!;
  }

  public get customerId(): number {
    return this.dialogData.customerId!;
  }

  public get currentYearString() {
    return this.currentYear.toString();
  }

  private get year(): FormControl {
    return this.filtersGroup.get('year') as FormControl;
  }

  public filtersGroup!: FormGroup;
  public filterOptions!: FilterOptions;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CustomerDownloadTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
  ) {
    this.initFilterOptions();
    this.initFiltersForm();
  }

  ngOnInit(): void {
  }

  private initFiltersForm() {

    this.filtersGroup = this.formBuilder.group({
      year: this.formBuilder.control(this.currentYear, [
        Validators.required,
      ]),
    });
  }

  private initFilterOptions() {
    // TODO pass validated year options
    
    this.filterOptions = {
      year: [
        this.currentYear - 3,
        this.currentYear - 2,
        this.currentYear - 1,
        this.currentYear,
        this.currentYear + 1,
        this.currentYear + 2
      ].map(
        (year) => year.toString()
      ),
    }
  }

  public downloadTableAsFile() {
    const selectedYear: string = this.year.value;

    this.dialogRef.close(selectedYear);
  }
}
