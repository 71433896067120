const { BigDecimal } = require('bigdecimal');
 

export default  class DatevCalculatorInput {
  constructor(props = {}) {
    /**  */
    this.af   =  1;

    /**  */
    this.AJAHR     =  0;

    /**  */
    this.ALTER1    =  0;

    /**  */
    this.ENTSCH    =  new BigDecimal(0);

    /**  */
    this.f         =  1.0;

    /**  */
    this.JFREIB    =  new BigDecimal(0);

    /**  */
    this.JHINZU    =  new BigDecimal(0);

    /**  */
    this.JRE4      =  new BigDecimal(0);

    /**  */
    this.JVBEZ     =  new BigDecimal(0);

    /**  */
    this.KRV       =  0;

    /**  */
    this.KVZ       =  new BigDecimal(1);

    /**
     * 1 = Jahr
     * 2 = Monat
     * 3 = Woche
     * 4 = Tag
     * */
    this.LZZ       =  0;

    /**  */
    this.LZZFREIB  =  new BigDecimal(0);

    /**  */
    this.LZZHINZU  =  new BigDecimal(0);

    /**  */
    this.PKPV      =  new BigDecimal(0);

    /**  */
    this.PKV       =  0;

    /**  */
    this.PVS       =  0;

    /**  */
    this.PVZ       =  0;

    /**  */
    this.R         =  0;

    /**  */
    this.RE4       =  new BigDecimal(0);

    /**  */
    this.SONSTB    =  new BigDecimal(0);

    /**  */
    this.STERBE    =  new BigDecimal(0);

    /**
     * 1 = I
     * 2 = II
     * 3 = III
     * 4 = IV
     * 5 = V
     * 6 = VI
     * */
    this.STKL      =  0;

    /**  */
    this.VBEZ      =  new BigDecimal(0);

    /**  */
    this.VBEZM     =  new BigDecimal(0);

    /**  */
    this.VBEZS     =  new BigDecimal(0);

    /**  */
    this.VBS       =  new BigDecimal(0);

    /**  */
    this.VJAHR     =  0;

    /**  */
    this.VKAPA     =  new BigDecimal(0);

    /**  */
    this.VMT       =  new BigDecimal(0);

    /**  */
    this.ZKF       =  new BigDecimal(0);

    /**  */
    this.ZMVB      =  0;

    /**  */
    this.JRE4ENT   =  new BigDecimal(BigDecimal.ZERO().toString());

    /**  */
    this.SONSTENT  =   new BigDecimal(BigDecimal.ZERO().toString());



    Object.keys(props).map(key => {
      if (this[key] instanceof BigDecimal) {
        this[key] = new BigDecimal(props[key]);
      } else {
        this[key] = props[key];
      }
    });
  }
}
