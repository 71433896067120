var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      







































































































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv\u003E\u003Cdiv class=\"box\" id=\"currentFinances\" sidebar-scroll\u003E\u003Cdiv class=\"title\"\u003E\u003Cspan\u003EÜbersicht\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\" overlay=\"$bonagoSide.loading.main\"\u003E\u003Cul\u003E\u003Cli\u003E\u003Cspan class=\"key light\"\u003EGuthaben\u003C\u002Fspan\u003E\u003Cspan class=\"value\"\u003E{{ $bonagoSide.user_balance | currency}}\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"$bonagoSide.iAmSpecialAdvisor &amp;&amp; !$bonagoSide.BenefitBonagoService.user_checks.has_valid_user_code\"\u003E\u003Cspan class=\"red\"\u003EUser hat noch keinen UV-Code.\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"$bonagoSide.iAmSpecialAdvisor &amp;&amp; !$bonagoSide.BenefitBonagoService.user_checks.employee_has_sachbezug_with_bonago &amp;&amp; !$bonagoSide.isUserCodeIdForSelectedScheme()\"\u003E\u003Cspan class=\"red\"\u003EUser hat einen falschen UV-Code.\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"$bonagoSide.iAmSpecialAdvisor &amp;&amp; !$bonagoSide.BenefitBonagoService.user_checks.employee_has_sachbezug_with_bonago\"\u003E\u003Cdiv class=\"red\"\u003EUser hat noch kein Vorteilskonto aktiviert.\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"$bonagoSide.iAmSpecialAdvisor &amp;&amp; $bonagoSide.BenefitBonagoService.user_checks.unknown_scheme_id\"\u003E\u003Cdiv class=\"red\"\u003EDas ausgewählte Schema {{ $bonagoSide.BenefitBonagoService.user_checks.user_code_scheme_id }} existiert nicht. Bitte, ein neues Schema auswählen.\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"!$bonagoSide.BenefitBonagoService.user_checks.employee_has_sachbezug_with_bonago\"\u003E\u003Cdiv\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_mixins["select"].call({
attributes: {"key": "$bonagoSide.BenefitBonagoService.user_scheme","title": "Gutscheinauswahl ändern","attributes": {"ng-options":"scheme as scheme.display for scheme in $bonagoSide.BenefitBonagoService.customer_schemes"}}
});
},
attributes: {"name": "$bonagoSide.forms.scheme_form"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"!$bonagoSide.basket.hasItems()\"\u003E\u003Cspan\u003EKeine Produkte im Warenkorb\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cli class=\"blue has-dropdown\" ng-repeat=\"(voucher_id, voucher) in $bonagoSide.basketItems\"\u003E\u003Ca\u003E\u003Cspan class=\"key light\"\u003E\u003Ci class=\"font-icon icon-info\" ng-click=\"$bonagoSide.showVoucherDetails(voucher)\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003E{{voucher.quantity }} x&nbsp;\u003C\u002Fspan\u003E\u003Cspan\u003E{{voucher.retailer_name }} ({{ voucher.price | currency }})\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"value\"\u003E\u003Cspan style=\"margin-right: 20px\"\u003E{{voucher.value | currency }}\u003C\u002Fspan\u003E\u003Ci class=\"font-icon icon-delete\" style=\"margin-left: -17px\" ng-click=\"$bonagoSide.basket.removeItem(voucher_id)\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Cspan class=\"key light\"\u003ERest\u003C\u002Fspan\u003E\u003Cspan class=\"value\"\u003E{{ $bonagoSide.getRest() | currency }}\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"!$bonagoSide.getRest() &lt; 0\"\u003E\u003Cspan class=\"red\"\u003EIhr aktuelles Guthaben ist leider nicht ausreichend. bitte passen Sie ggf. Ihren Warenkorb an.\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cdiv class=\"center buttons\"\u003E\u003Cbutton class=\"button button-primary expand\" ng-disabled=\"!$bonagoSide.canCheckout()\" ng-click=\"$bonagoSide.startCheckout()\" data-cy=\"benefit.bonago.preCheckout.menuSide.buttonStartCheckout\"\u003EEinlösen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;