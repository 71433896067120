import lodash from 'lodash';
import {ApiResponse} from './api-response';
import {NeoComponentState} from './neo-component-state';

export class ComponentModel extends ApiResponse {
  
    constructor(options) {
        super();
        this._options = lodash.cloneDeep(options);
        options = lodash.cloneDeep(options);
        let state_id = options.state_id || NeoComponentState.AVAILABLE;

        Object.assign(this, {
            _component_name: options.component_name,
            _state_id: state_id,
            _state: options.constants.NEO_COMPONENT_STATE_BY_ID[state_id],
            _constants: options.constants,
            _injector: options.injector,
            _customer_component_configuration: options.customer_component_configuration,
            _neo_project_component_configuration: options.neo_project_component_configuration,
            _employee_id: options.employee_id
        });

        this._API = this._injector.get('Made');
    }

    _set_state_by_id(state_id) {
        Object.assign(this, {
            _state_id: state_id,
            _state: this._constants.NEO_COMPONENT_STATE_BY_ID[state_id]
        });
    }

    get customer_component_configuration() {
        return this._customer_component_configuration;
    }

    get neo_project_component_configuration() {
        return this._neo_project_component_configuration;
    }

    get constants() {
        return this._constants;
    }

    get state() {
        return this._state;
    }

    get id() {
        return this._component_name;
    }

    get employee_id() {
        return this._employee_id;
    }

    async doCheckout() {
        return Promise.reject('no implemented errror');
    }
}
