const $inject = [
  '$scope',
  '$state',
  'CheckoutService'
];
export default class resumeCheckoutController {

  constructor(
    $scope,
    $state,
    CheckoutService
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.CheckoutService = CheckoutService;

    CheckoutService
      .checkSession()
      .then(session => this.checkoutSession = session);

  }

  resume() {
    this.$state.go('inApp.neo.side.checkout.zusammenfassung');
    this.$scope.confirm();
  }

  cancel() {
    this.CheckoutService
      .leaveSession()
      .then(() => {
        this.$scope.confirm();
        location.reload();
      });

  }
}
resumeCheckoutController.$inject = $inject;
