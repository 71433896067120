import bonago_toc_html from '../../../../modules/benefit/bonago/checkout/bonago_toc/bonago_toc.pug'
const $inject = [
  'dialogService',
  '$timeout',
  '$state',
  'BenefitBonagoService',
  'NotificationService',
  'employeeService',
  'Made'
];
export default class BenefitBonagoCheckoutController {
  constructor(
    dialogService,
    $timeout,
    $state,
    BenefitBonagoService,
    NotificationService,
    employeeService,
    Made
  ) {
    Object.assign(this, {
      dialogService,
      $timeout,
      $state,
      BenefitBonagoService,
      NotificationService,
      employeeService,
      Made
    });

    this.loading = {
      main: true,
      checkout: false
    };
    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.user = this.Made.user;
    this.has_read_toc = false;
    this.forms = {};
    this.models = {read_toc: false};
    this.basket = this.BenefitBonagoService.getBasket();
    this.user_balance = await this.BenefitBonagoService.getUserBalance();
    this.delivery_address = (await this.employeeService.getIntegrityData())['address'];
    this.delivery_information = {
      ...this.delivery_address,
      'email': this.user.emails[0]
    };
  }

  hasVouchers() {
    return this.basket.hasItems();
  }

  cancelCheckout() {
    this.navigateToHome();
  }

  navigateToHome() {
    this.$state.go('inApp.benefit.bonago.side.home');
  }

  canFinishCheckout() {
    return this.hasVouchers() &&
      Object.values(this.forms).reduce((acc, form) => {
        acc = acc && form.$valid;
        return acc;
      }, true);
  }

  completeCheckout() {
    this.loading.checkout = true;
    this.BenefitBonagoService.completeCheckout({delivery_information: this.delivery_information})
      .then((result) => {
        this.NotificationService.message('Ihre Bestellung wird verschickt.');
        this.basket.emptyBasket();
        this.navigateToHome();
      })
      .catch(e => {
        let error_message = 'Ihre Bestellung konnte nicht verschickt werden.';
        if (e && e.error && e.error.message) {
          let error_message_code = e.error.message.split('\n')[0];

          if (error_message_code === 'BONAGO_OUT_OF_STOCK_ERROR') {
            error_message = 'Ein gewünschter Gutschein ist aktuell leider vergriffen';
          }

        }

        this.NotificationService.error(error_message);
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.checkout = false;
        });
      });
  }

  getBasketSum() {
    return this.basket.total;
  }

  getBalance() {
    return this.user_balance;
  }

  getRest() {
    if (this.basket) {
      return this.user_balance - this.basket.total;
    } else {
      return this.user_balance;
    }
  }

  handleTocChange() {
    if (this.models.read_toc) {
      if (!this.has_read_toc) {
        this.showToc().then(() => {
          this.$timeout(() => {
            this.models.read_toc = this.has_read_toc;
          });
        });
      }
    } else {
      this.has_read_toc = false;
    }
  }

  showToc() {
    return this.dialogService.ngDialog.openConfirm({
      template: bonago_toc_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BenefitBonagoTocDialogController',
      controllerAs: '$bonagoTocDialogController',
      width: 1200,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    })
      .then((result) => {
        this.has_read_toc = result;
      })
      .catch(() => {
        this.has_read_toc = false;
      });
  }
}
BenefitBonagoCheckoutController.$inject = $inject;
angular
  .module('VSPApp')
  .controller('BenefitBonagoCheckoutController', BenefitBonagoCheckoutController);
