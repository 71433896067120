var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, headerAttributes, id, title, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};




















































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}
pug_mixins["fieldset"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
fieldsetCounter++;
title = attributes.title || ''
delete attributes.title
headerAttributes = {}
if (!attributes.noCollapse) {
headerAttributes['ng-click'] = 'fieldset'+ fieldsetCounter +'.closed = !fieldset'+ fieldsetCounter +'.closed'
}
if (attributes.closed) {
headerAttributes['ng-init'] = 'fieldset'+ fieldsetCounter +'.closed =' + attributes.closed
delete attributes.closed
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "bigbox"},attributes]), true)) + "\u003E\u003Cdiv" + (pug.attrs(pug.merge([{"class": "header"},headerAttributes]), true)) + "\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (!attributes.noCollapse) {
pug_html = pug_html + "\u003Cspan" + (" class=\"openable\""+pug.attr("ng-class", '{selected: !fieldset'+ fieldsetCounter +'.closed}', true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"main\""+pug.attr("ng-hide", 'fieldset'+ fieldsetCounter +'.closed', true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"row detail-view cashback-details\"\u003E\u003Csection class=\"row first-section\"\u003E\u003Cdiv class=\"columns medium-6 right-gap\"\u003E\u003Ch1\u003E{{ details.longTitle }}\u003C\u002Fh1\u003E\u003Cp\u003E{{ details.description }}\u003C\u002Fp\u003E\u003Cul\u003E\u003Cli ng-repeat=\"bulletPoint in details.bulletPoints\"\u003E\u003Cdiv class=\"font-icon icon-check\"\u003E\u003C\u002Fdiv\u003E{{bulletPoint}}\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Cp class=\"small\"\u003E{{ details.description2 }}\u003C\u002Fp\u003E\u003Cdiv class=\"video-container\"\u003E\u003Cdiv class=\"video responsive\"\u003E\u003Ciframe src=\"{{ details.embedUrl }}\" webkitallowfullscreen=\"\" mozallowfullscreen=\"\" allowfullscreen=\"\"\u003E\u003C\u002Fiframe\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E\u003C!-- TODO: does not work--\u003E\u003C!--input(type=\"file\", made-store-file=\"myfiles.onefile\", ng-model='myfiles.onefile', ng-change=\"onFileInputChange()\")--\u003E\u003C!--form(ng-if='!success', name=\"myForm\", sf-schema=\"schema\", sf-form=\"form\", sf-model=\"inputModel\", ng-submit=\"submit(myForm, inputModel)\")--\u003E\u003C!--div(ng-if=\"!success\")--\u003E";
globals = {}
pug_html = pug_html + "\u003Cdiv ng-hide=\"success\"\u003E";
pug_mixins["form"].call({
block: function(){
pug_mixins["fieldset"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"mainWrapper\"\u003E\u003Cp\u003E\u003Cstrong\u003ESie möchten durch Verbraucherhilfe 24 Ihre Chancen auf Rechtsdurchsetzung prüfen lassen?\u003C\u002Fstrong\u003E\u003C\u002Fp\u003E\u003Cp\u003ENach der kostenlosen Prüfung durch spezialisierte Juristen gehen wir Ihnen innerhalb von 48 Stunden Bescheid, ob Ihr Antrag Aussicht auf Erfolg hat!\u003C\u002Fp\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "inputModel.vorname","title": "Vorname","validationMessages": {"required":"Bitte Eingabe prüfen."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "inputModel.nachname","title": "Nachname","validationMessages": {"required":"Bitte Eingabe prüfen."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "inputModel.email","type": "email","title": "E-Mail Adresse","validationMessages": {"required":"Bitte Eingabe prüfen."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "inputModel.telefon","title": "Telefon *","required": pug.escape(true),"validationMessages": {"required":"Bitte Eingabe prüfen."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "centered","title": "AUFKLÄRUNG, TIPPS UND ANLAUFSTELLE FÜR VERBRAUCHER","noCollapse": true}
});
pug_mixins["fieldset"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"mainWrapper\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-12\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel for=\"bemerkungen\"\u003EBemerkungen\u003C\u002Flabel\u003E\u003Ctextarea rows=\"5\" id=\"bemerkungen\" name=\"bemerkungen\" ng-model=\"inputModel.note\"\u003E\u003C\u002Ftextarea\u003E\u003Cp class=\"helpText\" ng-if=\"!cashbackForm.bemerkungen.$valid\"\u003EBitte Eingabe prüfen.\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-12\"\u003E";
pug_mixins["checkbox"].call({
attributes: {"key": "inputModel.agree","title": "Ich bin damit einverstanden, dass oben angegebene Daten an Verbraucherhilfe 24, VH24 GmbH, Albert-Roßhaupter-Str. 32, 81369 München, weitergegeben wird und mit mir per E-Mail Kontakt aufnehmen. *","required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-12 centered\"\u003E\u003Cp class=\"small\"\u003EMit * markierte Felder müssen ausgefüllt werden.\u003C\u002Fp\u003E\u003Cdiv class=\"button-container\"\u003E\u003Cbutton ng-disabled=\"!cashbackForm.$valid || testmode || loading\"\u003EKostenlose Anfrage stellen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "centered","title": "BITTE TRAGEN SIE HIER DIE DATEN DES ZU ÜBERPRÜFENDEN FALLES EIN.","noCollapse": true}
});
},
attributes: {"class": "form-padding","name": "cashbackForm","id": "cbForm","ng-submit": "submit()"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"success\"\u003E\u003Cp\u003EIhre Anfrage wurde verschickt.\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"abbinder\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 small-8 large-9 columns\"\u003E\u003Ch3\u003EVerbraucherhilfe24\u003C\u002Fh3\u003E\u003Cp\u003EWir vertreten Ihre Rechte. Durch unser Netzwerk von knapp 100 Spezial-Anwälten, helfen wir Ihnen bei der Durchsetzung Ihrer Ansprüche. Möglich macht dies unser effizientes Klageverfahren, mit dem wir die Interessen zahlreicher Geschädigter systematisch abwickeln und somit auch gegen große Konzerne Erfolge erzielen. Ihr Vorteil: Sie klagen völlig stress- und risikofrei und bezahlen für unsere Leistung nur im Erfolgsfall.\u003C\u002Fp\u003E\u003Cp\u003EProfitieren Sie von unserer Erfahrung: Wir haben bereits über 120.000 Fälle erfolgreich geprüft!\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 large-3 columns\"\u003E\u003Cimg ng-src=\"\u002Fassets\u002Fimg\u002Flogos\u002Fvh24logo.svg\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "headerAttributes" in locals_for_with ?
        locals_for_with.headerAttributes :
        typeof headerAttributes !== 'undefined' ? headerAttributes : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;