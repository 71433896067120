const SteuerklassenConstants = [
  {
    id: 1,
    value: 1,
    name: 'I'
  },
  {
    id:2,
    value: 2,
    name: 'II'
  },
  {
    id:3,
    value: 3,
    name: 'III'
  },
  {
    id:4,
    value: 4,
    name: 'IV'
  },
  {
    id:5,
    value: 5,
    name: 'V'
  },
  {
    id:6,
    value: 6,
    name: 'VI'
  }
];

export default SteuerklassenConstants;
