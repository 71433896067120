import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

// const EMAIL_VALIDATION_REGEX = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
const EMAIL_VALIDATION_REGEX = "^[a-zA-ZöäüßA-ZÖÄÜ0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-ZöäüßA-ZÖÄÜ0-9-]+(?:\\.[a-zöäüßA-ZÖÄÜ0-9-]+)*$"

@Component({
  selector: 'vn-email-validator',
  templateUrl: './email-validator.component.html',
  styleUrls: ['./email-validator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EmailValidatorComponent)
    }
  ],
})
export class EmailValidatorComponent implements OnInit, ControlValueAccessor {

  constructor(
    @Inject('ByteplantService') private byteplantService: any,
    @Inject('EmployeeService') private employeeService: any,
    @Inject('customerService') private customerService: any,
  ) { }

  @Input()
  id: string | undefined;

  @Input()
  title: string | undefined;

  @Input()
  placeHolder: string = '';

  @Input()
  initEmailValue: string = '';

  @Input()
  dataCy: string | undefined;
  public dataCyInput: string | undefined;
  public dataCyButton: string | undefined;

  @Input()
  isDisabled: boolean = false;

  @Input()
  isRequired: boolean = false;

  @Input()
  isEmailPrivate: boolean = false;

  @Input()
  isCompanyEmailAllowed: boolean = false;

  @Output()
  isValid: boolean = false;
  @Output()
  isEmailValid$ = new EventEmitter<boolean>();

  public isProved: boolean = false;

  public isCompanyEmail: boolean = false;

  public errorMessage: string | undefined;

  private employee: any;
  private customer: any;

  async ngOnInit(): Promise<void> {
    this.initCypressAttributes();
    this.employee = await this.employeeService.get();
    this.customer = await this.customerService.getConfiguration();

    await this.initiationValue();
  }

  private formControlValue: string = '';
  public get value() {
    return this.formControlValue;
  }

  public onTouched = () => { };
  public onChange = (_: string) => { };

  public writeValue(value: string) {
    if (!this.isDisabled && value) {
      this.formControlValue = value;
      this.onChange(this.formControlValue);
    }
  }

  public registerOnChange(onChange: (_: string) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  async initiationValue() {
    if(this.initEmailValue) {
      this.writeValue(this.initEmailValue);
      await this.validateEmailAddress();
    }
  }

  initCypressAttributes() {
    this.dataCyInput = this.dataCy + '.EmailProveInput'
    this.dataCyButton = this.dataCy + '.EmailProveButton'
  }

  hideError() {
    this.errorMessage = ' ';
  }

  showError(message: string) {
    this.errorMessage = message;
  }

  async checkEmailValidity() {
    this.isValid = false;
    const emailAddress = this.value;
    if(!this.employeeService.checkIfItsTestEmployee(this.employee)) {

      // Check if this email address has taken or not in Globe
      let isEmailValid = await this.byteplantService.isEmailAddressValid(emailAddress);
      this.isValid = !!isEmailValid;
      if(!this.isValid) {
        this.showError("Bitte geben Sie eine gültige E-Mail Adresse an");
        this.isEmailValid$.emit(false);
        this.isProved = false;
        return false;
      }
    }

    this.isValid = true;
    this.hideError();
    this.isEmailValid$.emit(true);
    this.isProved = true;
    return true;
  }

  async handleEmailInputChange(emailAddress: string) {
    this.writeValue(emailAddress);
    this.isDisabled = true;
    this.isProved = false;
    this.isValid = false;
    await this.validateEmailAddress();
    this.isEmailValid$.emit(false);
    this.isDisabled = false;
  }

  async validateEmailAddress() {
    this.isValid = false;
    const emailAddress = this.value;

    let regex = new RegExp(EMAIL_VALIDATION_REGEX);

    if (!regex.test(emailAddress)) {
      this.showError("Bitte geben Sie eine gültige E-Mail Adresse an");
      this.isValid = false;
      return false;
    }

    if (!this.customer.customer_domains_config) {
      this.isValid = true;
      return true;
    }

    let isPrivateEmailAddress = this.customerService.isEmployeePrivateEmailDomainValid(
      emailAddress,
      this.customer.customer_domains_config
    );

    if (!isPrivateEmailAddress) {
      if (this.isCompanyEmailAllowed) {
        this.isCompanyEmail = true;
        this.isValid = false;
        return false;
      } else {
        this.showError("Bitte verwenden Sie Ihre private E-Mail anstatt Ihrer geschäftlichen E-Mail Adresse.");
        this.isValid = false;
        return false;
      }
    }

    this.hideError();
    this.isCompanyEmail = false;
    this.isValid = true;
    return true;
  }

  isAllowToProve() {
    return !this.isDisabled && this.isValid
  }

  handleCompanyEmailValidation(value: any) {
    this.isValid = !!value;
  }

}
