var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;


































pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-12 columns\"\u003E\u003Cdiv class=\"cockpit-datev\" overlay=\"ctrl.datevService.configs.loading\"\u003E\u003Cdiv id=\"meineakte_main\" ui-view=\"administrationMain\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;