const $inject = [
  '$state',
  'Made',
  'BonusService',
  '$timeout'
];

export class BonusListController {
  constructor(
    $state,
    Made,
    BonusService,
    $timeout
  ) {
    Object.assign(this, {
      $state,
      Made,
      BonusService,
      $timeout
    });

    this.loading = {
      main: false
    };

    this.init();
  }

  toShowBonusProjectToUser(bonus_project) {
    return this.BonusService.toShowToUser(bonus_project);
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.forms = {};


    this.bonus_projects = await this.BonusService.getBonusProjectsDisplayForUser();
    this.bonus_projects = this.bonus_projects.filter(bonus_project => this.toShowBonusProjectToUser(bonus_project));

    // redirect directly if only 1 bonus project
    if (this.bonus_projects.length === 1) {
      this.goToProject(this.bonus_projects[0]._id);
    }

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  selectProject(id) {
    this.goToProject(id);

  }

  goToProject(id) {
    this.$state.go('inApp.bonus.dashboard.main', { bonusProjectId: id });
  }

}

BonusListController.$inject = $inject;
