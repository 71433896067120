<h2 mat-dialog-title>
    Aufladungen bestellen
</h2>

<mat-dialog-content>
    <p>
        Möchten Sie die Boni für <strong>{{this.period}}</strong> bestellen? Nach dem Klick auf "Bestätigen" wird Ihre
        Bestellung von ValueNet weiterbearbeitet. Sie können dann die Boni aus dieser Bestellung nicht mehr selbständig
        bearbeiten.
    </p>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='confirm-button'
            data-cy="vn.directBonus.customerAdministration.confirmOrderDialog.buttonSubmit"
            mat-stroked-button
            (click)="confirmOrder()">
        Bestellen
    </button>
</mat-dialog-actions>