const $inject = [
  '$scope',
  'ByteplantService',
  '$timeout',
  'employeeService',
  'NotificationService',
  'lodash'
]
export default class employeeContactDetailsController {
  constructor($scope, ByteplantService, $timeout, employeeService, NotificationService, lodash) {
    Object.assign(this, {
      $scope,
      ByteplantService,
      $timeout,
      employeeService,
      NotificationService,
      lodash
    });

    this.employee = $scope.ngDialogData.employee;
    this.employeeId = this.employee.id || this.employee.valuenet_id;

    this.countries = this.ByteplantService.getCountriesOrderedByName();
    this.phoneValidationRegex = /^[0-9]*$/;
    this.forms = {};
    this.loading = {
      saving: false,
      loading: true
    };

    this.privatePhoneModel = {
      countryCode: '+49',
      number: undefined,
      combined: undefined,
      initialCountryCode: undefined,
      initialNumber: undefined,
      has_changed: false,
      control_name: 'privatePhone'
    };

    this.workPhoneModel = {
      countryCode: '+49',
      number: undefined,
      combined: undefined,
      initialCountryCode: undefined,
      initialNumber: undefined,
      has_changed: false,
      control_name: 'workPhone'
    };

    this.festnetzPrivatePhoneModel = {
      countryCode: '+49',
      number: undefined,
      combined: undefined,
      initialCountryCode: undefined,
      initialNumber: undefined,
      has_changed: false,
      control_name: 'festnetzPrivatePhone'
    };

    this.festnetzWorkPhoneModel = {
      countryCode: '+49',
      number: undefined,
      combined: undefined,
      initialCountryCode: undefined,
      initialNumber: undefined,
      has_changed: false,
      control_name: 'festnetzWorkPhone'
    };

    this.privateEmailModel = {
      initialValue: undefined,
      value: undefined,
      has_changed: false,
      control_name: 'privateEmail'
    };

    this.workEmailModel = {
      initialValue: undefined,
      value: undefined,
      has_changed: false,
      control_name: 'workEmail'
    };

    this.contactDetailsKeyToModelMap = {
      emailPrivate: this.privateEmailModel,
      emailWork: this.workEmailModel,
      mobilePrivate: this.privatePhoneModel,
      mobileWork: this.workPhoneModel,
      telephonePrivate: this.festnetzPrivatePhoneModel,
      telephoneWork: this.festnetzWorkPhoneModel
    }

    this.init();
  }

  async init() {
    this.contactDetails = await this.employeeService.getContactDetails(this.employeeId);

    this.workEmailModel.initialValue = this.workEmailModel.value = this.contactDetails.emailWork;
    this.privateEmailModel.initialValue = this.privateEmailModel.value = this.contactDetails.emailPrivate;

    let componentizedWorkPhone = await this.ByteplantService.componentizePhone(this.contactDetails.mobileWork);
    if (componentizedWorkPhone) {
      this.workPhoneModel.countryCode = componentizedWorkPhone.country_code;
      this.workPhoneModel.initialCountryCode = componentizedWorkPhone.country_code;
      this.workPhoneModel.number = componentizedWorkPhone.number;
      this.workPhoneModel.initialNumber = componentizedWorkPhone.number;
    }

    let componentizedPrivatePhone = await this.ByteplantService.componentizePhone(this.contactDetails.mobilePrivate);
    if (componentizedPrivatePhone) {
      this.privatePhoneModel.countryCode = componentizedPrivatePhone.country_code;
      this.privatePhoneModel.initialCountryCode = componentizedPrivatePhone.country_code;
      this.privatePhoneModel.number = componentizedPrivatePhone.number;
      this.privatePhoneModel.initialNumber = componentizedPrivatePhone.number;
    }

    let componentizedFestnetzPrivatePhone = await this.ByteplantService.componentizePhone(this.contactDetails.telephonePrivate);
    if (componentizedFestnetzPrivatePhone) {
      this.festnetzPrivatePhoneModel.countryCode = componentizedFestnetzPrivatePhone.country_code;
      this.festnetzPrivatePhoneModel.initialCountryCode = componentizedFestnetzPrivatePhone.country_code;
      this.festnetzPrivatePhoneModel.number = componentizedFestnetzPrivatePhone.number;
      this.festnetzPrivatePhoneModel.initialNumber = componentizedFestnetzPrivatePhone.number;
    }

    let componentizedFestnetzWorkPhone = await this.ByteplantService.componentizePhone(this.contactDetails.telephoneWork);
    if (componentizedFestnetzWorkPhone) {
      this.festnetzWorkPhoneModel.countryCode = componentizedFestnetzWorkPhone.country_code;
      this.festnetzWorkPhoneModel.initialCountryCode = componentizedFestnetzWorkPhone.country_code;
      this.festnetzWorkPhoneModel.number = componentizedFestnetzWorkPhone.number;
      this.festnetzWorkPhoneModel.initialNumber = componentizedFestnetzWorkPhone.number;
    }

    this.$timeout(() => {
      this.loading.loading = false;
    })
  }

  isLoading() {
    return this.loading.saving || this.loading.loading;
  }

  markPrivateEmailForCheck() {
    this.forms.contactDetails[this.privateEmailModel.control_name].$setValidity('invalid', true);
    if (
      this.privateEmailModel.initialValue === this.privateEmailModel.value
    ) {
      this.privateEmailModel.has_changed = false;
    } else {
      this.privateEmailModel.has_changed = true;
    }
  }

  markWorkEmailForCheck() {
    this.forms.contactDetails[this.workEmailModel.control_name].$setValidity('invalid', true);
    if (
      this.workEmailModel.initialValue === this.workEmailModel.value
    ) {
      this.workEmailModel.has_changed = false;
    } else {
      this.workEmailModel.has_changed = true;
    }
  }

  markWorkPhoneForCheck() {

    this.forms.contactDetails[this.workPhoneModel.control_name].$setValidity('invalid', true);
    if (
      this.workPhoneModel.initialCountryCode === this.workPhoneModel.countryCode &&
      this.workPhoneModel.initialNumber === this.workPhoneModel.number
    ) {
      this.workPhoneModel.has_changed = false;
    } else {
      this.workPhoneModel.has_changed = true;
    }
  }

  markPrivatePhoneForCheck() {
    this.forms.contactDetails[this.privatePhoneModel.control_name].$setValidity('invalid', true);
    if (
      this.privatePhoneModel.initialCountryCode === this.privatePhoneModel.countryCode &&
      this.privatePhoneModel.initialNumber === this.privatePhoneModel.number
    ) {
      this.privatePhoneModel.has_changed = false;
    } else {
      this.privatePhoneModel.has_changed = true;
    }
  }

  markFestnetzPrivatePhoneForCheck() {
    this.forms.contactDetails[this.festnetzPrivatePhoneModel.control_name].$setValidity('invalid', true);
    if (
      this.festnetzPrivatePhoneModel.initialCountryCode === this.festnetzPrivatePhoneModel.countryCode &&
      this.festnetzPrivatePhoneModel.initialNumber === this.festnetzPrivatePhoneModel.number
    ) {
      this.festnetzPrivatePhoneModel.has_changed = false;
    } else {
      this.festnetzPrivatePhoneModel.has_changed = true;
    }
  }

  markFestnetzWorkPhoneForCheck() {
    this.forms.contactDetails[this.festnetzWorkPhoneModel.control_name].$setValidity('invalid', true);
    if (
      this.festnetzWorkPhoneModel.initialCountryCode === this.festnetzWorkPhoneModel.countryCode &&
      this.festnetzWorkPhoneModel.initialNumber === this.festnetzWorkPhoneModel.numfestnetzWorkPhoneModelber
    ) {
      this.festnetzWorkPhoneModel.has_changed = false;
    } else {
      this.festnetzWorkPhoneModel.has_changed = true;
    }
  }

  isPhoneModelValid(model) {
    return !!model.number && model.number.trim() !== '';
  }

  isPhoneModelCombinedValid(model) {
    return !!model.combined && model.combined !== '';
  }

  isWorkPhoneCountryRequired() {
    return this.isPhoneModelValid(this.workPhoneModel);
  }

  isPrivatePhoneCountryRequired() {
    return this.isPhoneModelValid(this.privatePhoneModel);
  }

  isFestnetzPrivatePhoneCountryRequired() {
    return this.isPhoneModelValid(this.festnetzPrivatePhoneModel);
  }

  isFestnetzWorkPhoneCountryRequired() {
    return this.isPhoneModelValid(this.festnetzWorkPhoneModel);
  }

  canUpdatePhones() {
    let is_form_valid = this.forms.contactDetails.$valid

    return is_form_valid
  }

  combineCountryCodeAndPhone(model) {
    if (this.isPhoneModelValid(model)) {
      model.combined = model.countryCode + '-' + model.number;
    } else {
      model.combined = '';
    }
  }

  async updatePhones() {
    this.$timeout(() => {
      this.loading.saving = true;
    });

    this.combineCountryCodeAndPhone(this.workPhoneModel);
    this.combineCountryCodeAndPhone(this.privatePhoneModel);
    this.combineCountryCodeAndPhone(this.festnetzPrivatePhoneModel);
    this.combineCountryCodeAndPhone(this.festnetzWorkPhoneModel);

    if (this.canUpdatePhones()) {

      let has_updates =
        this.workEmailModel.has_changed ||
        this.privateEmailModel.has_changed ||
        this.workPhoneModel.has_changed ||
        this.privatePhoneModel.has_changed ||
        this.festnetzWorkPhoneModel.has_changed ||
        this.festnetzPrivatePhoneModel.has_changed;

      if (has_updates) {
        try {
          let newContactDetails = {
            mobileWork : this.workPhoneModel.combined,
            mobilePrivate : this.privatePhoneModel.combined,
            telephonePrivate : this.festnetzPrivatePhoneModel.combined,
            telephoneWork : this.festnetzWorkPhoneModel.combined,
            emailPrivate : this.privateEmailModel.value,
            emailWork : this.workEmailModel.value,
          }
          await this.employeeService.updateContactDetails(this.employeeId, newContactDetails);
          Object.assign(this.contactDetails, newContactDetails)
          this.NotificationService.message('Die Kontaktdetails wurde gespeichert.');
          this.$scope.confirm();
        } catch (e) {
          let invalid_key = this.lodash.get(e, 'error.data.invalid_key');
          if (invalid_key) {
            let invalid_control_name = this.contactDetailsKeyToModelMap[invalid_key].control_name;
            let invalid_control = this.forms.contactDetails[invalid_control_name];
            invalid_control.$setValidity('invalid', false);
          }
          this.NotificationService.error('Die Änderung der Kontaktdetails konnte nicht gespeichert werden.');
        }
      } else {
        this.$scope.confirm();
      }
    }

    this.$timeout(() => {
      this.loading.saving = false;
    });
  }

}
employeeContactDetailsController.$inject = $inject;
