import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class BenefitToolbarEventService {

  private _subject = new Subject<any>();

  constructor() {}

  hideSearchFromToolbar() {
    this._subject.next();
  }

  get events$ () {
    return this._subject.asObservable();
  }
}
