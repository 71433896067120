import feedback_html from "../../modules/feedback/feedback.pug";
import feedback_header from "../../helpers/header.main.pug";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.feedback', {
        url: '/feedback',
        views: {
          main: {
            template: feedback_html,
            controller: 'FeedbackController'
          },
          header: {
            template: feedback_header
          }
        }
      });

  }]);
