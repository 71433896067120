import { YEARLY_SESSION_ERRORS_BY_ID } from '../../../services/yearlyService';

const $inject = [
  '$scope',
  '$stateParams',
  'yearlyService',
];

export class YearlyErrorController {
  constructor(
    $scope,
    $stateParams,
    yearlyService,
  ) {
    Object.assign(this, {
      $scope,
      $stateParams,
      yearlyService,
    });
    this.error = YEARLY_SESSION_ERRORS_BY_ID[this.$stateParams.yearly_error_id];
  }
}

YearlyErrorController.$inject = $inject;
