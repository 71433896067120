var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["landing-menu-items"] = pug_interp = function(loginOnly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!loginOnly) {
pug_html = pug_html + "\u003Cli\u003E\u003Ca href=\"\u002Flanding#portal\" ng-click=\"landing.scrollTo(&quot;portal&quot;)\"\u003EValueNet Portal\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"\u002Flanding#preise\"\u003EPreise\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"\u002Flanding#testen\" ng-click=\"landing.scrollTo(&quot;testen&quot;)\"\u003ETesten\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
pug_html = pug_html + "\u003Cli\u003E\u003Ca ng-click=\"landing.goToCidaasLogin()\"\u003E\u003Cspan class=\"login\"\u003ELogin\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
};




pug_html = pug_html + "\u003Cheader class=\"landing-header sticky contain-to-grid\" scrolled-header\u003E\u003Cdiv class=\"content-wrapper\"\u003E\u003Csection class=\"tab-bar left-small left hide-for-medium-up\"\u003E\u003Ca class=\"aside-toggle menu-icon\" role=\"button\" ng-click=\"landing.toggle_aside()\"\u003E\u003Ci class=\"font-icon icon-hamburger\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fsection\u003E\u003Cnav class=\"top-bar\" data-topbar=\"\" role=\"navigation\"\u003E\u003Cul class=\"title-area\"\u003E\u003Cli class=\"name blank\"\u003E\u003Ca class=\"landing-logo\" href=\"\u002Flanding\"\u003E\u003Ch1\u003E\u003Cimg class=\"landing-logo blank\" src=\"\u002Fassets\u002Fimg\u002Flanding\u002Fvaluenet_logo.png\" alt=\"Valuenet Logo\"\u003E\u003C\u002Fh1\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"toggle-topbar menu-icon\"\u003E\u003Ca\u003E\u003Cspan\u003EMenu\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Csection class=\"top-bar-section hide-for-xsmall-only hide-for-small-only\"\u003E\u003Cul class=\"right\"\u003E";
pug_mixins["landing-menu-items"](true);
pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fsection\u003E\u003C\u002Fnav\u003E\u003C\u002Fdiv\u003E\u003C\u002Fheader\u003E\u003Cdiv class=\"off-canvas-wrap\"\u003E\u003Cdiv class=\"inner-wrap\"\u003E\u003Caside class=\"left-off-canvas-menu\"\u003E\u003Cul class=\"off-canvas-list\"\u003E";
pug_mixins["landing-menu-items"](true);
pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Faside\u003E\u003Cmain class=\"main-section landing\"\u003E\u003Cdiv class=\"bigbox centered imprint\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EDatenschutzerklärung\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main toc\"\u003E" + (null == (pug_interp = require("./_toc_public.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fmain\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;