const $inject = [
  '$state',
  '$scope',
  '$timeout',
  'mppService',
  'moment',
  'lodash'
];
export default class InvoicePaymentDialogController {

  constructor(
    $state,
    $scope,
    $timeout,
    mppService,
    moment,
    lodash
  ) {
    Object.assign(this, {
      $state,
      $scope,
      $timeout,
      mppService,
      moment,
      lodash
    });

    this.loading = {
      main: false
    };

    this.forms = {
      mark_paid_invoice: {}
    };

    this.invoice_payment_data = {
      payment_date: this.moment().toDate()
    }

    this.init().finally(()=>{
      this.$timeout(()=>{
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    this.basket = angular.copy(this.$scope.ngDialogData.basket);

    let invoiceReferenceId = this.lodash.get(this.basket, 'billing_process.invoice_id')
    if (invoiceReferenceId) {
      this.invoice = await this.mppService.getInvoiceByReferenceId(invoiceReferenceId);
    }

    this.$timeout(()=>{
      this.loading.main = false;
    });
    return;
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async markInvoiceAsPaid() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    let response = await this.mppService.markInvoiceAsPaid(this.basket._id, this.invoice_payment_data.payment_date)

    this.$timeout(()=>{
      this.loading.main = false;
    });
    this.$scope.closeThisDialog();
  }
}
InvoicePaymentDialogController.$inject = $inject;
