const $inject = [
  'Made',
  'VSP_CONSTANTS'
];

export default class ByteplantService {

  constructor(Made, VSP_CONSTANTS) {
    this.Made = Made;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
  }

  async componentizePhone(phone) {
    return this.Made.request(
      'rpc://valuenetdb/byteplant/componentize_phone',
      {phone}
    );
  }

  getCountriesOrderedByName() {
    let countries = Object.keys(this.VSP_CONSTANTS.COUNTRIES_PHONE_CODE);
    countries = countries.sort();
    return countries.reduce((acc, country)=> {
      acc.push({
        name: country,
        ...this.VSP_CONSTANTS.COUNTRIES_PHONE_CODE[country]
      })
      return acc;
    }, [])
  }

  isPhoneNumberValid(phone) {
    return this.Made.request(
      'rpc://valuenetdb/byteplant/validate_phone',
      {phone}
    );
  }

  isAddressValid(address) {
    return this.Made.request(
      'rpc://valuenetdb/byteplant/validate_address',
      {address}
    );
  }

  isEmailAddressValid(email) {
    return this.Made.request(
      'rpc://valuenetdb/send_email/domain_check',
      {email}
    ).then(response => {
      if (response.status != 200) {
        return false;
      }

      if (response.success) {
        return this.Made.request(
          'rpc://valuenetdb/byteplant/validate_email',
          {email}
        );
      }

      return false;
    });
  }

}

ByteplantService.$inject = $inject;
