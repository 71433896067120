import DashboardArbeitnehmerController from './arbeitnehmer/DashboardArbeitnehmerController';
import './arbeitgeber/DashboardArbeitgeberController';

import DashboardBeraterController from './berater/DashboardBeraterController';

angular
  .module( 'VSPApp' )
  .controller( 'DashboardBeraterController', DashboardBeraterController)
  .controller('DashboardArbeitnehmerController', DashboardArbeitnehmerController)
  ;
