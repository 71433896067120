import {
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'vn-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropzoneComponent
    }
  ]
})
export class DropzoneComponent extends Input {

  public panelOpenState = false;

  @Input()
  public asExpandable: boolean = false;

  @Input()
  public label!: string;

  @Input()
  public key!: string;

  @Input()
  public placeholder: string = '';

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public selfUpload: boolean = true;

  @Input()
  public multiple: boolean = false;

  @ViewChild('formInput')
  formInput!: ElementRef<HTMLInputElement>;

  @Output()
  onUpload: EventEmitter<File[]> = new EventEmitter();

  public files: File[] = [];

  public get hasFiles() {
    return !!this.files.length;
  }

  private formControlValue!: string;
  public get value() {
    return this.formControlValue
  }

  public onTouched = () => { };
  public onChange = (_: string) => { };


  public readonly dropzoneContext = {
    $implicit: this
  };

  constructor() { super(); }

  ngOnInit(): void {
  }

  public uploadFiles() {
    this.onUpload.emit(this.files);
  }

  public handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const item = files.item(i);
      if (!!item) {
        this.files.push(item);
      }
    }

    if(this.files && !this.selfUpload) {
      this.uploadFiles();
    }
  }

  // != <tag [disabled]='true'> -> use public isDisabled instead
  // excludes formControl from fromGroup.valueChanges$
  // called with new FormControl({value: 123, disabled: true}, [Validators...])
  public setDisabledState(formDisabled: boolean) {
    this.formDisabled = formDisabled;
  }

  public removeFile(idx: number) {
    const files = this.files.filter((file, _idx) => idx !== _idx);
    this.files = files;
  }
}
