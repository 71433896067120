import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import './models';
import { BackOfficeFileUploadService } from "./services/back-office-file-upload.service";
import { BackendFileUploadMapper } from "./mappers/backend-file-upload-mapper.service";


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
  ],
  providers: [
    BackOfficeFileUploadService,
    BackendFileUploadMapper
  ]
})
class BackOfficeModule { }

export { BackOfficeModule };
