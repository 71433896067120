angular.module("VSPApp").directive("disableScroll", [function () {
  return {
    restrict: "A",
    link: function (scope, element) {
      element.on("wheel", function (event) {
        event.preventDefault();
      });
    },
  };
}]);
