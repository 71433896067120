const $inject = [
  '$scope',
  'Made',
  'NotificationService',
  'employeeService'
];
export default class employeeEntrydateController {

  constructor(
    $scope,
    Made,
    NotificationService,
    employeeService
  ) {

    Object.assign(this, {
      $scope,
      NotificationService,
      employeeService
    });

    this.saving = true;
    this.employeeId = ($scope.ngDialogData && $scope.ngDialogData.employeeId) || Made.user.valuenet_id;

    employeeService
      .get(this.employeeId)
      .then(employee => this.employee = employee);

    this.getIntegrityData();
  }

  getIntegrityData() {
    this.employeeService
      .getIntegrityData(this.employeeId)
      .then(integrityData => {
        this.integrityData = integrityData;
        delete (this.integrityData.address);
        this.saving = false;
      });
  }

  save() {

    this.saving = true;
    this.employeeService
      .updateEntrydateData(this.integrityData, this.employeeId)
      .then(() => {
        this.$scope.confirm();
        this.NotificationService.message('Das Eintrittsdatum wurde gespeichert.');
      }, () => {
        this.error = true;
        this.NotificationService.error('Die Änderung des Eintrittsdatums konnte nicht gespeichert werden.');
      })
      .finally(() => this.saving = false);

    return true;
  }

}
employeeEntrydateController.$inject = $inject;
