<h2 mat-dialog-title>
    <span>
        Bonusbetrag ändern
    </span>

    <h3>
        {{this.employeeName}} {{this.employeeId}}
    </h3>

    <label>
        {{ componentType }} {{this.period}}
    </label>
</h2>

<div *ngIf='componentMaxValue'>
    <mat-dialog-content>
        <p>
            Sie können den Wert für den folgenden Bonus ändern.
            Tragen Sie dazu Ihren neuen Wert in das untere Feld ein:
        </p>

        <form [formGroup]="formGroup">
            <vn-input formControlName="bonusValue"
                      key="vn.directBonus.backofficeAdministration.editBonusDialog.inputBonusAmount"
                      [placeholder]="this.bonusValueStr"
                      type="number"
                      label="Neuer Bonuswert">
                <mat-error *ngIf="bonusValue.hasError('required')">
                    Eingabe fehlt.
                </mat-error>

                <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('min')">
                    Betrag darf nicht negativ sein.
                </mat-error>

                <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('max')">
                    Betrag darf nicht größer {{this.componentMaxValue}} sein.
                </mat-error>

                <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('pattern')">
                    Bitte geben Sie Euro-Beträge im Format 12345,67 an.
                </mat-error>
            </vn-input>
        </form>

        <p>
            Nach dem Klick auf "Speichern" wird der Wert für diesen Bonus
            und damit die Gesamtsumme der Bestellung geändert.
        </p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button
                mat-dialog-close>
            Abbrechen
        </button>

        <button class='edit-button'
                data-cy="vn.directBonus.backofficeAdministration.editBonusDialog.buttonSaveBonus"
                mat-stroked-button
                [disabled]="!formGroup.valid"
                (click)="editBonusValue()">
            Speichern
        </button>
    </mat-dialog-actions>
</div>
