export const DEFAULT_BONUS_PROJECT_BUDGET_FIELDS = [
  {
    label: 'Sachbezug',
    prop: 'sachbezug'
  },
  {
    label: 'Essensschecks PST, Stück',
    prop: 'essensschecks_pst'
  },
  {
    label: 'Essensschecks frei, Stück',
    prop: 'essensschecks_frei'
  },
  {
    label: 'PC-Leasing, Wert',
    prop: 'pc'
  },
  {
    label: 'PC-Leasing, Wert',
    prop: 'pc_1'
  },
  {
    label: 'Telekommunikation, EUR',
    prop: 'handy'
  },
  {
    label: 'Internetpauschale PST, EUR',
    prop: 'internet'
  },
  {
    label: 'Entfernungspauschale PST, EUR',
    prop: 'entfernung'
  },
  {
    label: 'Erholungsbeihilfe PST, EUR p.a.',
    prop: 'erholungsbeihilfe'
  },
  {
    label: 'Kindergarten',
    prop: 'kinder'
  },
  {
    label: 'Jobrad',
    prop: 'EBike'
  },
  {
    label: 'Dienstwagengarage (gemietet)',
    prop: 'dienstwagen_auslage'
  },
  {
    label: 'Dienstwagengarage (Eigentum)',
    prop: 'dienstwagen_miete'
  },
  {
    label: 'Personalrabatt',
    prop: 'personal_rabatt'
  },
  {
    label: 'Verpflegungsmehraufwand (pSt.)',
    prop: 'VMA_PST'
  },
  {
    label: 'Verpflegungsmehraufwand (st- u. sv-frei)',
    prop: 'VMA_STSVFREI'
  },
  {
    label: 'Festnetztelefon',
    prop: 'festnetz'
  },
  {
    label: 'Besondere Anlässe p.a.',
    prop: 'besonderer_anlass'
  },
  {
    label: 'Bikeleasing',
    prop: 'Bikeleasing'
  },
  {
    label: 'Bikeleasing',
    prop: 'bike_1'
  }
];
export const BONUS_PROJECT_BUDGET_FIELDS_BY_PROP = DEFAULT_BONUS_PROJECT_BUDGET_FIELDS.reduce((acc, field) => {
  acc[field.prop] = field;
  return acc;
}, {});
