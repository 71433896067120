const $inject = [
  '$q',
  'Made'
];

export default class cmsService {
  constructor( $q, Made ) {
    Object.assign( this, {
      $q,
      Made,
    } );

    this.globalCms = { loading: true, detailId: 0 };
  }

  save( cmsContent ) {
    return this.Made.request( 'rpc://cms/save', {
      content: cmsContent,
    } );
  }

  async loadById( id ) {
    return await this.Made.request( 'rpc://cms/loadById', {
      id: id,
    } );
  }

  sortNotesByPinned( items ) {
    let pinnedItemInCategoryIndex = items.findIndex( element => element.pinned );
    if ( pinnedItemInCategoryIndex > -1 ) {
      let pinnedItem = items.splice( pinnedItemInCategoryIndex, 1 )[ 0 ];
      items.unshift( pinnedItem );
    }

    return items;
  }

  getTeaserList( custId, category, limit ) {
    let params = { cust_id: null, limit: 0 };
    if ( custId ) {
      params.cust_id = parseInt( custId, 10 );
    }
    if ( limit ) {
      params.limit = parseInt( limit, 10 );
    }
    params.category = '';
    if ( category ) {
      params.category = category;
    }

    return this.Made.request( 'rpc://cms/getTeaserList', params );
  }

  loadEditorList( custId ) {
    let params = { cust_id: null };
    if ( custId ) {
      params = { cust_id: parseInt( custId, 10 ) };
    }
    return this.Made.request( 'rpc://cms/loadEditorList', params );
  }

  deleteCmsContent( id ) {
    return this.Made.request( 'rpc://cms/deleteCmsContent', { id: id } );
  }

  checkCustomerAccess( custId ) {
    return this.Made.request( 'rpc://cms/checkCustomerAccess', {
      customer_id: parseInt( custId + '', 10 ),
    } );
  }

  loadCategoryList( custId ) {
    let params = { cust_id: null };
    if ( custId ) {
      params = { cust_id: parseInt( custId, 10 ) };
    }
    return this.Made.request( 'rpc://cms/loadCategoryList', params );
  }

  loadUserCategoryList( custId ) {
    let params = { cust_id: null };
    if ( custId ) {
      params = { cust_id: parseInt( custId, 10 ) };
    }
    return this.Made.request( 'rpc://cms/loadUserCategoryList', params );
  }

  async deleteCmsCategory( category, custId ) {
    return await this.Made.request( 'rpc://cms/deleteCmsCategory', { category: category, cust_id: custId } );
  }

  async getCategory( id, custId ) {
    return await this.Made.request( 'rpc://cms/getCategory', {
      id: id,
      cust_id: parseInt( custId, 10 ),
    } );
  }

  saveCategory( categoryData, custId ) {
    return this.Made.request( 'rpc://cms/saveCategory', {
      category_data: categoryData,
      cust_id: parseInt( custId, 10 ),
    } );
  }
}
cmsService.$inject = $inject;