import {DEFAULT_BONAGO_SCHEME_ID_SACHBEZUG_NEO} from '../../../benefit/bonago/benefit_bonago.service'

const $inject = [
  '$location',
  '$state',
  '$scope',
  'Made',
  'vbmData',
  'CheckoutService',
  'UsageConstants',
  'employeeService',
  'customerService',
  'BenefitBonagoService',
  'lodash',
  'VSP_CONSTANTS',
  '$timeout'
];
export default class CheckoutEinsatzController {

  constructor(
    $location,
    $state,
    $scope,
    Made,
    vbmData,
    CheckoutService,
    UsageConstants,
    employeeService,
    customerService,
    BenefitBonagoService,
    lodash,
    VSP_CONSTANTS,
    $timeout
  ) {

    Object.assign(this, {
      $state,
      Made,
      vbmData,
      UsageConstants,
      customerService,
      employeeService,
      BenefitBonagoService,
      CheckoutService,
      lodash,
      VSP_CONSTANTS,
      $timeout
    });

    $scope.globalCheckoutCtrl.step = $scope.globalCheckoutCtrl.steps - 2;

    this.available_customer_schemes = [];
    this.forms = {};
    this.models = {};

    if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
      vbmData.employeeId = parseInt($location.search().employeeId);
      employeeService.get(vbmData.employeeId);
    }

    this.loading = true;
    this.isValid = false;

    vbmData
      .getData()
      .then(() => vbmData.getResult())
      .then(() => {
        vbmData.employees[vbmData.employeeId].neoChoices = {};
      })
      .then(() => CheckoutService.getNeoChoicesDefaultFromEmployee(vbmData.employees[vbmData.employeeId]))
      .then(result => {

        // if the use has nothing to choose go to next page
        if (!Object.keys(result.neoChoices).length) {
          if (Made.user.valuenet_id != vbmData.employeeId) {
            $location.url('/checkout/extrainfo?employeeId=' + vbmData.employeeId).replace();
          } else {
            $location.url('/checkout/extrainfo').replace();
          }
          return;
        }

        if (result.neoChoices.essenscheck) {
          result.neoChoices.essenscheck = this.VSP_CONSTANTS.ESSENSCHECK_TYPE_BY_ID[this.vbmData.employees[this.vbmData.employeeId].data.neo_components.essenscheck.type]['name'];
        }

        vbmData.employees[vbmData.employeeId].neoChoices = result.neoChoices;
        this.choicesDefault = result.choicesDefault;
        this.employee = vbmData.employees[vbmData.employeeId];

        this.updateIsValid();
      })
      .then(async () => {
        let customer_id = await this.employeeService.getCustomerId(vbmData.employeeId);
        this.bonago_schemes = await this.BenefitBonagoService.getCustomerBonagoSchemes(customer_id);
        let component_value_bonago_scheme_id = this.lodash.get(this.employee, 'data.neo_components.sachbezug.bonago_scheme_id');

        // prepopulate the dropdown if the selected bonago_scheme_id is valid
        if (component_value_bonago_scheme_id !== DEFAULT_BONAGO_SCHEME_ID_SACHBEZUG_NEO && this.lodash.find(this.bonago_schemes, (scheme) => {
          return scheme.scheme_id === component_value_bonago_scheme_id;
        })) {
          this.models.bonago_scheme_id = component_value_bonago_scheme_id;
        }
      })
      .then(() => this.$timeout(()=>this.loading = false, 0));
  }

  async handleNeoChoiceUpdate(componentId) {
    this.$timeout(()=>this.loading = true, 0);

    if (componentId === 'essenscheck') {
      const selectedEssenscheckType = this.VSP_CONSTANTS.ESSENSCHECK_TYPE_BY_NAME[this.employee.neoChoices[componentId]];
      this.vbmData.employees[this.vbmData.employeeId].data.neo_components.essenscheck.type = selectedEssenscheckType['id'];
      await this.vbmData.save(this.vbmData.employeeId, undefined, true);
    }

    this.updateIsValid();

    this.$timeout(()=>this.loading = false, 0);
  }

  updateIsValid() {
    for (const neoChoice in this.employee.neoChoices) {
      if (typeof this.employee.neoChoices[neoChoice] === 'undefined') {
        this.isValid = false;
        return;
      }
    }

    this.isValid = true;
  }

  isBonagoSelected(key) {
    return key === 'sachbezug' && this.employee.neoChoices[key] == 'vorteilskonto';
  }

  async proceed() {

    // reset to default if the user has deselected the Bonago
    if (!this.isBonagoSelected('sachbezug')) {
      this.models.bonago_scheme_id = undefined;
    }

    await this.vbmData.updateSachbezugBonagoScheme(this.models.bonago_scheme_id);
    await this.vbmData.setNeoChoices();

    if (this.Made.user.valuenet_id != this.vbmData.employeeId) {
      this.$state.go('inApp.neo.side.checkout.extrainfo', {
        employeeId: this.vbmData.employeeId
      });
    } else {
      this.$state.go('inApp.neo.side.checkout.extrainfo');
    }
  }

}
CheckoutEinsatzController.$inject = $inject;
