<table mat-table
       [dataSource]="tableData"
       class="mat-elevation-z8"
       multiTemplateDataRows
       matSort>

    <!-- ===================== Filters header ===================== -->
    <tr mat-header-row
        *matHeaderRowDef="filtersHeaderColumns">
    </tr>

    <ng-container matColumnDef="filtersHeader">
        <th mat-header-cell
            [attr.colspan]="mainTableColumns.length"
            *matHeaderCellDef>
            <ng-content select="form">
            </ng-content>
        </th>
    </ng-container>

    <ng-container matColumnDef="topLevelActionsMenu">
        <th mat-header-cell
            [attr.colspan]="1"
            *matHeaderCellDef>
            <ng-content select="button">
            </ng-content>

            <ng-content select="mat-menu">
            </ng-content>
        </th>
    </ng-container>

    <!-- ===================== Main table ===================== -->
    <!-- header row -->
    <tr mat-header-row
        *matHeaderRowDef="mainTableColumns"
        sticky="true">
    </tr>

    <!-- content rows -->
    <tr mat-row
        *matRowDef="let data; columns: mainTableColumns;"
        [ngClass]="getTableRowClass(data)"
        class="customer-order-table-row"
        (click)="onTableRowClick(data)">
    </tr>

    <!-- expanded rows -->
    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="customer-order-employee-table-row">
    </tr>

    <!-- expanded column -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell
            *matCellDef="let data"
            [attr.colspan]="mainTableColumns.length">
            <div class="customer-order-employee-table-row-expanded"
                 [@rowExpand]="data == expandedRow ? 'expanded' : 'collapsed'">
                <vn-direct-bonus-customer-bonus-employee-table
                    *ngIf="data == expandedRow"
                    [customerOrderData]="data"
                    (tableDataChange)="onTableDataChange()">
                </vn-direct-bonus-customer-bonus-employee-table>
            </div>
        </td>
    </ng-container>

    <!-- orderId column -->
    <ng-container matColumnDef="orderId">
        <th mat-header-cell
            *matHeaderCellDef>
            Bestellung
        </th>

        <td mat-cell
            *matCellDef="let data">
            {{ data.order.orderRefId }}
        </td>
    </ng-container>

    <!-- orderType column -->
    <ng-container matColumnDef="orderType">
        <th mat-header-cell
            *matHeaderCellDef>
            Typ
        </th>

        <td mat-cell
            *matCellDef="let data">
            Bonus
        </td>
    </ng-container>

    <!-- orderPeriod column -->
    <ng-container matColumnDef="orderPeriod">
        <th mat-header-cell
            *matHeaderCellDef>
            Periode
        </th>

        <td mat-cell
            *matCellDef="let data">
            {{ getOrderPeriod(data.order) }}
        </td>
    </ng-container>

    <!-- orderAmount column -->
    <ng-container matColumnDef="orderAmount">
        <th mat-header-cell
            *matHeaderCellDef>
            Betrag
        </th>

        <td mat-cell
            [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderTableRow.textOrderSum.' + data.order.bonusProjectId"
            *matCellDef="let data; let idx = index">
            {{ data.orderSum }} EUR
        </td>
    </ng-container>

    <!-- orderState column -->
    <ng-container matColumnDef="orderState">
        <th mat-header-cell
            *matHeaderCellDef>
            Status
        </th>

        <td mat-cell
            *matCellDef="let data">
            <vn-state [color]="getOrderStateColors(data)"
                      [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderTableRow.chipOrderState.' + data.order.bonusProjectId + '-' + data.order.forMonth"
                      [label]="getOrderStateLabels(data)">
            </vn-state>
        </td>
    </ng-container>

    <!-- actionsMenu column -->
    <ng-container matColumnDef="actionsMenu">
        <th mat-header-cell
            *matHeaderCellDef>
            Aktion
        </th>

        <td mat-cell
            *matCellDef="let data">
            <button mat-icon-button
                    [attr.data-cy]="'vn.directBonus.backofficeAdministration.customerOrderTableRow.buttonActionsMenu.' + data.order.bonusProjectId"
                    class="menu-button"
                    [matMenuTriggerFor]="menu"
                    (click)='menuClick($event)'
                    *ngIf="hasContextMenu(data.allowedOperations)">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item
                    *ngIf="data.allowedOperations.canCreateBonus"
                    (click)="createNewBonus(data.order)">
                    <mat-icon>format_list_bulleted_add</mat-icon>
                    <span>Bonus hinzufügen</span>
                    <!-- shown if in draft -->
                </button>

                <button mat-menu-item 
                    *ngIf="data.allowedOperations.canConfirmOrder"
                    (click)="confirmBonusOrder(data.order)">
                    <mat-icon>send</mat-icon>
                    <span>Aufladung bestellen</span> 
                </button>

                <button mat-menu-item 
                    *ngIf="data.allowedOperations.canRevertConfirmedOrder"
                    (click)="revertBonusOrder(data.order)">
                    <mat-icon>reply</mat-icon>
                    <span>Bestellung zurücknehmen</span>
                </button>

                <!-- <button mat-menu-item (click)="openBulkUploadDialog(data.order)">
                    <mat-icon>assignment_returned</mat-icon>
                    <span>Aufladungen importieren</span>
                </button> -->
            </mat-menu>
        </td>
    </ng-container>
</table>
