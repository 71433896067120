<div vnLoader [showLoader]="(isLoading$() | async)!">
  <mat-icon mat-dialog-close>clear</mat-icon>
  <h2 mat-dialog-title>Neuen Erstattungsbeleg bereitstellen</h2>

  <mat-dialog-content>
    <form [formGroup]="formGroup">
      <vn-select
        formControlName="component"
        label="Bitte wählen Sie eine Belegkategorie aus, die Sie einreichen möchten"
        [options]="bonusCategories"
      >
      </vn-select>

      <vn-input
        formControlName="requestedValue"
        label="Belegwert"
        type="number"
        min="0"
        *ngIf="isBonusSelected()"
        >
        <mat-error *ngIf="formGroup.get('requestedValue')?.hasError('required')">
          Eingabe fehlt.
        </mat-error>
        <mat-error *ngIf="formGroup.get('requestedValue')?.hasError('min')">
          Belegwert darf nicht negativ sein.
        </mat-error>
      </vn-input>

      <vn-date
        formControlName="receiptDate"
        label="Belegdatum"
        placeholder="DD.MM.JJJJ"
        *ngIf="isBonusSelected()"
      >
        <mat-error *ngIf="formGroup.get('receiptDate')?.hasError('required')">
          Eingabe fehlt.
        </mat-error>
      </vn-date>

      <vn-date
        formControlName="firstValidDate"
        label="erster gültiger Tag"
        placeholder="DD.MM.JJJJ"
        *ngIf="isBonusJobTicket()"
      >
        <mat-error *ngIf="formGroup.get('firstValidDate')?.hasError('required')">
          Eingabe fehlt.
        </mat-error>
      </vn-date>

      <vn-select
        formControlName="receiptValidPeriod"
        label="Gültigkeitsdauer"
        [options]="invoiceValidPeriods"
        *ngIf="isBonusJobTicket()"
      >
        <mat-error *ngIf="formGroup.get('receiptValidPeriod')?.hasError('required')">
          Eingabe fehlt
        </mat-error>
      </vn-select>


      <vn-dropzone
        label="Belege"
        [selfUpload]=false
        [multiple]=true
        (onUpload)="addFileToFormData($event)"
        *ngIf="isBonusSelected()"
      >
      </vn-dropzone>

      <vn-checkbox
        *ngIf="isBonusBgm()"
        formControlName="bgmNotUsed"
        (value$)="handleCheckbox($event)"
      >
        <p>Ich bestätige, dass ich über den angeforderten Erstattungsbetrag keine weitere Erstattung bei meiner Krankenversicherung einfordern werde oder bereits getan habe.
        Eine Doppelbezuschussung ist nicht zulässig. Auswirkungen haben Sie als Arbeitnehmer zu tragen.</p>
        <p>Sollte ich bereits einen Zuschuss erhalten haben, so habe ich die Zuschussabrechnung beigefügt.</p>
      </vn-checkbox>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      class="save-button"
      mat-stroked-button
      mat-dialog-close
      [disabled]="!isFormValid()"
      (click)="createCashbackRequest()"
    >
      Senden
    </button>
  </mat-dialog-actions>
</div>
