angular
  .module( 'VSPApp' )
  .directive( 'toggableContentContent', [
    function () {
      return {
        priority: 100,
        require: '^toggableContent',
        restrict: 'EA',
        template: '<ng-transclude></ng-transclude>',
        transclude: true,
        link: function ( $scope, iElm, iAttrs, ctrl ) {
          ctrl.setContent( $scope, iElm );
        }
      };
    }
  ] );
