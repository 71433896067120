const usageTitle = ["UsageConstants", function (UsageConstants) {
  return function (input) {

    if (UsageConstants[input]) {
      return UsageConstants[input].title;
    } else {
      console.warn('usage filter - unknown usage: ', input);
      return input;
    }
  };
}]

export {usageTitle}
