import { Injectable } from "@angular/core";
import { CustomError } from "src/app/shared/services/errors/custom-error";
import { DefaultError } from "src/app/shared/services/errors/default-error";
import { NotAllowToCreateExtraOrderAfter23thError } from "./not-allow-to-create-extra-order-after-23th-error";

enum ErrorType {
    NotAllowToCreateExtraOrderAfter23th = 'DIRECT_BONUS_EXTRA_ORDER_AFTER_23_DEADLINE',
}

@Injectable()
export class DirectBonusErrorHandler {

  handle(err: any): CustomError {
    let error = err.error
    
    if (error && error.message && error.message.code) {
      let type = error.message.code && error.message.code.name ? error.message.code.name : null;
      
      if (type == ErrorType.NotAllowToCreateExtraOrderAfter23th) {
        return new NotAllowToCreateExtraOrderAfter23thError();
      }
    }
    
    return new DefaultError();
  }
}
