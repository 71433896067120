const groupByMembership = [function () {
  return function (employees, projectEmloyees) {

    let members = [],
      nonMembers = [];

    if (!employees) {
      return employees;
    }

    employees
      .map(function (employee) {
        if (projectEmloyees[employee.id]) {
          members.push(employee);
        } else {
          nonMembers.push(employee);
        }
      });

    return [
      members,
      nonMembers
    ];
  };
}]

export { groupByMembership }
