const $inject = [
  "$scope",
  "$sce",
  "PopupService",
  "PopupVideoKeys",
  "VersicherungConstants"
];

export default class VersicherungController {

  constructor(
    $scope,
    $sce,
    PopupService,
    PopupVideoKeys,
    VersicherungConstants
  ) {

    Object.assign(this, {
      $sce
    });

    this.versicherungDetails = VersicherungConstants;

    $scope.clicker = {};

    PopupService.processModuleVideo(
      PopupVideoKeys.MODULE_VERSICHERUNGEN,
      $scope.clicker
    );
  }


  open(versicherungDetail) {

    this.iframeSrc = this.$sce.trustAsResourceUrl(
      `https://lotse.softfair-server.de/b2c/#/${versicherungDetail.link}?userexid=74ec0baa-5ac5-11e5-8000-e87944671d4a`
    );
  }

}

VersicherungController.$inject = $inject;
