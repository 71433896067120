import {CHURCH_TAX_TYPES} from "../../modules/datev/default_data/data";
import {ERHOLUNGSBEIHILFE_OPTIONS} from "./erholungsbeihilfe_options";
import {FREISTELLUNGSTAG_OPTIONS} from "./freistellungstag_options";
import {DEFAULT_TAX_PAYER, TAX_PAYERS_BY_NAME} from "./tax_payer";
import {TAX_TYPE_BY_NAME} from "./tax_type";

export const DEFAULT_BONUS_PROJECT_BUDGET = {
  sachbezug: {
    value: 25,
    neo_component_id: 'sachbezug',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    active: false,
    requires_address: true,
    requires_extra_documents: true,
    range: {
      min: 0.01,
      max_eq: 50,
      decimalsCount: 2
    },
    minimum_months_period: 1,
  },
  sachbezug_profit: {
    value: 25,
    neo_component_id: 'sachbezug_profit',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    active: false,
    requires_address: true,
    requires_extra_documents: true,
    range: {
      min: 25,
      max_eq: 50,
      decimalsCount: 2
    },
    minimum_months_period: 3,
  },
  sachbezug_pluxee: {
    value: 25,
    neo_component_id: 'sachbezug_pluxee',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    active: false,
    requires_address: true,
    requires_extra_documents: true,
    range: {
      min: 25,
      max_eq: 50,
      decimalsCount: 2
    },
    minimum_months_period: 1,
  },
  essensschecks_pst: {
    value: 15,
    neo_component_id: 'essencheck',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    range: {
      min_eq: 1,
      max_eq: 15,
      decimalsCount: 2
    },
    attributes: {currency: {to_format: false}},
    per_item_price: 6.4,
    per_item_price_settings: {
      is_editable: false,
      is_visible: true,
      range: {
        min: 0,
        max_eq: 6.4
      }
    },
    additional_costs: {
      flat_tax: {
        payer: DEFAULT_TAX_PAYER['id'],
        type: TAX_TYPE_BY_NAME['monthly']['id'],
        is_enabled: true,
        value: 0.25
      }
    },
    on_activation: {'disable': 'essensschecks_frei'},
    on_enable: {'disable': 'essensschecks_frei'},
    active: false,
    ag_cost: CHURCH_TAX_TYPES.reduce((acc, type) => {
      acc[type.id] = type.default;
      return acc;
    }, {}),
    multiplier: 3.3
  },
  essensschecks_frei: {
    value: 15,
    requires_address: true,
    neo_component_id: 'essenscheck',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    range: {
      min_eq: 1,
      max_eq: 15,
      decimalsCount: 2
    },
    attributes: {currency: {to_format: false}},
    per_item_price: 3.1,
    per_item_price_settings: {
      is_editable: false,
      is_visible: true,
      range: {
        min: 0,
        max_eq: 3.1
      }
    },
    on_enable: {'disable': 'essensschecks_pst'},
    on_activation: {'disable': 'essensschecks_pst'},
    active: false
  },
  pc: {
    value: 0,
    neo_component_id: 'pc',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: true
      }
    },
    requires_address: true,
    range: {
      min: 349,
      max_eq: 10000,
      decimalsCount: 2
    },
    per_item_price: 0.0477,
    per_item_price_settings: {is_visible: false},
    round: true,
    active: false
  },
  pc_1: {
    value: 0,
    neo_component_id: 'pc',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: true
      }
    },
    requires_address: true,
    range: {
      min: 349,
      max_eq: 10000,
      decimalsCount: 2
    },
    per_item_price: 0.0477,
    per_item_price_settings: {is_visible: false},
    round: true,
    active: false
  },
  handy: {
    value: 39.90,
    neo_component_id: 'handy',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    additional_costs: {
      valuenet_tax: {
        payer: TAX_PAYERS_BY_NAME['employee']['id'],
        type: TAX_TYPE_BY_NAME['yearly']['id'],
        is_enabled: true,
        value: 36
      }
    },
    range: {
      max_eq: 100,
      decimalsCount: 2
    },
    active: false
  },
  internet: {
    value: 35,
    neo_component_id: 'internet',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    additional_costs: {
      flat_tax: {
        payer: DEFAULT_TAX_PAYER['id'],
        type: TAX_TYPE_BY_NAME['monthly']['id'],
        is_enabled: true,
        value: 0.25
      }
    },
    requires_address: true,
    range: {
      min: 0,
      max_eq: 50,
      decimalsCount: 2
    },
    active: false,
    ag_cost: CHURCH_TAX_TYPES.reduce((acc, type) => {
      acc[type.id] = type.default;
      return acc;
    }, {})
  },
  entfernung: {
    value: 0,
    neo_component_id: 'entfernung',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    range: {
      max_eq: 4500,
      decimalsCount: 2
    },
    additional_costs: {
      flat_tax: {
        payer: DEFAULT_TAX_PAYER['id'],
        type: TAX_TYPE_BY_NAME['monthly']['id'],
        is_enabled: true,
        value: 0.15
      }
    },
    active: false,
    ag_cost: CHURCH_TAX_TYPES.reduce((acc, type) => {
      acc[type.id] = type.default;
      return acc;
    }, {})
  },
  erholungsbeihilfe: {
    value: ERHOLUNGSBEIHILFE_OPTIONS[0].default,
    neo_component_id: 'erholungsbeihilfe',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      dates: {
        bonus_start_date: undefined,
        bonus_end_date: undefined,
        user_decision_date: undefined
      },
      an_max_yearly_payment: 156,
      ehe_max_yearly_payment: 104,
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    options: ERHOLUNGSBEIHILFE_OPTIONS.reduce((acc, option) => {
      acc[option.id] = option.default;
      return acc;
    }, {}),
    range: {
      max_eq: 968,
      decimalsCount: 2
    },
    additional_costs: {
      flat_tax: {
        payer: DEFAULT_TAX_PAYER['id'],
        type: TAX_TYPE_BY_NAME['monthly']['id'],
        is_enabled: true,
        value: 0.25
      }
    },
    per_item_price: 1 / 12,
    per_item_price_settings: {is_visible: false},
    active: false,
    ag_cost: CHURCH_TAX_TYPES.reduce((acc, type) => {
      acc[type.id] = type.default;
      return acc;
    }, {})
  },
  kinder: {
    value: 0.0,
    neo_component_id: 'kinder',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    range: {
      max_eq: 1000,
      decimalsCount: 2
    },
    active: false
  },
  bgmbudget: {
    value: 0.0,
    neo_component_id: 'bgmbudget',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      }

    },
    requires_address: true,
    range: {
      max_eq: 600,
      decimalsCount: 2
    },
    active: false
  },
  jobticket: {
    value: 0.0,
    neo_component_id: 'jobticket',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      }
    },
    range: {
      max_eq: 4500,
      decimalsCount: 2
    },
    requires_address: true,
    active: false
  },
  egeschenk: {
    value: 0.0,
    neo_component_id: 'egeschenk',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    range: {
      max_eq: 10000,
      decimalsCount: 2
    },
    active: false
  },
  EBike: {
    value: 0.0,
    neo_component_id: 'EBike',
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    enabled: false,
    active: false
  },
  dienstwagen_miete: {
    value: 0.0,
    neo_component_id: 'dienstwagen_miete',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    active: false,
    range: {
      max_eq: 40,
      decimalsCount: 2
    }
  },
  dienstwagen_auslage: {
    value: 0.0,
    neo_component_id: 'dienstwagen_auslage',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    active: false,
    range: {
      max_eq: 40,
      decimalsCount: 2
    }
  },
  personal_rabatt: {
    value: 0.0,
    neo_component_id: 'personal_rabatt',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    range: {
      max_eq: 90,
      decimalsCount: 2
    },
    active: false
  },
  VMA_PST: {
    value: 0.0,
    neo_component_id: 'VMA_PST',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    per_item_price: 6.4,
    per_item_price_settings: {
      is_editable: false,
      is_visible: false,
      range: {
        min: 0,
        max_eq: 6.4
      }
    },
    on_activation: {'disable': 'VMA_STSVFREI'},
    on_enable: {'disable': 'VMA_STSVFREI'},
    range: {
      max_eq: 15,
      decimalsCount: 2
    },
    active: false
  },
  VMA_STSVFREI: {
    value: 0.0,
    neo_component_id: 'VMA_STSVFREI',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    on_activation: {'disable': 'VMA_PST'},
    on_enable: {'disable': 'VMA_PST'},
    per_item_price: 6.4,
    per_item_price_settings: {
      is_editable: false,
      is_visible: false,
      range: {
        min: 0,
        max_eq: 6.4
      }
    },
    range: {
      max_eq: 15,
      decimalsCount: 2
    },
    active: false
  },
  festnetz: {
    value: 0.0,
    neo_component_id: 'festnetz',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    additional_costs: {
      valuenet_tax: {
        payer: TAX_PAYERS_BY_NAME['employee']['id'],
        type: TAX_TYPE_BY_NAME['yearly']['id'],
        is_enabled: true,
        value: 12
      }
    },
    range: {
      max_eq: 100,
      decimalsCount: 2
    },
    active: false
  },
  besonderer_anlass: {
    value: 60.0,
    neo_component_id: 'besonderer_anlass',
    enabled: false,
    configuration: {
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    range: {
      max_eq: 60.0,
      decimalsCount: 2
    },
    active: false
  },
  Bikeleasing: {
    value: 0,
    neo_component_id: 'Bikeleasing',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    active: false
  },
  bike_1: {
    value: 0,
    neo_component_id: 'Bikeleasing',
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    active: false
  },
  VWL: {
    value: 0,
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: true,
    active: false
  },
  BAV: {
    value: 0,
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      },
      // dashboard: {
      //   visible: {
      //     is_enabled: true,
      //     start_date: undefined,
      //     end_date: undefined
      //   },
      //   active: {
      //     is_enabled: true,
      //     start_date: undefined,
      //     end_date: undefined
      //   }
      // }
    },
    range: {
      max_eq: 100,
      decimalsCount: 2
    },
    requires_address: true,
    active: false
  },
  Freistellungstag: {
    value: 0,
    enabled: false,
    configuration: {
      checkout: {
        auto_add: true
      },
      toc: {
        requires_additional_toc: false
      }
    },
    requires_address: false,
    active: false,
    options: FREISTELLUNGSTAG_OPTIONS.reduce((acc, option) => {
      acc[option.id] = option.default;
      return acc;
    }, {})
  },
  wertguthaben: {
    value: 0,
    enabled: false,
    configuration: {
      checkout: {
        auto_add: false
      },
      toc: {
        requires_additional_toc: false
      },
      dashboard: {
        visible: {
          is_enabled: true,
          start_date: undefined,
          end_date: undefined
        },
        active: {
          is_enabled: true,
          start_date: undefined,
          end_date: undefined
        }
      }
    },
    requires_address: true,
    active: false
  },
  total: 0,
  ag_cost_total: 0
};
