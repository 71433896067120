const $inject = [
  '$scope',
  '$q',
  'lodash',
  'dialogService',
  'moment',
  'Made',
  '$timeout',
  '$window',
  'employeeService',
  'BonusService',
  'administrationService',
  'customerService',
  'bikeleasingService'
];
export default class NeoBikeleasingApproveController {
  constructor(
    $scope,
    $q,
    lodash,
    dialogService,
    moment,
    Made,
    $timeout,
    $window,
    employeeService,
    BonusService,
    administrationService,
    customerService,
    bikeleasingService
  ) {

    Object.assign(this, {
      $scope,
      $q,
      lodash,
      dialogService,
      moment,
      Made,
      $timeout,
      $window,
      employeeService,
      BonusService,
      administrationService,
      customerService,
      bikeleasingService
    });

    this.loading = true;
    this.approval = this.$scope.ngDialogData.approval;
    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.formatBikeleasing(this.approval);
    this.loading = false;
  }

  formatBikeleasing(approval) {
    this.bikeleasing_component = {
      'checkout_config': {
        full_amount: approval['neo_bikeleasing']['full_amount'],
        per_month_value: approval['neo_bikeleasing']['per_month_value'],
        validation_code: approval['neo_bikeleasing']['validation_code'],
        validation_url: approval['neo_bikeleasing']['validation_url']
      },
      dates: {
        bonus_start_date: approval['neo_bikeleasing']['dates']['contract_begin_date']
      },
    };
  }

  async approveBikeleasingComponent() {
    this.loading = true;
    await this.bikeleasingService.approveNeoBikeleasing({bikeleasing_id: this.approval['entity']['_id']}).catch((err) => {
      this.$scope.closeThisDialog();
    });

    this.$scope.confirm();
  }

  close() {
    this.$scope.confirm();
  }
}
NeoBikeleasingApproveController.$inject = $inject;
