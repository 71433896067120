import { BonusComponentController } from './BonusComponentController';

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export class BonusComponentControllerFreistellungstag extends BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {

    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }

  async init() {
    this.FID_EMPLOYEE_TYPE_BY_NAME = this.BonusService.getFidEmployeeTypeByName();
  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true) {
    return super.addComponentToEmployeeBonusComponents(false).then(async (result) => {
      if (!result) {
        return;
      }

      if (!this.model.is_optimized) {
        this.calculateValue();
      }

      if (move_to_next_step) {
        this.WizardHandler.wizard().next();
      }

    });
  }

  calculateValue() {
    let user_fid_type = this.BonusService.getUserFidInfoType();
    this.model.value = this.component_budget_config.options[user_fid_type['option_id']];
  }
}

BonusComponentControllerFreistellungstag.$inject = $inject;
