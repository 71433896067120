import table_view_html from "./templates/tableView.pug";

angular
  .module('VSPApp')
  .directive('datevTableView', ['$timeout',function  ($timeout) {
    return {
      restrict: 'EA',
      template: table_view_html,
      replace: true,
      scope: {
        tableData: '<'
      },
      link: function ($scope) {

        // TODO: use the formatNumber filter
        const _getItem = (obj, key) => {
          let val = parseFloat(obj[key]) || './.';
          if (typeof val === 'number' && key !== 'raise_percent') {
            return (val / 100).toFixed(2);
          }
          return val;
        };


        $timeout(() => {
          const $table = document.querySelector('[datev-table-view]');

          const an3 = $table.querySelector('.veränderung_an > div:nth-child(3)');
          const an4 = $table.querySelector('.veränderung_an > div:nth-child(4)');

          const ag3 = $table.querySelector('.veränderung_ag > div:nth-child(3)');
          const ag4 = $table.querySelector('.veränderung_ag > div:nth-child(4)');

          ag3.classList.add(`${parseInt(ag3.innerText) > 0 ? 'positive-value' : 'negative-value'}`);
          ag4.classList.add(`${parseInt(ag4.innerText) > 0 ? 'positive-value' : 'negative-value'}`);
          an3.classList.add(`${parseInt(an3.innerText) > 0 ? 'positive-value' : 'negative-value'}`);
          an4.classList.add(`${parseInt(an4.innerText) > 0 ? 'positive-value' : 'negative-value'}`);

        });

        $scope.appendClassName = (className) => {
          return className.replace(' ', '_').toLowerCase();
        }
        $scope.$watch('tableData', (newData = $scope.tableData) => {
          const {before, normal, optimized} = newData;

          $scope.tableHeader = ['Bezeichnung', 'Aktuell', 'Bruttoerhöhung', 'ValueNet'];
          $scope.tableBody = [
            ['Brutto', _getItem(before, 'brutto'), _getItem(normal, 'brutto'), _getItem(optimized, 'brutto')],
            [`${_getItem(normal, 'raise_percent')} % Plus`, _getItem(before, 'raise_value'), _getItem(normal, 'raise_value'), _getItem(optimized, 'raise_value')],
            ['Benefits', _getItem(before, 'benefit'), _getItem(normal, 'benefit'), _getItem(optimized, 'benefit')],
            ['LSt', _getItem(before, 'lst'), _getItem(normal, 'lst'), _getItem(optimized, 'lst')],
            ['KiSt', _getItem(before, 'kst'), _getItem(normal, 'kst'), _getItem(optimized, 'kst')],
            ['SolZ', _getItem(before, 'SolZ'), _getItem(normal, 'SolZ'), _getItem(optimized, 'SolZ')],
            ['SV', _getItem(before, 'sv'), _getItem(normal, 'sv'), _getItem(optimized, 'sv')],
            ['Netto', _getItem(before, 'netto'), _getItem(normal, 'netto'), _getItem(optimized, 'netto')],
            ['Veränderung AN', _getItem(before, 'change_an'), _getItem(normal, 'change_an'), _getItem(optimized, 'change_an')],
            ['AGKosten', _getItem(before, 'ag_cost'), _getItem(normal, 'ag_cost'), _getItem(optimized, 'ag_cost')],
            ['Veränderung AG', _getItem(before, 'change_ag'), _getItem(normal, 'change_ag'), _getItem(optimized, 'change_ag')],
          ];
        });
      }
    };

  }]);
