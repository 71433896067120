angular
  .module('VSPApp')
  .directive('readFile', ['fileService', function (fileService) {
    return {
      restrict: 'A',
      scope: {
        variableName: '=',
        fileContentHandler: '&'
      },
      link: function ($scope, $element) {
        $element.bind('change', function (changeEvent) {
          fileService.readFile(changeEvent.target.files[0]).then(content => {
              let result = {};
              result[$scope.variableName] = content;
              $scope.fileContentHandler(result);
            }
          );
        });
      }
    };
  }]);
