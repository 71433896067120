var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["summary-box"] = pug_interp = function(selectedCompany, save, disabled, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"summary-box isTransparent\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns\"\u003E";
if (selectedCompany) {
pug_html = pug_html + "\u003Cspan class=\"summary-box__title\"\u003E\u003Cstrong\u003EFirma:\u003C\u002Fstrong\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' '+ selectedCompany) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"hide-for-small-only\"\u003E&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns text-right pull-right\"\u003E";
block && block();
if (save) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"button button-primary isOutline\""+pug.attr("ng-click", save, true, true)+pug.attr("ng-disabled", disabled, true, true)+pug.attr("data-cy", cyData, true, true)) + "\u003ESpeichern\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};








pug_mixins["searchHeader"] = pug_interp = function( title, searchPostFix, change, cyData ){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!searchPostFix) {
searchPostFix = ''
}
attributes = {}
if (change) {
attributes['ng-change']=change
}
pug_html = pug_html + "\u003Cdiv class=\"list-header with__search\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-4 columns\"\u003E\u003Cspan class=\"summary-box__title\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-8 medium-4 columns text-right\"\u003ESortieren:\u003Cselect class=\"simple-select\" ng-model=\"filter.order\" vn-number-to-bool\u003E\u003Coption value=\"0\"\u003EA-Z\u003C\u002Foption\u003E\u003Coption value=\"1\"\u003EZ-A\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 medium-4 columns\"\u003E\u003Cdiv class=\"row search-box\"\u003E\u003Cdiv class=\"xsmall-2 columns\"\u003E\u003Clabel class=\"prefix\" for=\"search\"\u003E\u003Ci class=\"font-icon icon-search\"\u003E\u003C\u002Fi\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-10 columns\"\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": "search-box__input","id": "search","type": "text","placeholder": "Suche...","ng-model": pug.escape(`filter.search${searchPostFix}`),"ng-model-options": "{debounce: 600}","data-cy": pug.escape(cyData)},attributes]), true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"cockpit_kundenauswahl-wrapper\"\u003E";
pug_mixins["summary-box"].call({
block: function(){
pug_html = pug_html + "\u003Cbutton class=\"button button-primary isOutline\" ng-if=\"KundenauswahlCtrl.iAmSpecialAdvisor || KundenauswahlCtrl.iAmGAdvisor\" ng-click=\"KundenauswahlCtrl.adviseCustomer()\"\u003ENeuen Kunden freischalten\u003C\u002Fbutton\u003E";
}
});
pug_mixins["searchHeader"](
    '{{ KundenauswahlCtrl.customers.length || "keine" }} Firmen',
    false,
    'KundenauswahlCtrl.search(filter.search)',
    'vn.navigation.cockpit.customerToolbar.inputSearch'
  );
pug_html = pug_html + "\u003Cdiv class=\"list-box\"\u003E\u003Cdiv class=\"list-box__item\" ng-repeat=\"customer in KundenauswahlCtrl.customers | orderBy:'name':filter.order\"\u003E\u003Cspan class=\"list-box__itemTitle\"\u003E{{ customer.id | formatNumber:'prepend' }} | \u003Cspan\u003E{{ customer.name }}\u003C\u002Fspan\u003E\u003Chover class=\"hovered\"\u003E- {{ customer.strasse }}, {{ customer.plz }} {{ customer.ort }}\u003C\u002Fhover\u003E\u003C\u002Fspan\u003E\u003Cdiv class=\"list-box__right\"\u003E\u003Cdiv class=\"changed-state left\" ng-show=\"customer.state_changed.changed_at != undefined\"\u003E{{ customer.state_changed.changed_at*1000 | date:'dd.MM'}}\u003Cbr\u003E{{ customer.state_changed.changed_at*1000 | date:'yyyy'}}\u003C\u002Fdiv\u003E\u003Cbutton class=\"button button-primary isOutline access-state\" ng-click=\"KundenauswahlCtrl.testmodeDialog(customer)\" ng-disabled=\"!KundenauswahlCtrl.iAmSpecialAdvisor\" ng-show=\"customer.deactivated == true \" ng-class=\"{red:true}\"\u003EInaktiv\u003C\u002Fbutton\u003E\u003Cbutton class=\"button button-primary isOutline access-state\" ng-click=\"KundenauswahlCtrl.testmodeDialog(customer)\" ng-disabled=\"!KundenauswahlCtrl.iAmSpecialAdvisor\" ng-show=\"customer.deactivated != true &amp;&amp; customer.testmode != true\" ng-class=\"{green:true}\"\u003EAktiv\u003C\u002Fbutton\u003E\u003Cbutton class=\"button button-primary isOutline access-state\" ng-click=\"KundenauswahlCtrl.testmodeDialog(customer)\" ng-disabled=\"!KundenauswahlCtrl.iAmSpecialAdvisor\" ng-show=\"customer.deactivated != true &amp;&amp; customer.testmode == true\" ng-class=\"{yellow:true}\"\u003EDemo\u003C\u002Fbutton\u003E\u003Cbutton class=\"button button-primary isOutline\" ng-click=\"KundenauswahlCtrl.goToCustomer(customer)\" data-cy=\"vn.navigation.cockpit.{{customer.id}}.buttonOpenCustomer\"\u003EKonfigurieren\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;