import {
  ID_EMPLOYEE_STATUS_FLOWS,
  NAME_EMPLOYEE_STATUSES_MAP,
  NAME_EMPLOYEE_STATUS_FLOWS
} from '../../../../services/employeeService';

const $inject = [
  '$scope',
  '$q',
  '$window',
  'administrationService',
  'customerService',
  'vbmData',
  'NotificationService',
  'dialogService',
  'userService',
  'employeeService',
  'Made',
  'UserNotificationService',
  'moment',
  '$timeout'
];
export default class ControlProcessDocumentFLowDialogController {
  constructor(
    $scope,
    $q,
    $window,
    administrationService,
    customerService,
    vbmData,
    NotificationService,
    dialogService,
    userService,
    employeeService,
    Made,
    UserNotificationService,
    moment,
    $timeout
  ) {

    Object.assign(this, {
      $scope,
      $q,
      $window,
      administrationService,
      customerService,
      vbmData,
      NotificationService,
      dialogService,
      userService,
      employeeService,
      Made,
      UserNotificationService,
      moment,
      $timeout
    });

    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.customer_id = this.$scope.ngDialogData.customer_id;
    this.NAME_EMPLOYEE_STATUSES_MAP = NAME_EMPLOYEE_STATUSES_MAP;
    this.loading = true;

    let promises = [];

    promises.push(
      this.customerService.get(this.customer_id).then((customer) => {
        this.customer = customer;
      })
    );

    promises.push(
      this.employeeService.getContracts(this.employee_id, true).then((contract) => {
        this.contract = contract;
      })
    );

    promises.push(
      this.employeeService.get(this.employee_id).then(employee => {
        this.employee = employee;
      })
    );

    promises.push(
      this.employeeService.getCurrentStatus(this.employee_id).then(employee_status => {
        this.employee_status = employee_status;
      })
    );

    let employee_lock_promise = this.administrationService.lockEmployee(this.employee_id);
    employee_lock_promise.then((employee_lock) => {
      this.employee_lock = employee_lock;
      this.is_locked_by_me = this.employee_lock.by_advisor['id'] === this.Made.user.valuenet_id;

      if (this.is_locked_by_me) {
        this.NotificationService.message('Mitarbeiter gesperrt');
      } else {
        this.NotificationService.error(`Datensatz in Bearbeitung durch ${employee_lock.by_advisor.id}, ${employee_lock.by_advisor.firstname}, ${employee_lock.by_advisor.lastname}`);
      }
    }).then(() => {
      return this.$q.all(promises);
    }).then(() => {
      this.$window.addEventListener('beforeunload', () => {
        this.administrationService.unlockEmployee(this.employee_id);
      });
    }).finally(() => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    });

    this.evg_files = [];
  }

  //TODO: Refactor all URLs for generating reports in a separate service
  openEVGDocument() {
    this.administrationService.createEVGContractDocument(this.employee.id, this.contract.calc_id).then(
      (res) => {
        this.$window.open(res.response, '_blank');
      }).catch((err) => {
        this.NotificationService.error('Fehlgeschlagen');
      });
  }

  updateEmployeeStatus(has_document_error, comment) {
    switch (this.employee_status.id) {
      //kontrolle_t -> kontrolle_p1 (flow: 28 - Dokumente gedruckt)
      case NAME_EMPLOYEE_STATUSES_MAP['kontrolle_t'].id:
        this.updateStatus(NAME_EMPLOYEE_STATUS_FLOWS['docflow_print'].id).then((res) => {
          this.$scope.confirm(true);
        }).catch((err) => {
          this.$scope.confirm(false);
        });
        break;

      //kontrolle_p1 -> kontrolle_p2 (flow: 29 - Dokumente versandt)
      case NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p1']['id']:
        this.updateStatus(NAME_EMPLOYEE_STATUS_FLOWS['docflow_sent'].id).then((res) => {
          this.administrationService.sendControlProcessEmail(this.employee.id, 'documents_sent');

          this.UserNotificationService.createNotification(this.employee.id, 'documents_sent').then((res) => {
            this.$scope.confirm(true);
          }).catch((err) => {
            this.$scope.confirm(true);
          });

        }).catch((err) => {
          this.$scope.confirm(false);
        });
        break;

      //kontrolle_p2 -> kontrolle_p3 (flow: 30 - Dokumente eingegangen)
      case NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p2']['id']:
        this.updateStatus(NAME_EMPLOYEE_STATUS_FLOWS['docflow_return'].id).then((res) => {
          this.administrationService.sendControlProcessEmail(this.employee.id, 'documents_received');
          this.UserNotificationService.createNotification(this.employee.id, 'documents_received').then((res) => {
            this.$scope.confirm(true);
          }).catch((err) => {
            this.$scope.confirm(true);
          });
        }).catch((err) => {
          this.$scope.confirm(false);
        });

        break;

      //kontrolle_p3 -> aktiv (flow: 13 - Aktiv) or kontrolle_p3 -> kontrolle_e (flow: 31 - Docflow Error)
      case NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p3']['id']:
        if (has_document_error) {
          this.updateStatus(NAME_EMPLOYEE_STATUS_FLOWS['docflow_error'].id).then((res) => {
            this.administrationService.sendControlProcessEmail(this.employee.id, 'documents_error', {comment: comment});
            this.$scope.confirm(true);
          }).catch((err) => {
            this.$scope.confirm(false);
          });
        } else {
          this.updateStatus(NAME_EMPLOYEE_STATUS_FLOWS['aktiv'].id).then((res) => {
            this.administrationService.sendControlProcessEmail(this.employee.id, 'documents_ready', {contract_date: this.contract.evbegin});
            this.UserNotificationService.createNotification(this.employee.id, 'documents_ready').then((res) => {
              this.$scope.confirm(true);
            }).catch((err) => {
              this.$scope.confirm(true);
            });
          }).catch((err) => {
            this.$scope.confirm(false);
          });
        }
        break;
      case NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p6']['id']:
        this.updateStatus(NAME_EMPLOYEE_STATUS_FLOWS['digital_sign_contract_cancellation'].id).then((res) => {
          this.$scope.confirm(true);
        }).catch((err) => {
          this.$scope.confirm(false);
        });
        break;
      default:
        this.NotificationService.error('Statusaktualisierung ist fehlgeschlagen.');
        this.$scope.confirm(false);
    }
  }

  updateStatus(flow_id) {
    let payload = {
      flow_id: ID_EMPLOYEE_STATUS_FLOWS[flow_id].id,
      comment: ' '
    };

    // 13 (aktiv) has to set as date_of_return the begin date of the contract
    if (flow_id === NAME_EMPLOYEE_STATUS_FLOWS['aktiv'].id) {
      payload.date_of_change = this.contract.evbegin;
    }

    return this.userService.updateNeoStatus(this.employee.id, payload, this.Made.user.valuenet_id).then(() => {
      this.NotificationService.message('Der Status wurde aktualisiert.');
    }).catch((err) => {
      this.NotificationService.error('Statusaktualisierung ist fehlgeschlagen.');
      this.$scope.confirm(false);
    });
  }

  iHaveAccess() {
    return this.is_locked_by_me && !this.loading;
  }

  rejectDocuments() {
    this.updateEmployeeStatus(true, this.documentErrorCommentForm.comment.$modelValue);
  }

  areEvgFilesValid() {
    return this.evg_files && this.evg_files.length > 0;
  }

  confirmDocuments() {
    if (this.areEvgFilesValid()) {
      this.uploadEVGDocuments().then(() => {
        this.updateEmployeeStatus();
      });
    }
  }

  uploadEVGDocuments() {
    let promises = [];

    this.uploading = true;

    let tags = ['evg', 'evg_final', this.contract.ai_id];

    for (let i = 0; i < this.evg_files.length; i++) {
      promises.push(this.Made.upload(this.evg_files[i], tags, this.employee._id));
    }

    return this.$q
      .all(promises)
      .then(() => {
        this.evg_files = [];
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  cancelDigitalSigning() {
    this.$timeout(() => {
      this.loading = true;
    });

    this.administrationService.cancelNeoDigitalSigning(
      this.employee_id,
      this.digitalSignatureCancellationForm.comment.$modelValue
    ).catch(() => {
      this.NotificationService.error("Fehlgeschlagen");
    }).finally(() => {
      this.$timeout(() => {
        this.loading = false;
      });

      this.$scope.confirm(true);
    });
  }
}
ControlProcessDocumentFLowDialogController.$inject = $inject;
