// parent component
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LoadingService } from '../../../../shared/services/loading.service';
import { DTicketOrder } from '../../../models/dticket/dticket-order.model';
import {DticketOrderService, EmployeeUpdateData, LegacyEmployeeData} from '../../../services/dticket-order.service';
import { DticketOrderActivateDialogComponent } from '../dticket-order-activate-component/dticket-order-activate-dialog.component';

export interface UserPreferencesDialogCloseData {
  employeeData: {
    firstname: string,
    birthdate: string,
    lastname: string
  } | null,
  pdfPreferencesProcessed: { success: boolean },
  requestToVesputiSent: { success: boolean }
  requestToRestoreTicket: { success: boolean }
}

export interface UserPreferencesData {
  dticketOrder: DTicketOrder,
  employeeData: EmployeeUpdateData
}

@Component({
  selector: 'vn-dticket-order-user-preferences-dialog',
  templateUrl: './dticket-order-vesputi-pdf-dialog.component.html',
  styleUrls: ['./dticket-order-vesputi-pdf-dialog.component.scss']
})
export class DticketOrderVesputiPdfDialogComponent extends DticketOrderActivateDialogComponent implements OnInit {
  dialogTitle = 'Einstellungen zur Ticketnutzung';
  dialogConfirmButton: string = "Speichern";
  form!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: UserPreferencesData,
    dialogRef: MatDialogRef<DticketOrderVesputiPdfDialogComponent>,
    dticketService: DticketOrderService,
    loader: LoadingService,
    formBuilder: FormBuilder,
    dialog: MatDialog,
    @Inject('Made') made: any
  ) {
    super(
      dialogData,
      dialogRef,
      dticketService,
      loader,
      formBuilder,
      dialog,
      made,
    );
  }

  ngOnInit() {
    if (!this.dialogData.dticketOrder.userPreferences) {
      throw new Error('No preferences');
    }

    this.canSendToVesputi = this.dialogData.dticketOrder.canSendOrderToVesputiOnUserPreferences();

    if (this.canSendToVesputi) {
      this.dialogConfirmButton += ' & Senden';
    }

    this.form = this.formBuilder.group({
      isPdfTicket: new FormControl(this.dialogData.dticketOrder.userPreferences.isPdfTicket)
    });
  }

  async handleFormSaved(savedData: LegacyEmployeeData|null) {

    const pdfTicketPreferences = this.form.getRawValue().isPdfTicket;
    await this.dticketService.processUserPDFPreferences(
      this.dialogData.dticketOrder.employeeId,
      pdfTicketPreferences
    );

    if (this.canSendToVesputi && pdfTicketPreferences) {
      await this.dticketService.sendVesputiRequest(this.dialogData.dticketOrder.id);
    }

    this.dialogRef.close(true);
  }
}
