import { Inject, Injectable } from "@angular/core";
import { BackofficeAdministrationBonusAllowedOperations, CustomerAdministrationBonusAllowedOperations } from "./direct-bonus-allowed-operations.model";
import { BaseMapper } from "./direct-bonus-project-overview.model";

export class DirectBonusProjectOverviewTableEmployee {
  constructor(
    public id: number,
    public name: string,
    public personalNumber: string,
    public status: string
  ) {
  }
}

export type DirectBonusComponentType = 'besonderer_anlass' | 'sachbezug';

export const DIRECT_BONUS_COMPONENTS: DirectBonusComponentType[] = [
  'sachbezug',
  'besonderer_anlass'
];

export interface DirectBonusComponent {
  value: number,
  bonusId: string,
  componentName: DirectBonusComponentType,
  reason: string
}


export interface DirectBonusProjectMonthOverview {
  orderId: string | null,
  components: DirectBonusComponent[],
  bonusAllowedOperation: CustomerAdministrationBonusAllowedOperations,
  isMainOrder: boolean,
  isTopUpped: boolean,
}

export interface BonusesPerMonth {
  [month: number]: DirectBonusProjectMonthOverview[];
}

export class DirectProjectOverviewBonusTableDataRow {
  constructor(
    public employee: DirectBonusProjectOverviewTableEmployee,
    public bonusesPerMonth: BonusesPerMonth,
  ) {

  }
}

@Injectable()
export class DirectProjectOverviewBonusTableDataRowMapper implements BaseMapper {
  constructor(
    @Inject('moment') private moment: any,
  ) { }

  public fromApi(o: any): DirectProjectOverviewBonusTableDataRow {
    const directBonusProjectEmployee = new DirectBonusProjectOverviewTableEmployee(
      o['employee']['id'],
      o['employee']['name'],
      o['employee']['personalNumber'],
      o['employee']['status']
    )

    let bonuses = o['bonuses_per_month']

    return new DirectProjectOverviewBonusTableDataRow(
      directBonusProjectEmployee,
      bonuses
    )
  }
}

