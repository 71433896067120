const ComponentWizardConstants = {
  dienstwagen_miete: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980824?dnt=1',
        subtitle:    'Garagenmiete vom Arbeitgeber',
        subsubtitle: 'Wenn Sie einen Dienstwagen haben und Sie stellen diesen in einer Garage unter deren Eigentümer sie sind, dann können Sie diese an Ihren Arbeitgeber vermieten. Hierzu schließen Sie einen Mietvertrag mit Ihrem Arbeitgeber ab und er überweist Ihnen jeden Monate die Miete zusammen mit Ihrem Gehalt. Die Miete ist kein Lohn und somit steuerfrei. Die monatliche Miete darf dabei maximal 40 Euro betragen.'
      },
      {
        title:      'Bitte geben Sie die Kosten Ihrer Dienstwagengarage an.',
        valueRange: {
          min: 0,
          max: 40
        }
      },
      {
        description: 'Sie haben {{ model.enabled ? model.value : 0 | currency }} als Kosten für ihre Dienstwagengarage angegeben. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  dienstwagen_auslage: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980824?dnt=1',
        subtitle:    'Steuerfreie Garagenmiete',
        subsubtitle: 'Wenn Sie einen Dienstwagen haben und diesen in einer von Ihnen gemieteten Garage unterstellen, kann Ihr Arbeitgeber Ihnen die Mietkosten steuerfrei ersetzen.'
      },
      {
        title:      'Bitte geben Sie die Kosten Ihrer Dienstwagengarage an.',
        valueRange: {
          min: 0,
          max: 250
        },
      },
      {
        description: 'Sie haben {{ model.enabled ? model.value : 0 | currency }} als Kosten für ihre Dienstwagengarage angegeben. Bitte beachten Sie, dass Sie zum Abschluss des Bausteins den Garagenmietvertrag benötigen. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  entfernung: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980824?dnt=1',
        subtitle:    'Sparen beim Fahren!',
        subsubtitle: 'Wir leisten einen Zuschuss zu Ihren Aufwendungen für Fahrten zwischen Wohnung und Arbeitsstätte. Die Höhe richtet sich nach den gesetzlichen Vorgaben des Einkommenssteuergesetzes und der Lohnsteuerrichtlinien (derzeit 0,30 € je Kilometer, für die einfache Strecke Wohnung - Arbeitsstätte). Die Abrechnung erfolgt nicht arbeitstäglich, sondern nach einer Pauschalierungsmethode mit 15 Arbeitstagen.\nDer Vorteil liegt auf der Hand: Zusätzlich zu der möglichen Steuerersparnis, hat der Arbeitnehmer noch die Ersparnis der Sozialversicherungsabgaben.'
      },
      {
        title:       'Bitte geben Sie Ihre Entfernung zu Ihrem Arbeitsplatz an.',
        description: 'Bitte geben Sie hier die Entfernung ein. Sie können die Entfernung auch berechnen lassen, wenn Ihre Adresse korrekt hinterlegt ist.',
        items:       [
          {
            type:      'section',
            htmlClass: 'display_1_1',
            items:     [
              {
                type:      'button',
                htmlClass: 'left',
                title:     'Berechnen',
                onClick:   'component.getDistance()'
              }
            ]
          },
          {
            key:   'anzahl_km',
            title: 'Anzahl km'
          },
          {
            key:   'anzahl_tage',
            title: 'Anzahl Tage'
          }
        ]
      },
      {
        description: 'Ihr Arbeitgeber kann Ihnen {{ model.enabled ? model.value : 0 | currency }} als Fahrtkostenzuschuss erstatten. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  kinder: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980824?dnt=1',
        subtitle:    'Steuerfreier Zuschuss zur Kinderbetreuung',
        subsubtitle: 'Ihr Arbeitgeber kann Ihnen einen steuerfreien Zuschuss zur Kinderbetreuung zahlen. Begünstigt sind nur die Unterbringung und die Betreuung nicht schulpflichtiger Kinder in Kindergärten. Den Kindergärten vergleichbare Einrichtungen sind z.B.: Kinderkrippen, Kindertagesstätten, Schulkindergärten, Tagesmütter- oderWochenmütter sowie Ganztagspflegestellen. Die Einrichtung muss gleichzeitig zur Unterbringung und Betreuung von Kindern geeignet sein; die Betreuung allein genügt nicht. Die Unterbringung beinhaltet auch Unterkunft und Verpflegung.'
      },
      {
        title:      'Bitte geben Sie die monatlichen Kosten für die Kinderbetreuung an.',
        valueRange: {
          min: 0,
          max: 1000
        },
        description: 'Ihr Arbeitgeber kann die Kosten für Ihre Kinderbetreuung übernehmen, dabei ist es egal ob Ihr Kind zu einer Tagesmutter oder in den Kindergarten geht.',
        items:       [
          {
            type:      'help',
            helpvalue: '<a target="_blank" rel="noreferrer" class="button button-primary" href="assets/files/Aufwendungsnachweis_Kindergarten.pdf">Aufwendungsnachweis</a> ',
            htmlClass: 'left'
          },
          {
            type:      'button',
            title:     'Assistent öffnen',
            onClick:   'component.openKinderAssistant()',
            htmlClass: 'right'
          }
        ]
      },
      {
        description: 'Die Kosten für die Kinderbetreuung betragen {{ model.enabled ? model.value : 0 | currency }}. Bitte beachten Sie, dass sie zur Nutzung dieses Bausteins einen Nachweis von Ihrer Kinderbetreuungsstätte benötigen. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  internet: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397993803?dnt=1',
        subtitle:    'Steuerfrei Surfen',
        subsubtitle: 'Sie haben einen privaten Internetzugang? Dann können Sie Barzuschüsse Ihres Arbeitgebers für Ihre Internetnutzung erhalten! Es geht um rein privat verursachte Kosten, eine betriebliche Nutzung ist nicht nötig. Ohne Nachweis von Belegen können wir Ihnen monatlich pauschal bis zu 50 € netto auszahlen!'
      },
      {
        title:       'Bitte geben Sie die monatliche Grundgebühr Ihres Internetanschlusses ein.',
        description: 'Ihr Arbeitgeber kann die Kosten für Ihren Internetanschluss übernehmen.',
        items:       [
          {
            key:        'value',
            type:       'checkbox',
            title:      'Internetpauschale (50€) aktivieren',
            trueValue:  50,
            falseValue: 0
          }
        ]
      },
      {
        description: 'Sie erhalten zukünftig monatlich {{ model.enabled ? model.value : 0 | currency }} Internetpauschale von Ihrem Arbeitgeber. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  festnetz: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980901?dnt=1',
        subtitle:    'Steuerfrei Telefonieren',
        subsubtitle: 'Reduzieren Sie Ihre laufenden monatlichen Telefonkosten. Ihr Arbeitgeber übernimmt die Kosten für Ihren Festnetzanschluss. Um diesen Vorteil zu nutzen, muss ihr Arbeitgeber Eigentümer des Gerätes sein und Ihnen dieses zur Verfügung stellen, hiefür erhalten Sie von uns ein Festnetztelefon.'
      },
      {
        title:      'Bitte geben Sie die monatliche Grundgebühr Ihres Festnetzanschlusses ein.',
        valueRange: {
          min: 0,
          max: 100
        },
        description: 'Ihr Arbeitgeber kann die Kosten für Ihren Festnetzanschluss übernehmen.',
      },
      {
        description: 'Die Kosten für Ihren Festnetzanschluss betragen {{ model.enabled ? model.value : 0 | currency }}. Bitte beachten Sie, dass ein Nachweis der letzten 3 Festnetzrechnungen für den Abschluss dieses Bausteines benötigt wird. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  handy: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980901?dnt=1',
        subtitle:    'Steuerfrei Telefonieren (Mobil)',
        subsubtitle: 'Wenn Sie einen Vertrag (kein Prepaid) mit einem Mobilfunkanbieter haben, kann Ihr Arbeitgeber Teile Ihrer Rechnung übernehmen. Um diesen Vorteil zu nutzen, muss Ihr Arbeitgeber Eigentümer des Gerätes sein und Ihnen dieses zur Verfügung stellen, hierfür erhalten Sie von uns ein Handy.',
      },
      {
        title:      'Bitte geben Sie die monatliche Grundgebühr Ihres Mobilfunkvertrages ein.',
        valueRange: {
          min: 0,
          max: 100
        },
        description: 'Ihr Arbeitgeber kann die Kosten für Ihren Mobilfunkvertrag übernehmen.',
      },
      {
        description: 'Ihre Mobilfunkrechnung beträgt {{ model.enabled ? model.value : 0 | currency }} im Monat. Bitte beachten Sie, dass ein Nachweis der letzten 3 Mobilfunkrechnungen für den Abschluss dieses Bausteines benötigt wird. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  VMA_PST: {
    items: [
      {
        subtitle:    'Verpflegungsmehraufwand verdoppeln',
        subsubtitle: 'Wenn Sie länger als 8 Stunden von Ihrer Wohnung oder Ihrer Arbeitsstätte abwesenden sind kann Ihr Arbeitgeber den steuerfreien Verpflegungsmehrauwand verdoppeln. Das bedeutet, Sie bekommen zustäzlich zu den steuerfreien 15 € pro Tag weitere 15 € von ihm ausbezahlt.'
      },
      {
        title:       'Bitte geben Sie die Anzahl der Tage an denen Sie auswärts arbeiten.',
        description: 'Bitte geben Sie den Durschnitt der Tage pro Monat an, an denen Sie im im letzten Jahr eine Auswärtstätigket (>8 Stunden) ausgeübt haben. Sie erhalten 2024 15 € pro Tag.',
        model:       'anzahl_tage',
        valueRange:  {
          min: 0,
          max: 15
        },
        hideValueLabel: true
      },
      {
        description: 'Sie erhalten {{ model.enabled ? model.value : 0 | currency }} Verpflegungsmehraufwand. ' +
          'Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  VMA_STSVFREI: {
    items: [
      {
        subtitle:    'Steuerfreier Verpflegungsmehraufwand',
        subsubtitle: 'Wenn Sie länger als 8 Stunden von Ihrer Wohnung oder Ihrer Arbeitsstätte abwesenden sind, kann Ihr Arbeitgeber Ihnen eine tägliche Verpflegungspauschale zahlen. Diese beträgt 2024 15 € pro Tag und ist steuerfrei.'
      },
      {
        title:       'Bitte geben Sie die Anzahl der Tage an, an denen Sie auswärts arbeiten.',
        description: 'Bitte geben Sie den Durschnitt der Tage pro Monat an, an denen Sie im im letzten Jahr eine Auswärtstätigket (>8 Stunden) ausgeübt haben. Sie erhalten 2024 15 € pro Tag.',
        model:       'anzahl_tage',
        valueRange:  {
          min: 0,
          max: 15
        },
        hideValueLabel: true
      },
      {
        description: 'Sie erhalten {{ model.enabled ? model.value : 0 | currency }} Verpflegungsmehraufwand. ' +
          'Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  essenscheck: {
    items: [
      {
        // This will uncommented when essencheck video get ready, then do not remove it
        // video:       'https://player.vimeo.com/video/397980875?dnt=1',
        subtitle:    'Sparen Sie Steuern beim Essen!',
        subsubtitle: 'Mit dem Essensscheck gewähren wir Ihnen jeden Tag einen bargeldlosen Essenszuschuss. Und zwar brutto für netto, Sie zahlen dafür weder Steuern noch Sozialabgaben.\nVon Pizza bis Schweinebraten, von Fast Food bis Salat - mit dem Essensscheck steht Ihnen auch der Lebensmitteleinkauf in 70% aller Supermärkte offen.',
      },
      {
        title:      'An wie vielen Tagen pro Woche arbeiten Sie?',
        model:      'anzahl_tage',
        valueRange: {
          min: 0,
          max: 5
        },
        hideValueLabel: true,
        description:    'Sie erhalten 3 Essensschecks pro Wochenarbeitstag. Sie können diesen Baustein nutzen, wenn Sie im Kalenderjahr keine ODER weniger als 36 Tage eine Auswärtstätigkeit (Abwesenheit größer 8 Stunden) ausüben.',
        items:          [
          {
            type:           'iframe-popup',
            title:          'Akzeptanzstellen',
            fieldHtmlClass: 'button button-primary',
            url:            '"https://app.sodexo.de/mysodexo?productId=RK0001"'
          }
        ],
      },
      {
        description: 'Sie haben {{ model.enabled ? model.anzahl_gutscheine : 0 }} Essenschecks gewählt. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  sachbezug: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980920?dnt=1',
        subtitle:    'Steuerfrei Shoppen',
        subsubtitle: 'Sie können monatlich einen Betrag von 50 € von Ihrem Gehalt in Ihr Vorteilskonto umwandeln und über Ihren Arbeitgeber einkaufen oder auf eine ValueCard laden lassen. Der Vorteil für Sie: Sie kaufen steuerfrei ein! Nutzen Sie die vielfältigen Möglichkeiten, sei es beim Tanken, Reisen, Einkaufen oder beim Online Shoppen.',
      },
      {
        title:      'Bitte geben Sie den monatlichen Sachbezug ein.',
        valueRange: {
          min: 0,
          max: 50
        },
        description: 'Sie können monatlich zwischen 1 € und 50 € steuerfrei als Sachbezug erhalten.',
        items:       [
          {
            type:           'iframe-popup',
            title:          'Akzeptanzpartner',
            fieldHtmlClass: 'button button-primary',
            url:            '"https://searchv2.edenred.com/?CountryID=7&ProductID=2&LangueID=1&iv=TPCBNN&Address="+user.employer_address.zip'
          }
        ]
      },
      {
        title:       'Zusammenfassung',
        description: 'Sie haben {{ model.enabled ? model.value : 0 | currency }} als Sachbezug gewählt. ' +
          'Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  werbeflaeche: {
    items: [
      {
        subtitle:    'Werbung die sich lohnt',
        subsubtitle: 'Wenn Sie einen PKW besitzen, können Sie Kennzeichenhalterungen mit einem Werbeauftritt an Ihrem PKW anbringen und erhalten steuerfrei monatlich 21 €.',
      },
      {
        title:       'Die Nummernschildhalterung als Werbefläche',
        description: 'Bringen Sie eine Nummernschildhalterung an Ihr privates Auto an.',
        items:       [
          {
            key:        'value',
            title:      'Ich stelle die Werbefäche an meinem privaten PKW für 21 € im Monat zur Verfügung',
            type:       'checkbox',
            trueValue:  21,
            falseValue: 0
          },
        ]
      },
      {
        type:        'wizardbox',
        title:       'Zusammenfassung',
        description: 'Wenn Sie diesen Baustein nutzen,  werden wir Ihnen eine Nummernschildhalterung zur Verfügung stellen, bitte bringen Sie diese an ihrem PKW an. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  EBike: {
    items: [
      {
        subtitle:    'Jobrad',
        subsubtitle: 'Bewegung, Motivation, Emotion. Ein nachhaltig gesundes Konzept, das sich garantiert für Sie rechnet - in mehrfacher Hinsicht: Sie tun etwas für Ihre Gesundheit und Fitness, Sie schonen die Umwelt und Ihren Geldbeutel. Umfahren Sie künftig den Stau zur Arbeit bequem mit Ihrem Dienstrad. So haben Sie morgens schon vor der Arbeit etwas für Ihre Gesundheit getan. Doch nicht nur Ihre Gesundheit profitiert von der Nutzung des Dienstrades. Auch die Umwelt atmet auf, denn durch die Fahrt mit dem Dienstrad tragen Sie erheblich zur Reduzierung des CO2-Ausstosses bei. Durch die Gehaltsumwandlung der Mietrate über Ihren Arbeitgeber erhalten Sie das Fahrvergnügen zum schlanken Preis, quasi Brutto für Netto – und das bei vollem Diebstahl-, Reparatur- und Versicherungsschutz. Da freut sich auch Ihr Geldbeutel. Übrigens, das Dienstrad kann auch ein E-Bike sein, so kommen Sie morgens ohne zu schwitzen am Arbeitsplatz an.',
      },
      {
        title: 'Bitte geben Sie folgende Daten zu Ihrem Fahrrad ein',
        items: [
          {
            type:      'help',
            htmlClass: 'clearfix',
            helpvalue: '<a target="_blank" rel="noreferrer" class="button button-primary right" href="https://www.jobrad.org/fachhaendler-suchmaschine">Händlersuche</a>'
          },
          {
            key:   'verkaufspreis',
            title: 'Verkaufspreis'
          },
          {
            key:   'listenpreis',
            title: 'Listenpreis'
          }
        ]
      },
      {
        description: 'Wir prüfen Ihre Angaben und informieren Sie schnellstmöglich über die weiteren Schritte. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.',
      }
    ]
  },
  Bikeleasing: {
    items: [
      {
        video:       'https://player.vimeo.com/video/398001594?dnt=1',
        subtitle:    'Bikeleasing',
        subsubtitle: 'Bewegung, Motivation, Emotion. Ein nachhaltig gesundes Konzept, das sich garantiert für Sie rechnet - in mehrfacher Hinsicht: Sie tun etwas für Ihre Gesundheit und Fitness, Sie schonen die Umwelt und Ihren Geldbeutel. Umfahren Sie künftig den Stau zur Arbeit bequem mit Ihrem Dienstrad. So haben Sie morgens schon vor der Arbeit etwas für Ihre Gesundheit getan. Doch nicht nur Ihre Gesundheit profitiert von der Nutzung des Dienstrades. Auch die Umwelt atmet auf, denn durch die Fahrt mit dem Dienstrad tragen Sie erheblich zur Reduzierung des CO2-Ausstosses bei. Durch die Gehaltsumwandlung der Mietrate über Ihren Arbeitgeber erhalten Sie das Fahrvergnügen zum schlanken Preis, quasi Brutto für Netto – und das bei vollem Diebstahl-, Reparatur- und Versicherungsschutz. Da freut sich auch Ihr Geldbeutel. Übrigens, das Dienstrad kann auch ein E-Bike sein, so kommen Sie morgens ohne zu schwitzen am Arbeitsplatz an.',
      },
      {
        title: 'Bitte geben Sie folgende Daten zu Ihrem Fahrrad ein',
        items: [
          {
            type:      'help',
            htmlClass: 'clearfix',
            helpvalue: '<a target="_blank" rel="noreferrer" class="button button-primary right" href="https://bikeleasing.de/haendler-finden#c23">Händlersuche</a>'
          },
          {
            key:   'verkaufspreis',
            title: 'Verkaufspreis'
          },
          {
            key:   'listenpreis',
            title: 'Listenpreis'
          }
        ]
      },
      {
        description: 'Wir prüfen Ihre Angaben und informieren Sie schnellstmöglich über die weiteren Schritte. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.',
      }
    ]
  },
  pc: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397980851?dnt=1',
        subtitle:    'Steuerfreie Elektronik',
        subsubtitle: 'Mit dem Baustein „PC - Leasing“ können Sie Ihren modernen Lifestyle sowie Ihren Sinn für Ästhetik und Technik unterstreichen – ohne sich finanziell einschränken zu müssen. Keine Frage: Wer möchte nicht gerne den neuesten Laptop, das neueste Smartphone, das neueste IPad besitzen. IT Technik ist aus unserem täglichen Leben nicht mehr weg zu denken, wäre da nicht die Höhe der Anschaffungskosten – bisher. Ihr Arbeitgeber kann Ihnen nun den Erwerb eines aktuellen Apple-Gerätes oder eines anderen Herstellers in Form eines exklusiven Vergütungsbausteins ermöglichen. Zu den Geräten zählen: Alle aktuellen Apple Geräte + Zubehör Notebooks von Lenovo, Asus, HP, Acer, Dell etc. Tablets von Sony, HP, Samsung, Asus, Lenovo etc. Smartphones von LG, Samsung, Nokia, Sony, HTC, Blackberry etc. Im Bestellprozess ist zu beachten, dass mindestens ein Hauptartikel aus einer der Kategorien PC, Notebook, Tablet oder Mobiltelefon enthalten ist. Eine Bestellung, z.B. eines Druckers und eines Monitors ist leider nicht staatlich gefördert und würde bei einer Bestellung abgelehnt werden. Der Mindestbestellwert beträgt netto 400 EUR. Die Leasinglaufzeit umfasst 24 Monate. Berücksichtigen Sie bitte, dass die Auslieferung der Bestellung jeweils zum Ersten eines Monats erfolgt und ein Vorlauf von ca. 4 Wochen erforderlich ist.',
      },
      {
        title: 'Hier gehts zum PC-Leasing Shop.',
        items: [
          {
            type:    'button',
            title:   'Zum Shop und Geräte wählen',
            onClick: 'component.openMPP()'
          },
          {
            key:   'value',
            title: 'Höhe der monatlichen Leasingrate aus dem PC-Leasing Shop'
          }
        ]
      },
      {
        description: 'Wenn Sie die Bausteinauswahl abschließen, wird Ihre Bestellung bearbeitet. Wir informieren Sie danach über die weiteren Schritte. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  personal_rabatt: {
    items: [
      {
        subtitle:    'Steuerfreier Personalrabatt',
        subsubtitle: 'Ihr Arbeitgeber kann Ihnen steuerfrei Vorteile bis zu € 1.080,- im Kalenderjahr dadurch einräumen, dass er Waren, die er produziert oder mit denen er handelt oder Dienstleistungen, die er am Markt anbietet, Ihnen kostenlos oder mit einem Rabatt zukommen lässt. Zu den vertriebenen oder hergestellten Waren können alle Wirtschaftsgüter gehören, die im Wirtschaftsverkehr wie Sachen behandelt werden. Nach Branchen unterschieden kommen z. B. in Betracht: Lebensmittel bei Bäckern oder Metzgern, Essen in Gastronomiebetrieben, Autos bei Autoherstellern oder -händlern oder das gesamtes Sortiment im Kaufhaus oder Supermarkt. Dienstleistungen sind alle personellen Leistungen, die üblicherweise gegen Entgelt erbracht werden. Nach Branchen unterschieden gehören dazu z. B.: Überlassung von möblierten Zimmern im Hotelgewerbe, Datenverarbeitung bei Datenverarbeitungsunternehmen, Überlassung von Kraftfahrzeugen bei PKW- Vermietungs- oder PKW Leasing-Unternehmen oder Arbeiten des Handwerkers für den Arbeitnehmer in dessen Wohnung.',
      },
      {
        title:      'Berechnen Sie Ihren steuerlichen Vorteil beim Personalrabatt.',
        valueRange: {
          min: 0,
          max: 90
        },
        description: 'Hier können Sie Ihren steuerlichen Vorteil des Personalrabattes berechnen. Bis zu 1080,- € im Jahr können Sie steuerfrei erhalten.',
      },
      {
        description: 'Sie haben {{ model.enabled ? model.value : 0 | currency }} monatlichen Personalrabatt angegeben. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  erholungsbeihilfe: {
    items: [
      {
        video:       'https://player.vimeo.com/video/397982776?dnt=1',
        subtitle:    'Steuerfreie Urlaubskasse!',
        subsubtitle: 'Wir zahlen Ihnen steuerfrei 156 € pro Jahr, als Zuschuss zu Ihrem Urlaub. Voraussetzung ist, dass Sie länger als fünf Arbeitstage am Stück Urlaub nehmen. Diese Zahlung hat keinen Einfluss auf andere Sonderzahlungen.',
      },
      {
        title:       'Erholungsbeihilfe durch Ihren Arbeitgeber.',
        description: 'Ihr Arbeitgeber kann Ihnen jährlich eine steuerfreie Erholungsbeihilfe auszahlen. Nutzen Sie diesn Vorteil!',
        htmlClass:   'sf-no-help-blocks',
        items:       [
          {
            key:        'value',
            title:      '156 € pro Jahr',
            type:       'checkbox',
            trueValue:  13,
            falseValue: 0,
          }
        ],
      },
      {
        description: 'Sie erhalten eine Erholungsbeihilfe i. H. v. {{ model.enabled ? model.value *12 : 0 | currency }} pro Jahr. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.'
      }
    ]
  },
  bav: {
    items: [
      {
        subtitle:    'Betriebliche Altersvorsorge',
        subsubtitle: 'Die gesetzliche Rentenversicherung wird Ihnen in Zukunft nur noch eine Grundversorgung bieten. Ohne zusätzliche Altersversorgung drohen Ihnen erhebliche finanzielle Einbußen im Alter. Durch unsere Nettobausteine können sie eine zusätzliche Altersvorsorge aufbauen - und das ohne auf Nettogehalt verzichten zu müssen.',
      },
      {
        model:      'beitrag',
        valueRange: {
          min: 0,
          max: 248
        },
        title: 'Wie viel wollen Sie monatlich in Ihre betriebliche Altersvorsorge investieren?'
      },
      {
        description: 'Sie wollen {{ model.enabled ? model.value : 0 | currency }} Euro im Monat in ihre betriebliche Altersvorsorge investieren. Sie können nun weitere Bausteine hinzufügen oder die Bausteinwahl abschließen.',
      }
    ]
  },
  car_leasing: {
    items: [
      {
        title: 'Auto Abo',
        //img: '/assets/img/steuerfrei-extras/car_leasing.jpg ',
        video:       'https://player.vimeo.com/video/696681606?dnt=1',
        subtitle:    'Auto Abo mit staatlichem Zuschuss​',
        subsubtitle: 'Mit Ihrem Benefit Auto Abo fahren Sie künftig klimaneutral und leise. So leisten Sie einen wertvollen Beitrag zum Thema Umweltschutz und Nachhaltigkeit. Aber mehr noch – Sie fahren mit einem brandneuen Fahrzeug mit allen Annehmlichkeiten, die Sie sich wünschen – und müssen sich keine Gedanken um Versicherung, Wartung oder Reifen machen. Das ist das All-Inclusive-Paket für Ihr neues Auto.​ Sie wollen auf Kraft und Leistung nicht verzichten? Agilität und Sportlichkeit sind Ihnen wichtig? Kein Problem! Die aktuellen E-Fahrzeuge sind wahre Kraftpakete und haben einen immens großen Spaßfaktor.​ Schauen Sie sich in den nachfolgenden Schritten im Auto-Onlineshop um – vergleichen Sie und informieren Sie sich. Aufgrund Ihrer Auswahl wird im nächsten Schritt eine Vorteilsberechnung durchgeführt, die Ihnen zeigt, wie sich Ihr Auto Abo auf Ihrem Bruttogehalt auswirkt. Was Sie am Ende tatsächlich dafür bezahlen ist noch weniger, da Sie Steuern und Sozialversicherung sparen, weil die monatlichen Raten aus Ihrem Brutto von Ihrem Arbeitgeber bezahlt werden.​',
        description: 'Sie wollen auf Kraft und Leistung nicht verzichten? Agilität und Sportlichkeit sind Ihnen wichtig? Kein Problem! Die aktuellen E-Fahrzeuge sind wahre Kraftpakete und haben einen immens großen Spaßfaktor.​ Schauen Sie sich in den nachfolgenden Schritten im Auto-Onlineshop um – vergleichen Sie und informieren Sie sich. Aufgrund Ihrer Auswahl wird im nächsten Schritt eine Vorteilsberechnung durchgeführt, die Ihnen zeigt, wie sich Ihr Auto Abo auf Ihrem Bruttogehalt auswirkt. Was Sie am Ende tatsächlich dafür bezahlen ist noch weniger, da Sie Steuern und Sozialversicherung sparen, weil die monatlichen Raten aus Ihrem Brutto von Ihrem Arbeitgeber bezahlt werden.​'
      },
    ]
  },
  dticket: {
    items: [
      {
        title: 'DeutschlandTicket JOB',
        img: '/assets/img/steuerfrei-extras/dticket.jpg ',
        subtitle:    'DeutschlandTicket JOB',
        subsubtitle: 'Der tägliche Weg zur Arbeit ist Arbeitnehmersache und jeder darf selbst entscheiden, wie er seine Arbeitsstätte am besten erreicht. Mit dem „Deutschlandticket JOB“ hat der Gesetzgeber eine Möglichkeit geschaffen, dass Unternehmen sich an den Kosten beteiligen. Damit sind Sie nicht nur auf dem Weg zur Arbeit, sondern auch in Ihrer Freizeit flexibel und umweltbewusst unterwegs. Wie Sie das Deutschlandticket ganz bequem über das Portal beantragen können, erfahren Sie in den nächsten Schritten.'
      },

    ]
  }
};

export default ComponentWizardConstants;
