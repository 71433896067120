angular
  .module('VSPApp')
  .service('vcpModel', ['Made', 'MonthsService', function (Made, MonthsService) {

    var vcpModel = {
      working: 0,
      antraegeSum: 0,
      mitarbeiterSum: 0,
      cities: [],
      citiesMap: {},
      cluster: {},
      clusterFilled: {},
      clusterWithAntraege: []
    };

    vcpModel.getAll = function (year, month, until) {

      var params = {};

      if (year) params.year = '' + year;
      if (month) params.month = '' + (month + 1);
      if (until) params.until = true;

      vcpModel.working++;

      return Made
        .request('rpc://vcp/location/list', params)
        .then(function (result) {

          vcpModel.cities = result;
          vcpModel.updateCitiesMap();
          vcpModel.working--;

        });
    };

    vcpModel.updateCitiesMap = function () {

      vcpModel.citiesMap = {};

      for (var i = vcpModel.cities.length - 1; i >= 0; i--) {
        vcpModel.citiesMap[vcpModel.cities[i].id] = i;
      }
    };

    vcpModel.reset = function () {
      vcpModel.cities = [];
      vcpModel.citiesMap = {};
    };

    vcpModel.closeAll = function () {
      for (var i = vcpModel.cities.length - 1; i >= 0; i--) {
        vcpModel.cities[i].open = false;
      }
    };

    vcpModel.cityById = function (id, city) {
      if (!city) {
        return vcpModel.cities[vcpModel.citiesMap[id]];
      }

      vcpModel.cities[vcpModel.citiesMap[id]] = city;
    };

    vcpModel.calculateMitarbeiterSum = function () {

      var mitarbeiterSum = 0;

      angular.forEach(vcpModel.cities, function (city) {
        mitarbeiterSum += city.employees;
      });

      vcpModel.mitarbeiterSum = mitarbeiterSum;
    };

    vcpModel.calculateAntraegeSum = function () {

      var antraegeSum = 0;

      angular.forEach(vcpModel.cities, function (city) {
        antraegeSum += city.numAntraege;
      });

      vcpModel.antraegeSum = antraegeSum;
    };

    vcpModel.getCitySums = function (city, year, month, until) {

      var params = {
        id: city.id
      };

      if (year) params.year = '' + year;
      if (month) params.month = '' + (month + 1);
      if (until) params.until = true;

      vcpModel.working++;

      return Made
        .request('rpc://vcp/location/get', params)
        .then(function (result) {

          angular.extend(city, result);
          vcpModel.working--;

          return city;
        });
    };

    vcpModel.getCityBoniIds = function (city) {
      var ids = [],
        i,
        j;

      for (i = city.persons.length - 1; i >= 0; i--) {
        if (city.persons[i].history) {
          for (j = city.persons[i].history.length - 1; j >= 0; j--) {
            if (!city.persons[i].history[j].accepter) ids.push(city.persons[i].history[j].id);
          }
        }
      }

      return ids;
    };

    vcpModel.getCityEmployees = function (query) {

      vcpModel.working++;

      return Made
        .request('rpc://vcp/employee/list', {
          query
        })
        .then(function (result) {

          var i,
            city;

          vcpModel.emptyCities();

          for (i = result.length - 1; i >= 0; i--) {
            city = vcpModel.cityById(result[i].locid);
            if (city)
              city.persons.push(result[i]);
          }

          vcpModel.working--;

        });
    };

    vcpModel.getCityEmployeesWithBonus = function (city, year, month, until, filterOverride) {
      var filter = {
        locid: ('object' === typeof city ? city.id : city),
        filter: 'with'
      };

      if ('string' === typeof filterOverride) filter.filter = filterOverride;

      if (!year) {
        year = MonthsService.year;
        month = MonthsService.month + 1;
      }

      filter.year = '' + year;
      if (month) filter.month = '' + month;

      if (until) filter.until = true;

      return vcpModel.getCityEmployees(filter, until);
    };

    vcpModel.getCity = function (city, all) {

      var filter = {
        locid: ('object' === typeof city ? city.id : city)
      };

      if (all) filter.filter = 'with';

      return vcpModel.getCityEmployees(filter);
    };

    vcpModel.initialize = function (year, month, until) {

      return vcpModel.getAll(year, month, until)
        .then(function () {
          vcpModel.calculateMitarbeiterSum();
          vcpModel.calculateAntraegeSum();
        });
    };

    vcpModel.search = function (query) {
      var filter = {};

      if (isNaN(parseInt(query))) {
        filter.name = query;
      } else {
        filter.empid = query;
      }

      vcpModel.emptyCities();

      return vcpModel
        .getCityEmployees(filter)
        .then(vcpModel.openWithEmployees);
    };

    vcpModel.emptyCities = function () {
      for (var i = vcpModel.cities.length - 1; i >= 0; i--) {
        vcpModel.cities[i].persons = [];
      }
    };

    vcpModel.openWithEmployees = function () {
      for (var i = vcpModel.cities.length - 1; i >= 0; i--) {
        vcpModel.cities[i].open = vcpModel.cities[i].persons && vcpModel.cities[i].persons.length;
      }
    };

    vcpModel.getCluster = function () {
      vcpModel.working++;

      return Made
        .request('rpc://vcp/get_cluster')
        .then(function (response) {
          vcpModel.cluster = response;

          vcpModel.working--;
          return response;
        });
    };

    vcpModel.fillCluster = function () {
      vcpModel.clusterFilled = {};

      angular.forEach(vcpModel.cluster, function (locations, cluster) {

        vcpModel.clusterFilled[cluster] = [];

        angular.forEach(locations, function (location) {
          if (vcpModel.cities[vcpModel.citiesMap[location]]) {
            vcpModel.clusterFilled[cluster].push(vcpModel.cities[vcpModel.citiesMap[location]]);
          }
        });
      });
    };

    vcpModel.getClusterWithAntraege = function (onlyOpen) {
      var numSumKeys = [
        'numAntraege',
        'numAbgelehnt',
        'numFreigegeben',
        'numOffen',
        'sumAntraege',
        'sumAbgelehnt',
        'sumFreigegeben',
        'sumOffen'
      ];

      vcpModel.clusterWithAntraege = [];

      angular.forEach(vcpModel.clusterFilled, function (locations, cluster) {
        var numSums = {},
          alleFreigegeben = true,
          locationsWithAntraege = [];

        numSumKeys.forEach(function (key) {
          numSums[key] = 0;
        });

        for (var i = locations.length - 1; i >= 0; i--) {
          if (
            ('object' === typeof locations[i]) &&
            (
              (onlyOpen && (0 < locations[i].numAbgelehnt + locations[i].numOffen)) ||
              (!onlyOpen && (0 < locations[i].numAntraege))
            )
          ) {

            numSumKeys.forEach(function (key) {
              numSums[key] += locations[i][key];
            });

            alleFreigegeben = alleFreigegeben && locations[i].alleFreigegeben;

            locationsWithAntraege.push(locations[i]);
          }
        }


        if (
          (onlyOpen && (0 < numSums.numAbgelehnt + numSums.numOffen)) ||
          (!onlyOpen && (0 < numSums.numAntraege))
        ) {
          var clusterWithAntrag = {
            name: cluster,
            locations: locationsWithAntraege,
            alleFreigegeben: alleFreigegeben
          };

          numSumKeys.forEach(function (key) {
            clusterWithAntrag[key] = numSums[key];
          });

          vcpModel.clusterWithAntraege.push(clusterWithAntrag);
        }
      });

    };


    return vcpModel;
  }]);

angular
  .module('VSPApp')
  .filter('irrelevantLocations', [() => {
    return function (as) {
      return as.filter(function (city) {
        return city.numAbgelehnt + city.numOffen > 0;
      });
    };
  }]);
