const $inject = [ 'Made' ];

export default class CommonService {
  constructor(
    Made
  ){
    Object.assign(this, {
      Made  
    });
  }
  
   add_vat(amount) {
    return this.Made.request('rpc://utility/common/add_vat', {
          amount: amount
        });
  }
  
  remove_vat(amount) {
    return this.Made.request('rpc://utility/common/remove_vat', {
              amount: amount
            });
  }
}

CommonService.$inject = $inject;