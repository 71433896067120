import cockpit_components_dialog_html from '../../../modules/cockpit/steuerfrei/cockpit.components.dialog.pug'
import reg_code_html from '../../../modules/cockpit/steuerfrei/reg_code.dialog.pug';
import qr_code_html from '../../../modules/cockpit/steuerfrei/qr-code.dialog.pug'
import {
  DticketOrderSteuerfreiDialogComponent
} from "../../../../app/dticket-order/dialogs/dticket-order-steuerfrei-dialog/dticket-order-steuerfrei-dialog.component";

const $inject = [
  '$q',
  '$state',
  '$transitions',
  '$http',
  '$location',
  'ngDialog',
  'ALVktgConstants',
  'VATypeConstants',
  'EinsparungsModellConstants',
  'customerService',
  'advisorService',
  'CheckoutService',
  'vbmService',
  'NotificationService',
  'authenticationService',
  'userService',
  'dialogService',
  'mailService',
  'ACLService',
  'MatDialogService'
];
export default class CockpitSteuerfreiController {

  constructor(
    $q,
    $state,
    $transitions,
    $http,
    $location,
    ngDialog,
    ALVktgConstants,
    VATypeConstants,
    EinsparungsModellConstants,
    customerService,
    advisorService,
    CheckoutService,
    vbmService,
    NotificationService,
    authenticationService,
    userService,
    dialogService,
    mailService,
    ACLService,
    MatDialogService
  ) {

    this.$state = $state;
    this.$transitions = $transitions;
    this.$http = $http;
    this.$location = $location;

    this.ngDialog = ngDialog;
    this.ALVktgConstants = ALVktgConstants;
    this.VATypeConstants = VATypeConstants;
    this.EinsparungsModellConstants = EinsparungsModellConstants;
    this.customerService = customerService;
    this.advisorService = advisorService;
    this.CheckoutService = CheckoutService;
    this.NotificationService = NotificationService;
    this.userService = userService;
    this.dialogService = dialogService;
    this.vbmService = vbmService;
    this.mailService = mailService;
    this.ACLService = ACLService;
    this.MatDialogService = MatDialogService;

    this.customerId = $state.params.customerId;
    this.neoProjectId = $state.params.neoProjectId;
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.working = true;
    this.neoProjectList = [];
    this.employees = [];
    this.neoProjectParticipants = {};
    this.accessList = {};

    let promises = {};

    promises.a = this.ACLService.amIEmployer(this.customerId).then(
      (iAmEmployer) => {
        this.iAmEmployer = iAmEmployer
        return;
      });

    promises.v = this.vbmService
      .getAvailableNeoComponents()
      .then(availableComponents => this.availableComponents = availableComponents
        .filter(component => component !== 'besonderer_anlass'));

    promises.c = customerService
      .get(this.customerId)
      .then(customer => this.customer = customer);

    promises.l = customerService
      .listNeoProjects(this.customerId)
      .then(neoProjectList => this.neoProjectList = neoProjectList);

    promises.o = customerService
      .getConfiguration(this.customerId)
      .then(configuration => this.configuration = configuration);

    promises.e = this.userService
      .getAccessStatusByCustomerId(this.customerId)
      .then(accessList => this.accessList = accessList)
      .then(() => this.customerService.getEmployees(this.customerId, Date.now()))
      .then(employees => this.employees = employees.filter(e => this.accessList[e.id]));



    if (this.neoProjectId) {

      promises.p = customerService
        .getNeoProject(this.customerId, this.neoProjectId)
        .then(neo_project => {
          this.neo_project = neo_project;
          if (typeof this.neo_project.project.insurances.va.pension_interest === 'undefined') {
            this.neo_project.project.insurances.va.pension_interest = 0.01;
          }
          this.custCode = neo_project.code ? ('' + neo_project.code).match(/.{1,3}/g).join(' ') : 'Noch keiner vorhanden';
        });

      promises.z = this.listNeoProjectParticipants();
    }

    $q.all(promises)
      .then(() => this.working = false)
      .catch(e => console.log('......', e));

  }

  edit(component_id) {

    if (!this.neo_project.neo_components) {
      this.neo_project.neo_components = {};
    }
    if (!this.neo_project.neoChoices) {
      this.neo_project.neoChoices = {};
    }
    if (!this.neo_project.neoChoices[component_id]) {
      this.neo_project.neoChoices[component_id] = {
        list: []
      };
    }

    for (let id of this.availableComponents) {
      if (!this.neo_project.neo_components[id]) {
        this.neo_project.neo_components[id] = {
          enabled: false
        };
      }
    }

    this.ngDialog.open({
      template: cockpit_components_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen cockpit_steuerfrei-dialog',
      controller: 'CockpitComponentDialogController',
      controllerAs: '$ctrl',
      closeByEscape: false,
      closeByDocument: false,
      showClose: false,
      data: {
        componentId: component_id,
        component: this.neo_project.neo_components[component_id],
        neoChoice: this.neo_project.neoChoices[component_id],
        neoChoicesDefault: this.CheckoutService.getAllNeoChoicesDefaults()[component_id]
      },
    });

  }

  openDialog(component_id) {

    const angular12Components = ['dticket'];

    if(angular12Components.includes(component_id)) {

      const componentDialogsByComponentId = {
        'dticket': DticketOrderSteuerfreiDialogComponent
      }

      this.MatDialogService.open(componentDialogsByComponentId[component_id], {
        data: {
          component_id: component_id, // ugly should be passed by reference
          neo_project: this.neo_project
        }
      });

    } else {
      this.edit(component_id);
    }
  }

  save() {
    this.modulesWorking = true;
    this.neo_project.changed = Date.now();

    this.customerService
      .updateNeoProject(this.customerId, this.neo_project)
      .then(() => this.NotificationService.message('Speichern erfolgreich.'),
        () => this.NotificationService.error('Speichern fehlgeschlagen.'))
      .then(() => {
        return this.mailService.updateContactsCustomersData(this.customerId, this.neo_project._id);
      })
      .finally(() =>
        this.customerService
          .getConfigurationByCustomerId(this.customerId, Date.now())
          .then(() => this.modulesWorking = false)
      );
  }

  reverseSelection(selfService) {
    for (let id of this.availableComponents) {
      if (selfService) {
        this.neo_project.neo_components[id].selfservice = this.neo_project.neo_components[id].enabled;
      } else {
        if (!this.neo_project.neo_components[id]) {
          this.neo_project.neo_components[id] = {
            enabled: false
          };
        }
        this.neo_project.neo_components[id].enabled = !this.neo_project.neo_components[id].enabled;
      }
    }
  }


  newNeoProject() {
    this.working = true;

    this.customerService
      .newNeoProject(this.customerId)
      .then(neo_project => {
        this.neo_project = neo_project;
        this.neoProjectId = neo_project._id;
        this.$state.go('.', {neoProjectId: this.neoProjectId}, {notify: false});
      })
      .finally(() => this.working = false);
  }

  listNeoProjectParticipants() {
    return this.customerService
      .listNeoProjectParticipants(this.customerId, this.neoProjectId)
      .then(neoProjectParticipants => this.neoProjectParticipants = neoProjectParticipants);
  }

  saveParticipants() {
    this.working = true;

    let neoProjectParticipantValuenetIds = [];

    for (let id in this.neoProjectParticipants) {
      if (this.neoProjectParticipants[id]) {
        neoProjectParticipantValuenetIds.push(parseInt(id));
      }
    }

    this.customerService
      .saveNeoProjectParticipants(this.customerId, this.neoProjectId, neoProjectParticipantValuenetIds)
      .then(() => this.NotificationService.message('Speichern erfolgreich.'),
        () => this.NotificationService.error('Speichern fehlgeschlagen.'))
      .finally(() => this.working = false);
  }

  reverseParticipantSelection() {
    let oldList = Object.assign({}, this.neoProjectParticipants);
    for (let emp of this.employees) {
      this.neoProjectParticipants[emp.id] = true;
    }
    for (let id in oldList) {
      if (oldList[id]) {
        this.neoProjectParticipants[id] = false;
      }
    }
  }

  createNewCode() {
    this.dialogService
      .question('Der alte Code wird nicht mehr gültig sein und kann nicht mehr zur Registrierung verwendet werden.', 'Soll wirklich ein neuer Code erstellt werden?')
      .then(() => {
        this.loading = true;
        this.createCode();
      });
  }

  openModalWithRegCode() {

    let host = this.$location.host();
    let company = 'https://';
    if (host.indexOf('.') >= 0) {
      company = host.split('.')[0];
    }

    let code = this.custCode.replace(/ /g, '');
    let reg_link = `${company}.valuenet.de/registration?regcode=${code}`;
    this.dialogService.ngDialog.open({
      template: reg_code_html(),
      plain: true,
      controller: 'RegCodeDialogController',
      data: {reg_link: reg_link}
    });
  }

  createCode() {
    this.loading = true;
    this.customerService.createCode(parseInt(this.customerId), this.neoProjectId)
      .then(code => {
        this.custCode = ('' + code).match(/.{1,3}/g).join(' ');
        this.neo_project.code = code;
      })
      .finally(() => this.loading = false);
  }

  openQRCode() {
    const code = this.custCode.replace(/ /g, '');
    const branding = this.configuration.branding && this.configuration.branding != '' && this.configuration.branding != 'null' ? this.configuration.branding + '.' : '';
    const regLink = `${branding}valuenet.de/registration?regcode=${code}`;

    this.dialogService.ngDialog.open({
      template: qr_code_html(),
      plain: true,
      className: 'qr-code-container',
      width: 348,
      closeByEscape: true,
      closeByDocument: true,
      closeByNavigation: true,
      data: regLink
    });
  }


  getSubDomain() {
    const host = this.$location.host();

    return host.indexOf('.') >= 0 ? host.split('.')[0] : `${this.$location.$$protocol}://`;
  }

  hasAccessForLinkOrQr() {
    return this.iAmSpecialAdvisor || this.iAmAdvisor || this.iAmEmployer
  }

}
CockpitSteuerfreiController.$inject = $inject;
