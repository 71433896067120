var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;


































pug_html = pug_html + "\u003Cdiv class=\"cockpit_allgemein-wrapper\"\u003E\u003Cdiv class=\"cockpit-panel\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EManuelles Anstoßen der Bikeleasing Synchronisation nach dem Import der neuen Fahrradverträge in die MySQL Datenbank\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E\u003Cbutton class=\"button button-primary\" ng-click=\"$ctrl.syncronizeBikeleasing()\" overlay=\"$ctrl.loading.sync_bikeleasing\"\u003EStarten Sie die Synchronisierung\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cemployee-filters options=\"{to_show_bike_approval: true}\" filters-wrapper=\"$ctrl.filters_wrapper\"\u003E\u003C\u002Femployee-filters\u003E";;return pug_html;};
module.exports = template;