const $inject = [
  "$q",
  "$window",
  "Made",
  "fileService",
  "CacheService",
  "vbmService",
  "customerService",
  "MppConstants"
];

export default class userService {

  constructor($q, $window, Made, fileService, CacheService, vbmService, customerService, MppConstants) {

    this.$q = $q;
    this.$window = $window;
    this.Made = Made;
    this.fileService = fileService;
    this.madeUserCache = CacheService.getDictionaryStore('madeUsers');
    this.vbmService = vbmService;
    this.customerService = customerService;
    this.MppConstants = MppConstants;
  }

  getMadeUserByValuenetId(valuenet_id, stale) {

    let user = this.madeUserCache.get(valuenet_id, stale);

    if (user) {
      return user;
    }
    user = this.Made.request('rpc://valuenetdb/get_user_by_id', {
      valuenet_id
    });

    return this.madeUserCache.set(valuenet_id, undefined, user);
  }

  getEmployeeIdByUserId(user_id) {
    return this.Made.request('rpc://valuenetdb/get_employee_id_by_user_id', {
      user_id
    });
  }

  addGroup(userId, group) {
    return this.Made.request('rpc://valuenetdb/user/group/add', {
      userId,
      group
    });
  }

  removeGroup(userId, group) {
    return this.Made.request('rpc://valuenetdb/user/group/remove', {
      userId,
      group
    });
  }

  getOnetimeSettings(userId = this.Made.user._id) {

    return this.Made.request('rpc://valuenetdb/user/settings/getOnetime', {
      userId
    });
  }

  setOnetimeSettings(settings, userId = this.Made.user._id) {

    if (this.iAmProxy()) {
      return this.$q.when(settings);
    }

    return this.Made.request('rpc://valuenetdb/user/settings/updateOnetime', {
      userId,
      settings
    });
  }

  getBonusUploaded(params) {
    return this.fileService.getUserBonusUploadedFiles(params);
  }

  getUploadedFiles(userId = this.Made.user._id, extras) {
    extras = {
      ...{
        for_latest_contract_only: false,
        valuenet_id: undefined,
        show_neo_documents: true,
        show_bonus_documents: true,
        tags: undefined,
        for_neo_component: false
      }, ...extras
    };
    if (extras.for_latest_contract_only) {
      return this.vbmService.getLatestCalculation(extras.valuenet_id, true).then((calculation) => {
        extras['session_id'] = calculation['metadata']['id'];
        return this.fileService.getUserFiles(userId, extras);
      });
    } else if ((extras.show_bonus_documents && !extras.show_neo_documents) || extras.tags || extras.valuenet_id) {
      return this.fileService.getUserFiles(undefined, extras);
    } else {
      return this.fileService.getUserFiles(userId);
    }
  }

  deleteFiles(idList) {
    return this.fileService.deleteFiles(idList);
  }

  enterProxy(employeeId, context_path='') {
    // return this.Made
    //   .request('rpc://valuenetdb/user/proxy/enter', {
    //     employeeId
    //   })
    //   .then(proxyUser => {
    //     this.Made.proxy(proxyUser);
    //   });
    window.location.href = "/auth_cidaas/impersonate/?impersonate_valuenet_id=" + employeeId + "&impersonate_context_path=" + context_path;
  }

  leaveProxy() {
    window.location.href = '/auth_cidaas/leave_impersonate/';
  }

  iAmProxy() {
    return !!this.Made.user.originalId;
  }

  getProxySecurityLogs(employeeId = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://valuenetdb/user/proxy/getSecurityLogs', {
      employeeId
    });
  }

  invite(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/invite', {employeeId});
  }

  reInvite(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/reInvite', {employeeId});
  }

  inviteMany(employeeIds) {
    return this.Made.request('rpc://valuenetdb/user/access/inviteMany', {
      employeeIds,
    });
  }

  getEmailForAccess(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/getEmail', {
      employee_id: employeeId
    });
  }

  getLastInviteDate(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/getLastInviteDate', {
      employeeId
    });
  }

  getAccessStatusByCustomerId(customerId) {
    return this.Made.request('rpc://valuenetdb/user/access/statusByCustomerId', {
      customerId: parseInt(customerId)
    });
  }

  updateNeoStatus(employeeId, new_status_payload, changer_id) {
    return this.Made.request('rpc://valuenetdb/user/access/updateNeoStatusById', {
      employeeId, new_status_payload, changer_id
    });
  }

  setAllToKorrekturByCustomerId(customerId, setAllToKorrektur, advisorId = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://valuenetdb/user/access/setAllToKorrekturByCustomerId', {
      customer_id: parseInt(customerId),
      set_all_to_korrektur: setAllToKorrektur,
      advisor_id: advisorId
    });
  }

  getNeoLogs(employee_id) {
    return this.Made.request('rpc://valuenetdb/user/access/getNeoLogs', {
      employee_id: employee_id
    });
  }

  getMPPLogs(employeeId, basketId, limit=100) {
    return this.Made.request('rpc://valuenetdb/user/access/get_mpp_logs', {
      employee_id: employeeId,
      basket_id: basketId,
      limit
    });
  }

  getNeoStatusTransitions(employee_id) {
    return this.Made.request('rpc://valuenetdb/user/access/getNeoStatusTransitions', {
      employee_id: employee_id
    });
  }

  getIntegrityStatus(checkout = false, employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/user/integrity/status', {
      'checkout': checkout,
      'employee_id': employeeId
    });
  }

  getBirthdateStatus(employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/user/integrity/checkBirthdate', {'employee_id': employeeId});
  }

  getAddressStatus(employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/user/integrity/checkAddress', {'employee_id': employeeId});
  }

  deactivate(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/deactivate', {
      employeeId
    });
  }

  activateUser(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/activate_user', {
      employeeId
    });
  }

  acceptToc(version) {
    return this.Made.request('rpc://valuenetdb/user/integrity/acceptToc', {
      version: '' + version
    });
  }

  externalAcceptToc(version, employeeId) {
    return this.Made.request('rpc://valuenetdb/user/integrity/externalAcceptToc', {
      employeeId: employeeId,
      version: '' + version
    });
  }

  refuseToc() {
    return this.Made.request('rpc://valuenetdb/user/integrity/refuseToc', {});
  }

  getTocStatus() {
    return this.Made.request('rpc://valuenetdb/user/integrity/getTocStatus', {});
  }

  getTocStatusById(employeeId = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://valuenetdb/user/integrity/getTocStatusById', {
      'employeeId': employeeId
    });
  }

  updateNeoProjectId(employeeId, neoProjectId) {
    return this.Made.request('rpc://valuenetdb/employee/updateNeoProjectId', {
      employeeId,
      neoProjectId
    });
  }

  inviteActivated(employeeId, activationCode) {
    return this.Made.request('rpc://valuenetdb/user/access/invite_activated', {
      employeeId,
      activationCode,
    });
  }

  inviteMadeUser(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/invite_made_user_to_cidaas',
    {'employee_id': employeeId}
    );
  }

  getUserStatusColor(is_existing, has_email, is_invited, has_logged_in, is_active, has_cidaas_sub) {
    return {
      none: true, //If there is no status matched the status dot won't show
      red: (is_existing && has_email === false),
      blue: (is_existing && has_email && has_cidaas_sub === false && is_invited === false && has_logged_in === false), //  && is_active === false
      yellow: (is_existing && has_cidaas_sub && is_invited && has_logged_in === false), //  && is_active === false
      grey: (is_existing && has_cidaas_sub && is_invited === false && has_logged_in === false),
      green: (is_existing && has_cidaas_sub && has_logged_in && is_active),
      black: (is_existing && is_active === false && has_logged_in)
    }
  }

  purgeCidaasUser(employeeId) {
    return this.Made.request('rpc://valuenetdb/user/access/purge_cidaas_user', {
      employee_id: employeeId
    });
  }
}

userService.$inject = $inject;
