import { Subject } from "rxjs";

export class BasketItem {
  constructor(item, retailer, quantity, $timeout) {
    this._item = item;
    this._retailer = retailer;
    this._quantity = quantity || this.DEFAULT_QUANTITY;
    this.$timeout = $timeout;
    this.updateValue();
  }

  get id() {
    return this._item.id;
  }

  get price() {
    return this._item.price;
  }

  get value() {
    return this._value || 0;
  }

  get retailer_name() {
    return this._retailer.name;
  }

  get retailer_id() {
    return this._retailer.id;
  }

  updateValue() {
    this.$timeout(() => {
      this._value = this.price * this.quantity;
    });
  }

  set quantity(quantity) {
    this.$timeout(() => {
      this._quantity = quantity;
    });
    this.updateValue();
  }

  get quantity() {
    return this._quantity;
  }
}

BasketItem.DEFAULT_QUANTITY = 1;

export class BasketItemDecorator {
  constructor(basketItem) {
    this.basketItem = basketItem
    this.totalQuantity = 0
    this.totalPrice = 0
    this._voucherKeys = []
  }

  addVoucherKey(key) {
    this._voucherKeys.push(key);
  }

  get voucherKeys() {
    return this._voucherKeys;
  }

  setTotalQuantity(val) {
    this.totalQuantity += val
  }

  setTotalPrice(val) {
    this.totalPrice += val
  }

  get id() {
    return this.basketItem.id;
  }

  get price() {
    return this.totalPrice;
  }

  get quantity() {
    return this.totalQuantity;
  }

  get retailer_name() {
    return this.basketItem.retailer_name;
  }

  get value() {
    return this.totalPrice;
  }

  get retailer_id() {
    return this.basketItem.retailer_id;
  }
}

export class Basket {
  constructor(owner_valuenet_id, $timeout) {
    this.$timeout = $timeout;
    this._items = {};
    this._owner_id = owner_valuenet_id;

  }

  emptyBasket() {
    this._items = {};
  }

  addItem(item_id, item, retailer, quantity) {
    this.$timeout(() => {
      this._items[item_id] = new BasketItem(item, retailer, quantity, this.$timeout);
    });
  }

  removeItem(voucher_id) {
    this.$timeout(() => {
      delete this._items[voucher_id];
    });
  }

  getItems() {
    return this._items;
  }

  setItems(items) {
    this._items = items;
  }

  getItemsAsArray() {
    return Object.values(this._items);
  }

  getCategories() {
    this.Made.request('rpc://shop/bonago/categories');
  }

  getItemQuantity(item_id) {
    let item = this._items[item_id];
    return item ? item.quantity : undefined;
  }

  getItemsIdsList() {
    return Object.keys(this._items);
  }

  hasItems() {
    return this.getItemsIdsList().length > 0;
  }

  get total() {
    return Object.values(this._items).reduce((acc, item) => {
      acc += item.value;
      return acc;
    }, 0);
  }
}

export class BonagoBasket extends Basket {
  constructor(employee_id, $timeout) {
    super(employee_id, $timeout);
    this.clearGroupedItems();
  }

  clearGroupedItems() {
    this.groupedItems = {};
  }

  emptyBasket() {
    super.emptyBasket();
    this.clearGroupedItems();
  }

  removeItem(id) {

    let callback = null

    if (this.groupedItems[id]) {
      for (let i in this.groupedItems) {
        callback = () => {
          this.groupedItems[i].voucherKeys.forEach(voucher_id => {
            delete this._items[voucher_id];
          });
          delete this.groupedItems[i];
        };
      }
    } else if (this._items[id]) {
      callback = () => delete this._items[id];
    }

    if (callback) {
      this.$timeout(callback);
    }

  }

  getItemsGroupedByRetailer() {

    let toGroup = angular.copy(super.getItems());
    this.clearGroupedItems();
    let basketItemDecorator = null;
    for (let v in toGroup) {

      let item = toGroup[v];

      if (!this.groupedItems[item.retailer_id]) {
        basketItemDecorator = new BasketItemDecorator(item);
        basketItemDecorator.addVoucherKey(item.id);
        basketItemDecorator.setTotalPrice(item.value);
        basketItemDecorator.setTotalQuantity(item.quantity);
        this.groupedItems[item.retailer_id] = basketItemDecorator;
      } else {
        this.groupedItems[item.retailer_id].addVoucherKey(item.id);
        this.groupedItems[item.retailer_id].setTotalPrice(item.value);
        this.groupedItems[item.retailer_id].setTotalQuantity(item.quantity);
      }

    }
    return this.groupedItems;
  }

}

export class BonagoRetailerVoucherItem {
  constructor(bonago_api_voucher_item) {
    this._sku = bonago_api_voucher_item.Sku;
    this._description = bonago_api_voucher_item.Description;
    this._vouche_type = bonago_api_voucher_item.VoucherType;
    this._denomination = bonago_api_voucher_item.Denomination;
    this._currency_code = bonago_api_voucher_item.CurrencyCode;
  }

  get id() {
    return this._sku;
  }

  get price() {
    return this._denomination;
  }

  get description() {
    return this._description;
  }
}

export class BonagoRetailerVoucher {
  constructor(bonago_api_voucher) {
    this._country = bonago_api_voucher.Country;
    this._voucher_items = bonago_api_voucher.VoucherItems.map(bonago_api_voucher_item => new BonagoRetailerVoucherItem(bonago_api_voucher_item));
  }

  get country() {
    return this._country;
  }

  get voucherItems() {
    return this._voucher_items;
  }
}

export class BonagoRetailerAsset {
  constructor(bonago_api_retailer_asset) {
    this._type = bonago_api_retailer_asset.Type;
    this._url = bonago_api_retailer_asset.Url;
    this._alt_text = bonago_api_retailer_asset.AltText;
  }

  isOfType(type) {
    return this._type === type;
  }

  get url() {
    return this._url;
  }
}

export class BonagoRetailerInfoDetail {
  constructor(bonago_api_retailer_info_detail) {
    this._name = bonago_api_retailer_info_detail.Name;
    this._value = bonago_api_retailer_info_detail.Value;
  }

  get name() {
    return this._name;
  }

  get value() {
    return this._value;
  }
}

export class BonagoRetailerInfo {
  constructor(bonago_api_retailer_info) {
    this._language = bonago_api_retailer_info.Language;
    this._details = bonago_api_retailer_info.Details.map(bonago_api_retailer_info_detail => new BonagoRetailerInfoDetail(bonago_api_retailer_info_detail));
  }

  get language() {
    return this._language;
  }

  get description() {
    return this._details.find(detail => detail.name === 'RetailerDescription');
  }

  get instructions() {
    return this._details.find(detail => detail.name === 'RedemptionInstructions');
  }
}

export class BonagoRetailerBaseCategory {
  constructor(bonago_api_retailer_base_category) {
    this._id = bonago_api_retailer_base_category.Id;
  }

  get id() {
    return this._id;
  }
}

export class BonagoRetailerCategory extends BonagoRetailerBaseCategory {
  constructor(bonago_api_retailer_category) {
    super(bonago_api_retailer_category);
    this._name = bonago_api_retailer_category.Name;
  }

  get name() {
    return this._name;
  }
}

function parseAddresses(description) {
  let value = description.value;
  let address_array = [];
  if (value && value.includes('[{')) {
    let start_index = value.indexOf('[');
    let end_index = value.lastIndexOf(']') + 1;
    let address_array_string = value.substring(start_index, end_index);
    address_array_string = address_array_string.replace(/`/g, '\"');
    address_array_string = address_array_string.replace(/,/g, ',');

    //possible endless loop
    while (address_array_string.indexOf('}') != -1) {
      let start_index = address_array_string.indexOf('{');
      let end_index = address_array_string.indexOf('}');
      let address_string = address_array_string.substring(start_index + 1, end_index);
      address_array_string = address_array_string.slice(end_index + 1);
      let address_parts = address_string.split(',');
      let address = {};
      address = {
        street: JSON.parse(address_parts[0]),
        postcode: JSON.parse(address_parts[1]),
        city: JSON.parse(address_parts[2]),
        url: JSON.parse(address_parts[3]),
        lat: JSON.parse(address_parts[4]),
        lng: JSON.parse(address_parts[5])
      };
      address_array.push(address);
    }

    return address_array;
  }
}

export class BonagoRetailerBasic {
  constructor(bonago_api_retailer_basic) {
    this._id = bonago_api_retailer_basic.Retailer.Id;
    this._name = bonago_api_retailer_basic.Retailer.Name;
    this._is_new = bonago_api_retailer_basic.Retailer.IsNew;
    this._assets = bonago_api_retailer_basic.Assets.map(bonago_api_retailer_asset => new BonagoRetailerAsset(bonago_api_retailer_asset));
    if (bonago_api_retailer_basic.Information) {
      this._information = bonago_api_retailer_basic.Information.map(bonago_api_retailer_info => new BonagoRetailerInfo(bonago_api_retailer_info));
    } else {
      this._information = null;
    }
  }


  get logo() {
    let asset_logo = this._assets.find(asset => asset.isOfType('LogoURL'));
    return asset_logo;
  }

  get small_logo() {
    let asset_logo = this._assets.find(asset => asset.isOfType('LogoSmallUrl'));
    return asset_logo;
  }

  get name() {
    return this._name;
  }

  get id() {
    return this._id;
  }

  get addresses() {
    if (!this._information) {
      return [];
    }

    // we hacked the system to put the addresses in the english description ... this is why
    let english_information = this._information.find(information => information.language === 'EN');
    if (english_information) {
      let description = english_information.description;
      return parseAddresses(description);
    }
    return [];
  }
}

export class BonagoRetailer extends BonagoRetailerBasic {
  constructor(bonago_api_retailer) {
    super(bonago_api_retailer);

    this._vouchers = bonago_api_retailer.Vouchers.map(bonago_api_voucher => new BonagoRetailerVoucher(bonago_api_voucher));
    this._information = bonago_api_retailer.Information.map(bonago_api_retailer_info => new BonagoRetailerInfo(bonago_api_retailer_info));
    this._categories = bonago_api_retailer.Categories.map(bonago_api_retailer_category => new BonagoRetailerBaseCategory(bonago_api_retailer_category));
  }

  get voucherItems() {
    let country_vouchers = this._vouchers.find(vouchers => vouchers.country === 'DE');
    return country_vouchers.voucherItems;
  }

  get country_information() {
    return this._information
      .find(information => information.language === 'DE');
  }

  get categories() {
    return this._categories.map(category => category.id);
  }

  get addresses() {
    // we hacked the system to put the addresses in the english description ... this is why
    let english_information = this._information.find(information => information.language === 'EN');
    if (english_information) {
      let description = english_information.description;
      return parseAddresses(description);
    }
    return [];
  }

  get description() {
    return this.country_information.description;
  }

  get instructions() {
    return this.country_information.instructions;
  }

}

export class BonagoScheme {
  constructor(scheme_api = {}) {
    this._id = scheme_api._id;
    this._name = scheme_api.name;
    this._display = scheme_api.display;
    this._description = scheme_api.description;
    this._scheme_id = scheme_api.scheme_id;
    this._language_code = scheme_api.language_code;
    this._country_code = scheme_api.country_code;
    this._default_postcode = scheme_api.default_postcode;
    this._enable_google_maps = scheme_api.enable_google_maps || false;
  }

  toJson() {
    return {
      _id: this.id,
      name: this.name,
      display: this.display,
      description: this.description,
      scheme_id: this.scheme_id,
      language_code: this.language_code,
      country_code: this.country_code,
      enable_google_maps: this.enable_google_maps,
      default_postcode: this.default_postcode
    };
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get display() {
    return this._display;
  }

  set display(display) {
    this._display = display;
  }

  get description() {
    return this._description;
  }

  set description(description) {
    this._description = description;
  }

  get scheme_id() {
    return this._scheme_id;
  }

  set scheme_id(sheme_id) {
    this._scheme_id = sheme_id;
  }

  get language_code() {
    return this._language_code;
  }

  set language_code(l_code) {
    this._language_code = l_code;
  }

  get country_code() {
    return this._country_code;
  }

  set country_code(c_code) {
    this._country_code = c_code;
  }

  get default_postcode() {
    return this._default_postcode;
  }

  set default_postcode(postcode) {
    this._default_postcode = postcode;
  }

  get enable_google_maps() {
    return this._enable_google_maps;
  }

  set enable_google_maps(enable_google_map) {
    this._enable_google_maps = enable_google_map;
  }

}

export const DEFAULT_BONAGO_SCHEME_ID_SACHBEZUG_NEO = -1;

const $inject = [
  'Made',
  '$timeout',
  'fileService',
  'customerService',
  'csv',
  '$state',
  '$q'
];
export default class BenefitBonagoService {
  constructor(
    Made,
    $timeout,
    fileService,
    customerService,
    csv,
    $state,
    $q
  ) {
    Object.assign(this, {
      Made,
      $timeout,
      fileService,
      customerService,
      csv,
      $state,
      $q
    });

    this.init();
  }

  async completeCheckout(options) {
    let current_user_basket = this.getBasket();
    let checkout_items_list = current_user_basket.getItemsAsArray();
    let items = checkout_items_list.reduce((acc, item) => {
      acc[item.id] = item.quantity;
      return acc;
    }, {});

    let delivery_information = options.delivery_information;

    return this.Made.request('rpc://shop/bonago/complete_checkout', {
      items: items,
      delivery_information: delivery_information
    });
  }

  getUserOrdersByValuenetId(valuenetId) {
    return this.Made.request(
      'rpc://shop/bonago/get_bonago_orders_by_valuenet_id',
      {valuenet_id : valuenetId}
    );
  }

  getUserBalance(valuenet_id = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://shop/bonago/get_user_balance', {
      valuenet_id: valuenet_id
    }).catch(() => {
      return 0;
    });
  }

  saveScheme(scheme) {
    return this.Made.request('rpc://shop/bonago/save_scheme', {
      scheme: scheme.toJson()
    });
  }

  getCustomerChecks() {
    return this.Made.request('rpc://shop/bonago/get_customer_checks');
  }

  getUserChecks() {
    return this.Made.request('rpc://shop/bonago/get_user_checks');
  }

  init() {
    // used to communicate between service and controllers
    this.communication = new Subject();
    // holds the baskets per user
    this._baskets = {};
    // holds the user_selected_scheme per user
    this._users_schemes = {};
    // holds the customer_schemes per user
    this._customer_schemes = {};
    // holds the user_validations per user
    this._users_checks = {};
    // holds all the loading activities
    this.loading = {
      customer_schemes: false,
      scheme_categories: false,
      scheme_retailers: false,
      retailer: false
    };
  }

  get user_checks() {
    let valuenet_id = this.Made.user.valuenet_id;
    return this._users_checks[valuenet_id];
  }

  get customer_schemes() {
    let valuenet_id = this.Made.user.valuenet_id;
    return this._customer_schemes[valuenet_id];
  }

  set user_scheme(scheme) {
    let valuenet_id = this.Made.user.valuenet_id;
    this._users_schemes[valuenet_id] = scheme;

    // tell others the user has changed the scheme
    this.communication.next('user_selected_scheme_update');

    // put him back at the start

    this.$state.go('inApp.benefit.bonago.side.home');
  }

  get user_scheme() {
    let valuenet_id = this.Made.user.valuenet_id;
    return this._users_schemes[valuenet_id];
  }

  async getCustomerBonagoSchemes(customer_id) {
    this.loading.customer_schemes = true;
    let customer_configuration = await this.customerService.getConfiguration(customer_id, new Date());
    let available_schemes = await this.getAvailableSchemes();

    this.loading.customer_schemes = false;
    return customer_configuration.bonago.schemes.map(scheme_id => {
      return available_schemes.find(scheme => scheme.scheme_id === scheme_id);
    });

  }

  async onShopEnter() {
    let checks_result = await this.getUserChecks();
    let valuenet_id = checks_result.valuenet_id;
    this._users_checks[valuenet_id] = checks_result.checks;

    if (!this._users_checks[valuenet_id].employee_has_sachbezug_with_bonago) {
      await this.setCustomerScheme(valuenet_id);
    } else {
      let available_schemes = await this.getAvailableSchemes();
      let user_scheme = available_schemes.find(scheme => scheme.scheme_id = this._users_checks[valuenet_id].user_code_scheme_id);
      if (!user_scheme) {
        this._users_checks[valuenet_id].unknown_scheme_id = true;
        await this.setCustomerScheme(valuenet_id);
      } else {
        this.user_scheme = user_scheme;
      }
    }
  }

  async setCustomerScheme(valuenet_id, update_default_user_scheme = true) {
    let customer_bonago_schemes = await this.getCustomerBonagoSchemes();
    this._customer_schemes[valuenet_id] = customer_bonago_schemes;

    if (update_default_user_scheme) {
      this.user_scheme = this._customer_schemes[valuenet_id][0];
    }
  }

  getBasket(employee_id = this.Made.user.valuenet_id) {
    if (!this._baskets[employee_id]) {
      this._baskets[employee_id] = new BonagoBasket(employee_id, this.$timeout);
    }
    return this._baskets[employee_id];
  }

  async getRetailer(retailer_id, scheme_id) {
    this.loading.retailer = true;
    if (angular.isUndefined(scheme_id)) {
      // must check if the user is in preview mode
      if (!this.user_checks.employee_has_sachbezug_with_bonago) {
        scheme_id = this.user_scheme.scheme_id;
      }
    } else {
      scheme_id = parseInt(scheme_id);
    }

    retailer_id = parseInt(retailer_id);

    return this.Made.request('rpc://shop/bonago/get_retailer', {
      scheme_id: scheme_id,
      retailer_id: retailer_id
    })
      .then(response => {
        this.$timeout(() => {
          this.loading.retailer = false;
        });
        return new BonagoRetailer(response.retailer);
      });
  }

  async getUserSchemeRetailers() {
    this.$timeout(() => {
      this.loading.scheme_retailers = true;
    });
    return this.Made.request('rpc://shop/bonago/get_retailers_basic', {
      scheme_id: this.user_scheme.scheme_id,
    }).then(retailers => {
      this.$timeout(() => {
        this.loading.scheme_retailers = false;
      });

      return retailers.map(retailer => new BonagoRetailerBasic(retailer.retailer));
    });
  }

  async getRetailersBasic(options) {
    let params = {
      category_id: parseInt(options.category_id),
      scheme_id: options.scheme_id,
      with_information: options.with_information
    };

    this.loading.scheme_retailers = true;
    if (angular.isUndefined(params.scheme_id)) {
      // must check if the user is in preview mode
      if (!this.user_checks.employee_has_sachbezug_with_bonago) {
        params.scheme_id = this.user_scheme.scheme_id;
      }
    } else {
      params.scheme_id = parseInt(options.scheme_id);
    }

    return this.Made.request('rpc://shop/bonago/get_retailers_basic', params)
      .then(retailers => {
        this.$timeout(() => {
          this.loading.scheme_retailers = false;
        });

        return retailers.map(retailer => new BonagoRetailerBasic(retailer.retailer));
      });
  }

  async getCategoriesBySchemaId(scheme_id) {
    this.loading.scheme_categories = true;
    if (angular.isUndefined(scheme_id)) {
      // must check if the user is in preview mode
      if (!this.user_checks.employee_has_sachbezug_with_bonago) {
        scheme_id = this.user_scheme.scheme_id;
      }
    } else {
      scheme_id = parseInt(scheme_id);
    }

    return this.Made.request('rpc://shop/bonago/get_categories_by_scheme_id', {
      scheme_id: scheme_id
    }).then((categories) => {
      this.$timeout(() => {
        this.loading.scheme_categories = false;
      });
      return categories.map(category => new BonagoRetailerCategory(category.category));
    });
  }

  formatFileData(file_data_string) {
    let file_data_array = this.csv.parse(file_data_string, ';');
    let headers = file_data_array[2];
    file_data_array.splice(0, 3);
    let required_header_to_index = {
      'PersNr': -1,
      'UV-ID': -1,
      'UV-Code': -1
    };

    for (let header in required_header_to_index) {
      required_header_to_index[header] = headers.indexOf(header);

      if (required_header_to_index[header] == -1) {
        throw 'Could not find required header. Erforderliche Spalte konnte nicht gefunden werden.';
      }
    }

    let users_data = [];
    for (let data of file_data_array) {
      users_data.push({
        valuenet_id: data[required_header_to_index['PersNr']],
        uv_id: data[required_header_to_index['UV-ID']],
        uv_code: data[required_header_to_index['UV-Code']],
      });
    }

    return users_data;
  }

  async getAvailableSchemes() {
    return this.Made.request('rpc://shop/bonago/get_available_schemes').then((schemes) => {
      return schemes.map(scheme_api => new BonagoScheme(scheme_api));
    });
  }

  async deleteBonagoScheme(scheme) {
    return this.Made.request('rpc://shop/bonago/delete_scheme', { scheme_id: scheme.scheme_id }).catch(e => console.error(e));
  }

  async readImportDocument(import_file) {
    let file_data_string = await this.fileService.readFile(import_file, 'string');
    let users_data = this.formatFileData(file_data_string);

    let promises = [];
    let chunk_size = 5000
    for(let i = 0; i < users_data.length; i += chunk_size) {
      promises.push(this.Made.request('rpc://shop/bonago/set_bonago_codes', {
        users: users_data.slice(i, i + chunk_size)
      }));
    }

    let res = {
      'updatedNumber': 0,
      'failedUsers': []
    };
    return this.$q.all(promises).then((responses) => {

      for(let response of responses) {
        res.updatedNumber += response.updatedNumber;
        res.failedUsers = res.failedUsers.concat(response.failedUsers);
      }

      console.log("RES", res);
      return res;
    });
  }

  async getTopUpsByEmployeeId(employeeId) {
    return this.Made.request(
      'rpc://shop/bonago/get_top_ups_by_employee_id',
      {employee_id: employeeId}
    );
  }
}
BenefitBonagoService.$inject = $inject;
