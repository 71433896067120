const $inject = [
  "$scope",
  "$state",
  "$window",
  "$sce",
  "mppService",
  "MppConstants",
  "lodash",
  "vbmData",
  "customerService",
  "$timeout",
  "CommonService",
  "authenticationService"
];

export default class MppDetailController {

  constructor($scope, $state, $window, $sce, mppService, MppConstants, lodash, vbmData, customerService, $timeout, CommonService, authenticationService) {

    Object.assign(this, {
      $scope,
      $sce,
      $state,
      $window,
      lodash,
      vbmData,
      customerService,
      $timeout,
      CommonService,
      mppService,
      authenticationService
    });

    this.mppService = mppService;
    this.$scope = $scope;
    this.$state = $state;
    this.leasingConst = this.lodash.cloneDeep(MppConstants.calculation);

    this.currentQuantity = 1;

    this.beschreibungClosed = true;
    this.datenblattClosed = true;
    this.hasNoSupply = false;
    this.hasLimitedSupply = false;
    this.isDropShip = false;
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');

    this.mppService.getSpecificArticle(this.$state.params.Artikelnummer).then(product => {
      product.Beschreibung = product['Beschreibung'].replace(/•/g, '<br/>•');
      this.product = product;

      if (this.$scope.$parent.selectedMainCategory === 'Kategorien') {
        this.$scope.$parent.selectedMainCategory = product['main'];
      }
      if (this.$scope.$parent.selectedSubCategory === 'Alle') {
        this.$scope.$parent.selectedSubCategory = product['sub'];
      }


      if (this.product.Verfuegbarkeit === 0 && this.product.Liefertermin === 'Verfügbar') {
        this.isDropShip = true;
      } else if (this.product.Verfuegbarkeit <= 1) {
        this.hasNoSupply = true;
      } else if (this.product.Verfuegbarkeit < 10) {
        this.hasLimitedSupply = true;
      }

      this.mppService.setSelectedCategories({ 'main': product['main'], 'sub': product['sub'] });
    }
    ).then(async () => {
      let configuration = await this.customerService.getConfigurationByEmployeeId(this.$scope.employeeId);
      let leasing_rate = this.lodash.get(configuration, 'neo_config.pc.leasing_rate', this.leasingConst.leasing);
      this.leasingConst.leasing = leasing_rate;
      this.leasingPrice = parseFloat((parseFloat(((this.product['Calculated_Preis']) * this.leasingConst.leasing).toFixed(2)) * 1.19).toFixed(2));
      this.leasingPrice_Netto = parseFloat(((this.product['Calculated_Preis']) * this.leasingConst.leasing).toFixed(2));

      this.$timeout(() => { });
    });
  }

  /**
   * Goes back in browser history without page reload.
   */
  backLink() {
    this.$window.history.back();
  }

  listing(categories) {
    this.$scope.$parent.selectedProducer = '';

    if (categories.main && categories.sub) {
      this.$scope.$parent.selectedMainCategory = categories.main;
      this.$scope.$parent.selectedSubCategory = categories.sub;

      this.mppService.setSelectedCategories(categories);

      this.$state.go('inApp.mpp.side.products.landing.maincat.subcat', categories);
    } else {

      this.$scope.$parent.selectedMainCategory = categories.main;
      this.mppService.setSelectedCategories(categories);

      this.$state.go('inApp.mpp.side.products.landing.maincat', categories);
    }

  }
}

MppDetailController.$inject = $inject;