<h2 mat-dialog-title>
  Bulk Bezahlt
</h2>

<mat-dialog-content >
  Sie markieren die ausgewählte(n) Bestellung(en) als bezahlt.
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
          mat-dialog-close>
    Abbrechen
  </button>

  <button class='save-button'
          mat-stroked-button
          data-cy='vn.directBonus.backofficeAdministration.bulkPaidDialog.buttonSubmit'
          (click)="bulkMarkOrdersAsPaid()">
    Bestätigen 
  </button>
</mat-dialog-actions>
