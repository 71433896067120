import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BonagoBasketItem, BonagoRetailerBasic, BonagoVoucher } from 'src/app/direct-bonus/models/benefit-bonago.model';
import { BenefitsBonagoBasketService } from 'src/app/direct-bonus/services/benefits-bonago-basket.service';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';

// ===============================================================

interface FormOptions {
  voucherAmount: Observable<SelectOption[]>,
  voucherQuantity: string[]
}

interface FormChoices {
  voucherQuantity: string,
  voucherAmount: string,
}

enum ActiveMode {
  Logo,
  Actions
}

// ===============================================================

@Component({
  selector: 'vn-benefit-card',
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss']
})
export class BenefitCardComponent implements OnInit {

  @Input()
  retailer!: BonagoRetailerBasic;

  private activeMode: ActiveMode = ActiveMode.Logo;

  public showErrors = false;

  public formGroup!: FormGroup;
  public formOptions!: FormOptions;

  public get voucherAmount(): FormControl {
    return this.formGroup.get('voucherAmount') as FormControl;
  }

  public get voucherQuantity(): FormControl {
    return this.formGroup.get('voucherQuantity') as FormControl;
  }

  constructor(
    @Inject('$state') private $state: any,
    private formBuilder: FormBuilder,
    private bonagoService: BenefitsBonagoService,
    private basketService: BenefitsBonagoBasketService,
  ) {
    this.initFormGroup();
    this.initFormOptions();
  }

  ngOnInit(): void {
  }

  public navigateToDetails() {
    const retailerId = this.retailer.id;

    this.bonagoService.fetchRetailer(retailerId)
    this.$state.go(
      'benefitDetails',
      { retailerId }
    );
  }

  public toggleActiveMode($event: MouseEvent, cardClick = false) {
    switch (this.activeMode) {
      case ActiveMode.Logo:
        this.activeMode = ActiveMode.Actions;
        break;
      case ActiveMode.Actions:
        $event.preventDefault();
        $event.stopPropagation();

        if (cardClick) {
          return;
        }

        this.activeMode = ActiveMode.Logo;
        break;
      default:
        throw new Error(`Invalid active mode: ${this.activeMode}`);
    }
  }

  public getCardClass() {
    return {
      'clickable': this.activeMode === ActiveMode.Logo
    }
  }

  public showLogo() {
    return this.activeMode === ActiveMode.Logo;
  }

  public fetchVoucherAmountOptions() {
    this.bonagoService.fetchRetailer(this.retailer.id);
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      voucherQuantity: this.formBuilder.control(undefined, [
        Validators.required
      ]),
      voucherAmount: this.formBuilder.control(undefined, [
        Validators.required
      ]),
    });
  }

  private initFormOptions() {
    this.formOptions = {
      voucherAmount: this.bonagoService.currentRetailer$.pipe(
        map(retailer => retailer.vouchers.map(
          voucher => ({
            name: voucher.displayValue,
            value: `${voucher.voucherAmount}_${voucher.id}`
          })
        )),
        tap(() => this.voucherAmount.reset())
      ),
      voucherQuantity: Array.from(Array(10).keys()).map(x => (x + 1).toString())
    };
  }

  public addToBasket() {
    if (!this.formGroup.valid) {
      this.showErrors = true;
      return;
    }

    const {
      voucherQuantity,
      voucherAmount
    } = this.formGroup.value as FormChoices;

    let [
      amount,
      id
    ] = voucherAmount.split('_');

    const item: BonagoBasketItem = {
      voucherId: id,
      retailer: this.retailer,
      amount: parseInt(amount),
      quantity: parseInt(voucherQuantity),
      isAvailable: true,
    };

    this.basketService.addToBasket(item);
  }
}
