import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseComponent} from 'src/app/shared/models/base.component';
import {AlertService} from 'src/app/shared/services/alert.service';
import {NeoCalculationBookmarkService} from '../../services/neo-calculation-bookmark.service';

@Component({
  selector: 'vn-customer-upsert-neo-calculation-dialog',
  templateUrl: './neo-calculation-bookmark-dialog.component.html',
  styleUrls: ['./neo-calculation-bookmark-dialog.component.scss']
})
export class NeoCalculationBookmarkDialogComponent extends BaseComponent implements OnInit {

  private bookmarkId: string = '';
  public formGroup!: FormGroup;

  private calculationId!: number;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private neoCalculationBookmarkService: NeoCalculationBookmarkService,
    private dialogRef: MatDialogRef<NeoCalculationBookmarkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { calculationId?: number },
    @Inject('authenticationService') private authenticationService: any,
    @Inject('userService') private userService: any,
    @Inject('VbmDataService') private vbmDataService: any,
  ) {
    super();

    this.initForm();
  }

  async ngOnInit(): Promise<void> {

    if (!this.dialogData?.calculationId) {
      const employeeId = this.vbmDataService.employeeId;
      const employee = this.vbmDataService.employees[employeeId];
      this.calculationId = employee.data.metadata.id;
    } else {
      this.calculationId = this.dialogData.calculationId
    }

    await this.getNeoCalculationBookmark();
  }

  private initForm() {
    this.formGroup = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.required]),
      isHidden: this.formBuilder.control(false, [Validators.required]),
      isReporting: this.formBuilder.control(false, [Validators.required]),
    });
  }

  private get employeeId(): number {
    return this.vbmDataService.employeeId;
  }

  private getFormValue(name: string): any {
    return this.formGroup.controls[name].value;
  }

  public allowToUpdateCalculationBookmark(): boolean {
    return !!this.bookmarkId;
  }

  public allowToCreateCalculationBookmark(): boolean {
    return !this.bookmarkId;
  }

  public allowToMarkCalculationBookmark(): boolean {
    const iAmAdvisor: boolean = this.authenticationService.iAm('advisor');
    const iAmProxy: boolean = this.userService.iAmProxy();
    return iAmAdvisor || iAmProxy
  }

  public async getNeoCalculationBookmark(): Promise<void> {
    this.setLoading$(true);

    const calculationBookmark = await this.neoCalculationBookmarkService.getNeoCalculationBookmarkByCalculationId(
      this.calculationId
    );

    if (calculationBookmark) {
      this.bookmarkId = calculationBookmark.id;
      this.formGroup.setValue({
        name: calculationBookmark.name,
        isHidden: calculationBookmark.isHidden,
        isReporting: calculationBookmark.isReporting,
      });
    }

    this.setLoading$(false);
  }

  public async createNeoCalculationBookmark(): Promise<void> {
    try {
      const createdCalculationBookmark = await this.neoCalculationBookmarkService.createNeoCalculationBookmark(
        this.employeeId,
        this.calculationId,
        this.getFormValue('name'),
        this.getFormValue('isHidden'),
        this.getFormValue('isReporting'),
      );
      if (createdCalculationBookmark) {
        this.alertService.message('Optimierung wurde markiert'); // TRANSLATE specially for creating
      }
    } catch (err) {
      this.alertService.error('Fehlgeschlagen');
      console.error(err);
    } finally {
      this.dialogRef.close();
    }
  }

  public async updateNeoCalculationBookmark(): Promise<void> {
    try {
      const updatedCalculationBookmark = await this.neoCalculationBookmarkService.updateNeoCalculationBookmark(
        this.bookmarkId,
        this.getFormValue('name'),
        this.getFormValue('isHidden'),
        this.getFormValue('isReporting'),
      );
      if (updatedCalculationBookmark) {
        this.alertService.message('Optimierung wurde markiert'); // TRANSLATE specially for updating
      }
    } catch (err) {
      this.alertService.error('Fehlgeschlagen');
      console.error(err);
    } finally {
      this.dialogRef.close();
    }
  }

  public async deleteNeoCalculationBookmark(): Promise<void> {
    try {
      const deletedCalculationBookmark = await this.neoCalculationBookmarkService.deleteNeoCalculationBookmark(
        this.bookmarkId,
      );
      if (deletedCalculationBookmark) {
        console.log(deletedCalculationBookmark);
        this.alertService.message('Optimierung wurde markiert'); // TRANSLATE specially for updating
      }
    } catch (err) {
      this.alertService.error('Fehlgeschlagen');
      console.error(err);
    } finally {
      this.dialogRef.close();
    }
  }

  public async getNeoCalculationBookmarksByEmployeeId(): Promise<void> {
    try {
      const allCalculationBookmark = await this.neoCalculationBookmarkService.getNeoCalculationBookmarksByEmployeeId(
        this.employeeId,
      );
      if (allCalculationBookmark) {
        console.log(allCalculationBookmark);
        this.alertService.message('Optimierung wurde markiert'); // TRANSLATE specially for updating
      }
    } catch (err) {
      this.alertService.error('Fehlgeschlagen');
      console.error(err);
    } finally {
      this.dialogRef.close();
    }
  }

}
