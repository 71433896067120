import { NEO_COMPONENTS_WITH_DOCUMENTS, BONUS_COMPONENTS_WITH_DOCUMENTS, PROJECT_TAGS, PROJECT_TAGS_BY_NAME } from '../../../services/customerService';
import {SUPPORT_REQUEST_TAG} from '../../../services/SupportService';
import {DEFAULT_YEARLY_DOCUMENTS_TAG_STRING} from '../../../services/yearlyService';
import {BONUS_CHECKOUT_FILE_TYPES_BY_TYPE} from "../../../services/bonus/bonus_checkout_file_types";
import {DigitalSignatureYouSignServiceV3} from "../../../../app/shared/services/digital-signature-you-sign.service";
/**
 * This controller is now basically DocumentController. It can be used everywhere via the `documentListComponent`
 * component to show the user' files
 */

const $inject = [
  '$q',
  '$scope',
  '$window',
  'Made',
  'vbmData',
  'employeeService',
  'advisorService',
  'userService',
  'DocumentConstants',
  'authenticationService',
  'dialogService',
  'componentsService',
  'customerService',
  'BonusService',
  'moment',
  'lodash',
  '$state',
  '$timeout',
  'fileService',
  'FilenameService',
  'ACLService',
  'NeoComponentsService',
  'DigitalSigningService',
  'DigitalSignatureYouSignServiceV3',
  'mailService',
  'VSP_CONSTANTS'
];

export default class MeineAkteDokumenteController {
  constructor(
    $q,
    $scope,
    $window,
    Made,
    vbmData,
    employeeService,
    advisorService,
    userService,
    DocumentConstants,
    authenticationService,
    dialogService,
    componentsService,
    customerService,
    BonusService,
    moment,
    lodash,
    $state,
    $timeout,
    fileService,
    FilenameService,
    ACLService,
    NeoComponentsService,
    DigitalSigningService,
    DigitalSignatureYouSignServiceV3,
    mailService,
    VSP_CONSTANTS
  ) {
    Object.assign(this, {
      $q,
      $scope,
      $window,
      Made,
      vbmData,
      employeeService,
      advisorService,
      userService,
      DocumentConstants,
      authenticationService,
      dialogService,
      componentsService,
      customerService,
      BonusService,
      moment,
      lodash,
      $state,
      $timeout,
      fileService,
      FilenameService,
      ACLService,
      NeoComponentsService,
      DigitalSigningService,
      DigitalSignatureYouSignServiceV3,
      mailService,
      VSP_CONSTANTS
    });

    this.now = new Date().getTime();
    this.iAmEmployee = authenticationService.iAm('employee');
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmFiduciaUser = authenticationService.iAm('fg_user');
    this.has_missing_documents = this.lodash.get(this.$state.params, 'nonUrlParam.has_missing_documents');
    this.is_cockpit = this.$state.current.name.includes('cockpit')
    this.files = [];
    this.components = [];

    $scope.employeeId = this.Made.user.valuenet_id;

    this.$onInit = () => {
      this.$timeout(()=>{
        this.loading = true;
      });
      this.forms = {};
      $scope.employeeId = (this.options && this.options.valuenet_id) || $scope.employeeId;
      this.employeeId = $scope.employeeId;
      $scope.to_show_controls = this.lodash.get(this.options, 'to_show_controls', true);
      $scope.show_neo_documents = this.lodash.get(this.options, 'show_neo_documents', true);
      $scope.show_bonus_documents = this.lodash.get(this.options, 'show_bonus_documents', true);
      $scope.show_control_process_form = this.lodash.get(this.options, 'show_control_process_form', false);
      $scope.for_latest_contract_only = this.lodash.get(this.options, 'for_latest_contract_only', false);
      $scope.show_salary_statements = this.lodash.get(this.options, 'show_salary_statements', true);
      $scope.show_support_request_files = this.lodash.get(this.options, 'show_support_request_files', true);
      $scope.bonus_project_id = this.lodash.get(this.options, 'bonus_project_id');
      $scope.to_notify_advisor_on_upload = this.lodash.get(this.options, 'to_notify_advisor_on_upload', true);
      $scope.hide_dropzone = this.lodash.get(this.options, 'hide_dropzone', false);
      $scope.allowed_components = this.lodash.get(this.options, 'allowed_components', []);
      $scope.show_audit_trail = this.lodash.get(this.options, 'show_audit_trail', false);
      $scope.show_bgm_documents = this.lodash.get(this.options, 'show_bgm_documents', true);
      this.PROJECT_TAGS_BY_NAME = PROJECT_TAGS_BY_NAME;

      // by default include all project tags
      this.project_tags = PROJECT_TAGS;
      this.components = [];
      this.filesPerBonusProject = {};
      if ($scope.show_bonus_documents && $scope.show_neo_documents) {
        // do nothing
      } else if ($scope.show_bonus_documents && !$scope.show_neo_documents) {
        // show only bonus project
        this.project_tags = this.project_tags.filter(tag => tag.value === 'bonus');
      } else {
        // show only NEO project
        this.project_tags = this.project_tags.filter(tag => tag.value !== 'bonus');
      }

      this.component_tag_selected = undefined;
      this.project_tag_selected = this.project_tags[0].value;

      if (this.project_tag_selected === 'bonus') {
        this.bonus_tag_selected = true;
        BONUS_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
          if (component.id !== 'bonus_contract') {
            this.components.push({
              name: component.display,
              value: component.id
            });
          }
        });
      } else if (this.project_tag_selected === 'neo') {
        this.neo_tag_selected = true;
        NEO_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
          this.components.push({
            name: component.display,
            value: component.id
          });
        });
      }

      let promises = [];

      promises.push(
        customerService
          .getConfiguration()
          .then(configuration => {
            this.configuration = configuration;
            this.configuration.salary_statement_config.is_enabled = true;
          })
      );

      promises.push(
        this.getBonusComponentsMap().then(()=>{
          return this.get();
        })
      );

      promises.push(
        this.BonusService.getBonusesForEmployee({
          customer_id: this.customerId,
          employee_id: this.employeeId,
          to_attach_administration_validations: true
        }).then( bonuses => {
          this.bonuses = bonuses;
        })
      )

      // if we have refreshed it will be null
      if (angular.isUndefined(this.has_missing_documents) || this.has_missing_documents === null) {
        promises.push(
          this.$q.all({
            has_missing_evg_documents: this.employeeService.hasMissingDocuments(),
            has_missing_bonus_documents: this.BonusService.hasMissingBonusDocuments(this.employeeId),
            has_missing_neo_component_documents: this.NeoComponentsService.getNeoComponentsRequiringDocuments({employee_id: this.employeeId}),
            has_missing_bo_requested_documents: this.DigitalSigningService.getRequestsForSigning(this.employeeId)
          }).then((res) => {
              this.has_missing_documents =
              res.has_missing_evg_documents ||
              res.has_missing_bonus_documents ||
              res.has_missing_neo_component_documents.length > 0 ||
              res.has_missing_bo_requested_documents.length > 0;
          })

        );
      }

      promises.push(
        vbmData
          .getResult($scope.employeeId)
          .then(() => $scope.employee = vbmData.employees[$scope.employeeId])
      );

      promises.push(
        employeeService
          .get($scope.employeeId)
          .then(employee => vbmData.employees[$scope.employeeId].data.person = employee)
          .then(async () => {
            this.iAmEmployer = await this.ACLService.amIEmployer($scope.employee.data.person.customer_id);
            return;
          })
      );

      return this.$q.all(promises)
        .then(() => {
          this.$timeout(()=>{
            this.loading = false;
          });
        });
    };
  }

  validateBonusUpload() {
    if (!this.$scope.allowed_components || this.$scope.allowed_components.length === 0) {
      return true;
    }

    let allowed = this.$scope.allowed_components.filter(comp => {
      return comp.component[this.component_tag_selected];
    });
    return this.component_tag_selected === 'other' || allowed.length;
  }

  async handleTagChange(){
    this.$timeout( () => {
      this.loading = true;
    },0);

    await this.updateDropzoneFileFilters();

    this.$timeout( () => {
      this.loading = false;
    },0);
  }

  async updateDropzoneFileFilters(){
    let file_filters = [];
    let components = [];
    if (this.project_tag_selected === this.PROJECT_TAGS_BY_NAME['bonus']['value']) {
      this.bonus_tag_selected = true;
      this.neo_tag_selected = false;
      BONUS_COMPONENTS_WITH_DOCUMENTS.forEach( component => {
        if (component.id !== 'bonus_contract') {
          components.push({
            name: component.display,
            value: component.id
          });
        }
      });

      if (this.component_tag_selected) {
        this.filtered_projects = [];
        if (this.$scope.bonus_project_id) {
          this.filtered_projects = this.employeeBonusProjects.filter(project => project._id === this.$scope.bonus_project_id );
          this.bonus_project_tag_selected = this.$scope.bonus_project_id;
        } else  {
          this.employeeBonusProjects.forEach( project => {
            this.bonuses.forEach( bonus => {
              if (Object.keys(bonus.component).includes(this.component_tag_selected) && project._id === bonus.bonus_project){
                this.filtered_projects.push(project);
              };
            });
          });
        }
        this.bonus_projects_for_component = this.filtered_projects.map( project => {
          return {
            id: project._id,
            name: project.name
          }
        })
        if (this.validateBonusUpload()) {
          // push file tag strings here as file_filters to pass to the dropzone for the 'choose existing file' functionality
          file_filters.push(this.component_tag_selected);
          file_filters.push('bonus_component_name-' + this.component_tag_selected);
        } else {
          this.component_tag_selected = '';

          this.files = [];
          alert("User doesnt have existing bonus component for this type of document")
        }
      }
    } else if (this.project_tag_selected === this.PROJECT_TAGS_BY_NAME['salaryStatement']['value'])  {
      file_filters.push(this.project_tag_selected);
      this.neo_tag_selected = false;
      this.bonus_tag_selected = false;
    } else if (this.project_tag_selected === this.PROJECT_TAGS_BY_NAME['neo']['value']) {
      this.neo_tag_selected = true;
      this.bonus_tag_selected = false;
      NEO_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
        components.push({
          name: component.display,
          value: component.id
        });
      });
      file_filters.push(this.component_tag_selected);
    }

    this.$timeout(()=>{
      this.file_filters = file_filters;
      this.components = components;
    });
  }

  initNeoComponentCategories() {
    let categories = {};

    NEO_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
      categories[component.id] = {
        title: component.display,
        documents: []
      };
    });

    return categories;
  }

  initBonusComponentCategories() {
    let categories = {};
    BONUS_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
      categories[component.id] = {
        id: component.id,
        title: component.display,
        documents: []
      };
    });
    return categories;
  }

  getTags(){
    let file_tags = [this.project_tag_selected];
    if (this.project_tag_selected === 'bonus') {
      if (this.component_tag_selected !== 'other') {
        file_tags.push(this.component_tag_selected);
      }
      this.$scope.bonus_project_id ? file_tags.push(`bonus_project_id-${this.$scope.bonus_project_id}`) : file_tags.push(`bonus_project_id-${this.bonus_project_tag_selected}`);
      file_tags.push('doc-type-administration_upload_for_bonus');
      file_tags.push('bonus_component_name-' + this.component_tag_selected);
    } else if (this.project_tag_selected === 'neo')  {
      file_tags.push(this.component_tag_selected);
    }

    return file_tags;
  }

  upload() {
    let promises = [];

    this.uploading = true;

    let tags = this.getTags();

    for (let i = 0; i < this.files.length; i++) {
      promises.push(this.Made.upload(this.files[i], tags, this.$scope.employeeId));
    }

    let upload_operation =  this.$q.all(promises);

    if (this.$scope.to_notify_advisor_on_upload){
      upload_operation
        .then(files => {
          return this.advisorService.notifyAdvisorAboutUploads(
            files.map(file => file.data._id),
            this.$scope.employeeId
          );

        });
    }

    upload_operation.then(() => {
      this.files = [];
      this.project_tag_selected = null;
      this.component_tag_selected = null;
      this.forms.uploadNewDocumentForm.$setPristine();
      this.uploading = false;
      return this.get();
    });
  }

  async get() {
    this.loading = true;
    //initialize categories for the neo component files
    this.neo_document_categories = this.initNeoComponentCategories();
    this.bonus_document_categories = this.initBonusComponentCategories();
    let promise = null;

    if (this.$scope.bonus_project_id && this.$scope.show_bonus_documents && !this.$scope.show_neo_documents) {
      promise = this.userService.getBonusUploaded({
        valuenet_id: this.$scope.employeeId,
        user_id: this.Made.user._id,
        bonus_project_id: this.$scope.bonus_project_id
      });
    } else {
      promise = this.userService
        .getUploadedFiles(undefined, {
          valuenet_id: this.$scope.employeeId,
          for_latest_contract_only: this.$scope.for_latest_contract_only,
          show_neo_documents: this.$scope.show_neo_documents,
          show_bonus_documents: this.$scope.show_bonus_documents
        });
    }

    promise
      .then(async (files) => {
        this.all_files = files;
        this.employeeBonusProjects = await this.BonusService.getBonusProjectsDisplayForUser(this.$scope.employeeId, true);

        if(!this.employeeBonusProjects.length) {
          this.project_tags = this.project_tags.filter(tag => tag.value !== this.PROJECT_TAGS_BY_NAME['bonus'].value);
        }
        this.hasUploadedNeoDocuments = false;
        this.hasUploadedBonusDocuments = false;
        this.neoDocuments = [];
        this.bonusCheckoutDocuments = [];

        let bonusYouSignFiles = []
        files.filter((file) => {
          let file_temp = file;
          if (!file.meta.tags) {
            return;
          }

          if (
              file.meta.tags.indexOf('audit_trail') !== -1 ||
              file.meta.tags.indexOf('bo_request') !== -1
          ) {
            return;
          }

          const bonusTagIndex = file.meta.tags.findIndex(tag => tag.includes('bonus_id-'));
          if(bonusTagIndex !== -1 && file.meta.tags.includes('yousign')) {
            const bonusTagId = file.meta.tags[bonusTagIndex]
            if(!bonusYouSignFiles.includes(bonusTagId)) {
              bonusYouSignFiles.push(bonusTagId);
            } else {
              return;
            }
          }


          if ((file.meta.tags.indexOf( 'neo' ) !== -1 || file.meta.tags.indexOf( 'evg_final' ) !== -1) && (-1 == file.meta.tags.indexOf(DEFAULT_YEARLY_DOCUMENTS_TAG_STRING))) {

            // salaryStatements will be collected at 1 place
            if (-1 !== file.meta.tags.indexOf('salaryStatement')) {
              return file;
            }

            let category_set = false;
            this.hasUploadedNeoDocuments = true;

            Object.keys(this.neo_document_categories).forEach((category) => {
              if (file.meta.tags.includes(category)) {
                this.neo_document_categories[category].documents.push(file);
                category_set = true;
              }
            });

            if (category_set === false) {
              this.neo_document_categories.other.documents.push(file);
              this.hasUploadedNeoDocuments = true;
            }
            this.neoDocuments.push(file);
          } else if ((-1 !== file.meta.tags.indexOf('bonus'))) {
            let category_set = false;
            this.hasUploadedBonusDocuments = true;


            Object.keys(this.bonus_document_categories).forEach((category) => {
              if (file.meta.tags.findIndex(tag => tag.includes('bonus_component_name-' + category)) !== -1) {
                this.bonus_document_categories[category].documents.push(file);
                category_set = true;
              }
            });


            if (!category_set) {
              if (
                file.meta.tags.includes('doc_type-user_upload_checkout_contract_main') ||
                file.meta.tags.includes('doc_type-bikeleasing_contract') ||
                file.meta.tags.includes('bikeleasing_contract')
              ) {
                this.bonus_document_categories['bonus_contract'].documents.push(file);
                category_set = true;
              }
            }

            if (category_set === false) {
              this.bonus_document_categories['other'].documents.push(file);
              this.hasUploadedBonusDocuments = true;
            }

            file.filename_bonus_project_name = 'N/A';
            let bonusProjectIdIndex = file.meta.tags.findIndex(tag => tag.includes('bonus_project_id'));
            if (bonusProjectIdIndex !== -1) {
              let bonusProjectId = file.meta.tags[bonusProjectIdIndex].split('-')[1];
              for (let bonusProject of this.employeeBonusProjects) {

                if (bonusProject['_id'] === bonusProjectId) {
                  file.filename_bonus_project_name = bonusProject['name'];
                }
              }

            }

            file.filename_formatted = this.getFileName(file);
            this.bonusCheckoutDocuments.push(file);
          }
          return file;
        });

        for (let bonusCategory in this.bonus_document_categories) {
          let bonusSs = this.bonus_document_categories[bonusCategory];
          let documents = bonusSs['documents'];

          documents.forEach(doc => {

            let project = this.employeeBonusProjects.find( el => el['name'] === doc.filename_bonus_project_name);
            if (project) {
              if (!this.filesPerBonusProject[project.name]) {
                this.filesPerBonusProject[project.name] = {};
              }

              if (! this.filesPerBonusProject[project.name][bonusCategory]) {
                this.filesPerBonusProject[project.name][bonusCategory] = {
                  "documents": []
                };
              }

              this.filesPerBonusProject[project.name][bonusCategory].documents.push(doc);
            }
          });

        }

        this.salaryDocuments = files.filter(file =>
          file.meta.tags &&
          (-1 !== file.meta.tags.indexOf('salaryStatement') && !this.fileService.isAuditTrail(file.meta.tags))
        );
        this.bgmDocuments = files.filter(file =>
          file.meta.tags &&
          (-1 !== file.meta.tags.indexOf('bgmCertificate') && !this.fileService.isAuditTrail(file.meta.tags))
        );
        this.yearlyDocuments = files.filter(file =>
          file.meta.tags &&
          (-1 !== file.meta.tags.indexOf(DEFAULT_YEARLY_DOCUMENTS_TAG_STRING))
        );
        this.insuranceDocuments = files.filter(file =>
          file.meta.tags &&
          (file.meta.tags.indexOf('insuranceDocument') !== -1)
        );

        this.support_request_files = files.filter((file) => {
          if (file.meta.tags && (-1 !== file.meta.tags.indexOf(SUPPORT_REQUEST_TAG.main_tag))) {
            file.filename_formatted = this.getFileName(file);
            return true;
          }
          return false;
        });

        this.preCalculationDocuments = files.filter(file => file.meta.tags && (-1 !== file.meta.tags.indexOf('calculation')));

        this.calculationDocuments = [];
        for (let i = 0; i < this.preCalculationDocuments.length; i++) {
          let doc = this.preCalculationDocuments[i];
          if ('' + doc.meta.tags[1] == '89' && (this.iAmEmployer || this.iAmGAdvisor || this.iAmAdvisor || this.iAmSpecialAdvisor)) {
            this.calculationDocuments.push(doc);
          } else if ('' + doc.meta.tags[1] != '89') {
            this.calculationDocuments.push(doc);
          }
        }
      });

    const carLeasingDocumentsYouSignV3 =
      await this.DigitalSignatureYouSignServiceV3.getCarLeasingDocumentsForMeineAkte(this.employeeId, (file) => {
        this.neo_document_categories['other'].documents.push(file);
      });
    //->old const carLeasingDocumentsYouSignV3 = await this.DigitalSignatureYouSignServiceV3.getCarLeasingDocuments(this.employeeId);
    const pcLeasingDocumentsYouSignV3 = await this.DigitalSignatureYouSignServiceV3.getPcLeasingBasketFiles(this.employeeId);
    const dTicketDocumentsYouSignV3 = await this.DigitalSignatureYouSignServiceV3.getDTicketFiles(this.employeeId);
    const bonusDocumentsYouSignV3 = await this.DigitalSignatureYouSignServiceV3.getBonusFiles(this.employeeId);
    const BODocumentsYouSignV3 = await this.DigitalSignatureYouSignServiceV3.getBORequestFiles(this.employeeId);
    console.log('carLeasingDocumentsYouSignV3', carLeasingDocumentsYouSignV3);
    console.log("pcLeasingDocumentsYouSignV3", pcLeasingDocumentsYouSignV3)
    console.log("dTicketDocumentsYouSignV3", dTicketDocumentsYouSignV3)
    console.log("bonusDocumentsYouSignV3", bonusDocumentsYouSignV3)
    console.log("BODocumentsYouSignV3", BODocumentsYouSignV3)
    // YouSign V3
    // bonus -> this.bonus_document_categories['other'].documents.push(file);
    // carleasing
    this.hasUploadedBonusDocuments = bonusDocumentsYouSignV3.length > 0;
    this.hasUploadedNeoDocuments = (
      dTicketDocumentsYouSignV3.length > 0 ||
      carLeasingDocumentsYouSignV3.length > 0 ||
      BODocumentsYouSignV3.length > 0 ||
      pcLeasingDocumentsYouSignV3.length > 0
    );

    //->old carLeasingDocumentsYouSignV3.forEach(file => {
    //   let date = new Date(file.metadata[1] * 1000);
    //   let year = date.getFullYear();
    //   let month = String(date.getMonth() + 1).padStart(2, '0');
    //   let day = String(date.getDate()).padStart(2, '0');
    //   let hours = String(date.getHours()).padStart(2, '0');
    //   let minutes = String(date.getMinutes()).padStart(2, '0');
    //   let seconds = String(date.getSeconds()).padStart(2, '0');
    //
    //   // Format the date as Y-m-d/H-M-S
    //   let formattedDate = `${year}-${month}-${day}/${hours}-${minutes}-${seconds}`;
    //   file.yousign_v3 = true;
    //   file.filename = '%_' + formattedDate;
    //   if (file.process_type === 'pre-contract') {
    //     file.filename = file.filename.replace('%', 'car_leasing_pre_contract_contract');
    //   } else if (file.process_type === 'digital') {
    //     file.filename = file.filename.replace('%', 'car_leasing_contract_contract');
    //   }
    //
    //   this.neo_document_categories['other'].documents.push(file);
    // })

    pcLeasingDocumentsYouSignV3.forEach(youSignPCLeasingV3File => {
      youSignPCLeasingV3File.yousign_v3 = true;
      youSignPCLeasingV3File.file_id = youSignPCLeasingV3File._id
      this.neo_document_categories['pc'].documents.push(youSignPCLeasingV3File);
    })

    dTicketDocumentsYouSignV3.forEach(youSignDTicketV3File => {
      youSignDTicketV3File.yousign_v3 = true;
      youSignDTicketV3File.file_id = youSignDTicketV3File._id
      this.neo_document_categories['dticket'].documents.push(youSignDTicketV3File);
    })


    bonusDocumentsYouSignV3.forEach(youSignBONUSLeasingV3File => {
      youSignBONUSLeasingV3File.yousign_v3 = true;
      youSignBONUSLeasingV3File.file_id = youSignBONUSLeasingV3File._id

      for (let bonusProject of this.employeeBonusProjects) {
        if (bonusProject['name'] === youSignBONUSLeasingV3File.bonus_project_name) {
          if (!this.filesPerBonusProject[youSignBONUSLeasingV3File.bonus_project_name]) {
            this.filesPerBonusProject[youSignBONUSLeasingV3File.bonus_project_name] = {};
          }

          if (! this.filesPerBonusProject[youSignBONUSLeasingV3File.bonus_project_name]['other']) {
            this.filesPerBonusProject[youSignBONUSLeasingV3File.bonus_project_name]['other'] = {
              "documents": []
            };
          }
        }
      }

      this.filesPerBonusProject[youSignBONUSLeasingV3File.bonus_project_name]['other'].documents.push(youSignBONUSLeasingV3File);
    })

    BODocumentsYouSignV3.forEach(youSignBonusV3File => {
      youSignBonusV3File.yousign_v3 = true;
      youSignBonusV3File.file_id = youSignBonusV3File._id
      if (this.neo_document_categories[youSignBonusV3File.type]) {
        this.neo_document_categories[youSignBonusV3File.type].documents.push(youSignBonusV3File);
      } else {
        this.neo_document_categories['other'].documents.push(youSignBonusV3File);
      }
    })

    promise.finally(() => {
      this.$timeout(()=>{
        this.loading = false;
      });
    });

    return promise;
  }

  deleteCheckoutContractDocument(doc, checkout_id) {
    this.deleteDocument(doc).then((result) => {
      if (result) {
        // don't know what to do
      }
    });
  }

  async openMatchingYouSignAuditTrail(document) {
    let procedure_id_tag = await this.fileService.getProcedureIDFileTag(document);
    await this.fileService.openMatchingYouSignAuditTrail(procedure_id_tag, this.all_files);
  }

  deleteDocument(document, fileName = null) {
    fileName = fileName || document.filename;

    return this.dialogService
      .frageDialog(`Sie möchten das Dokument "${fileName}" wirklich löschen?`, '', {
        approve: 'Bestätigen',
        decline: 'Abbrechen'
      })
      .then(
        () => {
          this.userService.deleteFiles([document._id]);
          this.get();
          return true;
        },
        () => {
          return false;
        }
      );
  }

  openDocument(document){
    this.fileService.openDocument(document._id);
  }

  openDocumentYouSignV3(document, type) {
    this.DigitalSignatureYouSignServiceV3.openYouSignFileInNewTab(document.file_id, type);
  }

  deleteAllDocuments(documents) {
    this.dialogService
      .frageDialog(`Sie möchten ${documents.length} Dateien löschen?`, '', {
        approve: 'Bestätigen',
        decline: 'Abbrechen'
      })
      .then(
        () => {
          let fileIds = [];
          for (var document of documents) {
            fileIds.push(document._id);
          }
          this.userService.deleteFiles(fileIds).then(() => {
            this.get();
          });
        },
        () => {
        }
      );
  }

  getBonusComponentsMap () {
    return this.BonusService.getAvailableBonusComponents().then((res) => {
      this.$timeout(()=>{
        this.BONUS_COMPONENTS_BY_NAME = res.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
      });
    });
  }

  getCheckoutDocumentFilename(document) {
    return this.FilenameService.getCheckoutDocumentFilename(document);
  }

  getFileName(file) {
    return this.FilenameService.getFileName(file);
  }

  getNeoDocumentFilename(document) {
    if(document.meta.tags.includes( DEFAULT_YEARLY_DOCUMENTS_TAG_STRING )) {
      let filename =  `jahresabfrage_${this.moment().year()}_${document.filename}`;
      return filename;
    }
    return document.filename;
  }

}

MeineAkteDokumenteController.$inject = $inject;
