import { Injectable } from '@angular/core';
import { Car } from '../models/car.model'
import { CarCostParamsByCarId, CarLeasingApiService } from './car-leasing-api.service';

@Injectable()
export class CarLeasingCatalogService {

  private $$cars: Promise<Car[]>;

  public constructor(
    private _carLeasingApiService: CarLeasingApiService
  ) {
    this.$$cars = this._carLeasingApiService.getCars();
  }

  async getCars() {
    return this.$$cars;
  }

  async getCatalogPrices(cars: Car[], customerId: number) {
    return this._carLeasingApiService.calculateCatalogCosts(
      cars.reduce(
        (carCostParams, car) => ({
          ...carCostParams,
          [car.id]: {
            price: car.getCataloguePrice(),
            duration: car.getMaxTerm(),
            fuelType: car.fuel
          }
        }),
        {} as CarCostParamsByCarId
      ),
      customerId
    );
  }

  async getCarById(carId: string): Promise<Car> {
    return this._carLeasingApiService.getCarById(carId);
  }

}
