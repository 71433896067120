angular
  .module('VSPApp')
  .directive('vnBoolToNumber', [function () {
    return {
      scope: {
        vnBoolToNumber: '='
      },
      require: 'ngModel',
      link: function ($scope, elem, attrs, ngModel) {

        ngModel.$parsers.unshift(function (newViewVal) {
          $scope.vnBoolToNumber = newViewVal ? 1 : 0;

          return newViewVal;
        });

        ngModel.$formatters.unshift(function () {
          return !!$scope.vnBoolToNumber;
        });
      }
    };
  }]);
