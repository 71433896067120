const CalculationNameConstants = [
  'brutto_steuer',
  'brutto_sv',
  'pap_kst',
  'pap_lst',
  'pap_szt',
  'sv_alos',
  'sv_kv_an',
  'sv_pflege_an',
  'sv_rente',
  'sv_sozialabgaben_an'
];

export default CalculationNameConstants;
