import spinner_html from "./spinner.pug";

angular
  .module('VSPApp')
  .directive('spinner', [function () {

    return {
      restrict : 'E',
      replace : true,
      template: spinner_html
   };
}]);
