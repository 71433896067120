const $inject = [
  '$state',
  '$scope',
  'NotificationService'
];

export default class DigitalSigningErrorController {
  constructor(
    $state,
    $scope,
    NotificationService
  ) {

    this.$state = $state;
    this.$scope = $scope;
    this.NotificationService = NotificationService;
    this.NotificationService.error('Fehlgeschlagen');
  }

}

DigitalSigningErrorController.$inject = $inject;
