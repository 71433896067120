<h2 mat-dialog-title>
    Bestellung ausführen
</h2>

<mat-dialog-content>
    <div>
        <p>
            Sie markieren die ausgewählte(n) Bestellung(en) als ausgeführt.
            Sie können auch eine Begründung dafür hinterlassen:
        </p>
    </div>

    <form [formGroup]="dialogInputForm">
        <vn-input formControlName='comment'
                  key="vn.directBonus.backofficeAdministration.completeOrderDialog.inputComment"
                  label="Kommentar">
        </vn-input>
    </form>

    <div>
        <p>
            Fügen Sie bitte in das untere Eingabefeld auch die URL auf das auftragsbezogene OTRS-Ticket ein:
        </p>
    </div>

    <form [formGroup]="OTRSTicketForm">
        <vn-input formControlName='OTRSTicket'
                  key="vn.directBonus.backofficeAdministration.completeOrderDialog.inputOTRSTicket"
                  label="OTRS Ticket">
        </vn-input>
    </form>

    <div>
        <p>
            Nach dem Klick auf “Bestätigen“ werden alle Boni
            aus der (den) ausgewählten Bestellung(en) als Topup-Guthaben auf Bonago gelade.
        </p>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='create-button'
            data-cy='vn.directBonus.backofficeAdministration.completeOrderDialog.buttonSubmit'
            mat-stroked-button
            (click)="markOrderAsDone()">
        Bestätigen
    </button>
</mat-dialog-actions>