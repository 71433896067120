var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (nettoOld) {
      var bruttoOld = '{{ employee.result.before.tax.brutto_gesamt | currency }}'
var nettoOld = '{{ employee.result.before.tax.net_netto | currency }}'
var nettoNew = '{{ employee.result.final.tax.net_netto + employee.result.final.tax.net_jahr | currency }}'
var nettoPlusMonthly = '{{ (employee.result.final.tax.net_netto - employee.result.before.tax.net_netto + employee.result.final.tax.net_jahr) | currency }}'
var nettoPlusYearly = '{{ (employee.result.final.tax.net_netto - employee.result.before.tax.net_netto + employee.result.final.tax.net_jahr) *12 | currency }}'
var nettoSmaller = 'employee.result.final.tax.net_netto + employee.result.final.tax.net_jahr < employee.result.before.tax.net_netto'
var nettoLarger = 'employee.result.final.tax.net_netto + employee.result.final.tax.net_jahr > employee.result.before.tax.net_netto'
var agKostenOld = '{{ employee.result.before.tax.ag_kosten | currency }}'
var agKostenNew = '{{ employee.result.final.tax.ag_kosten | currency }}'
var is1to1 = '"NONE" == employee.data.parameters.einsparungs_modell'
pug_html = pug_html + "\u003Cli ng-show=\"(!infomode &amp;&amp; undefined !== infomode) || !isSelfservice()\"\u003E\u003Cspan class=\"key\"\u003EAltes Nettogehalt\u003C\u002Fspan\u003E\u003Cspan class=\"value\"\u003E" + (pug.escape(null == (pug_interp = nettoOld) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fli\u003E";
    }.call(this, "nettoOld" in locals_for_with ?
        locals_for_with.nettoOld :
        typeof nettoOld !== 'undefined' ? nettoOld : undefined));
    ;;return pug_html;};
module.exports = template;