export const TAX_PAYERS = [
  { 'id': 0, 'name': 'employer', 'value': 'AG', 'display': 'AG' },
  { 'id': 1, 'name': 'employee', 'value': 'AN', 'display': 'AN' },
];
export const TAX_PAYERS_BY_NAME = TAX_PAYERS.reduce((acc, payer) => {
  acc[payer['name']] = payer;
  return acc;
}, {});
export const TAX_PAYERS_BY_VALUE = TAX_PAYERS.reduce((acc, payer) => {
  acc[payer['value']] = payer;
  return acc;
}, {});
export const DEFAULT_TAX_PAYER = TAX_PAYERS_BY_NAME['employer'];
