import {
  DEFAULT_MPP_DELIVERY_SERVICES,
  DEFAULT_MPP_TRACKING_ID_INPUT_ROW,
  MPP_ORDER_STATUSES_BY_NAME
} from '../../../../../services/mppService';

const $inject = [
  '$scope',
  'mppService',
  'NotificationService',
  '$timeout',
  'lodash'
];
export default class ready_for_delivery_controller {
  constructor(
    $scope,
    mppService,
    NotificationService,
    $timeout,
    lodash
  ) {

    Object.assign(this, {
      $scope,
      mppService,
      NotificationService,
      $timeout,
      lodash,
      DEFAULT_MPP_TRACKING_ID_INPUT_ROW,
    });

    this.basket = angular.copy(this.$scope.ngDialogData.basket);
    this.pick_up_date = this.lodash.get(this.basket, 'confirmation.pick_up_date'); //might not exist initially

    this.employeeId = this.basket.employeeId;

    this.errors = {
      begin_date_change_not_allowed: false,
      future_date_not_allowed: false,
      general_error: false
    };

    this.loading = {
      main: true,
      saving: false
    };

    this.forms = {
      'pick_up_date_form': {},
      'tracking_id_form': {}
    };


    this.answers = this.lodash.get(this.basket, 'trackingIds', []);
    this.DEFAULT_DELIVERY_SERVICES = DEFAULT_MPP_DELIVERY_SERVICES;

    if (lodash.isUndefined(this.basket.trackingIds)) {
      this.basket.trackingIds = [];
    }

    if (this.basket.trackingIds.length === 0) {
      this.addRow();
    }

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.MPP_ORDER_STATUSES_BY_NAME = await this.mppService.getOrderStatusesByName();

    //this pickup date can be changed from this dialog only when going through flow
    this.isPickUpDateChangeAllowed = this.canSetPickUpDate();
  }

  addRow() {
    let new_row = {
      tracking_id: undefined,
      delivery_service: undefined
    };

    this.basket.trackingIds.push(new_row);
  }

  generateKey(default_key) {
    return default_key + Math.random().toString(36).substr(2, 9);
  }

  formatDate(date, tstamp = false) {
    let dbDate = new Date(tstamp ? date * 1000 : date);

    return dbDate.getDate() + '.' + (dbDate.getMonth() + 1) + '.' + dbDate.getFullYear();
  }

  close() {
    this.$scope.confirm();
  }

  canSave() {
    // let is_tracking_forms_valid = true;
    // for (let [index, form] of this.forms['tracking_id_forms'].entries()) {
    //   if (form) {
    //     if (this.answers[index] && angular.isUndefined(this.answers[index].tracking_id) && angular.isUndefined(this.answers[index].delivery_service)) {
    //       // form is empty so its ok
    //       if (this.forms['tracking_id_forms'].length === 1) {
    //         is_tracking_forms_valid = is_tracking_forms_valid && false;
    //       }
    //     } else {
    //       is_tracking_forms_valid = is_tracking_forms_valid && form.$valid;
    //     }
    //   }
    // }

    let is_tracking_forms_valid = this.forms['tracking_id_form'].$valid;

    if (this.isPickUpDateChangeAllowed) {
      let is_pickup_date_form_valid = this.forms['pick_up_date_form'].$valid;
      return is_tracking_forms_valid && is_pickup_date_form_valid;
    }

    return is_tracking_forms_valid;
  }

  save() {
    this.loading.saving = true;

    if (this.canSave()) {
      let operation = undefined;
      //TODO: refactor ...

      this.storeTrackingInformation().then(() => {
        this.$timeout(() => {
          this.loading.saving = false;
        });
        this.$scope.confirm();
      }).catch((e) => {
        let error = e.error && e.error.message || '';
        if (error.includes('BEGIN-DATE-CHANGE-NOT-ALLOWED')) {
          this.errors.begin_date_change_not_allowed = true;
        } else if (error.includes('PICK-UP-DATE-IN-THE-FUTURE')) {
          this.errors.future_date_not_allowed = true;
        } else {
          this.errors.general_error = true;
        }

        this.$timeout(() => {
          this.loading.saving = false;
        });

        this.NotificationService.error('could not add tracking number');
      });
    }
  }

  handlePickUpDateChange() {
    this.errors.future_date_not_allowed = false;
    this.errors.begin_date_change_not_allowed = false;
  }

  canSetPickUpDate() {
    return [this.MPP_ORDER_STATUSES_BY_NAME['ready_for_delivery']['id'], this.MPP_ORDER_STATUSES_BY_NAME['sent_by_retailer']['id']].includes(this.lodash.get(this.basket, 'order_process.state_id'));
  }

  //update tracking information and update mpp control process if the state is valid
  storeTrackingInformation() {
    let params = {
      basket_id: this.basket._id,
      tracking_ids: this.basket.trackingIds
    };

    if (this.isPickUpDateChangeAllowed) {
      params.pick_up_date = this.pick_up_date;
    }

    return this.mppService.storeTrackingInformation(params);
  }
}
ready_for_delivery_controller.$inject = $inject;
