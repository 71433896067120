import vbmService from './vbmService';
import CheckoutService from './CheckoutService';

//TODO: refactor
import './vbm.data.service';

angular
  .module('VSPApp')
  .service('vbmService', vbmService)
  .service('CheckoutService', CheckoutService);
