import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectActionsDialogData } from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import {LoadingService} from "../../../../shared/services/loading.service";

@Component({
  selector: 'vn-direct-bonus-on-hold-dialog',
  templateUrl: './direct-bonus-on-hold-dialog.component.html',
  styleUrls: ['./direct-bonus-on-hold-dialog.component.scss']
})
export class DirectBonusOnHoldDialogComponent implements OnInit {

  public get ticketUrl(): FormControl {
    return this.dialogForm.get('ticketUrl') as FormControl;
  }

  public get comment(): FormControl {
    return this.dialogForm.get('comment') as FormControl;
  }

  public get orderId() {
    return this.dialogData.orderId!;
  }

  public dialogForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectBonusOnHoldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private loader: LoadingService
  ) {
    this.initDialogForm()
  }

  ngOnInit(): void {
  }

  public initDialogForm() {
    this.dialogForm = this.formBuilder.group({
      ticketUrl: this.formBuilder.control('', [Validators.required]),
      comment: this.formBuilder.control('', [Validators.required])
    });
  }

  public putOrderOnHold() {
    this.loader.show();
    return this.directBonusService.putOrderOnHold(
      this.orderId,
      this.ticketUrl.value,
      this.comment.value,
    )
      .subscribe(
        (result) => {
          this.loader.hide();
          const message = 'Der Auftrag wurde erfolgreich markiert';
          if (result) {
            this.alertService.message(message);
            this.dialogRef.close(true);
          }
        },
        (error: Error) => {
          this.loader.hide();
          // TODO: Do proer error handling with backend errors;
          this.alertService.error(`Es ist ein fehler aufgetreten.`);
        }
      );
  }
}
