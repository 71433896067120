const $inject = [
  "$q",
  "Made"
];

export default class UserGroupsService {

  constructor($q, Made) {
    this.$q = $q;
    this.Made = Made;
  }

  getEmployeeUserGroups(employee_id) {
    return this.Made.request('rpc://valuenetdb/user/group/get_employee_user_groups', {
      employee_id: employee_id
    });
  }

  getAllUserGroups() {
    return this.Made.request('rpc://valuenetdb/user/group/get_all_user_groups');
  }

  getUserGroups(employee_id) {
    return this.Made.request('rpc://valuenetdb/user/group/get_user_groups', {
      employee_id: employee_id
    });
  }

  updateUserGroups(employee_id, groups) {
    return this.Made.request('rpc://valuenetdb/user/group/update_user_groups', {
      employee_id: employee_id,
      groups: groups
    });
  }

  getPermissionTypes() {
    return this.Made.request('rpc://valuenetdb/user/group/get_permissions_types');
  }
}

UserGroupsService.$inject = $inject;