import {CarLeasingDetailsComponent} from "../../../app/car-leasing/components/car-leasing-details/car-leasing-details.component";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.car-leasing', {
        url: '/car-leasing',
        abstract: true,
        views: {
          main: {
            template: require('../../modules/car-leasing/main.pug'),
            controller: 'CarLeasingController',
            controllerAs: '$ctrl',
          },
          header: {
            template: require('../../helpers/header.main.pug')
          }
        }

      })
      .state('inApp.car-leasing.list', {
        url: '',
        views: {
          carLeasingMain: {
            template: require('../../modules/car-leasing/catalog/index.pug'),
            controller: 'CarLeasingCatalogController',
            controllerAs: '$ctrl',
          },
        }
      })
      .state('inApp.car-leasing.details', {
        url: '/car/:carId',
        params: {
          carId: null
        },
        views: {
          carLeasingMain: {
            component: CarLeasingDetailsComponent
          },
        }
      });

  }]);
