import * as moment from 'moment';
import { Injectable, Inject } from "@angular/core";
import { Moment } from "moment";
import { DirectBonusComponentType } from "./direct-bonus-project-overview-table-data.model";
import { BaseMapper, DirectBonusProjectOverview } from "./direct-bonus-project-overview.model";

export interface OrderInfo {
  month: number;
  year: number;
}

export interface DirectBonusFormData {
  bonusId?: string;
  orderId?: string;
  bonusProjectId?: string;
  employeeId?: number;
  customerId?: number;
  componentName?: DirectBonusComponentType;
  formValue?: number;
  formReason?: string;
  otrsTicket?: string;
  year?: number;
  month?: number;
  period?: string;
}

export interface DirectBonusDates {
  startDate: Moment;
  endDate: Moment;
}

export interface DirectBonusProjectComponentConfiguration {
  enabled: boolean;
  standardValue: number
  maxValue: number;
  componentType?: DirectBonusComponentType
};

export type DirectBonusProjectConfiguration = {
  [key in DirectBonusComponentType]: DirectBonusProjectComponentConfiguration;
};

export class DirectBonus {
  constructor(
    public bonusId: string,
    public bonusProjectId: string,
    public employeeId: number,
    public customerId: number,
    public componentName: DirectBonusComponentType,
    public value: number,
    public dates: DirectBonusDates,
    public componentConfiguration: DirectBonusProjectComponentConfiguration,
    public reason: string
  ) {
  }
}

@Injectable()
export class DirectBonusMapper implements BaseMapper {
  constructor(
  ) { }

  public fromApi(o: any): DirectBonus {

    let directBonusStartDate = moment.utc(o['dates']['start_date']);
    let directBonusEndDate = moment.utc(o['dates']['end_date']);

    const {
      'enabled': enabled,
      'range': {
        'max_eq': maxValue
      },
      'value': standardValue
    } = o['bonus_project_configuration'];

    const componentConfiguration: DirectBonusProjectComponentConfiguration = {
      enabled,
      maxValue,
      standardValue,
    };

    return new DirectBonus(
      o['id'],
      o['bonus_project_id'],
      o['employee_id'],
      o['customer_id'],
      o['component_name'],
      o['value'],
      {
        startDate: directBonusStartDate,
        endDate: directBonusEndDate
      },
      componentConfiguration,
      o['reason']
    );
  }
}

@Injectable()
export class DirectBonusProjectComponentConfigurationMapper implements BaseMapper {
  constructor(
  ) { }

  public fromApi(o: any): DirectBonusProjectComponentConfiguration {
    return {
      enabled: o['is_enabled'],
      maxValue: o['max_value'],
      standardValue: o['default_value'],
      componentType: o['neo_component_id'] as DirectBonusComponentType
    }
  }
}
