import * as moment from 'moment';

export class DticketOrderSide {
  public title: string;
  public brutto_value_month: string;
  public moment;

  isCarLeasing = true; // TODO remove when using real start/end dates

  constructor(brutto_value_month: string, title: string = 'D Ticket'){
    this.title = title;
    this.brutto_value_month = brutto_value_month;
    this.moment = moment;
  }

  perMonthValue() {
    return this.brutto_value_month;
  }

  getContractStartDate(format: string) {
    return this.moment().format(format); // TODO add real begin date
  }

  getContractEndDate(format: string) {
    return this.moment().format(format); // TODO add real end date
  }

}
