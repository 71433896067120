import {COMPONENTS_WITH_DOCUMENTS_BY_ID} from '../../../services/customerService';
import {
  PC_BONUS_COMPONENT_NAMES,
  BONUS_COMPONENTS_WITHOUT_CONTRACT_PREVIEW,
  BONUS_CHECKOUT_TOC_CONFIG,
} from '../../../services/BonusService';
import employeeService from "../../../services/employeeService";
import ComponentConstants from "../../../constants/ComponentConstants";
import {BONUS_STATES_BY_NAME} from "../../../services/bonus/bonus_state";
import {CHECKOUT_TYPES_BY_NAME} from "../../../services/bonus/checkout_types";
import {formatDateToYYYYMMDD} from 'src/app/shared/common';

const $inject = [
  '$scope',
  '$state',
  'userService',
  'employeeService',
  'vbmData',
  'Made',
  'WizardHandler',
  'componentsService',
  '$timeout',
  'BonusService',
  'NotificationService',
  'administrationService',
  '$window',
  '$http',
  'moment',
  'lodash',
  'authenticationService',
  'DirectBonusService',
  'mppService',
  'ComponentConstants'
];

export class BonusCheckoutController {
  constructor(
    $scope,
    $state,
    userService,
    employeeService,
    vbmData,
    Made,
    WizardHandler,
    componentsService,
    $timeout,
    BonusService,
    NotificationService,
    administrationService,
    $window,
    $http,
    moment,
    lodash,
    authenticationService,
    DirectBonusService,
    mppService,
    ComponentConstants
  ) {
    Object.assign(this, {
      $scope,
      $state,
      userService,
      employeeService,
      vbmData,
      Made,
      WizardHandler,
      componentsService,
      $timeout,
      BonusService,
      NotificationService,
      administrationService,
      $window,
      $http,
      moment,
      lodash,
      authenticationService,
      DirectBonusService,
      mppService,
      ComponentConstants
    });

    this.acceptedTocs = {};

    this.loading = {
      summary: true,
      gather_documents: true,
      user_info: true,
      contract_summary: true,
      finalize_checkout: false,
      contract_preview: true
    };
    // sent to inner components to gather errors
    this.errors = {
      doesNotIncludeBlockedComponents: true,
      sachbezugValidCheckoutDate: true
    };

    this.forms = {};
    this.contactInfo = {};

    this.initSummary()
      .finally(() => {
        this.$timeout(() => {
          this.loading.summary = false;
          if (this.overlappingComponents)
            this.errors.doesNotIncludeBlockedComponents = this.overlappingComponents.length === 0;
        });
      });

    // must be on scope in order to have access to CTRL when called by the Wizard Directive
    this.$scope.canEnterGatherDocuments = () => {
      this.loading.gather_documents = true;
      this.initGatherDocuments().finally(() => {
        this.$timeout(() => {
          this.loading.gather_documents = false;
        }, 0);
      });
      return true;
    };

    this.$scope.canEnterUserInfo = () => {
      this.loading.user_info = true;
      this.initUserInfo().finally(() => {
        this.$timeout(() => {
          this.loading.user_info = false;
          this.$timeout(() => {
            if (this.isContactInfoMandatory()) {
              this.initUserInfoForm();
            }
          }, 0);
        }, 0);
      });
      return true;
    };

    this.$scope.canEnterContractPreview = () => {
      this.loading.contract_preview = true;

      this.initContractPreview().then((has_contract) => {
        this.$timeout(() => {
          this.loading.contract_preview = false;
        });
        if (!has_contract) {
          this.proceed();
        }
      });

      return true;
    };

    this.$scope.canEnterContractSummary = () => {
      this.loading.contract_summary = true;
      this.initContractPreview().finally(() => {
        this.$timeout(() => {
          this.loading.contract_summary = false;
        }, 0);
      });

      return true;
    };
  }

  hasFreistellungstagInCheckout() {
    return !!Object.keys(this.getCurrentCheckouts()).find(component_name => component_name === 'Freistellungstag');
  }

  isAddressRequired() {
    this.address_step_disabled = true;
    this.is_address_required_for_future_checkout = false;
    for (let component_name of Object.keys(this.getCurrentCheckouts())) {
      for (let bonus of this.employee_bonuses) {
        if (bonus.component[component_name] && (bonus.component[component_name].requires_address === true)) {
          this.address_step_disabled = false;
          break;
        }
      }
    }

    for (let component_name of Object.keys(this.getFutureCheckouts())) {
      for (let bonus of this.employee_bonuses) {
        if (bonus.component[component_name] && (bonus.component[component_name].requires_address === true)) {
          this.address_step_disabled = false;
          this.is_address_required_for_future_checkout = true;
          break;
        }
      }
    }

    return this.address_step_disabled;
  }

  getFutureCheckoutPCBasketId() {
    let future_checkouts = this.getFutureCheckouts();
    let basket_id = null;

    // the employee can't have more than one pc component in the employee_checkout_config
    PC_BONUS_COMPONENT_NAMES.forEach((pc_component_name) => {
      if (this.lodash.has(future_checkouts, pc_component_name)) {
        basket_id = this.lodash.get(future_checkouts, `${pc_component_name}.cart._id`);
      }
    });
    return basket_id;
  }

  getCurrentCheckoutPCBasketId() {
    let current_checkouts = this.getCurrentCheckouts();
    let basket_id = null;

    // the employee can't have more than one pc component in the employee_checkout_config
    PC_BONUS_COMPONENT_NAMES.forEach((pc_component_name) => {
      if (this.lodash.has(current_checkouts, pc_component_name)) {
        basket_id = this.lodash.get(current_checkouts, `${pc_component_name}.cart._id`);
      }
    });
    return basket_id;
  }

  getFutureCheckoutPCComponent() {
    let pc_component = false;

    let future_checkouts = this.getFutureCheckouts();

    for (let component_name in future_checkouts) {
      if (PC_BONUS_COMPONENT_NAMES.includes(component_name)) {
        return future_checkouts[component_name];
      }
    }

    return pc_component;
  }

  getCurrentCheckoutPCComponent() {
    let pc_component = false;

    let current_checkouts = this.getCurrentCheckouts();

    for (let component_name in current_checkouts) {
      if (PC_BONUS_COMPONENT_NAMES.includes(component_name)) {
        return current_checkouts[component_name];
      }
    }

    return pc_component;
  }

  hasCurrentCheckoutPCComponent() {
    return !!this.getCurrentCheckoutPCComponent();
  }

  hasFutureCheckoutPCComponent() {
    return !!this.getFutureCheckoutPCComponent();
  }

  async handleUserInfoFormSubmit() {
    let address = {
      ort: this.employee_address.city,
      plz: this.employee_address.zip,
      strasse: this.employee_address.street
    };

    const contactInfo = {
      email_address: this.contactInfo.emailAddress,
      phone_number: this.contactInfo.phoneNumber,
    }
    const currentPCComponent = this.getCurrentCheckoutPCComponent();
    if (currentPCComponent) {
      const basketId = currentPCComponent['cart']['_id'];
      await this.mppService.addContactInfo(basketId, contactInfo);
    }

    const futurePCComponent = this.getFutureCheckoutPCComponent();
    if (futurePCComponent) {
      const basketId = futurePCComponent['cart']['_id'];
      await this.mppService.addContactInfo(basketId, contactInfo);
    }

    await this.employeeService.updateAddressData({address: address}, this.Made.user.valuenet_id)
    this.WizardHandler.wizard().next();
  }

  async generateContractPreview(components_for_contract) {
    let employeeId = this.employee_id;
    let bonusIds = [];
    let components_by_bonus_id = components_for_contract.reduce((acc, component_checkout_config) => {
      let component_checkout_type = CHECKOUT_TYPES_BY_NAME[component_checkout_config.checkout_type];
      acc[component_checkout_config.bonus_id] = component_checkout_config;
      if (component_checkout_type['name'] === CHECKOUT_TYPES_BY_NAME['current']['name']) {
        bonusIds.push(component_checkout_config.bonus_id);
      }
      return acc;
    }, {});

    let data = await this.BonusService.generateBonusCheckoutContractByBonusId(
      employeeId,
      undefined,
      bonusIds,
      components_by_bonus_id
    );

    this.contract_url = data.response;
  }

  async initContractPreview() {
    let current_components_checkout_configs = this.getCurrentCheckouts();
    let future_components_checkout_configs = this.getFutureCheckouts();
    // we assume there is no duplicate
    let to_be_checkout_components_checkout_config = this.lodash.merge({}, current_components_checkout_configs, future_components_checkout_configs);

    let components_for_contract = this.lodash.filter(Object.values(to_be_checkout_components_checkout_config), (component_checkout_config) => {
      if (!BONUS_COMPONENTS_WITHOUT_CONTRACT_PREVIEW.includes(component_checkout_config.component_name)) {
        return component_checkout_config;
      }
    });

    if (components_for_contract.length > 0) {
      await this.generateContractPreview(components_for_contract);
      return true;
    }
    return false;
  }

  isAdditionalTOCRequired() {
    this.additional_toc_step_disabled = true;
    this.additional_toc_component = null;
    this.additional_toc_checkout_type = null;
    for (let component_name of Object.keys(this.getCurrentCheckouts())) {
      for (let bonus of this.employee_bonuses) {
        if (bonus.component[component_name] && (bonus.component[component_name].configuration.toc.requires_additional_toc === true)) {
          this.additional_toc_step_disabled = false;
          this.additional_toc_component = component_name;
          this.additional_toc_checkout_type = CHECKOUT_TYPES_BY_NAME['current']['key'];
          break;
        }
      }
    }

    for (let component_name of Object.keys(this.getFutureCheckouts())) {
      for (let bonus of this.employee_bonuses) {
        if (bonus.component[component_name] && (bonus.component[component_name].configuration.toc.requires_additional_toc === true)) {
          this.additional_toc_step_disabled = false;
          this.additional_toc_component = component_name;
          this.additional_toc_checkout_type = CHECKOUT_TYPES_BY_NAME['future']['key'];
          break;
        }
      }
    }

    return this.additional_toc_step_disabled;
  }

  isSummaryValid() {
    const hasComponents = this.hasComponents();
    const hasErrors = Object.keys(this.errors).reduce((acc, error_key) => {

      let has_error = this.errors[error_key] === false;

      // if I am special advisor this error is not a problem VN-1182
      if (error_key === 'mppPriceError' && this.iAmSpecialAdvisor) {
        has_error = false;
      }

      acc = acc || has_error;
      return acc;
    }, false);

    return hasComponents && !hasErrors;
  }

  async initUserInfo() {
    this.employee_address = await this.employeeService.getAddress(this.employee_id);

    this.userPrivateInfo = {
      email: undefined,
      phone: undefined
    };
  }

  async initSummary() {
    console.log('BOnusCheckoutController')
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.bonus_project_id = this.$state.params.bonusProjectId;
    this.employee_id = this.Made.user.valuenet_id;
    this.customer_id = await this.employeeService.getCustomerId();
    this.tocMatchedModules = {}
    this.employee = this.vbmData.employees[this.employee_id];
    this.employee_checkout_config = this.employee.bonus ? this.employee.bonus[this.bonus_project_id] : undefined;

    this.employee_bonuses = await this.BonusService.getBonusesForEmployee({
      customer_id: this.customer_id,
      bonus_project_id: this.bonus_project_id,
      employee_id: this.employee_id,
      to_attach_checkout_validations: true
    });
    this.employee_bonuse_by_component_name = this.employee_bonuses.reduce((acc, bonus) => {
      acc[this.BonusService.getBonusComponentName(bonus)] = bonus;
      return acc;
    }, {});

    const current_components_key = CHECKOUT_TYPES_BY_NAME['current']['key'];
    const future_components_key = CHECKOUT_TYPES_BY_NAME['future']['key'];
    let min_begin_date = undefined;


    // remove 0 value components
    Object.keys(this.employee_checkout_config[current_components_key]).forEach(component_name => {
      // let available_statuses = [BONUS_STATES_BY_NAME['available']['id'], BONUS_STATES_BY_NAME['declined']['id']];
      let bonus = this.employee_bonuse_by_component_name[component_name];
      let is_component_in_use = this.BonusService.isComponentUsed(bonus);
      let is_component_expired = this.BonusService.isComponentExpired(bonus);
      if (this.employee_checkout_config[current_components_key][component_name].value === 0 || (is_component_in_use && !is_component_expired)) {
        delete this.employee_checkout_config[current_components_key][component_name];
      } else {
        if (!min_begin_date || this.moment(min_begin_date).isAfter(this.employee_checkout_config[current_components_key][component_name]['bonus_start_date'])) {
          min_begin_date = this.employee_checkout_config[current_components_key][component_name]['bonus_start_date'];
        }
      }
    });

    // remove 0 value components
    Object.keys(this.employee_checkout_config[future_components_key]).forEach(component_name => {
      // let available_statuses = [BONUS_STATES_BY_NAME['available']['id'], BONUS_STATES_BY_NAME['declined']['id']];
      let is_component_in_use = this.BonusService.isComponentUsed({state: this.employee_checkout_config[future_components_key][component_name]['bonus_state']});
      if (this.employee_checkout_config[future_components_key][component_name].value === 0 || is_component_in_use) {
        delete this.employee_checkout_config[future_components_key][component_name];
      } else {
        if (!min_begin_date || this.moment(min_begin_date).isAfter(this.employee_checkout_config[current_components_key][component_name]['bonus_start_date'])) {
          min_begin_date = this.employee_checkout_config[current_components_key][component_name]['bonus_start_date'];
        }
      }
    });

    const overlappingComponents = await this.BonusService.getOverlappingComponents(this.employee_id, this.employee_checkout_config, this.bonus_project_id);
    this.overlappingComponents = overlappingComponents.map((c) => this.ComponentConstants[c]['title']).join(',');
    this.step = this.WizardHandler.wizard().currentStepNumber;
    this.steps = this.WizardHandler.wizard().totalStepCount;

    for (const [key, compConfig] of Object.entries(this.employee_checkout_config.components)) {
      if (key.includes('sachbezug')) {
        this.bonus_project = await this.BonusService.getBonusProject(this.customer_id, this.bonus_project_id);
        const res = await this.Made.request('rpc://valuenetdb/bonus/project/sachbezug_check_checkout_date_is_valid', {
          checkout_date: this.employee_checkout_config.session.created_at,
          bonus_project: this.bonus_project,
        });
        this.errors.sachbezugValidCheckoutDate = res;
      }
    }

    if (this.employee_checkout_config && this.employee_checkout_config.components) {
      const components = Object.keys(this.employee_checkout_config.components);
      components.forEach(c => {
        let bonusCheckoutType = c;
        if (['handy', 'festnetz'].includes(bonusCheckoutType)) {
          bonusCheckoutType = 'telekom';
        } else if (c.includes('essensschecks')) {
          bonusCheckoutType = 'essensschecks'
        } else if (c.includes('pc')) {
          bonusCheckoutType = 'pc_leasing'
        }
        if (BONUS_CHECKOUT_TOC_CONFIG[bonusCheckoutType] && !this.tocMatchedModules[bonusCheckoutType]) {
          this.tocMatchedModules[bonusCheckoutType] = BONUS_CHECKOUT_TOC_CONFIG[bonusCheckoutType];
        }
      });
      this.tocsInCheckout = Object.keys(this.tocMatchedModules).length
      this.tocStepExists = !!this.tocsInCheckout;
      for (const [key, value] of Object.entries(this.tocMatchedModules)) {
        this.acceptedTocs = {
          ...this.acceptedTocs,
          [key]: false
        }
      }
    }

    console.log("accaptedTocs", this.acceptedTocs);
    console.log("tocMatchedModules", this.tocMatchedModules);
  }

  getCurrentCheckoutPCLeasingReport() {
    let current_checkouts = this.getCurrentCheckouts();
    let report = null;
    // the employee can't have more than one pc component in the employee_checkout_config
    PC_BONUS_COMPONENT_NAMES.forEach((pc_component_name) => {
      if (this.lodash.has(current_checkouts, `${pc_component_name}.future_data`)) {
        report = this.lodash.get(current_checkouts, `${pc_component_name}.future_data`);
      }
    });

    return report;
  }

  getFutureCheckoutPCLeasingReport() {
    let future_checkouts = this.getFutureCheckouts();
    let report = null;
    // the employee can't have more than one pc component in the employee_checkout_config
    PC_BONUS_COMPONENT_NAMES.forEach((pc_component_name) => {
      if (this.lodash.has(future_checkouts, `${pc_component_name}.future_data`)) {
        report = this.lodash.get(future_checkouts, `${pc_component_name}.future_data`);
      }
    });

    return report;
  }

  isPayedByBudget(component_config) {
    return this.BonusService.isPayedByBudget(component_config);
  }

  unixToDate(unix_timestamp) {
    return this.BonusService.unixToDate(unix_timestamp);
  }

  async initGatherDocuments() {
    this.isAddressRequired();
    this.isAdditionalTOCRequired();


    const COMPONENTS_REQUIRING_DOCUMENTS = await this.BonusService.getComponentsRequiringExtraDocuments();

    // get all of his bonuses and create a map COMPONENT_ID -> [BONUS_IDs] for easier tagging
    this.bonus_ids = [];
    this.checkout_bonuses = this.employee_bonuses.reduce((acc, bonus) => {
      let bonus_id = bonus._id;

      // create structure
      angular.forEach(bonus.component, (component_config, component_key) => {
        if (!acc[component_config.neo_component_id]) {
          acc[component_config.neo_component_id] = [];
        }
        acc[component_config.neo_component_id].push(bonus_id);
      });

      // note bonus_id for later
      this.bonus_ids.push(bonus_id);

      return acc;
    }, {});

    // create structure to handle file upload
    const current_checkouts = this.getCurrentCheckouts();
    let current_checkouts_components_requiring_documents = Object.keys(current_checkouts).filter((neo_component_id) => {
      return COMPONENTS_REQUIRING_DOCUMENTS.includes(neo_component_id);
    })
      .reduce((acc, neo_component_id) => {
        acc[neo_component_id] = {
          checkout_type: CHECKOUT_TYPES_BY_NAME['current']['name'],
          documents: [], // where the files are uploaded
          for_bonuses: [current_checkouts[neo_component_id]['bonus_id']], // for which bonuses is this file
          texts: COMPONENTS_WITH_DOCUMENTS_BY_ID[neo_component_id] // texts to be displayed in the widget
        };
        return acc;
      }, {});

    const future_checkouts = this.getFutureCheckouts();
    let future_checkouts_components_requiring_documents = Object.keys(future_checkouts).filter((neo_component_id) => {
      return COMPONENTS_REQUIRING_DOCUMENTS.includes(neo_component_id);
    })
      .reduce((acc, neo_component_id) => {
        acc[neo_component_id] = {
          checkout_type: CHECKOUT_TYPES_BY_NAME['future']['name'],
          documents: [], // where the files are uploaded
          for_bonuses: [future_checkouts[neo_component_id]['bonus_id']], // for which bonuses is this file
          texts: COMPONENTS_WITH_DOCUMENTS_BY_ID[neo_component_id] // texts to be displayed in the widget
        };
        return acc;
      }, {});

    // we assume there could not be a future and current checkout for the same component
    this.components_requiring_documents = Object.assign({}, current_checkouts_components_requiring_documents, future_checkouts_components_requiring_documents);

  }

  proceed() {
    this.WizardHandler.wizard().next();
  }

  areDocumentsValid() {
    for (let component in this.components_requiring_documents) {
      let isArray = angular.isArray(this.components_requiring_documents[component].documents);

      if (!isArray || (isArray && this.components_requiring_documents[component].documents.length === 0)) {
        return false;
      }
    }

    return true;
  }

  getComponentKeysRequiringDocuments() {
    if (this.components_requiring_documents) {
      return Object.keys(this.components_requiring_documents);
    } else {
      return [];
    }
  }

  wizardFinished() {
    // do the checkout
    this.loading.finalize_checkout = true;
    // set completed_at
    this.employee_checkout_config.session.completed_at = this.moment.utc().unix();

    // set the ID to be equal to the complated_at to optimize dokument showing
    this.employee_checkout_config.session.id = this.employee_checkout_config.session.completed_at;
    // note session_id for functionality which can not handle the full session object
    let checkout_session_id = this.employee_checkout_config.session.id;

    this.BonusService.completeCheckout(this.employee_id, this.employee_checkout_config, this.bonus_project_id).then(async (res) => {
      let contract_url = res.contract_url;
      if (contract_url) {
        this.$window.open(contract_url.response, '_blank');
      }

      // override bonus_ids if there was a previous or future checkout
      let checkedout_bonuses_component_names_to_bonus_ids_map = res.checkedout_bonuses_component_names_to_bonus_ids_map;
      angular.forEach(this.components_requiring_documents, (component_config, component_key) => {
        component_config.for_bonuses = [checkedout_bonuses_component_names_to_bonus_ids_map[component_key]];
      });

      await this.BonusService.uploadDocuments(this.components_requiring_documents, this.bonus_project_id, checkout_session_id);

      this.loading.finalize_checkout = false;
      this.NotificationService.message('Vielen Dank!');
      this.navigateToDashboard(true);
    }).catch((err) => {
      this.loading.finalize_checkout = false;
      this.NotificationService.error('Fehlgeschlagen! Bitte versuchen sie es erneut.');
      // this.navigateToDashboard(true);

    }).finally(() => {
      this.$timeout(() => {
      });
    });
  }

  wizardCanceled() {
    this.navigateToDashboard();
  }

  navigateToDashboard(reload = false) {
    this.$state.go('inApp.bonus.dashboard.main', {bonusProjectId: this.bonus_project_id}, {reload: reload});
  }

  hasComponents() {
    return this.hasCurrentCheckouts() || this.hasFutureCheckouts();
  }

  hasCurrentCheckouts() {
    return Object.keys(this.getCurrentCheckouts()).length > 0;
  }

  hasFutureCheckouts() {
    return Object.keys(this.getFutureCheckouts()).length > 0;
  }

  getFutureCheckouts() {
    if (!this.employee_checkout_config) {
      return {};
    } else {
      return this.employee_checkout_config[CHECKOUT_TYPES_BY_NAME['future']['key']];
    }
  }

  getCurrentCheckouts() {
    if (!this.employee_checkout_config) {
      return {};
    } else {
      return this.employee_checkout_config[CHECKOUT_TYPES_BY_NAME['current']['key']];
    }
  }

  getComponentsTotalValue() {
    let total_value = 0;
    let current_checkouts = this.getCurrentCheckouts();
    Object.keys(current_checkouts).forEach(key => {
      total_value += current_checkouts[key].value;
    });

    let future_checkouts = this.getFutureCheckouts();
    Object.keys(future_checkouts).forEach(key => {
      total_value += future_checkouts[key].value;
    });

    return total_value;
  }

  getComponentsNettoSum() {
    let netto_sum = 0;
    let current_checkouts = this.getCurrentCheckouts();
    Object.keys(current_checkouts).forEach(key => {
      netto_sum += current_checkouts[key].netto_value || current_checkouts[key].value || 0;
    });

    let future_checkouts = this.getFutureCheckouts();
    Object.keys(future_checkouts).forEach(key => {
      netto_sum += future_checkouts[key].netto_value || future_checkouts[key].value || 0;
    });
    return netto_sum;
  }

  getTotalFees() {
    let total_fees = 0;
    let current_checkouts = this.getCurrentCheckouts();
    Object.keys(current_checkouts).forEach(key => {
      total_fees += current_checkouts[key].tax_value || 0;
    });

    let future_checkouts = this.getFutureCheckouts();
    Object.keys(future_checkouts).forEach(key => {
      total_fees += future_checkouts[key].tax_value || 0;
    });

    return total_fees;
  }

  updateEmailAddress(emailAddress) {
    if (emailAddress) {
      this.forms.bonusCheckoutUserInfoForm.$setValidity('emailValid', true);
      this.contactInfo.emailAddress = emailAddress;
    } else {
      this.forms.bonusCheckoutUserInfoForm.$setValidity('emailValid', false);
    }
    this.userPrivateInfo.email = emailAddress;
  }

  updatePhoneNumber(phoneNumber) {
    if (phoneNumber) {
      this.forms.bonusCheckoutUserInfoForm.$setValidity('phoneValid', true);
      this.contactInfo.phoneNumber = phoneNumber;
    } else {
      this.forms.bonusCheckoutUserInfoForm.$setValidity('phoneValid', false);
    }
    this.userPrivateInfo.phone = phoneNumber;
  }

  initUserInfoForm() {
    this.forms.bonusCheckoutUserInfoForm.$setValidity('phoneValid', false);
    this.forms.bonusCheckoutUserInfoForm.$setValidity('emailValid', false);
  }

  isContactInfoMandatory() {
    return this.hasCurrentCheckoutPCComponent() ||
      this.hasFutureCheckoutPCComponent()
  }

  allTocsAccepted() {
    const tocKeys = Object.keys(this.acceptedTocs)
    if (tocKeys.length === 0) {
      return false
    }
    let valid = true
    tocKeys.forEach(tocKey => {
      valid = valid && this.acceptedTocs[tocKey];
    });

    return valid;
  }
}

BonusCheckoutController.$inject = $inject;
