import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { BonagoBasketItem, BonagoBasketListItem } from '../models/benefit-bonago.model';
import { BenefitsBonagoController } from './benefits-bonago.controller';
import { BenefitsBonagoService } from './benefits-bonago.service';

@Injectable()
export class BenefitsBonagoBasketService {

  private $basket = new BehaviorSubject<BonagoBasketItem[]>([]);
  public basket$ = this.$basket.asObservable();

  public areSelectedVouchersAvailable: boolean = true;

  public basketSum$: Observable<number> = this.basket$.pipe(
    map(bonagoBasket => bonagoBasket.reduce(
      (sum, item) => {
        return sum + item.quantity * item.amount;
      },
      0
    ))
  );

  public basketListItems$: Observable<BonagoBasketListItem[]> = this.basket$.pipe(
    map(bonagoBasket => bonagoBasket.map(
      item => ({
        retailerId: item.retailer.id,
        voucherId: item.voucherId,
        voucherSheetInformation: `${item.quantity} x ${item.retailer.title} (${item.amount}€)`,
        voucherCheckoutInformation: `${item.quantity} x ${item.retailer.title}`,
        totalValue: item.quantity * item.amount,
        itemValue: item.amount,
        quantity: item.quantity,
        isAvailable: item.isAvailable,
      })
    ))
  );

  public userBalance$$ = this.controller.getUserBalance$$;

  public restBalance$ = this.userBalance$$.pipe(
    switchMap(
      userBalance => this.basketSum$.pipe(
        map(basketSum => userBalance - basketSum)
      )
    )
  );

  constructor(
    private controller: BenefitsBonagoController,
  ) { }

  public addToBasket(newItem: BonagoBasketItem) {
    const currentBasket = this.getBasket();
    let sameVoucherExists = false;

    let newBasket = currentBasket.map(item => {
      if(item.voucherId === newItem.voucherId) {
        item['quantity'] += newItem.quantity;
        sameVoucherExists = true
      }
      return item
    })

    if(!sameVoucherExists) {
      newBasket = [
        ...newBasket,
        newItem
      ]
    }

    this.$basket.next(newBasket);
    this.controller.$userBalance.next(0);
  }

  public removeFromBasket(basketItemIdx: number) {
    let currentBasketItems = this.getBasket();
    currentBasketItems.splice(basketItemIdx, 1);

    this.$basket.next([
      ...currentBasketItems,
    ]);

    this.controller.$userBalance.next(0);
  }

  public clearBasket() {
    this.$basket.next([]);

    this.controller.$userBalance.next(0);
  }

  public getBasket() {
    return this.$basket.value;
  }

  public setBasketItemAvailability(voucherId: string, isAvailable: boolean) {
    let currentBasketItems = this.getBasket();

    for(const item of currentBasketItems) {
      if(item.voucherId === voucherId) {
        item['isAvailable'] = isAvailable;
      }
    }

    this.$basket.next([
      ...currentBasketItems,
    ]);
  }

  public checkSelectedVouchersAvailability() {
    const basketItems = this.$basket.value.map(item => {
      return {
        voucherId: item.voucherId, 
        retailerId: item.retailer.id,
      }
    });

    return basketItems.map(item => {
      const sub: any = this.controller.getBonagoRetailer$(item.retailerId)
        .pipe(finalize(() => sub.unsubscribe()))
        .subscribe(retailer => {
            const isAvailable = retailer.vouchers.filter(voucher => voucher.id = item.voucherId)
            if(!isAvailable) {
              this.areSelectedVouchersAvailable = false;
            }
          })
    });


  }
}