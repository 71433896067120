import header from '../../helpers/header.main.pug';
import versicherung_template from '../../modules/versicherung/versicherung.pug';

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.versicherung', {
        url: '/versicherung',
        views: {
          main: {
            template: versicherung_template,
            controller: 'VersicherungController',
            controllerAs: 'versicherung'
          },
          header: {
            template: header
          }
        }
      });

  }]);
