import './donau-isar/branding.config';
import './edeka/branding.config';
import './fondsfinanz/branding.config';
import './hertz/branding.config';
import './rafi/branding.config';
import './signal-iduna/branding.config';
import './klinikum-niederlausitz/branding.config';
import './glasshuette/branding.config';
import './aracom/branding.config';
import './audi-kloeker/branding.config';
import './lecos/branding.config';
