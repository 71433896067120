

import modules_html from '../../modules/vcp/moduleselect/modules.pug';
import vcp_html from '../../modules/vcp/vcp.pug';
import header_vcp_html from '../../modules/vcp/header.vcp.pug';
import side_side_html from '../../modules/side/side.pug';
import list_html from '../../modules/vcp/list/list.pug';
import zentrale_html from '../../modules/vcp/zentrale/zentrale.pug';
import zentrale_side_html from '../../modules/vcp/zentrale/side.pug';
import admin_html from '../../modules/vcp/admin/admin.pug';
import admin_side_html from '../../modules/vcp/admin/side.pug';
import location_html from '../../modules/vcp/location/location.pug';
import vcp_side_html from '../../modules/vcp/vcp.side.pug';

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    const authorized = ['authenticationService', function (authenticationService) {
      authenticationService.iMustBe([
        'vcp_location',
        'vcp_central',
        'vcp_administration',
        'admin'
      ]);
    }];

    $stateProvider
      .state('blank.moduleselect', {
        url: '/modselect',
        resolve: {
          authorized: authorized
        },
        template: modules_html,
        controller: 'ModuleSelectController',
      })
      .state('inApp.vcp', {
        resolve: {
          authorized: authorized
        },
        views: {
          main: {
            template: vcp_html,
          },
          header: {
            template: header_vcp_html
          }
        }
      })
      .state('inApp.vcp.side', {
        views: {
          vcpMain: {
            template: side_side_html
          }
        }
      })
      .state('inApp.vcp.side.list', {
        views: {
          sideMain: {
            template: list_html,
            controller: 'ListController'
          }
        }
      })
      .state('inApp.vcp.side.list.zentrale', {
        url: '/zentrale',
        views: {
          listMain: {
            template: zentrale_html,
            controller: 'ZentraleController'
          },
          'side@inApp.vcp.side': {
            template: zentrale_side_html,
            controller: 'VCPSideController'
          }
        }
      })
      .state('inApp.vcp.side.list.admin', {
        url: '/admin',
        views: {
          listMain: {
            template: admin_html,
            controller: 'AdminController'
          },
          'side@inApp.vcp.side': {
            template: admin_side_html,
            controller: 'VCPSideController'
          }
        }
      })
      .state('inApp.vcp.side.list.location', {
        url: '/location',
        views: {
          listMain: {
            template: location_html,
            controller: 'LocationController'
          },
          'side@inApp.vcp.side': {
            template: vcp_side_html,
            controller: 'VCPSideController'
          }
        }
      });

  }]);
