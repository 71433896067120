const $inject = [
  '$scope',
  '$state',
  'mppService'
];
export default class cancelCheckoutMPPController {

  constructor(
    $scope,
    $state,
    mppService
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.mppService = mppService;

    this.finalized = this.$scope.ngDialogData.finalized;
    this.employee = this.$scope.ngDialogData.employeeId;
    this.basketId = this.$scope.ngDialogData.basketId;
  }

  cancel() {
    this.$scope.confirm();
    location.reload();
  }

  definalize() {
    this.mppService
      .reOpenMPP(this.employee, this.basketId)
      .then(() => {
        this.$scope.confirm();
        location.reload();
      });
  }

  withFiles() {
    this.mppService
      .resetToSaved(this.employee, this.basketId)
      .then(() => {
        this.$scope.confirm();
        location.reload();
      });
  }

}
cancelCheckoutMPPController.$inject = $inject;
