import { Injectable } from '@angular/core';

@Injectable()
export default class CarAdvantageFuelMapper {

  public fromApi(carAdvantageFuel: any): object {
    const allowedFuelAmounts = carAdvantageFuel.allowed_fuel_bonus_by_fuel_and_charger_availability;
    const hybridAmounts = allowedFuelAmounts['FuelType.PLUG_IN_HYBRID'];
    const electricAmounts = allowedFuelAmounts['FuelType.ELECTRIC'];

    return {
      hybrid: {
        true: hybridAmounts['ElectricChargerAvailability.AVAILABLE'],
        false: hybridAmounts['ElectricChargerAvailability.UNAVAILABLE'],
      },
      electric: {
        true: electricAmounts['ElectricChargerAvailability.AVAILABLE'],
        false: electricAmounts['ElectricChargerAvailability.UNAVAILABLE'],
      }
    };
  }
}
