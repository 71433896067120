import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";


export interface AlertComponentConfig {
  data?: object,
  duration? : number,
  panelClass?: string[]
}
@Injectable()
export class AlertService {
  private default: { checkoutSession: (error: any) => void };

  constructor(private snackBar: MatSnackBar) {
    // TODO this is code from the old notification service, was included for backwards compatibility and needs to be refactored as a public method
    this.default = {
      checkoutSession: (error:any) => {
        if (error && error.invalid === 'unfinished checkout') {
          this.error('Während einer offenen Beantragung können diese Daten nicht verändert werden.')
        }
      }
    }
  }

  public message(message: string, action?: string) {
    let actionButton: string = action ? action : 'OK';
    let config: AlertComponentConfig = {};
    config.panelClass = ['blue-snackbar'];

    this.snackBar.open(message, actionButton, config);
  }

  public error(message: string, action?: string) {
    let actionButton: string = action ? action : 'OK';
    let config: AlertComponentConfig = {};
    config.panelClass = ['error-snackbar'];
    this.snackBar.open(message, actionButton, config)
  }

  public warning(message: string, action?: string) {
    let actionButton: string = action ? action : 'OK';
    let config: AlertComponentConfig = {};
    config.panelClass = ['warning-snackbar'];
    this.snackBar.open(message, actionButton, config)
  }

  public getSnackBar(): MatSnackBar {
    return this.snackBar;
  }
}
