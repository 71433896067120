angular
  .module('VSPApp')
  .controller('AdminController', ['$scope', 'vcpModel', 'VCPBonusService', 'MonthsService', function ($scope, vcpModel, VCPBonusService, MonthsService) {

    MonthsService.resetMonth();

    vcpModel.getData = function () {

      vcpModel
        .initialize(
          MonthsService.year,
          MonthsService.month
        )
        .then(vcpModel.getCluster)
        .then(function () {
          vcpModel.fillCluster();
          vcpModel.getClusterWithAntraege();
        });

      VCPBonusService
        .getOverview();
    };

    vcpModel.getData();

    $scope.vcpModel = vcpModel;
    $scope.currentCity = '';
    $scope.currentPerson = '';

    $scope.getApprovedAmount = VCPBonusService.getApprovedAmount;
    $scope.getOpenAmount = VCPBonusService.getOpenAmount;

    $scope.toggleCity = function (city) {
      if ($scope.currentCity === city.name) {
        $scope.currentCity = '';
      } else {
        $scope.currentCity = city.name;
        vcpModel
          .getCityEmployeesWithBonus(city);
      }
    };

  }]);
