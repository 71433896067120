import entfernung from './calculators/entfernung';
import besonderer_anlass from './calculators/besonderer_anlass';
import essenscheck from './calculators/essenscheck';
import VMA_PST from './calculators/VMA_PST';
import VMA_STSVFREI from './calculators/VMA_STSVFREI';
import EBike from './calculators/EBike';
import werbeflaeche from './calculators/werbeflaeche';
import internet from './calculators/internet';
import erholungsbeihilfe from './calculators/erholungsbeihilfe';
//import Bikeleasing from './calculators/Bikeleasing';

const $inject = []
export default class neoCalculateService {

  constructor() {

    this.calculators = {
      entfernung,
      besonderer_anlass,
      essenscheck,
      VMA_PST,
      VMA_STSVFREI,
      EBike,
      werbeflaeche,
      internet,
      erholungsbeihilfe,
    };

  }
}
neoCalculateService.$inject = $inject;
