<div>

  <div class="cockpit-panel">
    <div class="cockpit-panel__title">
      {{countResults}} Arbeitnehmer gefunden. Bitte mindestens einen Filter angeben
    </div>
    <div class="cockpit-panel__body employee-filters-containter">

      <div class="row">
        <h1>DeutschlandTicket JOB</h1>
      </div>

      <form [formGroup]="formGroup" (ngSubmit)="onClickSubmit(formGroup.value, $event)">
        <div class="row">
          <div class="columns small-6 filter-input">
            <vn-input formControlName='employeeId' key="employeeID" label="ValueNet ID">
            </vn-input>
          </div>
          <div class="columns small-6 filter-input">
            <vn-input formControlName='customerId' key="customerID" label="Customer ID">
            </vn-input>
          </div>
          <div class="columns small-6 filter-input">
            <vn-input formControlName='dticketId' key="dticketID" label="DTicket ID">
            </vn-input>
          </div>
        </div>
        <div class="row">
          <div class="columns small-6 filter-input">
            <vn-date formControlName='checkoutDate' label="Bestelldatum" format="MM.YYYY" placeholder="MM.JJJJ"
              [ignoreDay]=true [ignoreYear]=true>
            </vn-date>
          </div>

          <div class="columns small-6 filter-input">
            <vn-date formControlName='beginDate' label="Beginn" format="MM.YYYY" placeholder="MM.JJJJ" [ignoreDay]=true
              [ignoreYear]=true>
            </vn-date>
          </div>
        </div>

        <div class="row">
          <div class="columns small-6 filter-input">
            <vn-input formControlName='ticketId' key="ticketID" label="Ticket ID">
            </vn-input>
          </div>
        </div>

        <div class="row">
          <button type="submit" class="button button-primary right">Suchen</button>
        </div>

      </form>

    </div>
  </div>
  <div>
    <vn-matblur-loading></vn-matblur-loading>
    <div class="dticket-search-employees-container" *ngFor="let customerId of getKeys(dTicketOrders)">
      <div class="list-box__row">
        <div class="list-box__item">
          <span class="list-box__itemTitle">
            <span class="user-name ng-binding" style="font-weight: bold">{{ dTicketOrders[customerId].customer_name }}
              - {{ customerId }}</span>
          </span>
        </div>
      </div>

      <mat-accordion [multi]="true"
        *ngFor="let employeeId of excludeKey(getKeys(dTicketOrders[customerId]), 'customer_name');">

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="vn-mat-panel-title">
              {{ employeeId }} | {{ dTicketOrders[customerId][employeeId].last_name }}, {{ dTicketOrders[customerId][employeeId].first_name }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row second-row">
            <div class="columns text-right an-verwaltung-padding">
              <button class="button button-primary isOutline" (click)="impersonate( employeeId )">
                Nutzer übernehmen
              </button>
            </div>
          </div>

          <div class="cockpit_toggable-content"
            *ngFor="let employeeData of dTicketOrders[customerId][employeeId]['dticket_orders']">
            <div class="main" *ngIf="getDTicketOrder(employeeData); let orderItem">

              <div class="list-box__row">
                <div class="list-box__item dticket-box">
                  <span class="list-box__itemTitle">
                    <span class="user-name ng-binding columns small-6" style="font-weight: 100; cursor: default">
                      {{ orderItem.createdAt | date: 'dd.MM.yyyy' }} | {{ orderItem.taxPayer == "no_tax" ? "Nettoabzug": "Pauschalsteuer"}}
                    </span>
                  </span>
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>menu</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button class="stornier-dticket-button" mat-menu-item (click)="cancelTicket(orderItem)"
                      *ngIf="orderItem.isCancelAllowed()">
                      <mat-icon style="color: red;">
                        cancel
                      </mat-icon>

                      <span>
                        Stornieren
                      </span>
                    </button>

                    <button *ngIf="canRestoreTicket(orderItem)" mat-menu-item
                      (click)="restoreTicket(orderItem)">
                      <mat-icon>bookmark</mat-icon>
                      <span>
                        Wiederherstellen
                      </span>
                    </button>

                    <button mat-menu-item (click)="navigateTo('bonus', employeeId)">
                      <mat-icon>
                        link
                      </mat-icon>

                      <span>
                        Zu Bonus
                      </span>
                    </button>

                    <button mat-menu-item (click)="navigateTo('neo', employeeId)">
                      <mat-icon>
                        link
                      </mat-icon>

                      <span>
                        Zu NEO
                      </span>
                    </button>
                    <button mat-menu-item (click)="navigateTo('calc', employeeId)">
                      <mat-icon>
                        link
                      </mat-icon>

                      <span>
                        Rechner
                      </span>
                    </button>
                  </mat-menu>
                </div>
              </div>

              <div class="row">
                <div class="columns small-12">
                  <div class="mainWrapper">
                    <div class="vn-table">
                      <table>
                        <tbody>
                          <tr>
                            <td>Status</td>
                            <td>{{ orderItem.state }}</td>
                          </tr>
                          <tr>
                            <td>ID</td>
                            <td>{{ orderItem.id }}</td>
                          </tr>
                          <tr>
                            <td>Bestellreferenz</td>
                            <td>{{ orderItem.orderReference }}</td>
                          </tr>
                          <tr>
                            <td>Mobilitybox Order</td>
                            <td>{{ orderItem.mobilityBoxOrderId }}</td>
                          </tr>
                          <tr>
                            <td>Beginn</td>
                            <td *ngIf="orderItem.beginDate; else elseDate">
                              <span>{{ orderItem.beginDate | date: 'dd.MM.yyyy' }}</span>
                            </td>
                            <ng-template #elseDate>
                              <td><span>-</span></td>
                            </ng-template>
                          </tr>
                          <tr>
                            <td>Ende</td>
                            <td *ngIf="orderItem.endDate; else elseDate">
                              <span>{{ orderItem.endDate | date: 'dd.MM.yyyy' }}</span>
                            </td>
                            <ng-template #elseDate>
                              <td><span>-</span></td>
                            </ng-template>
                          </tr>
                          <tr>
                            <td>Ticket Gesamtpreis</td>
                            <td>{{ orderItem.price | currency : "EUR" }}</td>
                          </tr>
                          <tr>
                            <td>Anteil Arbeitgeber</td>
                            <td>{{ orderItem.employerSubsidy | currency : "EUR" }}</td>
                          </tr>
                          <tr>
                            <td>Anteil Arbeitnehmer</td>
                            <td>{{ orderItem.employeeContribution | currency : "EUR" }}</td>
                          </tr>
                          <tr>
                            <td>OTRS Ticket</td>
                            <td>{{ orderItem.otrsTicketNumber }}</td>
                          </tr>
                          <tr>
                            <td>Dokumente</td>
                            <td>
                              <a *ngIf="orderItem.contractFileId" (click)="openContractFile(orderItem)">
                                <mat-icon>
                                  picture_as_pdf
                                </mat-icon>
                              </a>
                              <a *ngIf="orderItem.contractFileId" (click)="openAuditFile(orderItem)">
                                <mat-icon>
                                  source
                                </mat-icon>
                              </a>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
