<div class='form-group'>
    <label [for]='key'>
        {{ label }}
    </label>

    <ng-content select='mat-error'>
    </ng-content>

    <select #formSelect
            class='form-control vn-select'
            [name]='key'
            [ngStyle]="{width: width}"
            [attr.data-cy]='key'
            [disabled]='isDisabled'
            (click)="onClick($event)"
            (change)='onChange($event.target.value)'>
        <option value=''
                *ngIf="withPlaceholder">
            {{ placeholder }}
        </option>

        <option *ngFor="let option of options"
                [value]='option.value'
                [selected]='option.value === value'>
            {{ option.name }}
        </option>
    </select>
</div>
