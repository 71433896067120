var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id) {
      



















































































































pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};






































































pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_mixins["summary-box"] = pug_interp = function(selectedCompany, save, disabled, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"summary-box isTransparent\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns\"\u003E";
if (selectedCompany) {
pug_html = pug_html + "\u003Cspan class=\"summary-box__title\"\u003E\u003Cstrong\u003EFirma:\u003C\u002Fstrong\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' '+ selectedCompany) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"hide-for-small-only\"\u003E&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns text-right pull-right\"\u003E";
block && block();
if (save) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"button button-primary isOutline\""+pug.attr("ng-click", save, true, true)+pug.attr("ng-disabled", disabled, true, true)+pug.attr("data-cy", cyData, true, true)) + "\u003ESpeichern\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};



















pug_html = pug_html + "\u003Cdiv class=\"cockpit_versicherungen-wrapper\"\u003E";
pug_mixins["summary-box"]('{{ VersicherungenCtrl.customer.name }}', 'VersicherungenCtrl.save()', 'VersicherungenCtrl.working || VersicherungenCtrl.configuration.fixated');
pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EVersicherungen\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E\u003Cdiv ng-repeat=\"insurance in VersicherungenCtrl.insurances\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"small-9 columns component-col\"\u003E\u003Clabel for=\"switchbox{{ insurance.id }}\" tabindex=\"1\"\u003E\u003Ch5\u003E\u003Cspan class=\"component-name\"\u003E{{ insurance.firma }}\u003C\u002Fspan\u003E\u003C\u002Fh5\u003E\u003C\u002Flabel\u003E\u003Ca ng-show=\"VersicherungenCtrl.configuration.insurances[insurance.id].enabled &amp;&amp; (VersicherungenCtrl.configuration.insuranceDefault != insurance.id)\" ng-click=\"VersicherungenCtrl.setDefault(insurance.id)\"\u003EDefault setzen\u003C\u002Fa\u003E\u003Ca ng-show=\"VersicherungenCtrl.configuration.insuranceDefault == insurance.id\"\u003Eist default\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"small-3 columns text-right\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchbox{{ insurance.id }}","key": "VersicherungenCtrl.configuration.insurances[insurance.id].enabled"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined));
    ;;return pug_html;};
module.exports = template;