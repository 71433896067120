import {
  BUDGET_TYPES_BY_VALUE,
  CALCULATION_TYPES_BY_VALUE,
  DEFAULT_ADDITIONAL_RATE_HEALTH_INSURANCE,
  DEFAULT_FACTOR,
  DEFAULT_MONTHLY_CONTRIBUTIONS, RAISE_TYPES
} from './default_data/data';

const $inject = [
  '$scope',
  'datevCalculatorService',
  '$timeout',
  '$filter',
  'WizardHandler'
];
export class EditPotentialDialogController {
  constructor(
    $scope,
    datevCalculatorService,
    $timeout,
    $filter,
    WizardHandler
  ) {
    Object.assign(this, {
      $scope,
      datevCalculatorService,
      $timeout,
      $filter,
      WizardHandler
    });

    this.init();
  }

  init() {

    this.$scope.CALCULATION_TYPES_BY_VALUE = CALCULATION_TYPES_BY_VALUE;
    this.watchers = [];
    let budget_watcher = this.$scope.$watch('ngDialogData.potential.budget', ((newBudgetType, oldBudgetType) => {
      if (!angular.equals(newBudgetType, oldBudgetType)) {
        this.$scope.ngDialogData.potential.calculation_settings = BUDGET_TYPES_BY_VALUE[newBudgetType].defaults.calculation_settings;
      }
    }));
    this.watchers.push(budget_watcher);
    this.models = {
      goal : this.$scope.ngDialogData.potential.calculation_settings.goal
    };

    this.onDestroy = () => {
      // remove watchers
      for(let watcher of this.watchers) {
        watcher();
      }
    };

    this.$scope.calculateAndMoveToNext = ($event) => {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();

        // update model to reflect goal
        if (this.$scope.ngDialogData.potential.budget === this.$scope.ngDialogData.budget_types_by_name['neueinstellung'].value) {
          this.models.goal = -this.$scope.ngDialogData.potential.calculation_settings.goal;
        }

        this.$scope.calculate();
        this.WizardHandler.wizard().next();
      }
    };

    this.$scope.calculate = () => {
      this.$scope.calculating = true;

      // update potential to reflect model
      if (this.$scope.ngDialogData.potential.budget === this.$scope.ngDialogData.budget_types_by_name['neueinstellung'].value) {
        this.$scope.ngDialogData.potential.calculation_settings.goal = -this.models.goal;
      }

      let tableData = this.datevCalculatorService.calculateForPotential(this.$scope.ngDialogData.potential);
      this.$scope.tableData = tableData;
      this.$scope.calculating = false;
    };

    this.$scope.onSliderChange = () => {
      this.$scope.calculate();
    };

    this.$scope.areConceptFormsValid = (form_name) => {
      let form = this.$scope.ngDialogData.forms[form_name];
      let are_forms_valid = false;
      if (!form) {
        return are_forms_valid;
      }
      are_forms_valid =  form.$valid;
      let totalForm = this.$scope.ngDialogData.forms['totalForm'];
      if (totalForm) {
        are_forms_valid = are_forms_valid && totalForm.$valid;
      }
      return  are_forms_valid;
    };

    this.$scope.getPlus = (raise) => {
      if (raise.type === this.$scope.ngDialogData.raise_types[0].value) {
        return raise.value + ' %';
      } else if (raise.type === this.$scope.ngDialogData.raise_types[1].value) {
        return this.$filter('currency')(raise.value);
      }
    };

    this.$scope.onChangeRaiseType = () => {
      this.$scope.ngDialogData.potential.raise.value = RAISE_TYPES[this.$scope.ngDialogData.potential.raise.type].def_value;
      this.$scope.ngDialogData.potential.advantage = 0;
    };

    this.$scope.onChangeTaxCategory = () => {
      if ( this.$scope.ngDialogData.potential.pap_stkl !== this.$scope.ngDialogData.steuerklassen[3].value ) {
        this.$scope.ngDialogData.potential.factor = DEFAULT_FACTOR;
      }
    };

    this.$scope.onChangeHealthInsurance = () => {
      this.$scope.ngDialogData.potential.monthly_contribution = DEFAULT_MONTHLY_CONTRIBUTIONS;
      this.$scope.ngDialogData.potential.additional_rate_health_insurance = DEFAULT_ADDITIONAL_RATE_HEALTH_INSURANCE;
    };

    let budgetFormAPI = {};
    this.$scope.onChangeChildTaxes = () => {
      if ( budgetFormAPI.calculateTotal()) {
        this.$timeout(()=>{
          budgetFormAPI.calculateTotal();
        });
      }
    };

    this.setBudgetFormAPI = (API) => {
      budgetFormAPI = API;
    };
  }
}

EditPotentialDialogController.$inject = $inject;
