import ComponentConstants from "../../../../constants/ComponentConstants";

angular
  .module('VSPApp')
  .controller('CheckoutZusatzvereinbarungController', [
    '$q',
    '$scope',
    '$location',
    '$state',
    '$window',
    'Made',
    'vbmData',
    'vbmService',
    'employeeService',
    'customerService',
    'DocumentConstants',
    'NotificationService',
    'CheckoutService',
    'authenticationService',
    'userService',
    '$timeout',
    'mppService',
    'componentsService',
    'fileService',
    'VSP_CONSTANTS',
    'ComponentConstants',
    'administrationService',
    function  (
      $q,
      $scope,
      $location,
      $state,
      $window,
      Made,
      vbmData,
      vbmService,
      employeeService,
      customerService,
      DocumentConstants,
      NotificationService,
      CheckoutService,
      authenticationService,
      userService,
      $timeout,
      mppService,
      componentsService,
      fileService,
      VSP_CONSTANTS,
      ComponentConstants,
      administrationService
    ) {
      $scope.globalCheckoutCtrl.step = $scope.globalCheckoutCtrl.steps;

      if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
        vbmData.employeeId = parseInt($location.search().employeeId);
        employeeService.get(vbmData.employeeId);

      }

      // find if there are missing documents
      let calculation_data;
      let calculationPromise = vbmData.getData().then((data) => {
        calculation_data = data;
        return vbmData.getResult();
      });

      let customerConfigPromise = customerService.getConfigurationByEmployeeId(vbmData.employeeId).then((configuration) => {
        $scope.customer_configuration = configuration;
      });

      let neoProjectPromise = customerService.getNeoProjectByEmployeeId(vbmData.employeeId).then(neoProject => {
        $scope.neoProject = neoProject;
      });

      let cartPromise = mppService.getCart(vbmData.employeeId, undefined, false).then(cart => {
        $scope.cart = cart;
      });

      vbmData.getNeoAgreement(vbmData.employeeId);
      $scope.forms = {};
      $scope.DocumentConstants = DocumentConstants;
      $scope.employee = vbmData.employees[vbmData.employeeId];
      $scope.iAmGAdvisor = authenticationService.iAm('gadvisor');
      $scope.iAmAdvisor = authenticationService.iAm('advisor');
      $scope.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
      $scope.iAmAdministration = authenticationService.iAm('sa_employee_administration');
      $scope.loading = true;
      $scope.busy = false;
      $scope.iAmProxy = userService.iAmProxy();
      $scope.finalizeOptimization = {enabled: true};
      // Status Changes disabled by default for special advisers
      $scope.employee_status = {to_change: $scope.iAmSpecialAdvisor ? false : true};
      $scope.mppTypeError = false;

      // check if advisor checkout
      $scope.notMe = vbmData.employeeId != Made.user.valuenet_id;
      if($scope.iAmSpecialAdvisor) {
        $scope.mail = {berater: false, mitarbeiter: false, kontrolle: false};
      } else if($scope.iAmAdvisor) {
        $scope.mail = {berater: true, mitarbeiter: true, kontrolle: true};
      }

      let checkoutSessionPromise = CheckoutService.getSession(vbmData.employeeId).then(session => {
        $scope.checkout_session = session;
        $scope.checkoutHasMissingDocuments = $scope.checkout_session.skipped_documents_upload;
      });

      let checkoutNeoExtrasPromise = CheckoutService
        .getNeoExtras(vbmData.employeeId)
        .then((neoExtras) => {
          let evBeginDate = new Date(neoExtras.evbegin);
          let documentsSubmissionDate = new Date(evBeginDate);
          documentsSubmissionDate.setMonth(evBeginDate.getMonth() - 1);
          documentsSubmissionDate.setDate(10);

          // check if the month was January and thus the previous months is last year
          if (evBeginDate.getMonth() - 1 > evBeginDate.getMonth()) {
            documentsSubmissionDate.setFullYear(evBeginDate.getFullYear() - 1);
          }

          documentsSubmissionDate.setMilliseconds(0);
          documentsSubmissionDate.setHours(0);
          documentsSubmissionDate.setMinutes(0);
          documentsSubmissionDate.setSeconds(0);


          $scope.documentsSubmissionDate = documentsSubmissionDate;
          return vbmData.finalizeCalculation(neoExtras);
        }).then(async (documents) => {
          $scope.vereinbarung = 6;

          // $scope.agreement = await fileService.getDocumentUrl(documents[$scope.vereinbarung]);
          // $scope.comparison = await fileService.getDocumentUrl(documents[99]);

          $scope.documents = documents;
        }, () => {
          $scope.error = true;
          NotificationService.error('Die Vorbereitung des Checkouts schlug fehl. Bitte kontaktieren Sie Ihren Berater');
        });

      $q.all([
        calculationPromise,
        neoProjectPromise,
        cartPromise,
        checkoutNeoExtrasPromise,
        checkoutSessionPromise,
        customerConfigPromise
      ]).then(async () => {
        if (
          vbmData.employees[vbmData.employeeId].data.neo_components.pc.enabled &&
          $scope.customer_configuration.neo_config.pc.mpp_type === VSP_CONSTANTS.MPP_TYPE.COMPONENT_BASED['id'] &&
          CheckoutService.willCheckoutBasket($scope.cart)
        ) {
          $scope.mppTypeError = true;
        }

        const result = await vbmData.isCalculationFinalizationAllowed({
          employee_id: vbmData.employeeId,
          calculation_id: vbmData.employees[vbmData.employeeId].data.metadata.id
        });

        $scope.has_overlapping_components_with_bonus_result = result.validations.has_overlapping_components_with_bonus;
        $scope.has_overlapping_components_with_bonus_result.validations.components = $scope.has_overlapping_components_with_bonus_result.validations.components.map((c) => ComponentConstants[c]['title']).join(',');

        $timeout(() => {
          $scope.loading = false;
        });
      });

      $scope.canProceed = () => {
        return $scope.error || $scope.busy || $scope.mppTypeError;
      }

      $scope.openAgreement = async () => {
        $scope.loading_agreement = true;
        let url = await fileService.getDocumentUrl($scope.documents[$scope.vereinbarung]);
        $window.open(url, '_blank');
        $scope.loading_agreement = false;
      };

      $scope.openComparison = async () => {
        $scope.loading_comparison = true;
        let url = await fileService.getDocumentUrl($scope.documents[99]);
        $window.open(url, '_blank');
        $scope.loading_comparison = false;
      };

      $scope.getEVGContractDocument = () => {
        getAiId($scope.documents.session_id).then((contract) => {
          administrationService.createEVGContractDocument(vbmData.employeeId, contract.calc_id).then(
            (res) => {
              $window.open(res.response, '_blank');
            }).catch((err) => {
              NotificationService.error('Fehlgeschlagen - ');
            });
        });
      };

      // session_id is mapped to calc_id
      function getAiId(session_id) {
        return employeeService.getContractByCalcId(session_id).then(contract => contract);
      }

      $scope.proceed = function () {
        if (!$scope.busy) {
          $scope.busy = true;
          vbmData
            .setNeoAgreement(
              vbmData.employeeId,
              $scope.checkout_session.preCalculationId,
            )
            .then((success) => {
              if (!success) {
                throw 'could not set neoAgreement';
              }

              if ($scope.finalizeOptimization.enabled) {
                return employeeService.finaliseContractByCalcId($scope.documents.session_id, vbmData.employeeId);
              }

              return success;
            })
            .then(success => {
              if (!success) {
                throw 'could not finalize contract';
              }

              if ($scope.finalizeOptimization.enabled) {
                if ($scope.notMe && !$scope.iAmSpecialAdvisor) {
                  return vbmService.sendLastCalculationEmail(vbmData.employeeId, $scope.mail, Made.user.valuenet_id, $scope.checkout_session.direct_contract.is_enabled);
                }
                // if I am specialAdvisor and want to act as if I am an alternateAdvisor so that it looks like this alternateAdvisor made the change
                else if ($scope.iAmSpecialAdvisor && $scope.globalCheckoutCtrl.alternateAdvisor) {
                  return vbmService.sendLastCalculationEmail(vbmData.employeeId, $scope.mail, $scope.globalCheckoutCtrl.alternateAdvisor, $scope.checkout_session.direct_contract.is_enabled);
                }
                // if I am advisor but not specialAdvisor and impersonated another user
                else if ($scope.iAmProxy && !$scope.iAmSpecialAdvisor) {
                  return userService
                    .getEmployeeIdByUserId(Made.user.originalId)
                    .then(originalEmployeeId => {
                      return vbmService.sendLastCalculationEmail(vbmData.employeeId, $scope.mail, originalEmployeeId, $scope.checkout_session.direct_contract.is_enabled);
                    });
                }
                // send calculation main to the employee he is the one doing it
                return vbmService.sendLastCalculationEmail(vbmData.employeeId, $scope.mail);
              }
              return success;
            })
            .then(() => {
              return employeeService.moveToControlStateVCP(vbmData.employeeId, $scope.notMe || $scope.iAmProxy, $scope.documents.session_id, $scope.checkout_session.direct_contract.is_enabled, $scope.employee_status.to_change, $scope.checkout_session.advisor_checkout_comment);
            })
            .then(() => {
              NotificationService.message('Vielen Dank für Ihr entgegengebrachtes Vertrauen. Wir nehmen schnellstmöglich mit Ihnen Kontakt auf.');
              $scope.busy = false;
              if ($scope.iAmSpecialAdvisor && $scope.iAmAdministration) {
                $state.go('inApp.administration.neo', {employeeId: vbmData.employeeId});
              } else {
                $state.go('blank.cidaas_login');
              }
            })
            .catch(() => NotificationService.error('Die Benachrichtigung über den Checkout schlug fehl. Bitte kontaktieren Sie Ihren Berater'));
        }
      };

    }]);
