const $inject = [
  'quizFactory'
];
export default class bavQuiz {
  constructor(quizFactory) {
    this.quizFactory = quizFactory;

    this.description = '';

    this.steps = [
      {
        id: 'question1',
        title: 'Wie viel wollen Sie monatlich in Ihre betriebliche Altersvorsorge investieren?',
        items: [
          {
            key: 'bav.beitrag',
            title: 'Bitte wählen Sie einen Betrag zwischen 0 und 248 Euro.'
          },
          {
            type: 'conditional',
            condition: 'quiz.bkv',
            items: [{
              type: 'help',
              helpvalue: '<a href="http://cms.consal-maklerservice.de/web/html/start/produkte/bkv/" target="_blank">Mehr Informationen</a>'
            }]
          }
        ]
      }
    ];

    this.bavQuiz = new quizFactory(this.steps, this.description);

    this.bavQuiz.exit = () => {
      console.log(this);

      return true;
    };

    this.bavQuiz.title = 'Betriebliche Altersvorsorge';
    this.bavQuiz.noSkip = true;

  }

  get() {
    return this.bavQuiz;
  }
}
bavQuiz.$inject = $inject;
