import iframe_html from "./iframe.pug";

angular
  .module('VSPApp')
  .directive('magnificPopup', [
    '$sce',
    'ngDialog',
    'PopupService',
    ($sce, ngDialog, PopupService) => {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {

        let click = e => {

          if (e) {
            e.stopPropagation();
            e.preventDefault();
          }

          if (options.type === 'iframe') {
            ngDialog.open({
              template: iframe_html(),
              plain: true,
              className: 'magnific-theme-vsp',
              data: {
                src: $sce.trustAsResourceUrl(attrs.href)
              }
            });
          }

          return false;
        };


        let options = scope.$eval(attrs.magnificPopup);

        element.bind('click', click);

        if (options.clicker) {
          options.clicker.click = click;
        }

      }
    };
  }]);
