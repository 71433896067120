angular
  .module('VSPApp')
  .service('kinderAssistantService', [function  () {

    let kidScaffold = {
      birthdate: '',
      subsidy: 0,
      august: false,
      foodFeeRecurrence: 'monthly',
      foodFee: 0,
      expenses: 0,
      valid: false,
      result: 0
    };

    this.employees = {};

    this.get = employeeId => {

      if (!this.employees[employeeId]) {
        this.employees[employeeId] = {
          kids: []
        };
        this.addChild(employeeId);
      }

      return this.employees[employeeId];
    };

    this.addChild = employeeId => this.employees[employeeId].kids.push(angular.copy(kidScaffold));

    this.calculateKid = kid => {

      kid.result = 0;

      if (!kid.valid.length) {
        kid.result += +kid.foodFee;
        if (kid.foodFeeRecurrence === 'daily') kid.result *= 15;

        kid.result += +kid.subsidy;
        kid.result += +kid.expenses;

        if (kid.august) kid.result = kid.result * 11 / 12;
      }

      return kid.result;
    };

  }]);
