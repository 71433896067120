angular
  .module( 'VSPApp' )
  .service( 'FeedbackService',[ '$q', 'Made', 'FeedbackConstants', function ( $q, Made, FeedbackConstants ) {

    this.list = customer_id => Made
      .request( 'rpc://valuenetdb/feedback/list', {
        customer_id
      } );

    this.get = (employee_id = Made.user.valuenet_id) => Made
      .request( 'rpc://valuenetdb/feedback/get', {
        employee_id
      } );

    this.save = (data, id) => Made
      .request( 'rpc://valuenetdb/feedback/save', {
        data,
        id
      } );

    this.moduleQuestions = () => {
      let questions = [];

      FeedbackConstants.modules.forEach( mod => {
        FeedbackConstants.moduleQuestions.forEach( (question, id) => {
          questions.push( {
            text: question.replace( '$module', '„' + mod.name + '“' ),
            id: mod.id + id,
            type: 'radio',
            answers: [
              {
                id: 0,
                text: 'Ja'
              },
              {
                id: 1,
                text: 'Nein'
              },
              {
                id: 2,
                text: 'Nicht sicher'
              }
            ]
          } );
        } );
      } );

      return $q.when( questions );
    };

    this.generalQuestions = () => {
      let questions = [];

      FeedbackConstants.generalQuestions.forEach( (question, id) => {
        questions.push( {
          text: question,
          id: id,
          type: 'radio',
          answers: [
            {
              id: 0,
              text: 'Ja'
            },
            {
              id: 1,
              text: 'Nein'
            },
            {
              id: 2,
              text: 'Nicht sicher'
            }
          ],
          additionalText: true
        } );
      } );

      return $q.when( questions );
    };

    this.textQuestions = () => {
      let questions = [];

      FeedbackConstants.textQuestions.forEach( (question, id) => {
        questions.push( {
          text: question,
          id: id,
          additionalText: true
        } );
      } );

      return $q.when( questions );
    };

    this.aggregate = list => {
      let statistics = {
        module: {},
        general: {},
        text: {}
      };

      list.forEach( function ( feedback ) {
        [
          'general',
          'module',
          'text'
        ].forEach( cluster => {
          angular.forEach( feedback.data[ cluster ], (answer, question) => {
            if ( !statistics[ cluster ][ question ] ) {
              statistics[ cluster ][ question ] = {
                radio: {
                  0: 0,
                  1: 0,
                  2: 0
                },
                texts: 0,
                total: 0,
                radioTotal: 0
              };
            }

            statistics[cluster][question].total++;

            if ( answer.text ) {
              statistics[cluster][question].texts++;
            }

            if ( undefined !== answer.radio ) {
              statistics[cluster][question].radioTotal++;
              statistics[cluster][question].radio[answer.radio]++;
            }
          } );
        } );
      } );

      return statistics;
    };

    this.expand = statistics => {
      let expanded = {
        module: [],
        general: [],
        text: []
      };

      let moduleQuestionDict = {};

      FeedbackConstants.modules.forEach( mod => {
        FeedbackConstants.moduleQuestions.forEach( (question, id) => {
          moduleQuestionDict[ mod.id + id ] = question.replace( '$module', '„' + mod.name + '“' );
        } );
      } );

      angular.forEach( statistics.module, (answers, question) => {
        expanded.module.push( {
          question: moduleQuestionDict[ question ],
          answers
        } );
      } );

      angular.forEach( statistics.general, (answers, question) => {
        expanded.general.push( {
          question: FeedbackConstants.generalQuestions[ question ],
          answers
        } );
      } );

      angular.forEach( statistics.text, (answers, question) => {
        expanded.text.push( {
          question: FeedbackConstants.textQuestions[ question ],
          answers
        } );
      } );


      return expanded;
    };

  } ] );
