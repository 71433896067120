export const FID_EMPLOYEE_TYPE = [
  {
    id: 1,
    option_id: 0,
    name: 'VZ',
    display: 'VZ'
  },
  {
    id: 2,
    option_id: 1,
    name: 'TZ',
    display: 'TZ'
  },
  {
    id: 3,
    option_id: 2,
    name: 'AZUBI',
    display: 'AZUBI'
  },
];

export const FID_EMPLOYEE_TYPE_BY_NAME = FID_EMPLOYEE_TYPE.reduce((acc, type) => {
  acc[type.name] = type;
  return acc;
}, {});

export const FID_EMPLOYEE_TYPE_BY_ID = FID_EMPLOYEE_TYPE.reduce((acc, type) => {
  acc[type.id] = type;
  return acc;
}, {});

export const DEFAULT_FID_EMPLOYEE_TYPE = FID_EMPLOYEE_TYPE_BY_NAME['VZ'];
