<h2 mat-dialog-title>

  Bulk Done
</h2>


<mat-dialog-content >

  Sie markieren die ausgewählte(n) Bestellung(en) als ausgeführt.

</mat-dialog-content>


<mat-dialog-actions>

  <button mat-button

          mat-dialog-close>

    Abbrechen

  </button>


  <button class='save-button'

          mat-stroked-button

          data-cy='vn.directBonus.backofficeAdministration.bulkDoneDialog.buttonSubmit'

          (click)="bulkMarkAsDone()">

    Speichern

  </button>

</mat-dialog-actions>
