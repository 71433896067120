const $inject = [
  '$scope',
  'PopupService',
  'PopupVideoKeys',
  'customerService',
  'VorsorgeConstants'
];

export default class VorsorgeHomeController {

  constructor(
    $scope,
    PopupService,
    PopupVideoKeys,
    customerService,
    VorsorgeConstants
  ) {
    this.$scope = $scope;
    this.PopupService = PopupService;
    this.customerService = customerService;
    this.VorsorgeConstants = VorsorgeConstants;

    this.$scope.clicker = {};

    this.PopupService.processModuleVideo(
      PopupVideoKeys.MODULE_VORSORGE,
      this.$scope.clicker
    );

    this.vorsorgeDetailsArray = [];

    this.customerService.getConfiguration()
      .then(configuration => {
        for (let k in configuration.vorsorge) {
          let obj = configuration.vorsorge[k];
          if (!obj.enabled && this.VorsorgeConstants[obj.title] !== undefined) {
            this.vorsorgeDetailsArray.push(this.VorsorgeConstants[obj.title]);
          }
        }
      });
  }
}

VorsorgeHomeController.$inject = $inject;
