export const ERHOLUNGSBEIHILFE_OPTIONS = [
  {
    label: 'Arbeitnehmer',
    prop: 'employee',
    id: 0,
    default: 156,
    cyData: 'vn.cockpit.bonus.createProjectDialog.inputEmployeeAmount-vacationGrant'
  },
  {
    label: 'Ehegatte',
    prop: 'spouse',
    id: 1,
    default: 104,
    cyData: 'vn.cockpit.bonus.createProjectDialog.inputSpouseAmount-vacationGrant'
  },
  {
    label: 'Kinder',
    prop: 'child',
    id: 2,
    default: 52,
    cyData: 'vn.cockpit.bonus.createProjectDialog.inputChildrenAmount-vacationGrant'
  },
];
