const $inject = [
  '$scope',
  'ShopCategoryConstants',
  'ShopService'
];
export default class HomeController {
  constructor(
    $scope,
    ShopCategoryConstants,
    ShopService
  ) {
    this.$scope = $scope;
    this.ShopCategoryConstants = ShopCategoryConstants;
    this.ShopService = ShopService;

    this.$scope.getImageSrc = this.ShopService.getImageSrc;
    this.$scope.shopCategories = this.ShopCategoryConstants.carousel;

    this.ShopService
      .loadOffers(this.ShopCategoryConstants.categories.dailyDeals)
      .then(dailyDeals => this.$scope.dailyDeals = dailyDeals);

    this.ShopService
      .loadOffers(this.ShopCategoryConstants.categories.techOffers)
      .then(techOffers => this.$scope.techOffers = techOffers);

    this.ShopService
      .highlightedCategories()
      .then(highlightedCategories => this.$scope.highlightedCategories = highlightedCategories);

  }
}
HomeController.$inject = $inject;
