import { enableProdMode, Injector, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { downgradeComponent, UpgradeModule } from '@angular/upgrade/static';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as angular from "angular";
import "./app-ajs";
import { BootstrapService } from "./app/bootstrap.service";

import { LoaderDirective } from "./app/shared/directives/loader/loader.component";
import { UIRouter, UrlService } from '@uirouter/core';

if (environment.production) {
  enableProdMode();
}


// Using AngularJS config block, call `deferIntercept()`.
// This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
// angular.module('VSPApp').config([ '$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept() ]);


platformBrowserDynamic().bootstrapModule(AppModule)
  .then(platformRef => {
    // Use the upgrade module to bootstrap the hybrid
    console.log('Angular app running');
    // const injector: Injector = platformRef.injector;
    // const bootstrapService = injector.get(BootstrapService);
    //
    // angular.module('VSPApp')
    //   .constant('USERLANE_DISABLED', bootstrapService.USERLANE_DISABLED)
    //   .constant('VIDEOS_DISABLED', bootstrapService.VIDEOS_DISABLED)
    //   .constant('VSP_CONSTANTS', bootstrapService.VSP_CONSTANTS)
    //
    //   // downgraded components
    //   .directive('loader', downgradeComponent({component: LoaderComponent}) as angular.IDirectiveFactory)
    //
    // // to tell AngularJS about Attribute directive
    // angular.module('VSPApp')
    //   .config(['$provide', ($provide: any) => {
    //     $provide.decorator('loaderDirective', ['$delegate', ($delegate: any) => {
    //       $delegate[0].restrict = 'A';
    //       return $delegate;
    //     }]);
    //   }])

    // const upgrade = injector.get(UpgradeModule) as UpgradeModule;
    // upgrade.bootstrap(document.documentElement, ['VSPApp'], {strictDi: true});
    // console.log('AngularJS app running');

    // https://github.com/ui-router/sample-app-angular-hybrid/blob/e4b1144d5e3e3451f0f0cc640175bb7055294fdd/app/bootstrap/bootstrap.ts
    // Initialize the Angular Module (get() any UIRouter service from DI to initialize it)
    // const urlService: UrlService = injector.get(UrlService);
    // // Instruct UIRouter to listen to URL changes
    // url.listen();
    // url.sync();
    // function startUIRouter() {
    //   urlService.sync();
    //   urlService.listen();
    // }
    //
    // injector.get<NgZone>(NgZone).run(startUIRouter);

    // Initialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
      urlService.listen();
      urlService.sync();
    }

    platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);

  }).catch(err => console.error(err));
