const TIME_REGEX = /^([01]?[1-9]|2[0-4]):([0-5]?[0-9])$/;

const FIRST_GROUP = /^([3-9]|\d{2})$/;
const FIRST_GROUP_NEEDS_PADDING = /^\d{1}:/;
const SECOND_GROUP_NEEDS_PADDING = /^\d{2}:\d{1}$/;
const IGNORE_KEY_CODES = [
  8, // Backspace
  46 //'Delete'
];

angular
  .module('VSPApp')
  .directive('vnTimeHelper', [function () {
    return {
      restrict: 'A',
      scope: {
        ngModel: '='
      },
      link: function (scope, element) {

        element.on('keyup', event => {

          if (-1 !== IGNORE_KEY_CODES.indexOf(event.keyCode)) {
            return;
          }

          let apply = false;

          if (FIRST_GROUP.test(scope.ngModel)) {
            scope.ngModel = scope.ngModel + ':';
            apply = true;
          }

          if (FIRST_GROUP_NEEDS_PADDING.test(scope.ngModel)) {
            scope.ngModel = '0' + scope.ngModel;
            apply = true;
          }


          if (apply) {
            scope.$applyAsync();
          }

        });

        element.on('blur', () => {

          if (SECOND_GROUP_NEEDS_PADDING.test(scope.ngModel)) {
            scope.ngModel = scope.ngModel.replace(':', ':0');

            scope.$applyAsync();
          }

        });

      }
    };
  }]);
