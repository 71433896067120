/**
 * @Author: D. Hristov <dean.hristov@bulpros.com>
 */

import menu_html from "../../modules/menu/menu.pug"
import datevbase_html from "../../modules/datev/datevbase.pug";
import header_html from "../../helpers/header.main.pug";
import datev_html from "../../modules/datev/datev.pug";
// import datev_result from "../../modules/datev/result/result.pug";
import datev_details from "../../modules/datev/details/details.pug";


const subdomain = location.hostname.split('.')[0];

const isDatevSubdomain = subdomain === 'gehaltsextras';
export { isDatevSubdomain };

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('test', {
        template: menu_html
      })
      .state('test.datev', {
        views: {
          main: {
            template: datevbase_html,
            controller: 'DatevBaseController'
          }
        }
      })
      .state('test.datev.landing', {
        // for creating app.js to deploy
        // url: '/',
        // for developing
        url: isDatevSubdomain ? '/' : '/datev',
        views: {
          datev: {
            template: datev_html,
            controller: 'DatevController',
            controllerAs: 'DatevCtrl'
          }
        }
      })
      // .state('test.datev.result', {
      //   url: '/datev/result',
      //   views: {
      //     datev: {
      //       template: datev_result,
      //       controller: 'DatevResultController',
      //       controllerAs: 'DatevResultCtrl'
      //     }
      //   }
      // })
      .state('test.datev.details', {
        // for creating app.js to deploy
        // url: '/details?:articleId',
        // for developing
        // url: '/datev/details?:articleId',
        url: isDatevSubdomain ? '/details?:articleId' : '/datev/details?:articleId',
        views: {
          datev: {
            template: datev_details,
            controller: 'DatevDetailsController',
            controllerAs: 'DatevDetailsCtrl'
          }
        }
      });
  }]);
