import overlay_html from './overlay.pug';
angular
  .module('VSPApp')
  .directive('overlay', [ function () {

    return {
      restrict: 'A',
      scope: {
        overlay: '=',
        ngClick: '&'
      },
      priority: 1,
      replace: true,
      transclude: true,
      template: overlay_html,
      link: function ($scope, elem, attr) {
        let clickAction = $scope.ngClick;

        const clickHandler = (event) => {
          if (clickAction) {
            $scope.$eval(clickAction);
          }
        };

        $scope.$watch('overlay', (newValue, oldValue) => {
          if (angular.equals(newValue, oldValue)) {
            return;
          }

          if (clickAction) {
            newValue ? elem.off('click') : elem.on('click', clickHandler);
          }
        });
      }
    };
  }]);
