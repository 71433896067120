const $inject = [
  "$scope",
  "$state",
  "$window",
  "$location",
  "mppService",
  "MppConstants",
  "$timeout"
];

export default class MppBarController {

  constructor($scope, $state, $window, $location, mppService, MppConstants, $timeout) {

    Object.assign(this, {
      $scope,
      $location,
      $state,
      $window,
      $timeout
    });
    this.$scope = $scope;
    this.$scope.mppCtrl = this.$scope.$parent.$parent;

    this.$timeout = $timeout;
    this.timer = false;
    this.filters = {};

    this.mppService = mppService;

    this.$scope.selectedMainCategory = 'Kategorien';
    this.$scope.selectedSubCategory = 'Alle';
    this.selectedCategories = this.mppService.getSelectedCategories();

    this.selectedProducer = '';
    this.$scope.producers = [];
    this.$scope.sorter = MppConstants.sorter;

    this.isOnDetail = /detail/.test(this.$state.current.name);
    this.searchDialog = this.$location.search().search && !this.isOnDetail ? decodeURI(this.$location.search().search) : '';

    this.currentPage = 0;
    this.itemsPerPage = this.mppService.getPageItems();

    this.mppService.getCategories().then(categories => {

      // Build category structure for pug
      this.mainCategories = [];
      this.subCategories = {};

      for (let i = 0; i < categories.length; i++) {
        let currentMainCategory = categories[i];

        this.mainCategories.push({
          name: currentMainCategory.name,
          img: currentMainCategory.name.toLowerCase().replace(/ /g, '').replace('&', 'u'),
        });
        this.subCategories[currentMainCategory.name] = { sub: currentMainCategory.sub };
      }
    });

    this.$scope.mobileArticleFilter = (article) => {
      if (this.filters.show_only_mobile) {
        return this.mppService.isMobileArticle(article);
      }

      return true;
    };

    this.$scope.mainArticleFilter = (article) => {
      if (this.filters.show_only_main) {
        return this.mppService.isMainArticle(article);
      }

      return true;
    };

    this.$scope.hasSupplyFilter = (article) => {
      if (this.filters.show_only_supply) {
        return this.mppService.hasSupply(article);
      }

      return true;
    };

    if (!this.isOnDetail) {

      if (this.searchDialog !== '') {
        this.search();
      }
    }

    //this.categorytWatch();
  }

  /**
   *
   * Adds / changes main category name for the filter object.
   * Delete if given the sub category name.
   *
   * If currentMain is false, reset category filter object.
   *
   * Set visible dropdown value
   *
   * @param {String|false} currentMain - Main category name
   */
  selectMain(currentMain) {
    if (this.$scope.selectedMainCategory === currentMain) {
      return;
    }
    this.selectedCategories = this.mppService.getSelectedCategories();

    this.$scope.selectedMainCategory = currentMain || 'Alle';

    if (currentMain !== false) {
      this.selectedCategories['main'] = currentMain;
      delete this.selectedCategories.sub;
      this.$scope.selectedSubCategory = 'Alle';
    } else {
      this.selectedCategories = {};
      this.$scope.selectedMainCategory = 'Alle';
    }

    this.mppService.setSelectedCategories(this.selectedCategories);

    this.filterByCategory();

  }

  /**
   *
   * Adds / changes sub category name for the filter object.
   *
   * If currentSub is false, delete the key.
   *
   * Set visible dropdown value
   *
   * @param {String|false} currentSub - Sub category name
   */
  selectSub(currentSub) {
    if (this.$scope.selectedSubCategory === currentSub) {
      return;
    }
    this.selectedCategories = this.mppService.getSelectedCategories();

    this.$scope.selectedSubCategory = currentSub || 'Alle';

    if (currentSub !== false) {
      this.selectedCategories.sub = currentSub;
    } else {
      delete this.selectedCategories.sub;
    }

    this.mppService.setSelectedCategories(this.selectedCategories);

    this.filterByCategory();
  }

  /**
   * Gets the current products based on the selected categories.
   *
   * Resets the selected Producer, just in case the given producer is not available in the selection.
   * Set new URI for user visualization and history "connections"
   *
   * Retrieves all current available producer.
   *
   * @param {object} currentCategory - selectedCategories
   */
  filterByCategory() {
    let currentCategory = this.mppService.getSelectedCategories();

    if (currentCategory.main !== undefined || currentCategory.sub !== undefined) {
      this.$location.path(this.calcPathURL(currentCategory));
    } else {
      this.$location.path('/mpp');
    }

    this.mppService.getByCategory(currentCategory)
      .then(products => {
        this.showResults(products);
      });

    // reset producer, might not available
    this.selectedProducer = '';

    this.searchDialog = '';
    this.$location.search('search', null);

    // reset order, results = asc popularity
    this.$scope.sorter.value = this.$scope.sorter.defaultValue;

    this.mppService.getCurrentProducers(currentCategory)
      .then(producers => {
        this.$scope.producers = producers;
      });
  }

  calcPathURL(urlPath) {
    if (urlPath.sub) {
      return ('/mpp/' + urlPath.main + '/' + urlPath.sub.replace('/', '-'));
    }

    return ('/mpp/' + urlPath.main);
  }

  search() {
    //this.initial      = false;
    this.selectedCategories = this.mppService.getSelectedCategories();

    this.$scope.sorter.value = this.$scope.sorter.defaultValue;
    let prepareSearch = {
      search: this.searchDialog,
    };

    if (this.selectedProducer !== '') {
      prepareSearch.Hersteller = [this.selectedProducer];
    }

    if (this.selectedCategories.main !== undefined) {
      prepareSearch.categories = this.selectedCategories;
    }

    if (this.$state.current.name === 'inApp.mpp.side.products.detail') {
      let newPath = '/mpp';

      if (this.selectedCategories.main !== undefined) {
        newPath += this.selectedCategories.sub !== undefined ? '/' + this.selectedCategories.main + '/' + this.selectedCategories.sub : '/' + this.selectedCategories.main;

        this.mppService.getCurrentProducers(this.selectedCategories)
          .then(producers => {
            this.$scope.producers = producers;
          });
      }

      this.$location.path(newPath);
    }

    this.mppService.getSearchResults(prepareSearch)
      .then(products => {
        this.showResults(products);
        this.timer = false;
      });

  }

  searchDelay() {
    if (this.searchDialog !== undefined && this.searchDialog !== '') {
      this.$location.search('search', encodeURI(this.searchDialog));
    } else {
      if (this.$location.search().search) {
        this.$location.search('search', null);
      }
    }

    if (this.timer) {
      this.$timeout.cancel(this.timer);
      this.timer = false;
    }

    this.timer = this.$timeout(this.search.bind(this), 1000);
  }

  /**
   * Updates Ctrl products for view and resets paging variables.
   *
   * @param {object} products - Articles
   */
  showResults(products) {
    this.$scope.mppCtrl.products = products;
    this.itemsPerPage = 12;
    this.mppService.setPageItems(this.itemsPerPage);
  }

  handleFilterChange() {
    // tell the FR that there was a change
    this.$timeout(() => {

    });
  }

  /**
   *
   * Returns the number of pages for pagination
   *
   * @returns {number} Pages
   */
  pageCount() {
    let pageCount = Math.ceil(this.mppService.getProductLength() / this.mppService.getPageItems()) - 1;
    if (pageCount > 0) {
      return pageCount;
    }

    return 0;
  }

  /**
   * Updates the number of items which have to appear
   */
  increaseListing() {
    if (this.pageCount()) {
      this.loading_content = true;
      this.itemsPerPage = this.mppService.getPageItems() + 12;
      this.mppService.setPageItems(this.itemsPerPage);
      this.loading_content = false;
    }
  }
}

MppBarController.$inject = $inject;