import checkout_html from "../../../modules/neo/checkout/checkout.pug";
import side_html from "../../../modules/neo/side/side.pug";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    let checkoutAllowed = ['customerService', function (customerService) {
      return customerService
        .isInfomode()
        .then(restricted => {
          if (restricted) {
            throw 'no checkout access in infomode or forbidden self checkout';
          }
        });
    }];


    $stateProvider
      .state('inApp.neo.side.checkout', {
        url: '/checkout',
        resolve: {
          infomode: checkoutAllowed
        },
        views: {
          sideMain: {
            template: checkout_html,
            controller: 'CheckoutController'
          },
          side: {
            template: side_html
          }
        }
      });

    let checkoutPages = [
      'zusammenfassung',
      'dokumente',
      'einsatz',
      'extrainfo',
      'zusatzvereinbarung'
    ];

    angular.forEach(checkoutPages, function (checkoutPage) {
      var upperCaseFirstLetterId = checkoutPage.charAt(0).toUpperCase() + checkoutPage.slice(1);

      $stateProvider
        .state('inApp.neo.side.checkout.' + checkoutPage, {
          url: '/' + checkoutPage + '?employeeId',
          views: {
            'checkoutMain': {
              template: require('../../../modules/neo/checkout/' + checkoutPage + '/checkout_' + checkoutPage + '.pug'),
              controller: 'Checkout' + upperCaseFirstLetterId + 'Controller',
              controllerAs: upperCaseFirstLetterId + 'Ctrl'
            }
          }
        });
    });

  }]);
