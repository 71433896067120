import ComponentController from '../ComponentController';

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'vbmService',
  'userService',
  'bikeleasingService',
  'ComponentWizardConstants',
];
export default class ComponentControllercar_leasing extends ComponentController {

  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    vbmService,
    userService,
    bikeleasingService,
    ComponentWizardConstants
  ) {

    super(
      $q,
      $injector,
      $scope,
      $state,
      $transitions,
      $location,
      Made,
      debounceFactory,
      vbmData,
      neoModifierService,
      customerService,
      PlausibilityService,
      dialogService,
      SliderOptionsConstants,
      componentsService,
      NotificationService,
      vbmService,
      userService,
      bikeleasingService,
      ComponentWizardConstants
    );

    this.loading = {
      main: true
    };

    // hack because of problem with inheritance
    this.$timeout = this.$injector.get('$timeout');
    this.employeeService = this.$injector.get('employeeService');
    this.VSP_CONSTANTS = this.$injector.get('VSP_CONSTANTS');
    this.$interpolate = this.$injector.get('$interpolate');

    this.carLeasingDashboardWizardIntro = ComponentWizardConstants['car_leasing'].items[0];

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }


  async init() {
    // await this.getData();
  }

  backToDashboard() {
    this.$state.go('inApp.neo.side.verguetung');
  }

  goToShop() {
    this.$state.go('inApp.car-leasing.list')
  }
}
ComponentControllercar_leasing.$inject = $inject;
