const $inject = ["Made"];

export default class TimetrackingService {

  constructor(Made) {
    this.Made = Made;
  }


  save(employee, data, category = 0 /*'Handwerkervorsorge'*/) {

    return this.Made.request('rpc://valuenetdb/timetracking/save', {
      employee,
      data,
      category
    });
  }

}

TimetrackingService.$inject = $inject;