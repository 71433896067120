angular
  .module('VSPApp')
  .directive('magnificPopupInlineContent', [function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {

          var MagnificPopupInlineContent = function (element) {
            this.element = element;
          };

          MagnificPopupInlineContent.prototype.close = function () {
            this.element.magnificPopup('close');
          };

          scope[attrs.magnificPopupInlineContent] = new MagnificPopupInlineContent(element);
        }
      };
    }]
  );
