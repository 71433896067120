import { Injectable } from "@angular/core";
import { CarTooExpensiveError } from "./car-too-expensive-error";
import { CustomError } from "./custom-error";
import { DefaultError } from "./default-error";
import { FinnAutoFailedError } from "./finn-auto-failed-error";
import { InvalidCarDurationError } from "./invalid-car-duration-error";
import { TooManyCarsError } from "./too-many-cars-error";
import { NoRetailerContractError } from "./no-retailer-contract-error";

enum ErrorType {
  TooManyCarLeasings = "TOO_MANY_CAR_LEASINGS",
  InvalidCarDuration = "INVALID_CAR_LEASING_DURATION",
  CarTooExpensive = "CAR_TOO_EXPENSIVE",
  FinnAutoFailed = "FINN_AUTO_FAILED_RESPONSE",
  NoRetailerContract = "NO_RETAILER_CONTRACT",

  // DIRECT BONUS
  BulkUploadMissingHeaders = 'DIRECT_BONUS_BULK_UPLOAD_MISSING_HEADER',
  BulkUploadWrongDelimiter = 'DIRECT_BONUS_BULK_UPLOAD_WRONG_DELIMITER',
  BulkUploadEndOfYearReached = 'DIRECT_BONUS_BULK_UPLOAD_END_OF_YEAR_REACHED',
  BulkUploadBonusValueInvalid = 'DIRECT_BONUS_BULK_UPLOAD_BONUS_VALUE_INVALID',
  BulkUploadBonusInternalError = 'DIRECT_BONUS_BULK_UPLOAD_INTERNAL_ERROR',
}

@Injectable()
export class CarLeasingErrorHandler {

  handle(e: any): CustomError {
    let error = e.error
    if (error && error.message && error.message.code) {
      let type = error.message.code && error.message.code.name ? error.message.code.name : null;
      let details = error.message.details ? error.message.details : null;

      if (type == ErrorType.NoRetailerContract) {
        return new NoRetailerContractError();
      }

      if (type == ErrorType.CarTooExpensive && details) {
        return new CarTooExpensiveError(details.employee_allowed_exchange);
      }

      if (type == ErrorType.InvalidCarDuration) {
        return new InvalidCarDurationError()
      }

      if (type == ErrorType.FinnAutoFailed) {
        return new FinnAutoFailedError()
      }

      if (type == ErrorType.TooManyCarLeasings) {
        return new TooManyCarsError()
      }

      if (type == ErrorType.BulkUploadMissingHeaders && details) {
        return new DefaultError(`Missing header(s): ${details.missing_header.join(',')} from file.`)
      }
      // #TRANSLATE
      if (type == ErrorType.BulkUploadWrongDelimiter) {
        return new DefaultError('CSV File delimiter should be ";"')
      }

      if (type == ErrorType.BulkUploadEndOfYearReached) {
        return new DefaultError('Die dem Kunden gewährte Frist für diese Bestellung ist abgelaufen.')
      }

      if (type == ErrorType.BulkUploadBonusValueInvalid && details) {
        return new DefaultError('Der Bonus überschreitet den Maximalwert.')
      }

      if (type == ErrorType.BulkUploadBonusInternalError) {
        if (error.message.message === 'deadline reached') {
          return new DefaultError('Frist ist abgelaufen'); 
        }
        return new DefaultError(error.message.message);
      }
    }

    return new DefaultError();
  }
} 
