import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomOfferFormControl } from '../components/incent/administration-configuration/administration-configuration.component';
import { IncentOffer, IncentOfferType, INCENT_DATES_FORMAT } from '../models/incent/incent-offer.model';
import { cashbackDataModel } from 'src/app/cashback/administration/review-request/administration-cashback-review/administration-cashback-review.model';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(
    @Inject('Made') private made: any,
    @Inject('fileService') private fileService: any,
  ) { }

  importLeasingData(
    f: File
  ) {
    const bulkUploadPromise = this.made.upload(
      f,
      [
        'mpp',
        'import-leasing-data',
      ],
      this.made.user._id
    ).then(() => {
      return this.made.request(
        'rpc://administration/mpp_control_process/import_leasing_data',
      )
    });

    return from(bulkUploadPromise);
  }

  importHrmonyData(
    f: File
  ) {
    const bulkUploadPromise = this.made.upload(
      f,
      [
        'hrmony',
        'import-hrmony-data',
      ],
      this.made.user._id
    ).then(() => {
      return this.made.request(
        'rpc://valuenetdb/hrmony/import_data',
      )
    });

    return from(bulkUploadPromise);
  }

  saveOrUpdateOffers(offers: Partial<CustomOfferFormControl>[]) : Observable<boolean> {

    let r$ =  from(this.made.request(
      'rpc://valuenetdb/incent/save_or_update_offers',
      {
        offers
      }
    ));

    return r$.pipe(map(r => !!r));
  }

  getIncentOffers(): Observable<IncentOffer[]> {
    let r$ =  from(this.made.request(
      'rpc://valuenetdb/incent/get_incent_offers',
    ));

    return r$.pipe(
      map(
        (results: any) => results.map((o: any) => new IncentOffer(
            o['id'],
            o['text'],
            o['type'] === IncentOfferType.DEFAULT ? IncentOfferType.DEFAULT : IncentOfferType.CUSTOM,
            o['link'],
            o['config'] ? {
              startDate: moment(o['config']['start_date'], INCENT_DATES_FORMAT),
              endDate: moment(o['config']['end_date'], INCENT_DATES_FORMAT),
              offerId: o['config']['offer_id']
            } : null,
            o['is_active']
          ))
      )
    );
  }

  getDashboardOffers(customerId: number) {
    let r$ =  from(this.made.request(
      'rpc://valuenetdb/incent/get_dashboard_offers',
      {customer_id: customerId}
    ));

    return r$;
  }

  openOfferLinkRequest( offerId: number ): Observable<string> {
    let r$ =  from<string>(this.made.request(
      'rpc://valuenetdb/incent/open_offer_link_request',
      {offer_id: offerId}
    ));

    return r$;
  }


  getCashbackRequestData(bonusId: string): Observable<cashbackDataModel> {
    const cashbackRequestData =  from(this.made.request(
      'rpc://valuenetdb/cashback/get_cashback_requests',
      {bonus_id: bonusId}
    ));

    return cashbackRequestData.pipe(
      map((data: any) => {
        const {
          bonus_type,
          component_rest,
          invoice_value,
          project_rest,
          cashback_requests,
        } = data;

        return {
          bonusType: bonus_type,
          invoiceValue: invoice_value,
          componentRest: component_rest,
          projectRest: project_rest,
          cashbackRequests: cashback_requests.map((request: any) => {
            const {
              id,
              bonus_id,
              receipt_date,
              receipt_valid_from,
              receipt_valid_period,
              state,
              requested_value,
              approved_value,
              files,
              comment,
              invoice_number,
              invoice_issuer
            } = request;

            return {
              id: id,
              bonusId: bonus_id,
              receiptDate: receipt_date,
              receiptValidFrom: receipt_valid_from,
              receiptValidPeriod: receipt_valid_period,
              approvedValue: approved_value,
              requestedValue: requested_value,
              state: state,
              comment: comment,
              files: files,
              invoiceNumber: invoice_number,
              invoiceIssuer: invoice_issuer
            }
          })
        };
      })
    )
  }

  approveCashbackRequest(
    cashbackId: number,
    approvedValue: number,
    invoiceNumber: string,
    invoiceIssuer: number,
    comment: string
  ): Observable<any> {
    return from(this.made.request(
      'rpc://valuenetdb/cashback/approve_cashback',
      {
        cashback_id: cashbackId,
        approved_value: approvedValue,
        invoice_number: invoiceNumber,
        invoice_issuer: invoiceIssuer,
        comment: comment,
      }
    )).pipe(
      switchMap(response => {
        return of(response)
      })
    );
  }

  declineCashbackRequest(
    cashbackId: number,
    invoiceNumber: string,
    invoiceIssuer: number,
    comment: string
  ): Observable<boolean> {
    return from(this.made.request(
      'rpc://valuenetdb/cashback/decline_cashback',
      {
        cashback_id: cashbackId,
        invoice_number: invoiceNumber,
        invoice_issuer: invoiceIssuer,
        comment: comment,
      }
    )).pipe(
      switchMap(response => {
        return of(response ? true : false)
      })
    );
  }

  getCashbackInvoiceIssuers(): Observable<string[]> {
    return from(
      this.made.request('rpc://valuenetdb/cashback/get_cashback_invoice_issuers')
    ).pipe(
      map((issuers: any) => issuers)
    );
  }

}
