const $inject = [
  '$transitions',
  '$state'
];
export default class CockpitBikeContractsController {

  constructor(
    $transitions,
    $state
  ) {

    this.customerId = $state.params.customerId;

  }
}
CockpitBikeContractsController.$inject = $inject;
