import * as angular from "angular";
import landing_html from "../../modules/landing/landing.pug"

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('landing', {
        url: '/landing',
        template: landing_html,
        controller: 'LandingController',
        controllerAs: 'landing',
        params: {
          next_url: null
        },
      });

  }]);
