import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CarLeasingApiService } from "./services/car-leasing-api.service";
import { CarLeasingCatalogService } from "./services/car-leasing-catalog.service";
import { CarLeasingDetailsComponent } from "./components/car-leasing-details/car-leasing-details.component";
import { CarLeasingCatalogComponent } from './components//car-leasing-catalog/car-leasing-catalog.component';

import "./models";
import { BackendCarLeasingBasketMapper } from "./services/mappers/backend-car-leasing-basket-mapper.service";
import { FinnApiCarMapper } from "./services/mappers/finn-api-car-mapper.service";
import { BackendCarLeasingCheckoutConfigurationMapper } from "./services/mappers/backend-car-leasing-checkout-configuration-mapper.service";
import { BackendDeliveryDataMapper } from "./services/mappers/backend-delivery-data-mapper.service";
import { BackendUserCarSettingsSelectionMapper } from "./services/mappers/backend-user-car-settings-selection-mapper.service";
import { BackendCarLeasingCarAdvantageResultMapper } from "./services/mappers/backend-car-leasing-car-advantage-result-mapper.service";
import { SharedModule } from "../shared/shared.module";
import { CarLeasingErrorHandler } from "./services/errors/car-leasing-error-handler";
import { CarLeasingCardComponent } from './components/car-leasing-card/car-leasing-card.component';
import { CarLeasingFinDataDialogComponent } from './dialogs/car-leasing-fin-data-dialog/car-leasing-fin-data-dialog.component';
import CarAdvantageFuelMapper from "./services/mappers/car-advantage-fuel-mapper.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    CarLeasingDetailsComponent,
    CarLeasingCatalogComponent,
    CarLeasingCardComponent,
    CarLeasingFinDataDialogComponent
  ],
  providers: [
    CarLeasingApiService,
    CarLeasingCatalogService,
    BackendCarLeasingBasketMapper,
    FinnApiCarMapper,
    BackendCarLeasingCheckoutConfigurationMapper,
    BackendDeliveryDataMapper,
    BackendUserCarSettingsSelectionMapper,
    BackendCarLeasingCarAdvantageResultMapper,
    CarLeasingErrorHandler,
    CarAdvantageFuelMapper,
  ]
})
class CarLeasingModule { }

export { CarLeasingModule };
