<vn-matblur-loading></vn-matblur-loading>

<div class="forms-wrapper">
  <form [formGroup]='formGroup'
      class="detection-form"
      data-cy="vn.registration.self.userConfirm.formElement"
  >
    <input formControlName='name'
           label="Name"
           data-cy="vn.registration.self.userConfirm.inputName"
    >
    <button [disabled]="!formGroup.valid"
            (click)="confirmAction()"
            data-cy="vn.registration.self.userConfirm.submitButton"
    >
      Confirm
    </button>
  </form>

  <div class="execution-form" *ngIf="isFormReady">
    <ng-content></ng-content>
  </div>
</div>
