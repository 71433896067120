export type EMPLOYEE_ID_TYPE = number;

export class NeoCalculationBookmark {
  constructor(
    public id: string,
    public employeeId: EMPLOYEE_ID_TYPE,
    public calculationId: number,
    public name: string,
    public isHidden: boolean,
    public isReporting: boolean,
    public createdBy: number,
    public createdAt: Date
  ) { }
}

export class NeoCalculationBookmarkMapper {
  public fromApi(o: any): NeoCalculationBookmark {
    return new NeoCalculationBookmark(
      o['id'],
      o['employeeId'],
      o['calculationId'],
      o['name'],
      o['isHidden'],
      o['isReporting'],
      o['createdBy'],
      o['createdAt']
    );
  }
}
