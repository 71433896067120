import * as moment from "moment";
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';

export type DateRange = {
  startDate: string;
  endDate: string
}

@Component({
  selector: 'vn-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangeComponent
    }
  ]
})
export class DateRangeComponent implements OnInit, ControlValueAccessor {

  @Input()
  public key!: string;

  @Input()
  public label = '';

  @Input()
  public placeholder = '';

  @Input()
  public defaultInputValue: DateRange|null = null;

  @Input()
  public isDisabled = false;

  private startDate!: string;
  private endDate!: string;
  public get dateRange(): string | null {
    if (!this.startDate || !this.endDate) {
      return null;
    }
    return `${this.startDate} — ${this.endDate}`
  }

  private formDisabled = false;
  private touched = false;

  public onTouched = () => { };
  public onChange = (_: DateRange) => { };

  @ViewChild(MatDateRangePicker) dateRangePicker!: MatDateRangePicker<any>;

  constructor() { }

  ngOnInit(): void {
  }

  public writeValue(value: DateRange) {
    if(this.defaultInputValue) {
      this.startDate = this.defaultInputValue.startDate;
      this.endDate = this.defaultInputValue.endDate;
    } else if (value && value.startDate && value.endDate) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    } 
  }

  public registerOnChange(onChange: (_: DateRange) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public pickDate(rawDate: string, emitOnChange = false) {
    if (!emitOnChange) {
      this.startDate = moment(rawDate).format('DD.MM.YYYY');
    } else {
      this.endDate = moment(rawDate).format('DD.MM.YYYY');
      if (this.endDate === 'Invalid date') {
        return;
      }

      const emitValue = {
        startDate: this.startDate,
        endDate: this.endDate
      };

      this.onChange(emitValue);
    }
  }

  public toggleDateRangePicker() {
    this.dateRangePicker.open();
  }

  public setDisabledState(formDisabled: boolean) {
    this.formDisabled = formDisabled;
  }
}
