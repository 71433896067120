import { BonusComponentController } from './BonusComponentController';

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter',
  'NeoComponentsService'
];

export class BonusComponentControllerElectronicPresent extends BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter,
    NeoComponentsService
  ) {

    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter,
      NeoComponentsService
    );

    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }

  async init() {
    console.log("CONTROLLER FOR ELECTRONIC PRESENT")
  }

  goToMPPShopForElectronicPresent() {
      // let pc_component = this.NeoComponentsService.createPCComponent({
      //   employee_id: this.employee_id,
      //   neo_project: this.neoProject,
      //   customer_configuration: this.$scope.customerConfiguration
      // });
      // this.$state.go(`inApp.neo.side.moduleNew${component.id}`, { neo_component: component });
    this.$state.go('inApp.mpp.side.products.landing', {
      employee_bonus_checkout_config: this.employee_bonus_checkout_config,
      mpp_data: this.mpp_data
    });
  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true) {
    return;
  }

  calculateValue() {}
}

BonusComponentControllerElectronicPresent.$inject = $inject;
