<h2 mat-dialog-title>
    Ladedaten hochladen
</h2>

<mat-dialog-content>
    <p>
        Zur Übertragung von neuen Ladedaten dient eine Excel-Vorlage. Laden Sie hier die Datei über den Knopf herunter.
        Die heruntergeladene Datei beinhaltet bereits alle für das unten angegebene Kalenderjahr ausgeführten und auch
        vorgemerkten Ladungen.
    </p>

    <button mat-stroked-button
            data-cy="vn.directBonus.customerAdministration.bulkAddBonusDialog.buttonDownloadCsv"
            (click)="downloadTableAsFile()">
        <mat-icon class="white-icon">
            download
        </mat-icon>

        <span>
            CSV herunterladen
        </span>
    </button>

    <button mat-stroked-button
            (click)="downloadTableAsFile(true)">
        <mat-icon class="white-icon">
            download
        </mat-icon>

        <span>
            CSV mit Grund herunterladen
        </span>
    </button>

    <p>
        Fügen Sie einfach in den gewünschen Monaten bei den gewünschten Einträgen Werte hinzu. Bitte beachten Sie die
        jeweilig gültigen monatlichen Höchstgrenzen. Dies wird gegenwärtig vom System nicht gesondert validiert.
        Ladungen aus den Vormonaten können durch Editierung der Liste nicht geändert werden.
    </p>

    <p>
        Speichern Sie die fertig bearbeitete Datei auf Ihrem Rechner. Laden Sie anschließend die Daten in das System
        hoch. Nach dem Upload wird Ihnen der Dateiname im System angezeigt. Prüfen Sie an dieser Stelle noch einmal, ob
        Sie die richtige Datei ausgewählt haben.
    </p>

    <div class='row filters'>
        <form [formGroup]='filtersGroup'>
            <vn-select formControlName='year'
                       key="vn.directBonus.customerAdministration.bulkAddBonusDialog.selectYear"
                       label="Auswahl Kalenderjahr für Verarbeitung"
                       placeholder='Alle'
                       [withPlaceholder]="false"
                       [initValue]="currentYearString"
                       [options]='filterOptions?.year'>
            </vn-select>
        </form>
    </div>

    <mat-divider></mat-divider>

    <mat-divider></mat-divider>

    <vn-dropzone label="Direktbonus CSV"
                 key="vn.directBonus.customerAdministration.bulkAddBonusDialog.fileBonusCsv"
                 [isDisabled]="!filtersGroup.valid"
                 (onUpload)="uploadTableFromFile($event)">
    </vn-dropzone>

    <mat-divider></mat-divider>

    <p>
        Mit dem Klick auf "Hochladen" wird sich dieser Dialog schließen und die Daten aktualisiert werden.
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            data-cy="vn.directBonus.customerAdministration.bulkAddBonusDialog.buttonCloseDialog"
            mat-dialog-close>
        Schließen
    </button>
</mat-dialog-actions>
