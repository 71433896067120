angular
  .module('VSPApp')
  .directive('vnFormatProjectTime', [function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attrs, ngModel) {


        let dateRegex = /^(\d{4})-(\d{1,2})$/;

        ngModel.$parsers.unshift((viewValue) => {
          if ('string' === typeof viewValue) {
            let date = viewValue.match(dateRegex);

            if (date) {
              return (new Date(date[1], date[2] - 1)).getTime();
            }
          }
        });

        ngModel.$formatters.push((modelValue) => {
          let dateValue = new Date(modelValue);
          // if ( ('object' === typeof modelValue) && modelValue.getDate ) {
          return '' + dateValue.getFullYear() + '-' + (dateValue.getMonth() + 1);
          // }
        });
      }
    };
  }]);
