import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';



@Component({
  selector: 'vn-reset-preend-pc-dialog',
  templateUrl: './reset-preend-pc-dialog.component.html',
  styleUrls: ['./reset-preend-pc-dialog.component.scss']
})
export class ResetPreendPcDialogComponent extends BaseComponent implements OnInit {

  public basket: any;
  public pcComponent: any;
  public form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ResetPreendPcDialogComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private dialogData: {basket: any, pcComponent: any},
    @Inject('mppService') private mppService: any,
  ) {
    super()
  }

  ngOnInit(): void {
    this.setLoading$(true);
    this.basket = this.dialogData.basket;
    this.pcComponent = this.dialogData.pcComponent;
    this.initForm();
    this.setLoading$(false);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      comment: this.formBuilder.control(undefined, [])
    });
  }

  async resetPreend() {
    if (!this.basket.bonus) {
      this.setLoading$(true);
      const comment = this.form.controls['comment'].value;
      await this.pcComponent.remove_preend_on_pc(comment)
        .then(() => {
          this.alertService.message('Die Nutzerentscheidung wurde zurückgesetzt.');
        }).catch(() => {
          this.alertService.error('Fehlgeschlagen.');
        });
      this.dialogRef.close(true);
      this.setLoading$(false);
    } else {
      this.alertService.error('Cannot remove preend on Bonus Basket. #translate');
    }
  }
}
