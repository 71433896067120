import * as moment from 'moment';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Moment } from 'moment';

@Component({
  selector: 'vn-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateComponent
    }
  ]
})
export class DateComponent implements OnInit {

  @Input()
  public key!: string;

  @Input()
  public label = '';

  @Input()
  public placeholder = '';

  @Input()
  public isDisabled = false;

  @Input()
  public minDate!: Date;

  @Input()
  public maxDate!: Date;

  @Input()
  public format = 'DD.MM.YYYY';

  @Input()
  public initDate!: Moment;

  @Input()
  public ignoreDay: boolean = false;

  @Input()
  public ignoreYear: boolean = false;

  private formDisabled = false;
  private touched = false;
  public initValue: string = '';
  public startView: "month" | "year" | "multi-year" = "multi-year";
  @ViewChild('shownInput') shownInput!: ElementRef;

  private formControlValue: Date | null = new Date();

  public get value(): string {
    if (this.formControlValue instanceof Date) {
      return moment(this.formControlValue).format(this.format);
    }

    return '';
  }

  public onTouched = () => { };
  public onChange = (_: any) => { };

  @ViewChild(MatDatepicker) datePicker!: MatDatepicker<any>;

  constructor() {
  }

  ngOnInit(): void {
    this.initiationValue();
    this.initFirstView();
  }

  private initFirstView() {
    if(this.ignoreYear) {
      this.startView = "year";
    }
  }

  private initiationValue() {
    if(this.initDate) {
      this.initValue = this.initDate.format(this.format);
    }
  }

  public writeValue(value: Date | null) {
    this.formControlValue = value;
  }

  public registerOnChange(onChange: (_: Date) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public pickDate(rawDate: Date | null) {
    this.writeValue(rawDate);
    this.onChange(rawDate);
  }

  public toggleDatePicker() {
    this.datePicker.open();
  }

  public setDisabledState(formDisabled: boolean) {
    this.formDisabled = formDisabled;
  }

  public changeDate(newValue: string): void {
    const newDate = moment(newValue, this.format, true);
    if(newDate.isValid()) {
      this.pickDate(newDate.toDate());
    } else {
      this.pickDate(null);
    }
  }

  public monthSelected(selectedDate: Date, datePicker: any) {
    if(this.ignoreDay) {
      datePicker.close();
      this.pickDate(selectedDate);
      setTimeout(()=>{
        this.shownInput.nativeElement.focus();
      }, 500);
    }
  }
}
