angular
  .module('VSPApp')
  .provider('BrandingService', [function () {


    let Branding = {
        list: {}
      },

      active = 'standard',

      brandingProperties = {
        chart: [
          'tooltipFillColor', // No filtering set for second level yet
          'colours' // No filtering set for second level yet
        ],
        circleProgress: [
          'light',
          'dark'
        ]
      },

      subdomain = window.location.hostname.split('.');

    if ((subdomain.length > 2) && Branding.hasOwnProperty(subdomain[0])) {
      active = subdomain[0];
    }


    this.add = function (branding, settings) {
      Branding.list[branding] = filterProperties(brandingProperties, settings);
      return this;
    };

    this.$get = function () {
      if (Branding.list[active]) {
        return Branding.list[active];
      } else {
        throw new Error('No active branding set yet!');
      }
    };

    function filterProperties(properties, object) {
      var filtered = {};

      for (var property in object) {
        if (object.hasOwnProperty(property) && (property in properties)) {
          // @todo: possible second lvl filtering
          filtered[property] = object[property];
        }
      }

      return filtered;
    }

  }]);
