const $injectLandingController = ['$scope', '$transitions', '$location', 'Made', 'LandingConstants', '$state', '$window'];
export default class LandingController {

  constructor( $scope, $transitions, $location, Made, LandingConstants, $state, $window) {

    Object.assign( this, {
      $scope,
      $transitions,
      $location,
      Made,
      LandingConstants,
      $state,
      $window
    } );

    Object.assign(this, {
      $state
    });

    this.regData = {};
    this.form = {
      unsent: true
    };
    this.$scope.currentYear = new Date().getFullYear();
  }

  scrollTo( section ) {
    this.$location.hash( section );
  }

  register( form ) {
    if ( form.$valid ) {
      this.form.unsent = false;
      this.form.sending = true;

      let code = this.regData.code ?  this.regData.code : 'None';

      //TODO: this belongs into service!
      this.Made
        .request( 'rpc://vbm/application/create_test_portal', {
          firstname: this.regData.firstname,
          lastname: this.regData.lastname,
          email: this.regData.email,
          company: this.regData.company,
          code: code
        } )
        .finally( () => {
          this.form.sending = null;
          this.form.sent = true;
        } );
    }
  }

  goToCidaasLogin() {
    this.$state.go('blank.cidaas_login', this.$state.params);
  }

  toggle_aside() {
    angular
      .element( document.getElementsByClassName( 'off-canvas-wrap' ) )
      .toggleClass( 'move-right' );
  }

  openOverlay( key, index ) {
    if ( this.LandingConstants[ key ] ) {
      this.selectedKey = key;
      this.selectedIndex = index;
      this.selectedItems = this.LandingConstants[ key ];
      this.selectedInfo = this.LandingConstants[ key ][ index - 1 ];
    }
  }

  closeOverlay() {
    this.selectedInfo = !this.selectedInfo;
  }

}
LandingController.$inject = $injectLandingController;
