import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import {LoadingService} from "../../../../shared/services/loading.service";
import { take } from 'rxjs/operators';


@Component({
  selector: 'vn-direct-bonus-done-dialog',
  templateUrl: './direct-bonus-done-dialog.component.html',
  styleUrls: ['./direct-bonus-done-dialog.component.scss']
})
export class DirectBonusDoneDialogComponent implements OnInit {
  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public dialogInputForm!: FormGroup;
  public OTRSTicketForm!: FormGroup;

  private get comment(): string {
    return this.dialogInputForm.get('comment')?.value;
  }

  private get OTRSTicket(): string {
    return this.OTRSTicketForm.get('OTRSTicket')?.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectBonusDoneDialogComponent>,
    private directBonusService: DirectBonusService,
    private notificationService: AlertService,
    private loader: LoadingService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData
  ) {
  }

  ngOnInit(): void {
    this.initForms();
  }

  private initForms() {
    this.dialogInputForm = this.formBuilder.group({
      comment: this.formBuilder.control('')
    });

    this.OTRSTicketForm = this.formBuilder.group({
      OTRSTicket: this.formBuilder.control('')
    });
  }

  public markOrderAsDone() {
    this.loader.show();
    return this.directBonusService.markOrderAsDone(
      this.orderId,
      this.comment,
      this.OTRSTicket
    )
      .subscribe(
        (result) => {
          this.loader.hide();
          const message = 'Sie haben die ausgewählte(n) Bestellung(en) ausgeführt. Alle Boni aus der (den) ausgewählten Bestellung(en) sind jetzt als Topup-Guthaben auf Bonago geladen.';
          if (result) {
            this.notificationService.message(message);
            this.dialogRef.close(true);
          }
        },
        async (error: any) => {
          this.loader.hide();
          // TODO: Do proer error handling with backend errors;
          this.directBonusService.getOrders({id: this.orderId}).pipe(take(1)).subscribe((orders) => {
            let errMessage = 'Es ist ein fehler aufgetreten.';
            const orderById = orders.length > 0 ? orders[0] : null;
            if (
              orderById && 
              (
                error && 
                error.error && 
                error.error.message.includes('Code still not provided') ||
                error.error.message.includes('Code not found')
              )
            ) {
              errMessage = `Die Aufladung der Bestellung [${orderById.orderRefId}] konnte nicht durchgeführt werden. ${error.error.message}`;                
            }
            this.notificationService.error(errMessage);
          });
        }
      );
  }
}
