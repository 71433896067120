import {BONUS_PROJECT_TYPES_BY_NAME} from "../../../../services/bonus/bonus_project_types";

const $inject = [
  '$scope',
  '$timeout',
  'BonusService'
];

export default class SetCheckoutDateDialogController {
  constructor(
    $scope,
    $timeout,
    BonusService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      BonusService
    });

    this.loading = {
      main: false
    };

    this.init();

    this.forms = {};
    this.models = {
      checkout_date: new Date()
    };
  }

  getMinLimitDate() {
    if (this.bonus_project && this.bonus_project.project_type === BONUS_PROJECT_TYPES_BY_NAME['fiducia_bonus']['id']) {
      return this.bonus_project.dates.bonus_project_start_date;
    }
  }

  getMaxLimitDate() {
    if (!this.bonus_project) {
      return;
    }

    if (this.bonus_project && this.bonus_project.project_type === BONUS_PROJECT_TYPES_BY_NAME['one_time_bonus']['id']) {
      return this.bonus_project.dates.user_decision_date;
    } else {
      return this.bonus_project.dates.bonus_project_end_date;
    }
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;
    this.customer_id = this.$scope.ngDialogData.customer_id;
    this.bonus_project = await this.BonusService.getBonusProject(this.customer_id, this.bonus_project_id);

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  set_date() {
    this.$scope.closeThisDialog(this.models.checkout_date);
  }
}

SetCheckoutDateDialogController.$inject = $inject;
