import { Component, Inject, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AdministrationService } from 'src/app/administration/services/administration.service';
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'vn-administration-mpp-import-leasingdata',
  templateUrl: './administration-mpp-import-leasingdata.component.html',
  styleUrls: ['./administration-mpp-import-leasingdata.component.scss']
})
export class AdministrationMppImportLeasingdataComponent extends BaseComponent implements OnInit {

  constructor(
    private service: AdministrationService,
    private alertService: AlertService,
  ) { 
    super()
  }

  ngOnInit(): void {
  }

  leasingDataImport(
    files: File[]
  ) {
    const {
      [0]: f
    } = files;

    if (!f) {
      throw new Error("Invalid file selection.")
    }

    this.setLoading$(true);

    this.service.importLeasingData(f).pipe(take(1)).subscribe(() => {
      this.setLoading$(false);

      this.alertService.message('Erfolgreich');
    }, e => {
      this.alertService.error('Fehlgeschlagen');
    })
  }

}
