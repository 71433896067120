const $inject = [
  '$q', '$scope', 'userService', '$timeout'
];
export default class ArbeitnehmerFreischaltenController {

  constructor($q, $scope, userService, $timeout) {

    this.$scope = $scope;
    this.userService = userService;
    this.$timeout = $timeout;
    this.employeeId = $scope.ngDialogData.employeeId;
    this.reInvite = $scope.ngDialogData.reInvite;
    this.error = false;
    this.working = false;
    this.userData = null;

    this.init().finally(() => {
      this.$timeout(() => {
        this.working = false
      })
    })
  }

  async init() {
    this.working = true;
    try {
      this.user = await this.userService.getMadeUserByValuenetId(this.employeeId, Date.now());
      this.userData = {
        email: this.user.emails[0] ? this.user.emails[0] : null,
        lastInvitedDate: this.user.invitation_date
      }
    } catch (e) {
      this.error = true;
    }
  }

  submit() {

    this.working = true;

    let promise = false;

    if (!this.user.cidaas_sub && !this.user.invitation_id) {
      promise = this.userService.inviteMadeUser(this.employeeId);
    } else if (this.user.cidaas_sub && this.user.invitation_id) {
      promise = this.userService.reInvite(this.employeeId);
    } else {
      promise = this.userService.invite(this.employeeId);
    }

    promise.then(
      () => this.$scope.confirm(0),
      error => {

        if (error.error.id === '8ff7cd6ae878dd2f5392cfa8b542bef8') {
          this.error = 'duplicateMail';
        } else if(error.error.message.includes('User already registered')) {
          this.error = 'alreadyRegisteredInCidaas'
        }
        else {
          this.error = true;
        }

        this.working = false;
      }
    );
  }


}
ArbeitnehmerFreischaltenController.$inject = $inject;
