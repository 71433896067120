import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectBonusCancelDialogComponent } from 'src/app/direct-bonus/components/backoffice/direct-bonus-cancel-dialog/direct-bonus-cancel-dialog.component';
import { ProjectActionsDialogData } from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';

export interface TocDialogData {
  title: string;
  text: string;
  buttonKey: string;
  readonlyDialog: boolean;
}

@Component({
  selector: 'vn-toc-checkbox-dialog',
  templateUrl: './toc-checkbox-dialog.component.html',
  styleUrls: ['./toc-checkbox-dialog.component.scss']
})
export class TocCheckboxDialogComponent implements OnInit {

  public get tocTitle() {
    return this.dialogData.title;
  }

  public get tocText() {
    return this.dialogData.text.split('\n');
  }

  public get readonlyDialog() {
    return this.dialogData.readonlyDialog;
  }

  public get buttonKey() {
    return this.dialogData.buttonKey;
  }

  constructor(
    public dialogRef: MatDialogRef<DirectBonusCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: TocDialogData
  ) { }

  ngOnInit(): void {
  }

}
