import {ArticleAvailability} from "./article-availability";
import {BasketArticle} from "./basket-article";

export class CatalogArticle {

  /**
   * @param {string} articleNo
   * @param name
   * @param grossPrice
   * @param netPrice
   * @param {ArticleAvailability} availability
   */
  constructor(articleNo, name, grossPrice, netPrice, availability) {
    this._articleNo = articleNo;
    this._name = name;
    this._grossPrice = grossPrice;
    this._netPrice = netPrice;
    this._availability = availability;
  }

  get articleNo() {
    return this._articleNo;
  }
  
  get name() {
    return this._name;
  }

  get grossPrice() {
    return this._grossPrice;
  }

  get netPrice() {
    return this._netPrice;
  }
  
  get availability() {
    return this._availability;
  }

  /**
   * @returns {BasketArticle}
   */
  getBasketArticle() {
    return new BasketArticle(this._articleNo, this._name, this._grossPrice, this._netPrice);
  }
  
}
