import { Injectable } from "@angular/core";
import { DirectBonusComponentType } from "../models/direct-bonus-project-overview-table-data.model";

@Injectable()
export class DirectBonusMapperService {

  constructor() {

  }

  public getDirectBonusComponentDisplayValue(componentType: DirectBonusComponentType) {
    switch (componentType) {
      case 'besonderer_anlass':
        return 'Besonderer Anlass';
      case 'sachbezug':
        return 'Sachbezug';
      default:
        throw new Error(`Invalid component type: ${componentType}`);
    }
  }
}