<h2 mat-dialog-title>
  Bulk Faktura
</h2>

<mat-dialog-content *ngIf="canContinue">
  Nach dem Klick auf “Speichern” wird eine Datei im CSV-Format für Ihr Fakturierungsprogramm heruntergeladen.
</mat-dialog-content>


<mat-dialog-content *ngIf="!canContinue">
  <p>
    Die folgenden Kunden sind in der MySQL Datenbank falsch eingerichtet.
    Bitte invoice email oder invoice payment type überprüfen
  </p>

  <ul>
    <li *ngFor="let problematic_order of dataStream$ | async">{{problematic_order.customerName}}</li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
          mat-dialog-close>
    Abbrechen
  </button>

  <button class='save-button'
          mat-stroked-button
          data-cy='vn.directBonus.backofficeAdministration.bulkInvoiceDialog.buttonSubmit'
          [disabled]="!canContinue"
          (click)="bulkMarkForInvoice()">
    Speichern
  </button>
</mat-dialog-actions>