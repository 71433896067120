const $inject = [
  '$transitions',
  '$state',
  'MenuConstants',
  'ShopConstants',
  'customerService',
  'NotificationService'
]
export default class CockpitShopController {

  constructor(
    $transitions,
    $state,
    MenuConstants,
    ShopConstants,
    customerService,
    NotificationService
  ) {

    Object.assign(this, {
      $transitions,
      $state,
      MenuConstants,
      ShopConstants,
      customerService,
      NotificationService
    });


    this.working = true;


    customerService
      .get($state.params.customerId)
      .then(customer => this.customer = customer);

    customerService
      .getConfiguration($state.params.customerId)
      .then(configuration => this.configuration = configuration)
      .then(() => this.working = false);

  }


  toggle(key) {

    if (!this.configuration.shop) {
      this.configuration.shop = {};
    }
    if (!this.configuration.shop[key]) {
      this.configuration.shop[key] = {
        enabled: true
      };
    }

    this.configuration.shop[key].enabled = !this.configuration.shop[key].enabled;
  }


  save() {

    this.working = true;

    this.customerService
      .setConfiguration(this.$state.params.customerId, this.configuration)
      .then(() => this.NotificationService.message('Speichern erfolgreich.'),
        () => this.NotificationService.error('Speichern fehlgeschlagen.'))
      .finally(() => this.working = false);

  }

}
CockpitShopController.$inject = $inject;
