import { CarLeasingCustomerConfiguration } from "./car-leasing-customer-configuration.model";
import { Car } from "./car.model";

export interface InclusiveKmPerMonth {
  km: number;
  price: number;
}

interface ContractTerms {
  duration: number;
  price: number;
}

export class CarLeasingCheckoutConfiguration {

  private _inclusiveKmPerMonth!: InclusiveKmPerMonth;
  private _contractTerms!: ContractTerms
  private _perMonthValue!: number;
  private _totalValue!: number;
  private _workHomeDistance: number|null = null;

  // refactor?
  constructor(
    private car: Car, // this is a NO!
    private customerConfiguration: CarLeasingCustomerConfiguration, // this too..
    public hasCompanyCar: boolean = false // this is how we should define the checkout config properties [EXPLICITLY]
  ) {
    this.init();
  }

  private init() {

    this._contractTerms = {
      duration: 0,
      price: 0
    };

    this._inclusiveKmPerMonth = {
      km: 0,
      price: 0
    };

  }
  
  public set perMonthValue(value: number) {
    this._perMonthValue = value;
    this._totalValue = this._perMonthValue * this.contractDuration;
  }

  public get totalValue() {
    return this._totalValue;
  }

  public get perMonthValue() {
    return this._perMonthValue;
  }

  public get workHomeDistance(): number|null {
    return this._workHomeDistance;
  }

  public set contractDuration(duration: number) {
    this._contractTerms.duration = duration;
    this._contractTerms.price = this.car.getBasePrice(duration);
  }

  public set contractDistance(distance: number) {
    const extraKm = this.car.getExtraKms().find(option => option.km === distance)

    this._inclusiveKmPerMonth = {
      km: extraKm!.km ?? 0,
      price: extraKm!.price ?? 0
    };
  }

  public set workHomeDistance(distance: number|null) {
    this._workHomeDistance = distance;
  }

  // ================== PUBLIC INTERFACE ==================

  public get carId(): string {
    return this.car.id;
  }

  public get additionalPricePerKm(): number {
    return this.car.price.extra_km_price;
  }

  public get carCosumption(): number {
    return this.car.consumption
  }

  public get carBrand(): string {
    return this.car.brand.id;
  }

  public get carModel(): string {
    return this.car.model;
  }

  public get carType(): string {
    return this.car.cartype;
  }

  public get carColor(): string {
    return this.car.color.id;
  }

  public get carFuelType(): string {
    return this.car.fuel;
  }

  public get msrp(): number {
    return this.car.price.msrp;
  }

  public get availableFrom(): string {
    return this.car.available_from;
  }

  public get financingType(): string {
    return this.car.financing_type;
  }

  public get power(): number {
    return this.car.power;
  }
  // ================== CALCULATION ==================

  public get extraKm(): InclusiveKmPerMonth {
    return this._inclusiveKmPerMonth;
  }

  public get maxKms() {
    return this._inclusiveKmPerMonth?.km * this._contractTerms?.duration
  }

  public get contractDuration() {
    return this._contractTerms?.duration;
  }

  public get finnPerMonthValueNet(): number {
    return -1;
  }

  public get finnPerMonthValueGross(): number {
    return this._inclusiveKmPerMonth?.price + this._contractTerms?.price;
  }

  public get finnPerMonthValue(): number {
    return this.customerConfiguration.exchangeType === 'brutto' ?
      this.finnPerMonthValueGross :
      this.finnPerMonthValueNet;
  }

  public get finnTotalValueGross() {
    return this.finnPerMonthValueGross * this.contractDuration;
  }

  public get finnTotalValueNet() {
    return this.finnPerMonthValueNet * this.contractDuration;
  }

  public get finnTotalValue() {
    return this.finnPerMonthValue * this.contractDuration;
  }

}
