import bonus_html from '../../modules/bonus/bonus.pug';
import header_html from '../../helpers/header.main.pug';
import main_layout_html from '../../modules/bonus/layouts/main_layout.pug';
import bonus_list_html from '../../modules/bonus/bonus_list/bonus_list.pug';
import bonus_dashboard_html from '../../modules/bonus/bonus_dashboard/bonus_dashboard.pug';
import side_html from '../../modules/bonus/bonus_side/side.pug';
import bonus_checkout_html from '../../modules/bonus/checkout/bonus_checkout.pug';
import fidApproval_html from '../../modules/bonus/fid_approval/FidApproval.pug';
import {
  BonusComponentControllerElectronicPresent
} from "../../modules/bonus/components/BonusComponentControllerElectronicPresent";

const BONUS_COMPONENT_ROUTES = {
  sachbezug: 'Sachbezug',
  sachbezug_profit: 'SachbezugProfit',
  sachbezug_pluxee: 'SachbezugPluxee',
  essensschecks_pst: 'Essenscheck',
  essensschecks_frei: 'Essenscheck',
  // essenscheck: 'Essenscheck',
  internet: 'Telecommunication',
  kinder: false,
  //bgmbudget: 'BGMBudget',
  bgmbudget: false,
  jobticket: false,
  egeschenk: 'ElectronicPresent',
  entfernung: 'Entfernung',
  dienstwagen_miete: 'Dienstwagen',
  dienstwagen_auslage: 'Dienstwagen',
  // werbeflaeche: false,
  handy: 'Telecommunication',
  personal_rabatt: 'PersonalRabatt',
  erholungsbeihilfe: 'Erholungsbeihilfe',
  // berufskleidung: false,
  // bav: false,
  festnetz: 'Telecommunication',
  // EBike: false,
  Bikeleasing: 'Bikeleasing',
  bike_1: 'Bikeleasing',
  VMA_STSVFREI: 'VMA',
  VMA_PST: 'VMA',
  pc: 'PC',
  pc_1: 'PC',
  VWL: false,
  BAV: 'BAV',
  wertguthaben: false,
  Freistellungstag: 'Freistellungstag'
};

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    const iAmApproval = ['authenticationService', 'ACLService', function (authenticationService, ACLService) {
      return ACLService.amIApprover();
    }];

    $stateProvider
      .state('inApp.bonus', {
        views: {
          main: {
            template: bonus_html,
            controller: 'BonusController'
          },
          header: {
            template: header_html
          }
        }
      })
      .state('inApp.bonus.dashboard', {
        url: '/bonus_dashboard',
        views: {
          bonusMain: {
            template: main_layout_html,
          }
        }
      })
      .state('inApp.bonus.dashboard.projects_list', {
        url: '/projects_list',
        views: {
          sideMain: {
            template: bonus_list_html,
            controller: 'BonusListController',
            controllerAs: '$bonus_list_ctrl'
          }
        }
      })
      .state('inApp.bonus.dashboard.main', {
        url: '/:bonusProjectId',
        params: {
          bonusProjectId: null,
          parent_state: 'bonus',
          mpp_data: null,
        },
        views: {
          sideMain: {
            template: bonus_dashboard_html,
            controller: 'BonusDashboardController',
            controllerAs: '$bonus_dashboard_ctrl'
          },
          side: {
            controller: 'bonusSideController',
            controllerAs: '$bonus_side_ctrl',
            template: side_html
          }
        }
      })
      .state('inApp.bonus.checkout', {
        url: '/bonus_dashboard/:bonusProjectId/checkout',
        params: {
          bonusProjectId: null,
          parent_state: 'bonus'
        },
        views: {
          bonusMain: {
            template: bonus_checkout_html,
            controller: 'BonusCheckoutController',
            controllerAs: '$bonus_checkout_ctrl'
          }
        }
      })
      .state('inApp.bonus.approvals', {
        url: '/approvals',
        resolve: {
          authorized: iAmApproval
        },
        views: {
          bonusMain: {
            template: fidApproval_html,
            controller: 'FidApprovalController',
            controllerAs: '$ctrl'
          }
        }
      })
      ;


    for (let bonus_component_name in BONUS_COMPONENT_ROUTES) {
      $stateProvider.state(`inApp.bonus.dashboard.module${bonus_component_name}`, {
        url: `/:bonusProjectId/${bonus_component_name}`,
        params: {
          bonusComponentName: bonus_component_name,
          bonusId: null,
          bonusProjectId: null,
          bonuses: null,
          bonusesById: null,
          bonusProject: null
        },
        views: {
          sideMain: {
            template: require(`../../modules/bonus/components/views/${bonus_component_name}.pug`),
            controller: 'BonusComponentController' + (BONUS_COMPONENT_ROUTES[bonus_component_name] ? BONUS_COMPONENT_ROUTES[bonus_component_name] : ''),
            controllerAs: 'component'
          },
          side: {
            controller: 'bonusSideController',
            controllerAs: '$bonus_side_ctrl',
            template: side_html
          }
        }
      });
    }

  }]);
