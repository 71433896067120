import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EMPTY, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export type SelectOption<T = string> = {
  name: string;
  value: T;
}

@Component({
  selector: 'vn-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  public key!: string;

  @Input()
  public label = '';

  @Input()
  public width!: string;

  @Input()
  public placeholder = '';

  @Input()
  public isDisabled = false;

  @Input()
  public withPlaceholder = true;

  @Input()
  public initValue?: string;

  @ViewChild('formSelect')
  formSelect!: ElementRef<HTMLInputElement>;

  @Output()
  public selectionChange$: Observable<string> = EMPTY;

  @Output()
  public click = new EventEmitter<MouseEvent>();

  // check public setDisabledState
  private formDisabled = false;
  private touched = false;

  public options!: SelectOption[];
  @Input('options')
  public set _options(options: string[] | SelectOption[] | undefined) {
    if (!options) {
      this.options = [];
    } else {
      this.options = options.map(
        (option: string | SelectOption) => typeof option === 'string' ?
          { name: option, value: option } :
          option
      );
    }
  }

  private formControlValue!: string;
  public get value() {
    return this.formControlValue
  }

  public onTouched = () => { };
  public onChange = (_: string) => { };

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.selectionChange$ = fromEvent(this.formSelect.nativeElement, 'change').pipe(
      map((event) => (event.target as HTMLSelectElement).value),
      distinctUntilChanged(),
    );
  }

  public writeValue(value: string) {
    this.formControlValue = value;
  }

  public registerOnChange(onChange: (_: string) => any) {
    this.onChange = onChange;

    if (!this.withPlaceholder) {
      const initValue = this.initValue || this.options[0]?.value;
      this.writeValue(initValue);
      this.onChange(initValue);
    }
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // != <tag [disabled]='true'> -> use public isDisable instead
  // excludes formControl from fromGroup.valueChanges$
  // called with new FormControl({value: 123, disabled: true}, [Validators...])
  public setDisabledState(formDisabled: boolean) {
    this.formDisabled = formDisabled;
  }

  public onClick(event: MouseEvent) {
    this.click.emit(event);
  }
}
