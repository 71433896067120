const $inject = [
  '$scope',
  '$location',
  'SupportService',
  'NotificationService'
];
export default class SupportDialogController {
  constructor(
    $scope,
    $location,
    SupportService,
    NotificationService
  ) {

    Object.assign(this, {
      $scope,
      $location,
      SupportService,
      NotificationService
    });

    this.loading = {
      sending: false
    };
    this.forms = {};

    this.models = {
      support: {
        category: null,
        subject: '',
        message: '',
        user_current_url: this.$location.absUrl(),
      },
      attachments: []
    };

    this.consentServiceName = 'ASANA';
    this.consentServiceAccepted = false;

    this.init();
  }

  init() {
    this.support_categories = this.SupportService.getSupportCategories([0, 4, 5, 6, 1, 2]);
  }

  close() {
    this.$scope.closeThisDialog();
  }

  send() {
    this.loading.sending = true;
    this.SupportService.sendSupportRequest(this.models.support, this.models.attachments)
      .then((response) => {
        this.loading.sending = false;
        this.NotificationService.message('Ihre Anfrage wurde übermittelt.');
        this.$scope.confirm({});
      }).catch((err) => {
      this.NotificationService.error('Fehlgeschlagen.');
      console.error(err);
      this.close();
    });
  }

  handleStatus(status) {
    this.consentServiceAccepted = status
  }

}
SupportDialogController.$inject = $inject;
