var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (containerAttributes, fieldsetCounter, fileInputAttributes, globals, id, validationId) {
      pug_mixins["dropzone"] = pug_interp = function(model, single, id, tags, employeeId, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
containerAttributes = {}
fileInputAttributes = {}
if (single) {
containerAttributes = { 'made-dropzone-single' : 'true' }
fileInputAttributes = { 'made-file-single' : 'true' }
}
else {
fileInputAttributes = { multiple : 'multiple' }
}
if (!id) {
id = 'uploader'
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "dropzone-container","made-dropzone": pug.escape(model)},containerAttributes,attributes]), true)) + "\u003E\u003Cdiv class=\"dropzone-inner-container\"\u003E";
if (employeeId) {
pug_html = pug_html + "\u003Cfile-chooser" + (pug.attr("files", model, true, true)+pug.attr("filter-tags", tags, true, true)+pug.attr("employee-id", employeeId, true, true)) + "\u003E\u003C\u002Ffile-chooser\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + ("\u003Cdiv class=\"drag-and-drop-label\"\u003E\u003Cimg src=\"\u002Fassets\u002Fimg\u002Fsvg\u002Ficon-download.svg\"\u003E" + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp)));
if (single) {
pug_html = pug_html + "\u003Cspan\u003EDatei hierherziehen\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003EDateien hierherziehen\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E\u003Cdiv class=\"file-input\"\u003E\u003Cinput" + (pug.attrs(pug.merge([{"id": pug.escape(id),"type": "file","made-file-input": pug.escape(model),"style": "visibility:hidden","data-cy": pug.escape(cyData)},fileInputAttributes]), true)) + "\u003E";
if (single) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDatei auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
else {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDateien auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"files\"\u003E";
if (block) {
block && block();
}
else
if (single) {
pug_html = pug_html + "\u003Cspan" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)+pug.attr("ng-click", model + '=undefined', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("ng-if", model +'._id', true, true)+pug.attr("ng-href", '/gridfs?id={{'+ model +'._id}}', true, true)+" target=\"_blank\" rel=\"noreferrer\"") + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"files\""+pug.attr("ng-repeat", '(index, file) in ' + model, true, true)) + "\u003E\u003Cspan ng-if=\"!file._id\"\u003E{{ file.name }} ({{ file.size | filesize }})\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-click", model + '.splice(index, 1)', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca ng-if=\"file._id\" ng-href=\"\u002Fgridfs?id={{ file._id }}\" target=\"_blank\" rel=\"noreferrer\"\u003E{{ file.filename }} ({{ file.meta.created *1000 | date }}, {{ file.length | filesize }})\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["textarea"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
if (!attributes.trim) {
attributes.trim = false
}
if (!attributes.rows) {
attributes.rows = 5
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"control-label\""+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Ctextarea" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"rows": pug.escape(attributes.rows),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"ng-trim": pug.escape(attributes.trim),"name": pug.escape(id),"required": pug.escape(attributes.required),"disabled": pug.escape(attributes.disabled)},attributes.attributes]), true)) + "\u003E\u003C\u002Ftextarea\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};













pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["radios"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"form-group schema-form-radios-inline\"\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel class=\"control-label\"\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp));
if (attributes.titleMap) {
pug_html = pug_html + "\u003Cul" + (pug.attr("class", pug.classes(['small-block-grid-'+ attributes.titleMap.length], [true]), false, true)) + "\u003E";
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var option = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["radio"].call({
attributes: pug.merge([{"index": pug.escape(index),"key": pug.escape(attributes.key)},option])
});
pug_html = pug_html + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var option = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["radio"].call({
attributes: pug.merge([{"index": pug.escape(index),"key": pug.escape(attributes.key)},option])
});
pug_html = pug_html + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
else
if (attributes.titleMapLength) {
pug_html = pug_html + "\u003Cul" + (pug.attr("class", pug.classes(['small-block-grid-' + attributes.titleMapLength], [true]), false, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Ful\u003E";
}
else {
block && block();
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};









pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cform class=\"form-padding\" name=\"cmsform\" ng-submit=\"create.submit()\"\u003E";
globals = {}
pug_html = pug_html + "\u003Cdiv class=\"bigbox centered\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EInhalte\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cdiv class=\"row\" ng-if=\"filters.customer.customerList &amp;&amp; iAmSpecialAdvisor\"\u003E\u003Cui-select ng-model=\"filters.customer.instance\" theme=\"select2\" style=\"min-width: 300px;\" title=\"Choose a customer\"\u003E\u003Cui-select-match placeholder=\"Select a customer by name or customerId\"\u003E\u003Cspan ng-bind=\"filters.customer.instance.name\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fui-select-match\u003E\u003Cui-select-choices repeat=\"item in (filters.customer.customerList | filter: $select.search) track by item.id\"\u003E\u003Cspan ng-bind=\"item.name\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fui-select-choices\u003E\u003C\u002Fui-select\u003E\u003Cui-select ng-model=\"filters.template.instance\" theme=\"select2\" style=\"min-width: 300px;\" title=\"Choose a template\"\u003E\u003Cui-select-match placeholder=\"Select a template\"\u003E\u003Cspan ng-bind=\"filters.template.instance.title\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fui-select-match\u003E\u003Cui-select-choices repeat=\"item in (filters.template.templateList | filter: $select.search) track by $index\"\u003E\u003Cspan ng-bind=\"item.title\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fui-select-choices\u003E\u003C\u002Fui-select\u003E\u003Cbutton class=\"button-primary\" ng-disabled=\"!filters.template.instance || !filters.customer.instance\" ng-click=\"create.loadTemplate()\" type=\"button\"\u003ELoad template\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
pug_mixins["input"].call({
attributes: {"type": "text","key": "create.cmsContent.title","title": "Titel","required": pug.escape(true)}
});
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"control-label\" for=\"create.cmsContent.category\"\u003EKategorie\u003C\u002Flabel\u003E\u003Cselect name=\"create.cmsContent.category\" ng-model=\"create.cmsContent.category\" id=\"create.cmsContent.category\" title=\"Titel\"\u003E\u003Coption value=\"\"\u003EKeine Kategorie\u003C\u002Foption\u003E\u003Coption ng-repeat=\"category in create.categories\" value=\"{{category._id}}\"\u003E{{category.title}}\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchUseDownloads","title": "Downloads anhängen","key": "create.cmsContent.showDownloads"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchUseButton","title": "Auf externe Seite verlinken","key": "create.cmsContent.useButton"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchUseAccess","title": "Zugriff beschränken","key": "create.cmsContent.useAccess"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchUseTimes","title": "Anzeigezeitraum angeben","key": "create.cmsContent.useTimes"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "switchPinnedPost","title": "Gepinnte Artikel","key": "create.cmsContent.pinned"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "sendMail","title": "Mail Link erstellen","key": "create.cmsContent.mail "}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "embedVideo","title": "Video einbinden","key": "create.cmsContent.embedVideo"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "userlane","title": "Userlane","key": "create.cmsContent.useUserlane"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "is_html","title": "HTML","key": "create.cmsContent.is_html"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Clabel for=\"cms-content\"\u003EInhalt\u003C\u002Flabel\u003E\u003Cdiv ng-if=\"!create.cmsContent.is_html\"\u003E\u003Cdiv class=\"quill-editor\"\u003E\u003Cng-quill-editor class=\"quill-content\" id=\"cms-content\" ng-model=\"create.cmsContent.content\" required=\"true\" ng-required=\"true\" editor-required=\"true\" theme=\"snow\"\u003E\u003C\u002Fng-quill-editor\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-12\" ng-if=\"create.cmsContent.is_html\"\u003E\u003Cdiv\u003E\u003Ctextarea ng-model=\"create.cmsContent.content\" rows=\"10\" required=\"true\" ng-required=\"true\"\u003E\u003C\u002Ftextarea\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cdiv ng-bind-html=\"create.cmsContent.content | trustHtml\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EBühnenbild\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cp\u003Eoptimale Bildmaße: 1920 x 410px\u003C\u002Fp\u003E";
pug_mixins["dropzone"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"files\" ng-if=\"create.cmsContent.stage\"\u003E\u003Cspan ng-if=\"!create.cmsContent.stage._id\"\u003E{{ create.cmsContent.stage.name }} ({{ create.cmsContent.stage.size | filesize }})\u003C\u002Fspan\u003E\u003Ca ng-if=\"create.cmsContent.stage._id\" ng-click=\"create.openDocument(create.cmsContent.stage)\"\u003E{{ create.cmsContent.stage.name }} ({{ create.cmsContent.stage.size | filesize }})\u003C\u002Fa\u003E\u003Ca ng-click=\"create.cmsContent.stage=null\"\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}, 'create.cmsContent.stage', true, 'cms-stage-image');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003ETeaserbild\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cp\u003Eoptimale Bildmaße: 530 x 325px\u003C\u002Fp\u003E";
pug_mixins["dropzone"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"files\" ng-if=\"create.cmsContent.teaser\"\u003E\u003Cspan ng-if=\"!create.cmsContent.teaser._id\"\u003E{{ create.cmsContent.teaser.name }} ({{ create.cmsContent.teaser.size | filesize }})\u003C\u002Fspan\u003E\u003Ca ng-if=\"create.cmsContent.teaser._id\" ng-click=\"create.openDocument(create.cmsContent.teaser)\"\u003E{{ create.cmsContent.teaser.name }} ({{ create.cmsContent.teaser.size | filesize }})\u003C\u002Fa\u003E\u003Ca ng-click=\"create.cmsContent.teaser=null\"\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}, 'create.cmsContent.teaser', true, 'cms-teaser-image');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\" ng-if=\"create.cmsContent.showDownloads\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EAnhang\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E";
pug_mixins["dropzone"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"files\" ng-repeat=\"(index, file) in create.cmsContent.download\"\u003E\u003Cspan ng-if=\"!file._id\"\u003E{{ file.name }} ({{ file.size | filesize }})\u003C\u002Fspan\u003E\u003Ca ng-if=\"file._id\" ng-click=\"create.openDocument(file)\"\u003E{{ file.name }} ({{ file.size | filesize }})\u003C\u002Fa\u003E\u003Ca ng-click=\"create.cmsContent.download.splice(index, 1)\"\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}, 'create.cmsContent.download', false, 'cms-download');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\" ng-if=\"create.cmsContent.useButton\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EButton\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E";
pug_mixins["input"].call({
attributes: {"type": "text","key": "create.cmsContent.button.text","title": "Button-Text"}
});
pug_mixins["input"].call({
attributes: {"type": "text","key": "create.cmsContent.button.target","title": "Ziel-Seite"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\" ng-if=\"create.cmsContent.useUserlane\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EUserlane\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E";
pug_mixins["input"].call({
attributes: {"type": "text","key": "create.cmsContent.userlane.text","title": "Button Text"}
});
pug_mixins["input"].call({
attributes: {"type": "text","key": "create.cmsContent.userlane.userlaneId","title": "Userlane ID"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\" ng-if=\"create.cmsContent.mail\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EMail link\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E";
pug_mixins["input"].call({
attributes: {"type": "text","required": pug.escape(true),"key": "create.cmsContent.mailForm.recipient","title": "Empfänger E-Mail Adresse eingeben"}
});
pug_mixins["input"].call({
attributes: {"type": "text","key": "create.cmsContent.mailForm.subject","title": "Betreff"}
});
pug_mixins["textarea"].call({
attributes: {"key": "create.cmsContent.mailForm.message","title": "Mail text"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\" ng-if=\"create.cmsContent.useAccess\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EGültigkeit\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "create.cmsContent.filterType","title": "gültig für","attributes": {"ng-change":"create.updateFilterOptions()"},"titleMap": [{"value":"1","name":"Alle"},{"value":"2","name":"Standorte"}],"required": pug.escape(true)}
});
pug_html = pug_html + "\u003Cdiv ng-if=\"create.cmsContent.filterType == &quot;2&quot;\"\u003E\u003Cdiv ng-repeat=\"location in create.locations\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"small-9 columns component-col\"\u003E\u003Clabel for=\"locations{{ location.id }}\" tabindex=\"1\"\u003E\u003Ch5\u003E\u003Cspan class=\"component-name\"\u003E{{ location.name }}\u003C\u002Fspan\u003E\u003C\u002Fh5\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"small-3 columns text-right\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "locations{{ location.id }}","key": "location.selected"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\" ng-if=\"create.cmsContent.useTimes\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EAnzeigezeitraum\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"startDateInput\" date-refocus=\"true\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "create.cmsContent.startdate","title": "Beginndatum","attributes": {"vn-date-helper":"true","ng-model-options":"{allowInvalid: false}","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","pattern":"^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$"},"fieldHtmlClass": "startDateInput","validationMessages": {"pattern":"Bitte Beginn im Format tt.mm.jjjj eingeben"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"endDateInput\" date-refocus=\"true\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "create.cmsContent.enddate","title": "Enddatum","attributes": {"vn-date-helper":"true","ng-model-options":"{allowInvalid: false}","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","pattern":"^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$"},"fieldHtmlClass": "endDateInput","validationMessages": {"pattern":"Bitte Ende im Format tt.mm.jjjj eingeben"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\" ng-if=\"create.cmsContent.embedVideo\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EVideo einbinden\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["radios"].call({
attributes: {"key": "create.cmsContent.videoPlatform","title": "Video Plattform","titleMap": [{"value":"youtube","name":"Youtube","required":true},{"value":"vimeo","name":"Vimeo","required":true}]}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cdiv ng-if=\"!create.cmsContent.videoPlatform\"\u003EBitte Plattform wählen\u003C\u002Fdiv\u003E";
pug_mixins["input"].call({
attributes: {"key": "create.cmsContent.videoId","title": "{{create.getPlatformHint()}}","placeholder": "Video ID","type": "text","required": true}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-12\"\u003E\u003Cdiv\u003EUm die bestmögliche Einbindung zu gewährleisten wird nur der Video-Code des Streaming-Dienstes verwendet. Diesen entnehmen Sie am Besten dem Link, der über Teilen angeboten wird.\u003C\u002Fdiv\u003E\u003Cdiv\u003EAm Beispiel YouTube: https:\u002F\u002Fyoutu.be\u002F\u003Cstrong\u003EG37843ApY45QI\u003C\u002Fstrong\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003EAm Beispiel Vimeo: https:\u002F\u002Fvimeo.com\u002F\u003Cstrong\u003E397982678\u003C\u002Fstrong\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"button button-primary\" type=\"submit\" ng-disabled=\"!create.$scope.cmsform.$valid\"\u003ESpeichern\u003C\u002Fbutton\u003E\u003C\u002Fform\u003E";
    }.call(this, "containerAttributes" in locals_for_with ?
        locals_for_with.containerAttributes :
        typeof containerAttributes !== 'undefined' ? containerAttributes : undefined, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "fileInputAttributes" in locals_for_with ?
        locals_for_with.fileInputAttributes :
        typeof fileInputAttributes !== 'undefined' ? fileInputAttributes : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;