

const $inject = [
  '$scope',
  '$state',
  'userService',
  'employeeService',
  'vbmData',
  'Made',
  'WizardHandler',
  '$timeout',
  'NotificationService',
  'administrationService',
  '$window',
  'moment',
  'lodash',
  'authenticationService',
  'NeoComponentsService',
  'BonusService',
  'mppService',
  'CheckoutService',
  'customerService',
  'ByteplantService',
  'MatDialogService'
];

export class StandaloneComponentCheckoutController {
  constructor(
    $scope,
    $state,
    userService,
    employeeService,
    vbmData,
    Made,
    WizardHandler,
    $timeout,
    NotificationService,
    administrationService,
    $window,
    moment,
    lodash,
    authenticationService,
    NeoComponentsService,
    BonusService,
    mppService,
    CheckoutService,
    customerService,
    ByteplantService,
    MatDialogService
  ) {
    Object.assign(this, {
      $scope,
      $state,
      userService,
      employeeService,
      vbmData,
      Made,
      WizardHandler,
      $timeout,
      NotificationService,
      administrationService,
      $window,
      moment,
      lodash,
      authenticationService,
      NeoComponentsService,
      BonusService,
      mppService,
      CheckoutService,
      customerService,
      ByteplantService,
      MatDialogService
    });

    this.loading = {
      special_custs: true,
      summary: true,
      confirm_delivery_address: true,
      accept_GDPR: true,
      contract_preview: false,
      finalize_checkout: false,
      updating_address: false,
      file: true,
      present_preview: false
    };

    this.forms = {
      GDPRForm: {
        privateEmailAddress: null,
        privatePhoneNumber: null,
        tocAccepted: false
      }
    };

    this.$scope.forms = this.forms;

    this.errors = {
      generate_contract: false
    };

    this.isPrivateContactsValid = {
      phone: false,
      email: false,
    };

    this.isPrufenButtonDisabled = false;
    this.isPrufenButtonProved = false;
    this.showPrufenErrorMessage = false;


    this.$scope.$watch('forms.confirmAddressForm.$valid', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        if (newVal) {
          this.isPrufenButtonProved = !newVal;
        }
      }
    });

    let set_up_promises = [];

    set_up_promises.push(
      this.employeeService.getCustomerId(this.employee_id).then((customerId) => {
        this.customerId = customerId;
      })
    );
    this.set_up_promise = Promise.all(set_up_promises).finally(() => {
      // this.validateCart();
      this.$timeout(() => {
        this.loading.special_custs = false;
      }, 0);
    });
    this.initSummary()
      .finally(() => {
        this.$timeout(() => {
          this.loading.summary = false;
        });
      });

    // must be on scope in order to have access to CTRL when called by the Wizard Directive
    this.$scope.canEnterConfirmAddress = () => {
      this.loading.confirm_delivery_address = true;
      this.initConfirmDeliveryAddress().finally(() => {
        this.$timeout(() => {
          this.loading.confirm_delivery_address = false;
        }, 0);
      });
      return true;
    };

    this.$scope.canEnterContactPreview = () => {
      this.loading.contract_preview = true;
      this.getPCComponentContractURL()
        .catch(() => {
          this.errors.generate_contract = true;
        })
        .finally(() => {
          this.$timeout(() => {
            this.loading.contract_preview = false;
          });
        });
      return true;
    };

    this.$scope.canEnterPresentPreview = () => {
      this.loading.present_preview = true;
      this.getPCPresent()
        .catch(() => {
          this.errors.present_preview = true;
        })
        .finally(() => {
          this.$timeout(() => {
            this.loading.present_preview = false;
          });
        });
      return true;
    };

  }

  generatePresent(basket) {
    this.$timeout(() => {
      this.loading.file = true;
    });

    let operation;
    operation = this.administrationService.generatePresent(basket);

    return operation.then(res=>{
      this.$window.open(res.response, '_blank');
    }).finally(( ) => {
      this.$timeout(() => {
        this.loading.file = false;
      });
    });

  }

  updateAddressInput() {
    this.isPrufenButtonProved = false;
  }

  isAddressRequired() {
    this.is_address_required = true;
    return this.is_address_required;
  }

  getPCBasketId() {
    // TODO: implement
  }

  handleUserInfoFormSubmit() {
    this.loading.updating_address = true;
    let address = {
      ort: this.employee_address.city,
      plz: this.employee_address.zip,
      strasse: this.employee_address.street
    };

    this.employeeService.updateAddressData({ address: address }, this.employee_id).then((res) => {
      this.loading.updating_address = false;
    }).catch((err) => {
      console.log(err);
    }).finally(async () => {
      this.present_url = await this.getPCPresent();
      this.WizardHandler.wizard().next();
    });

  }

  isAdditionalTOCRequired() {
    this.is_toc_required = false;
    return this.is_toc_required;
  }

  isSummaryValid() {
    return !this.hasComponents() && Object.keys(this.errors).reduce((acc, error_key) => {
      let result = this.errors[error_key] !== true;

      // if I am special advisor this error is not a problem VN-1182
      if (error_key === 'mppPriceError' && this.iAmSpecialAdvisor) {
        result = true;
      }

      acc = acc && result;
      return acc;
    }, true);
  }

  async initConfirmDeliveryAddress() {
    this.employee = await this.employeeService.get(this.employee_id, Date.now());
    this.employee_address = this.employee.address;
  }

  async initSummary() {
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.employee_id = this.vbmData.employeeid || this.Made.user.valuenet_id;
    this.checkout_basket = this.$state.params.checkout_basket;
    this.ev_begin_date = await this.CheckoutService.getEvBeginMinDate();
    this.basket_start_end_date = await this.mppService.calculateBeginEndDate({ begin_date: this.ev_begin_date });
    this.basket_start_end_date.begin_date = this.moment.unix(this.basket_start_end_date.begin_date).format('DD.MM.YYYY');
    this.basket_start_end_date.end_date = this.moment.unix(this.basket_start_end_date.end_date).format('DD.MM.YYYY');
    this.customer_configuration = await this.customerService.getConfigurationByEmployeeId(this.employee_id)
    this.is_special_customer = await this.mppService.is_customer_for_electronic_present(this.customerId);

    this.availableElectronicPresentComponents = await this.BonusService.getAvailableElectronicPresentComponentsAndBudget(this.employee_id);
    this.electronicPresentTotalValue = 0;
    this.hasEnoughBonusRestBudget = false;
    if (this.availableElectronicPresentComponents.is_available) {
      this.hasAvailableElectronicPresent = true;
      this.electronicPresentTotalValue = this.checkout_basket.per_month_value;
      this.hasEnoughBonusRestBudget = this.availableElectronicPresentComponents.rest_bonus > this.electronicPresentTotalValue;
    }

    // sent to inner components to gather errors
    this.errors = {};

    this.step = this.WizardHandler.wizard().currentStepNumber;
    this.steps = this.WizardHandler.wizard().totalStepCount;
  }

  hasPCComponent() {
    if (!this.checkout_basket) {
      return false;
    }

    return this.checkout_basket.hasComponent('pc');
  }

  getPCComponent() {
    if (!this.checkout_basket) {
      return [];
    }

    let pc = this.checkout_basket.getComponent('pc');
    return pc;
  }

  async getElectronicPresentTotalValue() {

    let pc = this.checkout_basket.getComponent('pc');
    return pc.per_month_value;
  }

  async getPCComponentContractURL() {
    let pc = this.checkout_basket.getComponent('pc');

    let data = await pc.generateContract();

    this.contract_url = data.response;

    return this.contract_url;
  }

  getContractUrl() {
    return this.contract_url;
  }

  async getPCPresent() {
    let pc = this.checkout_basket.getComponent('pc');

    let data = await pc.generatePresent();

    return data.response;
  }

  getPresentUrl() {
    return this.present_url;
  }

  hasBoniNr() {
    return this.lodash.get(this.customer_configration, 'neo_config.pc.boni_nr')
  }

  hasComponents() {
    if (!this.checkout_basket) {
      return false;
    }

    return this.checkout_basket.hasComponent();
  }

  getPCLeasingReport() {
    // TODO: define the report for the PC
  }

  proceed() {
    this.WizardHandler.wizard().next();
  }

  async wizardFinished() {
    this.$timeout(() => {
      this.loading.finalize_checkout = true;
    });

    try {
      await this.NeoComponentsService.completeCheckout(this.checkout_basket);
      this.navigateToDashboard();
    } catch {
      this.NotificationService.error('Der Abruf von PC-Leasing ist akuell nicht möglich.');
    }

    this.$timeout(() => {
      this.loading.finalize_checkout = false;
    });
  }

  navigateToMppPortal() {
    this.$state.go('inApp.mpp.side.products.landing', { neo_data: { neo_component: this.getPCComponent() } });
  }

  wizardCanceled() {
    this.navigateToDashboard();
  }

  navigateToDashboard(reload = true) {
    this.$state.go('inApp.neo.side.verguetung', { reload: reload });
  }

  isAllowToPassGDPRStep() {
    return this.forms.GDPRForm.tocAccepted
  }

  isAllowToPassContactStep() {
    return this.forms.confirmAddressForm.$valid &&
      this.isPrivateContactsValid.email &&
      this.isPrivateContactsValid.phone &&
      this.isPrufenButtonProved
  }

  updatePrivateEmailAddress(emailAddress) {
    if (emailAddress) {
      this.isPrivateContactsValid.email = true;
      this.forms.privateEmailAddress = emailAddress;
      this.checkout_basket.addContactInformation('emailAddress', emailAddress);
    } else {
      this.isPrivateContactsValid.email = false;
    }
  }

  updatePrivatePhoneNumber(phoneNumber) {
    if (phoneNumber) {
      this.isPrivateContactsValid.phone = true;
      this.forms.privatePhoneNumber = phoneNumber;
      this.checkout_basket.addContactInformation('phoneNumber', phoneNumber);
    } else {
      this.isPrivateContactsValid.phone = false;
    }
  }

  async prufenClicked(isClicked) {
    this.isPrufenButtonDisabled = true; // loading starts
    const employeeAddress = {
      city: this.employee_address.city,
      zip: this.employee_address.zip,
      street: this.employee_address.street
    }

    const promiseResult = await this.ByteplantService.isAddressValid(employeeAddress);

    this.isPrufenButtonProved = promiseResult;
    this.showPrufenErrorMessage = !this.isPrufenButtonProved;
    this.isPrufenButtonDisabled = false; // loading ends
  }


  async requestElectronicPresentCashback() {
    this.$timeout(() => {
      this.loading.finalize_checkout = true;
    });

    let pc = null;
    try {
      pc = this.checkout_basket.getComponent('pc');
    } catch (e) {
      this.NotificationService.error("Ein Fehler ist aufgetreten.");
      return;
    }

    let basket_id = pc.basket._id;

    if (!basket_id) {
      this.NotificationService.error("Ein Fehler ist aufgetreten.");
      return;
    }

    const requestPayload = {
        "employeeData": {
          'id': this.employee_id,
          'currentUrl': 'mpp_checkout'
        },
        "bonusData": {
          'bonusProjectId': this.availableElectronicPresentComponents.bonus_project_id,
          'name': 'egeschenk'
        },
        "component": 'egeschenk',
        "requestedValue": this.electronicPresentTotalValue,
        "fileUrl": this.present_url,
        "files": [],
        "firstValidDate": "2024-11-01",
        "receiptDate": "2024-11-01",
        "receiptValidPeriod": "one_month"
      };

      return this.BonusService.createEgeschenkCashbackRequest(
        requestPayload,
        basket_id
        ).then(
        (res) => {
          if (res['cashback_creation']['is_successful']) {
            this.NotificationService.message('Bestellung ist erfolgreich gesendet');
          } else {
            if (res['cashback_creation']['error_msg'] == 'max_requests_for_current_month') {
              this.NotificationService.error('Es ist nur eine Bestellung im Monat möglich');
            } else if (res['cashback_creation']['error_msg'].startsWith('Der Belegwert überschreitet')) {
              this.NotificationService.error(res['cashback_creation']['error_msg']); //TODO: 2188 refactor error handling here
            } else {
              this.NotificationService.error("Ein Fehler ist aufgetreten.");
            }
          }
        }).catch((err) => {
          this.NotificationService.error('Fehlgeschlagen.');
      }).finally(() => {
        //TODO: 2188 go to bonus dashboard if you have only one bonus project
        this.$state.go('inApp.bonus.dashboard.projects_list');
        this.$timeout(() => {
          this.loading.finalize_checkout = true;
        });
      })
  }

}


StandaloneComponentCheckoutController.$inject = $inject;
