<h1 class="title">
    Ihr Vorteilskonto Warenkorb
</h1>

<ng-container *ngIf="emptyBasket; else checkout">
    <h5 class="text">
        Es wurden noch keine Gutscheine gewählt.
    </h5>
</ng-container>

<ng-template #checkout>
    <h5 class="text">
        Aktuell befinden sich folgende Gutscheine in Ihrem Warenkorb:
    </h5>

    <div class="table">
        <vn-benefit-checkout-table [tableData]='basketList'>
        </vn-benefit-checkout-table>
    </div>

    <p class='text'>
        Um die aufgelisteten Gutscheine nun abschließend zu bestellen, akzeptieren Sie die Einlösebedingungen und
        klicken
        Sie auf "<b>Gutscheine abrufen</b>".
    </p>

    <p class='text'>
        Mit diesem Vorgang wird Ihr aktuelles Guthaben von <b>{{ userBalance$ | async }} €</b>
        mit <b>{{ basketSum$ | async }} €</b> belastet.
    </p>

    <p class='text'>
        Nach dem Abruf beträgt das verbleibendes Guthaben noch <b>{{ restBalance$ | async }} €</b>.
    </p>

    <p class='text'>
        Je nach Gutscheinart erhalten Sie die bestellten Gutscheine per E-Mail oder postalisch.
    </p>

    <p class='text'>
        Bitte prüfen Sie die Angaben und nehmen ggf. Anpassungen vor.
        Änderungen hier werden nicht gespeichert und sind nur
        einmalig für die Gutscheinbestellung relevant.
    </p>

    <form class="employee-form"
          [formGroup]='checkoutForm'>

        <vn-email-validator
            formControlName='email'
            [isCompanyEmailAllowed]="true"
            [initEmailValue]="getEmailInitiValue()"
            title="E-Mail Adresse"
            (isEmailValid$)="handleValidatorResult($event)"

            dataCy="benefit.bonago.checkout.basketOverview"
        >

        </vn-email-validator>

        <vn-input formControlName='street'
                  label="Straße">
        </vn-input>

        <vn-input formControlName='postcode'
                  type="number"
                  label="PLZ">
        </vn-input>

        <vn-input formControlName='city'
                  label="Stadt">
        </vn-input>

        <vn-bonago-toc [formGroup]="checkoutForm">
        </vn-bonago-toc>
    </form>

    <div class="action-buttons">
        <button mat-raised-button
                (click)="cancelCheckout()"
                class="cancel-checkout">
            Abbrechen
        </button>

        <button mat-raised-button
                (click)="completeCheckout()"
                data-cy="benefit.bonago.checkout.basketOverview.buttonFinishCheckout"
                [disabled]="!canCheckout || (isCheckoutRequested$() | async)"
                class="complete-checkout">
            Gutscheine abrufen
        </button>
    </div>
</ng-template>
