export const BONUS_TYPES = [
  {
    id: 1,
    name: 'optional',
    display: 'optional',
    description: 'optional',
    components: {
      forbidden: ['kinder', 'internet', 'entfernung']
    }
  },
  {
    id: 2,
    name: 'mandatory',
    display: 'mandatory',
    description: 'mandatory'
  },
];
export const BONUS_TYPES_BY_NAME = BONUS_TYPES.reduce((acc, bonus_type) => {
  acc[bonus_type.name] = bonus_type;
  return acc;
}, {});
export const BONUS_TYPES_BY_ID = BONUS_TYPES.reduce((acc, bonus_type) => {
  acc[bonus_type.id] = bonus_type;
  return acc;
}, {});
export const DEFAULT_BONUS_TYPE = BONUS_TYPES_BY_NAME['mandatory'];
