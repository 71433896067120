angular
  .module('VSPApp')
  .directive('toggableContent', [
    '$sce', '$compile', '$state', '$parse', '$timeout',
    function ($sce, $compile, $state, $parse, $timeout) {
      return {
        require: [
          'toggableContent',
          '?ngModel'
        ],
        restrict: 'A',
        template: '<ng-transclude></ng-transclude>',
        transclude: true,
        controllerAs: "toggableContentCtrl",
        controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
          var self = this;

          var parsedValue = $parse($attrs.toggableContent);

          this.setToggler = function (scope, element) {
            $scope.toggler = {
              scope: scope,
              element: element,
            };
          };

          this.setContent = function (scope, element) {
            $scope.content = {
              scope: scope,
              element: element,
            };
          };

          this.openContent = function () {
            parsedValue.assign($scope, true);
            $scope.open = true;
          };

          this.closeContent = function () {
            parsedValue.assign($scope, false);
            $scope.open = false;
          };

          this.togglerClicked = function () {
            if (!$scope.toggleContentShowable) {
              parsedValue.assign($scope, !parsedValue($scope));
            } else {
              $timeout(function () {
                parsedValue.assign($scope, !parsedValue($scope));
              }, 300);
            }

            $timeout(function () {
              $scope.toggleContentShowable = !$scope.toggleContentShowable;
            }, 20);
          };

          $scope.elementClicked = function ($event) {
            self.closeContent();

            // wtf - TouchEvent is broken
            if ($event.type === 'touchend') {
              $state.go(angular.element($event.target).attr('ui-sref'));
            }
          };
        }],
        link: function ($scope, iElm, iAttrs, ctrls) {
          if (ctrls[1] !== null) {
            ctrls[1].$setViewValue(ctrls[0]);
          }
        }
      };
    }
  ]);
