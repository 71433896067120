const HUMAN_TO_REGEX = {
  '#': '[0-9]',
  '-': '[-_/:]',
  'a': '[a-z]',
  'A': '[A-Z]'
};

const $inject = [
  '$q',
  '$timeout',
  '$transitions',
  '$state',
  'MenuConstants',
  'authenticationService',
  'customerService',
  'dialogService',
  'advisorService',
  'employeeService',
  'NotificationService',
  'fileService',
  '$window',
  'mailService',
  'ACLService',
  'VSP_CONSTANTS',
  'lodash'
];
export default class CockpitAllgemeinController {

  constructor(
    $q,
    $timeout,
    $transitions,
    $state,
    MenuConstants,
    authenticationService,
    customerService,
    dialogService,
    advisorService,
    employeeService,
    NotificationService,
    fileService,
    $window,
    mailService,
    ACLService,
    VSP_CONSTANTS,
    lodash
  ) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.$transitions = $transitions;
    this.SelectedCustomerMenuConstants = angular.copy(MenuConstants);
    this.dialogService = dialogService;
    this.advisorService = advisorService;
    this.employeeService = employeeService;
    this.customerService = customerService;
    this.NotificationService = NotificationService;
    this.fileService = fileService;
    this.mailService = mailService;
    this.ACLService = ACLService;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
    this.customerId = $state.params.customerId;
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmCmsEditor = authenticationService.iAm('cms-editor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.lodash = lodash;

    this.loading = {
      saving: false,
      advisors: false,
      initializing: true
    };

    this.$window = $window;

    this.forms = {};
    this.contractLevels = [];
    this.defaultContractLevels = this.VSP_CONSTANTS.CUSTOMER_CONTRACT_LEVELS;

    this.init().then(()=>{
      this.$timeout(()=>{
        this.loading.initializing = false;
      });
    });
  }

  isLoading() {
    return this.loading.initializing || this.loading.advisors || this.loading.saving;
  }

  async init() {
    this.iAmEmployer = await this.ACLService.amIEmployer(this.customerId);
    this.customer = await this.customerService.get(this.customerId);
    this.configuration = this.customer;

    if (this.configuration.cms_config && this.configuration.cms_config.is_enabled) {
      this.SelectedCustomerMenuConstants.cms.title = this.configuration.cms_config.menu_title;
    } else {
      this.SelectedCustomerMenuConstants.cms.title = this.SelectedCustomerMenuConstants.cms.default_title;
    }

    if (this.configuration.bonus_dashboard_config && this.configuration.bonus_dashboard_config.is_enabled) {
      this.SelectedCustomerMenuConstants.bonus_dashboard.title = this.configuration.bonus_dashboard_config.menu_title;
    } else {
      this.SelectedCustomerMenuConstants.bonus_dashboard.title = this.SelectedCustomerMenuConstants.bonus_dashboard.default_title;
    }

    if (this.configuration.steuerfrei_menu_config && this.configuration.steuerfrei_menu_config.is_enabled) {
      this.SelectedCustomerMenuConstants.steuerfreie_extras.title = this.configuration.steuerfrei_menu_config.menu_title;
    } else {
      this.SelectedCustomerMenuConstants.steuerfreie_extras.title = this.SelectedCustomerMenuConstants.steuerfreie_extras.default_title;
    }
    this.SelectedCustomerMenuConstants = {
      ...this.SelectedCustomerMenuConstants,
      incent: {
        title: 'Incent',
        state: 'inApp.administration.incent',
        icon: 'euro',
        order: 11,
        cyData: 'vn.navigation.header.modulesMenu.linkIncentAdministration'
      }
    }

    if (!this.configuration.personal_number_whitelist_error_message) {
      this.configuration.personal_number_whitelist_error_message = 'Sie sind aktuell nicht berechtigt, fortzufahren. \n' +
        'Bitte kontaktieren Sie zur Klärung Ihre Personalabteilung, vielen Dank.';
    }
    if (!this.configuration.personal_number_whitelist) {
      this.configuration.personal_number_whitelist = false;
    }

    let neoConfig = await this.customerService.updateNeoConfigShopInfo(this.customerId);
    this.configuration.neo_config = neoConfig;

    if (this.iAmSpecialAdvisor || this.iAmGAdvisor || this.iAmAdvisor) {
      await this.loadAdvisors();
      await this.loadBrandings();
    }

    await this.validateEmployeesPNP();
    await this.getEmployeesWithInvalidEmails();
  }

  // validate page forms depending on the role of the user
  validateForms() {
    let is_welcome_block_form_valid = this.lodash.get(this.forms, 'welcome_block_form.$valid', true);
    let is_information_form_valid = this.lodash.get(this.forms, 'information_form.$valid', true);
    let is_personalNumberConfig_form_valid = this.lodash.get(this.forms, 'personalNumberConfig.$valid', true);
    let is_organizationStructureForm_form_valid = this.lodash.get(this.forms, 'organizationStructureForm.$valid', true);
    let is_domain_whitelist_form_valid = this.lodash.get(this.forms, 'domain_whitelist_form.$valid', true);
    let is_GDPR_form_valid = this.lodash.get(this.forms, 'GDPR_form.$valid', true);
    let is_infomailing_form_valid = this.lodash.get(this.forms, 'infomailing_form.$valid', true);
    let is_customer_fixed = this.lodash.get(this.configuration, 'fixated');

    return this.isLoading() ||
      !is_welcome_block_form_valid ||
      !is_information_form_valid ||
      !is_personalNumberConfig_form_valid ||
      !is_organizationStructureForm_form_valid ||
      !is_domain_whitelist_form_valid ||
      !is_GDPR_form_valid ||
      !is_infomailing_form_valid ||
      is_customer_fixed;
  }

  togglePNP() {
    this.configuration.personal_number_config.is_enabled = !this.configuration.personal_number_config.is_enabled;
  }

  validateRegexFormat() {
    try {
      let regex = new RegExp(this.configuration.personal_number_config.syntax);
      this.forms.personalNumberConfig.syntax.$setValidity('pattern', true);
    } catch (e) {
      this.forms.personalNumberConfig.syntax.$setValidity('pattern', true);
    }
  }

  exportUsersWithWrongPattern() {
    this.fileService.saveFile(this.employeesWithWrongPN);
  }

  async validateEmployeesPNP() {
    this.employeesWithWrongPN = await this.customerService.validateEmployeesPNP(this.customerId);
  }

  arbeitnehmerAnlegen(employee) {
    this.dialogService
      .employeeCreate(employee ? employee.valuenet_id : undefined,
        employee ? undefined : this.customer.id, employee ? this.needMade : true)
      .then(() => this.validateEmployeesPNP());
  }

  async loadAdvisors() {
    this.$timeout(()=>{
      this.loading.advisors = true;
    });

    // get the advisor IDS
    this.advisors = await this.advisorService.getAdvisorsByCustomer(this.customerId);
    // load their data in the app
    await this.employeeService.getMany(this.advisors.map(advisor => advisor.id));

    this.$timeout(()=>{
      this.loading.advisors = false;
    });

  }

  async loadBrandings() {

    let promises = [];

    promises[0] = this.customerService
      .getBranding(this.customerId)
      .then(branding => this.branding = branding);

    promises[1] = this.customerService
      .getBrandingList()
      .then(brandingList => this.brandingList = brandingList);

    await this.$q.all(promises);
  }


  moduleClick(key) {

    if (!this.configuration.modules) this.configuration.modules = {};
    if (!this.configuration.modules[key]) this.configuration.modules[key] = {
      enabled: true
    };

    this.configuration.modules[key].enabled = !this.configuration.modules[key].enabled;
  }

  isModuleSelected(key) {
    return this.configuration && this.configuration.modules && this.configuration.modules[key] && this.configuration.modules[key].enabled
  }

  addAdvisor() {

    this.loading.advisors = true;

    this.dialogService
      .chooseAdvisor()
      .then(advisorId => this.advisorService.setAdvisorForCustomer(advisorId, this.customerId))
      .finally(() => this.loadAdvisors());
  }

  removeAdvisor(advisorId) {

    this.loading.advisors = true;

    this.advisorService
      .removeAdvisorFromCustomer(advisorId, this.customerId)
      .finally(() => this.loadAdvisors());
  }

  setDefault(advisorId) {

    this.loading.advisors = true;

    this.advisorService
      .setDefault(advisorId, this.customerId)
      .finally(() => this.loadAdvisors());
  }


  save() {

    let promises = [];

    this.loading.saving = true;

    promises[0] = this.customerService.setConfiguration(this.customerId, this.configuration);
    promises.push(this.validateEmployeesPNP());
    promises.push(this.getEmployeesWithInvalidEmails());
    this.$q
      .all(promises)
      .then(async ()=>{
        if ((this.iAmSpecialAdvisor || this.iAmAdvisor) && this.unsavedBranding) {
          await this.customerService.setBranding(this.customerId, this.branding);
          this.unsavedBranding = false;
        }
      })
      .then(() => this.mailService.updateContactsCustomersData(this.customerId))
      .then(() => {
        this.$window.location.reload();
      })
      .then(() => this.NotificationService.message('Speichern erfolgreich.'))
      .catch(() => this.NotificationService.error('Speichern fehlgeschlagen.'))
      .finally(() => {
        this.loading.saving = false;
      });

  }

  exportUsersWithWrongEmailDomain() {
    this.fileService.saveFile(this.employeesWithInvalidEmails);
  }

  async getEmployeesWithInvalidEmails() {
    this.employeesWithInvalidEmails = await this.customerService.getEmployeesWithInvalidEmails(this.customerId);
  }

}
CockpitAllgemeinController.$inject = $inject;
