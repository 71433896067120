const $inject = [
  '$scope',
  '$timeout',
  'authenticationService',
  'NotificationService',
  'employeeService',
  'customerService'
];
export default class changeMailController {

  constructor(
    $scope,
    $timeout,
    authenticationService,
    NotificationService,
    employeeService,
    customerService
  ) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.authenticationService = authenticationService;
    this.NotificationService = NotificationService;
    this.customerService = customerService;
    this.employeeService = employeeService;
    this.send = false;
    this.saving = true;

    this.forms = {};
    this.changeMailModel = {
      oldMail : undefined,
      newMail : undefined,
      repeatNewMail: undefined
    };
    this.passwordModel = {
      password: undefined
    };

    this.updateEmailOperationModel = {
      is_successful: false,
      saving_error: false
    };

    this.loading = {
      fetching: true,
      updating_email: false
    };

    this.init();
  }

  async handleNewMailChange() {

    // reset validations
    this.forms.changeMailForm.newMail.$setValidity('invalid', true);
    this.forms.changeMailForm.newMail.$setValidity('in_use', true);
    Object.keys(this.updateEmailOperationModel).forEach(key => this.updateEmailOperationModel[key]=false);

    // validate domain
    if (!this.employee.testclient) {
      let doesEmailAdhereCustomerDomainSettings  = this.customerService.isEmployeeEmailDomainValid(this.changeMailModel.newMail, this.customerDomainsConfiguration);
      this.forms.changeMailForm.newMail.$setValidity('domain', doesEmailAdhereCustomerDomainSettings);
      if(!doesEmailAdhereCustomerDomainSettings) {
        this.customerDomainErrorMessage = this.customerService.getCustomerDomainErrorMessage(this.customerDomainsConfiguration);
      }
    }

    await this.checkMailAlreadyExists();

  }

  isLoading() {
    return this.loading.fetching || this.loading.updating_email;
  }

  async init() {
    this.$timeout(()=>{
      this.loading.fetching = true;
    });

    this.employee = await this.employeeService.get();
    this.customerConfiguration = await this.customerService.getConfigurationByEmployeeId(this.employee.id);
    this.customerDomainsConfiguration = this.customerConfiguration.domains_config;
    this.changeMailModel.oldMail = this.employee.login.email;

    this.$timeout(()=>{
      this.loading.fetching = false;
    });
  }

  async checkMailAlreadyExists() {
    let check = false;
    if (typeof this.changeMailModel.newMail !== 'undefined' && this.changeMailModel.newMail !== '') {
      this.$timeout(()=>{
        this.loading.updating_email = true;
      });
      const result = await this.employeeService.checkEmployeeByEmail(this.changeMailModel.newMail)
      if (result && result.exists) {
        this.forms.changeMailForm.newMail.$setValidity('in_use', false);
        check = true
      }

      this.$timeout(()=>{
        this.loading.updating_email = false;
      });
    }

    return check;
  }


  canUpdateMail() {
    return this.forms.changeMailForm.$valid && !this.updateEmailOperationModel.saving_error;
  }

  async updateEmail() {
    if (!this.canUpdateMail()){
      return
    }

    this.$timeout(()=>{
      this.loading.updating_email = true;
    });

    try {
      await this.authenticationService.changeMail(
        this.passwordModel.password,
        this.changeMailModel.newMail,
        this.changeMailModel.oldMail
      );

      this.NotificationService.message('Sie erhalten umgehend ein Mail an Ihre neue E-Mail Adresse mit einen Link um Ihre neue E-Mail Adresse zu bestätigen');
      this.updateEmailOperationModel.is_successful = true;
      this.$timeout(() => {
        this.$scope.confirm(true);
      }, 3000);

    } catch (e) {

      this.updateEmailOperationModel.is_successful = false;
      const errorMessage = e.error.message;

      if (errorMessage.includes('mail already in use')) {
        this.forms.changeMailForm.newMail.$setValidity('in_use', false);
      } else if (errorMessage.includes('EmailValidationFail')){
        this.forms.changeMailForm.newMail.$setValidity('invalid', false);
      } else if (errorMessage.includes(':_CUSTOM_ERROR_MESSAGE_')){
        this.customerDomainErrorMessage = errorMessage.replace(':_CUSTOM_ERROR_MESSAGE_', '');
        this.NotificationService.error(this.customerDomainErrorMessage);
        this.forms.changeMailForm.newMail.$setValidity('domain', false);
      } else {
        this.updateEmailOperationModel.saving_error = true;
      }
    }

    this.$timeout(()=>{
      this.loading.updating_email = false;
    });
  }

}
changeMailController.$inject = $inject;
