const PopupVideoKeys = {
  MODULE_NEO: 'moduleNEO',
  MODULE_SHOP: 'moduleShop',
  MODULE_BENEFIT: 'moduleBenefit',
  MODULE_VORSORGE: 'moduleVorsorge',
  MODULE_DASHBOARD_AN: 'moduleDashboardAN',
  OPTIMISATION_PROCESS: 'optimisationProcess',
  MODULE_VERSICHERUNGEN: 'moduleVersicherungen',
};

export default PopupVideoKeys;
