var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["baustein"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"baustein\"\u003E\u003Cdiv class=\"bausteinContainer\"\u003E\u003Ca class=\"imgWrapper\" ng-click=\"transition( baustein.id )\" data-cy=\"neo.preCheckout.{{baustein.id}}.neoDashboard.cardNeoComponent\"\u003E\u003Cdiv class=\"imgContainer\" ng-style=\"{&quot;background-image&quot;:&quot;url(\u002Fassets\u002Fimg\u002Fsteuerfrei-extras\u002F{{ baustein.id }}.jpg)&quot;}\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"title\"\u003E{{baustein.title}}\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btns clearfix\"\u003E\u003Ca ng-if=\"baustein.video\" ng-href=\"{{ baustein.video }}\" magnific-popup=\"{type: 'iframe'}\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-play\"\u003E\u003C\u002Fi\u003E Play\u003C\u002Fa\u003E\u003Ca class=\"no-video\" ng-if=\"!baustein.video\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-play\"\u003E\u003C\u002Fi\u003E Play\u003C\u002Fa\u003E\u003Ca ng-click=\"transition( baustein.id )\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-plus\"\u003E\u003C\u002Fi\u003E Öffnen\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["componentTile"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"baustein\"\u003E\u003Cdiv class=\"bausteinContainer\"\u003E\u003Ca class=\"imgWrapper\" ng-click=\"$ctrl.transition( component )\" ng-if=\"!component.is_used\" data-cy=\"neo.preCheckout.{{component.id}}.neoDashboard.cardNeoComponent\"\u003E\u003Cdiv class=\"imgContainer\" ng-class=\"{ &quot;used&quot; : component.is_used}\" ng-style=\"{&quot;background-image&quot;:&quot;url(\u002Fassets\u002Fimg\u002Fsteuerfrei-extras\u002F{{ component.id }}.jpg)&quot;}\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"title\"\u003E{{component.title}}\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003Ca class=\"imgWrapper\" ng-if=\"component.is_used\"\u003E\u003Cdiv class=\"imgContainer\" ng-class=\"{ &quot;used&quot; : component.is_used}\" ng-style=\"{&quot;background-image&quot;:&quot;url(\u002Fassets\u002Fimg\u002Fsteuerfrei-extras\u002F{{ component.id }}.jpg)&quot;}\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"title\"\u003E{{component.title}} AKTIV\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btns clearfix\"\u003E\u003Ca ng-if=\"component.video\" ng-href=\"{{ component.video }}\" magnific-popup=\"{type: 'iframe'}\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-play\"\u003E\u003C\u002Fi\u003E Play\u003C\u002Fa\u003E\u003Ca class=\"no-video\" ng-if=\"!component.video\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-play\"\u003E\u003C\u002Fi\u003E Play\u003C\u002Fa\u003E\u003Ca ng-click=\"$ctrl.transition( component )\" ng-if=\"!component.is_used\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-plus\" ng-if=\"!component.is_used\"\u003E\u003C\u002Fi\u003E Öffnen\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cquiz quiz=\"bausteineQuiz\" ng-if=\"bausteineQuiz.state != undefined\"\u003E\u003C\u002Fquiz\u003E\u003Cquiz quiz=\"basicInfoQuiz\" ng-if=\"basicInfoQuiz.state != undefined\"\u003E\u003C\u002Fquiz\u003E\u003Cquiz quiz=\"bavQuiz\" ng-if=\"bavQuiz.state != undefined\"\u003E\u003C\u002Fquiz\u003E\u003C!--button(ng-click=\"$ctrl.getNewPC()\") get new pc--\u003E\u003Cdiv class=\"row\" id=\"auswahl\" ng-if=\"( bausteineQuiz.state == undefined ) &amp;&amp; ( basicInfoQuiz.state == undefined ) &amp;&amp; ( bavQuiz.state == undefined )\"\u003E\u003Cspan class=\"pagetitle medium-8 columns\"\u003E{{ infomode ? 'Informieren Sie sich über Ihre Bausteine' : 'Aktivieren Sie Ihre Bausteine für mehr Netto.' }}\u003C\u002Fspan\u003E\u003Cdiv class=\"xsmall-12 columns scrollable\" overlay=\"loadingBausteine || $ctrl.loading.components\"\u003E\u003Cdiv class=\"row bausteineRow\"\u003E\u003Cdiv class=\"xsmall-12 small-6 large-4 columns\" ng-repeat=\"baustein in extras\" random-animate\u003E\u003Cdiv class=\"bausteinWrapper\"\u003E";
pug_mixins["baustein"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-6 large-4 columns\" ng-repeat=\"baustein in components | orderBy:&quot;title&quot;\" random-animate\u003E\u003Cdiv class=\"bausteinWrapper\"\u003E";
pug_mixins["baustein"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-6 large-4 columns\" ng-repeat=\"component in $ctrl.pc_components\" random-animate\u003E\u003Cdiv class=\"bausteinWrapper\"\u003E";
pug_mixins["componentTile"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-6 large-4 columns\" ng-repeat=\"component in $ctrl.carLeasingDashboardComponents\" random-animate\u003E\u003Cdiv class=\"bausteinWrapper\"\u003E";
pug_mixins["componentTile"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C!--.xsmall-12.small-6.large-4.columns(--\u003E\u003C!--  ng-if='$ctrl.hasCustomerActivatedDTicket'--\u003E\u003C!--  random-animate--\u003E\u003C!--)--\u003E\u003C!--  .bausteinWrapper--\u003E\u003C!--    +componentTile()--\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;