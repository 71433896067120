import { USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME } from '../../../services/administrationService'

import buying_decision_dialog_html from './mpp_buying_decision_dialog.pug';
import {
  USER_DECISION_PROCESS_TYPES_BY_ID,
  USER_DECISION_PROCESS_TYPES_BY_NAME
} from "../../../services/bonus/user_decision_process_type";
import {
  ConfirmationDialogComponent
} from "../../../../app/shared/components/confirmation-dialog/confirmation-dialog.component";
import {take} from "rxjs/operators";

const $inject = [
  '$state',
  '$scope',
  '$timeout',
  'administrationService',
  'vbmData',
  'dialogService',
  'lodash',
  'fileService',
  'NotificationService',
  'customerService',
  'MatDialogService'
];

export default class MeineAkteUserDecisionsController {
  constructor(
    $state,
    $scope,
    $timeout,
    administrationService,
    vbmData,
    dialogService,
    lodash,
    fileService,
    NotificationService,
    customerService,
    MatDialogService
  ) {
    Object.assign(this, {
      $state,
      $scope,
      $timeout,
      administrationService,
      vbmData,
      dialogService,
      lodash,
      fileService,
      NotificationService,
      customerService,
      MatDialogService
    });

    this.loading = {
      main: false,
      processes: false,
      choosing: false
    };
    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.employeeId = this.vbmData.employeeId;
    this.customer_configration = await this.customerService.getConfigurationByEmployeeId(this.employeeId);
    this.endprice_percentage = this.lodash.get(this.customer_configration, 'neo_config.pc.endprice_percentage');

    await this.loadProcesses();
    this.USER_DECISION_PROCESS_TYPES_BY_ID = USER_DECISION_PROCESS_TYPES_BY_ID;
    this.USER_DECISION_PROCESS_TYPES_BY_NAME = USER_DECISION_PROCESS_TYPES_BY_NAME;
    this.USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME = USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async loadProcesses() {
    this.$timeout(() => {
      this.loading.processes = false;
    });


    this.user_decision_processes = await this.administrationService.getOpenDecisionProcessesForEmployee({ employee_id: this.employeeId, to_extract_entity: true });
    let makudecision = await this.administrationService.getOpenMakuUserDecisionProcessForEmployee(this.employeeId);
    this.makuDecisionProcess = makudecision ? [makudecision] : []
    this.$timeout(() => {

      this.loading.processes = false;
    });
  }

  generateCyData(process, option) {
    const cyData = option.cyData.replace('.{}.', `.${process.entity_id}.`);
    return cyData;
  }

  async handleOption(process, option) {
    this.$timeout(() => {
      this.loading.choosing = false;
    });


    if (process['type_id'] === this.USER_DECISION_PROCESS_TYPES_BY_NAME['bonus_payment_type']['id']) {
      await this.administrationService.decisionProcessChooseOption({ process_id: process['_id'], choice_value_id: option['choice_value_id'] });
      await this.loadProcesses();
    } else if (process['type_id'] === this.USER_DECISION_PROCESS_TYPES_BY_NAME['mpp_buying_decision']['id']) {
      let dialog_display_data = this.USER_DECISION_PROCESS_TYPES_BY_ID[process['type_id']]['available_options'][option['choice_value_id']]['dialog_texts']
      await this.dialogService.ngDialog.openConfirm({
        template: buying_decision_dialog_html(),
        plain: true,
        className: 'ngdialog-theme-default control_dialog cocpkit-panel',
        controller: 'MPPBuyingDecisionDialogController',
        controllerAs: '$ctrl',
        data: {
          process: process,
          selected_option: option['choice_value_id']
        },
        width: 1400,
        closeByDocument: false,
        closeByEscape: true,
        closeByNavigation: true
      }).then(async (res) => {
        if (res) {
          this.NotificationService.message('Erfolgreich');
          await this.loadProcesses();
        }

      }).catch(error => {
        this.NotificationService.message('Fehlgeschlagen');
      });
    }

    this.$timeout(() => {
      this.loading.choosing = true;
    });
  }

  async makuDecision(makuDecisionProcess, isMakuChosen) {
    this.MatDialogService.open(ConfirmationDialogComponent, {
      data: {
        title: "Bitte bestätigen Sie noch einmal die Auswahl “KurzurlauberCard” / “Auszahlung",
        yesButton: "Bestätigen"
      }
    }).afterClosed()
      .pipe(take(1))
      .subscribe(async result => {
        if (result) {

          const employeeChoiceResponse = await this.administrationService.EHBemployeeChoice(
            makuDecisionProcess,
            isMakuChosen
          )

          if (employeeChoiceResponse['success']) {
            this.NotificationService.message('Ihre Auswahl wurde getroffen.');
            this.$state.go('inApp.dashboardArbeitnehmer');
          } else {
            console.error(`Something went wrong with user decision:${makuDecisionProcess.id}`)
          }

        }
      })
  }

}

MeineAkteUserDecisionsController.$inject = $inject;
