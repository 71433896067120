import {BasketItem} from '../../../models/basket-item';

const $inject = [
  '$window',
  '$state',
  'mppService',
  '$timeout'
];
export default class MppSideController {

  constructor( $window, $state, mppService, $timeout) {

    Object.assign( this, {
      $window,
      $state,
      mppService,
      $timeout
    } );
    this.$state = $state;

    this.is_vacation = false;

    this.mppService.isVacationTime().then(is_vacation => {
      this.is_vacation = is_vacation;
      this.$timeout();
    });
  }

  /**
   * If article is not available or for article specifications open Cyberport link.
   *
   * @param {string} id - Article number
   */
  goTo(article) {
    if (article instanceof BasketItem) {
      // #COMMENT_LEX: after my refactoring I think the article (basket item) 'has_errors' couldn't become true anymore
      article = article.article; // it's actually a basket item
      this.$state.go( 'inApp.mpp.side.products.detail', { Artikelnummer: article.articleNo } );
    } else {
      article.error ? this.$window.open( 'https://www.cyberport.de/?DEEP=' + article.Artikelnummer + '&APID=250', '_blank' ): this.$state.go( 'inApp.mpp.side.products.detail', { Artikelnummer: article.Artikelnummer } );
    }

  }

}

MppSideController.$inject = $inject;
