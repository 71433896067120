const $inject = [
  '$scope',
  '$timeout',
  'mppService'
];
export default class AddOldBasketsOrderIdController {
  constructor(
    $scope,
    $timeout,
    mppService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      mppService
    });

    this.loading = {
      main: false
    };

    this.extras = {};
    this.forms = {
      add_old_baskets_order_id_form: {}
    };

    this.models = {
      order_id: undefined
    };

    this.init();
  }

  async init() {
    this.basket = this.$scope.ngDialogData.basket;
    this.basket_id = this.basket['_id'];
  }

  close() {
    this.$scope.closeThisDialog();
  }

  handleOrderIdChange() {
    this.forms.add_old_baskets_order_id_form['$$controls'][0].$setValidity('unique', true);
  }

  async addOrderIdToOldBaskets() {
    this.$timeout(() => {
      this.loading.saving = true;
    });

    let result = null;
    try {
      result = await this.mppService.addOrderIdToOldBaskets({
        'basket_id': this.basket_id,
        'order_id': this.models.order_id
      });
    } catch (e) {
      console.log("error", e);
    } finally {

      if (result && !result.success && result.status == 'order_id_exists') {
        this.orderIdExistsMessage = result.error;
        this.forms.add_old_baskets_order_id_form['$$controls'][0].$setValidity('unique', false);
      }

      this.$timeout(() => {
        this.loading.saving = false;
      });
    }

    if (result && result.success) {
      this.$scope.confirm();
    }

  }
}
AddOldBasketsOrderIdController.$inject = $inject;
