import {Injectable} from '@angular/core';
import { CancelationData, DigitalSigningRequest, RequestState, } from '../models/digital-signing-request.model';


@Injectable()
export class BackendFileUploadMapper {

  public toApi(digitalSigningRequest: DigitalSigningRequest): object {
    return {
      'digital_signing_request': {
        'requester_id': digitalSigningRequest.requesterId,
        'expiration_date': digitalSigningRequest.expirationDate,
        'file_type': digitalSigningRequest.fileType,
        'ticket_number': digitalSigningRequest.ticketNumber,
        'coordinates': digitalSigningRequest.coordinates,
        'comment': digitalSigningRequest.comment,
        'status': BackendFileUploadMapper.requestStateToApi(digitalSigningRequest.requestState),
        'cancelation_data': this._parseCancelationData(digitalSigningRequest.cancelationData),
      },
      'valuenet': {
        'employee_id': digitalSigningRequest.valuenetData.employeeId,
        'customer_id': digitalSigningRequest.valuenetData.customerId
      }
    };
  }

  private _parseCancelationData(data: CancelationData|null) {
    if (!data) {
      return null
    }

    return {
      'date': data.date,
      'by_who': data.by_who
    }
  }

  private static requestStateToApi(state: RequestState): 'awaiting_for_signing'|'expired'|'cancelled'|'signed' {
    if (state == RequestState.Cancelled)
      return 'cancelled';
    if (state == RequestState.Expired)
      return 'expired';
    if (state == RequestState.AwaitingForSigning)
      return 'awaiting_for_signing';
    if (state == RequestState.Signed)
      return 'signed';
    throw Error('Unknown contract process state "' + state + "'");
  }

}
