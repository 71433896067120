const $inject = [
  '$scope',
  'FeedbackService'
];
export default class FeedbackDialogController {
  constructor(
    $scope,
    FeedbackService
  ) {
    this.$scope = $scope;
    this.FeedbackService = FeedbackService;

    this.$scope.feedback = this.$scope.ngDialogData.feedback;

    this.FeedbackService
      .moduleQuestions()
      .then(questions => {
        this.$scope.moduleQuestions = questions;
      });

    this.FeedbackService
      .generalQuestions()
      .then(questions => {
        this.$scope.generalQuestions = questions;
      });

    this.FeedbackService
      .textQuestions()
      .then(questions => {
        this.$scope.textQuestions = questions;
      });

  }
}
FeedbackDialogController.$inject = $inject;
