import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { DirectBonusProjectOverview } from "../../../models/direct-bonus-project-overview.model";
import {
  DirectBonusComponent,
  DirectBonusComponentType,
  DirectBonusProjectMonthOverview,
  DirectProjectOverviewBonusTableDataRow
} from 'src/app/direct-bonus/models/direct-bonus-project-overview-table-data.model';
import { BehaviorSubject, from, Observable,} from "rxjs";
import { filter, map, pairwise, startWith, switchMap, take, tap, } from 'rxjs/operators';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomerAdministrationAllowedOperations } from '../../../models/direct-bonus-allowed-operations.model';
import { BonusTableDialogData, ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { CustomerDownloadTableDialogComponent } from '../customer-download-table-dialog/customer-download-table-dialog.component';
import { DirectBonusProjectConfiguration } from 'src/app/direct-bonus/models/direct-bonus-data.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CustomerBulkAddUsersDialogComponent } from '../customer-bulk-add-users-dialog/customer-bulk-add-users-dialog.component';
import * as moment from 'moment';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { CustomerBulkUploadDialogComponent, DownloadTableProps } from '../customer-bulk-upload-dialog/customer-bulk-upload-dialog.component';
import { CustomerCreateExtraOrderDialogComponent } from '../customer-create-extra-order-dialog/customer-create-extra-order-dialog.component';

// ===============================================================

interface FilterOptions {
  componentSelection: SelectOption<DirectBonusComponentType>[],
  employeeStatuses: {id: string, label: string}[],
  employeesWithBonuses: {name: string, value: string}[]
}

interface FilterChoices {
  firstLastName: string | null;
  valuenetIdPersonalNumber: string | null;
  componentSelection: DirectBonusComponentType | null;
  employeeStatusSelection: string[]
}
export const ACTIVE_EMPLOYEE_STATUS = {
  id: 'active',
  label: 'Aktiv'
};
export const INACTIVE_EMPLOYEE_STATUS = {
  id: 'inactive',
  label: 'Ausgeschieden'
};
export const TEMPORARILY_INACTIVE_STATUS = {
  id: 'temporary_inactive',
  label: 'Temporär aus LFZ'
};

export const FILTER_EMPLOYEE_STATUS = [
  ACTIVE_EMPLOYEE_STATUS,
  INACTIVE_EMPLOYEE_STATUS,
  TEMPORARILY_INACTIVE_STATUS
]

export const EMPLOYEES_WITHOUT_BONUSES = {
  value: 'all_employees',
  name: 'Alle Mitarbeiter'
};
export const EMPLOYEES_WITH_BONUSES = {
  value:'employees_with_bonuses',
  name: 'Mitarbeiter mit Bonus'
};

export const FILTER_EMPLOYEES_WITH_BONUSES = [
  EMPLOYEES_WITH_BONUSES,
  EMPLOYEES_WITHOUT_BONUSES,
];
// ===============================================================

@Component({
  selector: 'vn-customer-administration-new',
  templateUrl: './customer-administration-new.component.html',
  styleUrls: ['./customer-administration-new.component.scss']
})
export class CustomerAdministrationNewComponent implements OnInit {

  private downloadFlag = false;
  private CSVDownloadData: DownloadTableProps|null = null;
  private $selectedDirectBonusProjectOverview: BehaviorSubject<DirectBonusProjectOverview | undefined> =
  new BehaviorSubject(undefined as DirectBonusProjectOverview | undefined);

  private readonly currentYear = moment().year();
  private $selectedYear: BehaviorSubject<number> = new BehaviorSubject(this.currentYear);

  public directBonusProjectOverviews$!: Observable<DirectBonusProjectOverview[]>;
  public dataStream$!: Observable<DirectProjectOverviewBonusTableDataRow[]>;

  public customerId!: number;
  public isListFiltered: boolean = false;
  public loading$ = this.loader.loading$;
  private openCustomerOverview: number | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private loader: LoadingService,
    private dialog: MatDialog,
    @Inject('fileService') private fileService: any,
    @Inject('customerService') private customerService: any,
  ) {
    this.filterOptions = {
      ...this.filterOptions,
      employeeStatuses: FILTER_EMPLOYEE_STATUS,
      employeesWithBonuses: FILTER_EMPLOYEES_WITH_BONUSES
    }
    this.initFiltersForm();
  }

  ngOnInit() {
    this.loadProjectOverviews();
    this.loadDataStream();
  }

  private applyEmployeeStatusFilter(
    dataRow: DirectProjectOverviewBonusTableDataRow,
    employeeStatusSelection: string[]
  ): boolean {
    return employeeStatusSelection.includes(dataRow.employee.status);
  }

  private applyFirstLastNameFilter(
    dataRow: DirectProjectOverviewBonusTableDataRow,
    firstLastName: string | null
  ): boolean {

    if(firstLastName === null
      || !firstLastName
      || (Array.isArray(firstLastName) && firstLastName.length == 0)
      ) {
      return true
    }

    return dataRow.employee.name.toLowerCase().includes(firstLastName.toLowerCase());
  }

  private applyValuenetIdPersonalNumberFilter(
    dataRow: DirectProjectOverviewBonusTableDataRow,
    valuenetIdPersonalNumber: string | null
  ): boolean {
    return !valuenetIdPersonalNumber || (
      dataRow.employee.id.toString().includes(valuenetIdPersonalNumber) ||
      dataRow.employee.personalNumber.includes(valuenetIdPersonalNumber)
    );
  }

  private applyComponentFilter(
    dataRow: DirectProjectOverviewBonusTableDataRow,
    compSelected: string | null
  ): boolean {
    if (!compSelected)
      return true;

      for (const m in dataRow.bonusesPerMonth) {
        const monthBonuses = dataRow.bonusesPerMonth[m] as any;
        if (monthBonuses && monthBonuses.length) {
          const foundComp = !!monthBonuses
            .map((c: DirectBonusProjectMonthOverview) => c.components)
            .map((d: DirectBonusComponent[]) => {
              return !!d.filter((dd: DirectBonusComponent) => dd.componentName === compSelected)?.length
            }).filter((v: boolean) => v)?.length;
          if (foundComp)
            return true;
        }
      }
      return false
  }

  private applyTableDataFilters(
    filters: FilterChoices,
    tableData: DirectProjectOverviewBonusTableDataRow[]
  ): DirectProjectOverviewBonusTableDataRow[] {
    this.loader.show();

    const filteredData = tableData
      .filter(
        (dataRow) => this.applyFirstLastNameFilter(dataRow, filters.firstLastName) &&
          this.applyValuenetIdPersonalNumberFilter(dataRow, filters.valuenetIdPersonalNumber) &&
          this.applyEmployeeStatusFilter(dataRow, filters.employeeStatusSelection) &&
          this.applyComponentFilter(dataRow, filters.componentSelection)
      )

    this.loader.hide();

    return filteredData;
  }

  private initFiltersForm() {
    this.filtersGroup = this.formBuilder.group({
      firstLastName: this.formBuilder.control(null, []),
      valuenetIdPersonalNumber: this.formBuilder.control(null, []),
      componentSelection: this.formBuilder.control(null, []),
      employeesWithBonuses: this.formBuilder.control(EMPLOYEES_WITH_BONUSES.value, []),
      employeeStatusSelection: this.formBuilder.control(null, []),
    });
    this.filtersGroup.controls['employeeStatusSelection'].patchValue([ACTIVE_EMPLOYEE_STATUS.id])

    this.filtersGroup.controls['employeeStatusSelection'].valueChanges.
      subscribe(va => {
        if (!va.length ) {
          this.filtersGroup.controls['employeeStatusSelection'].patchValue([ACTIVE_EMPLOYEE_STATUS.id])
        }
    })
  }

  private loadProjectOverviews() {
    this.directBonusProjectOverviews$ = this.directBonusService.getDirectBonusProjectsOverview();
  }

  public selectedDirectBonusProjectId$: Observable<string | undefined> =
    this.$selectedDirectBonusProjectOverview.pipe(
      map(
        (overview) => overview?.bonusProjectId
      )
    );

  public selectedDirectBonusProjectAllowedOperations$: Observable<CustomerAdministrationAllowedOperations | undefined> =
    this.$selectedDirectBonusProjectOverview.pipe(
      map(
        (overview) => overview?.allowedOperations
      )
    );


  public selectedYear$: Observable<number> = this.$selectedYear.asObservable();

  public nextYear$ = this.$selectedYear.pipe(
    map(year => year + 1)
  );

  public previousYear$ = this.$selectedYear.pipe(
    map(year => year - 1)
  );

  public filtersGroup!: FormGroup;
  public filterOptions!: FilterOptions;

  public onTableDataChange() {
    this.loadProjectOverviews();
    this.loadDataStream();
  }

  // ===================== FILTERS =====================

  public loadDataStream() {
    this.dataStream$ = this.selectedDirectBonusProjectId$.pipe(
      tap(() => {
        this.loader.show();
      }),
      filter(((directBonusId) => !!directBonusId)),
      switchMap((directBonusId) => this.$selectedYear.pipe(
        map((year) => ({
          directBonusId: directBonusId as string, // undefined bonusProjectId selection is filtered out
          year
        }))
      )),
      switchMap(({
        directBonusId,
        year
      }) => {
        const { employeesWithBonuses } = this.filtersGroup.value;
        return this.directBonusService.getProjectOverviewTableData(
          directBonusId,
          year,
          employeesWithBonuses === EMPLOYEES_WITHOUT_BONUSES.value ? false : true
        ).pipe(
          map(tableData => ({ tableData, directBonusId }))
        )
      }),
      map(result => result),
      switchMap(({ tableData, directBonusId }) => {
        return this.directBonusService.getBonusProjectConfiguration(directBonusId).pipe(
          map((bonusProjectConfiguration: DirectBonusProjectConfiguration) => {
            const components = this.directBonusService.getAvailableBonusComponents(bonusProjectConfiguration);

            // prevent to re-render state-list on every loading-data even states are not changed
            let currentComponentsName: string[] = [];
            let componentsName = components.map((i: any) => i['name']).sort();

            if(this.filterOptions.componentSelection) {
              currentComponentsName = this.filterOptions.componentSelection.map((i: any) => i['name']).sort();
            }

            if(componentsName.join(',') !== currentComponentsName.join(',')) {
              this.filterOptions = {
                ...this.filterOptions,
                componentSelection: components,
              }
            }

            return {
              tableData: tableData
            }
          }),
        )
      }),
      tap((r: any) => {

        if (this.downloadFlag) {
          this.downloadTableAsFile(r.tableData, this.CSVDownloadData?.withComment);
          this.downloadFlag = false;
        }
      }),
      switchMap((r: any) => {
        return this.filtersGroup.valueChanges.pipe(
          startWith(this.filtersGroup.value as FilterChoices),
          map((filters: FilterChoices) => this.applyTableDataFilters(filters, r.tableData)),
          take(1)
        )
      }),
      tap(() => {
        this.loader.hide();
        if(!this.isFiltersFormEmpty()) {
          this.isListFiltered = true;
        } else {
          this.isListFiltered = false;
        }
      }),
    );
  }

  // ===================== DISPLAY =====================

  public selectBonusProjectId(directBonusProjectOverview: DirectBonusProjectOverview) {
    this.clearFilters();
    this.$selectedDirectBonusProjectOverview.next(directBonusProjectOverview);
    this.customerId = directBonusProjectOverview.customerId;
    this.toggleCustomerOverview(this.customerId);
  }

  private toggleCustomerOverview(customerId: number) {
    this.openCustomerOverview = this.openCustomerOverview === customerId ? undefined : customerId;
  }

  public isCustomerOverviewOpen(customerId: number) {
    return this.openCustomerOverview == customerId;
  }

  public changeSelectedYear(next: boolean) {
    const snapshotValue = this.$selectedYear.value;
    if (next) {
      this.$selectedYear.next(snapshotValue + 1)
    } else {
      // previous
      this.$selectedYear.next(snapshotValue - 1)
    }
  }

  public downloadTableAsFile(tableData: DirectProjectOverviewBonusTableDataRow[], withReason=false) {

    let monthsIndexToFile = {
      '1': 'JAN',
      '2': 'FEB',
      '3': 'MAR',
      '4': 'APR',
      '5': 'MAY',
      '6': 'JUN',
      '7': 'JUL',
      '8': 'AUG',
      '9': 'SEP',
      '10': 'OCT',
      '11': 'NOV',
      '12': 'DEC',
    } as { [key: string]: string }

    let columns = [
      'ValuenetId',
      'Mitarbeiter',
      'Status',
      'Personalnummer',
      'Components',
    ];

    if (withReason) {
      columns = columns.concat([
        'JAN',
        'Grund-JAN',
        'FEB',
        'Grund-FEB',
        'MAR',
        'Grund-MAR',
        'APR',
        'Grund-APR',
        'MAY',
        'Grund-MAY',
        'JUN',
        'Grund-JUN',
        'JUL',
        'Grund-JUL',
        'AUG',
        'Grund-AUG',
        'SEP',
        'Grund-SEP',
        'OCT',
        'Grund-OCT',
        'NOV',
        'Grund-NOV',
        'DEC',
        'Grund-DEC',
      ])
    } else {
      columns = columns.concat(Object.values(monthsIndexToFile).map(m => m));
    }

    const components: DirectBonusComponentType[] = this.filterOptions.componentSelection.map(opt => opt.value)

    let results: any[] = [];

    tableData.forEach((row, index) => {

      components.forEach(name => {

        let obj = Object.assign({}, ...columns.map(key => ({ [key]: null })));

        obj['ValuenetId'] = row.employee.id;
        obj['Mitarbeiter'] = row.employee.name;
        FILTER_EMPLOYEE_STATUS.forEach((status) => {
          if (status.id === row.employee.status) {
            obj['Status'] = status.label;
          }
        })
        obj['Personalnummer'] = row.employee.personalNumber;

        for (let [month, item] of Object.entries(row.bonusesPerMonth)) {
          let monthIndex = month.toString() as string
          obj[monthsIndexToFile[monthIndex]] = null;
          item = item[0];

          if (item && item.components) {
            let components: DirectBonusComponent[] = item.components
            let foundComp = components.find((c: DirectBonusComponent) => c.componentName === name);
            if (!foundComp) {
              continue;
            }
            obj[monthsIndexToFile[monthIndex]] = foundComp.value;
          }
        }

        obj['Components'] = name;
        results.push(obj);
      })
    });

    this.fileService.saveFile(results, columns[0], 'ladungsdaten_vorlage.csv')
  }

  public menuClick($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public getOrderStateColors(data: DirectBonusProjectOverview): string[] {
    const colors = ['grey'];

    switch (data.orderStateDisplay) {
      case 'Entwurf':
        colors.push('yellow');
        break;
      case 'Bestellt':
      case 'Fakturiert':
      case 'Bezahlt':
        colors.push('blue');
        break;
      case 'Storniert':
        colors.push('red');
        break;
      case 'Ausgeführt':
        colors.push('green');
        break;
      default:
        colors.push('black');
    }

    return colors;
  }

  public getAdditionalText(bonusProjectOverview: DirectBonusProjectOverview): string | null {
    switch (bonusProjectOverview.orderStateDisplay) {

      case 'Entwurf':
        return `Freigabe bis ${bonusProjectOverview.orderDeadlineDate.format('DD.MM.YYYY')} erforderlich.`;

      case 'Abgelaufen':
        return 'Änderungen nicht mehr möglich.';

      // case 'Bestellt':
      // case 'Fakturiert':
      // case 'Storniert':
      // case 'Ausgeführt':
      default:
        return null;
    }
  }

  employeesWithBonusesChanged(value: any) {
    console.log("changed value", value);
    this.isListFiltered = false;
  }


  public clearFilters() {
    this.filterOptions = {...this.filterOptions, componentSelection: []}
    this.filtersGroup.patchValue({
      firstLastName: null,
      valuenetIdPersonalNumber: null,
      componentSelection: null,
      employeesWithBonuses: EMPLOYEES_WITH_BONUSES.value,
      employeeStatusSelection: [ACTIVE_EMPLOYEE_STATUS.id]
    });
    this.loadDataStream();
    this.isListFiltered = false;
  }

  private isFiltersFormEmpty() {
    return Object.values(this.filtersGroup.value).every((val: any) => {
      return (
        !val
        || val == false
        || (val.constructor == Object && Object.keys(val).length == 0)
        || (Array.isArray(val) && val.length === 1 && val[0] === ACTIVE_EMPLOYEE_STATUS.id)
      )
    })
  }

  private csvActivationData(users: any) {
    for(const i in users) {
      if (typeof users[i] === 'object') {
        users[i]['Letzte Anmeldung'] = users[i]['lastLogin'] && moment(users[i]['lastLogin'] * 1000).format('DD-MM-YYYY');
        users[i]['Einladungs Datum'] = users[i]['invitationDate'] && moment(users[i]['invitationDate'] * 1000).format('DD-MM-YYYY');
        users[i]['Sex'] = users[i]['sex'] ? 'M' : 'F';
        users[i]['Test Kunde'] = users[i]['testclient'] ? 'Ja' : 'Nein';
        users[i]['Kunden Name'] = users[i]['customername'];
        users[i]['E-Mail Addresse'] = users[i]['emailAddress'];
        users[i]['Aktivierungs Code'] = users[i]['activationCode'];
        users[i]['Anrede'] = users[i]['salutation'];
        users[i]['Nachname'] = users[i]['lastname'];
        users[i]['Zugriffsstatus'] = users[i]['access_status']['neo_comment'];
        users[i]['Persönliche Nummer'] = users[i]['personalnummer'];
        users[i]['Titel'] = users[i]['title'];
        users[i]['Kunden Nummer'] = users[i]['custId'];
        users[i]['Vorname'] = users[i]['vorname'];
        users[i]['Name'] = users[i]['name'];
        users[i]['Adresszusatz'] = users[i]['adresszusatz'];
        users[i]['Plz'] = users[i]['plz'];
        users[i]['Strasse'] = users[i]['strasse'];
        users[i]['Co'] = users[i]['co'];
        users[i]['Lkz'] = users[i]['lkz'];
        users[i]['Ort'] = users[i]['ort'];
        users[i]['Id'] = users[i]['id'];
        users[i]['Geschlecht'] = users[i]['geschlecht'];
        users[i]['Name'] = users[i]['name'];
        users[i]['LocId'] = users[i]['locId'];
        users[i]['_Anrede'] = users[i]['anrede'];

        const deleteList = [
          'personalnummer',
          'access_status',
          'firstname',
          'lastname',
          'salutation',
          'activationCode',
          'emailAddress',
          'customername',
          'invitationDate',
          'lastLogin',
          'testclient',
          'titel',
          'custId',
          'sex',
          'vorname',
          'adresszusatz',
          'plz',
          'strasse',
          'lkz',
          'ort',
          'id',
          'geschlecht',
          'locId',
          'co',
          'anrede',
          'name'
        ];

        for (const j in deleteList)
          delete users[i][deleteList[j]];

      }
    }

    return users;
  }

  public openCreateExtraOrderDialog(overview: DirectBonusProjectOverview) {
    const {bonusProjectId} = overview;
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      bonusProjectId,
    };

    this.dialog.open(
      CustomerCreateExtraOrderDialogComponent,
      {
        width: dialogWidth,
        data: dialogData
      }
    ).afterClosed()
      .pipe()
      .subscribe(
        (result) => {
          if (result) {
            const message = 'Speichern erfolgreich.';
            this.alertService.message(message);
            this.loadProjectOverviews();
            this.loadDataStream();
          }
        }
      );
  }

  public openDownloadTableDialog(overview: DirectBonusProjectOverview) {
    const {
      bonusProjectId,
      customerId
    } = overview;

    const dialogWidth = '420';
    const dialogData: ProjectActionsDialogData = {
      bonusProjectId,
      customerId
    };

    const dialogRef = this.dialog.open(
      CustomerDownloadTableDialogComponent, {
      width: dialogWidth,
      data: dialogData
    });

    dialogRef.afterClosed()
      .subscribe(
        (result: string) => {
          const year = parseInt(result);

          if (year) {
            this.$selectedYear.next(year);
            this.downloadFlag = true;
            this.$selectedDirectBonusProjectOverview.next(overview);
          }
        }
      );
  }

  public openBulkAddUsersDialog(overview: DirectBonusProjectOverview) {
    const {
      customerId
    } = overview;

    const dialogWidth = '700px';
    const dialogData: ProjectActionsDialogData = {
      customerId
    };

    this.dialog.open(
      CustomerBulkAddUsersDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            const message = `Erfolg.`;
            this.alertService.message(message);
            this.loadProjectOverviews();
            this.loadDataStream();
          }
        },
        () => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }

  public downloadInvitationData(overview: DirectBonusProjectOverview) {
    const {
      customerId
    } = overview;

    this.loader.show();

    const source$ = this.directBonusService.downloadInvitationData$(customerId)
      .pipe(
        map(activationFlowResponse => activationFlowResponse),
        switchMap((activationFlowResponse: any) => {
          let employees = activationFlowResponse.map((emp: any) => ({
            'ValueNet-ID': emp.id,
            'Vorname': emp.firstname,
            'Nachname': emp.lastname,
            'Aktivierungscode': emp.activationCode,
            'Personalnummer': emp.personalnummer
          }));

          return from(this.customerService.generateEmployeesListPdf(employees, '24621')).pipe(
            map(pdfResponse => {

              return  {
                users: this.csvActivationData(activationFlowResponse),
                pdfResponse: pdfResponse
              } as {users: any[], pdfResponse: string[]}
            })
          )
        }),


      );
      source$.pipe(take(1)).subscribe((data: {users: any[], pdfResponse: string[]}) => {
        window.open(data.pdfResponse[0], '_blank');
        this.fileService.saveFile(data.users, undefined, 'users.csv');
        this.loader.hide();
      })
  }

  filtersChanged() {
    this.isListFiltered = false;
  }

  public openBulkUploadBonusDialog(bonusProjectOverview: DirectBonusProjectOverview) {
    const {
      bonusProjectId,
      customerId
    } = bonusProjectOverview;
    const dialogWidth = '700px';
    const dialogData: ProjectActionsDialogData = {
      bonusProjectId,
      customerId
    };
    this.dialog.open(
      CustomerBulkUploadDialogComponent, {
      width: dialogWidth,
      data: dialogData
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        this.downloadFlag = true;
        this.CSVDownloadData = res;
        const newValue = {
          ...this.filtersGroup.value,
          employeesWithBonuses: EMPLOYEES_WITHOUT_BONUSES.value,
        };
        this.filtersGroup.patchValue(newValue);
        this.$selectedYear.next(res.selectedYear);
        this.$selectedDirectBonusProjectOverview.next(bonusProjectOverview);
        this.customerId = customerId;
        if (!this.openCustomerOverview)
          this.toggleCustomerOverview(this.customerId);
      });
  }
}
