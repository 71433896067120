var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};



















































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_mixins["summary-box"] = pug_interp = function(selectedCompany, save, disabled, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"summary-box isTransparent\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns\"\u003E";
if (selectedCompany) {
pug_html = pug_html + "\u003Cspan class=\"summary-box__title\"\u003E\u003Cstrong\u003EFirma:\u003C\u002Fstrong\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' '+ selectedCompany) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"hide-for-small-only\"\u003E&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns text-right pull-right\"\u003E";
block && block();
if (save) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"button button-primary isOutline\""+pug.attr("ng-click", save, true, true)+pug.attr("ng-disabled", disabled, true, true)+pug.attr("data-cy", cyData, true, true)) + "\u003ESpeichern\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};



















pug_html = pug_html + "\u003Cdiv class=\"row\" overlay=\"$ctrl.working\"\u003E";
globals = {}
pug_mixins["summary-box"](
    '{{ $ctrl.customer.name }}',
    '$ctrl.save()',
    '$ctrl.customer_configuration.fixated',
    'vn.cockpit.pcLeasing.customerToolbar.buttonSaveSettings'
  );
pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EAllgemein\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E";
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.max_number_of_contracts","title": "Maximale Anzahl paralleler PC-Leasing","type": "number","required": pug.escape(true),"data-cy": "vn.cockpit.pcLeasing.settings.inputMaxLeasings","readonly": "!$ctrl.canEdit()","validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.mpp_type","title": "MPP Type","data-cy": "vn.cockpit.pcLeasing.settings.selectMppType","attributes": {"ng-disabled":"!$ctrl.iAmSpecialAdvisor","ng-options":"mpp_type.id as mpp_type.display for mpp_type in $ctrl.MPP_TYPE"},"validationMessages": {"required":"Bitte Eingabe prüfen"},"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.foerderungAg","title": "Förderung durch AG €","required": pug.escape(true),"data-cy": "vn.cockpit.pcLeasing.settings.{0}","readonly": "!$ctrl.canEdit()","validationMessages": {"required":"Bitte Eingabe prüfen","numberOrEmpty":"Bitte Eingabe prüfen","vnMin":"Der minimale Wert beträgt {{ 0 | currency }}","vnMax":"Der maximale Wert beträgt {{ 500 | currency }}"},"attributes": {"vn-currency":"true","vn-max":"500","vn-min":"0"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.insurance_payed_by","title": "Insurance payed by","data-cy": "vn.cockpit.pcLeasing.settings.selectInsuranceTaxPayer","attributes": {"ng-disabled":"!$ctrl.canEdit()","ng-options":"insurance_payer.value as insurance_payer.display for insurance_payer in $ctrl.BONUS_INSURANCE_PAYERS"},"validationMessages": {"required":"Bitte Eingabe prüfen"},"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.rate_type","title": "BruttoNetto Rate","data-cy": "vn.cockpit.pcLeasing.settings.selectRateType","attributes": {"ng-disabled":"!$ctrl.canEdit()","ng-options":"rate_type.value as rate_type.display for rate_type in $ctrl.PC_RATE_TYPES"},"validationMessages": {"required":"Bitte Eingabe prüfen"},"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.leasing_rate","title": "Leasing rate","required": pug.escape(true),"data-cy": "vn.cockpit.pcLeasing.settings.inputLeasingRate","readonly": "!$ctrl.canEdit()","attributes": {"vn-percent":"{decimalsCount: 4}","vn-percent-fraction":4,"vn-min":"0.00001","vn-max":"150"},"validationMessages": {"required":"Bitte Eingabe prüfen","vnMin":"Bitte geben Sie einen Wert größer 0,001 ein.","vnMax":"Bitte geben Sie einen Wert kleiner 1 ein.","rangeDecimalsCount":"Maximal 4 Dezimalstellen sind erlaubt"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.handling_fee_netto","title": "Handling Fee NETTO","required": pug.escape(true),"data-cy": "vn.cockpit.pcLeasing.settings.inputNettoHandlingFee","readonly": "!$ctrl.canEdit()","attributes": {"vn-min":"0","vn-range":"{decimalsCount:2}","vn-currency":"true"},"validationMessages": {"vnMin":"Bitte geben Sie einen Wert größer 0 € ein.","rangeDecimalsCount":"Maximal 2 Dezimalstellen sind erlaubt","required":"Bitte Eingabe prüfen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.mindestbestellwert","title": "Minimum value BRUTTO","required": pug.escape(true),"data-cy": "vn.cockpit.pcLeasing.settings.inputBruttoMinimumValue","readonly": "!$ctrl.canEdit()","attributes": {"vn-min":"1","vn-range":"{decimalsCount:2}","vn-currency":"true"},"validationMessages": {"vnMin":"Bitte geben Sie einen Wert größer 1 € ein.","rangeDecimalsCount":"Maximal 2 Dezimalstellen sind erlaubt","required":"Bitte Eingabe prüfen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.insurance_without_tax","title": "Insurance Without Tax","required": pug.escape(true),"data-cy": "vn.cockpit.pcLeasing.settings.inputTaxFreeInsurance","readonly": "!$ctrl.canEdit()","attributes": {"min":"0","vn-range":"{decimalsCount:2}","vn-currency":"true"},"validationMessages": {"min":"Eingabe prüfen ( must be \u003E 0 )","rangeDecimalsCount":"Eingabe prüfen ( max 2 digits after comma )","required":"Bitte Eingabe prüfen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.gap_paid_by","title": "Ausfallversicherung","attributes": {"ng-disabled":"!$ctrl.canEdit()","ng-options":"insurance_payer.value as insurance_payer.display for insurance_payer in $ctrl.GAP_INSURANCE_PAYERS"},"data-cy": "vn.cockpit.pcLeasing.settings.{1}","validationMessages": {"required":"Bitte Eingabe prüfen"},"required": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.endprice_percentage","title": "Endkauf Prozent","data-cy": "vn.cockpit.pcLeasing.settings.{2}","required": pug.escape(true),"readonly": "!$ctrl.canEdit()","validationMessages": {"required":"Bitte Eingabe prüfen","vnMax":"Der Wert darf 50% nicht überschreiten","vnMin":"Der Wert muss größer 3% sein"},"attributes": {"vn-min":"0.0300","vn-max":"0.5000","vn-percent":"{decimalsCount: 4}","vn-percent-fraction":4}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"name": "$ctrl.forms.pc_leasing_config"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel\" ng-if=\"$ctrl.iAmSpecialAdvisor\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003ELeasing Details\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.leasinglimit","title": "Höhe der freigegebenen Leasings","type": "number"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.mindestbestellwert","title": "Höhe des Mindestbestellwertes","type": "number","readonly": "!$ctrl.iAmSpecialAdvisor"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.usedlimit","title": "Höhe des aktuell verwendeten Limits","type": "number","disabled": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-5\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.customer_configuration.neo_config.pc.boni_nr","title": "Boni.-Nr.","type": "number","readonly": "!$ctrl.iAmSpecialAdvisor"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;