const $inject = [
  '$location',
  '$scope',
  'vbmData',
  'vbmService',
  'advisorService',
  'customerService'
];
export default class NeoBeraterTargetDialogController {
  constructor(
    $location,
    $scope,
    vbmData,
    vbmService,
    advisorService,
    customerService
  ) {

    $scope.vbmData = vbmData;
    $scope.advisorService = advisorService;
    $scope.customers = [];
    $scope.employees = [];
    $scope.loading = false;
    $scope.search = {
      customer: ''
    };

    $scope.dialog = {
      step: 'customers'
    };

    $scope.searchCustomer = () => {
      $scope.search.customer = ($scope.search.customer || '').trim();
      if (!$scope.search.customer) {

        $scope.customers = [];

      } else if ($scope.search.customer === '*') {
        $scope.loading = true;

        advisorService
          .getCustomers()
          .then(customers => {
            $scope.customers = customers;
          }).finally(() => $scope.loading = false);

      } else if (2 < $scope.search.customer.length) {

        $scope.loading = true;
        customerService
          .searchCustomers($scope.search.customer)
          .then(customers => {
            $scope.customers = customers;
          }).finally(() => $scope.loading = false);
      }
    };

    $scope.setCustomer = customer => {

      $scope.dialog.customer = customer;
      advisorService.customerId = customer.id;

      delete $scope.dialog.project;
      delete $scope.dialog.employee;
    };

    $scope.toEmployees = () => {

      $scope.dialog.step = 'employees';
      $scope.employees = [];

      $scope.employeesLoading = true;

      advisorService
        .getEmployeesByCustomer(advisorService.customerId)
        .then(employees => $scope.employees = employees)
        .finally(() => $scope.employeesLoading = false);
    };

    $scope.toProjects = () => {

      $scope.dialog.step = 'projects';
      $scope.projects = [];
      $scope.projectsLoading = true;

      customerService
        .getProjects(advisorService.customerId)
        .then(projects => $scope.projects = projects)
        .finally(() => $scope.projectsLoading = false);
    };

    $scope.setEmployee = employee => {

      $scope.dialog.employee = employee;
    };

    $scope.changeEmployee = () => {

      vbmData.employeeId = $scope.dialog.employee.id;

      $location.search('employeeId', vbmData.employeeId);

      vbmData.getResult(vbmData.employeeId);
      $scope.ngDialogData.changeEmployee();
      vbmData.trigger();
      $scope.closeThisDialog();
    };

    $scope.setProject = project => $scope.dialog.project = project;

    // $scope.applyNeoProject = withComponentValues => {
    //
    //   $scope.applyingProject = true;
    //
    //   customerService
    //     .getNeoProject( advisorService.customerId, Date.now() )
    //     .then( neoProject => vbmData.applyNeoProject( neoProject, withComponentValues ) )
    //     .finally( () => {
    //       $scope.applyingProject = false;
    //       $scope.closeThisDialog();
    //     } );
    // };

  }

}
NeoBeraterTargetDialogController.$inject = $inject;
