import {isDatevSubdomain} from "../../app/routes/datev.routes";
import { CashbackReceiptUploadDialogComponent } from "../../../app/cashback/dialogs/cashback-receipt-upload-dialog/cashback-receipt-upload-dialog.component";

const $inject = [
  '$window',
  '$rootScope',
  '$scope',
  '$location',
  '$state',
  '$timeout',
  'Made',
  'menuService',
  'authenticationService',
  'customerService',
  'userService',
  'dialogService',
  'employeeService',
  'MenuConstants',
  'BonusService',
  'ACLService',
  'SupportService',
  'UserlaneService',
  'componentsService',
  'AdministrationService',
  'NotificationService',
  'PopupService',
  'MatDialogService',
];

export default class MenuController {

  constructor(
    $window,
    $rootScope,
    $scope,
    $location,
    $state,
    $timeout,
    Made,
    menuService,
    authenticationService,
    customerService,
    userService,
    dialogService,
    employeeService,
    MenuConstants,
    BonusService,
    ACLService,
    SupportService,
    UserlaneService,
    componentsService,
    AdministrationService,
    NotificationService,
    PopupService,
    MatDialogService,
  ) {

    this.$window               = $window;
    this.$rootScope            = $rootScope;
    this.$scope                = $scope;
    this.$location             = $location;
    this.$state                = $state;
    this.$timeout              = $timeout;
    this.Made                  = Made;
    this.menuService           = menuService;
    this.authenticationService = authenticationService;
    this.userService           = userService;
    this.MenuConstants         = MenuConstants;
    this.BonusService          = BonusService;
    this.ACLService            = ACLService;
    this.dialogService         = dialogService;
    this.employeeService       = employeeService;
    this.customerService       = customerService;
    this.SupportService        = SupportService;
    this.UserlaneService       = UserlaneService;
    this.componentsService     = componentsService;
    this.AdministrationService = AdministrationService;
    this.NotificationService   = NotificationService;
    this.PopupService          = PopupService;
    this.MatDialogService = MatDialogService;

    if ( !authenticationService.isLoggedIn() ) {
      return;
    }

    this.iAmAdvisor = authenticationService.iAm( 'advisor' );
    this.iAmGAdvisor = authenticationService.iAm( 'gadvisor' );
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmEmployeeAdministrator = authenticationService.iAm('sa_employee_administration');
    this.iAmDjangoAdmin = authenticationService.iAm( 'django_admin' );
    this.iAmProxy = userService.iAmProxy();
    this.online = true;

    this.loading = {
      main: false
    };

    this.userPaths = [];
    this.isUserlaneLoaded = false;
    this.hasCashbackComponents = false;

    this.init();

    this.$scope.$on('ngDialog.opened', () => {
      this.PopupService.disallowLoadingUserlane();
    });

    this.$scope.$on('ngDialog.closed', () => {
      this.PopupService.allowLoadingUserlane();
    });

    this.$scope.$watch(() => {
      if (!this.isUserlaneLoaded &&
          this.userPaths.length &&
          this.PopupService.canLoadUserlane()) {

        this.UserlaneService.load(this.userPaths);
        this.isUserlaneLoaded = true;
      }
    });
  }

  async init() {

    this.$timeout(()=>{
      this.loading.main = true;
    });

    let promises = [];

    this.$window.addEventListener(
      'offline',
      () => {
        if ( this.Made.wss && (WebSocket.OPEN !== this.Made.wss.readyState) ) {
          this.$rootScope.$applyAsync( () => this.online = false );
        }
      },
      false
    );

    this.$window.addEventListener(
      'online',
      () => this.$rootScope.$applyAsync( () => this.online = true ),
      false
    );

    promises.push(
      this.customerService.getCustomerSpecificUserlaneTags().then((customerSpecificUserlaneTags) => {
        this.menuService.get()
          .then(item => item.map(val => val.data.path))
          .then(paths => {
            this.userPaths = paths.concat(customerSpecificUserlaneTags);
            return true;
          })
      })
    );

    promises.push(
      this.userService
        .getIntegrityStatus()
    );

    if (!this.iAmProxy) {
      promises.push(
        this.Made.request(
          'rpc://valuenetdb/analytics/store_user_activity',
          { employee_id: this.Made.user.valuenet_id }
        )
      )
    }

    if (typeof zE !== 'undefined') {
      promises.push(
        this.employeeService
          .get()
          .then( employee => {
            zE( () => {
              zE.identify({name: employee.id + ', ' + employee.firstname + ' ' + employee.lastname, email: employee.email});
            });
          })
      );
    }

    promises.push(
      this.customerService
        .getConfiguration()
        .then((configuration) => {
          this.$rootScope.customerConfiguration = configuration;
        })
    );

    promises.push(
      this.amIApprover().then(
        (i_am_approver) => {
          return this.iAmApprover = i_am_approver;
        }).catch((err) => {
        console.error(err);
      })
    );

    promises.push(this.amIEmployer());

    promises.push(
      this.getIncentOfferLink()
    );

    promises.push(
      this.BonusService.getAvailableCashbackComponents({ employee_id: this.Made.user.valuenet_id })
        .then(
          (res) => {
            if (res && res.length > 0) {
              this.hasCashbackComponents = true;
            }
        })
        .catch((err) => {
            console.log(err);
      }));

    return Promise.all(promises).then((resolved) => {
      this.cockpitAccessable = this.iAmGAdvisor || this.iAmAdvisor || this.iAmEmployer;

      // userlane loaded
      if (resolved) {
        let integrityStatus = resolved[1];
        if ( integrityStatus !== false ) {
          if ( !this.userService.iAmProxy() ) {
            this.dialogService.employeeIntegrity( integrityStatus );
          } else {
            this.dialogService.question(
              'Der User hat die Datenschutzerklärungen noch nicht akzeptiert / abgelehnt!',
              'Achtung',
              'Ok'
            );
          }
        }
      }

      this.$timeout(() => {
        this.loading.main = false;
      });
    });

  }



  openSupportDialog() {
    this.SupportService.openSupportRequestDialog();
  }

  openCashbackRequestDialog() {
    this.MatDialogService.open(
      CashbackReceiptUploadDialogComponent,
      {
        width: 500,
        disableClose: true,
        data: '',
      }
    )
  }

  jumpToAdminPortal() {
    this.$window.open('/auth_cidaas/auth/?target=admin', '_blank');
  }

  toggleSideMenu() {
    angular
      .element( document.getElementsByClassName( 'off-canvas-wrap' ) )
      .toggleClass( 'move-right' );
  }

  isActive( v ) {
    return this.$state.includes( v.state );
  }

  logout() {
    this.UserlaneService.hide();
    this.authenticationService.logout();
  }

  leaveProxy() {
    this.userService.leaveProxy();
  }

  amIApprover() {
    return this.ACLService.amIApprover();
  }

  amIEmployer() {
    return this.ACLService.amIEmployer()
      .then((res) => {
        return this.iAmEmployer = res;
      });
  }

  async openItem(item) {
    if (item.title === 'Rabatt-Welt') {
      await this.goToIncent();
    } else {
      this.$state.go(item.state);
    }
  }

  async goToIncent() {
    this.loading.main = true;
    const response = await this.AdministrationService.openOfferLinkRequest(this.incentOffer.id).toPromise();
    this.loading.main = false;
    if (response['is_valid']) {
      this.$window.open(response['url'], '_blank');
    } else {
      this.NotificationService.error('Fehlgeschlagen.​ Bitte kontaktieren Sie Ihren Berater');
    }
  }

  async getIncentOfferLink() {

    let customerId = await this.employeeService.getCustomerId(this.Made.user.valuenet_id)
    let offers = await this.AdministrationService.getDashboardOffers(customerId).toPromise();
    this.incentOffer = offers.length ? offers[0] : null;
    return this.incentOffer;
  }
}

MenuController.$inject = $inject;
