/**
 * Example a typescript class setters and getters usage that could be helpful
 */
export class UserCarSettingsSelection {

  constructor(
    public contractTerm: number,
    public fuelType: string,
    public finnPerMonthValue: number,
    public hasCompanyCar: boolean,
    public distanceWorkHome?: number,
    public inclusiveKmPerMonth?: number, // extraKm.km
    public inclusiveKmPerMonthPackagePrice?: number, // extraKm.price
    public carConsumption?: number,
    public totalCarValueMRSP?: number,
    public additionalKmPrice?: number,
  ) {
  }

}
