import mpp_html from "../../modules/mpp/mpp.pug";
import mpp_header_html from "../../helpers/header.main.pug";
import side_html from "../../modules/side/side.pug";
import shopbar_html from "../../modules/mpp/main/shopbar.pug";
import mpp_side_html from "../../modules/mpp/side/side.pug";
import detail_html from "../../modules/mpp/detail/detail.pug";
import landing_html from "../../modules/mpp/listing/landing.pug";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.mpp', {
        views: {
          main: {
            template: mpp_html,
            controller: 'MppController',
            controllerAs: 'mpp',
          },
          header: {
            template: mpp_header_html
          },
        }
      })
      .state('inApp.mpp.side', {
        views: {
          mppMain: {
            template: side_html
          }
        }
      })
      .state('inApp.mpp.side.products', {
        views: {
          sideMain: {
            template: shopbar_html,
            controller: 'MppBarController',
            controllerAs: 'mppBar',
          },
          side: {
            template: mpp_side_html,
            controller: 'MppSideController',
            controllerAs: 'mppSide',
          }
        }
      })
      .state('inApp.mpp.side.products.detail', {
        url: '/mpp/detail/{Artikelnummer}',
        views: {
          productsMain: {
            template: detail_html,
            controller: 'MppDetailController',
            controllerAs: 'mppDetail',
          }
        },
      })
      .state('inApp.mpp.side.products.landing', {
        url: '/mpp',
        params: {
          employee_bonus_checkout_config: null,
          mpp_data: null,
          neo_data: null
        },
        views: {
          productsMain: {
            template: landing_html,
            controller: 'MppListingController',
            controllerAs: 'mppList',
          }
        }
      })
      .state('inApp.mpp.side.products.landing.maincat', {
        url: '/{main}',
        views: {
          productsMain: {
            template: landing_html
          }
        }
      })
      .state('inApp.mpp.side.products.landing.maincat.subcat', {
        url: '/{sub}',
        views: {
          productsMain: {
            template: landing_html
          }
        }
      });

  }]);
