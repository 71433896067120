const KVTypConstants = [
  {
    value: 'GKV_PFLICHT',
    name: 'pflicht'
  },
  {
    value: 'GKV_FREIWILLIG',
    name: 'freiwillig'
  },
  {
    value: 'PKV_MIT_AG',
    name: 'privat mit AG-Zuschuss'
  },
  {
    value: 'PKV_OHNE_AG',
    name: 'privat ohne AG-Zuschuss'
  }
];

export default KVTypConstants;
