const timeUntilNow = ["moment", function (moment) {
  return function (timestamp, output_type = 'days') {
    if (timestamp) {
      let now = moment();
      let time_difference_in_hours = now.diff(moment.unix(timestamp), 'hours');

      if (output_type === 'hours') {
        return time_difference_in_hours;
      } else if (output_type === 'days') {
        let days = Math.floor(time_difference_in_hours / 24);
        let hours = time_difference_in_hours % 24;
        let result = '';
        if (days > 0) {
          result = days + 'd ' + hours + 'h';
        } else {
          result = hours + 'h';
        }
        return result;
      } else {
        console.error('TimeUntilNow Filter -> output_type is wrong.');
        return null;
      }
    } else {
      return ' ';
    }
  };
}]

export { timeUntilNow }
