const CalcaiDates = [
  'evbegin',
  'vertragsabschluss',
  'bavbegin'
];

function dateToValuenetDate(date) {

  date = {
    year: '' + date.getFullYear(),
    month: '' + (date.getMonth() + 1),
    day: '' + date.getDate()
  };

  if (date) {
    if (1 === date.day.length) {
      date.day = '0' + date.day;
    }
    if (1 === date.month.length) {
      date.month = '0' + date.month;
    }
    return date.day + '.' + date.month + '.' + date.year;
  }
}

export const DEFAULT_EVBEGIN_MONTH_BUFFER = 1;

const $inject = [
  '$q',
  'ComponentConstants',
  'Made',
  'customerService',
  'fileService',
  'userService',
  'mppService',
  'moment'
];
export default class CheckoutService {

  constructor(
    $q,
    ComponentConstants,
    Made,
    customerService,
    fileService,
    userService,
    mppService,
    moment
  ) {

    Object.assign(this, {
      $q,
      ComponentConstants,
      Made,
      customerService,
      fileService,
      userService,
      mppService,
      moment
    });

    this.neoCheckoutArray = [
      'checkout',
      'neo'
    ];

  }

  getNeoDocuments(id) {

    return this.userService
      .getMadeUserByValuenetId(id)
      .then(madeUser => this.fileService.getFilesByTags(this.neoCheckoutArray, madeUser._id))
      .then(files => {
        let neoDocuments = {};

        files = files.filter(file => (-1 === file.meta.tags.indexOf('done')));

        for (let i = 0; i < files.length; i++) {
          let componentId = files[i].meta.tags.filter(tag => (-1 === this.neoCheckoutArray.indexOf(tag)));
          if (!componentId.length) {
            componentId = [
              'unknown'
            ];
          }
          componentId = componentId[0];
          if (!neoDocuments[componentId]) {
            neoDocuments[componentId] = [];
          }
          neoDocuments[componentId].push(files[i]);
        }

        return neoDocuments;
      });
  }


  setNeoDocuments(neoDocuments, id, tags = []) {

    return this.userService
      .getMadeUserByValuenetId(id)
      .then(madeUser => {

        let promises = [];
        tags.push(...this.neoCheckoutArray);

        angular.forEach(neoDocuments, (files, componentId) => {
          let custom_component_tags_for_documents = angular.copy(tags);
          custom_component_tags_for_documents.push(componentId);

          for (let i = 0; i < files.length; i++) {
            promises.push(this.Made.upload(files[i], custom_component_tags_for_documents, madeUser._id));
          }
        });


        return this.$q.all(promises);
      });
  }

  finalizeCalculation(id, calcai) {
    calcai = angular.extend({
      'ebikeOptions': '',
      'empid': 0,
      'lkz': '',
      'kantinetage': '',
      'strasse': '',
      'bankblz': '',
      'festnetztyp': '',
      'plz': '',
      'bankntnr': '',
      'ebikeBrand': '',
      'ebikeColor': '',
      'ebikeType': '',
      'bankbic': '',
      'ebikeDealerplz': '',
      'vertragsabschluss': '',
      'handytyp': '',
      'ebikeDealername': '',
      'ebikeSize': '',
      'garageort': '',
      'ort': '',
      'intgaragestellplatz': '',
      'ebikeModel': '',
      'tanktankstelle': '',
      'pctyp': '',
      'bankinstitut': '',
      'boolkantine': '',
      'garagestrasse': '',
      'intgarageart': '',
      'persnr': '',
      'intversicherer': '',
      'locid': 0,
      'intvertragbisher': '',
      'ebikeDealerstr': '',
      'bankiban': '',
      'tankkraftstoff': '',
      'ebikeDealerort': '',
      'garageplz': ''
    }, calcai);

    if (calcai.evbegin) {
      calcai.evbeginDate = calcai.evbegin.toISOString();
    }


    for (let i of CalcaiDates) {
      if (calcai[i]) {
        calcai[i] = dateToValuenetDate(calcai[i]);
      }
    }


    return this.Made
      .request('rpc://vbm/neo/finalize_calculation', {
        employee_id: parseInt(id),
        calcai
      });
  }

  getNeoChoicesDefault(components) {
    let neoChoices = {};
    for (let component of components) {
      neoChoices[component] = this.ComponentConstants[component].usage;
    }
    return neoChoices;
  }


  getAllNeoChoicesDefaults() {
    let neoChoicesDefault = {};

    for (let component in this.ComponentConstants) {
      if (this.ComponentConstants[component].usage) {
        neoChoicesDefault[component] = Object.assign({}, {
          list: this.ComponentConstants[component].usage.list,
          default: this.ComponentConstants[component].usage.default
        });
      }
    }

    return neoChoicesDefault;
  }


  getNeoChoicesDefaultFromCustomer(employeeId) {
    return this.customerService
      .getNeoProjectByEmployeeId(employeeId)
      .then(configuration => {
        const neoChoices = configuration.neoChoices;
        return neoChoices || {};
      });
  }


  getNeoChoicesDefaultFromEmployee(employee, componentId) {
    const componentIds = employee.components.map(component => component.key);
    if (componentId) {
      componentIds.push(componentId)
    }

    let neoChoicesDefault = this.getNeoChoicesDefault(componentIds);

    // we get the defaultForCustomer in order to sanity check if the current employee default is still an option
    return this.getNeoChoicesDefaultFromCustomer(employee.data.metadata.employee_id)
      .then(neoChoices => {
        for (let choice in neoChoicesDefault) {
          if (neoChoices[choice] && neoChoices[choice].list.length) {
            neoChoicesDefault[choice] = neoChoices[choice];
          }
        }
      })
      .then(() => this.getNeoChoices(employee.data.metadata.employee_id))
      .then(neoChoices => {

        for (let choice in neoChoicesDefault) {
          if (!neoChoices[choice] || !neoChoicesDefault[choice].list.includes(neoChoices[choice])) {
            neoChoices[choice] = neoChoicesDefault[choice].default;
          }
        }

        for (let choice in neoChoices) {
          if (!neoChoicesDefault[choice]) {
            delete neoChoices[choice];
          }
        }



        return {
          choicesDefault: neoChoicesDefault,
          neoChoices
        };
      });
  }

  getNeoChoices(employee_id) {
    return this.Made.request('rpc://vbm/checkout/get_neo_choices', {
      employee_id: parseInt(employee_id)
    });
  }

  setNeoChoices(employee_id, calculation_id, pre_calculation_id, user_choices) {
    return this.Made.request('rpc://vbm/checkout/set_neo_choices', {
      employee_id: parseInt(employee_id),
      calculation_id: parseInt(calculation_id),
      pre_calculation_id: parseInt(pre_calculation_id),
      user_choices
    });
  }

  updateSachbezugBonagoScheme(calculation_id, bonago_scheme_id) {
    return this.Made.request('rpc://valuenetdb/calculation/set_user_sachbezug_bonago_scheme_id', {
      calculation_id: calculation_id,
      bonago_scheme_id: bonago_scheme_id
    });
  }

  getNeoAgreement(employee_id) {
    return this.Made.request('rpc://vbm/checkout/get_neo_agreement', {
      employee_id: parseInt(employee_id)
    });
  }

  setNeoAgreement(employee_id, pre_calculation_id, agreement) {
    return this.Made.request('rpc://vbm/checkout/set_neo_agreement', {
      employee_id: parseInt(employee_id),
      pre_calculation_id: parseInt(pre_calculation_id),
      agreement: agreement,
    });
  }

  getCheckoutAdjustment(employee_id) {
    return this.Made.request('rpc://vbm/checkout/getCheckoutAdjustment', {
      employee_id: parseInt(employee_id)
    });
  }

  setCheckoutAdjustment(employee_id, pre_calculation_id, adjustment) {
    return this.Made.request('rpc://vbm/checkout/setCheckoutAdjustment', {
      employee_id: parseInt(employee_id),
      pre_calculation_id: parseInt(pre_calculation_id),
      adjustment: adjustment,
    });
  }


  getNeoExtras(employee_id) {

    return this.Made
      .request('rpc://vbm/checkout/get_neo_extras', {
        employee_id: parseInt(employee_id)
      })
      .then(extras => {

        // if ( !extras.evbegin ) {
        //   extras.evbegin = this.getEvBeginMinDate();
        // }
        //
        //
        // if ( !extras.vertragsabschluss ) {
        //   extras.vertragsabschluss = new Date();
        // }
        // if ( !extras.bavbegin ) {
        //   extras.bavbegin = new Date();
        //   extras.noBav = true;
        // }

        for (let i of CalcaiDates) {
          if (extras[i] && 'string' === typeof extras[i]) {
            extras[i] = new Date(extras[i]);
          } else if (extras[i] && Number.isInteger(extras[i])) {
            extras[i] = new Date(extras[i] * 1000);
          }
        }


        return extras;
      });
  }

  setNeoExtras(employee_id, pre_calculation_id, extras) {
    extras = angular.copy(extras);
    if (typeof extras.noBav !== 'undefined') {
      delete extras.noBav;
    }
    // toISOString will convert to UTC and then format
    // e.g new Date('2022-12-01').toISOString() -> 2022-11-30T00:00:00.000Z which is wrong
    // we want only to use proper format
    extras.evbegin = this.moment(extras.evbegin).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    return this.Made.request('rpc://vbm/checkout/set_neo_extras', {
      employee_id: parseInt(employee_id),
      pre_calculation_id: parseInt(pre_calculation_id),
      extras
    });
  }

  setAlternateAdvisor(advisorId, customer_id, data) {
    return this.Made.request('rpc://vbm/neo/checkAdvisorAndGetId', {
      advisor_id: advisorId,
      customer_id: customer_id
    }).then(id => {
      data.metadata.advisor_id = id;

      return data;
    });
  }

  getEvBeginMinDate(additional_month_buffer = 0, employee_id = this.Made.user.valuenet_id) {
    // let evBeginMinDate = this.moment.utc();
    // let monthsToAdd = DEFAULT_EVBEGIN_MONTH_BUFFER + additional_month_buffer + (evBeginMinDate.date() > 10 ? 1:0);
    // evBeginMinDate.add(monthsToAdd, 'months');
    // evBeginMinDate.set({millisecond: 0, second:0, minute:0, hour:0, date:1});
    // // evBeginMinDate.subtract(1, 'second');
    // return evBeginMinDate.toDate();

    return this.Made.request('rpc://vbm/checkout/get_evbegin_min_date', {
      employee_id: employee_id
    }).then(epoch => {
      const time = this.moment.unix(epoch).toDate();
      time.setHours(0);
      time.setMinutes(0);
      time.setSeconds(0);
      time.setMilliseconds(0);
      return time;
    });
  }

  employeeBlocked(employeeId) {
    return this.Made.request('rpc://valuenetdb/employee/isBlocked', {
      employeeId: parseInt(employeeId)
    });
  }

  willCheckoutBasket(cart) {
    if (cart && (cart.state === 'saved' || cart.state === 'checkout' || cart.state === 'attention') && !cart.locked) {
      // cart is to be seen in checkout
      return true;
    } else {
      return false;
    }
  }

  enterSession(employeeId, preCalculationId) {
    return this.Made.request('rpc://vbm/checkout/enterSession', {
      employeeId,
      preCalculationId
    });
  }

  leaveSession(employeeId = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://vbm/checkout/leaveSession', {
      employeeId
    });
  }

  saveSession(valuenetId, session) {
    return this.Made.request('rpc://vbm/checkout/saveSession', {
      'valuenet_id': valuenetId,
      'session': session
    });
  }

  getSession(employeeId) {
    return this.checkSession(employeeId);
  }

  checkSession(employeeId = this.Made.user.valuenet_id) {
    return this.Made.request('rpc://vbm/checkout/checkSession', {
      employeeId
    });
  }

}
CheckoutService.$inject = $inject;
