import {
  DticketOrderConfigurationComponent
} from "../../app/dticket-order/components/cockpit/dticket-order-configuration/dticket-order-configuration.component";

const dticketResolver = ['$q', 'authenticationService', 'ACLService', 'NotificationService', ($q, authenticationService, ACLService, NotificationService) => {
  let deferred = $q.defer();

  const allowedGroups = authenticationService.iAm([
    'gadvisor',
    'advisor',
    'special_advisor'
  ]);

  ACLService.amIEmployer().then((iAmEmployer) => {
    if (allowedGroups || iAmEmployer) {
      deferred.resolve();
    } else {
      NotificationService.error('Access denied.');
      deferred.reject({redirectTo: 'inApp.cockpit.kundenauswahl'});
    }
  });

  return deferred.promise;
}];

const CockpitPagesConstants = [
  {
    id: 'kundenauswahl',
    title: 'Kundenauswahl',
    icon: 'home',
    key: 'kundenauswahl',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'allgemein',
    title: 'Allgemein',
    icon: 'settings',
    key: 'allgemein',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    cyData: 'vn.navigation.cockpit.modulesMenu.linkAllgemeinCockpit'
  },
  {
    id: 'statistics',
    title: 'Statistik',
    icon: 'statistics',
    key: 'statistics',
    controller_name: 'CockpitStatisticsController',
    controller_as_name: '$ctrl',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'arbeitnehmerverwaltung',
    title: 'Arbeitnehmerverwaltung',
    icon: 'users',
    key: 'employeeAdministration',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    cyData: 'vn.navigation.cockpit.modulesMenu.linkArbeitnehmerverwaltungCockpit'
  },
  {
    id: 'steuerfrei',
    title: 'Steuerfreie Extras',
    icon: 'vorsorge',
    routeSuffix: '?neoProjectId&search',
    key: 'steuerfrei',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    cyData: 'vn.navigation.cockpit.modulesMenu.linkNeoCockpit',
  },
  {
    id: 'bonus',
    title: 'Bonus',
    icon: 'verguetung',
    key: 'bonus',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    acl: {
      role: 'advisor'
    },
    disabled: false,
    cyData: 'vn.navigation.cockpit.modulesMenu.linkBonusCockpit',
  },

  {
    id: 'versicherungen',
    title: 'Versicherungen',
    icon: 'versicherungen',
    disabled: false,
    key: 'versicherungen',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'vorsorge',
    title: 'Vorsorge',
    icon: 'vorsorge',
    key: 'vorsorge',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'cashback',
    title: 'Rechtliche Hilfe',
    icon: 'cashback',
    key: 'cashback',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'shop',
    title: 'Rabatte',
    icon: 'shopping',
    key: 'shop',
    acl: {
      role: 'special_advisor'
    },
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'vorteilswelt',
    title: 'Steuerfrei Shoppen',
    icon: 'shopping',
    key: 'vorteilswelt',
    acl: {
      role: 'special_advisor'
    },
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'bike',
    title: 'Fahrrad',
    icon: 'bike',
    key: 'bike',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'pc',
    title: 'PC Leasing',
    icon: 'laptop',
    key: 'pc',
    controller_name: 'CockpitPCController',
    controller_as_name: '$ctrl',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    cyData: 'vn.navigation.cockpit.modulesMenu.linkCockpitPC',
  },
  {
    id: 'car-leasing',
    title: 'Auto Abo',
    icon: 'garage',
    key: 'carleasing',
    controller_name: 'CockpitCarLeasingController',
    controller_as_name: '$ctrl',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    cyData: 'vn.navigation.cockpit.modulesMenu.linkCarCockpit',
  },
  {
    id: 'd-ticket',
    title: 'DeutschlandTicket JOB',
    icon: 'train',
    key: 'dticket',
    cyData: 'vn.navigation.cockpit.modulesMenu.linkDTicketCockpit',
    component: DticketOrderConfigurationComponent,
    resolver: dticketResolver,
    angular12: true,
  },
  {
    id: 'bonago',
    title: 'Vorteilskonto',
    icon: 'dailydeals',
    key: 'bonago',
    controller_name: 'CockpitBonagoController',
    controller_as_name: '$ctrl',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    cyData: 'vn.navigation.cockpit.modulesMenu.linkBenefitCockpit',
  },
  {
    id: 'document_templates',
    title: 'Unterlagenadministration',
    icon: 'document',
    key: 'document_templates',
    controller_name: 'CockpitDocumentTemplatesController',
    controller_as_name: '$ctrl',
    acl: {
      role: 'document_administrator'
    },
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'approvers',
    title: 'Approvers',
    icon: 'cashback',
    key: 'approvers',
    controller_name: 'CockpitApproversController',
    controller_as_name: '$ctrl',
    acl: {
      role: 'special_advisor'
    },
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'user_groups',
    title: 'User Groups',
    icon: 'cashback',
    key: 'user_groups',
    controller_name: 'CockpitUserGroupsController',
    controller_as_name: '$ctrl',
    acl: {
      role: 'special_advisor'
    },
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    cyData: 'vn.navigation.cockpit.modulesMenu.linkUserGroups',
  }, {
    id: 'documents',
    title: 'Dokumentenabruf',
    icon: 'download',
    key: 'documents',
    controller_name: 'CockpitDocumentsController',
    controller_as_name: '$ctrl',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
  },
  {
    id: 'digital_signiture',
    title: 'Digitale Signatur',
    icon: 'tick',
    key: 'signiture',
    controller_name: 'DigitalSignitureController',
    controller_as_name: '$ctrl',
    routeSuffix: '?search',
    params: {
      customerId: null,
      search: {
        value: null,
        dynamic: true
      }
    },
    resolver: ['authenticationService', (authenticationService) => authenticationService.iMustBe(['special_advisor'])],
    acl: {
      role: 'special_advisor'
    },
  }
];

export default CockpitPagesConstants;
