import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdministrationService } from 'src/app/administration/services/administration.service';
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import ComponentConstants from '../../../../../app-ajs/constants/ComponentConstants';
import {
  cashbackDataModel,
  cashbackRequestModel,
  periodNames,
  status,
  cashbackStatusLabels
} from './administration-cashback-review.model';

type NavigateTo = 'mpp';

@Component({
  selector: 'vn-administration-cashback-review',
  templateUrl: './administration-cashback-review.component.html',
  styleUrls: ['./administration-cashback-review.component.scss'],
})
export class AdministrationCashbackReviewComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  private bonusId!: string;

  public status: any = status;
  public stateEnToDeMapper: any;
  public data!: cashbackDataModel;
  public requestId: number | null = null;
  public formGroup!: FormGroup;
  public invoiceIssuers!: string[];
  public cashbackStatusLabels: any;

  constructor(
    private administrationService: AdministrationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    @Inject('$state') private state: any,
    @Inject('fileService') private fileService: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.cashbackStatusLabels = cashbackStatusLabels;
    if (!this.bonusId) {
      this.bonusId = this.state.params.bonus_id;
    }


    this.loadRequests();
    this.loadCashbackInvoiceIssuers();
  }
  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      approvedValue: this.formBuilder.control('', [
        Validators.max(Math.min(this.data.projectRest, this.data.componentRest)),
        Validators.min(0),
        Validators.required]
      ),
      invoiceNumber: this.formBuilder.control('', []),
      invoiceIssuer: this.formBuilder.control('', []),
      comment: this.formBuilder.control('', [Validators.required]),
    });
  }

  loadRequests() {
    if (!this.bonusId) {
      return
    }
    this.administrationService
      .getCashbackRequestData(this.bonusId)
      .subscribe((data: any) => {
        this.data = data;
        this.initFormGroup();
      });
  }

  loadCashbackInvoiceIssuers() {
    this.administrationService
      .getCashbackInvoiceIssuers()
      .subscribe((issuers: any) => {
        this.invoiceIssuers = issuers;
      });
  }

  toggleRow(requestId: number) {
    this.requestId = this.requestId === requestId ? null : requestId;
  }

  approveRequest() {
    const {
      approvedValue,
      invoiceNumber,
      invoiceIssuer,
      comment,
    } = this.formGroup.value;

    if (!this.requestId) {
      return
    }

    this.administrationService
      .approveCashbackRequest(
        this.requestId,
        parseFloat(approvedValue),
        invoiceNumber,
        invoiceIssuer,
        comment,
      )
      .subscribe(
        (res: any) => {
          if (res === 'success') {
            this.alertService.message("Speichern erfolgreich");
            this.loadRequests();
          } else if (res === 'invoice_number_already_used') {
            this.alertService.error("Es wurde bereits ein Beleg mit dieser Rechnungsnummer eingereicht.");
          } else if (res === 'error') {
            this.alertService.error("Fehlgeschlagen.");
          }
        },
        () => {
          this.alertService.error("Fehlgeschlagen");
        }
      );
  }

  declineRequest() {
    const {
      invoiceNumber,
      invoiceIssuer,
      comment
    } = this.formGroup.value;

    if (!this.requestId) {
      return
    }

    this.administrationService
      .declineCashbackRequest(
        this.requestId,
        invoiceNumber,
        invoiceIssuer,
        comment,
      )
      .subscribe(
        () => {
          this.alertService.message("Speichern erfolgreich");
          this.loadRequests();
        },
        () => {
          this.alertService.error("Fehlgeschlagen");
        },
        () => {
          this.resetForm();
        }
      );
  }

  editRequest(cashback_request: cashbackRequestModel) {
    cashback_request.is_editing = true
    this.formGroup.patchValue({
        approvedValue: cashback_request.approvedValue ? cashback_request.approvedValue : 0,
        invoiceNumber: cashback_request.invoiceNumber,
        invoiceIssuer: cashback_request.invoiceIssuer,
        comment: cashback_request.comment
      }
    );

  }

  cancelEditRequest(cashback_request: cashbackRequestModel) {
    cashback_request.is_editing = false;
  }

  isRequestEditable(cashback_request: cashbackRequestModel) {
    return cashback_request.state !== status.pending;
  }

  getPeriod(request: cashbackRequestModel): string {
    const periodName: periodNames = request.receiptValidPeriod

    const periodNames = {
      'one_day': '1 Tag',
      'one_week': '1 Woche',
      'one_month': '1 Monat',
      'three_months': '3 Monate',
      'six_months': '6 Monate',
      'twelve_months': '12 Monate'
    }

    return periodNames[periodName];
  }

  navigateTo(to: NavigateTo, employeeId: number) { //TOODOO ValueNet ID 

    const urlFactory = {
      'mpp': `/administration/mpp/edit?employeeId=${employeeId}`,

    }

    window.open(
      urlFactory[to],
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  ableToApprove() {
    const { approvedValue } = this.formGroup.value;
    return !!approvedValue  && approvedValue >= 0;
  }

  ableToDecline() {
    const { comment } = this.formGroup.value;
    return comment ? true : false;
  }

  async openFile(fileId: string) {
    const fileUrl = await this.fileService.getDocumentUrl(fileId);
    window.open(fileUrl, '_blank')
  }

  private resetForm() {
    this.formGroup.reset({
      approvedValue: '',
      invoiceNumber: '',
      invoiceIssuer: '',
      comment: '',
    });
  }

  public getSumApprovedAmounts() {
    let sum = 0;
    for (const request of this.data.cashbackRequests) {
      if(request.approvedValue) {
        sum += request.approvedValue;
      }
    }
    return sum;
  }

  public mapComponentName(name: string) {
    switch (name){
      case 'jobticket':
      case 'bgmbudget':
      case 'kinder':
      case 'egeschenk':
      case 'BAV':
        return ComponentConstants[name]['title']
      default:
        return 'unknown'
    }
  }
}
