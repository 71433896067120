const $inject = [
  '$scope',
  '$timeout',
  'mppService',
  'NotificationService'
];
export default class bill_send_leasing_company_controller {
  constructor(
    $scope,
    $timeout,
    mppService,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      mppService,
      NotificationService
    });

    this.loading = {
      main: false
    };

    this.contractID = '';

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.basket = this.$scope.ngDialogData.basket;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async closeOrder() {
    this.$timeout(() => {
      this.loading.saving = true;
    });


    this.mppService.closeOrder({basket_id: this.basket._id})
      .then(() => {
        this.$scope.closeThisDialog();
      })
      .catch((err) => {
        console.log("error", err);
        this.NotificationService.error('Fehlgeschlagen.');
      });


    this.$timeout(() => {
      this.loading.saving = false;
    });
  }
}
bill_send_leasing_company_controller.$inject = $inject;
