angular
  .module('VSPApp')
  .constant('JSONform',
[
  {
    "type": "tabs",
    "tabs": [
      {
        "title": "Stammdaten",
        "items": [
          {
            "title": "Person",
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "readonly": false,
                "htmlClass": "display_1_5",
                "items": [
                  {
                    "key": "data.person.sex",
                    "title": "Anrede",
                    "type": "select",
                    "readonly": true,
                    "titleMap": [
                      {
                        "value": true,
                        "name": "Herr"
                      },
                      {
                        "value": false,
                        "name": "Frau"
                      }
                    ]
                  },
                  {
                    "title": "Bearbeiten",
                    "type": "button",
                    "htmlClass": "text-right",
                    "readonly": false,
                    "onClick": "berater.editEmployee()"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "readonly": true,
                "items": [
                  {
                    "key": "data.person.firstname",
                    "title": "Vorname"
                  },
                  {
                    "key": "data.person.lastname",
                    "title": "Name"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "readonly": true,
                "items": [
                  {
                    "key": "data.person.employer_address.name",
                    "type": "text",
                    "title": "Arbeitgeber"
                  },
                  {
                    "key": "data.person.employeenumber",
                    "title": "Personalnummer"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.parameters.bundesland",
                    "title": "Bundesland Betriebsstätte",
                    "titleMap": [
                      {
                        "value": "BADEN_WUERTTEMBERG",
                        "name": "Baden-Würtenberg"
                      },
                      {
                        "value": "BAYERN",
                        "name": "Bayern"
                      },
                      {
                        "value": "BERLIN_WEST",
                        "name": "Berlin West"
                      },
                      {
                        "value": "BERLIN_OST",
                        "name": "Berlin Ost"
                      },
                      {
                        "value": "BRANDENBURG",
                        "name": "Brandenburg"
                      },
                      {
                        "value": "BREMEN",
                        "name": "Bremen"
                      },
                      {
                        "value": "HAMBURG",
                        "name": "Hamburg"
                      },
                      {
                        "value": "HESSEN",
                        "name": "Hessen"
                      },
                      {
                        "value": "MECKLENBURG_VORPOMMERN",
                        "name": "Mecklenburg-Vorpommern"
                      },
                      {
                        "value": "NIEDERSACHSEN",
                        "name": "Niedersachsen"
                      },
                      {
                        "value": "NORDRHEIN_WESTFALEN",
                        "name": "Nordrhein-Westfalen"
                      },
                      {
                        "value": "RHEINLAND_PFALZ",
                        "name": "Rheinland-Pfalz"
                      },
                      {
                        "value": "SAARLAND",
                        "name": "Saarland"
                      },
                      {
                        "value": "SACHSEN",
                        "name": "Sachsen"
                      },
                      {
                        "value": "SACHSEN_ANHALT",
                        "name": "Sachsen-Anhalt"
                      },
                      {
                        "value": "SCHLESWIG_HOLSTEIN",
                        "name": "Schleswig-Holstein"
                      },
                      {
                        "value": "THUERINGEN",
                        "name": "Thüringen"
                      }
                    ]
                  },
                  {
                    "key": "data.metadata.renteneintritt",
                    "title": "Renteneintritt",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 67,
                        "name": "67"
                      },
                      {
                        "value": 66,
                        "name": "66"
                      },
                      {
                        "value": 65,
                        "name": "65"
                      },
                      {
                        "value": 64,
                        "name": "64"
                      },
                      {
                        "value": 63,
                        "name": "63"
                      },
                      {
                        "value": 62,
                        "name": "62"
                      },
                      {
                        "value": 61,
                        "name": "61"
                      },
                      {
                        "value": 60,
                        "name": "60"
                      }
                    ]
                  },
                  {
                    "key": "data.parameters.sv",
                    "title": "Berechnung SV",
                    "titleMap": [
                      {
                        "value": "OST",
                        "name": "Ost"
                      },
                      {
                        "value": "WEST",
                        "name": "West"
                      },
                      {
                        "value": "OST_SACHSEN",
                        "name": "Sachsen"
                      }
                    ]
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.parameters.abrechnungstabelle",
                    "title": "Auswahl Berechnungstabelle",
                    "titleMap": [
                      {
                        "value": "EINFACHE",
                        "name": "Allgemeine Lohnsteuertabelle"
                      },
                      {
                        "value": "BESONDERE",
                        "name": "Besondere Lohnsteuertabelle"
                      }
                    ]
                  },
                  {
                    "key": "data.parameters.version",
                    "title": "Beginn Optimierung",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 2011,
                        "name": "2011"
                      },
                      {
                        "value": 2012,
                        "name": "2012"
                      },
                      {
                        "value": 2013,
                        "name": "2013"
                      },
                      {
                        "value": 2014,
                        "name": "2014"
                      },
                      {
                        "value": 2015,
                        "name": "2015"
                      },
                      {
                        "value": 2016,
                        "name": "2016"
                      }
                    ]
                  }
                ]
              },
              {
                "key": "data.metadata.info",
                "condition": "berater.iAmAdvisor",
                "title": "Zusätzliche Informationen"
              }
            ]
          },
          {
            "title": "Steuerdaten",
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.parameters.pap_stkl",
                    "title": "Steuerklasse",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 1,
                        "name": "I"
                      },
                      {
                        "value": 2,
                        "name": "II"
                      },
                      {
                        "value": 3,
                        "name": "III"
                      },
                      {
                        "value": 4,
                        "name": "IV"
                      },
                      {
                        "value": 5,
                        "name": "V"
                      },
                      {
                        "value": 6,
                        "name": "VI"
                      }
                    ]
                  },
                  {
                    "key": "data.parameters.pap_f",
                    "title": "Faktorverfahren",
                    "condition": "berater.employee.data.parameters.pap_stkl == 4"
                  },
                  {
                    "key": "data.parameters.freibetrag",
                    "title": "Freibetrag (jährlich) in Euro"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.parameters.pap_zkf",
                    "title": "Kinderfreibetrag",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 0,
                        "name": "0"
                      },
                      {
                        "value": 0.5,
                        "name": "0.5"
                      },
                      {
                        "value": 1,
                        "name": "1"
                      },
                      {
                        "value": 1.5,
                        "name": "1.5"
                      },
                      {
                        "value": 2,
                        "name": "2"
                      },
                      {
                        "value": 2.5,
                        "name": "2.5"
                      },
                      {
                        "value": 3,
                        "name": "3"
                      },
                      {
                        "value": 3.5,
                        "name": "3.5"
                      },
                      {
                        "value": 4,
                        "name": "4"
                      },
                      {
                        "value": 4.5,
                        "name": "4.5"
                      },
                      {
                        "value": 5,
                        "name": "5"
                      },
                      {
                        "value": 5.5,
                        "name": "5.5"
                      },
                      {
                        "value": 6,
                        "name": "6"
                      }
                    ]
                  },
                  {
                    "key": "data.parameters.pap_pvz",
                    "title": "Kinderlos und über 23"
                  },
                  {
                    "key": "data.parameters.pap_r",
                    "title": "Kirchensteuer"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.metadata.mindestlohn",
                    "title": "Mindeststundenlohn"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.metadata.regelarbeitszeit",
                    "title": "Regelarbeitszeit/Woche"
                  },
                  {
                    "key": "data.metadata.arbeitstage",
                    "title": "Arbeitstage/Woche",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 1,
                        "name": "1"
                      },
                      {
                        "value": 2,
                        "name": "2"
                      },
                      {
                        "value": 3,
                        "name": "3"
                      },
                      {
                        "value": 4,
                        "name": "4"
                      },
                      {
                        "value": 5,
                        "name": "5"
                      },
                      {
                        "value": 6,
                        "name": "6"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "title": "Kranken- und Pflegeversicherung",
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.parameters.kv_typ",
                    "title": "Versicherungsart",
                    "titleMap": [
                      {
                        "value": "GKV_PFLICHT",
                        "name": "pflicht"
                      },
                      {
                        "value": "GKV_FREIWILLIG",
                        "name": "freiwillig"
                      },
                      {
                        "value": "PKV_MIT_AG",
                        "name": "privat mit AG-Zuschuss"
                      },
                      {
                        "value": "PKV_OHNE_AG",
                        "name": "privat ohne AG-Zuschuss"
                      }
                    ]
                  },
                  {
                    "key": "data.parameters.pap_kvz",
                    "title": "Zusatzbeitragssatz",
                    "type": "percent",
                    "condition": "berater.employee.data.parameters.kv_typ != 'PKV_MIT_AG' && employee.data.parameters.kv_typ != 'PKV_OHNE_AG'"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "condition": "berater.employee.data.parameters.kv_typ != 'GKV_PFLICHT' && employee.data.parameters.kv_typ != 'GKV_FREIWILLIG'",
                "items": [
                  {
                    "key": "data.parameters.sl_kpv_pv_gesamt",
                    "title": "Prämie KV Gesamt"
                  },
                  {
                    "key": "data.parameters.sl_kpv_kv_basis",
                    "title": "davon Basisbeitrag nach BEG"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "condition": "berater.employee.data.parameters.kv_typ != 'GKV_PFLICHT' && employee.data.parameters.kv_typ != 'GKV_FREIWILLIG' && employee.data.parameters.kv_typ != 'PKV_OHNE_AG'",
                "items": [
                  {
                    "key": "data.parameters.sl_kpv_kv_zuschuss",
                    "title": "Zuschuss KV"
                  },
                  {
                    "key": "data.parameters.sl_kpv_pv_zuschuss",
                    "title": "Zuschuss PV"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "Bisherige Lohnarten",
        "items": [
          {
            "title": "Bruttolohnarten",
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.parameters.brutto",
                    "title": "Grundgehalt in Euro"
                  },
                  {
                    "key": "data.parameters.gleitzone",
                    "title": "IGZ",
                    "trueValue": false,
                    "falseValue": true
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.parameters.zulage_stsv_frei",
                    "title": "Zulage st-/sv-frei in Euro"
                  },
                  {
                    "key": "data.parameters.zulage_st_frei",
                    "title": "Zulage st-frei in Euro"
                  },
                  {
                    "key": "data.parameters.zulage_sv_frei",
                    "title": "Zulage sv-frei in Euro"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.parameters.einmalzahlung",
                    "title": "Einmalzahlung in Euro"
                  },
                  {
                    "key": "data.parameters.avwl_zuschuss",
                    "title": "VL- bzw. AWL-Zuschuss in Euro"
                  }
                ]
              },
              {
                "key": "data.parameters.zulagen",
                "add": "Hinzufügen",
                "close": "",
                "title": "Zulagen st-/sv-pflichtig",
                "items": [
                  {
                    "key": "data.parameters.zulagen[].description",
                    "title": "Beschreibung"
                  },
                  {
                    "key": "data.parameters.zulagen[].value",
                    "title": "Wert in Euro"
                  }
                ]
              }
            ]
          },
          {
            "title": "Nettolohnarten",
            "type": "fieldset",
            "items": [
              {
                "key": "data.parameters.sl_netto_abzug_vl",
                "title": "Abzug VL in Euro"
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.parameters.sl_netto_bezuege",
                    "title": "Bezüge in Euro"
                  },
                  {
                    "key": "data.parameters.netto_abzug_zv",
                    "title": "Abzüge / AN-Anteil ZV in Euro"
                  }
                ]
              }
            ]
          },
          {
            "title": "Dienstwagen",
            "type": "fieldset",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.parameters.dienstwagen_listenpreis",
                    "title": "Listenpreis Dienstwagen in Euro"
                  },
                  {
                    "key": "data.parameters.geldwert_vorteil_dienstwagen",
                    "title": "gw. Vorteil Dienstwagen in Euro"
                  },
                  {
                    "key": "data.parameters.dienstwagen_km",
                    "title": "km"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_2",
                "items": [
                  {
                    "key": "data.parameters.bl_bl_fahrkosten_zuschuss",
                    "title": "best. Entfernungspauschale in Euro"
                  },
                  {
                    "type": "section",
                    "htmlClass": "display_1_1",
                    "items": [
                      {
                        "key": "data.parameters.dienstwagen_entfernungspauschale_pst_agan",
                        "title": "Pauschalsteuer"
                      },
                      ""
                    ]
                  }
                ]
              }
            ]
          },
          {
            "title": "Bestehende betriebliche Altersvorsorge 1",
            "closed": true,
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.insurances.betrieblich_alv1.beitrag",
                    "title": "monatlicher Beitrag in Euro"
                  },
                  {
                    "key": "data.insurances.betrieblich_alv1.zuschuss_ag",
                    "title": "Zuschuss AG in Euro"
                  },
                  {
                    "key": "data.insurances.betrieblich_alv1.typ",
                    "title": "Typ",
                    "titleMap": [
                      {
                        "value": "PENSIONSKASSE",
                        "name": "Pensionskasse"
                      },
                      {
                        "value": "DIREKT",
                        "name": "Direktversicherung"
                      },
                      {
                        "value": "UKASSE",
                        "name": "U-Kasse"
                      }
                    ]
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.insurances.betrieblich_alv1.paragraph",
                    "title": "Paragraph"
                  },
                  {
                    "condition": "berater.employee.data.insurances.betrieblich_alv1.paragraph == 'P40B'",
                    "key": "data.insurances.betrieblich_alv1.pst_agan",
                    "title": "AN zahlt Pauschalsteuer"
                  }
                ]
              }
            ]
          },
          {
            "title": "Bestehende betriebliche Altersvorsorge 2",
            "closed": true,
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.insurances.betrieblich_alv2.beitrag",
                    "title": "monatlicher Beitrag in Euro"
                  },
                  {
                    "key": "data.insurances.betrieblich_alv2.zuschuss_ag",
                    "title": "zuschuss AG in Euro"
                  },
                  {
                    "key": "data.insurances.betrieblich_alv2.typ",
                    "title": "Typ",
                    "titleMap": [
                      {
                        "value": "PENSIONSKASSE",
                        "name": "Pensionskasse"
                      },
                      {
                        "value": "DIREKT",
                        "name": "Direkversicherung"
                      },
                      {
                        "value": "UKASSE",
                        "name": "U-Kasse"
                      }
                    ]
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.insurances.betrieblich_alv2.paragraph",
                    "title": "Paragraph"
                  },
                  {
                    "condition": "berater.employee.data.insurances.betrieblich_alv2.paragraph == 'P40B'",
                    "key": "data.insurances.betrieblich_alv2.pst_agan",
                    "title": "AN zahlt Pauschalsteuer"
                  }
                ]
              }
            ]
          },
          {
            "title": "Zusatzversorgungskasse ZVK Angaben",
            "closed": true,
            "type": "fieldset",
            "items": [
              {
                "key": "data.parameters.zvk_hinzu",
                "title": "VersAnteil-ZVK in Euro"
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.parameters.zvk_versteuert",
                    "title": "ind-verstZVK in Euro"
                  },
                  {
                    "key": "data.parameters.zvk_sv_pflichtig",
                    "title": "SV-Pfl-Hinzu in Euro"
                  }
                ]
              }
            ]
          },
          {
            "title": "Entgeltrahmenabkommen",
            "closed": true,
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.parameters.era_ueberschreiter",
                    "title": "Überschreiter Ausgleich in Euro"
                  },
                  {
                    "key": "data.parameters.era_unterschreiter",
                    "title": "Unterschreiter Ausgleich in Euro"
                  }
                ]
              },
              {
                "key": "data.parameters.era_anpassungs_betrag",
                "title": "Anpassungsbetrag in Euro"
              }
            ]
          }
        ]
      },
      {
        "title": "NEO",
        "items": [
          {
            "title": "Ziel",
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.parameters.einsparungs_modell",
                    "title": "Zielart",
                    "onChange": "berater.updateZielausgleichArt()",
                    "titleMap": [
                      {
                        "value": "NONE",
                        "name": "1:1 Tausch"
                      },
                      {
                        "value": "AN",
                        "name": "AN Nettovorteil in €"
                      },
                      {
                        "value": "AN_RELATIVE",
                        "name": "AN Nettovorteil in %"
                      },
                      {
                        "value": "AG",
                        "name": "AG Lohnkostenersparnis in €"
                      },
                      {
                        "value": "AN_AG_RELATIVE",
                        "name": "50/50"
                      }
                    ]
                  },
                  {
                    "type": "section",
                    "items": [
                      {
                        "key": "data.parameters.einsparungs_wert",
                        "title": "Wert in Euro",
                        "description": "",
                        "condition": "berater.employee.data.parameters.einsparungs_modell == 'AN' || berater.employee.data.parameters.einsparungs_modell == 'AG'"
                      },
                      {
                        "key": "data.parameters.einsparungs_prozent",
                        "type": "percent",
                        "title": "Wert",
                        "description": "",
                        "condition": "berater.employee.data.parameters.einsparungs_modell == 'AN_RELATIVE'"
                      }
                    ]
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "condition": "berater.employee.data.parameters.einsparungs_modell",
                    "type": "button",
                    "title": "Berechnen",
                    "onClick": "berater.calculateZielausgleich()"
                  },
                  {
                    "key": "data.parameters.entgeltverzicht_additional",
                    "title": "zusätzlicher Entgeltverzicht in Euro"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "result.final.tax.entgeltverzicht",
                    "type": "number",
                    "readonly": true,
                    "title": "Entgeltverzicht gesamt in Euro"
                  }
                ]
              }
            ]
          },
          {
            "title": "Behandlung ALV und KTG",
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.metadata.behandlung_alvktg",
                    "title": "Art",
                    "titleMap": [
                      {
                        "value": "GELDWERTER_VORTEIL",
                        "name": "geldwerter Vorteil"
                      },
                      {
                        "value": "NETTOABZUG",
                        "name": "Nettoabzug"
                      },
                      {
                        "value": "OHNE_BERUECKSICHTIGUNG",
                        "name": "ohne Berücksichtigung"
                      }
                    ]
                  },
                  {
                    "key": "result.final.gap.alv_ktg",
                    "type": "text",
                    "title": "ALV/KTG-Ausgleich in Euro",
                    "readonly": "true"
                  }
                ]
              }
            ]
          },
          {
            "title": "Versorgunsausgleich",
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "type": "button",
                    "title": "Berechne VA",
                    "onClick": "berater.calculateVA()"
                  },
                  {
                    "title": "Rentenzins in %",
                    "key": "data.va.pension_interest",
                    "type": "percent"
                  },
                  {
                    "title": "errechneter VA in Euro",
                    "key": "resultVA",
                    "type": "number",
                    "readonly": "true"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.insurances.va.beitrag",
                    "title": "monatlicher Beitrag in Euro",
                    "readonly": true
                  },
                  {
                    "key": "data.insurances.va.zuschuss_ag",
                    "title": "Zuschuss AG in Euro",
                    "copyValueTo": [
                      "data.insurances.va.beitrag"
                    ]
                  },
                  {
                    "key": "data.insurances.va.typ",
                    "title": "Typ",
                    "titleMap": [
                      {
                        "value": "OHNEBERUECKSICHTIGUNG",
                        "name": "ohne Berücksichtigung"
                      },
                      {
                        "value": "PENSIONSKASSE",
                        "name": "Pensionskasse"
                      },
                      {
                        "value": "DIREKT",
                        "name": "Direktversicherung"
                      },
                      {
                        "value": "UKASSE",
                        "name": "U-Kasse"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "title": "Betriebliche Altersvorsorge",
            "closed": true,
            "type": "fieldset",
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.insurances.bav.beitrag",
                    "title": "monatlicher Beitrag in Euro"
                  },
                  {
                    "key": "data.insurances.bav.zuschuss_ag",
                    "title": "Zuschuss AG in Euro"
                  },
                  {
                    "key": "data.insurances.bav.typ",
                    "title": "Typ",
                    "titleMap": [
                      {
                        "value": "PENSIONSKASSE",
                        "name": "Pensionskasse"
                      },
                      {
                        "value": "DIREKT",
                        "name": "Direktversicherung"
                      },
                      {
                        "value": "UKASSE",
                        "name": "U-Kasse"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Sachbezug",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.sachbezug.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.neo_components.sachbezug.sachbezugwahl",
                    "title": "Sachbezugwahl",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 1,
                        "name": "Vorteilskonto"
                      },
                      {
                        "value": 2,
                        "name": "ValueCard"
                      },
                      {
                        "value": 3,
                        "name": "Amazon"
                      }
                    ]
                  },
                  {
                    "key": "data.neo_components.sachbezug.value",
                    "title": "Wert in Euro"
                  },
                  {}
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Essensscheck",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.essenscheck.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.neo_components.essenscheck.anzahl_tage",
                    "title": "Anzahl Essensschecks",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 1,
                        "name": "3"
                      },
                      {
                        "value": 2,
                        "name": "6"
                      },
                      {
                        "value": 3,
                        "name": "9"
                      },
                      {
                        "value": 4,
                        "name": "12"
                      },
                      {
                        "value": 5,
                        "name": "15"
                      }
                    ]
                  },
                  {
                    "key": "data.neo_components.essenscheck.value",
                    "title": "Wert in Euro"
                  },
                  {
                    "key": "data.neo_components.essenscheck.pst",
                    "title": "Pauschalsteuer"
                  }
                ]
              },
              {
                "key": "data.neo_components.essenscheck.is_sachbezug",
                "title": "als Sachbezug"
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Verpflegungsmehraufwand Steuer- und SV-frei",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.VMA_STSVFREI.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.neo_components.VMA_STSVFREI.anzahl_std_abwesend",
                    "title": "Anzahl Stunden Abwesend",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 0,
                        "name": "mind. 8h"
                      },
                      {
                        "value": 24,
                        "name": "ganztägig (24h)"
                      }
                    ]
                  },
                  {
                    "key": "data.neo_components.VMA_STSVFREI.anzahl_tage",
                    "title": "Anzahl Tage"
                  },
                  {
                    "key": "data.neo_components.VMA_STSVFREI.value_calc",
                    "title": "Wert in Euro"
                  },
                  {
                    "key": "data.neo_components.VMA_STSVFREI.value",
                    "title": "Wert in Euro"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Verpflegungsmehraufwand pauschal versteuert",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.VMA_PST.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.neo_components.VMA_PST.anzahl_std_abwesend",
                    "title": "Anzahl Stunden Abwesend",
                    "type": "select",
                    "titleMap": [
                      {
                        "value": 0,
                        "name": "mind. 8h"
                      },
                      {
                        "value": 24,
                        "name": "ganztägig (24h)"
                      }
                    ]
                  },
                  {
                    "key": "data.neo_components.VMA_PST.anzahl_tage",
                    "title": "Anzahl Tage"
                  },
                  {
                    "key": "data.neo_components.VMA_PST.value_calc",
                    "title": "Wert in Euro"
                  },
                  {
                    "key": "data.neo_components.VMA_PST.value",
                    "title": "Wert in Euro"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {},
                  {
                    "key": "data.neo_components.VMA_PST.pst",
                    "title": "Pauschalsteuer"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Mobiltelefon",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.handy.enabled",
                    "title": "Aktivieren"
                  },
                  {
                    "type": "section",
                    "htmlClass": "display_2_1",
                    "items": [
                      {
                        "key": "data.neo_components.handy.value",
                        "title": "Wert in Euro"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Festnetz",
            "closed": true,
            "items": [
              {
                "key": "data.neo_components.festnetz.enabled",
                "title": "Aktivieren"
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.festnetz.value",
                    "title": "Wert in Euro"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Werbeflächenanmietung",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.werbeflaeche.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.werbeflaeche.value",
                    "title": "Wert in Euro"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Dienstwagengarage Auslage",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.dienstwagen_auslage.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.dienstwagen_auslage.value",
                    "title": "Wert in Euro"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Dienstwagengarage Miete",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.dienstwagen_miete.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.dienstwagen_miete.value",
                    "title": "Wert in Euro"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "MPP",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.pc.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.pc.value",
                    "title": "Wert in Euro"
                  }
                ]
              },
              {
                "type": "button",
                "title": "Zum MPP-Portal",
                "onClick": "berater.openMPP()"
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Dienstrad",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "type": "section",
                    "htmlClass": "display_1_1",
                    "items": [
                      {
                        "key": "data.neo_components.EBike.enabled",
                        "title": "Aktivieren"
                      },
                      {
                        "type": "help",
                        "helpvalue": "<a target='_blank' class='button button-primary' href='https://www.jobrad.org/fachhaendler-suchmaschine'>Händlersuche</a>"
                      }
                    ]
                  },
                  {
                    "type": "section",
                    "htmlClass": "display_1_1_1",
                    "items": [
                      {
                        "key": "data.neo_components.EBike.anbieter",
                        "title": "Anbieter",
                        "type": "select",
                        "readonly": true,
                        "titleMap": [
                          {
                            "value": 2,
                            "name": "JobRad"
                          }
                        ]
                      },
                      {
                        "key": "data.neo_components.EBike.listenpreis",
                        "title": "Listenpreis"
                      },
                      {
                        "key": "data.neo_components.EBike.verkaufspreis",
                        "title": "Verkaufspreis"
                      }
                    ]
                  },
                  {
                    "type": "section",
                    "htmlClass": "display_1",
                    "items": [
                      {
                        "key": "data.neo_components.EBike.value",
                        "readonly": true,
                        "title": "Wert in Euro"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Erholungsbeihilfe",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.erholungsbeihilfe.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.erholungsbeihilfe.value",
                    "title": "Wert in Euro"
                  },
                  {
                    "key": "data.neo_components.erholungsbeihilfe.pst",
                    "title": "Pauschalsteuer"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Personalrabatt",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.personal_rabatt.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.personal_rabatt.value",
                    "title": "Wert in Euro"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Internetpauschale",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1",
                "items": [
                  {
                    "key": "data.neo_components.internet.enabled",
                    "title": "Aktivieren"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.internet.value",
                    "title": "Wert in Euro"
                  },
                  {
                    "key": "data.neo_components.internet.pst",
                    "title": "Pauschalsteuer"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Entfernungspauschale",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.neo_components.entfernung.enabled",
                    "title": "Aktivieren"
                  },
                  {
                    "type": "button",
                    "title": "Entfernung berechnen",
                    "onClick": "berater.calculateDistance()"
                  },
                  {
                    "type": "button",
                    "title": "Adresse bearbeiten",
                    "onClick": "berater.openAdressDialog()"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1_1",
                "items": [
                  {
                    "key": "data.neo_components.entfernung.anzahl_km",
                    "title": "Anzahl km"
                  },
                  {
                    "key": "data.neo_components.entfernung.anzahl_tage",
                    "title": "Anzahl Tage"
                  },
                  {
                    "key": "data.neo_components.entfernung.cent_pro_km",
                    "title": "Euro pro km",
                    "readonly": true
                  },
                  {
                    "key": "data.neo_components.entfernung.value_calc",
                    "title": "Wert in Euro"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_2_1",
                "items": [
                  {
                    "key": "data.neo_components.entfernung.value",
                    "title": "Wert in Euro"
                  },
                  {
                    "key": "data.neo_components.entfernung.pst",
                    "title": "Pauschalsteuer"
                  }
                ]
              }
            ]
          },
          {
            "type": "fieldset",
            "title": "Kindergartenzuschuss",
            "closed": true,
            "items": [
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.neo_components.kinder.enabled",
                    "title": "Aktivieren"
                  },
                  {
                    "type": "button",
                    "title": "Assistent öffnen",
                    "onClick": "berater.openKinderAssistant()"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "display_1_1",
                "items": [
                  {
                    "key": "data.neo_components.kinder.value",
                    "title": "Wert in Euro"
                  },
                  {
                    "type": "help",
                    "helpvalue": "<a target='_blank' href='assets/files/Aufwendungsnachweis_Kindergarten.pdf'>Aufwendungsnachweis</a>"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "Bonus",
        "readonly": true,
        "items": []
      },
      {
        "title": "Gehaltserhöhung",
        "readonly": true,
        "items": []
      }
    ]
  }
]);
