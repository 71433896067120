import {Inject, Injectable} from '@angular/core';
import {
  DirectBonusProjectOverviewMapper, DirectBonusProjectOverview
} from "../models/direct-bonus-project-overview.model";
import {
  DirectBonusComponentType,
  DirectProjectOverviewBonusTableDataRow, DirectProjectOverviewBonusTableDataRowMapper
} from '../models/direct-bonus-project-overview-table-data.model';
import {from, Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";
import {
  CustomerOrder,
  CustomerOrderFilterChoices,
  CustomerOrderFiltersMapper,
  CustomerOrderMapper,
  CustomerOrderTableData,
  CustomerOrderTableDataBO,
  CustomerOrderTableDataBOMapper,
  CustomerOrderTableDataMapper,
  ProblematicOrderForInvoice
} from '../models/direct-bonus-customer-order.model';
import {
  DirectBonus,
  DirectBonusFormData,
  DirectBonusMapper,
  DirectBonusProjectConfiguration,
  DirectBonusProjectComponentConfigurationMapper,
  DirectBonusProjectComponentConfiguration
} from '../models/direct-bonus-data.model';
import {SelectOption} from 'src/app/shared/components/form-mixins/select/select.component';
import {formatDateToYYYYMMDD} from 'src/app/shared/common';
import {DirectBonusBulkUploadResult} from "../models/direct-bonus-bulk-upload-result";
import * as moment from 'moment';

const _toObservableWithMap = (
  params: { mapper: string }
) => {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;

    if (typeof original === 'function') {
      descriptor.value = function (...args: any[]) {

        const serviceInstance = this as any
        try {
          const result$ = from(original.apply(this, args));
          return result$.pipe(
            map(
              (results: any) => results.map((o: any) => serviceInstance[params.mapper].fromApi(o))
            ),
            take(1),
          );
        } catch (e) {
          console.log(`Error: ${e}`);
          throw e;
        }
      }
    }
    return descriptor;
  }
}

@Injectable()
export class DirectBonusService {

  private readonly URL_PREFIX = 'rpc://valuenetdb/direct_bonus/';

  constructor(
    @Inject('Made') private made: any,
    @Inject('fileService') private fileService: any,
    @Inject('EmployeeService') private employeeService: any,
    @Inject('customerService') private customerService: any,
    private bonusProjectOverviewMapper: DirectBonusProjectOverviewMapper,
    private directProjectOverviewBonusTableDataRowMapper: DirectProjectOverviewBonusTableDataRowMapper,
    private customerOrderMapper: CustomerOrderMapper,
    private directBonusMapper: DirectBonusMapper,
    private customerOrderTableDataBOMapper: CustomerOrderTableDataBOMapper,
    private customerOrderTableDataMapper: CustomerOrderTableDataMapper,
    private directBonusProjectComponentConfigurationMapper: DirectBonusProjectComponentConfigurationMapper,
  ) {
  }

  // TODO fix as observable
  public async bonusIssuerHasDirectBonusProject(): Promise<boolean> {
    let bonusProjectOverviews: [] = await this.made.request(this.URL_PREFIX + 'get_direct_bonus_projects_overview');
    return !!bonusProjectOverviews.length;
  }

  async getActiveBonusesComponentNames(employeeId: number, activePeriodStart: Date, activePeriodEnd?: Date): Promise<string[]> {
    let activeBonusesNames: string[] = await this.made.request(this.URL_PREFIX + 'get_active_components_names', {
      employee_id: employeeId,
      active_period_start: activePeriodStart,
      active_period_end: activePeriodEnd
    });

    return activeBonusesNames;
  }

  public downloadInvoiceCsvData(invoiceData: any[]) {
    type InvoiceColumnsMapper = {
      [key: string]: string;
    };

    const columns: InvoiceColumnsMapper = {
      'proj': "project",
      'zahlungsart': "order_payment_type",
      'nachbem': "rem",
      'auftragdatum': "order_date",
      'bezeichnung': "designation",
      'anzahl': "number_of_bonuses",
      'einzel': "bonus_value",
      'summe': "bonus_value_sum",
      'freifeld1': "field1",
      'mandatsreferenz': "mandate_reference",
      'freifeld3': "order_reference",
      'auftragsbeschr': "order_description",
      'kunde': "customer_name",
      'standort': "location_name",
      'strasse': "street",
      'plz': "street_number",
      'ort': "city",
      'st': "st",
      'freifeld4': "field4",
      'rgdatum': "creation_date",
      'mail': "email",
      'warengruppe': "product_group",
      'freifeld5': "field5",
      'freifeld6': "field6",
      'einheit': "unit",
      'debitor': "debitor",
    };

    const csvColumns = Object.keys(columns);

    const csvData: any[] = [];
    let invoiceType: any = null;
    invoiceData.forEach((invoiceRow) => {

      if (!invoiceType) {
        invoiceType = invoiceRow[columns['freifeld1']];
      }

      const csvRow = Object.assign({}, ...csvColumns.map(
        key => ({[key]: invoiceRow[columns[key]]})
      ));

      csvData.push(csvRow);
    });

    const fileName = `${moment(new Date()).format('YYYY-MM-DD')}_${invoiceType}_Bonus.csv`;
    this.fileService.saveFile(csvData, csvColumns, fileName);
  }

  // ====================================================================================
  // ========================================== PROJECT ==========================================
  // ====================================================================================

  @_toObservableWithMap({mapper: 'directBonusProjectComponentConfigurationMapper'})
  public getBonusComponentConfigurationsByProjectId(
    bonusProjectId: string
  ): Observable<DirectBonusProjectComponentConfiguration[]> {
    return this.made.request(this.URL_PREFIX + 'get_bonus_configurations_by_project_id', {
      'bonus_project_id': bonusProjectId
    })
  }

  @_toObservableWithMap({mapper: 'directBonusProjectComponentConfigurationMapper'})
  public getAllowedForCheckoutComponentsConfigurations(
    employeeId: number,
    forMonth: number,
    forYear: number,
    bonusProjectId: string,
  ): Observable<DirectBonusProjectComponentConfiguration[]> {

    return this.made.request(this.URL_PREFIX + 'get_allowed_for_checkout_components_configurations', {
      'employee_id': employeeId,
      'for_month': forMonth,
      'for_year': forYear,
      'bonus_project_id': bonusProjectId
    });
  }

  public getAvailableBonusComponents(
    bonusProjectConfiguration: DirectBonusProjectConfiguration
  ): SelectOption<DirectBonusComponentType>[] {
    return Object.entries(bonusProjectConfiguration).reduce(
      (availableComponents, [componentType, componentConfiguration]) => {
        let componentName: string;

        switch (componentType) {
          case 'besonderer_anlass':
            componentName = 'Besonderer Anlass';
            break;
          case 'sachbezug':
            componentName = 'Sachbezug';
            break;
          default:
            throw Error('Ungültige Komponentenname.')
        }

        if (componentConfiguration.enabled) {
          availableComponents.push({
            name: componentName,
            value: componentType
          });
        }

        return availableComponents;
      },
      [] as SelectOption<DirectBonusComponentType>[]
    );
  }

  public canBoMarkOrderForInvoice(orderId: string): Observable<boolean> {
    return this.made.request(this.URL_PREFIX + 'can_bo_mark_order_for_invoice', {
      order_id: orderId
    });
  }

  public getBonusProjectConfiguration(
    bonusProjectId: string
  ): Observable<DirectBonusProjectConfiguration> {
    return this
      .getBonusComponentConfigurationsByProjectId(bonusProjectId)
      .pipe(
        map(componentConfigurations => {
          return componentConfigurations.reduce(
            (configuration, componentConfig) => {
              configuration[componentConfig.componentType!] = componentConfig;
              return configuration;
            },
            {} as DirectBonusProjectConfiguration
          )
        })
      );
  }

  @_toObservableWithMap({mapper: 'bonusProjectOverviewMapper'})
  public getDirectBonusProjectsOverview(): Observable<DirectBonusProjectOverview[]> {
    return this.made.request(this.URL_PREFIX + 'get_direct_bonus_projects_overview');
  }

  @_toObservableWithMap({mapper: 'directProjectOverviewBonusTableDataRowMapper'})
  public getProjectOverviewTableData(
    bonusProjectId: string,
    year: number,
    employeesWithBonuses: boolean,
  ): Observable<DirectProjectOverviewBonusTableDataRow[]> {
    return this.made.request(this.URL_PREFIX + 'get_project_overview_table_data', {
      'bonus_project_id': bonusProjectId,
      'year': year,
      'employees_with_bonuses': employeesWithBonuses
    });

  }

  // ====================================================================================
  // ========================================== ORDER ==========================================
  // ====================================================================================

  @_toObservableWithMap({mapper: 'customerOrderTableDataBOMapper'})
  public getCustomerOrdersTableDataBO(
    filters: CustomerOrderFilterChoices
  ): Observable<CustomerOrderTableDataBO[]> {
    return this.made.request(this.URL_PREFIX + 'get_orders_for_bo_administration', {
      'order_filters': new CustomerOrderFiltersMapper().toApi(filters)
    });
  }

  @_toObservableWithMap({mapper: 'customerOrderMapper'})
  public getOrders(filters: any): Observable<CustomerOrder[]> {
    // could be extended
    let beFilters = {} as any
    if (filters.id) {
      beFilters['id'] = filters.id
    }

    return this.made.request(this.URL_PREFIX + 'get_orders', {
      'order_filters': beFilters
    });
  }

  @_toObservableWithMap({mapper: 'customerOrderTableDataMapper'})
  public getCustomerOrdersTableData(
    filters: CustomerOrderFilterChoices
  ): Observable<CustomerOrderTableData[]> {
    return this.made.request(this.URL_PREFIX + 'get_orders_for_customer_administration', {
      'order_filters': new CustomerOrderFiltersMapper().toApi(filters)
    });
  }

  public confirmCustomerOrder(
    orderId: string
  ) {
    let confirmCustomerOrder = from(
      // TODO: extract into mapper
      this.made.request(this.URL_PREFIX + 'customer_confirm_order', {
        'customer_order_id': orderId
      })
    );

    return confirmCustomerOrder.pipe(take(1));
  }

  public revertCustomerOrder(
    orderId: string
  ) {
    let revertCustomerOrder$ = from(
      this.made.request(this.URL_PREFIX + 'customer_revert_confirmed_order', {
        'customer_order_id': orderId
      })
    );

    return revertCustomerOrder$;
  }

  public markOrderForInvoice(
    orderId: string
  ) {
    let markOrderForInvoice$ = from(
      this.made.request(this.URL_PREFIX + 'bo_mark_order_for_invoice', {
        'customer_order_id': orderId,
      })
    );

    return markOrderForInvoice$;
  }

  public getInvoiceCsvData(
    orderIds: string[]
  ) {
    let getInvoiceCsvData$ = from(
      this.made.request(this.URL_PREFIX + 'get_invoice_csv_data', {
        'order_ids': orderIds,
      })
    );

    return getInvoiceCsvData$;
  }

  public markOrderAsPaid(
    orderId: string,
    paymentDate: Date
  ) {
    let now_utc = Date.UTC(
      paymentDate.getUTCFullYear(),
      paymentDate.getUTCMonth(),
      paymentDate.getUTCDate(),
      paymentDate.getUTCHours(),
      paymentDate.getUTCMinutes(),
      paymentDate.getUTCSeconds()
    );

    paymentDate = new Date(now_utc);

    let markOrderAsPaid$ = from(
      this.made.request(this.URL_PREFIX + 'bo_mark_order_as_paid', {
        'customer_order_id': orderId,
        'date_of_payment': paymentDate
      })
    );

    return markOrderAsPaid$;
  }

  public markOrderAsUnpaid(
    orderId: string,
    comment: string,
    otrsTicket: string
  ) {
    let markOrderAsUnpaid$ = from(
      this.made.request(this.URL_PREFIX + 'bo_return_order_to_invoice', {
        'customer_order_id': orderId,
        'comment': comment,
        'otrs_ticket_number': otrsTicket
      })
    );

    return markOrderAsUnpaid$;
  }

  public markOrderAsDone(
    orderId: string,
    comment: string,
    OTRSTicket: string
  ) {
    let markOrderAsDone$ = from(
      this.made.request(this.URL_PREFIX + 'bo_mark_order_as_done', {
        'customer_order_id': orderId,
        'comment': comment,
        'otrs_ticket_number': OTRSTicket
      })
    );

    return markOrderAsDone$;
  }

  public markOrderAsCancelled(
    orderId: string,
    comment: string,
    OTRSTicket: string
  ) {
    let markOrderAsCancelled$ = from(
      this.made.request(this.URL_PREFIX + 'bo_mark_order_as_cancelled', {
        'customer_order_id': orderId,
        'comment': comment,
        'otrs_ticket_number': OTRSTicket
      })
    );

    return markOrderAsCancelled$;
  }

  public putOrderOnHold(
    orderId: string,
    ticketNumber: string,
    comment: string
  ) {
    let putOrderOnHold$ = from(
      this.made.request(this.URL_PREFIX + 'bo_update_customer_order_on_hold_status', {
        'customer_order_id': orderId,
        'is_on_hold': true,
        'otrs_ticket_number': ticketNumber,
        'comment': comment,
      })
    );

    return putOrderOnHold$;
  }

  public putOrderOffHold(
    orderId: string,
    ticketNumber: string,
    comment: string
  ) {
    let putOrderOffHold$ = from(
      this.made.request(this.URL_PREFIX + 'bo_update_customer_order_on_hold_status', {
        'customer_order_id': orderId,
        'is_on_hold': false,
        'otrs_ticket_number': ticketNumber,
        'comment': comment,
      })
    );

    return putOrderOffHold$;
  }

  public changeOrderPaymentType(
    orderId: string,
    ticketNumber: string,
    comment: string
  ) {
    let changeOrderPaymentType$ = from(
      this.made.request(this.URL_PREFIX + 'bo_update_customer_order_payment_type', {
        'customer_order_id': orderId,
        'otrs_ticket_number': ticketNumber,
        'comment': comment,
      })
    );

    return changeOrderPaymentType$;
  }

  public extendDeadline(
    orderId: string,
    ticketNumber: string,
    newDeadline: Date
  ) {

    return from(
      this.made.request(this.URL_PREFIX + 'bo_extend_order_deadline', {
        'order_id': orderId,
        'otrs_ticket_number': ticketNumber,
        'new_deadline': formatDateToYYYYMMDD(newDeadline),
      })
    );
  }

  // ====================================================================================
  // ========================================== BONUS ==========================================
  // ====================================================================================

  @_toObservableWithMap({mapper: 'directBonusMapper'})
  public getBonusesByOrderId(
    orderId: string
  ): Observable<DirectBonus[]> {
    return this.made.request(this.URL_PREFIX + 'get_bonuses_by_order_id', {
      'order_id': orderId,
    });
  }

  public addBonusToExistingOrder(
    directBonusDialogData: DirectBonusFormData
  ) {
    let addBonusToExistingOrder$ = from(
      this.made.request(this.URL_PREFIX + 'customer_create_create_bonus_and_add_to_order_by_id', {
        'direct_bonus_create': {
          bonus_project_id: directBonusDialogData.bonusProjectId,
          value: directBonusDialogData.formValue,
          employee_id: directBonusDialogData.employeeId,
          customer_id: directBonusDialogData.customerId,
          component_name: directBonusDialogData.componentName,
          reason: directBonusDialogData.formReason
        },
        'customer_order_id': directBonusDialogData.orderId
      })
    );

    return addBonusToExistingOrder$.pipe(take(1));
  }

  public customerAddBonusToExtraOrder(directBonusDialogData: DirectBonusFormData) {
    if (!directBonusDialogData.orderId) {
      throw new Error('Could not add bonus without order id');
    }

    let r$ = from(
      this.made.request(this.URL_PREFIX + 'customer_add_bonus_to_extra_order', {
        'direct_bonus_fe_model': {
          bonus_project_id: directBonusDialogData.bonusProjectId,
          value: directBonusDialogData.formValue,
          employee_id: directBonusDialogData.employeeId,
          customer_id: directBonusDialogData.customerId,
          component_name: directBonusDialogData.componentName,
          reason: directBonusDialogData.formReason,
          order_id: directBonusDialogData.orderId
        },
      })
    );
    return r$.pipe(take(1));
  }

  public addOrderBonusToExistingOrder(
    directBonusDialogData: DirectBonusFormData,
  ) {
    let addOrderBonusToExistingOrder$ = from(
      this.made.request(this.URL_PREFIX + 'bo_administration_create_bonus_and_add_to_order_by_id', {
        'direct_bonus_create': {
          bonus_project_id: directBonusDialogData.bonusProjectId,
          value: directBonusDialogData.formValue,
          employee_id: directBonusDialogData.employeeId,
          customer_id: directBonusDialogData.customerId,
          component_name: directBonusDialogData.componentName,
          reason: directBonusDialogData.formReason
        },
        'customer_order_id': directBonusDialogData.orderId,
      })
    );

    return addOrderBonusToExistingOrder$.pipe(take(1));
  }

  public addBonusToNewOrder(
    directBonusDialogData: DirectBonusFormData
  ) {
    let addBonusToNewOrder$ = from(
      // TODO: extract into mapper
      this.made.request(this.URL_PREFIX + 'customer_create_create_bonus_and_add_to_new_order', {
        'direct_bonus_create': {
          bonus_project_id: directBonusDialogData.bonusProjectId,
          value: directBonusDialogData.formValue,
          employee_id: directBonusDialogData.employeeId,
          customer_id: directBonusDialogData.customerId,
          component_name: directBonusDialogData.componentName,
          reason: directBonusDialogData.formReason,
        },
        'year': directBonusDialogData.year,
        'month': directBonusDialogData.month,
      })
    );

    return addBonusToNewOrder$.pipe(take(1));
  }

  public updateBonusAmount(
    directBonusDialogData: DirectBonusFormData,
  ) {
    let updateBonusAmount$ = from(
      // TODO: extract into mapper
      this.made.request(this.URL_PREFIX + 'customer_administration_update_bonus_value', {
        'bonus_id': directBonusDialogData.bonusId,
        'value': directBonusDialogData.formValue,
        'reason': directBonusDialogData.formReason
      })
    );

    return updateBonusAmount$.pipe(take(1));
  }

  public updateOrderBonusAmount(
    directBonusDialogData: DirectBonusFormData,
  ) {
    let updateOrderBonusAmount$ = from(
      // TODO: extract into mapper
      this.made.request(this.URL_PREFIX + 'bo_administration_update_bonus_value', {
        'bonus_id': directBonusDialogData.bonusId,
        'value': directBonusDialogData.formValue,
      })
    );

    return updateOrderBonusAmount$.pipe(take(1));
  }

  public removeBonusFromOrder(
    bonusId: string,
    orderId: string
  ) {
    let removeBonusFromOrder$ = from(
      this.made.request(this.URL_PREFIX + 'bo_remove_bonus_from_order', {
        'bonus_id': bonusId,
        'order_id': orderId,
      })
    );

    return removeBonusFromOrder$;
  }

  public bonusBulkUpload(
    bonusProjectId: string,
    customerId: number,
    year: number,
    csvTableFile: File,
  ): Observable<DirectBonusBulkUploadResult> {

    const bulkUploadPromise = this.made.upload(
      csvTableFile,
      [
        'direct_bonus',
        'bulk_upload',
        `bonus_project_id-${bonusProjectId}`,
        `customer_id-${customerId}`,
      ],
      this.made.user._id
    ).then(() => {
      return this.made.request(
        this.URL_PREFIX + 'bonus_bulk_upload',
        {
          'customer_id': customerId,
          'bonus_project_id': bonusProjectId,
          'for_year': year
        }
      )
    });

    return from(bulkUploadPromise).pipe(
      map((res: any) => {
        return {
          usersWithMissingEmployeeId: res.users_with_missing_employee_id.map((u:any) => {
            return {
              employeeId: u.employee_id,
              personalNumber: u.personal_number
            };
          }),
          usersWithInvalidEmployeeId: res.users_with_invalid_employee_id.map((u:any) => {
            return {
              employeeId: u.employee_id,
              personalNumber: u.personal_number
            };
          }),
          usersWithDifferentCustomer: res.users_with_different_customer.map((u:any) => {
            return {
              employeeId: u.employee_id,
              customerId: u.customer_id
            };
          }),
          usersWithDuplicateComponents: res.users_with_duplicate_components.map((u:any) => {
            return {
              employeeId: u.employee_id,
              duplicateComponents: u.duplicate_components
            };
          })
        };
      })
    );
  }

  public bulkAddUsers(
    customerId: number,
    csvTableFile: File
  ): Observable<{ data: any[]; errors: any[], rejected: bigint }> {
    const bulkAddUsersPromise: Promise<{ data: any[]; errors: any[], rejected: bigint }> = this.made.upload(
      csvTableFile,
      [
        `customer_id-${customerId}`,
        'bulk_user_upload'
      ],
      this.made.user._id
    ).then(() => {
      return this.made.request(
        this.URL_PREFIX + 'bulk_create_users',
        {
          'customer_id': customerId,
          'user_id': this.made.user._id,
        }
      )
    });

    return from(bulkAddUsersPromise);
  }

  public downloadInvitationData$(
    customerId: number,
  ) {
    const downloadInvitationData$ = from(
      this.customerService.getUsersWithActivationCode(customerId, false)
    );

    return downloadInvitationData$
  }

  public bulkMarkOrderAsPaid(
    filters: CustomerOrderFilterChoices
  ): Observable<Boolean> {
    const APIFilters = new CustomerOrderFiltersMapper().toApi(filters);

    const bulkMarkOrderAsPaid$ = from(
      this.made.request(this.URL_PREFIX + 'bulk_bo_mark_order_as_paid', {
        'order_filters': {
          'customer_id': APIFilters['customer_id'],
          'for_year': APIFilters['for_year'],
          'for_month': APIFilters['for_month']
        }
      })
    )

    return bulkMarkOrderAsPaid$.pipe(map(res => !!res));
  }

  public bulkMarkAsDone(
    filters: CustomerOrderFilterChoices
  ): Observable<Boolean> {
    const APIFilters = new CustomerOrderFiltersMapper().toApi(filters);

    const bulkMarkOrderAsPaid$ = from(
      this.made.request(this.URL_PREFIX + 'bulk_bo_mark_order_as_done', {
        'order_filters': {
          'customer_id': APIFilters['customer_id'],
          'for_year': APIFilters['for_year'],
          'for_month': APIFilters['for_month']
        }
      })
    )

    return bulkMarkOrderAsPaid$.pipe(map(res => !!res));
  }

  public bulkMarkBonusesForInvoice(
    filters: CustomerOrderFilterChoices
  ): Observable<string[]> {
    const APIFilters = new CustomerOrderFiltersMapper().toApi(filters);

    const bulkMarkBonusesForInvoice$ = from(
      this.made.request(this.URL_PREFIX + 'bulk_bo_mark_order_for_invoice', {
        'order_filters': {
          'customer_id': APIFilters['customer_id'],
          'for_year': APIFilters['for_year'],
          'for_month': APIFilters['for_month']
        }
      })
    )

    return bulkMarkBonusesForInvoice$.pipe(map(res => res as string[]));
  }

  public getInvalidOrdersForBulkInvoice(
    filters: CustomerOrderFilterChoices
  ): Observable<ProblematicOrderForInvoice[]> {

    const APIFilters = new CustomerOrderFiltersMapper().toApi(filters);

    const bulkCanBoMarkOrderForInvoice$ = from(
      this.made.request(this.URL_PREFIX + 'get_invalid_orders_for_bulk_invoice', {
        'order_filters': {
          'customer_id': APIFilters['customer_id'],
          'for_year': APIFilters['for_year'],
          'for_month': APIFilters['for_month']
        }
      })
    )

    return bulkCanBoMarkOrderForInvoice$.pipe(
      map((problematic_orders: any) => problematic_orders.map((problematic_order: any) => {
        return {
          customerId: problematic_order.customer_id,
          customerName: problematic_order.customer_name,
          orderId: problematic_order.order_id
        }
      })));
  }

  public getEmployeeName(
    employeeId: number
  ): Observable<string | null> {
    let getEmployeeName$ = from(
      this.employeeService.get(employeeId)
    )

    return getEmployeeName$.pipe(
      map((employee: any) => !!employee ? employee.firstname + ' ' + employee.lastname : null),
    );
  }

  public customerRemoveBonusFromOrder(
    bonusId: string,
    orderId: string
  ) {

    let removeBonusFromOrder$ = from(
      this.made.request(this.URL_PREFIX + 'customer_remove_bonus_from_order', {
        'bonus_id': bonusId,
        'order_id': orderId,
      })
    );

    return removeBonusFromOrder$;
  }

  public customerCreateOrder( // TODO
    bonusProjectId: String,
    orderDate: Date,
    isMainOrder: boolean
  ) {

    let createOrder$ = from(
      this.made.request(this.URL_PREFIX + 'customer_create_new_order', {
        'bonus_project_id': bonusProjectId,
        'order_date': orderDate,
        'is_main_order': isMainOrder
      })
    );

    return createOrder$;
  }

  async updateExistingOrdersVN_4216() {
    return this.made.request(this.URL_PREFIX + 'update_existing_orders_VN_4216');
  }

  testBCWrapperCron() {
    return this.made.request(this.URL_PREFIX + 'test_cron_proceed_orders_ready_to_be_invoiced');
  }
}
