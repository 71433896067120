angular
  .module('VSPApp')
  .controller('DashboardArbeitgeberController', [
    "$scope",
    "Made",
    "StatisticService",
    "BrandingService",
    "employeeService",
    "dialogService",
    "ACLService",
    function (
      $scope,
      Made,
      StatisticService,
      BrandingService,
      employeeService,
      dialogService,
      ACLService
    ) {

      $scope.user = Made.user;

      $scope.dashboard = {
        BrandingService
      };

      $scope.dashboard.arbeitnehmerAnlegen = () => dialogService.employeeCreate(undefined, $scope.dashboard.customerId);

      ACLService.amIApprover().then((iAmACLApprover) => {
        $scope.amIApprover = iAmACLApprover;
      });

      let customerIdPromise = employeeService.getCustomerId();

      customerIdPromise
        .then(customerId => $scope.dashboard.customerId = customerId);

      customerIdPromise
        .then(customerId => StatisticService.customerGeneral(customerId))
        .then(general => $scope.dashboard.general = general);

      customerIdPromise
        .then(customerId => StatisticService.customerComponents(customerId, true))
        .then(components => $scope.dashboard.components = components);

      employeeService
        .getAdvisorInfo()
        .then(advisorDetails => $scope.dashboard.advisorDetails = advisorDetails);
    }
  ]);
