import { BonusComponentController } from './BonusComponentController';
import { MONTHS_IN_YEAR } from '../../../services/BonusService'
import { BonusComponentControllerSachbezug } from './BonusComponentControllerSachbezug';

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export class BonusComponentControllerSachbezugPluxee extends BonusComponentControllerSachbezug {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {
    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    console.log("BonusComponentControllerSachbezugPluxee");
    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }


  setModelValues(model_default_values) {
    super.setModelValues(model_default_values);
    this.model.sliders.advantage.from = this.component_budget_config.value;
    this.model.sliders.advantage.to = this.component_budget_config.range.max_eq

    if(this.model.sliders.advantage.from === this.model.sliders.advantage.to) {
      this.model.sliders.advantage.from = 0;
    }
  }
}

BonusComponentControllerSachbezugPluxee.$inject = $inject;
