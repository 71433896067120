import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TocCheckboxDialogComponent, TocDialogData } from 'src/app/shared/components/form-mixins/toc-checkbox-dialog/toc-checkbox-dialog.component';

@Component({
  selector: 'vn-bonago-toc',
  templateUrl: './bonago-toc.component.html',
  styleUrls: ['./bonago-toc.component.scss']
})
export class BonagoTocComponent implements OnInit {

  @Input()
  public formGroup!: FormGroup;

  @Input()
  public readonlyDialog = false;

  @Input()
  public linkText?: string;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  public openTocDialog() {
    const dialogWidth = '60%';
    const dialogData: TocDialogData = {
      text: this.tocText,
      title: this.tocTitle,
      readonlyDialog: this.readonlyDialog,
      buttonKey: this.buttonKey
    };

    this.dialog.open(
      TocCheckboxDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => { }
      );
  }

  public readonly tocLabel = 'Hiermit stimme ich den Einlösebedingungen zu.';
  public readonly tocTitle = 'Allgemeine Einlösebedingungen';
  public readonly switchKey = 'benefit.bonago.checkout.basketOverview.checkTerms';
  public readonly buttonKey = 'benefit.bonago.checkout.termsDialog.buttonAcceptTerms';

  public readonly tocText = `
    Hinter dem Guthaben im ValueNet Vorteilskonto steckt ein BONAGO MitarbeiterBON, angeboten von der BONAGO Incentive Marketing Group GmbH, Aschauer Str. 28, 81549 München, nachfolgend "BONAGO" genannt. Sie kann in Akzeptanzpartner-Geschenkgutscheine eingelöst werden.

    Die Gültigkeit des jeweiligen Guthabens beschränkt sich nach aktueller Rechtslage(Urteil des VI.Senats vom 11.11.2010 - VI R 21 / 09; Urteil des VI.Senats vom 11.11.2010 - VI R 27 / 09; Urteil des VI.Senats vom 11.11.2010 - VI R 41 / 10) auf ein Jahr.Bei einmaliger Aufladung ist das Guthaben auf dem MitarbeiterBON für den Mitarbeiter 12 Monate ab Aufladedatum verfügbar.Bei Wiederaufladung / en innerhalb von 12 Monaten verlängert sich die jeweilige Gültigkeit des zu dem Zeitpunkt der Wiederaufladung verfügbaren Guthabens um je 12 Monate.Sofern Wiederaufladungen erfolgen, sind die Aufladebeträge jeweils maximal 36 Monate verfügbar.
    
    Bei Einlösung in Akzeptanzpartner - Geschenkgutscheine behält BONAGO sich vor, die Stückelung der von Empfänger bestellten Akzeptanzpartner - Geschenkgutscheine zu ändern, um Bestellungen im Sinne des Empfängers zeitnah abwickeln zu können.Es besteht grundsätzlich kein Anspruch auf die Verfügbarkeit eines spezifischen, explizit auch werblich ausgelobten Partners aus dem Akzeptanzpartner - Geschenkgutscheine - Portfolio.

    Eine Haftung von BONAGO gegenüber Erwerbern und Empfängern für Verlust oder Nichtgebrauch der Akzeptanzpartner - Geschenkgutscheine ist ausgeschlossen, eine Pflicht, gegebenenfalls Ersatz - Akzeptanzpartner - Geschenkgutscheine zur verschicken, besteht für BONAGO ausdrücklich nicht.
    
    Akzeptanzpartner - Geschenkgutscheine sind von der Rückgabe ausgeschlossen.Ein nachträglicher Umtausch bereits verausfolgter Akzeptanzpartner - Geschenkgutscheine in andere Gutscheinwerte ist nicht möglich; eine Barauszahlung Akzeptanzpartner - Geschenkgutscheinen bzw.von Restguthaben ist ausgeschlossen.
    
    Für die Einlösung der Akzeptanzpartner - Geschenkgutscheine bei Akzeptanzpartner gelten grundsätzlich die aktuellen rechtlichen Vorgaben; die individuelle Gültigkeit der Akzeptanzpartner - Geschenkgutscheine legt jedoch der Akzeptanzpartner fest.BONAGO hat hierauf keinen Einfluss.Durch den Eintausch von Guthaben in Akzeptanzpartner - Geschenkgutscheine kommen Vertragsbeziehungen ausschließlich zwischen dem Empfänger und dem Akzeptanzpartner zustande; BONAGO vermittelt lediglich den Akzeptanzpartner - Geschenkgutschein an Empfänger.Alle Ansprüche, die den Vertrag zwischen Empfänger und Akzeptanzpartner betreffen, und zwar Art und Weise der Leistung, Preisgestaltung und mögliche Leistungsverweigerung, sind an den Akzeptanzpartner zu richten.Dementsprechend trägt der Empfänger des Akzeptanzpartner - Geschenkgutscheins das Risiko einer etwaigen Insolvenz des Akzeptanzpartners.
    
    Für alle BONAGO Gutscheinprodukte gilt, dass grundsätzlich kein Anspruch auf Rücknahme besteht.Bei Dienstleistungen im Bereich der Freizeitgestaltung mit fixiertem Leistungszeitpunkt liegt kein Fernabsatzgeschäft gemäß § 312 b Abs. 3 Nr. 6 BGB vor, sodass kein Widerrufs - oder Rückgaberecht besteht.
    
    Neben den Akzeptanzpartnern haftet BONAGO bei ihrer Vermittlungstätigkeit für schuldhaft verursachte Schäden an Leben, Körper und Gesundheit sowie in Haftungsfällen nach dem Produkthaftungsrecht unbeschränkt.Für Schäden an sonstigen Gütern gilt: BONAGO bzw.Akzeptanzpartner haften bei Vorsatz im Umfang unbeschränkt oder bei grober Fahrlässigkeit ebenfalls unbeschränkt, wenn wesentliche Vertragspflichten verletzt wurden.Bei grober Fahrlässigkeit ist die Haftung sonst auf den vertragstypischen unmittelbaren Durchschnittsschaden beschränkt, wenn nur unwesentliche Vertragspflichten verletzt wurden.Im gleichen Umfang ist die Haftung bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten begrenzt.Bei leicht fahrlässiger Verletzung unwesentlicher Vertragspflichten haften BONAGO bzw.die Akzeptanzpartner nicht.Soweit die Haftung von BONAGO bzw.der Akzeptanzpartner ausgeschlossen oder beschränkt ist, gilt dies auch für die Haftung eingeschalteter Erfüllungs - und Verrichtungsgehilfen.
    
    BONAGO haftet nicht für Störungen, die durch außerhalb ihres Einflussbereiches liegende Umstände verursacht werden.Dies gilt insbesondere für Beeinträchtigungen, die auf Ausfall oder Störung des Telekommunikationsnetzes und der Stromversorgung zurückzuführen sind.Weiterhin übernimmt BONAGO keine Haftung für die Richtigkeit im Internet angegebener Daten sowie für die technische Störungsfreiheit des Internetangebots.
    
    Im Falle der Unwirksamkeit von Regelungen dieser AGB wird die Wirksamkeit des Vertrages oder die der AGB im Übrigen nicht berührt.Für Streitigkeiten über Vermittlung von Geschenkgutscheinen, die über das Internetangebot erfolgte, wird, soweit der Erwerber Vollkaufmann oder Person des öffentlichen Rechts ist, München als ausschließlicher Gerichtsstand vereinbart.Es kommt ausschließlich deutsches Recht zur Anwendung.Erfüllungsort für die Zurverfügungstellung der BONAGO –Universalgutscheine und für alle Zahlungen ist München.
    
    Stand 07/2016`;
}
