import {APPROVAL_PROCESS_TYPES_BY_NAME} from '../../../../services/administrationService';

const $inject = [
  '$scope',
  '$timeout',
  'administrationService'
];
export default class AdministrationMppApprovalsController {
  constructor(
    $scope,
    $timeout,
    administrationService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      administrationService
    });

    this.loading = {
      main: false
    };
    this.init();

  }

  async init() {
  }

}
AdministrationMppApprovalsController.$inject = $inject;
