import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CustomerOrderTableDataBO, CustomerOrder, CustomerOrderTableBonusData } from '../../../models/direct-bonus-customer-order.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import * as moment from 'moment';
import { EMPTY, from, Observable, of } from 'rxjs';
import { DirectBonus, DirectBonusFormData } from '../../../models/direct-bonus-data.model';
import { MatDialog } from '@angular/material/dialog';
import { DirectBonusEditDialogComponent } from '../direct-bonus-edit-dialog/direct-bonus-edit-dialog.component';
import { DirectBonusDeleteDialogComponent } from '../direct-bonus-delete-dialog/direct-bonus-delete-dialog.component';
import { BackofficeAdministrationBonusAllowedOperations } from 'src/app/direct-bonus/models/direct-bonus-allowed-operations.model';
import { ProjectActionsDialogData } from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { DirectBonusComponentType } from 'src/app/direct-bonus/models/direct-bonus-project-overview-table-data.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DirectBonusMapperService } from 'src/app/direct-bonus/services/direct-bonus.mapper';
import { BaseComponent } from 'src/app/shared/models/base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'vn-bonus-order-employees-table',
  templateUrl: './bonus-order-employees-table.component.html',
  styleUrls: ['./bonus-order-employees-table.component.scss']
})
export class BonusOrderEmployeesTableComponent extends BaseComponent implements OnInit {

  public displayedColumns: string[] = [
    'employeeId',
    'employeeName',
    'componentType',
    'orderPeriod',
    'employeeAmount',
    'actionsMenu',
  ];

  public filtersGroup!: FormGroup;
  public isListFiltered: boolean = false;

  @Input()
  customerOrderData!: CustomerOrderTableDataBO;

  public customerOrderBonusData$!: Observable<CustomerOrderTableBonusData[]>;

  @Output()
  tableDataChange = new EventEmitter<void>();

  public get orderPeriod(): string {
    const orderPeriodMoment = moment({
      month: this.customerOrderData.order.forMonth - 1,
      year: this.customerOrderData.order.forYear
    });

    return orderPeriodMoment.format('MM.YYYY');
  }

  constructor(
    private directBonusService: DirectBonusService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private mapper: DirectBonusMapperService,
    private formBuilder: FormBuilder,
    private loader: LoadingService,
    @Inject('employeeService') private employeeService: any,
  ) { 
    super();
    this.initFiltersForm();
  }

  ngOnInit(): void {
    this.loader.show();
    this.loadEmployees();
  }

  public loadEmployees() {
    this.customerOrderBonusData$ = this.directBonusService.getBonusesByOrderId(this.customerOrderData.order.orderId).pipe(
      switchMap((bonuses: DirectBonus[]) => {
        const employeeIds = [...new Set(bonuses.map(b => b.employeeId))];
        
        return from(this.employeeService.getManyMySQLEmployees(employeeIds)).pipe(
          map((emps: any) => ({emps, bonuses}))
        )
      }),
      map(({emps, bonuses}: {emps: any[], bonuses: DirectBonus[]}) => {
        let data: CustomerOrderTableBonusData[] = [];

        emps.forEach((emp: any) => {
          if (!this.isEmployeeInFilterRange(emp)) {
            return
          }

          const r = bonuses.filter(b => b.employeeId === emp.id);
          const { firstname, lastname } = emp;
          const empfullName = `${firstname} ${lastname}`;

          r.forEach((b: DirectBonus) => {
            data.push({
              bonus: b,
              employeeName: empfullName,
              bonusAllowedOperations: {
                canEditBonusValue: this.customerOrderData.order.isCustomerApproved(),
                canRemoveBonusFromOrder: this.customerOrderData.order.isCustomerApproved(),
              },
            });
          })
        });

        return data;
      }),
      tap( () => {
        this.loader.hide();
        if(!this.isFiltersFormEmpty()) {
          this.isListFiltered = true;
        } else {
          this.isListFiltered = false;
        }
      })
    );
  }

  private initFiltersForm() {
    this.filtersGroup = this.formBuilder.group({
      filterEmployeeIdPersonalNumber: this.formBuilder.control(undefined, []),
      filterEmployeeName: this.formBuilder.control(undefined, []),
    });
  }
  
  public getComponentName(componentType: DirectBonusComponentType) {
    return this.mapper.getDirectBonusComponentDisplayValue(componentType);
  }

  public hasBonusContextMenu(allowedOperations: BackofficeAdministrationBonusAllowedOperations) {
    return allowedOperations.canEditBonusValue ||
      allowedOperations.canRemoveBonusFromOrder;
  }

  public editBonusValue(data: CustomerOrderTableBonusData) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      bonusValue: data.bonus.value,
      bonusId: data.bonus.bonusId,
      employeeId: data.bonus.employeeId,
      employeeName: data.employeeName,
      period: this.orderPeriod,
      componentType: data.bonus.componentName,
      bonusProjectId: data.bonus.bonusProjectId
    };

    this.dialog.open(
      DirectBonusEditDialogComponent,
      {
        width: dialogWidth,
        data: dialogData
      }
    ).afterClosed()
      .pipe(
        switchMap((newBonusData: DirectBonusFormData | null) => {
          if (newBonusData) {
            return this.directBonusService.updateOrderBonusAmount(newBonusData).pipe(
              map(
                result => ({
                  result,
                  newBonusData
                })
              )
            );
          }

          return EMPTY;
        }),
      )
      .subscribe(
        ({
          result,
          newBonusData
        }) => {
          if (result) {
            const componentDisplayValue = this.mapper.getDirectBonusComponentDisplayValue(newBonusData.componentName!);
            const message = `Sie haben den folgenden Bonus der Bestellung im ${newBonusData.period} geändert:
            ${newBonusData.employeeId} - ${componentDisplayValue} - ${newBonusData.formValue} €`;
            this.alertService.message(message);

            this.tableDataChange.emit();
          }
        },
        (error) => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }

  public removeBonusFromOrder(data: CustomerOrderTableBonusData) {
    const dialogWidth = '420px';

    const {
      bonusId,
      componentName,
      employeeId
    } = data.bonus;

    const {
      order: {
        orderId
      }
    } = this.customerOrderData;

    const dialogData: ProjectActionsDialogData = {
      bonusId,
      orderId,
      employeeId,
      employeeName: data.employeeName,
      period: this.orderPeriod,
      componentType: componentName,
    };

    this.dialog.open(
      DirectBonusDeleteDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public menuClick($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public isEmployeeInFilterRange(employee: any): boolean {
    if(this.isFiltersFormEmpty()) {
      this.isListFiltered = false;
      return true;
    }
    
    const {
      filterEmployeeIdPersonalNumber, 
      filterEmployeeName
    } = this.filtersGroup.value;
    const fullName = `${employee.firstname} ${employee.lastname}`.toLowerCase();
    const employeeIdPersonalNumber = employee.id + " " + employee.personal_number; 

    if (!filterEmployeeIdPersonalNumber && !filterEmployeeName) {
      return true
    } else
    if ((filterEmployeeIdPersonalNumber && employeeIdPersonalNumber.includes(filterEmployeeIdPersonalNumber))
        || (filterEmployeeName && fullName.includes(filterEmployeeName.toLowerCase()))) {
      return true;
    }

    return false;
  }

  public clearFilters() {
    this.filtersGroup.patchValue({
      filterEmployeeIdPersonalNumber: {},
      filterEmployeeName: [],
    });
    this.loadEmployees();
    this.isListFiltered = false;
  }

  private isFiltersFormEmpty() {
    return Object.values(this.filtersGroup.value).every((val: any) => (
      !val 
      || val == false 
      || (val.constructor == Object && Object.keys(val).length == 0)
    ))
  }
}
