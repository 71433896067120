const $inject = [
  '$scope',
  '$timeout',
  'mppService'
];
export default class requested_by_VN_controller {
  constructor(
    $scope,
    $timeout,
    mppService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      mppService
    });

    this.loading = {
      main: false
    };

    this.extras = {};
    this.forms = {
      add_order_form: {}
    };

    this.models = {
      order: {
        order_id: undefined,
        require_changes: false,
        ticket_number: undefined,
        ticket_url: undefined
      }
    }

    this.init().finally(()=>{
      this.$timeout(()=>{
        this.models.order.order_id = this.basket.order_id ? this.basket.order_id : undefined;
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    this.basket = this.$scope.ngDialogData.basket;
    this.basket_id = this.basket['_id'];

    this.$timeout(()=>{
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  handleOrderIdChange() {
    this.forms.add_order_form['$$controls'][0].$setValidity('unique', true);
  }

  async saveOrderId() {
    this.$timeout(()=>{
      this.loading.saving = true;
    });

    let result = null;
    try {
      result = await this.mppService.saveOrderId(this.basket_id, this.models.order);
    } catch (e) {
      console.log("error", e);
    } finally {

      if (result && !result.success && result.status == 'order_id_exists') {
        this.orderIdExistsMessage = result.error;
        this.forms.add_order_form['$$controls'][0].$setValidity('unique', false);
      }

      this.$timeout(()=>{
        this.loading.saving = false;
      });
    }

    if (result && result.success) {
      this.$scope.confirm();
    }

  }

}
requested_by_VN_controller.$inject = $inject;
