var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      







































































































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Ccockpit-panel\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EAchtung\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\" overlay=\"$ctrl.loading.main || $ctrl.loading.saving\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Cp\u003EYou want to transfer data from old bonus project to the new one.\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"columns small-6\"\u003E\u003Cdiv\u003EInherit from\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-6\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "$ctrl.bonus_project.transfer.from_bonus_project_id","data-cy": "vn.cockpit.bonus.fiduciaPublishBonusProjectDialog.selectTransferProject","attributes": {"ng-options":"active_bonus_project._id as active_bonus_project.name for active_bonus_project in $ctrl.active_bonus_projects","ng-change":"$ctrl.handleActiveBonusProjectSelection()"}}
});
pug_html = pug_html + "\u003Cbr\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "row","overlay": "$ctrl.loading.active_bonus_project || $ctrl.loading.validating_selection","name": "$ctrl.forms.inherit_from_form"}
});
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Cbutton class=\"button right\" ng-click=\"$ctrl.cancelSelection()\" data-cy=\"vn.cockpit.bonus.fiduciaPublishBonusProjectDialog.buttonCancel\"\u003EAbbrechen\u003C\u002Fbutton\u003E\u003Cbutton class=\"button right\" ng-disabled=\"!$ctrl.canConfirmSelection()\" ng-click=\"$ctrl.confirmSelection()\" data-cy=\"vn.cockpit.bonus.fiduciaPublishBonusProjectDialog.buttonPublishProject\"\u003EBonus Projekt veröffentlichen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fcockpit-panel\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;