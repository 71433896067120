
<div class="uc-embedding-wrapper">

    <h3>Wir benötigen Ihre Zustimmung, um den Service zu laden!</h3>

    <p class="description-text">
        Wir verwenden einen Service eines Drittanbieters, um Videoinhalte einzubetten.
        Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details durch
        und stimmen Sie der Nutzung des Service zu, um diesen Inhalt anzusehen.
    </p>

    <p class="not-existing-service">
        Dieser Inhalt darf aufgrund von Trackern, die Besuchern nicht offengelegt werden,
        nicht geladen werden. Der Besitzer der Website muss diese mit seinem CMP einrichten,
        um diesen Inhalt zur Liste der verwendeten Technologien hinzuzufügen.
    </p>

    <div class="uc-embedding-buttons">
        <button (click)="acceptButtonClicked()"
                class="uc-embedding-accept">
            Akzeptieren
        </button>
    </div>

    <span>
        powered by
        <a target="_blank"
           class="uc-embedding-powered-by"
           href="https://usercentrics.com/">
            Usercentrics Consent Management Platform
        </a>
    </span>
</div>
