import shop_html from "../../modules/shop/shop.pug";
import header_main_html from "../../helpers/header.main.pug";
import shop_pre_html from "../../modules/shop/pre/shop.pre.pug";
import home_html from "../../modules/shop/home/home.pug";
import gutscheine_html from "../../modules/shop/gutscheine/gutscheine.pug";
import reisen_html from "../../modules/shop/reisen/reisen.pug";
import produkte_html from "../../modules/shop/produkte/produkte.pug";
import produkte_home_html from "../../modules/shop/produkte/home/home.pug";
import kategorie_html from "../../modules/shop/produkte/kategorie/kategorie.pug";
import details_html from "../../modules/shop/produkte/details/details.pug";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.shop', {
        url: '/shop',
        views: {
          main: {
            template: shop_html,
          },
          header: {
            template: header_main_html
          }
        }
      })
      .state('inApp.shop.pre', {
        url: '/pre',
        views: {
          shopMain: {
            template: shop_pre_html,
            controller: 'shopPreController'
          }
        },
        ncyBreadcrumb: {
          parent: 'blank.home',
          label: 'Shops'
        }
      })
      .state('inApp.shop.home', {
        url: '/home',
        views: {
          shopMain: {
            template: home_html
          }
        }
      })
      .state('inApp.shop.gutscheine', {
        url: '/gutscheine',
        views: {
          shopMain: {
            template: gutscheine_html
          }
        }
      })
      .state('inApp.shop.reisen', {
        url: '/shop/reisen',
        views: {
          shopMain: {
            template: reisen_html
          }
        }
      })
      .state('inApp.shop.produkte', {
        url: '/produkte',
        views: {
          shopMain: {
            template: produkte_html
          }
        }
      })
      .state('inApp.shop.produkte.home', {
        url: '/home',
        views: {
          produkteMain: {
            template: produkte_home_html,
            controller: 'HomeController'
          }
        },
        ncyBreadcrumb: {
          parent: 'inApp.shop.pre',
          label: 'Mitarbeitervorteile'
        }
      })
      .state('inApp.shop.produkte.kategorie', {
        url: '/kategorie/:kategorieId',
        params: {
          kategorieId: null
        },
        views: {
          produkteMain: {
            template: kategorie_html,
            controller: 'KategorieController',
          }
        }
      })
      .state('inApp.shop.produkte.details', {
        url: '/details/:produkteId',
        params: {
          produkteId: null
        },
        views: {
          produkteMain: {
            template: details_html,
            controller: 'DetailsController'
          }
        },
        ncyBreadcrumb: {
          parent: 'inApp.shop.produkte.home',
          label: '{{ offer.offer_title }}'
        }
      });

  }]);
