const CashbackConstants = {
    diesel_abgasskandal: {
        icon: '/assets/img/cashback/dieselskandal-dieselfahrverbote-v24.svg',
        helper: 'diesel_abgasskandal',
        title: 'Diesel Abgasskandal',
        disturber: 'Dieselauto 2008 -2015 gekauft?',
        image: '/assets/img/cashback/dieselskandal-dieselfahrverbote-v24.svg',
        imageIsIcon: true,
        videoUrl: 'https://player.vimeo.com/video/398037933?dnt=1',
        embedUrl: 'https://player.vimeo.com/video/398037933?dnt=1',
        longTitle: 'Vom Dieselskandal betroffen?',
        description: 'Wer ein manipuliertes Dieselfahrzeug vor dem 22.09.2015 gekauft hatte, wurde von VW, Daimler und Opel vermutlich nicht korrekt über die Fahrzeugmerkmale informiert. Prüfen Sie jetzt, ob auch Ihr Fahrzeug betroffen ist! Schnell handeln lohnt sich - die Verjährungsfrist VW läuft Ende 2018 ab.',
        description2: 'Wertverlust Ihres Fahrzeugs wegen Dieselskandal?\nWir werden für Sie tätig und setzen Ihre Klage auf Schadensersatz durch!',
        bulletPoints: [
            'Vom Dieselskandal betroffen sind die Automarken: VW, Seat, Skoda, Audi, Mercedes, BMW, Porsche, Opel',
            'Gemeinsam mit unseren Spezialanwälten begleiten wir Sie bei der Durchsetzung Ihrer Rechte im Dieselabgasskandal.',
            'Wir übernehmen dabei die komplette Abwicklung Ihres Falles – von der Prüfung der Fahrzeugdaten bis hin zur Klage gegen den Fahrzeughersteller. Sie müssen dafür selbst nicht aktiv werden und tragen keinerlei Kostenrisiko!',
            'Was können Sie erwarten? Kurz gesagt: Nachlieferung (neues Fahrzeug) und Schadensersatz vom Fahrzeughersteller.',
            'Ob Ihr Auto dazu gehört, können Sie ganz einfach über unserem kostenlosten Check herausfinden.'
        ]
    },
    lebensversicherung: {
        icon: '/assets/img/cashback/lebensversicherung-services-v24.svg',
        helper: 'lebensversicherung',
        title: 'Widerruf Lebensversicherung',
        disturber: 'LV zw. 1994 -2001 abgeschlossen?',
        image: '/assets/img/cashback/lebensversicherung-services-v24.svg',
        imageIsIcon: true,
        videoUrl: 'https://player.vimeo.com/video/398038055?dnt=1',
        embedUrl: 'https://player.vimeo.com/video/398038055?dnt=1',
        longTitle: 'Lebensversicherung widerrufen?',
        description: 'Wenn Sie eine Lebensversicherung besitzen, die im Zeitraum 1991 – 2007 abgeschlossen wurde und eine fehlerhafte Widerrufsbelehrung aufweist, können Sie Ihr Vertrag sofort rückabwickeln lassen – egal ob der Vertrag bereits gekündigt oder abgelaufen ist oder noch läuft.',
        description2: 'Jetzt Rückabwicklung ihres Lebens-  oder Rentenversicherungsvertrages prüfen!\nIm Durchschnitt 10.000€ Mehrertrag für Sie, auch aus bereits gekündigten Verträgen.',
        bulletPoints: [
            'Jetzt Rückabwicklung Ihres Lebensversicherungsvertrages prüfen!',
            'Im Durchschnitt 10.000 EUR Mehrertrag – kein Kostenrisiko!',
            'Lassen Sie Ihr Versicherungsvertrag durch Experten prüfen! Gemeinsam mit unseren Spezialanwälten begleiten wir Sie bei der Durchsetzung Ihrer Rechte bei der LV Rückabwicklung.',
            'Was können Sie erwarten? Kurz gesagt: Ihre eingezahlten Beiträge! Denn bei erfolgreichem Widerruf und damit der Rückabwicklung des Vertrages werden Sie so gestellt, als wenn der Vertrag nie zustande gekommen ist.',
            'Unsere Versicherungsmathematiker und Anwälte berechnen Ihren individuellen Anspruch im Rahmen unserer kostenlosen Vertragsprüfung!'
        ]
    },
    darlehens_widerruf: {
        icon: '/assets/img/cashback/darlehensvertraege-services-v24.svg',
        helper: 'darlehens_widerruf',
        title: 'Darlehens-Widerruf',
        disturber: 'Kredit nach 2010 abgeschlossen?',
        image: '/assets/img/cashback/darlehensvertraege-services-v24.svg',
        imageIsIcon: true,
        videoUrl: 'https://player.vimeo.com/video/398037870?dnt=1',
        embedUrl: 'https://player.vimeo.com/video/398037870?dnt=1',
        longTitle: 'Darlehensvertrag widerrufen?',
        description: 'Es gibt viele Gründe, einen bestehenden Kreditvertrag anzufechten und so von aktuell günstigen Kreditkonditionen zu profitieren.',
        description2: 'Lassen Sie Ihre KFZ-Kredite und Immobiliendarlehen prüfen und sichern sich mehrere Tausend Euro Vorteil!\nJetzt tätig werden.',
        bulletPoints: [
            'Falsche Widerrufsbelehrungen',
            'Zu hohe Vorfälligkeitsentschädigungen',
            'Unrechtmäßig behobene Bearbeitungsgebühren',
            'Welche Verträge sind betroffen? Verbraucherverträge, die nach 2010 geschlossen wurden sowie Immobiliendarlehensverträge nach dem 10.06.2010. Wurde keine Widerrufsbelehrung erteilt oder ist diese fehlerhaft, beginnt die Widerrufsfrist nicht zu laufen und der Darlehensvertrag kann weiterhin widerrufen werden',
            'Gemeinsam mit unseren Spezialanwälten begleiten wir Sie bei der Durchsetzung Ihrer Rechte!',
            'Befreien Sie sich jetzt von teuren Kreditkonditionen! Lassen Sie Ihr Kreditvertrag durch Experten kostenlos prüfen!'
        ]
    },
    private_krankenversicherung: {
        icon: '/assets/img/cashback/private-krankenversicherung-services-v24.svg',
        helper: 'private_krankenversicherung',
        title: 'Private Krankenversicherung',
        disturber: 'Beitrag zu hoch?',
        image: '/assets/img/cashback/private-krankenversicherung-services-v24.svg',
        imageIsIcon: true,
        videoUrl: 'https://player.vimeo.com/video/398037839?dnt=1',
        embedUrl: 'https://player.vimeo.com/video/398037839?dnt=1',
        longTitle: 'Private Krankenversicherung zu teuer?',
        description: 'Monatlich weniger Beitrag an Ihrer privaten Krankenversicherung zahlen, ohne die Gesellschaft zu wecheln!\n',
        description2: 'Sie sind länger als 10 Jahre privat krankenversichert?\nSparen Sie durch unsere Hilfe bis zu über 40% der Beiträge Ihrer PKV  - ohne Wechsel der Gesellschaft!',
        bulletPoints: [
            'Bis zu 40% Beiträge sparen durch Tarifwechsel – ohne Einschränkungen bei den Leistungen',
            'Egal bei welcher Gesellschaft Sie versichert sind, egal wie alt Sie sind  und auch ob Sie bereits Vorerkrankungen hatten - lassen Sie durch spezialisierte und erfahrene Versicherungsexperten beraten und zu Ihrem Recht verhelfen',
            'Wir unterstützen Sie auch bei Zahlungsverweigerung Ihres Krankenversicherers!',
            'Gemeinsam mit unseren Versicherungsexperten und Spezialanwälten begleiten wir Sie bei der Durchsetzung Ihrer Rechte!',
            'Sparen Sie ab sofort bares Geld, ohne auf Leistungen zu verzichten! Machen Sie mit uns den kostenlosten Check auf Beitragsminderung!'
        ]
    }
};

export default CashbackConstants;
