export class CarLeasingCustomerConfiguration {

  constructor(
    public exchangeType: 'netto' | 'brutto',
    public durationOptions: number[],
    public maxExchangePerMonth: number,
    public vnFlatTax: number,
    public bringBackFee: number,
  ) {
    this.exchangeType = exchangeType;
  }

  public static fromApiMapper(
    rawCustomerConfiguration: any
  ): CarLeasingCustomerConfiguration {
    return new CarLeasingCustomerConfiguration(
      rawCustomerConfiguration['exchange_type'],
      rawCustomerConfiguration['duration'],
      rawCustomerConfiguration['max_exchange'],
      rawCustomerConfiguration['vn_flat_tax'],
      rawCustomerConfiguration['bring_back_fee'],
    );
  }
}
