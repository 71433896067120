const $inject = [
    "cmsService", 
    "$state", 
    "$scope"
];

export default class CmsEditorListController {

    constructor(cmsService, $state, $scope) {
        Object.assign(this, {
            cmsService,
            $state,
            $scope
        });

        this.custId = $state.params.custId;
        this.items = {};

        this.selectedCategory = null;
        this.cmsService.globalCms.detailId = 0;
        this.loadList();
    }

    loadList() {
        this.cmsService.globalCms.loading = true;
        this.cmsService.loadEditorList(this.custId).then(cmsItems => {
            var categoryList = {};

            for (let i = 0; i < cmsItems.length; i++) {
                let currentCategory = cmsItems[i].category;

                if (!currentCategory) {
                    currentCategory = '';
                } else {
                    currentCategory = currentCategory.title;
                }

                if (!categoryList.hasOwnProperty(currentCategory)) {
                    categoryList[currentCategory] = [];
                }

                categoryList[currentCategory].push(cmsItems[i]);
            }

            self = this;
            Object.keys(categoryList).sort().forEach( (key) => {
                let itemsInCategory = categoryList[key];

                itemsInCategory = this.cmsService.sortNotesByPinned(itemsInCategory);

                self.items[key] = itemsInCategory;
            });

            this.cmsService.globalCms.loading = false;
        });
    }

    async deleteItem(id) {
        let reallyDelete = await confirm('Soll dieser Eintrag wirklich gelöscht werden?');

        if (reallyDelete) {
            this.cmsService.globalCms.loading = true;
            this.$scope.$applyAsync();
            this.cmsService.deleteCmsContent(id).then(() => {
                this.cmsService.globalCms.loading = false;
                this.$state.reload();
            });
        }
    }

    isOnline(item) {
        return !item.useTimes || !(this.isNotYetOnline(item) || this.isNotOnlineAnymore(item));
    }

    isNotYetOnline(item) {
        return (item.startdate && item.startdate !== '' && item.startdate > new Date().getTime() / 1000);
    }

    isNotOnlineAnymore(item) {
        return (item.enddate && item.enddate !== '' && item.enddate < new Date().getTime() / 1000);
    }

    updateCategorySelect(category) {
        this.selectedCategory = category;
    }
}

CmsEditorListController.$inject = $inject;