import {ERHOLUNGSBEIHILFE_OPTIONS} from "./erholungsbeihilfe_options";

export const BGM_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS = {
  budget: [
    {
      label: 'BGM Budget',
      prop: 'bgmbudget',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    }
  ],
  metadata: [
    {
      id: 0,
      type: 'checkbox',
      parent: 'documentation',
      key: 'is_enabled',
      title: 'Dokumentation erforderlich',
      value: {default: true},
      settings: {
        is_editable: false
      }
    },
    {
      id: 1,
      type: 'checkbox',
      parent: 'quiz',
      key: 'is_enabled',
      title: 'Quiz',
      value: {default: false},
      settings: {
        is_editable: true
      }
    },
    {
      id: 2,
      type: 'select',
      parent: 'bonus_config',
      key: 'expiration_action',
      title: 'Bonus verfällt',
      options: {key: 'BONUS_EXPIRATION_ACTIONS'},
      settings: {
        is_editable: false
      }
    },
    {
      id: 3,
      type: 'select',
      parent: 'bonus_config',
      key: 'bonus_type',
      title: 'Bonus Freiwillig',
      options: {key: 'BONUS_TYPES'},
      settings: {
        is_editable: false
      }
    },
    {
      id: 4,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_project_start_date',
      title: 'Beginn',
      settings: {
        date: {
          min: {
            key: 'today'
          }
        }
      },
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputProjectBeginDate',
    },
    {
      id: 5,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_project_end_date',
      title: 'Ende',
      settings: {
        date: {
          min: {
            key: 'bonus_project.dates.bonus_project_start_date'
          }
        }
      },
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputProjectEndDate',
    },
    {
      id: 6,
      type: 'datetime',
      parent: 'dates',
      key: 'user_decision_date',
      title: 'Entscheidung Nutzung bis',
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputUserDecisionDate',
      settings: {
        is_required: false,
        date: {
          max: {
            key: 'dates.bonus_project_start_date'
          }
        }
      }
    },
    {
      id: 7,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_publish_date',
      title: 'Bonus Veröffentlichung zum',
      settings: {
        is_visible: false,
        is_editable: false,
        equals: {
          key: 'dates.bonus_project_start_date'
        }
      }
    },
    {
      id: 8,
      type: 'checkbox',
      parent: 'project_approved',
      key: 'is_enabled',
      title: 'Projekt Freigegeben',
      value: {default: false},
      cyData: 'vn.cockpit.bonus.createProjectDialog.checkWithReleased',
      settings: {
        is_visible: true,
        is_editable: val => val
      }
    },
  ],
  notifications: [
    {
      id: 0,
      key: 'welcome_notification',
      parent: 'notifications',
      bonus_notification_type: 'welcome_notification',
      bonus_project_type: 4,
      title: 'Welcome Notification',
      text: 'Ihnen steht noch ein verfügbares Budget von ${restbonus} zur Verfügung.',
      bonus_type: 'einmal_bonus'
    },
    {
      id: 1,
      key: 'standard_notification',
      parent: 'notifications',
      bonus_notification_type: 'standard_notification',
      bonus_project_type: 4,
      title: 'Standard Notification',
      text: 'Ihnen steht noch ein verfügbares Budget von ${restbonus} zur Verfügung.',
      bonus_type: 'einmal_bonus'
    }
  ]
};
