import {Basket} from "../../models/basket";
import {BasketArticle} from "../../models/basket-article";
import {ShopApiService} from "./shop-api.service";
const $inject = [
  'shopApiService'
];
export class BasketRepositoryService {

  /**
   * @param {ShopApiService} shopApiService
   */
  constructor(shopApiService) {
    this._shopApiService = shopApiService;
  }

  /**
   * @param {Basket} basket
   * @returns {Promise<Array<BasketArticle>>}
   *
   * Apparently it doesn't make much sense to pass in the basket itself in the load function. This used to be called
   * refreshBasket. However, the basket shouldn't be used before being loaded via this method because the articles
   * are updated and the basket config is refreshed as well.
   *
   * #TODO this should be refactored so the basket is really loaded here given just an employee id
   */
  async loadBasket(basket) {
    let items = [...basket.items];
    basket.clear();
    let changedOrDeletedArticles = [];
    for (let item of items) {
      let oldArticle = item.article;
      let catalogArticle = await this._shopApiService.getCatalogArticle(oldArticle.articleNo);
      if (catalogArticle && catalogArticle.availability.isAvailableForCheckout) {
        let newArticle = catalogArticle.getBasketArticle();
        basket.addArticle(newArticle, item.quantity);
        if (newArticle.grossPrice !== oldArticle.grossPrice || newArticle.netPrice !== oldArticle.netPrice) {
          changedOrDeletedArticles.push(oldArticle);
        }
      }
      else {
        changedOrDeletedArticles.push(oldArticle);
      }
    }
    return changedOrDeletedArticles;
  }

  /**
   * @param {Basket} basket
   * @returns {Promise<Basket>}
   */
  async saveBasket(basket) {
    return this._shopApiService.saveBasketItems(basket);
  }

}
BasketRepositoryService.$inject = $inject;
