import {PC_BONUS_COMPONENT_NAMES} from '../../../services/BonusService';
import SetCheckoutDate_html from '../../../modules/bonus/dialogs/set_checkout_date/SetCheckoutDate.pug'
import {TAX_PAYERS_BY_NAME} from "../../../services/bonus/tax_payer";
import {CHECKOUT_TYPES_BY_NAME} from "../../../services/bonus/checkout_types";

const $inject = [
  '$scope',
  'vbmData',
  'employeeService',
  'BonusService',
  'Made',
  '$state',
  '$q',
  '$timeout',
  'dialogService',
  'NotificationService',
  'lodash',
  'CacheService',
  'datevCalculatorService',
  'mppService',
  'moment'
];

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
export default class bonusSideController {
  constructor(
    $scope,
    vbmData,
    employeeService,
    BonusService,
    Made,
    $state,
    $q,
    $timeout,
    dialogService,
    NotificationService,
    lodash,
    CacheService,
    datevCalculatorService,
    mppService,
    moment
  ) {
    Object.assign(this, {
      Made,
      employeeService,
      $scope,
      BonusService,
      vbmData,
      $state,
      $q,
      $timeout,
      dialogService,
      NotificationService,
      lodash,
      CacheService,
      datevCalculatorService,
      mppService,
      moment
    });

    this.bonus_checkout_cache = CacheService.getDictionaryStore('bonus_checkout');

    this.loading = {
      main: false,
      component_change: false
    };

    let calculation_promise = this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
        if (
          this.lodash.get(this.bonus_project.quiz, 'is_enabled', false) && false
          // !this.lodash.get(this.bonus_project.for_employees[this.employee_id], 'bonus_assistant.do_not_show', false) &&
          // !this.bonus_checkout_cache.is_bonus_assistant_seen
        ) {
          this.openAssistantDialogue();
        }
      }, 0);
    });

    this.BonusService.calculation_promise = calculation_promise;
  }

  getExchangeSum() {
    let current_components = this.getComponents();
    let current_components_exchange_sum = this.BonusService.calculateExchangeBonusesSum(current_components, (checkout_config) => { return !this.isComponentCancelled(checkout_config.component_name);});
    let future_components = this.getFutureCheckouts();
    let future_components_exchange_sum = this.BonusService.calculateExchangeBonusesSum(future_components, (checkout_config) => { return !this.isComponentCancelled(checkout_config.component_name);});
    return current_components_exchange_sum + future_components_exchange_sum;
  }

  getCurrentComponentContractDatesDisplay(checkout_component_config) {
    if (!this.bonuses_by_id) {
      return ;
    }

    let bonus_id = checkout_component_config.bonus_id;
    let bonus = this.bonuses_by_id[bonus_id];
    let bonus_start_date = this.BonusService.getComponentDisplayStartDate(bonus);
    let bonus_end_date = this.BonusService.getComponentDisplayEndDate(bonus);

    if (!this.BonusService.isComponentActive(bonus)) {
      bonus_start_date = checkout_component_config.bonus_start_month_year || bonus_start_date;
      bonus_end_date = checkout_component_config.bonus_end_month_year || bonus_end_date;
    }

    return this.moment(bonus_start_date).format('MM.YY') + ' - ' + this.moment(bonus_end_date).format('MM.YY');

  }

  getPreviousComponentContractDatesDisplay(checkout_component_config) {
    let bonus = checkout_component_config;
    let bonus_start_date = this.BonusService.getComponentDisplayStartDate(bonus);
    let bonus_end_date = this.BonusService.getComponentDisplayEndDate(bonus);
    return this.moment(bonus_start_date).format('MM.YY') + ' - ' + this.moment(bonus_end_date).format('MM.YY');

  }

  getFutureComponentContractDatesDisplay(checkout_component_config) {
    if (!this.bonuses_by_id) {
      return ;
    }

    let bonus_id = checkout_component_config.connected_bonus_id;
    let future_checkouts = this.bonuses_by_id[bonus_id].future_checkouts;

    if (future_checkouts) {
      let bonus = future_checkouts[checkout_component_config.bonus_id];

      if (bonus) {
        // the future bonus exists
        let bonus_start_date = this.BonusService.getComponentDisplayStartDate(bonus);
        let bonus_end_date = this.BonusService.getComponentDisplayEndDate(bonus);
        return this.moment(bonus_start_date).format('MM.YY') + ' - ' + this.moment(bonus_end_date).format('MM.YY');
      } else {
        // we are creating a new future bonus
        let bonus_start_date = checkout_component_config.bonus_start_month_year;
        let bonus_end_date = checkout_component_config.bonus_end_month_year;
        return this.moment(bonus_start_date).format('MM.YY') + ' - ' + this.moment(bonus_end_date).format('MM.YY');
      }
    }
  }

  getPreviousCheckoutsBudgetBonusesSum() {
    return this.BonusService.calculatePreviousCheckoutsBudgetBonusesSum(this.bonuses);
  }

  getFutureCheckoutsBudgetBonusesSum() {
    return this.BonusService.calculateFutureCheckoutsBudgetBonusesSum(this.bonuses)
  }

  async getRestBonus(){
    let restbonus = await this.BonusService.getRestBonus(this.employee_id, this.bonus_project_id);
    console.log(restbonus);
  }

  removeFutureComponent (component_checkout_config) {
    if (!this.isComponentUsed(component_checkout_config)) {
      let component_key = component_checkout_config.component_name;
      delete this.employee.bonus[this.bonus_project_id].future_checkouts[component_key];
    } else {
      this.NotificationService.error('Nicht möglich');
    }
  }

  getBonusProjectEmployeeCheckoutConfiguration() {
    if (this.employee && this.employee.bonus && this.employee.bonus[this.bonus_project_id]) {
      return this.employee.bonus[this.bonus_project_id];
    } else {
      return EMPTY_OBJECT;
    }
  }

  getPreviousCheckouts(){
    if (this.employee && this.employee.bonus && this.employee.bonus[this.bonus_project_id]) {
      return this.employee.bonus[this.bonus_project_id]["previous_checkouts_by_bonus_id"];
    } else {
      return EMPTY_ARRAY;
    }
  }

  getEndedCheckouts(){
    if (this.employee && this.employee.bonus && this.employee.bonus[this.bonus_project_id]) {
      return this.employee.bonus[this.bonus_project_id][CHECKOUT_TYPES_BY_NAME['ended']['key']];
    } else {
      return EMPTY_ARRAY;
    }
  }


  getFutureCheckouts() {
    if (this.employee && this.employee.bonus && this.employee.bonus[this.bonus_project_id]) {
      return this.employee.bonus[this.bonus_project_id][CHECKOUT_TYPES_BY_NAME['future']['key']];
    } else {
      return EMPTY_ARRAY;
    }
  }

  removeComponent(component_checkout_config) {
    if (!this.isComponentUsed(component_checkout_config)) {
      delete this.employee.bonus[this.bonus_project_id].components[component_checkout_config.component_name];
    } else {
      this.NotificationService.error('Nicht möglich');
    }
  }

  hasComponents() {
    return Object.keys(this.getComponents()).length > 0;
  }

  getComponents() {
    if (this.employee && this.employee.bonus && this.employee.bonus[this.bonus_project_id]) {
      return this.employee.bonus[this.bonus_project_id][CHECKOUT_TYPES_BY_NAME['current']['key']];
    } else {
      return EMPTY_ARRAY;
    }
  }

  isTaxPayedByEmployee(component_checkout_config) {
    //the assumption is that if you don't have a tax_payer, the payer would be the employee
    const omg = component_checkout_config.tax_payer === TAX_PAYERS_BY_NAME['employee']['id'];
    return component_checkout_config.tax_payer === TAX_PAYERS_BY_NAME['employee']['id'];
  }

  async init() {
    this.bonus_project_id = this.$state.params.bonusProjectId;
    this.bonus_component_name = this.$state.params.bonusComponentName; // set only in detailed view
    this.customer_id = await this.employeeService.getCustomerId();
    this.employee_id = this.Made.user.valuenet_id;
    this.employee = this.vbmData.employees[this.employee_id];
    this.is_checkout_valid = true;
    this.is_customer_pc_valid = true;
    this.is_fid_leader = this.BonusService.getUserFidIsLeader();

    let promises = {};
    promises.bonus_project_promise = this.BonusService.getBonusProject(this.customer_id, this.bonus_project_id).then((bonus_project) => {
      this.bonus_project = bonus_project;
      this.employee_bonus_config = bonus_project.for_employees[this.employee_id];
    });

    promises.bonuses_promise = this.BonusService.getBonusesForEmployee({
      customer_id: this.customer_id,
      bonus_project_id: this.bonus_project_id,
      employee_id: this.employee_id,
      to_attach_checkout_validations: true
    }).then(async bonuses => {
      this.bonuses = bonuses;
      this.bonuses_by_id = this.bonuses.reduce((acc, bonus) => {
        acc[bonus['_id']] = bonus;
        return acc;
      }, {});

      // add MPP bonuses if any
      let mpp_data = this.$state.params.mpp_data;
      if (mpp_data) {
        // get the custom_checkout_date
        if (mpp_data.custom_checkout_date) {
          this.BonusService.checkout_date = mpp_data.custom_checkout_date;
        } else {
          this.BonusService.checkout_date = undefined;
          this.BonusService.can_set_custom_checkout_date = false;
        }

        // reset the checkout
        this.BonusService.resetCheckout(this.employee, this.bonus_project_id);

        if (mpp_data.bonus_component_name && PC_BONUS_COMPONENT_NAMES.includes(mpp_data.bonus_component_name)) {
          PC_BONUS_COMPONENT_NAMES.forEach((pc_component_name) => {
            if (mpp_data.bonus_component_name !== pc_component_name) {
              delete mpp_data.employee_bonus_checkout[mpp_data.checkout_type['key']][pc_component_name];
            }
          });

        }

        // get old checkout
        let old_checkout = mpp_data.employee_bonus_checkout;

        // add the current components
        for (let component_name in old_checkout.components) {
          let component_checkout = old_checkout.components[component_name];
          await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, component_checkout, true);
        }

        // add the future checkouts components
        const future_checkouts_key = CHECKOUT_TYPES_BY_NAME['future']['key']
        for (let component_name in old_checkout[future_checkouts_key]) {
          let component_checkout = old_checkout[future_checkouts_key][component_name];
          await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, component_checkout, true, CHECKOUT_TYPES_BY_NAME['future']);
        }

        // add the previous checkouts components
        const previous_checkouts_key = CHECKOUT_TYPES_BY_NAME['previous']['key']
        for (let component_name in old_checkout[previous_checkouts_key]) {
          let component_checkout = old_checkout[previous_checkouts_key][component_name];
          await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, component_checkout, true, CHECKOUT_TYPES_BY_NAME['previous']);
        }

        // add the ended checkouts components
        const ended_checkouts_key = CHECKOUT_TYPES_BY_NAME['ended']['key']
        for (let component_name in old_checkout[ended_checkouts_key]) {
          let component_checkout = old_checkout[ended_checkouts_key][component_name];
          await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, component_checkout, true, CHECKOUT_TYPES_BY_NAME['ended']);
        }
      }

      // add all old bonuses overriding previous configurations
      for (const bonus_config of bonuses ) {
        let component_name = this.BonusService.getBonusComponentName(bonus_config);
        let component_display_name = component_name;

        if (component_name === 'wertguthaben') {
          component_display_name = 'Wertguthaben';
        }
        // let available_statuses = [BONUS_STATES_BY_NAME['available']['id'], BONUS_STATES_BY_NAME['declined']['id']];
        if ( this.BonusService.isComponentUsed(bonus_config) && !this.BonusService.isComponentExpired(bonus_config)) {
          let checkout_config = bonus_config.checkout_config;
          if (!checkout_config) {
            // bonus was added through outside
            checkout_config = await this.BonusService.createCheckoutConfig(bonus_config.bonus_value, component_display_name, component_name, bonus_config._id,  bonus_config.state );
          } else {
            // update state
            Object.assign(checkout_config, {
              bonus_state: bonus_config.state
            });
          }
          await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, checkout_config, true);
        }

        if (this.BonusService.isComponentEnded(bonus_config)) {
          let checkout_config = bonus_config.checkout_config;
          if (!checkout_config) {
            // bonus was added through outside
            checkout_config = await this.BonusService.createCheckoutConfig(bonus_config.bonus_value, component_display_name, component_name, bonus_config._id,  bonus_config.state );
          } else {
            Object.assign(checkout_config, {
              bonus_state: bonus_config.state
            });
          }
          await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, checkout_config, true, CHECKOUT_TYPES_BY_NAME['ended']);
        }

        if (this.BonusService.hasFutureCheckouts(bonus_config)) {
          const future_checkouts = this.BonusService.getFutureCheckouts(bonus_config);
          for ( const future_checkout_id in future_checkouts) {
            const future_checkout = future_checkouts[future_checkout_id];
            Object.assign(future_checkout.checkout_config, {
              bonus_state: future_checkout.state,
              dates: future_checkout.dates
            });
            if (this.BonusService.isComponentUsed(future_checkout, false)) {
              await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, future_checkout.checkout_config, true, CHECKOUT_TYPES_BY_NAME['future'] );
            }
          }
        }

        if (this.BonusService.hasPreviousCheckouts(bonus_config)) {
          const previous_checkouts = this.BonusService.getPreviousCheckouts(bonus_config)
          for ( const previous_checkout_id in previous_checkouts) {
            const previous_checkout = previous_checkouts[previous_checkout_id];
            Object.assign(previous_checkout.checkout_config, {
              bonus_state: previous_checkout.state,
              dates: previous_checkout.dates
            });
            if (this.BonusService.isComponentUsed(previous_checkout, false)) {
              await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, previous_checkout.checkout_config, true, CHECKOUT_TYPES_BY_NAME['previous'] );
            }
          }
        }
      }
    });

    promises.advisor_details_promise = this.employeeService
      .getAdvisorInfo()
      .then(advisorDetails => this.$scope.advisorDetails = advisorDetails);

    // get proxy user for special things
    this.iAmSpecialAdvisor = this.BonusService.authenticationService.iAm('special_advisor');
    await this.BonusService.setIsAllowedToSetCustomCheckoutDate({ bonus_project_id: this.bonus_project_id });

    // set up calculation
    this.calculationWatcher = this.$scope.$watch(() => {
      return this.getBonusProjectEmployeeCheckoutConfiguration();
    }, (newValue, oldValue) => {
      this.handleComponentChange(newValue);
    }, true);

    // cleanup
    this.$scope.$on('$destroy', () => {
      this.calculationWatcher();
    });

    await this.$q.all(promises);

    // set date if needed
    if (this.BonusService.can_set_custom_checkout_date || this.iAmSpecialAdvisor) {
      // we dont want to open dialog only we are viewing complet
      if (!this.BonusService.checkout_date && !this.bonus_component_name) {
        let dialog = this.dialogService.ngDialog.open({
          template: SetCheckoutDate_html(),
          plain: true,
          controller: 'SetCheckoutDateDialogController',
          controllerAs: '$ctrl',
          data: {
            bonus_project_id: this.bonus_project_id,
            customer_id: this.customer_id
          },
          width: 1200,
          closeByDocument: false,
          closeByEscape: false,
          closeByNavigation: false
        });

        let closeResult = await dialog.closePromise;
        this.BonusService.checkout_date = !this.moment.isDate(closeResult.value) ? new Date() : closeResult.value;
      }
    } else {
      this.BonusService.checkout_date = undefined;
      this.BonusService.can_set_custom_checkout_date = false;
    }

  }

  isCheckoutConfigValid(checkout_configuration) {
    let is_value_valid = checkout_configuration.value !== 0;
    let is_netto_value_valid = this.lodash.get(checkout_configuration, 'netto_value', 1) > 0;
    return is_value_valid && is_netto_value_valid;
  }

  async handleComponentChange(bonus_project_employee_checkout_config){
    this.$timeout(()=>{
      this.loading.component_change = true;
    });

    this.removeInvalidComponents(bonus_project_employee_checkout_config);

    this.calculateBonusReminder(bonus_project_employee_checkout_config);
    this.calculateOptimization(bonus_project_employee_checkout_config);
    await this.validateCheckout(bonus_project_employee_checkout_config);

    this.$timeout(()=>{
      this.loading.component_change = false;
    });
  }

  getOptimizedValue() {
    return this.employee.bonus[this.bonus_project_id].optimization.result.optimized.verandurengunAn;
  }

  async validateCheckout(bonus_project_employee_checkout_config){
    let validation_result = await this.BonusService.getCheckoutValidations(this.employee_id, this.bonus_project_id);
    this.is_customer_pc_valid = validation_result.is_customer_pc_valid;
    this.decision_date_reached = validation_result.decision_date_reached;

    this.is_checkout_valid = !this.decision_date_reached;

    let bonus_checkout_configurations = bonus_project_employee_checkout_config.components || {};
    let bonus_future_checkout_configurations = bonus_project_employee_checkout_config.future_checkouts || {};

    PC_BONUS_COMPONENT_NAMES.some((pc_component_name) => {
      if (bonus_checkout_configurations[pc_component_name] || bonus_future_checkout_configurations[pc_component_name]) {
        this.is_checkout_valid = this.is_checkout_valid && this.is_customer_pc_valid;
        return true;
      }
    });
  }

  hasOptimization() {
    return this.employee && this.employee.bonus && this.employee.bonus[this.bonus_project_id] && this.employee.bonus[this.bonus_project_id].optimization;
  }

  removeInvalidComponents(bonus_project_employee_checkout_config, skip_same_component=true) {
    if (Object.keys(bonus_project_employee_checkout_config).length === 0) {
      return;
    }

    let components = bonus_project_employee_checkout_config.components;
    let future_checkouts = bonus_project_employee_checkout_config.future_checkouts;

    this.removeInvalidComponent(components, skip_same_component);
    this.removeInvalidComponent(future_checkouts, skip_same_component);
  }

  removeInvalidComponent(bonus_checkout_configurations, skip_same_component=true) {
    if (!bonus_checkout_configurations) {
      return
    }

    this.lodash.forEach(bonus_checkout_configurations, (checkout_configuration, bonus_component_name)=>{
      if (skip_same_component && this.bonus_component_name === bonus_component_name) {
        // skip the validation
        // this is so that the component stays in SideMenu when the employee is in Component DetailsView and has not yet created a valid checkout
      } else {
        if (!this.isCheckoutConfigValid(checkout_configuration) ) {
          delete bonus_checkout_configurations[bonus_component_name];
        }
      }
    });
  }

  toShowAssistant() {
    if (!this.bonus_project) {
      return false;
    }

    return this.lodash.get(this.bonus_project, 'quiz.is_enabled', false);
  }

  isComponentUsed (component_checkout_config) {
    return this.BonusService.isComponentUsed({state: component_checkout_config.bonus_state});
    // let available_statuses = [BONUS_STATES_BY_NAME['available']['id'], BONUS_STATES_BY_NAME['declined']['id']];
    // return !available_statuses.includes(this.employee.bonus[this.bonus_project_id].components[component_key].bonus_state);
  }

  isComponentApproved (component_checkout_config) {
    return this.BonusService.isComponentApproved(component_checkout_config.bonus_state);
  }

  isComponentCancelled(component_key) {
    if (this.employee && this.employee.bonus && this.employee.bonus[this.bonus_project_id] &&  this.employee.bonus[this.bonus_project_id].components[component_key]) {
      return this.BonusService.isComponentCancelled({state: this.employee.bonus[this.bonus_project_id].components[component_key].bonus_state});
    }
    return false
  }

  calculateOptimization(bonus_project_employee_checkout_config) {
    if (!this.hasOptimization()) {
      return;
    }

    let potential = this.employee.bonus[this.bonus_project_id].optimization.potential;

    // reset
    for (const bonus_component_name in potential.direct) {
      if (['total', 'ag_cost_total'].includes(bonus_component_name)) {
        continue;
      }

      potential.direct[bonus_component_name].active = false;
    }

    // set new
    let bonus_components = bonus_project_employee_checkout_config.components;
    for (const bonus_component_name in bonus_components) {
      if (['total', 'ag_cost_total'].includes(bonus_component_name)) {
        continue;
      }

      potential.direct[bonus_component_name].active = true;
      potential.direct[bonus_component_name].result = bonus_components[bonus_component_name].value;
    }

    // optimize
    let optimization_result = this.datevCalculatorService.calculateForPotential(potential);
    this.employee.bonus[this.bonus_project_id].optimization.result = optimization_result;
  }

  calculateBonusReminder (bonus_project_employee_checkout_config) {
    if (!(this.employee.bonus && this.employee.bonus[this.bonus_project_id])) {
      return;
    }

    // this.employee.bonus[this.bonus_project_id].sum = this.BonusService.calculateBudgetBonusesSum(bonus_components);
    bonus_project_employee_checkout_config.remaining_amount = this.getLeftBonus();
  }

  async startCheckout () {
    let bonus_project_employee_checkout_config = this.getBonusProjectEmployeeCheckoutConfiguration();
    this.removeInvalidComponent(bonus_project_employee_checkout_config, false);
    this.calculateBonusReminder(bonus_project_employee_checkout_config);
    this.calculateOptimization(bonus_project_employee_checkout_config);
    await this.validateCheckout(bonus_project_employee_checkout_config);
    if (this.canCheckout()) {
      this.$state.go('inApp.bonus.checkout', { 'bonusProjectId': this.bonus_project_id });
    }

  }

  canCheckout() {
    return this.getLeftBonus() >= 0 && this.is_checkout_valid;
  }

  getLeftBonus () {
    let bonus_project_employee_checkout_config = this.getBonusProjectEmployeeCheckoutConfiguration();
    let employee_bonus_amount = this.lodash.get(this.employee_bonus_config, 'bonus.amount', 0);
    return this.BonusService.calculateRestBonus(bonus_project_employee_checkout_config, employee_bonus_amount);
  }

  isPayedByBudget(checkout_config) {
    return this.BonusService.isPayedByBudget(checkout_config);
  }

  openAssistantDialogue(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    return;

    // let data = {
    //   bonus_project_id: this.bonus_project_id,
    //   employee_id: this.employee_id,
    //   customer_id: this.customer_id
    // };

    // this.dialogService
    //   .ngDialog.openConfirm({
    //   template: '/modules/bonus/assistant/assistant_dialogue/bonus_assistant_dialog.html',
    //   data: data,
    //   width: 1000
    // });
  }

}

bonusSideController.$inject = $inject;
