import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UIRouterGlobals} from "@uirouter/core";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {DticketOrderService} from "../../../services/dticket-order.service";
import {LoadingService} from "../../../../shared/services/loading.service";
import {DTicketConfig} from "../../../models/dticket/dticket-config.model";


@Component({
  selector: 'cockpit-dticket-configuration',
  templateUrl: './dticket-order-configuration.component.html',
  styleUrls: ['./dticket-order-configuration.component.scss']
})
export class DticketOrderConfigurationComponent implements OnInit {

  selectedTab: string = 'tab1';
  formGroup!: FormGroup;
  customerId: number;
  customerName: string = '';
  isCustomerConfigurationFixated: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private route: UIRouterGlobals,
    @Inject('customerService') private customerService: any,
    private dticketOrderService: DticketOrderService,
    private loader: LoadingService,
  ) {
    this.customerId = parseInt(this.route.params.customerId);
  }

  isLoading() {
    return this.loader.loading$;
  }

  ngOnInit(): void {
    this.setData();
  }

  async setData() {
    this.loader.show();
    const dticketConfig = await this.dticketOrderService.getDticketOrderConfig(this.customerId);
    this.isCustomerConfigurationFixated = await this.customerService.isFixated(this.customerId);
    const customerData = await this.customerService.getCustomerNameByCustomerId(this.customerId);
    this.customerName = customerData.name;

    const ticketPriceMaxValidation = await this.dticketOrderService.calculateTicketPriceWithDiscount();

    this.formGroup = this.formBuilder.group({
      euroSubsidy: [
        {
          value: dticketConfig.euroSubsidy,
          disabled: this.isCustomerConfigurationFixated
        },
        [
          Validators.required,
          Validators.min(0),
          Validators.max(ticketPriceMaxValidation)
        ]
      ],
      percentageSubsidy: [
        {
          value: dticketConfig.percentageSubsidy,
          disabled: true
        },
        []
      ],
      ordersApproval: [
        {
          value: dticketConfig.ordersApproval,
          disabled: this.isCustomerConfigurationFixated
        },
        []
      ],
      changeOfSubsidy: [
        {
          value: dticketConfig.changeOfSubsidy,
          disabled: this.isCustomerConfigurationFixated || !dticketConfig.ordersApproval
        },
        []
      ]
    });

    this.formGroup.get('ordersApproval')?.valueChanges.subscribe((approvalValue) => {
      const changeOfSubsidyControl = this.formGroup.get('changeOfSubsidy') as AbstractControl;
      if (approvalValue) {
        changeOfSubsidyControl.enable();
      } else {
        changeOfSubsidyControl.disable();
        changeOfSubsidyControl.setValue(false);
      }
    });

    this.formGroup.controls['euroSubsidy'].valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(async val => {
        if (this.isFormValid()) {
          const price = parseFloat(val)
          const percentageSubsidy = await this.dticketOrderService.calculatePercentageSubsidy(price);
          this.formGroup.patchValue({percentageSubsidy});
        }
      });

    this.loader.hide();
  }

  public isFormValid() {
    if (this.isCustomerConfigurationFixated) {
      return false;
    }
    return this.formGroup && this.formGroup.valid
  }

  public get euroSubsidy(): FormControl {
    return this.formGroup.get('euroSubsidy') as FormControl;
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  async saveConfiguration() {
    this.loader.show();
    const dTicketConfiguration = DTicketConfig.getInstanceFromObject(this.formGroup.getRawValue());
    const result = await this.dticketOrderService.updateDticketOrderConfig(this.customerId, dTicketConfiguration);
    this.loader.hide();
  }

}
