const $inject = [
  '$scope',
  '$transitions',
  '$state',
  'BenefitBonagoService',
  '$timeout'
];

class BenefitBonagoKategorieController {
  constructor($scope,
              $transitions,
              $state,
              BenefitBonagoService,
              $timeout
  ) {
    Object.assign(this, {
      $scope,
      $transitions,
      $state,
      $timeout,
      BenefitBonagoService
    });

    this.loading = {
      main: false,
      retailers: false
    };

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  $onDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async setRetailers() {
    this.loading.retailers = true;
    return this.BenefitBonagoService
      .getRetailersBasic({category_id: this.selectedCategoryId})
      .then((retailers) => {
        this.retailers = retailers;
      }).then(() => {
        this.$timeout(() => {
          this.loading.retailers = false;
        });
      });
  }

  async init() {
    this.loading.main = true;
    this.selectedCategoryId = parseInt(this.$state.params.kategorieId, 10);

    this.subscriptions = [
      this.BenefitBonagoService.communication.subscribe({
        next: async (msg) => {
          if (msg === 'user_selected_scheme_update') {
            await this.setRetailers();
          }
        }
      })
    ];

    return this.setRetailers();
  }
}

BenefitBonagoKategorieController.$inject = $inject;
angular
  .module('VSPApp')
  .controller('BenefitBonagoKategorieController', BenefitBonagoKategorieController);
