angular
  .module('VSPApp')
  .directive('vnPercent', ['$locale', '$filter', 'lodash', function ($locale, $filter, lodash) {


    const groupSepRegex = new RegExp('\\' + $locale.NUMBER_FORMATS.GROUP_SEP, 'g'),
      decimalSepRegex = new RegExp('\\' + $locale.NUMBER_FORMATS.DECIMAL_SEP),
      whitespaceRegex = /\s/g;

    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        vnPercent: '='
      },
      link: function (scope, ele, attr, ngModel) {

        let fraction = parseInt(attr.vnPercentFraction) || 2,
          convert = attr.vnPercentNoDiv ? 1 : 100,
          decimalsCount = lodash.get(scope.vnPercent, 'decimalsCount');
        let fixViewValue = (viewValue) => {
          return viewValue
            .replace($locale.NUMBER_FORMATS.CURRENCY_SYM, '')
            .replace(groupSepRegex, '')
            .replace(decimalSepRegex, '.')
            .replace(whitespaceRegex, '');
        }

        ngModel.$parsers.unshift(function (viewValue) {

          let modelValue = fixViewValue(viewValue);
          if (0 === modelValue.length) {
            modelValue = 0;
          } else {
            modelValue = parseFloat(parseFloat(modelValue).toFixed(fraction)) / convert;
            // due to JS handling of floats it is possible to have more than 4 digits. eg. 4.757 / 100 = 0.047569999999999994
            modelValue = parseFloat(parseFloat(modelValue).toFixed(fraction + Math.ceil(Math.log10(convert))));
          }

          return modelValue;
        });

        ngModel.$formatters.push(function (value) {
          return isNaN(value) ? '' : ($filter('number')(value * convert, fraction) + ' %');
        });

        ngModel.$validators.numberOrEmpty = function (modelValue) {
          return !isNaN(modelValue);
        };

        ngModel.$validators.rangeDecimalsCount = (modelValue, viewValue) => {
          if (decimalsCount === undefined || !!attr.disabled) {
            return true;
          }

          if (!modelValue || ngModel.$isEmpty(viewValue)) {
            return true;
          }

          let isValid = countDecimals(parseFloat(fixViewValue(viewValue))) <= decimalsCount;
          return isValid;

        };

        const countDecimals = (value) => {
          if (Math.floor(value) !== parseFloat(value)) {
            return value.toString().split(/,|\./)[1].length || 0;
          }
          return 0;
        }

        if (undefined !== attr.vnMin) {

          var vnMinVal;
          ngModel.$validators.vnMin = function (value) {
            return value >= vnMinVal;
          };

          attr.$observe('vnMin', function (val) {
            if ((undefined !== val) && !isNaN(val)) {
              val = parseFloat(val, 10);
            }
            vnMinVal = !isNaN(val) ? val : undefined;
            ngModel.$validate();
          });
        }

        if (undefined !== attr.vnMax) {
          var vnMaxVal;

          ngModel.$validators.vnMax = function (value) {
            return ngModel.$isEmpty(value) || (undefined === vnMaxVal) || value <= vnMaxVal;
          };

          attr.$observe('vnMax', function (val) {
            if ((undefined !== val) && !isNaN(val)) {
              val = parseFloat(val, 10);
            }
            vnMaxVal = !isNaN(val) ? val : undefined;
            ngModel.$validate();
          });
        }

      }
    };
  }]);
