const $inject = [
  '$state',
  '$scope',
  'Made',
  'employeeService',
  'userService',
  'authenticationService',
  'dialogService',
  'customerService',
  'UserlaneService',
  'PopupService'
];
export default class employeeIntegrityController {

  constructor(
    $state,
    $scope,
    Made,
    employeeService,
    userService,
    authenticationService,
    dialogService,
    customerService,
    UserlaneService,
    PopupService
  ) {
    this.$state = $state;
    this.$scope = $scope;
    this.employeeService = employeeService;
    this.userService = userService;
    this.authenticationService = authenticationService;
    this.dialogService = dialogService;
    this.customerService = customerService;
    this.UserlaneService = UserlaneService;
    this.PopupService = PopupService;

    this.type = $scope.ngDialogData.status;
    this.empId = $scope.ngDialogData.employeeId;
    this.isCheckout = $scope.ngDialogData.isCheckout;

    if (this.empId) {
      employeeService
        .get(this.empId)
        .then(employee => this.employee = employee);
    } else {
      employeeService
        .get()
        .then(employee => this.employee = employee);
    }

    if (this.type === 'toc') {
      this.getToc();
    } else {
      this.getIntegrityData();
    }
  }

  getToc() {
    this.warning = false;

    this.working = true;
    this.employeeService.get().then(employee => {
      this.employeeId = employee.id;
      this.userService.getTocStatusById(employee.id).then(status => {
        this.alreadyAccepted = !!(status) && !!(status.toc);
      });
      this.customerService.getConfigurationByCustomerId(employee.customer_id).then(configuration => {
        this.customerConfiguration = configuration;
        this.working = false;
        this.UserlaneService.hide();
      });
    });
  }

  getIntegrityData() {
    this.working = true;
    this.employeeId = this.empId;

    this.employeeService
      .getIntegrityData(this.empId)
      .then(integrityData => {
        this.integrityData = integrityData;
        this.working = false;
      });
  }

  save() {
    this.working = true;

    this.employeeService
      .updateIntegrityData(this.integrityData, this.employeeId)
      .then(() => {
        this.$scope.confirm();
        this.NotificationService.message('Ihre Angaben wurden gespeichert.');
        this.employeeService.get(this.employee.id, Date.now());
      }, () => {
        this.error = true;
        this.NotificationService.error('Ihre Angaben konnten nicht gespeichert werden.');
      })
      .finally(() => this.working = false);
  }

  cancel() {
    this.authenticationService.logout();
  }

  backToComponents() {
    this.$state.go('inApp.neo.side.verguetung');
    this.$scope.confirm();
  }

  reAccept() {
    this.$scope.confirm();
  }

  acceptToc() {
    this.working = true;

    this.userService.acceptToc(0)
      .then(() => {
        this.PopupService.setTocAccepted();
        this.$scope.confirm();
        this.working = false;

        this.userService.getIntegrityStatus()
          .then(status => {
            if (status) {
              this.dialogService.employeeIntegrity(status);
            }
          });
      });
  }

  cancelToc() {
    this.userService
      .refuseToc()
      .then(() => {
        this.$scope.closeThisDialog(true);
        this.authenticationService.logout();
      });
  }

  showWarning() {
    this.warning = true;
  }

}

employeeIntegrityController.$inject = $inject;
