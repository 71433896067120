import { Moment } from "moment";

export enum IncentOfferType {
  DEFAULT = 'default',
  CUSTOM = 'custom'
}

export const INCENT_DATES_FORMAT = 'DD.MM.YYYY'

export interface IncentOfferConfig {
  startDate: Moment,
  endDate: Moment,
  offerId: string|null,
}

export class IncentOffer {
  constructor(
    public id: string|null,
    public text: string,
    public type: IncentOfferType,
    public link: string,
    public config: IncentOfferConfig|null,
    public isActive: boolean,
  ) {
    if (type === IncentOfferType.CUSTOM && !config) {
      throw new Error('Could not instantiate custom offer without config');
    }
    if (type === IncentOfferType.DEFAULT && !isActive) {
      throw new Error('Could not instantiate default offer which is inactive');
    }
  }

  isDefault() {
    return this.type === IncentOfferType.DEFAULT;
  }

  getPeriodForDateRange() {
    if (!this.config)
      return null;

    return {
      startDate: this.config.startDate.format(INCENT_DATES_FORMAT),
      endDate: this.config.endDate.format(INCENT_DATES_FORMAT)
    }
  }

  getTitle() {
    return this.isDefault() ? 'Startseitentext' : 'Besonderes Angebottext';
  }
}
