import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  DirectBonusFormData,
  DirectBonusProjectComponentConfiguration,
  DirectBonusProjectConfiguration
} from '../../../models/direct-bonus-data.model';
import {BonusTableDialogData} from '../../../models/direct-bonus-table-dialog-data.model';
import {
  DirectBonusComponent,
  DirectBonusComponentType,
  DIRECT_BONUS_COMPONENTS
} from '../../../models/direct-bonus-project-overview-table-data.model';
import {Observable} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import {SelectOption} from 'src/app/shared/components/form-mixins/select/select.component';
import {DirectBonusService} from 'src/app/direct-bonus/services/direct-bonus.service';
import * as moment from 'moment';
import { DirectBonusMapperService } from 'src/app/direct-bonus/services/direct-bonus.mapper';

// ===============================================================

interface FormOptions {
  componentName: SelectOption<DirectBonusComponentType>[];
}

interface FormChoices {
  componentName: DirectBonusComponentType;
  bonusValue: string;
  formReason: string | undefined;
}

interface RemoveBonusConfig {
  clickStartTime: Date | undefined,
  clickEndTime: Date | undefined,
  isRemoving: boolean,
  isRemovable: boolean,
  isRemoved: boolean
}

// ===============================================================

@Component({
  selector: 'vn-direct-bonus-create-dialog',
  templateUrl: './direct-bonus-create-dialog.component.html',
  styleUrls: ['./direct-bonus-create-dialog.component.scss']
})
export class DirectBonusCreateDialogComponent implements OnInit {

  public currentBonusValue$!: Observable<string>
  public currentBonusReason$!: Observable<string>
  public currentBonusIsRemovable$!: Observable<boolean>;
  public currentBonusIsRemoved$!: Observable<boolean>;
  public hasAvailableForCheckoutComponents = true;

  public usedComponents: any[] = [];

  // TODO make observable!!
  public initialComponentName!: string | null;

  // TODO make observable!!
  public componentMaxValue!: number;

  // TODO make observable!!
  public componentStandardValue!: string;

  public formGroup!: FormGroup;
  public formOptions!: FormOptions;

  private bonusProjectConfiguration!: DirectBonusProjectConfiguration;
  private removeBonusConfigurations: { [key: string]: RemoveBonusConfig } = {};

  public get bonusProjectId(): string {
    return this.dialogData.bonusProjectId;
  }

  public get customerId(): number {
    return this.dialogData.customerId!;
  }

  public get orderId(): string | undefined {
    return this.dialogData.monthBonuses?.orderId!;
  }

  public get employeeId() {
    return this.dialogData.employeeId;
  }

  public get employeeName() {
    return this.dialogData.employeeName;
  }

  public get year() {
    return this.dialogData.year;
  }

  public get month() {
    return this.dialogData.month;
  }

  public get monthStr() {
    const orderPeriodMoment = moment({
      month: this.month - 1,
      year: this.year
    });

    return orderPeriodMoment.format('MMM');
  }

  public get bonusValue(): FormControl {
    return this.formGroup.get('bonusValue') as FormControl;
  }

  constructor(
    private formBuilder: FormBuilder,
    private directBonusService: DirectBonusService,
    private mapper: DirectBonusMapperService,
    public dialogRef: MatDialogRef<DirectBonusCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: BonusTableDialogData
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.getBonusProjectConfiguration();
  }

  private showValues() {

    this.currentBonusIsRemovable$ = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value as FormChoices),
      map(({componentName}: FormChoices) => {
        return this.removeBonusConfigurations[componentName].isRemovable;
      })
    );

    this.currentBonusIsRemoved$ = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value as FormChoices),
      map(({componentName}: FormChoices) => {
        return this.removeBonusConfigurations[componentName].isRemoved;
      })
    );

    this.currentBonusValue$ = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value as FormChoices),
      tap((formChoices: FormChoices) => {
        const {
          componentName
        } = formChoices;

        const componentConfig = this.bonusProjectConfiguration[componentName];

        this.componentMaxValue = componentConfig.maxValue;
        this.componentStandardValue = `${componentConfig.standardValue} €`;

        this.bonusValue.setValidators([
          Validators.required,
          Validators.pattern(/^\-?[0-9]+(?:\.[0-9]{2})?$/),
          Validators.min(0),
          Validators.max(componentConfig.maxValue),
        ])
      }),
      map(({componentName}: FormChoices) => this.getBonusByComponentName(componentName as DirectBonusComponentType)?.value || 0),
      map(bonusValue => `${bonusValue} €`),
    );

    this.currentBonusReason$ = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value as FormChoices),
      map(({componentName}: FormChoices) => this.getBonusByComponentName(componentName as DirectBonusComponentType)?.reason || '-'),
    )
  }

  private getBonusProjectConfiguration() {
    this.directBonusService.getAllowedForCheckoutComponentsConfigurations(
      this.employeeId,
      this.month,
      this.year,
      this.bonusProjectId
    ).pipe(
      map((allowedForCheckoutComponentsConfigurations: DirectBonusProjectComponentConfiguration[]) => {
        return allowedForCheckoutComponentsConfigurations.reduce(
          (configuration, componentConfig) => {
            configuration[componentConfig.componentType!] = componentConfig;
            return configuration;
          },
          {} as DirectBonusProjectConfiguration
        )
      })
    ).subscribe((bonusProjectConfiguration) => {
      this.bonusProjectConfiguration = bonusProjectConfiguration;
      const components = this.directBonusService.getAvailableBonusComponents(bonusProjectConfiguration);
      
      DIRECT_BONUS_COMPONENTS.map(c => {
        if(!Object.keys(bonusProjectConfiguration).includes(c)) {
          this.usedComponents.push(this.mapper.getDirectBonusComponentDisplayValue(c));
        }
      })

      if (components.length) {
        this.hasAvailableForCheckoutComponents = true;
        this.formOptions = {componentName: components};

        this.initialComponentName = this.formOptions.componentName[0].value;
        this.formGroup.patchValue({componentName: this.initialComponentName})

        for (let component of components) {
          let bonus = this.getBonusByComponentName(component.value)
          this.removeBonusConfigurations[component.value] = {
            clickStartTime: undefined,
            clickEndTime: undefined,
            isRemoving: false,
            isRemovable: (bonus?.value || 0) > 0,
            isRemoved: false
          }
        }

        this.showValues();
      } else {
        this.hasAvailableForCheckoutComponents = false;
      }
    });
  }

  private getBonusByComponentName(componentName: DirectBonusComponentType): DirectBonusComponent | null {
    return this.dialogData.monthBonuses?.components.find((o: DirectBonusComponent) => o.componentName == componentName)!;
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      componentName: this.formBuilder.control(
        this.initialComponentName,
        []
      ),
      bonusValue: this.formBuilder.control(undefined, []),
      reason: this.formBuilder.control(undefined, []),
    });
  }

  public saveBonus() {
    const {
      componentName,
      bonusValue,
      reason
    } = this.formGroup.value;

    const bonus = this.getBonusByComponentName(componentName as DirectBonusComponentType);

    const newBonusData: DirectBonusFormData = {
      bonusProjectId: this.bonusProjectId,
      orderId: this.orderId,
      bonusId: bonus?.bonusId!,
      employeeId: this.employeeId,
      customerId: this.customerId,
      componentName: componentName,
      formValue: parseFloat(bonusValue),
      formReason: reason,
      year: this.year,
      month: this.month,
    };

    this.dialogRef.close(newBonusData);
  }

  public removingBonusTimer(state: string) {

    const componentName = this.formGroup.controls['componentName'].value;

    if (state === 'start') {
      this.removeBonusConfigurations[componentName].isRemoving = true;
      this.removeBonusConfigurations[componentName].clickStartTime = new Date();
    } else if (state === 'stop') {
      this.removeBonusConfigurations[componentName].isRemoving = false;
      this.removeBonusConfigurations[componentName].clickEndTime = new Date();

      const bonus = this.getBonusByComponentName(componentName as DirectBonusComponentType)!;

      if (this.removeBonusConfigurations[bonus.componentName].clickStartTime!.getTime() + 1000 < this.removeBonusConfigurations[bonus.componentName].clickEndTime!.getTime()) {
        this.removeBonus(bonus);
      }
    }
  }

  private removeBonus(bonus: DirectBonusComponent) {
    const bonusId = bonus.bonusId;
    const orderId = this.orderId!;
    this.directBonusService.customerRemoveBonusFromOrder(bonusId, orderId).subscribe(
      () => {
        if (this.dialogData.monthBonuses) {
          this.dialogData.monthBonuses.components = this.dialogData.monthBonuses.components
            .filter((component: DirectBonusComponent) => component.bonusId !== bonusId);

          this.removeBonusConfigurations[bonus.componentName].isRemoved = true;
          this.removeBonusConfigurations[bonus.componentName].isRemovable = false;
        }

        this.showValues();
      }
    );


  }

  public getRemovingBonusClass(): string {
    const componentName = this.formGroup.controls['componentName'].value;
    return this.removeBonusConfigurations[componentName].isRemoving ? 'on' : '';
  }
}
