import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';
import { map, startWith, tap } from 'rxjs/operators';
import { BonagoCategory } from 'src/app/direct-bonus/models/benefit-bonago.model';
import { Subscription } from 'rxjs';
import {BenefitToolbarEventService} from "../../../services/benefit-toolbar-event.service";


// ===============================================================

interface FormOptions {
}

interface FormChoices {
  searchValue: string;
}

// ===============================================================


@Component({
  selector: 'vn-benefits-account',
  templateUrl: './benefits-account.component.html',
  styleUrls: ['./benefits-account.component.scss']
})
export class BenefitsAccountComponent implements OnInit, OnDestroy {

  private currentWindowWidth!: number;

  public selectedCategoryName$ = this.bonagoService.selectedCategory$.pipe(
    map(category => category?.name),
  );
  private categoryNameSubscription!: Subscription;
  private selectedCategoryName!: string;

  public showBackButton$ = this.selectedCategoryName$.pipe(
    startWith(null),
    map(categoryName => !!categoryName),
  );

  public showMapLink$ = this.bonagoService.userBonagoScheme$.pipe(
    map(scheme => scheme.enableGoogleMaps)
  );

  public categories$ = this.bonagoService.bonagoCategories$;

  public retailerNames$ = this.bonagoService.allBonagoRetailersBasic$.pipe(
    map(retailers => retailers.map(
      retailer => retailer.title.toLowerCase()
    )),
    map(normalizedRetailerNames => [
      ...new Set(normalizedRetailerNames)
    ])
  );

  constructor(
    private bonagoService: BenefitsBonagoService,
    private benefitToolbarEventService: BenefitToolbarEventService,
    @Inject('$state') private $state: any,
  ) {
  }

  ngOnInit(): void {
    this.currentWindowWidth = window.innerWidth;

    this.categoryNameSubscription = this.selectedCategoryName$.subscribe(
      (categoryName) => {
        this.selectedCategoryName = categoryName;
      }
    );
  }

  ngOnDestroy(): void {
    this.categoryNameSubscription.unsubscribe();
  }

  public getCategoryClass(category?: BonagoCategory) {
    return {
      'selected-category': !!category ?
        category.name === this.selectedCategoryName :
        !this.selectedCategoryName && this.$state.current.name === 'benefitCatalog'
    };
  }

  public openDrawer(sideNav: MatDrawer) {
    sideNav.open();
  }

  public closeDrawer(sideNav: MatDrawer) {
    sideNav.close();
  }

  public onResize($event: any) {
    this.currentWindowWidth = $event.target.innerWidth;
  }

  private calculateUiViewWidth() {
    if (this.currentWindowWidth <= 700) {
      return 325;
    } else if (this.currentWindowWidth <= 1025) {
      return 650;
    } else if (this.currentWindowWidth <= 1350) {
      return 975;
    } else {
      return 1300;
    }
  }

  public getUiViewStyle() {
    return {
      width: `${this.calculateUiViewWidth()}px`
    };
  }

  public selectCategory(category?: BonagoCategory) {
    if (!!category) {
      this.bonagoService.selectCategoryId(category.id);

      this.$state.go(
        'benefitCatalog',
        {
          categoryId: category.id
        }
      );
    } else {
      this.bonagoService.selectCategoryId(0);

      this.$state.go(
        'benefitCatalog',
        {
          categoryId: undefined
        }
      );
    }
  }

  public openMap() {
    this.bonagoService.selectCategoryId(0);

    this.$state.go(
      'benefitsMap',
    );
  }

  public goHome() {
    this.bonagoService.selectCategoryId(0);

    this.$state.go(
      'inApp.directBonusBenefitsAccount',
    );
  }

  hideSearchInToolbar() {
    this.benefitToolbarEventService.hideSearchFromToolbar();
  }
}
