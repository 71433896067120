const $inject = [
  'advisorService', 'employeeService'
];
export default class chooseAdvisorDialogController {

  constructor(advisorService, employeeService) {
    this.working = true;

    advisorService
      .list()
      .then(advisors => employeeService.getMany(advisors))
      .then(advisors => {
        this.advisors = advisors;
        this.working = false;
      });
  }
}
chooseAdvisorDialogController.$inject = $inject;
