<div class="incent-offers-container" vnLoader [showLoader]='(isLoading$() | async)!'>
  <h1>Rabatt-Welt</h1>
  <div class="cockpit-panel">
    <div class="cockpit-panel__body">
      <form [formGroup]="incentOffersForm" (ngSubmit)="onSubmit()">
        <div class="row" *ngIf="offers.length && !(isLoading$() | async)">
          <div class="column small-12">
            <div class="right">
              <button [disabled]="incentOffersForm.invalid || (isLoading$() | async)"
                class="button.button-primary.isOutline">
                Speichern
              </button>

              <button *ngIf="offers.length < 2" style="margin-left: 5px" (click)="addCustomOffer($event)" class="button.button-primary.isOutline">
                Hinzufügen
              </button>
            </div>
          </div>
        </div>

        <div class="row" *ngFor="let incentOffer of offersFormGroup; let i = index">

          <div class="column small-12" *ngIf="getOfferByIndex(i) as foundOffer">
            <div class="row">
              <h3>{{ foundOffer.getTitle() }} </h3>
              <label>
                Inhalt
              </label>
            </div>
            <div class="row" [formGroup]="incentOffer">

              <quill-editor formControlName="text" [modules]="QUILL_MODULES" placeholder="Text hier eingeben...">
              </quill-editor>

              <div *ngIf="incentOffer.controls.text.errors" class="helpText">
                <div *ngIf="incentOffer.controls.text.errors.required">Text field is required</div>
              </div>

              <div *ngIf="!foundOffer.isDefault()">
                <mat-slide-toggle formControlName="isActive">Aktivieren</mat-slide-toggle>

                <vn-date-range formControlName='period' label='Verfügbarkeit' placeholder='Zeitinterval auswählen'
                  [defaultInputValue]="foundOffer.getPeriodForDateRange()">
                </vn-date-range>

                <div *ngIf="incentOffer.controls.period.errors" class="helpText">
                  <div *ngIf="incentOffer.controls.period.errors.required">Bitte Eingabe prüfen</div>
                </div>

                <vn-input formControlName='offerId' label="Offer" placeholder='Add offer id'>
                </vn-input>

                <div *ngIf="incentOffer.controls.offerId.errors" class="helpText">
                  <div *ngIf="incentOffer.controls.offerId.errors.required">Bitte geben Sie eine Offer ID an.</div>
                </div>

              </div>

            </div>

          </div>

        </div>

      </form>

    </div>

  </div>
</div>
