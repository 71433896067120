angular
  .module('VSPApp')
  .controller('CashbackDetailsController', [
    "$scope",
    "$state",
    "$q",
    "ngDialog",
    "Made",
    "CashbackConstants",
    "customerService",
    "NotificationService",
    function (
      $scope,
      $state,
      $q,
      ngDialog,
      Made,
      CashbackConstants,
      customerService,
      NotificationService,
    ) {
      $scope.NotificationService = NotificationService;

      $scope.details = CashbackConstants[$state.params.cashbackId];
      $scope.Made = Made;
      $scope.inputModel = {};

      $scope.inputModel.title = $scope.details.title;
      $scope.inputModel.vorname = Made.user.firstname;
      $scope.inputModel.nachname = Made.user.lastname;
      $scope.inputModel.email = Made.user.emails[0];
      $scope.inputModel.telefon = '';
      $scope.inputModel.note = '';
      $scope.loading = true;

      Made
        .request('rpc://shop/cashback/get_user_data')
        .then(data => {
          console.log(data);
          data = data.data;
          $scope.inputModel.email = data.email ? data.email : $scope.inputModel.email;
          $scope.inputModel.vorname = data.firstname;
          $scope.inputModel.nachname = data.lastname;
          $scope.inputModel.telefon = data.phone;
        })
        .finally(() => $scope.loading = false);


      $scope.submit = () => {

        if ($scope.testmode !== false) {
          return;
        }

        if ($scope.cashbackForm.$valid) {
          $scope.loading = true;
          $scope.inputModel.note = $scope.escapeHtml($scope.inputModel.note);

          Made
            .request('rpc://shop/cashback/request', {
              'data': $scope.inputModel
            })
            .then(() => {
              $scope.NotificationService.message('Ihre Nachricht wurde erfolgreich übermittelt und wird in Kürze bearbeitet.');
              $scope.success = true;
            }, () => {
              $scope.NotificationService.error('Ihre Nachricht konnte nicht übermittelt werden.');
            })
            .finally(function () {
              $scope.loading = false;
            });
        }
      };



      /**
       * NEED schemaform info:
       *  - New empty line (OK)
       *  - Textarea input (OK)
       *  - Styling submit button
       *  - Required field validation
       *  - Checkbox
       *  - Data upload decorator
       *  - API handling - submit
       */
      customerService
        .isTestmode()
        .then(testmode => $scope.testmode = testmode);

      $scope.privacyPopup = function () {
        ngDialog
          .open({
            template: '/modules/cashback/details/privacy.html',
            className: 'ngdialog-theme-vsp mitarbeiter-anlegen'
          });
      };

      $scope.escapeHtml = (text) => {
        var map = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#039;'
        };

        return text.replace(/[&<>"']/g, function (m) { return map[m]; });
      }

    }]);
