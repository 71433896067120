<table *ngIf="tableData && tableData.length"
       mat-table
       [dataSource]="tableData"
       class="mat-elevation-z8 customer-project-overview-table"
       data-cy="vn.directBonus.customerAdministration.customerProjectOverviewTable.elementTable"
       matSort>
    <!-- definition for header and footer rows -->
    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"
        sticky="true">
    </tr>

    <tr mat-header-row
        *matHeaderRowDef="secondHeaderColumns"
        class="second-header-row"
        sticky="true">
    </tr>

    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;">
    </tr>

    <!-- Second group header columns -->
    <ng-container matColumnDef="secondHeaderName">
        <th mat-header-cell
            *matHeaderCellDef>
            Gesamt
        </th>
    </ng-container>

    <ng-container matColumnDef="secondHeaderFiller1">
        <th mat-header-cell
            *matHeaderCellDef>
        </th>
    </ng-container>

    <ng-container matColumnDef="secondHeaderFiller2">
        <th mat-header-cell
            *matHeaderCellDef>
        </th>
    </ng-container>

    <ng-container *ngFor="let month of months"
                  [matColumnDef]="month.id.toString()">
        <th mat-header-cell
            *matHeaderCellDef>
            {{ getSumForMonthForAllEmployees(month.id) | currency : '€'}}
        </th>
    </ng-container>

    <!-- id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell
            *matHeaderCellDef>
            ID
        </th>

        <td mat-cell
            [ngStyle]="applyEmployeeStatusStyle(data.employee.status)"
            *matCellDef="let data"
            [attr.data-cy]="'vn.directBonus.customerAdministration.customerProjectOverviewTable.cellEmployeeId.' + data.employee.id">
            {{ data.employee.id }}
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef>
            Mitarbeiter
        </th>

        <td mat-cell
            *matCellDef="let data">
            {{ data.employee.name }}
        </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="personalNumber">
        <th mat-header-cell
            *matHeaderCellDef>
            PN
        </th>

        <td mat-cell
            *matCellDef="let data"
            [attr.data-cy]="'vn.directBonus.customerAdministration.customerProjectOverviewTable.cellPersonalNumber.' + data.employee.id">
            {{ data.employee.personalNumber }}
        </td>
    </ng-container>

    <ng-container *ngFor="let month of months"
                  [matColumnDef]="month.displayColumnName">
        <th mat-header-cell
            *matHeaderCellDef>
            {{ month.label }}
        </th>

        <td mat-cell
            *matCellDef="let data"
            class="dashed"
            [ngClass]="getMonthClass(data, month.id)"
            [attr.data-cy]="'vn.directBonus.customerAdministration.customerProjectOverviewTable.cellEmployeeMonthBonus.' + data.employee.id + '-' + month.id"
            (click)="openCreateBonusDialog(data, month.id)">
            <ng-container *ngFor="let monthBonuses of data['bonusesPerMonth'][month.id]">
                <span
                    *ngIf="hasBonusForMonth(monthBonuses)"
                    [ngClass]="getOrderClass(monthBonuses)">
                    {{ (getSumPerMonthPerEmployee(monthBonuses) | currency : '€')}}
                </span>
            </ng-container>
        </td>
    </ng-container>

    <!-- Sum column -->
    <ng-container matColumnDef="sumForUser">
        <th mat-header-cell
            *matHeaderCellDef>
            Gesamt
        </th>

        <td mat-cell
            [attr.data-cy]="'vn.directBonus.customerAdministration.customerProjectOverviewTable.cellEmployeeTotalBonus.' + data.employee.id"
            *matCellDef="let data">
            {{ getTotalForEmployeeForAllMonths(data) ?
            (getTotalForEmployeeForAllMonths(data) | currency : '€' )
            : '-'
            }}
        </td>
    </ng-container>
</table>
