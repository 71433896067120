<ng-content select="[headerText]"></ng-content>
<ng-content select="[descriptionText]"></ng-content>
<ng-content></ng-content>
<mat-dialog-content *ngIf="!disableFields">
  <form [formGroup]="formGroup">
    <div class="row userPreferencesData">
      <div class="columns small-5">
        <label for="dTicketUserFirstName">Vorname:</label>
        <input formControlName="firstName" id="dTicketUserFirstName"/>
        <mat-error *ngIf="firstName.hasError('required')">
          Eingabe fehlt.
        </mat-error>
      </div>

      <div class="columns small-5">
        <label for="dTicketUserLastName">Name:</label>
        <input formControlName="lastName" id="dTicketUserLastName"/>
        <mat-error *ngIf="lastName.hasError('required')">
          Eingabe fehlt.
        </mat-error>
      </div>

      <!-- Birth Date -->
      <div class="columns small-5">
        <mat-form-field appearance="outline">
          <mat-label>Geburtsdatum:</mat-label>
          <input id="dateOfBirth" matInput [matDatepicker]="picker" formControlName="birthDate"/>
          <mat-error *ngIf="birthDate.hasError('required')">
            Eingabe fehlt.
          </mat-error>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions class="userPreferencesActionItems">
  <button
    mat-button
    mat-dialog-close
    class='confirm-button'
    mat-stroked-button
  >
    Schließen
  </button>

  <button
    [disabled]="!disableFields && !formGroup.valid"
    class='confirm-button'
    mat-stroked-button
    (click)="save()">
    {{ dialogConfirmButtonText }}
  </button>
</mat-dialog-actions>
