export const RATE_TYPES = [
  {'id': 0, 'name': 'brutto', 'value': 'brutto', 'display': 'Brutto'},
  {'id': 1, 'name': 'netto', 'value': 'netto', 'display': 'Netto'}
];
export const PC_RATE_TYPES = [...RATE_TYPES];
export const PC_RATE_TYPES_BY_NAME = PC_RATE_TYPES.reduce((acc, type) => {
  acc[type['name']] = type;
  return acc;
}, {});
export const PC_RATE_TYPES_BY_ID = PC_RATE_TYPES.reduce((acc, type) => {
  acc[type['id']] = type;
  return acc;
}, {});
export const DEFAULT_PC_RATE_TYPE = PC_RATE_TYPES_BY_NAME['netto'];
