const $inject = [
  '$timeout',
  'userService',
  'customerService',
  'VIDEOS_DISABLED'
];

export default class filmService {

  constructor(
    $timeout,
    userService,
    customerService,
    VIDEOS_DISABLED
  ) {

    Object.assign(this, {
      $timeout,
      userService,
      customerService,
      VIDEOS_DISABLED
    });
  }

  oneTimeClick(film, clicker) {
    return this.userService
      .getOnetimeSettings()
      .then( onetimeSettings => {

        if ( this.VIDEOS_DISABLED || onetimeSettings[ film ] ) {
          return;
        }

        this.clickClicker(clicker);

        onetimeSettings[ film ] = Date.now();

        return this.userService.setOnetimeSettings( onetimeSettings );
      } );
  }

  clickClicker(clicker) {
    if (clicker && clicker.click) {
      this.$timeout( () => clicker.click(), 500 );
    }
  }

  autoOpen(film, clicker) {
    return this.customerService
      .isTestmode()
      .then( testmode => {
        if ( testmode && !window[ film ] ) {

          this.clickClicker(clicker);
          window[ film ] = true;
        } else {

          this.oneTimeClick( film, clicker );
        }
      } );
  }
}

filmService.$inject = $inject;
