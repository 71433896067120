<div 
  vnLoader
  [showLoader]='(isLoading$() | async)!'
  class="hrmony-import-container"
>
  <h3>
    Hrmony
  </h3>
  
  <vn-dropzone label="Import Hrmony Data "
      (onUpload)="dataImport($event)">
  </vn-dropzone>
</div>
