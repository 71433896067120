const ALVktgConstants = [
  {
    value: 'GELDWERTER_VORTEIL',
    name: 'geldwerter Vorteil'
  },
  {
    value: 'NETTOABZUG',
    name: 'Nettoabzug'
  },
  {
    value: 'OHNE_BERUECKSICHTIGUNG',
    name: 'ohne Berücksichtigung'
  }
];

export default ALVktgConstants;
