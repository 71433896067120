angular
  .module('VSPApp')
  .constant('JSONcockpit',
    {
      "type": "object",
      "properties": {
        "kundenauswahl": {
          "type": "object",
          "title": "Kundenauswahl"
        },
        "allgemein": {
          "type": "object",
          "title": "Allgemein"
        },
        "steuerfrei":{
          "type":"object",
          "title":"Steuerfreie Extras"
        },
        "versicherungen":{
          "type":"object",
          "title":"Versicherungen"
        },
        "vorsorge":{
          "type":"object",
          "title":"Vorsorge"
        },
        "cashback":{
          "type":"object",
          "title":"Cashback"
        },
        "shop":{
          "type":"object",
          "title":"Shop"
        },
        "bonus":{
          "type":"object",
          "title":"Bonus"
        },
        "entgelterhoenung": {
          "type": "object",
          "title": "Entgelterhönung"
        },
        "sonstiges": {
          "type": "object",
          "title": "Sonstiges"
        }
      }
    });
