angular
  .module('VSPApp')
  .service('BenefitService', ['$q', 'Made',function ($q, Made) {

    this.getBalance = (employee_id = Made.user.valuenet_id) => Made
      .request('rpc://valuenetdb/konto/balance', {
        employee_id
      })
    ;

  }]);
