const bausteinIcon = ["ComponentConstants", function (ComponentConstants) {
  return function (input) {

    if ('object' === typeof input) {
      input = input.id;
    }

    if (ComponentConstants[input]) {
      return ComponentConstants[input].icon;
    } else {
      console.log('baustein filter - unknown baustein: ', input);
      return 'support';
    }
  };
}]


const bausteinTitle = ["ComponentConstants", function (ComponentConstants) {
  return function (input) {

    if ('object' === typeof input) {
      input = input.id;
    }

    if (ComponentConstants[input]) {
      return ComponentConstants[input].title;
    } else {
      console.log('baustein filter - unknown baustein: ', input);
      return input;
    }
  };
}]


export { bausteinIcon, bausteinTitle }
