var pug = require("!../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};














pug_mixins["radios"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"form-group schema-form-radios-inline\"\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel class=\"control-label\"\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp));
if (attributes.titleMap) {
pug_html = pug_html + "\u003Cul" + (pug.attr("class", pug.classes(['small-block-grid-'+ attributes.titleMap.length], [true]), false, true)) + "\u003E";
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var option = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["radio"].call({
attributes: pug.merge([{"index": pug.escape(index),"key": pug.escape(attributes.key)},option])
});
pug_html = pug_html + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var option = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["radio"].call({
attributes: pug.merge([{"index": pug.escape(index),"key": pug.escape(attributes.key)},option])
});
pug_html = pug_html + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
else
if (attributes.titleMapLength) {
pug_html = pug_html + "\u003Cul" + (pug.attr("class", pug.classes(['small-block-grid-' + attributes.titleMapLength], [true]), false, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Ful\u003E";
}
else {
block && block();
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"kids__assistant\"\u003E\u003Cdiv class=\"kids__head\"\u003E\u003Cul\u003E\u003Cli class=\"kid right\"\u003E\u003Ca ng-click=\"addChild()\"\u003EWeiteres Kind\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"kid\" ng-repeat=\"kid in employee.kids\" title=\"{{kid.result}}\"\u003E\u003Ca ng-click=\"$parent.selected = $index\" ng-class=\"{selected: $parent.selected == $index}\"\u003EKind {{$index + 1}}\u003C\u002Fa\u003E\u003Cdiv class=\"remove\" ng-click=\"removeChild($index)\"\u003Ex\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_mixins["input"].call({
attributes: {"key": "employee.kids[selected].birthdate","title": "Geburtsdatum","required": pug.escape(true),"attributes": {"vn-date":"true","vn-date-age-min":2,"vn-date-age-max":6},"validationMessages": {"required":"Bitte Geburtsdatum angeben","parse":"Bitte Geburtsdatum im Format tt.mm.jjjj eingeben","age":"Das Kind muss im Kindergartenalter (2-6 Jahre) sein"}}
});
pug_mixins["input"].call({
attributes: {"key": "employee.kids[selected].subsidy","title": "Betreuungsgeld (mtl.)","required": pug.escape(true),"attributes": {"vn-currency":"true"},"validationMessages": {"required":"Bitte Betreuungsgeld angeben"}}
});
pug_mixins["checkbox"].call({
attributes: {"key": "employee.kids[selected].august","title": "August beitragsfrei"}
});
pug_mixins["radios"].call({
attributes: {"key": "employee.kids[selected].foodFeeRecurrence","title": "Verpflegungsgeld","titleMap": [{"value":"monthly","name":"monatlich"},{"value":"daily","name":"täglich"}]}
});
pug_mixins["input"].call({
attributes: {"key": "employee.kids[selected].foodFee","title": "Verpflegungsgeld","attributes": {"vn-currency":"true"},"required": pug.escape(true),"validationMessages": {"required":"Bitte Höhe des Verpflegungsgeldes angeben"}}
});
pug_mixins["input"].call({
attributes: {"key": "employee.kids[selected].expenses","title": "sonstige Aufwände","attributes": {"vn-currency":"true"},"required": pug.escape(true),"validationMessages": {"required":"Bitte Höhe der sonstigen Aufwände angeben"}}
});
},
attributes: {"name": "assistantForm"}
});
pug_html = pug_html + "\u003Cdiv\u003E\u003Clabel\u003EBerechneter Beitrag: {{ employee.kids[selected].result | currency }}\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"small-6 columns\"\u003E\u003Clabel\u003EGesamtbeitrag: {{ total | currency }}\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"small-6 columns\"\u003E\u003Cbutton ng-click=\"closeThisDialog()\"\u003EAbbrechen\u003C\u002Fbutton\u003E" + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp)) + "\u003Cbutton ng-click=\"confirm(total)\" ng-disabled=\"!assistantForm.$valid\"\u003EFertig\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;