<div class="map-content-wrapper">

    <div *ngIf="!consentServiceAccepted; else consent_accepted">
        <vn-consent-dialog
                [ucServiceName]="consentServiceName"
                (ucServiceStatus)="handleStatus($event)"
                (ucServiceAction)="triggerAction($event)">
        </vn-consent-dialog>
    </div>

    <ng-template #consent_accepted>
        <div class="google-maps-container">
            <form #formElement
                  *ngIf="mapForm"
                  [formGroup]='mapForm'
                  class="search-bar">

                <vn-input formControlName='searchValue'
                          placeholder='Suchen...'>
                </vn-input>
            </form>

            <google-map #benefitPlacesMap
                        *ngIf="mapOptions$ | async as mapOptions"
                        width="100%"
                        height="65vh"
                        [options]="mapOptions"
                        [center]="latLong">

                <map-marker-clusterer [imagePath]="markerClustererImagePath">
                    <map-marker *ngFor="let markerItem of retailerMarkers$ | async"
                                #markerRef="mapMarker"
                                [position]="markerItem.marker.getPosition()!"
                                (mapClick)="openInfo(markerRef, markerItem.address)"
                                [icon]="markerItem.marker.getIcon()!">
                    </map-marker>
                </map-marker-clusterer>

                <map-info-window>
                    <div [innerHTML]="infoContent"></div>
                </map-info-window>
            </google-map>
        </div>
    </ng-template>
</div>
