import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vn-feature-a',
  templateUrl: './feature-a.component.html',
  styleUrls: ['./feature-a.component.scss']
})
export class FeatureAComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
