const $inject = [
  'Made', 'customerService', 'advisorService'
];
export default class adviseCustomerDialogController {

  constructor(Made, customerService, advisorService) {

    Object.assign(this, {
      Made,
      customerService,
      advisorService
    });

    this.searchQuery = '';

  }

  load() {

    if (this.searchQuery.length < 3) {
      return;
    }

    this.working = true;

    this.customerService
      .listUnadvised(this.searchQuery)
      .then(customers => this.customers = customers)
      .finally(() => this.working = false);
  }


  advise(customerId) {

    this.working = true;
    this.advisorService
      .setAdvisorForCustomer(this.Made.user.valuenet_id, customerId)
      .finally(() => {
        this.customerService.setTestmode(customerId, true);
        this.load();
      });
  }
}
adviseCustomerDialogController.$inject = $inject;
