const formatTimeDifference = [function () {
  return function (differenceInHours) {

    if (differenceInHours != undefined) {
      if (differenceInHours == 0) {
        return 'Weniger als 1 Stunde';
      }
      else if (isNaN(differenceInHours)) {
        return 'Fehlerhafter Zeitstempel'; // check if differnce in hours is not a number
      }
      else {
        const days = Math.floor(differenceInHours / 24);
        let hours = differenceInHours % 24;

        let result = '';
        if (days > 0) {
          result = days + 'd' + hours + ' h';
        } else {
          result = hours + ' h';
        }
        return result;
      }
    } else return ' '; // check if difference in hours is missing
  };
}]

export { formatTimeDifference }
