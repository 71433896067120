import headebr_html from '../../../helpers/header.main.pug';
import dashboard_berater_template from '../../../modules/dashboard/berater/index.pug';

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.dashboardBerater', {
        url: '/dashboard_berater',
        views: {
          main: {
            template: dashboard_berater_template,
            controller: 'DashboardBeraterController',
            controllerAs: 'dashboard'
          },
          header: {
            template: headebr_html
          }
        }
      });

  }]);
