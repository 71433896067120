export const EMPLOYEE_STATUSES = [
  {id: 1, name: 'kontrolle_b', display: 'Beraterabschluss', is_prefix: false, color: 'black', flows: [1, 2]},
  {id: 2, name: 'kontrolle_s', display: 'Selfservice', is_prefix: false, color: 'black', flows: [1, 2]},
  {id: 3, name: 'kontrolle_k', display: 'Kontrolle - Anpassung', is_prefix: false, flows: [1, 2]},
  {id: 4, name: 'kontrolle_t', display: 'Warten auf Druck', is_prefix: false, color: 'black', flows: [1, 2, 27, 29]},
  {id: 5, name: 'kontrolle', display: 'Kontrolle', is_prefix: false, color: 'black', flows: [1, 2]},
  {id: 6, name: 'nachforschung', display: 'Anpassung', is_prefix: false, color: 'green', flows: [1, 2, 4, 27]},
  {id: 7, name: 'korrektur_', display: 'Nachberatung', is_prefix: true, color: 'green', flows: [1, 2]},
  {id: 8, name: 'aktiv', display: 'Aktiv', is_prefix: false, color: 'green', flows: []},
  {id: 9, name: 'inaktiv', display: 'Inaktiv', is_prefix: false, color: 'red', flows: [1, 2, 4]},
  {id: 10, name: 'ruhend', display: 'Ausserhalb LFZ', is_prefix: false, color: 'yellow', flows: [1, 2, 3, 5, 27]},
  {id: 11, name: 'DELETE', display: 'DELETE', is_prefix: false, flows: [1, 2]},
  {id: 12, name: 'kontrolle_m_b', display: 'Berater ohne Nachweise', color: 'black', flows: [1, 2, 6]},
  {id: 13, name: 'kontrolle_m_s', display:'Selfservice ohne Nachweise', color: 'black', flows: [1, 2, 7]},
  {id: 14, name: 'ruhendkv', display:'Krankheit', color: 'yellow', flows: [1, 2, 3, 5]},
  {id: 15, name: 'nachforschung_r', display:'Rückrechnung', color: 'green', flows: [1, 2, 4]},
  {id: 16, name: 'inaktiv_a', display:'Austritt', color: 'red', flows: [2]},
  {id: 17, name: 'inaktiv_n', display:'NEO Aufhebung', color: 'red', flows: []},
  {id: 18, name: 'ruhend_nachforschung', display:'Ruhend Nachforschung', color: 'yellow', flows: [1, 2, 5]},
  {id: 19, name: 'ruhendkv_nachforschung', display:'Ruhendkv Nachforschung', color: 'yellow', flows: [1, 2, 5]},
  {id: 20, name: 'ruhendkv_nachforschung_r', display:'Ruhendkv Nachforschung R', color: 'yellow', flows: [1, 2, 5]},
  {id: 21, name: 'ruhend_nachforschung_r', display:'Ruhend Nachforschung R', color: 'yellow', flows: [1, 2, 5]},
  {id: 22, name: 'korrektur_nachforschung', display:'Nachberatung Anpassung', color: 'green', flows: [1, 2, 4, 27]},
  {id: 23, name: 'korrektur_aktiv', display:'Nachberatung Aktiv', color: 'green', flows: [1, 2, 3, 4, 27]},
  {id: 24, name: 'korrektur_ruhend', display:'Nachberatung Ausserhalb LFZ', color: 'green', flows: [1, 2, 3, 5, 27]},
  {id: 25, name: 'kontrolle_m_d', display:'Direkt ohne Nachweis', color: 'black', flows: []},
  {id: 26, name: 'kontrolle_d', display:'Direktabschluss', color: 'black', flows: [13]},
  {id: 27, name: 'kontrolle_p1', display:'Warten auf Versand', color: 'black', flows: [29,30]},
  {id: 28, name: 'kontrolle_p2', display:'Warten auf Eingang', color: 'black', flows: [30]},
  {id: 29, name: 'kontrolle_p3', display:'Warten auf Aktiv', color: 'black', flows: [13, 31]},
  {id: 30, name: 'kontrolle_e', display:'Dokumente Fehlerhaft	', color: 'black', flows: [13, 31]},
  {id: 31, name: 'korrektur_nachforschung_r', display:'Nachberatung Anpassung R', color: 'green', flows: [1, 2, 4, 27]},
  {id: 32, name: 'korrektur_ruhendkv', display:'Nachberatung Krankheit', color: 'green', flows: [1, 2, 3, 5, 27]},
  {id: 33, name: 'korrektur_ruhendkv_nachforschung', display:'Nachberatung Anpassung KV', color: 'green', flows: [1, 2, 4, 27]},
  {id: 34, name: 'korrektur_ruhend_nachforschung_r', display:'Nachberatung Anpassung R A', color: 'green', flows: [1, 2, 4, 27]},
  {id: 35, name: 'korrektur_ruhendkv_nachforschung_r', display:'Nachberatung Anpassung R KV', color: 'green', flows: [1, 2, 4, 27]},
  {id: 36, name: 'korrektur_ruhend_nachforschung', display:'Nachberatung Anpassung A', color: 'green', flows: [1, 2, 4, 27]},
  {id: 37, name: 'kontrolle_p6', display:'Warten auf Digital', color: 'green', flows: [35]},
  {id: 38, name: 'kontrolle_p7', display:'Kontrolle Abgelaufen', color: 'green', flows: []},
];

export const EMPLOYEE_STATUSES_FOR_FILTERS = EMPLOYEE_STATUSES.filter(status => {
  let excluded_statuses =  ['korrektur_aktiv', 'korrektur_ruhend','korrektur_nachforschung','korrektur_nachforschung_r','korrektur_ruhendkv','korrektur_ruhendkv_nachforschung','korrektur_ruhend_nachforschung_r','korrektur_ruhendkv_nachforschung_r','korrektur_ruhend_nachforschung'];
  return !excluded_statuses.includes(status.name);

});

export const ID_EMPLOYEE_STATUSES_MAP = EMPLOYEE_STATUSES.reduce((acc, curr) => {
  acc[curr.id] = curr;
  return acc;
}, {});
export const NAME_EMPLOYEE_STATUSES_MAP = EMPLOYEE_STATUSES.reduce((acc, curr) => {
  acc[curr.name] = curr;
  return acc;
}, {});

export const ALL_CONTROL_STATUS_NAMES = [
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_b'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_s'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_d'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_k'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_m_b'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_m_s'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_m_d'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p1'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p2'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p3'].name,
  NAME_EMPLOYEE_STATUSES_MAP['kontrolle_t'].name
];

export const EMPLOYEE_STATUSES_GROUPS = [
  {id: 1, name: 'alle_kontrolle', display: ' alle Kontrolle', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_b'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_s'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_d'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_k']
    ]
  },
  {id: 2, name: 'fehlende_unterlagen', display: ' alle fehlenden Unterlagen', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_m_b'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_m_s'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_m_d']
    ]
  },
  {id: 3, name: 'dokumentenfluss' ,display: ' alle Dokumentenfluss', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p1'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p2'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p3'],
      NAME_EMPLOYEE_STATUSES_MAP['kontrolle_t']
    ]
  },
  {id: 4, name: 'ruhende' ,display: ' alle Ruhenden', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['ruhend'],
      NAME_EMPLOYEE_STATUSES_MAP['ruhendkv'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhend'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhendkv']
    ]
  },
  {id: 5, name: 'anpassungen' ,display: ' alle Anpassungen', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['nachforschung_r']
    ]
  },
  {id: 6, name: 'anpassungruhend' ,display: ' alle ruhenden Anpassungen', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['ruhend_nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['ruhend_nachforschung_r'],
      NAME_EMPLOYEE_STATUSES_MAP['ruhendkv_nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['ruhendkv_nachforschung_r'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhend_nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhend_nachforschung_r'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhendkv_nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhendkv_nachforschung_r']
    ]
  },
  {id: 7, name: 'nachberatungen' ,display: ' alle Nachberatungen', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_aktiv'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_nachforschung_r'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhend'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhendkv'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhend_nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhend_nachforschung_r'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhendkv_nachforschung'],
      NAME_EMPLOYEE_STATUSES_MAP['korrektur_ruhendkv_nachforschung_r']
    ]
  },
  {id: 7, name: 'inaktive' ,display: ' alle inaktiven', statuses: [
      NAME_EMPLOYEE_STATUSES_MAP['inaktiv_a'],
      NAME_EMPLOYEE_STATUSES_MAP['inaktiv_n']
    ]
  }
];

export const EMPLOYEE_STATUS_FLOWS = [
  {
    id: 1, name: 'austritt', display: 'Austritt', is_new_status: true, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Austrittsdatum', type: 'date'},
      {field_id: 'date_of_inactive', name: 'Bausteine inaktiv zum', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 2, name: 'neo_aufhebung', display: 'Neo Aufhebung', is_new_status: true, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Aufhebungsdatum', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 3, name: 'anpassung', display: 'Anpassung', is_new_status: false, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'reason', name: 'Grund', type: 'select', options: [
          {value: 'anpassung', name: 'Anpassung'},
          {value: 'ruckrechnung', name: 'Rückrechnung'}
        ]},
      {field_id: 'date_of_change', name: 'Änderung gültig ab', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 4, name: 'ausserhalb', display: 'Ausserhalb LFZ', is_new_status: false, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Änderung gültig ab', type: 'date'},
      {field_id: 'reason', name: 'Grund', type: 'select', options: [
          {value: 'krankheit', name: 'Krankheit'},
          {value: 'elternzeit', name: 'Elternzeit'},
          {value: 'mutterschutz', name: 'Mutterschutz'},
          {value: 'med_arbeitsverbot', name: 'med. Arbeitsverbot'},
          {value: 'unbezahlte_freistellung', name: 'unbezahlte Freistellung'},
          {value: 'bezahlte_freistellung', name: 'bezahlte Freistellung'},
          {value: 'sonstiges', name: 'Sonstiges'}
        ]},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 5, name: 'ruckkehr_krankenstand', display: 'Rückkehr Krankenstand', required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Gültig ab', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 6, name: 'kontrolle_b', display: 'Beraterabschluss', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 7, name: 'kontrolle_s', display: 'Selfservice', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 8, name: 'kontrolle_k', display: 'Kontrolle - Anpassung', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 9, name: 'kontrolle_t', display: 'Dokumentenfluss', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 10, name: 'kontrolle', display: 'Kontrolle', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 11, name: 'nachforschung', display: 'Anpassung', is_new_status: true, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Gültig ab', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 12, name: 'korrektur_', display: 'Nachberatung', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 13, name: 'aktiv', display: 'Aktiv', is_new_status: false, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Gültig ab', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 14, name: 'inaktiv', display: 'Inaktiv', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 15, name: 'ruhend', display: 'Ausserhalb LFZ', is_new_status: true, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Änderung gültig ab', type: 'date'},
      {field_id: 'reason', name: 'Grund', type: 'select', options: [
          {value: 'krankheit', name: 'Krankheit'}
        ]},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 16, name: 'DELETE', display: 'Delete', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 17, name: 'kontrolle_m_b', display: 'Berater ohne Nachweise', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 18, name: 'kontrolle_m_s', display: 'Selfservice ohne Nachweise', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 19, name: 'ruhendkv', display: 'Krankheit', is_new_status: false, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Änderung gültig ab', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 20, name: 'nachforschung_r', display: 'Rückrechnung', is_new_status: true, required: [
      {field_id: 'ticket_number', name: 'Ticket-Number', type: 'integer_id'},
      {field_id: 'date_of_change', name: 'Gültig ab', type: 'date'},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 23, name: 'ruhend_nachforschung', display: 'Ruhend Nachforschung', is_new_status: true, required: [
      {field_id: 'reason', name: 'Grund', type: 'select', options: [
          {value: 'anpassung', name: 'Anpassung'},
          {value: 'elternzeit', name: 'Elternzeit'},
          {value: 'mutterschutz', name: 'Mutterschutz'},
          {value: 'med_arbeitsverbot', name: 'med. Arbeitsverbot'},
          {value: 'unbezahlte_freistellung', name: 'unbezahlte Freistellung'},
          {value: 'bezahlte_freistellung', name: 'bezahlte Freistellung'},
          {value: 'sonstiges', name: 'Sonstiges'}
        ]},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'},
    ]
  }, {
    id: 24, name: 'ruhendkv_nachforschung', display: 'Ruhendkv Nachforschung', is_new_status: true, required: [
      {field_id: 'reason', name: 'Grund', type: 'select', options: [
          {value: 'krankheit', name: 'Krankheit'},
          {value: 'anpassung', name: 'Anpassung'}
        ]},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 25, name: 'ruhendkv_nachforschung_r', display: 'Ruhendkv Nachforschung R', is_new_status: true, required: [
      {field_id: 'reason', name: 'Grund', type: 'select', options: [
          {value: 'ruckrechnung', name: 'Rückrechnung'},
          {value: 'krankheit', name: 'Krankheit'}
        ]},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 26, name: 'ruhend_nachforschung_r', display: 'Ruhend Nachforschung R', is_new_status: true, required: [
      {field_id: 'reason', name: 'Grund', type: 'select', options: [
          {value: 'ruckrechnung', name: 'Rückrechnung'},
          {value: 'elternzeit', name: 'Elternzeit'},
          {value: 'mutterschutz', name: 'Mutterschutz'},
          {value: 'med_arbeitsverbot', name: 'med. Arbeitsverbot'},
          {value: 'unbezahlte_freistellung', name: 'unbezahlte Freistellung'},
          {value: 'bezahlte_freistellung', name: 'bezahlte Freistellung'},
          {value: 'sonstiges', name: 'Sonstiges'}
        ]},
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 27, name: 'korrektur', display: 'Korrektur', is_new_status: false, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 28, name: 'docflow_print', display: 'Dokumente gedruckt', is_new_status: false, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 29, name: 'docflow_sent', display: 'Dokumente versandt', is_new_status: false, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 30, name: 'docflow_return', display: 'Dokumente eingegangen', is_new_status: false, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 31, name: 'docflow_error', display: 'Dokumente fehlerhaft', is_new_status: false, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 32, name: 'kontrolle_d', display: 'Direktabschluss', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 33, name: 'kontrolle_m_d', display: 'Direkt ohne Nachweise', is_new_status: true, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 34, name: 'no_missing_documents', display: 'No More Missing Documents', is_new_status: false, required: [
      {field_id: 'comment', name: 'Kommentar', type: 'comment'}
    ]
  }, {
    id: 35, name: 'digital_sign_contract', display: 'digital_sign_contract', is_new_status: false, required: []
  }, {
    id: 36, name: 'digital_sign_contract_expiration', display: 'digital_sign_contract_expiration', is_new_status: false, required: []
  }, {
    id: 37, name: 'digital_sign_contract_cancellation', display: 'digital_sign_contract_cancellation', is_new_status: false, required: []
  }, {
    id: 38, name: 'kein_status', display: 'Kein Status', is_new_status: true, required: []
  }
];
export const ID_EMPLOYEE_STATUS_FLOWS = EMPLOYEE_STATUS_FLOWS.reduce((acc, curr) => {
  acc[curr.id] = curr;
  return acc;
}, {});

export const NAME_EMPLOYEE_STATUS_FLOWS = EMPLOYEE_STATUS_FLOWS.reduce((acc, curr) => {
  acc[curr.name] = curr;
  return acc;
}, {});

const $inject = [
  '$q', 'Made', 'CacheService', 'moment', '$state', 'lodash'
]
export default class employeeService {

  constructor($q, Made, CacheService, moment, $state, lodash) {

    this.$q = $q;
    this.$state = $state;
    this.Made = Made;
    this.moment = moment;
    this.lodash = lodash;

    this.cache = CacheService.getDictionaryStore('employees');
    this.employees = this.cache.store;
    this.current_employee = undefined;
  }

  goToOTRS(valuenet_id){
    let link = `https://valuenet.managed-otrs.com/agent/customer/user/${valuenet_id}`;
    window.open(link, '_blank');
  }

  goToNorbert(valuenet_id){
    let link = `https://msteams.valuenet.de:9251/user/${valuenet_id}/detail/compact`;
    window.open(link, 'Nutzerdetail', 'width=1500,height=1000');
  }

  goToCidaasAdminPortal(cidaas_sub) {
    let link = `https://auth.valuenet.de/myaccounts-webapp/userdetails/${cidaas_sub}`;
    window.open(link, '_blank');
  }

  goTo(config) {
    this.$state.go(config.route, config.params);
  }

  updatePersonalInfoApproval(user){
    return this.Made.request('rpc://valuenetdb/employee/updatePersonalInfoApproval', {
      employee_id: user.id,
      data:user,
    });
  }

  update(user, reinvite = false) {
    return this.Made.request('rpc://valuenetdb/employee/update', {
      employee_id: user.id,
      user,
      reInvite: reinvite
    }).then(employee_id => {
      return this.get(employee_id, new Date());
    });
  }

  updateEmployeeState( employee_id, employee_state_model) {
    return this.Made.request('rpc://valuenetdb/employee/update_employee_state', {
      employee_id: employee_id,
      employee_state_object: employee_state_model
    });
  }

  create(user) {
    return this.Made
      .request('rpc://valuenetdb/employee/create', {
        user
      })
      .then(response => {

        user.id = response;
        this.employees[user.id] = {
          data: user
        };

        return user;
      });
  }

  isEmployeeTemporary(employee){
    return employee.status === 1;
  }

  isEmployeeTest(employee){
    return employee.testclient;
  }

  hasMissingDocuments(employeeId = this.Made.user.valuenet_id, sessionId) {
    return this.getComponentsWithMissingDocumentsList(employeeId, sessionId).then(result => result.components_list.length > 0);
  }

  getComponentsWithMissingDocumentsList(employeeId = this.Made.user.valuenet_id, sessionId = 0) {
    return this.Made.request('rpc://vbm/neo/get_missing_documents_list', {
      'employee_id': employeeId,
      'session_id': sessionId
    }).then(result => {
      return {
        session_id: result.session_id,
        components_list: result.missing_documents_for_components_list
      };
    });
  }

  moveToControlStateVCP(employeeid, advisorCheckout, session_id, is_direct_contract, change_employee_status, advisor_checkout_comment = '') {
    return this.Made
      .request('rpc://vcp/employee/moveToControlState', {
        employeeid,
        'advisor_checkout': advisorCheckout,
        'session_id': session_id,
        'is_direct_contract': is_direct_contract,
        'change_employee_status' : change_employee_status,
        'advisor_checkout_comment' : advisor_checkout_comment
      })
      .then(() => {
        return employeeid;
      });
  }

  noMoreMissingDocumentsForEmployee(employeeId) {
    return this.Made.request('rpc://vcp/employee/no_more_missing_documents', {'employee_id': employeeId});
  }

  createVCP(user) {

    return this.Made
      .request('rpc://vcp/employee/create', {
        user
      })
      .then(response => {

        user.id = response;
        this.employees[user.id] = {
          data: user
        };

        return user;
      });
  }

  createMade(employee_id, employee) {

    return this.Made.request('rpc://valuenetdb/user/access/create_made', {
      employee_id: employee_id,
      employee: employee
    });
  }

  getKist(id = this.Made.user.valuenet_id) {
    if (this.current_employee){
      return this.current_employee.employer_address.kist;
    }
    return 0;
  }

  async get_EMPLOYEE_EMAIL_TYPES_BY_NAME () {
    return this.get_EMPLOYEE_EMAIL_TYPES().then((email_types)=> {
      return email_types.reduce((acc, email_type)=> {
        acc[email_type['name']] = email_type;
        return acc;
      },{});
    });
  }

  async get_EMPLOYEE_EMAIL_TYPES() {
    let EMPLOYEE_EMAIL_TYPES = this.cache.get('EMPLOYEE_EMAIL_TYPES');

    if (EMPLOYEE_EMAIL_TYPES) {
      return this.$q.when(EMPLOYEE_EMAIL_TYPES);
    }

    let EMPLOYEE_EMAIL_TYPES_promise = this.Made.request('rpc://valuenetdb/employee/EMPLOYEE_EMAIL_TYPES');

    return this.cache.set('EMPLOYEE_EMAIL_TYPES', undefined, EMPLOYEE_EMAIL_TYPES_promise);
  }

  async getApprovalEmail(employee) {
    let EMPLOYEE_EMAIL_TYPES_BY_NAME = await this.get_EMPLOYEE_EMAIL_TYPES_BY_NAME();
    let employee_approval_email = employee.contact_details[EMPLOYEE_EMAIL_TYPES_BY_NAME['approval_email']['art_name']];

    if (employee_approval_email && employee_approval_email !== '') {
      return employee_approval_email;
    } else {
      return employee['login']['email'];
    }
  }

  async setEmailPreferences(options) {
    let params = {
      email_type_id : options['email_type_id'],
      to_receive: options['to_receive'],
      email_string: options['email_string'],
      for_employee_id: parseInt(options['for_employee_id'])
    };

    return this.Made.request('rpc://valuenetdb/employee/update_email_preferences', params);
  }

  get(id = this.Made.user.valuenet_id, stale = 0) {

    let employee = this.cache.get(id, stale);

    if (employee) {
      return employee;
    }

    if (!id) {
      console.trace(id);
    }

    employee = this.Made.request('rpc://valuenetdb/employee/get', {
      employee_id: parseInt(id)
    }).then(employee => {
      if (id === this.Made.user.valuenet_id) {
        this.current_employee = employee;
      }
      return employee;
    });

    return this.cache.set(id, undefined, employee);
  }

  async getAddress(employee_id = this.Made.user.valuenet_id) {
    let employee = await this.get(employee_id);

    // as per checkout.py: 196 ( get_neo_extras )
    if (!employee.address) {
      employee.address = {
        city : '',
        zip :'',
        street: ''
      };
    }

    return this.lodash.cloneDeep(employee.address);
  }

  saveAddress(employee_id = this.Made.user.valuenet_id, address = this.employees[employee_id].data.address) {

    return this.Made.request('rpc://valuenetdb/employee/new_location', {
      employee_id,
      address
    });
  }

  deleteNotification(employeeId, notification_id) {

    return this.Made.request('rpc://valuenetdb/employee/pop_latest_notifications', {
      employee_id: parseInt(employeeId),
      notification_id
    });
  }

  getNotifications(employeeId = this.Made.user.valuenet_id) {

    return this.Made.request('rpc://valuenetdb/employee/get_notifications', {
      employee_id: parseInt(employeeId)
    });
  }

  getCustomerId(employeeId = this.Made.user.valuenet_id) {

    return this
      .get(employeeId)
      .then(employee => employee.customer_id);
  }


  getDictionary(employeeList) {
    let dictionary = {};

    employeeList.forEach(employee => {
      dictionary[employee.id] = employee;
    });

    return dictionary;
  }


  getAdvisorInfo(employeeId = this.Made.user.valuenet_id) {

    return this.Made.request('rpc://valuenetdb/employee/get_advisor_info', {
      employee_id: parseInt(employeeId)
    });
  }

  getManyMySQLEmployees(ids) {
    return this.Made
    .request('rpc://valuenetdb/employee/get_mysql_employees_by_ids', {ids})
  }

  getMany(employeeIds, stale = 0) {

    let result = [];

    for (let i = 0; i < employeeIds.length; i++) {

      employeeIds[i] = parseInt(employeeIds[i]);
      let employee = this.cache.getData(employeeIds[i], stale);

      if (employee) {
        result.push(employee);
        employeeIds.splice(i, 1);
      }
    }

    if (employeeIds.length) {

      return this.Made
        .request('rpc://valuenetdb/employee/get_many', {
          employee_ids: employeeIds
        })
        .then(employees => {

          for (let i = 0; i < employees.length; i++) {

            result.push(this.cache.setData(employees[i].id, employees[i]));

          }

          return result;
        });
    }

    return this.$q.when(result);
  }


  getContactDetails(employeeId) {
    return this.Made.request('rpc://valuenetdb/employee/contact/get_contact_details', {
      employee_id: employeeId
    });
  }

  updateContactDetails(employeeId, contactDetails) {
    return this.Made.request('rpc://valuenetdb/employee/contact/updateContactDetails', {
      employeeId,
      contactDetails
    });
  }


  getIntegrityData(employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/employee/integrity/getData', {'employee_id': employeeId});
  }

  updateIntegrityData(integrityData, employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/employee/integrity/updateData', {
      integrityData,
      'employee_id': employeeId
    });
  }

  updateBirthdateData(integrityData, employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/employee/integrity/updateBirthdate', {
      integrityData,
      'employee_id': employeeId
    });
  }

  updateEntrydateData(integrityData, employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/employee/integrity/updateEntrydate', {
      integrityData,
      'employee_id': employeeId
    });
  }

  updateAddressData(integrityData, employeeId = 0) {
    return this.Made.request('rpc://valuenetdb/employee/integrity/updateAddress', {
      integrityData,
      'employee_id': employeeId
    });
  }

  setEmployeeEmail(email, employee_id) {
    return this.Made.request('rpc://valuenetdb/employee/setEmployeeEmail', {
      email: email,
      employee_id: employee_id
    });
  }

  // ok!
  checkEmployeeByEmail(email) {
    return this.Made.request('rpc://valuenetdb/employee/checkByEmail', {
      email
    });
  }

  getByMongoId(id) {
    return this.Made.request('rpc://valuenetdb/employee/get_by_mongoid', {
      id: id
    });
  }

  validateActivationCredentials(activation_credentials) {
    return this.Made.request('rpc://valuenetdb/employee/validateActivationCredentials', {
      activation_credentials
    });
  }

  getByActivationCode(activation_code) {
    return this.Made.request('rpc://valuenetdb/employee/getByActivationCode', {
      activation_code
    });
  }

  getContracts(id, only_last = false) {
    return this.Made.request('rpc://valuenetdb/employee/getContracts', {
      employee_id: id,
      only_last: only_last
    });
  }

  finaliseContractByCalcId(calc_id, valuenet_id) {
    return this.Made.request('rpc://valuenetdb/employee/finaliseContractByCalcId', {
      calc_id: calc_id,
      valuenet_id: valuenet_id
    });
  }

  async getCurrentUsedComponents(employeeId, ev_begin_limit_start) {
    return this.Made.request('rpc://valuenetdb/calculation/get_blocked_components', {
      valuenet_ids: [parseInt(employeeId)],
      ev_begin_start_limit: ev_begin_limit_start
    });
  }

  getContractByCalcId(calc_id) {
    return this.Made.request('rpc://valuenetdb/employee/getContractByCalcId', {
      calc_id: calc_id
    });
  }

  finaliseContract(ai_id, calc_id, timestamp, valuenet_id) {
    return this.Made.request('rpc://valuenetdb/employee/finaliseContract', {
      ai_id: ai_id,
      calc_id: calc_id,
      timestamp: this.moment(timestamp).format('YYYY-MM-DD HH:mm:ss'),
      valuenet_id: valuenet_id
    });
  }

  unfinaliseContract(ai_id, calc_id) {
    return this.Made.request('rpc://valuenetdb/employee/unfinaliseContract', {
      ai_id: ai_id,
      calc_id: calc_id
    });
  }

  filterEmployees(filters) {
    return this.Made.request('rpc://valuenetdb/employee/getAllEmployees', {
        filters: filters
      }
    );
  }

  getEmployeesWithApprovals(filters) {
    return this.Made.request('rpc://valuenetdb/employee/getEmployeesWithApprovals', {
        filters: filters
      }
    );
  }

  getCurrentStatus(employee_id){
    return this.Made.request('rpc://valuenetdb/user/access/getCurrentStatus', {
      employee_id: employee_id
    });
  }

  getEmployeeIds (employees) {
    return employees.map(employee => employee.id);
  }

  indexCustomers (employees) {
    return employees.reduce((acc, curr) => {
      acc[curr.customer_id] = {
        name: curr.customer_name,
        id: curr.customer_id
      };
      return acc;
    }, {});
  }
  groupEmployeesByCustomerId (employees) {
    return employees.reduce((r, a) => {
      r[a.customer_id] = [...r[a.customer_id] || [], a];
      return r;
    }, {});
  }

  parseEmployeeStatuses (employees) {
    employees.forEach((employee) => {
      employee.employee_status = this.getNeoStatusForEmployee(employee.access_status.neo_status);
    });

    return employees;
  }



  getNeoStatusForEmployee (neo_status) {
    let result = { display: ' - ' };

    // sanity check
    if (typeof neo_status !== 'string') {
      return result;
    }

    let status_split = neo_status.split(' ');
    let status_name = status_split.shift();

    let multiname_status = (status_name || '').split('_');
    let is_korrectur = multiname_status.indexOf('korrektur') > -1;
    let is_kontrolle = multiname_status.indexOf('kontrolle') > -1;

    if (status_name) {
      if (is_korrectur) {
        result = { ...ID_EMPLOYEE_STATUSES_MAP[7], ...{ display: ID_EMPLOYEE_STATUSES_MAP[7].display + ' ' + multiname_status[1] } };
      } else if (is_kontrolle) {
        result = { ...result, ...NAME_EMPLOYEE_STATUSES_MAP[status_name.toLowerCase()] };
      } else {
        result = { ...result, ...NAME_EMPLOYEE_STATUSES_MAP[status_name] };
      }
    }

    return result;
  }

  filterBonusEmployees( filters ) {
    return this.Made.request('rpc://valuenetdb/employee/getAllBonusEmployees', {
        filters: filters
      }
    );
  }

  exists(params) {
    return this.Made.request('rpc://valuenetdb/employee/exists_overall', params);
  }

  migrateCustomerIds() {
    return this.Made.request('rpc://valuenetdb/employee/transfer_custid_to_mongo_employees');
  }

  togglePcLeaseBlockingForEmployee(employeeId, shouldBlock, blockingReason) {
    return this.Made.request(
      'rpc://valuenetdb/employee/toggle_pc_lease_blocking_for_employee',
      {
        employee_id: employeeId,
        should_block: shouldBlock,
        blocking_reason: blockingReason,
      }
    );
  }

  getEmployeeBlockedPcComponentsLog(employeeId) {
    return this.Made.request(
      'rpc://valuenetdb/employee/get_employee_latest_blocked_pc_log',
      {
        employee_id: employeeId
      }
    );
  }

  async isPcBlockedForEmployee(employeeId) {
    return this.Made.request(
        'rpc://valuenetdb/employee/is_pc_leasing_blocked_for_employee',
      {
        employee_id: employeeId
      }
    );
  }

  getEmployeeMobileDevices(employeeId = this.Made.user.valuenet_id) {

    return this.Made.request('rpc://valuenetdb/employee/get_employee_mobile_devices', {
      employee_id: parseInt(employeeId)
    });
  }

  updateEmployeeMobileDeviceNotify(device_record_id, be_notified) {
    return this.Made.request('rpc://valuenetdb/employee/update_employee_mobile_device_notify', {
      device_record_id: parseInt(device_record_id),
      be_notified: parseInt(be_notified)
    });
  }

  updateEmployeeMobileDeviceName(device_record_id, name) {
    return this.Made.request('rpc://valuenetdb/employee/update_employee_mobile_device_name', {
      device_record_id: parseInt(device_record_id),
      name: name
    });
  }

  checkIfItsTestEmployee(emp) {
    return this.isEmployeeTemporary(emp) || this.isEmployeeTest(emp)
  }
}
employeeService.$inject = $inject;
