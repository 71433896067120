/**
      * Berechnung Monatslohn, zur Beurteilung der Mindestlohngesetze
                 = Steuerbrutto
                  - Monatlicher Beiträge Versicherung (BAV1+BAV2 + BAV+VA)
                  + monatliche AG-Zuschüsse Versicherung (BAV1+BAV2 + BAV+VA)
                  - geldwerter Vorteil Firmenwagen         (bes Lohnarten: gw. Vorteil Dienstwagen)
                  - geldwerter Vorteil ALV-KTG             (Behandlung ALV+KRG: Geldwerter Vorteil)
                  - geldwerter Vorteil Fahrrad             (Optimierung: Ebike  1% vom Listenpreis)

      * @return Monatslohn zur Beurteilung der Mindestlohngesetze
      */
//
// public static BigDecimal getMonatslohn_Mindestlohn(CalculationExtended cb) {
//
//       if (cb == null) throw new AssertionError("calculationbean not set");
//
//
//       BigDecimal versicherungen =
//                   cb.getMonatlicherBeitrag(Bestandstyp.VORHER)
//                   .add(cb.getMonatlicherBeitrag(Bestandstyp.BAV))
//                   .add(cb.getMonatlicherBeitrag(Bestandstyp.VA));
//       BigDecimal zuschuesseAG =
//                   cb.getZuschussAG(Bestandstyp.VORHER)
//                   .add(cb.getZuschussAG(Bestandstyp.BAV))
//                   .add(cb.getZuschussAG(Bestandstyp.VA));
//
//       BigDecimal gwVorteile = cb.getValues().getGeldwert()
//                   .add(cb.getValues().getAlvKtgGeldwert());
//       if (cb.getOptimization().getComponent(Constants.COM_EBIKE) != null) {
//             BigDecimal gwEBike = new BigDecimal(cb.getOptimization().getComponent(Constants.COM_EBIKE)
//                                                      .getLongValue(Constants.CVK_LISTENPREIS_CENT).intValue())
//                                          .multiply(Constants.ONE_PERCENT);
//             gwVorteile = gwVorteile.add(Utilities.getInEuro(gwEBike));
//       }
//       BigDecimal monatslohn = cb.getEntgelt().getBruttosteuer()
//                   .add(versicherungen).subtract(zuschuesseAG)
//                   .subtract(gwVorteile);
//
//       if (Session.getCustomer().getBranche()== "Bau"){
//             monatslohn = cb.getEntgelt().getBruttosteuer()
//                         .subtract(gwVorteile);
//       }
//       return monatslohn;
// }


/** check if declarations/calculation match with mindestlohn.
 * Threatening range is a range 20 percent above Mindestlohn.
 * @param showMessage
* @param useThreateningRange - if true, check passes only if even threatening range is not touched.
*                                             If false check passes only if mindestlohn is not underrun
 *
 * @return true, if monatslohn underrun or threatened by mindestlohn
*/
// public static boolean isMindestlohnThreatened(boolean showMessage, boolean useThreateningRange) {
//       boolean threatended = false;
//       BigDecimal monatslohn = Berechnungen.getMonatslohn_Mindestlohn(m_view.getDataCalculationExt());
//
//       if (monatslohn.compareTo(BigDecimal.ZERO) > 0) {
//
//             SalaryPanelData spd = m_view.getData().salary;
//             BigDecimal mindestmonatslohn = spd.steuer.mindestlohn.multiply(spd.steuer.regelarbeitszeit.setScale(2)).multiply(Basics.WOCHEN_PRO_MONAT).setScale(2, BigDecimal.ROUND_HALF_UP);
//
//             BigDecimal comparisonvalue = mindestmonatslohn;
//             BigDecimal schwellenWert = new BigDecimal("1.2");
//             if (useThreateningRange) comparisonvalue = mindestmonatslohn.multiply(schwellenWert);
//
//             if (monatslohn.compareTo(comparisonvalue) <= 0) {
//                   threatended = true;
//                   m_logger.warn("Mindestmonatslohn: ["+mindestmonatslohn.toPlainString()+"], Schwellenwert: ["+mindestmonatslohn.multiply(schwellenWert).toPlainString()+"], Monatslohn: ["+monatslohn.toPlainString()+"]");
//
//                   if (showMessage) {
//
//                         String msg = "Die Basis für die Ermittlung des Mindeslohns beträgt " + Utilities.roundEuro(monatslohn) +" €."
//                                    + "\nDer gültige Mindestlohn wird um " + Utilities.roundEuro(mindestmonatslohn).subtract(Utilities.roundEuro(monatslohn)) +" € im Monat unterschritten."
//                              + "\nBitte überprüfen Sie die korrekte Eingabe der Mindestlohndaten oder passen Sie die Berechnung an.";
//
//                         if (useThreateningRange) {
//                              if (monatslohn.compareTo(mindestmonatslohn) > 0) {         //value is in threatening range
//                                    msg = "Die Basis für die Ermittlung des Mindeslohns beträgt " + Utilities.roundEuro(monatslohn) +" €."
//                                          + "\nSie liegt damit im Prüfbereich von 20% über dem Mindestmonatslohn von € " + Utilities.roundEuro(mindestmonatslohn) +"."
//                                          + "\nBitte verifizieren Sie die Eingabe der Mindestlohndaten.";
//
//                         }
//                         Utilities.showInfoMsg(msg, "MiLoG -> Eingaben überprüfen");
//                   }
//             }
//       }
//       return threatended;
// }


const WeeksPerMonth = 4.35;
const Threshold = 1.1; // VSP-597 von 1.2 auf 1.1
const $inject = [];

export default class PlausibilityService {

  check(employee) {

    let result = {
      errors: {},
      warnings: {},
      notifications: {},
      info: {
        hourlyWage: this.getHourlyWage(employee),
        minimumHourlyWageTolerance: this.getMinimumHourlyWageTolerance(employee)
      },
      success: true
    };

    if (this.minimumWageViolated(employee)) {
      result.errors.minimumWage = true;
      result.success = false;
    }

    if (this.essenscheckDaysViolated(employee)) {
      result.errors.essenscheckDays = true;
      result.success = false;
    }

    return result;
  }


  monthlyWage(employee) {
    let version = employee.data.parameters.jahr;

    let wage = employee.result.final.tax.brutto_steuer,
      insurances = 0,
      employerSubsidy = 0,
      noncashBenefit = 0;


    insurances += employee.data.insurances.betrieblich_alv1.beitrag;
    insurances += employee.data.insurances.betrieblich_alv2.beitrag;
    insurances += employee.data.insurances.bav.beitrag;
    insurances += employee.data.insurances.va.beitrag;


    employerSubsidy += employee.data.insurances.betrieblich_alv1.zuschuss_ag;
    employerSubsidy += employee.data.insurances.betrieblich_alv2.zuschuss_ag;
    employerSubsidy += employee.data.insurances.bav.zuschuss_ag;
    employerSubsidy += employee.data.insurances.va.zuschuss_ag;


    noncashBenefit += employee.data.parameters.geldwert_vorteil_dienstwagen;
    if (employee.data.metadata.behandlung_alvktg != 'OHNE_BERUECKSICHTIGUNG') {
      noncashBenefit += employee.result.final.gap.alv_ktg;
    }

    if (employee.data.neo_components.EBike.enabled) {
      if (version >= 2020) {
        noncashBenefit += Math.floor(employee.data.neo_components.EBike.listenpreis * .0025) / 100;
      }
      else if (version == 2019) {
        noncashBenefit += Math.floor(employee.data.neo_components.EBike.listenpreis * .005) / 100;
      } else {
        noncashBenefit += employee.data.neo_components.EBike.listenpreis / 100;
      }
    }
    if (employee.data.neo_components.Bikeleasing.enabled) {
      if (version >= 2020) {
        noncashBenefit += Math.floor(employee.data.neo_components.Bikeleasing.listenpreis * .0025) / 100;
      } else if (version == 2019) {
        noncashBenefit += Math.floor(employee.data.neo_components.Bikeleasing.listenpreis * .005) / 100;
      } else {
        noncashBenefit += employee.data.neo_components.Bikeleasing.listenpreis / 100;
      }
    }

    wage = wage + insurances - employerSubsidy - noncashBenefit;

    //TODO: check customer branch for Bau

    return wage;
  }


  getHourlyWage(employee, monthlyWage = this.monthlyWage(employee)) {
    let hourlyWage = monthlyWage / employee.data.metadata.regelarbeitszeit / WeeksPerMonth;

    return Math.round((hourlyWage + 0.00001) * 100) / 100;
  }


  getMinimumWage(employee) {

    let minimumWage = employee.data.metadata.mindestlohn * employee.data.metadata.regelarbeitszeit * WeeksPerMonth;

    return Math.ceil((minimumWage + 0.00001) * 100) / 100;
  }


  getMinimumWageTolerance(employee, minimumWage = this.getMinimumWage(employee)) {
    return Threshold * minimumWage;
  }


  getMinimumHourlyWageTolerance(employee) {
    return Math.round((Threshold * employee.data.metadata.mindestlohn + 0.00001) * 100) / 100;
  }


  minimumWageViolated(employee, monthlyWage = this.monthlyWage(employee), minimumWage = this.getMinimumWage(employee)) {

    if (monthlyWage <= 0) {
      return false;
    }

    return monthlyWage < this.getMinimumWageTolerance(employee, minimumWage);
  }

  essenscheckDaysViolated(employee) {
    return employee.data.neo_components.essenscheck.enabled &&
      employee.data.neo_components.essenscheck.anzahl_tage > employee.data.metadata.arbeitstage;
  }

}

PlausibilityService.$inject = $inject;