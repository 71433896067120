<button 
  class="button" 
  type="button" 
  [disabled]="isDisabled" 
  (click)="prufenClickedHandler()" 
  *ngIf="!isProved"
>
  Prüfen
</button>
<button 
  class="button checked" 
  type="button" 
  *ngIf="isProved"
>
  <mat-icon>check_circle_outline</mat-icon>
</button>

<div class="error-message" *ngIf="showErrorMessage">
  Bitte geben Sie eine gültige Adresse ein.
</div>
