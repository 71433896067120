const $inject = [
  'Made',
  'authenticationService',
  '$scope',
  'userService',
  'employeeService',
  'dialogService',
  '$location',
  'customerService',
  '$timeout',
  'ByteplantService',
  'NotificationService',
]
export default class RegistrationController {

  constructor(
    Made,
    authenticationService,
    $scope,
    userService,
    employeeService,
    dialogService,
    $location,
    customerService,
    $timeout,
    ByteplantService,
    NotificationService
  ) {

    Object.assign(this, {
      Made,
      authenticationService,
      $scope,
      userService,
      employeeService,
      dialogService,
      customerService,
      $timeout,
      ByteplantService,
      NotificationService,
    });

    this.data = {
      login: {
        email: '',
        is_work: false
      },
      toc: false
    };

    this.forms = {
      selfRegistrationForm: {}
    };

    this.readToc = false;

    this.newsletter_subscription = {
      is_accepted: false,
      is_rejected: false
    };

    this.iAmProxy = false;

    this.is_registration_nltg_text = true;

    this.__loading(false);

    if ($location.search().regcode) {
      this.enteredCode = this.parseCustomerCode($location.search().regcode);
    }

    this.next = false;

    this.resetWhitelist();
  }

  getDomainErrorMessage() {
    return this.customerService.getCustomerDomainErrorMessage(this.$scope.configuration.domains_config);
  }

  getCustomerLocations() {
    this.authenticationService
      .getLocationsByCustomerCode(this.customerCode)
      .then(locations => {
        this.errorCode = !locations;

        if (!this.errorCode) {
          this.neoProjectId = locations.neoProjectId;
          this.locations = locations.locations;
          this.customerLocations = [];

          if (locations.locations.length === 1) {
            this.data.loc_id = locations.locations[0].id;
          }

          for (let location of this.locations) {
            this.customerLocations.push({
              name: [
                location.name,
                location.strasse,
                location.plz,
                location.ort
              ].join(', '),
              value: location.id
            });
          }

          this.data.customer_id = locations.id;
          this.getCustomerConfig(this.data.customer_id);
        }
      });
  }

  async getCustomerConfig(customer_id) {
    let configuration = await this.customerService.getConfiguration(customer_id);

    this.$timeout(() => {
      this.$scope.configuration = configuration;
    }, 0);

  }

  parseCustomerCode(code) {
    return typeof code === 'number' ? code : parseInt(code.replace(/ /g, ''));
  }

  way() {
    this.customerCode = this.parseCustomerCode(this.enteredCode);
    this.getCustomerLocations();
    this.data.toc = false;
  }

  async selfSave() {
    this.__loading(true);

    // new way - CURRENT;
    this.data.testclient = false;

    if (typeof this.data.sex === 'undefined') {
      this.data.sex = false;
    }

    return this.authenticationService.create(
      this.data,
      this.neoProjectId,
      this.newsletter_subscription.is_accepted,
      'selfregistration'
    ).then(
      employeeId => {
        if (!employeeId) {
          location.reload();
        }
        this.newEmployee = employeeId;
        return this.userService.externalAcceptToc(
          0,
          this.newEmployee,
        ).then(result => {
            if (!result) {
              location.reload();
            }
            this.success = true;
          },() => {
            this.error = true;
          }
        );
      },(err) => {
        let error = err.error;
        let message = error.message.text;
        if (error) {
          const flag = error.message.flag;
          switch (flag) {
            case 'personalnumberHasTaken':
              this.setValidity('employeenumber', 'unique', false);
              break;
            case 'emailNotUnique':
              this.setValidity('login.email', 'unique', false);
              break;
            case 'emailNotExist':
              this.setValidity('login.email', 'exist', false);
              break;
            case 'domainIsIncorrect':
              this.setValidity('login.email', 'domain', false);
              message = this.getDomainErrorMessage();
              break;
          }
          this.error = true;
          this.NotificationService.error(message);
        }
      }).then(() => {
        this.__loading(false);
      });

  }

  toggleRead(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.data.toc) {
      this.showToc();
    } else {
      this.data.toc = !this.data.toc;
    }
  }

  showToc() {
    this.dialogService.registrationToc(this.$scope.configuration).then((result) => {
      if (result) {
        this.readToc = true;
        this.data.toc = true;
      }
    });
  }

  handlePersonalNumberChanged() {
    this.resetUniqueEmployeeId();
    this.resetWhitelist();
  }

  handleEmailInputChange() {
    this.resetEmailValidations();
    this.checkEmailUniqueness();
    this.checkEmailDomain();
  }

  resetUniqueEmployeeId() {
    this.setValidity('employeenumber', 'unique', true);
  }

  resetEmailValidations() {
    this.setValidity('login.email', 'unique', true);
    this.setValidity('login.email', 'exist', true);
    this.setValidity('login.email', 'domain', true);
    this.setValidity('login.email', 'email', true);
  }

  resetWhitelist() {
    this.whitelistChecked = false;
    this.whitelistDisabled = true;
    this.whitelistError = false;
    this.whitelistErrorMessage = '';
  }

  __loading(val) {
    this.$timeout(() => {
      this.loading = val;
    });
  }

  toggleNewsletterSubscription(allow_subscription) {
    if (allow_subscription) {
      this.newsletter_subscription.is_accepted = true;
      this.newsletter_subscription.is_rejected = false;
    } else {
      this.newsletter_subscription.is_accepted = false;
      this.newsletter_subscription.is_rejected = true;
    }
  }

  isNewsletterSubscriptionSelected() {
    return this.newsletter_subscription.is_accepted ||
      this.newsletter_subscription.is_rejected;
  }

  async checkEmailUniqueness() {
    const email = this.data.login.email;

    if (email === undefined) {
      this.setValidity('login.email', 'email', false);
      return false;
    }

    try {
      const isEmailUnique = await this.employeeService.checkEmployeeByEmail(email);
      if (isEmailUnique.exists) {
        this.setValidity('login.email', 'unique', false);
      } else {
        this.setValidity('login.email', 'unique', true);
      }
    } catch (err) {
      this.setValidity('login.email', 'email', false);
      this.error = true;
    }
  }

  async checkEmailDomain() {
    if (this.data.testclient) {
      this.setValidity('login.email', 'domain', true);
      return true;
    }

    const email = this.data.login.email;
    const config = this.$scope.configuration.domains_config;

    if (email === undefined) {
      this.setValidity('login.email', 'email', false);
      return false;
    }

    try {
      const isBaseOnDomain = await this.customerService.isEmployeeEmailDomainValid(email, config);
      if (isBaseOnDomain) {
        this.setValidity('login.email', 'domain', true);
      } else {
        this.setValidity('login.email', 'domain', false);
      }
    } catch (err) {
      this.setValidity('login.email', 'email', false);
      this.error = true;
    }
  }

  async checkPersonalNumber() {
    let location_id = this.data.loc_id;
    let personal_number = this.data.employeenumber;
    let whitelist = await this.Made.request('rpc://valuenetdb/customer/whitelist/get_whitelist_by_location_id_and_personal_number', { location_id, personal_number });
    this.whitelistChecked = true;
    this.whitelistDisabled = whitelist.disabled;
    this.whitelistError = whitelist.disabled;
    this.whitelistErrorMessage = whitelist.error_message;
    this.data.kostenstelle = whitelist.location;
  }

  canContinue() {
    return !this.loading && this.enteredCode;
  }

  canSave() {
    return !this.loading && this.forms.selfRegistrationForm.$valid && this.isNewsletterSubscriptionSelected() && this.whitelistChecked && !this.whitelistDisabled;
  }

  canCheckPersonalNumber() {
    return this.data.employeenumber !== undefined;
  }
  setValidity(inputName, paramName, paramValue) {
    const inputTarget = '$ctrl.data.' + inputName;
    this.forms.selfRegistrationForm[inputTarget].$setValidity(paramName, paramValue);
  }

}

RegistrationController.$inject = $inject;
