const $inject = [
  '$scope', 'BenefitBonagoService', '$timeout', '$state', 'customerService'
];
export default class CockpitBonagoController {
  constructor($scope, BenefitBonagoService, $timeout, $state, customerService) {
    Object.assign(this, {
      $scope, BenefitBonagoService, $timeout, $state, customerService
    });


    this.loading = true;
    this.init().then(() => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    });
  }

  async init() {
    this.customer_id = this.$state.params.customerId;
    this.forms = {};
    this.models = {};

    let promises = [];

    promises.push(
      this.customerService.getConfiguration(this.customer_id, Date.now()).then(configuration => {
        this.customer_configuration = configuration;
      })
    );

    promises.push(
      this.customerService.get(this.customer_id, Date.now()).then(customer => {
        this.customer = customer;
      })
    );

    promises.push(
      this.BenefitBonagoService.getAvailableSchemes(this.customer_id).then((bonago_schemes) => {
        this.bonago_schemes_map = bonago_schemes.reduce((acc, scheme) => {
          acc[scheme.scheme_id] = scheme;
          return acc;
        }, {});
        this.bonago_schemes = bonago_schemes;
      })
    );

    return Promise.all(promises);
  }

  removeScheme(scheme_id) {
    this.customer_configuration.bonago.schemes = this.customer_configuration.bonago.schemes.filter(scheme => scheme != scheme_id);
  }

  addNewScheme() {
    this.customer_configuration.bonago.schemes.push(this.models.new_scheme_id);
    this.models.new_scheme_id = undefined;
  }

  save() {
    this.loading = true;


    this.customerService.setConfiguration(this.customer_id, this.customer_configuration).then(() => {
      this.NotificationService.message('Speichern erfolgreich.');
    }).catch(() => {
      this.NotificationService.error('Speichern fehlgeschlagen.');
    }).finally(() => {
      this.$timeout(() => {
        this.loading = false;
      });
    });
  }
}
CockpitBonagoController.$inject = $inject;
