<h1>Bestellung anlegen</h1>

<mat-dialog-content>
  <p>Sie fügen eine Bestellung für die folgende Periode hinzu</p>

  <form [formGroup]="formGroup">
    <vn-date formControlName='newOrderDate'
            label="Neue Bestellung"
            key="vn.directBonus.backofficeAdministration.createOrderDialog.inputOrderDate"
            placeholder="DD.MM.JJJJ"
            (keyup)="checkDateValidity()">

      <mat-error *ngIf="orderDateControl.hasError('required')">
          Eingabe fehlt.
      </mat-error>

      <!-- #TRANSLATE -->
      <mat-error *ngIf="!orderDateControl.hasError('required') && orderDateControl.hasError('existingOrderValidation')">
        There is an already existing order
      </mat-error>
    </vn-date>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
          mat-dialog-close>
      Abbrechen
  </button>

  <button mat-flat-button
    (click)="createNewOrder()"
    [disabled]="formGroup.invalid"
  >
    Speichern
  </button>

</mat-dialog-actions>
