<mat-drawer-container>
    <mat-drawer #drawer>
        <mat-action-list (click)='closeDrawer(drawer)'>
            <a mat-list-item
               (click)='goHome()'>
                <mat-icon class='nav-icons material-icons-outlined'>
                    home
                </mat-icon>

                <span>
                    Startseite
                </span>
            </a>

            <a mat-list-item
               (click)='selectCategory()'
               [ngClass]="getCategoryClass()">
                <mat-icon class='nav-icons material-icons-outlined'>
                    label_important
                </mat-icon>

                <span>
                    Alle Kategorien
                </span>
            </a>

            <a *ngFor="let category of categories$ | async"
               [ngClass]="getCategoryClass(category)"
               data-cy="benefit.bonago.preCheckout.menuCategories.buttonPickCategory"
               mat-list-item
               (click)='selectCategory(category)'>
                <mat-icon class='nav-icons material-icons-outlined'>
                    label
                </mat-icon>

                <span>
                    {{category.name}}
                </span>
            </a>

            <a mat-list-item
               *ngIf="showMapLink$ | async"
               (click)='openMap()'>
                <mat-icon class='nav-icons material-icons-outlined'>
                    map
                </mat-icon>

                <span>
                    Zur Karte
                </span>
            </a>
        </mat-action-list>
    </mat-drawer>

    <mat-drawer-content>
        <div class='flex'>
            <vn-benefits-toolbar [retailerNames]="(retailerNames$ | async)!">
                <button class='menu-button'
                        mat-mini-fab
                        data-cy='benefit.bonago.preCheckout.toolbar.buttonCategories'
                        (click)="openDrawer(drawer)">
                    <mat-icon>
                        menu
                    </mat-icon>
                </button>
            </vn-benefits-toolbar>

            <div class="ui-view"
                 (window:resize)="onResize($event)"
                 [ngStyle]="getUiViewStyle()"
                 (click)="hideSearchInToolbar()">
                <ui-view>
                    <vn-benefits-home></vn-benefits-home>
                </ui-view>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
