// #COMMENT_LEX: I know this is redundant and we have to make sure it is in sync with the backend version. This is
// why the effort was made to change the way constants are handled and they are now imported into VSP_CONSTANTS with an
// initial server request. 
//
// However, this also introduces problems: In the JS world our IDE can't help us anymore. We don't get autocomplete
// we don't get hints when there are typos (is SIGNITURE a typo or is it spelled with two I on purpose?). Instead, we
// get an "unresolved variable" warning every time. We further lose the ability to quickly navigate to this list of
// all the different states (or view with Ctlr-Shift-I in IntelliJ when cursor is on NeoComponentState).
//
// On the other hand, there are very good reasons to not maintain too many constants twice. So I propose to only
// do it for this kind of magic numbers that are virtually impossible to change anyway (for what reason?). All the
// texts and the other stuff is still imported via VSP_CONSTANTS. Those can be rather seen as configuration that
// may change over time (e.g. text labels). This way we never have to change such a config setting twice. The only
// thing we have to do is to add new constants to the frontend as well when they are introduced. But in such a case we
// have to make lots of changes to the frontend anyway and it's unlikely that they get out of sync.
//
// It's not a perfect solution but I think it makes a real difference whether we write (and read!) for instance
// state == NeoComponentState.AWAITING_DOCUMENTS_GATHERING 
// or
// state == this.VSP_CONSTANTS.NEO_COMPONENT_STATE_BY_NAME['awaiting_documents_gather']['id']
// where the former comes with full IDE support. And in addition we don't have to pass around the VSP_CONSTANTS in 
// many places anymore and not have to inject it into virtually every class.

export let NeoComponentState = Object.freeze({
  'AVAILABLE': 0,
  'ACTIVE': 1,
  'EXPIRED': 2,
  'CHECK_OUT': 3,
  'AWAITING_BO_CONTROL': 4,
  'AWAITING_DOCUMENTS_GATHERING': 7,
  'AWAITING_ORDER_CONFIRMATION': 5,
  'AWAITING_APPROVAL_PROCESS': 6,
  'DECLINED': 8,
  'AUTO_DECLINED': 9
})
