import ComponentController from '../ComponentController';

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'mppService',
  'vbmService',
  'userService',
  'bikeleasingService'
];
export default class ComponentControllersachbezug extends ComponentController {

  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    mppService,
    vbmService,
    userService,
    bikeleasingService
  ) {

    super(
      $q,
      $injector,
      $scope,
      $state,
      $transitions,
      $location,
      Made,
      debounceFactory,
      vbmData,
      neoModifierService,
      customerService,
      PlausibilityService,
      dialogService,
      SliderOptionsConstants,
      componentsService,
      NotificationService,
      mppService,
      vbmService,
      userService,
      bikeleasingService
    );

    let employeeService = this.$injector.get('employeeService');

    employeeService
      .get(vbmData.employeeId)
      .then(user => this.user = user);
  }

  hasValuecard() {
    return this.configuration.neoChoices.sachbezug.list.includes('valuecard');
  }
}
ComponentControllersachbezug.$inject = $inject;
