import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'vn-back-link',
  templateUrl: './back-link.component.html'
})
export class BackLinkComponent {

  @Input()
  target: string = 'blank';

  constructor(
    @Inject('$state') private $state: any,
  ) {

  }

  public strings = {
    'carLeasingDetails.backLink.text': 'zurück'
  }
  
  public onClick() {
    this.$state.go(this.target);
  }
}
