import {BasketArticle} from './basket-article';

/**
 * An entry in the basket that consists of an article and a quantity.
 * 
 * The basic article data (articleNo, name, prices) has been intentionally extracted to a separate class, currently
 * BasketArticle. It could be a more general Article class or CatalogArticle in the future. However, if the article
 * data was mixed with the quantity in the BasketItem, such a generalization would not be possible anymore.
 *
 * Finally, the BasketItem maintains an "open" state that determines whether the item is expanded in the sidebar.
 * This shouldn't be done here but managed in the sidebar. However, it is another argument for separating this item
 * from the article master data. The open state clearly doesn't have anything to do with the rest of the article
 * attributes.
 */
export class BasketItem {

  /**
   * @param {BasketArticle} article
   * @param {int} quantity
   */
  constructor(article, quantity) {
    this._article = article;
    this._quantity = quantity;
  }

  /**
   * @param {BasketItem} other
   */
  equals(other) {
    return this.quantity === other.quantity && this._article.equals(other._article);
  }
  
  // #TODO_LEX: this should not be part of the model. It's the sidebar's business whether basket items are expanded or
  // not. Here we don't even know if the sidebar provides this feature.
  get open() {
    return this._open;
  }

  set open(open) {
    this._open = open;
  }

  get name() {
    return this._article.name;
  }

  get quantity() {
    return this._quantity;
  }

  set quantity(quantity) {
    this._quantity = quantity;
  }

  get price() {
    return this._article.grossPrice;
  }

  /**
   * @deprecated use articleNo instead. this is not really an id. there may be several basket items with the same
   * articleNo. Maybe not in the same basket but this id is not enough to really identify the basket item.
   */
  get id () {
    return this.articleNo;
  }
  
  get articleNo() {
    return this._article.articleNo;
  }
  
  get article() {
    return this._article;
  }
  
}
