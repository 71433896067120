import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectBonusFormData } from 'src/app/direct-bonus/models/direct-bonus-data.model';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';

// ===============================================================

interface FormChoices {
  comment: string;
  otrsTicket: string;
}

// ===============================================================

@Component({
  selector: 'vn-direct-bonus-cancel-dialog',
  templateUrl: './direct-bonus-cancel-dialog.component.html',
  styleUrls: ['./direct-bonus-cancel-dialog.component.scss']
})
export class DirectBonusCancelDialogComponent implements OnInit {

  public get orderId(): string {
    return this.dialogData.orderId!;
  }
  public formGroup!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private directBonusService: DirectBonusService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
    public dialogRef: MatDialogRef<DirectBonusCancelDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.initForms();
  }

  private initForms() {
    this.formGroup = this.formBuilder.group({
      comment: this.formBuilder.control(undefined, [Validators.required]),
      otrsTicket: this.formBuilder.control(undefined, [Validators.required]),
    });
  }

  public cancelOrder() {
    const {
      comment,
      otrsTicket,
    }: FormChoices = this.formGroup.value;

    const bonusData: DirectBonusFormData = {
      formReason: comment,
      otrsTicket
    };

    return this.dialogRef.close(bonusData);
  }
}
