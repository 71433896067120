const $inject = ['$q', '$rootScope', 'authenticationService'];
export default class AppCacheService {

  constructor( $q, $rootScope, authenticationService ) {

    this.$q                    = $q;
    this.$rootScope            = $rootScope;
    this.authenticationService = authenticationService;

    this.notification        = {};
    this.registered          = false;
    this.$rootScope.appCache = this.notification;

    this.notification.closeNotification = () => this.notification.notify = false;
    this.notification.reload            = () => window.location.reload();
  }


  register() {

    if ( !this.authenticationService.isLoggedIn() || !this.authenticationService.iAm( [
        'gadvisor',
        'advisor',
        'special_advisor'
      ] ) ) {
      return;
    }

    let appCache = window.applicationCache;

    if ( !this.registered && appCache ) {

      this.registered = true;

      appCache.addEventListener( 'progress', e => this.progress( e ), false );
      appCache.addEventListener( 'updateready', () => this.ready(), false );
      appCache.addEventListener( 'cached', () => this.done(), false );

    }
  }

  progress( e ) {
    this.$rootScope.$applyAsync( () => {
      this.notification.notify   = true;
      this.notification.progress = e.loaded / e.total * 100;
    } );
  }

  ready() {
    this.$rootScope.$applyAsync( () => {
      this.notification.notify = true;
      this.notification.ready  = true;
    } );
  }

  done() {
    this.$rootScope.$applyAsync( () => this.notification.notify = false );
  }

}
AppCacheService.$inject = $inject;
