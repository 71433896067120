const $inject = [
  '$scope',
  '$timeout',
  'mppService',
  'NotificationService'
];
export default class CancelOrderController {
  constructor(
    $scope,
    $timeout,
    mppService,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      mppService,
      NotificationService
    });

    this.loading = {
      main: false
    };

    this.contractID = '';

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.basket = this.$scope.ngDialogData.basket;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async cancelOrder() {
    this.$timeout(() => {
      this.loading.saving = true;
    });

    await this.mppService.cancelOrder({basket_id: this.basket._id}).then(() => {
      this.$scope.confirm();
    }).catch(() => {
      this.NotificationService.error('Fehlgeschlagen.');
    });

    this.$timeout(() => {
      this.loading.saving = false;
    });
  }
}
CancelOrderController.$inject = $inject;
