<h2 class='title'>
    Vorteilskonto Startseite
</h2>

<p class='text'>
    <span>
        Herzlich willkommen im Einlösebereich Ihres Vorteilskontos!
      Die hier aufgeführten Waren und Dienstleistungen sind Angebote der <b>BONAGO Incentive Marketing Group GmbH.</b> Für die Einlösebedingungen klicken Sie bitte
    </span>

    <vn-bonago-toc [readonlyDialog]="true"
                   linkText="hier.">
    </vn-bonago-toc>
</p>

<!-- <p class='text'>
    <i>INDIVIDUAL TEXT BLOCK - managed in Cockpit/Vorteilskonto</i>
</p> -->

<mat-card class='text'>
    Ihr aktuelles Guthaben beträgt <b>{{ userBalance$ | async }} EUR</b>.
</mat-card>

<h1 class='title'>
    Wie funktioniert das Vorteilskonto?
</h1>

<p class='text'>
    Um auf die Gutscheinangebote zugreifen zu können, klicken Sie bitte zunächst auf den <b>Menü-Button</b><mat-icon>menu</mat-icon> oben links. Hier haben Sie nun die Wahl, sich alle Gutscheinanbieter anzeigen zu lassen oder in den einzelnen Kategorien zu stöbern.
</p>

<p class='text'>
   Die einzelnen Gutscheinanbieter werden Ihnen nach Auswahl der Kategorie in Form von Kacheln angezeigt. Mit einem Klick auf den gewünschten Gutscheinanbieter erhalten Sie Informationen zu den einzelnen Gutscheinen und den jeweiligen Einlösebedingungen. <b>Bitte lesen Sie sich diese in jedem Fall durch, bevor Sie eine Bestellung aufgeben, da sich die Bedingungen für eine Einlösung von Anbieter zu Anbieter unterscheiden können.</b>
</p>

<p class='text'>
    In Ihrem Warenkorb <mat-icon>shopping_cart</mat-icon> finden Sie eine genaue Übersicht der aktuell hinzugefügten Gutscheine. Hier können Sie die finale Gutscheinbestellung nun abschließen.
 </p>
<p class='text'>
  Wir wünschen Ihnen viel Spaß beim Shoppen!
</p>
