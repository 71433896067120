import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { FeatureAComponent } from './components/feature-a/feature-a.component';
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {FEATURE_A_STATES} from "./components/feature-a/feature-a.states";

@NgModule({
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: FEATURE_A_STATES })
  ],
  declarations: [
    BlankPageComponent,
    FeatureAComponent
  ]
})
class ExamplesModule {}

export {ExamplesModule};
