import in_app_html from "../../modules/menu/menu.pug"

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    let authenticated = ['Made', '$location', function (Made, $location) {
      if (!Made.isLoggedIn()) throw {error: 'Not Logged In', current_absolute_url: $location.absUrl()};
    }];

    $stateProvider
      .state('inApp', {
        template: in_app_html,
        controller: 'MenuController',
        controllerAs: 'menu',
        resolve: {
          authenticated: authenticated
        },
        ncyBreadcrumb: {
          skip: true
        }
      });
  }]);
