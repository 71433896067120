import { Inject, Injectable } from "@angular/core";
import { Moment } from 'moment';
import { CustomerAdministrationAllowedOperations } from "./direct-bonus-allowed-operations.model";

export class DirectBonusProjectOverview {
  constructor(
    public bonusProjectId: string,
    public customerName: string,
    public customerId: number,
    public orderId: string | null,
    public orderStateDisplay: string,
    public orderDeadlineDate: Moment,
    public orderMonthDisplay: string,
    public allowedOperations: CustomerAdministrationAllowedOperations,
  ) { }

  getOrderDeadlineDayMonthDisplay() {
    return this.orderDeadlineDate.format('DD.MM');
  }

  getOrderDeadlineYearDisplay() {
    return this.orderDeadlineDate.format('YYYY');
  }
}

export interface BaseMapper { }

@Injectable()
export class DirectBonusProjectOverviewMapper implements BaseMapper {
  constructor(
    @Inject('moment') private moment: any,
  ) { }

  public fromApi(o: any): DirectBonusProjectOverview {

    let orderDeadlineDate = this.moment.utc(o['order_deadline_date'] * 1000);

    let canBulkAddUsers = o['allowed_operations']['can_bulk_add_users'];
    let canCreateExtraOrder = o['allowed_operations']['can_create_extra_order'];
    let canDownloadInvitationData = o['allowed_operations']['can_download_invitation_data'];
    

    return new DirectBonusProjectOverview(
      o['bonus_project_id'],
      o['customer_name'],
      o['customer_id'],
      o['order_id'],
      o['order_state_display'],
      orderDeadlineDate,
      o['order_month_display'],
      {
        canCreateExtraOrder,
        canBulkAddUsers,
        canDownloadInvitationData,
      }
    );
  }
}
