const $inject = [
    "cmsService",
    "$state",
    "$scope",
];

export default class CmsCategoryListController {

    constructor(
        cmsService,
        $state,
        $scope,
    ) {
        Object.assign(this, {
            cmsService, $state, $scope
        });

        this.custId = $state.params.custId;

        this.items = [];
        this.cmsService.globalCms.detailId = 0;
        this.loadList();
    }

    loadList() {
        this.cmsService.loadCategoryList(this.custId).then((items) => {
            this.items = items;
            this.cmsService.globalCms.loading = false;
        });
    }

    async deleteItem(category) {
        let reallyDelete = await confirm('Soll die Kategorie wirklich gelöscht werden?');

        if (reallyDelete) {
            this.cmsService.globalCms.loading = true;
            this.$scope.$applyAsync();
            await this.cmsService.deleteCmsCategory(category, this.custId);
            this.cmsService.globalCms.loading = false;
            this.$scope.$applyAsync();
            this.$state.reload();
        }
    }
}

CmsCategoryListController.$inject = $inject;