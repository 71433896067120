const $inject = [
  '$scope',
  '$timeout',
  'mppService'
];
export default class AddContractIDController {
  constructor(
    $scope,
    $timeout,
    mppService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      mppService
    });

    this.loading = {
      main: false
    };

    this.contractID = '';
    this.$scope.leasingRegex = /^\d+\/\d+$/;

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.basket = this.$scope.ngDialogData.basket;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async addContractID() {
    this.$timeout(() => {
      this.loading.saving = true;
    });

    try {
      await this.mppService.addContractID({contract_id: this.contractID, basket_id: this.basket._id});
    } catch (e) {
      console.log("error", e);
    } finally {
      this.$scope.confirm();
    }

    this.$timeout(() => {
      this.loading.saving = false;
    });
  }

}
AddContractIDController.$inject = $inject;
