import {BONUS_STATES_BY_ID, BONUS_STATES_BY_NAME} from "../../../services/bonus/bonus_state";
import {INTERNAL_USERS_BY_NAME} from "../../../services/bonus/internal_users";

const $inject = [
  '$scope',
  '$q',
  'lodash',
  'dialogService',
  'moment',
  'Made',
  '$timeout',
  '$window',
  'employeeService',
  'BonusService',
  'administrationService'
];
export default class BonusHistoryDialogController {
  constructor(
    $scope,
    $q,
    lodash,
    dialogService,
    moment,
    Made,
    $timeout,
    $window,
    employeeService,
    BonusService,
    administrationService
  ) {

    Object.assign(this, {
      $scope,
      $q,
      lodash,
      dialogService,
      moment,
      Made,
      $timeout,
      $window,
      employeeService,
      BonusService,
      administrationService
    });

    this.loading = true;

    this.BONUS_STATES_BY_ID = BONUS_STATES_BY_ID;
    this.BONUS_STATES_BY_NAME = BONUS_STATES_BY_NAME;

    this.bonus = this.$scope.ngDialogData.bonus;
    this.employee_id = this.bonus['employee'];

    this.bonus_history = [];

    let promises = [];

    promises.push(
      this.getBonusComponentsMap()
    );

    promises.push(
      this.getBonusChangeReasonsMap()
    );

    promises.push(
      this.administrationService.getEmployeePersonalInfo(this.employee_id).then(
        (res) => {
          this.employee = res;
        })
    );

    promises.push(
      this.getBonusHistory()
    );

    this.$q.all(promises).finally(
      () => {
        this.$timeout(() => {
          this.loading = false;
        });
      });
  }

  async getBonusHistory() {
    this.$timeout(()=>{
      this.loading = true;
    });

    let bonus_history = await this.BonusService.getBonusHistory({
      bonus_id: this.bonus['_id'],
      employee_id: this.employee_id,
      show_changer: true,
      with_parent: true,
      with_bonus_project_info: true
    });

    let bonus_history_per_project = bonus_history.reduce((acc, bonus_history_entry) => {
      if (!acc[bonus_history_entry['bonus_project']['_id']]) {
        acc[bonus_history_entry['bonus_project']['_id']] = {
          'bonus_project': bonus_history_entry['bonus_project'],
          'bonus_history': []
        };
      }

      acc[bonus_history_entry['bonus_project']['_id']]['bonus_history'].push(bonus_history_entry);
      return acc;
    }, {});

    this.$timeout(() => {
      this.bonus_history = bonus_history;
      this.bonus_history_per_project = bonus_history_per_project;
      this.loading = false;
    });
  }

  getComponentDisplayStartDate(bonus) {
    if (bonus) {
      return this.BonusService.getComponentDisplayStartDate(bonus);
    }
  }

  getChangerName(log) {

    let changer_id = log.changer_id;

    if (log['changer']) {
      return log.changer.firstname + ' ' + log.changer.lastname;
    } else if (INTERNAL_USERS_BY_NAME[changer_id]) {
      return INTERNAL_USERS_BY_NAME[changer_id]['display'];
    } else {
      return changer_id;
    }
  }

  getComponentDisplayEndDate(bonus) {
    if (bonus) {
      return this.BonusService.getComponentDisplayEndDate(bonus);
    }
  }

  async close() {
    this.$scope.confirm();
  }

  getBonusComponentsMap() {
    return this.BonusService.getAvailableBonusComponents().then(
      (res) => {
        this.BONUS_COMPONENTS_BY_NAME = res.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
      });
  }

  getBonusChangeReasonsMap() {
    return this.BonusService.getBonusChangeReasons().then(
      (res) => {
        this.BONUS_CHANGE_REASONS_BY_ID = res.reduce((acc, field) => {
          acc[field.id] = field;
          return acc;
        }, {});

        this.BONUS_CHANGE_REASONS_BY_NAME = res.reduce((acc, reason) => {
          acc[reason.name] = reason;
          return acc;
        }, {});
      });
  }
}
BonusHistoryDialogController.$inject = $inject;
