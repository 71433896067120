import './kinder/assistant/assistant.controller';
import './kinder/assistant/kinder.assistant.service';


import ComponentController from './ComponentController';
import ComponentControllerkinder from './kinder/ComponentControllerkinder';
import ComponentControllerentfernung from './entfernung/ComponentControllerentfernung';
import ComponentControlleressenscheck from './essenscheck/ComponentControlleressenscheck';
import ComponentControllersachbezug from './sachbezug/ComponentControllersachbezug';
import ComponentControllerpc from './pc/ComponentControllerpc';
import ComponentControllercar_leasing from './car_leasing/ComponentControllercar_leasing';
import ComponentControllerdticket from './dticket/ComponentControllerdticket';

import './wizards/CalculationComponentCheckoutWizard/CalculationComponentCheckoutBackLink';
import './wizards/CalculationComponentCheckoutWizard/CalculationComponentCheckoutStepIntro';
import './wizards/CalculationComponentCheckoutWizard/CalculationComponentCheckoutStepSummary';
import './wizards/CalculationComponentCheckoutWizard/CalculationComponentCheckoutStepValues';


angular
  .module( 'VSPApp' )
  .controller( 'ComponentController', ComponentController )
  .controller( 'ComponentControllerkinder', ComponentControllerkinder )
  .controller( 'ComponentControllerentfernung', ComponentControllerentfernung )
  .controller( 'ComponentControlleressenscheck', ComponentControlleressenscheck )
  .controller( 'ComponentControllersachbezug', ComponentControllersachbezug )
  .controller( 'ComponentControllerpc', ComponentControllerpc )
  .controller( 'ComponentControllercar_leasing', ComponentControllercar_leasing )
  .controller( 'ComponentControllerdticket', ComponentControllerdticket );
