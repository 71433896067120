import {DEFAULT_KIND, MONTHS_IN_YEAR} from '../../../../services/BonusService';
import {CHILD_TAX_CREDIT} from '../../../datev/default_data/data';
import {DEFAULT_CAL_POTENTIAL} from '../../../datev/DatevController';
import {DEFAULT_BONUS_PROJECT_BUDGET} from "../../../../services/bonus/default_bonus_project_budget";
import {OPTIMIZATION_ASSISTANT_STEPS} from "../../../../services/bonus/optimization_assistant_steps";
import {DEFAULT_ASSISTANT_STEPS} from "../../../../services/bonus/assistant_steps";

// const DEFAULT_ASSISTANT_ANSWERS = {
//   kinder: [],
//   Bikeleasing: {
//     enabled: false,
//     amount: 0
//   },
//   essensschecks_pst: {
//     out_of_office: false,
//     working_days: 0
//   },
//   erholungsbeihilfe: {
//     steuerklasse: undefined
//   },
//   entfernung: {
//     entfernung_transport_type: undefined
//   },
//   handy: {
//     amount: 0,
//     enabled: false
//   },
//   festnetz: {
//     amount: 0,
//     enabled: false
//   },
//   internet: {
//     enabled: false,
//     amount: 0
//   }
// };
const answers_reducer = (answers, step) => {
  answers[step.id] = {};
  step.options.forEach(option => {
    let root = answers[step.id];
    if (option.is_global) {
      if (!answers.global) {
        answers.global = {};
      }
      root = answers.global;
    }

    if (option.parent) {
      root[option.parent] = Object.assign({}, root[option.parent]);
      root[option.parent][option.key] = option.default;
    } else {
      root[option.key] = option.default;
    }
  });
  return answers;
};

const DEFAULT_ASSISTANT_ANSWERS = DEFAULT_ASSISTANT_STEPS.reduce(answers_reducer, {});

const DEFAULT_OPTIMIZATION_ANSWERS = OPTIMIZATION_ASSISTANT_STEPS.reduce(answers_reducer, {});

const $inject = [
  '$scope',
  'lodash',
  'vbmData',
  'BonusService',
  '$q',
  '$timeout',
  'SteuerklassenConstants',
  'KVTypConstants',
  'SliderOptionsConstants',
  'WizardHandler',
  'CacheService',
  'datevCalculatorService',
  'Made'
];

export default class bonusAssistantDialogController {
  constructor(
    $scope,
    lodash,
    vbmData,
    BonusService,
    $q,
    $timeout,
    SteuerklassenConstants,
    KVTypConstants,
    SliderOptionsConstants,
    WizardHandler,
    CacheService,
    datevCalculatorService,
    Made
  ) {
    Object.assign(this, {
      $scope,
      lodash,
      vbmData,
      BonusService,
      $q,
      $timeout,
      SteuerklassenConstants,
      KVTypConstants,
      SliderOptionsConstants,
      WizardHandler,
      CacheService,
      datevCalculatorService,
      Made
    });

    this.bonus_checkout_cache = CacheService.getDictionaryStore('bonus_checkout');
    this.bonus_checkout_cache.is_bonus_assistant_seen = true;

    this.slider_options = Object.assign({}, SliderOptionsConstants, {
      from: 1,
      to: 10,
      step: 1
    });
    this.slider_value = 1;

    this.init().then(() => {
      let watcher = this.$scope.$watch(() => {
        return this.employee_bonus_config['bonus_assistant'].do_not_show;
      }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.bonus_project.for_employees[this.employee_id] = this.employee_bonus_config;
          this.BonusService.updateBonusProject(this.bonus_project);
        }
      });

      this.$scope.$on('$destroy', () => {
        watcher();
      });
    });
  }

  async init() {
    this.recommendedComponents = [];
    this.availableComponents = [];
    this.default_kid = angular.copy(DEFAULT_KIND);
    this.CHILD_TAX_CREDIT = CHILD_TAX_CREDIT;

    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.steps_ready = false;
    this.forms = {};
    this.AvailableWorkingDays = [
      {
        id: 1,
        display: 1
      },
      {
        id: 2,
        display: 2
      },
      {
        id: 3,
        display: 3
      },
      {
        id: 4,
        display: 4
      },
      {
        id: 5,
        display: 5
      },
    ];

    this.employee = this.vbmData.employees[this.employee_id];
    this.customer_id = this.$scope.ngDialogData.customer_id;
    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;

    if (!this.employee.bonus) {
      this.BonusService.createBonusObject(this.employee, this.bonus_project_id);
    }
    this.employee_bonus_checkout_config = this.employee.bonus[this.bonus_project_id];
    this.employee_bonus_checkout_config.assistent = {};
    this.assitant_model = this.employee_bonus_checkout_config.assistent;

    this.sliders = {};

    let promises = {};
    promises.bonuses = this.BonusService.getBonusesForEmployee({
      customer_id: this.customer_id,
      bonus_project_id: this.bonus_project_id,
      employee_id: this.employee_id,
      to_attach_checkout_validations: true
    }).then(bonuses => {
      this.bonuses = bonuses;
      this.bonuses_by_name = this.bonuses.reduce((acc, bonus) => {
        acc[this.BonusService.getBonusComponentName(bonus)] = bonus;
        return acc;
      }, {});
      this.available_bonus_components_by_priority = this.bonuses
        .reduce((acc, bonus) => {
          // if (angular.equals(bonus.state, BONUS_STATES_BY_NAME['available']['id'])) {
          if (!this.BonusService.isComponentUsed(bonus) && this.BonusService.isComponentCheckoutAllowed(bonus)) {
            acc.push(bonus);
          }
          return acc;
        }, [])
        .sort((bonus_1, bonus_2) => {
          let bonus_1_component_priority = bonus_1.component[this.BonusService.getBonusComponentName(bonus_1)].priority;
          let bonus_2_component_priority = bonus_2.component[this.BonusService.getBonusComponentName(bonus_2)].priority;
          return bonus_1_component_priority - bonus_2_component_priority;
        })
        .map(bonus => {
          return this.BonusService.getBonusComponentName(bonus);
        });
      // this.steps = this.filterValidSteps(angular.copy(DEFAULT_ASSISTANT_STEPS), bonuses);
      // this.setSliderOptions(this.steps);
    });

    promises.bonus_project_promise = this.BonusService.getBonusProject(this.customer_id, this.bonus_project_id).then((bonus_project) => {
      this.bonus_project = bonus_project;
      this.employee_bonus_config = bonus_project.for_employees[this.employee_id];
      this.bonus_project_duration_in_months = this.BonusService.calculateBonusProjectDurationInMonths(this.bonus_project);
      this.setBonusAssistantObject();
    });

    return this.$q.all(promises);
  }

  async onAssistantFinish() {
    this.assistantFinishing = true;
    if (this.assitant_model.is_optimized) {
      // add components in correct order
      let potential = angular.copy(DEFAULT_CAL_POTENTIAL);
      potential.direct = angular.copy(DEFAULT_BONUS_PROJECT_BUDGET);

      let set_of_components_without_questions = new Set(this.available_bonus_components_by_priority);
      this.steps.forEach(step => {
        if (step.components) {
          step.components.forEach(component_name => {
            set_of_components_without_questions.delete(component_name);
          });
        }
      });

      // add components which are already checkout to the optimization
      let checkout_config_components = this.BonusService.getComponentsCheckout(this.employee, this.bonus_project_id);
      for (const component_name in checkout_config_components) {
        let component_checkout_config = checkout_config_components[component_name];

        potential.direct[component_name].active = true;
        potential.direct[component_name].result = component_checkout_config.value;
      }

      // add new components
      for (const component_name of this.available_bonus_components_by_priority) {

        // 1. check if component is good
        if (set_of_components_without_questions.has(component_name)) {
          // no questions for this => assume good
        } else if (!this.assitant_model.optimization.selected_components[component_name]) {
          // has not been selected in assistant
          continue;
        }

        if (!(await this.BonusService.isBonusComponentManagedByValuenet(component_name))) {
          // is not managed by Valuenet
          continue;
        }

        // for components which do not have questions and thus are not added by the assistant
        let step = {};
        let answer = {};
        if (this.assitant_model.optimization.selected_components[component_name]) {
          step = this.assitant_model.optimization.selected_components[component_name].step;
          answer = this.assitant_model.optimization.selected_components[component_name].answer;
        }
        await this.calculateComponentValueForAnswer(component_name, answer, step, true);
        let added_to_checkout = await this.addComponentToCheckout(component_name, answer);

        if (added_to_checkout) {
          potential.direct[component_name].active = true;
          potential.direct[component_name].result = answer.value;
        }
      }

      // do optimization
      potential.brutto = parseInt(this.answers.global.brutto);
      potential.child_tax_credit = this.answers.global.kinderfreibetrag;
      potential.health_insurance = 0;
      if (potential.health_insurance === 1) {
        potential.monthly_contribution = 1;
      }
      potential.pap_r = this.answers.global.has_church_tax;
      potential.pap_stkl = this.answers.global.steuer_klasse;


      let optimization_result = this.datevCalculatorService.calculateForPotential(potential);
      this.employee_bonus_checkout_config.optimization = {
        result: optimization_result,
        potential: potential
      };
      this.assistantFinishing = false;
      this.$scope.closeThisDialog(true);
    } else {
      this.assistantFinishing = false;
      this.$scope.closeThisDialog(true);
    }
  }

  setBonusAssistantObject() {
    if (!this.employee_bonus_config.bonus_assistant) {
      this.employee_bonus_config.bonus_assistant = {
        do_not_show: false
      };
      this.bonus_project.for_employees[this.employee_id] = this.employee_bonus_config;
      this.BonusService.updateBonusProject(this.bonus_project);
    }
  }

  addKid() {
    this.answers.kinder.push(angular.copy(this.default_kid));
  }


  filterValidSteps(steps, bonuses) {
    let valid_steps = [];
    steps.forEach(step => {
      if (step.components) {
        step.components.forEach(component_name => {
          if (this.isBonusComponentAvailable(component_name)) {
            valid_steps.push(step);
          }
        });
      } else {
        // no components => information step
        valid_steps.push(step);
      }
    });
    return valid_steps;
  }

  showNextQuestion(step) {
    this.addRecommendedComponents(step).then(() => {
      this.WizardHandler.wizard().next();
    });
  }

  async addRecommendedComponents(step) {
    if (!step.components) {
      // nothing to add
      return;
    }

    // safety - if someone forgets to select proper validations/control types
    if (this.lodash.isString(this.answers[step.id].value)) {
      this.answers[step.id].value = parseInt(this.answers[step.id].value);
    }

    let answer = this.answers[step.id];
    let global_do_not_add = this.lodash.get(step, 'components_settings.do_not_add', false);

    if (global_do_not_add) {
      // nothing to do
      return;
    }

    for (const component_name of step.components) {

      let component_do_not_add = this.lodash.get(step, `components_settings['${component_name}'].do_not_add`, false);

      if (component_do_not_add || !this.isAnswerValid(step) || !this.isBonusComponentAvailable(component_name)) {
        // do nothing
      } else if (this.assitant_model.is_optimized) {
        // optimization on => note which step and answer added this componet
        if (!this.assitant_model.optimization) {
          this.assitant_model.optimization = {};
        }

        if (!this.assitant_model.optimization.selected_components) {
          this.assitant_model.optimization.selected_components = {};
        }

        this.assitant_model.optimization.selected_components[component_name] = { answer: answer, step: step };
      } else {
        // just add the component to the checkout

        // calculate value
        await this.calculateComponentValueForAnswer(component_name, answer, step);

        // add to checkout
        await this.addComponentToCheckout(component_name, answer);
      }
    }
  }

  async addComponentToCheckout(component_name, answer) {

    // check if to add is appropriate even if the answer is valid
    let is_disabled = typeof answer.is_disabled === 'undefined' ? false : answer.is_disabled;
    let is_enabled = typeof answer.is_enabled === 'undefined' ? true : answer.is_enabled;

    let rest_bonus = this.BonusService.calculateRestBonus(this.employee_bonus_checkout_config, this.bonus_project.for_employees[this.employee_id].bonus.amount);

    // if component was rejected, note that infoq
    if (!this.assitant_model.rejected_components) {
      this.assitant_model.rejected_components = {};
    }

    if (is_disabled || !is_enabled) {
      this.assitant_model.rejected_components[component_name] = { answer: answer };
    }

    if (is_disabled || !is_enabled || !this.isAnswerValid(undefined, answer) || (rest_bonus - answer.value) < 0) {
      return false;
    }

    // remove from rejected
    delete this.assitant_model.rejected_components[component_name];

    // add to checkout
    let checkout_config = await this.returnCheckoutConfig(component_name, answer);
    await this.BonusService.addComponentToCheckout(this.employee, this.bonus_project_id, component_name, checkout_config);

    return true;
  }

  async calculateComponentValueForAnswer(component_name, answer, step, is_optimization = false) {
    let component_budget_config = this.bonus_project.budget_config[component_name];
    let checkout_config_components = this.employee_bonus_checkout_config.components;
    try {
      switch (component_name) {
        case 'kinder': {
          // 1 time payment
          break;
        }
        case 'erholungsbeihilfe': {
          let is_ehe_allowed = this.BonusService.is_ehe_allowed(this.answers.global.steuer_klasse);
          if (!is_ehe_allowed) {
            answer.value = 156;
          } else {
            answer.value = 260;
          }
          answer.extras = {
            steuer_class: this.answers.global.steuer_klasse,
            ehe_enabled: is_ehe_allowed
          };
          break;
        }
        case 'festnetz':
        case 'handy': {
          let bonus_amount = this.bonus_project.for_employees[this.employee_id].bonus.amount;
          let remaining_amount = this.BonusService.calculateRemainingWithoutCurrentBonus(
            component_name,
            checkout_config_components,
            bonus_amount
          );
          // let max_values = await this.BonusService.getMaxValues(component_name, new Date().getUTCFullYear());
          // let max_working_days = max_values[component_name];
          let per_month_value = answer.advantage;
          let max_months = Math.floor(remaining_amount / per_month_value);
          max_months = Math.max(Math.min(max_months, this.bonus_project_duration_in_months, MONTHS_IN_YEAR), 0); //max_months has to be 0 or more
          answer.value = per_month_value * max_months;
          answer.value = this.addAdditionalCosts(component_name, answer);
          answer.extras = {
            per_month_value: per_month_value,
            advantage: per_month_value,
            max_advantage_value: per_month_value,
            months: max_months,
            max_months: max_months
          };
          break;
        }
        case 'enfernung':
          if (!this.answers.essensschecks_pst.out_of_office) {
            answer.value = this.bonuses_by_name[component_name].component.essensschecks_pst.per_item_price * this.answers.essensschecks_pst.working_days;
          } else {
            return;
          }
          break;
        case 'essensschecks_pst':
        case 'essensschecks_frei': {
          let bonus_amount = this.bonus_project.for_employees[this.employee_id].bonus.amount;
          let remaining_amount = this.BonusService.calculateRemainingWithoutCurrentBonus(
            component_name,
            checkout_config_components,
            bonus_amount
          );
          let max_values = await this.BonusService.getMaxValues(component_name, new Date().getUTCFullYear());
          let max_working_days = max_values[component_name];
          let working_days = step.calculation_settings.variables.working_days.is_global ? this.answers.global[step.calculation_settings.variables.working_days.key] : this.answers[step.calculation_settings.variables.working_days.step][step.calculation_settings.variables.working_days.key];
          let per_month_value = component_budget_config.per_item_price * (component_budget_config.value / max_working_days) * working_days;
          let max_months = Math.floor(remaining_amount / per_month_value);
          max_months = Math.max(Math.min(max_months, this.bonus_project_duration_in_months, MONTHS_IN_YEAR), 0); //max_months has to be 0 or more
          answer.value = per_month_value * max_months;
          answer.extras = {
            per_month_value: per_month_value,
            months: max_months,
            max_months: max_months,
            working_days: working_days,
            max_working_days: max_working_days
          };
          break;
        }
        case 'Freistellungstag': {
          let user_fid_type = this.BonusService.getUserFidInfoType();
          answer.value = component_budget_config.options[user_fid_type['id']];
          break;
        }
        default:
          if (typeof answer.value === 'undefined') {
            let bonus_amount = this.bonus_project.for_employees[this.employee_id].bonus.amount;
            let remaining_amount = this.BonusService.calculateRemainingWithoutCurrentBonus(
              component_name,
              checkout_config_components,
              bonus_amount
            );
            let per_month_value = component_budget_config.value;
            let max_months = Math.floor(remaining_amount / per_month_value);
            max_months = Math.max(Math.min(max_months, this.bonus_project_duration_in_months, MONTHS_IN_YEAR), 0); //max_months has to be 0 or more
            answer.value = per_month_value * max_months;

          }
      }

      if (is_optimization) {
        answer.extras = Object.assign({}, answer.extras, { is_optimized: true });
      }

    } catch (e) {
      // indicate error
      answer.value = undefined;
    }
  }

  addAdditionalCosts(component_name, answer) {
    if ((answer.value > 0) && this.bonuses_by_name[component_name].component[component_name].additional_costs && this.bonuses_by_name[component_name].component[component_name].additional_costs.valuenet_tax.is_enabled) {
      answer.value += this.bonuses_by_name[component_name].component[component_name].additional_costs.valuenet_tax.value;
    }
    return answer.value;
  }

  isBonusComponentAvailable(component_name) {
    return this.available_bonus_components_by_priority.includes(component_name);
    // return this.bonuses_by_name[component_name] && this.bonuses_by_name[component_name].state === BONUS_STATES_BY_NAME['available']['id'];
  }

  declineOptimization() {
    this.answers = angular.copy(DEFAULT_ASSISTANT_ANSWERS);
    this.steps = this.filterValidSteps(angular.copy(DEFAULT_ASSISTANT_STEPS), this.bonuses);

    this.assitant_model.answers = this.answers;
    this.assitant_model.steps = this.steps;
    this.assitant_model.is_optimized = false;
    this.steps_ready = true;
    this.$timeout(() => {
      this.WizardHandler.wizard().next();
    }, 0);
  }

  acceptOptimization() {
    // TODO: when he opens the dialog again his prev answers should be remembered
    this.answers = angular.copy(DEFAULT_OPTIMIZATION_ANSWERS);
    this.steps = this.filterValidSteps(angular.copy(OPTIMIZATION_ASSISTANT_STEPS), this.bonuses);

    this.assitant_model.answers = this.answers;
    this.assitant_model.steps = this.steps;
    this.assitant_model.is_optimized = true;
    this.steps_ready = true;
    this.$timeout(() => {
      this.WizardHandler.wizard().next();
    }, 0);
  }

  isAnswerValid(step, answer) {

    // handle global options
    let is_global_valid = true;
    if (step) {
      for (const option of step.options || []) {
        if (option.is_global) {
          let is_null = this.answers.global[option.key] === null;
          let is_undefined = this.answers.global[option.key] === undefined;
          is_global_valid = is_global_valid && !(is_null || is_undefined);
        }
      }
    }

    // handle local options
    if (!answer) {
      answer = this.answers[step.id];
    }
    let is_control_by_disable = answer.is_disabled !== undefined;
    let is_control_by_enable = answer.is_enabled !== undefined;
    let has_value = answer.value !== undefined;

    let is_value_correct = has_value ? answer.value > 0 : true;
    let is_other_settings_valid = Object.keys(answer).reduce((acc, prop) => {
      if (!['is_disabled', 'is_enabled', 'value', 'extras'].includes(prop)) {
        let is_null = answer[prop] === null;
        let is_undefined = answer[prop] === undefined;
        acc = acc && !(is_null || is_undefined);
      }
      return acc;
    }, true);
    let are_value_and_settings_correct = is_value_correct && is_other_settings_valid && is_global_valid;

    if (is_control_by_disable) {
      return answer.is_disabled ? true : are_value_and_settings_correct;
    } else if (is_control_by_enable) {
      return answer.is_enabled ? are_value_and_settings_correct : true;
    }

    // default
    return are_value_and_settings_correct;
  }

  async returnCheckoutConfig(component_name, answer) {
    let display = (await this.BonusService.getComponentByName(component_name))['display']['title'];
    let checkout_config = await this.BonusService.createCheckoutConfig(answer.value, display, component_name, this.bonuses_by_name[component_name]['_id'], this.bonuses_by_name[component_name]['state']);

    Object.assign(checkout_config, answer.extras);

    return checkout_config;
  }

  // essenschecks = !this.bonuses_by_name[component_name].component.essensschecks_pst.per_item_price
}

bonusAssistantDialogController.$inject = $inject;
