import {
  APPROVAL_PROCESS_TYPES_BY_NAME,
  EMPLOYEE_ADMINISTRATION_DEFAULT_FILTERS,
  APPROVAL_PROCESS_FILTER_CONFIGURATION,
  NEO_PC_APPROVAL_PROCESS_STATE_FILTER_CONFIGURATION,
  APPROVAL_PROCESS_FLOWS_BY_NAME,
  APPROVAL_PROCESS_STATES_BY_ID,
  APPROVAL_PROCESS_STATES, APPROVAL_PROCESS_STATES_BY_NAME, APPROVAL_PROCESS_FLOWS
} from '../../services/administrationService';
import {
  NEO_BIKELEASING_FILTER_CONFIGURATION,
  NEO_BIKELEASING_FLOWS_BY_NAME,
  NEO_BIKELEASING_STATES_BY_ID,
} from '../../services/bikeleasingService';

import decline_approval_process_html from '../../dialogs/decline_approval_process/decline_approval_process.pug';
import bikeleasing_approve_html from '../../modules/administration/bikeleasing_control_process/bikeleasing_approve.pug';
import bike_edit_dialog_html from '../../modules/administration/bike_approval/bike_edit/bike_edit_dialog.pug'


const $inject = [
  '$scope',
  '$state',
  '$q',
  'lodash',
  'authenticationService',
  'employeeService',
  'administrationService',
  'mppService',
  'dialogService',
  'userService',
  '$timeout',
  'NotificationService',
  'Made'
];

export class EmployeeFiltersComponent {
  constructor(
    $scope,
    $state,
    $q,
    lodash,
    authenticationService,
    employeeService,
    administrationService,
    mppService,
    dialogService,
    userService,
    $timeout,
    NotificationService,
    Made
  ) {
    Object.assign(this, {
      $scope,
      $state,
      lodash,
      $q,
      authenticationService,
      mppService,
      employeeService,
      administrationService,
      dialogService,
      userService,
      $timeout,
      NotificationService,
      Made
    });

    let promises = {};
    promises['orderStatuses'] = this.mppService.getOrderStatuses();

    const contractId = this.$state.router.locationService.search().contractId

    this.init()
      .then(() => this.$q.all(promises))
      .then(result => {

        this.to_show_bike_approval = this.lodash.get(this.options, 'to_show_bike_approval', false);
        this.to_show_order_process_filter = this.lodash.get(this.options, 'to_show_order_process_filter', false);
        this.to_show_neo_pc_state_filter = this.lodash.get(this.options, 'to_show_neo_pc_state_filter', false);
        this.to_show_pc_approval = this.lodash.get(this.options, 'to_show_pc_approval', false);
        this.is_only_controls = this.lodash.get(this.options, 'only_controls', false);

        let filters_config = this.lodash.merge(
          {
            contract_id: !!contractId ? contractId : ''
          },
          EMPLOYEE_ADMINISTRATION_DEFAULT_FILTERS,
          NEO_PC_APPROVAL_PROCESS_STATE_FILTER_CONFIGURATION,
          APPROVAL_PROCESS_FILTER_CONFIGURATION,
          NEO_BIKELEASING_FILTER_CONFIGURATION
        );
        result.orderStatuses.shift();

        if (this.to_show_order_process_filter) {
          filters_config['order_process_states'] = {
            selected: [],
            options: result.orderStatuses
          };
        }

        this.$scope.filters = filters_config;
        this.ORDER_STATUSES_BY_ID = result.orderStatuses.reduce((acc, orderStatus) => {
          acc[orderStatus.id] = orderStatus;
          return acc;
        }, {});

        this.$scope.APPROVAL_PROCESS_FLOWS_BY_NAME = APPROVAL_PROCESS_FLOWS_BY_NAME;

      }).finally(() => {
      this.loading = {
        main: false,
      };

      if (!!contractId) {
        this.search()
      }
    });
  }

  async init() {
    this.loading = {
      main: true,
    };
    this.approveBasket = {};
    this.employees = [];
    this.filters_wrapper = this.filtersWrapper;
    this.APPROVAL_PROCESS_STATES_BY_ID = APPROVAL_PROCESS_STATES_BY_ID;
    this.APPROVAL_PROCESS_STATES = APPROVAL_PROCESS_STATES;
    this.APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES_BY_NAME;
    this.NEO_BIKELEASING_STATES_BY_ID = NEO_BIKELEASING_STATES_BY_ID;
  }

  triggerBasketApprove(basketId) {
    this.$timeout(() => {
      this.loading.main = true;
    });
    // this.approveBasket[basketId] = !this.approveBasket[basketId];
    this.mppService.approveMppBasket(basketId, this.approveBasket[basketId]);
    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  getSelectedFilters() {
    let filters = {
      id: this.$scope.filters.id,
      name: this.$scope.filters.name,
      personal_number: this.lodash.get(this.$scope.filters, 'personal_number', ''),
      contract_id: this.lodash.get(this.$scope.filters, 'contract_id', ''),
      order_by_name: !!this.$scope.filters.order_by_name ? 'DESC' : 'ASC',
      enable_timestamp: false,
      approval_state: this.lodash.get(this.$scope.filters, 'approval_state.selected', []),
      fahrrad_beginn: this.lodash.get(this.$scope.filters, 'fahrrad_beginn', ''),
      bikeleasing_state: this.lodash.get(this.$scope.filters, 'bikeleasing_state.selected', []),
      neo_pc_state: this.lodash.get(this.$scope.filters, 'neo_pc_state.selected', []),
      order_process_states: this.lodash.get(this.$scope.filters, 'order_process_states.selected', [])
    };

    this.bike_leasing = this.lodash.get(this.options, 'bike_leasing', false);

    // neo_bike_approval logic
    if (this.to_show_bike_approval) {
      filters['type_id'] = this.APPROVAL_PROCESS_TYPES_BY_NAME['neo_bikeleasing']['id'];
    } else if (this.to_show_pc_approval) {
      filters['type_id'] = this.APPROVAL_PROCESS_TYPES_BY_NAME['neo_pc']['id'];
    }

    //customer id/name logic
    let is_cust_id = !isNaN(this.$scope.filters.custId);
    if (is_cust_id) {
      filters.cust_id = this.$scope.filters.custId;
      filters.customer_name = '';
    } else {
      filters.cust_id = '';
      filters.customer_name = this.$scope.filters.custId;
    }

    return filters;
  }

  areFiltersEmpty(filters) {
    let user_entered_filter_keys = [
      'id',
      'cust_id',
      'customer_name',
      'name',
      'personal_number',
      'contract_id',
      'approval_state',
      'fahrrad_beginn',
      'bikeleasing_state',
      'neo_pc_state',
      'order_process_states'
    ];

    let are_filters_empty = true;

    user_entered_filter_keys.forEach((key) => {
      if ((this.lodash.get(filters, key, '').length > 0)) {
        are_filters_empty = false;
      }
    });

    return are_filters_empty;
  }

  search() {
    this.filters = this.getSelectedFilters();

    if (this.filters_wrapper) {
      this.filters_wrapper.filters = this.filters;
    }

    if (!this.areFiltersEmpty(this.filters)) {
      this.filterEmployees();
    } else {
      this.grouped_employees = [];
    }
  }

  filterEmployees() {
    if (this.is_only_controls) {
      return;
    }

    this.loading.main = true;

    this.employeeService.getEmployeesWithApprovals(this.filters)
      .then((employees) => {
        let approvals_key;
        if (this.to_show_bike_approval) {
          approvals_key = 'bikeleasing_approvals';
        } else if (this.to_show_pc_approval) {
          approvals_key = 'pc_approvals';
        } else {
          throw 'not implemented yet';
        }

        for (let employee of employees) {
          employee[approvals_key] = employee['approvals'];
        }

        this.employeeIds = this.employeeService.getEmployeeIds(employees);
        this.employees = employees;
        this.employee_objects = {};
        this.employees.forEach((employee) => {
          this.employee_objects[employee.id] = {};
        });
        this.grouped_employees = this.employeeService.groupEmployeesByCustomerId(this.employees);
        this.customers_by_id = this.employeeService.indexCustomers(this.employees);
      })
      .then(() => this.administrationService.getAllLockedEmployees(this.employeeIds))
      .then(result => this.lockedEmployees = result)
      .finally(() => {
        this.loading.main = false;
      });
  }

  openPCApproveDialog(approval) {
    this.administrationService.approvalInternalApprove({process_id: approval['_id']}).finally(() => {
      this.search();
    });
  }

  canCommunicate(approval) {
    return approval.state_id === APPROVAL_PROCESS_STATES_BY_NAME['waiting_external_approval']['id'] && this.Made.user.valuenet_id === 66279;
  }

  async resendCommunicationMails(approval) {
    this.$timeout(() => {
      this.loading.resend_communication = true;
    });

    this.communication_result = await this.administrationService.createApprovalProcessNotificationAndEmail({
      approval_process_notification_name: APPROVAL_PROCESS_STATES_BY_ID[approval.state_id]['name'],
      approval_process_id: approval._id,
    });

    this.NotificationService.message('Erfolgreich');
  }

  // deprecated
  openPCDeclineDialog(approval, employee_id) {
    this.dialogService.ngDialog.openConfirm({
      template: decline_approval_process_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'DeclineApprovalProcessController',
      data: {
        process_id: approval._id,
        employee_id: employee_id,
        state_id: approval.state_id
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).finally(() => {
      this.search();
    });

  }

  openBikeleasingApproveDialog(approval) {
    this.dialogService.ngDialog.openConfirm({
      template: bikeleasing_approve_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'NeoBikeleasingApproveController',
      data: {
        employee_id: approval.neo_bikeleasing.employee_id,
        approval: approval
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).finally(() => {
      this.search();
    });
  }

  canApproveNeoBikeleasing(approval) {
    return approval.neo_bikeleasing.validations.flows[NEO_BIKELEASING_FLOWS_BY_NAME['approve']['name']].can_enter;
  }

  become(employeeData) {

    this.loading.main = true;
    const context_path = '/administration/fahrrad';

    this.dialogService
      .question(`Achtung, der Nutzer wird über diesen Vorgang informiert.
        Wollen Sie wirklich in ${employeeData.name}, ${employeeData.vorname}
        (${employeeData.id}) schlüpfen?`)
      .then(() => this.userService.enterProxy(employeeData.id, context_path))
      .catch(() => this.loading.main = false);

  }

  openBikeleasingEditDialog(approval) {
    this.dialogService.ngDialog.openConfirm({
      template: bike_edit_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BikeEditController',
      data: {
        employee_id: approval.neo_bikeleasing.employee_id,
        approval: approval
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    }).catch(() => {
      this.search();
    });
  }
}

EmployeeFiltersComponent.$inject = $inject;

import employee_filters_ctrl_html from './employee_filters_component.pug';

angular
  .module('VSPApp')
  .controller('EmployeeFiltersComponent', EmployeeFiltersComponent)
  .component('employeeFilters', {
    controller: 'EmployeeFiltersComponent',
    controllerAs: 'EmployeeFiltersCtrl',
    template: employee_filters_ctrl_html(),
    bindings: {
      options: '<',
      filtersWrapper: '='
    }
  });
