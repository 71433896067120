import { Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: '[vnLoader]',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderDirective implements OnChanges, OnInit {
  private _setShowLoader: boolean = false;

  @HostBinding('style.pointer-events')
  public get pEvents(): string {
    if (this._setShowLoader || this.showLoader) {
      return 'none';
    }
    return 'auto';
  }

  public get toShowLoader() {
    return this._setShowLoader || this.showLoader;
  }

  @Input()
  public showLoader!: boolean;

  @Input()
  public set setShowLoader(value: boolean) {
    if (value !== undefined) {
      this._setShowLoader = value;
    }
  }

  constructor(
    private elem: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showLoader) {
      console.log('LoaderComponent ngOnChanges: ', changes.showLoader.currentValue);
    }
  }

  ngOnInit(): void {}

}
