import {Injectable} from '@angular/core';
import {Car} from "../../models/car.model";

@Injectable()
export class FinnApiCarMapper {

  public fromApi(o: any): Car {
    return new Car(
      o['id'],
      o['uid'],
      o['offer_type'],
      o['brand'],
      o['efficiency_image'],
      o['picture'],
      o['pictures'],
      o['model'],
      o['engine'],
      o['price'],
      o['logo'],
      o['available_terms'],
      o['color'],
      o['color_list'],
      o['config'],
      o['specific_color'],
      o['power'],
      o['fuel'],
      o['consumption'],
      o['co2emission'],
      o['equipment'],
      o['gearshift'],
      o['cartype'],
      o['config_drive'],
      o['available_from'],
      o['available_to'],
      o['description'],
      o['equipment_line'],
      o['financing_type'],
      o['ev_range'],
      o['has_hitch'],
      o['tires'],
      o['seats'],
      o['doors'],
      o['model_year'],
    );
  }

}
