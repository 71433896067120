import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { fromEvent, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BenefitsBonagoBasketService } from 'src/app/direct-bonus/services/benefits-bonago-basket.service';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';
import { InputComponent } from 'src/app/shared/components/form-mixins/input/input.component';
import { BenefitBasketSheetComponent } from '../benefit-basket-sheet/benefit-basket-sheet.component';
import {BenefitToolbarEventService} from "../../../services/benefit-toolbar-event.service";

// ===============================================================

interface ToolbarOptions {
}


interface ToolbarChoices {
  inputValue: string,
}

enum ActiveMode {
  Basket,
  Search,
  Postcode
}

// ===============================================================

@Component({
  selector: 'vn-benefits-toolbar',
  templateUrl: './benefits-toolbar.component.html',
  styleUrls: ['./benefits-toolbar.component.scss']
})
export class BenefitsToolbarComponent implements OnInit, OnDestroy {

  public basketSum$ = this.basketService.basketSum$;

  public selectedCategory$ = this.bonagoService.selectedCategory$;
  private selectedCategorySubscription!: Subscription;

  @Input()
  retailerNames?: string[];

  @ViewChild(InputComponent)
  set searchField(inputComponent: InputComponent) {
    if (!inputComponent) {
      return;
    }

    inputComponent.focusInput();
  };

  private activeMode: ActiveMode = ActiveMode.Basket;

  public toolbarForm!: FormGroup;
  public toolbarOptions!: ToolbarOptions;
  private formSubscription!: Subscription;

  constructor(
    @Inject('$state') private $state: any,
    private formBuilder: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private basketService: BenefitsBonagoBasketService,
    private bonagoService: BenefitsBonagoService,
    private benefitToolbarEventService: BenefitToolbarEventService
  ) {
    this.initToolbarForm();
    this.initToolbarOptions();
  }

  ngOnInit(): void {
    this.selectedCategorySubscription = this.selectedCategory$.subscribe(
      (category) => {
        this.activeMode = ActiveMode.Basket;
      }
    );

    this.benefitToolbarEventService.events$.forEach(event => {
      this.hideSearch();
    });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
    this.selectedCategorySubscription.unsubscribe();
  }

  private initToolbarForm() {
    this.toolbarForm = this.formBuilder.group({
      inputValue: this.formBuilder.control(undefined, []),
    });

    this.formSubscription && this.formSubscription.unsubscribe();

    this.formSubscription = this.toolbarForm.valueChanges
      .subscribe(
        (formValues: ToolbarChoices) => {
          const {
            inputValue
          } = formValues;

          if (this.showMap()) {
            this.changePostcode(inputValue);
          } else {
            this.filterRetailers(inputValue);
          }
        }
      );
  }

  public filterRetailers(name: string) {
    this.bonagoService.filterRetailers({
      rawName: name
    });
  }

  public changePostcode(postcode: string) {
    this.bonagoService.changePostcode(postcode);
  }

  private initToolbarOptions() {
    this.toolbarOptions = {
    };
  }

  public showBasket() {
    return this.activeMode === ActiveMode.Basket;
  }

  public showMap() {
    return this.$state.current.name === "benefitsMap";
  }

  public isCheckout() {
    return this.$state.current.name === "benefitCheckout";
  }

  public openBottomSheet() {
    this.bottomSheet.open(BenefitBasketSheetComponent);
  }

  public toggleActiveMode() {
    switch (this.activeMode) {
      case ActiveMode.Basket:
        this.$state.go(
          'benefitCatalog',
          {
            categoryId: undefined
          }
        );

        setTimeout(() => {
          this.activeMode = ActiveMode.Search;
        }, 500);
        break;
      case ActiveMode.Search:
        this.activeMode = ActiveMode.Basket;
        break;
      default:
        throw new Error(`Invalid active mode: ${this.activeMode}`);
    }
  }

  public hideSearch() {
    setTimeout(() => {
      this.activeMode = ActiveMode.Basket;
    }, 500);
  }
}
