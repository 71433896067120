<ng-container *ngIf="!readonlyDialog; else tocLink">
    <form [formGroup]="formGroup">
        <vn-toc-checkbox formControlName="toc"
                         [tocLabel]="tocLabel"
                         [tocTitle]="tocTitle"
                         [tocText]="tocText"
                         [switchKey]="switchKey"
                         [buttonKey]="buttonKey">
        </vn-toc-checkbox>
    </form>
</ng-container>

<ng-template #tocLink>
    <a (click)="openTocDialog()">
        {{ linkText }}
    </a>
</ng-template>