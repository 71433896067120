export const RAISE_TYPES = [
  {value: 0, display: '%', desc: 'percent', def_value: 0.025},
  {value: 1, display: 'EUR', desc: 'euro', def_value: 50}
];

export const CALCULATION_TYPES = [{
  display: 'standard',
  value: 'standard'
}, {
  display: 'Goal',
  value: 'goal'
}];

export const CALCULATION_TYPES_BY_VALUE = CALCULATION_TYPES.reduce((acc, calc_type)=>{
  acc[calc_type.value] = calc_type;
  return acc;
},{});

export const DEFAULT_CALCULATION_SETTINGS = {
  type: CALCULATION_TYPES_BY_VALUE['standard']['value'],
  goal: 0,
  allowed_error_eur: 1
};

export const BUDGET_TYPES = [
  {value: 0, display: 'Direktauswahl Gehaltsextras', name: 'gehaltsextras', desc: '', defaults: { calculation_settings: Object.assign({}, DEFAULT_CALCULATION_SETTINGS)}},
  {value: 1, display: 'Budgetberechnung', desc: '', name: 'budgetberechnung', defaults: { calculation_settings: Object.assign({}, DEFAULT_CALCULATION_SETTINGS)}},
  {
    value: 2, 
    display: 'Neueinstellung', 
    name: 'neueinstellung',
    desc: '', 
    defaults: {
      calculation_settings: Object.assign({}, DEFAULT_CALCULATION_SETTINGS, {
        type : CALCULATION_TYPES_BY_VALUE['goal']['value']
      })
    }
  }
];

export const BUDGET_TYPES_BY_VALUE = BUDGET_TYPES.reduce((acc, budget_type)=> {
  acc[budget_type.value] = budget_type;
  return acc;
}, {});


export const BUDGET_TYPES_BY_NAME = BUDGET_TYPES.reduce((acc, budget_type)=> {
  acc[budget_type.name] = budget_type;
  return acc;
}, {});

export const CHURCH_TAX_TYPES = [
  {id:1, value: 0, display: '0%', default: 0.26375},
  {id:2, value: 0.08, display: '8%', default: 0.2837},
  {id:3, value: 0.09, display: '9%', default: 0.2862}
];

export const CHURCH_TAX_TYPES_BY_ID = CHURCH_TAX_TYPES.reduce((acc, church_tax)=> {
  acc[church_tax.id] = church_tax;
  return acc;
}, {});

export const CHURCH_TAX_TYPE_BY_VALUE = CHURCH_TAX_TYPES.reduce((acc, church_tax)=>{
  acc[church_tax.value] = church_tax;
  return acc;
},{});

export const DEFAULT_CHURCH_TAX_TYPE = CHURCH_TAX_TYPES[0];

export const DEFAULT_CAL_CONTROLS = {
  netto: 10,
  employer: 10,
  employee: 1,
  range_type: 1,
  for_year: false
};

export const DEFAULT_RAISE = {
  value: RAISE_TYPES[1].def_value,
  type: RAISE_TYPES[1].value
};

export const DEFAULT_FACTOR = 1;
export const DEFAULT_ADDITIONAL_RATE_HEALTH_INSURANCE = 0.01;
export const DEFAULT_MONTHLY_CONTRIBUTIONS = 0;





const TAX_BRACKETS = [
  {value: 1, display: '1'},
  {value: 2, display: '2'},
  {value: 3, display: '3'},
  {value: 4, display: '4'},
  {value: 4.5, display: '4 mit Faktro'},
  {value: 5, display: '5'},
  {value: 6, display: '6'}
];

const CHILD_ALLOWANCES = [
  {value: 0, display: '0'},
  {value: 0.5, display: '0.5'},
  {value: 1, display: '1'},
  {value: 1.5, display: '1.5'},
  {value: 2, display: '2'},
  {value: 2.5, display: '2.5'},
  {value: 3, display: '3'},
  {value: 3.5, display: '3.5'},
  {value: 4, display: '4'},
  {value: 4.5, display: '4.5'},
  {value: 5, display: '5'},
  {value: 5.5, display: '5.5'},
  {value: 6, display: '6'},
  {value: 6.5, display: '6.5'},
  {value: 7, display: '7'},
  {value: 7.5, display: '7.5'},
  {value: 8, display: '8'},
  {value: 8.5, display: '8.5'},
  {value: 9, display: '9'}
];

const CHURCH_TAXES = [
  { value: 'WAHL', display: 'keine Kirchensteuer'},
  { value: 'BW', display: 'Baden-Württemberg'},
  { value: 'BAYERN', display: 'Bayern'},
  { value: 'BERLIN', display: 'Berlin'},
  { value: 'BRANDENBURG', display: 'Brandenburg'},
  { value: 'BREMEN', display: 'Bremen'},
  { value: 'HH', display: 'Hamburg'},
  { value: 'HESSEN', display: 'Hessen'},
  { value: 'MECKLENBURG', display: 'Mecklenburg-Vorpommern'},
  { value: 'NIEDERSACHSEN', display: 'Niedersachsen'},
  { value: 'NRW', display: 'Nordrhein-Westfalen'},
  { value: 'RP', display: 'Rheinland-Pfalz'},
  { value: 'SAARLAND', display: 'Saarland'},
  { value: 'SACHSEN', display: 'Sachsen'},
  { value: 'SA', display: 'Sachsen-Anhalt'},
  { value: 'SH', display: 'Schleswig-Holstein'},
  { value: 'TH', display: 'Thüringen'}
];

const PERIODS = [
  {value: 1, display: 'Jahr'},
  {value: 2, display: 'Monat'},
  {value: 3, display: 'Woche'},
  {value: 4, display: 'Tag'}
];

const PENSION_INSURANCE =  [
  {value: 0, display:'gesetzliche RV West'},
  {value: 1, display:'gesetzliche RV Ost'}
];

const HEALTH_INSURANCE = [
  {value: 0, display: 'gesetzliche Krankenversicherung'},
  {value: 1, display: 'private Krankenversicherung'},
];

const CARE_INSURANCE = [
  {value: 0, display: 'ohne Zuschlag von 0,35%'},
  {value: 1, display: 'mit Zuschlag von 0,35%'},
  {value: 2, display: 'Sachsen ohne Zuschlag von 0,35%'},
  {value: 3, display: 'Sachsen mit Zuschlag von 0,35%'},
];

const CHILD_TAX_CREDIT = [
  { value: 0, display: '0'},
  { value: 0.5, display:'0,5'},
  { value: 1, display: '1'},
  { value: 1.5, display: '1,5'},
  { value: 2, display: '2'},
  { value: 2.5, display: '2,5'},
  { value: 3, display: '3'},
  { value: 3.5, display: '3,5'},
  { value: 4, display: '4'}
];


export { TAX_BRACKETS, CHILD_ALLOWANCES , CHURCH_TAXES, PERIODS, PENSION_INSURANCE, HEALTH_INSURANCE, CARE_INSURANCE, CHILD_TAX_CREDIT};
