const $inject = [
  "$scope",
  "$state",
  "$window",
  "$location",
  "$filter",
  "mppService",
  "MppConstants",
  "$timeout"
];

export default class MppListingController {

  constructor($scope, $state, $window, $location,
    $filter, mppService, MppConstants, $timeout) {

    Object.assign(this, {
      $scope,
      $state,
      $window,
      mppService,
      $timeout
    });


    this.$scope = $scope;
    this.$location = $location;
    this.mppBarCtrl = this.$scope.$parent;

    this.mppService = mppService;

    this.filters = {
      show_only_main: false,
      show_only_mobile: false,
      show_only_supply: false
    };

    this.currentCategories = this.mppService.getSelectedCategories();

    this.mppService.setPageItems(36);

    if (this.$state.params.main) {
      this.mppBarCtrl.selectedMainCategory = this.$state.params.main;
      this.currentCategories.main = this.$state.params.main;

      if (this.$state.params.sub === undefined) {
        this.mppBarCtrl.selectedSubCategory = 'Alle';
        delete this.currentCategories.sub;
      }
    }
    if (this.$state.params.sub) {
      this.mppBarCtrl.selectedSubCategory = this.$state.params.sub;
      this.currentCategories.sub = this.$state.params.sub;
    }

    if (this.currentCategories.main !== undefined) {
      this.mppService.setSelectedCategories(this.currentCategories);

      if (this.$location.search().search === undefined) {
        this.mppService.getByCategory(this.currentCategories)
          .then(products => this.mppBarCtrl.mppCtrl.products = products);

        this.mppService.getCurrentProducers(this.currentCategories)
          .then(producers => {
            this.mppBarCtrl.producers = producers;
          });
      }
    }
    this.orderBy = $filter('orderBy');

  }

  /**
   * Opens detail view, without site refresh.
   *
   * @param {string} id - Article number
   */
  goToDetail(id) {
    if (!this.mppBarCtrl.mppCtrl.alternateUser) {
      let absUrl = this.$state.href('inApp.mpp.side.products.detail', { Artikelnummer: id }, { absolute: true });
      window.open(absUrl + '?employeeId=' + this.mppBarCtrl.mppCtrl.employeeId + (this.mppBarCtrl.mppCtrl.mpp.isAdvisor ? '&calc' : ''), '_self');
    } else {
      this.$state.go('inApp.mpp.side.products.detail', { Artikelnummer: id });
    }
  }

  isMainArticle(article) {
    return this.mppService.isMainArticle(article);
  }

  hasSupply(article) {
    return this.mppService.hasSupply(article);
  }
  /**
   *
   * Orders the visible products (de|a)cending by it's given value
   *
   * @param {String} options - Value::Boolean
   */
  order(options) {
    let split = options.split('::'),
      val = split[0],
      asc = (split[1] === 'true');

    this.mppBarCtrl.mppCtrl.products = this.orderBy(this.mppBarCtrl.mppCtrl.products, val, asc);
  }
}

MppListingController.$inject = $inject;