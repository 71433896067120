import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { DirectBonusErrorHandler } from 'src/app/direct-bonus/services/errors/direct-bonus-error-handler';
import { AlertService } from 'src/app/shared/services/alert.service';

interface DialogData {
  bonusProjectId: string;
}

@Component({
  selector: 'vn-customer-create-extra-order-dialog',
  templateUrl: './customer-create-extra-order-dialog.component.html',
  styleUrls: ['./customer-create-extra-order-dialog.component.scss']
})
export class CustomerCreateExtraOrderDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CustomerCreateExtraOrderDialogComponent>,
    private directBonusService: DirectBonusService,
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private notificationService: AlertService,
    private directBonusErrorHandler: DirectBonusErrorHandler,
  ) {}

  ngOnInit(): void {
  }

  createNewExtraOrder() {

    let today = new Date()
    this.directBonusService.customerCreateOrder(
      this.dialogData.bonusProjectId,
      today,
      false,
    ).subscribe(
      () => {
        this.notificationService.message('Sie haben eine neue Bestellung für den Monat gesetzt.');
        this.dialogRef.close(true);
      },
      (error: any) => {
        this.notificationService.error(this.directBonusErrorHandler.handle(error).getMessage())
      }
    )
  }

}
