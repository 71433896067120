import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { BonagoBasketListItem, EmployeeAddress } from 'src/app/direct-bonus/models/benefit-bonago.model';
import { BenefitsBonagoBasketService } from 'src/app/direct-bonus/services/benefits-bonago-basket.service';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';
import { LoadingService } from 'src/app/shared/services/loading.service';

// ===============================================================

interface FormOptions {
}

interface FormChoices {
  email: string,
  street: string,
  postcode: string,
  city: string,
}

// ===============================================================

@Component({
  selector: 'vn-benefit-checkout',
  templateUrl: './benefit-checkout.component.html',
  styleUrls: ['./benefit-checkout.component.scss']
})
export class BenefitCheckoutComponent implements OnInit, OnDestroy {

  public basketSum$ = this.basketService.basketSum$;
  public userBalance$ = this.basketService.userBalance$$;
  public restBalance$ = this.basketService.restBalance$;

  public basketList$ = this.basketService.basketListItems$;
  public basketList: BonagoBasketListItem[] = [];
  private basketListSubscription!: Subscription;

  public checkoutForm!: FormGroup;
  public formOptions!: FormOptions;

  public isEmailValidated = false;

  public get employeeAddress(): EmployeeAddress | null {
    return this.$state.params.employeeAddress;
  }

  public get emptyBasket(): boolean {
    return !(!!this.basketList.length &&
      !!this.employeeAddress);
  }

  public get canCheckout(): boolean {
    return !this.emptyBasket && this.checkoutForm.valid && this.isEmailValidated;
  }

  constructor(
    @Inject('$state') private $state: any,
    private formBuilder: FormBuilder,
    private basketService: BenefitsBonagoBasketService,
    private bonagoService: BenefitsBonagoService,
    private loading: LoadingService
  ) {
    this.initCheckoutForm();
    this.initFormOptions();
  }

  ngOnInit(): void {
    this.basketListSubscription = this.basketList$.subscribe(
      basketList => this.basketList = basketList
    );
  }

  ngOnDestroy(): void {
    this.basketListSubscription.unsubscribe();
  }

  private initCheckoutForm() {
    this.checkoutForm = this.formBuilder.group({
      email: this.formBuilder.control(this.employeeAddress?.email, [
        Validators.required
      ]),
      street: this.formBuilder.control(this.employeeAddress?.street, [
        Validators.required
      ]),
      postcode: this.formBuilder.control(this.employeeAddress?.postcode, [
        Validators.required
      ]),
      city: this.formBuilder.control(this.employeeAddress?.city, [
        Validators.required
      ]),
      toc: this.formBuilder.control(undefined, [
        Validators.required
      ])
    });
  }

  private initFormOptions() {
    this.formOptions = {
    };
  }

  getEmailInitiValue() {
    return this.employeeAddress?.email ? this.employeeAddress?.email : '' 
  }

  handleValidatorResult(r: any) {
    this.isEmailValidated = !!r;
  }

  public cancelCheckout() {
    this.$state.go('inApp.directBonusBenefitsAccount');
  }

  public completeCheckout() {
    const formValue = this.checkoutForm.value as FormChoices;
    this.bonagoService.completeCheckout(this.basketList, formValue);
  }

  public isCheckoutRequested$() : Observable<boolean> {
    return this.loading.loading$;
  }

  
}
