import AdministrationController from './AdministrationController';
import EmployeeListController from './employeeList/EmployeeListController';
import DocumentTemplatesComponentController from './document_templates/documentTemplatesComponent.js';
import DocumentAdministrationController from './document_templates/documentAdministrationController.js';
import BonusAdministrationController from './bonus_administration/bonusAdministrationController';
import BonusControlProcessController from './bonus_control_process/bonusControlProcessController';
import BonusBikeleasingControlProcessController from './bikeleasing_control_process/bonusBikeleasingCPController';
import BonusBikeleasingApproveController from './bikeleasing_control_process/bonusBikeleasingApproveController';
import BonusEditController from './bonus_control_process/bonusEditController.js';
import './control_process';
import { AdministrationYearlyController } from './yearly/AdministrationYearlyController';
import InitiateYearlyDialogController from './yearly/InitiateYearlyDialogController';
import MPPAdministrationController from './mpp/MPPAdministrationController.js';
import AdministrationMppCalculationController from './mpp/calculation/AdministrationMppCalculationController';
import TelecommunicationAdministrationController from './telecommunication/TelecommunicationAdministrationController';
import AdministraitonMppEditController from './mpp/editable/AdministraitonMppEditController';
import AddOldBasketsOrderIdController from './mpp/editable/add_old_baskets_order_id/AddOldBasketsOrderIdController';
import AdministrationDatevController from './datev/AdministrationDatevController';
import AdministrationDatevListController from './datev/list/AdministrationDatevListController';
import AdministrationDatevCreateController from './datev/create/AdministrationDatevCreateController';
import BonusHistoryDialogController from './bonus_history/bonus_history_dialog';
import AdministrationBonagoController from './bonago/AdministrationBonagoController';
import BonagoSchemeConfigureDialogController from './bonago/scheme/configure/bonago_scheme_configure_dialog.controller';
import AdministrationBikeApprovalController from './bike_approval/bikeApprovalController';
import NeoBikeleasingApproveController from './bikeleasing_control_process/neoBikeleasingApproveController';
import BikeEditController from './bike_approval/bike_edit/bikeEditDialogController';
import AdministrationMppApprovalsController from './mpp/approvals/AdministrationMppApprovalsController';
import AddNewBasketController from './mpp/editable/add_new_basket/AddNewBasketController';
//order process controllers
import requested_by_VN_controller from './mpp/editable/order_process/requested_by_VN_controller';
import order_created_in_retailer_controller from './mpp/editable/order_process/order_created_in_retailer_controller';
import order_created_in_retailer_with_changes_controller from './mpp/editable/order_process/order_created_in_retailer_with_changes_controller';
import ready_for_delivery_controller from './mpp/editable/order_process/ready_for_delivery_controller';
import delivered_controller from './mpp/editable/order_process/delivered_controller';
import AddContractIDController from './mpp/editable/order_process/AddContractIDController';
import bill_send_leasing_company_controller from './mpp/editable/order_process/bill_send_leasing_company_controller';

import SpecialAdministrationController from "./special_admin_panel/specialAdministrationController";
import CancelOrderController from './mpp/editable/order_process/cancelOrderController';

import UploadOrderProcessDocumentsDialogController from './mpp/editable/order_process/upload_order_process_documents_dialog_controller';
import BonusCancelDialogController from './bonus_control_process/BonusCancelDialogController';
import EditBasketGeneralController from './mpp/editable/edit_basket_general/EditBasketGeneralDialog';
import MigrateBasketController from './mpp/editable/migrate/MigrateBasketController';
import PreendPCDialogController from './mpp/editable/preend_pc_dialog/PreendPCDialog';
import document_templates_html from './document_templates/document_templates.pug'
import AdministrationCarLeasingController from "./car-leasing/administration-car-leasing.controller";
import InvoicePaymentDialogController from "./mpp/editable/invoice_payment_dialog/invoice_payment_dialog_controller";
import AdministrationCashbackController from "./cashback/administration_cashback.controller";
angular
  .module( 'VSPApp' )
  .controller( 'AdministrationController', AdministrationController )
  .controller( 'AddNewBasketController', AddNewBasketController )
  .controller( 'EmployeeListController', EmployeeListController)
  // .controller( 'DocumentTemplatesController', DocumentTemplatesController)
  .controller( 'CockpitYearlyController', AdministrationYearlyController)
  .controller( 'InitiateYearlyDialogController', InitiateYearlyDialogController)
  .controller( 'DocumentAdministrationController', DocumentAdministrationController)
  .controller( 'DocumentTemplatesComponentController', DocumentTemplatesComponentController)
  .component( 'documentTemplatesComponent', {
    template: document_templates_html(),
    bindings: {
      options: '<'
    },
    controller:   'DocumentTemplatesComponentController',
    controllerAs: '$ctrl'
  })
  .controller( 'BonusAdministrationController', BonusAdministrationController)
  .controller( 'BonusControlProcessController', BonusControlProcessController)
  .controller( 'BonusBikeleasingControlProcessController', BonusBikeleasingControlProcessController)
  .controller( 'BonusBikeleasingApproveController', BonusBikeleasingApproveController)
  .controller( 'MPPAdministrationController', MPPAdministrationController)
  .controller( 'AdministrationMppCalculationController', AdministrationMppCalculationController)
  .controller( 'TelecommunicationAdministrationController', TelecommunicationAdministrationController)
  .controller( 'BonusEditController', BonusEditController)
  .controller( 'AdministraitonMppEditController', AdministraitonMppEditController )
  .controller( 'AddOldBasketsOrderIdController', AddOldBasketsOrderIdController)
  .controller( 'AdministrationDatevController', AdministrationDatevController )
  .controller( 'AdministrationDatevListController', AdministrationDatevListController )
  .controller( 'AdministrationDatevCreateController', AdministrationDatevCreateController )
  .controller( 'BonusHistoryDialogController', BonusHistoryDialogController)
  .controller( 'AdministrationBonagoController', AdministrationBonagoController)
  .controller( 'BonagoSchemeConfigureDialogController', BonagoSchemeConfigureDialogController)
  .controller( 'AdministrationBikeApprovalController', AdministrationBikeApprovalController)
  .controller( 'NeoBikeleasingApproveController', NeoBikeleasingApproveController)
  .controller( 'BikeEditController', BikeEditController)
  .controller( 'AdministrationMppApprovalsController', AdministrationMppApprovalsController)
  .controller( 'requested_by_VN_controller', requested_by_VN_controller)
  .controller( 'order_created_in_retailer_controller', order_created_in_retailer_controller)
  .controller( 'order_created_in_retailer_with_changes_controller', order_created_in_retailer_with_changes_controller)
  .controller( 'ready_for_delivery_controller', ready_for_delivery_controller)
  .controller( 'delivered_controller', delivered_controller)
  .controller( 'AddContractIDController', AddContractIDController)
  .controller( 'bill_send_leasing_company_controller', bill_send_leasing_company_controller)
  .controller( 'SpecialAdministrationController', SpecialAdministrationController)
  .controller( 'CancelOrderController', CancelOrderController)
  .controller( 'UploadOrderProcessDocumentsDialogController', UploadOrderProcessDocumentsDialogController)
  .controller( 'BonusCancelDialogController' , BonusCancelDialogController)
  .controller( 'EditBasketGeneralController', EditBasketGeneralController)
  .controller( 'MigrateBasketController', MigrateBasketController)
  .controller( 'PreendPCDialogController', PreendPCDialogController)
  .controller( 'AdministrationCarLeasingController', AdministrationCarLeasingController)
  .controller( 'InvoicePaymentDialogController', InvoicePaymentDialogController)
  .controller( 'AdministrationCashbackController', AdministrationCashbackController);
