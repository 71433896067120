import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';


@Component({
  selector: 'vn-direct-bonus-revert-dialog',
  templateUrl: './direct-bonus-revert-dialog.component.html',
  styleUrls: ['./direct-bonus-revert-dialog.component.scss']
})
export class DirectBonusRevertDialogComponent implements OnInit {
  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public get period(): string {
    return this.dialogData.period!
  }

  constructor(
    public dialogRef: MatDialogRef<DirectBonusRevertDialogComponent>,
    private directBonusService: DirectBonusService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData
  ) {
  }

  ngOnInit(): void {
  }

  public revertOrder() {
    this.directBonusService.revertCustomerOrder(this.orderId)
      .subscribe(
        () => this.dialogRef.close()
      );
  }
}
