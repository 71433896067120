angular
  .module('VSPApp')
  .constant('JSONspec', {
    "type": "object",
    "properties": {
      "register_date": {
        "type": "number",
        "title": "Registrierungstag",
        "format": "date"
      },
      "anrede": {
        "type": "number",
        "default": 1,
      "title": "Anrede"
    },
    "vorname": {
      "type": "string",
      "title": "Vorname"
    },
    "nachname": {
      "type": "string",
      "title": "Nachname"
    },
    "strasse": {
      "type": "string",
      "title": "Straße und Hausnummer"
    },
    "plz": {
      "type": "string",
      "title": "PLZ"
    },
    "email": {
      "type": "string",
      "title": "E-Mail Adresse"
    },
    "partner_id": {
      "type": "string",
      "title": "Partner-ID"
    },
    "telefon": {
      "type": "string",
      "title": "Telefon"
    },
    "stadt": {
      "type": "string",
      "title": "Stadt"
    },
    "arbeitgeber": {
      "type": "string",
      "title": "Arbeitgeber"
    },
    "personalnummer": {
      "type": "number",
      "title": "Personalnummer"
    },
    "eintrittsdatum": {
      "type": "string",
      "title": "Eintrittsdatum"
    },
    "geburtsdatum": {
      "type": "string",
      "title": "Geburtsdatum"
    },
    "renteneintritt": {
      "type": "string",
      "minLength": 2,
      "title": "Renteneintritt",
      "enum": [
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67"
      ]
    },
    "bundesland": {
      "type": "number",
      "title": "Bundesland Betriebsstätte"
    },
    "sv": {
      "type": "number",
      "title": "Berechnung SV"
    },
    "abrechnungstabelle": {
      "type": "number",
      "title": "Auswahl Berechnungstabelle"
    },
    "version": {
      "type": "number",
      "title": "Beginn Optimierung"
    },
    "zusatzinfo": {
      "type": "string",
      "title": "Zusätzliche Informationen"
    },
    "brutto": {
      "type": "number",
      "title": "Grundgelt",
      "format": "currency"
    },
    "avwl_zuschuss": {
      "type": "number",
      "title": "VL- bzw. AVWL-Zuschuss",
      "format": "currency"
    },
    "Zulage 1": {
      "type": "number",
      "title": "Zulage 1"
    },
    "Zulage 2": {
      "type": "number",
      "title": "Zulage 2"
    },
    "Zulage 3": {
      "type": "number",
      "title": "Zulage 3"
    },
    "Zulage 4": {
      "type": "number",
      "title": "Zulage 4"
    },
    "era_ueberschreiter": {
      "type": "number",
      "title": "Überschreiter Ausgleich",
      "format": "currency"
    },
    "era_anpassungs_betrag": {
      "type": "number",
      "title": "Anpassungsbetrag",
      "format": "currency"
    },
    "era_unterschreiter": {
      "type": "number",
      "title": "Unterschreiter Ausgleich",
      "format": "currency"
    },
    "zvk_hinzu": {
      "title": "VersAnteil-ZVK",
      "type": "number",
      "format": "currency"
    },
    "zvk_versteuert": {
      "title": "ind-verstZVK",
      "type": "number",
      "format": "currency"
    },
    "zvk_sv_pflichtig": {
      "title": "SV-Pfl-Hinzu",
      "type": "number",
      "format": "currency"
    },
    "pap_stkl": {
      "title": "Steuerklasse",
      "type": "number"
    },
    "pap_f": {
      "title": "Faktorverfahren",
      "type": "number"
    },
    "pap_r": {
      "title": "Kirchensteuer",
      "type": "boolean"
    },
    "pap_zkf": {
      "title": "Kinderfreibetrag",
      "type": "number"
    },
    "kv_typ": {
      "type": "number",
      "title": "Versicherungsart"
    },
    "pap_kvz": {
      "type": "number",
      "title": "Zusatzbeitragssatz",
      "format": "percent"
    },
    "pap_pvz": {
      "type": "boolean",
      "title": "Kinderlos und über 23"
    },
    "sl_kpv_kv_gesamt": {
      "type": "number",
      "title": "Prämie KV Gesamt",
      "format": "currency"
    },
    "sl_kpv_kv_basis": {
      "type": "number",
      "title": "davon Basisbeitrag nach BEG",
      "format": "currency"
    },
    "sl_kpv_kv_zuschuss": {
      "type": "number",
      "title": "Zuschuss KV",
      "default": 0,
      "format": "currency"
    },
    "sl_kpv_pv_zuschuss": {
      "type": "number",
      "title": "Zuschuss PV",
      "format": "currency"
    },
    "sl_netto_abzug_vl": {
      "type": "number",
      "title": "Abzug VL",
      "format": "currency"
    },
    "sl_netto_bezuege": {
      "type": "number",
      "title": "Bezüge",
      "format": "currency"
    },
    "sl_netto_abzuege": {
      "type": "number",
      "title": "Abzüge/ An-Anteil ZV",
      "format": "currency"
    },
    "bl_bl_zulage_stsv_frei": {
      "type": "number",
      "title": "Zulage st/sv-frei",
      "format": "currency"
    },
    "bl_bl_zulage_st_frei": {
      "type": "number",
      "title": "Zulage st-frei",
      "format": "currency"
    },
    "bl_bl_zulage_sv_frei": {
      "type": "number",
      "title": "Zulage sv-frei",
      "format": "currency"
    },
    "bl_bl_dienstwagen_preis": {
      "type": "number",
      "title": "Listenpreis Dienstwagen",
      "format": "currency"
    },
    "bl_bl_dienstwagen_vorteil": {
      "type": "number",
      "title": "gw. Vorteil Dienstwagen",
      "format": "currency"
    },
    "bl_bl_dienstwagen_km": {
      "type": "number",
      "title": "km"
    },
    "bl_bl_freibetrag": {
      "type": "number",
      "title": "Freibetrage (jährlich)",
      "format": "currency"
    },
    "bl_bl_einmalzahlung": {
      "type": "number",
      "title": "Einmalzahlung",
      "format": "currency"
    },
    "bl_bl_fahrkosten_zuschuss": {
      "type": "number",
      "title": "best. Entfernungspauschale",
      "format": "currency"
    },
    "bl_bl_fahrkosten_pauschalsteuer": {
      "type": "boolean",
      "title": "Pauschalsteuer Arbeitnehmer"
    },
    "bl_bl_verplegung_zuschuss": {
      "type": "number",
      "title": "Verpflegungsmehraufwand",
      "format": "currency"
    },
    "bl_alv_ktg_typ": {
      "type": "number",
      "title": "Behandlung ALV/KTG"
    },
    "bl_alv_ktg_vorteil": {
      "type": "number",
      "title": "Geldwerter Vorteil",
      "format": "currency"
    },
    "bl_alv_ktg_spar": {
      "type": "boolean",
      "title": "AG Spar"
    },
    "blv_alt1_beitrag": {
      "type": "number",
      "title": "monatlicher Beitrag",
      "format": "currency"
    },
    "blv_alt1_zuschuss_ag": {
      "type": "number",
      "title": "Zuschuss AG",
      "format": "currency"
    },
    "blv_alt1_typ": {
      "type": "string",
      "title": "Typ",
      "enum": [
        "Pension Kasse",
        "Direktversicherung",
        "U-Kasse"
      ]
    },
    "blv_alt1_paragraph": {
      "type": "string",
      "enum": [
        "§ 3.63",
        "§ 40b"
      ],
      "title": "Nach Paragraph"
    },
    "blv_alt1_pauschalsteuer_an": {
      "type": "boolean",
      "title": "Arbeitnehmer zahlt Pauschalsteuer",
      "format": "currency"
    },
    "blv_alt2_beitrag": {
      "type": "number",
      "title": "monatlicher Beitrag",
      "format": "currency"
    },
    "blv_alt2_zuschuss_ag": {
      "type": "number",
      "title": "Zuschuss AG",
      "format": "currency"
    },
    "blv_alt2_typ": {
      "type": "string",
      "title": "Typ",
      "enum": [
        "Pension Kasse",
        "Direktversicherung",
        "U-Kasse"
      ]
    },
    "blv_alt2_paragraph": {
      "type": "string",
      "enum": [
        "§ 3.63",
        "§ 40b"
      ],
      "title": "Nach Paragraph"
    },
    "blv_alt2_pauschalsteuer_an": {
      "type": "boolean",
      "title": "Arbeitnehmer zahlt Pauschalsteuer",
      "format": "currency"
    },
    "neo_components": {
      "type": "object",
      "title": "Neo Bausteine",
      "properties": {
        "sachbezug": {
          "type": "object",
          "title": "Sachbezug",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "essenscheck": {
          "type": "object",
          "title": "Essensschecks",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "num": {
              "type": "number",
              "default": 0,
              "title": "Stück"
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            },
            "Typ": {
              "type": "string",
              "enum": [
                "AG",
                "AN"
              ]
            }
          }
        },
        "VMA_PST": {
          "type": "object",
          "title": "Verpflegungsmehraufwand (pts)",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "num": {
              "type": "number",
              "default": 0,
              "title": "Tage"
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            },
            "valueModifier": {
              "type": "number",
              "default": 24
            },
            "Typ": {
              "type": "string",
              "enum": [
                "AG",
                "AN"
              ]
            }
          },
          "value": "value"
        },
        "VMA_STSVFREI": {
          "type": "object",
          "title": "Verpflegungsmehraufwand (stsvfrei)",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": false
            },
            "num": {
              "type": "number",
              "default": 0,
              "title": "Tage"
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            },
            "Typ": {
              "type": "string",
              "enum": [
                "AG",
                "AN"
              ]
            }
          },
          "value": "value"
        },
        "handy": {
          "type": "object",
          "title": "Mobiltelefon",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "festnetz": {
          "type": "object",
          "title": "Festnetztelefon",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "internet": {
          "type": "object",
          "title": "Internetpauschale",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 50,
              "format": "currency"
            },
            "Typ": {
              "type": "string",
              "enum": [
                "AG",
                "AN"
              ]
            }
          },
          "value": "value"
        },
        "kinder": {
          "type": "object",
          "title": "Kindergartenzuschuss",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "entfernung": {
          "type": "object",
          "title": "Entfernungspauschale",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "distance": {
              "type": "string",
              "default": 0,
              "title": "km"
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            },
            "Typ": {
              "type": "string",
              "enum": [
                "AG",
                "AN"
              ]
            }
          },
          "value": "value"
        },
        "dienstwagen": {
          "type": "object",
          "title": "Dienstwagengarage",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            },
            "Typ": {
              "type": "string",
              "enum": [
                "miete",
                "auslagenrechnung"
              ],
              "default": "miete"
            }
          },
          "value": "value"
        },
        "werbeflaeche": {
          "type": "object",
          "title": "Werbeflächenanmietung",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 21,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "pc": {
          "type": "object",
          "title": "Personalcomputer, mtl. Rate",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "heimarbeit": {
          "type": "object",
          "title": "Heimarbeit",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "personal_rabatt": {
          "type": "object",
          "title": "Personalrabatt",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "erholungsbeihilfe": {
          "type": "object",
          "title": "Erholungsbeihilfe",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "Sonstiges": {
          "type": "object",
          "title": "Sonstiges",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "EBike": {
          "type": "object",
          "title": "E-Bike",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            },
            "anbieter": {
              "type": "enum",
              "enum": [
                "JobRad",
                "BeGreen"
              ]
            },
            "listenpreis": {
              "type": "number",
              "default": 0,
              "format": "currency",
              "title": "Listenpreis Fahrrad"
            },
            "verkaufspreis": {
              "type": "number",
              "default": 0,
              "format": "currency",
              "title": "Kaufpreis Fahrrad"
            }
          },
          "value": "value"
        },
        "berufskleidung": {
          "type": "object",
          "title": "Berufskleidung",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        }
      }
    },

    "versicherungen": {
      "type": "object",
      "title": "Versicherungen",
      "properties": {
        "haftpflicht": {
          "type": "object",
          "title": "Haftpflicht",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "hausrat": {
          "type": "object",
          "title": "Hausrat",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "hund": {
          "type": "object",
          "title": "Hund",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        },
        "pferd": {
          "type": "object",
          "title": "Pferd",
          "properties": {
            "enabled":{
              "type":"boolean",
              "title": " ",
              "default": false
            },
            "displayed": {
              "type": "boolean",
              "title": "Displayed",
              "default": true
            },
            "value": {
              "type": "number",
              "default": 0,
              "format": "currency"
            }
          },
          "value": "value"
        }
      }
    },

    "freiwillige_sonderzulage": {
      "type": "number",
      "title": "freiwillige_sonderzulage",
      "format": "currency"
    },
    "brutto_inc" : {
      "type": "number",
      "title": "brutto_inc",
      "format": "currency"
    },
    "zulagen" : {
      "type": "number",
      "title": "zulagen"
    },
    "entgeldverzicht" : {
      "type": "number",
      "title": "entgeldverzicht",
      "format": "currency"
    },
    "endgeldverzicht_additional" : {
      "type": "number",
      "title": "endgeldverzicht_additional",
      "format": "currency"
    },
    "vl_zuschuss_neu_wert" : {
      "type": "number",
      "title": "vl_zuschuss_neu_wert",
      "format": "currency"
    },
    "sonderzahlung_st_frei" : {
      "type": "number",
      "title": "sonderzahlung_st_frei",
      "format": "currency"
    },
    "zuschuss_st_sv_frei" : {
      "type": "number",
      "title": "zuschuss_st_sv_frei",
      "format": "currency"
    },
    "pst_an" : {
      "type": "number",
      "title": "pst_an",
      "format": "currency"
    },
    "geldwert_vorteil" : {
      "type": "number",
      "title": "geldwert_vorteil",
      "format": "currency"
    },
      "alv_ktg_geldwert": {
        "type": "number",
        "title": "alv_ktg_geldwert",
        "format": "currency"
      }
    },
    "required": ["bundesland"]
  });
