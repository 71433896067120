var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (bruttoOld) {
      var bruttoOld = '{{ employee.result.before.tax.brutto_gesamt | currency }}'
var nettoOld = '{{ employee.result.before.tax.net_netto | currency }}'
var nettoNew = '{{ employee.result.final.tax.net_netto + employee.result.final.tax.net_jahr | currency }}'
var nettoPlusMonthly = '{{ (employee.result.final.tax.net_netto - employee.result.before.tax.net_netto + employee.result.final.tax.net_jahr) | currency }}'
var nettoPlusYearly = '{{ (employee.result.final.tax.net_netto - employee.result.before.tax.net_netto + employee.result.final.tax.net_jahr) *12 | currency }}'
var nettoSmaller = 'employee.result.final.tax.net_netto + employee.result.final.tax.net_jahr < employee.result.before.tax.net_netto'
var nettoLarger = 'employee.result.final.tax.net_netto + employee.result.final.tax.net_jahr > employee.result.before.tax.net_netto'
var agKostenOld = '{{ employee.result.before.tax.ag_kosten | currency }}'
var agKostenNew = '{{ employee.result.final.tax.ag_kosten | currency }}'
var is1to1 = '"NONE" == employee.data.parameters.einsparungs_modell'
pug_html = pug_html + "\u003Cli class=\"dropdown subtext\" ng-show=\"(!infomode &amp;&amp; undefined !== infomode) || !isSelfservice()\"\u003E\u003Ca ng-click=\"meinBruttoOpen = !meinBruttoOpen\" ng-class=\"{ openable: true, selected: meinBruttoOpen }\"\u003E\u003Cspan class=\"key\"\u003EBrutto\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Cspan class=\"value\"\u003E\u003Ca ng-click=\"enterBrutto()\" style=\"line-height: .7rem;\" data-cy=\"vn.configuration.neoDashboard.sideMenu.linkSalaryDialog\"\u003E" + (pug.escape(null == (pug_interp = bruttoOld) ? "" : pug_interp)) + "\u003Cbr\u003E\u003Cspan class=\"micro\" style=\"display: block;font-size: .7rem;\"\u003Ebearbeiten\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003Cul class=\"submenue\" ng-show=\"meinBruttoOpen\"\u003E\u003Cli ng-repeat=\"key in CalculationResultConstants\"\u003E\u003Cspan class=\"key\"\u003E{{ CalculationResultNameConstants[ key ] }}\u003C\u002Fspan\u003E\u003Cspan class=\"value\"\u003E{{ employee.result.final.tax[ key ] | currency }}\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"employee.plausibilites\"\u003E\u003Cspan class=\"key\"\u003EStundenlohn\u003C\u002Fspan\u003E\u003Cspan class=\"value\"\u003E{{ employee.plausibilites.info.hourlyWage | currency }}\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003Cli ng-if=\"employee.plausibilites\"\u003E\u003Cspan class=\"key\"\u003Eüber Mindestlohn\u003C\u002Fspan\u003E\u003Cspan class=\"value\" ng-class=\"{red: employee.plausibilites.info.hourlyWage &lt; employee.data.metadata.mindestlohn}\"\u003E{{ employee.plausibilites.info.hourlyWage - employee.data.metadata.mindestlohn | currency }}\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fli\u003E";
    }.call(this, "bruttoOld" in locals_for_with ?
        locals_for_with.bruttoOld :
        typeof bruttoOld !== 'undefined' ? bruttoOld : undefined));
    ;;return pug_html;};
module.exports = template;