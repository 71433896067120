import { NgHybridStateDeclaration, NgHybridStatesModule } from "@uirouter/angular-hybrid/interfaces";
import { BenefitsCatalogComponent } from "./components/benefits/benefits-catalog/benefits-catalog.component";
import { BenefitDetailsComponent } from "./components/benefits/benefit-details/benefit-details.component";
import { BenefitsMapComponent } from "./components/benefits/benefits-map/benefits-map.component";
import { BenefitCheckoutComponent } from "./components/benefits/benefit-checkout/benefit-checkout.component";

const directBonusStates: NgHybridStateDeclaration[] = [
  {
    parent: 'inApp.directBonusBenefitsAccount',
    name: 'benefitCatalog',
    url: '/category/:categoryId',
    component: BenefitsCatalogComponent,
    params: {
      categoryId: null
    },
  },
  {
    parent: 'inApp.directBonusBenefitsAccount',
    name: 'benefitDetails',
    url: '/retailer/:retailerId',
    component: BenefitDetailsComponent,
    params: {
      retailerId: null
    },
  },
  {
    parent: 'inApp.directBonusBenefitsAccount',
    name: 'benefitsMap',
    url: '/map',
    component: BenefitsMapComponent,
    params: {
      lat: null,
      lng: null
    },
  },
  {
    parent: 'inApp.directBonusBenefitsAccount',
    name: 'benefitCheckout',
    url: '/checkout',
    params: {
      employeeAddress: null
    },
    component: BenefitCheckoutComponent,
  },
];

const directBonusStatesModule: NgHybridStatesModule = {
  states: directBonusStates
};

export { directBonusStatesModule };