import { Component, Input, OnInit } from '@angular/core';
import { BonagoBasketListItem } from 'src/app/direct-bonus/models/benefit-bonago.model';
import { BenefitsBonagoBasketService } from 'src/app/direct-bonus/services/benefits-bonago-basket.service';

@Component({
  selector: 'vn-benefit-checkout-table',
  templateUrl: './benefit-checkout-table.component.html',
  styleUrls: ['./benefit-checkout-table.component.scss']
})
export class BenefitCheckoutTableComponent implements OnInit {

  public basketSum$ = this.basketService.basketSum$;

  @Input()
  tableData!: BonagoBasketListItem[];

  public readonly displayedColumns: string[] = [
    'itemInfo',
    'itemValue',
    'totalValue'
  ];

  constructor(
    private basketService: BenefitsBonagoBasketService
  ) { }

  ngOnInit(): void {
  }



}
