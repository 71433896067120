import RegistrationController from './registration/RegistrationController';
import registrationTocController from './registration/toc/registrationTocController';
import mailChangeController from './mailchange/mailChangeController';
import ActivationController from './activation/ActivationController';
import CidaasLoginController from './cidaas_login/CidaasLoginController';


angular
  .module( 'VSPApp' )
  .controller( 'RegistrationController', RegistrationController )
  .controller( 'registrationTocController', registrationTocController )
  .controller( 'mailChangeController', mailChangeController )
  .controller( 'ActivationController', ActivationController )
  .controller( 'CidaasLoginController', CidaasLoginController )
;
