import datev_pagination_html from "./templates/datevPagination.pug"

angular
  .module('VSPApp')
  .directive('datevPagination',  [function () {
    return {
      restrict: 'EA',
      template: datev_pagination_html,
      replace: true,
      scope: {
        potentials: '=',
        gotoNext: '&',
        gotoPrev: '&'
      },
      link: function ($scope) {
        $scope.currentStep    = 0;

        $scope.$watch('potentials', (newData) => {
          // TODO: this is a hack because of the poor implementation of the iterator and the pagination
          $scope.currentStep    = 0;
          $scope.stepsCount     = newData.length - 1;
          $scope.prevIsDisabled = $scope.currentStep === 0;
          $scope.nextIsDisabled = $scope.currentStep === $scope.stepsCount;
          $scope.title          = $scope.potentials[ $scope.currentStep  ].title;
        });

        $scope.next = () => {
          $scope.currentStep    += 1;
          $scope.prevIsDisabled = false;

          if ($scope.currentStep === $scope.stepsCount) {
            $scope.nextIsDisabled = true;
          }

          $scope.title = $scope.potentials[$scope.currentStep].title;
          $scope.gotoNext();
        };


        $scope.prev = () => {
          $scope.currentStep    -= 1;
          $scope.nextIsDisabled = false;

          if ($scope.currentStep === 0) {
            $scope.prevIsDisabled = true;
          }

          $scope.title = $scope.potentials[$scope.currentStep].title;
          $scope.gotoPrev();
        };

      }
    };
  }]);
