var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["contact"] = pug_interp = function(prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!prefix) {
prefix=''
}
pug_html = pug_html + "\u003Cdiv class=\"box contact\"\u003E\u003Cdiv class=\"title\"\u003EIhr Ansprechpartner\u003C\u002Fdiv\u003E\u003Cdiv class=\"main text-center\"\u003E\u003Cdiv class=\"detail\"\u003E\u003Cfigure class=\"avatar\"\u003E\u003Cdiv class=\"picture\"\u003E\u003Cimg" + (" class=\"image\""+pug.attr("ng-src", '/assets/img/advisors/{{ '+prefix+'advisorDetails.id }}.jpg', true, true)+" onerror=\"this.src=&quot;\u002Fassets\u002Fimg\u002Fadvisors\u002Ffallback.jpg&quot;\" onabort=\"this.src=&quot;\u002Fassets\u002Fimg\u002Fadvisors\u002Ffallback.jpg&quot;\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cfigcaption class=\"name\"\u003E" + (pug.escape(null == (pug_interp = '{{ '+prefix+'advisorDetails.firstname }} {{ '+prefix+'advisorDetails.name }}') ? "" : pug_interp)) + "\u003C\u002Ffigcaption\u003E\u003C\u002Ffigure\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"detail email\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-email\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"key\"\u003E\u003Ca" + (pug.attr("ng-href", 'mailto:{{ '+prefix+'advisorDetails.email }}', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = '{{ '+prefix+'advisorDetails.email }}') ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"detail phone\" ng-if=\"advisorDetails.phone_support\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-telephone\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"key\"\u003E\u003Ca" + (pug.attr("ng-href", 'tel:+{{ '+prefix+'advisorDetails.phone_support }}', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = '{{ '+prefix+'advisorDetails.phone_support }}') ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Csection id=\"dashboard-box\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"medium-12 large-9 columns\" id=\"main-view\"\u003E\u003Ctabset\u003E\u003Ctab class=\"bigtab xsmall-12 medium-4 columns\" heading=\"Analytics\"\u003E\u003Cheader class=\"tabpage-header clearfix\"\u003E\u003C\u002Fheader\u003E\u003Csection class=\"tabpage-content transparent\"\u003E";
pug_mixins["circle"] = pug_interp = function(max, current, title, theme){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!theme) {
theme = 'light'
}
pug_html = pug_html + "\u003Cdiv class=\"circletext\"\u003E\u003Cdiv" + (" class=\"circle\""+pug.attr("style", pug.style('background:{{dashboard.BrandingService.circleProgress.'+theme+'.bgColor}};'), true, true)) + "\u003E\u003Cround-progress" + (pug.attrs(pug.merge([{"max": pug.escape(max),"current": pug.escape(current),"color": pug.escape('{{dashboard.BrandingService.circleProgress.'+theme+'.color}}'),"bgcolor": pug.escape('{{dashboard.BrandingService.circleProgress.'+theme+'.bgColor}}'),"radius": "60","stroke": "4","responsive": "true"},attributes]), true)) + "\u003E\u003C\u002Fround-progress\u003E\u003Cspan" + (" class=\"circle-text\""+pug.attr("style", pug.style('color:{{dashboard.BrandingService.circleProgress.'+theme+'.color}};'), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"bigbox transparent\"\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\" ng-if=\"$parent.tabs[0].active\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\" title=\"{{ dashboard.general.employees.count }}\"\u003EArbeitnehmer\u003C\u002Fspan\u003E";
}
}, 
            'dashboard.general.employees.count',
            'dashboard.general.employees.count',
            '{{ dashboard.general.employees.count }}',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E{{ dashboard.general.employees.neo }} von {{ dashboard.general.employees.count }}\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\"\u003ETeilnehmerquote\u003C\u002Fspan\u003E";
}
}, 
            'dashboard.general.employees.count',
            'dashboard.general.employees.neo',
            '{{ dashboard.general.employees.neo / dashboard.general.employees.count * 100 | number:1 }} %',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 small-4 columns\"\u003E";
pug_mixins["circle"].call({
block: function(){
pug_html = pug_html + "\u003Cspan class=\"subtitle\"\u003E{{ dashboard.general.employees.active }} von {{ dashboard.general.employees.count }}\u003C\u002Fspan\u003E\u003Cspan class=\"subsubtitle\"\u003Eaktive Nutzer\u003C\u002Fspan\u003E";
}
}, 
            'dashboard.general.employees.count',
            'dashboard.general.employees.active',
            '{{ dashboard.general.employees.active / dashboard.general.employees.count * 100 | number:1 }} %',
            'dark'
          );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E\u003C\u002Ftab\u003E\u003Ctab class=\"bigtab xsmall-12 medium-4 columns\" heading=\"Bausteine\"\u003E\u003Cheader class=\"tabpage-header clearfix\"\u003E\u003C\u002Fheader\u003E\u003Csection class=\"tabpage-content transparent\"\u003E" + (null == (pug_interp = require("../shared/tabs/_components.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fsection\u003E\u003C\u002Ftab\u003E\u003C\u002Ftabset\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"side-view\" fixonheader\u003E";
pug_mixins["contact"]('dashboard.');
pug_html = pug_html + ("\u003Cdiv class=\"box actions\"\u003E\u003Cdiv class=\"title\"\u003EAktionen\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Ca class=\"button button-primary\" ng-click=\"dashboard.arbeitnehmerAnlegen()\"\u003E\u003Ci class=\"font-icon icon-user\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EArbeitnehmer freischalten\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca class=\"button button-primary\" ng-href=\"\u002Fcockpit\u002Fkundenauswahl\u002F\"\u003E\u003Ci class=\"font-icon icon-cockpit\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003ECockpit\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca class=\"button button-primary\" href=\"\u002Fdashboard\"\u003E\u003Ci class=\"font-icon icon-arrow-right\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EArbeitnehmeransicht\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca class=\"button button-primary\" ng-if=\"amIApprover\" href=\"\u002Fapprovals\"\u003E\u003Ci class=\"font-icon icon-arrow-right\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EFreigabeassistent\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"box actions\"\u003E\u003Cdiv class=\"title\"\u003EFeedback\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Ca class=\"button button-primary\" href=\"\u002Ffeedback\"\u003E\u003Ci class=\"font-icon icon-support\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EFragebogen starten\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E" + (null == (pug_interp = require("../../../helpers/_footer.pug").call(this, locals)) ? "" : pug_interp));;return pug_html;};
module.exports = template;