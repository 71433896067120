import {BonusDigitalSigningComponent} from "../../../services/digital-signing/components/bonus-component";
import { CarLeasingDigitalSigningComponent } from "../../../services/digital-signing/components/car-leasing-component";
import {PcDigitalSigningComponent} from "../../../services/digital-signing/components/pc-component";
import {DticketDigitalSigningComponent} from "../../../services/digital-signing/components/dticket-component";

const $inject = [
  '$q',
  '$scope',
  '$state',
  '$timeout',
  'customerService',
  'VSP_CONSTANTS',
  'NotificationService',
  'DigitalSigningService',
  'lodash',
];
export default class DigitalSignitureController {

  constructor(
    $q,
    $scope,
    $state,
    $timeout,
    customerService,
    VSP_CONSTANTS,
    NotificationService,
    DigitalSigningService,
    lodash,
  ) {

    Object.assign(this, {
      $q,
      $scope,
      $state,
      $timeout,
      customerService,
      VSP_CONSTANTS,
      NotificationService,
      DigitalSigningService,
      lodash
    });
    this.loading = false;
    this.customer = null;

    this.config = {
      digital_signiture_config: {
        is_enabled: false,
        enabled_components: []
      }
    };

    this.customerId = this.$state.params.customerId;


    this.$onInit = () => {

      this.init().then((result) => {
        this.config.digital_signiture_config = {...result.configuration.digital_signiture_config};

        this.customer = result.customer;

        this.components = [
          new PcDigitalSigningComponent(
            this.isModuleEnabled(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.PC),
            this.canSignManually(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.PC),
            result.configuration.neo_config.pc.mpp_type === this.VSP_CONSTANTS.MPP_TYPE.COMPONENT_BASED['id'],
          ),
          new BonusDigitalSigningComponent(
            this.isModuleEnabled(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BONUS),
            this.canSignManually(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BONUS),
            true, // probably check if customer have active bonus porject
          ),
          new CarLeasingDigitalSigningComponent(
            this.isModuleEnabled(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING),
            this.canSignManually(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING),
            true
          ),
          new DticketDigitalSigningComponent(
            this.isModuleEnabled(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.DTICKET),
            this.canSignManually(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.DTICKET),
            true
          )
        ];

        this.__loading(false);
      });

    };

  }

  isModuleEnabled(type) {
    return this.DigitalSigningService.getEnabledComponentsConfigByType(this.config, type).enabled;
  }

  canSignManually(type) {
    return this.DigitalSigningService.getEnabledComponentsConfigByType(this.config, type).canSignManually;
  }


  enableDigitalSigniture() {

    if (this.config.digital_signiture_config.is_enabled === false) {
      if (!confirm(`
        This will disable all of the components digital signing functionality. Are you sure? \r\n
        NB!!! : Thats temporary!!! A proper dialog should pop up.
      `)
      ) {
        this.config.digital_signiture_config.is_enabled = true;
      } else {
        // this.config.digital_signiture_config.enabled_components = [];
        this.components.forEach(comp => {
          if (this.config.digital_signiture_config.enabled_components[comp.value]) {

            comp.enabled = false;
            comp.canSignManually = false;

            this.updateComponents(comp);
          }

        });
      }
    }
  }

  updateComponents(comp) {
    this.config.digital_signiture_config.enabled_components = {
      ...this.config.digital_signiture_config.enabled_components,
      ...comp.toMongo(),
    };
  }

  handleDigitalSignitureForComponent(component) {
    if (!component.enabled || !component.moduleEnabled) {
      component.canSignManually = false;
    }

    this.updateComponents(component);
  }

  async save() {
    this.__loading(true);
    let res = await this.customerService.setConfiguration(this.customerId, {
      digital_signiture_config: this.config.digital_signiture_config
    });
    if (res) {
      this.NotificationService.message('Die Änderungen wurden gespeichert.');
    }

    this.__loading(false);
  }

  async init() {
    let promises = {};
    promises.configuration = this.customerService.getConfiguration(this.customerId, Date.now());
    promises.customer = this.customerService.get(this.customerId);

    this.__loading(true);

    return this.$q.all(promises);
  }

  __loading(val) {
    this.$timeout(() => {
      this.loading = val;
    });
  }
}
DigitalSignitureController.$inject = $inject;
