import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'vn-prufen-button',
  templateUrl: './prufen-button.component.html',
  styleUrls: ['./prufen-button.component.scss']
})
export class PrufenButtonComponent implements OnInit {

  @Input()
  public showErrorMessage: boolean = false;

  public isClicked: boolean = false;

  constructor() { 
  }

  @Input()
  public isProved: boolean = false;

  @Input()
  public isDisabled: boolean = false;

  @Output()
  public prufenClicked$ = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  prufenClickedHandler() {
    this.isClicked = true;
    this.prufenClicked$.emit(this.isClicked);
  }

}
