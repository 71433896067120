import {BONUS_PAYMENT_TYPES} from "./bonus_payment_type";
import {USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME} from "../administrationService";

export const USER_DECISION_PROCESS_TYPES = [
  {
    'id': 0, 'name': 'bonus_payment_type',
    'display': 'Bikeleasing', //used only for bonus bikeleasing
    'description': [
      'Aktuell ist Ihr Fahrrad als Budget eingerichtet. Daher ist gegenwärtig Ihr Budget mit dem Betrag für Bikeleasing blockiert.',
      'Sie können nun entscheiden, ob Sie Ihr Fahrrad über Ihr Budget finanzieren wollen oder ob die Leistung als Entgeltumwandlung eingereicht werden soll. Nach Ihrer Entscheidung prüft Ihr Arbeitgeber Ihre Anfrage.',
      'Sollten Sie keine Entscheidung treffen, so wird nach 48 Stunden der Antrag automatisch mit der Finanzierungsart Budget an Ihren Arbeitgeber übermittelt.',
      'Ihre Entscheidung kann später nicht geändert werden, eine Aufteilung ist nicht möglich.'
    ],
    'settings': {'entity': 'bonus'},
    'available_options': BONUS_PAYMENT_TYPES.reduce((acc, type, index) => {
      acc.push({
        id: index,
        entity_type: 'BONUS_PAYMENT_TYPES',
        display_widget_type_id: USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME['button']['id'],
        choice_value_id: type['id'],
        cyData: `vn.userDecisions.common.{}.buttonDecision-${type.name}`,
        display: {
          text: type['display'],
          description: type['user_decision_description']
        }
      });
      return acc;
    }, [])
  },
  {
    'id': 1, 'name': 'mpp_buying_decision',
    'display': 'Ablöseangebot PC-Leasing',
    'description': [
      'Bitte entscheiden Sie!'
    ],
    'settings': {'entity': 'mpp_basket'},
    'available_options': [
      {
        'id': 0,
        'display_widget_type_id': USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME['button']['id'],
        'choice_value_id': 0,
        cyData: 'vn.userDecisions.common.{}.buttonDecision-buy',
        'entity_type': 'BUYING_DECISION',
        'display': {
          'text': 'Kaufprozess starten',
        },
        'dialog_texts': {
          'heading': 'Bestätigen Sie Ihren Kauf',
          'body': ['Möchten Sie das überlassene Equipment käuflich erwerben? '],
          'buttons': {
            'confirm': 'Kaufen',
            'decline': 'Abbrechen'
          }
        }
      },
      {
        'id': 1,
        'display_widget_type_id': USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME['button']['id'],
        'choice_value_id': 1,
        cyData: 'vn.userDecisions.common.{}.buttonDecision-return',
        'entity_type': 'BUYING_DECISION',
        'display': {
          'text': 'Rückgabeprozess starten'
        },
        'dialog_texts': {
          'heading': 'Bestätigen Sie die Rückgabe',
          'body': ['Möchten Sie das überlassene Equipment zurückgeben?'],
          'buttons': {
            'confirm': 'Zurücksenden',
            'decline': 'Abbrechen'
          }
        }
      }
    ]
  }
];
export const USER_DECISION_PROCESS_TYPES_BY_ID = USER_DECISION_PROCESS_TYPES.reduce((acc, type) => {
  acc[type['id']] = type;
  return acc;
}, {});
export const USER_DECISION_PROCESS_TYPES_BY_NAME = USER_DECISION_PROCESS_TYPES.reduce((acc, type) => {
  acc[type['name']] = type;
  return acc;
}, {});
