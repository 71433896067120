<form [formGroup]='contactsValidatorForm'>
    <vn-email-validator
        formControlName='emailAddress'
        [title]="emailTitle"
        [dataCy]="cyData"
        (isEmailValid$)="emitEmailValidationResult($event)"
        *ngIf="!isEmailHide"
        [isEmailPrivate]="isEmailPrivate"
        [initEmailValue]="initEmailValue"
        [isCompanyEmailAllowed]="isCompanyEmailAllowed"
    ></vn-email-validator>
    <vn-phone-validator
        formControlName='phoneNumber'
        [title]="phoneTitle"
        [dataCy]="cyData"
        (isPhoneValid$)="emitPhoneValidationResult($event)"
        *ngIf="!isPhoneHide"
        [initPhoneValue]="initPhoneValue"
    ></vn-phone-validator>
</form>
