import support_request_dialog_html from '../modules/support/support_dialog/support_dialog.pug';

export const SUPPORT_CATEGORIES = [{
  'id': 0,
  'name': 'need_support',
  'display': 'Ich wünsche eine Beratung'
}, {
  'id': 1,
  'name': 'report_error',
  'display': 'Fehler melden'
}, {
  'id': 2,
  'name': 'other',
  'display': 'Sonstige Anfrage'
}, {
  'id': 3,
  'name': 'feedback',
  'display': 'Feedback'
}, {
  'id': 4,
  'name': 'request_change',
  'display': 'Anpassung mitteilen'
}, {
  'id': 5,
  'name': 'pc_error',
  'display': 'Schaden PC-Leasing melden'
}, {
  'id': 6,
  'name': 'bicycle_error',
  'display': 'Schaden Fahrradleasing melden'
}];

export const SUPPORT_REQUEST_TAG = {
  main_tag: 'support_request',
  session_tag: 'support_request_session'
};

const $inject = [
  "$q",
  "$window",
  "Made",
  "dialogService"
];

export default class SupportService {
  constructor($q, $window, Made, dialogService) {
    this.$q = $q;
    this.$window = $window;
    this.Made = Made;
    this.dialogService = dialogService;
  }

  getSupportCategories(order = []) {
    let categories = angular.copy(SUPPORT_CATEGORIES);
    if (order.length) {
      let newOrder = [];
      order.forEach(c => {
        newOrder.push(categories[c]);
      });
      return newOrder;
    }
    return categories;
  }

  openSupportRequestDialog() {
    let support_dialog_config = {
      template: support_request_dialog_html(),
      plain: true,
      controller: 'SupportDialogController',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-default control_dialog cocpkit-panel ',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    };

    //in case the support page is opened on mobile it need to be fullscreen
    if (this.$window.innerWidth <= 768) {
      support_dialog_config.width = '100%';
      support_dialog_config.height = '100%';
      support_dialog_config.className = support_dialog_config.className + ' vn-dialog-fullscreen';
    }

    this.dialogService.ngDialog.openConfirm(support_dialog_config);
  }

  sendSupportRequest(support_form, attachment_files = []) {

    let file_names = attachment_files.map(file => {
      return file.name;
    });

    return this.Made.request('rpc://administration/support/send_support_request', {
      valuenet_id: this.Made.user.valuenet_id,
      support_form: support_form,
      file_names: file_names
    }).finally(() => {
      let SUPPORT_REQUEST_FILE_TAGS = [
        SUPPORT_REQUEST_TAG.main_tag,
        SUPPORT_REQUEST_TAG.session_tag + '-' + new Date().getTime() / 1000
      ];

      let promises = [];
      for (let i = 0; i < attachment_files.length; i++) {
        promises.push(this.Made.upload(attachment_files[i], SUPPORT_REQUEST_FILE_TAGS, this.Made.user.valuenet_id));
      }
      return this.$q.all(promises)
        .then((res) => {
          return res;
        }).catch((err) => {
          console.error(err);
          return false;
        });
    });

  }
}

SupportService.$inject = $inject;