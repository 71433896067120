const $inject = [
  '$scope',
  '$state',
  'BenefitBonagoService',
  'customerService',
  'ShopService',
  '$timeout',
  'Made',
  'authenticationService'
];

export class BonagoSideController {
  constructor(
    $scope,
    $state,
    BenefitBonagoService,
    customerService,
    ShopService,
    $timeout,
    Made,
    authenticationService
  ) {
    Object.assign(this, {
      $scope,
      $state,
      BenefitBonagoService,
      customerService,
      ShopService,
      $timeout,
      Made,
      authenticationService
    });

    this.loading = {
      main: true
    };
    this.models = {};
    this.user_has_code_id = true;
    this.isBonagoHome = this.$state.current.name == 'inApp.benefit.bonago.side.home';
    this.$scope.isBonagoHome = this.isBonagoHome;

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.models = {};
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.user_balance = await this.BenefitBonagoService.getUserBalance();
    this.basket = this.BenefitBonagoService.getBasket();

    if (this.isBonagoHome) {
      this.basketItems = this.basket.getItemsGroupedByRetailer();
    } else {
      this.basketItems = this.basket.getItems();
    }

  }

  showVoucherDetails(voucher) {
    // console.log("vouche", voucher);
    this.$state.go('inApp.benefit.bonago.side.details', {retailerId: voucher.retailer_id});
  }

  isUserCodeIdForSelectedScheme() {
    // if employee has NEO SACHBEZUG with Bonago we are always showing only that scheme
    if (this.BenefitBonagoService.user_checks.employee_has_sachbezug_with_bonago) {
      return true;
    }

    // in preview mode but he could have had a code assigned
    return this.BenefitBonagoService.user_checks.has_valid_user_code &&
      this.BenefitBonagoService.user_checks.user_code_scheme_id === this.BenefitBonagoService.user_scheme.scheme_id;
  }

  isUserChecksPassed() {
    return this.BenefitBonagoService.user_checks.has_valid_user_code;
  }

  canCheckout() {
    if (this.basket) {
      return this.basket.hasItems()
        && this.getRest() >= 0
        && this.isUserChecksPassed()
        && this.isUserCodeIdForSelectedScheme();
    } else {
      return false;
    }
  }

  getRest() {
    if (this.basket) {
      return this.user_balance - this.basket.total;
    } else {
      return this.user_balance;
    }
  }

  startCheckout() {
    this.$state.go('inApp.benefit.bonago.side.checkout');
  }
}

BonagoSideController.$inject = $inject;
angular.module('VSPApp').controller('BonagoSideController', BonagoSideController);


