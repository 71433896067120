import { DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS } from '../../../../services/mapsService';

const $inject = [
  '$scope',
  '$state',
  'BenefitBonagoService',
  'customerService',
  '$timeout',
  'MapsService',
  '$location'
];

class BenefitBonagoDetailsController {
  constructor(
    $scope,
    $state,
    BenefitBonagoService,
    customerService,
    $timeout,
    MapsService,
    $location
  ) {
    Object.assign(this, {
      $scope,
      $state,
      BenefitBonagoService,
      customerService,
      $timeout,
      MapsService,
      $location
    });

    this.loading = {
      main: false,
      details: false
    };

    this.DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS = DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS;

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async getDetails() {
    this.$timeout(() => {
      this.loading.details = true;
    });


    return this.BenefitBonagoService
      .getRetailer(this.retailerId)
      .then(retailer => {
        this.retailer = retailer;

        this.retailer.voucherItems.forEach(voucher_item => {
          let voucher_item_id = voucher_item.id;
          let voucher_item_basket_quantity = this.basket.getItemQuantity(voucher_item_id);
          if (voucher_item_basket_quantity) {
            this.models.quantity[voucher_item_id] = voucher_item_basket_quantity;
          } else {
            this.models.quantity[voucher_item_id] = 0;
          }
        });
      })
      .then(() => {
        // create google map for retailer
        this.is_maps_allowed = this.isMapsAllowed();

        if (this.is_maps_allowed) {
          let retailerAdresses = angular.copy(this.retailer.addresses);
          this.selected_street = retailerAdresses[0].street;
          this.selected_address = retailerAdresses[0];
          this.selected_options = retailerAdresses.map(address => ({
            name: `${address.street}, ${address.city}`,
            value: address.street
          }));

          let home_url = this.$location.protocol() + '://' + location.host;
          let icon_url = `${home_url}/assets/img/maps/default_map_icon_image.png`;

          this.staticMap = this.generateStaticMapUrl(retailerAdresses[0]);
        }

      })
      .catch((e) => {
        console.log('err', e);
        this.$state.go('inApp.benefit.bonago.side.home');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.details = false;
        });
      });
  }

  isMapsAllowed() {
    let is_category_with_maps = this.retailer.categories.includes(this.DEFAULT_CATEGORY_ID_FOR_RETAILERS_WITH_MAPS);
    let is_maps_enabled = this.BenefitBonagoService.user_scheme.enable_google_maps;
    let retailer_has_addresses = !!(this.retailer.addresses && this.retailer.addresses.length);
    return !!(is_category_with_maps && is_maps_enabled && retailer_has_addresses);
  }

  generateStaticMapUrl(address) {
    return `https://maps.googleapis.com/maps/api/staticmap?markers=${address.lat},${address.lng}&zoom=17,4&size=300x300&map_id=${this.MapsService.getDetailsMapId()}&key=AIzaSyBzm2AJUd6IhAh34SSFlqhsvwy5OdHH7Uc`;
  }

  $onDestroy() {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }

  goToOverviewMap() {
    this.$state.go('inApp.benefit.bonago.retailersOverviewMap', {
      lat: this.selected_address.lat,
      lng: this.selected_address.lng
    });
  }

  async init() {
    this.loading.main = true;

    let promises = [];
    this.retailerId = this.$state.params.retailerId;
    this.basket = this.BenefitBonagoService.getBasket();
    this.forms = {};
    this.models = {
      quantity: {}
    };

    this.subscriptions = [
      this.BenefitBonagoService.communication.subscribe({
        next: async (msg) => {
          if (msg === 'user_selected_scheme_update') {
            await this.getDetails();
          }
        }
      })
    ];

    promises.push(
      this.customerService
        .isTestmode()
        .then(testmode => this.$scope.testmode = testmode)
    );

    promises.push(
      this.getDetails()
    );

    return Promise.all(promises);

  }

  selectRetailerAddress() {
    this.selected_address = [...this.retailer.addresses].find(address => address.street == this.selected_street);
    this.staticMap = this.generateStaticMapUrl(this.selected_address);
  }

  addVoucherToBasket(voucher_item) {
    let voucher_item_id = voucher_item.id;
    if (this.models.quantity[voucher_item_id] > 0) {
      this.basket.addItem(voucher_item_id, voucher_item, this.retailer, this.models.quantity[voucher_item_id]);
    } else {
      this.basket.removeItem(voucher_item_id);
    }
  }

  addVouchersToBasket() {
    this.retailer.voucherItems.forEach(voucher_item => {
      this.addVoucherToBasket(voucher_item);
    });
  }

}

BenefitBonagoDetailsController.$inject = $inject;

angular
  .module('VSPApp')
  .controller('BenefitBonagoDetailsController', BenefitBonagoDetailsController);
