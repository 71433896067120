
// right now the only thing we care about is whether it is explicitly available or not
export let ShippingInfo = Object.freeze({
  'AVAILABLE': 'available',
  'OTHER': 'other' 
})

export class ArticleAvailability {

  /**
   * @param {number} supply
   * @param {string} shippingInfo
   */
  constructor(supply, shippingInfo) {
    this._supply = supply;
    this._shippingInfo = shippingInfo;
  }

  /**
   * @param {ArticleAvailability} other
   * @returns {boolean}
   */
  equals(other) {
    return other._supply === this._supply && other._shippingInfo === this._shippingInfo;
  }
  
  get shippingInfo() {
    return this._shippingInfo;
  }
  
  get isDropShipping() {
    return this._supply === 0 && this._shippingInfo === ShippingInfo.AVAILABLE;
  }
  
  get isAvailableForCheckout() {
    return this.isDropShipping || this._supply >= 2;
  }
  
}
