import {CHURCH_TAX_TYPE_BY_VALUE, DEFAULT_CHURCH_TAX_TYPE} from '../default_data/data';

const $inject = [
  '$scope'
];

export default class BudgetFormController {
  constructor($scope){
    Object.assign(this, {$scope});

    this.forms = {};
    this.API = {
      calculateTotal: () => { this.calculateTotalInternal(); }
    };
    this.$onInit = () => {
      if ( !this.fields ) {
        throw new Error('Can\'t work without fields');
      }

      if ( this.direct == null ){
        //this.direct = angular.copy(DEFAULT_DIRECT_BUDGET);
        throw new Error('Can\'t work without direct');
      }

      if ( this.forms == null ){
        this.forms = {};
      }

      if ( this.formName == null ) {
        this.formName = 'omg';
      }

      if ( this.papR == null) {
        this.papR = DEFAULT_CHURCH_TAX_TYPE.value;
      }

      if (typeof this.passApi === 'function') {
        this.passApi(this.API);
      }

      if (this.allowedFields != null && Array.isArray(this.allowedFields)){
        let budget_fields = angular.copy(this.direct_budget_fields);
        budget_fields = budget_fields.filter(budget_field => this.allowedFields.includes(budget_field.prop));
        this.direct_budget_fields = budget_fields;
      }

      if ( this.preselected != null ){
        if ( typeof this.preselected === 'string') {
          try {
            this.direct[this.preselected].active = true;
          } catch (e) {
            console.error(e);
          }
        }
      }

      this.calculateTotalInternal();
    };

    $scope.$watch(()=>{return this.fields;}, (newValue, oldValue)=>{
      if (!angular.equals(newValue, oldValue)){
        let allowed_props = newValue.map(field => field['prop']);
        this.direct = Object.keys(this.direct).reduce(( acc, component_key ) => {
          let component_selection = this.direct[component_key];

          if (!['total', 'ag_cost_total'].includes(component_key)){
            if (!allowed_props.includes(component_key)){
              component_selection.active = false;
            }
          }

          acc[component_key] = component_selection;
          return acc;
        }, {});
        this.calculateTotalInternal();
      }
    });

    $scope.handleActivationChange = (field_data) => {
        let isActivated = field_data.active;
        if (isActivated){
          $scope.activateExtra(field_data);
        } else {
          $scope.deactivateExtra(field_data);
        }
    };

    $scope.deactivateExtra = (field_data) => {
      this.calculateFieldResult(field_data);
    };

    $scope.activateExtra = (field_data) => {
      // do on-activation actions
      if (field_data.on_activation && angular.isArray(field_data.on_activation.disable)) {
        field_data.on_activation.disable.forEach((field) => {
          this.direct[field].active = true;
        });
      } if (field_data.on_activation && field_data.on_activation.disable) {
        this.direct[field_data.on_activation.disable].active = false;
      }

      this.calculateFieldResult(field_data);
    };

    this.calculateFieldResultInternal = (field_data) =>{
      if (field_data && field_data.active) {
        field_data.result = field_data.value * (field_data.per_item_price || 1);
      }
    };

    this.calculateFieldResult = (field_data) => {
      this.calculateFieldResultInternal(field_data);
      this.calculateTotalInternal();
    };
    $scope.calculateFieldResult = this.calculateFieldResult;

    this.calculateTotalInternal = () => {
      let total = 0;
      let ag_cost_total = 0;
      let keys = Object.keys(this.direct);
      for (let i = 0; i < keys.length; i++) {
        let value = this.direct[keys[i]];
        if (value.active && value.value) {
          if(typeof value.result === 'undefined'){
            this.calculateFieldResultInternal(value);
          }
          total += value.result || 0;
        }
        if (value.active && value.value && value.ag_cost) {
          ag_cost_total += value.value * (value.ag_cost[CHURCH_TAX_TYPE_BY_VALUE[this.papR].id] || 1) * (value.multiplier || 1);
        }
      }
      this.direct.total = total;
      this.direct.ag_cost_total = ag_cost_total;
    };



    $scope.keys = Object.keys;
  }
}

BudgetFormController.$inject = $inject;
