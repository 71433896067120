const $inject = [
  'Made',
  'ACLService',
  'menuService',
  'UserlaneService',
  'customerService'
];

export class YearlyContoller {

  constructor(
    Made,
    ACLService,
    menuService,
    UserlaneService,
    customerService
  ) {

    this.Made = Made;
    this.ACLService = ACLService;
    this.menuService = menuService;
    this.UserlaneService = UserlaneService;
    this.customerService = customerService

    if (Object.keys(this.Made.user).length !== 0) {
      this.init();
    }
  }

  async init() {
    this.customerService.getCustomerSpecificUserlaneTags().then((customerSpecificUserlaneTags) => {
      this.menuService.get()
        .then(item => item.map(val => val.data.path))
        .then(paths => {
          this.userPaths = paths.concat(customerSpecificUserlaneTags);
          return this.UserlaneService.load(this.userPaths);
        });
    });
  }
}

YearlyContoller.$inject = $inject;
