const $inject = [
  '$scope'
];
export default class confirmationInfoDialogController {
  constructor(
    $scope
  ) {
    Object.assign(this, {
      $scope
    });

    this.$scope.bonus_project = this.$scope.ngDialogData.bonus_project;
    let for_employees = this.$scope.bonus_project.for_employees;
    this.$scope.number_of_employees = Object.keys(for_employees).length ? Object.keys(for_employees).length : 0;
  }
}
confirmationInfoDialogController.$inject = $inject;
