const $inject = [
  '$q', 
  'Made'
];
export default class BenefitAccountService {
  constructor($q, Made) {
    Object.assign(this, {
      $q,
      Made
    });
  }

  getBalance(employeeId) {
    return this.$q.when({
      balance: 100
    });
  }

  getTransactions(employeeId) {
    return this.$q.when({
      transactions: [
        {
          id: 1,
          timestamp: 1538558023,
          type: "anything",
          service: "anything",
          value: 10,
          comment: "p",
          basket: 10
        }
      ]
    });
  }

  getVorteilskonto(employeeId) {
    return this.$q
      .all([this.getBalance(employeeId), this.getTransactions(employeeId)])
      .then(([balance, transactions]) => {
        let analysis = transactions.transactions.reduce(
          (accumulator, transaction) => {
            if (transaction.value > 0) {
              accumulator.ladung =
                accumulator.ladung &&
                accumulator.ladung.value > transaction.value
                  ? accumulator.ladung
                  : transaction;
            } else {
              accumulator.nutzung =
                accumulator.nutzung &&
                accumulator.nutzung.value < transaction.value
                  ? accumulator.nutzung
                  : transaction;
            }

            return accumulator;
          },
          { ladung: null, nutzung: null }
        );

        return Object.assign({}, balance, transactions, {
          timestamp_ladung: analysis.ladung ? analysis.ladung.timestamp : null,
          timestamp_nutzung: analysis.nutzung ? analysis.nutzung.timestamp : null
        });
      });
  }
}

BenefitAccountService.$inject = $inject;