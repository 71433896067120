import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { LoadingService } from "../../../../shared/services/loading.service";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";
import { DticketOrderService } from "../../../services/dticket-order.service";
import { DTicketOrder } from "../../../models/dticket/dticket-order.model";
import { ConfirmationDialogComponent } from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { UIRouterGlobals } from "@uirouter/core";
import { AlertService } from '../../../../shared/services/alert.service';
import { DticketOrderActivateDialogComponent } from '../../../dialogs/dticket-order-user-preferences-dialog/dticket-order-activate-component/dticket-order-activate-dialog.component';
import {DigitalSignatureYouSignServiceV3} from "../../../../shared/services/digital-signature-you-sign.service";

interface FiltersFormData {
  dticketId: string | null;
  employeeId: any;
  customerId: any;
  checkoutDate: Date | null;
  beginDate: Date | null;
  ticketId: any;
}

type NavigateTo = 'neo' | 'bonus' | 'calc';


@Component({
  selector: 'vn-dticket-search-employees',
  templateUrl: './dticket-search-employees.component.html',
  styleUrls: ['./dticket-search-employees.component.scss']
})
export class DTicketSearchEmployeesComponent implements OnInit {

  formGroup!: FormGroup;
  dTicketOrders: any;
  countResults = 0;
  panelOpenState = false;
  routeParamEmployeeId: number | null;
  routedTicketId: string | null;
  preSearch: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private dTicketOrderService: DticketOrderService,
    private youSignV3Service: DigitalSignatureYouSignServiceV3,
    public loader: LoadingService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private route: UIRouterGlobals,
    @Inject('fileService') private fileService: any,
    @Inject('userService') private userService: any,
    @Inject('employeeService') private employeeService: any,
    @Inject('authenticationService') private authenticationService: any,
    private notificationService: AlertService,
  ) {
    this.routeParamEmployeeId = parseInt(this.route.params.employeeId) || null;

    this.routedTicketId = this.route.params.dticketId || null;
    this.preSearch = !!this.routeParamEmployeeId || !!this.routedTicketId

  }

  ngOnInit(): void {
    this.setData();
  }

  async setData() {
    this.loader.show();

    this.formGroup = this.formBuilder.group({
      dticketId: this.routedTicketId,
      employeeId: this.routeParamEmployeeId,
      customerId: null,
      checkoutDate: null,
      beginDate: null,
      ticketId: null,
    } as FiltersFormData);

    if (this.preSearch) {
      await this.onClickSubmit(this.formGroup.value);
    }

    this.loader.hide();
  }

  async onClickSubmit(formData: FiltersFormData, event?: Event) {
    event?.preventDefault();

    if (this.isFormEmpty()) {
      return;
    }
    const result = await this.loader.executePromisesInOrderAndShowLoading([
      () => this.dTicketOrderService.getDTicketOrdersForAdministration(formData)
    ]);
    this.dTicketOrders = result[0];

    this.countResults = Object.keys(this.dTicketOrders).length;
  }

  isFormEmpty() {
    const filterControls = Object.values(this.formGroup.controls);
    return filterControls.every(control => !control.value);
  }

  getDTicketOrder(data: any): DTicketOrder {
    return DTicketOrder.getInstanceFromVNMiddelware(data);
  }

  getKeys(obj: any) {
    if (obj) {
      const g = Object.keys(obj);
      return g.length ? g : [];
    }
    return [];
  }

  excludeKey(keys: any[], key: string) {
    return keys.filter(v => v !== key)
  }

  impersonate(employeeId: string) {
    this.userService.enterProxy(employeeId, '/administration/dticket');
  }

  async openContractFile(orderItem: DTicketOrder) {
    if (orderItem.isYouSignV3) {
      await this.openV3File(orderItem, 'signed');
    } else {
      this.fileService.openDocument(orderItem.contractFileId);
    }
  }

  async openV3File(orderItem: DTicketOrder, docType: 'audit_trail'|'signed') {
    const digitalSignature = await this.youSignV3Service.getDigitalSignatureByMongoID(orderItem.id);
    console.log("digitalSignature", digitalSignature);
    await this.youSignV3Service.openYouSignFileInNewTab(digitalSignature['yousign_request_id'], docType);
  }

  async openAuditFile(orderItem: DTicketOrder) {
    if (orderItem.isYouSignV3) {
      await this.openV3File(orderItem, 'audit_trail');
    } else {
      this.loader.show();
      const auditFile = await this.fileService.getDTicketAuditTrailFile(orderItem.id);
      this.loader.hide();
      this.fileService.openDocument(auditFile[0]._id);
    }
  }

  cancelTicket(item: DTicketOrder) {

    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Deutschlandticket stornieren",
        withDeclineReason: true,
        noButton: 'Zurück',
        yesButton: 'Storniervorgang bestätigen',
        reasonLabel: 'Mit der Bestätigung wird der Auftrag storniert.'
      }
    }).afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result && result.decision === 'with_reason') {
          this.ngZone.run(async () => {
            const r = await this.loader.executePromisesInOrderAndShowLoading<boolean | any>([
              () => this.dTicketOrderService.employeeCancelDTicketOrder(item.id),
              () => this.dTicketOrderService.getDTicketOrdersForAdministration(this.formGroup.value),
            ]);
            this.dTicketOrders = r[1];
          })
        }
      })
  }

  navigateTo(to: NavigateTo, employeeId: number) {

    const urlFactory = {
      'neo': `/administration/neo?employeeId=${employeeId}`,
      'bonus': `/administration/bonus?employeeId=${employeeId}`,
      'calc': `/berater?employeeId=${employeeId}`
    }

    window.open(
      urlFactory[to],
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  canRestoreTicket(dticket: DTicketOrder) {
    return dticket.canRestoreTicket && (
      this.userService.iAmProxy() ||
      this.authenticationService.iAm('advisor') ||
      this.authenticationService.iAm('special_advisor')
    );
  }

  async restoreTicket(item: DTicketOrder) {
    try {
      const empData = await this.employeeService.get(item.employeeId);
      this.dialog.open(DticketOrderActivateDialogComponent, {
        panelClass: "userPreferencesDialogResponsive",
        data: {
          dticketOrder: item,
          employeeData: {
            'firstName': empData.firstname,
            'lastName': empData.lastname,
            'birthDate': empData.birthdate,
            'employeeId': item.employeeId
          },
        }
      }).afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.ngZone.run(async () => {
              const r = await this.loader.executePromisesInOrderAndShowLoading<boolean | any>([
                () => this.dTicketOrderService.getDTicketOrdersForAdministration(this.formGroup.value)
              ]);
              this.dTicketOrders = r[0];

              this.notificationService.message("Ihr Ticket wurde erfolgreich wiederhergestellt. Bitte folgen Sie den Anweisungen in der Email.");
            })
          }
        })
    } catch (e) {
      this.notificationService.error("Ihr Ticket konnte nicht wiederhergestellt werden.");
    }
  }
}
