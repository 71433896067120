import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {EMPTY, Observable} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import {LoadingService} from "../../../../shared/services/loading.service";


// ===============================================================

interface FormOptions {
}

interface FormChoices {
}

// ===============================================================

@Component({
  selector: 'vn-direct-bonus-invoice-dialog',
  templateUrl: './direct-bonus-invoice-dialog.component.html',
  styleUrls: ['./direct-bonus-invoice-dialog.component.scss']
})

export class DirectBonusInvoiceDialogComponent implements OnInit {
  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public filtersGroup!: FormGroup;
  public filterOptions!: FormOptions;
  public confirmFlag$: Observable<boolean> = this.directBonusService.canBoMarkOrderForInvoice(this.orderId);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectBonusInvoiceDialogComponent>,
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private loader: LoadingService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
  ) {
  }

  ngOnInit(): void {
    this.initFilterOptions();
    this.initFiltersForm();
  }

  private initFiltersForm() {

    this.filtersGroup = this.formBuilder.group({});
  }

  private initFilterOptions() {
    this.filterOptions = {
      paymentType: [
        {
          name: 'S€PA-Lastschrift',
          value: 'sepa'
        },
        {
          name: 'Banküberweisung',
          value: 'not_sepa'
        }
      ]
    };
  }

  public markOrderForInvoice() {
    this.loader.show();
    this.directBonusService.markOrderForInvoice(
      this.orderId,
    ).subscribe(
        (data: any) => {
          this.loader.hide();
            const message = 'Sie haben die ausgewählte(n) Bestellung(en) als fakturiert markiert. Eine Datei im CSV-Format für Ihr Fakturierungsprogramm wurde heruntergeladen.';
            this.alertService.message(message);
            this.dialogRef.close(true);
        },
        (error: Error) => {
          this.loader.hide();
          this.alertService.error(`Es ist ein Fehler aufgetreten.`);
        }
      );
  }


}

