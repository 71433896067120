import deleteBonusDialog from './deletebonus.pug'

angular
  .module('VSPApp')
  .controller('LocationController', [
    '$scope',
    'ngDialog',
    'nameFilter',
    'vcpModel',
    'Made',
    'MonthsService',
    'VCPBonusService',
    function (
      $scope,
      ngDialog,
      nameFilter,
      vcpModel,
      Made,
      MonthsService,
      VCPBonusService
    ) {

      MonthsService.resetMonth();

      vcpModel.getData = function (city) {

        if (city) {
          vcpModel.getCitySums(
            city,
            MonthsService.year,
            MonthsService.month,
            true
          );
          vcpModel
            .getCity(city)
            .then(function () {
              VCPBonusService.hasUnapproved(city.persons);
            });
        } else {
          vcpModel.initialize(
            MonthsService.year,
            MonthsService.month,
            true
          );
        }

        VCPBonusService.getOverview();
      };

      vcpModel.getData();

      $scope.currentCity = '';
      $scope.currentPerson = '';

      $scope.nameFilter = nameFilter;
      $scope.vcpModel = vcpModel;

      $scope.getOpenAmount = VCPBonusService.getOpenAmount;
      $scope.months = MonthsService.months;
      $scope.getMonthName = MonthsService.getMonthName;

      $scope.bonusYear = MonthsService.getNextPaymentDate()[0];
      $scope.bonusMonth = MonthsService.getNextPaymentDate()[1];

      VCPBonusService
        .reasonsPromise
        .then(function () {
          $scope.bonusReasons = VCPBonusService.reasons;
        });

      $scope.toggleCity = function (city) {
        city.open = !city.open;

        if (city.open)
          $scope.vcpModel
            .getCity(city, false)
            .then(function () {

              VCPBonusService.hasUnapproved(city.persons);

              // if ( VCPBonusService.reasons[ city.cluster ] ) {
              //   $scope.bonusReasons = VCPBonusService.reasons[ city.cluster ];
              // } else {
              //   $scope.bonusReasons = [];
              // }
            });
      };

      $scope.togglePerson = function (id) {
        if ($scope.currentPerson == id) {
          $scope.currentPerson = '';
        } else {
          $scope.currentPerson = id;
        }
      };

      $scope.reasonChange = function () {
        if ('object' === typeof this.reason && this.reason.amount) {
          this.amount = this.reason.amount;
        }
      };

      $scope.submitBonus = function (city) {
        var amount = parseInt(this.amount) * 100 || 0,
          reason = (this.reason && this.reason.reason) || '',
          custom = this.custom || '',
          year = $scope.bonusYear,
          month = $scope.bonusMonth,
          that = this;

        if (custom) {
          reason = reason + ' ' + custom;
        }

        if (undefined !== this.von) {
          month = this.von.month + 1;
          year = this.von.year;
        }

        if (!reason || amount < 1) {
          return;
          //TODO: inform user that there needs to be a reason for bonus payment..
        }

        if ('object' === typeof reason && reason.reason) {
          reason = reason.reason;
        }

        VCPBonusService
          .requestByUser(this.person.id, amount, reason, month, year)
          .then(function () {

            that.custom = '';
            that.reason = {};
            that.amount = '';
            that.von = {
              year: $scope.bonusYear,
              month: $scope.bonusMonth
            };

            vcpModel.getData(city);
          });

      };

      $scope.deleteBonus = function (bonus) {
        var that = this;

        ngDialog
          .openConfirm({
            template: deleteBonusDialog(),
            plain: true,
            className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
            showClose: true
          })
          .then(function () {
            return VCPBonusService.delete(bonus);
          })
          .then(function () {
            vcpModel.getData(that.city);
          });
      };

      $scope.deletePerson = function (employee_id, city) {
        ngDialog
          .openConfirm({
            className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
            showClose: true,
            template: '/modules/vcp/location/delete.html'
          })
          .then(() => Made
            .request('rpc://valuenetdb/employee/delete', {
              employee_id
            })
          )
          .then(() => {
            vcpModel.getData(city);
          });
      };

      // $scope.dateChange = function ( von ) {
      //   var now = new Date();
      //   if ( von.year <= now.getFullYear() && von.month < now.getMonth() ) {
      //     von.year = now.getFullYear() + 1;
      //   }
      // };

    }]);
