<label>{{ title }}</label>

<input
    type="email"
    [value]="value"
    [title]="title"
    placeholder="E-Mail-Addresse"
    [attr.data-cy]="dataCyInput"
    [disabled]="isDisabled"
    [required]="isRequired"
    (keyup)="handleEmailInputChange($event.target.value)"
>

<button
    class="button"
    type="button"
    [disabled]="!isAllowToProve()"
    (click)="checkEmailValidity()"
    [attr.data-cy]="dataCyButton"
    *ngIf="!isProved"
>
    Prüfen
</button>

<button
    class="button checked"
    type="button"
    *ngIf="isProved"
>
    <mat-icon>check_circle_outline</mat-icon>
</button>

<div class="error">
    {{errorMessage}}
</div>

<div *ngIf="isCompanyEmailAllowed && isCompanyEmail">
    <vn-company-email-checkbox
            switchKey="benefit.bonago.checkout.basketOverview.checkCompanyEmail"
            (isCompanyEmailValid$)="handleCompanyEmailValidation($event)">
    </vn-company-email-checkbox>
</div>

