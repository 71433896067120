import datenschutz_html from '../../modules/landing/datenschutz.pug';
import datenschutz_intern_html from '../../modules/landing/datenschutz-intern.pug';

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('datenschutz', {
        url: '/datenschutz',
        template: datenschutz_html
      });

    $stateProvider
      .state('datenschutzintern', {
        url: '/datenschutz-intern',
        template: datenschutz_intern_html
      });

  }]);
