export const BONUS_PROJECT_TYPES = [
  {
    id: 1,
    name: 'direct_bonus',
    display: 'DirektBonus',
    description: 'DirektBonus',
    is_enabled: true,
    for_employees: {
      is_enabled: false
    }
  },
  {
    id: 2,
    name: 'one_time_bonus',
    display: 'EinmalBonus',
    description: 'EinmalBonus',
    is_enabled: true,
    for_employees: {
      is_enabled: true
    }
  },
  {
    id: 3,
    name: 'long_running_bonus',
    display: 'DauerBonus',
    description: 'DauerBonus',
    is_enabled: false
  },
  {
    id: 4,
    name: 'fiducia_bonus',
    display: 'FiduciaBonus',
    description: 'FiduciaBonus',
    is_enabled: true,
    for_employees: {
      is_enabled: false
    }
  },
  {
    id: 5,
    name: 'bgm_bonus',
    display: 'BGM Bonus Project',
    description: 'BGM Only Bonus Project',
    is_enabled: true,
    for_employees: {
      is_enabled: true
    }
  }
];
export const BONUS_PROJECT_TYPES_BY_ID = BONUS_PROJECT_TYPES.reduce((acc, bonus_type) => {
  acc[bonus_type.id] = bonus_type;
  return acc;
}, {});
export const BONUS_PROJECT_TYPES_BY_NAME = BONUS_PROJECT_TYPES.reduce((acc, bonus_type) => {
  acc[bonus_type.name] = bonus_type;
  return acc;
}, {});
