import {Component, Inject, OnInit} from '@angular/core';
import {AlertService} from "../../../shared/services/alert.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CarLeasingApiService} from "../../services/car-leasing-api.service";
import {BaseComponent} from "../../../shared/models/base.component";

@Component({
  selector: 'vn-car-leasing-fin-data-dialog',
  templateUrl: './car-leasing-fin-data-dialog.component.html',
  styleUrls: ['./car-leasing-fin-data-dialog.component.scss']
})
export class CarLeasingFinDataDialogComponent extends BaseComponent implements OnInit {

  public formGroup!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private carLeasingService: CarLeasingApiService,
    public dialogRef: MatDialogRef<CarLeasingFinDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {basketId: string},
  ) {
    super()
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      orderId: this.formBuilder.control('', [Validators.required]),
      contactId: this.formBuilder.control('', [Validators.required]),
    });
  }

  saveFinData() : void {
    let {orderId, contactId} = this.formGroup.value;

    this.setLoading$(true);

    this.carLeasingService.addFinnData(
      this.dialogData.basketId,
      contactId,
      orderId
    )
      .then(() => {
        this.alertService.message('Erfolgreich');
        this.dialogRef.close(true);
      })
      .catch(e => this.alertService.error('Fehlgeschlagen'))
      .finally(() => this.setLoading$(false));
  }

}
