var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (containerAttributes, fieldsetCounter, fileInputAttributes, globals, id, validationId) {
      pug_mixins["dropzone"] = pug_interp = function(model, single, id, tags, employeeId, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
containerAttributes = {}
fileInputAttributes = {}
if (single) {
containerAttributes = { 'made-dropzone-single' : 'true' }
fileInputAttributes = { 'made-file-single' : 'true' }
}
else {
fileInputAttributes = { multiple : 'multiple' }
}
if (!id) {
id = 'uploader'
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "dropzone-container","made-dropzone": pug.escape(model)},containerAttributes,attributes]), true)) + "\u003E\u003Cdiv class=\"dropzone-inner-container\"\u003E";
if (employeeId) {
pug_html = pug_html + "\u003Cfile-chooser" + (pug.attr("files", model, true, true)+pug.attr("filter-tags", tags, true, true)+pug.attr("employee-id", employeeId, true, true)) + "\u003E\u003C\u002Ffile-chooser\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + ("\u003Cdiv class=\"drag-and-drop-label\"\u003E\u003Cimg src=\"\u002Fassets\u002Fimg\u002Fsvg\u002Ficon-download.svg\"\u003E" + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp)));
if (single) {
pug_html = pug_html + "\u003Cspan\u003EDatei hierherziehen\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003EDateien hierherziehen\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E\u003Cdiv class=\"file-input\"\u003E\u003Cinput" + (pug.attrs(pug.merge([{"id": pug.escape(id),"type": "file","made-file-input": pug.escape(model),"style": "visibility:hidden","data-cy": pug.escape(cyData)},fileInputAttributes]), true)) + "\u003E";
if (single) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDatei auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
else {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDateien auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"files\"\u003E";
if (block) {
block && block();
}
else
if (single) {
pug_html = pug_html + "\u003Cspan" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)+pug.attr("ng-click", model + '=undefined', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("ng-if", model +'._id', true, true)+pug.attr("ng-href", '/gridfs?id={{'+ model +'._id}}', true, true)+" target=\"_blank\" rel=\"noreferrer\"") + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"files\""+pug.attr("ng-repeat", '(index, file) in ' + model, true, true)) + "\u003E\u003Cspan ng-if=\"!file._id\"\u003E{{ file.name }} ({{ file.size | filesize }})\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-click", model + '.splice(index, 1)', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca ng-if=\"file._id\" ng-href=\"\u002Fgridfs?id={{ file._id }}\" target=\"_blank\" rel=\"noreferrer\"\u003E{{ file.filename }} ({{ file.meta.created *1000 | date }}, {{ file.length | filesize }})\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};































































pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};






































































pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"cockpit-datev-create\"\u003E";
globals = {}
pug_html = pug_html + "\u003Cform class=\"form-padding\" name=\"datevform\" ng-submit=\"CDatevCreateCtrl.onSubmit()\"\u003E\u003Cdiv class=\"bigbox centered\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EInhalte\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E";
pug_mixins["input"].call({
attributes: {"type": "text","key": "article.title","title": "Titel","required": pug.escape(true)}
});
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6 small-12\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"title": "Anzeigen","key": "article.showArticle"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Clabel for=\"datev-content\"\u003EInhalt\u003C\u002Flabel\u003E\u003Cdiv class=\"quill-editor\"\u003E\u003Cng-quill-editor class=\"quill-content\" id=\"datev-content\" ng-model=\"article.content\" required=\"true\" ng-required=\"true\" editor-required=\"true\" theme=\"snow\"\u003E\u003C\u002Fng-quill-editor\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EBühnenbild\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cp\u003Eoptimale Bildmaße: 1920 x 410px\u003C\u002Fp\u003E";
pug_mixins["dropzone"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"files\" ng-if=\"article.stage\"\u003E\u003Cspan ng-if=\"!article.stage._id\"\u003E{{ article.stage.name }} ({{ article.stage.size | filesize }})\u003C\u002Fspan\u003E\u003Ca ng-if=\"article.stage._id\" ng-click=\"CDatevCreateCtrl.openDocument(article.stage)\"\u003E{{ article.stage.name }} ({{ article.stage.size | filesize }})\u003C\u002Fa\u003E\u003Ca ng-click=\"article.stage=null\"\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}, 'article.stage', true, 'datev-stage-image');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bigbox centered\"\u003E\u003Cdiv class=\"header\"\u003E\u003Cspan\u003ETeaserbild\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cp\u003Eoptimale Bildmaße: 530 x 325px\u003C\u002Fp\u003E";
pug_mixins["dropzone"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"files\" ng-if=\"article.teaser\"\u003E\u003Cspan ng-if=\"!article.teaser._id\"\u003E{{ article.teaser.name }} ({{ article.teaser.size | filesize }})\u003C\u002Fspan\u003E\u003Ca ng-if=\"article.teaser._id\" ng-click=\"CDatevCreateCtrl.openDocument(article.teaser)\"\u003E{{ article.teaser.name }} ({{ article.teaser.size | filesize }})\u003C\u002Fa\u003E\u003Ca ng-click=\"article.teaser=null\"\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}, 'article.teaser', true, 'datev-teaser-image');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"button button-primary\" type=\"submit\" ng-disabled=\"!CDatevCreateCtrl.$scope.datevform.$valid\"\u003ESpeichern\u003C\u002Fbutton\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "containerAttributes" in locals_for_with ?
        locals_for_with.containerAttributes :
        typeof containerAttributes !== 'undefined' ? containerAttributes : undefined, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "fileInputAttributes" in locals_for_with ?
        locals_for_with.fileInputAttributes :
        typeof fileInputAttributes !== 'undefined' ? fileInputAttributes : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;