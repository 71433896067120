<ng-container *ngIf="asExpandable else dropzone">
    <mat-accordion class="mat-elevation-z8">
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Dropzone
                </mat-panel-title>
                <mat-panel-description>
                    Toggle auf/zu
                </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-container *ngTemplateOutlet="dropzone; context: dropzoneContext">
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>

<ng-template #dropzone
             let-this>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar
                 class="dropzone-header-image">
                <mat-icon>
                    save_alt
                </mat-icon>
            </div>

            <mat-card-title>
                Dateiauswahl
            </mat-card-title>

            <mat-card-subtitle>
                {{ label }}
            </mat-card-subtitle>
        </mat-card-header>

        <div class='form-group'>
            <input #formInput
                [multiple]="multiple"
                type="file"
                [attr.data-cy]="this.key"
                (change)="this.handleFileInput($event.target.files)"
            />
        </div>

        <div id="drop-area" (click)="formInput.click()">
            Zum Hochladen klicken
        </div>

        <div id="files">
            <div *ngFor="let file of files; let idx = index">
                <p>{{ file.name }}</p>
                <mat-icon (click)="removeFile(idx)">delete_outline</mat-icon>
            </div>
        </div>

        <mat-card-actions>
            <button class='my-documents-button'
                    disabled
                    mat-stroked-button>
                Meine Unterlagen
            </button>

            <button class='choose-files-button'
                    (click)="this.formInput.click()"
                    mat-stroked-button>
                <label for="file">
                    Datei auswählen
                </label>
            </button>

            <button class='upload-files-button'
                    *ngIf="this.hasFiles && this.selfUpload"
                    [disabled]='this.isDisabled'
                    [attr.data-cy]="this.key + '.buttonUpload'"
                    mat-stroked-button
                    (click)="this.uploadFiles()">
                Hochladen
            </button>
        </mat-card-actions>
    </mat-card>
</ng-template>
