<h2 mat-dialog-title>
    <span>
        Bonus hinzufügen
    </span>

    <h3>
        Periode {{this.period}}
    </h3>
</h2>

<div>
    <mat-dialog-content>
        <form [formGroup]='formGroup'>
            <p>
                Sie fügen den folgenden Bonus der Bestellung hinzu:
            </p>

            <vn-input formControlName='employeeId'
                      key="vn.directBonus.backofficeAdministration.addBonusDialog.inputEmployeeId"
                      label='ValueNet ID'
                      [debounceTime]="1000">
                <mat-error *ngIf="employeeId.hasError('required')">
                    Eingabe fehlt.
                </mat-error>
            </vn-input>

            <div *ngIf="!hasAvailableForCheckoutComponents">
              OMG THIS IS THE BEST USER
            </div>

            <div *ngIf="selectedComponentName">

                <pre> Name : {{ employeeName$ | async }}</pre>
                <vn-select formControlName='componentName'
                           label="Komponentenauswahl"
                           key="vn.directBonus.backofficeAdministration.addBonusDialog.selectBonusComponent"
                           [withPlaceholder]="false"
                           [initValue]="selectedComponentName"
                           [options]='formOptions.componentName'>
                </vn-select>

                <vn-input formControlName='bonusValue'
                          key="vn.directBonus.backofficeAdministration.addBonusDialog.inputBonusAmount"
                          label="Bonuswert"
                          type="number"
                          [placeholder]='componentStandardValue'>
                    <mat-error *ngIf="bonusValue.hasError('required')">
                        Eingabe fehlt.
                    </mat-error>

                    <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('min')">
                        Betrag darf nicht negativ sein.
                    </mat-error>

                    <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('max')">
                        Betrag darf nicht größer {{this.componentMaxValue}} sein.
                    </mat-error>

                    <mat-error *ngIf="!bonusValue.hasError('required') && bonusValue.hasError('pattern')">
                        Bitte geben Sie Euro-Beträge im Format 12345,67 an.
                    </mat-error>
                </vn-input>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button
                mat-dialog-close>
            Abbrechen
        </button>

        <button class='save-button'
                data-cy="vn.directBonus.backofficeAdministration.addBonusDialog.buttonSaveBonus"
                mat-stroked-button
                [disabled]="!formGroup.valid"
                (click)="saveBonus()">
            Speichern
        </button>
    </mat-dialog-actions>
</div>
