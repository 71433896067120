export const BONUS_PROJECT_STATES = [
  {
    id: 1,
    name: 'draft',
    display: 'Entwurf',
    description: 'Created, but not confirmed',
    color: 'red'
  },
  {
    id: 2,
    name: 'confirmed',
    display: 'Aktiv',
    description: 'Created and confirmed, not published',
    color: 'yellow'
  },
  {
    id: 3,
    name: 'published',
    display: 'Published',
    description: 'Created, confirmed and published',
    color: 'green'
  },
];
export const BONUS_PROJECT_STATES_BY_ID = BONUS_PROJECT_STATES.reduce((acc, bonus_state) => {
  acc[bonus_state.id] = bonus_state;
  return acc;
}, {});
export const BONUS_PROJECT_STATES_BY_NAME = BONUS_PROJECT_STATES.reduce((acc, bonus_state) => {
  acc[bonus_state.name] = bonus_state;
  return acc;
}, {});
export const DEFAULT_BONUS_PROJECT_STATE = BONUS_PROJECT_STATES_BY_NAME['draft'];
