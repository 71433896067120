var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_html = pug_html + "\u003Cdiv ng-if=\"employeeIntegrity.type==&quot;data&quot;\"\u003E";
pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};



















































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Ch1\u003EDaten vervollständigen\u003C\u002Fh1\u003E\u003Cp ng-if=\"!employeeIntegrity.isCheckout\"\u003ESehr geehrter Nutzer, es freut uns, dass Sie nun mit der Nutzung Ihrer Vorteile starten möchten.\n\u003Cbr\u003E\nDamit wir Ihnen auch korrekte Berechnungen sowie richtige Unterlagen anzeigen können, benötigen wir von Ihnen noch ein paar Daten, die uns Ihr Arbeitgeber noch nicht übermitteln konnte.\n\u003Cbr\u003E\nSollten Sie die Daten nicht angeben wollen, so können Sie den Vorgang auch abbrechen, eine Nutzung des Portals ist dann aber nicht möglich.\u003C\u002Fp\u003E\u003Cp ng-if=\"employeeIntegrity.isCheckout\"\u003ESehr geehrter Nutzer, es freut uns, dass Sie nun mit der Nutzung Ihrer Vorteile starten möchten.\n\u003Cbr\u003E\nDamit wir Ihnen auch korrekte Berechnungen sowie richtige Unterlagen anzeigen können, benötigen wir von Ihnen noch ein paar Daten.\u003C\u002Fp\u003E\u003Cbr\u003E\u003Cdiv class=\"dialog-content-wrapper\" overlay=\"employeeIntegrity.working\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Ch4\u003E{{ employeeIntegrity.employee.firstname }} {{ employeeIntegrity.employee.lastname }}\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"birthdateInput\" date-refocus=\"true\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeIntegrity.integrityData.data.geburtsdatum","title": "Geburtsdatum","required": pug.escape(true),"attributes": {"vn-date-helper":"true","ng-model-options":"{allowInvalid: true}","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","tabindex":1},"fieldHtmlClass": "birthdateInput","validationMessages": {"required":"Bitte Geburtsdatum angeben","pattern":"Bitte Geburtsdatum im Format tt.mm.jjjj eingeben"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"entrydateInput\" date-refocus=\"false\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeIntegrity.integrityData.data.eintritt","title": "Datum Firmeneintritt","required": pug.escape(true),"attributes": {"vn-date-helper":"true","ng-model-options":"{allowInvalid: true}","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","tabindex":2},"fieldHtmlClass": "entrydateInput","validationMessages": {"required":"Bitte Eintrittsdatum angeben","pattern":"Bitte Eintrittsdatum im Format tt.mm.jjjj eingeben"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeIntegrity.integrityData.address.strasse","title": "Straße","required": pug.escape(true),"validationMessages": {"required":"Bitte geben Sie eine Straße an"},"attributes": {"tabindex":3}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeIntegrity.integrityData.address.plz","title": "Postleitzahl","required": pug.escape(true),"validationMessages": {"required":"Bitte geben Sie eine Postleitzahl an"},"attributes": {"tabindex":4}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeIntegrity.integrityData.address.ort","title": "Stadt","required": pug.escape(true),"validationMessages": {"required":"Bitte geben Sie eine Stadt an"},"attributes": {"tabindex":5}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E\u003Cbr\u003E\u003Cspan class=\"error\" ng-show=\"employeeIntegrity.error\"\u003EBitte überprüfen Sie Ihre Eingaben, oder setzen sich mit Ihrem Berater in Verbindung.\u003C\u002Fspan\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cbutton class=\"button right button-primary\" type=\"submit\" form=\"employeeIntegrityForm\" ng-disabled=\"employeeIntegrityForm.$invalid\" value=\"Speichern\"\u003ESpeichern\u003C\u002Fbutton\u003E\u003Cbutton class=\"button left alert\" ng-if=\"!employeeIntegrity.isCheckout\" type=\"button\" ng-click=\"employeeIntegrity.cancel()\"\u003EAbbrechen und Ausloggen\u003C\u002Fbutton\u003E\u003Cbutton class=\"button left alert\" ng-if=\"employeeIntegrity.isCheckout\" type=\"button\" ng-click=\"employeeIntegrity.backToComponents()\"\u003ECheckout abbrechen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "form-padding","name": "employeeIntegrityForm","id": "employeeIntegrityForm","ng-submit": "employeeIntegrity.save()"}
});
pug_html = pug_html + "\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"employeeIntegrity.type==&quot;toc&quot;\"\u003E" + (null == (pug_interp = require("./_toc.pug").call(this, locals)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;