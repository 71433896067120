import header_html from '../../../helpers/header.main.pug';
import dashboard_arbeitnehmer_template from '../../../modules/dashboard/arbeitnehmer/index.pug';


angular
  .module( 'VSPApp' )
  .config( ['$stateProvider', function( $stateProvider ) {

    $stateProvider
      .state( 'inApp.dashboardArbeitnehmer', {
        url: '/dashboard',
        views: {
          main: {
            template: dashboard_arbeitnehmer_template,
            controller: 'DashboardArbeitnehmerController',
            controllerAs: '$ctrl'
          },
          header: {
            template: header_html
          }
        }
      } );

  }] );
