import customer_testmode_dialog_html from './testmode/cockpit.kundenauswahl.testmode.dialog.pug';

const $inject = [
  '$scope',
  'ngDialog',
  'authenticationService',
  'dialogService',
  'customerService',
  'advisorService',
  'employeeService',
  'ACLService',
  'Made',
  '$state'
];
export default class CockpitKundenauswahlController {

  constructor(
    $scope,
    ngDialog,
    authenticationService,
    dialogService,
    customerService,
    advisorService,
    employeeService,
    ACLService,
    Made,
    $state
  ) {

    Object.assign(this, {
      $scope,
      ngDialog,
      dialogService,
      authenticationService,
      advisorService,
      customerService,
      employeeService,
      ACLService,
      Made,
      $state
    });

    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmEmployer = authenticationService.iAm('employer');
    this.loading = true;

    this.$scope.filter = {
      search: this.$state.params.search,
    };

    this.loadCustomers();
  }

  goToCustomer(customer) {
    let params = Object.assign({}, this.$state.params, {customerId: customer.id});

    if (this.iAmAdvisor || this.iAmGAdvisor || this.iAmSpecialAdvisor) {
      this.$state.go('inApp.cockpit.allgemein', params);
    } else {
      this.$state.go('inApp.cockpit.arbeitnehmerverwaltung', params);
    }
  }

  async search(query) {

    query = (query || '').trim();

    await this.$state.transitionTo(this.$state.$current, {'search': query}, {reload: false});
    if (this.authenticationService.iAm('gadvisor') || this.authenticationService.iAm('advisor')) {

      if (!query) {

        this.customers = [];

      } else if (query === '*') {

        this.loadAllCustomers();

      } else if (2 < query.length) {

        this.loading = true;
        if (!isNaN(query)) {
          this.customerService.get(parseInt(query))
            .then(customer => this.customers = [customer])
            .finally(() => this.loading = false);
        } else {

          this.customerService
            .searchCustomers(query)
            .then(customers => {
              this.customers = customers;
            }).finally(() => this.loading = false);
        }
      }
    } else {
      this.loading = true;

      const filterCustomers = (query) => {
        this.customers = this.customers.filter(customer => {
          const cond = (
            customer.name.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
            String(customer.id).indexOf(query) > -1
          )
          return cond;
        })
      }

      if (!query || !this.customers || !this.customers.length) {
        this.customerService.getCustomersByEmployerId(this.Made.user.valuenet_id)
        .then(customers => {
          this.customers = customers;
          if (query.length > 2) {
            filterCustomers(query);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
      } else {
        if (query.length > 2) {
          filterCustomers(query);
        }
      }
    }
  }

  loadCustomers() {
    this.search(this.$scope.filter && this.$scope.filter.search);
  }

  loadAllCustomers() {

    this.loading = true;

    this.advisorService
      .getCustomers()
      .then(customers => {
        this.customers = customers;
      }).finally(() => this.loading = false);
  }


  testmodeDialog(customer) {

    this.ngDialog.openConfirm({
      template: customer_testmode_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      controller: 'CockpitKundenauswahlTestmodeDialogController',
      controllerAs: '$ctrl',
      data: {
        customer: customer
      }
    })
    .finally(() => this.loadCustomers());
  }

  adviseCustomer() {

    this.dialogService
      .adviseCustomer()
      .finally(() => this.loadCustomers());
  }

}
CockpitKundenauswahlController.$inject = $inject;
