import {
  APPROVAL_PROCESS_STATES_BY_ID,
  APPROVAL_PROCESS_STATES_BY_NAME
} from '../../../../services/administrationService';
import {NEO_BIKELEASING_FLOWS_BY_NAME} from '../../../../services/bikeleasingService';

const $inject = [
  '$scope',
  'administrationService',
  'NotificationService',
  'bikeleasingService',
  'userService',
  'fileService',
  '$timeout',
  'Made'
];
export default class BikeEditController {
  constructor(
    $scope,
    administrationService,
    NotificationService,
    bikeleasingService,
    userService,
    fileService,
    $timeout,
    Made
  ) {

    Object.assign(this, {
      $scope,
      administrationService,
      NotificationService,
      bikeleasingService,
      userService,
      fileService,
      $timeout,
      Made
    });

    this.loading = {
      main: true,
      resend_communication: false
    };

    this.init()
      .then(() => {
        this.$timeout(() => {
          this.loading.main = false;
        }, 0);
      });
  }

  canCommunicate() {
    return this.approval.state_id === APPROVAL_PROCESS_STATES_BY_NAME['waiting_external_approval']['id'] && this.Made.user.valuenet_id === 66279;
  }

  async resendCommunicationMails() {
    this.$timeout(() => {
      this.loading.resend_communication = true;
    });

    this.communication_result = await this.administrationService.createApprovalProcessNotificationAndEmail({
      approval_process_notification_name: APPROVAL_PROCESS_STATES_BY_ID[this.approval.state_id]['name'],
      approval_process_id: this.approval._id,
    });

    this.NotificationService.message('Erfolgreich');
  }

  async getLastApprovalLog() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    let last_approval_log = await this.administrationService.getApprovalProcessLog({
      entity_id: this.approval.entity_id,
      only_last: true
    });

    if (last_approval_log) {
      this.models.decline.decline_comment = last_approval_log.comment;
    }

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  canEdit() {
    return true;
  }

  canDecline() {
    return this.approval.neo_bikeleasing.validations.flows[NEO_BIKELEASING_FLOWS_BY_NAME['decline']['name']].can_enter;
  }

  updateBikeleasing() {
    return this.bikeleasingService.updateNeoBikeleasing({
      bikeleasing_id: this.approval.neo_bikeleasing._id,
      log_comment: this.models.update.update_comment,
      updated_begin_date: this.approval.neo_bikeleasing.dates.contract_begin_date
    })
      .then(() => {
        this.NotificationService.message('Speichern erfolgreich.');
        this.$scope.confirm();
      })
      .catch((err) => {
        console.error(err);
        this.NotificationService.error('Fehlgeschlagen');
      });
  }


  async decline() {
    await this.bikeleasingService.declineNeoBikeleasing({
      bikeleasing_id: this.approval.neo_bikeleasing['_id'],
      log_comment: this.models.decline.decline_comment
    }).catch((err) => {
      console.error(err);
      this.NotificationService.error('Fehlgeschlagen');
    });

    this.$scope.confirm();
  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }

  async init() {
    this.approval = this.$scope.ngDialogData.approval;
    this.employee_id = this.$scope.ngDialogData.employee_id;

    this.APPROVAL_PROCESS_STATES_BY_ID = APPROVAL_PROCESS_STATES_BY_ID;
    this.APPROVAL_PROCESS_STATES_BY_NAME = APPROVAL_PROCESS_STATES_BY_NAME;

    this.models = {
      decline: {
        decline_comment: undefined
      },
      update: {
        update_comment: undefined
      }
    };

    await this.getLastApprovalLog();

    this.bikeleasing_files = await this.userService.getUploadedFiles(undefined, {
      valuenet_id: this.employee_id,
      tags: [
        this.approval.neo_bikeleasing._id
      ]
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }
}
BikeEditController.$inject = $inject;
