<div class="country-code">
    <label>Land</label>
    <select (change)="resetCountryCode($event.target.value)" [(ngModel)]="country">
        <option [value]="country.key"
                *ngFor="let country of countriesPhoneCode | keyvalue"
        >{{country.key}}
        </option>
    </select>
</div>

<div class="phone-number">
    <label>{{title}}</label>
    <input
        type="text"
        [value]="value"
        [title]="title"
        [placeholder]="placeHolder"
        [attr.data-cy]="dataCyInput"
        [disabled]="isDisabled"
        [required]="isRequired"
        (keyup)="handlePhoneNumberChanges($event.target.value)"
    >
    <button
        class="button"
        type="button"
        [disabled]="!isAllowToProve()"
        (click)="checkPhoneNumberValidity()"
        [attr.data-cy]="dataCyButton"
        *ngIf="!isProved"
    > Prüfen
    </button>
    <button
        class="button checked"
        type="button"
        *ngIf="isProved"
    >
        <mat-icon>check_circle_outline</mat-icon>
    </button>
</div>

<div class="error-message">
    {{errorMessage}}
</div>
