import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { AlertService } from 'src/app/shared/services/alert.service';

interface FormChoices {
  newOrderDate: Date;
}

interface DialogData {
  bonusProjectId: string;
  isMainOrder: boolean;
}

const EXISTING_ORDER_ERROR_CODE = 3001;

@Component({
  selector: 'vn-customer-create-new-order-dialog',
  templateUrl: './customer-create-new-order-dialog.component.html',
  styleUrls: ['./customer-create-new-order-dialog.component.scss']
})
// Toni: keep this because at some time in the future we can reuse it? - > https://vsp.atlassian.net/browse/VN-4140
export class CustomerCreateNewOrderDialogComponent implements OnInit, OnDestroy {

  public formGroup!: FormGroup;
  private formValueChangeSubscription$!: Subscription;

  public get orderDateControl(): FormControl {
    return this.formGroup.get('newOrderDate') as FormControl;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CustomerCreateNewOrderDialogComponent>,
    private directBonusService: DirectBonusService,
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private notificationService: AlertService,
  ) {}

  ngOnInit(): void {
    this.initForms();
  }

  private initForms() {
    this.formGroup = this.formBuilder.group({
      newOrderDate: this.formBuilder.control(undefined, [
        Validators.required
      ]),
    });

    this.formValueChangeSubscription$ = this.formGroup.controls.newOrderDate.valueChanges.subscribe(newValue => {
      this.formGroup.controls.newOrderDate.setErrors(null);
    })
  }

  createNewOrder() { // TODO
    const { newOrderDate } = this.formGroup.value as FormChoices;

    // Send correct date to the backend service!
    let orderDate = new Date(newOrderDate)
    orderDate.setMinutes(orderDate.getMinutes() - orderDate.getTimezoneOffset());

    this.directBonusService.customerCreateOrder(
      this.dialogData.bonusProjectId,
      orderDate,
      this.dialogData.isMainOrder,
    ).subscribe(
      () => {
        this.notificationService.message(`Sie haben eine neue Bestellung für den Monat gesetzt.`);
        this.dialogRef.close(true);
      },
      (e: any) => {
        let error = e.error
        if (error && error.message && error.message.code && error.message.code.value === EXISTING_ORDER_ERROR_CODE) {
          this.formGroup.controls.newOrderDate.setErrors({ existingOrderValidation: true })
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription$.unsubscribe();
  }

  checkDateValidity() {
    const isDateValid = this.formGroup.controls['newOrderDate'].value;
    if(!isDateValid) {
      this.formGroup.controls['newOrderDate'].setErrors({'valid': false});
    }
  }

}
