const USER_LANE_ID = '32390';

const TRACKED_MODULES = [
  'mpp',
  'cockpit',
  'cms',
  'administration',
  'verguetung',
  'bonus',
  'dashboardArbeitnehmer',
  'meineakte',
];

const ACTIVE_USER_MODULE_PREFIX = 'user_in_module-';

const $inject = [
  "Made",
  "ACLService",
  "$transitions",
  "$state",
  "lodash",
  "USERLANE_DISABLED"
];

export default class UserlaneService {

  constructor(
    Made,
    ACLService,
    $transitions,
    $state,
    lodash,
    USERLANE_DISABLED
  ) {

    Object.assign(this, {
      Made,
      ACLService,
      $transitions,
      $state,
      lodash,
      USERLANE_DISABLED
    });

    this.userlane_tags = [];

    if (!USERLANE_DISABLED && this.isDefined()) {
      $transitions.onSuccess({}, transition => {
        const to_name = transition.to().name;

        let to_be_removed_tags = this.lodash.filter(this.userlane_tags, (tag) => {
          return tag && tag.includes(ACTIVE_USER_MODULE_PREFIX);
        });

        let to_be_added_tag = undefined;
        for (let module_name of TRACKED_MODULES) {
          if (this.isTrackedState(to_name, module_name)) {
            to_be_added_tag = this.createActiveUserModuleTag(module_name);
            break;
          }
        }

        if (to_be_removed_tags.length) {
          Userlane('untag', ...to_be_removed_tags);
          this.lodash.pullAll(this.userlane_tags, to_be_removed_tags);
        }

        if (to_be_added_tag) {
          Userlane('tag', to_be_added_tag);
          this.userlane_tags.push(to_be_added_tag);
        }
      });
    }
  }

  createActiveUserModuleTag(module_name) {
    return ACTIVE_USER_MODULE_PREFIX + module_name;
  }

  isTrackedState(state_name, module_name) {
    return state_name.includes('.' + module_name);
  }

  async load(extra_tags) {
    if (this.USERLANE_DISABLED || !this.isDefined()) {
      return true;
    }

    if (this.Made.user.valuenet_id) {
      // add the user
      Userlane('user', this.Made.user.valuenet_id, this.Made.user.created);

      let user_groups = this.Made.user.groups;
      let customer_specific_groups = await this.ACLService.getEmployeeCustomerSpecificGroups()
        .then((customer_specific_groups_res) => {
          return Object.keys(customer_specific_groups_res || {});
        });
      this.userlane_tags = [...user_groups, ...customer_specific_groups];
    }
    this.userlane_tags = [...this.userlane_tags, ...extra_tags].filter(function(tag) {
      return tag !== undefined;
    });

    let state_name = this.$state.current.name;
    for (let module_name of TRACKED_MODULES) {
      if (this.isTrackedState(state_name, module_name)) {
        this.userlane_tags.push(this.createActiveUserModuleTag(module_name));
      }
    }


    // add the tags
    Userlane('tag', this.userlane_tags);

    this.init();

    return true;
  }

  init() {
    if (!this.isDefined()) {
      return;
    }

    Userlane('init', USER_LANE_ID);
  }

  hide() {
    if (!this.isDefined()) {
      return;
    }

    Userlane('hide');
  }

  isDefined() {
    return typeof Userlane !== undefined;
  }
}

UserlaneService.$inject = $inject;
