const $inject = [
  '$scope',
  'fileService',
  'moment'
];
export default class FilteredUsersDialogController {
  constructor(
    $scope,
    fileService,
    moment,
  ) {
    this.moment = moment;
    this.fileService = fileService;
    this.enableAll = true;
    try {
      this.users = this.editUsers($scope.ngDialogData);
      this.initData();
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  editUsers(users) {
    for(const i in users) {
      if (typeof users[i] === 'object') {
        const reserve3 = users[i]['access_status']['neo_comment'];

        users[i]['Letzte Anmeldung'] = users[i]['lastLogin'] && this.moment.unix(users[i]['lastLogin']).format('DD-MM-YYYY');
        users[i]['Einladungs Datum'] = users[i]['invitationDate'] && this.moment.unix(users[i]['invitationDate']).format('DD-MM-YYYY');
        users[i]['Sex'] = users[i]['sex'] ? 'M' : 'F';
        users[i]['Test Kunde'] = users[i]['testclient'] ? 'Ja' : 'Nein';
        users[i]['Kunden Name'] = users[i]['customername'];
        users[i]['E-Mail Addresse'] = users[i]['emailAddress'];
        users[i]['Aktivierungs Code'] = users[i]['activationCode'];
        users[i]['Aktivierung Ja/Nein'] = users[i]['hasUsedCode'] ? 'Ja' : 'Nein';
        users[i]['Anrede'] = users[i]['salutation'];
        users[i]['Nachname'] = users[i]['lastname'];
        users[i]['Zugriffsstatus'] = reserve3
        users[i]['Status'] = users[i]['access_status']['neo_comment2']
        users[i]['Persönliche Nummer'] = users[i]['personalnummer'];
        users[i]['Titel'] = users[i]['titel'];
        users[i]['Kunden Nummer'] = users[i]['custId'];
        users[i]['Vorname'] = users[i]['vorname'];
        users[i]['Name'] = users[i]['name'];
        users[i]['Adresszusatz'] = users[i]['adresszusatz'];
        users[i]['Plz'] = users[i]['plz'];
        users[i]['Strasse'] = users[i]['strasse'];
        users[i]['Co'] = users[i]['co'];
        users[i]['Lkz'] = users[i]['lkz'];
        users[i]['Ort'] = users[i]['ort'];
        users[i]['Id'] = users[i]['id'];
        users[i]['Geschlecht'] = users[i]['geschlecht'];
        users[i]['LocId'] = users[i]['locId'];
        users[i]['_Anrede'] = users[i]['anrede'];
        users[i]['isEnabled'] = true;

        const deleteList = [
          'personalnummer',
          'access_status',
          'firstname',
          'lastname',
          'salutation',
          'activationCode',
          'hasUsedCode',
          'emailAddress',
          'customername',
          'invitationDate',
          'lastLogin',
          'testclient',
          'titel',
          'custId',
          'sex',
          'vorname',
          'adresszusatz',
          'plz',
          'strasse',
          'lkz',
          'ort',
          'id',
          'geschlecht',
          'locId',
          'co',
          'anrede',
          'name'
        ];

        for (const j in deleteList)
          delete users[i][deleteList[j]];

      }
    }

    return users;
  }

  initData() {
    this.handleChangeTitle();
    this.handleEnableItems(this.users);
  }

  handleToggleAll() {
    this.handleChangeTitle();
    this.handleEnableItems(this.users, this.enableAll ? true : false);
  }

  handleEnableItems(items, isEnabled = true) {
    items.forEach(item => item.isEnabled !== isEnabled ? item.isEnabled = isEnabled : item.isEnabled);
  }

  handleChangeTitle() {
    this.enableAllSwitchTitle = this.enableAll ? 'Disable All' : 'Enable All';
  }

  downloadCSV() {
    const users = angular.copy(this.users).filter(user => user.isEnabled === true);
    users.forEach(user => delete user.isEnabled);

    this.fileService.saveFile(users, undefined, 'users.csv');
  }
}
FilteredUsersDialogController.$inject = $inject;
