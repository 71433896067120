

export const DEFAULT_YEARLY_INFO_VALIDATION_ANSWERS = {
  user: {
    email: undefined
  },
  current: {
    personal_info: {
      address: {},
      pap_stkl: {},
      birthdate: {}
    },
    components: {

    }
  },
  updated: {
    personal_info: {
      address: {},
      pap_stkl: {},
      birthdate: {}
    },
    components: {

    }
  }
};

export const DEFAULT_YEARLY_DOCUMENTS_TAG_STRING = 'yearly';

export const DEFAULT_YEARLY_ADVISOR_DETAILS = {
  id: 'fallback',
  email: 'jahresabfrage@valuenet.de',
  phone_support: '089 - 416 176 530'
};

export const DEFAULT_YEARLY_INFO_VALIDATION_WIZARD_STEPS = [
  {
    id: 1,
    title: 'Änderungsmeldung Festnetz',
    component: 'festnetz',
    options: [
      {
        key: 'date_of_change',
        parent: 'festnetz',
        type: 'datepicker',
        title: 'Seit wann hat sich der Betrag geändert?',
        default: null
      },
      {
        key: 'value',
        parent: 'festnetz',
        type: 'input',
        attributes: {
          currency: true
        },
        title: 'Bitte geben Sie den aktuellen Monatsbeitrag an, der seit dem oben genannten Datum vorliegt.',
      }
    ],
    documents_upload: {
      required: true,
      description: 'Bitte stellen Sie uns zur Überprüfung weiter eine aktuelle Monatsrechnung samt Detailseiten zur Verfügung. Der oben genannte Betrag muss vollständig durch die bereitgestellten Unterlagen nachvollziehbar sein.',
    }
  },
  {
    id: 1.5,
    title: 'Änderungsmeldung Mobiltelefon',
    component: 'handy',
    options: [
      {
        key: 'date_of_change',
        parent: 'handy',
        type: 'datepicker',
        title: 'Seit wann hat sich der Betrag geändert?',
        default: null
      },
      {
        key: 'value',
        parent: 'handy',
        type: 'input',
        attributes: {
          currency: true
        },
        title: 'Bitte geben Sie den aktuellen Monatsbeitrag an, der seit dem oben genannten Datum vorliegt.',
      },
    ],
    documents_upload: {
      required: true,
      description: 'Bitte stellen Sie uns zur Überprüfung weiter eine aktuelle Monatsrechnung samt Detailseiten zur Verfügung. Der oben genannte Betrag muss vollständig durch die bereitgestellten Unterlagen nachvollziehbar sein.',
    }
  },
  {
    id: 2,
    title: 'Änderungsmeldung Entfernungspauschale',
    component: 'entfernung',
    options: [
      {
        key: 'date_of_change',
        parent: 'entfernung',
        type: 'datepicker',
        title: 'Seit wann hat sich die Entfernung geändert?',
        default: null
      },
      {
        key: 'anzahl_km',
        parent: 'entfernung',
        type: 'input',
        subtype: 'number',
        title: 'Bitte geben Sie die einfache Wegstrecke zwischen Ihrer Wohnung und Ihrer ersten Tätigkeitsstätte an. Sollten Sie keine erste Tätigkeitsstätte haben, so geben Sie bitte 0 an.',
      },
      {
        key: 'anzahl_tage',
        parent: 'entfernung',
        type: 'input',
        subtype: 'number',
        title: 'Bitte geben Sie die Anzahl der regelmäßigen Arbeitstage je Woche an.',
      }
    ],
    documents_upload: {
      reduired: false
    }
  },
  {
    id: 3,
    title: 'Bitte geben Sie die monatliche Kosten für die Kinderbetreuung an.',
    component: 'kinder',
    options: [
      {
        key: 'value',
        parent: 'kinder',
        type: 'slider',
        title: 'Value',
        default: 0
      },
    ],
    documents_upload: {
      required: true,
      description: 'Bitte geben Sie uns einen Nachweis Ihrer Betreuungskosten. Sie können sie scannen oder abfotografieren.',
    }
  },
  {
    id: 4,
    title: 'Änderungsmeldung Internetpauschale',
    component: 'internet',
    options: [
      {
        key: 'date_of_change',
        parent: 'internet',
        type: 'datepicker',

        title: 'Seit wann hat sich der Betrag geändert?',
      },
      {
        key: 'value',
        parent: 'internet',
        type: 'input',
        default: true,
        max: 50,
        attributes: {
          currency: true
        },
        title: 'Bitte teilen Sie uns mit, wie hoch die Aufwendungen aktuell sind.',
      }
    ],
    documents_upload: {
      required: false
    }
  },

  {
    id: 5,
    title: 'PC',
    component: 'pc',
    options: [
      {
        key: 'value',
        parent: 'pc',
        type: 'slider',
        default: 0,
        title: 'PC',
        additional_text: 'PC',
        // attributes: {
        //   currency: true
        // }
      },
      {
        key: 'date',
        parent: 'pc',
        type: 'datepicker',
        title: 'PC',
        additional_text: 'PC'
      }
    ],
    documents_upload: {
      required: true,
      description: 'Bitte geben Sie uns einen Nachweis Ihrer Betreuungskosten. Sie können sie scannen oder abfotografieren.',
      additional_text: 'Bitte beachten Sie, dass die Jahresabfrage erst abgeschlossen ist, wenn die erforderlichen Unterlagen hochgeladen worden sind. Sollte die Nachreichung der Dokumente nicht erfolgen, müssen wir leider den Baustein aus der Optimierung entfernen.'
    }
  }
];

export const DEFAULT_YEARLY_SESSION_CONFIG = {
  metadata: [
    {
      id: 4,
      type: 'datetime',
      parent: 'dates',
      key: 'yearly_session_start_date',
      title: 'Beginn',
      settings: {
        date: {
          min: {
            key: 'today'
          }
        }
      }
    },
    {
      id: 5,
      type: 'datetime',
      parent: 'dates',
      key: 'yearly_session_end_date',
      title: 'Ende',
      settings: {
        date: {
          min: {
            key: 'today'
          }
        }
      }
    },
    {
      id: 6,
      type: 'input',
      parent: undefined,
      key: 'yearly_session_name',
      title: 'Bezeichnung'
    }
  ]
};

export const YEARLY_SESSION_ERRORS = [
  {
    id: 0,
    name: 'general_error',
    title: 'General error message',
    description: "ERR00",
    state: 'blank.yearly.error'
  },
  {
    id: 1,
    name: 'yearly_inactive',
    title: 'Die Abfrage ist beendet',
    description: 'Uns liegen aktuell keine Abfragedaten für Sie vor.',
    state: 'blank.yearly.error'
  },
  {
    id: 2,
    name: 'user_completed_yearly',
    title: 'Vielen Dank',
    description: 'Sie haben die Abfrage bereits abgeschlossen. Wir kommen auf Sie zu, falls noch etwas zu tun ist.',
    state: 'blank.yearly.error'
  },
  {
    id: 3,
    name: 'user_not_part_of_yearly',
    title: 'Vielen Dank für Ihr Interesse',
    description: 'Uns liegen aktuell keine Abfragedaten für Sie vor. - ERR03',
    state: 'blank.yearly.error'
  },
  {
    id: 4,
    name: 'user_not_in_made',
    title: 'Vielen Dank für Ihr Interesse',
    description: 'Uns liegen aktuell keine Abfragedaten für Sie vor. - ERR04',
    state: 'blank.cidaas_login'
  }
];

export const YEARLY_SESSION_ERRORS_BY_ID = YEARLY_SESSION_ERRORS.reduce((acc, error) => {
  acc[error.id] = error;
  return acc;
}, {});

export const YEARLY_SESSION_ERRORS_BY_NAME = YEARLY_SESSION_ERRORS.reduce((acc, error) => {
  acc[error.name] = error;
  return acc;
}, {});

const $inject = [
  "administrationService", "Made", "employeeService", "moment", "$q"
];
export default class yearlyService {
  constructor(administrationService, Made, employeeService, moment, $q) {
    Object.assign(this, {
      administrationService, Made, employeeService, moment, $q
    });
  }

  getYearlySessionConfig() {
    return angular.copy(DEFAULT_YEARLY_SESSION_CONFIG);
  }

  getOverviewDocument(employee_id, answers) {
    return this.Made.request('rpc://administration/yearly/generate_overview_document', {
      employee_id: employee_id,
      answers: answers
    });
  }

  getCalculationById(calculation_id, employee_id) {
    return this.Made.request('rpc://administration/yearly/get_calculation_by_id', {
      calculation_id: calculation_id,
      employee_id: employee_id
    });
  }

  getUsersWithValidationCode() {
    return this.Made.request('rpc://administration/yearly/get_users_with_validation_code');
  }

  getUsersWithoutValidationCodes() {
    return this.Made.request('rpc://administration/yearly/get_users_without_validation_codes');
  }

  async isYearlyDocumentsGatheringAvailable() {
    let current_user = await this.getCurrentUser();
    //check if there is latest yearly
    if (!current_user) {
      return YEARLY_SESSION_ERRORS_BY_NAME['user_not_in_made'];
    }

    let latest_yearly = await this.getLatestYearlySession();
    if (!latest_yearly) {
      return YEARLY_SESSION_ERRORS_BY_NAME['yearly_inactive'];
    }

    // check if user is part of yearly
    if (latest_yearly._id !== current_user.yearly_session_id) {
      return YEARLY_SESSION_ERRORS_BY_NAME['user_not_part_of_yearly'];
    }

    // if latest yearly is active check if user has completed it
    if (current_user && current_user.meta && current_user.meta.yearly_finished_date) {
      return YEARLY_SESSION_ERRORS_BY_NAME['user_completed_yearly'];
    }

    return true;
  }

  async isLatestYearlySessionActive() {
    return this.Made.request('rpc://administration/yearly/is_latest_yearly_session_active');
  }

  async getLatestYearlySession() {
    return this.Made.request('rpc://administration/yearly/get_latest_yearly_session');
  }

  initiateYearlyDocumentGathering(yearly_session) {
    return this.Made.request('rpc://administration/yearly/initiate_yearly_document_gathering', {
      'yearly_session': yearly_session
    });
  }

  stopYearlyDocumentGathering(yearly_session_id) {
    return this.Made.request('rpc://administration/yearly/stop_yearly_document_gathering', {
      'yearly_session_id': yearly_session_id
    });
  }

  validateUser(validation_credentials) {
    return this.Made.request('rpc://administration/yearly/validate_user', {
      'validation_credentials': validation_credentials
    }).then(user => {
      if (!!user) {
        this.setCurrentUser(user);
        return true;
      }
      // this.user = user;
      return false;
    });
  }

  async updateYearlyData(employee, yearly_session_id, answers, create_made_user = false) {
    // upload files
    let data = await this.Made.request('rpc://administration/yearly/store_yearly_update_data', {
      'valuenet_id': employee.id,
      'yearly_session_id': yearly_session_id,
      'answers': answers,
      'create_made_user': create_made_user
    });

    let yearly_overview_file_owner;
    if (employee._id) {
      yearly_overview_file_owner = employee._id;
    } else {
      yearly_overview_file_owner = data.new_made_user_id ? data.new_made_user_id : employee.id;
    }

    let file_promises = [];

    for (const component_name in answers.updated.components) {
      let component_config = answers.updated.components[component_name];
      if (component_config.hasOwnProperty('documents')) {
        for (const file of component_config.documents) {
          file_promises.push(this.Made.upload(file, [`document_gathering-${yearly_session_id}`, 'neo', 'component_name-' + component_name, DEFAULT_YEARLY_DOCUMENTS_TAG_STRING], yearly_overview_file_owner));
        }

        delete component_config.documents;
      }
    }

    await this.$q.all(file_promises);

    return data;
  }

  setCurrentUser(user) {
    this.user = user;
  }

  unsetCurrentUser() {
    delete this.user;
  }

  async getCurrentUser() {
    if (this.user) {
      return this.user;
    }

    if (this.Made.user.valuenet_id) {
      return this.Made.request('rpc://administration/yearly/get_user_by_emp_id', {
        'emp_id': this.Made.user.valuenet_id
      }).then(user => {
        if (user) {
          this.setCurrentUser(user);
        }
        return user;
      });
    }

    return this.user;
  }

  async get_sessions(options) {
    let parameters = {
      customer_id: options.customer_id
    };
    return this.Made.request('rpc://administration/yearly/get_yearly_sessions', parameters);
  }

  async sendYearlyInitiationEmails() {
    return this.Made.request('rpc://administration/yearly/send_yearly_initiation_emails');
  }

  async generate_overview_document_for_yearly_for_testing() {
    return this.Made.request('rpc://administration/yearly/generate_overview_document_for_yearly_for_testing')
  }

  async send_yearly_emails_to_bo_for_already_finished_processes() {
    return this.Made.request('rpc://administration/yearly/send_yearly_emails_to_bo_for_already_finished_processes')
  }
}
yearlyService.$inject = $inject;
