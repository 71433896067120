import DeclineApprovalProcessController from "../../decline_approval_process/declineApprovalProcess";
import {USER_DECISION_PROCESS_TYPES_BY_ID} from "../../../services/bonus/user_decision_process_type";


const $inject = [
  '$state',
  '$scope',
  'Made',
  'dialogService',
  'lodash',
  '$timeout',
  'mppService'
];
export default class GenerateInvoicesDialog {

  constructor(
    $state,
    $scope,
    Made,
    dialogService,
    lodash,
    $timeout,
    mppService
  ) {
    Object.assign(this, {
      $state,
      $scope,
      Made,
      dialogService,
      lodash,
      $timeout,
      mppService
    });

    this.loading = {
      main: false
    };

    this.models = {
      invoices: {
        count: 1
      }
    };

    this.init().finally(() => {

      this.$timeout(()=>{
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    this.nextInvoiceNumber = await this.mppService.getLastInvoiceNumber();

  }

  close() {
    this.$scope.closeThisDialog();
  }

  submit() {
    this.$timeout(()=>{
      this.loading.main = true;
    });

    this.mppService.generateInvoices({
      num: this.models.invoices.count
    }).then(created => {
      if (created)

        this.$timeout(()=>{
          this.loading.main = false;
          this.$scope.confirm(true);
        });

    }).catch(err => console.log("err", err));


  }
}

GenerateInvoicesDialog.$inject = $inject;
