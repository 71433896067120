const $inject = [
  '$scope',
  '$state',
  'ShopService'
];
export default class DetailsController {
  constructor(
    $scope,
    $state,
    ShopService
  ) {

    this.$scope = $scope;
    this.$state = $state;
    this.ShopService = ShopService;


    this.$scope.detailsId = this.$state.params.produkteId;

    this.ShopService
      .loadOffer(this.$scope.detailsId)
      .then(offer => {
        this.$scope.offer = offer;
        this.$scope.imageSource = this.ShopService.getImageSrc(offer);
      });


    this.$scope.getLink = offer => offer.offer_linkurl || offer.offer_linkcode;

    this.$scope.getAjaxLink = offer => {

      this.$scope.ajaxLinkRequested = true;

      this.ShopService
        .getAjaxLink(offer)
        .then(link => offer.ajaxCode = link)
        .finally(this.$scope.ajaxLinkRequested = false);
    };

  }
}

DetailsController.$inject = $inject;
