const $inject = [
  "$q",
  "Made",
  "projectService"
];

export default class paymentService {

  constructor($q, Made, projectService) {

    Object.assign(this, {
      $q,
      Made,
      projectService
    });

    this.payments = {};
  }

  create(payment) {
    return this.Made
      .request('rpc://valuenetdb/project/payment/create', {
        payment: payment
      })
      .then(response => {

        var finalPayment = response;

        this.payments[finalPayment.id] = finalPayment;
        this.projectService.employees[finalPayment.project_id][finalPayment.receiver_id] = finalPayment.id;

        return finalPayment;
      });
  }

  get(paymentId, withProject = true) {

    var payment = this.payments[paymentId];

    if (payment && payment.promise)
      return payment.promise;
    if (payment && ((withProject && payment.project) || !withProject))
      return this.$q.when(payment);
    payment = this.payments[paymentId] = {};

    payment.promise = this.Made
      .request('rpc://valuenetdb/project/payment/get', {
        bonus_id: parseInt(paymentId)
      })
      .then(response => {
        this.payments[paymentId] = response;
        return withProject && this.projectService.get(response.project_id);
      })
      .then((project) => {
        if (project) this.payments[paymentId].project = project;
        return this.payments[paymentId];
      });

    return payment.promise;
  }

  save(payment) {
    this.payments[payment.id] = payment;
    this.payments[payment.id].time = '' + this.payments[payment.id].time;

    if (this.payments[payment.id].accepted === null)
      delete this.payments[payment.id].accepted;
    if (this.payments[payment.id].accepter_id === null)
      delete this.payments[payment.id].accepter_id;

    return this.Made
      .request('rpc://valuenetdb/project/payment/save', {
        payment: payment
      });
  }

  delete(employeeId, projectEmployees) {
    var paymentId = projectEmployees[employeeId];

    return this.Made
      .request('rpc://valuenetdb/project/payment/delete', {
        id: paymentId
      })
      .then(() => {
        // delete paymentService.payments[paymentId];
        delete this.projectService.employees[employeeId];
        delete projectEmployees[employeeId];
      });
  }

  activate(paymentId) {
    return this.Made
      .request('rpc://valuenetdb/project/payment/activate', {
        pid: paymentId
      });
  }

  forEmployee(employee_id = this.Made.user.valuenet_id) {
    return this.Made
      .request('rpc://valuenetdb/project/payment/for_employee', {
        employee_id
      });
  }
}

paymentService.$inject = $inject;