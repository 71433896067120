import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { BonagoBasketListItem, BonagoCustomerChecks, BonagoScheme, BonagoUserChecks, EmployeeAddress } from 'src/app/direct-bonus/models/benefit-bonago.model';
import { BenefitsBonagoBasketService } from 'src/app/direct-bonus/services/benefits-bonago-basket.service';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';

// ===============================================================

interface FormOptions {
  scheme: Observable<SelectOption[]>
}

interface FormChoices {
  schemeId: string;
}

// ===============================================================

@Component({
  selector: 'vn-benefit-basket-sheet',
  templateUrl: './benefit-basket-sheet.component.html',
  styleUrls: ['./benefit-basket-sheet.component.scss']
})
export class BenefitBasketSheetComponent implements OnInit, OnDestroy {

  public employeeAddress$ = this.bonagoService.employeeAddress$;

  public basketSum$ = this.basketService.basketSum$;
  public basketList$ = this.basketService.basketListItems$;
  public basketSum!: number;

  public userBalance$ = this.basketService.userBalance$$;
  public restBalance$ = this.basketService.restBalance$;
  public restBalance!: number;

  private basketSumSubscription!: Subscription;
  private restBalanceSubscription!: Subscription;
  private resolvedDataSubscription!: Subscription;
  private formSubscription!: Subscription;

  public summaryForm!: FormGroup;
  public formOptions!: FormOptions;

  private resolvedData$ = this.resolveData$();
  public resolvedData!: [BonagoUserChecks, BonagoCustomerChecks, BonagoScheme[], BonagoScheme];

  public selectedVouchersAvailable: boolean = true;

  public get userChecks(): BonagoUserChecks {
    return this.resolvedData[0];
  }

  public get userScheme(): BonagoScheme {
    return this.resolvedData[3];
  }

  private get iAmSpecialAdvisor(): boolean {
    return this.authenticationService.iAm('special_advisor');
  }

  private get isUserCodeIdForSelectedScheme(): boolean {
    return this.userChecks.hasBonagoEmployeeBenefit || (
      this.userChecks.hasValidUserCode &&
      this.userChecks.userCodeSchemeId === this.userScheme.schemeId
    );
  }

  constructor(
    @Inject('authenticationService') private authenticationService: any,
    @Inject('$state') private $state: any,
    private formBuilder: FormBuilder,
    private bottomSheetRef: MatBottomSheetRef<BenefitBasketSheetComponent>,
    private basketService: BenefitsBonagoBasketService,
    private bonagoService: BenefitsBonagoService,
  ) {
    this.initFiltersForm();
    this.initFilterOptions();
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.basketSumSubscription.unsubscribe();
    this.restBalanceSubscription.unsubscribe();
    this.resolvedDataSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  private resolveData$() {
    return combineLatest([
      this.bonagoService.userChecks$,
      this.bonagoService.customerChecks$,
      this.bonagoService.customerBonagoSchemes$,
      this.bonagoService.userBonagoScheme$,
    ]);
  }

  private initSubscriptions() {
    this.basketSumSubscription = this.basketSum$.subscribe(
      basketSum => this.basketSum = basketSum
    );

    this.restBalanceSubscription = this.restBalance$.subscribe(
      restBalance => this.restBalance = restBalance
    );

    this.resolvedDataSubscription = this.resolvedData$.subscribe(
      resolvedData => this.resolvedData = resolvedData
    );

    this.formSubscription = this.summaryForm.valueChanges
      .subscribe(
        (formValues: FormChoices) => {
          const {
            schemeId
          } = formValues;

          if (schemeId) {
            this.bonagoService.selectSchemeId(parseInt(schemeId));
          }
        }
      );
  }

  private initFiltersForm() {
    this.summaryForm = this.formBuilder.group({
      scheme: this.formBuilder.control(undefined, [
        Validators.required
      ]),
    });
  }

  private initFilterOptions() {
    this.formOptions = {
      scheme: this.bonagoService.customerBonagoSchemes$.pipe(
        map(
          (customerSchemes) => customerSchemes.map(
            scheme => ({
              name: scheme.display,
              value: scheme.schemeId.toString()
            })
          )
        )
      )
    };
  }

  public showNoCodeError() {
    return this.iAmSpecialAdvisor && !this.userChecks.hasValidUserCode;
  }

  public showInvalidCodeError() {
    return this.iAmSpecialAdvisor && !this.userChecks.hasBonagoEmployeeBenefit && this.isUserCodeIdForSelectedScheme;
  }

  public showBenefitsAccountError() {
    return this.iAmSpecialAdvisor && !this.userChecks.hasBonagoEmployeeBenefit;
  }

  public showBonagoSchemeError() {
    return this.iAmSpecialAdvisor && !this.userChecks.hasValidSchemeId;
  }

  public showBonagoSchemeSelection() {
    return !this.userChecks.hasBonagoEmployeeBenefit;
  }

  public isBasketEmpty(basketList: BonagoBasketListItem[]) {
    return !!basketList && !basketList.length;
  }

  public removeBasketItem(basketItemIdx: number) {
    this.basketService.removeFromBasket(basketItemIdx);
  }

  public openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  public openRetailerDetails(retailerId: number) {
    this.$state.go('benefitDetails', {
      retailerId
    });
    this.bottomSheetRef.dismiss();
  }

  public canCheckout(): boolean {
    if (this.userChecks) {
      return this.basketSum > 0 &&
        this.restBalance >= 0 &&
        this.userChecks.hasValidUserCode &&
        this.isUserCodeIdForSelectedScheme;
    }
    return false;
  }

  public startCheckout(employeeAddress: EmployeeAddress) {
    let _allAvailable = true;
    const basket = this.basketService.getBasket();
    let basketVouchers: any = {};
    let vouchersList = [];

    for(const item of basket) {
      basketVouchers[item['voucherId']] = item['quantity'];
      vouchersList.push(item['voucherId'])
    }

    const _subscription: any = this.bonagoService.getVouchersInStockNumber$(vouchersList)
      .pipe(finalize(() => {
        _subscription.unsubscribe();
        this.selectedVouchersAvailable = false;
        if(_allAvailable) {
          this.selectedVouchersAvailable = true;
          this.$state.go('benefitCheckout', {
            employeeAddress
          });
          this.bottomSheetRef.dismiss();
        }
      }))
      .subscribe((vouchersStockNumber: any) => {
        for (const voucherId in vouchersStockNumber) {
          const inStockNumber = vouchersStockNumber[voucherId];
          if(inStockNumber < basketVouchers[voucherId]) {
            this.basketService.setBasketItemAvailability(voucherId, false);
            _allAvailable = false;
          }
        }
      })
  }

}
