interface FileType {
  type: string,
  subtype: string
}

interface ValueNetData {
  employeeId: number,
  customerId: number
}

export enum RequestState {
  AwaitingForSigning,
  Expired,
  Cancelled,
  Signed,
}

export interface CancelationData {
  date: Date,
  by_who: string,
}


export class DigitalSigningRequest {
  constructor(
    public requesterId: number,
    public expirationDate: string,
    public fileType: FileType,
    public ticketNumber: string,
    public comment: string,
    public valuenetData: ValueNetData,
    public coordinates: string,
    public requestState: RequestState,
    public cancelationData: CancelationData|null
  ) {

  }
}
