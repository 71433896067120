import lodash from 'lodash';

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'vbmService',
  'bikeleasingService',
  'administrationService',
  'moment',
  '$window',
  '$sce',
  'userService',
];

export default class ComponentController {
  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    vbmService,
    bikeleasingService,
    administrationService,
    moment,
    $window,
    $sce,
    userService,
  ) {
    this.$q = $q;
    this.$injector = $injector;
    this.$transitions = $transitions;
    this.$scope = $scope;
    this.$location = $location;
    this.$state = $state;
    this.Made = Made;
    this.vbmData = vbmData;
    this.neoModifierService = neoModifierService;
    this.PlausibilityService = PlausibilityService;
    this.dialogService = dialogService;
    this.componentsService = componentsService;
    this.NotificationService = NotificationService;
    this.vbmService = vbmService;
    this.customerService = customerService;
    this.userService = userService;
    this.bikeleasingService = bikeleasingService;
    this.employeeService = this.$injector.get('employeeService');
    this.mppService = this.$injector.get('mppService');
    this.administrationService = administrationService;
    this.moment = moment;
    this.$window = $window;

    this.sliderOptions = angular.copy(SliderOptionsConstants);

    $scope.employeeId = vbmData.employeeId = Made.user.valuenet_id;
    this.employeeId = $scope.employeeId;
    $scope.path = this.$state.params.path;
    this.moduleId = this.$state.params.moduleId;

    this.forms = {};
    this.custom_model = {};
    $scope.forms = this.forms;
    $scope.custom_model = this.custom_model;

    this.customerService
      .getNeoProjectByEmployeeId(this.vbmData.employeeId)
      .then(neoProject => {
        this.configuration = neoProject;
        let selfservice = this.moduleId == 'bav' ? false : !neoProject.neo_components[this.moduleId].selfservice;

        if (selfservice) {
          this.$state.go('inApp.neo.side.verguetung');
        }
      });

    this.customerService
      .getConfigurationByEmployeeId(this.vbmData.employeeId)
      .then(customerConfiguration => {
        let config = customerConfiguration.neo_config;

        this.bikeleasingAGCode = config.bike.agCode
        this.bikeleasingCalculatorUrl = 'https://calculator.bikeleasing.de/calculator?country=DE&lang=de-DE'

        if (this.bikeleasingAGCode) {
          this.bikeleasingCalculatorUrl = this.bikeleasingCalculatorUrl + '&code=' + this.bikeleasingAGCode
        }
    });

    this.sliderReady = false;
    this.disableCheckoutIfNoSalary = false;
    this.salaryError = null;

    this.box = {
      step: 'intro',
    };

    if (this.moduleId == 'Bikeleasing') {
      this.showEBikeAdvantage = false;
      this.result = false;
      this.inputs = {
        verkaufspreis: 0,
        listenpreis: 0,
        versicherungPremAN: false,
        inspection: false
      };
    }

    // 20201112 PF - Address is only requested in checkout - we do not need to request address during the use of the portal
    this.addressComponents = [''];

    // TODO: wait for 300ms instead of making code dependent
    // Update the calculation
    this.compute = debounceFactory(() => {
      let employee = this.vbmData.employees[this.vbmData.employeeId];
      this.employee = employee;

      this.vbmData
        .getResult()
        .then(
          () => {
            employee.plausibilites = PlausibilityService.check(employee);
          },
        );
    }, 300);

    // TODO: wait for 300ms instead of making code dependent
    this.save = debounceFactory(this._save, 300);

    this.getDataPromise = vbmData
      .getData(vbmData.employeeId, 0)
      .then(() => this.getData());

    if (
      $location.hash() &&
      ($location.hash() === 'values' || $location.hash() === 'checkout')
    ) {
      this.toggleStep($location.hash());
    }
    this.salaryLoading = true;

    this.$onInit = async () => {
      this.is_already_used = await this.isAlreadyUsed()
      this.setSalaryCheckout(await this.getDataPromise);
      this.salaryLoading = false;

      var iframe = document.getElementById('bikeleasingCalculatorIframe');
      if (iframe) {
        iframe.addEventListener('load', function () {
          // Add event listener to the message event
          window.addEventListener('message', function (event) {
            // Verify that the message is from the iframe
            if (event.source === iframe.contentWindow) {
              // Check the message data
              if (event.data === 'buttonClicked') {
                // Button clicked in the iframe
                $scope.$apply(function () {
                  console.log('Button clicked in the iframe');
                  // Add your AngularJS logic here
                });
              }
            }
          });
        });
      }
    }
  }

  trustSrc = function(src) {
    return $sce.trustAsResourceUrl(src);
  }

  async isAlreadyUsed() {
    return await this.Made.request(
      'rpc://vbm/neo/is_component_already_used_in_bonus',
      {
        component_key: this.moduleId
      }
    )
  }

  canExitValuesScreen() {
    return Object.values(this.forms).reduce((acc, form) => {
      acc = acc && form.$valid;
      return acc;
    }, true);
  }

  setSalaryCheckout(vbmData) {
    let hasSalarySet = false;

    if (vbmData.before && vbmData.before.tax && vbmData.before.tax.brutto_gesamt !== 0) {
      hasSalarySet = true;
    }

    if (this.userService && this.userService.iAmProxy()) {
      this.disableCheckoutIfNoSalary = false;
    } else {
      this.disableCheckoutIfNoSalary = !hasSalarySet;
    }

    this.setSalaryError();
  }

  setSalaryError() {
    if (this.disableCheckoutIfNoSalary) {
      this.salaryError = `<p class="error">Eine Beantragung ist aufgrund fehlender Einstellungen aktuell nicht möglich.</p>`;
    } else {
      this.salaryError = null;
    }
  }

  async getData() {
    this.model = this.vbmData.getComponent(this.moduleId);

    // set custom_model to ensure forms are valid
    // VN-1311 ensure custom_model has correct values
    if (this.moduleId === 'handy') {
      this.custom_model.mpp_combined = this.model.mpp_combined;
    }

    let employee = this.vbmData.employees[this.vbmData.employeeId];
    // add those so on the intro step we can show error and disable checkout for selfservice ( no proxy - employee ) only

    if (this.addressComponents.indexOf(this.moduleId) > -1) {
      this.userService.getAddressStatus().then(state => {
        if (!state) {
          this.dialogService.employeeAddress(this.vbmData.employeeId);
        }
      });
    }

    let promises = {};
    promises.slider = this.sliderCheck(employee);
    if (this.moduleId == 'Bikeleasing') {
      promises.bike = this.neoConfig(this.vbmData.employeeId);
    }

    await this.$q.all(promises);

    return this.vbmData.getResult();
  }

  async sliderCheck(employee) {
    // TODO: this call is executed twice ....
    let configuration = await this.customerService.getNeoProjectByEmployeeId(
      this.vbmData.employeeId,
    );
    this.infomode = configuration.infomode || false;
    this.noSelfCheckout = configuration.noSelfCheckout || false;
    this.customerConfiguration = configuration;
    let customerConfiguration = await this.customerService.getConfigurationByEmployeeId(this.vbmData.employeeId);
    this.neoConfig = customerConfiguration.neo_config;

    this.customer_id = await this.employeeService.getCustomerId();
    this.mppLink = await this.mppService.getMPPLink(this.customer_id);

    let hasSlider = false;
    if (typeof this.customerConfiguration.neo_components[this.moduleId] !== 'undefined') {
      if ((typeof this.customerConfiguration.neo_components[this.moduleId].max_self_value !== 'undefined' || this.customerConfiguration.neo_components[this.moduleId].max_self_value > 0)) {
        this.maxValue = this.customerConfiguration.neo_components[this.moduleId].max_self_value;
      } else if ((typeof this.customerConfiguration.neo_components[this.moduleId].max_value !== 'undefined' || this.customerConfiguration.neo_components[this.moduleId].max_value > 0)) {
        this.maxValue = this.customerConfiguration.neo_components[this.moduleId].max_value;
      }
    } else { this.maxValue = 0; }

    if (this.maxValue !== 0 && typeof this.maxValue !== 'undefined') {
      hasSlider = true;
    } else {
      let values = await this.componentsService.getMaxValues(
        this.moduleId,
        employee.data.parameters.jahr,
      );
      if (typeof values[this.moduleId] !== 'undefined') {
        this.maxValue = values[this.moduleId];
        hasSlider = true;
      }
    }

    if (hasSlider) {
      this.sliderOptions.to = this.maxValue;
      this.sliderReady = true;
    }

    return hasSlider;
  }

  async neoConfig(employeeId = 0) {
    let config = await this.customerService.getNeoConfigBike(employeeId);

    this.config = config;

    this.premAN = this.config.traegerVersicherungPremiumPlus == 'an_frei';
    this.inspectAN = this.config.inspektion == 'an_frei';
  }

  goToMPP() {
    if (this.mppLink) {
      if (this.neoConfig.pc.useInternalMPP) {
        this.$state.go('inApp.mpp.side.products.landing', { neo_data: { is_selfservice: true } });
      } else {
        window.open(this.mppLink, '_blank');
      }
    }
  }

  toggleStep(step) {
    if (this.is_already_used)
      return;

    this.box.step = this.box.step !== step ? step : false;

    if ('values' === this.box.step) {
      this.watch();
    }
    if ('checkout' === this.box.step) {
      this.compareNecessaryValuewithMaxValue();
      if (this.canExitValuesScreen()) {
        this.save();
      } else {
        this.box.step = 'values';
      }
    }
  }

  _save() {
    this.vbmData
      .save()
      .then(
        () => { },
        error => this.NotificationService.default.checkoutSession(error),
      );
  }

  calcModifiedValues() {
    let employee = this.vbmData.employees[this.vbmData.employeeId],
      components = {};

    components[this.moduleId] = this.model;

    return this.neoModifierService
      .calculateModifiedValues(
        components,
        employee.data.parameters.jahr,
        this.vbmData.employeeId,
      )
      .finally(
        () =>
          (employee.plausibilites = this.PlausibilityService.check(employee)),
      );
  }

  setHandyMppCombined(mpp_combined) {
    this.model.mpp_combined = mpp_combined;
    this.custom_model.mpp_combined = this.model.mpp_combined;
  }

  async watch() {
    await this.getDataPromise;

    if (this.watcher) {
      return;
    }

    let watcher = () => {
      this.calcModifiedValues().then(() => {
        this.model.enabled = this.model.value > 0 && this.moduleId != 'Bikeleasing';

        return this.compute();
      });
    };

    watcher();

    this.watcher = this.$scope.$watch('component.model', watcher, true);
  }

  contact() {
    this.dialogService.contactAdvisor();
  }

  calcEBikeAdvantage() {
    let data = JSON.parse(
      JSON.stringify(this.vbmData.employees[this.vbmData.employeeId].data),
    );

    this.vbmService.getSingleResult(data, 'EBike').then(result => {
      let nettoplus =
        result.before.tax.net_netto - result.final.tax.net_auszahlung;
      this.totalEBikeExpense =
        nettoplus * 36 + data.neo_components.EBike.verkaufspreis * 0.17;
      this.relEBikeRate = this.totalEBikeExpense / 36;
      this.advEBikePercent =
        (1 - this.totalEBikeExpense / data.neo_components.EBike.verkaufspreis) *
        100;

      this.showEBikeAdvantage = true;
    });
  }

  compareNecessaryValuewithMaxValue() {
    if (this.moduleId.includes('VMA')) {
      if (this.model.anzahl_tage > this.maxValue) {
        this.model.anzahl_tage = this.maxValue;
      }
    } else if (this.moduleId.includes('essenscheck')) {
      if (this.model.anzahl_tage > this.maxValue) {
        this.model.anzahl_tage = this.maxValue;
      }
    } else if (this.moduleId.includes('sachbezug')) {
      if (this.model.value > this.maxValue) {
        this.model.value = this.maxValue;
      }
    } else {
      if (this.model.value > this.maxValue) {
        this.model.value = this.maxValue;
      }
    }
  }

  setEheValue() {
    // this.model is the Component settings that were used to create the latest calculations
    // this.model is the specific Component which is being loaded from rechner/neo.py as values


    if (!this.model.an_enabled && !this.model.ehe_enabled) {
      this.model.an_enabled = true;
    }

    let an = this.model.an_enabled ? 13 : 0,
      ehe = this.model.ehe_enabled ? 8.67 : 0;

    this.model.value = an + ehe;
  }

  async downloadBikeleasingDocument() {
    let now = this.moment().format('HH:mm DD.MM.YYYY')
    return this.administrationService.createBikeleasingBonusDocument(this.Made.user.valuenet_id, now).then((res) => {
      this.$window.open(res.response, '_blank');
    }).catch((err) => {
      console.log(err);
    });
  }

}

ComponentController.$inject = $inject;
