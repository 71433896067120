<div>
    <h1 style="font-weight: inherit; color: black">
        {{ title }}
    </h1>
</div>
<div *ngIf="subtitle">
    <h5>
        {{ subtitle }}
    </h5>
</div>

<br />

<mat-dialog-content *ngIf="withDeclineReason">
    <form [formGroup]="declineReasonForm">
        <vn-input formControlName='declineReason' [label]="reasonLabel"
            placeholder='Kommentar/ Korrekturhinweis...'>
        </vn-input>
        <mat-error *ngIf="declineReasonForm.get('declineReason')?.hasError('required')">
            Es wird eine Eingabe benötigt.
        </mat-error>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>
        {{ noButton }}
    </button>

    <button mat-stroked-button [disabled]="withDeclineReason && !declineReasonForm.valid" (click)="userAction('yes')">
        {{ yesButton }}
    </button>
</mat-dialog-actions>
