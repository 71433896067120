<mat-card class="benefit-card"
          [ngClass]="getCardClass()"
          data-cy="benefit.bonago.preCheckout.dashboardRetailers.cardRetailer"
          (click)='navigateToDetails()'>
    <ng-container *ngIf="showLogo();else actions">
        <mat-card-content>
            <img mat-card-image
                 [src]="retailer.assets.LogoURL?.url">
        </mat-card-content>
    </ng-container>

    <ng-template #actions>
        <mat-card-actions>
            <div>
                <form [formGroup]="formGroup">
                    <vn-select formControlName='voucherAmount'
                               placeholder="Gutscheinwert wählen"
                               data-cy="benefit.bonago.preCheckout.cardRetailer.selectFetchValues"
                               key="benefit.bonago.preCheckout.cardRetailer.selectVoucherValue"
                               (click)="fetchVoucherAmountOptions()"
                               [options]='(formOptions.voucherAmount | async)!'>
                        <mat-error *ngIf="showErrors && voucherAmount.hasError('required')">
                            Bitte Gutscheinwert angeben.
                        </mat-error>
                    </vn-select>

                    <vn-select formControlName='voucherQuantity'
                               width="200px"
                               key="benefit.bonago.preCheckout.cardRetailer.selectVoucherQuantity"
                               placeholder="Anzahl wählen"
                               [options]='formOptions?.voucherQuantity'>
                        <mat-error *ngIf="showErrors && voucherQuantity.hasError('required')">
                            Bitte Anzahl angeben.
                        </mat-error>
                    </vn-select>
                </form>
            </div>

            <div class="action-buttons">
                <button mat-mini-fab
                        class='back-button'
                        data-cy="benefit.bonago.preCheckout.cardRetailer.buttonCardView"
                        (click)='toggleActiveMode($event)'>
                    <mat-icon>
                        arrow_left
                    </mat-icon>
                </button>

                <button mat-mini-fab
                        data-cy="benefit.bonago.preCheckout.cardRetailer.buttonDetailsView"
                        class='details-button'
                        (click)='navigateToDetails()'>
                    <mat-icon>
                        info
                    </mat-icon>
                </button>

                <button mat-mini-fab
                        data-cy="benefit.bonago.preCheckout.cardRetailer.buttonAddToBasket"
                        class='basket-button'
                        (click)='addToBasket()'>
                    <mat-icon>
                        add_circle
                    </mat-icon>
                </button>
            </div>
        </mat-card-actions>
    </ng-template>
</mat-card>