const $inject = [
  '$q',
  '$location',
  '$scope',
  '$stateParams',
  '$timeout',
  'StatesDEConstants',
  'SVCalculationConstants',
  'SteuerklassenConstants',
  'KVTypConstants',
  'BAVTypeConstants',
  'BAVParagraphConstants',
  'EinsparungsModellConstants',
  'ALVktgConstants',
  'VATypeConstants',
  'Made',
  'vbmData',
  'employeeService',
  'debounceFactory',
  'dialogService',
  'neoModifierService',
  'authenticationService',
  'customerService',
  'vbmService',
  'PlausibilityService',
  'NotificationService',
  'mppService',
  'MppConstants',
  'CheckoutService',
  'componentsService',
  'BikeConstants',
  'bikeleasingService',
  'VSP_CONSTANTS'
];
export default class BeraterController {

  constructor(
    $q,
    $location,
    $scope,
    $stateParams,
    $timeout,
    StatesDEConstants,
    SVCalculationConstants,
    SteuerklassenConstants,
    KVTypConstants,
    BAVTypeConstants,
    BAVParagraphConstants,
    EinsparungsModellConstants,
    ALVktgConstants,
    VATypeConstants,
    Made,
    vbmData,
    employeeService,
    debounceFactory,
    dialogService,
    neoModifierService,
    authenticationService,
    customerService,
    vbmService,
    PlausibilityService,
    NotificationService,
    mppService,
    MppConstants,
    CheckoutService,
    componentsService,
    BikeConstants,
    bikeleasingService,
    VSP_CONSTANTS
  ) {

    this.$q = $q;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.StatesDEConstants = StatesDEConstants;
    this.SVCalculationConstants = SVCalculationConstants;
    this.SteuerklassenConstants = SteuerklassenConstants;
    this.KVTypConstants = KVTypConstants;
    this.BAVTypeConstants = BAVTypeConstants;
    this.BAVParagraphConstants = BAVParagraphConstants;
    this.EinsparungsModellConstants = EinsparungsModellConstants;
    this.ALVktgConstants = ALVktgConstants;
    this.VATypeConstants = VATypeConstants;
    this.Made = Made;
    this.vbmData = vbmData;
    this.employeeService = employeeService;
    this.debounceFactory = debounceFactory;
    this.dialogService = dialogService;
    this.neoModifierService = neoModifierService;
    this.authenticationService = authenticationService;
    this.customerService = customerService;
    this.vbmService = vbmService;
    this.PlausibilityService = PlausibilityService;
    this.NotificationService = NotificationService;
    this.mppService = mppService;
    this.mppConstants = MppConstants;
    this.CheckoutService = CheckoutService;
    this.componentsService = componentsService;
    this.BikeConstants = BikeConstants;
    this.bikeleasingService = bikeleasingService;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
    this.loading = {
      'pc': false
    };

    this.MPP_COMBINED_SETTINGS = [
      {name: 'Ja', value: true},
      {name: 'Nein', value: false}
    ];

    this.bike = {
      'EBike': {},
      'Bikeleasing': {},
    };

    this.defaultVaPercent = 0.01;

    if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
      vbmData.employeeId = parseInt($location.search().employeeId);
    }

    this.employee = {};
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');

    vbmData.prepare();
    vbmData.trigger = final => this.getDataAndEmployee(final);

    this.compute = debounceFactory(
      () => this.vbmData
        .getResult()
        .then(() => this.employee.plausibilites = this.PlausibilityService.check(this.employee)),
      300);

    this.customerService.getConfiguration().then(configuration => this.infomode = configuration.infomode || false);
    this.minVAZuschuss = false;
    this.currentBavBeitrag = 0;

    this
      .getDataAndEmployee()
      .then(() => {
        let currentYear = new Date().getFullYear();
        if (!(angular.equals(this.employee.data.parameters.version, currentYear) && angular.equals(this.employee.data.parameters.jahr, currentYear))) {
          NotificationService.message('Achtung - Die Berechnung basiert aktuell auf einem alten Steuerjahr.');
        }
      })
      .finally(() => {
        this.calcZuschuss = this.insuranceCalc || true;

        if ((typeof this.neoProject.neo_components.besonderer_anlass !== 'undefined' && this.neoProject.neo_components.besonderer_anlass.enabled) && typeof this.employee.data.person.entrydate != 'string') {
          this.checkEntrydate = true;
        }

        //console.log(typeof this.employee.data.neo_components.Bikeleasing.value, JSON.stringify( this.employee.data.neo_components.Bikeleasing.value ) );

        // Wenn BBG wieder beachtet werden muss
        //this.calcZuschuss = this.insuranceCalc || (this.employee.result.final.gap.calculation_information.bbg_rente > (this.employee.result.final.tax.sv_kvbrutto * 100));
        this.checkMindestlohnMinimum();
        $scope.$watch('berater.employee.data', (newVal, oldVal) => {
          //console.log('Old',JSON.stringify(oldVal.neo_components.Bikeleasing), 'New',JSON.stringify(newVal.neo_components.Bikeleasing));
          if (newVal.parameters.einsparungs_modell !== oldVal.parameters.einsparungs_modell) {
            this.employee.data.insurances.va.beitrag = 0;
            this.employee.data.insurances.va.zuschuss_ag = 0;
            this.employee.data.parameters.evz_manual = false;
            this.vbmData.employees[this.vbmData.employeeId].data.parameters.ohneRentenausgleich = newVal.parameters.einsparungs_modell === 'NONE';
          }

          if (this.checkEntrydate && (newVal.neo_components.besonderer_anlass.enabled !== oldVal.neo_components.besonderer_anlass.enabled) && newVal.neo_components.besonderer_anlass.enabled) {
            this.dialogService.employeeEntrydate(this.vbmData.employeeId).then(() => location.reload());
          }

          this.bavZuschuss();
          if (this.neoExtras) {
            this.checkInsurance();
          }
          this.compareSettings(this.vbmData.employees[this.vbmData.employeeId].data, this.neoProject);
          this
            .calcModifiedValues()
            .finally(() => {
              this.employee.plausibilites = this.PlausibilityService.check(this.employee);

              this.employee.minBVA =
                (this.minInsurance > this.employee.data.insurances.bav.beitrag)
                || this.minVAZuschuss
                || (this.employee.data.insurances.va.beitrag != 0 ? this.employee.data.insurances.va.beitrag < this.minInsurances : false)
                || (this.lowBavAG || this.highBavAG);
              this.getNewMppCart();
              this.compute();
            });

        }, true);
      });

  }


  async getEmployee() {
    let data = this.vbmData.employees[this.vbmData.employeeId].data,
      employee = await this.employeeService.get(this.vbmData.employeeId, Date.now());

    data.person = employee;

    if (!employee.birthdate) {
      this.dialogService.employeeBirthdate(this.vbmData.employeeId).then(() => location.reload());
    }

    data.parameters.geburtsdatum = employee.birthdate;

    this.customerService
      .getNeoProjectByEmployeeId(employee.id)
      .then(neoProject => {
        this.neoProject = neoProject;

        this.customerService.getConfiguration(employee.customer_id)
          .then(
            configuration => {
              this.configuration = configuration;
              this.standalonePCEnabled = this.configuration.neo_config.pc.mpp_type === this.VSP_CONSTANTS.MPP_TYPE.COMPONENT_BASED['id'];
              this.needsPCMigration = this.standalonePCEnabled && !!this.employee.data.neo_components.pc.value;
              if (this.needsPCMigration) {
                this.dialogService
                  .frageDialog('ACHTUNG: Neues PC Leasing ist aktiv - Baustein muss entfernt werden und Vertragsdokumente aktualisiert werden.', '')();
              }
              if (typeof neoProject.project.insurances.va.pension_interest === 'undefined') {
                configuration.neo_project.changed = Date.now();

                configuration.neo_project.insurances.va.pension_interest = this.defaultVaPercent;

                this.customerService
                  .setConfiguration(configuration._id, configuration)
                  .then(
                    () => this.NotificationService.message('Kunde erfolgreich gespeichert.'),
                    () => this.NotificationService.error('Speichern fehlgeschlagen.'),
                  );
              }
            }
          );

      });

    this.$scope.$applyAsync();
  }

  async getData(final) {
    let calc_id = this.$stateParams.calculation_id;
    await this.vbmData.getResult(this.vbmData.employeeId, new Date(), final, calc_id);
    this.employee = this.vbmData.employees[this.vbmData.employeeId];
    this.conditions = this.vbmData.employees[this.vbmData.employeeId].conditions;

    if ('NONE' !== this.vbmData.employees[this.vbmData.employeeId].data.parameters.einsparungs_modell &&
      'VA' !== this.vbmData.employees[this.vbmData.employeeId].data.parameters.einsparungs_modell) {
      this.employee.data.insurances.va.typ = 'DIREKT';
    }
    this.employee.data.insurances.bav.typ = 'DIREKT';

    if (this.vbmData.employees[this.vbmData.employeeId].data.parameters.va_prozent === 0) {
      this.vbmData.employees[this.vbmData.employeeId].data.parameters.va_prozent = this.defaultVaPercent;
    }

    this.oldBAVBeitrag = this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.beitrag;

    this.bavAG = 0;
    let configuration = await this.customerService.getConfiguration(this.vbmData.employees[this.vbmData.employeeId].data.person.customer_id);
    this.infomode = configuration.infomode || false;

    this.customerConfig = configuration;

    this.neoProject = await this.customerService.getNeoProjectByEmployeeId(this.vbmData.employeeId);
    this.insuranceCalc = this.neoProject.project.insurances.bav.zuschuss || true;
    this.noValidateMin = this.neoProject.project.insurances.bav.noValidateMin || false;
    this.bavAG = this.neoProject.project.insurances.bav.value || 0;

    let pc_sum_result = await this.mppService.getEmployeeLeasing(this.vbmData.employeeId);
    this.employee.pc_sum_result = pc_sum_result;

    if (this.employee.data.insurances.va.beitrag == 0) {
      this.minVaCheck = true;
    }

    this.setBikeConfigDefaults();

    this.compareSettings(this.employee.data, this.neoProject);

    if (this.employee.projectDifference) {
      if (this.iAmGAdvisor || this.iAmAdvisor || this.iAmSpecialAdvisor) {
        this.dialogService
          .frageDialog('Cockpit-Einstellungen wurden geändert, möchten Sie diese auf die Berechnung anwenden?', 'Projekt Einstellungen')
          .then(
            () => {
              this.vbmService.applyConfig(this.vbmData.employees[this.vbmData.employeeId].data).then(result => {
                this.vbmData.setAlternateData(this.vbmData.employeeId, result.params, this.neoProject);
              });
            },
            () => {
            }
          );
      }
    }
    this.currentBavBeitrag = this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.beitrag || 0;
    this.bavZuschuss();
    await this.getInsurances(final);

    this.getNewMppCart();
    this.maxValues();

    this.$scope.$applyAsync();
  }

  changePCCompValue() {
    if (this.basket.report) {
      this.employee.data.neo_components.pc.component_value = this.basket.leasing;
    } else {
      this.employee.data.neo_components.pc.component_value = 0;
    }
  }

  getNewMppCart() {
    if (this.configuration.neo_config.pc.useInternalMPP !== 0 && this.employee.data.neo_components.pc.enabled) {
      this.mppService.getCart(this.vbmData.employeeId).then(basket => {
        this.basket = basket;
        this.definalizeReadOnly = this.iAmSpecialAdvisor && !basket.closed;
        this.orderButtonState = basket.closed;
      });
    } else {
      this.basket = false;
    }
  }

  getDataAndEmployee(final) {
    return this.$q.all([
      this.getData(final),
      this.getEmployee(),
    ]);
  }

  async getSumAllActive() {
    this.$timeout(() => {
      this.loading.pc = true;
    });

    let pc_sum_result = await this.mppService.getEmployeeLeasing(this.vbmData.employeeId);
    this.employee.pc_sum_result = pc_sum_result;

    this.$timeout(() => {
      this.loading.pc = false;
    });
  }

  calcModifiedValues() {

    return this.neoModifierService.calculateModifiedValues(
      this.employee.data.neo_components,
      this.employee.data.parameters.jahr,
      this.vbmData.employeeId,
    );
  }

  async editEmployee() {
    try {
      await this.dialogService.employeeCreate(this.vbmData.employeeId);
      await this.getEmployee();
    } finally {
      this.compute();
    }
  }

  disableActivate() {
    return this.standalonePCEnabled && (this.employee.data && !this.employee.data.neo_components.pc.enabled);
  }

  calculateVA() {
    return this.dialogService.question(
      'Bitte berücksichtigen Sie, dass hier nur ein auf den aktuellen Eingaben basierender Beitrag für den Rentenausgleich ermittelt wird. Dieser wird nicht automatisch in die Berechnung übernommen!',
      'Berechne Versorgungsausgleich',
      'OK'
    )
      .then(() => this.vbmData.calculateVA());
  }

  calculateZielausgleich() {

    this.zielausgleichError = false;
    this.zielausgleichCalculating = true;
    return this.vbmData
      .calculateAdditionalEntgeltverzicht()
      .then(() => {
        this.vbmData.save(this.vbmData.employeeId);
        this.minimumVAZuschussAg = this.vbmData.employees[this.vbmData.employeeId].data.insurances.va.zuschuss_ag;
        if (this.minVAZuschuss) {
          this.minVAZuschuss = false;
          this.changeVAvalue();
        }

      }, error => {
        if (
          'object' === typeof error.error &&
          'object' === typeof error.error.message &&
          1000 === error.error.message.error_code
        ) {
          this.zielausgleichError = true;
        }
        this.NotificationService.default.checkoutSession(error);
      }).finally(() => this.zielausgleichCalculating = false);
  }

  updateZielausgleichArt() {

    if ('NONE' === this.vbmData.employees[this.vbmData.employeeId].data.parameters.einsparungs_modell) {
      this.vbmData.employees[this.vbmData.employeeId].data.parameters.ohneRentenausgleich = true;
      this.vbmData.employees[this.vbmData.employeeId].data.metadata.behandlung_alvktg = 'OHNE_BERUECKSICHTIGUNG';
      this.vbmData.employees[this.vbmData.employeeId].data.insurances.va.zuschuss_ag = 0;
      this.vbmData.employees[this.vbmData.employeeId].data.insurances.va.beitrag = 0;
      this.vbmData.employees[this.vbmData.employeeId].data.insurances.va.typ = 'OHNEBERUECKSICHTIGUNG';
      this.minVAZuschuss = false;
    } else if ('VA' === this.vbmData.employees[this.vbmData.employeeId].data.parameters.einsparungs_modell) {
      this.vbmData.employees[this.vbmData.employeeId].data.parameters.ohneRentenausgleich = false;
      this.vbmData.employees[this.vbmData.employeeId].data.metadata.behandlung_alvktg = this.customerConfig.neo_project.metadata.behandlung_alvktg;
      this.vbmData.employees[this.vbmData.employeeId].data.insurances.va.zuschuss_ag = 0;
      this.vbmData.employees[this.vbmData.employeeId].data.insurances.va.beitrag = 0;
      this.vbmData.employees[this.vbmData.employeeId].data.insurances.va.typ = 'OHNEBERUECKSICHTIGUNG';
    } else {
      this.vbmData.employees[this.vbmData.employeeId].data.parameters.ohneRentenausgleich = false;
    }

    if (!this.vbmData.employees[this.vbmData.employeeId].data.parameters.evz_manual) {
      //this.vbmData.employees[ this.vbmData.employeeId ].data.parameters.entgeltverzicht_additional = 0;
    }

    this.vbmData.employees[this.vbmData.employeeId].data.parameters.einsparungs_wert = 0;
    this.vbmData.employees[this.vbmData.employeeId].data.parameters.einsparungs_prozent = 0;
  }

  updateAvwlZuschuss() {
    let data = this.vbmData.employees[this.vbmData.employeeId].data;
    if (data.parameters.avwl_zuschuss) {
      data.parameters.vl_zuschuss_neu_checked = false;
      data.parameters.vl_zuschuss_neu_wert = 0;
    }
  }

  async openKinderAssistant() {

    let total = await this.dialogService.kinderAssistant(this.vbmData.employeeId);
    this.vbmData.employees[this.vbmData.employeeId].data.neo_components.kinder.value = total.toFixed(2);

    this.$scope.$applyAsync();
  }

  async openMPP() {

    let data = this.vbmData.employees[this.vbmData.employeeId].data,
      customerId = data.person.customer_id,

      mppLink = '123456789';

    let configuration = await this.customerService.getConfiguration(data.person.customer_id);

    if (!configuration.testmode && !configuration.neo_config.pc.useInternalMPP) {
      mppLink = await this.vbmService.getMPPLink(customerId);
    }

    if (!configuration.neo_config.pc.useInternalMPP) {
      window.open('https://vmpp.cyberport.de/?KEY=' + mppLink, '_blank');
    } else {

      this.calcModifiedValues()
        .finally(() => {
          return this.vbmData
            .save(this.vbmData.employeeId)
            .then(() => {
            }, error => this.NotificationService.default.checkoutSession(error));
        })
        .finally(() => {
          window.open('/mpp?employeeId=' + this.vbmData.employeeId + '&calc', '_self');
        });

    }
  }

  async saveMPP(cart) {
    this.employee.data.neo_components.pc.value = cart.leasing = await this.mppService.updateLeasing(cart);

    this.mppService.setCart(cart);
  }

  async updateMPP(cart) {
    this.employee.data.neo_components.pc.value = cart.leasing = await this.mppService.updateLeasing(cart);

    await this.saveMPP(cart);
  }

  definalizeMpp(cart) {
    this.dialogService.cancelCheckoutMPP(cart.locked, this.vbmData.employeeId, cart._id);
  }

  sendMPPInfomodeOrder() {
    this.orderBusy = true;

    this.mppService.sendInfomodeOrder(this.vbmData.employeeId)
      .then(() => {
        this.orderButtonState = true;
        this.orderBusy = false;
        this.dialogService.question('Vielen Dank. Wir haben Ihre Vorab-Bestellung erhalten. Sie erhalten eine Zusammenfassung als Mail.\n' +
          'Wir kommen in Kürze auf Sie zu.', '', 'OK');
      });
  }

  openAdressDialog() {
    this.dialogService.employeeAddress(this.vbmData.employeeId);
  }

  calculateDistance() {

    this.vbmService
      .getDistance(this.vbmData.employeeId)
      .then(distance => this.vbmData.employees[this.vbmData.employeeId].data.neo_components.entfernung.anzahl_km = distance);
  }

  addZulage() {

    this.vbmData.employees[this.vbmData.employeeId].data.parameters.zulagen.push({
      description: '',
      value: 0,
    });

  }

  changedBrutto() {
    if (this.employee.data.parameters.brutto > 1300 || this.employee.data.parameters.brutto < 450.01) {
      this.employee.data.parameters.gleitzone = true;
    }
  }

  changeBesondererAnlassAnlass(anlass) {

    let besonderer_anlass = this.employee.data.neo_components.besonderer_anlass,
      birthdate = this.employee.data.person.birthdate,
      entrydate = this.employee.data.person.entrydate;

    if (besonderer_anlass['betrag' + anlass] == null || besonderer_anlass['betrag' + anlass] <= 0) {
      besonderer_anlass['betrag' + anlass] = 60;
    }

    switch (besonderer_anlass['anlass' + anlass]) {
      case 'Geburtstag':
        besonderer_anlass['datum' + anlass] = birthdate.replace(/\d{2,4}$/, '');
        break;
      case 'Firmenjubiläum':
        besonderer_anlass['datum' + anlass] = entrydate.replace(/\d{2,4}$/, '');
        break;
      case '':
        besonderer_anlass['datum' + anlass] = '';
        besonderer_anlass['betrag' + anlass] = 0;
        // delete besonderer_anlass[ 'anlass' + anlass ];
        break;
    }

  }

  editEmployeeContactDetails() {
    this.dialogService
      .employeeContactDetails(this.employee.data.person)
      .then(() => this.getEmployee());
  }

  changeDefaultMindestlohn() {
    let defaultData = this.employee.data.metadata.mindestlohnData,
      selectedYear = this.employee.data.parameters.jahr,
      currentMinimum = this.employee.data.metadata.mindestlohn;

    if (defaultData[selectedYear]) {
      this.employee.data.metadata.mindestlohn = currentMinimum < defaultData[selectedYear] ? defaultData[selectedYear] : currentMinimum;
    } else {
      this.employee.data.metadata.mindestlohn = currentMinimum ? currentMinimum : 0;
    }
  }

  checkMindestlohnMinimum() {

    let defaultData = this.employee.data.metadata.mindestlohnData,
      selectedYear = this.employee.data.parameters.jahr,
      currentMinimumWage = this.employee.data.metadata.mindestlohn,
      currentMinimum = defaultData[selectedYear] ? defaultData[selectedYear] : 0;

    if (currentMinimumWage < currentMinimum) {
      this.employee.data.metadata.mindestlohn = currentMinimum;
    }
  }

  checkMobiles() {
    if (!this.employee.data.neo_components.handy.AnzahlTelefon && !this.employee.data.neo_components.handy.mpp_combined) {
      this.employee.data.neo_components.handy.AnzahlTelefon = 1;
    }
  }

  bavZuschuss() {
    if (this.calcZuschuss) {
      let praemie = this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.beitrag,
        zuschuss = this.bavAG / 100,
        zuschussVal = this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.zuschuss_ag;

      if (this.oldBAVBeitrag !== praemie || !this.noValidateMin) {
        this.oldBAVBeitrag = praemie;
        let ag_min = this.vbmData.getBAVZuschuss(praemie, zuschuss);

        if (praemie !== this.currentBavBeitrag) {
          if (ag_min > zuschussVal) {
            this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.zuschuss_ag = ag_min;
          }
          if (zuschussVal > praemie) {
            this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.zuschuss_ag = praemie;
          }
          this.lowBavAG = false;
          this.highBavAG = false;
          this.currentBavBeitrag = praemie;
        } else {
          if (ag_min > zuschussVal) {
            this.lowBavAG = true;
          }
          if (zuschussVal > praemie) {
            this.highBavAG = true;
          }
        }

        if (zuschussVal === undefined || isNaN(zuschussVal)) {
          this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.zuschuss_ag = 0;
        }

        this.minBavAG = this.noValidateMin ? 0 : ag_min;
      }
    }

    if (!this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.beitrag) {
      this.vbmData.employees[this.vbmData.employeeId].data.insurances.bav.zuschuss_ag = 0;
    }
  }

  checkInsurance() {
    this.minInsurances = this.insurancesId[this.neoExtras.intversicherer] && this.insurancesId[this.neoExtras.intversicherer].value ? this.insurancesId[this.neoExtras.intversicherer].value : this.insuranceDefaultMin;

    if (this.employee.data.insurances.bav.beitrag !== 0) {
      this.minInsurance = this.minInsurances;
    } else {
      this.minInsurance = 0;
      this.employee.data.insurances.bav.zuschuss_ag = 0;
    }
  }

  async getInsurances(loadFinal = false) {
    let calc_versicherer = -1;
    if (loadFinal) {
      calc_versicherer = await this.vbmService.getCalcVersicherer(this.vbmData.employeeId);
    }

    let insurances = await this.customerService.getInsurancesByCustomerId(this.vbmData.employees[this.vbmData.employeeId].data.person.customer_id);

    this.insuranceDefault = insurances.default;
    this.insuranceDefaultMin = 0;
    this.insurances = insurances.insurances;
    this.insurancesId = {};

    if (!this.insurances.length && typeof this.insuranceDefault === 'undefined') {
      let generalInsurances = await this.customerService.listInsurances();
      for (let i = 0; i < generalInsurances.length; i++) {
        let ins = generalInsurances[i];
        if (ins.id === 2) {
          this.insuranceDefault = 2;
          this.insurances = [ins];
          break;
        }
      }
    }

    try {
      this.neoExtras = await this.CheckoutService.getNeoExtras(this.vbmData.employeeId);
    } catch (exp) {
      let id = await this.employeeService.createMade(this.vbmData.employeeId, this.vbmData.employees[this.vbmData.employeeId].data.person);
      this.neoExtras = await this.CheckoutService.getNeoExtras(this.vbmData.employeeId);
    }

    let maxValues = await this.componentsService.getMaxValues('bav', this.employee.data.parameters.jahr);
    this.maxBav = maxValues.bav;

    if (this.neoExtras.intversicherer === undefined || this.neoExtras.intversicherer == 0 || this.neoExtras.intversicherer == 'None') {
      this.neoExtras.intversicherer = this.insuranceDefault;
    }
    this.neoExtras.intversicherer = calc_versicherer !== -1 ? parseInt(calc_versicherer) : parseInt(this.neoExtras.intversicherer);

    let insurancesMinimum = 0,
      check = true;

    for (let insurance of this.insurances) {
      if (insurance['produkt1'] === '') {
        continue;
      }

      let val = Math.round(parseFloat(insurance['produkt1']) * 100) / 100;

      this.insurancesId[insurance.id] = {value: val};
      if (insurance.id === this.insuranceDefault) {
        insurancesMinimum = val;
        check = false;
      }

      if (insurance['produkt1'] > insurancesMinimum && check) {
        insurancesMinimum = val;
      }
    }

    if (!insurancesMinimum) {
      insurancesMinimum = 15;
    }

    this.insuranceDefaultMin = insurancesMinimum;
    this.minInsurance = insurancesMinimum;
    this.minInsurances = insurancesMinimum;
  }

  setNeoExtras() {
    this.CheckoutService.getSession(vbmData.employeeId).then((session) => {
      this.CheckoutService.setNeoExtras(
        this.vbmData.employeeId,
        session.preCalculationId,
        this.neoExtras
      ).then(() => {
        this.checkInsurance();
      });
    });
  }

  compareSettings(employee, neoProject) {
    this.vbmData.compareSettings(employee, neoProject);
  }

  calcEBikeAdvantage() {
    let data = JSON.parse(
      JSON.stringify(this.vbmData.employees[this.vbmData.employeeId].data),
    );

    this.vbmService.getSingleResult(data, 'EBike').then(result => {
      let nettoplus =
        result.before.tax.net_netto - result.final.tax.net_auszahlung;
      this.totalEBikeExpense =
        nettoplus * 36 + data.neo_components.EBike.listenpreis * 0.18;
      this.relEBikeRate = this.totalEBikeExpense / 36;
      this.advEBikePercent =
        (1 - this.totalEBikeExpense / data.neo_components.EBike.verkaufspreis) *
        100;

      this.showEBikeAdvantage = true;
    });
  }

  changeVAvalue() {
    if (this.neoProject.project.versorgungsausgleichAGfinanziert) {
      this.employee.data.insurances.va.beitrag = this.employee.data.insurances.va.zuschuss_ag;
    } else {
      this.minVAZuschuss = this.employee.data.insurances.va.zuschuss_ag < this.minimumVAZuschussAg;
    }
  }

  setVAZuschuss() {
    if (!this.neoProject.project.versorgungsausgleichAGfinanziert) {
      let newZuschussAG = this.vbmData.getBAVZuschuss(this.employee.data.insurances.va.beitrag, this.bavAG / 100);

      this.employee.data.insurances.va.zuschuss_ag = newZuschussAG;
      this.minimumVAZuschussAg = newZuschussAG;
      this.minVAZuschuss = false;
    }
  }

  maxValues() {
    this.componentMaxValues = {};

    this.componentMaxValues['essenscheck'] = this.neoProject.neo_components.essenscheck.max_value ? this.neoProject.neo_components.essenscheck.max_value : this.vbmData.employees[this.vbmData.employeeId].data.metadata.arbeitstage;

    this.componentsService.getMaxValues()
      .then(result => {
        let components = this.neoProject.neo_components;

        for (const key in result) {
          if (key === 'bav') {
            continue;
          }

          if (typeof components[key].max_value !== 'undefined' && components[key].max_value > 0) {
            this.componentMaxValues[key] = components[key].max_value;
          } else {
            this.componentMaxValues[key] = result[key];
          }
        }
      });
  }

  setBikeConfigDefaults() {
    let bikeConfig = this.BikeConstants;

    if (this.customerConfig.neo_config.bike) {
      bikeConfig = {...bikeConfig, ...this.customerConfig.neo_config.bike};
    }

    this.customerConfig.neo_config.bike = bikeConfig;

    this.premAN = this.customerConfig.neo_config.bike.traegerVersicherungPremiumPlus == 'an_frei';
    this.inspectAN = this.customerConfig.neo_config.bike.inspektion == 'an_frei';

    this.result = false;
    this.inputs = {
      verkaufspreis: 0,
      listenpreis: 0,
      versicherungPremAN: false,
      inspection: false
    };

  }

  // The special needs area
  calcBikeAdvantage() {
    this.$q.when(this.getDetails()).then(() => {
      //this.showEBikeAdvantage = true;
    });

  }

  async getDetails() {
    let data = JSON.parse(
      JSON.stringify(this.vbmData.employees[this.vbmData.employeeId].data),
    );

    let currentBikeConfig = this.customerConfig.neo_config.bike;
    this.result = await this.bikeleasingService.getDetailedResult(currentBikeConfig, this.inputs, data);
  }

  updateRatenausfallPremium() {
    this.customerConfig.neo_config.bike.ausfallversicherungKuendigung = false;
    this.customerConfig.neo_config.bike.rechtschutzHaftpflicht = false;
  }

}
BeraterController.$inject = $inject;
