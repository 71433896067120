import add_bike_dialog_html from '../../../dialogs/add_bike_dialog/add_bike_dialog.pug';

const $inject = [
  '$scope',
  '$timeout',
  'lodash',
  'dialogService',
  'administrationService',
  'NotificationService'
];

export default class AdministrationBikeApprovalController {
  constructor(
    $scope,
    $timeout,
    lodash,
    dialogService,
    administrationService,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      dialogService,
      lodash,
      $timeout,
      administrationService,
      NotificationService
    });

    this.init();
  }

  init() {
    this.loading = {
      main: false,
      sync_bikeleasing: false
    };

    this.watchers = [];
    this.filters_wrapper = {};

    this.watchers.push(
      this.$scope.$watch(() => {
        return this.filters_wrapper;
      }, async (newValue, oldValue) => {
        if (newValue) {
          await this.loadData();
        }
      })
    );

    this.$scope.$on('destroy', () => {
      for (let watcher of this.watchers) {
        watcher();
      }
    });
  }

  async syncronizeBikeleasing() {
    this.$timeout(() => {
      this.loading.sync_bikeleasing = true;
    });


    await this.administrationService.synchronizeBikeleasing();

    this.NotificationService.message('OK');
    this.$timeout(() => {
      this.loading.sync_bikeleasing = false;
    });
  }

  async loadData() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  addNeoBikeleasing() {
    this.dialogService.ngDialog.openConfirm({
      template: add_bike_dialog_html(),
      plain: true,
      controller: 'AddNeoBikeDialogController',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      data: {
        'employee_id': undefined
      },
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    });
  }
}
AdministrationBikeApprovalController.$inject = $inject;
