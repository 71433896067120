import quiz_html from './quiz.pug';

angular
  .module('VSPApp')
  .directive('quiz', [ function () {

    return {
      restrict: 'E',
      scope: {
        quiz: '='
      },
      replace: true,
      template: quiz_html
    };
  }]);
