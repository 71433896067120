import { BonusComponentController } from './BonusComponentController';

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export class BonusComponentControllerBikeleasing extends BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {
    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {

        this.loading.child = false;
      }, 0);
    });
  }

  async init() {

    this.premAN = false;
    this.inspectAN = false;

    await this.BonusService.calculateBikeleasingLimits(this.employee_id, this.bonus_project._id, this.customer_id)
      .then(limits => {
        this.limits = limits;

        this.bikeleasing_permitted = this.limits.has_bbg || this.limits.is_user_rate_valid;
      }).catch(() => {
        // TODO create exception filters (currently, we don't care about errors from this call)
      });

    await this.BonusService.isOverspendingAllowed(this.employee_id).then(is_overspending_allowed => {
      this.is_overspending_allowed = is_overspending_allowed;
    });


    this.inputs = {
      list_price: undefined,
      buy_price: undefined,
      insurance_prem_an: false,
      inspection: false
    };


    this.agCode = this.lodash.get(await this.customerService.getConfigurationByCustomerId(this.bonus_project.customer_id), 'neo_config.bike.agCode', '');
  }

  async generateBikeleasingDocument() {
    let now = this.moment().format('HH:mm DD.MM.YYYY')
    return this.administrationService.createBikeleasingBonusDocument(this.Made.user.valuenet_id, now).then((res) => {
      this.$window.open(res.response, '_blank');
    }).catch((err) => {
      console.log(err);
    });
  }
}

BonusComponentControllerBikeleasing.$inject = $inject;
