angular
  .module('VSPApp')
  .service('vbmData', [
    '$q', '$rootScope', 'Made', 'vbmScaffoldConstant', 'vbmService', 'componentsService', 'customerService', 'neoModifierService', 'CheckoutService', 'lodash',
    function ($q, $rootScope, Made, vbmScaffoldConstant, vbmService, componentsService, customerService, neoModifierService, CheckoutService, lodash
    ) {

      this.employees = {};
      this.employeeId = false;
      this.componentNames = null;

      this._getResult = (id = this.employeeId, save = false, stale = new Date(), final = false, preCalculationId = false, override = false) => {

        this.prepare(id);

        if (this.employees[id].result.promise && !save && !final) {
          return this.employees[id].result.promise;
        }
        if (
          !save &&
          !final &&
          this.employees[id].result &&
          this.employees[id].stale > stale
        ) {
          return $q.when(this.employees[id].result);
        }

        delete this.employees[id].error;

        this.employees[id].result.promise = this
          .getData(id, preCalculationId ? new Date() : 0, final, preCalculationId)
          .then(data => vbmService.getResult(data, save, override))
          .then(data => {
            if (save) {
              return this
                .getData(id, new Date(), final)
                .then(() => data);
            }
            return data;
          })
          .then(data => {

            this.employees[id].result = data;

            this.refresh(id);

            this.employees[id].stale = new Date();

            return this.employees[id].result;
          });

        this.employees[id].result.promise
          .catch(error => this.employees[id].error = error);

        this.employees[id].result.promise
          .finally(() => {
            delete this.employees[id].result.promise;
          });

        return this.employees[id].result.promise;
      };

      this.getComponentNames = () => {
        if (this.componentNames) {
          return $q.when(this.componentNames);
        } else {
          return Made.request('rpc://vbm/neo/get_component_names', {})
            .then(result => {
              this.componentNames = result;
              return this.componentNames;
            });
        }
      };

      this.getComponent = (componentId, employeeId = this.employeeId) => {
        if ('bav' === componentId) {
          if (!this.employees[employeeId].data.insurances.bav) {
            this.employees[employeeId].data.insurances.bav = {};
          }
          return this.employees[employeeId].data.insurances.bav;
        }
        if (!this.employees[employeeId].data.neo_components[componentId]) {
          this.employees[employeeId].data.neo_components[componentId] = {};
        }
        return this.employees[employeeId].data.neo_components[componentId];
      };

      this.getData = (id = this.employeeId, stale = new Date(), final = false, preCalculationId = false) => {
        this.prepare(id);

        if (this.employees[id].dataPromise && !final) {
          return this.employees[id].dataPromise;
        }
        if (this.employees[id].data && this.employees[id].stale > stale && !final) {
          return $q.when(this.employees[id].data);
        }

        this.employees[id].dataPromise = vbmService
          .getData(id, final, preCalculationId)
          .then(data => {

            this.employees[id].data.metadata = data.metadata;
            this.employees[id].data.parameters = data.parameters;
            this.employees[id].data.neo_components = data.neo_components;
            this.employees[id].data.insurances = data.insurances;

            if (this.employees[id].data.person && this.employees[id].data.person.birthdate) {
              this.employees[id].data.parameters.geburtsdatum = this.employees[id].data.person.birthdate;
            }

            this.refresh(id);

            this.employees[id].stale = new Date();

            return this.employees[id].data;
          });

        this.employees[id].dataPromise
          .finally(() => {
            delete this.employees[id].dataPromise;
          });

        return this.employees[id].dataPromise;
      };

      this.getResult = (id, stale, final = false, preCalculationId = false) => this._getResult(id, false, stale, final, preCalculationId);

      this.save = (id, save = 'session', override = false) => this._getResult(id, save, false, undefined, undefined, override);

      this._setEntgeltVerzichAdditional = value => {
        if (!this.employees[this.employeeId].data.parameters.evz_manual)
          this.employees[this.employeeId].data.parameters.entgeltverzicht_additional = value;
      };

      // this._addEntgeltVerzichAdditional = value => {
      //   if ( !this.employees[ this.employeeId ].data.parameters.evz_manual )
      //     this.employees[ this.employeeId ].data.parameters.entgeltverzicht_additional = value;
      // };

      this.prepare = (id = this.employeeId) => {

        if (!this.employees[id]) {
          this.employees[id] = angular.copy(vbmScaffoldConstant);
        }
        return id;
      };

      this.refresh = id => {

        this.employees[id].components = componentsService.getComponentsOfEmployee(this.employees[id]);

        componentsService
          .getUnknownComponents(this.employees[id])
          .then(unknownComponents => this.employees[id].unknownComponents = unknownComponents);

        this.employees[id].componentSum = componentsService.getComponentSum(this.employees[id]);
      };

      this.hasComponents = (id = this.employeeId) => {
        this.prepare(id);
        return this.employees[id].components.length + this.employees[id].unknownComponents.length > 0;
      };

      this.isCalculationFinalizationAllowed = (options) => {
        let params = {
          employee_id: options.employee_id,
          calculation_id: options.calculation_id,
          calculation: options.calculation
        };

        return Made.request('rpc://vbm/neo/is_calculation_finalization_allowed', params);
      };

      this.hasBAV = (id = this.employeeId) => {
        this.prepare(id);
        return this.employees[id].data.insurances.bav && this.employees[id].data.insurances.bav.beitrag;
      };

      this.getNeoDocuments = (id = this.employeeId) => {

        this.prepare(id);

        return CheckoutService
          .getNeoDocuments(id)
          .then(documents => this.employees[id].neoDocuments = documents);
      };

      this.setNeoDocuments = (id = this.employeeId) => {

        this.prepare(id);

        return CheckoutService.setNeoDocuments(id, this.employees[id].neoDocuments);
      };

      this.finalizeCalculation = (neoExtras, employeeId = this.employeeId) => {
        this.prepare(employeeId);

        return CheckoutService.finalizeCalculation(employeeId, neoExtras);
      };

      this.getNeoChoices = (id = this.employeeId) => {
        this.prepare(id);

        return CheckoutService
          .getNeoChoices(id)
          .then(choices => this.employees[id].neoChoices = choices);
      };

      this.updateSachbezugBonagoScheme = (bonago_scheme_id, employee_id = this.employeeId) => {

        return CheckoutService.updateSachbezugBonagoScheme(this.employees[employee_id].data.metadata.id, bonago_scheme_id);
      };

      this.setNeoChoices = (id = this.employeeId) => {
        this.prepare(id);
        return CheckoutService.getSession(id).then(session => {
          return CheckoutService.setNeoChoices(
            id,
            this.employees[id].data.metadata.id,
            session.preCalculationId,
            this.employees[id].neoChoices
          );
        });
      };

      this.getNeoAgreement = (id = this.employeeId) => {
        this.prepare(id);

        return CheckoutService
          .getNeoAgreement(id)
          .then(agreement => this.employees[id].neoAgreement = agreement);
      };

      this.setNeoAgreement = (id = this.employeeId, calculation_id) => {
        this.prepare(id);

        return CheckoutService.setNeoAgreement(
          id,
          calculation_id,
          this.employees[id].neoAgreement
        );
      };

      this.calculateVA = (employeeId = this.employeeId) => {
        return Made.request('rpc://vbm/versorgungs_ausgleich', {
          pension_gap: this.employees[employeeId].result.final.gap.rente,
          pension_interest: this.employees[employeeId].data.parameters.va_prozent,
          years_to_pension: vbmService.yearsToPension(this.employees[employeeId])
        })
          .then(response => this.employees[employeeId].resultVA = (Math.ceil((response + 0.1) * 2) / 2).toFixed(1));
      };


      this.calculateAdditionalEntgeltverzicht = (employeeId = this.employeeId) => {

        let params = {
          pension_gap: this.employees[employeeId].result.final.gap.rente,
          pension_interest: this.employees[employeeId].data.parameters.va_prozent,
          years_to_pension: vbmService.yearsToPension(this.employees[employeeId]),
          metadata: this.employees[employeeId].data.metadata,
          parameters: this.employees[employeeId].data.parameters,
          neo_components: this.employees[employeeId].data.neo_components,
          insurances: this.employees[employeeId].data.insurances,
          mode: this.employees[employeeId].data.parameters.einsparungs_modell,
          va_auto: !(this.employees[employeeId].data.parameters.ohneRentenausgleich)
        };

        params.value = 0;
        if ([
          'AN',
          'AG'
        ].includes(this.employees[employeeId].data.parameters.einsparungs_modell)) {

          params.value = this.employees[employeeId].data.parameters.einsparungs_wert;

        } else if ([
          'AN_RELATIVE',
          'AG_RELATIVE',
          'AN_AG_RELATIVE'
        ].includes(this.employees[employeeId].data.parameters.einsparungs_modell)) {

          params.value = this.employees[employeeId].data.parameters.einsparungs_prozent;
        }

        if (isNaN(params.metadata.id) || null === params.metadata.id) params.metadata.id = 0;

        let promise = Made
          .request('rpc://vbm/zielausgleich/calculateAdditionalEntgeltverzicht', params)
          .then(zielausgleichResult => {

            var zielausgleich = 0;

            if (zielausgleichResult) {
              zielausgleich = zielausgleichResult.additional_entgeltverzicht;
            }

            this.employees[employeeId].resultZielausgleich = zielausgleich;
            this._setEntgeltVerzichAdditional(parseFloat((zielausgleich / 100).toFixed(2)));

            if (!this.employees[employeeId].data.parameters.ohneRentenausgleich) {
              if (zielausgleichResult.error_code == 0) {
                this.zrZuschussAg = zielausgleichResult.zuschuss_ag / 100;
                this.zrBeitragAN = zielausgleichResult.va / 100;
                this.zrBeitragANprecise = zielausgleichResult.va_precise / 100;

                return this.getEmployeeMinInsurance(employeeId);
              }
            } else {
              //TODO: show error  zielausgleichResult.plausibility
            }
            //return zielausgleich;
          }).then(insurance => {
            if (insurance) {
              this.employees[employeeId].data.insurances.va.beitrag = this.zrBeitragAN;
              this.employees[employeeId].resultVA = parseFloat(this.zrBeitragANprecise);
              this.employees[employeeId].data.insurances.va.typ = 'DIREKT';
              this.employees[employeeId].data.insurances.va.zuschuss_ag = parseFloat(this.zrZuschussAg);

              if (this.employees[employeeId].data.metadata.versorgungsausgleichAGfinanziert) {
                this.employees[employeeId].data.insurances.va.zuschuss_ag = this.zrBeitragAN;
              }
            }
          });

        promise.catch(() => {
          //this._setEntgeltVerzichAdditional( 0 );
        });

        return promise;
      };

      this.getDocument = (report_id, employee_id = this.employeeId) => {

        if (this.employees[employee_id].data.person) {
          this.employees[employee_id].data.calcai.locid = this.employees[employee_id].data.person.loc_id;
          this.employees[employee_id].data.calcai.ort = this.employees[employee_id].data.person.address.city;
          this.employees[employee_id].data.calcai.plz = this.employees[employee_id].data.person.address.zip;
          this.employees[employee_id].data.calcai.strasse = this.employees[employee_id].data.person.address.street;
        }

        return vbmService.getDocument(
          this.employees[employee_id].data.calcai,
          employee_id,
          report_id
        );
      };

      //TODO: get neo projects via neoProjectId
      this.applyNeoProject = (neoProject, withComponentValues = false, employeeId = this.employeeId) => {

        if (
          neoProject.project.insurances &&
          neoProject.project.insurances.va &&
          neoProject.project.insurances.va.typ
        ) {
          this.employees[employeeId].data.insurances.va.typ = neoProject.project.insurances.va.typ;
        }
        if (
          neoProject.project.metadata &&
          neoProject.project.metadata.behandlung_alvktg
        ) {
          this.employees[employeeId].data.metadata.behandlung_alvktg = neoProject.project.metadata.behandlung_alvktg;
        }
        if (neoProject.project.zielausgleich) {
          this.employees[employeeId].data.parameters.einsparungs_modell = neoProject.project.zielausgleich.mode;
          this.employees[employeeId].data.parameters.einsparungs_wert = +neoProject.project.zielausgleich.value;
        }

        if (withComponentValues) {
          this.employees[employeeId].data.neo_components = {};
        }

        angular.forEach(neoProject.components, (component, componentId) => {
          if (component.enabled) {
            if (withComponentValues) {
              this.employees[employeeId].data.neo_components[componentId] = angular.copy(component);
            } else if (this.employees[employeeId].data.neo_components[componentId]) {
              this.employees[employeeId].data.neo_components[componentId].pst = component.pst;
            }
            if (
              this.employees[employeeId].data.neo_components[componentId] &&
              this.employees[employeeId].data.neo_components[componentId].anzahl_std_abwesend
            ) {
              this.employees[employeeId].data.neo_components[componentId].anzahl_std_abwesend = +this.employees[employeeId].data.neo_components[componentId].anzahl_std_abwesend;
            }
          }
        });

        return neoModifierService
          .calculateModifiedValues(
            this.employees[employeeId].data.neo_components,
            this.employees[employeeId].data.parameters.jahr,
            employeeId
          )
          .then(() => {
            return this.calculateZielausgleich(employeeId);
          })
          .then(zielausgleich => {
            if (!zielausgleich) zielausgleich = 0;
            this._setEntgeltVerzichAdditional(parseFloat((zielausgleich / 10000).toFixed(2)));
          }, () => {
            this._setEntgeltVerzichAdditional(0);
          });
      };

      this.trigger = angular.noop;

      if (!this.employeeId) {
        this.employeeId = (Made.user.valuenet_id || 0);
      }
      this.prepare();

      $rootScope.$on('authenticationService.login', user => {
        this.employeeId = user.valuenet_id;
        this.prepare();
      });

      $rootScope.$on('authenticationService.logout', () => {
        this.employeeId = false;
      });

      this.getBAVZuschuss = (beitrag, zuschuss) => {
        return Math.round((beitrag - (beitrag / (1 + zuschuss))) * 100) / 100;
      };

      this.getEmployeeMinInsurance = async (employeeId) => {
        let [neoExtras, insurances] = [
          await CheckoutService.getNeoExtras(employeeId),
          await customerService.listInsurances()
        ];

        if (neoExtras.intversicherer === undefined || neoExtras.intversicherer == 0 || neoExtras.intversicherer == 'None') {
          neoExtras.intversicherer = 2;
        }

        //     console.log('Get current insurance', insurances.filter( insurance => (insurance.id == neoExtras.intversicherer)));

        return insurances.filter(insurance => (insurance.id == neoExtras.intversicherer));
      };

      this.setAlternateData = (id, data, neoProject) => {
        if (neoProject) {
          this.neoProject = neoProject
        }

        if (data.parameters.einsparungs_modell == 'AN' || data.parameters.einsparungs_modell == 'AG') {
          if (data.parameters.einsparungs_wert != this.neoProject.project.zielausgleich.value) {
            data.parameters.einsparungs_wert = this.neoProject.project.zielausgleich.value;
          }
        }

        this.employees[id].data.metadata = data.metadata;
        this.employees[id].data.parameters = data.parameters;
        this.employees[id].data.insurances = data.insurances;

        for (let component in data.neo_components) {
          if (this.employees[id].data.neo_components[component].enabled) {
            data.neo_components[component].enabled = true;
            if (data.neo_components[component].value == 0) {
              data.neo_components[component].value = this.employees[id].data.neo_components[component].value;
            }
            if (typeof data.neo_components[component].AnzahlTelefon !== 'undefined' && this.employees[id].data.neo_components[component].AnzahlTelefon > 1) {
              data.neo_components[component].AnzahlTelefon = this.employees[id].data.neo_components[component].AnzahlTelefon;
            }

          }

          if (component === 'handy') {
            if (this.employees[id].data.neo_components[component].mpp_combined  !== null) {
              data.neo_components[component].mpp_combined = this.employees[id].data.neo_components[component].mpp_combined;
            }
          }

          if (component === 'essenscheck') {
            data.neo_components[component]['type'] = neoProject.neo_components[component]['type'];
          }

        }

        this.employees[id].data.neo_components = data.neo_components;
      };

      this.isEssenscheckTypeSame = (calculation, neo_project_essenscheck_settings) => {
        let project_essenscheck_default_type_setting = lodash.get(neo_project_essenscheck_settings, 'type');
        let calculation_essenschecks_type = lodash.get(calculation, 'type');
        return project_essenscheck_default_type_setting === calculation_essenschecks_type
      };

      this.compareSettings = (employee, neoProject) => {
        this.neoProject = neoProject;
        this.employees[this.employeeId].projectDifference = (employee.metadata.behandlung_alvktg !== neoProject.project.metadata.behandlung_alvktg) ||
          (employee.parameters.einsparungs_modell !== neoProject.project.zielausgleich.mode);

        if (employee.parameters.einsparungs_modell != 'NONE' && 'VA' != employee.parameters.einsparungs_modell) {
          if (employee.parameters.einsparungs_modell == 'AN_RELATIVE') {
            if (employee.parameters.einsparungs_prozent != neoProject.project.zielausgleich.value) {
              this.employees[this.employeeId].projectDifference = true;
            }
          }

          if (employee.parameters.einsparungs_modell == 'AN' || employee.parameters.einsparungs_modell == 'AG') {
            if (employee.parameters.einsparungs_wert != neoProject.project.zielausgleich.value) {
              this.employees[this.employeeId].projectDifference = true;
            }
          }
        }

        // console.log("neo project: handy - ", neoProject.neo_components['handy'].social_security, "; festnetz - ", neoProject.neo_components['festnetz'].social_security, '; pc - ', neoProject.neo_components['pc'].social_security);
        // console.log("emp project: handy - ", employee.neo_components['handy'].social_security, "; festnetz - ", employee.neo_components['festnetz'].social_security, '; pc - ', employee.neo_components['pc'].social_security);

        for (let comp in employee.neo_components) {
          if (typeof neoProject.neo_components[comp] !== 'undefined') {

            if (comp === 'essenscheck') {
              if (!this.isEssenscheckTypeSame(employee.neo_components[comp], neoProject.neo_components[comp])) {
                this.employees[this.employeeId].projectDifference = true;
              }
            }


            if (employee.neo_components[comp].enabled) {
              if (typeof neoProject.neo_components[comp].pst !== 'undefined' && neoProject.neo_components[comp].pst !== employee.neo_components[comp].pst) {
                this.employees[this.employeeId].projectDifference = true;
              }

              if (typeof neoProject.neo_components[comp].social_security !== 'undefined' && neoProject.neo_components[comp].social_security !== employee.neo_components[comp].social_security) {
                this.employees[this.employeeId].projectDifference = true;
              }

              if (typeof neoProject.neo_components[comp].is_sachbezug !== 'undefined' && neoProject.neo_components[comp].is_sachbezug !== employee.neo_components[comp].is_sachbezug) {
                this.employees[this.employeeId].projectDifference = true;
              }
            } else {
              if (typeof neoProject.neo_components[comp].pst !== 'undefined' && neoProject.neo_components[comp].pst !== employee.neo_components[comp].pst) {

                employee.neo_components[comp].pst = neoProject.neo_components[comp].pst;
              }

              if (typeof neoProject.neo_components[comp].is_sachbezug !== 'undefined' && neoProject.neo_components[comp].is_sachbezug !== employee.neo_components[comp].is_sachbezug) {
                employee.neo_components[comp].is_sachbezug = neoProject.neo_components[comp].is_sachbezug;
              }
            }
          }
        }
      };
    }]);
