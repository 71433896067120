import {DATEV_ADVISOR} from '../../../services/datev/DatevService';

const $inject = [
  '$scope',
  '$state',
  'datevService',
  '$window'
]

export default class DatevDetailsController {
  constructor(
    $scope,
    $state,
    datevService,
    $window
  ) {
    Object.assign(this, {
      $scope,
      $state,
      datevService,
      $window
    });

    this.initDatevContent($state.params.articleId);

    // custom advisor
    this.$scope.datev = DATEV_ADVISOR;
  }

  async initDatevContent(articleId) {
    if ( !articleId ) {
      this.$state.go('inApp.datev');
    }

    this.currentArticle = await this.getArticleById(articleId);
  }

  async getArticleById(articleId) {
    return this.datevService.fetchArticleById(articleId);
  }

  backLink() {
    this.$window.history.back();
  }
}

DatevDetailsController.$inject = $inject;
