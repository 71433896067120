import { BonusComponentController } from './BonusComponentController';
import { MONTHS_IN_YEAR } from '../../../services/BonusService';

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export class BonusComponentControllerDienstwagen extends BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {
    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true) {
    return super.addComponentToEmployeeBonusComponents(false).then(async (result) => {
      if (!result) {
        return;
      }

      if (!this.model.sliders) {

        let sliders = {
          advantage: angular.copy(this.SliderOptionsConstants),
          months: angular.copy(this.SliderOptionsConstants)
        };


        if (!this.model.max_months) {
          let available_months = Math.floor(this.employee_bonus_checkout_config.remaining_amount / this.component_budget_config.value);
          this.max_months = Math.max(Math.min(this.bonus_project_duration_in_months, available_months), 0);
        } else {
          this.max_months = this.model.max_months;
        }

        Object.assign(sliders.months, {
          to: this.max_months,
          step: 1,
          from: 0
        });


        if (!this.model.max_advantage_value) {
          this.max_advantage_value = (await this.BonusService.getMaxValues(
            this.bonus_component_name,
            this.moment().year()
          ))[this.bonus_component_name];

          if (this.max_advantage_value) {
            this.max_advantage_value = Math.min(this.max_advantage_value, this.component_budget_config.range.max_eq);
          } else {
            this.max_advantage_value = this.component_budget_config.range.max_eq;
          }
        } else {
          this.max_advantage_value = this.model.max_advantage_value;
        }

        Object.assign(sliders.advantage, {
          to: this.max_advantage_value,
          from: 0,
          step: 1
        });

        this.model.sliders = sliders;

        // ORDER IS IMPORTANT
        this.setModelValues({
          advantage: this.component_budget_config.value,
          months: 0
        });

        if (!this.model.is_optimized) {
          this.calculate();
        }
      } else {
        this.setModelValues({
          advantage: this.component_budget_config.value,
          months: 1
        });
      }

      this.calculateMonthsDifference();

      if (this.model.advantage && this.model.advantage === 0) {
        this.wizardCanceled();
      }

      this.advantage_watcher = this.$scope.$watch(() => { return this.model.advantage; }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            // this.calculate();
            this.setMaxMonths();
            this.calculateValue();
            this.calculateMonthsDifference();

          }, 0);
        }
      });

      this.months_watcher = this.$scope.$watch(() => { return this.model.months; }, (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          this.$timeout(() => {
            // this.calculate();
            this.calculateValue();
            this.calculateMonthsDifference();

          }, 0);
        }
      });

      this.$scope.$on('$destroy', () => {
        this.advantage_watcher();
        this.months_watcher();
        // this.remaining_amount();
      });

      if (move_to_next_step) {
        this.WizardHandler.wizard().next();
      }
    });
  }

  async init() { }

  setMaxAdvantage() {
    if (this.model.advantage.to > this.employee_bonus_checkout_config.remaining_amount) {
      this.model.sliders.advantage.to = this.employee_bonus_checkout_config.remaining_amount;
    }

    if (this.model.advantage > this.employee_bonus_checkout_config.remaining_amount) {
      this.model.advantage = this.employee_bonus_checkout_config.remaining_amount;
    }
  }

  setMaxMonths() {
    let remaining_amount = this.calculateRemainingWithoutCurrentBonus();
    let max_months = Math.floor(remaining_amount / Math.max(this.calculatePerMonthValue(), 1));
    max_months = Math.min(max_months, this.bonus_project_duration_in_months, MONTHS_IN_YEAR);

    if (max_months === 0) {
      // this.wizardCanceled();
      max_months = 1;
    }

    if (this.model.sliders.months.to !== max_months) {
      // if (this.model.months > max_months) {
      //   this.model.months = max_months;
      // }
      this.model.sliders.months.to = max_months;
    }

    this.model.months = 0;
  }

  calculatePerMonthValue() {
    let per_month_value = this.model.advantage;
    this.model.per_month_value = per_month_value;
    return per_month_value;
  }

  calculate() {
    this.setMaxAdvantage();
    this.setMaxMonths();
    this.calculateValue();
  }

  calculateValue() {
    this.model.value = this.calculatePerMonthValue() * this.model.months;
    this.model.duration_months = this.model.months;
  }
}

BonusComponentControllerDienstwagen.$inject = $inject;
