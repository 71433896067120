import * as moment from 'moment';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import {LoadingService} from "../../../../shared/services/loading.service";


@Component({
  selector: 'vn-direct-bonus-pay-dialog',
  templateUrl: './direct-bonus-pay-dialog.component.html',
  styleUrls: ['./direct-bonus-pay-dialog.component.scss']
})
export class DirectBonusPayDialogComponent implements OnInit {
  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public filtersGroup!: FormGroup;

  private get paymentDate(): Date {
    return this.filtersGroup.get('paymentDate')?.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectBonusPayDialogComponent>,
    private directBonusService: DirectBonusService,
    private notificationService: AlertService,
    private loader: LoadingService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData
  ) {
    this.initFiltersForm();
  }

  ngOnInit(): void {
  }

  private initFiltersForm() {
    this.filtersGroup = this.formBuilder.group({
      paymentDate: this.formBuilder.control(undefined, [Validators.required])
    });
  }

  public canMarkOrderAsPaid() {
    return this.filtersGroup.valid;
  }

  public markOrderAsPaid() {
    this.loader.show();
    return this.directBonusService.markOrderAsPaid(
      this.orderId,
      this.paymentDate
    )
      .subscribe(
        (result) => {
          this.loader.hide();
          if (result) {
            const paymentDate = moment(this.paymentDate).format('DD.MM.YYYY');
            const message = `Sie haben die ausgewählte(n) Bestellung(en) als bezahlt markiert. Folgendes Zahlungsdatum für diese Bestellung(en) haben Sie eingegeben: ${paymentDate}`;
            this.notificationService.message(message);

            this.dialogRef.close(true);
          }
        },
        (error) => {
          // TODO: Do proper error handling with backend errors
          this.loader.hide();
          this.notificationService.error('Fehler');
        }
      );
  }
}


