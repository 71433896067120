import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CarLeasingErrorHandler } from 'src/app/car-leasing/services/errors/car-leasing-error-handler';
import { AlertService } from "../../../../shared/services/alert.service";

// ===============================================================

interface FilterOptions {
  year: string[];
}

interface FilterChoices {
  year: number;
}

export interface DownloadTableProps {
  selectedYear: number;
  withComment: boolean
}

// ===============================================================

@Component({
  selector: 'vn-customer-bulk-upload-dialog',
  templateUrl: './customer-bulk-upload-dialog.component.html',
  styleUrls: ['./customer-bulk-upload-dialog.component.scss']
})
export class CustomerBulkUploadDialogComponent implements OnInit {

  @Output()
  downloadTable = new EventEmitter<DownloadTableProps>();

  public readonly currentYear = moment().year();
  public filtersGroup!: FormGroup;
  public filterOptions!: FilterOptions;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CustomerBulkUploadDialogComponent>,
    private directBonusService: DirectBonusService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
    private notificationService: AlertService,
    private carLeasingErrorHandler: CarLeasingErrorHandler,
    @Inject('fileService') private fileService: any,
  ) {
    this.initFilterOptions();
    this.initFiltersForm();
  }

  ngOnInit(): void {
  }

  public get bonusProjectId(): string {
    return this.dialogData.bonusProjectId!;
  }

  public get customerId(): number {
    return this.dialogData.customerId!;
  }

  public get currentYearString() {
    return this.currentYear.toString();
  }

  public downloadTableAsFile(withComment = false) {
    const selectedYear = parseInt(this.year.value);
    const emittedData = {selectedYear, withComment};
    this.downloadTable.emit(emittedData);
    this.dialogRef.close(emittedData);
  }


  public uploadTableFromFile(
    files: File[] // should only contain one item
  ) {
    const {
      [0]: csvTableFile
    } = files;

    if (!csvTableFile) {
      throw new Error("Invalid file selection.")
    }

    const isValidFile = !!csvTableFile.name.match(/\.([^\.]+)$/)?.find(el => [".csv", ".text"].includes(el));

    if (!isValidFile) {
      alert('Only .csv and .txt file formats are supported.');
      return;
    }

    const {
      bonusProjectId,
      customerId,
    } = this.dialogData;

    const selectedYear = parseInt(this.year.value);

    this.directBonusService.bonusBulkUpload(
      bonusProjectId!,
      customerId!,
      selectedYear,
      csvTableFile
    ).subscribe(
      (result) => {
        let hasError = false;
        if (result.usersWithMissingEmployeeId.length > 0) {
          hasError = true;
          this.fileService.saveFile(result.usersWithMissingEmployeeId, ['employeeId', 'personalNumber'], 'users_with_missing_employee_id.csv');
        }
        if (result.usersWithInvalidEmployeeId.length > 0) {
          hasError = true;
          this.fileService.saveFile(result.usersWithInvalidEmployeeId, ['employeeId', 'personalNumber'], 'users_with_invalid_employee_id.csv');
        }
        if (result.usersWithDifferentCustomer.length > 0) {
          hasError = true;
          this.fileService.saveFile(result.usersWithDifferentCustomer, ['employeeId', 'customerId'], 'users_with_different_customer.csv');
        }
        if (result.usersWithDuplicateComponents.length > 0) {
          hasError = true;
          this.fileService.saveFile(result.usersWithDuplicateComponents, ['employeeId', 'duplicateComponents'], 'users_with_duplicate_components.csv')
        }
        if (!hasError) {
          this.notificationService.message("Die Aufladungen wurden erfolgreich importiert.");
          this.dialogRef.close(result);
        } else {
          this.notificationService.error(`Die Aufladung ist fehlgeschlagen: Mitarbeiter sind dem falschen Kunden ${customerId} zugewiesen.`);
        }
      },
      (error) => {
        this.notificationService.error(this.carLeasingErrorHandler.handle(error).getMessage())
      }
    );
  }


  private get year(): FormControl {
    return this.filtersGroup.get('year') as FormControl;
  }

  private initFiltersForm() {

    this.filtersGroup = this.formBuilder.group({
      year: this.formBuilder.control(this.currentYear, [
        Validators.required,
      ]),
    });
  }

  private initFilterOptions() {
    // TODO pass validated year options

    this.filterOptions = {
      year: [
        this.currentYear,
        this.currentYear + 1,
        this.currentYear + 2
      ].map(
        (year) => year.toString()
      ),
    }
  }
}
