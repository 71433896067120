import './landing';
import './menu';
import './authentication';
import './timetracking';
//
// //TODO: refactor
import './arbeitnehmer';
import './benefit';
import './bonus';
import './cashback';
import './cockpit';
import './dashboard';
import './dialog';
import './feedback';
import './meineakte';
import './neo';
import './quiz';
import './shop';
import './versicherung';
import './vorsorge';
import './cms';
import './datev';
import './administration';
import './yearly';
import './support';
import './mpp';
import './vcp';
import './car-leasing';
import './dticket';
