export const DATEV_ADVISOR = {
  advisorDetails: {
    email: 'info@valuenet.de',
    phone_support: '089 - 416 176 530',
    id: 'datev'
  }
};

const $inject = [
  '$q', 'Made'
];
export default class DatevService {

  constructor( $q, Made ) {
    Object.assign( this, {
      $q,
      Made,
    } );

    this.configs = {
      loading: false
    }
  }

  fetchArticles() {
    return this.Made.request( 'rpc://cms/fetchDatevArticles').then(response => response.article);
  }

  fetchArticleById(id) {
    return this.Made.request('rpc://cms/fetchDatevArticles', {id: id}).then(response => response.article[0]);
  }

  deleteArticle(id) {
    return this.Made.request('rpc://cms/deleteDatevArticle', {id: id}).then(response => response.article);
  }

  saveArticle(article) {
    return this.Made.request('rpc://cms/saveDatevArticle', {content: article}).then(response => response.article);
  }
}
DatevService.$inject = $inject;
