import generate_invoices_dialog_html from '../../../dialogs/special_admin/invoices/generate_invoices_dialog.pug';

const $inject = [
  '$scope',
  '$timeout',
  'authenticationService',
  'ACLService',
  'lodash',
  'BonusService',
  'mppService',
  'UtilityService',
  'employeeService',
  'NeoComponentsService',
  'mailService',
  'dialogService',
  'DigitalSigningService',
  'CarLeasingApiService',
  'yearlyService',
  'administrationService',
  'fileService',
  'DirectBonusService',
  'IS_DEV_ENVIRONMENT',
  'IS_STAGE_ENVIRONMENT',
];
export default class SpecialAdministrationController {

  constructor(
    $scope,
    $timeout,
    authenticationService,
    ACLService,
    lodash,
    BonusService,
    mppService,
    UtilityService,
    employeeService,
    NeoComponentsService,
    mailService,
    dialogService,
    DigitalSigningService,
    CarLeasingApiService,
    yearlyService,
    administrationService,
    fileService,
    DirectBonusService,
    IS_DEV_ENVIRONMENT,
    IS_STAGE_ENVIRONMENT,
  ) {

    Object.assign(this, {
      $scope,
      $timeout,
      authenticationService,
      ACLService,
      lodash,
      BonusService,
      mppService,
      UtilityService,
      employeeService,
      NeoComponentsService,
      mailService,
      dialogService,
      DigitalSigningService,
      CarLeasingApiService,
      yearlyService,
      administrationService,
      fileService,
      DirectBonusService,
      IS_DEV_ENVIRONMENT,
      IS_STAGE_ENVIRONMENT,
    });

    this.loading = {
      main: false,
      deleted_baskets: true
    };
    this.iAmSpecialAdmin = authenticationService.iAm('special_admin');
  }

  async fixConfigurationsOnBasket() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.fixConfigurationsOnBasket();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async setConfigurationsOnBaskets() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.setConfigurationsOnBaskets();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  migrateEmployersToACL() {
    console.log('start migration');
    this.ACLService.migrateCurrentEmployersToACL()
      .then((res) => {
        console.log(res);
      });
  }

  //TODO: not tested yet
  migrateFidApprovalToACL() {
    console.log('start fid approval migration');
    this.$timeout(() => {
      this.loading.main = true;
    });
    this.ACLService.migrateFidApprovalToACL()
      .then((res) => {
        console.log(res);
        console.log('finish fid approval migration');
      })
      .catch((err) => {
        console.log('finish fid approval migration - ', err);
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  validateApprovedBonusBaskets() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    console.log('start validating approved bonus baskets');
    this.BonusService.validateApprovedBonusBaskets()
      .then((res) => {
        console.log('finish validating approved bonus baskets - ', res);
      })
      .catch((err) => {
        console.log('error validating approved bonus baskets - ', err);
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  fixBasketsExport() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    console.log('start fix baskets export ');
    this.mppService.fixBasketsExport()
      .then((res) => {
        console.log('finish fix baskets export - ', res);
      })
      .catch((err) => {
        console.log('error fix baskets export - ', err);
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  returnDeletedBasketIdsInMYSQL() {
    this.$timeout(() => {
      this.loading.deleted_baskets = true;
    });
    console.log('start deleting exported baskets ');
    this.mppService.returnDeletedBasketIdsInMYSQL()
      .then((res) => {
        this.deleted_basket_ids = res;
        console.log('finish deleting exported baskets - ', res);
        return res;
      })
      .catch((err) => {
        console.log('error deleting exported baskets - ', err);
      })
      .finally((res) => {
        this.$timeout(() => {
          this.loading.deleted_baskets = false;
        });
      });
  }

  sendLeasingEmailsManually() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    console.log('start sending emails manually ');
    this.mppService.sendLeasingEmailsManually()
      .then((res) => {
        this.deleted_basket_ids = res;
        console.log('finish sending emails manually - ', res);
        return res;
      })
      .catch((err) => {
        console.log('error sending emails manually - ', err);
      })
      .finally((res) => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  migrateNltgContacts() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    console.log('start migrating nltg contacts');
    this.UtilityService.migrateNltgContacts()
      .then((res) => {
        this.deleted_basket_ids = res;
        console.log('finish migrating nltg contacts - ', res);
        return res;
      })
      .catch((err) => {
        console.log('error  migrating nltg contacts - ', err);
      })
      .finally((res) => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }


  migrateCustomerIds() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    console.log('start migration of customer ids ');
    this.employeeService.migrateCustomerIds()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log('error sending emails manually - ', err);
      })
      .finally((res) => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }


  createInitialNewsletterContactsByToc() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    console.log('start creating nltg internal contacts');
    this.UtilityService.createInitialNewsletterContactsByToc()
      .then((res) => {
        this.deleted_basket_ids = res;
        console.log('finish creating nltg internal contacts - ', res);
        return res;
      })
      .catch((err) => {
        console.log('error creating nltg internal contacts - ', err);
      })
      .finally((res) => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  createInitialCustomersUsersByNLTG() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    console.log('start sync customer users with nltg');
    this.UtilityService.createInitialCustomersUsersByNLTG()
      .then((res) => {
        this.deleted_basket_ids = res;
        console.log('finish sync customer users with nltg - ', res);
        return res;
      })
      .catch((err) => {
        console.log('error sync customer users with nltg - ', err);
      })
      .finally((res) => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  updateDeclinedBonusBaskets() {
    console.log('start update declined bonus baskets');
    this.$timeout(() => {
      this.loading = true;
    });
    this.BonusService.updateDeclinedBonusBaskets()
      .then((res) => {
        console.log('finish update declined bonus baskets - ', err);
      })
      .catch((err) => {
        console.log('error update declined bonus baskets -', err);
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading = false;
        });
      });
  }

  async expireBaskets() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.expireBaskets();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }


  async addOrderClosedDateToExportedBaskets() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.addOrderClosedDateToExportedBaskets()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }



  async convertPreendInformationDates() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.convertPreendInformationDates()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async startBasketDecisionProcess() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    await this.mppService.startBuyingProcesses()
    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async reminderForBuyingProcesses() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.reminderForBuyingProcesses();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }
  async initBuyingProcessTicketNumber() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.initBuyingProcessTicketNumber()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async informForLeasingEndInThreeMonths() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.informForLeasingEndInThreeMonths()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async getBuyingProcessEmailsReport() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    let response = await this.mppService.getBuyingProcessEmailsReport()

    if(typeof response == 'undefined' || response == null || response == "")
        return ;

    this.fileService.saveFile(response, undefined, 'buying_process_report.csv');


    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  //VN-3406
  async informForLeasingEndInTwoMonths() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.informForLeasingEndInTwoMonths()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async billingProcessReminders() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.billingProcessReminders()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async generateInvoices() {
    this.dialogService.ngDialog.openConfirm({
      template: generate_invoices_dialog_html(),
      plain: true,
      controller: 'GenerateInvoicesDialogController',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    });
  }

  async processPendingYouSignRequest() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.DigitalSigningService.processPendingYouSignRequest();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async expireUserDecisionProcesses() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.expireUserDecisionProcesses();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async sendYearlyInitiationEmails() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.yearlyService.sendYearlyInitiationEmails();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async generate_overview_document_for_yearly_for_testing() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.yearlyService.generate_overview_document_for_yearly_for_testing();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async send_yearly_emails_to_bo_for_already_finished_processes() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.yearlyService.send_yearly_emails_to_bo_for_already_finished_processes();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async send_missing_approval_approved_email() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.administrationService.send_missing_approval_approved_email();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  //VN-3416
  async manual_emails_for_mpp_buying_process() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.manual_emails_for_mpp_buying_process()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  //VN-3416
  async manual_emails_for_mpp_buying_process_get_ticket_ids() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.manual_emails_for_mpp_buying_process_get_ticket_ids()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  //VN-3416
  async parse_buying_process_email_manually() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.parse_buying_process_email_manually()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }


  //VN-3418
  async parse_invoice_manually() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.parse_invoice_manually()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  //VN-3439
  async resend_missing_cyberport_emails_VN3439() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.resend_missing_cyberport_emails_VN3439()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async fix_future_data() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.BonusService.fix_future_data()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }


  //VN-3665
  async reexportMppBaskets() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.reexportMppBaskets()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async test_sftp(){
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.BonusService.test_sftp()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async validate_pdfa() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.BonusService.validate_pdfa()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  // VN-4135
  async fixFutureCheckoutBaskets() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.BonusService.fixFutureCheckoutBaskets()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async testPcCombinedPreending(basket) {
    await this.mppService.testPcCombinedPreending(basket);
  }

  async fixProblematicMPPBasketsVN4481() {
    await this.mppService.fixProblematicMPPBasketsVN4481()
  }

  async recreateBillingProcess() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.recreateBillingProcess()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async processExistingOrders() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.DirectBonusService.updateExistingOrdersVN_4216();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async testBCWrapperCronJob() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.DirectBonusService.testBCWrapperCron();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async get_wrong_future_data_report() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    await this.mppService.get_wrong_future_data_report()
    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async validate_mpp_basket_dates_for_pickup_issues() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.validate_mpp_basket_dates_for_pickup_issues()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async recalculate_bonus_future_data() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.recalculate_bonus_future_data()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async get_future_checkouts_with_missing_state() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.get_future_checkouts_with_missing_state()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async get_mpp_baskets_with_diff_in_order_closed_date_and_begin() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.get_mpp_baskets_with_diff_in_order_closed_date_and_begin()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async expireBikeleasingBonuses() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.BonusService.expireBikeleasingBonuses()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async activateFutureCheckoutsExternal() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.BonusService.activateFutureCheckoutsExternal()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  jumpToAdminPortal() {
    window.location.href = '/auth_cidaas/auth/?target=admin';
  }

  async exportBonusProjects() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.BonusService.exportBonusProjects();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async expireNeoContractsAwaitingForSigning() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.administrationService.expireNeoContractsAwaitingForSigning();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async expireDigitalSigningRequests() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.administrationService.expireDigitalSigningRequests();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  //P92VN-2259
  async trigger_otrs_email_for_three_month_emails() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.trigger_otrs_email_for_three_month_emails()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  //P92VN-2259
  async trigger_otrs_email_for_one_month_emails() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.mppService.trigger_otrs_email_for_one_month_emails()

    this.$timeout(() => {
      this.loading.main = false;
    });
  }trigger_otrs_email_for_one_month_emails
}

SpecialAdministrationController.$inject = $inject;
