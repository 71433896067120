import './vcp.side';
import './locationselect.controller';

import './admin/admin.controller';

import './list/list.controller';

import './location/location.controller';

import './mitarbeiter/vcp.mitarbeiter.controller';
import './mitarbeiter/reasondialog.controller';

import './models/vcp';

import './moduleselect/moduleselect.controller';
import './moduleselect/moduleselect.service';

import './services/VCPBonusService';

import './side/chooseMonthDialogCtrl';

import './zentrale/zentrale.controller';
