import dialog_html from '../../../modules/neo/berater/target/dialog.pug'

const $inject = [
  '$scope',
  '$state',
  'Made',
  'StatisticService',
  'BrandingService',
  'FeedbackService',
  'advisorService',
  'authenticationService',
  'employeeService',
  'customerService',
  'dialogService',
  'ngDialog',
  'vbmData',
  'UserNotificationService',
  '$timeout'
];
export default class CockpitStatisticsController {

  constructor(
    $scope,
    $state,
    Made,
    StatisticService,
    BrandingService,
    FeedbackService,
    advisorService,
    authenticationService,
    employeeService,
    customerService,
    dialogService,
    ngDialog,
    vbmData,
    UserNotificationService,
    $timeout
  ) {

    Object.assign(this, {
      $scope,
      $state,
      Made,
      StatisticService,
      BrandingService,
      FeedbackService,
      advisorService,
      authenticationService,
      employeeService,
      customerService,
      dialogService,
      ngDialog,
      vbmData,
      UserNotificationService,
      $timeout
    });

    this.user = Made.user;
    this.isDashboardAdvisor = true;
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.customerId = parseInt($state.params.customerId);

    this.loading = {
      main: false
    };

    this.loading.main = true;
    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });

  }

  async init() {

    let promises = [];

    promises.push(
      this.customerService.get(this.customerId)
    );

    if (this.iAmAdvisor || this.iAmSpecialAdvisor) {
      promises.push(
        this.getFeedback()
      );
    }

    promises.push(
      this.getStatistics()
    );

    if (this.iAmSpecialAdvisor || this.iAmAdvisor) {
      promises.push(
        this.advisorService
          .getEmployeesByCustomer(this.customerId)
          .then(employees => {
            this.employees = employees;
            this.employeeDict = this.employeeService.getDictionary(employees);
          })
      );
    }

    promises.push(
      this.customerService
        .getConfiguration()
        .then(configuration => {
          this.$scope.configuration = configuration;
        })
    );

    return Promise.all(promises);
  }

  getFeedback() {

    return this.FeedbackService
      .list(this.customerId)
      .then(list => {
        this.feedbackList = list;
        return this.FeedbackService.aggregate(list);
      })
      .then(feedback => this.FeedbackService.expand(feedback));
  }

  async getStatistics() {
    let promises = [];

    if (this.customerId) {
      promises.push(
        this.StatisticService
          .customerGeneral(this.customerId)
          .then(general => this.general = general)
      );

      promises.push(
        this.StatisticService
          .customerComponents(this.customerId, true)
          .then(components => this.components = components)
      );
    } else {
      promises.push(
        this.StatisticService
          .advisorGeneral()
          .then(general => this.general = general)
      );

      promises.push(
        this.StatisticService
          .advisorComponents(false, true)
          .then(components => this.components = components)
      );
    }

    return Promise.all(promises);
  }

  arbeitnehmerAnlegen() {
    this.dialogService.employeeCreate();
  }

  showFeedback(feedback) {
    this.dialogService.feedback(feedback);
  }

  chooseTargetDialog() {
    this.ngDialog.open({
      template: dialog_html(),
      plain: true,
      className: 'ngdialog-theme-vsp mitarbeiter-anlegen',
      controller: 'NeoBeraterTargetDialogController',
      data: {
        changeEmployee: () => this.choseEmployee()
      }
    });
  }

  choseEmployee() {
    this.$state.go('inApp.neo.side.berater', {
      employeeId: this.vbmData.employeeId
    });
  }

}
CockpitStatisticsController.$inject = $inject;
