import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchAll, switchMap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BonagoBasketListItem, BonagoRetailer, BonagoUserChecks, EmployeeAddress } from '../models/benefit-bonago.model';
import { BenefitsBonagoBasketService } from './benefits-bonago-basket.service';
import { BenefitsBonagoController } from './benefits-bonago.controller';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Injectable()
export class BenefitsBonagoService {


  public selectedCategory$ = this.controller.getBonagoCategoriesMap$().pipe(
    switchMap(
      categoriesMap => this.controller.$selectedCategoryId.asObservable().pipe(
        map(categoryId => categoriesMap.get(categoryId)!)
      )
    )
  );


  public userChecks$ = this.controller.$$getUserChecks.pipe(
    switchMap(userChecks => this.controller.$userChecks.pipe(
      map(additionalChecks => ({
        ...userChecks,
        ...additionalChecks
      } as BonagoUserChecks))
    ))
  );
  public customerChecks$ = this.controller.$$getCustomerChecks;

  private $retailerFilters = new BehaviorSubject<Partial<BonagoRetailer>>({});
  public retailerFilters$ = this.$retailerFilters.asObservable();

  private $currentRetailer = new Subject<Observable<BonagoRetailer>>();
  public currentRetailer$: Observable<BonagoRetailer> = this.$currentRetailer.pipe(
    switchAll(), // squash all cold-observables into a hot observable
  );
  private currentRetailerId!: number

  private $currentPostcode = new Subject<string>();
  public currentPostcode$ = this.$currentPostcode.asObservable();

  public customerBonagoSchemes$ = this.controller.$$getCustomerBonagoSchemes;
  public userBonagoScheme$ = this.controller.$$getUserBonagoScheme;
  public bonagoCategories$ = this.controller.$$getBonagoCategories;
  public bonagoRetailersBasic$ = this.controller.$$getBonagoRetailersBasic;
  public allBonagoRetailersBasic$ = this.controller.$$getAllBonagoRetailersBasic;
  public employeeAddress$ = this.controller.$$getEmployeeAddress;

  constructor(
    @Inject('$state') private $state: any,
    private controller: BenefitsBonagoController,
    private basket: BenefitsBonagoBasketService,
    private alertService: AlertService,
    private loading: LoadingService
  ) { }

  public getBonagoRetailer$(retailerId: number): Observable<BonagoRetailer> {
    return this.controller.getBonagoRetailer$(retailerId);
  }

  public selectCategoryId(categoryId: number) {
    this.controller.$selectedCategoryId.next(categoryId);

    this.filterRetailers(); // clear filters
  }

  public selectSchemeId(schemeId: number) {
    this.controller.$selectedSchemeId.next(schemeId);
  }

  public filterRetailers(filters: Partial<BonagoRetailer> = {}) {
    this.$retailerFilters.next(filters);
  }

  public changePostcode(postcode: string) {
    this.$currentPostcode.next(postcode);
  }

  public fetchRetailer(retailerId: number) {
    if (this.currentRetailerId === retailerId) {
      return;
    }

    this.currentRetailerId = retailerId;
    const retailer = this.controller.getBonagoRetailer$(retailerId);
    this.$currentRetailer.next(retailer);
  }

  public completeCheckout(
    basketItems: BonagoBasketListItem[],
    employeeAddress: EmployeeAddress
  ) {
    // @TODO IMPORTANT : https://vsp.atlassian.net/browse/P92VN-1487
    // this logic shoould not be here, service should not redirect etc
    // needs to be refactored and pulled out in the controller
    this.loading.show(); // loading added to use the variable from the behaviour subject in the controller -> HACK
    this.controller.completeBonagoCheckout$(basketItems, employeeAddress).subscribe(
      result => {
        // result is null
        this.basket.clearBasket();
        
        this.$state.go('inApp.directBonusBenefitsAccount');
        
        this.alertService.message('Gutscheinabruf erfolgreich!')
        this.loading.hide();
      },
      error => {
        const errorMessage = error.error.message;

        if(errorMessage.includes('BONAGO_OUT_OF_STOCK_ERROR')) {
          this.alertService.error('Ein gewünschter Gutschein ist aktuell leider vergriffen!');
        } else {
          this.alertService.error('Fehler beim Gutscheinabruf!');
        }
        this.loading.hide();
      },
    );
  }

  public getVouchersInStockNumber$(vouchersList: string[]): Observable<string[]> {
    return this.controller.getVouchersInStockNumber$(vouchersList);
  }
}
