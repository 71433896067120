var pug = require("!../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (ngif) {
      pug_mixins["bigbox"] = pug_interp = function(keys, addClass){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ('bav' === keys) {
ngif = 'ExtrainfoCtrl.employee.data.insurances.bav.beitrag'
}
else {
if ('string' === typeof keys) {
keys = [keys]
}
// iterate keys
;(function(){
  var $$obj = keys;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var val = $$obj[index];
keys[index] = 'ExtrainfoCtrl.componentKeys.indexOf("'+ val + '") > -1'
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var val = $$obj[index];
keys[index] = 'ExtrainfoCtrl.componentKeys.indexOf("'+ val + '") > -1'
    }
  }
}).call(this);

ngif = keys.join(' || ')
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["bigbox","centered",addClass], [false,false,true]), false, true)+pug.attr("ng-if", ngif, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["bigbox"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"header\"\u003E\u003Cspan\u003EDienstwagengarage\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"mainWrapper\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"control-label\" for=\"intgaragestellplatz\"\u003EStellplatz\u003C\u002Flabel\u003E\u003Cselect name=\"intgaragestellplatz\" ng-model=\"ExtrainfoCtrl.neoExtras.intgaragestellplatz\"\u003E\u003Coption value=\"Garage\"\u003EGarage\u003C\u002Foption\u003E\u003Coption value=\"Stellplatz\"\u003EStellplatz\u003C\u002Foption\u003E\u003Coption value=\"Carport\"\u003ECarport\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003Clabel class=\"control-label\" for=\"garagestrasse\"\u003Eabw. Straße\u003C\u002Flabel\u003E\u003Cinput class=\"from-control\" type=\"text\" ng-model=\"ExtrainfoCtrl.neoExtras.garagestrasse\" name=\"garagestrasse\"\u003E\u003Clabel class=\"control-label\" for=\"garageplz\"\u003Eabw. PLZ\u003C\u002Flabel\u003E\u003Cinput class=\"from-control\" type=\"text\" ng-model=\"ExtrainfoCtrl.neoExtras.garageplz\" name=\"garageplz\"\u003E\u003Clabel class=\"control-label\" for=\"garageort\"\u003Eabw. Ort\u003C\u002Flabel\u003E\u003Cinput class=\"from-control\" type=\"text\" ng-model=\"ExtrainfoCtrl.neoExtras.garageort\" name=\"garageort\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group schema-form-submit continue-margin-top\"\u003E\u003Cbutton class=\"btn btn-primary\" ng-click=\"ExtrainfoCtrl.proceed()\" ng-disabled=\"ExtrainfoCtrl.busy || ExtrainfoCtrl.needMail\"\u003EWeiter\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"clearfix\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
}, ['dienstwagen_auslage', 'dienstwagen_miete'], 'dienstwagengarage');
    }.call(this, "ngif" in locals_for_with ?
        locals_for_with.ngif :
        typeof ngif !== 'undefined' ? ngif : undefined));
    ;;return pug_html;};
module.exports = template;