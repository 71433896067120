import CmsController from './CmsController';

import CmsDetailsController from './details/CmsDetailsController';
import CmsCreateController from './create/CmsCreateController';
import CmsListController from './list/CmsListController';
import CmsEditorListController from './editorList/CmsEditorListController';
import CmsCategoryListController from './categoryList/CmsCategoryListController';
import CmsCategoryCreateController from './categoryCreate/CategoryCreateController';
import CmsEntryListController from './entryList/CmsEntryListController';

angular
    .module('VSPApp')
    .controller('CmsController', CmsController)
    .controller('CmsDetailsController', CmsDetailsController)
    .controller('CmsCreateController', CmsCreateController)
    .controller('CmsListController', CmsListController)
    .controller('CmsEditorListController', CmsEditorListController)
    .controller('CmsCategoryListController', CmsCategoryListController)
    .controller('CmsCategoryCreateController', CmsCategoryCreateController)
    .controller('CmsEntryListController', CmsEntryListController);
