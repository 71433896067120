const $inject = [
  'Made',
  '$scope',
  '$timeout',
  'employeeService',
  'NotificationService',
  'dialogService',
];

export default class AppverwaltungController {
  constructor(
    Made,
    $scope,
    $timeout,
    employeeService,
    NotificationService,
    dialogService,
  ) {
    Object.assign(this, {
      Made,
      $scope,
      $timeout,
      employeeService,
      NotificationService,
      dialogService,
    });

    this.errors = {};
    this.devices = {};
    this.loading = {
      main: true
    };

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init(){
    await this.employeeService.getEmployeeMobileDevices()
      .then((devices) => {
        for (let i = 0; i < devices.length; i++) {
          this.devices[devices[i].id] = devices[i];
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async update_device_notify(deviceRecordId) {
    let device = this.devices[deviceRecordId];
    await this.employeeService.updateEmployeeMobileDeviceNotify(device.id, device.notification_preference)
      .then((response) =>{
        if (response.success) {
          this.NotificationService.message(response.message);
        } else {
          this.NotificationService.error(response.message);
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  update_device_name(deviceRecordId){
    let device = this.devices[deviceRecordId];
    console.log(device);
    this.dialogService.updateMobileDeviceName(device);
  }
}

AppverwaltungController.$inject = $inject;
