export class BasketCalculation {

  // #COMMENT_LEX: Yes, that's some boilerplate code here. We can improve this when we switch to typescript.
  constructor(
    leasing,
    cart_leasing_month_brutto,
    cart_leasing_month_netto,
    sum,
    netto_base,
    brutto_base,
    brutto_sum,
    netto_sum,
  ) {
    this._leasing = leasing;
    this._cart_leasing_month_brutto = cart_leasing_month_brutto;
    this._cart_leasing_month_netto = cart_leasing_month_netto;
    this._sum = sum;
    this._netto_base = netto_base;
    this._brutto_base = brutto_base;
    this._brutto_sum = brutto_sum;
    this._netto_sum = netto_sum;
  }

  get leasing() {
    return this._leasing;
  }
  
  get cart_leasing_month_brutto() {
    return this._cart_leasing_month_brutto;
  }
  
  get cart_leasing_month_netto() {
    return this._cart_leasing_month_netto;
  }
  
  get sum() {
    return this._sum;
  }
  
  get netto_base() {
    return this._netto_base;
  }
  
  get brutto_base() {
    return this._brutto_base;
  }
  
  get brutto_sum() {
    return this._brutto_sum;
  }
  
  get netto_sum() {
    return this._netto_sum;
  }
  
}
