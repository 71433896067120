import meineakte_html from '../../modules/meineakte/meineakte.pug';
import meineakte_header_html from "../../helpers/header.main.pug";
import meineakte_vorteilskonto_html from "../../modules/meineakte/vorteilskonto/meineakte_vorteilskonto.pug";
import meineakte_uebersicht_html from '../../modules/meineakte/uebersicht/meineakte_uebersicht.pug';
import meineakte_steuerfrei_html from '../../modules/meineakte/steuerfrei/meineakte_steuerfrei.pug';
import meineakte_versicherungen_html from "../../modules/meineakte/versicherungen/meineakte_versicherungen.pug";
import meineakteAccount_html from "../../modules/meineakte/account/meineakteAccount.pug";
import meineakteMpp_html from '../../modules/meineakte/mpp/meineakteMpp.pug';
import missing_documents_html from "../../modules/meineakte/missing_documents/missing_documents.pug";
import MeineAkteUserDecisions_html from '../../modules/meineakte/user_decisions/MeineAkteUserDecisions.pug';
import success_signing_html from "../../modules/meineakte/digital_signing/success_signing.pug";
import error_signing_html from "../../modules/meineakte/digital_signing/error_signing.pug";
import appverwaltung_html from "../../modules/meineakte/appverwaltung/appverwaltung.pug";
import {
  DticketOrderHistoryComponent
} from "../../../app/dticket-order/components/meineakte/dticket-order-history/dticket-order-history.component";


angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.meineakte', {
        url: '/meineakte',
        views: {
          main: {
            template: meineakte_html,
            controller: 'MeineakteController',
            controllerAs: '$ctrl'
          },
          header: {
            template: meineakte_header_html
          }
        }
      })

      .state('inApp.meineakte.dticket', {
        url: '/dticket',
        views: {
          meineakteMain: {
            component: DticketOrderHistoryComponent
          }
        }
      })

      .state('inApp.meineakte.vorteilskonto', {
        url: '/vorteilskonto',
        views: {
          meineakteMain: {
            template: meineakte_vorteilskonto_html,
            controller: 'MeineakteVorteilskontoController',
            controllerAs: '$ctrl'
          }
        }
      })
      .state('inApp.meineakte.uebersicht', {
        url: '/uebersicht',
        views: {
          meineakteMain: {
            template: meineakte_uebersicht_html,
            controller: 'MeineakteUebersichtController'
          }
        }
      })
      .state('inApp.meineakte.steuerfrei', {
        url: '/steuerfrei',
        views: {
          meineakteMain: {
            template: meineakte_steuerfrei_html,
            controller: 'MeineakteSteuerfreiController'
          }
        }
      })
      .state('inApp.meineakte.dokumente', {
        url: '/dokumente',
        params: {
          nonUrlParam: {
            has_missing_documents: undefined
          }
        },
        views: {
          meineakteMain: {
            component: 'documentListComponent'
          }
        }
      })
      .state('inApp.meineakte.versicherungen', {
        url: '/versicherungen',
        views: {
          meineakteMain: {
            template: meineakte_versicherungen_html,
            controller: 'MeineakteVersicherungenController'
          }
        }
      })
      .state('inApp.meineakte.account', {
        url: '/account',
        views: {
          meineakteMain: {
            template: meineakteAccount_html,
            controller: 'MeineakteAccountController',
            controllerAs: '$ctrl'
          }
        }
      })
      .state('inApp.meineakte.mpp', {
        url: '/pc-leasing',
        views: {
          meineakteMain: {
            template: meineakteMpp_html,
            controller: 'MeineakteMppController',
            controllerAs: '$ctrl'
          }
        }
      })
      .state('inApp.meineakte.missing_documents', {
        url: '/missing_documents',
        views: {
          meineakteMain: {
            template: missing_documents_html,
            controller: 'MeineAkteMissingDocumentsController',
            controllerAs: '$ctrl'
          }
        }
      })
      .state('inApp.meineakte.user_decisions', {
        url: '/user_decisions',
        views: {
          meineakteMain: {
            template: MeineAkteUserDecisions_html,
            controller: 'MeineAkteUserDecisionsController',
            controllerAs: '$ctrl'
          }
        }
      })
      .state('inApp.meineakte.calculations', {
        url: '/calculations',
        views: {
          meineakteMain: {
            component: 'employeeCalculationsComponent'
          }
        }
      })
      .state('inApp.meineakte.digital_signing_success', {
        url: '/digital_signing_success?procedureId',
        params: {
          procedureId: null,
        },
        views: {
          meineakteMain: {
            template: success_signing_html,
            controller: 'DigitalSigningSuccessController',
            controllerAs: '$ctrl'
          },
        }
      })
      .state('inApp.meineakte.digital_signing_error', {
        url: '/digital_signing_error',
        views: {
          meineakteMain: {
            template: error_signing_html,
            controller: 'DigitalSigningErrorController',
            controllerAs: '$ctrl'
          },
        }
      })
      .state('inApp.meineakte.appverwaltung', {
        url: '/appverwaltung',
        views: {
          meineakteMain: {
            template: appverwaltung_html,
            controller: 'AppverwaltungController',
            controllerAs: '$ctrl'
          }
        }
      });



  }]);
