const $inject = [
  '$scope'
]
export class DTicketController {
  constructor($scope)
  {
    Object.assign(this, {
      $scope
    });
  }

}

DTicketController.$inject = $inject;


