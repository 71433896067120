angular
  .module('VSPApp')
  .directive('fixonheader', [function () {
    return {
      link: function (scope, el, attr) {
        var raw = el[0],
          fc = angular.element(document.querySelector('#side-view .box:first-child')),
          parent = attr.fixonheader || 'vcp';

        // TODO: add timeout..

        var checkBounds = () => {
          var rectObject = raw.getBoundingClientRect();
          fc.css('margin-top', (rectObject.top < 77 ? 77 - rectObject.top : 0) + 'px');
        };

        angular
          .element(document.getElementById(parent))
          .on('scroll', checkBounds);
      }
    };
  }]);
