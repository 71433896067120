import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { BehaviorSubject, Subscription } from "rxjs";
import {
  CustomerOrderFilterChoices,
  CustomerOrderStateValue,
  CustomerOrderTableData
} from '../../../models/direct-bonus-customer-order.model';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import { BaseComponent } from 'src/app/shared/models/base.component';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DirectBonusProjectOverview } from 'src/app/direct-bonus/models/direct-bonus-project-overview.model';


interface FilterOptions {
  orderState: SelectOption<CustomerOrderStateValue>[];
}

interface FilterChoices {
  orderState: CustomerOrderStateValue | null;
  orderPeriod: Date | null;
}


@Component({
  selector: 'vn-direct-bonus-customer-overview',
  templateUrl: './direct-bonus-customer-overview.component.html',
  styleUrls: ['./direct-bonus-customer-overview.component.scss']
})
export class DirectBonusCustomerOverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  private dataStreamSubscription!: Subscription;
  public dataStream$: BehaviorSubject<CustomerOrderTableData[]> = new BehaviorSubject<CustomerOrderTableData[]>([]);

  public filtersGroup!: FormGroup;
  public filterOptions!: FilterOptions;
  public isListFiltered: boolean = false;
  public canSearch: boolean = false;
  @Input() public directBonusProjectOverview!: DirectBonusProjectOverview;

  @Output()
  tableDataChange = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private directBonusService: DirectBonusService,
    private dialog: MatDialog,
    private loader: LoadingService,
  ) {
    super();

    this.initFiltersForm();
    this.initFilterOptions();
  }

  ngOnInit() {
    this.loadDataStream();
  }

  ngOnDestroy(): void {
    this.dataStreamSubscription.unsubscribe();
  }

  // ===================== FILTERS =====================

  private initFiltersForm() {
    this.filtersGroup = this.formBuilder.group({
      orderState: this.formBuilder.control(undefined, []),
      orderPeriod: this.formBuilder.control(moment().format(), []),
    });
  }

  private initFilterOptions() {
    this.filterOptions = {
      orderState: [
        { name: "Entwurf", value: '1' },
        { name: "Bestellt", value: '2' },
        { name: "Fakturiert", value: '3' },
        { name: "Bezahlt", value: '4' },
        { name: "Storniert", value: '5' },
        { name: "Ausgeführt", value: '6' },
        { name: "Abgelaufen", value: '7' }
      ]
    };
  }

  // ===================== TABLE =====================

  public onTableDataChange() {
    this.loadDataStream();
    this.tableDataChange.emit()
  }

  public menuClick($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  private getOrderFilters(): CustomerOrderFilterChoices {
    let orderState = null;
    let forMonth = null;
    let forYear = null;

    if(!this.isFiltersFormEmpty()) {
      const filters: FilterChoices = this.filtersGroup.value;
      orderState = filters.orderState?.toString() ? parseInt(filters.orderState) : null;
      if(filters.orderPeriod && filters.orderPeriod.constructor !== Object) {
        forMonth = filters.orderPeriod ? moment(filters.orderPeriod).month() + 1 : null;
        forYear = filters.orderPeriod ? moment(filters.orderPeriod).year() : null;
      }
    }

    const orderFilters: CustomerOrderFilterChoices = {
      customerId: this.directBonusProjectOverview.customerId,
      forMonth,
      forYear,
      orderState,
    };

    return orderFilters;
  }

  public loadDataStream() {

    this.loader.show();
    const dataFilters = this.getOrderFilters();

    if(this.isFiltersFormEmpty()) {
      this.isListFiltered = false;
    }

    this.canSearch = false;
    
    this.dataStreamSubscription = this.directBonusService.getCustomerOrdersTableData(dataFilters)
      .pipe(
        tap(p => {
          this.dataStream$.next(p);
        }),
      )
      .subscribe(
        () => {
          this.loader.hide();
          if(!this.isFiltersFormEmpty()) {
            this.isListFiltered = true;
          }
          setTimeout(() => {
            this.canSearch = true;
          }, 1000);
        }
      );
  }

  public clearFilters() {
    this.initFilterOptions();
    this.filtersGroup.patchValue({
      orderPeriod: {},
      orderState: null,
    });
    this.loadDataStream();
    this.isListFiltered = false;
  }

  private isFiltersFormEmpty() {
    return Object.values(this.filtersGroup.value).every((val: any) => (
      !val
      || val == false
      || (val.constructor == Object && Object.keys(val).length == 0)
    ))
  }

  public getToday() {
    return moment();
  }
}
