import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';


@Component({
  selector: 'direct-bonus-bulk-create-users-errors',
  template: `<div *ngFor="let error of data">
    <span class="">Fehler: {{ error }}</span>
  </div>`,
})
export class DirectBonusBulkCreateUsersErrorsComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { 
  }
}
