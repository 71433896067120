

const $inject = [
  '$scope',
  'customerService',
  'authenticationService',
  '$timeout'
];

export default class CockpitKundenauswahlTestmodeDialogController {
  constructor($scope, customerService, authenticationService, $timeout) {
    Object.assign(this, {
      $scope,
      customerService,
      authenticationService,
      $timeout
    });
    
    this.state = {
      doAction: false
    }
    this.allowDeactivate = false;
    try {
      this.allowDeactivate = this.authenticationService.iMustBe(['special_advisor', 'advisor']);
    } catch {
    }

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.saveData = true;
    });

    this.configuration = await this.customerService.getConfiguration(this.$scope.ngDialogData.customer.id, Date.now())

    this.$timeout(() => {
      this.saveData = false;
    });
  }

  setTestmode() {
    this.$timeout(() => {
      this.saveData = true;
    });

    this.customerService.setTestmode(this.$scope.ngDialogData.customer.id, this.configuration.testmode)
      .finally(() => {
        this.$timeout(() => {
          this.saveData = false
        });
      });
  }

  setDeactivationState() {
    this.$timeout(() => {
      this.saveData = true;
    });

    this.customerService
      .setDeactivationState(this.$scope.ngDialogData.customer.id, this.configuration.deactivated)
      .finally(() => {
        this.state.doAction = false;
        this.$timeout(() => {
          this.saveData = false;
        });
      });
  }

  close() {
    this.$scope.closeThisDialog();
  }
}

CockpitKundenauswahlTestmodeDialogController.$inject = $inject;
