const $inject = [
  '$scope'
];
export default class frageDialogController {

  constructor(
    $scope
  ) {

    if ($scope.ngDialogData && $scope.ngDialogData.content) {
      $scope.content = $scope.ngDialogData.content;
    }

    $scope.approve = () => {
      $scope.confirm(true);
    };
    $scope.decline = () => {
      $scope.closeThisDialog(true);
    };
  }


}
frageDialogController.$inject = $inject;
