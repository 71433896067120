const $inject = [
  '$q', 'Made', 'CacheService', 'neoCalculateService'
];
export default class neoModifierService {

  constructor($q, Made, CacheService, neoCalculateService) {

    this.$q = $q;
    this.Made = Made;
    this.neoCalculateService = neoCalculateService;


    this.cache = CacheService.getDictionaryStore('neoModifiers');
    this.neoModifiers = this.cache.store;
  }


  loadModifiers(employeeId) {

    let neoModifiers = this.cache.get(employeeId);

    if (neoModifiers) {
      return neoModifiers;
    }

    neoModifiers = this.Made.request('rpc://vbm/neo/get_neo_modifiers', {
      employee_id: parseInt(employeeId)
    });

    return this.cache.set(employeeId, undefined, neoModifiers);
  }


  get(employeeId, component, version) {

    if (!this.neoModifiers[employeeId].data.hasOwnProperty(component)) {
      return false;
    }

    if (!this.neoModifiers[employeeId].data[component].hasOwnProperty(version)) {

      console.warn(
        'version of modifiers not defined. Fallback to highest version',
        employeeId, component, version
      );

      version = Object.keys(this.neoModifiers[employeeId].data[component]).sort().pop();
    }

    return this.neoModifiers[employeeId].data[component][version];
  }


  calculateModifiedValues(components, version, employeeId) {

    return this
      .loadModifiers(employeeId)
      .then(() => {
        angular.forEach(components, (component, componentId) => {
          if (this.neoCalculateService.calculators[componentId]) {
            this.neoCalculateService.calculators[componentId].calculate(
              component,
              this.get(employeeId, componentId, version)
            );
          }
        });

      });
  }

}
neoModifierService.$inject = $inject;
