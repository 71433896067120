export class BaseDigitalSigningComponent {
    constructor(value, label, icon, enabled = false, canSignManually = false, moduleEnabled = false) {

        this.label = label;
        this.value = value;
        this.icon = icon;
        this.moduleEnabled = moduleEnabled;
        this.enabled = enabled;
        this.canSignManually = canSignManually;
    }

    toMongo() {
        return {
            [this.value]: {
                title: this.value,
                enabled: this.enabled,
                canSignManually: this.canSignManually,
            }
        };
    }
}