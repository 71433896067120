import './benefit.service';
import './benefit.controller';

import './amazon/benefit_amazon.controller';

import './bonago/benefit_bonago.controller';
import './bonago/benefit_bonago.service';
import './bonago/details/benefit_bonago_details.controller';
import './bonago/home/benefit_bonago_home.controller';
import './bonago/kategorie/benefit_bonago_kategorie.controller';
import './bonago/templates/bonago_kategorie_menu.controller';
import './bonago/side/bonago_side.controller';
import './bonago/checkout/benefit_bonago_checkout_controller';
import './bonago/checkout/bonago_toc/bonago_toc.controller';

import './bonago/maps/overview/benefit_bonago_retailers_overview_map.controller';

import './home/benefit.home.controller';
