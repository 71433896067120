import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DirectBonusService } from "../../../services/direct-bonus.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BulkDialogData } from "../../../models/direct-bonus-table-dialog-data.model";
import { BehaviorSubject, Subscription } from "rxjs";
import { CustomerOrderTableDataBO, ProblematicOrderForInvoice } from "../../../models/direct-bonus-customer-order.model";
import { map, switchMap } from "rxjs/operators";
import { AlertService } from 'src/app/shared/services/alert.service';
import {LoadingService} from "../../../../shared/services/loading.service";

@Component({
  selector: 'vn-direct-bonus-bulk-invoice-dialog',
  templateUrl: './direct-bonus-bulk-invoice-dialog.component.html',
  styleUrls: ['./direct-bonus-bulk-invoice-dialog.component.scss']
})
export class DirectBonusBulkInvoiceDialogComponent implements OnInit, OnDestroy {

  public dataStream$: BehaviorSubject<ProblematicOrderForInvoice[]> = new BehaviorSubject<ProblematicOrderForInvoice[]>([]);
  public canContinue: boolean = false;
  private subscriptions: Subscription[] = []

  constructor(
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private loader: LoadingService,
    public dialogRef: MatDialogRef<DirectBonusBulkInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: BulkDialogData,
  ) {
  }

  ngOnInit(): void {

    let s1 = this.directBonusService
      .getInvalidOrdersForBulkInvoice(this.dialogData.orderFilters)
      .pipe(map(p => this.dataStream$.next(p)))
      .subscribe();
    this.subscriptions.push(s1)

    let s2 = this.dataStream$.subscribe(
      problematic_orders => this.canContinue = problematic_orders.length == 0
    )
    this.subscriptions.push(s2)
  }

  bulkMarkForInvoice() {
    this.loader.show();
    this.directBonusService.bulkMarkBonusesForInvoice(this.dialogData.orderFilters)
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.message('Sie haben die ausgewählte(n) Bestellung(en) als fakturiert markiert.');
          this.dialogRef.close(true);
        },
        () => {
          this.loader.hide();
          this.alertService.error('Fehlgeschlagen');
        }
      )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

}
