import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'vn-matblur-loading',
  templateUrl: './matblur-loading.component.html',
  styleUrls: ['./matblur-loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatblurLoadingComponent implements OnInit {
  public loading$ = this.loader.loading$
  constructor(private loader: LoadingService,) { }

  ngOnInit(): void {
  }

}
