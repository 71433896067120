const $inject = [
    "authenticationService",
    "cmsService",
    "$state"
];

export default class CmsController {

    constructor(authenticationService, cmsService, $state) {
        Object.assign(this, {
            cmsService
        });

        this.roleCmsEditor = false;

        this.roleCmsEditor = authenticationService.iAm('cms-editor');
        this.custId = $state.params.custId;
        this.accessViolation = true;

        this.checkAccess();
    }

    async checkAccess() {
        try {
            this.cmsService.checkCustomerAccess(this.custId).then(() => {
                this.accessViolation = false;
            });
        } catch (e) {
            if (e.error.name === 'AccessViolationException') {
                this.accessViolation = true;
            }
        }
    }
}

CmsController.$inject = $inject;
