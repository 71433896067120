angular
  .module('VSPApp')
  .controller('ReasonDialogController', ['$scope', function ($scope) {

    $scope.reason = '';

    $scope.submit = function () {
      if ($scope.reason.trim()) {
        $scope.closeThisDialog({
          accepted: false,
          reason: $scope.reason
        });
      }
    };

  }]);
