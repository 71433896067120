angular
  .module( 'VSPApp' )
  .config(['$stateProvider', function($stateProvider) {

    $stateProvider
      .state( 'inApp.carleasing_checkout', {
        url: '/carleasing_checkout',
        params: {
          carLeasingCheckoutConfiguration: null,
          carAdvantageResult: null,
          userSelection: null,
        },
        views: {
          main: {
            template: require('../../modules/car-leasing/checkout/carleasing_checkout_controller/carleasing_checkout.pug'),
            controller: 'CarLeasingCheckoutController',
            controllerAs: '$ctrl',
          },
          header: {
            template: require('../../helpers/header.main.pug')
          }
        }

      })
      .state('inApp.dticket_checkout', {
        url: '/dticket_checkout',
        views: {
          main: {
            template: require('../../modules/dticket/checkout/dticket_checkout.pug'),
            controller: 'DTicketCheckoutController',
            controllerAs: '$ctrl',
          },
          header: {
            template: require('../../helpers/header.main.pug')
          }
        }

      });

  }]);
