const $inject = [
  '$q',
  'SchemaService',
  'ArbeitnehmerSpec',
  'ArbeitnehmerVCPSpec'
]

export default class SpecsService {
  constructor($q, SchemaService, ArbeitnehmerSpec, ArbeitnehmerVCPSpec) {
    this.$q = $q;
    this.SchemaService = SchemaService;
    this.ArbeitnehmerSpec = ArbeitnehmerSpec;
    this.ArbeitnehmerVCPSpec = ArbeitnehmerVCPSpec;

    this.specs = {
      arbeitnehmer: this.ArbeitnehmerSpec,
      arbeitnehmerVCP: this.ArbeitnehmerVCPSpec,
    };
  }

  form(form) {
    return angular.copy(this.specs[form].form);
  };

  schema(schema) {
    console.log("SCHEMA BACE - ", schema)
    console.log("SCHEMA BACE typeof- ", typeof this.specs[schema].schema)
    if ('string' === typeof this.specs[schema].schema) return this.SchemaService.get(schema);
    if ('object' === typeof this.specs[schema].schema) return this.$q.when(schema);
    return this.specs[schema].schema();
  };
}

SpecsService.$inject = $inject;

