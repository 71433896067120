import './checkout.controller';
import './dokumente/checkout_dokumente.controller';
import './zusatzvereinbarung/checkout_zusatzvereinbarung.controller';


import CheckoutZusammenfassungController from './zusammenfassung/CheckoutZusammenfassungController';
import CheckoutEinsatzController from './einsatz/CheckoutEinsatzController';
import CheckoutExtrainfoController from './extrainfo/checkout_extrainfo.controller';

angular
  .module( 'VSPApp' )
  .controller( 'CheckoutZusammenfassungController', CheckoutZusammenfassungController)
  .controller( 'CheckoutEinsatzController', CheckoutEinsatzController)
  .controller( 'CheckoutExtrainfoController', CheckoutExtrainfoController);
