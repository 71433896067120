const $inject = [
  '$q',
  '$scope',
  'customerService',
  'userService',
  'employeeService',
  'NotificationService',
  'authenticationService',
  'lodash',
  '$timeout',
  'ACLService',
  'ByteplantService',
];
export default class employeeEditController {

  constructor(
    $q,
    $scope,
    customerService,
    userService,
    employeeService,
    NotificationService,
    authenticationService,
    lodash,
    $timeout,
    ACLService,
    ByteplantService
  ) {

    this.$q = $q;
    this.authenticationService = authenticationService;
    this.$scope = $scope;
    this.employeeService = employeeService;
    this.customerService = customerService;
    this.userService = userService;
    this.NotificationService = NotificationService;
    this.ACLService = ACLService;
    this.iAmGAdvisor = this.authenticationService.iAm('gadvisor');
    this.iAmAdvisor = this.authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.lodash = lodash;
    this.$timeout = $timeout;
    this.ByteplantService = ByteplantService;
    this.loading = {
      main: false,
      checking_mail: false,
    };
    this.email = {
      valid: false,
      checking: false
    }
    this.forms = {};

    this.checked = false;

    let dialogData = $scope.ngDialogData || {};
    this.needMadeUser = dialogData.needMade;

    this.customers = [];
    this.locations = [];
    this.locationsPromise = $q.when(1);
    this.employee = {
      sex: true,
      testclient: false,
      status: 0, // 0 is normal status, 1 is temporary status
    };

 //   if (this.iAmGAdvisor || this.iAmAdvisor) {

//      customerService
//        .getCustomers()
//        .then(customers => {
//          customers.forEach(customer => {
//            this.customers.push({
//              name: customer.name,
//              value: customer.id,
//              personal_number_regex: customer.personal_number_config,
//              organization_structure: customer.organization_structure,
//              domains_config: customer.domains_config
//            });
//          });
//        });

//    } else {
      customerService
        .get()
        .then(customer => this.customers.push({
          name: customer.name,
          value: customer.id,
          personal_number_regex: customer.personal_number_config,
          organization_structure: customer.organization_structure,
          domains_config: customer.domains_config
        }));
//    }

    if (dialogData.customerId) {

      this.employee.customer_id = dialogData.customerId;
      this.amIEmployer();
      this.updateCustomer();
    }

    if (dialogData.employeeId) {
      this.working = true;

      employeeService
        .get(dialogData.employeeId, Date.now())
        .then((employee) => {
          employee = angular.copy(employee);
          angular.extend(employee, employee.address);
          this.current_login_email = employee.login.email;
          this.employee = employee;
          this.customerEdit = (!this.iAmSpecialAdvisor && this.employee.id);
          this.amIEmployer();
          this.updateCustomer(true);
          if (!this.needMadeUser) {
            return customerService.getNeoProjectByEmployeeId(dialogData.employeeId);
          } else {
            this.working = false;
          }
        })
        .then(neoProject => {
          this.neoProjectId = neoProject._id;
          this.working = false;
        });
    }

  }

  amIEmployer() {
    this.loading.main = true;
    return this.ACLService.amIEmployer(this.employee.customer_id)
      .then((res) => {
        this.iAmEmployer = res;
      })
      .catch((err) => {
        console.err(err);
        this.iAmEmployer = false;
      })
      .finally(() => {
        this.loading.main = false;
        return;
      });
  }

  getOrganizationConfigForEmployer() {
    let selectedEmployer = this.customers.find(customer => customer.value == this.employee.customer_id);

    return this.customerService.getOrganizationConfigForEmployer(selectedEmployer);
  }

  getPatternForEmployer() {
    if (this.canSkipEmailValidation()) {
      return null;
    }

    let selectedCustomer = this.customers.find(customer => customer.value == this.employee.customer_id);
    return selectedCustomer && selectedCustomer.personal_number_regex && selectedCustomer.personal_number_regex.is_enabled ? selectedCustomer.personal_number_regex : null;
  }

  updateCustomer(preserve) {
    this.locations = [];

    if (!preserve) {
      delete this.employee.loc_id;
      delete this.neoProjectId;
    }

    this.locationsPromise = this.locationsPromise
      .finally(angular.noop)
      .then(() => this.customerService.getLocations(this.employee.customer_id))
      .then(locations => {

        this.locations = [];

        locations.forEach(location => {
          this.locations.push({
            name: [
              location.name,
              location.strasse,
              location.plz,
              location.ort
            ].join(', '),
            value: location.id
          });
        });

        if (1 === this.locations.length && !preserve) {
          this.employee.loc_id = this.locations[0].value;
        }
        if (0 === this.locations.length) {
          delete this.employee.loc_id;
        }
      })
      .then(() => this.customerService.listNeoProjects(this.employee.customer_id))
      .then(neoProjectList => {

        this.neoProjectList = [];
        neoProjectList.forEach(project => {
          this.neoProjectList.push({
            name: project.name,
            value: project._id
          });
        });

        if (this.neoProjectList.length && !preserve) {
          this.neoProjectId = this.neoProjectList[0].value;
        }

        if (0 === this.neoProjectList.length) {
          delete this.neoProjectId;
        }
      })
      .then(() => {
        this.validateCustomerEmailSyntax();
      });

    return this.locationsPromise;
  }

  canSave() {
    let is_form_valid = this.forms.employeeEditForm.$valid
    let is_email_taken = this.isEmailTaken();
    let is_email_valid = this.mailChanged ? this.email.valid : true;
    let is_test_client = this.employee.testclient;

    return is_form_valid && (is_test_client || is_email_valid) && !is_email_taken;
  }

  isEmailTaken() {
    if (!this.check) {
      return false
    }

    return this.check.exists && this.check.user.id !== this.employee.id
  }

  async save() {
    if (!this.forms.employeeEditForm.$valid) {
      return;
    }

    this.should_reInvite = (this.employee.login.email !== this.current_login_email); // if the user has changed their login email they need to be reinvited;
    this.working = true;
    let is_existing_user = this.employee.id;

    if (typeof this.employee.login.email !== 'string') {
      this.employee.login.email = '';
    }

    for (let employeeElement in this.employee) {
      if (this.employee[employeeElement] === null || this.employee[employeeElement] === '') {
        delete this.employee[employeeElement];
      }
    }

    let operation;
    if (is_existing_user) {
      operation = this.employeeService.update(this.employee, this.should_reInvite)
        .then(() => {
            return this.userService.updateNeoProjectId(this.employee.id, this.neoProjectId);
          }
        );

    } else {
      // creates MySQL user and Mongo user, invites and sets neoProjectId;
      operation = this.authenticationService.create(this.employee, this.neoProjectId, null, false, 'regisration')
        .then((employee_id) => {
          return this.userService.updateNeoProjectId(employee_id, this.neoProjectId);
        });
    }

    operation
      .then(() => {
        this.NotificationService.message('Arbeitnehmerdaten wurden gespeichert.');
        this.$scope.confirm(0);
      })
      .catch(error => {
        if(error.error && error.error.message.flag == 'cidaasInviteFail') {
          this.NotificationService.error('Dieser Benutzer existiert bereits');
        } else {
          this.NotificationService.error('Speichern nicht erfolgreich.');
        }

        if (error.error && (error.error.message.includes('locid-custid-personalnummer'))) {
          this.forms.employeeEditForm['employeeEditController.employee.employeenumber'].$setValidity('unique', false);
        } else if (error.error && (error.error.message.includes('not unique email'))) {
          this.checkEmployeeExistence();
        } else {
          this.error = true;
        }
      }).finally(() => this.working = false);

    return true;
  }

  resetUniqueEmployeeId() {
    this.forms.employeeEditForm['employeeEditController.employee.employeenumber'].$setValidity('unique', true);
  }

  checkEmployeeExistence() {
    return this.employeeService.checkEmployeeByEmail(this.employee.login.email)
      .then(result => {
        this.check = result;
        this.checked = true;

        return result;
      })
      .catch(err => console.log(err));
  }

  handleTestEntitySwitch() {
    this.resetEmailValidity();
    this.validateCustomerEmailSyntax();
  }

  async handleEmailInputChange() {
    this.$timeout(() => {
      this.loading.checking_mail = true;
    });

    this.mailChanged = true;

    this.resetEmailValidity()

    this.validateCustomerEmailSyntax();
    await this.checkMail();

    this.$timeout(() => {
      this.loading.checking_mail = false;
    });

  }

  getEmployerDomainConfig() {
    let selectedCustomer = this.customers.find(customer => customer.value == this.employee.customer_id);
    return selectedCustomer.domains_config;
  }

  getDomainErrorMessage() {
    let employer_domain_config = this.getEmployerDomainConfig();
    return this.customerService.getCustomerDomainErrorMessage(employer_domain_config);
  }

  validateCustomerEmailSyntax() {
    let isEmailAddressValid = this.canSkipEmailValidation();

    if (!isEmailAddressValid) {
      let selectedCustomer = this.customers.find(customer => customer.value === this.employee.customer_id);
      if (!selectedCustomer) {
        return;
      }

      if (this.employee.login) {
        isEmailAddressValid = !!this.customerService.isEmployeeEmailDomainValid(
          this.employee.login.email,
          selectedCustomer.domains_config
        );
      }
    }

    this.forms.employeeEditForm['email'].$setValidity('domain', isEmailAddressValid);
  }

  async checkMail() {
    this.checked = false;
    if (typeof this.employee.login.email !== 'undefined' && this.employee.login.email !== '') {
      await this.checkEmployeeExistence();
    }
  }

  resetEmailValidity() {
    this.email.checked = false;
    this.email.valid = false;
  }

  async checkEmailValidity() {
    if (this.canSkipEmailValidation()) {
      this.email.valid = true;
      return;
    }

    this.email.valid = false;
    this.email.checked = true;

    if (this.employee.login) {
      const isValid = await this.ByteplantService.isEmailAddressValid(
        this.employee.login.email
      )

      this.email.valid = !!isValid;
      if (!this.email.valid) this.NotificationService.error('Validierung der E-Mail-Adresse ist fehlgeschlagen');
    }
  }

  canSkipEmailValidation() {
    return this.employeeService.isEmployeeTemporary(this.employee) || this.employeeService.isEmployeeTest(this.employee) || this.isCidaasUser();
  }

  isCidaasUser() {
    return this.employee.is_cidaas_user;
  }


}

employeeEditController.$inject = $inject;
