const FIRST_GROUP = /^([4-9]|\d{2})$/;
const SECOND_GROUP = /^\d{1,2}\.([2-9]|\d{2})$/;
const FIRST_GROUP_NEEDS_PADDING = /^\d{1}\./;
const SECOND_GROUP_NEEDS_PADDING = /^\d{2}\.\d{1}\./;
const IGNORE_KEY_CODES = [
  8, // Backspace
  46, //'Delete'
  37, // Left arrow
  38, // Up arrow
  39, // Right arrow
  40, // Down arrow
  91, // Meta key
  9, // Tab key
];

angular
  .module('VSPApp')
  .directive('vnDate', ['$timeout', 'moment', function ($timeout, moment) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attrs, ngModel) {
        const dateRegex = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;

        scope.$watch(attrs.ngModel, (viewValue) => {
          if ((viewValue && dateRegex.test(viewValue))) {
            ngModel.$setViewValue(viewValue);
            ngModel.$render();
          }
        });

        attrs.$observe('vnDateMin', () => {

          ngModel.$validate();
        });

        attrs.$observe('vnDateMax', () => {

          ngModel.$validate();
        });

        if (!attrs.vnDateHelper && attrs.vnDateReformat) {
          elm.on('keyup', event => {

            if (-1 !== IGNORE_KEY_CODES.indexOf(event.keyCode)) {
              return;
            }

            $timeout(() => {
              let apply = false;
              let viewValue = ngModel.$viewValue;

              if (FIRST_GROUP.test(viewValue) || SECOND_GROUP.test(viewValue)) {
                viewValue = viewValue + '.';
                apply = true;
              }

              if (FIRST_GROUP_NEEDS_PADDING.test(viewValue)) {
                viewValue = '0' + viewValue;
                apply = true;
              }

              if (SECOND_GROUP_NEEDS_PADDING.test(viewValue)) {
                viewValue = viewValue.replace('.', '.0');
                apply = true;
              }

              if (apply) {
                ngModel.$setViewValue(viewValue);
                ngModel.$render();
              }
            }, 100);

          });
        }

        ngModel.$parsers.unshift(function (viewValue) {
          if (attrs.vnDateStringModel) {
            const date = parseStringToDate(viewValue);

            if (date) {
              return setLeadingZero(date.getDate()) + '.' + setLeadingZero((date.getMonth() + 1)) + '.' + date.getFullYear();
            }
          }

          if ('string' === typeof viewValue) {
            if (viewValue === '') {
              return null;
            }

            let dateComponents = viewValue.match(dateRegex);

            if (dateComponents) {
              let resultDate = new Date(dateComponents[3], dateComponents[2] - 1, dateComponents[1]);
              if (attrs.vnDateUseUtc) {
                resultDate = moment.utc({
                  year: dateComponents[3],
                  month: dateComponents[2] - 1,
                  day: dateComponents[1]
                }).toDate();
              }
              return resultDate;
            }
          }

          if (moment.isDate(viewValue)) {
            let resultDate = viewValue;
            if (attrs.vnDateUseUtc) {
              resultDate = moment(resultDate).utc().toDate();
            }
            return resultDate;
          }
        });

        ngModel.$formatters.push(function (modelValue) {
          let result = undefined;

          if (modelValue == undefined) {
            return result;
          }

          if (('object' === typeof modelValue) && modelValue.getDate) {
            result = moment(modelValue);
            // return '' + modelValue.getDate() + '.' + (modelValue.getMonth() + 1) + '.' + modelValue.getFullYear();
          } else if ('string' === typeof modelValue && (new Date(modelValue))) {
            result = moment(new Date(modelValue));
          } else if ('number' === typeof modelValue) {
            result = moment.unix(modelValue).utc();
          }
          return result ? result.format('DD.MM.YYYY') : '';
        });

        let ageValidator = function (modelValue, viewValue) {
          if (ngModel.$isEmpty(modelValue)) {
            return true;
          }

          if (attrs.vnDateStringModel) {
            const dateString = dateRegex.test(modelValue) ? modelValue : viewValue;
            modelValue = parseStringToDate(dateString.toString());
          }

          let today = new Date(),
            age = today.getFullYear() - modelValue.getFullYear(),
            m = today.getMonth() - modelValue.getMonth();

          if (!attrs.vnDateYearRange) {
            if (m < 0 || (m === 0 && today.getDate() < modelValue.getDate())) {
              age--;
            }
          }

          if (attrs.vnDateAgeMax && age > scope.$eval(attrs.vnDateAgeMax)) {
            return false;
          }

          return true;
        };

        let dateMinValidator = (modelValue, viewValue) => {
          if (!attrs.vnDateMin || attrs.vnDateMin === '') {
            return true;
          }

          if (ngModel.$isEmpty(modelValue)) {
            return true;
          }

          if (attrs.vnDateStringModel) {
            const dateString = dateRegex.test(modelValue) ? modelValue : viewValue;
            modelValue = parseStringToDate(dateString);
          }

          let compare_date = attrs.vnDateMin;

          if (angular.isNumber(compare_date)) {
            modelValue = new Date(compare_date * 1000);
          }

          //console.log('dateMinValidator', new Date( modelValue ), new Date( modelValue ) >= new Date( attrs.vnDateMin ), new Date( attrs.vnDateMin ) );
          return new Date(modelValue) >= new Date(compare_date);
        };


        let dateMaxValidator = (modelValue, viewValue) => {
          if (!attrs.vnDateMax) {
            return true;
          }

          if (ngModel.$isEmpty(modelValue)) {
            return true;
          }

          if (attrs.vnDateStringModel) {
            const dateString = dateRegex.test(modelValue) ? modelValue : viewValue;
            modelValue = parseStringToDate(dateString);
          }

          let compare_date = attrs.vnDateMax;

          if (angular.isNumber(compare_date)) {
            modelValue = new Date(compare_date * 1000);
          }

          //console.log('dateMaxValidator', new Date( modelValue ), new Date( modelValue ) < new Date( attrs.vnDateMax ), new Date( attrs.vnDateMax ) );
          return new Date(modelValue) < new Date(compare_date);
        };

        let parseStringToDate = function (dateString) {
          let date = dateString.match(dateRegex);

          if (date) {
            return new Date(date[3], date[2] - 1, date[1]);
          }
        };

        let setLeadingZero = function (string) {
          return string < 10 ? '0' + string : string;
        };

        if (attrs.vnDateAgeMax || attrs.vnDateAgeMin) {
          ngModel.$validators.age = ageValidator;
        }

        ngModel.$validators.dateMin = dateMinValidator;

        ngModel.$validators.dateMax = dateMaxValidator;

      }
    };
  }]);
