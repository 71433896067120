import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  DirectBonusFormData,
  DirectBonusProjectComponentConfiguration,
  DirectBonusProjectConfiguration
} from '../../../models/direct-bonus-data.model';
import { DirectBonusComponentType } from '../../../models/direct-bonus-project-overview-table-data.model';
import {startWith, take, tap, debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';
import { CustomerOrder } from 'src/app/direct-bonus/models/direct-bonus-customer-order.model';
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { ProjectActionsDialogData } from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { AlertService } from 'src/app/shared/services/alert.service';

// ===============================================================

interface FormOptions {
  componentName: SelectOption<DirectBonusComponentType>[];
}

interface FormChoices {
  componentName: DirectBonusComponentType;
  bonusValue: string;
  employeeId: string;
}

// ===============================================================

@Component({
  selector: 'vn-direct-bonus-add-dialog',
  templateUrl: './direct-bonus-add-dialog.component.html',
  styleUrls: ['./direct-bonus-add-dialog.component.scss']
})
export class DirectBonusAddDialogComponent implements OnInit, OnDestroy {

  // TODO make observable!!
  public selectedComponentName!: DirectBonusComponentType | null;
  public hasAvailableForCheckoutComponents = true;

  // TODO make observable!!
  public componentMaxValue!: number;

  // TODO make observable!!
  public componentStandardValue!: string;

  public formGroup!: FormGroup;
  public formOptions!: FormOptions;
  public employeeName$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public employeeValid: boolean = false;
  private bonusProjectConfiguration!: DirectBonusProjectConfiguration;

  public get bonusProjectId(): string {
    return this.dialogData.bonusProjectId!;
  }

  public get customerId(): number {
    return this.dialogData.customerId!;
  }

  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public get period(): string {
    return this.dialogData.period!;
  }

  public get bonusValue(): FormControl {
    return this.formGroup.get('bonusValue') as FormControl;
  }

  public get employeeId(): FormControl {
    return this.formGroup.get('employeeId') as FormControl;
  }

  private componentChangeSubscription!: Subscription;
  private employeeIdSubscription!: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public directBonusService: DirectBonusService,
    public dialogRef: MatDialogRef<DirectBonusAddDialogComponent>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  private getAllowedForCheckoutComponentsConfigurations(employeeId: number) {
    this.directBonusService
      .getAllowedForCheckoutComponentsConfigurations(
        employeeId,
        this.dialogData.month!,
        this.dialogData.year!,
        this.dialogData.bonusProjectId!
      ).pipe(
      map((allowedForCheckoutComponentsConfigurations: DirectBonusProjectComponentConfiguration[]) => {
        return allowedForCheckoutComponentsConfigurations.reduce(
          (configuration, componentConfig) => {
            configuration[componentConfig.componentType!] = componentConfig;
            return configuration;
          },
          {} as DirectBonusProjectConfiguration
        )
      })
    )
      .subscribe(
        (bonusProjectConfiguration: DirectBonusProjectConfiguration
        ) => {
          this.bonusProjectConfiguration = bonusProjectConfiguration;

          const components = this.directBonusService.getAvailableBonusComponents(bonusProjectConfiguration);

          if (components.length) {
            this.hasAvailableForCheckoutComponents = true;
            this.formOptions = {
              componentName: components
            };

            this.selectedComponentName = components[0].value;
            this.setBonusValueValidators();
          } else {
            this.selectedComponentName = null;
            this.hasAvailableForCheckoutComponents = false;
          }
        });
  }

  private setBonusValueValidators() {
    const componentConfig = this.bonusProjectConfiguration[this.selectedComponentName!];
    this.componentMaxValue = componentConfig.maxValue;
    this.componentStandardValue = `${componentConfig.standardValue} €`;

    this.bonusValue.setValidators([
      Validators.required,
      Validators.pattern(/^\-?[0-9]+(?:\.[0-9]{2})?$/),
      Validators.min(0),
      Validators.max(this.componentMaxValue),
    ]);
  }

  ngOnDestroy(): void {
    this.componentChangeSubscription.unsubscribe();
    this.employeeIdSubscription.unsubscribe();
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      componentName: this.formBuilder.control(undefined, [Validators.required,]),
      employeeId: this.formBuilder.control(undefined, [Validators.required]),
      bonusValue: this.formBuilder.control(undefined, []),
    });

    this.employeeIdSubscription = this.formGroup.controls['employeeId'].valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    )
      .subscribe((employeeId: any) => {
        if(!!employeeId) {
          const _employeeId = parseInt(employeeId);
          if (isNaN(_employeeId)) {
            this.alertService.error(`${employeeId} is not a number`);
          } else {
            this.fetchEmployee(_employeeId);
            this.getAllowedForCheckoutComponentsConfigurations(_employeeId);
          }
        }
      })

    this.componentChangeSubscription = this.formGroup.controls['componentName'].valueChanges.subscribe(
      (value: DirectBonusComponentType) => {
        this.selectedComponentName = value;
        this.setBonusValueValidators();
      }
    )
  }

  public fetchEmployee(employeeId: number) {
    this.directBonusService.getEmployeeName(employeeId).subscribe(
      (employeeName: string | null) => {
        this.employeeName$.next(employeeName);
      },
      (error => {
        this.employeeName$.next(null);
        this.alertService.error(`Can not find employee ${employeeId} `);
      })
    )
  }

  public saveBonus() {
    const {
      componentName,
      bonusValue,
      employeeId,
    }: FormChoices = this.formGroup.value;

    const newBonusData: DirectBonusFormData = {
      bonusProjectId: this.bonusProjectId,
      customerId: this.customerId,
      orderId: this.orderId,
      employeeId: parseInt(employeeId),
      componentName: componentName,
      formValue: parseFloat(bonusValue),
      period: this.period
    };

    this.dialogRef.close(newBonusData);
  }
}
