<div class='form-group'>
    <label [for]='key'>
        {{ label }}
    </label>

    <input class='form-control'
           [name]='key'
           [attr.data-cy]='key'
           [disabled]='isDisabled'
           [placeholder]='placeholder'
           [value]='dateRange'
           (click)='toggleDateRangePicker()'>
</div>

<div class='hidden'>
    <mat-date-range-input [rangePicker]="picker">]
        <input matStartDate
               (dateInput)='pickDate($event.value)'>
        <input matEndDate
               (dateInput)='pickDate($event.value, true)'>
    </mat-date-range-input>

    <mat-datepicker-toggle [for]="picker"
                           [disableRipple]='true'>
    </mat-datepicker-toggle>

    <mat-date-range-picker #picker
                           touchUi>
    </mat-date-range-picker>
</div>