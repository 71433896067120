import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AlertService } from '../../shared/services/alert.service';
import { BenefitsBonagoService } from './benefits-bonago.service';

@Injectable()
export class BenefitMapService {

  public readonly defaultCategoryIdForRetailersWithMaps = '51';

  public readonly overviewMapId = 'ee695372458ffb1a';
  public readonly detailsMapId = 'ce01075e52ab3128';

  constructor(
    private alertService: AlertService,
    private benefitService: BenefitsBonagoService
  ) {
  }

  public getAddressFromPostcode$(): Observable<google.maps.LatLng> {

    return this.benefitService.userBonagoScheme$.pipe(
      switchMap(
        userScheme => from(new Promise((resolve, reject) => {
          const geocoder = new google.maps.Geocoder();

          const request = {
            componentRestrictions: {
              country: 'DE',
              postalCode: userScheme.defaultPostcode
            }
          };

          geocoder.geocode(
            request,
            (
              results: google.maps.GeocoderResult[] | null,
              status: google.maps.GeocoderStatus
            ) => {
              if (!!results && status == google.maps.GeocoderStatus.OK) {
                resolve(results[0].geometry.location);
              } else {
                this.alertService.error('Geocode was not successful for the following reason: ' + status);
                reject(status);
              }
            }
          );
        })) as Observable<google.maps.LatLng>
      )
    );
  }
}
