function toArray(components) {

  let result = {
    components: []
  };

  for (let component in components) {
    if ('total' === component) {
      result.total = components.total;
    } else {
      result.components.push({
        component,
        count: components[component]
      });
    }
  }

  return result;
}

const $inject = ["Made"];
export default class StatisticService {

  constructor(Made) {
    this.Made = Made;
  }


  customerGeneral(customerId) {

    if (customerId) {
      customerId = parseInt(customerId);
    }

    return this.Made.request('rpc://valuenetdb/statistic/general', {
      customerId
    });
  }


  advisorGeneral(advisorId) {

    let params = {};

    if (advisorId) {
      params.advisorId = parseInt(advisorId);
    }

    return this.Made.request('rpc://valuenetdb/statistic/general', params);
  }


  customerComponents(customerId, asArray = false) {

    if (customerId) {
      customerId = parseInt(customerId);
    }

    let promise = this.Made.request('rpc://valuenetdb/statistic/components', {
      customerId
    });

    if (asArray) {
      return promise.then(toArray);
    }

    return promise;
  }


  advisorComponents(advisorId, asArray = false) {

    let params = {};

    if (advisorId) {
      params.advisorId = parseInt(advisorId);
    }

    let promise = this.Made.request('rpc://valuenetdb/statistic/components', params);

    if (asArray) {
      return promise.then(toArray);
    }

    return promise;
  }


}

StatisticService.$inject = $inject;
