import {
  PENSION_INSURANCE,
  HEALTH_INSURANCE,
  CARE_INSURANCE,
  CHILD_TAX_CREDIT,
  DEFAULT_FACTOR,
  DEFAULT_ADDITIONAL_RATE_HEALTH_INSURANCE,
  DEFAULT_RAISE,
  DEFAULT_CAL_CONTROLS,
  DEFAULT_CHURCH_TAX_TYPE,
  BUDGET_TYPES,
  CHURCH_TAX_TYPES,
  RAISE_TYPES,
  DEFAULT_CALCULATION_SETTINGS, CALCULATION_TYPES, BUDGET_TYPES_BY_NAME
} from './default_data/data';
import SteuerklassenConstants from '../../constants/calculation/SteuerklassenConstants';
import {
  DIRECT_BUDGET_FIELDS, DEFAULT_DIRECT_BUDGET, DEFAULT_GOAL_BUDGET, GOAL_BUDGET_FIELDS
} from '../../services/datev/DatevCalculatorService';
import {DATEV_ADVISOR} from '../../services/datev/DatevService';

import editPotentialDialog2_html from '../../modules/datev/editPotentialDialog2.pug'

export const DEFAULT_CAL_POTENTIAL = {
  desc: 'Standard',
  brutto: 2500,
  pap_stkl: SteuerklassenConstants[0].value,
  pap_r: DEFAULT_CHURCH_TAX_TYPE.value,
  factor: DEFAULT_FACTOR,
  budget: BUDGET_TYPES[0].value,
  raise: angular.copy(DEFAULT_RAISE),
  direct: angular.copy(DEFAULT_DIRECT_BUDGET),
  goal_direct : angular.copy(DEFAULT_GOAL_BUDGET),
  calculation_settings: angular.copy(DEFAULT_CALCULATION_SETTINGS),
  employee: 1,
  pension_insurance: PENSION_INSURANCE[0].value,
  health_insurance: HEALTH_INSURANCE[0].value,
  additional_rate_health_insurance: DEFAULT_ADDITIONAL_RATE_HEALTH_INSURANCE,
  care_insurance: CARE_INSURANCE[0].value,
  monthly_contribution: 0,
  child_tax_credit: CHILD_TAX_CREDIT[0].value,
  annuals: 0,
  r_id: '_' + Math.random().toString(36).substr(2, 9),
  advantage: 0
};

const $inject = [
  '$scope',
  'employeeService',
  'datevService',
  'SteuerklassenConstants',
  '$timeout',
  'dialogService',
  'datevCalculatorService',
  '$window',
  '$filter',
  'fileService',
  'USERLANE_DISABLED',
  'UserlaneService',
  'customerService'
];

export default class DatevController {
  constructor(
    $scope,
    employeeService,
    datevService,
    SteuerklassenConstants,
    $timeout,
    dialogService,
    datevCalculatorService,
    $window,
    $filter,
    fileService,
    USERLANE_DISABLED,
    UserlaneService,
    customerService
  ) {
    Object.assign(this, {
      $scope,
      datevService,
      $timeout,
      dialogService,
      datevCalculatorService,
      $window,
      $filter,
      fileService,
      USERLANE_DISABLED,
      UserlaneService,
      customerService
    });

    // constants
    // TODO: remove when wizard is implemented
    $scope.steuerklassen = SteuerklassenConstants;
    $scope.pension_insurances = PENSION_INSURANCE;
    $scope.health_insurances = HEALTH_INSURANCE;
    $scope.church_tax_types = CHURCH_TAX_TYPES;
    $scope.care_insurances = CARE_INSURANCE;
    $scope.controls = angular.copy(DEFAULT_CAL_CONTROLS);
    $scope.child_tax_credits = CHILD_TAX_CREDIT;

    // calculator data
    $scope.calculatedData = null;
    $scope.iteratorData = null;
    $scope.savedFile = null;
    $scope.calculationsData = {};
    $scope.directBudgetFields = DIRECT_BUDGET_FIELDS;
    $scope.goalBudgetFields = GOAL_BUDGET_FIELDS;
    $scope.budgetTypes = BUDGET_TYPES;
    $scope.budgetTypesByName = BUDGET_TYPES_BY_NAME;
    $scope.raiseTypes = RAISE_TYPES;

    // table
    $scope.tableResultBody = [];
    $scope.potentials = [];
    $scope.paginationList = [];
    $scope.potentialsResult = [];
    $scope.currentDate = new Date();

    // additional flags
    $scope.redoingCalculation = false;
    $scope.jahresbetrachtung = false; // TODO: needed ?
    $scope.showFlatTaxes = false;

    this.forms = {};

    this.datevService.fetchArticles().then(async articles => {

      this.styles = {};

      for (let item of articles) {
        if (item.teaser && item.teaser._id) {
          this.styles[item.teaser._id] = {
            'background-image': 'url(' + await this.fileService.getDocumentUrl(item.teaser._id) + ')'
          };
        }
      }

      this.$timeout(() => {
        this.$scope.datevArticles = articles;
      });
    });

    this.init(employeeService);
  }

  init(employeeService) {
    if (sessionStorage.getItem('datev-cal-config')) {

      this.$scope.showForms = true;
      this.loadConfig(sessionStorage.getItem('datev-cal-config'));
    } else {
      // this.resetCalConfig();
      // this.$scope.potentials = [DEFAULT_CAL_POTENTIAL];
    }

    this.watchers = [];

    // add watches
    let potentials_watcher = this.$scope.$watch(
      'potentials',
      (newValue, oldValue) => {
        if (newValue && newValue.length && !angular.equals(newValue, oldValue)) {
          this.$scope.potentials = newValue;

          this.saveCalConfigToSession();
          this.calculate();
        }
      },
      true
    );
    this.watchers.push(potentials_watcher);

    let controls_watcher = this.$scope.$watch(
      'controls',
      (newControls, oldControls) => {
        if (newControls && !angular.equals(newControls, oldControls)) {
          this.saveCalConfigToSession();
          this.calculate();
        }
      },
      true
    );
    this.watchers.push(controls_watcher);

    // custom advisor
    this.$scope.datev = DATEV_ADVISOR;

    if (!this.USERLANE_DISABLED) {
      this.customerService.getCustomerSpecificUserlaneTags()
        .then((customerSpecificUserlaneTags) => {
          this.userPaths = customerSpecificUserlaneTags.concat(['datev']);
          this.UserlaneService.load(this.userPaths);
      });
    }

    this.onDestroy = () => {
      // remove watchers
      for(let watcher of this.watchers) {
        watcher();
      }
    };
  }

  loadConfig(config) {
    // sanity check
    if (!config) return false;

    // try to parse
    try {
      config = JSON.parse(config);

      // extract saved controls
      let saved_controls = config.controls;

      // extract saved potentials
      let saved_potentials = config.potentials;

      // sanity check
      if (saved_controls instanceof Object === false || saved_potentials instanceof Array === false) {
        // user has tempered with the saved data
        // indicate
        return false;
      }

      // merge with default controls
      this.$scope.controls = {
        ...DEFAULT_CAL_CONTROLS,
        ...saved_controls
      };

      // import saved data
      let import_ready_potentials = [];
      for (let potential of saved_potentials) {
        import_ready_potentials.push({
          ...DEFAULT_CAL_POTENTIAL,
          ...potential
        });
      }

      this.$scope.potentials = import_ready_potentials;
    } catch (e) {
      // not valid JSON
      return false;
    }

    // everything is fine
    return true;
  }

  showCalcForm() {
    this.$scope.showForms = true;
    // this.addPotential();
    // this.editPotential(0);
  }

  reset() {
    // ask user
    if (window.confirm('Sind Sie sicher, dass Sie die Seite zurücksetzen möchten? Alle nicht heruntergeladenen Berechnungen werden dadurch verworfen.')) {
      this.resetCalConfig();
    }
  }

  resetCalConfig() {
    sessionStorage.removeItem('datev-cal-config');
    this.$window.location.reload();

    // this.$scope.potentials          = [DEFAULT_CAL_POTENTIAL];
    // this.$scope.calculatedData      = null;
    // this.$scope.changedPoitentialId = 0;
    // this.$scope.potentialsResult    = [];
    // this.$scope.controls            = DEFAULT_CAL_CONTROLS;
    // this.$scope.showForms           = false;
  }

  getDataForSave() {
    return {
      controls: this.$scope.controls,
      potentials: this.$scope.potentials
    };
  }

  saveCalConfigToSession() {
    let data = this.getDataForSave();
    sessionStorage.setItem('datev-cal-config', JSON.stringify(data));
  }

  saveCalConfigToFile() {
    let filename = 'config.json';
    let data = this.getDataForSave();
    let file = new Blob([JSON.stringify(data)], {
      type: 'application/json'
    });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      let a = document.createElement('a');
      let url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  loadedJson(file) {
    if (this.loadConfig(file))
      this.calculate();
  }

  multiplyCalResult(cal_result, factor) {
    let result = angular.copy(cal_result);
    result = Object.keys(result).reduce((acc, cal_key) => {
      let cal_result = result[cal_key];
      acc[cal_key] = Object.keys(cal_result).reduce((acc, cal_property) => {
        if (cal_property === 'direct') {
          let direct_result = cal_result[cal_property];
          let direct_components = Object.keys(direct_result).filter(result => result !== 'total' && result !== 'ag_cost_total');

          acc[cal_property] = direct_components.reduce((acc, cal_direct_key) => {
            acc[cal_direct_key] = {...direct_result[cal_direct_key]};
            if (direct_result[cal_direct_key]['active']) {
              acc[cal_direct_key]['result'] = direct_result[cal_direct_key]['result'] * 12;
            }
            return acc;
          }, {});
        } else {
          acc[cal_property] = cal_result[cal_property] * factor;
        }
        return acc;
      }, {});
      return acc;
    }, {});

    return result;
  }

  calculate() {
    let cal_data = [];
    let potentials_sum = [];

    this.$scope.potentials.forEach(potential => {
      let potential_temp = angular.copy(potential);

      let cal_result = this.datevCalculatorService.calculateForPotential(potential_temp);


      if (this.$scope.controls.for_year) {
        cal_result = this.multiplyCalResult(cal_result, 12);
      }

      let title = `${potential_temp.desc} - 1 Mitarbeiter ${this.$scope.controls.for_year ? '- Jahresbetrachtung' : ''}`;

      cal_data.push({
        pagTitle: title,
        calcBasisType: 'month',
        budgetType: potential_temp.budget,
        id: potential_temp.r_id,
        ...cal_result
      });


      let cal_result_sum_employees = angular.copy(cal_result);
      cal_result_sum_employees = this.multiplyCalResult(cal_result_sum_employees, potential_temp.employee);

      // add the result to the results
      potentials_sum.push(cal_result_sum_employees);

      // add result to table if needed
      if (potential_temp.employee > 1) {
        cal_data.push({
          pagTitle: `${potential_temp.desc} - ${potential_temp.employee} Mitarbeiter ${this.$scope.controls.for_year ? '- Jahresbetrachtung' : ''}`,
          calcBasisType: 'employees',
          budgetType: potential_temp.budget,
          id: potential_temp.r_id,
          ...cal_result_sum_employees
        });
      }
    });

    if (this.$scope.potentials.length > 1) {
      const showFlatTaxes = this.$scope.potentials.some(potential => potential.budget === this.$scope.budgetTypes[0].value);

      let all = {before: {}, normal: {}, optimized: {}};
      potentials_sum.forEach(potential_sum => {
        Object.keys(potential_sum).forEach((calc_key, calc_key_index) => {
          let calculation = potential_sum[calc_key];
          Object.keys(calculation).forEach((cal_prop_key, calc_prop_key_index) => {
            all[calc_key][cal_prop_key] = (all[calc_key][cal_prop_key] || 0) + calculation[cal_prop_key];
          });
        });
      });
      cal_data.push({
        ...all,
        pagTitle: `Zusammenfassung ${this.$scope.controls.for_year ? '- Jahresbetrachtung' : ''}`,
        calcBasisType: 'summary',
        showFlatTaxes
      });
    }

    this.$scope.potentialsResult = cal_data.map(item => ({title: item.pagTitle}));
    const filteredCalcData = cal_data.filter(data => data.calcBasisType !== 'summary' && data.calcBasisType !== 'employees');
    this.$scope.calculationsData = {
      potentials: [...filteredCalcData.map(item => {
        const currentPotential = this.$scope.potentials.find(potential => potential.r_id === item.id);
        const currentCalculations = cal_data.filter(data => data.id === item.id);

        return {
          info: {...currentPotential},
          calculations: currentCalculations,
        };
      })],
      summary: {...cal_data.find(data => data.calcBasisType === 'summary')},
    };
    this.$scope.iteratorData = this.createIterator(cal_data);
    this.$scope.calculatedData = this.$scope.iteratorData.next().value;
    this.$timeout(() => {
      this.$scope.showForms = true;
    }, 0);
    // this.$scope.showForms = true;
  }

  redoCalculation() {
    this.calculate();
  }

  addPotential() {
    const newPotential = angular.copy(DEFAULT_CAL_POTENTIAL);
    newPotential.r_id = '_' + Math.random().toString(36).substr(2, 9);
    this.editPotential(newPotential);
  }


  parsePotentialTitle(potential) {
    const potentialRaise = potential.raise.type === 0 ? ((potential.raise.value * 100).toFixed(2) + ' %') : this.$filter('currency')(potential.raise.value);
    const potentialCalcMethod = potential.budget === 0 ? this.$filter('currency')(potential.direct.total) : potentialRaise.replace('.', ',');

    return `
      ${potential.desc} |
      ${this.$filter('currency')(potential.brutto)} |
      StKl. ${potential.pap_stkl} |
      ${potentialCalcMethod}  |
      ${potential.employee} MA
    `;
  }

  createIterator(dataCollection = this.$scope.calculatedData.data) {
    let idx = -1;

    return {
      next: () => {
        const done = idx >= dataCollection.length - 1;
        const value = !done ? dataCollection[++idx] : undefined;

        return {
          done,
          value
        };
      },
      prev: () => {
        --idx;

        const done = idx === -1;
        const value = !done ? dataCollection[idx] : undefined;

        return {
          done,
          value
        };
      }
    };
  }

  nextGroup() {
    const groupData = this.$scope.iteratorData.next();

    if (!groupData.done) {
      this.$scope.calculatedData = groupData.value;
    }
  }

  prevGroup() {
    const groupData = this.$scope.iteratorData.prev();

    if (!groupData.done) {
      this.$scope.calculatedData = groupData.value;
    }
  }

  findItemInCollection(id, collection) {
    return collection.find(item => item.value === id).display;
  }

  editPotential(potential, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    let data = {
      potential: typeof potential === 'number' ? angular.copy(this.$scope.potentials[potential]) : potential,
      steuerklassen: this.$scope.steuerklassen,
      pension_insurances: this.$scope.pension_insurances,
      health_insurances: this.$scope.health_insurances,
      care_insurances: this.$scope.care_insurances,
      child_tax_credits: this.$scope.child_tax_credits,
      budget_types: BUDGET_TYPES,
      budget_types_by_name: BUDGET_TYPES_BY_NAME,
      raise_types: RAISE_TYPES,
      direct_budget_fields: DIRECT_BUDGET_FIELDS,
      goal_budget_fields: GOAL_BUDGET_FIELDS,
      church_taxes: CHURCH_TAX_TYPES,
      onFinish: this.finishedWizard,
      onCancel: this.cancelledWizard,
      forms: this.forms,
      available_years: [{
        display: '2022',
        value: 2022
      }],
      calculation_types: CALCULATION_TYPES
    };

    let dialog = this.dialogService.ngDialog.open({
      template: editPotentialDialog2_html(),
      plain: true,
      data: data,
      scope: this.$scope,
      controller: 'EditPotentialDialogController',
      controllerAs: '$ctrl',
      width: 800,
      closeByEscape: true,
      closeByDocument: true,
      closeByNavigation: true,
      preCloseCallback: () => {
        const isSalaryValid = data.potential.brutto && data.potential.brutto > 0;
        const isEmployeesValid = !(!data.potential.employee) && data.potential.employee > 0;
        const isPrivateValid = (data.potential.health_insurance !== HEALTH_INSURANCE[0].value && data.potential.monthly_contribution > 0) || data.potential.health_insurance === HEALTH_INSURANCE[0].value;
        const isContributionValid = data.potential.additional_rate_health_insurance >= 0 && data.potential.additional_rate_health_insurance < 5;

        const forms = Object.keys(data.forms);
        let areFormsValid = true;
        for (let i = 0; i < forms.length; i++) {
          areFormsValid = areFormsValid && data.forms[forms[i]].$valid;
        }

        // console.log('isSalartValid: ', isSalaryValid, '\tisEmployeesValid: ', isEmployeesValid, '\tisPrivateValid: ', isPrivateValid, '\tisContributionValid: ', isContributionValid);


        return isSalaryValid && isEmployeesValid && isPrivateValid && isContributionValid && areFormsValid;
      }
    });

    return dialog.closePromise
      .then(() => {
        if (typeof potential === 'number') {
          this.$scope.potentials[potential] = angular.copy(data.potential);
        } else if (typeof potential === 'object') {
          this.$scope.potentials.unshift(angular.copy(data.potential));
        }
        this.showCalcForm();
      });
  }

  removePotential(potentialIndex) {
    this.$scope.potentials.splice(potentialIndex, 1);
  }

  finishedWizard() {
    console.log('finishedWizard controller');
  }

  cancelledWizard() {
    console.log('cancelledWizard controller');
  }
}

DatevController.$inject = $inject;
