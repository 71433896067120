import { BonusComponentController } from './BonusComponentController';

const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];

export class BonusComponentControllerPC extends BonusComponentController {
  constructor(
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {

    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    this.loading.child = true;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
    this.isPcBlocked = true;

  }

  async addComponentToEmployeeBonusComponents(move_to_next_step = true) {
    return super.addComponentToEmployeeBonusComponents(false).then(async (result) => {
      if (!result) {
        return;
      }

      this.enterShop();
    });
  }

  calculateMppBonusLimits() {
    return this.BonusService.calculatePCLeasing(this.employee_id, this.bonus_project, this.bonus_id, this.customer_id);
  }


  canContinue() {
    return super.canContinue() && this.can_enter_shop && !this.isPcBlocked;
  }

  getLimits() {
    return this.calculateMppBonusLimits()
      .then(bonus_limits => {
        this.bonus_limits = bonus_limits;
        this.can_enter_shop = this.bonus_limits.is_user_rate_valid || this.bonus_limits.has_bbg;
      });
  }

  enterShop() {
    this.mpp_data = {
      bonus_limits: this.bonus_limits,
      bonus_project_id: this.bonus_project_id,
      bonus_id: this.bonus_id,
      bonus_component_name: this.bonus_component_name,
      custom_checkout_date: this.BonusService.checkout_date,
      checkout_type: this.checkout_type
    };

    this.$state.go('inApp.mpp.side.products.landing', {
      employee_bonus_checkout_config: this.employee_bonus_checkout_config,
      mpp_data: this.mpp_data
    });
  }

  async init() {
    let promises = [];

    promises.push(
      this.getLimits()
    );

    promises.push(
      this.BonusService.isOverspendingAllowed(this.employee_id).then(is_overspending_allowed => {
        this.is_overspending_allowed = is_overspending_allowed;
      })
    );

    promises.push(
      this.employeeService
        .get(this.vbmData.employeeId)
        .then(user => this.user = user)
    );

    promises.push(
      this.employeeService
        .isPcBlockedForEmployee(this.vbmData.employeeId)
        .then(isBlocked => this.isPcBlocked = isBlocked)
    );

    return Promise.all(promises);
  }
}

BonusComponentControllerPC.$inject = $inject;
