import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter, forwardRef,
  Input,
  Output, Renderer2,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, EMPTY, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'vn-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent)
    }
  ],
})
export class InputComponent implements AfterViewInit, ControlValueAccessor {

  @Input()
  public key!: string;

  @Input()
  public label = '-';

  @Input()
  public type = 'text';

  @Input()
  public rounded: boolean = false;

  @Input()
  public placeholder = '';

  @Input()
  public isDisabled = false;

  @Input()
  public autoCompleteMinChar: number = 0;

  @Input()
  public autoCompleteMaxList!: number;

  @Input()
  public autocompleteOptions!: string[];
  public filteredOptions$!: Observable<string[]>;

  @Input()
  public debounceTime = 0;

  @Input()
  disabled = false;

  @Input()
  multiLine = false;

  @Input()
  require: boolean = false;

  @ViewChild('formInput')
  formInput!: ElementRef<HTMLInputElement>;

  @Output()
  public debouncedInput$: Observable<string> = EMPTY;

  @Output()
  public onBlur = new EventEmitter();

  // needed for ControlValueAccessor
  private touched = false;

  private formControlValue!: string;
  public get value() {
    return this.formControlValue;
  }

  public onTouched = () => { };
  public onChange = (_: string) => {};

  public get withAutocomplete() {
    return !!this.autocompleteOptions && !!this.autocompleteOptions.length;
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.debouncedInput$ = fromEvent(this.formInput.nativeElement, 'keyup').pipe(
      debounceTime(this.debounceTime),
      map((event) => (event.target as HTMLInputElement).value),
      distinctUntilChanged(),
      tap((value) => this.onChange(value)),
    );

    this.filteredOptions$ = this.debouncedInput$.pipe(
      startWith(''),
      map((inputValue: string) => {
          if (!!inputValue && inputValue.length >= this.autoCompleteMinChar) {
            let filteredList = this.autocompleteOptions
              .filter(option => option.toLowerCase().includes(inputValue.toLowerCase()))
              .sort();
            if(this.autoCompleteMaxList) {
              filteredList = filteredList.slice(0, this.autoCompleteMaxList);
            }
            return filteredList;
          }
          return [];
        }
      ),
    );
  }

  public focusInput() {
    this.formInput.nativeElement.focus();
  }

  public getLabelClass() {
    return {
      'hidden': this.label === '-',
      'invisible': this.rounded
    }
  }

  public getInputClass() {
    return {
      'rounded': this.rounded
    };
  }

  public writeValue(value: string) {
    if (!this.disabled) {
      this.formControlValue = value;
      this.onChange(this.formControlValue)
    }
  }

  public registerOnChange(onChange: (_: string) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // != <tag [disabled]='true'> -> use public isDisable instead
  // excludes formControl from fromGroup.valueChanges$
  // called with new FormControl({value: 123, disabled: true}, [Validators...])
  public setDisabledState(formDisabled: boolean) {
    this.disabled = formDisabled;
    this._renderer.setAttribute(this._elementRef.nativeElement, 'disabled', this.disabled ? "true" : "");
  }

  public getInputElement(): HTMLInputElement {
    return this.formInput.nativeElement;
  }
}
