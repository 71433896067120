const $inject = [
  '$scope',
  '$state',
  'datevService'
];
export default class AdministrationDatevListController {
  constructor(
    $scope,
    $state,
    datevService
  ) {
    Object.assign(this, {
      $scope,
      $state,
      datevService
    });

    this.onFetchArticles();
  }

  onDeleteArticle(id) {
    const confirmationMessage = confirm('Are you sure you want to delete this article');

    if ( confirmationMessage ) {
      this.datevService.deleteArticle(id).then(response => {
        if ( response.raw_result.ok ) {
          this.onFetchArticles();
        }
      });
    }
  }

  onFetchArticles() {
    this.datevService.fetchArticles().then(articles => {
      this.$scope.articles = articles;
    });
  }

  isArticleVisible(item) {
    return item.showArticle;
  }
}
AdministrationDatevListController.$inject = $inject;
