import impressum_html from "../../modules/landing/impressum.pug";
angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('impressum', {
        url: '/impressum',
        template: impressum_html
      });

  }]);
