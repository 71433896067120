const $inject = [];
export default class CalculationComponentCheckoutStepValues {
  constructor() {
    this.$onInit = () => {};
  }

  handleClickNext() {
    this.onClickNext();
  }

  handleClickHeader() {
    if(this.canClickNext()){
      this.onClickHeader();
    }
  }
}
CalculationComponentCheckoutStepValues.$inject = $inject;

import html from "./CalculationComponentCheckoutStepValues.pug"

angular
  .module( 'VSPApp' )
  .component( 'checkoutValuesStep', {
    template: html(),
    transclude: {
      'advantage_form' : 'advantageForm'
    },
    bindings: {
      title: '<',
      img: '<',
      description: '<',
      onClickHeader: '&',
      onClickNext: '&',
      canClickNext: '&',
      isOpen : '<'
    },
    controller: CalculationComponentCheckoutStepValues,
    controllerAs: '$ctrl'
  });

