const $inject = [
  '$q',
  '$transitions',
  '$state',
  'customerService',
  'NotificationService'
];
export default class CockpitVersicherungenController {

  constructor(
    $q,
    $transitions,
    $state,
    customerService,
    NotificationService
  ) {
    this.customerId = $state.params.customerId;
    this.customerService = customerService;
    this.working = true;
    this.NotificationService = NotificationService;

    $q.all([
      customerService
        .listInsurances()
        .then(insurances => this.insurances = insurances),
      customerService
        .getConfiguration(this.customerId)
        .then(configuration => this.configuration = configuration)
    ])
      .then(() => this.working = false);

    customerService
      .get(this.customerId)
      .then(customer => this.customer = customer);
  }


  save() {
    this.working = true;

    this.customerService
      .setConfiguration(this.customerId, this.configuration)
      .then(() => this.NotificationService.message('Speichern erfolgreich.'),
        () => this.NotificationService.error('Speichern fehlgeschlagen.'))
      .finally(() => this.working = false);
  }

  setDefault(insuranceId) {
    this.configuration.insuranceDefault = insuranceId;
  }
}
CockpitVersicherungenController.$inject = $inject;
