import * as moment from "moment";
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/shared/models/base.component';
import { CarLeasingCustomerConfiguration } from '../../models/car-leasing-customer-configuration.model';
import { Car } from '../../models/car.model';
import { CarCatalogCostByCarId, CarLeasingApiService } from '../../services/car-leasing-api.service';
import { CarLeasingCatalogService } from '../../services/car-leasing-catalog.service';
import { map, startWith, tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { DateRange } from "src/app/shared/components/form-mixins/date-range/date-range.component";

interface FilterOptions {
  brand: string[];
  gearshift: string[];
  cartype: string[];
  fuel: string[];
  duration: string[];
  availability: DateRange; // <=> initial dates
}

interface FilterChoices {
  brand: string;
  gearshift: string;
  cartype: string;
  fuel: string;
  duration: string;
  availability: DateRange
}

@Component({
  selector: 'vn-car-leasing-catalog',
  templateUrl: './car-leasing-catalog.component.html',
  styleUrls: ['./car-leasing-catalog.component.scss'],
})
export class CarLeasingCatalogComponent extends BaseComponent implements OnInit {

  public filtersGroup!: FormGroup;
  public filterOptions!: FilterOptions;

  public get filterPlaceholder() {
    return 'Alle';
  }

  public customerId!: number;

  public customerConfiguration!: CarLeasingCustomerConfiguration

  private _cars!: Car[];
  private filteredCars$!: Observable<Car[]>

  public get cars$() {
    return this.filteredCars$;
  }

  public catalogPrices!: CarCatalogCostByCarId;

  constructor(
    @Inject('$state') private $state: any,
    @Inject('$location') private $location: any,
    @Inject('EmployeeService') private employeeService: any,
    private formBuilder: FormBuilder,
    private apiService: CarLeasingApiService,
    private catalogService: CarLeasingCatalogService,
  ) {
    super();

    this.initFiltersForm();
  }

  async ngOnInit() {
    this.setLoading$(true);

    await this.init();
    this.initFilterOptions();

    this.setLoading$(false);
  }

  private async init() {
    this.customerId = await this.employeeService.getCustomerId();

    this._cars = await this.catalogService.getCars();
    this.catalogPrices = await this.catalogService.getCatalogPrices(this._cars, this.customerId);
    this.customerConfiguration = await this.apiService.getCarLeasingCustomerConfig(this.customerId);

    this.filteredCars$ = this.filtersGroup.valueChanges.pipe(
      startWith(this.filtersGroup.value as FilterChoices),
      map((filters: FilterChoices) => this.applyCarFilters(filters)),
    );
  }

  // ===================== FILTERS =====================
  private _userBrandFilter = (car: Car, brand: string) => {
    return !brand || car.brand.id === brand;
  }

  private _userGearshiftFilter = (car: Car, gearshift: string) => {
    return !gearshift || car.gearshift === gearshift;
  }

  private _userCartypeFilter = (car: Car, cartype: string) => {
    return !cartype || car.cartype === cartype;
  }
  private _userFuelFilter = (car: Car, fuel: string) => {
    return !fuel || car.fuel === fuel;
  }

  private _userDurationFilter = (car: Car, duration: number) => {
    return !duration || car.available_terms.includes(duration);
  }

  private _userAvailabilityFilter = (car: Car, availability: DateRange) => {
    if (!availability) {
      return true;
    }

    return (
      moment(availability.startDate, 'DD.MM.YYYY').isSameOrBefore(
        moment(car.available_to, 'YYYY-MM-DD')
      ) &&
      moment(car.available_from, 'YYYY-MM-DD').isSameOrBefore(
        moment(availability.endDate, 'DD.MM.YYYY'),
      )
    )
  }

  private applyCarFilters(filters: FilterChoices): Car[] {
    this.$location.search({
      ...filters,
      availability: filters.availability && `${filters.availability.startDate}-${filters.availability.endDate}`
    });

    return this._cars
      .filter(
        car => this._userBrandFilter(car, filters.brand) &&
          this._userGearshiftFilter(car, filters.gearshift) &&
          this._userCartypeFilter(car, filters.cartype) &&
          this._userFuelFilter(car, filters.fuel) &&
          this._userDurationFilter(car, parseInt(filters.duration)) &&
          this._userAvailabilityFilter(car, filters.availability)
      )
      .sort(
        (car1: Car, car2: Car) => car1.getCataloguePrice() > car2.getCataloguePrice() ? 1 : -1
      );
  }

  private initFilterOptions() {
    this.filterOptions = {
      brand: [...new Set(this._cars.map(car => car.brand.id))],
      gearshift: [...new Set(this._cars.map(car => car.gearshift))],
      cartype: [...new Set(this._cars.map(car => car.cartype))],
      fuel: [...new Set(this._cars.map(car => car.fuel))],
      duration: [...new Set(this._cars.reduce(
        (durations, car) => durations.concat(car.available_terms),
        [] as number[]
      ))]
        .filter(
          duration => this.customerConfiguration?.durationOptions.includes(duration)
        )
        .map(String),
      availability: {
        startDate: moment.min(
          this._cars.map(
            car => moment(car.available_from)
          )
        ).format('DD.MM.YYYY'),
        endDate: moment.max(
          this._cars.map(
            car => moment(car.available_to)
          )
        ).format('DD.MM.YYYY'),
      }
    };

    this.availabilityFilter.setValue(this.filterOptions.availability);
  }

  private initFiltersForm() {
    this.filtersGroup = this.formBuilder.group({
      brand: this.formBuilder.control(undefined, []),
      gearshift: this.formBuilder.control(undefined, []),
      cartype: this.formBuilder.control(undefined, []),
      fuel: this.formBuilder.control(undefined, []),
      duration: this.formBuilder.control(undefined, []),
      availability: this.formBuilder.control(undefined, [])
    });
  }

  public get availabilityFilter() {
    return this.filtersGroup.get('availability') as FormControl;
  }

  // ===================== DISPLAY =====================

  navigateToCar(carId: string) {
    this.setLoading$(true);

    this.$state.go(
      'inApp.car-leasing.details',
      { carId }
    );

    this.setLoading$(false);
  }

}
