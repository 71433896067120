import header from '../../helpers/header.main.pug';
import cashback_main_template from '../../modules/cashback/cashback.pug';
import cashback_details_template from '../../modules/cashback/details/cashback_details.pug';
import  cashback_home_template from '../../modules/cashback/home/cashback_home.pug';


angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.cashback', {
        url: '/cashback',
        views: {
          main: {
            template: cashback_main_template
          },
          header: {
            template: header
          }
        }
      })
      .state('inApp.cashback.details', {
        url: '/details/:cashbackId',
        params: {
          cashbackId: null
        },
        views: {
          cashbackMain: {
            template: cashback_details_template,
            controller: 'CashbackDetailsController',
          }
        }
      })
      .state('inApp.cashback.home', {
        url: '/home',
        views: {
          cashbackMain: {
            template: cashback_home_template,
            controller: 'CashbackHomeController',
          }
        }
      });

  }]);
