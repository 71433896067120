import index_html from "../../modules/nextcloud/index.pug";
import header_main_html from "../../helpers/header.main.pug";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.nextcloud', {
        url: '/nextcloud',
        views: {
          main: {
            template: index_html,
          },
          header: {
            template: header_main_html
          }
        }
      });
  }]);
