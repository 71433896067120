import { CarColor, FinnAutoCarBrand, FinnAutoCarConfig, FinnAutoCarEquipment, FinnAutoCarPicture } from "src/app/car-leasing/models/car-leasing.interfaces";
import * as lodash from 'lodash'

export class Car {

  constructor(
    public id: string,
    public uid: number,
    public offer_type: string,
    public brand: FinnAutoCarBrand,
    public efficiency_image: FinnAutoCarPicture|null = null,
    public picture: FinnAutoCarPicture,
    public pictures: FinnAutoCarPicture[],
    public model: string,
    public engine: any,
    public price: any,
    public logo: any,
    public available_terms: number[],
    public color: any,
    public color_list: CarColor[],
    public config: FinnAutoCarConfig,
    public specific_color: any,
    public power: number,
    public fuel: string,
    public consumption: number,
    public co2emission: number,
    public equipment: FinnAutoCarEquipment,
    public gearshift: any,
    public cartype: any,
    public config_drive: any,
    public available_from: string,
    public available_to: string,
    public description: string,
    public equipment_line: string,
    public financing_type: string,
    public ev_range: number,
    public has_hitch: string,
    public tires: string,
    public seats: number,
    public doors: number,
    public model_year: number,
    // public delivery_options: string[] = []
  ) {

  }


  public getKw(): number {
    let num = (this.power * 1.35962)
    return Math.ceil(parseFloat(num.toFixed(2)))
  }

  public getMaxTerm(): number {
    return Math.max(...this.available_terms)
  }

  public getExtraKms(): { km: number, price: number }[] {

    let priceKms = Object.keys(this.price).filter((key: string) => {
      return key.includes('extra') && key !== 'extra_km_price'
    }).map((key: string) => {
      let km = parseInt(key.split('_')[1]);
      return {
        km,
        price: this.price[key]
      }
    })
    return lodash.orderBy(priceKms, 'km');
  }

  public getMinKm(): number {
    return Math.min(...this.getExtraKms().map(obj => obj.km));
  }

  public getBasePrice(contractDuration: number = 0, toReturnNetto = false): number {
    let prefix = 'b2c_';
    if (toReturnNetto) {
      prefix = 'b2b_';
    }
    let basePriceKey = !contractDuration ? prefix + this.getMaxTerm() : prefix + contractDuration;
    return this.price.available_price_list[basePriceKey.toString()];
  }

  public getMaxKms(): number {
    return this.getMinKm() * this.getMaxTerm()
  }

  public getMinExtraPrice(): number {
    let minValueKey = 'extra_' + this.getMinKm();
    return this.price[minValueKey];
  }

  public getCataloguePrice(): number {
    let maxtermPrice = this.price.available_price_list['b2c_' + this.getMaxTerm()];
    let minKmPrice = this.price['extra_' + this.getMinKm()];
    return maxtermPrice + minKmPrice;
  }

  public getConsumptionUnit() {
    return this.fuel === 'Benzin' || this.fuel === 'Diesel' ?
      'l' :
      'kWh'
  }

  public getTires() {
    const tiresFactory: any = {
      'all_season': 'Ganzjahresreifen',
      'summer_winter': 'Sommer-/Winterreifen',
      'all_season, summer_winter': '',
      'summer_winter, all_season': ''
    };
    if (!(this.tires in tiresFactory)) {
      console.error(this.tires + ' not supported!');
      return '';
    }
    return tiresFactory[this.tires];
  }

  public getHitch() {
    return this.has_hitch === 'true'?
      'mit' :
      'ohne'
  }

  public getCo2Emission() {
    return this.co2emission === null?
      '0' :
      this.co2emission
  }
}
