const $injectCidaasLoginController = [
  '$scope',
  '$state',
  'Made',
  '$timeout',
  'VSP_CONSTANTS'
];
export default class CidaasLoginController {

  constructor(
    $scope,
    $state,
    Made,
    $timeout,
    VSP_CONSTANTS
  ) {

    Object.assign(this, {
      $scope,
      $state,
      Made,
      $timeout,
      VSP_CONSTANTS
    });

    this.loading = {
      main: false
    };

    if ($scope.$resolve.$stateParams.goToHome) {
      this.go_home();
    }

    this.connectivity = Made.wss && (WebSocket.OPEN === Made.wss.readyState);
    this.connectivityTimeout = false;

    let connectionOpen = () => {

        $timeout.cancel(this.connectivityTimeout);
        this.connectivityTimeout = false;

        this.$scope.$applyAsync(() => this.connectivity = true);
      },
      connectionClose = () => {

        if (!this.connectivityTimeout) {
          this.connectivityTimeout = $timeout(() => this.connectivity = false, 5000);
        }
      };

    Made.on('socket:open', connectionOpen);
    Made.on('socket:close', connectionClose);

    $scope.$on('$destroy', () => {
      Made.off('socket:open', connectionOpen);
      Made.off('socket:close', connectionClose);
    });

    this.showEmployeeVideo = true;
    let subdomain = location.hostname.split('.')[0];
    if (subdomain == 'fiduciagad') {
      this.showEmployeeVideo = false;
    }
  }

  go_home() {
    window.location.href = this.Made.user.home_url;
  }
}

CidaasLoginController.$inject = $injectCidaasLoginController;
