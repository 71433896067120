const $inject = [
  '$scope'
]
export class CarLeasingController {
  constructor($scope)
  {
    Object.assign(this, {
      $scope
    });
  }

}

CarLeasingController.$inject = $inject;


