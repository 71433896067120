import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';


@Component({
  selector: 'vn-direct-bonus-confirm-dialog',
  templateUrl: './direct-bonus-confirm-dialog.component.html',
  styleUrls: ['./direct-bonus-confirm-dialog.component.scss']
})
export class DirectBonusConfirmDialogComponent implements OnInit {
  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public get period(): string {
    return this.dialogData.period!
  }

  constructor(
    public dialogRef: MatDialogRef<DirectBonusConfirmDialogComponent>,
    private directBonusService: DirectBonusService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData
  ) {
  }

  ngOnInit(): void {
  }

  public confirmOrder() {
    this.directBonusService.confirmCustomerOrder(this.orderId)
      .subscribe(
        () => this.dialogRef.close(true)
      );
  }
}
