const $inject = [
  '$scope', '$timeout', 'userService', 'FilenameService', 'fileService'
];

export class FileChooserDialogController {
  constructor($scope, $timeout, userService, FilenameService, fileService) {
    Object.assign(this, {
      $scope,
      $timeout,
      userService,
      FilenameService,
      fileService
    });

    this.loading = {
      main: false
    };
    this.init();
  }

  accept() {
    // note selected files
    let selected_files = [];
    let deselected_files_id = new Set();
    Object.values(this.models.file_selection).forEach(file_selection_configuration => {
      if (file_selection_configuration.selected) {
        selected_files.push(file_selection_configuration.file);
      } else {
        deselected_files_id.add(file_selection_configuration.file._id);
      }
    });

    // remove deselected
    let deselected_indexes = [];
    for (let i = 0; i < this.outbound_files.length; i++) {
      if (deselected_files_id.has(this.outbound_files[i]['_id'])) {
        deselected_indexes.push(i);
      }
    }

    while (deselected_indexes.length) {
      this.outbound_files.splice(deselected_indexes.pop(), 1);
    }

    // add selected
    this.outbound_files.push(...selected_files);

    // close dialog
    this.$scope.confirm({});
  }

  hasFiles() {
    if (this.models) {
      return Object.keys(this.models.file_selection).length !== 0;
    } else {
      return false;
    }
  }

  getFileName(file) {
    return this.FilenameService.getFileName(file);
  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }

  close() {
    // close dialog
    this.$scope.confirm({});
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    //
    this.filter_tags = this.$scope.ngDialogData.filter_tags;
    this.outbound_files = this.$scope.ngDialogData.outbound_files;
    let preselected_files_ids_set = new Set(this.outbound_files.map(file => file._id));
    this.employee_id = this.$scope.ngDialogData.employee_id;

    // get user files

    let user_files = await this.userService.getUploadedFiles(undefined, {
      valuenet_id: this.employee_id,
      tags: this.filter_tags
    });

    let file_selection_model = {};

    user_files.forEach((file) => {
      let is_preselected = preselected_files_ids_set.has(file._id);
      file_selection_model[file._id] = {
        selected: is_preselected,
        file: file
      };
    });

    this.models = {
      file_selection: file_selection_model
    };

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

}

FileChooserDialogController.$inject = $inject;

angular
  .module('VSPApp')
  .controller('FileChooserDialogController', FileChooserDialogController);
