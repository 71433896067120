const $inject = [
  'quizFactory',
  'componentsService'
];
export default class bausteineQuiz {
  constructor(
    quizFactory,
    componentsService
  ) {

    this.quizFactory = quizFactory;
    this.componentsService = componentsService;


    this.description = 'Damit wir Ihnen eine optimale Bonus-Empfehlung geben können, beantworten Sie uns bitte ein paar Fragen';

    this.steps = [{
      id: 'question1',
      title: 'Nutzen Sie privat Internet?',
      key: 'internet',
      options: [{
        label: 'Ja',
        value: 1
      }, {
        label: 'Nein',
        value: 0
      }]
    }, {
      id: 'question2',
      title: 'Wie lang ist Ihr Arbeitsweg?',
      key: 'entfernung',
      options: []
    }, {
      id: 'question3',
      title: 'Haben Sie Aufwände für Kinderbetreuung im nicht schulfähigen Alter?',
      key: 'kinder',
      options: [{
        label: 'Ja',
        value: 1
      }, {
        label: 'Nein',
        value: 0
      }]
    }, {
      id: 'question4',
      title: 'Haben Sie ein privates Fahrzeug? (ausgenommen Dienstwagen)',
      key: 'werbeflaeche',
      options: [{
        label: 'Ja',
        value: 1
      }, {
        label: 'Nein',
        value: 0
      }]
    }, {
      id: 'question5',
      title: 'Planen Sie in den nächsten 3 Jahren ein Fahrrad zu kaufen?',
      key: 'EBike',
      options: [{
        label: 'Ja',
        value: 1
      }, {
        label: 'Nein',
        value: 0
      }]
    }, {
      id: 'question6',
      title: 'Planen Sie in den nächsten 2 Jahren einen PC oder ein Smartphone zu kaufen?',
      key: 'pc',
      options: [{
        label: 'Ja',
        value: 1
      }, {
        label: 'Nein',
        value: 0
      }]
    }];

    this.bausteineQuiz = new quizFactory(this.steps, this.description);

    this.bausteineQuiz.exit = function () {

      if (!componentsService.blacklist) componentsService.blacklist = [];

      angular.forEach(this.model, function (value, key) {
        //console.log( value, key );
        if (!value) {
          componentsService.blacklist.push({baustein: key});
        }
      });

      componentsService.filter();

      return true;
    };
  }

  get() {
    return this.bausteineQuiz;
  }
}
bausteineQuiz.$inject = $inject;
