import {ERHOLUNGSBEIHILFE_OPTIONS} from "./erholungsbeihilfe_options";

export const EINMAL_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS = {
  budget: [
    {
      label: 'Sachbezug Vorteilskonto',
      prop: 'sachbezug',
      is_visible: true,
      settings: [
        {
          label: 'Mindestwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-employeeBenefit',
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-employeeBenefit',
        },
        {
          label: 'Mindestlaufzeit',
          key: 'minimum_months_period',
          attributes: {currency: {to_format: false}},
          type: 'input',
          id: 2,
          cyData: 'vn.cockpit.bonus.createProjectDialog.minimum_months_period-sachbezugProfit'
        }
      ]
    },
    {
      label: 'Sachbezug ProFit',
      prop: 'sachbezug_profit',
      is_visible: true,
      settings: [
        {
          label: 'Mindestwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-employeeBenefit',
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-employeeBenefit',
        },
        {
          label: 'Mindestlaufzeit',
          key: 'minimum_months_period',
          attributes: {currency: {to_format: false}},
          type: 'input',
          id: 2,
          cyData: 'vn.cockpit.bonus.createProjectDialog.minimum_months_period-sachbezugProfit'
        }
      ]
    },
    {
      label: 'Sachbezug Pluxee',
      prop: 'sachbezug_pluxee',
      is_visible: true,
      settings: [
        {
          label: 'Mindestwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-employeeBenefit',
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-employeeBenefit',
        },
        {
          label: 'Mindestlaufzeit',
          key: 'minimum_months_period',
          attributes: {currency: {to_format: false}},
          type: 'input',
          id: 2,
          cyData: 'vn.cockpit.bonus.createProjectDialog.minimum_months_period-sachbezugPluxee'
        }
      ]
    },
    {
      label: 'Essensschecks PST, Stück',
      prop: 'essensschecks_pst',
      is_visible: true,
      settings: [
        {
          label: 'Standardstückzahl',
          key: 'value',
          attributes: {currency: {to_format: false}},
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardQuantity-foodVoucher$',
        },
        {
          label: 'Preis per Stück',
          key: 'per_item_price',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputPricePerUnit-foodVoucher$',
        },
        {
          label: 'Maximale Stückzahl',
          key: 'max_eq',
          parent: '.range',
          attributes: {currency: {to_format: false}},
          id: 2,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalQuantity-foodVoucher$',
        },
        {
          label: 'Flat Tax',
          key: 'is_enabled',
          parent: '.additional_costs.flat_tax',
          id: 4,
          type: 'checkbox',
          settings: {is_editable: false}
        },
        {
          label: 'Tax Payer',
          key: 'payer',
          parent: '.additional_costs.flat_tax',
          id: 4,
          type: 'select',
          options: {key: 'TAX_PAYERS'},
          cyData: 'vn.cockpit.bonus.createProjectDialog.selectTaxPayer-foodVoucher$',
        }
      ]
    },
    {
      label: 'Essensschecks frei, Stück',
      prop: 'essensschecks_frei',
      is_visible: true,
      settings: [
        {
          label: 'Standardstückzahl',
          key: 'value',
          attributes: {currency: {to_format: false}},
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardQuantity-foodVoucher'
        },
        {
          label: 'Preis per Stück',
          key: 'per_item_price',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputPricePerUnit-foodVoucher'
        },
        {
          label: 'Maximale Stückzahl',
          key: 'max_eq',
          parent: '.range',
          attributes: {currency: {to_format: false}},
          id: 2,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalQuantity-foodVoucher'
        }
      ]
    },
    {
      label: 'Mobiltelefon',
      prop: 'handy',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-mobilePhone'
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-mobilePhone'
        }
      ]
    },
    {
      label: 'PC-Leasing, Wert',
      prop: 'pc',
      is_visible: false,
      settings: []
    },
    {
      label: 'Internetpauschale PST, EUR',
      prop: 'internet',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-internetAllowance'
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-internetAllowance'
        },
        {
          label: 'Flat Tax',
          key: 'is_enabled',
          parent: '.additional_costs.flat_tax',
          id: 4,
          type: 'checkbox',
          settings: {is_editable: false}
        },
        {
          label: 'Tax Payer',
          key: 'payer',
          parent: '.additional_costs.flat_tax',
          id: 4,
          type: 'select',
          options: {key: 'TAX_PAYERS'},
          cyData: 'vn.cockpit.bonus.createProjectDialog.selectTaxPayer-internetAllowance'
        }
      ]
    },
    {
      label: 'Entfernungspauschale PST, EUR',
      prop: 'entfernung',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-commutingAllowance'
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-commutingAllowance'
        },
        {
          label: 'Flat Tax',
          key: 'is_enabled',
          parent: '.additional_costs.flat_tax',
          id: 4,
          type: 'checkbox',
          settings: {is_editable: false}
        },
        {
          label: 'Tax Payer',
          key: 'payer',
          parent: '.additional_costs.flat_tax',
          id: 4,
          type: 'select',
          options: {key: 'TAX_PAYERS'},
          cyData: 'vn.cockpit.bonus.createProjectDialog.selectTaxPayer-commutingAllowance'
        }
      ]
    },
    {
      label: 'Erholungsbeihilfe PST, EUR p.a.',
      prop: 'erholungsbeihilfe',
      is_visible: true,
      settings: [...ERHOLUNGSBEIHILFE_OPTIONS.map((option, index) => {
        return {
          label: option.label,
          key: option.id,
          parent: '.options',
          id: index,
          cyData: option.cyData
        };
      }),
        {
          label: 'Start date',
          type: 'datetime',
          key: 'bonus_start_date',
          parent: '.configuration.dates',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputBeginDate-vacationGrant'
        },
        {
          label: 'End date',
          type: 'datetime',
          key: 'bonus_end_date',
          parent: '.configuration.dates',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length + 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputEndDate-vacationGrant'
        },
        {
          label: 'Decision date',
          type: 'datetime',
          key: 'user_decision_date',
          parent: '.configuration.dates',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length + 2,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputDecisionDate-vacationGrant'
        },
        {
          label: 'Flat Tax',
          key: 'is_enabled',
          parent: '.additional_costs.flat_tax',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length + 3,
          type: 'checkbox',
          settings: {is_editable: false}
        },
        {
          label: 'Tax Payer',
          key: 'payer',
          parent: '.additional_costs.flat_tax',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length + 4,
          type: 'select',
          options: {key: 'TAX_PAYERS'},
          cyData: 'vn.cockpit.bonus.createProjectDialog.selectTaxPayer-vacationGrant'
        }
      ]
    },
    {
      label: 'BGM Budget',
      prop: 'bgmbudget',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Jobticket',
      prop: 'jobticket',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Elektronikgeschenke',
      prop: 'egeschenk',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Bikeleasing',
      prop: 'bike_1',
      is_visible: true,
      settings: [
        // {
        //   label: 'Standardwert',
        //   key: 'value',
        //   id: 0
        // },
        // {
        //   label: 'Maximalwert',
        //   key: 'max_eq',
        //   parent: '.range'
        // }
      ]
    },
    {
      label: 'Kindergartenzuschuss',
      prop: 'kinder',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Jobrad',
      prop: 'EBike',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        // {
        //   label: 'Maximalwert',
        //   key: 'max_eq',
        //   parent: '.range'
        // }
      ]
    },
    {
      label: 'Dienstwagengarage (Eigentum)',
      prop: 'dienstwagen_miete',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Dienstwagengarage (gemietet)',
      prop: 'dienstwagen_auslage',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Personalrabatt',
      prop: 'personal_rabatt',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Verpflegungsmehraufwand (st- u. sv-frei)',
      prop: 'VMA_STSVFREI',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Verpflegungsmehraufwand (pSt.)',
      prop: 'VMA_PST',
      is_visible: false,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1
        }
      ]
    },
    {
      label: 'Festnetztelefon',
      prop: 'festnetz',
      is_visible: true,
      settings: [
        {
          label: 'Standardwert',
          key: 'value',
          id: 0,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputStandardValue-landlinePhone'
        },
        {
          label: 'Maximalwert',
          key: 'max_eq',
          parent: '.range',
          id: 1,
          cyData: 'vn.cockpit.bonus.createProjectDialog.inputMaximalValue-landlinePhone'
        }
      ]
    },
    {
      label: 'Bikeleasing',
      prop: 'Bikeleasing',
      is_visible: false,
      settings: [
        // {
        //   label: 'Standardwert',
        //   key: 'value',
        //   id: 0
        // },
        // {
        //   label: 'Maximalwert',
        //   key: 'max_eq',
        //   parent: '.range'
        // }
      ]
    }
  ],
  metadata: [
    {
      id: 0,
      type: 'checkbox',
      parent: 'documentation',
      key: 'is_enabled',
      title: 'Dokumentation erforderlich',
      value: {default: true},
      settings: {
        is_editable: false
      }
    },
    {
      id: 1,
      type: 'checkbox',
      parent: 'quiz',
      key: 'is_enabled',
      title: 'Quiz',
      value: {default: false},
      settings: {
        is_editable: true
      }
    },
    {
      id: 2,
      type: 'select',
      parent: 'bonus_config',
      key: 'expiration_action',
      title: 'Bonus verfällt',
      options: {key: 'BONUS_EXPIRATION_ACTIONS'},
      settings: {
        is_editable: false
      }
    },
    {
      id: 3,
      type: 'select',
      parent: 'bonus_config',
      key: 'bonus_type',
      title: 'Bonus Freiwillig',
      options: {key: 'BONUS_TYPES'},
      settings: {
        is_editable: false
      }
    },
    {
      id: 4,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_project_start_date',
      title: 'Beginn',
      settings: {
        date: {
          min: {
            key: 'today'
          }
        }
      },
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputProjectBeginDate',
    },
    {
      id: 5,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_project_end_date',
      title: 'Ende',
      settings: {
        date: {
          min: {
            key: 'bonus_project.dates.bonus_project_start_date'
          }
        }
      },
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputProjectEndDate',
    },
    {
      id: 6,
      type: 'datetime',
      parent: 'dates',
      key: 'user_decision_date',
      title: 'Entscheidung Nutzung bis',
      cyData: 'vn.cockpit.bonus.createProjectDialog.inputUserDecisionDate',
      settings: {
        is_required: false,
        date: {
          max: {
            key: 'dates.bonus_project_start_date'
          }
        }
      }
    },
    {
      id: 7,
      type: 'datetime',
      parent: 'dates',
      key: 'bonus_publish_date',
      title: 'Bonus Veröffentlichung zum',
      settings: {
        is_visible: false,
        is_editable: false,
        equals: {
          key: 'dates.bonus_project_start_date'
        }
      }
    },
    {
      id: 8,
      type: 'checkbox',
      parent: 'project_approved',
      key: 'is_enabled',
      title: 'Projekt Freigegeben',
      value: {default: false},
      cyData: 'vn.cockpit.bonus.createProjectDialog.checkWithReleased',
      settings: {
        is_visible: true,
        is_editable: val => val
      }
    },
  ],
  notifications: [
    {
      id: 0,
      key: 'welcome_notification',
      parent: 'notifications',
      bonus_notification_type: 'welcome_notification',
      bonus_project_type: 4,
      title: 'Welcome Notification',
      text: 'Ihnen steht noch ein verfügbares Budget von ${restbonus} zur Verfügung.',
      bonus_type: 'einmal_bonus'
    },
    {
      id: 1,
      key: 'standard_notification',
      parent: 'notifications',
      bonus_notification_type: 'standard_notification',
      bonus_project_type: 4,
      title: 'Standard Notification',
      text: 'Ihnen steht noch ein verfügbares Budget von ${restbonus} zur Verfügung.',
      bonus_type: 'einmal_bonus'
    }
  ]
};
