<h4>
    Übersicht
</h4>

<ng-container *ngIf="!!resolvedData">
    <mat-list role="list">
        <mat-list-item>
            <span>
                Guthaben
            </span>

            <span class='spacer'></span>

          <span class="basket-sum">
               {{ ((userBalance$ | async) || 0) | number:'0.2-2' }} €
            </span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <ng-container *ngIf="!!userChecks && showNoCodeError()">
            <mat-list-item>
                <mat-error>
                    User hat noch keinen UV-Code.
                </mat-error>
            </mat-list-item>

            <mat-divider></mat-divider>
        </ng-container>

        <ng-container *ngIf="!!userChecks && showInvalidCodeError()">
            <mat-list-item>
                <mat-error>
                    User hat einen falschen UV-Code.
                </mat-error>
            </mat-list-item>

            <mat-divider></mat-divider>
        </ng-container>

        <ng-container *ngIf="!!userChecks && showBenefitsAccountError()">
            <mat-list-item>
                <mat-error>
                    User hat noch kein Vorteilskonto aktiviert.
                </mat-error>
            </mat-list-item>

            <mat-divider></mat-divider>
        </ng-container>

        <ng-container *ngIf="!!userChecks && showBonagoSchemeError()">
            <mat-list-item>
                <mat-error>
                    Das ausgewählte Schema {{ userChecks.userCodeSchemeId }}
                    existiert nicht.
                </mat-error>
            </mat-list-item>

            <mat-divider></mat-divider>
        </ng-container>

        <ng-container *ngIf="formOptions.scheme | async as schemeOptions">
            <form [formGroup]="summaryForm"
                  *ngIf="!!userChecks && !!schemeOptions.length && showBonagoSchemeSelection()">
                <mat-list-item class='select-region'>
                    <vn-select formControlName='scheme'
                               [withPlaceholder]="false"
                               width="250px"
                               label="Gutscheinauswahl ändern"
                               [options]='schemeOptions'>
                    </vn-select>
                </mat-list-item>
                <mat-divider></mat-divider>
            </form>


        </ng-container>

        <ng-container *ngIf="basketList$ | async as basketList">
            <ng-container *ngIf="isBasketEmpty(basketList); else basketListItems">
                <mat-list-item>
                    <span class="red">
                        Keine Produkte im Warenkorb.
                    </span>
                </mat-list-item>
            </ng-container>

            <ng-template #basketListItems>
                <div *ngIf="!selectedVouchersAvailable" id="alert" class="not-available">Die Gutscheine sind derzeit nicht verfügbar.</div>
                <mat-list-item *ngFor="let basketListItem of basketList; let idx = index"
                    [ngClass]="!basketListItem.isAvailable ? 'not-available' : ''">

                    <span class="voucher-info"
                          (click)="openRetailerDetails(basketListItem.retailerId)">
                        {{ basketListItem.voucherSheetInformation }}
                    </span>

                    <span class='spacer'></span>

                    <span class="red">
                        {{ ( basketListItem.totalValue || 0) | number:'0.2-2'  }} €
                    </span>

                    <mat-icon class="remove-icon"
                              (click)="removeBasketItem(idx)">
                        remove_circle_outline
                    </mat-icon>
                </mat-list-item>
            </ng-template>
        </ng-container>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>
                Rest
            </span>

            <span class='spacer'></span>

            <span>
                {{ (restBalance || 0) | number:'0.2-2' }} €
            </span>
        </mat-list-item>

        <mat-list-item *ngIf="restBalance < 0">
            <mat-error>
                Ihr aktuelles Guthaben ist leider nicht ausreichend.
            </mat-error>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item *ngIf="employeeAddress$ | async as employeeAddress"
                       class='checkout-button-item'>
            <button mat-button
                    data-cy="benefit.bonago.preCheckout.bottomSheet.buttonStartCheckout"
                    [disabled]="!canCheckout()"
                    (click)="startCheckout(employeeAddress)"
                    class='checkout-button'>
                Einlösen
            </button>
        </mat-list-item>
    </mat-list>
</ng-container>
