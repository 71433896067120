import {Component, Inject, OnInit} from '@angular/core';
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import {AlertService} from "../../../../shared/services/alert.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BulkDialogData} from "../../../models/direct-bonus-table-dialog-data.model";
import {take} from "rxjs/operators";
import {LoadingService} from "../../../../shared/services/loading.service";

@Component({
  selector: 'vn-direct-bonus-bulk-done-dialog',
  templateUrl: './direct-bonus-bulk-done-dialog.component.html',
  styleUrls: ['./direct-bonus-bulk-done-dialog.component.scss']
})
export class DirectBonusBulkDoneDialogComponent implements OnInit {

  constructor(
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private loader: LoadingService,
    public dialogRef: MatDialogRef<DirectBonusBulkDoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: BulkDialogData,
  ) { }

  ngOnInit(): void {
  }

  bulkMarkAsDone() {
    this.loader.show();

    this.directBonusService.bulkMarkAsDone(this.dialogData.orderFilters)
      .pipe(take(1))
      .subscribe(
        (r) => {
          this.loader.hide();
          this.alertService.message('Erfolgreich');
          this.dialogRef.close(true);
        },
        () => {
          this.loader.hide();
          this.alertService.error('Fehlgeschlagen');
        }
      )
  }


}
