const $inject = [
  '$scope',
  'employeeService',
  '$timeout',
  'VSP_CONSTANTS',
  'NotificationService',
  'authenticationService'
];
export default class ChangeEmployeeStateDialogueController {
  constructor(
    $scope,
    employeeService,
    $timeout,
    VSP_CONSTANTS,
    NotificationService,
    authenticationService
  ) {

    Object.assign(this, {
      $scope,
      employeeService,
      $timeout,
      VSP_CONSTANTS,
      NotificationService,
      authenticationService
    });
    this.$timeout(() => {
      this.loading = {
        main: true,
        saving: false
      };
    }, 0);


    this.init()
      .then(() => {
        this.$timeout(() => {
          this.loading.main = false;
        }, 0);
      });
  }

  async init() {
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.EMPLOYEE_STATE_ARRAY = Object.values(this.VSP_CONSTANTS.EMPLOYEE_STATE);
    this.EMPLOYEE_STATES_SELECT_OPTIONS = this.EMPLOYEE_STATE_ARRAY.filter(state => state.name !== this.VSP_CONSTANTS.EMPLOYEE_STATE_BY_NAME['waiting_for_invite']['name']);
    this.employee_id = this.$scope.ngDialogData.employee_id;

    this.models = {
      employee_state: {
        state_name: undefined,
        comment: ''
      }
    };

    this.employee = await this.employeeService.get(this.employee_id, Date.now());
    this.models.employee_state = {...this.employee.state};
    if (this.models.employee_state.state_name === this.VSP_CONSTANTS.EMPLOYEE_STATE_BY_NAME['waiting_for_invite']['name']) {
      this.models.employee_state.state_name = this.VSP_CONSTANTS.EMPLOYEE_STATE_BY_NAME['active']['name'];
    }
  }


  close() {
    this.$scope.closeThisDialog();
  }

  async updateEmployeeState() {
    try {
      this.loading.saving = true;
      await this.employeeService.updateEmployeeState(this.employee_id, this.models.employee_state);
      this.loading.saving = false;
      this.NotificationService.message('Die Änderungen wurden gespeichert');
      this.$scope.confirm();

    } catch (e) {

      this.NotificationService.error(e);
      this.loading.saving = false;
      this.$scope.closeThisDialog();
    }

  }
}

ChangeEmployeeStateDialogueController.$inject = $inject;
