import {BonagoScheme} from '../../../../benefit/bonago/benefit_bonago.service'

const $inject = [
  '$scope', 'BenefitBonagoService', '$timeout', 'NotificationService'
];
export default class BonagoSchemeConfigureDialogController {
  constructor($scope, BenefitBonagoService, $timeout, NotificationService) {
    Object.assign(this, {
      $scope, BenefitBonagoService, $timeout, NotificationService
    });

    this.scheme = this.$scope.ngDialogData.scheme;
    this.is_new = !this.scheme;

    if (this.is_new) {
      this.scheme = new BonagoScheme();
    }

    this.forms = {};
    this.loading = {
      saving: false
    };
  }

  canSave() {
    return Object.keys(this.forms).reduce((acc, form_name) => {
      acc = acc && this.forms[form_name].$valid;
      return acc;
    }, true);
  }

  save() {
    this.loading.saving = true;
    this.BenefitBonagoService.saveScheme(this.scheme).then((result) => {
      if (result) {
        this.$scope.confirm();
      }
    }).catch((e) => {
      this.NotificationService.error(e.error.message);
    }).finally(() => {
      this.$timeout(() => {
        this.loading.saving = false;
      });
    });
  }
}
BonagoSchemeConfigureDialogController.$inject = $inject;
