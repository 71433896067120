angular
  .module('VSPApp')
  .service('ModulesService', ['Made', function (Made) {

    var modulesService = {
      modules: [{
        name: 'Cockpit',
        icon: 'cockpit',
        type: 'type3',
        state: 'inApp.cockpit',
        groups: ['vsp', 'admin']
      },
        {
          name: 'Landingpage',
          icon: 'plus',
          type: 'type3',
          state: 'landing',
          groups: ['vsp', 'admin']
        },
        {
          name: 'Shop',
          icon: 'shopping',
          type: 'type3',
          state: 'inApp.shop.produkte.home',
          groups: ['vsp', 'admin']
        },
        {
          name: 'Entgeltoptimierung',
          icon: 'plus',
          type: 'type2',
          state: 'inApp.neo.side.berater',
          groups: ['vsp', 'admin']
        },
        {
          name: 'VCP Zentrale',
          icon: 'creditcard',
          type: 'type1',
          state: 'inApp.vcp.side.list.zentrale',
          groups: ['admin', 'vcp_central']
        },
        {
          name: 'Administration',
          icon: 'creditcard',
          type: 'type1',
          state: 'inApp.vcp.side.list.admin',
          groups: ['vcp_administration', 'admin']
        },
        {
          name: 'VCP Standort',
          icon: 'creditcard',
          type: 'type1',
          state: 'inApp.vcp.side.list.location',
          groups: ['vcp_location', 'admin']
        },
        {
          name: 'Steuerfreie Extras',
          icon: 'verguetung',
          type: 'type1',
          state: 'inApp.neo.side.verguetung',
          groups: ['vsp', 'admin']
        },
        {
          name: 'Meine Akte',
          icon: 'user',
          type: 'type1',
          state: 'inApp.meineakte.uebersicht',
          groups: ['vsp', 'admin']
        },
        {
          name: 'Dashboard AN',
          icon: 'statistics',
          type: 'type1',
          state: 'inApp.dashboardArbeitnehmer',
          groups: ['vsp', 'admin']
        },
        {
          name: 'cashback',
          icon: 'cashback',
          type: 'type1',
          state: 'inApp.cashback.home',
          groups: ['vsp', 'admin']
        },
        {
          name: 'settings',
          icon: 'settings',
          type: 'type1',
          state: 'inApp.settings',
          groups: ['vsp', 'admin']
        }]
    };

    modulesService.getUserModules = function () {
      var i;

      return this.modules.filter(function (module) {
        for (i = Made.user.groups.length - 1; i >= 0; i--) {
          if (-1 !== module.groups.indexOf(Made.user.groups[i])) {
            return true;
          }
        }

        return false;
      });
    };

    return modulesService;

  }]);
