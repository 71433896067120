import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'vn-toggle-pc-lease-blocking-dialog',
  templateUrl: './toggle-pc-lease-blocking-dialog.component.html',
  styleUrls: ['./toggle-pc-lease-blocking-dialog.component.scss']
})
export class TogglePcLeaseBlockingDialogComponent extends BaseComponent implements OnInit {

  public employee: any;
  public blockingReason!: string | undefined;
  public shouldBlock: boolean = false;
  
  constructor(
    @Inject('EmployeeService') private employeeService: any,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<TogglePcLeaseBlockingDialogComponent>,
    private notificationService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.employee = this.dialogData.employee;
    this.blockingReason = this.dialogData.blockingReason;
    this.shouldBlock = this.dialogData.shouldBlock;
  }

  saveBlockingPcLeasingInformation() {
    this.setLoading$(true);
    this.employeeService.togglePcLeaseBlockingForEmployee(
      this.employee.id, 
      this.shouldBlock || false,
      this.blockingReason
    ).then(() => {
      this.notificationService.message('Ihre Angaben wurden gespeichert.');
    }).finally(() => {
      this.dialogRef.close(true);
      this.setLoading$(false);
    });
  }
}
