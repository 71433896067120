const DocumentConstants = {
  99: {
    name: 'Entgeltnachweis'
  },
  96: {
    name: 'Arbeitnehmerbilanz'
  },
  10: {
    name: 'Vertragsdokumente'
  },
  6: {
    name: 'Vertragsdokumente'
  },
  89: {
    name: 'Arbeitgeberbilanz'
  }
};

export default DocumentConstants;
