import {
  DEFAULT_MPP_ARTICLE,
  ORDER_STATUS_BUTTON_CONFIG_BY_ID,
  STORNO_BASKET_STATE,
  DELETED_BASKET_STATE,
} from '../../services/mppService';
import {APPROVAL_PROCESS_STATES_BY_ID, APPROVAL_PROCESS_TYPES_BY_NAME} from '../../services/administrationService';
import bonus_control_process_dialog_html from "../../modules/administration/bonus_control_process/bonus_control_process_dialog.pug"
import add_contract_id_dialog_html from '../../modules/administration/mpp/editable/order_process/add_contract_id_dialog.pug'
import upload_order_process_documents_dialog_html from '../../modules/administration/mpp/editable/order_process/upload_order_process_documents_dialog.pug'
import cancel_order_dialog_html from '../../modules/administration/mpp/editable/order_process/cancel_order_dialog.pug'
import ready_for_delivery_dialog_html from '../../modules/administration/mpp/editable/order_process/ready_for_delivery_dialog.pug'
import edit_basket_general_html from '../../modules/administration/mpp/editable/edit_basket_general/edit_basket_general.pug'
import preend_pc_dialog_html from '../../modules/administration/mpp/editable/preend_pc_dialog/preend_pc_dialog.pug'
import { MppStatusLogDialogComponent } from '../../../app/administration/dialogs/mpp/status-log/mpp-status-log-dialog.component'
import { ResetPcOrderConfirmDialogComponent } from '../../../app/administration/dialogs/reset-pc-order-confirm-dialog/reset-pc-order-confirm-dialog.component'
import { ResetPreendPcDialogComponent } from "../../../app/administration/dialogs/reset-preend-pc-dialog/reset-preend-pc-dialog.component";

const $inject = [
  '$state',
  '$scope',
  'Made',
  'BonusService',
  'employeeService',
  '$timeout',
  'NotificationService',
  'mppService',
  'dialogService',
  'lodash',
  '$q',
  'administrationService',
  'MppConstants',
  'authenticationService',
  'fileService',
  'VSP_CONSTANTS',
  '$injector',
  'NeoComponentsService',
  '$window',
  'customerService',
  'MatDialogService',
  'DigitalSignatureYouSignServiceV3'
];

export default class MppBasketConroller {

  constructor(
    $state,
    $scope,
    Made,
    BonusService,
    employeeService,
    $timeout,
    NotificationService,
    mppService,
    dialogService,
    lodash,
    $q,
    administrationService,
    MppConstants,
    authenticationService,
    fileService,
    VSP_CONSTANTS,
    $injector,
    NeoComponentsService,
    $window,
    customerService,
    MatDialogService,
    DigitalSignatureYouSignServiceV3
  ) {
    Object.assign(this, {
      $state,
      $scope,
      Made,
      BonusService,
      employeeService,
      $timeout,
      NotificationService,
      mppService,
      dialogService,
      lodash,
      $q,
      administrationService,
      MppConstants,
      authenticationService,
      fileService,
      VSP_CONSTANTS,
      $injector,
      NeoComponentsService,
      $window,
      customerService,
      MatDialogService,
      DigitalSignatureYouSignServiceV3
    });

    this.loading = {
      calculating_sum: false,
      main: false,
      data:false,
      update_basket: false,
      mark_basket_as_valid: false,
      order_process: {}
    };

    let wachers = [];
    this.onDestroy = () => {
      // remove watchers
      for(let watcher of wachers) {
        watcher();
      }
    };


    this.$onInit = () => {

      this.options = {
        override_allow_editing: this.lodash.get(this.options, 'override_allow_editing', true)
      };

      this.employee_id = this.employeeId;
      this.DEFAULT_MPP_ARTICLE = DEFAULT_MPP_ARTICLE;
      this.ORDER_STATUS_BUTTON_CONFIG_BY_ID = ORDER_STATUS_BUTTON_CONFIG_BY_ID;
      this.APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES_BY_NAME;
      this.APPROVAL_PROCESS_STATES_BY_ID = APPROVAL_PROCESS_STATES_BY_ID;
      this.BONUS_PAYMENT_TYPES_BY_NAME = BONUS_PAYMENT_TYPES_BY_NAME;
      this.STORNO_BASKET_STATE = STORNO_BASKET_STATE;

      this.$scope.status = MppConstants.status;

      this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
      let to_load_watcher = this.$scope.$watch(()=>{return this.toLoadData;}, (newValue)=>{
        if (newValue) {
          this.loadData();
        }
      });
      wachers.push(to_load_watcher);

      this.allowBasketsToReset = [];

      this.initBasket().then(() => {
        this.$timeout(() => {
          this.loading.main = false;
        }, 0);
      });
    };
  }

  formatDate(date, tstamp = false) {
    let dbDate = new Date(tstamp ? date * 1000 : date);
    return dbDate.getDate() + '.' + (dbDate.getMonth() + 1) + '.' + dbDate.getFullYear();
  }

  async initBasket() {
    this.$timeout(() => {
      this.loading.main = true;
    }, 0);

    this.MPP_ORDER_STATUSES_BY_ID = await this.mppService.getOrderStatusesById();
    this.MPP_ORDER_STATUSES_BY_NAME = await this.mppService.getOrderStatusesByName();
    this.$scope.basket = this.basket;

    this.forms = {
      change: {},
      decline: {},
      edit_basket: {},
      control_basket: {}
    };

    this.models = {
      edit_basket: {
        edit_basket_comment: ''
      }
    };

    this.has_loaded_data = false;

    if (!this.basket){
      this.initNewBasket();
    } else {
      await this.hasEmployeeWantsReturnPc(this.basket.id);

      if (this.VSP_CONSTANTS.BASKET_TYPE_BY_NAME[this.basket.type || '']['id'] === this.VSP_CONSTANTS.BASKET_TYPE_BY_NAME['neo_component']['id']) {
        let pc_components = await this.NeoComponentsService.getPCComponent({basket_id: this.basket._id});
        this.pc_component = pc_components[0];
      }

      this.employee_id = this.basket.employeeId;
      this.customer_configration = await this.customerService.getConfigurationByEmployeeId(this.employee_id);
      this.endprice_percentage = this.lodash.get(this.customer_configration, 'neo_config.pc.endprice_percentage');

      this.original_basket = angular.copy(this.basket);
      this.is_for_bonus_basket = !!this.basket.bonus;
      this.basket_not_linked_to_bonus = false;

      if (this.basket.articles) {
        this.basket.articles.forEach((article) => {
          article.full_price = article.price * article.quantity;
        });
      }

      let invoiceReferenceId = this.lodash.get(this.basket, 'billing_process.invoice_id')
      if (invoiceReferenceId) {
        this.invoice = await this.mppService.getInvoiceByReferenceId(invoiceReferenceId);
      }

      if (this.toLoadData) {
        await this.loadData();
      }
    }
  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }
  async openYouSignV3File(document) {
      await this.DigitalSignatureYouSignServiceV3.openYouSignFileInNewTab(document._id, document.type);
  }

  openControlDialog(){
    this.dialogService.ngDialog.openConfirm({
      template: bonus_control_process_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusControlProcessController',
      data: {
        neo_component: this.pc_component
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    });
  }

  getBasketTypeDisplay(basket) {
    return this.VSP_CONSTANTS.BASKET_TYPE_BY_NAME[basket.type || '']['display'];
  }

  goToNEO(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.neo',
      'params': {employeeId: employeeId}
    });
  }
  goToBonus(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.bonus',
      'params': {employeeId: employeeId}
    });
  }

  showStatusLog($event, basket) {
    $event.preventDefault();
    $event.stopPropagation();

    this.MatDialogService.open(MppStatusLogDialogComponent, { data: { basket }, width: 1200});
  }

  async loadData() {
    // check if new data
    if (!this.basket || this.loading.data || this.has_loaded_data) {
      return;
    }

    this.$timeout(() => {
      this.loading.data = true;
    });

    this.MPP_ORDER_STATUSES_BY_ID = await this.mppService.getOrderStatusesById();

    this.pc_approvals = await this.administrationService.getApprovalProcesess({
      entity_ids: [this.basket._id],
      to_extract_entity: true,
      to_add_validations: true
    });

    if (this.is_for_bonus_basket) {
      this.bonus = await this.BonusService.getBonusesForEmployee({
        bonus_project_id: this.basket.bonus.bonus_project_id,
        employee_id: this.employee_id,
        basket_id: this.basket._id,
        to_standalone_other_bonuses: true
      });

      if (this.bonus && this.bonus.checkout_config) {
        this.pc_leasing_limits = await this.getPCLeasingLimits();
        // set up variables
        let customer_approved_limit = this.lodash.get(this.bonus, 'limits.customer_approved_limit');
        let has_customer_approved_limit = angular.isNumber(customer_approved_limit);
        let is_exchange = this.bonus.checkout_config['bonus_payment_type'] === this.BONUS_PAYMENT_TYPES_BY_NAME['exchange']['id'];
        let is_budget = this.bonus.checkout_config['bonus_payment_type'] === this.BONUS_PAYMENT_TYPES_BY_NAME['budget']['id'];

        // define basket_max_limit
        this.basket_max_limit = undefined;
        if (has_customer_approved_limit && is_exchange) {
          this.basket_max_limit = customer_approved_limit;
        } else if (has_customer_approved_limit && is_budget) {
          this.basket_max_limit = Math.min(customer_approved_limit, this.pc_leasing_limits['max_brutto_value']);
        } else if (!has_customer_approved_limit && is_exchange) {
          this.basket_max_limit = this.bonus.checkout_config.full_amount;
        } else if (!has_customer_approved_limit && is_budget) {
          this.basket_max_limit = Math.min(this.pc_leasing_limits['max_brutto_value'], this.bonus.checkout_config.full_amount);
        } else {
          throw 'dont know what to do';
        }

        if (!angular.isNumber(this.basket_max_limit)) {
          throw 'must have basket_max_limit';
        }
      } else {
        this.basket_not_linked_to_bonus = true;
      }
    }

    this.has_loaded_data = true;

    this.$timeout(() => {
      this.loading.data = false;
    });
  }

  generateContract(basket) {
    this.$timeout(() => {
      this.loading.main = true;
    });

    let operation;

    if (basket.bonus) {
      operation = this.BonusService.generateBonusCheckoutContractByBonusId(basket.employeeId, basket.bonus.bonus_id);
    } else {
      operation = this.administrationService.generateContract(basket);
    }

    return operation.then(res=>{
      this.$window.open(res.response, '_blank');
    }).finally(( ) => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });

  }

  canUpload(basket) {
    return basket.order_process &&
      this.MPP_ORDER_STATUSES_BY_NAME &&
      [
        this.MPP_ORDER_STATUSES_BY_NAME['ready_for_delivery']['id'],
        this.MPP_ORDER_STATUSES_BY_NAME['sent_by_retailer']['id'],
        this.MPP_ORDER_STATUSES_BY_NAME['delivered']['id'],
        this.MPP_ORDER_STATUSES_BY_NAME['bill_send_leasing_company']['id'],
        this.MPP_ORDER_STATUSES_BY_NAME['declined']['id'],
      ].indexOf(basket.order_process.state_id) !== -1;
  }

  canOpenContractDialog(basket) {
    return this.MPP_ORDER_STATUSES_BY_NAME && basket.order_process && [
      this.MPP_ORDER_STATUSES_BY_NAME['bill_send_leasing_company']['id'],
      this.MPP_ORDER_STATUSES_BY_NAME['order_closed']['id']
    ].indexOf(basket.order_process.state_id) !== -1 && !basket.contract_id;
  }

  openContractDialog($event, basket) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: add_contract_id_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'AddContractIDController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.dispatchChangeEvent({'action': 'contract_added',});
    });
  }


  //@deprecated
  initNewBasket() {
    // trying to represent our model in the mongo
    this.withArticles = false;
    this.addNewBasketFormModel = {
      employeeId: this.employee_id,
      leasing: '',
      cart_leasing_month_netto: '',
      sum: '',
      order_id: '',
      contract_id: '',
      beginDate: '',
      endDate: '',
      closed: false,
      locked: false,
      articles: []
    };
    //angular.copy(DEFAULT_MPP_ARTICLE)
  }

  async delete(basket) {

    if (!this.canDeleteBasket(basket)) {
      return;
    }

    this.$timeout(() => {
      this.loading.main = true;
    });
    try {

      await this.mppService.deleteBasket({basket_id: basket['_id']});

      //  delete basket
      this.NotificationService.message('OK');
      this.dispatchChangeEvent({'action': 'basket_deleted', is_successful: true})
    } catch (e) {
      this.dispatchChangeEvent({'action': 'basket_deleted', is_successful: false, error: e});
      this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
    } finally {
      this.$timeout(() => {
        this.loading.main = false;
      });
    }
  }

  dispatchChangeEvent(data) {
    this.onChange({
      'changeEvent': data
    });
  }

  async boTakesAction(data, action) {
    this.$timeout(() => {
      this.loading.main = true;
    });

    try {
      await this.mppService.BOnewBasketCreation(data);
      this.dispatchChangeEvent({'action': action});
    } catch (e) {
      this.$timeout(() => {
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      }, 0);

    }

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  openOrderProcessDocumentUploadDialog(basket, $event) {
    this.dialogService.ngDialog.openConfirm({
      template: upload_order_process_documents_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'UploadOrderProcessDocumentsDialogController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).finally(() => {
      this.dispatchChangeEvent({'action': 'document_uploaded'});
    });
  }

  async storno(cart) {
    this.$timeout(() => {
      this.loading.storno = true;
    });
    try {
      await this.mppService.stornoBasket(cart).then(basket => cart.state = basket.state);
      this.NotificationService.message('OK');
      this.dispatchChangeEvent({'action': 'basket_storno', is_successful: true});
    } catch (e) {
      this.dispatchChangeEvent({'action': 'basket_storno', is_successful: false, error: e});
      this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
    } finally {
      this.$timeout(() => {
        this.loading.storno = false;
      });
    }
  }

  canDeleteBasket(basket) {
    if (this.is_for_bonus_basket) {
      return false;
    }

    let is_stornieren_visible = this.canOpenCancelOrderDialog(basket);
    let order_process_without_order_id = !this.lodash.get(basket, 'order_id');
    let is_in_storno_state = basket.state === STORNO_BASKET_STATE;

    return !is_stornieren_visible && (order_process_without_order_id || is_in_storno_state);
  }

  canOpenCancelOrderDialog(basket) {
    if (this.is_for_bonus_basket) {
      return false;
    }
    let isStorno = [STORNO_BASKET_STATE, DELETED_BASKET_STATE].includes(basket.state);
    let isRequestedByVN = this.lodash.get(basket, 'order_process') && (this.MPP_ORDER_STATUSES_BY_NAME && (this.lodash.get(basket, 'order_process.state_id') ===  this.MPP_ORDER_STATUSES_BY_NAME['requested_by_VN']['id']));

    let canCancel = basket.order_id && !isStorno && !isRequestedByVN;

    if (this.is_for_bonus_basket) {
      return !this.basket_not_linked_to_bonus && canCancel;
    }
    return canCancel;
  }

  openCancelOrderDialog($event, basket) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: cancel_order_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'CancelOrderController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).finally(() => {
      this.dispatchChangeEvent({'action': 'order_cancelled'});
    });
  }

  openTrackingDialog($event) {
    $event.preventDefault();

    this.dialogService.ngDialog.openConfirm({
      template: ready_for_delivery_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'ready_for_delivery_controller',
      controllerAs: '$ctrl',
      data: {
        'basket': this.basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.dispatchChangeEvent({'action': 'added_tracking_id'});
    });
  }

  isBasketEditable(basket) {
    if (!basket) {
      return false;
    }
    return !basket.order_process || this.isOrderProcessNeedsChanges();
  }

  addArticle() {
    if (this.$scope.basket) {
      if (this.$scope.basket.articles) {
        this.$scope.basket.articles.push(angular.copy(DEFAULT_MPP_ARTICLE));
      }
      else {
        this.$scope.basket.articles = [angular.copy(DEFAULT_MPP_ARTICLE)];
      }
    } else {
      this.addNewBasketFormModel.articles.push(angular.copy(DEFAULT_MPP_ARTICLE));
    }
  }

  removeArticle(index) {
    this.$scope.basket.articles.splice(index, 1);
  }

  basketDataChanged() {
    return !angular.equals(this.$scope.basket, this.original_basket);
  }

  areFormsValid(forms) {
    return Object.keys(forms).reduce((acc, form_name) => {
      acc = acc && forms[form_name].$valid;
      return acc;
    }, true);
  }

  async getPCLeasingLimits() {
    return this.BonusService.calculatePCLeasing(this.employee_id, this.bonus.bonus_project, this.bonus._id);
  }

  async compareCartSum() {
    if (!this.isBonusBasketValid()) {
      return;
    }
    if (this.is_for_bonus_basket) {
      this.$timeout(() => {
        this.loading.calculating_sum = true;
      });

      let user_bonus_payment_type_choice_id = this.lodash.get(this.$scope.basket, 'user_bonus_payment_type_choice.id');
      if (!user_bonus_payment_type_choice_id) {
        user_bonus_payment_type_choice_id = this.lodash.get(this.bonus, 'checkout_config.bonus_payment_type');
      }

      return this.BonusService.checkoutBonusMppCart({
          employee_id: this.employee_id,
          bonus_project_id: this.bonus.bonus_project,
          bonus_id: this.bonus._id,
          start_date: this.bonus.dates.bonus_start_date,
          user_bonus_payment_type_choice_id: user_bonus_payment_type_choice_id,
          cart: this.$scope.basket,
          is_test: true
        }
      ).then((new_checkout_config) => {
        this.new_full_amount = new_checkout_config['full_amount'];
        this.over_sum_limit = this.new_full_amount > this.basket_max_limit;
        this.checkout_check_error = undefined;
      }).catch((e) => {
        this.checkout_check_error = e.error && e.error.message;
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      }).finally(() => {
        this.$timeout(() => {
          this.loading.calculating_sum = false;
        });
      });
    }
  }

  canUpdateBasket() {
    let general_validation = this.areEditBasketFormsValid() && this.isBonusBasketValid();
    if (this.is_for_bonus_basket) {
      return general_validation && !this.over_sum_limit;
    }

    return general_validation;
  }

  areEditBasketFormsValid() {
    return this.areFormsValid(this.forms.edit_basket);
  }

  async updateBasket() {
    this.$timeout(() => {
      this.loading.update_basket = true;
    });

    //TODO: this has to be refactored!!
    let basket_copy = angular.copy(this.$scope.basket);
    delete basket_copy.pc_component;

    try {
      await this.mppService.updateMPPArticleData({
        basket: basket_copy,
        comment: this.models.edit_basket.edit_basket_comment
      });
      this.NotificationService.message('Die Änderungen wurden gespeichert');
    } catch(e) {
      this.NotificationService.error(e.error.message.split('\n')[0]);
    } finally {
      this.$timeout(() => {
        this.loading.update_basket = false;
      });
      this.dispatchChangeEvent({'action': 'confirm_basket_changes'});
    }
  }

  isOrderProcessNeedsChanges() {
    return this.basket.order_process && this.basket.order_process.state_id === this.mppService.MPP_ORDER_STATUSES_BY_NAME['order_created_in_retailer_with_changes']['id'];
  }

  isBonusBasketValid() {
    return this.is_for_bonus_basket && this.basket_not_linked_to_bonus ? false : true;
  }

  async initOrderProcessFileTags() {
    this.$scope.order_process_file_tags = await this.mppService.initOrderProcessFileTags({basket_id: this.basket._id});
  }

  async confirmBasketChanges() {

    this.$timeout(() => {
      this.loading.order_process.confirm_changes = true;
    });

    let result = null;

    try {
      result = await this.mppService.confirmBasketChanges(this.basket['_id']);
    } finally {
      this.$timeout(() => {
        this.loading.order_process.confirm_changes = false;
      }, 0);
    }

    if (result && result['success']) {
      this.dispatchChangeEvent({'action': 'confirm_basket_changes'});
      this.NotificationService.message('Die Änderungen wurden gespeichert');
    }
  }

  async boCreatesBasket(savedEvent) {
    await this.boTakesAction(savedEvent, 'bo_creates_basket');
  }

  uploadDocuments() {
    this.loading.uploading_documents = true;
    let promises = [];
    this.$scope.order_process_file_tags = this.initOrderProcessFileTags();
    for (let i = 0; i < this.models.order_process.documents.length; i++) {
      promises.push(this.Made.upload(this.models.order_process.documents[i], [...this.$scope.order_process_file_tags, `upload_index-${i}`], this.basket.employeeId));
    }

    return this.$q.all(promises)
      .then(() => {
        this.models.order_process.documents = [];
        this.NotificationService.message('Erfolgreich: Die Dateien wurden hochgeladen');
        this.loading.uploading_documents = false;

      })
      .catch(e => {
        this.NotificationService.message(e);
      });
  }


  getTrackingURL(tracking_id_object) {
    return this.mppService.getTrackingURL(tracking_id_object);
  }

  definalizeMpp(cart) {
    this.dialogService.cancelCheckoutMPP(cart.locked, cart.employeeId, cart._id);
  }

  editBasketGeneralInformation() {
    this.dialogService.ngDialog.openConfirm({
      template: edit_basket_general_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'EditBasketGeneralController',
      controllerAs: '$ctrl',
      data: {
        'basket': this.basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.dispatchChangeEvent({'action': 'contract_added',});
    });
  }

  transferBasketToMySQL(basket_id) {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.mppService.transferBasketToMySQL(basket_id)
      .then((res) => {
        this.NotificationService.message('Erfolgreich: Aktualisierung MySQL');
      })
      .catch((err) => {
        this.NotificationService.error('ERROR: Aktualisierung MySQL');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  sendOrderEmailToLeasingCompany(basket) {
    if (!basket.order_id) {
      this.NotificationService.error('ERROR: Fehlende Auftragsnummer');
      return;
    }

    if (!this.lodash.get(basket, 'confirmation.pick_up_date')) {
      this.NotificationService.error('ERROR: Fehlendes Übernahmedatum');
      return;
    }

    if (basket.bill_sent_to_leasing_company) {
      this.NotificationService.error('ERROR: Email bereits an Leasing übermittelt');
      return;
    }


    this.$timeout(() => {
      this.loading.main = true;
    });
    return this.mppService.sendOrderEmailToLeasingCompany(basket._id)
      .then((res) => {
        this.NotificationService.message('Erfolgreich: Email an Leasing');
        this.hideSendOrderEmailToLeasingCompanyButton = true;
      })
      .catch((err) => {
        this.NotificationService.error('ERROR: Email an Leasing');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  initiateOrderProcess(basket) {
    this.$timeout(() => {
      this.loading.main = true;
    });
    return this.mppService.initiateOrderProcess({basket_id: basket._id})
      .then((res) => {
        this.NotificationService.message('Erfolgreich: Order Process Initiated');
        this.dispatchChangeEvent({'action': 'order_process_initiated',});
      })
      .catch((err) => {
        this.NotificationService.error('ERROR: Problem with initiating order process');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }

  isOrderClosed() {
    let is_order_closed = this.MPP_ORDER_STATUSES_BY_NAME && this.lodash.get(this.basket, 'order_process.state_id') === this.MPP_ORDER_STATUSES_BY_NAME['order_closed']['id'];
    return is_order_closed;
  }

  isBasketCancelled() {
    let is_basket_cancelled = false;
    if (this.is_for_bonus_basket) {
      is_basket_cancelled = this.lodash.get(this.bonus, 'state') === BONUS_STATES_BY_NAME['cancelled']['id'];
    } else {
      is_basket_cancelled = this.basket.component_state_id === this.VSP_CONSTANTS.NEO_COMPONENT_STATE_BY_NAME['cancelled']['id'];
    }

    return is_basket_cancelled;
  }

  isBasketPreended() {
    return !!this.basket.preend_information;
  }

  openCancelPCDialog() {
    this.dialogService.ngDialog.openConfirm({
      template: preend_pc_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'PreendPCDialogController',
      data: {
        basket: this.basket,
        pc_component: this.pc_component
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).finally(() => {
      this.dispatchChangeEvent({'action': 'preended'});
    });
  }

  async hasEmployeeWantsReturnPc(basketId) {
    const hasRequested = await this.mppService.hasEmployeeWantsReturnPc(basketId);
    if(hasRequested) {
      this.allowBasketsToReset.push(basketId);
    }
  }

  allowToResetOrder(basketId) {
    return this.allowBasketsToReset.includes(basketId);
  }

  openResetOrderDialog(basket) {
    this.MatDialogService.open(
      ResetPcOrderConfirmDialogComponent,
      {
        data: {
          'basket': basket
        },
        width: 600
      }
    ).afterClosed()
      .subscribe((res)=>{
        if (res) {
          this.dispatchChangeEvent({'action': 'reset_order'});
        }
      });
  }

  async openRemovePreendDialog() {
    this.MatDialogService.open(
      ResetPreendPcDialogComponent,
      {
        data: {
          'basket': this.basket,
          'pcComponent': this.pc_component
        },
        width: 600
      }
    ).afterClosed()
      .subscribe((res)=>{
        if (res) {
          this.dispatchChangeEvent({'action': 'preended'});
        }
      });
  }

}
MppBasketConroller.$inject = $inject;

import mpp_basket_html from './mpp_basket.pug';
import {BONUS_PAYMENT_TYPES_BY_NAME} from "../../services/bonus/bonus_payment_type";
import {BONUS_STATES_BY_NAME} from "../../services/bonus/bonus_state";

angular
  .module('VSPApp')
  .controller('MppBasketController', MppBasketConroller)
  .component('mppBasketComponent', {
    controller: 'MppBasketController',
    controllerAs: '$ctrl',
    template: mpp_basket_html(),
    bindings: {
      basket: '<',
      employeeId: '<',
      onChange: '&',
      options: '<',
      toLoadData: '<'
    }
  });
