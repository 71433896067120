import blank_html from "../../helpers/blank.layout.pug";
import blank_mailchange_html from "../../modules/authentication/mailchange/mailchange.pug";
import blank_registration_html from "../../modules/authentication/registration/registration.pug";
import blank_activation_html from "../../modules/authentication/activation/activation.pug";
import blank_timetracking_html from "../../modules/timetracking/timetracking.pug";
import {BlankPageComponent} from "../../../app/examples/components/blank-page/blank-page.component";
import blank_cidaas_login_html from "../../modules/authentication/cidaas_login/cidaas_login.pug";

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('blank', {
        template: blank_html,
        ncyBreadcrumb: {
          skip: true
        }
      })
      .state('blank.blank', {
        url: '/blank',
        component: BlankPageComponent
      })
      .state('blank.mailchange', {
        url: '/mailchange/:token',
        template: blank_mailchange_html,
        controller: 'mailChangeController',
        controllerAs: 'mailChange'
      })
      .state('blank.registration', {
        url: '/registration',
        template: blank_registration_html,
        controller: 'RegistrationController',
        controllerAs: '$ctrl'
      })
      .state('blank.activation', {
        url: '/activation',
        template: blank_activation_html,
        controller: 'ActivationController',
        controllerAs: 'activation'
      })
      .state('blank.timetracking', {
        url: '/zeiterfassung',
        template: blank_timetracking_html,
        controller: 'TimetrackingController',
        controllerAs: 'timetracking'
      })
      .state('blank.file', {
        url: '/gridfs?id&token',
      })
      .state('blank.cidaas_login', {
        url: '/cidaas_login',
        template: blank_cidaas_login_html,
        controller: 'CidaasLoginController',
        controllerAs: 'cidaas_login',
        params: {
          next_url: null,
          goToHome: false,
        },
        ncyBreadcrumb: {
          label: 'Home'
        }
      })
      .state('blank.home', {
        url: '/auth_cidaas',
        controller: 'CidaasLoginController',
        controllerAs: 'cidaas_login',
        params: {
          next_url: null,
          goToHome: true,
        },
        ncyBreadcrumb: {
          label: 'Home'
        }
      })
  }]);
