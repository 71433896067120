import { RejectType } from '@uirouter/core';
const $injectrun = ['$rootScope', '$state', 'Made', 'AppCacheService', '$window', '$cookies', 'ngDialog', '$transitions', 'CacheService', '$timeout', '$urlRouter'];
export default function run( $rootScope, $state, Made, AppCacheService, $window, $cookies, ngDialog, $transitions, CacheService, $timeout, $urlRouter) {

  // Start: Cookies Preference
  // if (!CookieService.isCookiePreferenceSet()) {
  //   let today = new Date();
  //   let preference_exp_date = CookieService.getCookiePreferenceExpDate();

  //   if (!preference_exp_date || today > preference_exp_date) {
  //     ngDialog.open( {
  //       template:   '/dialogs/cookiesPreference/cookiesPreference.html',
  //       className:    'ngdialog-theme-vsp ngdialog-theme-cookies mitarbeiter-anlegen',
  //       closeByDocument: false,
  //       closeByEscape:   false,
  //       showClose:       false,
  //       overlay: false,
  //       controller: ['$scope', '$window' ,'CookieService', function($scope, $window, CookieService) {
  //         $scope.cookie_preference = {
  //           analytical_cookies: CookieService.getCookiePreference() || false,
  //           functional_cookies: true
  //         };

  //         $scope.close = function() {
  //           CookieService.setAnalyticsCookiePreference($scope.cookie_preference.analytical_cookies);
  //           $window.location.reload();
  //         };
  //       }]
  //     });
  //   }
  // }
  // End: Cookies Preference

  $transitions.onError({}, (transition) => {
    let error = transition.error();
    if (error.type === RejectType.IGNORED) {
      return;
    }

    if (error && error.detail && error.detail.redirectTo) {
      let params = error.detail.params ? error.detail.params : {};
      $state.go(error.detail.redirectTo, params);
      return ;
    }

    let errored_url = error.detail.current_absolute_url;

    let brandingLanding = ['perashr' ,'bonago-portal'];
    let subdomain = location.hostname.split('.')[0];

    if ( /.*?valuenet\.de$/.test( location.hostname ) && brandingLanding.indexOf(subdomain) === -1 ) {
      $timeout( () => $state.go( 'blank.cidaas_login', { next_url : errored_url }));
    } else if ( subdomain === 'gehaltsextras' ) {
      $timeout( () => $state.go( 'test.datev.landing' ));
    } else {
      $timeout( () => $state.go( 'landing', { next_url : errored_url }));
    }
  });


  // $rootScope.$on( '$stateChangeError', () => {
  //
  //
  //   let brandingLanding = ['perashr' ,'bonago-portal'];
  //   let subdomain = location.hostname.split('.')[0];
  //
  //   if ( /.*?valuenet\.de$/.test( location.hostname ) && brandingLanding.indexOf(subdomain) === -1 ) {
  //     $state.go( 'blank.login' );
  //   } else if ( subdomain == 'gehaltsextras' ) {
  //     $state.go( 'test.datev.landing' );
  //   } else {
  //     $state.go( 'landing' );
  //   }
  // } );

  Made.on( 'logout', () => {
    $timeout(()=>{ $state.go( 'blank.cidaas_login' ); });
    $window.localStorage.clear(); //clear local storage
    console.clear(); // clear console
    CacheService.stores = {}; // clear cache
  } );

  AppCacheService.register();

  // $urlRouter.listen();
}
run.$inject = $injectrun;
