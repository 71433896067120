<div vnLoader [showLoader]='(isLoading$() | async)!'>
  <h2 mat-dialog-title>
    Auto-Abo FINN Information 
  </h2>

  <mat-dialog-content>
    <form [formGroup]='formGroup'>

      <vn-input formControlName='orderId' label='Auftragsnummer' >
        <mat-error *ngIf="formGroup.get('orderId')?.hasError('required')">
          Eingabe fehlt.
        </mat-error>
      </vn-input>

      <vn-input formControlName='contactId' label='Kontaktnummer'>
        <mat-error *ngIf="formGroup.get('contactId')?.hasError('required')">
          Eingabe fehlt.
        </mat-error>
      </vn-input>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button mat-dialog-close>
      Abbrechen
    </button>

    <button class='save-button'
            mat-stroked-button
            [disabled]="!formGroup.valid"
            (click)="saveFinData()">
      Speichern
    </button>
  </mat-dialog-actions>
</div>
