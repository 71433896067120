const $inject = [
  "$scope"
];

export default class dialogController {
  constructor(
    $scope
  ) {
    this.$scope = $scope;

    if (this.$scope.ngDialogData && this.$scope.ngDialogData.content) {
      this.$scope.content = this.$scope.ngDialogData.content;
    }

    this.$scope.close = () => {
      this.$scope.confirm(true);
    };

  }
}

dialogController.$inject = $inject;