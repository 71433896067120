<div vnLoader [showLoader]='(isLoading$() | async)!' class='row car-detail'>

  <div *ngIf="!(isLoading$() | async)">
    <div class='row'>
      <vn-back-link target='inApp.car-leasing.list'></vn-back-link>
    </div>

    <div class='row nav'>
      <span *ngIf="!!car">
        {{ car?.cartype }} / {{ car?.brand?.id }} / {{ car?.model }}
      </span>
    </div>

    <div class='row'>

      <div class='xsmall-8 columns col-l'>
        <div class='thumbs'>
          <span (click)='slideImg(-1)'>
            <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor" d="M23.816 41.683l-1.65-1.65 13.2-13.2 1.65 1.65z"></path>
              <path fill="currentColor" d="M22.168 16.816l1.65-1.65 13.199 13.2-1.65 1.65z"></path>
            </svg>
          </span>

          <ul>
            <li *ngFor="let img of car?.pictures; index as idx;" (mouseover)='slideImg(1, idx)'
              [ngStyle]='getThumbnailsStyle()' [ngClass]='getThumbnailsClass(idx)'>
              <img [src]='"https://res.cloudinary.com/finn-auto/image/fetch/q_auto,f_auto,c_lpad,dpr_2,w_360,ar_8:5,b_white/" + img?.url' />
            </li>
          </ul>

          <span (click)='slideImg(1)'>
            <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor" d="M23.816 41.683l-1.65-1.65 13.2-13.2 1.65 1.65z"></path>
              <path fill="currentColor" d="M22.168 16.816l1.65-1.65 13.199 13.2-1.65 1.65z"></path>
            </svg>
          </span>
        </div>

        <div class='monitor' (click)='slideImg(1)'>
          <img *ngFor="let img of car?.pictures" [src]='"https://res.cloudinary.com/finn-auto/image/fetch/q_auto,f_auto,c_lpad,dpr_2,w_360,ar_8:5,b_white/" + img?.url' [ngStyle]='getSlideImgStyle()' />
        </div>

        <div class="results" *ngIf="!!carAdvantageResult">
          <h2>Ihr ValueNet Auto Abo im Vergleich</h2>
          <table>
            <thead>
              <th></th>
              <th>private Anschaffung</th>
              <th>ValueNet Auto Abo</th>
            </thead>
            <tbody>
              <tr>
                <td>Finanzierungskosten / Abo-Rate</td>
                <td style="text-align:right">{{ carAdvantageResult.privateCostFinance | currency : '€' }}</td>
                <td style="text-align:right">{{(checkoutConfiguration.finnPerMonthValue +
                  customerConfiguration.vnFlatTax) | currency : '€'}}</td>
              </tr>
              <tr>
                <td>Überführung / Rückholung</td>
                <td style="text-align:right">{{ carAdvantageResult.transferCost | currency : '€'}}</td>
                <td style="text-align:right">{{ (customerConfiguration.bringBackFee /
                  checkoutConfiguration.contractDuration) | currency : '€' }}</td>
              </tr>
              <tr>
                <td>Versicherung</td>
                <td style="text-align:right">{{ carAdvantageResult.privateCostInsurance | currency : '€'}}</td>
                <td style="text-align:right">inkl.</td>
              </tr>
              <tr>
                <td>Zulassung</td>
                <td style="text-align:right">{{ carAdvantageResult.adminCost | currency : '€'}}</td>
                <td style="text-align:right">inkl.</td>
              </tr>
              <tr>
                <td>Werkstattkosten</td>
                <td style="text-align:right">{{ carAdvantageResult.privateCostGarage | currency : '€' }}</td>
                <td style="text-align:right">inkl.</td>
              </tr>
              <tr>
                <td>Reifen</td>
                <td style="text-align:right">{{ carAdvantageResult.privateCostAutoPart | currency : '€' }}</td>
                <td style="text-align:right">inkl.</td>
              </tr>
              <tr>
                <td>Gesamtkosten</td>
                <td style="text-align:right">{{ carAdvantageResult.privateCostTotal | currency : '€' }}</td>
                <td style="text-align:right">{{ carCost | currency : '€' }}</td>
              </tr>
              <tr>
                <td>Zuschuss AG</td>
                <td style="text-align:right">{{ 0 | currency : '€' }}</td>
                <td style="text-align:right">{{ -carAdvantageResult.carFuelBonus | currency : '€' }}</td>
              </tr>
              <tr>
                <td>Steuer-SV Abzug</td>
                <td style="text-align:right">{{ 0 | currency : '€'}}</td>
                <td style="text-align:right">{{ -(carCost - carAdvantageResult.reductionFromNetto) | currency : '€'}}
                </td>
              </tr>
              <tr class="highlightTableValues">
                <td class="highlightTextFontLarge">Gesamtkosten monatlich</td>
                <td class="highlightTextFontLarge" style="text-align:right">{{ carAdvantageResult.privateCostTotal |
                  currency : '€' }}</td>
                <td class="highlightTextFontLarge" style="text-align:right">{{ carAdvantageResult.reductionFromNetto -
                  carAdvantageResult.carFuelBonus | currency : '€' }}</td>
              </tr>
              <tr class="highlightTableValues">
                <td class="highlightTextFontLargeLast">Ersparnis durch Auto Abo €</td>
                <td class="highlightTextFontLargeLast" style="text-align:right">-</td>
                <td class="highlightTextFontLargeLast" style="text-align:right">{{ carAdvantageResult.privateCostTotal -
                  carAdvantageResult.reductionFromNetto + carAdvantageResult.carFuelBonus | currency : '€' }}</td>
              </tr>
              <tr class="highlightTableValues">
                <td class="highlightTextFontLargeLast">Ersparnis durch Auto Abo %</td>
                <td class="highlightTextFontLargeLast" style="text-align:right">-</td>
                <td class="highlightTextFontLargeLast" style="text-align:right">{{
                  ((carAdvantageResult.privateCostTotal - carAdvantageResult.reductionFromNetto +
                  carAdvantageResult.carFuelBonus) / carAdvantageResult.privateCostTotal) * 100 | currency : '%'
                  }}</td>
              </tr>
            </tbody>
          </table>
          <div class='row'>
            <span class='space-top'>
              Die hier dargestellten Werte sind Mittelwerte. Berücksichtigt sind die Anschaffungskosten des Fahrzeuges
              abzgl. einem gemittelten, üblichen Nachlass, gemittelten Überführungskosten der Hersteller, gemittelten
              Zulassungskosten
              sowie gemittelten Kosten für Wartung, Services, Verschleißreparaturen und Reifenersatz. Angenommen wird
              eine Privatfinanzierung über eine Laufzeit von 48 Monaten,
              ohne Anzahlung, mit realistischer Schlussrate bei einer Laufleistung ca.12.000 km pro Jahr. Die Angaben
              sind ohne Gewähr und ohne Anspruch auf Vollständig- und Richtigkeit. ​
            </span>
          </div>
          <div class='row'>
            <span class='space-top'>
              <a href="https://www.adac-autokosten.de" target="_blank">Ihre individuellen Fahrzeugkosten können Sie zB.
                unter ADAC Autokosten ermitteln und vergleichen.</a>
            </span>
          </div>
          <button class='button button-primary' [disabled]='validationError || !canCheckout()' (click)='checkout()'
            data-cy='neo.preCheckout.carLeasing.carDetails.buttonStartCheckout'>
            gewähltes Fahrzeug bestellen
          </button>

          <div class='row' *ngIf="validationError && !(isLoading$() | async)">
            <span class="alert">{{ validationError }} </span>
          </div>
        </div>
        <div class='data'>
          <h2>
            Fahrzeugdaten
          </h2>

          <span>
            <small>
              Modelljahr
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                d="M8.006 16.64c0 1.34-.959 2.426-2.14 2.426-1.183 0-2.142-1.086-2.142-2.426l-1.686-.246c-.625-.051-1.212-.663-1.169-1.372l.309-3.245c.045-.74.504-1.363 1.138-1.546.403-.117.806-.213 1.21-.29 1.137-.215 3.411-1.933 4.928-2.578.777-.33 2.354-.438 3.834-.43 1.407.008 2.722.116 3.368.43.56.272 1.633 1.274 3.223 3.008 1.895.287 3.222.573 3.98.86 1.138.43 1.896 1.288 2.085 1.933.127.43.19 1.074.19 1.934l-.002.344c0 .593-.425 1.074-.948 1.074l-1.905.124c0 1.34-.958 2.426-2.14 2.426-1.183 0-2.142-1.086-2.142-2.426H8.006z"
                stroke="#272B3E" stroke-width="1.2">
              </path>
              <path stroke="#272B3E" stroke-width="1.2" d="M5.201 10.667h12.133"></path>
            </svg>
            <span>
              {{car?.model_year}}
            </span>
          </span>
          <span>

            <small>
              Fahrzeugtyp
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                d="M8.006 16.64c0 1.34-.959 2.426-2.14 2.426-1.183 0-2.142-1.086-2.142-2.426l-1.686-.246c-.625-.051-1.212-.663-1.169-1.372l.309-3.245c.045-.74.504-1.363 1.138-1.546.403-.117.806-.213 1.21-.29 1.137-.215 3.411-1.933 4.928-2.578.777-.33 2.354-.438 3.834-.43 1.407.008 2.722.116 3.368.43.56.272 1.633 1.274 3.223 3.008 1.895.287 3.222.573 3.98.86 1.138.43 1.896 1.288 2.085 1.933.127.43.19 1.074.19 1.934l-.002.344c0 .593-.425 1.074-.948 1.074l-1.905.124c0 1.34-.958 2.426-2.14 2.426-1.183 0-2.142-1.086-2.142-2.426H8.006z"
                stroke="#272B3E" stroke-width="1.2">
              </path>
              <path stroke="#272B3E" stroke-width="1.2" d="M5.201 10.667h12.133"></path>
            </svg>
            <span>
              {{car?.cartype}}
            </span>
          </span>

          <span>
            <small>
              Farbe
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.338 24.267l9.963-9.99 1.174 1.205.792-.794L13.075 3.467l-.791.793 1.146 1.123-1.61 1.614-2.648-2.654a2.453 2.453 0 00-3.467 0A2.436 2.436 0 005.177 7c.123.296.302.565.528.791l2.675 2.682-4.913 4.9 8.871 8.894zM6.497 7.025a1.314 1.314 0 010-1.916 1.363 1.363 0 011.91 0l2.648 2.682-1.91 1.916-2.648-2.682zm2.647 4.214l2.02 2.026a2.303 2.303 0 00-.382 1.286 2.44 2.44 0 001.5 2.25 2.424 2.424 0 002.648-.528 2.438 2.438 0 00-1.718-4.158 2.589 2.589 0 00-1.283.356l-2.02-1.998 4.285-4.297 7.316 7.335-9.172 9.196-7.315-7.335 4.121-4.133zm2.73 3.312a1.343 1.343 0 011.338-1.341 1.336 1.336 0 011.337 1.341 1.343 1.343 0 01-1.337 1.341 1.336 1.336 0 01-1.338-1.341z"
                fill="#272B3E">
              </path>
            </svg>

            <span>
              {{car?.color.specific}}
            </span>
          </span>

          <span>
            <small>
              Kraftstoff
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.442 3.92c1.374 1.514 2.404 3.33 3.091 5.448v8.626c0 1.003-.922 1.816-2.06 1.816-1.14 0-2.062-.813-2.062-1.816v-4.54c0-1.003-.923-1.816-2.061-1.816h-1.031m1.546 10.896H3.467h13.398zm-1.546 0V5.282c0-1.003-.923-1.816-2.061-1.816H7.074c-1.138 0-2.061.813-2.061 1.816v17.25h10.306zm-1.546-10.896H6.56h7.214z"
                stroke="#272B3E" stroke-width="1.2">
              </path>
            </svg>

            <span>
              {{car?.fuel}}
            </span>
          </span>

          <span>
            <small>
              Schaltung
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.534 3.467v19.067M16.178 3.466v19.067M9.822 3.466v19.067M3.467 3.466V13h19.067"
                stroke="#272B3E" stroke-width="1.2">
              </path>
            </svg>

            <span>
              {{car?.gearshift}}
            </span>
          </span>
          <span>
            <small>
              Sitze/Türen
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                    d="M8.006 16.64c0 1.34-.959 2.426-2.14 2.426-1.183 0-2.142-1.086-2.142-2.426l-1.686-.246c-.625-.051-1.212-.663-1.169-1.372l.309-3.245c.045-.74.504-1.363 1.138-1.546.403-.117.806-.213 1.21-.29 1.137-.215 3.411-1.933 4.928-2.578.777-.33 2.354-.438 3.834-.43 1.407.008 2.722.116 3.368.43.56.272 1.633 1.274 3.223 3.008 1.895.287 3.222.573 3.98.86 1.138.43 1.896 1.288 2.085 1.933.127.43.19 1.074.19 1.934l-.002.344c0 .593-.425 1.074-.948 1.074l-1.905.124c0 1.34-.958 2.426-2.14 2.426-1.183 0-2.142-1.086-2.142-2.426H8.006z"
                    stroke="#272B3E" stroke-width="1.2">
              </path>
              <path stroke="#272B3E" stroke-width="1.2" d="M5.201 10.667h12.133"></path>
            </svg>

            <span>
              {{car?.seats}}/{{car?.doors}}
            </span>
          </span>
          <span>
            <small>
              Leistung
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M24.343 17.358v-.2h-2.968v3.605a.693.693 0 01-.211.496.741.741 0 01-.517.208H8.357s0 0 0 0a.744.744 0 01-.464-.164h-.001l-4.053-3.215-.055-.044H.929a.741.741 0 01-.518-.208.692.692 0 01-.211-.496V8.678c0-.185.075-.363.211-.496a.741.741 0 01.518-.208h8.697l-.01-.21a1.123 1.123 0 010-.12h0V5.942H6.672a.741.741 0 01-.517-.208.692.692 0 01-.212-.496c0-.185.076-.364.212-.496a.741.741 0 01.517-.208h7.322c.194 0 .38.075.517.208a.692.692 0 01.211.496.692.692 0 01-.211.496.741.741 0 01-.517.208h-2.935v1.702l.001.01a.482.482 0 010 .1l-.023.22h6.295a.74.74 0 01.517.208.693.693 0 01.211.496v2.574h2.587a.74.74 0 01.517.208.692.692 0 01.211.496v3.817h2.968v-3.225c0-.185.075-.364.211-.496a.741.741 0 01.517-.208.74.74 0 01.517.208.692.692 0 01.212.496v7.84a.692.692 0 01-.212.495.742.742 0 01-.517.209.742.742 0 01-.517-.208.692.692 0 01-.211-.497v-3.029zm-4.425-4.475v-.2h-2.586a.741.741 0 01-.517-.208.692.692 0 01-.212-.497V9.382H1.657v7.254h2.41s0 0 0 0a.74.74 0 01.464.164h0l4.022 3.215.054.043h11.311v-7.175z"
                fill="#272B3E" stroke="#fff" stroke-width="0.4">
              </path>
            </svg>

            <span>
              {{car?.power}} kW ({{car?.getKw()}} PS)
            </span>
          </span>

          <span>
            <small>
              CO₂ Emissionen (WLTP)
            </small>

            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 22.804c4.373 0 7.8-3.486 7.8-7.936 0-1.809-1.252-4.447-3.72-7.842a58.713 58.713 0 00-3.651-4.508.583.583 0 00-.86 0c-.018.02-1.847 2.027-3.65 4.508-2.468 3.395-3.72 6.034-3.72 7.842 0 4.45 3.426 7.936 7.8 7.936zM9.852 7.702a58.535 58.535 0 013.146-3.933c.64.731 1.9 2.216 3.146 3.933 2.287 3.147 3.496 5.625 3.496 7.166 0 3.809-2.917 6.792-6.642 6.792-3.725 0-6.642-2.983-6.642-6.792 0-1.54 1.209-4.019 3.496-7.166z"
                fill="#272B3E">
              </path>
              <path
                d="M12.813 20.252c2.996 0 5.342-2.384 5.342-5.428a.575.575 0 00-.579-.572.574.574 0 00-.579.571c0 2.403-1.838 4.285-4.184 4.285a.575.575 0 00-.579.572c0 .316.26.572.58.572z"
                fill="#272B3E">
              </path>
            </svg>

            <span>
              {{car?.getCo2Emission()}} g/km
            </span>
          </span>

          <span>
            <small>
              Verbrauch (WLTP)
            </small>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 22.804c4.373 0 7.8-3.486 7.8-7.936 0-1.809-1.252-4.447-3.72-7.842a58.713 58.713 0 00-3.651-4.508.583.583 0 00-.86 0c-.018.02-1.847 2.027-3.65 4.508-2.468 3.395-3.72 6.034-3.72 7.842 0 4.45 3.426 7.936 7.8 7.936zM9.852 7.702a58.535 58.535 0 013.146-3.933c.64.731 1.9 2.216 3.146 3.933 2.287 3.147 3.496 5.625 3.496 7.166 0 3.809-2.917 6.792-6.642 6.792-3.725 0-6.642-2.983-6.642-6.792 0-1.54 1.209-4.019 3.496-7.166z"
                fill="#272B3E">
              </path>
              <path
                d="M12.813 20.252c2.996 0 5.342-2.384 5.342-5.428a.575.575 0 00-.579-.572.574.574 0 00-.579.571c0 2.403-1.838 4.285-4.184 4.285a.575.575 0 00-.579.572c0 .316.26.572.58.572z"
                fill="#272B3E">
              </path>
            </svg>

            <span>
              {{car?.consumption}} {{car?.getConsumptionUnit()}} / 100km
            </span>
          </span>
          <span *ngIf="car?.ev_range">
            <small>
              Elektrische Reichweite (WLTP)
            </small>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 550"
                 xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 550 550">
              <g>
                <g>
                  <path
                    d="m447.25,360.1l-187.7-49.1 165.8-33.9-4.2-21.8-210.6,43.7c-13,2.8-9.4,20.9 0,21.8l168.9,44.8-191.8,13.1c-10.5,1.5-12.6,13.3-6.3,19.7l62.6,59-136.6,10.9 1,21.8 159.5-13.1c7.7,0.6 14.9-11.2 6.3-19.7l-61.5-59 233.5-16.4c11.5-0.5 12.8-18.6 1.1-21.8z" />
                  <path
                    d="m460.85,81.8c0-27.2-21.9-49.2-49-49.2s-49,22-49,49.2 21.9,49.2 49,49.2c26.1,0 49-22 49-49.2zm-77.1,1c0-15.7 12.5-28.3 28.1-28.3 14.6,0 28.1,11.5 28.1,27.2s-12.5,28.3-28.1,28.3c-15.6,0-28.1-11.5-28.1-27.2z" />
                  <path
                    d="m490.05,78.6c0-44-35.4-78.6-78.2-78.6-42.7,0-78.2,34.6-78.2,78.6 0,43.9 78.2,161.3 78.2,161.3s78.2-117.4 78.2-161.3zm-78.2-57.6c32.3,0 57.3,26.2 57.3,57.6 0,25.1-36.5,88-57.3,122.6-20.8-34.6-57.3-97.4-57.3-122.6 0-31.4 25-57.6 57.3-57.6z" />
                  <path
                    d="m78.25,269.9c-27.1,0-49,22-49,49.2s21.9,49.2 49,49.2c26.1,0 49-22 49-49.2s-21.9-49.2-49-49.2zm0,77.6c-15.6,0-28.1-11.5-28.1-27.2s12.5-28.3 28.1-28.3c14.6,0 28.1,11.5 28.1,27.2 0,15.7-12.5,28.3-28.1,28.3z" />
                  <path
                    d="m78.25,237.5c-42.7,0-78.2,34.6-78.2,78.6 0,43.9 78.2,161.3 78.2,161.3s78.2-117.4 78.2-161.3c0-44.1-35.5-78.6-78.2-78.6zm-57.3,78.5c-3.55271e-15-31.4 25-57.6 57.3-57.6s57.3,26.2 57.3,57.6c0,25.1-36.5,88-57.3,122.6-20.9-34.6-57.3-97.4-57.3-122.6z" />
                </g>
              </g>
            </svg>



            <span>
              {{car?.ev_range}} km
            </span>
          </span>
          <span>
            <small>
              Anhängekupplung
            </small>

            <svg viewBox="0 0 56 56" version="1.1" preserveAspectRatio="xMidYMid meet"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>trailer-line</title>
              <path
                d="M15,19.2c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8S18.2,19.2,15,19.2z M15,29.2c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2S17.3,29.2,15,29.2z"
                class="clr-i-outline clr-i-outline-path-1"></path>
              <rect x="14" y="24" width="2" height="2" class="clr-i-outline clr-i-outline-path-2"></rect>
              <path
                d="M33,9H2v13.1c0,0,0,0,0,0C2,24.3,3.7,26,5.9,26H7v-2H5.9c-1,0-1.8-0.8-1.9-1.9V15h22v7.1c0,1-0.8,1.8-1.9,1.9H23v2h1.1c0,0,0,0,0,0c2.1,0,3.8-1.7,3.8-3.9V11h5c0.6,0,1-0.4,1-1S33.6,9,33,9z M26,13H4v-2h22V13z"
                class="clr-i-outline clr-i-outline-path-3"></path>
              <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
            </svg>

            <span>
              {{car?.getHitch()}}
            </span>
          </span>
          <span *ngIf="car?.getTires()">
            <small>
              Reifen
            </small>

            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 454.532 454.532" style="enable-background:new 0 0 454.532 454.532;"
              xml:space="preserve">
              <g>
                <path d="M127.267,0C57.092,0,0,57.091,0,127.266s57.092,127.266,127.267,127.266c70.174,0,127.266-57.091,127.266-127.266
                    S197.44,0,127.267,0z M127.267,217.656c-49.922,0-90.391-40.468-90.391-90.39s40.469-90.39,90.391-90.39
                    c49.92,0,90.39,40.468,90.39,90.39S177.186,217.656,127.267,217.656z" />
                <path
                  d="M127.267,48.578c-43.39,0-78.689,35.299-78.689,78.688c0,43.389,35.3,78.688,78.689,78.688
                    c43.389,0,78.688-35.299,78.688-78.688C205.955,83.877,170.655,48.578,127.267,48.578z M195.878,122.249h-38.18
                    c-0.78-4.825-2.686-9.275-5.435-13.079l26.954-26.954C188.679,93.112,194.771,106.996,195.878,122.249z M132.204,58.648
                    c15.244,1.087,29.123,7.156,40.025,16.591l-26.948,26.949c-3.804-2.748-8.253-4.653-13.077-5.433V58.648z M122.329,58.648v38.106
                    c-4.824,0.78-9.274,2.685-13.078,5.434L82.302,75.24C93.204,65.805,107.085,59.735,122.329,58.648z M75.313,82.217l26.955,26.954
                    c-2.749,3.803-4.654,8.253-5.434,13.077h-38.18C59.761,106.996,65.853,93.113,75.313,82.217z M58.643,132.123h38.192
                    c0.779,4.824,2.685,9.274,5.434,13.078l-27.029,27.029C65.788,161.308,59.714,147.398,58.643,132.123z M122.329,195.884
                    c-15.285-1.09-29.197-7.188-40.113-16.666l27.035-27.035c3.804,2.749,8.254,4.654,13.078,5.434V195.884z M122.329,147.459v0.072
                    c-2.131-0.518-4.131-1.36-5.953-2.474l0.047-0.047c-2.85-1.738-5.244-4.132-6.982-6.983l-0.046,0.046
                    c-1.114-1.822-1.956-3.821-2.474-5.952h0.071c-0.385-1.585-0.611-3.233-0.611-4.937c0-1.704,0.227-3.352,0.611-4.937h-0.071
                    c0.518-2.13,1.359-4.129,2.474-5.951l0.046,0.046c1.738-2.85,4.133-5.245,6.982-6.982l-0.047-0.047
                    c1.822-1.114,3.822-1.957,5.953-2.474v0.072c1.586-0.385,3.233-0.612,4.938-0.612s3.352,0.227,4.938,0.612v-0.072
                    c2.131,0.518,4.13,1.359,5.951,2.473l-0.047,0.047c2.851,1.737,5.245,4.132,6.983,6.982l0.046-0.046
                    c1.115,1.822,1.957,3.822,2.475,5.953h-0.071c0.385,1.585,0.611,3.233,0.611,4.937c0,1.704-0.227,3.352-0.611,4.937h0.071
                    c-0.518,2.131-1.359,4.131-2.475,5.953l-0.046-0.046c-1.738,2.85-4.133,5.244-6.983,6.982l0.047,0.046
                    c-1.821,1.114-3.82,1.956-5.951,2.474v-0.072c-1.586,0.385-3.233,0.612-4.938,0.612S123.915,147.845,122.329,147.459z
                    M132.204,195.884v-38.267c4.824-0.78,9.273-2.685,13.077-5.433l27.034,27.034C161.4,188.696,147.488,194.794,132.204,195.884z
                    M179.292,172.23l-27.028-27.028c2.749-3.804,4.654-8.254,5.435-13.079h38.191C194.818,147.398,188.745,161.308,179.292,172.23z" />
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
            </svg>

            <span>
              {{car?.getTires()}}
            </span>
          </span>
        </div>

        <div class='furnish'>
          <h2>
            Ausstattung
          </h2>

          <div>
            <strong>
              Bruttolistenpreis:
            </strong>

            <span>
              {{car?.price.msrp | currency : '€'}}
            </span>
          </div>

          <div>
            <strong>
              Ausstattungslinie:
            </strong>

            <span>
              {{car?.equipment_line}}
            </span>
          </div>

          <p>
            {{ carDescription }}
          </p>

          <span>
            <div *ngFor="let key of collapsibleKeys" [ngClass]='getCollapsibleClass(key)'>
              <strong (click)='toggleCollapse(key)'>
                {{ getCollapsibleTitle(key) }}
              </strong>

              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M17.916 12l.016.016L9 20.948V17.97L14.97 12 9 6.03V3.052l8.932 8.932-.016.016z"
                  fill="currentColor">
                </path>
              </svg>

              <ul>
                <ng-container>
                  <li *ngFor="let item of getCollapsibleData(key)">
                    {{item}}
                  </li>
                </ng-container>
              </ul>
            </div>
          </span>
        </div>

        <a *ngIf="car?.config?.link" [href]="car?.config?.link" target="_blank">
          Konfiguration herunterladen
        </a>
      </div>

      <form [formGroup]="form">
        <div class='xsmall-4 columns col-r'>

          <div>
            <h1>
              {{ car?.brand?.id }} {{ car?.model }}
              <img [src]='car?.brand?.helper_brand_logo?.url' />
            </h1>
            <p>
              {{ car?.engine }} Edition
            </p>
            <div>
              <svg class='green' viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="8.5" stroke="currentColor"></circle>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.393 11.234L5.159 9l-.76.755 2.994 2.995 6.427-6.43-.755-.755-5.673 5.668z" fill="currentColor"
                  stroke="currentColor" stroke-width="0.5">
                </path>
              </svg>

              <p class='green'>
                Verfügbar ab {{ carAvailableFrom }}
              </p>
            </div>

            <div *ngIf="higherAgeNeeded()">
              <p class='red'>
                Mindestalter für dieses Fahrzeug ist 23!
              </p>
            </div>
          </div>

          <div class='color'>
            <span>
              Verfügbare Farben : {{ car?.color.id }}
            </span>
            <ul class='color-bullet'>
              <li *ngFor="let color of carColorList" (click)='changeColor(color.car_id)'
                [ngClass]='getColorClass(color)' [ngStyle]='getColorStyle(color)' [title]='color.id'>
              </li>
            </ul>

          </div>
          <div>
            <a *ngIf="car?.config?.link" [href]="car?.config?.link" target="_blank">
              Konfiguration herunterladen
            </a>
          </div>
          <div>
            <h2>Drei Schritte zu Ihrer persönlichen Rate</h2>
            <h3>
              1. Was wäre Ihre gewünschte Vertragslaufzeit?
            </h3>
            <div class='grid _50'>
              <span class='btn' *ngFor="let duration of carAvailableTerms; index as idx"
                (click)='selectDurationOptionAndValidate(idx, duration)'
                [attr.data-cy]="'neo.preCheckout.carLeasing.carDetails.buttonDuration-' + idx"
                [ngClass]='getDurationOptionClass(idx)'>
                {{ duration }} Monat{{ (duration === 1) ? '' : 'e' }}
              </span>
            </div>
          </div>

          <div *ngIf="canGoNextLevel(2)">
            <h3>
              2. Welches monatliche Kilometerpaket passt zu Ihrem Alltag?
            </h3>
            <div class='grid _33'>
              <span class='btn' *ngFor="let option of availableExtraKmOption; index as idx"
                (click)='selectDistanceOptionAndValidate(idx, option.km)'
                [attr.data-cy]="'neo.preCheckout.carLeasing.carDetails.buttonDistance-' + idx"
                [ngClass]='getDistanceOptionClass(idx)'>
                {{ option.km }} km
              </span>
            </div>
            <span class='space-top'>
              Bedenken Sie sowohl den täglichen Fahrtweg zu Ihrem Arbeitsplatz als auch private Unternehmungen.
              Mehrkilometer über Ihr Kilometerpaket hinaus werden mit {{ car?.price.extra_km_price | currency : '€' }}
              pro km berechnet. Die Abrechnung erfolgt am Ende des Auto Abos und kann nicht über Entgeltumwandlung
              erfolgen.
            </span>
          </div>

          <div *ngIf="canGoNextLevel(3)">
            <h3>
              3. Nutzen Sie bereits einen Dienstwagen, den Sie über Ihr Entgelt versteuern?
            </h3>
            <div class='grid _50'>
              <span class='btn' (click)="toggleHasCompanyCar('ja')"
                data-cy="neo.preCheckout.carLeasing.carDetails.buttonAdditionalCompanyCarYes"
                [ngClass]="getHasCompanyCarClass('ja')">
                Ja
              </span>
              <span class='btn' (click)="toggleHasCompanyCar('nein')"
                data-cy="neo.preCheckout.carLeasing.carDetails.buttonAdditionalCompanyCarNo"
                [ngClass]="getHasCompanyCarClass('nein')">
                Nein
              </span>
            </div>

          </div>

          <div *ngIf="canGoNextLevel(4)">
            <h3>
              4. Wie weit ist Ihre tägliche Fahrtstrecke einfach?
            </h3>
            <p>
              Zur Ermittlung Ihrer persönlichen Rate benötigen wir noch die Angabe der Entfernung zwischen Ihrem
              Wohnsitz und Ihrer Tätigkeitsstätte. Dies ist erforderlich, um den geldwerten Vorteil zu ermitteln. Er
              entfällt,
              sofern Sie bereits einen Dienstwagen mit 1 % Versteuerung in Anspruch nehmen.
            </p>
            <label class='columns small-4 left control-label' for='km-input'>
              km Wohnung Arbeitsstätte
            </label>
            <div class='columns small-6 right'>
              <input name='km-input' formControlName="distanceWorkHome"
                data-cy="neo.preCheckout.carLeasing.carDetails.inputDistanceToWork" type='number' step="1"
                (change)="resetAdvantageResult()">
            </div>
            <div *ngIf="!canCalculate()" class="alert">Bitte Eingabe prüfen</div>
          </div>

          <div *ngIf="canGoNextLevel(5)">
            <button class='button button-primary' (click)='calculateCarAdvantage()' [disabled]='!canCalculate()'
              data-cy='neo.preCheckout.carLeasing.carDetails.buttonCalculateAdvantage'>
              Vorteil berechnen
            </button>
          </div>

          <div>
            <ul class='check-fix'>
              <li>
                <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8.5" stroke="currentColor"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.393 11.234L5.159 9l-.76.755 2.994 2.995 6.427-6.43-.755-.755-5.673 5.668z" fill="currentColor"
                    stroke="currentColor" stroke-width="0.5">
                  </path>
                </svg>

                <span>
                  Klimaneutral Autofahren
                </span>
              </li>

              <li>
                <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8.5" stroke="currentColor"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.393 11.234L5.159 9l-.76.755 2.994 2.995 6.427-6.43-.755-.755-5.673 5.668z" fill="currentColor"
                    stroke="currentColor" stroke-width="0.5">
                  </path>
                </svg>

                <span>
                  Alles inklusive außer Tanken
                </span>
              </li>

              <li>
                <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8.5" stroke="currentColor"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.393 11.234L5.159 9l-.76.755 2.994 2.995 6.427-6.43-.755-.755-5.673 5.668z" fill="currentColor"
                    stroke="currentColor" stroke-width="0.5">
                  </path>
                </svg>

                <span>
                  Kostenlose Haustürlieferung
                </span>
              </li>

              <li>
                <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8.5" stroke="currentColor"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.393 11.234L5.159 9l-.76.755 2.994 2.995 6.427-6.43-.755-.755-5.673 5.668z" fill="currentColor"
                    stroke="currentColor" stroke-width="0.5">
                  </path>
                </svg>

                <span>
                  Kurze Laufzeiten
                </span>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
