const $inject = [
  'authenticationService',
  'BenefitBonagoService',
  'Made',
  '$scope',
  '$timeout',
  'moment',
];

export default class MeineakteVorteilskontoController {
  constructor(
    authenticationService,
    BenefitBonagoService,
    Made,
    $scope,
    $timeout,
    moment,
  ) {
    Object.assign(
      this, {
      authenticationService,
      BenefitBonagoService,
      Made,
      $scope,
      $timeout,
      moment,
    });

    this.iAmEmployee = this.authenticationService.iAm('employee');
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.employeeId = this.Made.user.valuenet_id;
    this.ordersAndTopupsHistory = [];
    this.loading = {
      fetching_orders: true
    };

    this.$onInit = async () => {
      await this.init();
    }
  }

  isLoading() {
    return this.loading.fetching_orders;
  }

  async init() {
    this.$timeout(()=>{
      this.loading.fetching_orders = true;
    });

    this.ordersAndTopupsHistory = [];
    let topUps = [];
    let orders = [];

    try {
      topUps = await this.BenefitBonagoService.getTopUpsByEmployeeId(this.employeeId);
    } catch (e) {
      // we dont want to brake the page
    }

    try {
      orders = await this.BenefitBonagoService.getUserOrdersByValuenetId(this.employeeId);
    } catch (e) {
      // we dont want to brake the page
    }

    if (topUps.length) {

      for (let topup of topUps) {
        let formattedTopup = {
          'ReferenceNumber': topup.Reason,
          'IssueDate': topup.ValidFrom,
          'RetailerName': '',
          'Quantity': '1',
          'Value': topup.Value,
          'DispatchDate': topup.ValidFrom,
          'Status': topup.status,
          'Type': 'topup'
        }
        this.ordersAndTopupsHistory.push(formattedTopup);
      }
    }

    if (orders.length) {
      for (let order of orders ) {
        let referenceNumber = order.ReferenceNumber;
        for (let orderItem of order.OrderItems) {
          let formattedOrderItem = Object.assign({}, orderItem, {
            ReferenceNumber: referenceNumber,
            'Type': 'checkout'
          });
          this.ordersAndTopupsHistory.push(formattedOrderItem);
        }
      }
  
      this.ordersAndTopupsHistory.sort((order_1, order_2) => {
        let a = parseInt(this.moment(order_1.IssueDate).format("YYYYMMDDHHmmss"));
        let b = parseInt(this.moment(order_2.IssueDate).format("YYYYMMDDHHmmss"));
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      });

    }


    this.$timeout(()=>{
      this.loading.fetching_orders = false;
    });
  }

  sortDescByDate(items, sortKey) {
    const sortedObject = {};
    for(const item of items) {
      const key = parseInt(this.moment(item[sortKey]).format("YYYYMMDDHHmmss"));
      sortedObject[key] = item;
    }
    return Object.values(sortedObject).reverse()
  }
}

MeineakteVorteilskontoController.$inject = $inject;
