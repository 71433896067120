import { currency } from './shared';


export default class VMA_PST {

  static calculate( data, modifier ) {

    data.value_calc = this.valueFromAnzahlTage(
      data.anzahl_tage, data.anzahl_std_abwesend, modifier
    );

    if ( data.hasOwnProperty( 'bonus_anzahl_tage' ) ) {
      data.bonus_monthly_value = this.valueFromAnzahlTage(
        data.bonus_anzahl_tage, data.anzahl_std_abwesend, modifier
      );
    }
  }


  static valueFromAnzahlTage( anzahl_tage, anzahl_std_abwesend, modifier ) {
    return currency( modifier.anzahl_std_abwesend[ anzahl_std_abwesend ] * anzahl_tage );
  }


  static calculateDaysFromValue( data, modifier, value, max = 12 ) {

    let days = value / modifier.anzahl_std_abwesend[ data.anzahl_std_abwesend ];

    return Math.min( data.bonus_anzahl_tage * max, Math.floor( days ) );
  }

}
