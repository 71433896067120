angular
  .module('VSPApp')
  .controller('CockpitProjekteFormController', [
    '$scope',
    'projectService',
    function (
      $scope,
      projectService
    ) {

      // Populate template
      $scope.project = $scope.newProject || $scope.currentProject;

      // Data container for the form
      $scope.projectData = {
        // kinds: [],
        types: [],
        alv_ktg_compensations: [],
        supply_compensations: [],
        targets: []
      };

      // Options for baustein overlay
      $scope.popupOptions = {
        items: {
          type: 'inline',
          src: '#bausteineSelect'
        },
        prependTo: '.cockpit-main',
        showCloseBtn: true,
      };

      // Interpolate custom form mechanizm from properties
      $scope.$watch('project.supply_compensation_id', function (newVal) {
        if ($scope.projectPropHelpers.supply_compensation_enabled === null) {
          $scope.projectPropHelpers.supply_compensation_enabled = newVal > 1;
        }
      });

      $scope.$watch('project.alv_ktg_compensation_id', function (newVal) {
        if ($scope.projectPropHelpers.alv_ktg_compensation_enabled === null) {
          $scope.projectPropHelpers.alv_ktg_compensation_enabled = newVal > 1;
        }
      });

      // Request form options datas
      projectService
        .types()
        .then(function (types) {
          $scope.projectData.types = types;
        });

      projectService
        .alvKtgCompensations()
        .then(function (comps) {
          $scope.projectData.alv_ktg_compensations = comps;
        });

      projectService
        .supplyCompensations()
        .then(function (comps) {
          $scope.projectData.supply_compensations = comps;
        });

      projectService
        .targets()
        .then(function (targets) {
          $scope.projectData.targets = targets;
        });

      $scope.addModule = function (popup, moduleData) {
        popup.close();

        $scope.project.components.push({
          baustein: moduleData.baustein,
          value: parseFloat(moduleData.defaultValue),
          priority: parseInt(moduleData.priority),
        });

        $scope.newModuleData = {};
      };

      $scope.deleteModule = function (index) {
        if (index >= 0 && index < $scope.project.components.length) {
          $scope.project.components.splice(index, 1);
        }
      };

      $scope.isButtonDisabled = function (newModuleData) {
        return !(newModuleData.baustein && newModuleData.defaultValue && newModuleData.priority);
      };

      $scope.changeType = function (typeId) {
        $scope.project.type_id = $scope.project.editable ? typeId : $scope.project.type_id;
      };
    }]);
