const $inject = [
  '$injector',
  '$scope',
  'WizardHandler',
  '$state',
  '$timeout',
  'vbmData',
  'moment',
  'lodash',
  'NeoComponentsService',
]
export default class NeoComponentController {
  constructor(
    $injector,
    $scope,
    WizardHandler,
    $state,
    $timeout,
    vbmData,
    moment,
    lodash,
    NeoComponentsService
  ) {
    Object.assign(this, {
      $injector,
      $scope,
      WizardHandler,
      $state,
      $timeout,
      vbmData,
      moment,
      lodash,
      NeoComponentsService
    });
    this.userService = this.$injector.get('userService');

    this.loading = {
      parent: true
    };

    this.baseInitPromise = new Promise(resolve => {
      let ctrl = this;

      ctrl.baseInit().then(() => {
        ctrl.$timeout(() => {
          ctrl.loading.parent = false;
        });
        resolve();
      });
    });
  }

  step2SelectionPossible() {
    return true;
  }

  enterStepTwo() {
    this.WizardHandler.wizard().next();
  }

  canEnterStepTwo() {
    return true;
  }

  async baseInit() {
    this.employee_id = this.vbmData.employeeId;
    this.neo_component = this.$state.params.neo_component;
    this.neo_component_id = this.$state.params.moduleId;
    this.checkout_basket = await this.NeoComponentsService.getCheckoutBasket({employee_id: this.employee_id});

    if (!this.neo_component) {

      let neo_component = await this.NeoComponentsService.getAvailableComponent(this.neo_component_id, this.employee_id);

      if (!neo_component) {
        neo_component = await this.NeoComponentsService.createComponent(this.neo_component_id, this.employee_id);
      }

      if (neo_component) {
        this.neo_component = neo_component;
      } else {
        this.navigateBackToDashboard();
        return;
      }
    }

    this.forms = {};
    this.model = {};
    this.employee = this.vbmData.employees[this.employee_id];

  }

  navigateBackToDashboard() {
    this.$state.go('inApp.neo.side.verguetung');
  }

  wizardFinished() {
    // TODO: go to checkout
    console.log('WizardFinished');
    console.log(this.checkout_basket);
    this.$state.go('inApp.neo.side.checkout_component', {checkout_basket: this.checkout_basket});
  }

  wizardCanceled() {
    this.navigateBackToDashboard();
  }
}
NeoComponentController.$inject = $inject;
