import { map, switchMap } from 'rxjs/operators';
import { from, fromEventPattern, Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Component, Inject, AfterViewInit, OnInit, ViewChild, NgZone } from '@angular/core';
import { BenefitMapService } from 'src/app/direct-bonus/services/benefit-map.service';
import { BonagoRetailerAddress } from 'src/app/direct-bonus/models/benefit-bonago.model';
import { InputComponent } from 'src/app/shared/components/form-mixins/input/input.component';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';

// ===============================================================

interface MapChoices {
  searchValue: string,
}

interface ExtendedMarker {
  marker: google.maps.Marker;
  address: BonagoRetailerAddress;
}

// ===============================================================


@Component({
  selector: 'vn-benefits-map',
  templateUrl: './benefits-map.component.html',
  styleUrls: ['./benefits-map.component.scss']
})
export class BenefitsMapComponent implements OnInit, AfterViewInit {

  public mapForm!: FormGroup;
  public consentServiceName: string = 'GOOGLE_MAPS';
  public consentServiceAccepted: boolean = false;
  public mapOptions$!: Observable<google.maps.MapOptions>;
  public onPlaceChanged$!: Observable<google.maps.LatLngLiteral>;
  public retailerMarkers$!: Observable<ExtendedMarker[]>;
  public markerClustererImagePath = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
  public latLong: any;

  private autocomplete!: google.maps.places.Autocomplete;

  public get searchValue(): FormControl {
    return this.mapForm.get('searchValue') as FormControl;
  }

  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  public infoContent = '';

  @ViewChild(GoogleMap)
  set googleMap(googleMap: GoogleMap) {
    if (!googleMap) {
      return;
    }
  }

  @ViewChild(InputComponent)
  set searchField(inputComponent: InputComponent) {
    if (!inputComponent || !google.maps.places) {
      return;
    }

    this.autocomplete = new google.maps.places.Autocomplete(inputComponent.getInputElement())
    this.autocomplete.addListener('place_changed',() => {
      this.ngZone.run(async () => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        this.latLong = {
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng()
        }
      })
    });
  }

  constructor(
    @Inject('$state') private $state: any,
    @Inject('$location') private $location: any,
    @Inject('UsercentricsConstants') private ucConstants: any,
    private bonagoService: BenefitsBonagoService,
    private benefitMap: BenefitMapService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone
  ) {
    this.initMapForm();
  }

  ngOnInit(): void {
    this.initMapOptions();
  }

  ngAfterViewInit(): void {
    this.setRetailerMarkers();
  }

  private initMapForm() {
    this.mapForm = this.formBuilder.group({
      searchValue: this.formBuilder.control(undefined, []),
    });
  }

  private initMapOptions() {
    this.mapOptions$ = from([{
      mapId: this.benefitMap.overviewMapId,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    }]).pipe(
      switchMap(options => {
        const {
          lat,
          lng
        } = this.$state.params;

        if (!!lat && !!lng) {
          return from([{
            ...options,
            center: {
              lat,
              lng
            } as google.maps.LatLng,
            zoom: 16,
          } as google.maps.MapOptions]);
        } else {
          return this.benefitMap.getAddressFromPostcode$().pipe(
            map(center => ({
              ...options,
              center,
              zoom: 11,
            } as google.maps.MapOptions))
          )
        }
      }),
    );
  }

  private setRetailerMarkers() {
    this.retailerMarkers$ = this.bonagoService.allBonagoRetailersBasic$.pipe(
      map(retailers => retailers
        .reduce(
          (addresses: BonagoRetailerAddress[], retailer) => [
            ...addresses,
            ...retailer.addresses
          ],
          []
        ).map(
          (address: BonagoRetailerAddress) => ({
            marker: new google.maps.Marker({
              position: new google.maps.LatLng(address.lat, address.lng),
              icon: {
                url: `${this.$location.protocol() + '://' + location.host}/assets/img/maps/default_map_icon_image.png`,
                scaledSize: new google.maps.Size(30, 34), // scaled size
                origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(16, 34) // anchor
              },
            }),
            address
          })
        )
      )
    );
  }

  public openInfo(marker: MapMarker, address: BonagoRetailerAddress) {
    this.infoContent = `
    <div class="retailer-template-content">
      <span class="info-box-heading">${address.retailerName}</span>
      <span class="info-text">${address.street}</span>
      <span class="info-text">${address.postcode} ${address.city}</span>
      <span class="info-text">
        <a href="${address.url}">${address.url}</a>
      </span>
      <span class="info-text go-to-retailer">
        <a href="/direct-bonus/benefits-account/retailer/${address.retailerId}">zum Händler</a>
      </span>
    </div>`;
    this.infoWindow.open(marker)
  }

  public handleStatus(status: boolean): void {
    this.consentServiceAccepted = status
  }

  public triggerAction(action: string): void {
    if (action === 'accepted') {
      location.reload();
    }
  }

}
