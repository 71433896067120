const $inject = [
  "cmsService", 
  "$scope", 
  "$window", 
  "$state", 
  "$location", 
  "fileService", 
  "$timeout"
];

export default class CmsListController {

    constructor(cmsService, $scope, $window, $state, $location, fileService, $timeout) {
        Object.assign(this, {
            cmsService,
            $window,
            $scope,
            $location,
            $state,
            fileService,
            $timeout
        });

        this.cmsCtrl = $scope.$parent.$parent.$parent;
        this.custId = $state.params.custId;
        this.category = $state.params.categoryId;
        this.items = [];
        this.cmsService.globalCms.detailId = 0;
        this.loadList().then(()=>{
          this.$timeout(()=>{});
        });
    }

    loadList() {
        let promises = [];
        let teaser_promise = this.cmsService.getTeaserList(this.custId, this.category).then( async (items) => {
            items = this.cmsService.sortNotesByPinned(items);

            this.items = items;

            this.styles = {};
            for (let item of this.items){
              if (item.teaser && item.teaser._id){
                this.styles[item.teaser._id] = {
                  'background-image' : 'url(' +  await this.fileService.getDocumentUrl(item.teaser._id) +')'
                };
              }

              if (item.stage && item.stage._id){
                this.styles[item.stage._id] = {
                  'background-image' : 'url(' +  await this.fileService.getDocumentUrl(item.stage._id) +')'
                };
              }
            }
            
            this.cmsService.globalCms.loading = false;
        });
        let category_promise = this.cmsService.getCategory(this.category).then((category) => {
            this.category = category;

            if (!this.category && this.$location.path().split('/').slice(-1).pop() === 'pinned'){
                this.category = { title: 'Gepinnte Artikel' };
            }


            this.cmsService.globalCms.loading = false;
        });
        
        promises.push(teaser_promise);
        promises.push(category_promise);
        return Promise.all(promises);
    }

    openDocument(document){
      this.fileService.openDocument(document._id);
    }

    backLink() {
        console.log('Zurück???');
        this.$window.history.back();
    }
}

CmsListController.$inject = $inject;