export const BONUS_DURATION = [
  {
    id: 1,
    name: 'one_time',
    display: 'einmalig',
    description: ''
  },
  {
    id: 2,
    name: 'continuous',
    display: 'laufend',
    description: ''
  }
];
export const BONUS_DURATION_BY_ID = BONUS_DURATION.reduce((acc, duration) => {
  acc[duration.id] = duration;
  return acc;
}, {});
export const BONUS_DURATION_BY_NAME = BONUS_DURATION.reduce((acc, duration) => {
  acc[duration.name] = duration;
  return acc;
}, {});
export const DEFAULT_BONUS_DURATION = BONUS_DURATION_BY_NAME['one_time']['id'];
