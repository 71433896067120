import { Side } from "./car-leasing.interfaces";
import * as moment from 'moment';

export class CarLeasingSide implements Side {
  public title: string;
  public is_order_delivered: boolean;
  public is_order_waiting: boolean;
  public brutto_value_month: string;
  public moment;

  isCarLeasing = true; // TODO remove when using real start/end dates

  constructor(is_order_delivered: boolean, is_order_waiting: boolean, brutto_value_month: string, title: string = 'Auto Abo'){
    this.title = title;
    this.is_order_delivered = is_order_delivered;
    this.is_order_waiting = is_order_waiting;
    this.brutto_value_month = brutto_value_month;
    this.moment = moment;
  }

  totalBrutto() {
    throw Error('Not implemented')
  }

  perMonthValue() {
    return this.brutto_value_month;
  }

  getContractStartDate(format: string) {
    return this.moment().format(format); // TODO add real begin date
  }

  getContractEndDate(format: string) {
    return this.moment().format(format); // TODO add real end date
  }

}
