const $inject = [
  "customerService",
  "MenuConstants",
  "Made",
  "BonusService",
  "authenticationService",
  "BenefitBonagoService",
  'ACLService',
  'DirectBonusService',
  'employeeService',
  'NeoComponentsService'
];

export default class menuService {

  constructor(
    customerService,
    MenuConstants,
    Made,
    BonusService,
    authenticationService,
    BenefitBonagoService,
    ACLService,
    DirectBonusService,
    employeeService,
    NeoComponentsService
  ) {

    Object.assign(this, {
      customerService,
      MenuConstants,
      Made,
      BonusService,
      authenticationService,
      BenefitBonagoService,
      ACLService,
      DirectBonusService,
      employeeService,
      NeoComponentsService
    });

    this.menu = [];
    this.isFiduciaUser = authenticationService.iAm('fg_user');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
  }

  get() {
    this.menu.splice(0, this.menu.length);

    return this.customerService
      .getConfiguration()
      .then(async (configuration) => {
        var blacklist = [];

        if (configuration && configuration.modules) {
          for (let id in configuration.modules) {
            let module = configuration.modules[id];

            // check if module is benefits -> vorteskolio
            // if module is activated -> should be seen
            // if module is deactivated , but the user have UV CODE -> should be seen
            if (id === 'benefits') {
              const hasBonagoCode = await this.Made.request('rpc://shop/bonago/has_bonago_code', {
                'employee_id': this.Made.user.valuenet_id
              });
              if (hasBonagoCode) {
                continue;
                // we want to skip the "blacklist" if bonago code for benefits are available
              }
            }

            if (!module.enabled) {
              blacklist.push(id);
            }

          }
          //this is temporary solution because incent is the only module that needs this logic atm
          //a script has to be written so that we set explicitly the modules inside the customer
          let incentModuleId = 'incent'
          if (!configuration.modules[incentModuleId]) {
            blacklist.push(incentModuleId);
          }
        }

        const userId = this.Made.user.valuenet_id;
        const hasEmployeeActiveSelfServiceComponent = await this.NeoComponentsService.hasEmployeeActiveSelfServiceComponent(userId);
        if(!hasEmployeeActiveSelfServiceComponent) {
          blacklist.push('steuerfreie_extras');
        }

        if (!configuration.testmode) blacklist.push('testmode');

        return blacklist;
      }, () => [])
      .then(async (blacklist) => {

        for (let id in this.MenuConstants) {
          let module = this.MenuConstants[id];

          if (id === 'bonus_dashboard') {
            let bonus_projects = await this.BonusService.getBonusProjectsDisplayForUser();
            if (bonus_projects.length > 0) {
              this.menu.push({
                id: id,
                data: module
              });
            }
          } else if (!blacklist.includes(id)) {
            this.menu.push({
              id: id,
              data: module
            });
          }
        }

        let iAmBonusIssuer = await this.ACLService.amIBonusIssuer();
        if (iAmBonusIssuer || this.iAmSpecialAdvisor) {
          let hasDirectBonusProject = await this.DirectBonusService.bonusIssuerHasDirectBonusProject();

          if (hasDirectBonusProject) {
            this.menu.push({
              id: 'directBonusCustomerAdministration',
              data: {
                  title: 'Direkt-Bonus',
                  state: 'inApp.directBonusCustomerAdministration',
                  icon: 'support',
                  order: 10,
                  cyData: 'vn.navigation.header.modulesMenu.linkCustomerAdministration'
                }
            });
          }
        }

        return this.menu;
      });
  }
}

menuService.$inject = $inject;
