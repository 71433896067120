import {Component, Inject, Input, OnInit} from '@angular/core';
import {
  UserPreferencesData
} from "../dticket-vesputi-pdf-component/dticket-order-vesputi-pdf-dialog.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder} from "@angular/forms";
import {DticketOrderService, EmployeeUpdateData, LegacyEmployeeData} from "../../../services/dticket-order.service";
import {LoadingService} from "../../../../shared/services/loading.service";


@Component({
  selector: 'vn-dticket-order-activate-component',
  templateUrl: './dticket-order-activate-dialog.component.html',
  styleUrls: ['./dticket-order-activate-dialog.component.scss']
})
export class DticketOrderActivateDialogComponent implements OnInit {

  public canSendToVesputi: boolean = false;
  public dialogTitle: string = "Überprüfung Ihrer Daten";
  public dialogConfirmButton: string = "Wiederherstellen";
  public employeeData!: UserPreferencesData

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: UserPreferencesData,
    public dialogRef: MatDialogRef<DticketOrderActivateDialogComponent>,
    protected dticketService: DticketOrderService,
    protected loader: LoadingService,
    protected formBuilder: FormBuilder,
    protected dialog: MatDialog,
    @Inject('Made') protected made: any,
  ) {
  }


  ngOnInit(): void {
  }

  async handleFormSaved(savedData: LegacyEmployeeData|null) {
    if (savedData) {
      this.made.saveUser({
        ...this.made.user,
        ...savedData,
      });
    }
    this.loader.show();

    let issuerId = this.made.user.originalId || null;

    const restoredDticket = await this.dticketService.restoreDTicket(
      this.dialogData.dticketOrder.id,
      issuerId
    );
    if (!restoredDticket) {
      console.error(`An error occurred -> Dticket: ${this.dialogData.dticketOrder.id} could not be restored`)
    }
    this.loader.hide();
    this.dialogRef.close(true);
  }

}
