const $inject = [
  'employeeService'
];
export default class contactAdvisorController {

  constructor(
    employeeService
  ) {

    employeeService
      .getAdvisorInfo()
      .then(advisorDetails => this.advisorDetails = advisorDetails);
  }


}
contactAdvisorController.$inject = $inject;
