const $inject = [
  '$scope',
  'mppService',
  '$timeout',
  'NotificationService'
];
export default class AddNewBasketController {
  constructor(
    $scope,
    mppService,
    $timeout,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      mppService,
      $timeout,
      NotificationService
    });

    this.loading = false;
    this.valuenetID = this.$scope.ngDialogData.valuenetID;
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async basketChangeHandler(changeEvent) {
    this.$scope.confirm();
  }

}
AddNewBasketController.$inject = $inject;
