import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'vn-company-email-checkbox',
  templateUrl: './company-email-checkbox.component.html',
  styleUrls: ['./company-email-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompanyEmailCheckboxComponent
    }
  ]
})
export class CompanyEmailCheckboxComponent implements OnInit {

  public noticeLabel!: string;

  @Input()
  public switchKey!: string;

  @Output()
  isCompanyEmailValid$ = new EventEmitter<boolean>();

  @ViewChild(MatSlideToggle)
  slider!: MatSlideToggle;

  private formControlValue!: boolean;
  public get value() {
    return this.formControlValue;
  }

  private touched = false;

  public onTouched = () => { };
  public onChange = (_: boolean) => { };

  constructor() {
    this.noticeLabel = "Ich möchte meine Firmen E-Mail Adresse verwenden. Mir ist bewusst, dass im Falle von Problemen mit erhaltenen Gutscheinen zu Reklamationsverweigerungen durch den Dienstleister kommen kann, da ein Zugriff durch Firmenangehörige nicht ausgeschlossen sein kann. Hierfür kann der Dienstleister keine Verantwortung übernehmen.";
  }

  ngOnInit(): void {
  }

  public toggle(value: boolean) {
    this.isCompanyEmailValid$.emit(value);
  }

  public writeValue(value: boolean) {
    this.formControlValue = value;
  }

  public registerOnChange(onChange: (_: boolean) => any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
