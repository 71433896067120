const $inject = [
  '$location',
  '$state',
  '$scope',
  'Made',
  'dialogService',
  'vbmData',
  'vbmService',
  'employeeService',
  'CheckoutService',
  'customerService',
  'authenticationService',
  'mppService',
  'moment',
  'NotificationService',
  'userService',
  '$timeout'
];
export default class CheckoutExtrainfoController {

  constructor(
    $location,
    $state,
    $scope,
    Made,
    dialogService,
    vbmData,
    vbmService,
    employeeService,
    CheckoutService,
    customerService,
    authenticationService,
    mppService,
    moment,
    NotificationService,
    userService,
    $timeout
  ) {

    $scope.globalCheckoutCtrl.step = $scope.globalCheckoutCtrl.steps - 1;

    if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
      vbmData.employeeId = parseInt($location.search().employeeId);
    }

    this.NotificationService = NotificationService;
    this.vbmData = vbmData;
    this.vbmService = vbmService;
    this.CheckoutService = CheckoutService;
    this.dialogService = dialogService;
    this.$scope = $scope;
    this.$state = $state;
    this.Made = Made;
    this.mppService = mppService;
    this.employeeService = employeeService;
    this.moment = moment;
    this.$timeout = $timeout;

    this.busy = true;
    // this.setMinDates();
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmProxy = userService.iAmProxy();
    this.extraAdvisor = this.iAmGAdvisor || this.iAmAdvisor || this.iAmSpecialAdvisor || this.iAmProxy;
    this.newMpp = false;
    this.notMe = vbmData.employeeId != Made.user.valuenet_id;
    this.customer = 0;
    this.selectedAdvisor = 0;
    this.needMail = false;

    employeeService
      .get(vbmData.employeeId)
      .then(employee => {
        if (!employee.email) {
          this.employeeData = employee;
          this.needMail = true;
          this.busy = true;
        }

        this.customer = employee.customer_id;
        return customerService.getInsurancesByCustomerId(employee.customer_id);
      })
      .then(insurances => {
        this.insuranceDefault = insurances.default;
        this.insurances = insurances.insurances;

        if (typeof this.insuranceDefault === 'undefined') {
          this.insuranceDefault = '2';
        }
      })
      .then(() => {
        if (this.notMe) {
          this.selectedAdvisor = Made.user.valuenet_id;
          if (this.iAmSpecialAdvisor) {
            return customerService.getAdvisorsCheckout(this.customer);
          }
        }
      })
      .then(advisors => {
        this.advisors = advisors;
      });

    this.CheckoutService.getSession(vbmData.employeeId).then((session) => {
      this.checkout_session = session;
    });

    CheckoutService
      .getNeoExtras(vbmData.employeeId)
      .then(neoExtras => this.neoExtras = neoExtras)

      .then(() => vbmData.getData())
      .then(() => vbmData.getResult())
      .then(() => {

        this.employee = vbmData.employees[vbmData.employeeId];
        this.componentKeys = vbmData.employees[vbmData.employeeId].components.filter(component => component.component.vertrag_ag_an == 'AN' || component.component.vertrag_ag_an == null).map(component => component.key);

        return customerService
          .getConfigurationByEmployeeId(this.employee.data.metadata.employee_id)
          .then((configuration => {
            this.customer_configuration = configuration;

            return this.setMinDates();
          }));
      })
      .finally(() => {
        this.$timeout(() => {
          this.busy = false;
        });
      });

  }

  async setMinDates() {
    // if ( this.checkout_session.direct_contract.is_enabled && !this.checkout_session.skipped_documents_upload && this.moment().date() < 23 ) {
    //   month_buffer -= 1;
    // }

    this.vertragsabschlussMinDate = this.moment.utc().set({
      millisecond: 0,
      second: 0,
      minute: 0,
      hour: 0
    }).toDate();

    this.evBeginMinDate = await this.CheckoutService.getEvBeginMinDate();

    this.$timeout(() => {

      console.log('this.evBeginMinDate', this.evBeginMinDate.toISOString());
      // update evBegin
      this.neoExtras.evbegin = this.moment.utc(this.evBeginMinDate).toDate();


      // ensure no validation if super advisor
      if (this.iAmSpecialAdvisor) {
        this.evBeginMinDate = null;
        this.vertragsabschlussMinDate = null;
      }

      this.checkBavBegin();
    }, 0);

  }

  handleDirectContractChange() {
    this.busy = true;
    this.CheckoutService.saveSession(this.vbmData.employeeId, this.checkout_session)
      .then(() => {
        return this.setMinDates();
      })
      .finally(() => {
        this.busy = false;
      });
  }

  checkBavBegin() {
    if (this.employee.data.insurances.bav.beitrag) {
      if (this.neoExtras.noBav) {
        this.neoExtras.bavbegin = this.moment.utc(this.neoExtras.evbegin).toDate();
      } else {
        this.bavNotification = this.moment.utc(this.neoExtras.bavbegin).toDate();
        this.neoExtras.bavbegin = this.neoExtras.evbegin;
      }
    }
  }

  proceed() {

    if (!this.$scope.checkoutNeoExtrasForm.$valid) {
      this.NotificationService.error('The input data is invalid');
      return;
    }

    this.error = false;
    this.busy = true;

    if (typeof this.neoExtras.intversicherer !== 'undefined' && typeof this.neoExtras.intversicherer !== 'string') {
      this.neoExtras.intversicherer = '' + this.neoExtras.intversicherer;
    }

    if (typeof this.neoExtras.intversicherer === 'undefined' || this.neoExtras.intversicherer === 'None') {
      this.neoExtras.intversicherer = this.insuranceDefault;
    }

    this.CheckoutService
      .setNeoExtras(
        this.vbmData.employeeId,
        this.checkout_session.preCalculationId,
        this.neoExtras
      )
      .then(() => {

        if (this.Made.user.valuenet_id !== this.vbmData.employeeId) {
          this.$state.go('inApp.neo.side.checkout.zusatzvereinbarung', {
            employeeId: this.vbmData.employeeId,
          });
        } else {
          this.$state.go('inApp.neo.side.checkout.zusatzvereinbarung');
        }
      }).catch(() => {
      this.error = true;
      this.busy = false;
    });
  }

  openAdressDialog() {
    this.dialogService.employeeAddress(this.vbmData.employeeId)
      .then(() => {
        this.CheckoutService
          .getNeoExtras(vbmData.employeeId)
          .then(neoExtras => this.neoExtras = neoExtras);

      });
  }

  advisorSelection() {
    this.$scope.globalCheckoutCtrl.alternateAdvisor = this.selectedAdvisor;

    this.CheckoutService
      .setAlternateAdvisor(this.selectedAdvisor, this.customer, this.vbmData.employees[this.vbmData.employeeId].data)
      .then(result => {

        if (result) {
          this.vbmService.getResult(result, 'session', true)
            .then(response => {
              if (response.fix_id) {

                this.vbmService.getData(this.vbmData.employeeId, false);

                this.CheckoutService.enterSession(this.vbmData.employeeId, response.fix_id);
              }
            });
        }
      })
      .catch(error => {
        let message = error.error.message,
          errorDefault = {
            title: 'Fehler',
            text: 'Es ist ein Fehler aufgetreten',
            button: 'Schließen'
          };

        if (/unknown advisor/.test(message)) {
          errorDefault.text = 'Der gewählte Berater ist im Portal nicht aktiv.';
        }
        if (/is no advisor/.test(message)) {
          errorDefault.text = 'Der gewählte Berater hat im Portal nicht die Rolle "Berater".';
        }

        this.dialogService
          .question(errorDefault.text, errorDefault.title, errorDefault.button)
          .then(() => {
          })
          .catch(() => {
          });
      });
  }

  checkEmail() {
    this.employeeService.checkEmployeeByEmail(this.employeeData.email)
      .then(result => {
        if (result.exists) {
          this.$scope.checkoutNeoExtrasForm['ExtrainfoCtrl.employeeData.email'].$setValidity('unique', false);
          this.needMail = true;
        } else {
          this.employeeService.setEmployeeEmail(this.employeeData.email, this.vbmData.employeeId).then(result => this.needMail = !result);
        }
      });
  }

  resetUniqueMail() {
    this.$scope.checkoutNeoExtrasForm['ExtrainfoCtrl.employeeData.email'].$setValidity('unique', true);
  }

  test() {
    this.now = this.neoExtras.evbegin;
  }
}

CheckoutExtrainfoController.$inject = $inject;
