import contactAdvisorController from './contactAdvisor/contactAdvisorController';
import resumeCheckoutController from './resumeCheckout/resumeCheckoutController';
import cancelCheckoutMPPController from './cancelCheckoutMPP/cancelCheckoutMPPController';
import frageDialogController from './frageDialog/frageDialogController';
import changeMailController from './changeMail/changeMailController';
import userNotificationController from './userNotification/userNotificationController';
import StatusLogController from './statusLog/statusLogController';
import AddNeoBikeDialogController from './add_bike_dialog/addNeoBikeDialogController';
import DeclineApprovalProcessController from './decline_approval_process/declineApprovalProcess';
import ChangeEmployeeStateDialogueController from './change_employee_state/ChangeEmployeeStateDialogueController';
import MissingDocumentDialogController from './missing_document/MissingDocumentDialogController';
import CarLeasingTicketDialogController from './car-leasing/ticket/car-leasing-ticket-dialog.controller';
import CarLeasingCancellationDialogController from './car-leasing/cancellation/car-leasing-cancellation-dialog.controller';
import CancelBoRequestDialogController from './cancel_bo_digital_signing_request_dialog/cancel-bo-request-dialog.controller';
import CarLeasingFirstCheckDialogController from './car-leasing/first-check/car-leasing-first-check-dialog.controller';
import GenerateInvoicesDialog from './special_admin/invoices/GenerateInvoicesDialog.controller';
import CarLeasingContractProcessDialogController from './car-leasing/contract-process/car-leasing-contract-process-dialog.controller'

angular
  .module( 'VSPApp' )
  .controller( 'contactAdvisorController', contactAdvisorController )
  .controller( 'resumeCheckoutController', resumeCheckoutController )
  .controller( 'cancelCheckoutMPPController', cancelCheckoutMPPController )
  .controller( 'frageDialogController', frageDialogController )
  .controller( 'changeMailController', changeMailController )
  .controller( 'userNotificationController', userNotificationController)
  .controller( 'StatusLogController', StatusLogController)
  .controller( 'AddNeoBikeDialogController', AddNeoBikeDialogController)
  .controller( 'DeclineApprovalProcessController', DeclineApprovalProcessController)
  .controller( 'ChangeEmployeeStateDialogueController',  ChangeEmployeeStateDialogueController)
  .controller( 'MissingDocumentDialogController', MissingDocumentDialogController)
  .controller( 'CarLeasingTicketDialogController', CarLeasingTicketDialogController)
  .controller( 'CarLeasingCancellationDialogController', CarLeasingCancellationDialogController)
  .controller( 'CarLeasingFirstCheckDialogController', CarLeasingFirstCheckDialogController)
  .controller( 'CarLeasingContractProcessDialogController', CarLeasingContractProcessDialogController)
  .controller( 'CancelBoRequestDialogController', CancelBoRequestDialogController)
  .controller( 'GenerateInvoicesDialogController', GenerateInvoicesDialog)
;
