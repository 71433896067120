import config from './config';
import run from './run';
import './routes/benefit.routes';
import './routes/blank.routes';
import './routes/cashback.routes';
import './routes/cockpit.routes';
import './routes/feedback.routes';
import './routes/imprint.routes';
import './routes/toc.routes';
import './routes/inapp.routes';
import './routes/landing.routes';
import './routes/meineakte.routes';
import './routes/mpp.routes';
import './routes/shop.routes';
import './routes/vcp.routes';
import './routes/administration.routes';

import './routes/datev.routes';

import './routes/versicherung.routes';
import './routes/vorsorge.routes';
import './routes/nextcloud.routes';
import './routes/cms.routes';

import './routes/neo/neo.routes';
import './routes/neo/neo.checkout.routes';

import './routes/dashboard/dashboard.arbeitgeber.routes';
import './routes/dashboard/dashboard.arbeitnehmer.routes';
import './routes/dashboard/dashboard.berater.routes';

import './routes/bonus.routes';
import './routes/support.routes';

import './routes/yearly.routes';

import './routes/car-leasing.routes';
import './routes/checkout.routes';

import './routes/direct-bonus.routes';

angular
  .module('VSPApp')
  .config(config)
  .run(run);

