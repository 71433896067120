const StatesDEConstants = [
  {
    value: 'BADEN_WUERTTEMBERG',
    name: 'Baden-Württemberg'
  },
  {
    value: 'BAYERN',
    name: 'Bayern'
  },
  {
    value: 'BERLIN_WEST',
    name: 'Berlin West'
  },
  {
    value: 'BERLIN_OST',
    name: 'Berlin Ost'
  },
  {
    value: 'BRANDENBURG',
    name: 'Brandenburg'
  },
  {
    value: 'BREMEN',
    name: 'Bremen'
  },
  {
    value: 'HAMBURG',
    name: 'Hamburg'
  },
  {
    value: 'HESSEN',
    name: 'Hessen'
  },
  {
    value: 'MECKLENBURG_VORPOMMERN',
    name: 'Mecklenburg-Vorpommern'
  },
  {
    value: 'NIEDERSACHSEN',
    name: 'Niedersachsen'
  },
  {
    value: 'NORDRHEIN_WESTFALEN',
    name: 'Nordrhein-Westfalen'
  },
  {
    value: 'RHEINLAND_PFALZ',
    name: 'Rheinland-Pfalz'
  },
  {
    value: 'SAARLAND',
    name: 'Saarland'
  },
  {
    value: 'SACHSEN',
    name: 'Sachsen'
  },
  {
    value: 'SACHSEN_ANHALT',
    name: 'Sachsen-Anhalt'
  },
  {
    value: 'SCHLESWIG_HOLSTEIN',
    name: 'Schleswig-Holstein'
  },
  {
    value: 'THUERINGEN',
    name: 'Thüringen'
  }
];

export default StatesDEConstants;
