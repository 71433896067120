const $inject = [
  '$scope', 'BenefitService', '$timeout', 'BenefitBonagoService'
];

class BenefitController {
  constructor($scope, BenefitService, $timeout, BenefitBonagoService) {
    Object.assign(this, {
      $scope, BenefitService, $timeout, BenefitBonagoService
    });

    this.loading = {
      main: true
    };
    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$scope.submenus = [
      {
        sref: 'inApp.benefit.bonago.side.home',
        title: 'Gutscheine',
      },
      {
        sref: 'inApp.benefit.amazon',
        title: 'Amazon',
      },
      {
        sref: 'inApp.benefit.bonago.side.home',
        title: 'Reisen',
      }
    ];

    await this.BenefitService.getBalance().then((result) => {
      this.$scope.balance = parseFloat(result);
    });
  }
}

BenefitController.$inject = $inject;

angular
  .module('VSPApp')
  .controller('BenefitController', BenefitController);
