const $inject = ["Made"];

export default class ShopService {

  constructor(Made) {

    Object.assign(this, {
      Made
    });

  }


  loadCategories() {
    return this.Made.request('rpc://shop/mvp/categories');
  }

  highlightedCategories() {
    return this.Made.request('rpc://shop/mvp/highlighted_categories');
  }


  getImageSrc(offer) {

    if (!offer.post_images) {
      return;
    }

    return offer.post_images[Object.keys(offer.post_images)[0]];
  }


  loadOffer(oid) {
    return this.Made.request('rpc://shop/mvp/offer_by_id', {
      oid
    });
  }


  loadOffers(category) {
    return this.Made.request('rpc://shop/mvp/offers', {
      category
    });
  }


  getAjaxLink(offer) {
    return this.Made.request('rpc://shop/mvp/get_ajax_link', {
      ajax: offer.gutscheincode_ajax
    });
  }
}

ShopService.$inject = $inject;