import {
    BONUS_PROJECT_BUDGET_FIELDS_BY_PROP,
    DEFAULT_BONUS_PROJECT_BUDGET_FIELDS
} from "../../../../services/bonus/default_bonus_project_budget_fields";
import {
  BONUS_PROJECT_TYPES,
  BONUS_PROJECT_TYPES_BY_ID,
  BONUS_PROJECT_TYPES_BY_NAME
} from "../../../../services/bonus/bonus_project_types";
import {DEFAULT_BONUS_PROJECT_BUDGET} from "../../../../services/bonus/default_bonus_project_budget";
import {BONUS_DURATION, BONUS_DURATION_BY_NAME} from "../../../../services/bonus/bonus_duration";
import {BONUS_TYPES} from "../../../../services/bonus/bonus_type";
import {DEFAULT_BONUS_PROJECT} from "../../../../services/bonus/default_bonus_project";
import {DEFAULT_BONUS_CONFIG} from "../../../../services/bonus/default_bonus_config";

const $inject = [
  '$scope', 'BonusService', 'customerService', '$q', '$timeout', 'NotificationService', 'Made', 'moment'
];
export default class BonusDialogController {
  constructor($scope, BonusService, customerService, $q, $timeout, NotificationService, Made, moment) {
    Object.assign(this, {
      $scope,
      BonusService,
      customerService,
      $q,
      $timeout,
      NotificationService,
      Made,
      moment
    });

    // defaults
    this.customer_id = this.$scope.ngDialogData.customer_id;
    this.employee_ids = this.$scope.ngDialogData.employee_ids;
    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;
    this.BONUS_DURATION = BONUS_DURATION;
    this.BONUS_DURATION_BY_NAME = BONUS_DURATION_BY_NAME;
    this.today = this.moment().utc().set({millisecond: 0, second: 0, minute: 0, hour: 0});
    this.loading = true;
    let promises = {};
    this.forms = {};
    this.bonusForEmployee = {};
    this.bonus_config = this.BonusService.getDefaultBonusConfig();
    this.blocked_components = {};

    // get customer
    promises.bonus_project = this.BonusService
      .getBonusProject(this.customer_id, this.bonus_project_id)
      .then(bonus_project => {
        // note project
        this.bonus_project = bonus_project;
        this.bonus_config.bonus_project = this.bonus_project['_id'];
        this.bonus_config.budget = angular.copy(this.bonus_project.budget_config);

        this.set_allowed_fields();
      });

    // get all employees
    promises.employees = this.customerService
      .getEmployees(this.$scope.ngDialogData.customer_id, new Date().getTime(), true, true, true, this.employee_ids)
      .then(employees => {
        // note employees
        this.employees = employees;

        // note who receives bonus
        this.employees.forEach(employee => {
          this.bonusForEmployee[employee.id] = true;
        });
      });

    this.$q.all(promises)
      .then(() => {

      })
      .finally(() => {
        this.$timeout(() => {
          this.loading = false;
        }, 0);
      });

  }

  set_allowed_fields() {
    // extract allowed fields
    this.$timeout(() => {
      this.allowed_fields = Object.keys(this.bonus_config.budget).reduce((acc, component_key) => {
        if (this.bonus_config.budget[component_key].enabled && !this.blocked_components[component_key]) {
          acc.push(BONUS_PROJECT_BUDGET_FIELDS_BY_PROP[component_key]);
        }
        return acc;
      }, []);
    }, 0);
  }

  isDialogValid() {
    return this.areFormsValid() && Object.values(this.bonusForEmployee).reduce((acc, value) => {
      return acc || value;
    }, false);
  }

  handleStartDateChange() {
    if (this.bonus_config.duration === this.BONUS_DURATION_BY_NAME['one_time']['id']) {
      this.bonus_config.dates.bonus_end_date = this.bonus_config.dates.bonus_start_date;
    }
    this.check_plausibility();
  }

  check_plausibility() {
    this.loading = true;
    this.BonusService.get_blocked_components(this.employee_ids, this.bonus_config.dates.bonus_start_date, this.bonus_config.dates.bonus_end_date).then(result => {
      this.blocked_components = result.reduce((acc, component_key) => {
        acc[component_key] = true;
        return acc;
      }, {});

      this.set_allowed_fields();
      // this.$timeout(()=>{
      //   this.set_allowed_fields();
      // }, 5000);

      // this.$timeout(()=>{
      //   this.blocked_components = [];
      //   this.set_allowed_fields();
      // }, 6000);
    }).finally(() => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    });
  }

  handleEndDateChange(to_check_plausibility) {
    if (to_check_plausibility) {
      this.check_plausibility();
    }
  }

  handleBonusDurationChange() {
    this.bonus_config.dates.bonus_end_date = this.bonus_config.dates.bonus_start_date;
  }

  areFormsValid() {
    let are_forms_valid = true;

    Object.values(this.forms).forEach((form) => {
      if (!form) return are_forms_valid;
      are_forms_valid = are_forms_valid && form.$valid;
    });

    return are_forms_valid;
  }

  getDate(instance) {
    return this.BonusService.getDate(instance);
  }

  giveBonus() {

    // create employees
    let employees_getting_a_bonus = Object.keys(this.bonusForEmployee).reduce((acc, valuenet_id) => {
      acc[valuenet_id] = this.BonusService.getEmployeeBonusConfig(valuenet_id, this.bonus_config.budget.total);
      return acc;
    }, {});
    this.bonus_config.employees = employees_getting_a_bonus;

    this.BonusService.giveBonus(this.bonus_config).then(() => {
      this.NotificationService.message('Bonus wurde gewährt');
      this.$scope.closeThisDialog();
    }).catch(() => {
      this.NotificationService.error('Die Änderung konnte nicht gespeichert werden');
    });
  }
}
BonusDialogController.$inject = $inject;
