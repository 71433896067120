const CalculationResultNameConstants = {
  lohnsteuer:               'Lohnsteuer',
  solidarzuschlag:          'Solidaritätszuschlag',
  kirchensteuer:            'Kirchensteuer',
  krankenversicherung:      'Krankenversicherung',
  pflegeversicherung:       'Pflegeversicherung',
  rentenversicherung:       'Rentenversicherung',
  arbeitslosenversicherung: 'Arbeitslosenversicherung',
  kstsatz:                  'Kirchensteuersatz',
  t_auszahlung:             'Nettogehalt',
  net_minderung:            'Netto Minderung',
  net_netto:                'net_netto',
  net_verdienst:            'Netto Verdienst',
  brutto_steuer:            'Steuer-Brutto',
  brutto_sv:                'SV-Brutto',
  pap_kst:                  'Kirchensteuer',
  pap_lst:                  'Lohnsteuer',
  pap_szt:                  'Solidaritätszuschlag',
  sv_alos:                  'Arbeitslosenversicherung',
  sv_brutto:                'Brutto (SV)',
  sv_kv_ag:                 'Krankenversicherung (AG)',
  sv_kv_an:                 'Krankenversicherung',
  sv_pflege_ag:             'Pflegeversicherung (AG)',
  sv_pflege_an:             'Pflegeversicherung',
  sv_rente:                 'Rentenbeitrag',
  sv_sozialabgaben_ag:      'Sozialabgaben (AG)',
  sv_sozialabgaben_an:      'Sozialabgaben',
  luecke_rente:             'Rentenlücke'
};

export default CalculationResultNameConstants;
