import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BonagoBasketItem, BonagoRetailer, BonagoRetailerAddress, BonagoRetailerBasic } from 'src/app/direct-bonus/models/benefit-bonago.model';
import { BenefitMapService } from 'src/app/direct-bonus/services/benefit-map.service';
import { BenefitsBonagoBasketService } from 'src/app/direct-bonus/services/benefits-bonago-basket.service';
import { BenefitsBonagoService } from 'src/app/direct-bonus/services/benefits-bonago.service';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';

// ===============================================================

interface FormOptions {
  voucherAmount: Observable<SelectOption[]>,
  voucherQuantity: string[]
}

interface FormChoices {
  voucherQuantity: string,
  voucherAmount: string,
}

// ===============================================================

@Component({
  selector: 'vn-benefit-details',
  templateUrl: './benefit-details.component.html',
  styleUrls: ['./benefit-details.component.scss']
})
export class BenefitDetailsComponent implements OnInit {

  public retailerId: number = parseInt(this.$state.params.retailerId);

  public retailer$: Observable<BonagoRetailer> = this.benefitService.getBonagoRetailer$(this.retailerId).pipe(
    shareReplay()
  );

  public addresses$: Observable<BonagoRetailerAddress[]> = this.retailer$.pipe(
    map(retailer => retailer.addresses)
  );

  public showMaps$: Observable<boolean> = this.benefitService.userBonagoScheme$.pipe(
    map((scheme => scheme.enableGoogleMaps))
  );

  public showErrors = false;

  public formGroup!: FormGroup;
  public formOptions!: FormOptions;

  public get voucherAmount(): FormControl {
    return this.formGroup.get('voucherAmount') as FormControl;
  }

  public get voucherQuantity(): FormControl {
    return this.formGroup.get('voucherQuantity') as FormControl;
  }

  // this.staticMap = this.generateStaticMapUrl(retailerAdresses[0]);


  constructor(
    @Inject('$state') private $state: any,
    private formBuilder: FormBuilder,
    private benefitService: BenefitsBonagoService,
    private basketService: BenefitsBonagoBasketService,
    private mapService: BenefitMapService,
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.initFormOptions();
  }

  public generateStaticMapUrl(address: BonagoRetailerAddress) {
    return `https://maps.googleapis.com/maps/api/staticmap?markers=${address.lat},${address.lng}&zoom=17,4&size=300x300&map_id=${this.mapService.detailsMapId}&key=AIzaSyBzm2AJUd6IhAh34SSFlqhsvwy5OdHH7Uc`;
  }

  public goToOverviewMap(address: BonagoRetailerAddress) {
    this.benefitService.selectCategoryId(0);

    this.$state.go('benefitsMap', {
      lat: address.lat,
      lng: address.lng
    });
  }

  public fetchVoucherAmountOptions() {
    this.benefitService.fetchRetailer(this.retailerId);
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      voucherQuantity: this.formBuilder.control(undefined, [
        Validators.required
      ]),
      voucherAmount: this.formBuilder.control(undefined, [
        Validators.required
      ]),
    });
  }

  private initFormOptions() {
    this.formOptions = {
      voucherAmount: this.retailer$.pipe(
        map(retailer => retailer.vouchers.map(
          voucher => ({
            name: voucher.displayValue,
            value: `${voucher.voucherAmount}_${voucher.id}`
          })
        )),
      ),
      voucherQuantity: Array.from(Array(10).keys()).map(x => (x + 1).toString())
    };
  }

  public addToBasket(retailer: BonagoRetailer) {
    if (!this.formGroup.valid) {
      this.showErrors = true;
      return;
    }

    const {
      voucherQuantity,
      voucherAmount
    } = this.formGroup.value as FormChoices;

    let [
      amount,
      id
    ] = voucherAmount.split('_');

    const item: BonagoBasketItem = {
      retailer: retailer,
      voucherId: id,
      amount: parseInt(amount),
      quantity: parseInt(voucherQuantity),
      isAvailable: true,
    };

    this.basketService.addToBasket(item);
  }
}
