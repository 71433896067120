import administration_template from '../../modules/administration/administration.pug';
import administration_header from '../../helpers/header.main.pug';
import employee_list_template from '../../modules/administration/employeeList/employeeList.pug';
import document_administration_template from '../../modules/administration/document_templates/document_administration.pug';
import bonus_administration_template from '../../modules/administration/bonus_administration/bonus_administration.pug';
import administration_mpp_template from '../../modules/administration/mpp/administration_mpp.pug';
import administration_mpp_approvals_template from '../../modules/administration/mpp/approvals/administration_mpp_approvals.pug';
import administration_mpp_edit_template from '../../modules/administration/mpp/editable/administration_mpp_edit.pug';
import administration_telecommunication_template from '../../modules/administration/telecommunication/telecommunication_administration.pug';
import administration_datev_template from '../../modules/administration/datev/administration_datev.pug';
import administration_datev__create_template from '../../modules/administration/datev/create/administration_datev_create.pug';
import administration_datev_edit_template from '../../modules/administration/datev/create/administration_datev_create.pug';
import administration_datev_list_template from '../../modules/administration/datev/list/administration_datev_list.pug';
import administration_bonago_template from '../../modules/administration/bonago/administration_bonago.pug';
import administration_bike_approval_template from '../../modules/administration/bike_approval/bike_approval.pug';
import administration_special_admin_template from '../../modules/administration/special_admin_panel/specialAdministration.pug';
import administration_yearly_template from '../../modules/administration/yearly/administration_yearly.pug';
import administration_mpp_calculation from '../../modules/administration/mpp/calculation/mpp_calculation.pug';
import { BackofficeAdministrationComponent} from '../../../app/direct-bonus/components/backoffice/backoffice-administration/backoffice-administration.component'
import { AdministrationMppImportLeasingdataComponent } from '../../../app/administration/components/mpp/import-leasingdata/administration-mpp-import-leasingdata/administration-mpp-import-leasingdata.component'
import { AdministrationHrmonyFileImportComponent } from '../../../app/administration/components/hrmony/file-import/administration-hrmony-file-import/administration-hrmony-file-import.component'
import { AdministrationConfigurationComponent } from "../../../app/administration/components/incent/administration-configuration/administration-configuration.component";
import { AdministrationCashbackReviewComponent } from "../../../app/cashback/administration/review-request/administration-cashback-review/administration-cashback-review.component";
import {
  DTicketSearchEmployeesComponent
} from "../../../app/dticket-order/components/administration/dticket-search-employees/dticket-search-employees.component";


angular
  .module('VSPApp')
  .config(['$stateProvider',  ($stateProvider) => {

    const authorized = ['authenticationService', authenticationService => authenticationService.iMustBe([
      'sa_employee_administration'
    ])];

    const document_administrator_authorization = ['authenticationService', authenticationService => authenticationService.iMustBe([
      'document_administrator'
    ])];

    const bonus_administrator_authorization = ['authenticationService', authenticationService => authenticationService.iMustBe([
      'sa_employee_administration'  // for now, later we must add a different role.
    ])];

    const special_admin_authorization = ['authenticationService', authenticationService => authenticationService.iMustBe([
      'special_admin'  // for now, later we must add a different role.
    ])];

    const datev_admin_authorization = ['authenticationService', authenticationService => authenticationService.iMustBe([
      'datev_admin'
    ])];

    $stateProvider
      .state('inApp.administration', {
        url: '/administration',
        resolve: {
          authorized: authorized
        },
        views: {
          main: {
            template: administration_template,
            controller: 'AdministrationController',
            controllerAs: 'AdministrationCtrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'Administration',
          parent: 'blank.home'
        }
      })
      .state('inApp.administration.neo', {
        url: '/neo?employeeId',
        params: {
          employeeId: null
        },
        views: {
          administrationMain: {
            template: employee_list_template,
            controller: 'EmployeeListController',
            controllerAs: 'EmployeeListCtrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Neo'
        }
      })
      .state('inApp.administration.document_templates', {
        url: '/document_templates',
        resolve: {
          authorized: document_administrator_authorization
        },
        views: {
          administrationMain: {
            template: document_administration_template,
            controller: 'DocumentAdministrationController',
            controllerAs: '$ctrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'Documents'
        }
      })
      .state('inApp.administration.bonus', {
        url: '/bonus?employeeId',
        params: {
          employeeId: null
        },
        resolve: {
          authorized: bonus_administrator_authorization
        },
        views: {
          administrationMain: {
            template: bonus_administration_template,
            controller: 'BonusAdministrationController',
            controllerAs: 'BonusAdminCtrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'Bonus'
        }
      })

      .state('inApp.administration.mpp', {
        url: '/mpp',
        views: {
          administrationMain: {
            template: administration_mpp_template,
            controller: 'MPPAdministrationController',
            controllerAs: 'CMppCtrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'MPP verwalten'
        }
      })

      .state('inApp.administration.mpp.calculate', {
        url: '/calculate',
        views: {
          administrationMain: {
            template: administration_mpp_calculation,
            controller: 'AdministrationMppCalculationController',
            controllerAs: '$ctrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'MPP'
        }
      })

      .state('inApp.administration.mpp.import-leasingdata', {
        url: '/import-leasingdata',
        views: {
          administrationMain: {
            component: AdministrationMppImportLeasingdataComponent
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'MPP'
        }
      })

      .state('inApp.administration.mpp.approvals', {
        url: '/approvals',
        views: {
          administrationMain: {
            template: administration_mpp_approvals_template,
            controller: 'AdministrationMppApprovalsController',
            controllerAs: 'cmApprovals'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'MPP Approvals'
        }
      })

      .state('inApp.administration.mpp.edit', {
        url: '/edit?employeeId?orderId',
        params: {
          employeeId: null,
          orderId: null
        },
        views: {
          administrationMain: {
            template: administration_mpp_edit_template,
            controller: 'AdministraitonMppEditController',
            controllerAs: 'cmEdit'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'MPP Edit'
        }
      })

      .state('inApp.administration.car-leasing', {
        url: '/car-leasing?employeeId',
        params: {
          employeeId: null,
        },
        views: {
          administrationMain: {
            template: require('../../modules/administration/car-leasing/administration_car_leasing.pug'),
            controller: 'AdministrationCarLeasingController',
            controllerAs: '$ctrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'Auto Abo Edit'
        }
      })

      .state('inApp.administration.cashback', {
        url: '/cashback?employeeId?customerId?bonusId',
        params: {
          employeeId: null,
          customerId: null,
          bonusId: null
        },
        views: {
          administrationMain: {
            template: require('../../modules/administration/cashback/administration_cashback.pug'),
            controller: 'AdministrationCashbackController',
            controllerAs: '$ctrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'Cashback'
        }
      })

      .state('inApp.administration.telecommunication', {
        url: '/telecommunication',
        views: {
          administrationMain: {
            template: administration_telecommunication_template,
            controller: 'TelecommunicationAdministrationController',
            controllerAs: '$ctrl'
          },
          header: {
            template: administration_header
          }
        },
        ncyBreadcrumb: {
          label: 'telecommunication'
        }
      })

      .state('inApp.administration.datev', {
        url: '/datev',
        resolve: {
          authorized: datev_admin_authorization
        },
        views: {
          administrationMain: {
            template: administration_datev_template,
            controller: 'AdministrationDatevController',
            controllerAs: 'CDatevCtrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Datev'
        }
      })

      .state('inApp.administration.datev.create', {
        url: '/create',
        views: {
          administrationMain: {
            template:  administration_datev__create_template,
            controller: 'AdministrationDatevCreateController',
            controllerAs: 'CDatevCreateCtrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Create'
        }
      })

      .state('inApp.administration.datev.edit', {
        url: '/edit?:articleId',
        views: {
          administrationMain: {
            template: administration_datev_edit_template,
            controller: 'AdministrationDatevCreateController',
            controllerAs: 'CDatevCreateCtrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Edit'
        }
      })

      .state('inApp.administration.datev.list', {
        url: '/list',
        views: {
          administrationMain: {
            template: administration_datev_list_template,
            controller: 'AdministrationDatevListController',
            controllerAs: 'CDatevListCtrl'
          }
        },
        ncyBreadcrumb: {
          label: 'List'
        }
      })

      .state('inApp.administration.bonago', {
        url: '/bonago',
        views: {
          administrationMain: {
            template: administration_bonago_template,
            controller: 'AdministrationBonagoController',
            controllerAs: '$ctrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Bonago'
        }
      })


      .state('inApp.administration.bike_approval', {
        url: '/fahrrad',
        views: {
          administrationMain: {
            template: administration_bike_approval_template,
            controller: 'AdministrationBikeApprovalController',
            controllerAs: '$ctrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Fahrrad'
        }
      })

      .state('inApp.administration.special_admin_panel', {
        url: '/special_admin_panel',
        resolve: {
          authorized: special_admin_authorization
        },
        views: {
          administrationMain: {
            template: administration_special_admin_template,
            controller: 'SpecialAdministrationController',
            controllerAs: '$ctrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Special Administration Panel'
        }
      })

      .state('inApp.administration.directBonusBackofficeAdministration', {
        url: '/directbonus-administration',
        views: {
          administrationMain: {
            component: BackofficeAdministrationComponent
          }
        },
        ncyBreadcrumb: {
          label: 'Direkt Bonus'
        }
      })

      .state('inApp.administration.hrmony', {
        url: '/hrmony',
        views: {
          administrationMain: {
            component: AdministrationHrmonyFileImportComponent
          }
        },
        ncyBreadcrumb: {
          label: 'Direkt Bonus'
        }
      })

      .state('inApp.administration.dticket', {
        url: '/dticket?employeeId?dticketId',
        params: {
          employeeId: null,
          dticketId: null
        },
        views: {
          administrationMain: {
            component: DTicketSearchEmployeesComponent
          }
        },
        ncyBreadcrumb: {
          label: 'DTicket JOB'
        }
      })

      .state('inApp.administration.incent', {
        url: '/incent',
        views: {
          administrationMain: {
            component: AdministrationConfigurationComponent
          }
        },
        ncyBreadcrumb: {
          label: 'Incent'
        }
      })

      .state('inApp.administration.cashback_review', {
        url: '/cashback_review?:bonus_id',
        views: {
          administrationMain: {
            component: AdministrationCashbackReviewComponent
          }
        },
        ncyBreadcrumb: {
          label: 'Cashback Review'
        }
      });

    $stateProvider
      .state('inApp.administration.yearly', {
        url: '/yearly',
        views: {
          administrationMain: {
            template: administration_yearly_template,
            controller: 'CockpitYearlyController',
            controllerAs: '$ctrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Yearly'
        }
      });
  }]);
