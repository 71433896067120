import {
  NEO_COMPONENTS_WITH_DOCUMENTS
} from '../../../../services/customerService';

angular
  .module('VSPApp')
  .controller('CheckoutDokumenteController', [
    '$q',
    '$location',
    '$state',
    '$scope',
    'Made',
    'vbmData',
    'CheckoutService',
    'employeeService',
    'authenticationService',
    'fileService',
    function  (
      $q,
      $location,
      $state,
      $scope,
      Made,
      vbmData,
      CheckoutService,
      employeeService,
      authenticationService,
      fileService
    ) {
      $scope.openDocument = fileService.openDocument;

      this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');

      $scope.globalCheckoutCtrl.step = $scope.globalCheckoutCtrl.steps - 3;

      if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
        vbmData.employeeId = parseInt($location.search().employeeId);
        employeeService.get(vbmData.employeeId);
      }

      this.employeeId = vbmData.employeeId;

      this.texts = initTexts();

      function initTexts() {
        let components = {};

        NEO_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
          if (component.text) {
            components[component.id] = component.text;
          }
        });

        return components;
      }

      this.loading = true;
      this.neoDocuments = {};
      this.skipDocumentsForNeoComponent = {};
      this.showSkippedWarning = false;
      this.needDocuments = false;

      vbmData
        .getData()
        .then(() => vbmData.getResult())
        .then(result => {

          result.neo_api_ids.forEach(component => {
            if (this.texts[component]) {
              this.neoDocuments[component] = [];
              this.skipDocumentsForNeoComponent[component] = false;
              this.needDocuments = true;
            }
          });
          return this.getDocuments();
        })
        .then(() => {
          return CheckoutService.getSession(vbmData.employeeId);
        })
        .then(session => {
          this.checkout_session = session;
        })
        .finally(() => {
          this.loading = false;
        });


// Watchers for neoDocument uploads
      $scope.$watch('DokumenteCtrl.neoDocuments', (newValue, oldValue, $scope) => {
        if (!angular.equals(newValue, oldValue)) {
          Object.keys(newValue).forEach((key) => {
            if (!angular.equals(newValue[key], oldValue[key])) {
              $scope.DokumenteCtrl.skipDocumentsForNeoComponent[key] = false;
            }
          });
        }
      }, true);

      this.getDocuments = () => {
        let promises = {};

        promises.neoDocuments = CheckoutService
          .getNeoDocuments(vbmData.employeeId)
          .then(neoDocuments => {

            angular.forEach(neoDocuments, (documents, component) => {
              if (this.neoDocuments[component]) {
                this.neoDocuments[component] = documents;
                this.skipDocumentsForNeoComponent[component] = false;
              }
            });
          });

        return $q.all(promises);
      };


      this.save = () => {

        let promises = {};

        this.loading = true;
        this.checkout_session.skipped_documents_upload = !this.areDocumentsValid();

        promises.saveSession = CheckoutService.saveSession(vbmData.employeeId, this.checkout_session);
        promises.neoDocuments = CheckoutService.setNeoDocuments(this.neoDocuments, vbmData.employeeId);

        return $q.all(promises);
      };


      this.proceed = () => this
        .save()
        .then(() => {
          if (Made.user.valuenet_id != vbmData.employeeId) {
            $state.go('inApp.neo.side.checkout.einsatz', {
              employeeId: vbmData.employeeId
            });
          } else {
            $state.go('inApp.neo.side.checkout.einsatz');
          }
        }, () => this.loading = false);

      this.isSkippingDocuments = () => {
        for (let neoComponent in this.neoDocuments) {
          if (this.skipDocumentsForNeoComponent[neoComponent]) {
            return true;
          }
        }

        return false;
      };

      this.areNeoDocumentsValid = (toDisregardSkipped = false) => {
        for (let neoComponent in this.neoDocuments) {
          if (this.skipDocumentsForNeoComponent[neoComponent] && !toDisregardSkipped) {
            continue;
          }
          if (!angular.isArray(this.neoDocuments[neoComponent]) || (angular.isArray(this.neoDocuments[neoComponent]) && this.neoDocuments[neoComponent].length === 0)) {
            return false;
          }
        }

        return true;
      };

      this.shouldProceed = () => {
        if (this.iAmSpecialAdvisor) {
          return true;
        }
        return this.areDocumentsValid();
      };

      this.areDocumentsValid = () => {
        return this.areNeoDocumentsValid();
      };
    }])
;
