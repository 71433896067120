export default class erholungsbeihilfe {


  static calculate() {}


  static calculateMonthsFromValue( data, modifier, value, max = 12 ) {

    let months = 0;

    months = value / data.bonus_monthly_value;

    return Math.min( Math.floor( months ), max );
  }


}
