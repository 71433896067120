<h2 mat-dialog-title>
    Historie herunterladen
</h2>

<mat-dialog-content>
    <div class='row filters'>
        <form [formGroup]='filtersGroup'>
            <vn-select formControlName='year'
                       label="Jahrauswahl"
                       placeholder='Alle'
                       [withPlaceholder]="false"
                       [initValue]="currentYearString"
                       [options]='filterOptions?.year'>
            </vn-select>
        </form>
    </div>

    <mat-divider></mat-divider>

    <mat-list role="list">
        <mat-list-item role="listitem">
            Bonusprojekt ID: {{ bonusProjectId }}
        </mat-list-item>

        <mat-list-item role="listitem">
            Kunde ID: {{ customerId }}
        </mat-list-item>
    </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Schließen
    </button>

    <button mat-stroked-button
            [disabled]="!filtersGroup.valid"
            (click)="downloadTableAsFile()">
        <mat-icon class="white-icon">
            download
        </mat-icon>

        <span>
            Herunterladen
        </span>
    </button>
</mat-dialog-actions>