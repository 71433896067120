<h2 mat-dialog-title>
    Zahlungsart ändern
</h2>

<mat-dialog-content>
    <form [formGroup]="formGroup">
        <p>
            Sie ändern die Zahlungsart für diese Bestellung von SEPA-Lastschrift zu Banküberweisung.
            Bitte die neue Zahlungsart angeben und hinterlassen Sie eine Begründung dafür:
        </p>

        <vn-input formControlName="reason"
                  key="vn.directBonus.backofficeAdministration.changePaymentTypeDialog.inputReason"
                  placeholder=""
                  label="Begründung">
            <mat-error *ngIf="reason.hasError('required')">
                Eingabe fehlt.
            </mat-error>
        </vn-input>

        <p>
            Fügen Sie bitte in das untere Eingabefeld auch die URL auf das auftragsbezogene OTRS-Ticket ein:
        </p>

        <vn-input formControlName="ticketUrl"
                  key="vn.directBonus.backofficeAdministration.changePaymentTypeDialog.inputOTRSTicket"
                  placeholder=""
                  label="OTRS-Ticket URL">
            <mat-error *ngIf="ticketUrl.hasError('required')">
                Eingabe fehlt.
            </mat-error>
        </vn-input>

        <p>
            Nach dem Klick auf "Bestätigen" wird die Zahlungsart für diese Bestellung in Banküberweisung geändert.
        </p>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='save-button'
            mat-stroked-button
            data-cy='vn.directBonus.backofficeAdministration.changePaymentTypeDialog.buttonSubmit'
            [disabled]="!formGroup.valid"
            (click)="changePaymentType()">
        Bestätigen
    </button>
</mat-dialog-actions>