import {Injectable} from '@angular/core';

@Injectable()
export class BackOfficeFileUploadService {


  public constructor(
  ) {
  }

}
