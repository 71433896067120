import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";

import { EmailValidatorComponent } from "./components/email-validator/email-validator.component";
import { PhoneValidatorComponent } from "./components/phone-validator/phone-validator.component";
import { EmailAndPhoneValidatorComponent } from './components/email-and-phone-validator/email-and-phone-validator.component';
import { FormsModule } from "@angular/forms";
import { PrufenButtonComponent } from './components/prufen-button/prufen-button.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
  ],
  declarations: [
    EmailValidatorComponent,
    PhoneValidatorComponent,
    EmailAndPhoneValidatorComponent,
    PrufenButtonComponent,
  ],
  exports: [
    EmailValidatorComponent,
    PhoneValidatorComponent,
    EmailAndPhoneValidatorComponent,
    PrufenButtonComponent
  ],
  providers: []
})
class FormElementsModule { }

export { FormElementsModule };
