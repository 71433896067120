import { Component, OnInit, forwardRef, Inject } from '@angular/core';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";

@Component({
  selector: 'vn-honeypot-form',
  templateUrl: './honeypot-form.component.html',
  styleUrls: ['./honeypot-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HoneypotFormComponent)
    }
  ]
})
export class HoneypotFormComponent implements OnInit {

  public formGroup!: FormGroup;
  public isFormReady!: boolean;

  constructor(
    @Inject('$state') private $state: any,
    private formBuilder: FormBuilder,
    private loader: LoadingService,
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.honeyPotTimeInit();
  }

  private honeyPotTimeInit() {
    this.isFormReady = false;
    this.loader.show();
    setTimeout(() => {
      this.isFormReady = true;
      this.loader.hide();
    }, 2000);
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      'name': [undefined, [Validators.required]]
    });
  }

  public confirmAction() {
    this.$state.go('blank.cidaas_login');
  }
}
