import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { DirectBonusService } from 'src/app/direct-bonus/services/direct-bonus.service';
import { BehaviorSubject, Subscription } from "rxjs";
import {
  CustomerOrderFilterChoices,
  CustomerOrderStateValue,
  CustomerOrderTableDataBO
} from '../../../models/direct-bonus-customer-order.model';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import {
  DirectBonusBulkInvoiceDialogComponent
} from "../direct-bonus-bulk-invoice-dialog/direct-bonus-bulk-invoice-dialog.component";
import { BulkDialogData } from "../../../models/direct-bonus-table-dialog-data.model";
import { BaseComponent } from 'src/app/shared/models/base.component';
import { DirectBonusBulkPaidDialogComponent } from '../direct-bonus-bulk-paid-dialog/direct-bonus-bulk-paid-dialog.component';
import {
  DirectBonusBulkDoneDialogComponent
} from "../direct-bonus-bulk-done-dialog/direct-bonus-bulk-done-dialog.component";
import { LoadingService } from 'src/app/shared/services/loading.service';

// ===============================================================

interface FilterOptions {
  orderState: SelectOption<CustomerOrderStateValue>[];
}

interface FilterChoices {
  customerId: number | null;
  customerName: string | null;
  orderState: CustomerOrderStateValue | null;
  orderPeriod: Date | null;
  withOnHold: boolean;
}

// ===============================================================


@Component({
  selector: 'vn-backoffice-administration',
  templateUrl: './backoffice-administration.component.html',
  styleUrls: ['./backoffice-administration.component.scss'],
})
export class BackofficeAdministrationComponent extends BaseComponent implements OnInit, OnDestroy {
  private dataStreamSubscription!: Subscription;
  public dataStream$: BehaviorSubject<CustomerOrderTableDataBO[]> = new BehaviorSubject<CustomerOrderTableDataBO[]>([]);

  public filtersGroup!: FormGroup;
  public filterOptions!: FilterOptions;
  public isListFiltered: boolean = false;
  public canSearch: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private directBonusService: DirectBonusService,
    private dialog: MatDialog,
    private loader: LoadingService,
  ) {
    super();

    this.initFiltersForm();
    this.initFilterOptions();
  }

  ngOnInit() {
    this.loadDataStream();
  }

  ngOnDestroy(): void {
    this.dataStreamSubscription.unsubscribe();
  }

  // ===================== FILTERS =====================

  private initFiltersForm() {
    this.filtersGroup = this.formBuilder.group({
      customerId: this.formBuilder.control(undefined, []),
      customerName: this.formBuilder.control(undefined, []),
      orderState: this.formBuilder.control(undefined, []),
      orderPeriod: this.formBuilder.control(moment().toDate(), []),
      withOnHold: this.formBuilder.control(false, []),
    });
  }

  private initFilterOptions() {
    this.filterOptions = {
      orderState: [
        { name: "Entwurf", value: '1' },
        { name: "Bestellt", value: '2' },
        { name: "Fakturiert", value: '3' },
        { name: "Bezahlt", value: '4' },
        { name: "Storniert", value: '5' },
        { name: "Ausgeführt", value: '6' },
        { name: "Abgelaufen", value: '7' }
      ]
    };
  }

  // ===================== TABLE =====================

  public onTableDataChange() {
    this.loadDataStream(); // trigger refetch of data
  }

  public bulkOrderAsDone() {
    const dialogWidth = '420px';
    const dialogData: BulkDialogData = {
      orderFilters: this.getOrderFilters()
    }

    this.dialog.open(
      DirectBonusBulkDoneDialogComponent, {
        width: dialogWidth,
        data: dialogData
      }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.loadDataStream();
          }
        }
      );
  }

  public bulkOrderAsPaid() {
    const dialogWidth = '420px';
    const dialogData: BulkDialogData = {
      orderFilters: this.getOrderFilters()
    }

    this.dialog.open(
      DirectBonusBulkPaidDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.loadDataStream();
          }
        }
      );
  }

  public bulkInvoice() {
    const dialogWidth = '420px';
    const dialogData: BulkDialogData = {
      orderFilters: this.getOrderFilters()
    }

    this.dialog.open(
      DirectBonusBulkInvoiceDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.loadDataStream();
          }
        }
      );
  }

  public hasContextMenu() {
    return true;
  }

  public menuClick($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  private getOrderFilters(): CustomerOrderFilterChoices {
    let orderFilters: CustomerOrderFilterChoices;

    let orderState = null;
    let customerId = null;
    let customerName = null;
    let withOnHold = false;
    let forMonth = null;
    let forYear = null;
    
    if(!this.isFiltersFormEmpty()) {
      const filters: FilterChoices = this.filtersGroup.value;

      orderState = filters.orderState?.toString() ? parseInt(filters.orderState) : null;
      customerId = Number(filters.customerId) ? Number(filters.customerId) : null;
      customerName = filters.customerName ? filters.customerName : null;
      withOnHold = filters.withOnHold;

      if(filters.orderPeriod) {
        forMonth = moment(filters.orderPeriod).month() + 1;
        forYear = moment(filters.orderPeriod).year();
      }
    }

    orderFilters = {
      forMonth,
      forYear,
      customerName,
      customerId,
      orderState,
      withOnHold
    };

    return orderFilters;
  }

  public loadDataStream() {
    
    this.loader.show();
    const dataFilters = this.getOrderFilters();

    if(this.isFiltersFormEmpty()) {
      this.isListFiltered = false;
    }

    this.canSearch = false;
    
    this.dataStreamSubscription = this.directBonusService.getCustomerOrdersTableDataBO(dataFilters)
      .pipe(
        tap(data => {
          let filtered = data;
          if (dataFilters.customerName) {
            const re = new RegExp(dataFilters.customerName+'.+$', 'i');
            filtered = data.filter(item => item.customerName.search(re) != -1);
          }
          this.dataStream$.next(filtered);
        }),
      )
      .subscribe(
        () => {
          this.loader.hide();
          if(!this.isFiltersFormEmpty()) {
            this.isListFiltered = true;
          }
          setTimeout(() => {
            this.canSearch = true;
          }, 1000);
        }
      );
  }

  public clearFilters() {
    this.initFilterOptions();
    this.filtersGroup.patchValue({
      // There is issue in Angular code which for solving this we can send
      customerId: {}, // empty object for number inputs
      customerName: [], // empty array for text inputs
      orderPeriod: {}, // empty object for our date mixin component
      orderState: null,
      withOnHold: false,
      // To make form empty
    });
    this.loadDataStream();
    this.isListFiltered = false;
  }

  private isFiltersFormEmpty() {
    return Object.values(this.filtersGroup.value).every((val: any) => (
      !val 
      || val == false 
      || (val.constructor == Object && Object.keys(val).length == 0)
    ))
  }
}
