const $inject = [
  '$scope'
];

export class BenefitBonagoTocDialogController {
  constructor($scope) {
    Object.assign(this, {
      $scope
    });
  }

  confirm() {
    this.$scope.confirm(true);
  }

  reject() {
    this.$scope.confirm(false);
  }
}

BenefitBonagoTocDialogController.$inject = $inject;

angular
  .module('VSPApp')
  .controller('BenefitBonagoTocDialogController', BenefitBonagoTocDialogController);
