import { currency } from './shared';

export default class besonderer_anlass {

  static calculate( data ) {

    let value = 0;

    for (let i = 1; i <= 3; i++) {
      if ( data[ 'anlass' + i ] ) {
        value += (data[ 'betrag' + i ] || 0);
      }
    }

    data.value = currency( value );
  }
}
