const $inject = [
  'BonusService',
  'Made'
];

export default class BonusController {
  constructor(
    BonusService,
    Made
  ) {
    Object.assign(this, { BonusService, Made });

    this.init();
  }

  async init() {
    const valuenet_id = this.Made.user.valuenet_id;
    let employee_fid_info = await this.BonusService.getEmployeeFidInfo(valuenet_id);
    this.Made.user.fid_info = employee_fid_info;
    this.Made.saveUser(this.Made.user);
  }
}

BonusController.$inject = $inject;
