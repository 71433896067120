angular
  .module('VSPApp')
  .controller('BenefitAmazonController', ['$scope', 'BenefitBonagoService', function ($scope, BenefitBonagoService) {

    $scope.selectedRetailer = {
      LogoURL: '/assets/img/benefit/amazon-com-logo.svg',
    };

    $scope.selectedVoucher = {
      Denomination: 0
    };

    $scope.selectRetailer = function (retailer) {
      $scope.selectedRetailer = retailer;
    };

  }]);
