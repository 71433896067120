const $inject = [
  "$q",
  "Made"
];

export default class projectService {

  constructor($q, Made) {

    Object.assign(this, {
      $q,
      Made
    });

    Object.assign(this, {
      projects: {},
      employees: {},
      typesCache: null,
      kindsCache: null,
      alvKtgCompensationsCache: null,
      supplyCompensationsCache: null,
      targetsCache: null
    });
  }

  get(id) {
    var project = this.projects[id];

    if (project) {
      if (project.promise) {
        return project.promise;
      } else {
        return this.$q.when(project);
      }
    } else {
      project = {};
      project.promise = this.Made
        .request('rpc://valuenetdb/project/get', {
          id: id
        })
        .then(response => this.projects[id] = response);

      project.promise
        .finally(() => {
          delete project.promise;
        });

      return project.promise;
    }
  }

  create(data) {
    return this.Made
      .request('rpc://valuenetdb/project/create', {
        project: data
      })
      .then(response => this.projects[response.id] = response);
  }

  save(data /*, update_members*/) {
    this.projects[data.id] = data;
    data.time = '' + data.time;
    data.created = '' + data.created;

    return this.Made
      .request('rpc://valuenetdb/project/save', {
        project: data
        //TODO: check
        // uncomment if backend ready
        // update_members: update_members
      });
  }

  delete(data) {
    return this.Made
      .request('rpc://valuenetdb/project/delete', {
        id: data.id
      });
  }

  getEmployees(projectId) {
    var employees = this.employees[projectId];

    if (employees) {
      if (employees.promise) {
        return employees.promise;
      } else {
        return this.$q.when(employees);
      }
    } else {
      employees = {};
      employees.promise = this.Made
        .request('rpc://valuenetdb/project/employees', {
          id: projectId
        })
        .then(function (response) {
          this.employees[projectId] = {};

          response.map(function (el) {
            this.employees[projectId][el.id] = el.payment_id;
          });

          return this.employees[projectId];
        });

      employees.promise
        .finally(function () {
          delete employees.promise;
        });

      return employees.promise;
    }
  }

  types() {
    if (this.typesCache) {
      if (this.typesCache.promise) {
        return this.typesCache.promise;
      } else {
        return this.$q.when(this.typesCache);
      }
    } else {
      this.typesCache = {};
      this.typesCache.promise = this.Made
        .request('rpc://valuenetdb/project/types', {})
        .then(response => this.typesCache = response);

      return this.typesCache.promise;
    }
  }

  kinds() {
    if (this.kindsCache) {
      if (this.kindsCache.promise) {
        return this.kindsCache.promise;
      } else {
        return this.$q.when(this.kindsCache);
      }
    } else {
      this.kindsCache = {};
      this.kindsCache.promise = this.Made
        .request('rpc://valuenetdb/project/kinds', {})
        .then(response => this.kindsCache = response);

      return this.kindsCache.promise;
    }
  }

  alvKtgCompensations() {
    if (this.alvKtgCompensationsCache) {
      if (this.alvKtgCompensationsCache.promise) {
        return this.alvKtgCompensationsCache.promise;
      } else {
        return this.$q.when(this.alvKtgCompensationsCache);
      }
    } else {
      this.alvKtgCompensationsCache = {};
      this.alvKtgCompensationsCache.promise = this.Made
        .request('rpc://valuenetdb/project/alv_ktg_compensations', {})
        .then(response => this.alvKtgCompensationsCache = response);

      return this.alvKtgCompensationsCache.promise;
    }
  }

  supplyCompensations() {
    if (this.supplyCompensationsCache) {
      if (this.supplyCompensationsCache.promise) {
        return this.supplyCompensationsCache.promise;
      } else {
        return this.$q.when(this.supplyCompensationsCache);
      }
    } else {
      this.supplyCompensationsCache = {};
      this.supplyCompensationsCache.promise = this.Made
        .request('rpc://valuenetdb/project/supply_compensations', {})
        .then(response => this.supplyCompensationsCache = response);

      return this.supplyCompensationsCache.promise;
    }
  }

  targets() {
    if (this.targetsCache) {
      if (this.targetsCache.promise) {
        return this.targetsCache.promise;
      } else {
        return this.$q.when(this.targetsCache);
      }
    } else {
      this.targetsCache = {};
      this.targetsCache.promise = this.Made
        .request('rpc://valuenetdb/project/targets', {})
        .then(response => this.targetsCache = response);

      return this.targetsCache.promise;
    }
  }


}

projectService.$inject = $inject;