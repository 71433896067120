<h2 mat-dialog-title>
    {{ tocTitle }}
</h2>

<mat-dialog-content class="mat-typography">
    <p *ngFor="let tocTextPart of tocText">
        {{ tocTextPart }}
    </p>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <ng-container *ngIf="!readonlyDialog; else closeButton">
        <button mat-button
                mat-dialog-close>
            Ablehnen
        </button>

        <button mat-button
                [attr.data-cy]="buttonKey"
                [mat-dialog-close]="true"
                cdkFocusInitial>
            Akzeptieren
        </button>
    </ng-container>

    <ng-template #closeButton>
        <button mat-button
                mat-dialog-close>
            Schließen
        </button>
    </ng-template>
</mat-dialog-actions>