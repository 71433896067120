import {DEFAULT_BIKELEASING_CHECKOUT_CONFIG} from '../../services/administrationService';

const $inject = [
  '$scope',
  'administrationService',
  'Made',
  'lodash',
  'NotificationService',
  'bikeleasingService',
  'BIKELEASING_CONTRACT_REGEX'
];
export default class AddNeoBikeDialogController {
  constructor(
    $scope,
    administrationService,
    Made,
    lodash,
    NotificationService,
    bikeleasingService,
    BIKELEASING_CONTRACT_REGEX
  ) {

    Object.assign(this, {
      $scope,
      administrationService,
      Made,
      lodash,
      NotificationService,
      bikeleasingService,
      BIKELEASING_CONTRACT_REGEX
    });

    this.employee = this.lodash.get(this.$scope.ngDialogData, 'employee');
    this.bikeleasing_checkout_config = angular.copy(DEFAULT_BIKELEASING_CHECKOUT_CONFIG);
  }

  saveNeoBikeleasing() {
    this.loading = true;

    return this.bikeleasingService.giveNeoBikeLeasing(this.employee.id, this.bikeleasing_checkout_config)
      .then(() => {
        this.NotificationService.message('Ihre Angaben wurden gespeichert.');
        this.$scope.confirm();
      }).catch((e) => {
        this.NotificationService.error('Fehlgeschlagen.');
        console.error(e);
      }).finally(() => {
        this.loading = false;
      });

  }

  close() {
    this.$scope.closeThisDialog();
  }

}
AddNeoBikeDialogController.$inject = $inject;
