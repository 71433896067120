const $inject = [
  '$scope', '$state', 'CheckoutService', 'UserNotificationService'
];
export default class userNotificationController {

  constructor($scope, $state, CheckoutService, UserNotificationService) {
    this.$scope = $scope;
    this.$state = $state;
    this.CheckoutService = CheckoutService;
    this.UserNotificationService = UserNotificationService;
    this.notification = $scope.ngDialogData.notification;

  }

  closeNotification() {
    this.UserNotificationService.markNotificationAsSeen(this.notification._id).then(() => {
      this.$scope.confirm();
    }).catch((err) => {
      this.$scope.confirm();
    })
  }

  cancel() {
    this.$scope.confirm();
  }
}
userNotificationController.$inject = $inject;
