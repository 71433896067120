<h2 mat-dialog-title>
    Bestellung fakturieren
</h2>

<mat-dialog-content>
    <p>
        Nach dem Klick auf “Bestätigen“ wird auch eine Datei im CSV-Format für Ihr Fakturierungsprogramm
        heruntergeladen.
    </p>

    <mat-error *ngIf="!( confirmFlag$ | async )">
        Der Kunde ist in der MySQL Datenbank falsch eingerichtet. Bitte <strong>invoice email</strong> oder
        <strong>invoice payment type</strong> überprüfen.
    </mat-error>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='create-button'
            data-cy='vn.directBonus.backofficeAdministration.markForInvoiceDialog.buttonSubmit'
            mat-stroked-button
            [disabled]="!(confirmFlag$ | async)"
            (click)="markOrderForInvoice()">
        Bestätigen
    </button>
</mat-dialog-actions>