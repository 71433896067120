var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      












































pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};































































pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"main\" overlay=\"$ctrl.loading\"\u003E\u003Cdiv class=\"row\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "$ctrl.basketModel.create_order_process","title": "Bestellprozess starten","data-cy": "vn.administration.pcLeasing.manualBasketDialog.checkStartOrderProcess","labelHtmlClass": "switchbox-float-label","ng-change": "$ctrl.withArticles = $ctrl.basketModel.create_order_process"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"newBasketBeginDate\" date-refocus=\"false\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.basketModel.beginDate","title": "Beginn","data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputBeginDate","attributes": {"autocomplete":"off","vn-date":"true","vn-date-reformat":"true","vn-date-use-utc":"true","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","ng-change":"$ctrl.getEndDate()"},"required": pug.escape(true),"fieldHtmlClass": "newBasketBeginDate","validationMessages": {"required":"Pflichtfeld"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"newBasketEndDate\" date-refocus=\"false\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.basketModel.endDate","title": "Ende","data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputEndDate","attributes": {"autocomplete":"off","vn-date":"true","vn-date-reformat":"true","vn-date-use-utc":"true","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F"},"required": pug.escape(true),"fieldHtmlClass": "newBasketEndDate","validationMessages": {"required":"Pflichtfeld"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-show=\"!$ctrl.basketModel.create_order_process\"\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.basketModel.order_id","title": "Auftragsnummer","data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputOrderId","attributes": {"ng-required":"!$ctrl.basketModel.create_order_process"},"validationMessages": {"required":"Pflichtfeld"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.basketModel.contract_id","title": "Leasingvertragsnummer","data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputContractId","placeholder": "Leasingvertragsnummer im Format 123123\u002F123123 eingeben.","attributes": {"ng-pattern":"leasingRegex","ng-required":"!$ctrl.basketModel.create_order_process"},"validationMessages": {"required":"Pflichtfeld","pattern":"Bitte Eingabe prüfen"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "$ctrl.basketModel.closed","data-cy": "vn.administration.pcLeasing.manualBasketDialog.checkFinalizeBasket","title": "Warenkorb Finalisiert","labelHtmlClass": "switchbox-float-label","readonly": "true"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "$ctrl.basketModel.locked","data-cy": "vn.administration.pcLeasing.manualBasketDialog.checkWithCheckout","title": "Checkout Durchlaufen","labelHtmlClass": "switchbox-float-label","readonly": "true"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\" ng-if=\"!$ctrl.withArticles\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.basketModel.sum","title": "Nettosumme","type": "number","step": "any","data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputPriceNetto","required": pug.escape(true),"attributes": {"ng-change":"$ctrl.calculateLeasing()","ng-model-options":"{ debounce: 500 }"},"validationMessages": {"required":"Pflichtfeld"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\" ng-if=\"!$ctrl.withArticles\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.basketModel.leasing","title": "Umwandlungsrate Entgelt","type": "number","step": "any","data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputExchangeRate","required": pug.escape(true),"validationMessages": {"required":"Pflichtfeld"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\" ng-if=\"!$ctrl.withArticles &amp;&amp; $ctrl.basketModel.cart_leasing_month_netto\" style=\"margin-top: 25px\"\u003E\u003Cspan\u003ELeasingrate netto: {{ $ctrl.basketModel.cart_leasing_month_netto | currency }}\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4 newBasketInputsGroup\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "$ctrl.withArticles","data-cy": "vn.administration.pcLeasing.manualBasketDialog.checkWithItems","title": "Artikel verwenden","labelHtmlClass": "switchbox-float-label"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.withArticles\"\u003E\u003Cdiv class=\"columns medium-12 no-label\"\u003E\u003Cbutton class=\"button button-primary\" ng-click=\"$ctrl.addArticle()\" data-cy=\"vn.administration.pcLeasing.manualBasketDialog.buttonAddItem\"\u003EArtikel hinzufügen\u003C\u002Fbutton\u003E\u003Ctable ng-if=\"$ctrl.basketModel.articles.length\"\u003E\u003Ctr\u003E\u003Cth\u003EPosition\u003C\u002Fth\u003E\u003Cth\u003EArtikelname\u003C\u002Fth\u003E\u003Cth\u003EArtikelnummer\u003C\u002Fth\u003E\u003Cth\u003EAnzahl\u003C\u002Fth\u003E\u003Cth\u003EPreis Gesamt (Netto)\u003C\u002Fth\u003E\u003C\u002Ftr\u003E\u003Ctr ng-repeat=\"article in $ctrl.basketModel.articles\" ng-class-odd=\"odd\"\u003E\u003Ctd\u003E{{$index + 1}}\u003C\u002Ftd\u003E\u003Ctd\u003E";
pug_mixins["input"].call({
attributes: {"key": "article.Artikelname","id": "new_basket_article_name{{$index}}","required": pug.escape(true),"data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputItemName-{{$index}}","validationId": "'new_basket_article_name' + $index","validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
pug_mixins["input"].call({
attributes: {"key": "article.Artikelnummer","id": "new_basket_article_number{{$index}}","required": pug.escape(true),"data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputItemId-{{$index}}","validationId": "'new_basket_article_number' + $index","validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
pug_mixins["input"].call({
attributes: {"key": "article.quantity","type": "number","id": "new_basket_article_quantity{{$index}}","required": pug.escape(true),"data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputItemCount-{{$index}}","validationId": "'new_basket_article_quantity' + $index","validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
pug_mixins["input"].call({
attributes: {"key": "article.netprice","type": "number","step": "any","data-cy": "vn.administration.pcLeasing.manualBasketDialog.inputTotalPrice-{{$index}}","id": "article_netprice{{$index}}","required": pug.escape(true),"validationId": "'article_netprice' + $index","validationMessages": {"required":"Es wird eine Eingabe benötigt."}}
});
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cbutton ng-click=\"$ctrl.removeArticle($index)\" data-cy=\"vn.administration.pcLeasing.manualBasketDialog.buttonDeleteItem-{{$index}}\"\u003EX\u003C\u002Fbutton\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cbr\u003E\u003Cbutton class=\"btn btn-primary right\" type=\"submit\" data-cy=\"vn.administration.pcLeasing.manualBasketDialog.buttonSubmit\" ng-disabled=\"!$ctrl.canSave()\" ng-click=\"$ctrl.save()\"\u003ESpeichern\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "row","name": "$ctrl.basketModelForm"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;