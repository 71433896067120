<div id="meineakte-dticket" class="meineakte-dticket row">
  <vn-matblur-loading></vn-matblur-loading>
  <div class="xsmall-12 columns" *ngIf="!(loader.loading$ | async)">
    <div class="bigbox centered verguetung" *ngIf="!dticketOrders.length;else ordersBlock">
      <span>Aktuell liegen keine Bestellungen zur Anzeige vor.</span>
    </div>
    <ng-template #ordersBlock>
      <!-- https://vsp.atlassian.net/browse/P92VN-1332 -->
      <div class="bigbox centered verguetung" *ngIf="false">
        <div class="dTicketPdfPreferences">
          <button (click)="openPreferencesDialog()" class="dTicketPdfPreferencesDialogButton" mat-raised-button>
            <mat-icon>picture_as_pdf</mat-icon>
          </button>
          <span class="dTicketPdfPreferencesText">
            Das Deutschlandticket wird Ihnen als digitales Ticket (E-Ticket) auf Ihrem Mobiltelefon zur Verfügung gestellt.
            Möchten Sie das Ticket stattdessen ausdrucken und in Papierform nutzen, dann aktivieren Sie hier bitte die Einstellung dafür.
            Dazu klicken Sie bitte auf das Symbol "PDF" - das Ihnen links vom Text angezeigt wird -
            und geben in dem sich öffnenden Fenster Ihre bevorzugten Einstellungen an.
          </span>
        </div>
      </div>
      <div class="bigbox centered verguetung">
        <div class="header">
          <span>Bestellhistorie</span>
        </div>
        <div class="main">
          <table class="full-width">
            <thead>
            <tr>
              <td><span>Bestellreferenz</span></td>
              <td><span>Arbeitgeber Anteil</span></td>
              <td><span>Ihr Anteil</span></td>
              <td><span>Pauschalsteuer</span></td>
              <td><span>Gültig von</span></td>
              <td><span>Gültig bis</span></td>
              <td><span>Aktionen</span></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let orderItem of dticketOrders">
              <td><span>{{ orderItem.orderReference }}</span></td>
              <td><span>{{ orderItem.employerSubsidy | currency:'EUR' }}</span></td>
              <td><span>{{ orderItem.employeeContribution | currency:'EUR' }}</span></td>
              <td><span>{{ displayOrderFlatTax(orderItem) }}</span></td>
              <td *ngIf="orderItem.beginDate; else elseBeginDateBlock"><span>{{ orderItem.beginDate | date: 'dd.MM.yyyy' }}</span>
              </td>
              <ng-template #elseBeginDateBlock>
                <td><span>-</span></td>
              </ng-template>
              <td *ngIf="orderItem.endDate; else elseEndDateBlock"><span>{{ orderItem.endDate | date: 'dd.MM.yyyy' }}</span></td>
              <ng-template #elseEndDateBlock>
                <td><span>-</span></td>
              </ng-template>
              <td *ngIf="
                  orderItem.isCancelAllowed() ||
                  canRestoreTicket(orderItem)
                else elseStateBlock
              ">
                <button mat-button [matMenuTriggerFor]="menu" (menuOpened)="menuOpened()">
                  <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    #menuItem1
                    *ngIf="orderItem.isCancelAllowed()"
                    mat-menu-item
                    (click)="cancelDTicketOrder(orderItem.id)"
                  >

                    <mat-icon>
                      remove_circle
                    </mat-icon>
                    <span>
                      Abbestellen
                    </span>
                  </button>

                  <button
                    *ngIf="canRestoreTicket(orderItem)"
                    [disabled]="(isLoading() | async)"
                    mat-menu-item
                    (click)="restoreTicket(orderItem.id)"
                  >
                    <mat-icon>bookmark</mat-icon>
                    <span>
                      Wiederherstellen
                    </span>
                  </button>

                </mat-menu>
              </td>
              <ng-template #elseStateBlock>
                <td><span>&nbsp;</span></td>
              </ng-template>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>

  </div>
</div>
