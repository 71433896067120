angular
  .module('VSPApp')
  .directive('vnRange', ['$parse', function ($parse) {
    return {
      restrict: 'A',
      require: '?ngModel',
      scope: {
        vnRange: '='
      },
      link: function (scope, elm, attrs, ngModel) {

        let watcher = scope.$watch(() => {
          return attrs.disabled;
        }, () => {
          ngModel.$validate();
        });

        scope.$on('destroy', () => {
          watcher();
        });

        // if (scope.vnRange) {
        ngModel.$validators.rangeMin = (modelValue, viewValue) => {
          if (!!scope.vnRange && scope.vnRange.min === undefined || !!attrs.disabled) {
            return true;
          }

          let value = modelValue;
          let isValid = value > scope.vnRange.min;
          return ngModel.$isEmpty(viewValue) || isValid;
        };

        ngModel.$validators.rangeMinEq = (modelValue, viewValue) => {
          if (!!scope.vnRange && scope.vnRange.min_eq === undefined || !!attrs.disabled) {
            return true;
          }

          let value = modelValue;
          let isValid = value >= scope.vnRange.min_eq;
          return ngModel.$isEmpty(viewValue) || isValid;
        };

        ngModel.$validators.rangeMax = (modelValue, viewValue) => {
          if (!!scope.vnRange && scope.vnRange.max === undefined || !!attrs.disabled) {
            return true;
          }

          let value = modelValue;
          let isValid = parseInt(value) < scope.vnRange.max;
          return ngModel.$isEmpty(viewValue) || isValid;
        };

        ngModel.$validators.rangeMaxEq = (modelValue, viewValue) => {
          if (!!scope.vnRange && scope.vnRange.max_eq === undefined || !!attrs.disabled) {
            return true;
          }

          let value = modelValue;
          let isValid = value <= scope.vnRange.max_eq;
          return ngModel.$isEmpty(viewValue) || isValid;
        };
        ngModel.$validators.rangeDecimalsCount = (modelValue, viewValue) => {
          if (!!scope.vnRange && scope.vnRange.decimalsCount === undefined || !!attrs.disabled) {
            return true;
          }

          if (!modelValue) {
            return true;
          }

          let isValid = countDecimals(modelValue) <= scope.vnRange.decimalsCount;
          return ngModel.$isEmpty(viewValue) || isValid;

        };
        const countDecimals = (value) => {
          if (Math.floor(value) !== parseFloat(value)) {
            return value.toString().split(/,|\./)[1].length || 0;
          }
          return 0;
        };
      }
    };
  }]);
