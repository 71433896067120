const $inject = [
  '$scope',
  '$timeout',
  'BonusService',
  'NotificationService'
];
export default class TransferInfoDialogController {
  constructor(
    $scope,
    $timeout,
    BonusService,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      BonusService,
      NotificationService
    });

    this.bonus_project = this.$scope.ngDialogData.bonus_project;
    this.loading = {
      main: false,
      active_bonus_project: false
    };
    this.forms = {};

    this.init();
  }

  canConfirmSelection() {
    if (!this.forms.inherit_from_form) {
      return false;
    }

    return this.forms.inherit_from_form.$valid;
  }

  async handleActiveBonusProjectSelection() {
    this.$timeout(() => {
      this.loading.saving = true;
    });

    await this.BonusService.updateBonusProject(this.bonus_project, false);

    this.$timeout(() => {
      this.loading.saving = false;
    });
  }

  cancelSelection() {
    this.$scope.confirm(false);
  }

  confirmSelection() {
    this.$scope.confirm(true);
  }

  async getActievBonusProjects() {
    this.$timeout(() => {
      this.loading.active_bonus_project = true;
    });

    this.active_bonus_projects = await this.BonusService.getActiveBonusProjects({
      customer_id: this.bonus_project.customer_id,
      bonus_project_type: this.bonus_project.project_type
    });

    this.$timeout(() => {
      this.loading.active_bonus_project = false;
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.getActievBonusProjects();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }


}
TransferInfoDialogController.$inject = $inject;
