import {FID_EMPLOYEE_TYPE_BY_NAME} from "./fid_employee_type";

export const FREISTELLUNGSTAG_OPTIONS = [
  {
    id: 0,
    prop: FID_EMPLOYEE_TYPE_BY_NAME['VZ']['id'],
    label: FID_EMPLOYEE_TYPE_BY_NAME['VZ']['display'],
    default: 250,
    cyData: 'vn.cockpit.bonus.createProjectDialog.inputFullTime-additionalPaidLeave'
  },
  {
    id: 1,
    prop: FID_EMPLOYEE_TYPE_BY_NAME['TZ']['id'],
    label: FID_EMPLOYEE_TYPE_BY_NAME['TZ']['display'],
    default: 120,
    cyData: 'vn.cockpit.bonus.createProjectDialog.inputPartTime-additionalPaidLeave'
  },
  {
    id: 2,
    prop: FID_EMPLOYEE_TYPE_BY_NAME['AZUBI']['id'],
    label: FID_EMPLOYEE_TYPE_BY_NAME['AZUBI']['display'],
    default: 60,
    cyData: 'vn.cockpit.bonus.createProjectDialog.inputTrainee-additionalPaidLeave'
  }
];
