
import assign_approval_permissions_dialog_html from '../../../modules/cockpit/approvers/assign_approval_permissions_dialog.pug'
const $inject = [
  '$scope', '$timeout', 'customerService', '$state', '$q', 'dialogService', 'UserGroupsService', 'ACLService', 'lodash'
];
export default class CockpitApproversController {
  constructor($scope, $timeout, customerService, $state, $q, dialogService, UserGroupsService, ACLService, lodash) {
    Object.assign(this, {
      $scope,
      customerService,
      $timeout,
      $state,
      $q,
      dialogService,
      UserGroupsService,
      ACLService,
      lodash
    });

    this.ALLOW_ALL_SIGN = '*';
    this.loading = true;
    this.customer_id = this.$state.params.customerId;
    this.bonus_type =
      this.forms = {};

    this.init().then(() => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    });
  }

  async init() {
    let promises = [];
    promises.push(
      this.ACLService.getACLModules('approval').then(
        (acl_module) => {
          this.acl_module = acl_module;
          return this.ACLService.getACLModuleActions(this.acl_module.name).then(
            (permission_actions) => {
              this.permission_actions_by_name = permission_actions.reduce((acc, field) => {
                acc[field.name] = field;
                return acc;
              }, {});
            });
        }).catch((err) => {
          console.error(err);
        }
      )
    );

    promises.push(
      this.ACLService.getApprovalComponents().then(
        (approval_components) => {
          this.approval_components = approval_components;
        }
      )
    );


    promises.push(this.getApprovers());

    return this.$q.all(promises);
  }

  getApprovers() {
    this.loading = true;
    return this.ACLService.getCustomerApprovers(this.customer_id).then(
      (approvers) => {
        this.approvers = approvers[this.ALLOW_ALL_SIGN];
        this.loading = false;
      }).catch((err) => {
      console.error(err);
    });
  }

  getApprovalEmailDisplay(employee) {
    if (employee.employee_data.employee_wants_approval_mail) {
      return employee.employee_data.approval_email;
    } else {
      return '-';
    }

  }

  getPermissionDisplayByName(action_name) {
    if (action_name) {
      return this.permission_actions_by_name && this.permission_actions_by_name[action_name] && (this.permission_actions_by_name[action_name].short_display || this.permission_actions_by_name[action_name].display);
    } else {
      return '';
    }
  }

  openUserGroupPermissions(module_process, component_name, employee_id = null, employee = null) {
    this.dialogService.ngDialog.openConfirm({
      template: assign_approval_permissions_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'AssignApprovalPermissionsDialogController',
      controllerAs: '$ctrl',
      data: {
        employee_id: employee_id,
        customer_id: this.customer_id,
        acl_module: 'approval',
        acl_module_process: module_process,
        process_resource: component_name,
        employee: employee
      },
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then((result) => {
      if (result) {
        this.getApprovers();
      }
    });
  }

  save() {
    this.loading = true;
  }
}
CockpitApproversController.$inject = $inject;
