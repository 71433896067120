const $inject = [];
export default class CalculationComponentCheckoutStepSummary {

}
CalculationComponentCheckoutStepSummary.$inject = $inject;

import html from './CalculationComponentCheckoutStepSummary.pug'

angular
  .module( 'VSPApp' )
  .component( 'checkoutSummaryStep', {
    template: html(),
    transclude: {
      'bikeleasing': '?bikeleasingDescription',
      'description': '?checkoutDescription'
    },
    bindings: {
      title: '<',
      img: '<',
      video : '<',
      description: '<',
      onClickHeader: '&',
      onClickNext: '&',
      onBackClick: '&',
      canClickNext: '&',
      canSeeNext: '&',
      isOpen : '<'
    },
    controller: CalculationComponentCheckoutStepSummary,
    controllerAs: '$ctrl'
  });

