import ComponentController from '../ComponentController';

const $inject = [
  '$q',
  '$injector',
  '$scope',
  '$state',
  '$transitions',
  '$location',
  'Made',
  'debounceFactory',
  'vbmData',
  'neoModifierService',
  'customerService',
  'PlausibilityService',
  'dialogService',
  'SliderOptionsConstants',
  'componentsService',
  'NotificationService',
  'vbmService',
  'userService',
  'bikeleasingService'
];
export default class ComponentControllerkinder extends ComponentController {

  constructor(
    $q,
    $injector,
    $scope,
    $state,
    $transitions,
    $location,
    Made,
    debounceFactory,
    vbmData,
    neoModifierService,
    customerService,
    PlausibilityService,
    dialogService,
    SliderOptionsConstants,
    componentsService,
    NotificationService,
    vbmService,
    userService,
    bikeleasingService
  ) {

    super(
      $q,
      $injector,
      $scope,
      $state,
      $transitions,
      $location,
      Made,
      debounceFactory,
      vbmData,
      neoModifierService,
      customerService,
      PlausibilityService,
      dialogService,
      SliderOptionsConstants,
      componentsService,
      NotificationService,
      vbmService,
      userService,
      bikeleasingService
    );

  }

  openKinderAssistant() {

    this.dialogService
      .kinderAssistant(this.vbmData.employeeId)
      .then(total => this.vbmData.employees[this.vbmData.employeeId].data.neo_components.kinder.value = total.toFixed(2));
  }
}
ComponentControllerkinder.$inject = $inject;
