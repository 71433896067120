<h2 mat-dialog-title>
    Neue Mitarbeiter importieren
</h2>

<mat-dialog-content>
    <p>
        Zur Übertragung von neuen Mitarbeiterdaten dient eine Excel-Vorlage. Laden Sie hier die Datei über den Knopf
        herunter.
    </p>

    <button mat-stroked-button
            data-cy="vn.directBonus.customerAdministration.bulkAddUsersDialog.buttonDownloadCsv"
            (click)="downloadTableAsFile()">
        <mat-icon class="white-icon">
            download
        </mat-icon>

        <span>
            Vorlage
        </span>
    </button>

    <p>
        Angegeben werden muss mindestens <b>Vorname, Name und Personalnummer</b>. Die E-Mailadresse ist optional - aber
        empfohlen, um die Einladung ins System optimal zu unterstützen. Für Mitarbeiter ohne E-Mailadresse stellen wir
        Ihnen Daten für ein individuelles Einladungsschreiben zur Verfügung.
    </p>

    <p>
        Speichern Sie die fertig bearbeitete Datei auf Ihrem Rechner. Laden Sie anschließend die Daten in das System
        hoch.
    </p>

    <p>
        Nach dem Upload wird Ihnen der Dateiname im System angezeigt.Prüfen Sie an dieser Stelle noch einmal, ob Sie die
        richtige Datei ausgewählt haben.
    </p>

    <vn-dropzone label="Legen Sie hier die Datei ab"
                 key="vn.directBonus.customerAdministration.bulkAddUsersDialog.fileUsersCsv"
                 (onUpload)="bulkAddUsers($event)">
    </vn-dropzone>

    <mat-divider></mat-divider>

    <p>
        Mit dem Klick auf "Hochladen" wird sich dieser Dialog schließen und die Daten aktualisiert werden.
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            data-cy="vn.directBonus.customerAdministration.bulkAddUsersDialog.buttonCloseDialog"
            mat-dialog-close>
        Schließen
    </button>
</mat-dialog-actions>