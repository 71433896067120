angular.module('VSPApp').controller('LocationSelectController', ['$scope', 'vcpModel', function ($scope, vcpModel) {
  console.log('MM', vcpModel);

  $scope.city = {};

  $scope.change = function (_id) {
    var city = vcpModel.cities.find(function (city) {
      return city.id == parseInt(_id);
    });

    $scope.city = city;

    // var mitarbeiter = $scope.$parent.$parent.$parent.$parent.$parent.$parent.mitarbeiter;
    // mitarbeiter.zip = mitarbeiter.zip || city.plz;
    // mitarbeiter.street = mitarbeiter.street || city.strasse;
    // mitarbeiter.city = city.ort;

  };
}]);
