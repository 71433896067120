var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["summary-box"] = pug_interp = function(selectedCompany, save, disabled, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"summary-box isTransparent\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns\"\u003E";
if (selectedCompany) {
pug_html = pug_html + "\u003Cspan class=\"summary-box__title\"\u003E\u003Cstrong\u003EFirma:\u003C\u002Fstrong\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' '+ selectedCompany) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"hide-for-small-only\"\u003E&nbsp;\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"xsmall-12 medium-6 large-6 columns text-right pull-right\"\u003E";
block && block();
if (save) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"button button-primary isOutline\""+pug.attr("ng-click", save, true, true)+pug.attr("ng-disabled", disabled, true, true)+pug.attr("data-cy", cyData, true, true)) + "\u003ESpeichern\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};



















pug_html = pug_html + "\u003Cdiv class=\"cockpit_vorsorge-wrapper\"\u003E";
pug_mixins["summary-box"]('{{ VorsorgeCtrl.customer.name }}', 'VorsorgeCtrl.save()', 'VorsorgeCtrl.working|| VorsorgeCtrl.configuration.fixated');
pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel\" overlay=\"VorsorgeCtrl.working\"\u003E\u003Cdiv class=\"cockpit-panel__title\"\u003EVorsorgebausteine aktivieren\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\"\u003E\u003Cp ng-if=\"VorsorgeCtrl.MenuConstants.vorsorge.disabled\"\u003EAktuell keine Konfiguration möglich.\u003C\u002Fp\u003E\u003Cul class=\"module-select\" ng-if=\"!VorsorgeCtrl.MenuConstants.vorsorge.disabled\"\u003E\u003Cli class=\"module-select__item\" ng-repeat=\"(key, module) in VorsorgeCtrl.VorsorgeConstants\" ng-class=\"{isSelected: !VorsorgeCtrl.configuration.vorsorge[$index+1].enabled || !VorsorgeCtrl.configuration.vorsorge[$index+1] }\" ng-click=\"VorsorgeCtrl.toggle( $index+1 )\"\u003E\u003Ci class=\"module-select__icon icon font-icon icon-{{ key | vorsorgeIcon }}\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"module-select__title\"\u003E\u003Ch5\u003E{{ module.breakTitle }}\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;