import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import { CustomerOrder, CustomerOrderTableDataBO } from '../../../models/direct-bonus-customer-order.model';
import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ExtendDeadlineDialogData,
  ProjectActionsDialogData
} from 'src/app/direct-bonus/models/direct-bonus-table-dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { DirectBonusInvoiceDialogComponent } from '../direct-bonus-invoice-dialog/direct-bonus-invoice-dialog.component';
import { DirectBonusPayDialogComponent } from '../direct-bonus-pay-dialog/direct-bonus-pay-dialog.component';
import { DirectBonusUnpaidDialogComponent } from '../direct-bonus-unpaid-dialog/direct-bonus-unpaid-dialog.component';
import { DirectBonusCancelDialogComponent } from '../direct-bonus-cancel-dialog/direct-bonus-cancel-dialog.component';
import { DirectBonusDoneDialogComponent } from '../direct-bonus-done-dialog/direct-bonus-done-dialog.component';
import { map, switchMap, tap } from 'rxjs/operators';
import { DirectBonusFormData } from 'src/app/direct-bonus/models/direct-bonus-data.model';
import { EMPTY, from} from 'rxjs';
import { DirectBonusAddDialogComponent } from '../direct-bonus-add-dialog/direct-bonus-add-dialog.component';
import { DirectBonusOnHoldDialogComponent } from '../direct-bonus-on-hold-dialog/direct-bonus-on-hold-dialog.component';
import { DirectBonusOffHoldDialogComponent } from '../direct-bonus-off-hold-dialog/direct-bonus-off-hold-dialog.component';
import { BackofficeAdministrationAllowedOperations } from 'src/app/direct-bonus/models/direct-bonus-allowed-operations.model';
import { BonusOrderPaymentTypeDialogComponent } from '../bonus-order-payment-type-dialog/bonus-order-payment-type-dialog.component';
import {
  BonusOrderExtendDeadlineDialogComponent
} from "../bonus-order-extend-deadline-dialog/bonus-order-extend-deadline-dialog.component";
import { AlertService } from 'src/app/shared/services/alert.service';
import { DirectBonusMapperService } from 'src/app/direct-bonus/services/direct-bonus.mapper';

@Component({
  selector: 'vn-bonus-order-table',
  templateUrl: './bonus-order-table.component.html',
  styleUrls: ['./bonus-order-table.component.scss'],
  animations: [
    trigger('rowExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BonusOrderTableComponent implements OnInit {

  public filtersHeaderColumns: string[] = [
    'filtersHeader',
    'topLevelActionsMenu'
  ];

  public mainTableColumns: string[] = [
    'orderId',
    'customerId',
    'customerName',
    'orderType',
    'orderPeriod',
    'orderAmount',
    'orderState',
    'actionsMenu'
  ];

  public expandedRow!: CustomerOrderTableDataBO | null;

  @Input()
  tableData!: CustomerOrderTableDataBO[];

  @Output()
  tableDataChange = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    private mapper: DirectBonusMapperService,
  ) {

  }

  ngOnInit(): void {
    this.tableData = [];
  }

  public getOrderPeriod(order: CustomerOrder): string {
    const orderPeriodMoment = moment({
      month: order.forMonth - 1,
      year: order.forYear
    });

    const orderPeriod = orderPeriodMoment.format('MM.YYYY');
    return orderPeriod;
  }

  public getOrderStateColors(data: CustomerOrderTableDataBO): string[] {
    const colors = [];
    const state = "" + data.order.state.value

    if (data.order.paymentType === 'sepa') {
      colors.push('grey');
    }

    switch (state) {
      case "1":
        colors.push('yellow'); // draft
        break;
      case "2":
      case "3":
      case "4":
        colors.push('blue');
        break;
      case "5":
        colors.push('red');
        break;
      case "6":
        colors.push('green');
        break;
      default:
        colors.push('black');
    }

    if (data.order.isOnHold) {
      colors.push('black');
    }

    return colors;
  }

  public getOrderStateLabels(data: CustomerOrderTableDataBO): string[] {
    const labels = [];

    if (data.order.paymentType === 'sepa') {
      labels.push('S€PA');
    }

    labels.push(data.order.state.name);

    if (data.order.isOnHold) {
      labels.push('$report_gmailerrorred');
    }

    return labels;
  }

  public getTableRowClass(data: CustomerOrderTableDataBO) {
    return {
      'customer-order-table-row-expanded': this.expandedRow === data
    };
  }

  public onTableRowClick(data: CustomerOrderTableDataBO) {
    this.expandedRow = this.expandedRow === data ? null : data;
  }

  public hasContextMenu(allowedOperations: BackofficeAdministrationAllowedOperations) {
    return allowedOperations.canStartMarkOrderForInvoice ||
      allowedOperations.canMarkOrderAsPaid ||
      allowedOperations.canMarkOrderAsUnpaid ||
      allowedOperations.canMarkOrderAsCancelled ||
      allowedOperations.canMarkOrderAsDone ||
      allowedOperations.canCreateNewBonus ||
      allowedOperations.canPutOrderOnHold ||
      allowedOperations.canPutOrderOffHold ||
      allowedOperations.canChangeOrderPaymentType ||
      allowedOperations.canExtendOrderDeadline;
  }

  public menuClick($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public startMarkOrderForInvoice(orderId: string) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId
    };

    this.dialog.open(
      DirectBonusInvoiceDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public markOrderAsPaid(order: CustomerOrder) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId: order.orderId,
      paymentType: order.paymentType
    };

    this.dialog.open(
      DirectBonusPayDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public markOrderAsUnpaid(orderId: string) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId
    };

    this.dialog.open(
      DirectBonusUnpaidDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public markOrderAsDone(orderId: string) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId
    };

    this.dialog.open(
      DirectBonusDoneDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public cancelOrder(order: CustomerOrder) {
    const {
      orderId,
      state: {
        name: orderState
      }
    } = order;

    const withCsvDownload = ['Fakturiert', 'Bezahlt'].includes(orderState);

    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId,
    };

    this.dialog.open(
      DirectBonusCancelDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .pipe(
        switchMap((formData: DirectBonusFormData) => {
          if (formData) {
            const {
              formReason,
              otrsTicket
            } = formData;

            return this.directBonusService.markOrderAsCancelled(orderId, formReason!, otrsTicket!);
          }

          return EMPTY;
        }),
      )
      .subscribe(
        (result) => {
          if (result) {
            const message = 'Sie haben diese Bestellung storniert. Dieser Auftrag ist nun aus dem Bestellprozess für immer entfernt.';
            const csvMessage = ' Eine Datei im CSV-Format für Ihr Fakturierungsprogramm wurde heruntergeladen.';
            this.alertService.message(withCsvDownload ? message + csvMessage : message);

            this.tableDataChange.emit();
          }
        },
        (error) => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }

  public createNewBonus(order: CustomerOrder) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      bonusProjectId: order.bonusProjectId,
      customerId: order.customerId,
      orderId: order.orderId,
      month: order.forMonth,
      year: order.forYear,
      period: this.getOrderPeriod(order)
    };

    this.dialog.open(
      DirectBonusAddDialogComponent,
      {
        width: dialogWidth,
        data: dialogData
      }
    ).afterClosed()
      .pipe(
        switchMap((newBonusData: DirectBonusFormData | null) => {
          if (newBonusData) {
            return this.directBonusService.addOrderBonusToExistingOrder(newBonusData).pipe(
              map(
                result => ({
                  result,
                  newBonusData
                })
              )
            );
          }

          return EMPTY;
        })
      )
      .subscribe(
        ({
          result,
          newBonusData
        }) => {
          if (result) {
            const componentDisplayValue = this.mapper.getDirectBonusComponentDisplayValue(newBonusData.componentName!);
            const message = `Sie haben den folgenden Bonus der Bestellung im ${newBonusData.period} hinzugefügt:
            ${newBonusData.employeeId} - ${componentDisplayValue} - ${newBonusData.formValue} €`;
            this.alertService.message(message);

            this.tableDataChange.emit();
          }
        },
        (error) => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }

  public putOrderOnHold(orderId: string) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId
    };

    this.dialog.open(
      DirectBonusOnHoldDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public putOrderOffHold(orderId: string) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId
    };

    this.dialog.open(
      DirectBonusOffHoldDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public changePaymentType(orderId: string) {
    const dialogWidth = '420px';
    const dialogData: ProjectActionsDialogData = {
      orderId
    };

    this.dialog.open(
      BonusOrderPaymentTypeDialogComponent, {
      width: dialogWidth,
      data: dialogData
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public extendDeadline(order: CustomerOrder) {
    const dialogWidth = '420px';
    const dialogData: ExtendDeadlineDialogData = {
      orderId: order.orderId,
      deadlineDate: order.deadlineDate
    };

    this.dialog.open(
      BonusOrderExtendDeadlineDialogComponent, {
      width: dialogWidth,
      data: dialogData
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.tableDataChange.emit();
          }
        }
      );
  }

  public onTableDataChange() {
    this.tableDataChange.emit();
  }
}
