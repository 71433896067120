const $inject = [
  '$scope',
  'BonusService',
  '$timeout',
  'moment',
  'NotificationService'
];
export default class BonusCancelDialogController {
  constructor(
    $scope,
    BonusService,
    $timeout,
    moment,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      BonusService,
      $timeout,
      moment,
      NotificationService
    });


    this.loading = {
      main: false
    };

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.forms = {
      cancel_form: {}
    };

    this.models = {
      cancel: {
        cancel_comment: '',
        new_end_date: undefined
      }
    };

    this.bonus = this.$scope.ngDialogData.bonus;
    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;
    this.datepicker_enabled_dates = this.generateEnabledEndDatesArray();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  canCancelForm() {
    return this.forms.cancel_form.$valid;
  }

  cancelBonus() {
    if (!this.canCancelForm()) {
      return;
    }

    this.$timeout(() => {
      this.loading.cancel = true;
    });


    return this.BonusService.administrationBonusCancel({
      bonus_id: this.bonus._id,
      comment: this.models.cancel.cancel_comment,
      new_end_date: this.models.cancel.new_end_date
    })
      .then(() => {
        this.$scope.confirm();
        this.NotificationService.message('OK');
      })
      .catch(e => {
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.cancel = false;
        });
      });
  }

  generateEnabledEndDatesArray() {
    let bonus_start_date = this.moment.unix(this.bonus.dates.bonus_start_date);
    let bonus_end_date = this.moment.unix(this.bonus.dates.bonus_end_date).endOf('month');
    let dates = [];

    let available_end_date = this.moment(bonus_start_date).endOf('month');
    for (; available_end_date.isSameOrBefore(bonus_end_date); available_end_date.add(1, 'months').endOf('month')) {
      let formatted_date = this.moment(available_end_date).toDate();
      dates.push(formatted_date);
    }

    return dates;
  }

}
BonusCancelDialogController.$inject = $inject;
