import DatevBaseController from './DatevBaseController';
import DatevController from './DatevController';
import DatevDetailsController from './details/DatevDetailsController';
import BudgetFormController from './budget_form/budget_form.component';
import {EditPotentialDialogController} from './editPotentialDialog.controller';

import html from "./budget_form/budget_form.directive.pug"

angular
  .module( 'VSPApp' )
  .controller('DatevBaseController', DatevBaseController)
  .controller('DatevController', DatevController)
  .controller('DatevDetailsController', DatevDetailsController)
  .controller('BudgetFormController', BudgetFormController)
  .controller('EditPotentialDialogController', EditPotentialDialogController)
  .component('budgetFormComponent', {
    controller: 'BudgetFormController',
    controllerAs: '$ctrl',
    template: html(),
    bindings: {
      direct : '=',
      forms: '=',
      formName: '@',
      papR: '<',
      passApi: '<',
      preselected: '<',
      allowedFields: '<',
      fields:'<'
    }
  }
);
