angular
  .module('VSPApp')
  .controller('ChooseMonthDialogController', ['$scope', 'MonthsService', function ($scope, MonthsService) {

    $scope.months = MonthsService.months;
    $scope.month = MonthsService.month;
    $scope.year = MonthsService.year;

    $scope.submit = function () {
      MonthsService.year = $scope.year;
      MonthsService.month = $scope.month;
      $scope.confirm();
    };

  }]);
