const $inject = [
  '$scope',
  'ShopService',
  'BenefitBonagoService',
  '$timeout'
];

class BenefitBonagoHomeController {
  constructor(
    $scope,
    ShopService,
    BenefitBonagoService,
    $timeout
  ) {
    Object.assign(this, {
      $scope,
      ShopService,
      BenefitBonagoService,
      $timeout
    });

    this.loading = {
      main: false,
      retailers: false
    };
    this.search_term = '';

    this.loading.main = true;
    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    await this.setRetailers();

    this.subscriptions = [
      this.BenefitBonagoService.communication.subscribe({
        next: async (msg) => {
          if (msg === 'user_selected_scheme_update') {
            await this.setRetailers();
          }
        }
      })
    ];
  }

  $onDestroy() {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }

  async searchByRetailer() {

    if (!this.search_term) {
      this.filteredRetailers = []
    }

    if (this.allRetailers && this.search_term) {
      this.filteredRetailers = this.allRetailers.filter(retailer => retailer.name.toLowerCase().indexOf(this.search_term.toLocaleLowerCase()) !== -1);
    }
  }

  async setRetailers() {
    this.loading.retailers = true;

    this.allRetailers = await this.BenefitBonagoService.getUserSchemeRetailers();

    this.offeredRetailers = await this.BenefitBonagoService
      .getRetailersBasic({category_id: 17});

    this.carouselItems = await this.BenefitBonagoService
      .getRetailersBasic({category_id: 17});


    this.$timeout(() => {
      this.loading.retailers = false;
    });
  }
}

BenefitBonagoHomeController.$inject = $inject;

angular.module('VSPApp').controller('BenefitBonagoHomeController', BenefitBonagoHomeController);


