const $inject = [
  '$scope',
  '$timeout',
  'employeeService',
  'NotificationService'
]

export default class MobileDeviceNameDialogController {
  constructor(
    $scope,
    $timeout,
    employeeService,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      employeeService,
      NotificationService
    });

    this.errors = {};
    this.form = {};
    this.device = $scope.ngDialogData.device;
    this.deviceModel = {
      id: undefined,
      device_name: undefined,
      control_name: 'deviceName'
    };
    this.loading = {
      main: true
    };

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init(){
    this.deviceModel.id = this.device.id;
    this.deviceModel.device_name = this.device.device_name;
  }

  async update_device_name() {
    await this.employeeService.updateEmployeeMobileDeviceName(this.deviceModel.id, this.deviceModel.device_name)
      .then((response) =>{
        if (response.success) {
          this.NotificationService.message(response.message);
          this.device.device_name = this.deviceModel.device_name;
          this.$scope.closeThisDialog();
        } else {
          this.NotificationService.error(response.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
