import {DeliveryData} from "../../models/car-leasing.interfaces";
import {Injectable} from "@angular/core";

@Injectable()
export class BackendDeliveryDataMapper {

  public toApi(a: DeliveryData): object {
    return {
      'name': a.name,
      'last_name': a.lastname,
      'city': a.city,
      'street': a.street,
      'zip_code': a.zip,
      'phone': a.phone,
      'comment': a.comment,
      'preferred_delivery_date': a.preferredDeliveryDate
    }
  }

}
