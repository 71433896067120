import angular from "angular";
import './made-js/MadeJS';

import "@uirouter/angular-hybrid";
import 'angular-i18n/angular-locale_de-de';
import 'angular-touch';
import 'angular-messages';
import 'angular-animate';
import 'ng-dialog';

import 'angular-foundation';
import 'angular-awesome-slider';
import 'angular-svg-round-progressbar';
import 'angular-breadcrumb';
import 'angular-carousel';
import 'angularjs-datepicker';
import 'ng-quill';
import 'angular-wizard';
import 'angular-cookies';
import 'angular-bind-html-compile';
import 'ui-select';
import * as PDFJS from 'pdfjs-dist';
import * as csv from 'csv-string';
import moment from 'moment';
import lodash from 'lodash';

// remove
import 'angular-sanitize';
import 'angular-schema-form';

let ng1module = angular.module('VSPApp', [
  'made-js',
  'ui.router',
  'ui.router.upgrade',
  'ngMessages',
  'ngAnimate',
  'ngDialog',
  'mm.foundation',
  'angularAwesomeSlider',
  'angular-svg-round-progressbar',
  'ncy-angular-breadcrumb',
  'angular-carousel',
  '720kb.datepicker',
  'ngQuill',
  'mgo-angular-wizard',
  'ngSanitize',
  'ui.select',
  'ngCookies',
  'angular-bind-html-compile',
  'schemaForm'
])
  .constant('moment', moment)
  .constant('lodash', lodash)
  .constant('PDFJS', PDFJS)
  .constant('csv', csv);

ng1module.config(['$urlServiceProvider', ($urlService) => $urlService.deferIntercept()]);
