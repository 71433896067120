const $inject = [
  '$q', 'Made'
];
export default class ACLService {

  constructor($q, Made) {
    this.$q = $q;
    this.Made = Made;
  }

  getACLModuleActions(acl_module_name) {
    return this.Made.request('rpc://valuenetdb/user/acl/get_acl_module_actions', {
      acl_module_name: acl_module_name
    });
  }

  getACLModules(acl_module_name = null) {
    return this.Made.request('rpc://valuenetdb/user/acl/get_acl_modules', {
      acl_module_name: acl_module_name
    });
  }

  updatePermissions(employee_id, acl_module_name, customer_id = '*', permissions_to_add = [], permissions_to_delete = []) {
    return this.Made.request('rpc://valuenetdb/user/acl/update_permissions', {
      employee_id: parseInt(employee_id),
      acl_module_name: acl_module_name,
      customer_id: customer_id,
      permissions_to_add: permissions_to_add,
      permissions_to_delete: permissions_to_delete
    });
  }

  removeBonusApprover(employee_id, customer_id, bonus_component) {
    return this.Made.request('rpc://valuenetdb/user/acl/remove_bonus_approver', {
      employee_id: parseInt(employee_id),
      customer_id: customer_id,
      bonus_component: bonus_component
    });
  }

  updateBonusApprover(employee_id, customer_id, bonus_component, permission_actions) {
    return this.Made.request('rpc://valuenetdb/user/acl/update_bonus_approver', {
      employee_id: parseInt(employee_id),
      customer_id: customer_id,
      bonus_component: bonus_component,
      permission_ids: permission_actions
    });
  }

  getApproverPermissions(employee_id, customer_id = null, acl_module = null, bonus_component = null) {
    return this.Made.request('rpc://valuenetdb/user/acl/get_approver_permissions', {
      employee_id: parseInt(employee_id),
      customer_id: customer_id,
      acl_module: acl_module,
      bonus_component: bonus_component,
    });
  }

  getEmployeePermissionActions(employee_id, customer_id, acl_module, acl_module_process, process_resource) {
    return this.Made.request('rpc://valuenetdb/user/acl/get_employee_permission_actions', {
      employee_id: parseInt(employee_id),
      customer_id: customer_id,
      acl_module: acl_module,
      acl_module_process: acl_module_process,
      process_resource: process_resource
    });
  }

  getCustomerApprovers(customer_id) {
    return this.Made.request('rpc://valuenetdb/user/acl/get_customer_approvers', {
      customer_id: customer_id,
    });
  }

  canApproveModule(employee_id, customer_id, module) {
    return this.Made.request('rpc://valuenetdb/user/acl/can_approve_decline_module', {
      employee_id: employee_id,
      customer_id: customer_id,
      module: module
    });
  }

  getEmployerCustomers(options) {
    let parameters = {
      employer_id: options.employer_id
    };

    return this.Made.request('rpc://valuenetdb/user/acl/get_employer_customers', {
      employer_id: parameters.employer_id
    });
  }

  getCustomerGroups(customer_id, user_group_name = null) {
    return this.Made.request('rpc://valuenetdb/user/acl/get_customer_groups', {
      customer_id: customer_id,
      user_group_name: user_group_name
    });
  }

  amIApprover() {
    return this.Made.request('rpc://valuenetdb/user/acl/am_i_approver');
  }

  amIBonusIssuer(customer_id = null) {
    return this.Made.request('rpc://valuenetdb/user/acl/am_i_bonus_issuer', {
      customer_id: customer_id
    });
  }

  amIEmployer(customer_id = null) {
    return this.Made.request('rpc://valuenetdb/user/acl/am_i_employer', {
      customer_id: customer_id
    });
  }

  getACLUserGroups(options = {}) {
    let params = {
      to_include_approval: options.to_include_approval
    };
    return this.Made.request('rpc://valuenetdb/user/acl/get_acl_user_groups', params);
  }

  getEmployeeCustomerSpecificGroups(employee_id) {
    return this.Made.request('rpc://valuenetdb/user/acl/get_employee_customer_specific_groups', {
      employee_id: employee_id
    });
  }

  migrateCurrentEmployersToACL() {
    return this.Made.request('rpc://valuenetdb/user/acl/migrate_current_employers_to_acl');
  }

  migrateFidApprovalToACL() {
    return this.Made.request('rpc://valuenetdb/user/acl/migrate_fid_approval_to_acl');
  }

  getApprovalComponents() {
    return this.Made.request('rpc://valuenetdb/user/acl/get_approval_components')
  }
}
ACLService.$inject = $inject;
