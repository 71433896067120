import {PC_YEAR_CONTRACT_DURATION} from "../../../../../services/mppService";

const $inject = [
  '$scope',
  '$timeout',
  'mppService',
  'lodash'
];
export default class EditBasketGeneralController {
  constructor(
    $scope,
    $timeout,
    mppService,
    lodash
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      mppService,
      lodash
    });

    this.$scope.leasingRegex = /^\d+\/\d+$/;

    this.loading = {
      main: false
    };

    this.forms = {
      edit_basket_general_form: {}
    };

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.basket = angular.copy(this.$scope.ngDialogData.basket);


    this.basket_id = this.basket['_id'];

    this.is_bonus_basket = !!this.basket.bonus;

    let basket_fields_for_edit = ['order_id',
      'contract_id',
      'otrs',
      'confirmation',
      'bill_sent_to_leasing_company',
      'contact_information'
    ];

    if (!this.is_bonus_basket) {
      basket_fields_for_edit.push('beginDate', 'endDate');
    }

    this.basketModel = this.lodash.pick(this.basket, basket_fields_for_edit);

    if (!this.basketModel.contact_information) {
      this.initBasketContactInformation();
    }

    this.isEmailValid = true;
    this.isPhoneValid = true;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  handleOrderIdChange() {
    this.forms.edit_basket_general_form['$$controls'][3].$setValidity('unique', true);
  }

  async saveBasketGeneralInformation() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    let result = null;

    try {
      result = await this.mppService.saveBasketGeneralInformation(this.basket_id, this.basketModel);
    } catch (e) {
      console.log("error", e);
    } finally {
      this.$timeout(() => {
        this.loading.main = false;
      });
    }

    if (result) {
      this.$scope.confirm();
    }
  }

  //extract into general function (copied from basket_form)
  async getEndDate() {
    let begin_date = this.basketModel.beginDate;
    this.$timeout(() => {
      this.loading.main = true;
    });

    return this.mppService.calculateBeginEndDate({
      begin_date: begin_date,
      to_format: true
    }).then((result) => {
      this.basketModel.endDate = result.end_date;

      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  initBasketContactInformation() {
    this.basketModel.contact_information = {
      email_address: '',
      phone_number: ''
    };
  }

  updateEmailAddress(emailAddress) {
    if(emailAddress) {
      this.isEmailValid = true;
      this.basketModel.contact_information.email_address = emailAddress || undefined;
    } else {
      this.isEmailValid = false;
    }
  }

  updatePhoneNumber(phoneNumber) {
    if(phoneNumber) {
      this.isPhoneValid = true;
      this.basketModel.contact_information.phone_number = phoneNumber || undefined;
    } else {
      this.isPhoneValid = false;
    }
  }
}
EditBasketGeneralController.$inject = $inject;
