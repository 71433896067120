import {Injectable} from '@angular/core';
import {CarLeasingCheckoutConfiguration} from "../../models/car-leasing-checkout-configuration.model";
import {CarLeasingAdvantageCalculationResult} from "../../models/car-leasing-advantage-calculation-result.model";

@Injectable()
export class BackendCarLeasingCarAdvantageResultMapper {

  public toApi(carAdvantageResult: CarLeasingAdvantageCalculationResult): object {
    return {
      'reduction_from_netto': carAdvantageResult.reductionFromNetto,
      'car_fuel_bonus': carAdvantageResult.carFuelBonus,
      'fuel_cost_per_month': carAdvantageResult.fuelCostPerMonth,
      'personal_cost_of_car': carAdvantageResult.personalCostOfCar,
      'private_cost_base': carAdvantageResult.privateCostBase,
      'private_cost_total': carAdvantageResult.privateCostTotal,
      'private_cost_auto_parts': carAdvantageResult.privateCostAutoPart,
      'private_cost_finance': carAdvantageResult.privateCostFinance,
      'private_cost_garage': carAdvantageResult.privateCostGarage,
      'private_cost_insurance': carAdvantageResult.privateCostInsurance,
      'admin_cost': carAdvantageResult.adminCost,
      'transfer_cost': carAdvantageResult.transferCost
    };
  }
}
