import header from '../../helpers/header.main.pug';
import cms_main_template from '../../modules/cms/cms.pug';
import cms_emtry_list_template from '../../modules/cms/entryList/entryList.pug';
import cms_list_template from '../../modules/cms/list/list.pug';
import cms_editor_list_template from '../../modules/cms/editorList/editorList.pug';
import cms_category_list_template from '../../modules/cms/categoryList/categoryList.pug';
import cms_category_create_template from '../../modules/cms/categoryCreate/categoryCreate.pug';
import cms_details_template from '../../modules/cms/details/details.pug';
import cms_details_header_template from '../../modules/cms/details/detailsHead.pug';
import cms_create_template from '../../modules/cms/create/create.pug';
angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    let authorized = ['authenticationService', function (authenticationService) {
      authenticationService.iMustBe([
        'cms-editor'
      ]);
    }];

    $stateProvider
      .state('inApp.cms', {
        url: '/cms',
        views: {
          main: {
            template: cms_main_template,
            controller: 'CmsController',
            controllerAs: 'cms'
          },
          header: {
            template: header
          }
        }
      })
      .state('inApp.cms.list', {
        url: '/list?custId',
        views: {
          content: {
            template: cms_emtry_list_template,
            controller: 'CmsEntryListController',
            controllerAs: 'list',
          }
        }
      })
      .state('inApp.cms.itemlist', {
        url: '/itemlist/:categoryId?custId',
        params: {
          categoryId: null
        },
        views: {
          content: {
            template: cms_list_template,
            controller: 'CmsListController',
            controllerAs: 'list',
          }
        }
      })
      .state('inApp.cms.editorlist', {
        url: '/editorlist?custId',
        resolve: {
          authorized: authorized
        },
        views: {
          content: {
            template: cms_editor_list_template,
            controller: 'CmsEditorListController',
            controllerAs: 'list',
          }
        }
      })
      .state('inApp.cms.categorylist', {
        url: '/categorylist?custId',
        resolve: {
          authorized: authorized
        },
        views: {
          content: {
            template: cms_category_list_template,
            controller: 'CmsCategoryListController',
            controllerAs: 'list',
          }
        }
      })
      .state('inApp.cms.categorycreate', {
        url: '/category/create?custId',
        resolve: {
          authorized: authorized
        },
        views: {
          content: {
            template: cms_category_create_template,
            controller: 'CmsCategoryCreateController',
            controllerAs: 'create',
          }
        }
      })
      .state('inApp.cms.categoryedit', {
        url: '/category/edit/:categoryId?custId',
        params: {
          categoryId: null
        },
        resolve: {
          authorized: authorized
        },
        views: {
          content: {
            template: cms_category_create_template,
            controller: 'CmsCategoryCreateController',
            controllerAs: 'create',
          }
        }
      })
      .state('inApp.cms.details', {
        url: '/details/:cmsId?custId',
        params: {
          cmsId: null
        },
        views: {
          content: {
            template: cms_details_template,
            controller: 'CmsDetailsController',
            controllerAs: 'details',
          },
          head: {
            template: cms_details_header_template,
            controller: 'CmsDetailsController',
            controllerAs: 'details',
          }
        }
      })
      .state('inApp.cms.create', {
        url: '/create?custId',
        resolve: {
          authorized: authorized
        },
        views: {
          content: {
            template: cms_create_template,
            controller: 'CmsCreateController',
            controllerAs: 'create',
          }
        }
      })
      .state('inApp.cms.edit', {
        url: '/edit/:cmsId?custId',
        params: {
          cmsId: null
        },
        resolve: {
          authorized: authorized
        },
        views: {
          content: {
            template: cms_create_template,
            controller: 'CmsCreateController',
            controllerAs: 'create',
          }
        }
      });

  }]);
