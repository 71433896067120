const $inject = [
  '$scope',
  '$q',
  'lodash',
  'dialogService',
  'Made',
  '$timeout',
  'employeeService',
  'administrationService',
  'UserGroupsService',
  'ACLService',
  'NotificationService',
  'ByteplantService',
];
export default class AssignApprovalPermissionsDialogController {
  constructor(
    $scope,
    $q,
    lodash,
    dialogService,
    Made,
    $timeout,
    employeeService,
    administrationService,
    UserGroupsService,
    ACLService,
    NotificationService,
    ByteplantService
  ) {
    Object.assign(this, {
      $scope,
      $q,
      lodash,
      dialogService,
      Made,
      $timeout,
      employeeService,
      administrationService,
      UserGroupsService,
      ACLService,
      NotificationService,
      ByteplantService
    });

    this.loading = {
      main: true,
      email_preferences: false,
      permissions: false
    };

    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.customer_id = this.$scope.ngDialogData.customer_id;

    this.acl_module_name = this.$scope.ngDialogData.acl_module;
    this.acl_module_process_name = this.$scope.ngDialogData.acl_module_process;
    this.process_resource_name = this.$scope.ngDialogData.process_resource;

    this.forms = {};

    this.filter = {
      employee_id: this.employee_id || null
    };

    this.models = {
      email_settings: {
        to_email: undefined,
        current_to_email: undefined,
        to_receive_approval_communication: true
      }
    };

    this.employee = null;
    this.approve_permission = false;
    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      }, 0);
    });

    this.email = {
      valid: false,
      checking: false
    }
  }

  async init() {
    let promises = [];
    promises.push(this.initACLModuleConfig());
    promises.push(this.initPermisssionTypes());
    promises.push(this.get_EMPLOYEE_EMAIL_TYPES());

    return this.$q.all(promises).then(() => {
      if (this.filter.employee_id) {
        return this.filterEmployees();
      }
    });
  }

  toSetEmailPreferences() {
    return this.acl_module_name === 'approval';
  }

  canSaveEmailPreferences() {
    return this.lodash.get(this.forms, 'email_preferences_form.$valid', false);
  }

  areFormsValid(forms) {
    return Object.keys(forms).reduce((acc, form_name) => {
      acc = acc && forms[form_name].$valid;
      return acc;
    }, true);
  }

  async get_EMPLOYEE_EMAIL_TYPES() {
    this.EMPLOYEE_EMAIL_TYPES_BY_NAME = await this.employeeService.get_EMPLOYEE_EMAIL_TYPES_BY_NAME();
  }

  async saveEmailPreferences() {

    if (!this.canSaveEmailPreferences()) {
      this.NotificationService.error('Bitte geben Sie eine gültige E-Mail Adresse ein.');
      return;
    }

    this.$timeout(() => {
      this.loading.email_preferences = true;
    });

    await this.employeeService.setEmailPreferences({
      for_employee_id: this.filter.employee_id,
      to_receive: this.models.email_settings.to_receive_approval_communication,
      email_string: this.models.email_settings.to_email,
      email_type_id: this.EMPLOYEE_EMAIL_TYPES_BY_NAME['approval_email']['id'],
    }).finally(() => {
      this.$timeout(() => {
        this.loading.email_preferences = false;
      });
    });

    this.$scope.confirm(this.employee);
  }

  initACLModuleConfig() {
    let promises = []
    promises.push(this.ACLService.getACLModules(this.acl_module_name).then(
      (acl_module) => {
        this.acl_module = acl_module;

        return this.acl_module;
      }).catch((err) => {
      console.error(err);
    }));

    promises.push(
        this.ACLService.getApprovalComponents().then(
        (approval_components) => {
          this.approval_components = approval_components;
        })
    );

    return this.$q.all(promises).then(() => {

      return;
    });
  }

  initPermisssionTypes() {
    return this.ACLService.getACLModuleActions(this.acl_module_name).then(
      (permission_actions) => {
        this.permission_actions_by_name = permission_actions.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
        return this.permission_actions_by_name;
      }).catch((err) => {
      console.error(err);
    });
  }

  filterEmployees() {
    this.loading.filter = true;
    this.employee = null;
    return this.employeeService
      .get(this.filter.employee_id, new Date())
      .then(async (employee) => {
        let promises = [];
        this.employee = employee || null;

        if (this.employee) {
          promises.push(
            this.getEmployeePermissionActions(
              this.filter.employee_id,
              this.customer_id,
              this.acl_module_name,
              this.acl_module_process_name,
              this.process_resource_name
            )
          );
          this.models.email_settings.to_email = await this.employeeService.getApprovalEmail(this.employee);
          this.models.email_settings.current_to_email = this.models.email_settings.to_email;
          this.models.email_settings.to_receive_approval_communication = this.lodash.get(this.employee, 'email_preferences.approval_email.to_receive', this.models.email_settings.to_receive_approval_communication);
        }

        return this.$q.all(promises).then(() => {
          return this.employee;
        });
      }).catch((err) => {
        this.employee = null;
        console.error(err);
      }).finally(() => {
        this.$timeout(() => {
          this.loading.filter = false;
        });
      });
  }

  getEmployeePermissionActions(employee_id, customer_id, acl_module, process, process_resource) {
    return this.ACLService.getEmployeePermissionActions(employee_id, customer_id, acl_module, process, process_resource).then(
      (permissions) => {
        permissions.forEach((permission_action) => {
          if (this.permission_actions_by_name[permission_action]) {
            this.permission_actions_by_name[permission_action].selected = true;
          }
        });
        return;
      }
    ).catch((err) => {
      console.error(err);
    });
  }

  async savePermissions() {

    if (this.toSetEmailPreferences()) {
      await this.saveEmailPreferences();
    }

    this.$timeout(() => {
      this.loading.permissions = true;
    });

    let permissions_to_add = this.getPersmissions(true);
    let permissions_to_delete = this.getPersmissions(false);

    return this.ACLService.updatePermissions(this.employee.id, this.acl_module_name, this.customer_id, permissions_to_add, permissions_to_delete).then(
      (response) => {
        this.$scope.confirm(this.employee);
      }).catch((err) => {
      console.error(err);
    }).finally(() => {
      this.$timeout(() => {
        this.loading.permissions = false;
      });
    });

    this.$scope.confirm(this.employee);
  }

  async close() {
    this.$scope.confirm();
  }

  getPersmissions(are_assigned = true) {
    let permissions = [];
    Object.keys(this.permission_actions_by_name).forEach((key) => {
      if ((are_assigned && this.permission_actions_by_name[key].selected === true) || (!are_assigned && !this.permission_actions_by_name[key].selected)) {
        permissions.push({
          permission_action: this.permission_actions_by_name[key].name,
          module: this.acl_module_process_name,
          resources: [this.process_resource_name]
        });
      }
    });
    return permissions;
  }

  async checkEmailValidity() {
    this.email.valid = false;
    this.email.checking = true;
    const isValid = await this.ByteplantService.isEmailAddressValid(
      this.models.email_settings.to_email
    );
    this.email.valid = isValid ? true : false;
    this.email.checking = false;
    if (!isValid) this.NotificationService.error('Validierung der E-Mail-Adresse ist fehlgeschlagen');
  }
}
AssignApprovalPermissionsDialogController.$inject = $inject;
