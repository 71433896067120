import {OPTIMIZATION_ASSISTANT_STEPS} from "./optimization_assistant_steps";
export const DEFAULT_ASSISTANT_STEPS = [];
export const ASSISTANT_STEPS = [
  {
    id: 1,
    title: 'Erholungsbeihilfe',
    default_settings: {
      is_available: true
    },
    optimization_settings: {
      is_available: false
    },
    components: ['erholungsbeihilfe'],
    question: 'Bitte teilen Sie uns Ihre Steuerklasse mit, diese wird für die Validierung der Wahlleistungen benötigt.',
    options: [{
      type: 'select',
      key: 'steuer_klasse',
      is_global: true,
      // parent: 'steuer_klass',
      default: null,
      title: 'Steuerklassen',
      options: {
        key: 'SteuerklassenConstants',
        key_property: 'value',
        display_property: 'name'
      }
    }]
  },
  // {
  //   id: 2,
  //   title: 'Bikeleasing',
  //   optimization_settings: {
  //     is_available: true
  //   },
  //   components: ['Bikeleasing'],
  //   question: 'Planen Sie in den nächsten 12 Monaten sich ein neues Fahrrad zu kaufen? Dann tragen Sie hier bitte den Kaufpreis ein, den Sie sich vorgestellt hatten. Haben Sie aktuell keine Vorstellung, klicken Sie auf ‚Ich plane keine Anschaffung‘.',
  //   options: [
  //     {
  //       type: 'input',
  //       control_type: 'number',
  //       title: '€',
  //       key: 'value',
  //       // parent: 'Bikeleasing',
  //       default: 0,
  //       settings: {
  //         disabled: {
  //           key: 'is_disabled'
  //         },
  //         range: { min: 0 }
  //       }
  //     },
  //     {
  //       type: 'checkbox',
  //       title: 'Ich plane keine Anschaffung',
  //       // parent: 'Bikeleasing',
  //       key: 'is_disabled',
  //       default: false,
  //       // settings: {
  //       //   checkbox: {
  //       //     true_value: false,
  //       //     false_value: true
  //       //   }
  //       // }
  //     }
  //   ]
  // },
  {
    id: 3,
    title: 'WorkingDays',
    default_settings: {
      is_available: true
    },
    optimization_settings: {
      is_available: true
    },
    components: ['essensschecks_pst', 'essensschecks_frei'],
    question: 'Wieviel Tage arbeiten Sie regelmäßig in der Woche',
    components_settings: {
      essensschecks_pst: {
        do_not_add: true
      },
      essensschecks_frei: {
        do_not_add: true
      }

    },
    options: [
      {
        type: 'select',
        title: 'Arbeitstage in der Woche',
        key: 'working_days',
        is_global: true,
        default: 5,
        options: {
          key: 'AvailableWorkingDays'
        }

        // slider_options: {
        //   from: 1, step: 1, to: 5
        // }
      }
    ]
  },
  {
    id: 4,
    title: 'Mobiltelefon',
    default_settings: {
      is_available: false
    },
    optimization_settings: {
      is_available: false
    },
    components: ['handy'],
    question: 'Haben Sie einen Mobilfunkvertrag mit einer monatlichen Flatrate, die keine Finanzierung eines Telefons beinhaltet?',
    options: [
      {
        type: 'input',
        control_type: 'number',
        title: '€',
        key: 'advantage',
        default: 0,
        settings: {
          disabled: {
            key: 'is_disabled'
          },
          range: {min: 0},
          currency: true,
        },

      },
      {
        type: 'checkbox',
        title: 'nicht zutreffend',
        key: 'is_disabled',
        default: false,
        // settings: {
        //   checkbox: {
        //     true_value: false,
        //     false_value: true
        //   }
        // }
      }
    ]
  },
  {
    id: 5,
    title: 'Festnetz',
    default_settings: {
      is_available: false
    },
    optimization_settings: {
      is_available: false
    },
    components: ['festnetz'],
    question: 'Nutzen Sie zuhause einen Internetanschluss und haben hierfür einen Vertrag mit einer Flatrate? Hierfür steht eine Wahlleistung zur Verfügung.',
    options: [
      {
        type: 'input',
        control_type: 'number',
        title: 'Dann tragen Sie hier bitte den Betrag ein, den Sie monatlich dafür zahlen.',
        key: 'advantage',
        default: 0,
        settings: {
          disabled: {
            key: 'is_disabled'
          },
          range: {min: 0},
          currency: true,
        }
      },
      {
        type: 'checkbox',
        title: 'nicht zutreffend',
        key: 'is_disabled',
        default: false,
        // settings: {
        //   checkbox: {
        //     true_value: false,
        //     false_value: true
        //   }
        // }
      }
    ]
  },
  {
    id: 6,
    title: 'Auswärtstätigkeit',
    default_settings: {
      is_available: true
    },
    optimization_settings: {
      is_available: true
    },
    components: ['essensschecks_pst', 'essensschecks_frei'],
    question: 'Haben Sie im Monatsdurchschnitt keine oder weniger als 3 Tage eine Auswärtstätigkeit?',
    calculation_settings: {
      variables: {
        working_days: {
          is_global: true,
          key: 'working_days'
        }
      }
    },
    options: [
      {
        type: 'checkbox',
        title: 'zutreffend',
        key: 'has_worked_outside_firm',
        default: null
      },
      {
        type: 'checkbox',
        title: 'nicht zutreffend',
        key: 'has_worked_outside_firm',
        default: null,
        settings: {
          checkbox: {
            true_value: false,
            false_value: true
          }
        }
      }
    ]
  }
];


// add default steps which are also valid for optimization
ASSISTANT_STEPS.forEach(step => {
  if (step.optimization_settings && step.optimization_settings.is_available) {
    OPTIMIZATION_ASSISTANT_STEPS.push(step);
  }
});

ASSISTANT_STEPS.forEach(step => {
  if (step.default_settings && step.default_settings.is_available) {
    DEFAULT_ASSISTANT_STEPS.push(step);
  }
});
