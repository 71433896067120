import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from "../../../../shared/models/base.component";
import { AdministrationService } from 'src/app/administration/services/administration.service';
import { QuillModules } from "ngx-quill";
import { AlertService } from "../../../../shared/services/alert.service";
import { Subscription } from 'rxjs';
import { IncentOffer, IncentOfferType } from 'src/app/administration/models/incent/incent-offer.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { first, switchMap } from 'rxjs/operators';

export interface CustomOfferFormControl {
  text: string,
  period: string,
  isActive: boolean,
  offerId: string
}

@Component({
  selector: 'vn-administration-configuration',
  templateUrl: './administration-configuration.component.html',
  styleUrls: ['./administration-configuration.component.scss']
})
export class AdministrationConfigurationComponent extends BaseComponent implements OnInit, OnDestroy {

  QUILL_MODULES: QuillModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }], // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }], // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],

        ['link', 'image', 'video']
      ]
    }
  };

  private subscriptions$: Subscription[] = [];
  offers: IncentOffer[] = [];
  incentOffersForm!: FormGroup;

  constructor(
    private alertService: AlertService,
    private service: AdministrationService,
    private formBuilder: FormBuilder
  ) {
    super()
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(s => {
      s.unsubscribe();
    })
  }

  ngOnInit(): void {
    this.load();
  }

  get form() { return this.incentOffersForm.controls }
  get oFormArray() { return <FormArray>this.form.offers as FormArray; }

  get offersFormGroup(): FormGroup[] {
    return this.oFormArray.controls as FormGroup[]
  }

  private load() {
    this.setLoading$(true);

    this.incentOffersForm = this.formBuilder.group({
      offers: new FormArray([])
    });
    this.offers = [];

    this.subscriptions$.push(this.service.getIncentOffers().subscribe((r: IncentOffer[]) => {
        this.offers = [...r];
        if (!this.offers.length) {
          const startDate = moment();
          this.offers = [
            new IncentOffer(
              null,
              '',
              IncentOfferType.DEFAULT,
              '',
              null,
              true
            ),
          ]
        }

        this.offers.forEach((o: IncentOffer) => {
          this.oFormArray.push(this.formBuilder.group(this.getBuilderControls(o)));
        });

        this.setLoading$(false);
      })
    )
  }


  getOfferByIndex(i: number): IncentOffer {
    return this.offers[i]
  }

  addCustomOffer($event: Event) {
    $event.preventDefault();

    const customOffer = new IncentOffer(
      null,
      '',
      IncentOfferType.CUSTOM,
      '',
      {
        startDate: moment(),
        endDate: moment().add(5, 'days'),
        offerId: null
      },
      false
    )

    this.offers.push(customOffer);

    const formControl = this.getBuilderControls(customOffer);
    this.oFormArray.push(this.formBuilder.group(formControl));
  }

  getBuilderControls(o: IncentOffer) {
    const defaultTextRule = [o.text, Validators.required]

    if (o.config) {
      return {
        id: o.id,
        text: defaultTextRule,
        period: [o.getPeriodForDateRange(), Validators.required],
        offerId: [o.config.offerId, Validators.required],
        isActive: o.isActive,
        offerType: o.type
      }
    }

    return {
      id: o.id,
      text: defaultTextRule,
      period: null,
      offerId: null,
      isActive: true,
      offerType: o.type
    }

  }

  onSubmit() {
    this.setLoading$(true);
    const r = this.incentOffersForm.value.offers as Partial<CustomOfferFormControl>[];
    
    this.service.saveOrUpdateOffers(r).pipe(first()).subscribe((r: boolean) => {
      if (r) {
        this.load();
        this.setLoading$(false);
      }
    });


  }
}
