import header from '../../helpers/header.main.pug';
import cockpit_template from '../../modules/cockpit/cockpit.pug';
import cockpit_contracts_template from '../../modules/cockpit/bike/contracts/cockpit_bike_contracts.pug';


angular
  .module('VSPApp')
  .config(['$stateProvider', 'CockpitPagesConstants', function ($stateProvider, CockpitPagesConstants) {

    const amIAuthorized = ['authenticationService', 'ACLService', (authenticationService, ACLService) => {
      const iAmAdvisor = authenticationService.iAm([
        'gadvisor',
        'advisor',
      ]);

      return ACLService.amIEmployer().then((iAmEmployer) => {
        return iAmAdvisor || iAmEmployer;
      });
    }];

    $stateProvider
      .state('inApp.cockpit', {
        url: '/cockpit',
        resolve: {
          authorized: amIAuthorized
        },
        views: {
          main: {
            template: cockpit_template,
            controller: 'CockpitController',
            controllerAs: 'CockpitCtrl'
          },
          header: {
            template: header
          }
        },
        ncyBreadcrumb: {
          label: 'Cockpit',
          parent: 'blank.home'
        }
      });

    angular.forEach(CockpitPagesConstants, function (cockpitPage) {
      generateCockpitRoutes($stateProvider, cockpitPage);
    });

    $stateProvider
      .state('inApp.cockpit.bikeContracts', {
        url: '/bike/:customerId/contracts',
        params: {
          customerId: null
        },
        views: {
          cockpitMain: {
            template: cockpit_contracts_template,
            controller: 'CockpitBikeContractsController',
            controllerAs: 'BikeContractsCtrl'
          }
        },
        ncyBreadcrumb: {
          label: 'Fahrrad'
        }
      });

  }]);


function generateCockpitRoutes($stateProvider, cockpitPage) {

  if (cockpitPage.disabled) {
    return;
  }

  const resolver = cockpitPage.resolver ? {resolve: {authorized: cockpitPage.resolver}} : {};
  const routeSuffix = cockpitPage.routeSuffix ? cockpitPage.routeSuffix : '';

  let routeConfig = {
    url: '/' + cockpitPage.id + '/:customerId' + routeSuffix,
    params: cockpitPage.params,
    ncyBreadcrumb: {
      label: cockpitPage.title
    },
    ...resolver
  }

  if (cockpitPage.angular12) {
    routeConfig = {
      ...routeConfig,
      ...{
        views: {
          cockpitMain: {
            component: cockpitPage.component
          },
        },
      }
    };
  } else {
    const upperCaseFirstLetterId = cockpitPage.key
      .charAt(0)
      .toUpperCase() + cockpitPage.key.slice(1);
    const controller_name = cockpitPage.controller_name || 'Cockpit' + upperCaseFirstLetterId + 'Controller';
    const controller_as_name = cockpitPage.controller_as_name || upperCaseFirstLetterId + 'Ctrl';

    routeConfig = {
      ...routeConfig,
      ...{
        views: {
          cockpitMain: {
            template: require(`../../modules/cockpit/${cockpitPage.id}/cockpit_${cockpitPage.key.toLowerCase()}.pug`),
            controller: controller_name,
            controllerAs: controller_as_name
          }
        },
      }
    };

  }
  $stateProvider.state('inApp.cockpit.' + cockpitPage.id, routeConfig);
}
