import {Injectable} from '@angular/core';
import {CarLeasingCheckoutConfiguration} from "../../models/car-leasing-checkout-configuration.model";

@Injectable()
export class BackendCarLeasingCheckoutConfigurationMapper {

  public toApi(checkoutConfig: CarLeasingCheckoutConfiguration): object {
    return {
      'car_id': checkoutConfig.carId,
      'contract_duration': checkoutConfig.contractDuration,
      'extra_km': checkoutConfig.extraKm.km,
      'extra_km_package_price': checkoutConfig.extraKm.price,
      'additional_price_per_km': checkoutConfig.additionalPricePerKm,
      'per_month_value': checkoutConfig.perMonthValue,
      'total_value': checkoutConfig.totalValue,
      'finn_per_month_value': checkoutConfig.finnPerMonthValue,
      'finn_total_value_gross': checkoutConfig.finnTotalValueGross,
      'car_brand': checkoutConfig.carBrand,
      'car_model': checkoutConfig.carModel,
      'car_type': checkoutConfig.carType,
      'car_color': checkoutConfig.carColor,
      'car_fuel_type': checkoutConfig.carFuelType,
      'available_from': checkoutConfig.availableFrom,
      'work_home_distance': checkoutConfig.workHomeDistance,
      'has_company_car': checkoutConfig.hasCompanyCar,
      'financing_type': checkoutConfig.financingType,
      'msrp': checkoutConfig.msrp,
      'power': checkoutConfig.power
    };
  }

}
