import { OnInit, DoCheck, Component, Inject, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'vn-consent-dialog',
  templateUrl: './consent-dialog.component.html',
  styleUrls: ['./consent-dialog.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ConsentDialogComponent)
    }
  ]
})
export class ConsentDialogComponent implements OnInit, DoCheck {

  private consentApi!: any;
  private foundService!: any;
  private consentId: string = '';

  @Input() ucServiceName: string = '';
  @Output() ucServiceStatus = new EventEmitter<boolean>();
  @Output() ucServiceAction = new EventEmitter<string>();

  constructor(
    @Inject('UsercentricsConstants') private ucConstants: any
  ) {
  }

  ngOnInit():void {
    this.consentId = this.ucConstants[this.ucServiceName];
  }

  ngDoCheck(): void {
    if (!this.consentApi) {
      // @ts-ignore
      if (window.UC_UI && window.UC_UI.isInitialized()) {
        // @ts-ignore
        this.consentApi = window.UC_UI;
      }
    } else {
      if (!this.foundService) {
        this.foundService = this.consentApi.getServicesBaseInfo().find(
          (svc: any) => svc.id === this.ucConstants[this.ucServiceName]
        );
      } else {
        this.ucServiceStatus.emit(this.foundService.consent.status);
      }
    }
  }

  public acceptButtonClicked(): void {
    this.consentApi.acceptService(this.consentId).then(() => {
      this.consentApi.enableScriptsForServicesWithConsent();
    });

    this.ucServiceStatus.emit(true);
    this.ucServiceAction.emit('accepted');
  }

}
