<div class='form-group'>
    <mat-slide-toggle [name]='switchKey'
                      [disabled]='isDisabled'
                      [checked]="value"
                      [attr.data-cy]='switchKey'
                      (change)="toggle($event.checked)">
    </mat-slide-toggle>

    <span class="toc-label">
        {{ tocLabel }}
    </span>
</div>