const $inject = [
  '$transitions',
  '$state',
  'MenuConstants',
  'VorsorgeConstants',
  'customerService',
  'NotificationService',
  'authenticationService'
];
export default class CockpitVorsorgeController {

  constructor(
    $transitions,
    $state,
    MenuConstants,
    VorsorgeConstants,
    customerService,
    NotificationService,
    authenticationService
  ) {

    Object.assign(this, {
      $transitions,
      $state,
      MenuConstants,
      VorsorgeConstants,
      customerService,
      NotificationService,
      authenticationService
    });

    this.working = true;
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');

    customerService
      .get($state.params.customerId)
      .then(customer => this.customer = customer);

    customerService
      .getConfiguration($state.params.customerId)
      .then(configuration => {
        this.configuration = configuration;
        for (let k in configuration.vorsorge) {
          if (configuration.vorsorge[k].title === 'betriebliche_altersvorsorge') {
            this.zuschuss = !configuration.vorsorge[k].enabled;
            this.bavKey = k;
            if (configuration.vorsorge[k].value === undefined) {
              configuration.vorsorge[k].value = 0;
            }
          }
        }
      })
      .then(() => this.working = false);

  }

  toggle(key) {

    if (!this.configuration.vorsorge) {
      this.configuration.vorsorge = {};
    }
    if (!this.configuration.vorsorge[key]) {
      this.configuration.vorsorge[key] = {
        enabled: true,
      };
    }

    this.configuration.vorsorge[key].enabled = !this.configuration.vorsorge[key].enabled;
    if (this.configuration.vorsorge[key].title === 'betriebliche_altersvorsorge') {
      this.zuschuss = !this.configuration.vorsorge[key].enabled;
    }

  }

  save() {

    this.working = true;

    this.customerService
      .setConfiguration(this.$state.params.customerId, this.configuration)
      .then(() => this.NotificationService.message('Speichern erfolgreich.'),
        () => this.NotificationService.error('Speichern fehlgeschlagen.'))
      .finally(() => this.working = false);

  }

}
CockpitVorsorgeController.$inject = $inject;
