import { CarLeasingBasket } from "../../../../app/car-leasing/models/car-leasing-basket.model";
import { CarLeasingTicketCreation } from '../../../../app/car-leasing/models/car-leasing-ticket-creation.model'

const $inject = [
  '$scope',
  '$timeout',
  'CarLeasingApiService',
  'NotificationService'
]

export default class CarLeasingCancellationDialogController {
  constructor(
    $scope,
    $timeout,
    CarLeasingApiService,
    NotificationService
  ) {
    Object.assign(this, {
      $scope,
      $timeout,
      CarLeasingApiService,
      NotificationService
    });

    this.loading = {
      main: false,
      cancellation: false,
    };

    this.forms = {
      cancellationCommentForm: {}
    };

    this.model = {
      cancellationComment: ''
    }

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    /** @var CarLeasingBasket */
    this.basket = this.$scope.ngDialogData.basket;

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  async cancelBasket() {
    this.$timeout(() => {
      this.loading.cancellation = true;
    });
    try {

      let response = await this.CarLeasingApiService.cancelBasket(
        this.basket.id,
        this.model.cancellationComment
      );

      console.log(response);

      if (response.success && !response.missing_otrs) {
        this.NotificationService.message('Auto Abo wurde storniert.');
      } else if(response.success && response.missing_otrs) {
        this.NotificationService.warning("Die E-Mail Benachrichtigung konnte auf Grund fehlender OTRS Ticketverknüpfung nicht verschickt werden - bitte nachpflegen.");
      } else {
        this.NotificationService.error(response.msg);
      }

      this.$scope.confirm();

    } catch (e) {
      this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
    } finally {
      this.$timeout(() => {
        this.loading.cancellation = false;
      });
    }
  }

}

CarLeasingCancellationDialogController.$inject = $inject
