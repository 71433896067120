const $inject = [
  '$scope',
  '$state',
  'ShopService'
];
export default class KategorieController {
  constructor(
    $scope,
    $state,
    ShopService
  ) {

    this.$scope = $scope;
    this.$state = $state;
    this.ShopService = ShopService;

    this.kategorieId = this.$state.params.kategorieId;
    this.$scope.categories = this.ShopService.categories;
    this.$scope.offers = [];

    this.$scope.getImageSrc = this.ShopService.getImageSrc;

    this.ShopService
      .loadOffers(this.kategorieId)
      .then(offers => this.$scope.offers = offers);

  }
}
KategorieController.$inject = $inject;
