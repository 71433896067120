import * as moment from "moment";
import { Component, OnInit, Input } from '@angular/core';
import { CarColor } from '../../models/car-leasing.interfaces';
import { Car } from '../../models/car.model';

@Component({
  selector: 'vn-car-leasing-card',
  templateUrl: './car-leasing-card.component.html',
  styleUrls: ['./car-leasing-card.component.scss']
})
export class CarLeasingCardComponent implements OnInit {

  @Input()
  public car!: Car;

  public get colorList() {
    if (!this.car) {
      return [];
    }

    return this.car.color_list;
  }

  public isElectric() {
    return this.car?.fuel === 'Elektro';
  }

  public isHybrid() {
    return this.car?.fuel === 'Plug-In-Hybrid' || this.car?.fuel === 'Hybrid-Benzin';
  }

  public hasQuickDelivery() {
    // REVIEW add delivery options to car
    (this.car as any)?.delivery_options === 'delivery'
  }

  public getColorStyle(color: CarColor) {
    return {
      'background-color': color.color_hex
    };
  }

  public getBackgroundImageStyle() {
    if (!this.car) {
      return {};
    }

    let imageUrl = this.car?.picture?.url;

    if (!imageUrl) {
      imageUrl = this.car?.brand?.helper_brand_logo?.thumbnails?.large?.url;
    }

    const style = {
      'background-image': `url(https://res.cloudinary.com/finn-auto/image/fetch/q_auto,f_auto,c_fill,dpr_2,w_296,h_176,fl_progressive/${imageUrl})`
    };

    return style;
  }

  public transformDate(carDate: string | undefined) {
    if (!carDate) {
      return '';
    }

    return moment(carDate, 'YYYY-MM-DD').format('DD.MM.YY');
  }

  constructor() { }

  ngOnInit(): void {
  }

}
