<vn-dticket-order-shared-employee-data
  [employee]='dialogData.employeeData'
  (formSaved)="handleFormSaved($event)"
  [dialogConfirmButtonText]="dialogConfirmButton"
>
  <h1 mat-dialog-title headerText>
    {{ dialogTitle }}
  </h1>
  
  <div descriptionText>
    <h5>Mit Ausführung der Wiederherstellung wird ihr aktuelles Ticket deaktiviert und Sie erhalten stattdessen ein neues gültiges Ticket. Eine Email mit allen relevanten Informationen dazu wird Ihnen zugesandt.</h5>
  
    <p>Bitte überprüfen Sie Ihre persönlichen Daten. Diese werden zur Legitimation benötigt.</p>
  </div>
  

</vn-dticket-order-shared-employee-data>
