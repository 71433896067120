var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (containerAttributes, fieldsetCounter, fileInputAttributes, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};



















































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_mixins["dropzone"] = pug_interp = function(model, single, id, tags, employeeId, cyData){
var block = (this && this.block), attributes = (this && this.attributes) || {};
containerAttributes = {}
fileInputAttributes = {}
if (single) {
containerAttributes = { 'made-dropzone-single' : 'true' }
fileInputAttributes = { 'made-file-single' : 'true' }
}
else {
fileInputAttributes = { multiple : 'multiple' }
}
if (!id) {
id = 'uploader'
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "dropzone-container","made-dropzone": pug.escape(model)},containerAttributes,attributes]), true)) + "\u003E\u003Cdiv class=\"dropzone-inner-container\"\u003E";
if (employeeId) {
pug_html = pug_html + "\u003Cfile-chooser" + (pug.attr("files", model, true, true)+pug.attr("filter-tags", tags, true, true)+pug.attr("employee-id", employeeId, true, true)) + "\u003E\u003C\u002Ffile-chooser\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + ("\u003Cdiv class=\"drag-and-drop-label\"\u003E\u003Cimg src=\"\u002Fassets\u002Fimg\u002Fsvg\u002Ficon-download.svg\"\u003E" + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp)));
if (single) {
pug_html = pug_html + "\u003Cspan\u003EDatei hierherziehen\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003EDateien hierherziehen\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider\"\u003Eoder\u003C\u002Fdiv\u003E\u003Cdiv class=\"file-input\"\u003E\u003Cinput" + (pug.attrs(pug.merge([{"id": pug.escape(id),"type": "file","made-file-input": pug.escape(model),"style": "visibility:hidden","data-cy": pug.escape(cyData)},fileInputAttributes]), true)) + "\u003E";
if (single) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDatei auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
else {
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id, true, true)) + "\u003E\u003Cspan class=\"button\"\u003EDateien auswählen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"files\"\u003E";
if (block) {
block && block();
}
else
if (single) {
pug_html = pug_html + "\u003Cspan" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-if", model +' && !'+ model +'._id', true, true)+pug.attr("ng-click", model + '=undefined', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("ng-if", model +'._id', true, true)+pug.attr("ng-href", '/gridfs?id={{'+ model +'._id}}', true, true)+" target=\"_blank\" rel=\"noreferrer\"") + "\u003E" + (pug.escape(null == (pug_interp = ' {{ '+ model +'.name }} ({{ '+ model +'.size | filesize }})') ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (" class=\"files\""+pug.attr("ng-repeat", '(index, file) in ' + model, true, true)) + "\u003E\u003Cspan ng-if=\"!file._id\"\u003E{{ file.name }} ({{ file.size | filesize }})\u003C\u002Fspan\u003E\u003Ca" + (pug.attr("ng-click", model + '.splice(index, 1)', true, true)) + "\u003E\u003Ci class=\"font-icon icon-close right\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca ng-if=\"file._id\" ng-href=\"\u002Fgridfs?id={{ file._id }}\" target=\"_blank\" rel=\"noreferrer\"\u003E{{ file.filename }} ({{ file.meta.created *1000 | date }}, {{ file.length | filesize }})\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"cockpit-panel__title\"\u003EKUNDE: {{$ctrl.personal_info.customer_id}} | {{$ctrl.personal_info.employer_address.name}} | {{$ctrl.employee_id}}, {{ $ctrl.personal_info.firstname }} {{ $ctrl.personal_info.lastname }}\u003C\u002Fdiv\u003E\u003Cdiv class=\"cockpit-panel__body\" overlay=\"$ctrl.loading\"\u003E\u003Cdiv class=\"row\"\u003E\u003Ch4\u003ENeues Fahrrad für Mitarbeiter {{ $ctrl.employee_id}}, {{ $ctrl.personal_info.firstname }} {{ $ctrl.personal_info.lastname }} erfassen\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column small-4 filter-input\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"entrydateInput\" date-refocus=\"false\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.begin_date","title": "Beginn","required": pug.escape(true),"data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputPaymentBeginDate","attributes": {"vn-date-helper":"true","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","ng-change":"$ctrl.calculateLimits()"},"validationMessages": {"required":"Bitte abschätzen, wann Beginn wahrscheinlich ist.","pattern":"Bitte Beginndatum im Format tt.mm.jjjj eingeben"},"fieldHtmlClass": "entrydateInput"}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.monthly_amount","title": "monatliche Rate","required": pug.escape(true),"data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputPerMonthAmount","validationMessages": {"required":"Pflichtfeld","vnMax":"Der monatliche Betrag darf {{ $ctrl.limits.max_user_rate | currency : 'EUR' }} nicht überschreiten."},"attributes": {"vn-currency":"true","vn-max":"{{ $ctrl.is_overspending_allowed ? '' : $ctrl.limits.max_user_rate }}"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.tax_base_amount","title": "UVP Brutto","required": pug.escape(true),"data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputTaxBaseAmount","validationMessages": {"required":"Pflichtfeld"},"attributes": {"vn-currency":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.full_amount","title": "Bruttokaufpreis","required": pug.escape(true),"data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputFullAmount","validationMessages": {"required":"Pflichtfeld"},"attributes": {"vn-currency":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.validation_url","title": "URL Freigabe","required": pug.escape(true),"data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputValidationUrl","validationMessages": {"required":"Bitte Hyperlink aus E-Mail einfügen."}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.validation_code","title": "FreigabeCode","data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputValidationCode"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.ticket_number","title": "Ticket ID","required": pug.escape(true),"data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputTicketNumber","validationMessages": {"required":"Bitte Ticket ID einfügen"},"attributes": {}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-4\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.bikeleasing_checkout_config.contract_id","title": "Contract ID","required": pug.escape(true),"data-cy": "bonus.checkout.bikeLeasing.giveBikeDialog.inputContractId","validationMessages": {"required":"Bitte Contract ID einfügen","pattern":"Contract-ID ist ungültig, bitte Eingabe prüfen"},"attributes": {"ng-pattern":"$ctrl.BIKELEASING_CONTRACT_REGEX"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"columns small-12\"\u003E\u003Ch3\u003EEinzelleasingvertrag Bikeleasing\u003C\u002Fh3\u003E";
pug_mixins["dropzone"](
          '$ctrl.bikeleasing_checkout_config.file',
          true,
          'fahrrad_anlegen_dropzone_id',
          undefined,
          undefined,
          'bonus.checkout.bikeLeasing.giveBikeDialog.fileContract'
        );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"class": "form-padding","name": "$ctrl.forms.bikeleasingCPForm"}
});
pug_html = pug_html + "\u003Cdiv class=\"row\" ng-if=\"$ctrl.limits\"\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary\" type=\"button\" ng-disabled=\"!$ctrl.forms.bikeleasingCPForm.$valid || !$ctrl.bikeleasing_checkout_config.file\" ng-click=\"$ctrl.accept()\" data-cy=\"bonus.checkout.bikeLeasing.giveBikeDialog.buttonSubmit\"\u003EZur Freigabe an AG übermitteln\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cbutton class=\"button button-primary right\" type=\"button\" ng-click=\"$ctrl.decline()\" data-cy=\"bonus.checkout.bikeLeasing.giveBikeDialog.buttonCancel\"\u003EAbbrechen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "containerAttributes" in locals_for_with ?
        locals_for_with.containerAttributes :
        typeof containerAttributes !== 'undefined' ? containerAttributes : undefined, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "fileInputAttributes" in locals_for_with ?
        locals_for_with.fileInputAttributes :
        typeof fileInputAttributes !== 'undefined' ? fileInputAttributes : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;