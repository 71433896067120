import {BonusComponentController} from './BonusComponentController';
import {TAX_PAYERS_BY_NAME} from "../../../services/bonus/tax_payer";
import {MONTHS_IN_YEAR} from "../../../services/BonusService";
import {TAX_TYPE_BY_NAME} from "../../../services/bonus/tax_type";


const $inject = [
  'WizardHandler',
  '$scope',
  '$state',
  'BonusService',
  'customerService',
  'moment',
  'vbmData',
  'Made',
  '$timeout',
  'employeeService',
  'SliderOptionsConstants',
  'SteuerklassenConstants',
  'vbmService',
  'dialogService',
  '$window',
  'administrationService',
  'lodash',
  '$filter'
];
export default class BonusComponentControllerEssenscheck extends BonusComponentController {

  constructor (
    WizardHandler,
    $scope,
    $state,
    BonusService,
    customerService,
    moment,
    vbmData,
    Made,
    $timeout,
    employeeService,
    SliderOptionsConstants,
    SteuerklassenConstants,
    vbmService,
    dialogService,
    $window,
    administrationService,
    lodash,
    $filter
  ) {

    super(
      WizardHandler,
      $scope,
      $state,
      BonusService,
      customerService,
      moment,
      vbmData,
      Made,
      $timeout,
      employeeService,
      SliderOptionsConstants,
      SteuerklassenConstants,
      vbmService,
      dialogService,
      $window,
      administrationService,
      lodash,
      $filter
    );

    this.loading.child = true;
    this.TAX_PAYERS_BY_NAME = TAX_PAYERS_BY_NAME;
    this.baseInitPromise.then(() => {
      return this.init();
    }).finally(() => {
      this.$timeout(() => {
        this.loading.child = false;
      }, 0);
    });
  }

  async addComponentToEmployeeBonusComponents (move_to_next_step = true) {
    return super.addComponentToEmployeeBonusComponents(false).then(async (result) => {
      if (!result) {
        return;
      }

      if (!this.model.max_working_days) {
        this.max_working_days = (await this.BonusService.getMaxValues(this.bonus_component_name, this.moment().year()))[this.bonus_component_name];
      } else {
        this.max_working_days = this.model.max_working_days;
      }

      if (!this.model.sliders) {

        let sliders = {
          working_days: angular.copy(this.SliderOptionsConstants),
          months: angular.copy(this.SliderOptionsConstants)
        };

        Object.assign(sliders.working_days, {
          to: this.max_working_days,
          step: 1,
          from: 1
        });

        Object.assign(sliders.months, {
          to: this.model.max_months = MONTHS_IN_YEAR,
          step: 1,
          from: 0
        });

        this.model.sliders = sliders;

        this.setModelValues({
          working_days: 5,
          months: 0
        });

        // was set by assistant
        if (!this.model.is_optimized) {
          await this.setMaxMonths();
          await this.calculateValue();
          this.calculateMonthsDifference();
        }
      }

      this.working_days_watcher = this.$scope.$watch(() => {
        return this.model.working_days;
      }, async (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          await this.setMaxMonths();
          await this.calculateValue();
        }
      });

      this.months_watcher = this.$scope.$watch(() => {
        return this.model.months;
      }, async (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          await this.calculateValue();
          this.calculateMonthsDifference();
        }
      });

      this.$scope.$on('$destroy', () => {
        this.working_days_watcher();
        this.months_watcher();
        // this.remaining_amount();
      });

      if (move_to_next_step) {
        this.WizardHandler.wizard().next();
      }

    });
  }

  async init () {
  }

  async setMaxMonths (reset_month = true) {
    this.$timeout(()=>{
      this.loading.calculating = true;
    });

    let remaining_amount = this.calculateRemainingWithoutCurrentBonus();
    let max_months = Math.floor(remaining_amount / Math.max(await this.calculatePerMonthValue(true), 1));
    max_months = Math.max(Math.min(max_months, this.bonus_project_duration_in_months, MONTHS_IN_YEAR, this.max_available_months), 0); //max_months has to be 0 or more

    if (max_months === 0) {
      max_months = 1;
    }

    if (this.model.sliders.months.to !== max_months) {
      this.model.sliders.months.to = max_months;
    }

    if (reset_month) {
      this.model.months = 0;
    }

    this.$timeout(()=>{
      this.loading.calculating = false;
    });
  }

  is_additional_tax_enabled (tax_type_id) {
    return this.BonusService.is_additional_tax_enabled(this.component_budget_config, tax_type_id);
  }

  get_additional_tax_value (tax_base, tax_type_id, payer_id) {
    let tax_result = this.BonusService.get_additional_tax_value(this.component_budget_config, {
      payer_id: payer_id,
      tax_base: tax_base,
      tax_type_id: tax_type_id,
      employee_bonus_project_configuration: this.employee_bonus_project_configuration,
      checkout_type: this.checkout_type,
      bonus: this.bonus
    });
    return tax_result;
  }

  async calculatePerMonthValue (return_with_tax = false) {
    this.$timeout(()=>{
      this.loading.calculating = true;
    });

    // let max_pieces_per_day = this.component_budget_config.value / this.max_working_days;
    let flat_days_per_month_factor = 3;
    let pieces_per_month = flat_days_per_month_factor * this.model.working_days;
    let per_month_value = this.BonusService.roundNumber(this.component_budget_config.per_item_price * pieces_per_month);
    let tax_type_id = TAX_TYPE_BY_NAME['monthly']['id'];

    let sachbezug_wert = await this.BonusService.getSachbezugWert();
    let tax_base = sachbezug_wert * this.model.working_days * flat_days_per_month_factor;
    this.model.tax_base = tax_base;

    // ASSUME ALL TAX_PROVIDERS HAVE SAME PAYER
    if (this.component_budget_config.additional_costs && Object.keys(this.component_budget_config.additional_costs).length > 0) {
      let tax_provider = Object.keys(this.component_budget_config.additional_costs)[0];
      this.model.tax_payer = this.component_budget_config.additional_costs[tax_provider].payer;
    } else {
      this.model.tax_payer = null;
    }

    if (this.is_additional_tax_enabled(tax_type_id)) {
      let tax_result = this.get_additional_tax_value(tax_base, tax_type_id);

      let per_month_tax_value = this.lodash.get(tax_result, 'overall', 0);

      this.model.per_month_tax_value = per_month_tax_value;
      this.model.per_month_value_netto = per_month_value;

      // per_month_value += per_month_tax_value;
      delete tax_result.overall;
      this.model.tax_result = this.lodash.merge(this.model.tax_result || {}, tax_result);
    }

    this.model.pieces_per_month = pieces_per_month;
    this.model.per_month_value = per_month_value;
    this.$timeout(()=>{
      this.loading.calculating = false;
    });

    if (return_with_tax && this.model.tax_payer === this.TAX_PAYERS_BY_NAME['employee']['id']){
      return this.model.per_month_value + (this.model.per_month_tax_value || 0);
    }
    return this.model.per_month_value;
  }

  async calculateValue () {
    this.$timeout(()=>{
      this.loading.calculating = true;
    });

    this.model.value = (await this.calculatePerMonthValue(true)) * this.model.months;
    let tax_type_id =  TAX_TYPE_BY_NAME['yearly']['id'];
    if (this.is_additional_tax_enabled()) {
      this.model.netto_value = !angular.isUndefined(this.model.per_month_value_netto) ? this.model.per_month_value_netto * this.model.months : this.model.value;

      let tax_result = this.get_additional_tax_value(this.model.netto_value, tax_type_id);
      this.model.fixed_tax = this.lodash.get(tax_result, 'overall', 0);

      delete tax_result.overall;
      this.model.tax_result = this.lodash.merge(this.model.tax_result || {}, tax_result);
      let variable_tax = (this.model.per_month_tax_value || 0) * this.model.months;
      this.model.tax_value = variable_tax + (this.model.fixed_tax || 0);
      this.model.variable_tax = variable_tax;

      let additional_amount_to_value = this.model.tax_payer === this.TAX_PAYERS_BY_NAME['employee']['id'] ? this.model.fixed_tax : 0;

      this.model.value = this.model.tax_value === 0 ? this.model.value : this.model.value + additional_amount_to_value;
    }

    this.$timeout(()=>{
      this.loading.calculating = false;
    });
  }
}

BonusComponentControllerEssenscheck.$inject = $inject;
