import { BehaviorSubject, Observable } from "rxjs";

export class BaseComponent {

  constructor() {
    
  }

  // ===================== LOADING =====================

  private $loading = new BehaviorSubject(false);
  private _loading$ = this.$loading.asObservable();
  
  protected setLoading$(isLoading: boolean) {
    this.$loading.next(isLoading);
  }

  public isLoading$(): Observable<boolean> {
    return this._loading$;
  }
}