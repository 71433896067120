import {PCComponentModel} from "../../models/pc-component-model";

export const COMPONENTS_WITH_OPTIONAL_SOCIAL_SECURITY = ['pc', 'festnetz', 'handy'];
const $inject = [
  '$q',
  'Made',
  'ComponentWizardConstants',
  'ComponentConstants',
  'vbmService',
  'customerService',
  'VSP_CONSTANTS',
  'employeeService',
  'lodash'
];
export default class componentsService {

  constructor(
    $q,
    Made,
    ComponentWizardConstants,
    ComponentConstants,
    vbmService,
    customerService,
    VSP_CONSTANTS,
    employeeService,
    lodash
  ) {

    this.$q = $q;
    this.Made = Made;
    this.ComponentWizardConstants = ComponentWizardConstants;
    this.vbmService = vbmService;
    this.ComponentConstants = ComponentConstants;
    this.customerService = customerService;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
    this.employeeService = employeeService;
    this.lodash = lodash;

    this.components = [];
    this.availableNeoComponents = {};
  }

  getMaxValues(component = '', year = 0) {
    return this.Made.request('rpc://vbm/neo/getComponentsMaxValues', {
      year: year,
      component: component,
    }).then(result => {
      return result;
    });
  }

  // should be on the BE
  getAvailableNeoComponents() {

    if (Object.keys(this.availableNeoComponents).length) {
      return this.$q.when(this.availableNeoComponents);
    }

    if (this.availableNeoComponentsPromise) {
      return this.availableNeoComponentsPromise;
    }


    this.availableNeoComponentsPromise = this.Made
      .request('rpc://vbm/neo/available_neo_components')
      .then(response => this.availableNeoComponents = response.properties);

    this.availableNeoComponentsPromise.finally(() => {
      delete this.availableNeoComponentsPromise;
    });

    return this.availableNeoComponentsPromise;
  }


  getComponents() {

    if (this.components.length) {
      return this.$q.when(this.components);
    }

    if (this.componentsPromise) {
      return this.componentsPromise;
    }

    this.componentsPromise = this
      .getAvailableNeoComponents()
      .then(availableNeoComponents => {

        for (let componentId in availableNeoComponents) {

          let wizard = this.ComponentWizardConstants[componentId],
            component = availableNeoComponents[componentId];

          if (!wizard) {
            continue;
          }

          this.components.push({
            title: this.ComponentConstants[componentId].title || componentId,
            value: component.properties.value || '',
            id: componentId,
            video: wizard.items && wizard.items[0] && wizard.items[0].video
          });
        }

        return this.components;
      });

    this.componentsPromise.finally(() => {
      delete this.componentsPromise;
    });

    return this.componentsPromise;
  }

  isComponentDisabledForSelfservice(neoProject, component_id, verguetung = false) {
    if (!neoProject.neo_components || !neoProject.neo_components[component_id]) {
      return false
    }
    return !neoProject.neo_components[component_id].enabled || (verguetung && !neoProject.neo_components[component_id].selfservice)
  }

  // this should be on the BE
  async applyConfiguration(customerId, verguetung = false) {

    if (!customerId) {
      customerId = await this.employeeService.getCustomerId()
    }


    return this.customerService
      .getNeoProject(customerId)
      .then( async (neoProject) => {

        let customer_configuration = await this.customerService.getConfigurationByCustomerId(customerId);
        let filtered = [],
          cloneComponents = this.components.slice(0);


        for (let componentId in neoProject.neo_components) {
          if (
            this.isComponentDisabledForSelfservice(neoProject, componentId, verguetung) ||
            (componentId === PCComponentModel.ID && customer_configuration.neo_config.pc.mpp_type === this.VSP_CONSTANTS.MPP_TYPE.COMPONENT_BASED['id'])
          ) {
            continue;
          }

          for (let comp in cloneComponents) {
            if (cloneComponents[comp].id === componentId) {
              filtered.push(cloneComponents.splice(comp, 1)[0]);
              break;
            }
          }
        }

        return filtered;
      });
  }


  getComponentsOfEmployee(employee, unused = false) {

    let filtered = [];

    for (let componentId in employee.data.neo_components) {
      if (
        (unused === employee.data.neo_components[componentId].enabled) ||
        !this.availableNeoComponents[componentId]
      ) {
        continue;
      }

      let fee = 0
      let name = this.ComponentConstants[componentId].title || componentId;
      let tax_modifiers = this.lodash.get(employee, 'result.final.tax.modifiers', []);
      tax_modifiers.forEach((modifier)=>{
        if (modifier.reference === name && modifier.SIGNATURE === 'FFN000') {
          fee = modifier.value / 100;
        }
      });

      filtered.push({
        component: employee.data.neo_components[componentId],
        key: componentId,
        name: name,
        fee: fee
      });
    }

    return filtered;
  }

  getUnusedComponentsOfEmployee(employee) {
    return this.getComponentsOfEmployee(employee, true);
  }


  getUnknownComponents(employee) {
    return this
      .getAvailableNeoComponents()
      .then(availableNeoComponents => {

        let filtered = [];

        for (let componentId in employee.data.neo_components) {
          if (
            !employee.data.neo_components[componentId].enabled ||
            availableNeoComponents[componentId]
          ) {
            continue;
          }

          filtered.push({
            component: employee.data.neo_components[componentId],
            key: componentId,
            name: this.ComponentConstants[componentId].title || componentId
          });
        }

        return filtered;
      });
  }


  getComponentSum(employee) {
    return employee.components
      .concat(employee.unknownComponents)
      .reduce((sum, component) => {
        sum += component.component.value;
        sum += component.fee;
        return sum
      }, 0);
  }

  // required because the salary statement tag is different from the other component tags
  getSalaryStatementTag(){
    // neo.py:70 SALARY_STATEMENT_TAG
    return 'salaryStatement';
  }

  getComponentsRequiringExtraDocuments() {
    return this.Made.request('rpc://vbm/neo/get_components_requiring_extra_documents');
  }

}
componentsService.$inject = $inject;
