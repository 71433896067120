import { APPROVAL_PROCESS_TYPES_BY_NAME, APPROVAL_PROCESS_TYPES_BY_ID, APPROVAL_PROCESS_STATES_BY_ID, APPROVAL_PROCESS_STATES_BY_NAME } from '../../../../services/administrationService';
import { STORNO_BASKET_STATE } from '../../../../services/mppService';
const $inject = [
  '$scope',
  '$q',
  'administrationService',
  'customerService',
  'vbmData',
  'NotificationService',
  'dialogService',
  'UserNotificationService',
  'moment',
  'Made',
  '$timeout',
  '$window',
  'employeeService',
  'lodash',
  'mppService',
  'VSP_CONSTANTS'
];
export default class ControlProcessControlDialogController {
  constructor(
    $scope,
    $q,
    administrationService,
    customerService,
    vbmData,
    NotificationService,
    dialogService,
    UserNotificationService,
    moment,
    Made,
    $timeout,
    $window,
    employeeService,
    lodash,
    mppService,
    VSP_CONSTANTS
  ) {

    Object.assign(this, {
      $scope,
      $q,
      administrationService,
      customerService,
      vbmData,
      NotificationService,
      dialogService,
      UserNotificationService,
      moment,
      Made,
      $timeout,
      $window,
      employeeService,
      lodash,
      mppService,
      VSP_CONSTANTS
    });

    this.loading = true;
    this.hasNewPCEnabled = false;
    this.$scope.ammendment_documents = {};
    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.customer_id = this.$scope.ngDialogData.customer_id;
    this.forms = {};
    this.digital_request_form = {};
    this.control_checkbox = {
      optimisation: false,
      documents: false,
      tax: false,
      personal_info: false
    };

    this.digitalSignatureOptions = {
      isEnabled: false,
      ticketNumber: null,
      expirationDate: null
    };

    this.today = this.moment().utc().set({millisecond: 0, second: 0, minute:0, hour: 0});

    this.APPROVAL_PROCESS_STATES_BY_ID = APPROVAL_PROCESS_STATES_BY_ID;
    this.APPROVAL_PROCESS_STATES_BY_NAME = APPROVAL_PROCESS_STATES_BY_NAME;
    this.STORNO_BASKET_STATE = STORNO_BASKET_STATE;
    this.APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES_BY_NAME;
    this.APPROVAL_PROCESS_TYPES_BY_ID = APPROVAL_PROCESS_TYPES_BY_ID;
    this.blocking_baskets = [];

    let employee_personal_info_promise = this.administrationService.getEmployeePersonalInfo(this.employee_id);
    let employee_tax_info_promise = this.administrationService.getEmployeeTaxInfo(this.employee_id);
    let employee_optimisation_promise = this.administrationService.getEmployeeOptimization(this.employee_id);
    let customer_promise = this.customerService.get(this.customer_id, new Date().getTime());
    let employee_lock_promise = this.administrationService.lockEmployee(this.employee_id);
    let employee_finalised_contract_promise = this.employeeService.getContracts(this.employee_id, true);


    employee_lock_promise.then(employee_lock => {
      this.employee_lock = employee_lock;
      this.is_locked_by_me = this.employee_lock.by_advisor['id'] === this.Made.user.valuenet_id;

      if (this.is_locked_by_me) {
        this.NotificationService.message('Mitarbeiter gesperrt');
      } else {
        this.NotificationService.error(`Datensatz in Bearbeitung durch ${employee_lock.by_advisor.id}, ${employee_lock.by_advisor.firstname}, ${employee_lock.by_advisor.lastname}`);
      }
    }).then(() => {
      return this.$q.all({
        customer: customer_promise,
        personal_info: employee_personal_info_promise,
        tax_info: employee_tax_info_promise,
        optimisation: employee_optimisation_promise,
        contract: employee_finalised_contract_promise,
        MPP_ORDER_STATUSES_BY_ID: this.mppService.getOrderStatusesById()
      }).then((result) => {
          this.customer = result.customer;
          this.hasNewPCEnabled = this.customer.neo_config.pc.mpp_type === this.VSP_CONSTANTS.MPP_TYPE.COMPONENT_BASED['id'];
          this.$scope.personal_info = result.personal_info;
          this.optimisation = result.optimisation;
          this.stopControlProcessIfCheckoutContainsPC = !!this.optimisation.components.find(el => el.name === 'pc') && this.hasNewPCEnabled;
          this.tax_info = result.tax_info;
          this.has_finalized_contract = result.contract && this.optimisation && result.contract.ai_id === this.optimisation.aiid;
          this.MPP_ORDER_STATUSES_BY_ID = result.MPP_ORDER_STATUSES_BY_ID;

          this.latestExpirationDate = this.moment(this.optimisation.beginn, "DD.MM.YYYY").utc().set({second: 59, minute:59, hour: 23})
          this.latestExpirationDateFormatted = this.latestExpirationDate.format('DD.MM.YYYY')
          this.digitalSignatureOptions.expirationDate = angular.copy(this.latestExpirationDate.format('DD.MM.YYYY'));
        })
        .then(async ()=>{
          //TODO: get rid of array structure for approvals
          this.pending_pc_approvals = await this.administrationService.getApprovalsByCalculation({ session_id: this.optimisation['calcId'] });

          this.blocking_baskets = await this.mppService.getBlockingBaskets({session_id:this.optimisation['calcId']});

          this.has_pending_pc_approvals = false;
          this.has_declined_pc_approvals = false;

          for (let approval_process of this.pending_pc_approvals) {
            if (this.lodash.get(approval_process, 'mpp_basket.order_process')) {
              approval_process.mpp_basket.sent_for_delivery_date = this.getDefaultSentForDeliveryDate();
            }

            if (approval_process.state_id === this.APPROVAL_PROCESS_STATES_BY_NAME['waiting_external_approval']['id']) {
              this.has_pending_pc_approvals = true;
              break;
            } else if (approval_process.state_id === this.APPROVAL_PROCESS_STATES_BY_NAME['external_declined']['id']) {
              this.has_declined_pc_approvals = true;
              break;
            }
          }
        })
        .then(() => {
          this.$window.addEventListener('beforeunload', () => {
            this.administrationService.unlockEmployee(this.employee_id);
          });
        })
        .catch(() => {
          this.NotificationService.error('Couldn\'t fetch needed information.');
          this.loading_error = true;
        }).finally(() => {
          this.$timeout(()=>{
            this.loading = false;
          });
        });
      });
  }

  getOrderProcessStateName(basket) {
    if (basket && basket.order_process) {
      return this.MPP_ORDER_STATUSES_BY_ID[basket.order_process.state_id].display;
    }
    return 'N/A';
  }

  canEditDeliveryDate(basket) {
    if (basket && basket.order_process) {
      return this.MPP_ORDER_STATUSES_BY_ID[basket.order_process.state_id].name === 'order_created_in_retailer';
    }
    return false;
  }

  editPersonalInfo(employee_id) {
        this.dialogService
          .employeeCreate(employee_id)
          .then(() => {
            return this.administrationService.getEmployeePersonalInfo(employee_id);
          })
          .then((result) => {
            return result;
          })
          .then((result) => {
            this.$scope.personal_info = result;
            // this.$scope.apply(); ->> personal_info is hooked to
            // $scope because it needs refreshing through a different controller, which causes the update to leave the scope of this ctrl
          });
      }

  hasUserCheckedEverything() {
        return Object.values(this.forms).reduce((acc, form) => {
          return form.$valid && acc;
        }, true);
      }

  requireDocsValidation() {
        let documents_required = this.lodash.some(this.$scope.ammendment_documents, (document) => {
          return document['for_ammendment'] === true;
        });

        let documents_required_valid = true;
        this.lodash.forEach(this.$scope.ammendment_documents, (document) => {
          if (document['for_ammendment'] === true && !document['comment']) {
            documents_required_valid = false;
          }
        });

        return !this.control_checkbox.documents && documents_required && documents_required_valid;
      }

  formatDocumentAmmendmentPayload() {
        let payload = {
          'comment': this.comment || '',
          'components': {}
        };

        Object.keys(this.$scope.ammendment_documents).forEach((component_id) => {
          if (this.$scope.ammendment_documents[component_id]['for_ammendment'] === true) {
            payload.components[component_id] = { ...this.$scope.ammendment_documents[component_id], ...{ is_uploaded: false } };
          }
        });

        return payload;
      }

  async updateOrderProcessDeliveryDate(basket_id, sent_for_delivery_date) {
    await this.mppService.markForDelivery({'basket_id': basket_id, 'sent_for_delivery_date': sent_for_delivery_date});
  }

  async accept() {
    this.$timeout(()=>{
      this.loading = true;
    });

    //TODO: refactor
    this.pending_pc_approvals.forEach(async (approval) => {
      let order_delivery_date = this.lodash.get(approval, 'mpp_basket.sent_for_delivery_date');
      await this.updateOrderProcessDeliveryDate(approval.mpp_basket._id, order_delivery_date);
    });

    let digitalSignaturePayload = angular.copy(this.digitalSignatureOptions);
    digitalSignaturePayload.expirationDate = this.moment(digitalSignaturePayload.expirationDate).format('DD.MM.YYYY')

    if (digitalSignaturePayload.isEnabled) {
      await this.administrationService.moveToKontrolleP6(this.employee_id);
    }

    this.administrationService.acceptApplication(
      this.employee_id,
      this.comment,
      digitalSignaturePayload,
    )

    this.NotificationService.message('Der Status wurde aktualisiert.');

    this.$timeout(()=>{
      this.loading = false;
    });

    this.$scope.confirm({
      result: true,
      accept: true
    });
  }

  async ammendCalculation() {
        this.administrationService.ammendCalculation(this.employee_id, this.comment).then(() => {
          this.administrationService.sendControlProcessEmail(this.employee_id, 'documents_declined', { comment: this.comment });
          this.UserNotificationService.createNotification(this.employee_id, 'information_wrong').then(() => {
            this.NotificationService.message('Verwaltungsticket wurde erstellt.');
            this.$scope.confirm({
              result: true,
              decline: true
            });
          }).catch(() => {
            this.$scope.confirm({
              result: true,
              decline: true,
              errors: { notification_create: true }
            });
          });
        }).catch((err) => {
          this.NotificationService.error('Fehlgeschlagen.');
          this.$scope.confirm({
            result: true,
            decline: true,
            errors: { application_decline: err }
          });
        });
      }

  async requireNeoDocuments() {
        let payload = this.formatDocumentAmmendmentPayload();
        this.administrationService.requireNeoDocuments(this.employee_id, payload).then(() => {
          this.administrationService.sendControlProcessEmail(this.employee_id, 'documents_declined', payload);
          this.UserNotificationService.createNotification(this.employee_id, 'documents_declined', payload).then(() => {
            this.NotificationService.message('Der Status wurde aktualisiert.');
            this.$scope.confirm({
              result: true,
              decline: true
            });
          }).catch(() => {
            this.$scope.confirm({
              result: true,
              decline: true,
              errors: { notification_create: true }
            });
          });
        }).catch((err) => {
          this.NotificationService.error('Fehlgeschlagen.');
          this.$scope.confirm({
            result: true,
            decline: true,
            errors: { application_decline: err }
          });
        });
      }

  canDeleteCart(approval) {
        return !angular.equals(approval.entity.state, this.STORNO_BASKET_STATE) && angular.equals(approval.state_id, this.APPROVAL_PROCESS_STATES_BY_NAME['external_declined']['id']);
      }

  deleteCart(cart) {
        this.dialogService
          .frageDialog('Durch das Zurücksetzen wird der gesamte Warenkorbinhalt entfernt. Fortfahren?', 'Warenkorb zurücksetzen')
          .then(
            () => {
              this.mppService.cockpitDelete(cart.employeeId, cart._id).then(result => {
                if (result) {
                  location.reload();
                }
              });
            },
            () => { }
          );
      }

  getDefaultSentForDeliveryDate() {
    return this.mppService.getDefaultSentForDeliveryDate();
  }


  isDigitalRequestFormValid() {
    if (this.digitalSignatureOptions.isEnabled) {
      return this.digitalRequestForm.digitalSignatureForm.$valid
    }
    return true;
  }

  canAcceptApplication() {
    if (!this.stopControlProcessIfCheckoutContainsPC &&
      this.hasUserCheckedEverything() &&
      !this.blocking_baskets.length &&
      !this.loading_error &&
      this.isDigitalRequestFormValid()
    ) {
      return true
    }
    return false;
  }

  extractYear(date) {
    return this.moment(date).format('YYYY')
  }
}
ControlProcessControlDialogController.$inject = $inject;
