import {BasketArticle} from "../models/basket-article";

export const DEFAULT_MPP_DELIVERY_SERVICES = [
  {
    id: 0,
    value: 'DHL',
    display: 'DHL',
    tracking_url_template: 'https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?lang=de&idc='
  }, {
    id: 1,
    value: 'Hermes',
    display: 'Hermes',
    tracking_url_template: 'https://tracking.hermesworld.com/?TrackID='
  }, {
    id: 2,
    value: 'DPD',
    display: 'DPD',
    tracking_url_template: 'https://extranet.dpd.de/status/de_DE/parcel/'
  }
];

export const PC_YEAR_CONTRACT_DURATION = 2;

export const DEFAULT_MPP_DELIVERY_SERVICES_BY_NAME = DEFAULT_MPP_DELIVERY_SERVICES.reduce((acc, deliver_service) => {
  acc[deliver_service.display] = deliver_service;
  return acc;
}, {});

export const DEFAULT_MPP_TRACKING_ID_INPUT_ROW = {
  select: {
    title: 'Versand',
    titleMap: DEFAULT_MPP_DELIVERY_SERVICES
  },
  input: {
    title: 'Tracking-ID'
  }
};

export const MPP_LINK_CONSTANTS = [
  {
    name: 'internal',
    link_string: '/mpp'
  },
  {
    name: 'external',
    link_string: 'https://vmpp.cyberport.de/?KEY='
  }
];


export const MPP_LINK_CONSTANTS_BY_NAME = MPP_LINK_CONSTANTS.reduce((acc, constant) => {
  acc[constant.name] = constant;
  return acc;
}, {});


export const STORNO_BASKET_STATE = 'storno';
export const DELETED_BASKET_STATE = 'deleted';
export const IN_CHECKOUT_BASKET_STATE = 'checkout';

export const DEFAULT_MPP_ARTICLE = {
  'Artikelname': '',
  'Artikelnummer': '',
  'netprice': 0.00,
  'price': 0.00,
  'quantity': 1
};

export const ORDER_STATUS_BUTTON_CONFIG = [
  {
    'id': 0,
    'name': 'created_internal',
    'button_display': '',
    'to_show': false,
    'template_config': null
  }, {
    'id': 1,
    'name': 'requested_by_VN',
    'button_display': 'Auftragsnummer',
    'to_show': true,
    'template_config': null
  }, {
    'id': 2,
    'name': 'order_created_in_retailer',
    'button_display': '',
    'to_show': false,
    'template_config': null
  }, {
    'id': 3,
    'name': 'order_created_in_retailer_with_changes',
    'button_display': 'OP - Change Basket',
    'to_show': false,
    'template_config': null
  }, {
    'id': 4,
    'name': 'ready_for_delivery',
    'button_display': 'Trackingnummern',
    'to_show': true,
    'template_config': {
      'controller_name': 'ready_for_delivery_controller', //This has to be refactored!
      'template_name': 'ready_for_delivery_dialog.html'
    }
  }, {
    'id': 5,
    'name': 'sent_by_retailer',
    'button_display': 'Trackingnummern',
    'to_show': true,
    'template_config': {
      'controller_name': 'ready_for_delivery_controller',
      'template_name': 'ready_for_delivery_dialog.html'
    }
  }, {
    'id': 6,
    'name': 'delivered',
    'button_display': 'An Leasing übermitteln',
    'to_show': true,
    'template_config': null
  }, {
    'id': 7,
    'name': 'bill_send_leasing_company',
    'button_display': 'Auftrag abschließen',
    'to_show': true,
    'template_config': null
  }, {
    'id': 8,
    'name': 'declined',
    'button_display': '',
    'to_show': false,
    'template_config': null
  }
];

export const ORDER_STATUS_BUTTON_CONFIG_BY_ID = ORDER_STATUS_BUTTON_CONFIG.reduce((acc, config) => {
  acc[config.id] = config;
  return acc;
}, {});

export const ORDER_STATUS_BUTTON_CONFIG_BY_NAME = ORDER_STATUS_BUTTON_CONFIG.reduce((acc, config) => {
  acc[config.name] = config;
  return acc;
}, {});

export const DEFAULT_ORDER_PROCESS_FILE_TAGS = ['mpp_basket_invoice'];

const $inject = [
  "$q",
  "Made",
  "MppConstants",
  "CacheService",
  "VSP_CONSTANTS",
  "CommonService",
  "moment"
];
export default class mppService {

  constructor($q, Made, MppConstants, CacheService, VSP_CONSTANTS, CommonService, moment) {
    this.$q = $q;
    this.Made = Made;
    this.MppConstants = MppConstants;
    this.CacheService = CacheService;
    this.VSP_CONSTANTS = VSP_CONSTANTS;
    this.CommonService = CommonService;
    this.moment = moment;

    this.init();
  }

  getDefaultSentForDeliveryDate(days_from_now = 0) {
    let sent_for_delivery_date = this.moment().add(days_from_now, 'days').toDate();
    return sent_for_delivery_date;
  }

  async init() {
    this.cache = this.CacheService.getDictionaryStore('mpp');
    this.employeeCache = this.CacheService.getDictionaryStore('customerEmployees');

    this.pageItems = 12;
    this.productLength = 0;

    this.selectedCategories = {};
  }

  async getBlockingBaskets(options) {
    let params = {
      session_id: options.session_id
    };

    return this.Made.request('rpc://shop/mpp/get_cp_blocking_baskets', params);
  }

  isMobileArticle(article) {
    let article_number = article.Artikelnummer;
    let is_mobile_article = false;

    if (article_number) {
      //VN-1583
      for (let main_article_code of ['A4']) {
        if (article_number.startsWith(main_article_code)) {
          is_mobile_article = true;
          break;
        }
      }
    }

    return is_mobile_article;
  }

  addContactInfo(basketId, contactInfo) {
    return this.Made.request(
      'rpc://shop/mpp/add_contact_info',
      {
        basket_id: basketId,
        contact_info: contactInfo
      }
    );
  }

  // #TODO_LEX refactor so this can be removed
  isMainArticle(article) {
    return BasketArticle.isMainArticleNo(article.Artikelnummer);
  }

  hasSupply(article) {
    let verfuegbarkeit = article.Verfuegbarkeit;
    let liefertermin = article.Liefertermin;
    let has_supply = false;

    if (liefertermin === "Verfügbar" && verfuegbarkeit !== '1') {
      has_supply = true;
    }

    return has_supply;
  }

  async isVacationTime() {
    return this.Made.request('rpc://shop/mpp/isVacation');
  }

  getAllEmployeeBaskets() {
    return this.getEmployeeBasket({});
  }

  getEmployeeBasket(options) {
    let params = {
      'employee_id': options['employee_id'],
      'customer_id': options['customer_id'],
      'order_id': options['order_id'],
      'begin_date': options['begin_date'],
      'end_date': options['end_date'],
      'approval_states': options['approval_states'],
      'order_process_states': options['order_process_states'],
      'with_deleted': options['with_deleted'],
      'with_storno': options['with_storno'],
      'contract_id': options['contract_id'],
      'bo_flags_filters': options['bo_flags_filters'],
      'component_states': options['component_states'],
      'basket_types': options['basket_types']
    };

    return this.Made.request('rpc://shop/mpp/getAllEmployeeBaskets', params);
  }

  //TODO: this is a temporary duplicate, the original has to be refactored
  getMPPAdministrationBaskets(options) {
    let params = {
      'employee_id': options['employee_id'],
      'customer_id': options['customer_id'],
      'order_id': options['order_id'],
      'begin_date': options['begin_date'],
      'end_date': options['end_date'],
      'approval_states': options['approval_states'],
      'order_process_states': options['order_process_states'],
      'with_deleted': options['with_deleted'],
      'with_storno': options['with_storno'],
      'contract_id': options['contract_id'],
      'bo_flags_filters': options['bo_flags_filters'],
      'component_states': options['component_states'],
      'basket_types': options['basket_types'],
      'billing_states': options['billing_states'],
      'invoice_reference': options['invoice_reference']
    };

    return this.Made.request('rpc://shop/mpp/getMPPAdministrationBaskets', params);
  }

  getSpecificArticle(id, stale = 0) {
    let product = this.cache.get(id, stale);

    if (product) {
      return product;
    }

    product = this.Made.request('rpc://shop/mpp/getProduct', {
      'filter': id,
    });

    return this.cache.set(id, undefined, product);

  }

  setPageItems(currentItems) {
    this.pageItems = currentItems;
  }

  getPageItems() {
    return this.pageItems;
  }

  setProductLength(length) {
    this.productLength = length;
  }

  getProductLength() {
    return this.productLength;
  }

  setSelectedCategories(categories) {
    this.selectedCategories = categories;
  }

  getSelectedCategories() {
    return this.selectedCategories;
  }

  getCategories(stale = 0) {
    let categories = this.cache.get('all_categories', stale);

    if (categories) {
      return categories;
    }

    categories = this.Made.request('rpc://shop/mpp/getAllCategories');

    return this.cache.set('all_categories', undefined, categories);
  }

  getCurrentProducers(category = {}, stale = 0) {
    let stringify = category.main || category.sub ? JSON.stringify(category) : 'allProducer',
      producers = this.cache.get(stringify, stale);

    if (producers) {
      return producers;
    }

    producers = this.Made.request('rpc://shop/mpp/getCurrentProducers', { 'filter': category });

    return this.cache.set(stringify, undefined, producers);
  }

  getByCategory(category) {
    return this.Made.request('rpc://shop/mpp/getCategoryProducts', { 'filter': category }).then(products => {
      if (angular.isArray(products)) {
        this.setProductLength(products.length);
      } else {
        this.setProductLength(0);
      }
      return products;
    });
  }

  async fixConfigurationsOnBasket() {
    return this.Made.request('rpc://shop/mpp/fixConfigurationsOnBasket');
  }

  setConfigurationsOnBaskets() {
    return this.Made.request('rpc://shop/mpp/setConfigurationsOnBaskets');
  }

  getSearchResults(query) {
    return this.Made.request('rpc://shop/mpp/searchResults', { 'filter': query }).then((results) => {
      this.setProductLength(results.length);

      return results;
    });
  }

  getHandlingFee(is_brutto, customer_id) {
    return this.Made.request('rpc://shop/mpp/get_handling_fee', { 'is_brutto': is_brutto, 'customer_id': customer_id });
  }

  setCart(basket) {
    return this.Made.request('rpc://shop/mpp/changeBasket', { 'basket': basket });
  }

  updateMPPArticleData(options) {
    let parameters = {
      'basket': options.basket,
      'comment': options.comment
    };

    return this.Made.request('rpc://shop/mpp/updateMPPArticleData', parameters);
  }

  calculateCartSum(options) {
    let parameters = {
      'cart_id': options.cart_id,
      'is_brutto': options.is_brutto,
      'cart': options.cart,
      'both': options.both
    };

    return this.Made.request('rpc://shop/mpp/calculate_cart_sum', parameters);
  }

  getCartById(basket_id) {
    return this.Made.request('rpc://shop/mpp/getBasketById', { 'basket_id': basket_id });
  }

  getCart(employeeId, bonus_project_id, to_create_new=true) {
    return this.Made.request('rpc://shop/mpp/getBasket', {'employeeId': employeeId, 'bonus_project_id': bonus_project_id, 'to_create_new': to_create_new});
  }

  getAllCarts(employeeId) {
    return this.Made.request('rpc://shop/mpp/getAllBasket', { 'id': employeeId });
  }

  getEmployeeLeasing(employeeId) {
    return this.Made.request('rpc://shop/mpp/getEmployeeLeasing', { 'employee_id': employeeId });
  }

  getTotals(employeeId) {
    return this.Made.request('rpc://shop/mpp/getSumOfAllActive', { 'id': employeeId });
  }

  getTrackingURL(tracking_id_object) {
    let url = '';
    switch (tracking_id_object.delivery_service) {
      case 'DHL':
        url = `${DEFAULT_MPP_DELIVERY_SERVICES_BY_NAME['DHL']['tracking_url_template']}${tracking_id_object.tracking_id}`;
        break;
      case 'Hermes':
        url = `${DEFAULT_MPP_DELIVERY_SERVICES_BY_NAME['Hermes']['tracking_url_template']}${tracking_id_object.tracking_id}`;
        break;
      case 'DPD':
        url = `${DEFAULT_MPP_DELIVERY_SERVICES_BY_NAME['DPD']['tracking_url_template']}${tracking_id_object.tracking_id}`;
    }

    return url;
  }
  //This is duplicated in MPPAdministrationController and has to be refactored
  getAbsoluteUrl(url) {
    let protocols = ['https://', 'http://'];
    let abs_url_prefix = '//';

    let abs_url_response = '';

    if (typeof (url) == 'string') {
      protocols.forEach((protocol) => {
        if (url.startsWith(protocol)) {
          abs_url_response = url;
        }
      });

      if (!abs_url_response) {
        abs_url_response = abs_url_prefix + url;
      }
    }

    return abs_url_response;
  }

  //TODO: this has to be refactored in a function to be used for both neo and bonus on the BE
  async updateLeasing(cart) {
    let sum = 0,
      lC = this.MppConstants.calculation;

    for (let i = 0; i < cart.articles.length; i++) {
      let cCA = cart.articles[i];
      sum += cCA.netprice * cCA.quantity;
    }

    cart.sum = sum + lC.shipping;
    cart.netto_base = parseFloat((cart.sum).toFixed(2));
    cart.brutto_base = await this.CommonService.add_vat(cart.netto_base);

    cart.cart_leasing_month_netto = parseFloat((cart.netto_base * lC.leasing).toFixed(2));
    cart.cart_leasing_month_brutto = await this.CommonService.add_vat(cart.cart_leasing_month_netto);



    await this.saveCartSum(cart);
    //TODO: when refactor
    return cart.cart_leasing_month_brutto;
  }

  saveCartSum(cart) {
    return this.Made.request('rpc://shop/mpp/applySumToCart', {
      'basket': cart,
    });
  }

  /**
   * Add the PC component to the calculation
   * */
  saveLeasingToMPP(employeeId, leasing) {
    // we also let users without an active NEO pc component to at least load the leasing calculation
    return this.Made.request('rpc://vbm/mpp/applyLeasingToMPP', {
      'employee_id': employeeId,
      'leasingRate': leasing,
    });
  }

  closeMPP(employeeId) {
    this.getCart(employeeId)
      .then(cart => {
        cart.closed = true;
        this.currentCart = cart;
      })
      .then(() => {
        this.setCart(this.currentCart);
      });
  }

  openMPP(employeeId) {
    this.getCart(employeeId)
      .then(cart => {
        cart.closed = false;
        if (cart.beginDate) {
          delete cart.beginDate;
          delete cart.endDate;
        }
        this.currentCart = cart;
      })
      .then(() => {
        this.setCart(this.currentCart);
      });
  }

  deleteBasket(options) {
    let params = {
      basket_id: options.basket_id
    };

    return this.Made.request('rpc://shop/mpp/delete_basket', params);
  }

  returnDeletedBasketIdsInMYSQL() {
    return this.Made.request('rpc://administration/mpp_control_process/return_deleted_basket_ids_in_mysql');
  }

  deactivateCart(employeeId) {
    this.getCart(employeeId)
      .then(cart => {
        cart.active = !cart.active;
        this.currentCart = cart;
      })
      .then(() => {
        this.setCart(this.currentCart);
      });
  }

  validateBasketById(basket_id) {
    return this.Made.request('rpc://shop/mpp/validateBasketById', { 'basket_id': basket_id });
  }

  approveMppBasket(basket_id, is_approved) {
    return this.Made.request('rpc://shop/mpp/approve_mpp_basket', { 'basket_id': basket_id, 'is_approved': is_approved });
  }

  getMPPLink(customer_id) {
    return this.Made.request('rpc://vbm/get_mpp_link', {
      custid: parseInt(customer_id)
    })
      .then(key => {
        let mppLink = key === 0 ? MPP_LINK_CONSTANTS_BY_NAME['internal']['link_string'] : MPP_LINK_CONSTANTS_BY_NAME['external']['link_string'] + key
        return mppLink;
      });
  }

  validateCustomerMPPLimit(customer_id, basket_id) {
    return this.Made.request('rpc://shop/mpp/validate_customer_mpp_limit', { 'customer_id': customer_id, 'basket_id': basket_id });
  }

  checkCart(employeeId) {
    return this.Made.request('rpc://shop/mpp/validateBasket', { 'id': employeeId });
  }

  hasMultipleBaskets(employeeId) {
    return this.Made.request('rpc://shop/mpp/hasMultipleBaskets', { 'id': employeeId });
  }

  calculateBeginEndDate(options) {
    let params = {
      begin_date: options.begin_date,
      to_format: options.to_format
    };

    return this.Made.request('rpc://shop/mpp/calculate_basket_begin_end', params);
  }

  setBasketBeginEnd(options) {
    let params = {
      basket_id: options.basket_id,
      employee_id: options.employee_id,
      begin_date: options.begin_date
    };

    return this.Made.request('rpc://shop/mpp/setBasketBeginEnd', params);
  }

  getStartEndDate(date) {
    let start = date,
      year = start.getFullYear(),
      month = start.getMonth(),
      day = start.getDate(),
      end = new Date(year + 2, month, day);

    return { 'start': start, 'end': end };
  }

  reOpenMPP(employeeId, basketId) {
    return this.Made.request('rpc://shop/mpp/openBasket', { 'id': employeeId, 'basket_id': basketId });
  }

  resetToSaved(employeeId, basketId) {
    return this.Made.request('rpc://shop/mpp/resetToSaved', { 'id': employeeId, 'basket_id': basketId });
  }

  stornoBasket(basket) {
    this.Made.request('rpc://vbm/mpp/checkAndUpdateUsedLimit', { 'customerId': 0 });

    return this.Made.request('rpc://shop/mpp/stornoBasket', { 'basket': basket });
  }

  validateBasketArticlePrices(basket_id) {
    this.Made.request('rpc://shop/mpp/validateBasketPrices', { 'basket_id': basket_id });
  }

  checkBasket(employeeId) {
    return this.Made.request('rpc://shop/mpp/prepareBasketForCheckout', { 'id': employeeId });
  }

  checkCustomerLimit(customer_id, employee_id, bonus_project_id) {
    return this.Made.request('rpc://shop/mpp/can_customer_afford_cart', { customer_id: customer_id, employee_id: employee_id, bonus_project_id: bonus_project_id });
  }

  sendInfomodeOrder(employeeId) {
    return this.Made.request('rpc://shop/mpp/infomodeOrder', { 'id': employeeId });
  }

  getInfoButtonState(employeeId) {
    return this.Made.request('rpc://shop/mpp/getInfoOrderState', { 'id': employeeId });
  }

  getBasketPrecalculatedValues(params) {
    return this.Made.request('rpc://shop/mpp/get_calculated_leasing_fields', params);
  }


  BOnewBasketCreation(params) {
    return this.Made.request('rpc://shop/mpp/bo_new_basket_creation', params);
  }

  cockpitDelete(employeeId, basketId) {
    return this.Made.request('rpc://shop/mpp/specialDeleteBasket', { 'id': employeeId, 'basket_id': basketId });
  }

  //ORDER PROCESS
  getOrderStatusState(id) {
    return this.Made.request('rpc://administration/mpp_control_process/get_order_status_by_id', { state_id: id });
  }

  addContractID(params) {
    return this.Made.request('rpc://administration/mpp_control_process/add_contract_id', params);
  }

  getOrderStatuses() {
    return this.Made.request('rpc://administration/mpp_control_process/get_all_order_status');
  }

  getUnvalidatedMppOrderStatuses() {
    return this.Made.request('rpc://administration/mpp_control_process/get_unvalidated_mpp_order_statuses_object');
  }

  async getOrderStatusesById() {
    if (this.MPP_ORDER_STATUSES_BY_ID) {
      return this.$q.when(this.MPP_ORDER_STATUSES_BY_ID);
    }

    return this.getOrderStatusesByName().then((MPP_ORDER_STATUSES_BY_NAME) => {
      this.MPP_ORDER_STATUSES_BY_ID = Object.keys(MPP_ORDER_STATUSES_BY_NAME).reduce((acc, status_name) => {
        acc[MPP_ORDER_STATUSES_BY_NAME[status_name]['id']] = MPP_ORDER_STATUSES_BY_NAME[status_name];
        return acc;
      }, {});
      return this.MPP_ORDER_STATUSES_BY_ID;
    });
  }

  async getOrderStatusesByName() {
    if (this.MPP_ORDER_STATUSES_BY_NAME) {
      return this.$q.when(this.MPP_ORDER_STATUSES_BY_NAME);
    }

    return this.Made.request('rpc://administration/mpp_control_process/get_all_order_status_by_name')
      .then((MPP_ORDER_STATUSES_BY_NAME) => {
        this.MPP_ORDER_STATUSES_BY_NAME = MPP_ORDER_STATUSES_BY_NAME;
        return this.MPP_ORDER_STATUSES_BY_NAME;
      });
  }

  async getOrderStatusDialogConfig(state_id) {
    let orderStatus = await this.getOrderStatusState(state_id);

    if (!orderStatus) {
      throw "Status not found";
    }

    let dialog_config = ORDER_STATUS_BUTTON_CONFIG_BY_ID[state_id].template_config;

    if (!dialog_config) {
      dialog_config = {
        template_name: orderStatus.name + '_dialog.html',
        controller_name: orderStatus.name + '_controller'
      };
    }

    return dialog_config;
  }

  initiateOrderProcess(options) {
    let params = {
      basket_id: options.basket_id
    };

    return this.Made.request('rpc://administration/mpp_control_process/initiate_order_process', params);

  }

  saveOrderId(basket_id, options) {
    let params = {
      basket_id: basket_id,
      order_id: options.order_id,
      require_changes: options.require_changes,
      ticket_number: options.ticket_number,
      ticket_url: options.ticket_url
    };

    return this.Made.request('rpc://administration/mpp_control_process/order_created_flow', params);
  }

  addOrderIdToOldBaskets(params) {
    return this.Made.request('rpc://administration/mpp_control_process/add_old_baskets_order_id', params);
  }

  confirmBasketChanges(basket_id) {
    return this.Made.request('rpc://administration/mpp_control_process/confirm_basket_changes_flow', {
      basket_id: basket_id
    });
  }

  createOrderInRetailer(options) {
    let params = {
      basket: options.basket_id
    };

    return this.Made.request('rpc://administration/mpp_control_process/create_order_in_retailer', params);
  }

  markForDelivery(options) {
    let params = {
      basket_id: options.basket_id,
      sent_for_delivery_date: options.sent_for_delivery_date
    };

    return this.Made.request('rpc://administration/mpp_control_process/mark_order_for_delivery', params);
  }


  storeTrackingInformation(options) {
    let params = {
      basket_id: options.basket_id,
      tracking_ids: options.tracking_ids,
      pick_up_date: options.pick_up_date
    };

    return this.Made.request('rpc://administration/mpp_control_process/store_tracking_information', params);
  }

  markAsDelivered(options) {
    let params = {
      basket_id: options.basket_id
    };

    return this.Made.request('rpc://administration/mpp_control_process/mark_as_delivered', params);
  }


  initOrderProcessFileTags(options) {
    let basket_id = options.basket_id;

    let order_process_file_tags = angular.copy(DEFAULT_ORDER_PROCESS_FILE_TAGS);
    order_process_file_tags.push(`basket_id-${basket_id}`);

    return order_process_file_tags;
  }

  //updates the order process and sends email to cyberport
  sendOrderToLeasingCompany(options) {
    let params = {
      basket_id: options.basket_id
    };

    return this.Made.request('rpc://administration/mpp_control_process/send_order_to_leasing_company', params);
  }

  //send only the bill email to cyberport without updating the order process - VN-1878
  sendOrderEmailToLeasingCompany(basket_id) {
    let params = {
      basket_id: basket_id
    };
    return this.Made.request('rpc://administration/mpp_control_process/send_order_email_to_leasing_company_external', params);
  }

  getBasketInvoices(options) {
    let params = {
      basket_id: options.basket_id
    };

    return this.Made.request('rpc://administration/mpp_control_process/get_basket_invoices', params);
  }

  closeOrder(options) {
    return this.Made.request('rpc://administration/mpp_control_process/close_order', options);
  }

  cancelOrder(options) {
    let params = {
      basket_id: options.basket_id
    };
    return this.Made.request('rpc://administration/mpp_control_process/cancel_order', params);
  }

  transferBasketToMySQL(basket_id) {
    let params = {
      basket_id: basket_id
    };
    return this.Made.request('rpc://administration/mpp_control_process/transfer_basket_to_mysql_external', params);
  }

  saveBasketGeneralInformation(basket_id, options) {
    let params = {
      basket_id: basket_id,
      options: {
        beginDate: options.beginDate,
        endDate: options.endDate,
        order_id: options.order_id,
        contract_id: options.contract_id,
        otrs: options.otrs,
        confirmation: options.confirmation,
        bill_sent_to_leasing_company: options.bill_sent_to_leasing_company,
        contact_information: {
          email_address: options.contact_information.email_address,
          phone_number: options.contact_information.phone_number
        }
      }
    };
    return this.Made.request('rpc://administration/mpp_control_process/edit_basket_general_information', params);
  }

  //Once all baskets are transferred to the new pc component this will be unused
  getBasketLogs(basket_id, reason_id = null, only_last = false) {
    return this.Made.request('rpc://shop/mpp/get_basket_logs', {
      'basket_id': basket_id,
      'reason_id': reason_id,
      'only_last': only_last
    });
  }

  fixBasketsExport() {
    return this.Made.request('rpc://administration/mpp_control_process/fix_baskets_export');
  }

  sendLeasingEmailsManually() {
    return this.Made.request('rpc://administration/mpp_control_process/send_leasing_emails_manually');
  }

  expireBaskets() {
    return this.Made.request('rpc://shop/mpp/expire_basket_external');
  }

  preendNeoPC(basket_id, preend_data) {
    return this.Made.request('rpc://shop/mpp/preend_basket', {
      'basket_id': basket_id,
      'preend_data': preend_data
    })
  }

  addOrderClosedDateToExportedBaskets() {
    return this.Made.request('rpc://administration/mpp_control_process/add_order_closed_date_to_exported_baskets');
  }

  initBuyingProcessTicketNumber() {
    return this.Made.request('rpc://administration/mpp_buying_process/init_upcoming_leasing_end_4_months');
  }

  informForLeasingEndInThreeMonths() {
    return this.Made.request('rpc://administration/mpp_buying_process/inform_about_upcoming_leasing_end_after_three_months');
  }

  getBuyingProcessEmailsReport() {
    return this.Made.request('rpc://administration/mpp_buying_process/get_buying_process_emails_report');
  }

  //VN-3406
  informForLeasingEndInTwoMonths() {
    return this.Made.request('rpc://administration/mpp_buying_process/inform_about_upcoming_leasing_end_after_two_months');
  }

  //VN-3416
  manual_emails_for_mpp_buying_process() {
    return this.Made.request('rpc://administration/mpp_buying_process/manual_emails_for_mpp_buying_process');
  }

  //VN-3416
  manual_emails_for_mpp_buying_process_get_ticket_ids() {
    return this.Made.request('rpc://administration/mpp_buying_process/manual_emails_for_mpp_buying_process_get_ticket_ids')
  }

  //VN-3416
  parse_buying_process_email_manually() {
    return this.Made.request('rpc://administration/mpp_buying_process/parse_buying_process_email_manually')
  }

  startBuyingProcesses() {
    return this.Made.request('rpc://administration/mpp_buying_process/start_buying_processes');
  }

  reminderForBuyingProcesses() {
    return this.Made.request('rpc://administration/mpp_buying_process/remind_employees_for_started_buying_process');
  }

  billingProcessReminders() {
    return this.Made.request('rpc://administration/mpp_billing_process/send_billing_process_reminders')
  }

  getLastInvoiceNumber() {
    return this.Made.request('rpc://administration/invoice/get_last_invoice_number');
  }

  generateInvoices(params) {
    return this.Made.request('rpc://administration/invoice/generate', params);
  }

  getInvoiceByReferenceId(invoiceReferenceId) {
    return this.Made.request('rpc://administration/invoice/get_invoice_by_reference_id', {
      'invoice_reference_id': invoiceReferenceId
    });
  }

  convertPreendInformationDates() {
    return this.Made.request('rpc://administration/mpp_control_process/convert_preend_information_dates')
  }

  markInvoiceAsPaid(basket_id, payment_date) {
    return this.Made.request('rpc://administration/mpp_billing_process/mark_invoice_as_paid', {
      'basket_id': basket_id,
      'payment_date': payment_date
    });
  }

  expireUserDecisionProcesses() {
    return this.Made.request('rpc://administration/mpp_buying_process/expire_user_decision_processes');
  }

  hasUnfinishedBuyingProcess(employee_id) {
    return this.Made.request('rpc://administration/mpp_buying_process/has_unfinished_buying_process', {
      'employee_id': employee_id
    });
  }

  //VN-3418
  parse_invoice_manually() {
    return this.Made.request('rpc://administration/mpp_control_process/parse_invoice_manually');
  }

  resend_missing_cyberport_emails_VN3439() {
    return this.Made.request('rpc://shop/mpp/resend_missing_cyberport_emails_VN3439')
  }

  fixProblematicMPPBasketsVN4481() {
    return this.Made.request('rpc://shop/mpp/fix_poblematic_baskets_VN4481')
  }

  reexportMppBaskets() {
    return this.Made.request('rpc://administration/mpp_control_process/reexport_mpp_baskets');
  }

  hasEmployeeWantsReturnPc(basketId) {
    let params = {
      basket_id: basketId
    };
    return this.Made.request('rpc://administration/mpp_control_process/has_employee_wants_return_pc', params);
  }

  resetOrder(basket, comment) {
    let params = {
      basket,
      comment
    };
    return this.Made.request('rpc://administration/mpp_control_process/reset_order', params);
  }

  removePreendOnNeoPC(basketId) {
    let params = {
      basket_id: basketId
    };
    return this.Made.request('rpc://administration/mpp_control_process/remove_preend_on_neo_pc', params);
  }

  testPcCombinedPreending() {
    return this.Made.request('rpc://administration/mpp_control_process/inform_bo_for_ending_mpp_combined');
  }


  async getUsedPcBonusComponents(employeeId) {
    const bonuses = await this.Made.request(
      'rpc://shop/mpp/get_used_pc_bonus_components_by_employeeId',
      {employee_id: employeeId}
    )
    return bonuses;
  }

  async recreateBillingProcess() {
    return this.Made.request('rpc://administration/mpp_billing_process/recreate_mpp_billing_process_internal');
  }


  async validate_mpp_basket_dates_for_pickup_issues() {
    return this.Made.request('rpc://valuenetdb/bonus_validator/validate_mpp_basket_dates_for_pickup_issues')
  }

  async get_wrong_future_data_report() {
    return this.Made.request('rpc://valuenetdb/bonus_validator/get_wrong_future_data_report')
  }
  async recalculate_bonus_future_data() {
    return this.Made.request('rpc://valuenetdb/bonus_validator/recalculate_bonus_future_data')
  }

  async get_future_checkouts_with_missing_state() {
    return this.Made.request('rpc://valuenetdb/bonus_validator/get_future_checkouts_with_missing_state')
  }

  async get_mpp_baskets_with_diff_in_order_closed_date_and_begin() {
    return this.Made.request('rpc://valuenetdb/bonus_validator/get_mpp_baskets_with_diff_in_order_closed_date_and_begin')
  }

  async is_customer_for_electronic_present(customer_id) {
    // const specialCustomerIds = [24452, 4095, 5744];
    return this.Made.request('rpc://valuenetdb/bonus/project/is_customer_for_electronic_present', {
      customer_id: customer_id
    });
  }
}

mppService.$inject = $inject;

