export const NOTIFICATION_TYPES = [
  {
    'id': 0,
    'name': 'bonus_notification'
  },
  {
    'id': 1,
    'name': 'approval_process_notification'
  },
  {
    'id': 2,
    'name': 'neo_bikeleasing'
  },
  {
    'id': 3,
    'name': 'mpp_decision_process_notification'
  }
];

export const NOTIFICATION_TYPES_BY_ID = NOTIFICATION_TYPES.reduce((acc, notification_type) => {
  acc[notification_type.id] = notification_type;
  return acc;
}, {});

export const NOTIFICATION_TYPES_BY_NAME = NOTIFICATION_TYPES.reduce((acc, notification_type) => {
  acc[notification_type.name] = notification_type;
  return acc;
}, {});

const $inject = ["Made"];

export default class UserNotificationService {

  constructor( Made ) {

    Object.assign( this, {
      Made
    } );

  }

  getBonusProjectsNotifications(employee_id) {
    return this.Made.request('rpc://valuenetdb/notification/get_bonus_projects_notifications', {
      'employee_id': employee_id
    });
  }

  updateFiduciaBonusNotification(employee_id, bonus_project_id, data) {
    return this.Made.request('rpc://valuenetdb/notification/updateFiduciaBonusNotification', {
      'employee_id': parseInt(employee_id),
      'bonus_project_id': bonus_project_id,
      'data': data
    });
  }

  getNotSeenNotifications(employee_id, type=null, notification_name=null, only_last=false) {
    return this.Made.request('rpc://valuenetdb/notification/get_not_seen_notifications', {
      'employee_id': employee_id,
      'notification_type': type,
      'notification_name': notification_name,
      'only_last': only_last
    });
  }

  getDticketNotification(employee_id) {
    return this.Made.request('rpc://valuenetdb/d_ticket/get_notification_by_employee', {
      'employee_id': employee_id,
    });
  }

  markDticketNotificationAsSeen(dticket_id, employee_id) {
    return this.Made.request('rpc://valuenetdb/d_ticket/mark_dticket_notification_as_seen', {
      'dticket_id': dticket_id,
      'employee_id': employee_id,
    });
  }

  getYearlyNotSeenNotifications(employee_id) {
    return this.Made.request('rpc://valuenetdb/notification/get_yearly_not_seen_notifications', {
      'employee_id': employee_id,
    });
  }

  // future call to get all notification
  getAllNotifications(employee_id) {
    return this.Made.request('rpc://valuenetdb/notification/getAllNotifications', {
      'employee_id': employee_id
    });
  }

  getMissingEVGDocumentsNotification(employee_id) {
    return this.Made.request('rpc://valuenetdb/notification/getMissingEVGDocumentsNotification', {
      'employee_id': employee_id
    });
  }

  markMissingEVGDocumentsNotificationsAsSeen(employee_id) {
    return this.Made.request('rpc://valuenetdb/notification/markMissingEVGDocumentsNotificationsAsSeen', {
      'employee_id': employee_id
    });
  }

  createNotification(employee_id, notification_name, extra={}) {
    employee_id = parseInt(employee_id);

    return this.Made.request('rpc://valuenetdb/notification/createNotification', {
      'employee_id': employee_id,
      'notification_name': notification_name,
      'extra': extra
    });
  }

  getBonusBikeleasingConfirmationNotifications(employee_id) {
    return this.Made.request('rpc://valuenetdb/bonus/project/get_bonus_bikeleasing_confirmation_notifications', {
      employee_id: employee_id
    });
  }

  deleteNotification(notification_id) {
    return this.Made.request('rpc://valuenetdb/notification/deleteNotification', {
      'notification_id': notification_id
    });
  }

  markNotificationAsSeen(notification_id) {
    return this.Made.request('rpc://valuenetdb/notification/markNotificationAsSeen', {
      'notification_id': notification_id
    });
  }

  markNotificationAsExpired(notification_id) {
    return this.Made.request('rpc://valuenetdb/notification/markNotificationAsExpired', {
      'notification_id': notification_id
    });
  }
}

UserNotificationService.$inject = $inject;
