import {CarLeasingBasket} from "../../../../app/car-leasing/models/car-leasing-basket.model";
import {CarLeasingApiService} from "../../../../app/car-leasing/services/car-leasing-api.service";

const $inject = [
  '$scope',
  '$timeout',
  'CarLeasingApiService',
  'fileService',
  '$http'
]

export default class CarLeasingFirstCheckDialogController {
  constructor(
    $scope,
    $timeout,
    CarLeasingApiService,
    fileService,
    $http
  ) {

    /** @var {CarLeasingApiService} */
    this.carLeasingApi = CarLeasingApiService;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.fileService = fileService;
    this.$http = $http;

    this.loading = {
      main: false,
      saving: false,
    };

    this.forms = {
      addOrderForm: {}
    };

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    /** @var {CarLeasingBasket} */
    this.basket = this.$scope.ngDialogData.basket;
    this.employee = this.$scope.ngDialogData.employee;

    this.firstCheckOptions = [
      {
        'name': -1,
        'value': 'Select an option',
      },
      {
        'name': 'rejected',
        'value': 'Reject',
      },
      {
        'name': 'approval',
        'value': 'Approve',
      },
      {
        'name': 'new_documents_request',
        'value': 'New Documents Request',
      }
    ]

    this.forms = {}

    this.requestNewDocumentFormModel = {
      items: []
    }

    this.rejectedFormModel = {
      comment: ''
    }

    this.firstCheckOption = this.firstCheckOptions[0]['name']

    this.driverLicenseFiles = [];

    for (const [fileType, fileId] of Object.entries(this.basket.firstCheckFiles['drivingLicenseFiles'])) {
      this.driverLicenseFiles.push({
        id: fileId,
        type: fileType,
        src: await this.getImgData(fileId)
      })

      this.requestNewDocumentFormModel.items.push({
        fileId: fileId,
        name: fileType,
        selected: false,
        comment: ''
      });

      
    }

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  userHasSelectedAFirstCheckAction() {
    return this.firstCheckOption !== -1
  }

  isFirstCheckApproved() {
    return this.firstCheckOption === 'approval'
  }

  isFirstCheckRejected() {
    return this.forms.rejectedForm && this.forms.rejectedForm.$valid;
  }

  isFirstCheckNewDocumentsAreRequested() {
    return this.forms.requestNewDocumentForm && this.forms.requestNewDocumentForm.$valid && this.requestNewDocumentFormModel.items.filter(obj => obj.selected).length
  }

  canMakeAnAction() {
    return (
      (
        this.isFirstCheckRejected() ||
        this.isFirstCheckNewDocumentsAreRequested()
      ) && this.userHasSelectedAFirstCheckAction()
    ) || this.isFirstCheckApproved()
  }

  async makeAnAction() {
    let result = null
    if (this.isFirstCheckRejected()) {
      result = await this.carLeasingApi.rejectFirstCheck(this.basket.id, this.rejectedFormModel.comment);
    } else if (this.isFirstCheckNewDocumentsAreRequested()) {
      let commentsBySlot = {}
      this.requestNewDocumentFormModel.items.forEach(item => {
        if (item.selected) {
          commentsBySlot[item.name] = item.comment
        }
      });
      result = await this.carLeasingApi.requestNewDocuments(this.basket.id, commentsBySlot);
    } else if (this.isFirstCheckApproved()) {
      result = await this.carLeasingApi.approveFirstCheck(this.basket.id);
    } else {
      throw 'Invalid action'
    }

    if (result) {
      this.$scope.confirm();
    }

  }

  openDriverLicenseImage(fileMongoId) {
    this.fileService.openDocument(fileMongoId)
  }
  

  async getImgData(file_mongo_id) {
    let url = await this.fileService.getDocumentUrl(file_mongo_id);
    let res = await this.$http({
      method: 'GET',
      responseType: 'blob',
      url
    });
    return window.URL.createObjectURL(res.data);
  }

}

CarLeasingFirstCheckDialogController.$inject = $inject
