import { currency } from './shared';

export default class essenscheck {


  static calculate( data, modifier ) {

    data.anzahl_gutscheine = data.anzahl_tage * 3 || 0;

    data.value = this.valueFromAnzahlGutscheine(
      data.anzahl_gutscheine, data.is_sachbezug, modifier
    );

    if ( data.hasOwnProperty( 'bonus_anzahl_gutscheine' ) ) {
      data.bonus_monthly_value = this.valueFromAnzahlGutscheine(
        data.bonus_anzahl_gutscheine, data.is_sachbezug, modifier
      );
    }
  }


  static valueFromAnzahlGutscheine( anzahl_gutscheine, is_sachbezug, modifier ) {
    if ( is_sachbezug ) {
      return currency( anzahl_gutscheine * (modifier.pauschal - modifier.is_sachbezug) );
    }

    return currency( anzahl_gutscheine * modifier.pauschal );
  }


  static calculateDaysFromValue( data, modifier, value, max = 12 ) {

    let coupons = 0;

    if ( data.is_sachbezug ) {
      coupons = value / modifier.is_sachbezug;
    } else {
      coupons = value / modifier.pauschal;
    }


    return Math.min(
      Math.floor( max * data.bonus_anzahl_gutscheine / 3 ),
      Math.floor( coupons / 3 )
    );
  }


}
