<vn-dticket-order-shared-employee-data
  [employee]="dialogData.employeeData"
  (formSaved)="handleFormSaved($event)"
  [disableFields]="!canSendToVesputi"
  [dialogConfirmButtonText]="dialogConfirmButton"
>
  <h1 mat-dialog-title headerText>
    {{ dialogTitle }}
  </h1>

  <div class="userPreferencesForm">
    <form [formGroup]="form">
      <hr>
      <mat-slide-toggle formControlName="isPdfTicket">
        <h5>
          Ticket in Papierform nutzen
        </h5>
      </mat-slide-toggle>
      <hr>
    </form>
  </div>
</vn-dticket-order-shared-employee-data>
