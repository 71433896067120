const $inject = [];

export default class MonthsService {

  constructor() {

    let months = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ];

    this.months = [];

    for (let i = months.length - 1; i >= 0; i--) {
      this.months[i] = {
        name: months[i],
        i: i
      };
    }
  }


  getMonthName(month) {

    month = month % 12 - 1;
    if (month < 0) {
      month = 11;
    }
    return this.months[month].name;
  }


  getNextPaymentDate() {

    let now = new Date();

    now.setMonth(now.getMonth());

    return [
      now.getFullYear(),
      now.getMonth()
    ];
  }


  setMonth(year, month) {
    this.year = year;
    this.month = month;
  }


  resetMonth() {
    this.year = this.getNextPaymentDate()[0];
    this.month = this.getNextPaymentDate()[1];
  }

}

MonthsService.$inject = $inject;