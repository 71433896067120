import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectOption } from 'src/app/shared/components/form-mixins/select/select.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProjectActionsDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';


// ===============================================================

interface FormOptions {
  paymentType: SelectOption[];
}

interface FormChoices {
  paymentType: string;
  ticketUrl: string;
  reason: string;
}

// ===============================================================

@Component({
  selector: 'vn-bonus-order-payment-type-dialog',
  templateUrl: './bonus-order-payment-type-dialog.component.html',
  styleUrls: ['./bonus-order-payment-type-dialog.component.scss']
})
export class BonusOrderPaymentTypeDialogComponent implements OnInit {

  public get orderId(): string {
    return this.dialogData.orderId!;
  }

  public get reason(): FormControl {
    return this.formGroup.get('reason') as FormControl;
  }

  public get ticketUrl(): FormControl {
    return this.formGroup.get('ticketUrl') as FormControl;
  }

  public formGroup!: FormGroup;
  public formOptions!: FormOptions;


  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BonusOrderPaymentTypeDialogComponent>,
    private directBonusService: DirectBonusService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProjectActionsDialogData,
  ) {
  }

  ngOnInit(): void {
    this.initFilterOptions();
    this.initFiltersForm();
  }

  private initFiltersForm() {
    this.formGroup = this.formBuilder.group({
      ticketUrl: this.formBuilder.control(undefined, [
        Validators.required,
        Validators.maxLength(160)
      ]),
      reason: this.formBuilder.control(undefined, [
        Validators.required,
        Validators.maxLength(160)
      ]),
    });
  }

  private initFilterOptions() {
    this.formOptions = {
      paymentType: [
        {
          name: 'S€PA-Lastschrift',
          value: 'sepa'
        },
        {
          name: 'Banküberweisung',
          value: 'not_sepa'
        }
      ]
    };
  }

  public changePaymentType() {
    const {
      ticketUrl,
      reason
    } = this.formGroup.value as FormChoices;

    this.directBonusService.changeOrderPaymentType(
      this.orderId,
      ticketUrl,
      reason
    )
      .subscribe(
        (invoiceData) => {
          if (invoiceData) {
            const message = 'Sie haben die Zahlungsart für diese Bestellung in Banküberweisung geändert.';

            this.alertService.message(message);

            this.dialogRef.close(true);
          }
        },
        (error: Error) => {
          this.alertService.error(`Es ist ein Fehler aufgetreten.`);
        }
      );
  }


}

