enum status {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
}

const cashbackStatusLabels = {
  pending: 'Zur Kontrolle',
  approved: 'Genehmigt',
  declined: 'Abgelehnt',
}

type periodNames =
  | 'one_day'
  | 'one_week'
  | 'one_month'
  | 'three_months'
  | 'six_months'
  | 'twelve_months';

interface fileModel {
  id: string;
  name: string;
}

interface cashbackRequestModel {
  id: number;
  bonusId: string;
  receiptDate: number;
  receiptValidFrom: number;
  receiptValidPeriod: periodNames;
  state: string;
  requestedValue: number;
  approvedValue: number | null;
  comment: string;
  files: fileModel[];
  is_editing: boolean;
  invoiceNumber: string;
  invoiceIssuer: string;
  createdAt: string;
}

interface cashbackDataModel {
  bonusType: string;
  invoiceValue: number;
  componentRest: number;
  projectRest: number;
  cashbackRequests: cashbackRequestModel[];
}


export { cashbackRequestModel, cashbackDataModel, status, periodNames, cashbackStatusLabels };
