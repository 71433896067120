angular
  .module('VSPApp')
  .directive('infiniteScroll', ['$timeout', '$window', function ($timeout, $window) {
    return {
      restrict: 'E',
      scope: {ctrlFn: '&ctrlCallback', loadingFn: '&loadingIndicator'},
      transclude: true,
      template: '<div>' +
        '<div class="listing" ng-transclude></div>' +
        '<div class="row">' +
        '<div class="columns small-12 center">' +
        '<img src="/assets/img/loading.gif" class="loading-image" style="max-width:50px;" alt="loading"/>' +
        '<div class="round to-top hide" >' +
        '<i class="font-icon icon-arrow-up"/>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>',
      link: function ($scope, element, attr) {

        let currentElement = element,
          body = angular.element(document.querySelector('.main-section'))[0],
          topBtn = angular.element(element[0].querySelectorAll('.to-top')[0]),
          loadingImg = angular.element(element[0].querySelectorAll('.loading-image')[0]);

        if (typeof attr.observerElement === 'string' && attr.observerElement !== '') {
          currentElement = angular.element(document.querySelectorAll(attr.observerElement)[0]);
        }

        let watchTarget = angular.element(document.querySelectorAll('.main-section')[0]),
          scrolling = false;

        if (!$scope.loadingFn()) {
          loadingImg.addClass('hide');
        }

        watchTarget.on('scroll', function () {
          if (scrolling) {
            return;
          }

          scrolling = true;
          handleScroll();

          $timeout(function () {
            scrolling = false;
          }, 50);
        });

        topBtn.on('click', scrollToTop);

        function handleScroll() {
          $timeout(function () {
            angular.element(watchTarget)
              .triggerHandler('scroll');
          }, 250);

          let wHeight = $window.innerHeight,
            reloadSpace = wHeight * .3;

          if ((body.scrollTop + wHeight) + reloadSpace > currentElement[0].offsetHeight - reloadSpace) {
            $scope.ctrlFn();
          }

          if (body.scrollTop > wHeight) {
            topBtn.removeClass('hide');
          } else {
            topBtn.addClass('hide');
          }

          if (!$scope.loadingFn()) {
            loadingImg.addClass('hide');
          }
        }

        function scrollToTop() {
          body.scrollTop = 0;
        }
      }
    };
  }]);
