var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};




















































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column\"\u003E\u003Cdiv class=\"red\" ng-if=\"$ctrl.fields.length === 0\"\u003ENo components are allowed\u003C\u002Fdiv\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"budget-form-row\" ng-repeat=\"field in $ctrl.fields\"\u003E";
pug_mixins["checkbox"].call({
attributes: {"key": "$ctrl.direct[field.prop].active","id": "row-{{$ctrl.formName}}-{{$index}}","attributes": {"ng-change":"handleActivationChange($ctrl.direct[field.prop])"}}
});
pug_html = pug_html + "\u003Cdiv class=\"field_label\"\u003E{{ field.label }}\u003C\u002Fdiv\u003E";
pug_mixins["input"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv ng-messages=\"$ctrl.forms[$ctrl.formName]['field_value-'+ $ctrl.formName + '-' + $index].$error\"\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeMinEq\"\u003EDer Mindestbetrag liegt bei {{ $ctrl.direct[field.prop].attributes.currency.to_format ? ($ctrl.direct[field.prop].range.min_eq | currency) : $ctrl.direct[field.prop].range.min_eq }}.\u003C\u002Fdiv\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeMaxEq\"\u003EDer Höchstbetrag liegt bei {{ $ctrl.direct[field.prop].attributes.currency.to_format ? ($ctrl.direct[field.prop].range.max_eq | currency ) : $ctrl.direct[field.prop].range.max_eq }}.\u003C\u002Fdiv\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeMin\"\u003EBitte geben Sie mindestens {{ $ctrl.direct[field.prop].attributes.currency.to_format ? ($ctrl.direct[field.prop].range.min | currency) : $ctrl.direct[field.prop].range.min }} an.\u003C\u002Fdiv\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeMax\"\u003EBitte geben Sie maximal {{ $ctrl.direct[field.prop].attributes.currency.to_format ? ($ctrl.direct[field.prop].range.max | currency) : $ctrl.direct[field.prop].range.max }} an.\u003C\u002Fdiv\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeDecimalsCount\"\u003EEingabe prüfen\u003C\u002Fdiv\u003E\u003Cdiv class=\"helpText\" ng-message=\"required\"\u003EEingabe prüfen\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"id": "field_value-{{$ctrl.formName}}-{{$index}}","groupHtmlClass": "field_value","groupAttributes": {"ng-class":"{large: !$ctrl.direct[field.prop].per_item_price_settings.is_visible}"},"key": "$ctrl.direct[field.prop].value","attributes": {"ng-change":"calculateFieldResult($ctrl.direct[field.prop])","ng-model-options":"{ debounce: 1000 }","vn-currency":"{{ $ctrl.direct[field.prop].attributes.currency }}","vn-range":"$ctrl.direct[field.prop].range","ng-disabled":"!$ctrl.direct[field.prop].active","ng-required":"$ctrl.direct[field.prop].active"}}
});
pug_html = pug_html + "\u003C!--+input(--\u003E\u003C!--  id=\"field_value-{{$index}}\"--\u003E\u003C!--  type=\"number\"--\u003E\u003C!--  groupHtmlClass=\"field_value\"--\u003E\u003C!--  key=\"$ctrl.direct[field.prop].value\"--\u003E\u003C!--  groupAttributes={--\u003E\u003C!--      'ng-if': \"!!$ctrl.direct[field.prop].per_item_price_settings.is_visible\",--\u003E\u003C!--  }--\u003E\u003C!--  attributes={--\u003E\u003C!--      'ng-change':'calculateFieldResult($ctrl.direct[field.prop])',--\u003E\u003C!--      'ng-model-options' :'{ debounce: 1000 }',--\u003E\u003C!--      'vn-range': \"$ctrl.direct[field.prop].range\",--\u003E\u003C!--      'ng-disabled':\"!$ctrl.direct[field.prop].active\",--\u003E\u003C!--      'ng-required':\"$ctrl.direct[field.prop].active\"--\u003E\u003C!--  }--\u003E\u003C!--)--\u003E\u003C!--  div(ng-messages=\"$ctrl.forms[$ctrl.formName]['field_value-'+$index].$error\")--\u003E\u003C!--    div.helpText(ng-message=\"rangeMaxEq\") Es können maximal {{$ctrl.direct[field.prop].range.max_eq}} Essensschecks gewählt werden.--\u003E\u003C!--    div.helpText(ng-message=\"rangeMinEq\") Es können manimal {{$ctrl.direct[field.prop].range.min_eq}} Essensschecks gewählt werden.--\u003E\u003C!--    div.helpText(ng-message=\"rangeDecimalsCount\") Eingabe prüfen--\u003E\u003C!--    div.helpText(ng-message=\"required\") Eingabe prüfen--\u003E\u003Cdiv class=\"per_item_settings\" ng-if=\"!!$ctrl.direct[field.prop].per_item_price_settings.is_visible\"\u003E\u003Cspan class=\"field_x\"\u003Ex\u003C\u002Fspan\u003E";
pug_mixins["input"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv ng-messages=\"$ctrl.forms[$ctrl.formName]['per_item_price-' + $ctrl.formName + '-'+$index].$error\"\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeMin\"\u003EEingabe prüfen\u003C\u002Fdiv\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeMaxEq\"\u003EDer Betrag darf {{ $ctrl.budget[field.prop].per_item_price_settings.range.max_eq | currency }} nicht überschreiten.\u003C\u002Fdiv\u003E\u003Cdiv class=\"helpText\" ng-message=\"required\"\u003EEingabe prüfen\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"id": "per_item_price-{{$ctrl.formName}}-{{$index}}","groupHtmlClass": "field_per_item_price","key": "$ctrl.direct[field.prop].per_item_price","attributes": {"ng-if":"!!$ctrl.direct[field.prop].per_item_price_settings.is_editable","ng-change":"calculateFieldResult($ctrl.direct[field.prop])","ng-model-options":"{ debounce: 1000 }","vn-currency":"true","vn-range":"$ctrl.direct[field.prop].per_item_price_settings.range","ng-disabled":"!$ctrl.direct[field.prop].active","ng-required":"$ctrl.direct[field.prop].active ? true : false"}}
});
pug_html = pug_html + "\u003Cspan ng-if=\"!ctrl.direct[field.prop].per_item_price_settings.is_editable\"\u003E{{ $ctrl.direct[field.prop].per_item_price | currency }}\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"field_result\"\u003E\u003Cdiv class=\"not_included\" ng-if=\"!$ctrl.direct[field.prop].active\"\u003E{{ 0 | formatNumber:'':'':2 | currency }}\u003C\u002Fdiv\u003E\u003Cdiv class=\"result\" ng-if=\"$ctrl.direct[field.prop].active &amp;&amp; keys($ctrl.forms[$ctrl.formName]['field_value-' + $ctrl.formName + '-'+$index].$error || []).length === 0\"\u003E{{$ctrl.direct[field.prop].result | formatNumber:'':'':2 | currency }}\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"name": "$ctrl.forms[$ctrl.formName]"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column\"\u003E\u003Chr\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"column small-6\"\u003E\u003Cdiv\u003EGesamt\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"column small-6 right right-text\"\u003E";
pug_mixins["form"].call({
block: function(){
pug_mixins["input"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv ng-messages=\"$ctrl.forms.totalForm['total-'+$ctrl.formName].$error\"\u003E\u003Cdiv class=\"helpText\" ng-message=\"rangeMin\"\u003ESumme muss größer 0 sein\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
},
attributes: {"id": "total","key": "$ctrl.direct.total","fieldHtmlClass": "right","attributes": {"vn-currency":"true","vn-range":"{min:0}","ng-required":"true","ng-disabled":"true"}}
});
},
attributes: {"name": "$ctrl.forms.totalForm","ng-hide": "true"}
});
pug_html = pug_html + "\u003Cspan\u003E{{ $ctrl.direct.total | currency }}\u003C\u002Fspan\u003E\u003Cdiv ng-if=\"$ctrl.direct.total &lt;= 0\"\u003E\u003Cdiv class=\"helpText\"\u003ESumme muss größer 0 sein\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;