angular
  .module('VSPApp')
  .directive('vnCurrency', ['$locale', '$filter', 'lodash', function ($locale, $filter, lodash) {


    const groupSepRegex = new RegExp('\\' + $locale.NUMBER_FORMATS.GROUP_SEP, 'g'),
      decimalSepRegex = new RegExp('\\' + $locale.NUMBER_FORMATS.DECIMAL_SEP),
      whitespaceRegex = /\s/g;

    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, ele, attr, ngModel) {
        const vnCurrencyControls = {
          is_enabled: true,
          to_format: true, //attr.vnCurrencyToFormat !== 'false',
          to_parse: true, // attr.vnCurrencyToParse !== 'false'
          is_number_or_empty_validator_enabled: true
        };

        try {
          let settings_string = lodash.cloneDeep(attr.vnCurrency);
          let settings = JSON.parse(settings_string);
          Object.assign(vnCurrencyControls, settings);
        } catch (e) {
          let settings_string = lodash.cloneDeep(attr.vnCurrency);
          settings_string = settings_string.replace(/"/g, '""');
          settings_string = settings_string.replace(/'/g, '"');
          try {
            let settings = JSON.parse(settings_string);
            Object.assign(vnCurrencyControls, settings);
          } catch (e) {

          }
        }

        const formatter = (value) => {
          if (!(vnCurrencyControls.is_enabled && vnCurrencyControls.to_format)) {
            return value;
          }
          return isNaN(value) ? '' : $filter('currency')(value);
        };

        const parser = (viewValue) => {
          if (!(vnCurrencyControls.is_enabled && vnCurrencyControls.to_parse)) {
            return viewValue;
          }

          let modelValue = String(viewValue)
            .replace($locale.NUMBER_FORMATS.CURRENCY_SYM, '')
            .replace(groupSepRegex, '')
            .replace(decimalSepRegex, '.')
            .replace(whitespaceRegex, '');

          if (0 === modelValue.length) {
            modelValue = 0;
          } else {
            modelValue = parseFloat(parseFloat(modelValue).toFixed(2));
          }

          return modelValue;
        };

        ngModel.$parsers.unshift(parser);
        ngModel.$formatters.push(formatter);

        ngModel.$validators.numberOrEmpty = (modelValue) => {
          if (!vnCurrencyControls.is_enabled || !vnCurrencyControls.is_number_or_empty_validator_enabled) {
            return true;
          }

          return !isNaN(modelValue);
        };


        if (undefined !== attr.vnMin) {

          var vnMinVal;
          ngModel.$validators.vnMin = (value) => {
            if (!vnCurrencyControls.is_enabled) {
              return true;
            }
            return value >= vnMinVal;
          };

          attr.$observe('vnMin', function (val) {
            if (val == 'false') {
              return;
            }

            if ((undefined !== val) && !isNaN(val)) {
              val = parseFloat(val, 10);
            }
            vnMinVal = !isNaN(val) ? val : undefined;
            ngModel.$validate();
          });
        }

        if (undefined !== attr.vnMax) {
          var vnMaxVal;

          ngModel.$validators.vnMax = (value) => {
            if (!vnCurrencyControls.is_enabled) {
              return true;
            }
            return ngModel.$isEmpty(value) || (undefined === vnMaxVal) || value <= vnMaxVal;
          };

          attr.$observe('vnMax', function (val) {
            if ((undefined !== val) && !isNaN(val)) {
              val = parseFloat(val, 10);
            }
            vnMaxVal = !isNaN(val) ? val : undefined;
            ngModel.$validate();
          });
        }

      }
    };
  }]);
