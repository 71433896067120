const $inject = [
  '$scope',
  'Made',
  'NotificationService',
  'employeeService',
  'vbmData',
  '$timeout'
];
export default class employeeBirthdateController {

  constructor(
    $scope,
    Made,
    NotificationService,
    employeeService,
    vbmData,
    $timeout
  ) {

    Object.assign(this, {
      $scope,
      NotificationService,
      employeeService,
      vbmData,
      Made,
      $timeout
    });

    this.saving = true;
    this.employeeId = ($scope.ngDialogData && $scope.ngDialogData.employeeId) || Made.user.valuenet_id;

    employeeService
      .get(this.employeeId)
      .then(employee => this.employee = employee);

    this.getIntegrityData();
  }

  getIntegrityData() {
    this.employeeService
      .getIntegrityData(this.employeeId)
      .then(integrityData => {
        this.integrityData = integrityData;
        delete (this.integrityData.address);
        this.saving = false;
      });
  }

  async save() {
    this.$timeout(() => {
      this.saving = true;
    })

    // update birthDate on BE
    await this.employeeService.updateBirthdateData(this.integrityData, this.employeeId)

    // create new calculation
    this.vbmData.employees[this.employeeId].data.parameters.geburtsdatum = this.integrityData.data.geburtsdatum;
    await this.vbmData.save(this.employeeId);

    this.NotificationService.message('Das Geburtsdatum wurde gespeichert.');

    this.$timeout(() => {
      this.saving = false;
    })

    this.$scope.confirm();
  }

}
employeeBirthdateController.$inject = $inject;
