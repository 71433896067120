const $inject = [
  "Made",
  "$scope",
  "$state",
  "$q",
  "$window",
  "employeeService",
  "fileService",
  "$timeout",
  "cmsService",
  "USERLANE_DISABLED"
];

export default class CmsDetailsController {
    constructor(Made,
                $scope,
                $state,
                $q,
                $window,
                employeeService,
                fileService,
                $timeout,
                cmsService,
                USERLANE_DISABLED
    ) {

        Object.assign(this, {
            employeeService,
            cmsService,
            $scope,
            Made,
            $q,
            $window,
            fileService,
            $timeout,
            $state,
            USERLANE_DISABLED
        });
        
        this.styles = {};

        this.custId = $state.params.custId;

        this.initCmsContent($state.params.cmsId).then(()=>{
          this.$timeout(()=>{});
        });
    }
    
    openDocument(document){
      this.fileService.openDocument(document._id);
    }

    async initCmsContent(cmsId) {
        if (!cmsId) {
            this.$state.go('inApp.cms.list');
        }

        this.cmsContent = await this.cmsService.loadById(cmsId);

        if(this.cmsContent.edited && this.cmsContent.edited.length) {
            this.lastEdit = this.cmsContent.edited[ this.cmsContent.edited.length - 1];
        } else {
            this.lastEdit = this.cmsContent.created;
        }
        this.lastEditor = await this.employeeService.getByMongoId(this.lastEdit.user);
        
        if (this.cmsContent.stage && this.cmsContent.stage._id){
          this.styles[this.cmsContent.stage._id] = { 'background-image' : 'url(' + await this.fileService.getDocumentUrl(this.cmsContent.stage._id) + ')' };
        }

        this.cmsService.globalCms.detailId = cmsId;
        this.cmsService.globalCms.loading = false;
        this.$scope.$applyAsync();
    }
    
    startUserlane(userlaneId) {
      if (!this.USERLANE_DISABLED) {
        Userlane('start', userlaneId);
      }
    }

    backLink() {
        this.$window.history.back();
    }
}

CmsDetailsController.$inject = $inject;