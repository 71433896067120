const {BigDecimal} = require('bigdecimal');

export default class DatevCalculatorOutput {
  constructor(props = {}) {
    /**  */
    this.BK      =  new BigDecimal(0);

    /**  */
    this.BKS     =  new BigDecimal(0);

    /**  */
    this.BKV     =  new BigDecimal(0);

    /**  */
    this.LSTLZZ  =  new BigDecimal(0);

    /**  */
    this.SOLZLZZ =  new BigDecimal(0);

    /**  */
    this.SOLZS   =  new BigDecimal(0);

    /**  */
    this.SOLZV   =  new BigDecimal(0);

    /**  */
    this.STS     =  new BigDecimal(0);

    /**  */
    this.STV     =  new BigDecimal(0);

    /**  */
    this.VKVLZZ  =  new BigDecimal(0);

    /**  */
    this.VKVSONST=  new BigDecimal(0);

    /**  */
    this.VFRB    =  new BigDecimal(0);

    /**  */
    this.VFRBS1  =  new BigDecimal(0);

    /**  */
    this.VFRBS2  =  new BigDecimal(0);

    /**  */
    this.WVFRB   =  new BigDecimal(0);

    /**  */
    this.WVFRBO  =  new BigDecimal(0);

    /**  */
    this.WVFRBM  =  new BigDecimal(0);

    Object.keys(props).map(key => {
      if (this[key] instanceof BigDecimal) {
        this[key] = new BigDecimal(props[key]);
      } else {
        this[key] = props[key];
      }
    });
  }

}
