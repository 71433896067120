const $inject = [
  '$state', 'authenticationService'
];
export default class AdministrationController {
  constructor($state, authenticationService) {
    this.state = $state;
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmDocumentAdministrator = authenticationService.iAm('document_administrator');
    this.iAmDatevAdmin = authenticationService.iAm('datev_admin');
    this.iAmSpecialAdmin = authenticationService.iAm('special_admin');
  }
}
AdministrationController.$inject = $inject;
