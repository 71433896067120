<h2 mat-dialog-title>
    Bestellung von Bulk-Aktionen ausschließen
</h2>

<mat-dialog-content>
    <form [formGroup]='dialogForm'>
        <p>
            Sie schalten die Warteschleife für diese Bestellung an.
            Bitte hinterlassen Sie eine Begründung dafür:
        </p>

        <div class='row filters'>
            <vn-input formControlName='comment'
                      key="vn.directBonus.backofficeAdministration.putOnHoldDialog.inputComment"
                      label="Kommentar"
                      placeholder="-">
                <mat-error *ngIf="comment.hasError('required')">
                    Eingabe fehlt.
                </mat-error>
            </vn-input>
        </div>

        <p>
            Fügen Sie bitte in das untere Eingabefeld auch die URL auf das auftragsbezogene OTRS-Ticket ein:
        </p>

        <div class='row filters'>
            <vn-input formControlName='ticketUrl'
                      key="vn.directBonus.backofficeAdministration.putOnHoldDialog.inputOTRSTicket"
                      label="OTRS-Ticket"
                      placeholder="Ticket ID">
                <mat-error *ngIf="ticketUrl.hasError('required')">
                    Eingabe fehlt.
                </mat-error>
            </vn-input>
        </div>

        <p>
            Nach dem Klick auf “Bestätigen” wird die Bestellung in Wartenschleife für solange aus einer weiteren
            Sammelbearbeitung ausgeschlossen, bis die Warteschleife für die Bestellung wieder ausgeschaltet wird.
        </p>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='create-button'
            data-cy='vn.directBonus.backofficeAdministration.putOnHoldDialog.buttonSubmit'
            mat-stroked-button
            [disabled]="!dialogForm.valid"
            (click)="putOrderOnHold()">
        Bestätigen
    </button>
</mat-dialog-actions>
