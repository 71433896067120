import {CarLeasingBasket} from "../../../../app/car-leasing/models/car-leasing-basket.model";
import {CarLeasingApiService} from "../../../../app/car-leasing/services/car-leasing-api.service";

const $inject = [
  '$scope',
  '$rootScope',
  '$timeout',
  'CarLeasingApiService',
  'fileService',
  '$http'
]

export default class CarLeasingContractProcessDialogController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    CarLeasingApiService,
    fileService,
    $http
  ) {

    /** @var {CarLeasingApiService} */
    this.carLeasingApi = CarLeasingApiService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.fileService = fileService;
    this.$http = $http;

    this.loading = {
      main: false,
      saving: false,
    };

    this.controlProcessActions = [
      {
        'name': -1,
        'value': 'Select an option',
        'disabled': false
      },
      {
        'name': 'approval',
        'value': 'Approve',
        'disabled': false,
      },
      {
        'name': 'new_contract_request',
        'value': 'New Contract Request',
        'disabled': false
      }
    ]

    this.controlProcessActionSelected = this.controlProcessActions[0]['name']

    this.forms = {}
    this.contractIsValid = true;

    let contractPreviewErrorHandler = (event, data) => {
      this.contractIsValid = false;
      this.controlProcessActions[1].disabled = true
    }
    let unbindPreviewErrorEventHandler = $rootScope.$on('contract-preview-error', contractPreviewErrorHandler);
    $scope.$on('$destroy', function () {
      unbindPreviewErrorEventHandler();
    });

    this.init().finally(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    /** @var {CarLeasingBasket} */
    this.basket = this.$scope.ngDialogData.basket;
    this.contractUrl = await this.fileService.getDocumentUrl(this.basket.contractProcessData.fileId)
    
    this.$timeout(() => {
      this.loading.main = false;
    });
  
  }

  userHasSelectedAction() {
    return this.controlProcessActionSelected !== -1
  }

  close() {
    this.$scope.closeThisDialog();
  }

  isApproved() {
    return this.controlProcessActionSelected === 'approval'
  }

  isRequestedNewContract() {
    return (
      this.userHasSelectedAction() && 
      this.forms.newContractForm && 
      this.forms.newContractForm.$valid
    );
  }

  canMakeAnAction() {
    return this.isRequestedNewContract() || (this.isApproved() && this.contractIsValid);
  }

  async makeAnAction() {
    let result = null
    
    if (this.isRequestedNewContract()) {
      result = await this.carLeasingApi.requestNewContract(
        this.basket.id,
        this.newContractComment
      )
    } else if(this.isApproved()) {
      result = await this.carLeasingApi.approveContractProcess(this.basket.id)
    } else {
      throw 'Invalid action';
    }

    if (result) {
      this.$scope.confirm();
    }

  }

}

CarLeasingContractProcessDialogController.$inject = $inject
