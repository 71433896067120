import './meineakte.controller';

import './steuerfrei/meineakte_steuerfrei.controller';
import './uebersicht/meineakte_uebersicht.controller';
import './versicherungen/meineakte_versicherungen.controller';
import './mpp/MeineakteMppController';
import './appverwaltung/AppverwaltungController';

import MeineakteController from './meineakte.controller';
import DigitalSigningErrorController from './digital_signing/DigitalSigningErrorController';
import DigitalSigningSuccessController from './digital_signing/DigitalSigningSuccessController';
import MeineAkteDokumenteController from './dokumente/MeineAkteDokumenteController';
import MeineakteAccountController from './account/MeineakteAccountController';
import MeineakteVorteilskontoController from './vorteilskonto/meineakte_vorteilskonto.controller';
import MeineAkteMissingDocumentsController from './missing_documents/missing_documents.controller';
import CalculationsComponentController from './calculations-component/calculationsComponentController';
import MeineAkteUserDecisionsController from './user_decisions/MeineAkteUserDecisions.controller'
import MPPBuyingDecisionDialogController from './user_decisions/MPPBuyingDecisionDialog.controller';
import MeineakteMppController from "./mpp/MeineakteMppController";
import AppverwaltungController from "./appverwaltung/AppverwaltungController";
import MobileDeviceNameDialogController from "./appverwaltung/MobileDeviceNameDialogController";

import meineakte_dokumente_html from "./dokumente/meineakte_dokumente.pug";
import calculations_component_html from  './calculations-component/calculations_component.pug'

angular
  .module( 'VSPApp' )
  .controller( 'MeineakteController', MeineakteController)
  .controller( 'MeineakteVorteilskontoController', MeineakteVorteilskontoController)
  .controller( 'MeineAkteDokumenteController', MeineAkteDokumenteController )
  .controller( 'MeineakteAccountController', MeineakteAccountController )
  .controller( 'MeineAkteMissingDocumentsController', MeineAkteMissingDocumentsController)
  .controller( 'CalculationsComponentController', CalculationsComponentController)
  .controller( 'MeineAkteUserDecisionsController', MeineAkteUserDecisionsController)
  .controller( 'DigitalSigningSuccessController', DigitalSigningSuccessController)
  .controller( 'DigitalSigningErrorController', DigitalSigningErrorController)
  .controller( 'MeineakteMppController', MeineakteMppController )
  .controller( 'MPPBuyingDecisionDialogController', MPPBuyingDecisionDialogController)
  .controller('AppverwaltungController', AppverwaltungController)
  .controller('MobileDeviceNameDialogController', MobileDeviceNameDialogController)
  .component( 'documentListComponent', {
    restrict: 'E',
    scope: {},
    template: meineakte_dokumente_html(),
    bindings: {
      options: '<',
      ammendmentDocuments: '=documents'
    },
    controller:   MeineAkteDokumenteController,
    controllerAs: 'dokumente'
  })
  .component( 'employeeCalculationsComponent', {
    template: calculations_component_html(),
    bindings: {
      options: '<'
    },
    controller:   'CalculationsComponentController',
    controllerAs: '$ctrl'
  });

