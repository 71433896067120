angular
  .module('VSPApp')
  .directive('vnAutoSwitchFields', [function () {
    return {
      restrict: 'A',
      link: function (scope, elm, attrs) {
        const maxLength = attrs.ngMaxlength;
        const domElement = elm[0];

        function focusNext() {
          if (attrs.tabindex != undefined) {
            const nextTabIndex = parseInt(attrs.tabindex) + 1;
            const nextField = document.querySelector('[tabindex="' + nextTabIndex + '"]');
            if (nextField) {
              nextField.focus();
            }
          }
        }

        scope.$watch(() => elm.val(), v => {
          const hasFocus = domElement == document.activeElement;
          const cursorAtEnd = domElement.selectionStart == maxLength;
          if (v.length == maxLength && hasFocus && cursorAtEnd) {
            focusNext();
          }
        });
      }
    };
  }]);
