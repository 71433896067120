const LandingConstants = {
  'arbeitgeber': [
    {
      title: 'Innovation',
      short_desc: 'Alle Mitarbeiterzusatzleistungen auf einem Portal',
      items: [
        'Erstes Outsourcing Portal für alle Arbeitnehmerzusatzleistungen',
        'Eine Schnittstelle zwischen Arbeitgebern, Arbeitnehmern, Lieferanten und den jeweiligen Verwaltungssystemen',
        'via App mobil auf Smartphone oder Tablet. Im Büro oder zuhause für die ganze Familie via PC oder Mac!',
        'Ein Ansprechpartner für alle Zusatzleistungen',
        'Eine digitale Akte für alle Arbeitnehmerleistungen'
      ]
    },
    {
      title: 'Verwaltung',
      short_desc: 'über ValueNet heißt:',
      items: [
        'Entlastung der Personalabteilung und Entgeltabrechnung',
        'Einfaches Handling und automatisierte Prozesse für alle Abteilungen im Unternehmen',
        'Unbegrenzte Anzahl an Lieferanten möglich',
        'Prozessvereinfachung und Aufwandsreduzierung bei bestehenden Zusatzleistungen',
        'Revisionssichere Datenverwaltung und einheitlicher Datenschutz für alle Lieferanten',
        'Lückenlose transparente Dokumentation',
        'Ein Rechnungssteller für alle Zusatzleistungen',
        'Lieferantenmanagement- und Abrechnung',
        'Digitalisierter Datenaustausch mit allen Lieferanten für Zusatzleistungen'
      ]
    },
    {
      title: 'Employer Branding',
      short_desc: 'Hohe Mitarbeiter-Identifikation',
      items: [
        'Alle Mitarbeiterportale werden im Corporate Design des Unternehmens erstellt und sind überall verfügbar',
        'Jeder Arbeitgeber bekommt eine eigene auf ihn individualisierte Mitarbeiter-App',
        'Das ValueNet Portal liefert Informationen über Ihre Arbeitgeberzusatzleistungen auf Facebook, Xing und vielen anderen sozialen Netzwerken in Bezug auf Ihr Unternehmen. Dadurch wird die Wirkung auf Ihre Bewerber und Ihre Mitarbeiter in den sozialen Netzwerken erhöht.',
        'Bewerbern kann das Portal zeitlich befristet geöffnet werden',
        'u. v. m.'
      ]
    },
    {
      title: 'Kommunikation',
      short_desc: 'ValueNet macht Zusatzleistungen transparent und verständlich via:',
      items: [
        'Mitarbeiter-App',
        'Mitarbeiter-Portal',
        'Filme',
        'Online-Beratung',
        'Webinare',
        'Persönliche Gespräche',
        'u. v. m.'
      ]
    },
    {
      title: 'Compliance ',
      short_desc: 'und Rechtssicherheit',
      items: [
        'Rechtliche Prüfung aller Angebote nach gesetzlichen Richtlinien',
        'Steuerliche Prüfung aller Zusatzleistungen',
        'Haftungsfreistellung des Arbeitgebers durch Bestätigung des Finanzamtes',
        'Ausschreibung und Auswahl der Lieferanten',
        'Best-Price-Konditionen aller gewählten Lieferanten durch Einkaufsbündelung'
      ],
      post: [
        '* ValueNet erbringt keine Steuer, - oder Rechtsberatung sondern beauftragt für diese externe zugelassene Steuerberater und Rechtsanwälte'
      ]
    }
  ],
  'arbeitnehmer': [
    {
      title: 'Höheres Realeinkommen',
      short_desc: 'mehr Geld zum Leben durch:',
      items: [
        'Effektive Nutzung der gesetzlichen Gestaltungsmöglichkeiten in Lohn- und Gehalt',
        'Steuerfreier Einkauf über den Arbeitgeber',
        'Zusatzleistungen, die effektiv das Haushaltseinkommen erhöhen',
        'Preisvorteile bei Einkauf, Reisen u. v. m.',
        'Bis zu 7.000 € p. a. Vorteile und Einsparungen je Mitarbeiter möglich'
      ]
    },
    {
      title: 'Cash Back Services',
      short_desc: 'Kostenlose rechtliche Hilfestellung* bei:',
      items: [
        'Ungerechtfertigten Kreditgebühren (Auto, Konsum usw.)',
        'Baufinanzierungen',
        'Lebens,- und Rentenversicherungen',
        'Private Krankenversicherungen',
        'Fluggastrechte',
        'Falschberatung',
        'Steuererklärungen',
        'u. v. m.'
      ],
      post: [
        '* ValueNet erbringt keine Steuer,- oder Rechtsberatung sondern beauftragt zugelassene Steuerberater und Rechtsanwälte mit o. g. Dienstleistungen.'
      ]
    },
    {
      title: 'Steuerfrei Einkaufen via ValueNet Portal',
      short_desc: 'Mitarbeiter können bei folgenden Anbietern Steuer,- und Sozialversicherungsfrei einkaufen und sparen dadurch bis zu 50 % im Vergleich zum Privateinkauf:',
      items: [
        'Apple',
        'Samsung',
        'Media Markt',
        'Ikea',
        'Amazon',
        'H & M',
        'Otto',
        'Kaufhof',
        'alle bekannten Fahrradmarken',
        'alle bekannten Automobilhersteller',
        'alle bekannten Reiseveranstalter',
        'Insgesamt weit mehr als 300 Anbieter verfügbar!'
      ]
    },
    {
      title: 'Vorsorge und Absicherung',
      short_desc: 'unschlagbare Konditionen und Leistungen',
      items: [
        'Absolut transparente und günstige Versicherungs,- und Vorsorgeprodukte durch provisionsfreie Nettotarife mit bis zu 50 % Beitragsersparnis für folgende Bereiche:',
        'Betriebliche Altersvorsorge',
        'Betriebliche Absicherung der Arbeitskraft',
        'Betriebliche Krankenversicherung',
        'Haftpflicht',
        'Hausrat',
        'Rechtschutz',
        'u. v. m.'
      ]
    },
    {
      title: 'Transparente Darstellung und neutrale, unabhängige Schulung',
      short_desc: 'für mehr Vertrauen',
      items: [
        'Das Portal ist selbsterklärend und mit vielen Filmen ausgestattet, die das Thema erläutern.',
        'Die Beantragung einer Leistung oder der Einkauf kann direkt online durchgeführt werden',
        'Transparente Darstellung der Module.',
        'Der Mitarbeiter erhält telefonische Unterstützung.',
        'Nach Absprache kann auch eine vor-Ort-Schulung durchgeführt werden.'
      ]
    }
  ],
  'price_info': [
    {
      title: '',
      short_desc: '',
      items: [
        'Wenn Sie als Arbeitgeber die nächste Gehaltserhöhung oder Bonuszahlung über das Portal umsetzen lassen, berechnen wir Ihnen für jeden Mitarbeiter nur 2 € pro Monat.',
        'ValueNet wird aus den durch das Portal generierten Einsparungen bezahlt.',
        'Wenn Sie mehr als 30 Mitarbeiter beschäftigen, erhalten Sie Ihre eigene gebrandete Unternehmens-App zum Aufpreis von nur 2 € pro Mitarbeiter.'
      ],
    },
    {
      title: '',
      short_desc: '',
      items: [
        'Sollten wir Ihre Mitarbeiter im Umgang mit dem Portal schulen dürfen und es stehen keine Gehaltserhöhungen oder Bonuszahlungen an, berechnen wir Ihnen für jeden Mitarbeiter nur 4 € pro Monat.',
        'ValueNet wird aus den durch das Portal generierten Einsparungen bezahlt.',
        'Wenn Sie mehr als 30 Mitarbeiter beschäftigen, erhalten Sie Ihre eigene gebrandete Unternehmens-App zum Aufpreis von nur 2 € pro Mitarbeiter.',
      ],
    },
    {
      title: '',
      short_desc: '',
      items: [
        'Sollten wir Ihre Mitarbeiter nicht im Umgang mit dem Portal schulen dürfen, zahlen Sie für jeden Mitarbeiter nur 6 € pro Monat.',
        'ValueNet wird aus den durch das Portal generierten Einsparungen bezahlt.',
        'Wenn Sie mehr als 30 Mitarbeiter beschäftigen, erhalten Sie Ihre eigene gebrandete Unternehmens-App zum Aufpreis von nur 2 € pro Mitarbeiter.',
      ]
    }
  ]
};

export default LandingConstants;
