import benefit_html from '../../modules/benefit/benefit.pug';
import header_main_html from '../../helpers/header.main.pug';
import benefit_home_html from '../../modules/benefit/home/benefit.home.pug';
import benefit_bonago_html from '../../modules/benefit/bonago/benefit_bonago.pug';
import side_html from '../../modules/side/side.pug';
import benefit_bonago_home_html from '../../modules/benefit/bonago/home/benefit_bonago_home.pug';
import benefit_bonago_kategorie_html from '../../modules/benefit/bonago/kategorie/benefit_bonago_kategorie.pug';
import benefit_bonago_retailers_overview_map_html from '../../modules/benefit/bonago/maps/overview/benefit_bonago_retailers_overview_map.pug';
import benefit_bonago_details_html from '../../modules/benefit/bonago/details/benefit_bonago_details.pug';
import benefit_bonago_checkout_html from '../../modules/benefit/bonago/checkout/benefit_bonago_checkout.pug';
import benefit_amazon_html from '../../modules/benefit/amazon/benefit_amazon.pug';


const SIDE = {
  template: require('../../modules/benefit/bonago/side/bonago_side.pug')(),
  controller: 'BonagoSideController',
  controllerAs: '$bonagoSide',
};

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.benefit', {
        url: '/benefit',
        views: {
          main: {
            template: benefit_html,
            controller: 'BenefitController'
          },
          header: {
            template: header_main_html
          }
        }
      })
      .state('inApp.benefit.home', {
        url: '/home',
        views: {
          benefitMain: {
            template: benefit_home_html,
            controller: 'benefitHomeController',
            controllerAs: '$benefitHomeController'
          }
        }
      })
      .state('inApp.benefit.bonago', {
        url: '/bonago',
        onEnter: ['BenefitBonagoService',(BenefitBonagoService) => {
          return BenefitBonagoService.onShopEnter();
        }],
        views: {
          benefitMain: {
            template: benefit_bonago_html,
            controller: 'BenefitBonagoController'
          }
        }
      })
      .state('inApp.benefit.bonago.side', {
        views: {
          bonagoMain: {
            template: side_html,
          }
        }
      })
      .state('inApp.benefit.bonago.side.home', {
        url: '/home',
        views: {
          sideMain: {
            template: benefit_bonago_home_html,
            controller: 'BenefitBonagoHomeController',
            controllerAs: '$bonagoHomeCtrl'
          },
          side: SIDE
        }
      })
      .state('inApp.benefit.bonago.side.kategorie', {
        url: '/kategorie/:kategorieId',
        params: {
          kategorieId: null
        },
        views: {
          sideMain: {
            template: benefit_bonago_kategorie_html,
            controller: 'BenefitBonagoKategorieController',
            controllerAs: '$bonagoKategorieCtrl'
          },
          side: SIDE
        }
      })
      .state('inApp.benefit.bonago.retailersOverviewMap', {
        url: '/retailers-map',
        params: {
          lat: null,
          lng: null
        },
        views: {
          bonagoMain: {
            template: benefit_bonago_retailers_overview_map_html,
            controller: 'BenefitBonagoOverviewMapController',
            controllerAs: '$bonagoOverviewMapCtrl'
          }
        }
      })
      .state('inApp.benefit.bonago.side.details', {
        url: '/details/:retailerId',
        params: {
          retailerId: null
        },
        views: {
          sideMain: {
            template: benefit_bonago_details_html,
            controller: 'BenefitBonagoDetailsController',
            controllerAs: '$bonagoDetailsCtrl'
          },
          side: SIDE
        }
      })
      .state('inApp.benefit.bonago.side.checkout', {
        url: '/checkout',
        params: {
          retailerId: null
        },
        views: {
          sideMain: {
            template: benefit_bonago_checkout_html,
            controller: 'BenefitBonagoCheckoutController',
            controllerAs: '$bonagoCheckoutCtrl'
          }
        }
      })
      .state('inApp.benefit.amazon', {
        url: '/amazon',
        views: {
          benefitMain: {
            template: benefit_amazon_html,
            controller: 'BenefitAmazonController'
          }
        }
      });

  }]);
