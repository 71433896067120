import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DirectProjectOverviewBonusTableDataRow,
  DirectBonusComponentType,
  DirectBonusComponent,
  DirectBonusProjectMonthOverview,
  DirectBonusProjectOverviewTableEmployee
} from '../../../models/direct-bonus-project-overview-table-data.model';
import { BonusTableDialogData } from '../../../models/direct-bonus-table-dialog-data.model';
import { DirectBonusService } from '../../../services/direct-bonus.service';
import { DirectBonusCreateDialogComponent } from '../direct-bonus-create-dialog/direct-bonus-create-dialog.component';
import { switchMap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { DirectBonusFormData } from '../../../models/direct-bonus-data.model';
import { DIRECT_BONUS_TABLE_MONTHS } from '../../../direct-bonus.module';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatSelectChange } from '@angular/material/select';
import { ACTIVE_EMPLOYEE_STATUS, INACTIVE_EMPLOYEE_STATUS, TEMPORARILY_INACTIVE_STATUS } from '../customer-administration-new/customer-administration-new.component';

@Component({
  selector: 'vn-customer-project-overview-table',
  templateUrl: './customer-project-overview-table.component.html',
  styleUrls: ['./customer-project-overview-table.component.scss']
})
export class CustomerProjectOverviewTableComponent {

  public months = DIRECT_BONUS_TABLE_MONTHS;

  public displayedColumns: string[] = [
    'id',
    'name',
    'personalNumber',
    ...this.months.map(month => month.displayColumnName),
    'sumForUser'
  ];

  public secondHeaderColumns: string[] = [
    'secondHeaderName',
    'secondHeaderFiller1',
    'secondHeaderFiller2',
    ...this.months.map(month => month.id.toString()),
  ];

  @Input()
  bonusProjectId!: string;

  @Input()
  customerId!: number;

  @Input()
  year!: number;

  @Input()
  componentSelection: string | null = null;

  @Input()
  tableData: DirectProjectOverviewBonusTableDataRow[] = [];

  @Output()
  tableDataChange = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private directBonusService: DirectBonusService,
    private alertService: AlertService
  ) {}

  ngOnChanges() {
    this.bringWithBonusesOrdersUp();
  }

  private bringWithBonusesOrdersUp() {
    const arrangedOrders: DirectProjectOverviewBonusTableDataRow[] = [];

    if(this.tableData) {
      this.tableData.forEach(order => {
        const allBonuses = order.bonusesPerMonth;
        let hasGivenBonus = false;

        for(const month in allBonuses) {
          const bonuses = allBonuses[month];
          bonuses.forEach(bonus => {
            if(bonus.components.length) {
              hasGivenBonus = true;
            }
          });
        }

        if(hasGivenBonus) {
          arrangedOrders.unshift(order);
        } else {
          arrangedOrders.push(order);
        }
      });
    }

    this.tableData = arrangedOrders;
  }

  public getSumPerMonthPerEmployee(monthOverview: DirectBonusProjectMonthOverview) {
    const components = monthOverview['components'];
    let amountSum = 0;

    if(!components) {
      return 0
    }

    components.forEach((item: DirectBonusComponent) => amountSum += item.value);

    return amountSum;
  }

  public getSumForMonthForAllEmployees(monthId: number) {
    let totalForMonth = 0;

    this.tableData.forEach((dataRow: DirectProjectOverviewBonusTableDataRow) => {
      let monthSum = 0;
      dataRow.bonusesPerMonth[monthId].forEach(bonus =>
        bonus.components.forEach(component => monthSum += component.value)
      );
      totalForMonth += monthSum;
    });

    return totalForMonth;
  }

  public getTotalForEmployeeForAllMonths(dataRow: DirectProjectOverviewBonusTableDataRow) {
    let totalForEmployee = 0;

    this.months.forEach((month: any) => {
      let monthSum = 0;
      dataRow.bonusesPerMonth[month.id].forEach(bonus =>
        bonus.components.forEach(component => monthSum += component.value)
      );
      totalForEmployee += monthSum;
    });

    return totalForEmployee;
  }

  // ===================== DIALOG =====================

  public getOrderClass(bonus: DirectBonusProjectMonthOverview) {
    const allowedOperations = bonus.bonusAllowedOperation;
    const isActionEnabled = allowedOperations.canCustomerCreateUpdateBonus;
    const hasComponents = bonus.components.length > 0;
    let classNames = '';

    if(isActionEnabled) {
      classNames += ' action-enabled';
    }

    if(bonus.isMainOrder) {
      classNames += ' main-order';
    }

    if(!bonus.isTopUpped && hasComponents) {
      classNames += ' not-top-upped';
    }

    return classNames;
  }

  public hasBonusForMonth(bonus: DirectBonusProjectMonthOverview) {
    return bonus.components.length > 0;
  }

  public getMonthClass(
    tableData: DirectProjectOverviewBonusTableDataRow,
    monthId: number
  ) {
    const hasOnlyOneOrder = tableData.bonusesPerMonth[monthId].length === 1;
    if (!hasOnlyOneOrder) {
      return '';
    }

    const allowToEdit = tableData.bonusesPerMonth[monthId][0].bonusAllowedOperation.canCustomerCreateUpdateBonus;
    if (!allowToEdit) {
      return '';
    }

    return 'clickable';
  }

  applyEmployeeStatusStyle(status: string) {

    const empStatusFactory = {
      [ACTIVE_EMPLOYEE_STATUS.id]: {'border-left': '5px solid green'},
      [INACTIVE_EMPLOYEE_STATUS.id]: {'border-left': '5px solid red'},
      [TEMPORARILY_INACTIVE_STATUS.id]: {'border-left': '5px solid yellow'},
    }

    return empStatusFactory[status] || null;
  }

  public openCreateBonusDialog(
    tableData: DirectProjectOverviewBonusTableDataRow,
    monthId: number
  ) {

    const monthBonuses = tableData.bonusesPerMonth[monthId];
    const employee = tableData.employee;

    const ordersNumberForMonth = monthBonuses.length;
    if(ordersNumberForMonth > 1) {
      this.alertService.error("Änderungen nicht mehr möglich.");
      return;
    }

    // could not edit top-upped bonuses
    const bonus = monthBonuses[0];
    if(bonus.isTopUpped) {
      this.alertService.error("Änderungen nicht mehr möglich.");
      return;
    }

    const isAllowToEdit = bonus.bonusAllowedOperation.canCustomerCreateUpdateBonus;
    if (!isAllowToEdit) {
      this.alertService.error("Änderungen nicht mehr möglich.");
      return;
    }

    const dialogWidth = '420px';
    const dialogData: BonusTableDialogData = {
      bonusProjectId: this.bonusProjectId,
      customerId: this.customerId,
      employeeId: employee.id,
      employeeName: employee.name,
      monthBonuses: bonus,
      componentSelection: this.componentSelection as DirectBonusComponentType | null,
      year: this.year,
      month: monthId,
    };

    this.dialog.open(
      DirectBonusCreateDialogComponent,
      {
        width: dialogWidth,
        data: dialogData
      }
    ).afterClosed()
      .pipe(
        switchMap((newBonusData: DirectBonusFormData | null) => {
          if (newBonusData) {
            const {
              orderId,
              bonusId,
            } = newBonusData;

            if (orderId) {
              return !bonusId ?
                this.directBonusService.addBonusToExistingOrder(newBonusData) :
                this.directBonusService.updateBonusAmount(newBonusData)
            }

            return this.directBonusService.addBonusToNewOrder(newBonusData);
          }

          return EMPTY;
        }),
      )
      .subscribe(
        (result) => {
          if (result) {
            const message = 'Speichern erfolgreich.';
            this.alertService.message(message);

            this.tableDataChange.emit();
          }
        },
        () => {
          const message = 'Fehlgeschlagen! Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Berater.';
          this.alertService.error(message);
        }
      );
  }
}
