angular
  .module('VSPApp')
  .directive('buttonBlur', [function () {
    return {
      restrict: 'A',
      link: function (scope, element) {
        element.on('click', () => {
          element[0].blur();
        });
      }
    };
  }]);
