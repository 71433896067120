<div class='form-group'>
    <label [for]='key'
           [ngClass]="getLabelClass()">
        {{ label }}
        <span *ngIf="require">required</span>
    </label>

    <ng-content select='mat-error'>
    </ng-content>

    <input #formInput
           class='form-control'
           [ngClass]='getInputClass()'
           [name]='key'
           [type]='type'
           [attr.data-cy]='key'
           [disabled]="isDisabled"
           [placeholder]="placeholder"
           [matAutocomplete]="auto"
           [value]="value"
           (change)="onChange($event.target.value)"
           (click)="onChange($event.target.value)"
           *ngIf="!multiLine"/>

    
    <textarea #formInput
        class='form-control'
        [ngClass]='getInputClass()'
        [name]='key'
        type='text'
        [attr.data-cy]='key'
        [placeholder]="placeholder"
        [matAutocomplete]="auto"
        (change)="onChange($event.target.value)"
        (click)="onChange($event.target.value)"
        *ngIf="multiLine"
    >{{ value }}</textarea>

    <mat-autocomplete #auto="matAutocomplete">
        <ng-container *ngIf="withAutocomplete">
            <mat-option *ngFor="let option of filteredOptions$ | async"
                        [value]="option"
                        (change)="onChange(option)"
                        (click)="onChange(option)">
                {{option}}
            </mat-option>
        </ng-container>
    </mat-autocomplete>

    <span class='invisible'>
        {{ debouncedInput$ | async }}
    </span>
</div>
