// if changes are made don't forget the salary report
export const BONUS_PAYMENT_TYPES = [
  {
    'id': 0,
    'name': 'budget',
    'display': 'Budget',
    'short_display': 'B',
    'description': '',
    'user_decision_description': 'Wählen Sie Budget, so wird die Rate für das Fahrrad über Ihr Restbudget abgedeckt. Beachten Sie, dass hier bereits für 36 Monate das Budget zur Finanzierung des Fahrrads blockiert wird.'
  },
  {
    'id': 1,
    'name': 'exchange',
    'display': 'Umwandlung',
    'short_display': 'E',
    'description': '',
    'user_decision_description': 'Wählen Sie Entgeltumwandlung, so wird die Rate für das Fahrrad als Entgeltumwandlung eingerichtet. Ihr Budget steht nach dieser Entscheidung weiterhin zur Verfügung. \n\n Ihr Arbeitgeber prüft dann, ob hierfür ausreichend umwandlungsfähige Vergütungsbestandteile bestehen. Bei einer Ablehnung auf Grund unzureichender Umwandlungsmittel wird der Fahrradantrag erneut als Budget eingereicht.'
  }
];
export const BONUS_PAYMENT_TYPES_BY_NAME = BONUS_PAYMENT_TYPES.reduce((acc, payment_type) => {
  acc[payment_type['name']] = payment_type;
  return acc;
}, {});
export const BONUS_PAYMENT_TYPES_BY_ID = BONUS_PAYMENT_TYPES.reduce((acc, payment_type) => {
  acc[payment_type['id']] = payment_type;
  return acc;
}, {});
