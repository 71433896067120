export const FID_EMPLOYEE_BBG = [
  {
    id: 0,
    name: '1',
    value: '1',
    display: 'Ja',
    short_display: 'E'
  },
  {
    id: 1,
    name: '2',
    value: '2',
    display: 'Nein',
    short_display: 'B'
  },
  {
    id: 2,
    name: 'not_specified',
    value: 'not_specified',
    display: 'Not Specifed',
    short_display: 'NS'
  }
];
export const FID_EMPLOYEE_BBG_BY_NAME = FID_EMPLOYEE_BBG.reduce((acc, type) => {
  acc[type.name] = type;
  return acc;
}, {});
export const FID_EMPLOYEE_BBG_BY_VALUE = FID_EMPLOYEE_BBG.reduce((acc, type) => {
  acc[type.value] = type;
  return acc;
}, {});
export const DEFAULT_FID_EMPLOYEE_BBG = FID_EMPLOYEE_BBG_BY_VALUE['not_specified'];
export const FID_EMPLOYEE_BBG_BY_ID = FID_EMPLOYEE_BBG.reduce((acc, type) => {
  acc[type.id] = type;
  return acc;
}, {});
