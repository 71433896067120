const $inject = [
  '$q',
  '$scope',
  '$state',
  '$transitions',
  'customerService',
  'authenticationService',
  'NotificationService',
  'BikeConstants'
];
export default class CockpitBikeController {

  constructor(
    $q,
    $scope,
    $state,
    $transitions,
    customerService,
    authenticationService,
    NotificationService,
    BikeConstants
  ) {

    Object.assign(this, {
      $scope,
      customerService,
      NotificationService,
      BikeConstants
    });

    this.working = true;
    this.customerId = $state.params.customerId;

    let promises = {};

    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');

    if (!this.iAmGAdvisor && !this.iAmAdvisor && !this.iAmSpecialAdvisor) {
      $state.go('inApp.cockpit.arbeitnehmerverwaltung', {customerId: this.customerId});
      return;
    }

    promises.c = customerService
      .get(this.customerId)
      .then(customer => this.customer = customer);

    promises.cC = customerService
      .getConfigurationByCustomerId(this.customerId, Date.now())
      .then((customerConfig) => {
        this.customerConfig = customerConfig;
        this.setBikeConfigDefaults();
      });

    $q.all(promises)
      .then(() => this.working = false)
  }

  setBikeConfigDefaults() {
    let bikeConfig = this.BikeConstants;

    if (this.customerConfig.neo_config.bike) {
      bikeConfig = {...bikeConfig, ...this.customerConfig.neo_config.bike};
    }

    this.customerConfig.neo_config.bike = bikeConfig;
  }

  async save() {
    this.working = true;

    // TODO: Check if necessary due to fresh get in construct
    let actualConfig = await this.customerService.getConfigurationByCustomerId(this.customerId, Date.now());
    actualConfig.neo_config.bike = this.customerConfig.neo_config.bike;

    this.customerService
      .setConfiguration(this.customerId, actualConfig)
      .then(
        (customerConfig) => {
          this.NotificationService.message('Speichern erfolgreich.');
          this.customerConfig = customerConfig;
        },
        () => this.NotificationService.error('Speichern fehlgeschlagen.')
      )
      .finally(() => this.working = false);
  }

  updateRatenausfallPremium() {
    this.customerConfig.neo_config.bike.ausfallversicherungKuendigung = false;
    this.customerConfig.neo_config.bike.rechtschutzHaftpflicht = false;
  }
}
CockpitBikeController.$inject = $inject;
