const $inject = [
  '$scope',
  'FeedbackService'
];
export default class FeedbackController {
  constructor(
    $scope,
    FeedbackService
  ) {

    this.$scope = $scope;
    this.FeedbackService = FeedbackService;

    this.FeedbackService
      .get()
      .then(feedback => {
        this.$scope.feedback = feedback;
      }).finally(() => {
      if (!this.$scope.feedback)
        this.$scope.feedback = {};
    });

    this.FeedbackService
      .moduleQuestions()
      .then(questions => {
        this.$scope.moduleQuestions = questions;
      });

    this.FeedbackService
      .generalQuestions()
      .then(questions => {
        this.$scope.generalQuestions = questions;
      });

    this.FeedbackService
      .textQuestions()
      .then(questions => {
        this.$scope.textQuestions = questions;
      });

    this.$scope.save = () => {
      this.$scope.saving = true;

      this.FeedbackService
        .save(this.$scope.feedback.data, this.$scope.feedback._id || undefined)
        .then(() => {
          this.$scope.thankYou = true;
        }, () => {
          this.$scope.error = true;
        })
        .finally(() => {
          this.$scope.saving = false;
        });
    };
  }
}
FeedbackController.$inject = $inject;
