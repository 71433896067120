const $inject = [
  'Made',
  'dialogService',
  'userService',
  'mailService',
  'NotificationService',
  'customerService',
  '$timeout'
];

export default class MeineakteAccountController {
  constructor(
    Made,
    dialogService,
    userService,
    mailService,
    NotificationService,
    customerService,
    $timeout
  ) {
    Object.assign(this, {
      dialogService,
      mailService,
      userService,
      NotificationService,
      customerService,
      $timeout,

    });
    this.loading = {
      main: true
    };
    this.user = Made.user;
    this.iAmProxy = userService.iAmProxy();
    this.acceptedToc = false;

    this.newsletter_subscription = {
      is_accepted: false,
      is_rejected: false
    };

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    await this.userService.getProxySecurityLogs()
      .then((logs) => {
        this.security = logs;
      })
      .catch((err) => {
        console.error(err);
      });

    await this.userService.getTocStatus()
      .then(user => {
        if (user) {
          this.acceptedToc = user.toc && user.toc['0'];
        }
      })
      .catch((err) => {
        console.error(err);
      });

    await this.customerService.getConfigurationByEmployeeId(this.user.valuenet_id)
      .then((configuration) => {
        this.customer_configuration = configuration;
        return this.mailService.getNewslettertogoPreference(this.user.valuenet_id).
          then((response) => {
            console.log("RESPONSE BACE - ", response);
            if (response === true) {
              this.newsletter_subscription.is_accepted = true;
            } else if (response === false) {
              this.newsletter_subscription.is_rejected = true;
            }

          }).catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });


    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  async changeEmail() {
    window.open('/auth_cidaas/email_change/');
  }

  async changePassword() {
    window.open('/auth_cidaas/password_change/');
  }

  toc() {
    this.dialogService.employeeIntegrity('toc');
  }

  changeMail() {
    this.dialogService.changeMail();
  }

  editContactDetails() {
    this.dialogService
      .employeeContactDetails(this.user);
  }

  toggleNewsletterSubscription(allow_subscription) {

    this.newsletter_subscription.is_accepted = allow_subscription;
    this.newsletter_subscription.is_rejected = !allow_subscription;

    this.$timeout(() => {
      this.loading.main = true;
    });

    this.mailService.updateContact(this.user.valuenet_id, this.newsletter_subscription.is_accepted)
      .then((res) => {
        this.NotificationService.message("Die Änderungen wurden gespeichert");
      })
      .catch((err) => {
        console.error(err);
        this.newsletter_subscription.is_accepted = !allow_subscription;
        this.newsletter_subscription.is_rejected = allow_subscription;
        this.NotificationService.error('Fehlgeschlagen');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        });
      });
  }
}

MeineakteAccountController.$inject = $inject;
