const formatNumber = [function () {
  return function (number, op, op_arg, toFixed) {

    if (angular.isUndefined(number)) {
      return;
    }

    if (op === 'prepend') {
      let result = number.toString();
      let prepend_symbol = (op_arg && op_arg.prepend_symbol) | '0';
      let total_length = (op_arg && op_arg.total_length) | 6;
      for (let i = result.length; i < total_length; i++) {
        result = prepend_symbol + result;
      }
      return result;
    } else {

      let result = parseFloat(number);

      if (typeof result !== 'number' || Number.isNaN(result)) {
        return result;
      }

      if (op === '*' || op === 'mul') {
        result = (result * op_arg);
      } else if (op === '/' || op === 'div') {
        result = (result / op_arg);
      }

      if (toFixed > -1) {
        result = result.toFixed(toFixed);
      }

      return result;
    }
  }
}]

export { formatNumber }
