import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AdministrationService } from 'src/app/administration/services/administration.service';
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'vn-administration-hrmony-file-import',
  templateUrl: './administration-hrmony-file-import.component.html',
  styleUrls: ['./administration-hrmony-file-import.component.scss']
})
export class AdministrationHrmonyFileImportComponent extends BaseComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private service: AdministrationService
  ) { 
    super();
  }

  ngOnInit(): void {
  }

  dataImport(
    files: File[]
  ) {
    const {
      [0]: f
    } = files;

    if (!f) {
      throw new Error("Invalid file selection.")
    }

    this.setLoading$(true);

    this.service.importHrmonyData(f).pipe(take(1)).subscribe(() => {
      this.setLoading$(false);

      this.alertService.message('Erfolgreich');
    }, e => {
      this.alertService.error('Fehlgeschlagen');
    })
  }

}
