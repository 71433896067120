angular
  .module('VSPApp')
  .controller('ModuleSelectController', [
    '$scope',
    'Made',
    'ModulesService',
    '$state',
    'authenticationService',
    function (
      $scope,
      Made,
      ModulesService,
      $state,
      authenticationService,
    ) {

      $scope.moduleData = ModulesService.getUserModules();

      $scope.logout = function () {
        authenticationService.logout();
      };

      if ($scope.moduleData.length === 1) {
        $state.go($scope.moduleData[0].state);
      }

    }])
;
