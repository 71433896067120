import { Injectable } from '@angular/core';
import { UserCarSettingsSelection } from '../../models/user-car-settings-selection.model';

@Injectable()
export class BackendUserCarSettingsSelectionMapper {

  public toApi(settingsSelection: UserCarSettingsSelection): object {
    return {
      'finn_per_month_value': settingsSelection.finnPerMonthValue,
      'inclusive_km_per_month': settingsSelection.inclusiveKmPerMonth,
      'inclusive_km_per_month_price_package': settingsSelection.inclusiveKmPerMonthPackagePrice,
      'contract_term': settingsSelection.contractTerm,
      'car_fuel_type': settingsSelection.fuelType,
      'car_consumption': settingsSelection.carConsumption,
      'distance_work_home': settingsSelection.distanceWorkHome,
      'total_car_value': settingsSelection.totalCarValueMRSP,
      'additional_km_price': settingsSelection.additionalKmPrice,
      'has_company_car': settingsSelection.hasCompanyCar,
    };
  }

}
