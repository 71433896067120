import header from '../../../helpers/header.main.pug';
import dashboard_arbeitgeber_template from '../../../modules/dashboard/arbeitgeber/index.pug';

const $injectDashboardRoutes = ['$stateProvider'];
const dashboardRoutes = ( $stateProvider ) => {

  $stateProvider
    .state( 'inApp.dashboardArbeitgeber', {
      url: '/dashboard_ag',
      views: {
        main: {
          template: dashboard_arbeitgeber_template,
          controller: 'DashboardArbeitgeberController'
        },
        header: {
          template: header
        }
      }
    } )
    // .state( 'inApp.dashboardArbeitgeber.analytics', {
    //   url: '/analytics',
    //   views: {
    //     dashboardAgMain: {
    //       templateUrl: '',
    //       controller: ''
    //     }
    //   }
    // } )
    // .state( 'inApp.dashboardArbeitgeber.ersparnisse', {
    //   url: '/ersparnisse',
    //   views: {
    //     dashboardAgMain: {
    //       templateUrl: '',
    //       controller: ''
    //     }
    //   }
    // } )
    // .state( 'inApp.dashboardArbeitgeber.bausteine', {
    //   url: '/bausteine',
    //   views: {
    //     dashboardAgMain: {
    //       templateUrl: '',
    //       controller: ''
    //     }
    //   }
    // } );
}
dashboardRoutes.$inject = $injectDashboardRoutes;

angular
  .module( 'VSPApp' )
  .config( dashboardRoutes );
