import { Dashboard } from "./car-leasing.interfaces";

export class CarLeasingDashboard implements Dashboard {

  public id: string = 'car_leasing';
  public title: string = 'Auto Abo';
  public pic_id: string = '';
  public video: string = '';
  public frame: string = '';

  constructor(
    public is_used: boolean = false,
  ) {}

}
