const COOKIE_MADE_USER = 'made-user';
const $inject = [
  '$q',
  '$rootScope',
  'Made',
  'ACLService',
  'mailService',
  '$cookies',
];
export default class authenticationService {

  constructor(
    $q,
    $rootScope,
    Made,
    ACLService,
    mailService,
    $cookies
  ) {

    this.$q         = $q;
    this.$rootScope = $rootScope;
    this.Made       = Made;
    this.ACLService = ACLService;
    this.mailService = mailService;
    this.$cookies    = $cookies;

    this.groupMap = {
      // alias example:
      // MegaMan     : 'vsp_vcp_Derbe',
    };

    Made.on( 'message:error', data => {
      if (
        ('object' === typeof data.error) &&
           ('NotLoggedInException' === data.error.name )
      ) {
        this.logout();
      }
    } );

    window.anonymous_logout = () => this.logout();

  }

  isLoggedInPromise() {
    return this.isLoggedIn() ? this.$q.when( true ) : this.$q.reject( false );
  }

  isLoggedIn() {
    return this.Made.isLoggedIn();
  }

  login( email, password ) {
    let promise = this.Made.login( email, undefined, password );

    promise
      .then((user)=> {
        return this.mailService.updateContact(user.valuenet_id);
      })
      .then( () => {
        this.$rootScope.$broadcast( 'authenticationService.login', this.Made.user );
        try {
          window.webkit.messageHandlers.logStatusChanged.postMessage( 'loggedin' );
        } catch (e) {
          /*ignore, it means we are bit in an ios app container*/
        }
      } );

    return promise;
  }

  logout() {
    this.$rootScope.$broadcast('authenticationService.logout');

    const made_user = JSON.parse(this.$cookies.get(COOKIE_MADE_USER));
    this.$cookies.remove(COOKIE_MADE_USER);

    if (made_user.state === undefined) {
      window.location.href = '/cidaas_login'
    }

    window.location.href = '/auth_cidaas/logout/?state=' + made_user.state;
  }

  iAm( groups, user = this.Made.user ) {
    let group = null,
      i;

    if ( !user || Object.keys(user).length === 0 ) {
      return false;
    }

    if ( !angular.isArray( groups ) ) {
      groups = [
        groups
      ];
    }

    for (i = 0; i < groups.length; i++) {
      group = groups[ i ];

      if ( this.groupMap[ group ] ) {
        group = this.groupMap[ group ];
      }

      if ( -1 !== user.groups.indexOf( group ) ) {
        return true;
      }
    }

    return false;
  }

  iMustBe( groups ) {
    for (var i = 0; i < groups.length; i++) {

      if ( this.iAm( groups[ i ] ) ) {
        return groups[ i ];
      }
    }
    throw 'Access denied';
  }

  getLocationsByCustomerCode(customerCode) {
    return this.Made.request('rpc://valuenetdb/customer/get_customer_by_code', {
      customer_code: customerCode
    });
  }

  create(data, neoProjectId, newsletterAccepted=null, registrationType = 'registration') {
    return this.Made.request( 'rpc://valuenetdb/employee/create_external_user', {
      employee: data,
      host: location.origin,
      neo_project_id: neoProjectId,
      newsletter_accepted: newsletterAccepted,
      registration_type: registrationType
    });
  }

  changeMail( password, newMail, oldMail, userId = this.Made.user._id ) {
    //console.log(password, newMail, oldMail, userId);

    let promise = this.Made
                      .request( 'rpc://valuenetdb/employee/changeMail', {
                        userId,
                        password,
                        newMail,
                        oldMail
                      } );

    promise.then( passwordChanged => {
      //this.Made.user.passwordChanged = passwordChanged;
      //this.Made.saveUser(this.Made.user );
    } );

    return promise;
  }

  activateNewMail(token) {
    return this.Made
        .request( 'rpc://valuenetdb/employee/changeMailActivation', {
          token
        });
  }

  activateUserWithActivationCode(employeeData) {
    return this.Made
      .request('rpc://valuenetdb/employee/activate_user_with_activation_code', {
        'employee_activation': employeeData
      });
  }
}
authenticationService.$inject = $inject;
