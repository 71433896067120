import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';

interface MPPLog {
  date: string;
  oldStatus: string;
  newStatus: string;
  comment: string;
  changer: {
    id: string;
    firstname: string;
    lastname: string
  } | null;
}

interface TableDataItem {
  date: string;
  oldStatus: string;
  newStatus: string;
  comment: string;
  changerName: string | null;
}

@Component({
  selector: 'vn-mpp-status-log-dialog',
  templateUrl: './mpp-status-log-dialog.component.html',
  styleUrls: ['./mpp-status-log-dialog.component.scss']
})
export class MppStatusLogDialogComponent extends BaseComponent implements OnInit {

  public formGroup!: FormGroup;
  public logsTableData: TableDataItem[] = [];
  public displayedColumns: string[] = [
    'date',
    'oldStatus',
    'newStatus',
    'changerName',
    'comment'
  ];

  constructor(
    public dialogRef: MatDialogRef<MppStatusLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {basket: any},
    public alertService: AlertService,
    @Inject('UserService') private userService: any,
  ) {
    super()
  }

  ngOnInit(): void {
    this.setLoading$(true);

    const { _id, employeeId }: {_id: string, employeeId: number} = {...this.dialogData.basket}
    this.userService.getMPPLogs(employeeId, _id).then((r: MPPLog[]) => {
      this.logsTableData = r.map(log => ({
          date: log.date,
          oldStatus: log.oldStatus,
          newStatus: log.newStatus,
          comment: log.comment,
          changerName: log.changer ? `${log.changer.firstname} ${log.changer.lastname}` : null
        })
      );
      this.setLoading$(false);
    });
  }
}
