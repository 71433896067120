var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["contact"] = pug_interp = function(prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!prefix) {
prefix=''
}
pug_html = pug_html + "\u003Cdiv class=\"box contact\"\u003E\u003Cdiv class=\"title\"\u003EIhr Ansprechpartner\u003C\u002Fdiv\u003E\u003Cdiv class=\"main text-center\"\u003E\u003Cdiv class=\"detail\"\u003E\u003Cfigure class=\"avatar\"\u003E\u003Cdiv class=\"picture\"\u003E\u003Cimg" + (" class=\"image\""+pug.attr("ng-src", '/assets/img/advisors/{{ '+prefix+'advisorDetails.id }}.jpg', true, true)+" onerror=\"this.src=&quot;\u002Fassets\u002Fimg\u002Fadvisors\u002Ffallback.jpg&quot;\" onabort=\"this.src=&quot;\u002Fassets\u002Fimg\u002Fadvisors\u002Ffallback.jpg&quot;\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cfigcaption class=\"name\"\u003E" + (pug.escape(null == (pug_interp = '{{ '+prefix+'advisorDetails.firstname }} {{ '+prefix+'advisorDetails.name }}') ? "" : pug_interp)) + "\u003C\u002Ffigcaption\u003E\u003C\u002Ffigure\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"detail email\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-email\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"key\"\u003E\u003Ca" + (pug.attr("ng-href", 'mailto:{{ '+prefix+'advisorDetails.email }}', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = '{{ '+prefix+'advisorDetails.email }}') ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"detail phone\" ng-if=\"advisorDetails.phone_support\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-telephone\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"key\"\u003E\u003Ca" + (pug.attr("ng-href", 'tel:+{{ '+prefix+'advisorDetails.phone_support }}', true, true)) + "\u003E" + (pug.escape(null == (pug_interp = '{{ '+prefix+'advisorDetails.phone_support }}') ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Csection class=\"arbeitnehmer\" id=\"dashboard-box\"\u003E\u003Carticle class=\"section-bg datev-page-header\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-7 large-6 columns\"\u003E\u003Cdiv class=\"panel semitransparent\"\u003E\u003Ch2\u003ERechner für Gehaltsextras\u003C\u002Fh2\u003E\u003Ch3\u003Epowered by ValueNet\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Farticle\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv id=\"main-view\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"xsmall-12 medium-12 large-12 columns\"\u003E\u003Cdiv class=\"summary-box\"\u003E\u003Ca class=\"back-link\" ng-click=\"DatevDetailsCtrl.backLink()\"\u003E\u003Ci class=\"font-icon icon-left\"\u003E\u003C\u002Fi\u003E zurück\u003C\u002Fa\u003E\u003Cdiv class=\"datev-article\" ng-if=\"!DatevDetailsCtrl.currentArticle.is_html\"\u003E\u003Cdiv class=\"quill-editor\"\u003E\u003Cng-quill-editor class=\"quill-content\" id=\"cms-content\" ng-model=\"DatevDetailsCtrl.currentArticle.content\" required=\"true\" ng-required=\"true\" editor-required=\"true\" theme=\"snow\" read-only=\"true\" modules=\"{toolbar:false}\"\u003E\u003C\u002Fng-quill-editor\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"datev-article\" ng-if=\"DatevDetailsCtrl.currentArticle.is_html\"\u003E\u003Cdiv ng-bind-html=\"DatevDetailsCtrl.currentArticle.content | trustHtml\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca class=\"back-link\" ng-click=\"DatevDetailsCtrl.backLink()\"\u003E\u003Ci class=\"font-icon icon-left\"\u003E\u003C\u002Fi\u003E zurück\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"side-view\" fixonheader\u003E";
pug_mixins["contact"]('datev.');
pug_html = pug_html + "\u003Cdiv class=\"box video-container\"\u003E\u003Cdiv class=\"title\"\u003EDas ValueNet Portal\u003C\u002Fdiv\u003E\u003Cdiv class=\"main\"\u003E\u003Cdiv class=\"video\"\u003E\u003Ciframe src=\"https:\u002F\u002Fplayer.vimeo.com\u002Fvideo\u002F397980824?dnt=1\" webkitallowfullscreen mozallowfullscreen allowfullscreen\u003E\u003C\u002Fiframe\u003E\u003Ca class=\"video-link\" href=\"https:\u002F\u002Fplayer.vimeo.com\u002Fvideo\u002F397980824?dnt=1\" magnific-popup=\"{type: &quot;iframe&quot;, clicker: clicker}\"\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E";;return pug_html;};
module.exports = template;