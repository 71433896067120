const SliderOptionsConstants = {
  step: 1,
  limits: true,
  dimension: '',
  css: {
    background: {
      'background-color': '#c8c8c8'
    },
    before: {
      'background-color': '#ffb500'
    },
    default: {
      'background-color': '#ffb500'
    },
    after: {
      'background-color': '#ffb500'
    },
    range: {
      'background-color': '#ffb500'
    },
    pointer: {
      'background-color': '#ffb500',
      'top': '-8px',
      'width': '20px',
      'height': '20px',
      'margin-left': '-10px'
    }
  }
};

export default SliderOptionsConstants;
