const UsercentricsConstants = {
  LEADLAB: 'HkdvTCJTN',
  AMAZON_WEB_SERVICES: 'J39GyuWQq',
  CLOUDFLARE: 'HkIVcNiuoZX',
  GOOGLE_MAPS: 'S1pcEj_jZX',
  GOOGLE_TAG_MANAGER: 'BJ59EidsWQ',
  GOOGLE_ANALYTICS: 'HkocEodjb7',
  GOOGLE_TRANSLATE: 'qvkzz7qPe',
  GSTATIC: 'rJJjcVouoZ7',
  YOUTUBE_VIDEO: 'BJz7qNsdj-7',
  USERCENTRICS_CMP: 'H1Vl5NidjWX',
  FACEBOOK_SOCIAL_PLUGINS: 'XYQZBUojc',
  SALESFORCE: 'd_5HNF7Yc',
  USERLANE: '5wohJ1Vz9',
  VIMEO: 'HyEX5Nidi-m',
  MPP_VALUENET: 'NUADGRZ8H',
  BYTEPLANT_GMBH: '1j7ubPcB2',
  PDF_GENERATOR_API: 'cto86HTJA',
  ASANA: 'Lp8RRIcQl'
}

export default UsercentricsConstants;
