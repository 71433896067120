export const OPTIMIZATION_ASSISTANT_STEPS = [
  {
    id: '2b',
    title: 'Brutto',
    html: '<p>Sie möchten eine Vorteilsberechnung durchführen. Hierzu benötigen wir einige Angaben.Zur Vereinfachung der Vorteilsermittlung wird auf folgende Punkte nicht eingegangen, da sie nur eine untergeordnete Einflussnahme auf das Ergebnis haben:</p>' +
      '<ul>' +
      '<li>individuelle Freibeträge auf der Lohnsteuerkarte (Ausnahme Kinderfreibetrag)</li>' +
      '<li>Individueller Faktor in Steuerklasse 4</li>' +
      '<li>Zusatzbeitragssatz Krankenversicherung</li>' +
      '<li>Zusätzliche Absetzbarkeit private Krankenversicherung</li>' +
      '</ul>',
    options: [
      {
        type: 'input',
        subquestion: 'Bitte geben Sie Ihr monatliches Bruttoentgelt an. Verwenden Sie hier zur Vereinfachung das Steuerbrutto von Ihrer Gehaltsabrechnung.',
        is_global: true,
        default: null,
        key: 'brutto',
        title: 'Brutto €',
        settings: {
          range: {min: 0},
          currency: true
        }
      },
      {
        type: 'select',
        subquestion: 'Bitte nennen Sie uns Ihre Steuerklasse, einen Kinderfreibetrag und ob Sie kirchensteuerpflichtig sind oder nicht.',
        key: 'steuer_klasse',
        is_global: true,
        // parent: 'steuer_klass',
        default: null,
        title: 'Steuerklasse',
        options: {
          key: 'SteuerklassenConstants',
          key_property: 'value',
          display_property: 'name'
        },
        settings: {
          required: true
        }
      },
      {
        type: 'select',
        key: 'kinderfreibetrag',
        is_global: true,
        // parent: 'steuer_klass',
        default: null,
        title: 'Kinderfreibetrag',
        options: {
          key: 'CHILD_TAX_CREDIT',
          key_property: 'value'
        },
        settings: {
          required: true
        }
      },
      {
        type: 'checkbox',
        title: 'Kirchensteuerpflicht',
        is_global: true,
        // parent: 'Bikeleasing',
        key: 'has_church_tax',
        default: false,
      },
      {
        type: 'checkbox',
        subquestion: 'Sind Sie privat oder gesetzlich krankenversichert?',
        is_global: true,
        key: 'is_private',
        title: 'gesetzlich',
        default: null,
        settings: {
          checkbox: {
            true_value: false,
            false_value: true
          }
        }
      },
      {
        type: 'checkbox',
        is_global: true,
        key: 'is_private',
        title: 'privat',
        default: null
      }
    ]
  },
  // {
  //   id: '2c',
  //   title: '',
  //
  //   question: 'Bitte nennen Sie uns Ihre Steuerklasse, einen Kinderfreibetrag und ob Sie kirchensteuerpflichtig sind oder nicht.',
  //   options: []
  // },
  // {
  //   id: '2d',
  //   title: 'krankenversichert',
  //   question: 'Sind Sie privat oder gesetzlich krankenversichert?',
  //   options: []
  // }
];
