import cockpit_project_update_mode_html from '../../../modules/cockpit/projekte/cockpit_project_update_mode.pug'


angular
  .module('VSPApp')
  .controller('CockpitProjekteController', [
    '$scope',
    '$filter',
    'ComponentConstants',
    'SchemaService',
    'projectService',
    'paymentService',
    'ngDialog',
    function (
      $scope,
      $filter,
      ComponentConstants,
      SchemaService,
      projectService,
      paymentService,
      ngDialog
    ) {

      var projectDefaults = {
        name: 'Neue Projekte',
        kind_id: 1,
        type_id: 1,
        customer_id: null,
        amount: 0,
        time: Date.now(),
        flat_tax: false,
        supply_compensation_id: 1,
        alv_ktg_compensation_id: 1,
        target_id: 1,
        target_amount: 0,
        components: []
      };

      $scope.search = {
        text: ''
      };

      $scope.newProject = null;
      $scope.project = null;
      $scope.loading = false;
      $scope.possibleBausteine = ComponentConstants;
      $scope.selectedModules = [];
      $scope.projectPropHelpers = {
        supply_compensation_enabled: null,
        alv_ktg_compensation_enabled: null
      };
      $scope.currentProject = angular.copy(projectDefaults);
      $scope.currentProjectEmployees = [];

      $scope.addNewProject = function () {
        projectDefaults.customer_id = $scope.selectedCompany.id;

        $scope.newProject = angular.extend({
          editable: true
        }, projectDefaults);
      };

      $scope.configureProject = function (project) {
        $scope.currentProject = angular.copy(project, $scope.currentProject);
      };

      $scope.selectProject = function (project) {
        if (!project) {
          $scope.selectedProject = project;
          return;
        }

        projectService
          .getEmployees(project.id)
          .then(function (employees) {
            $scope.selectedProject = project;
            $scope.currentProjectEmployees = employees;
            groupEmployees();
          });
      };

      $scope.saveNewProject = function () {
        var project = $scope.newProject;
        // Remove invalid fields
        delete project.editable;

        projectService
          .create(project)
          .then(function (project) {
            $scope.getCustomerData(project.customer_id);
            $scope.companyProjects.push(project); // Latency compensation
            $scope.project = null;
            $scope.newProject = null;
            $scope.currentProject = null;
          });
      };

      $scope.saveProjectConfig = function () {
        projectService
          .getEmployees($scope.currentProject.id)
          .then(function (employees) {
            $scope.currentProjectEmployees = employees;
            return Object.keys(employees).length;
          })
          .then(function (employeesInProjectCount) {
            // if current project does not contains any member yet,
            // update the project immediately
            if (employeesInProjectCount === 0) {
              updateProject(false);
              return;
            }

            ngDialog.openConfirm({
              template: cockpit_project_update_mode_html(),
              plain: true,
              showClose: false,
              preCloseCallback: updateProject
            });

          });

        function updateProject(update_members) {
          // lets assume update_members will be set to false if project update not updates
          // members, set to true if update also updates all members
          projectService
            .save($scope.currentProject, update_members)
            .then(function () {
              $scope.getCustomerData($scope.currentProject.customer_id);
              $scope.project = null;
              $scope.newProject = null;
              $scope.currentProject = null;
            });
        }
      };

      $scope.deleteProject = function (project) {
        projectService
          .delete(project)
          .then(function () {
            $scope.getCustomerData(project.customer_id);
            $scope.project = null;
            $scope.newProject = null;
            $scope.currentProject = null;
          });
      };

      $scope.addToProject = function (project, employeeId) {
        var bonus = {
          project_id: project.id,
          receiver_id: employeeId,
          amount: project.amount,
          time: project.time,
          params: project.params,
          flat_tax: project.flat_tax,
          supply_compensation_id: project.supply_compensation_id,
          alv_ktg_compensation_id: project.alv_ktg_compensation_id,
          target_id: project.target_id,
          target_amount: project.target_amount,
        };

        paymentService
          .create(bonus)
          .then(function (payment) {
            $scope.currentProjectEmployees[payment.receiver_id] = payment.id;
            groupEmployees();
          });
      };

      $scope.savePayment = function () {
        paymentService.save($scope.selectedPayment);
      };

      $scope.removeFromProject = paymentService.delete;

      $scope.loadPaymentDetails = function (employeeId, toggleStatus, callback) {
        var paymentId = $scope.currentProjectEmployees[employeeId];

        if (!paymentId) {
          return;
        }

        if (toggleStatus === false &&
          callback && typeof callback === 'function') {
          callback();
          return;
        }

        paymentService
          .get(paymentId, false)
          .then(function (payment) {
            $scope.selectedPayment = payment;

            if (callback && typeof callback === 'function') {
              callback();
            }
          });
      };

      $scope.addAllEmployeeToProject = function () {
        // Send nonMembers ids
      };

      $scope.removeAllEmployeeFromProject = function () {
        // Send member ids
      };

      function groupEmployees() {
        $scope.filteredEmployees = $filter('groupByMembership')($scope.employees, $scope.currentProjectEmployees);
        $scope.members = $scope.filteredEmployees[0];
        $scope.nonMembers = $scope.filteredEmployees[1];
      }
    }]);
