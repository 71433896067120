<h5>
    Mit der Bestätigung wird die Entscheidung zurückgesetzt und alle offenen Kaufvorgänge werden ebenfalls gelöscht.
</h5>

<mat-dialog-content>
    <form [formGroup]='form'>
        <vn-input formControlName='comment'
            required
            label="Kommentar">
        </vn-input>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button mat-stroked-button
            [disabled]="form.invalid"
            (click)="resetOrder()">
        Bestätigen
    </button>
</mat-dialog-actions>