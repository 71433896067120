import lodash from 'lodash';
import {ComponentModel} from './component-model';

export class CheckoutBasket {
  constructor(options) {
    this._options = lodash.cloneDeep(options);
    options = lodash.cloneDeep(options);
    this.component_names = ['pc'];
    
    this._components = [];
    this._components_by_type = {};
    this._contact_information = {};

    this._setValues(options, true);
  }
  
  reset() {
    this._components = [];
    this._components_by_type = {};
  }
  
  addComponent(components) {
    if (!angular.isArray(components)) {
      components = [components];
    }
    
    // validate 
    for (let component of components) {
      if (!(component instanceof ComponentModel)) {
        throw 'component must be of type ComponentModel';
      }
    }
    
    // add
    for (let component of components) {
      
      if (this.hasComponent(component)) {
        continue;
      }
      
      this._components.push(component);
      this._cacheComponent(component);
    }
  }
  
  _getComponentId(component) {

    let is_component_model = component instanceof ComponentModel;
    let is_valid_component_name = angular.isString(component) && this.component_names.includes(component);
    
    if (is_component_model) {
      return component.id;
    } else if (is_valid_component_name) {
      return component;
    } else {
      throw 'component must be of type ComponentModel';
    }
  }
  
  hasComponent(component) {
    if (angular.isUndefined(component)) {
      return this._components.length > 0;
    }

    let component_id = this._getComponentId(component);
    return this._components_by_type[component_id] > 0;
  }
  
  getComponent(component) {
    if (angular.isUndefined(component)) {
      return;
    }
    
    let component_id = this._getComponentId(component);
    
    return this._components.find(current_component => current_component.id === component_id);
  }
  
  removeComponent(index) {
    if (index < this._components.length && index >= 0 ) {
      let removed = this._components.splice(index, 1);
      for (let component in removed ) {
        this._removeFromCache(component);
      }
    }
  }
  
  get brutto_sum () {
    // TODO: can cache
    let sum = 0;
    return this._components.reduce((acc, component)=>{
      acc += component.brutto_value;
      return acc;
    }, sum);
  }
  
  get per_month_value ( ) {
    let sum = 0;
    return this._components.reduce((acc, component) => {
      acc += component.per_month_value;
      return acc;
    }, sum);
  }
  
  get tax_sum() {
    return 0;
  }
  
  get netto_sum () {
    // TODO: can cache
    let sum = 0;
    return this._components.reduce((acc, component)=>{
      acc += component.netto_value;
      return acc;
    }, sum);
  }
  
  get components(){
    return this._components;
  }
  
  get employee_id() {
    return this._employee_id;
  }
  
  _setValues(options, to_attach_others=false) {
    this._employee_id = options.employee_id;
    
    if (to_attach_others) {
      delete options.employee_id;
      Object.assign(this, options);
    }
  }
  
  _cacheComponent(component) {
    let component_type = component.id;
    
    if (lodash.isUndefined(this._components_by_type[component_type])) {
      this._components_by_type[component_type] = 0;
    }

    this._components_by_type[component_type] += 1;
  }
  
  _removeFromCache(component) {
    let component_type = component.id;

    if (lodash.isNumber(this._components_by_type[component_type])) {
      this._components_by_type[component_type] -= 1; 
    }
  }

  addContactInformation(type, value) {
    if(type === "phoneNumber") {
      this._contact_information['phone_number'] = value;
    } else
    if(type === "emailAddress") {
      this._contact_information['email_address'] = value;
    }
  }

  get contact_information() {
    return this._contact_information
  }
}
