const $inject = [
  '$q',
  'Made'
]

export default class SchemaService {
  constructor($q, Made) {
    this.$q = $q;
    this.Made = Made;

    this.schemata = {};
    this.promises = {};
  }

  get(url) {
    if (this.schemata[url]) {
      return this.$q.when(this.schemata[url]);
    }

    if (this.promises[url]) {
      return this.promises[url];
    }

    this.promises[url] = this.Made.schema(url).then(response => this.schemata[url] = response.schema.properties);

    this.promises[url].finally(() => delete this.promises[url]);

    return this.promises[url];
  };
}

SchemaService.$inject = $inject
