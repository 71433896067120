<h2 mat-dialog-title>Bitte bestätigen Sie die Entscheidung</h2>
<div vnLoader [showLoader]='(isLoading$() | async)!'>
    <mat-dialog-content>
        <strong>
            {{ shouldBlock ? 'Sperren ' : 'Freigeben ' }}
        </strong>
        <span>
            der PC Leasing Komponente für {{ employee.id }},  {{ employee.firstname }} {{ employee.lastname }}
        </span>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button mat-dialog-close>
            Abbrechen
        </button>
        <button class='save-button'
            mat-stroked-button
            data-cy='neo.checkout.pcLeasing.blockingDialog.buttonSubmit'
            (click)="saveBlockingPcLeasingInformation()">
            Bestätigen
        </button>
    </mat-dialog-actions>
</div>