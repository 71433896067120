import {
  BONUSES_WITH_EDITABLE_BEGIN_DATE,
  PC_BONUS_COMPONENT_NAMES,
  BIKELEASING_BONUS_NAMES
} from '../../../services/BonusService';
import {DEFAULT_MPP_ARTICLE} from '../../../services/mppService';
import {APPROVAL_PROCESS_TYPES_BY_NAME} from '../../../services/administrationService';
import {BONUS_PAYMENT_TYPES_BY_ID, BONUS_PAYMENT_TYPES_BY_NAME} from "../../../services/bonus/bonus_payment_type";
import {BONUS_STATES_BY_ID} from "../../../services/bonus/bonus_state";

const $inject = [
  '$scope',
  '$q',
  'lodash',
  'BonusService',
  'employeeService',
  'dialogService',
  '$timeout',
  'mppService',
  'NotificationService',
  '$window',
  'fileService',
  'moment',
  'administrationService'
];
export default class BonusEditController {
  constructor(
    $scope,
    $q,
    lodash,
    BonusService,
    employeeService,
    dialogService,
    $timeout,
    mppService,
    NotificationService,
    $window,
    fileService,
    moment,
    administrationService
  ) {
    Object.assign(this, {
      $scope,
      $q,
      lodash,
      BonusService,
      employeeService,
      dialogService,
      $timeout,
      mppService,
      NotificationService,
      $window,
      fileService,
      moment,
      administrationService
    });

    this.BONUSES_WITH_EDITABLE_BEGIN_DATE = BONUSES_WITH_EDITABLE_BEGIN_DATE;
    this.BONUS_PAYMENT_TYPES_BY_ID = BONUS_PAYMENT_TYPES_BY_ID;
    this.BONUS_PAYMENT_TYPES_BY_NAME = BONUS_PAYMENT_TYPES_BY_NAME;
    this.BIKELEASING_BONUS_NAMES = BIKELEASING_BONUS_NAMES;

    this.loading = {
      main: true,
      update_bonus: false,
      update_basket: false,
      calculating_sum: false,
      decline: false
    };

    this.dates = {
      bonus_start_date: undefined
    };

    this.init().then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  async init() {
    this.employee_id = this.$scope.ngDialogData.employee_id;
    this.to_allow_same_day_change = this.$scope.ngDialogData.to_allow_same_day_change;
    this.bonus = angular.copy(this.$scope.ngDialogData.bonus);
    this.bonus_id = this.bonus['_id'];
    this.bonus_component_name = this.BonusService.getBonusComponentName(this.bonus);
    this.BONUS_STATES_BY_ID = BONUS_STATES_BY_ID;
    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;
    this.isCashbackBonus = this.BonusService.isBonusCashback(this.bonus);


    this.forms = {
      change: {},
      decline: {},
      edit_basket: {},
      cancel: {}
    };

    this.models = {
      change: {
        new_begin_date: angular.copy(this.bonus.dates.bonus_start_date),
        comment: undefined
      },
      decline: {
        decline_comment: ''
      }
    };

    this.$scope.models = {
      edit_basket: {
        edit_basket_comment: ''
      }
    };

    if (this.bonusIsPC()) {
      this.ORDER_STATUSES_BY_NAME = await this.mppService.getOrderStatusesByName();

      await this.initPCBasket();

      // get pc_limits
      try {
        this.pc_leasing_limits = await this.getPCLeasingLimits();

        // set up variables
        let customer_approved_limit = this.lodash.get(this.bonus, 'limits.customer_approved_limit');
        let has_customer_approved_limit = angular.isNumber(customer_approved_limit);
        let is_exchange = this.bonus.checkout_config['bonus_payment_type'] === this.BONUS_PAYMENT_TYPES_BY_NAME['exchange']['id'];
        let is_budget = this.bonus.checkout_config['bonus_payment_type'] === this.BONUS_PAYMENT_TYPES_BY_NAME['budget']['id'];

        // define basket_max_limit
        this.basket_max_limit = undefined;
        if (has_customer_approved_limit && is_exchange) {
          this.basket_max_limit = customer_approved_limit;
        } else if (has_customer_approved_limit && is_budget) {
          this.basket_max_limit = Math.min(customer_approved_limit, this.pc_leasing_limits['max_brutto_value']);
        } else if (!has_customer_approved_limit && is_exchange) {
          this.basket_max_limit = this.bonus.checkout_config.full_amount;
        } else if (!has_customer_approved_limit && is_budget) {
          this.basket_max_limit = Math.min(this.pc_leasing_limits['max_brutto_value'], this.bonus.checkout_config.full_amount);
        } else {
          throw 'dont know what to do';
        }

        if (!angular.isNumber(this.basket_max_limit)) {
          throw 'must have basket_max_limit';
        }
      } catch (err) {
        console.error(err);
        this.pc_leasing_limits = null;
      }
    }

    this.bonus_files = await this.BonusService.getFilesForBonuses(
      [
        `bonus_id-${this.bonus._id}`,
        `bonus_project_id-${this.bonus.bonus_project}`
      ]
    );

    this.models.decline.decline_comment = await this.getBonusLastDeclinedApprovalComment(this.bonus);

    this.BONUS_COMPONENTS_BY_NAME = await this.getBonusComponentsMapByName();
  }

  bonusIsPC() {
    return PC_BONUS_COMPONENT_NAMES.includes(this.bonus_component_name);
  }

  async initPCBasket() {
    let basket = await this.mppService.getCartById(this.bonus.checkout_config.cart._id);
    this.$scope.basket = angular.copy(basket);
    this.original_basket = basket;
  }

  async getPCLeasingLimits() {
    return this.BonusService.calculatePCLeasing(this.employee_id, this.bonus.bonus_project, this.bonus._id);
  }

  deleteArticle(hashKey) {
    this.$scope.basket.articles = this.$scope.basket.articles.filter(article => !angular.equals(article.$$hashKey, hashKey));
  }

  addArticle() {
    this.$scope.basket.articles.push(angular.copy(DEFAULT_MPP_ARTICLE));
  }

  async updateBasket() {
    // is sum too high
    if (this.canUpdateBasket()) {
      this.$timeout(() => {
        this.loading.update_basket = true;
      });

      // store basket
      await this.mppService.updateMPPArticleData({
        basket: this.$scope.basket,
        comment: this.$scope.models.edit_basket.edit_basket_comment
      })
        .catch(e => {
          this.NotificationService.error(e.error.message.split('\n')[0]);
        }).finally(() => {
          this.$timeout(() => {
            this.loading.update_basket = false;
            this.NotificationService.message('Die Änderungen wurden gespeichert');
            this.$scope.confirm({});
          });
        });
    }
  }

  openDocument(document) {
    this.fileService.openDocument(document._id);
  }

  generateBonusContract() {
    this.BonusService.generateBonusCheckoutContractByBonusId(this.employee_id, this.bonus._id).then(
      (res) => {
        if (res) {
          this.$window.open(res.response, '_blank');
        } else {
          throw new Error('Contract not generated')
        }
      }).catch((err) => {
      console.error(err);
    });
  }

  getComponentDisplayStartDate(bonus) {
    if (bonus) {
      return this.BonusService.getComponentDisplayStartDate(bonus);
    }
  }

  getComponentDisplayEndDate(bonus) {
    if (bonus) {
      return this.BonusService.getComponentDisplayEndDate(bonus);
    }
  }

  async compareCartSum() {
    this.$timeout(() => {
      this.loading.calculating_sum = true;
    });

    let user_bonus_payment_type_choice_id = this.lodash.get(this.$scope.basket, 'user_bonus_payment_type_choice.id');
    if (!user_bonus_payment_type_choice_id) {
      user_bonus_payment_type_choice_id = this.lodash.get(this.bonus, 'checkout_config.bonus_payment_type');
    }

    return this.BonusService.checkoutBonusMppCart({
        employee_id: this.employee_id,
        bonus_project_id: this.bonus_project_id,
        bonus_id: this.bonus_id,
        start_date: this.bonus.dates.bonus_start_date,
        user_bonus_payment_type_choice_id: user_bonus_payment_type_choice_id,
        cart: this.$scope.basket,
        is_test: true
      }
    ).then((new_checkout_config) => {
      this.new_full_amount = new_checkout_config['full_amount'];
      this.over_sum_limit = this.new_full_amount > this.basket_max_limit;
      this.checkout_check_error = undefined;
    }).catch((e) => {
      this.checkout_check_error = e.error && e.error.message;
      this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
    }).finally(() => {
      this.$timeout(() => {
        this.loading.calculating_sum = false;
      });
    });
  }

  basketDataChanged() {
    return !angular.equals(this.$scope.basket, this.original_basket);
  }

  hasChangedStartDate() {
    return this.models.change.new_begin_date !== this.bonus.dates.bonus_start_date;
  }

  areFormsValid(forms) {
    return Object.keys(forms).reduce((acc, form_name) => {
      acc = acc && forms[form_name].$valid;
      return acc;
    }, true);
  }

  areChangeFormsValid() {
    let forms = this.forms.change;
    return this.areFormsValid(forms);
  }

  areDeclineFormsValid() {
    let forms = this.forms.decline;
    return this.areFormsValid(forms);
  }

  areEditBasketFormsValid() {
    return this.areFormsValid(this.forms.edit_basket);
  }

  canUpdateBonusSameDate() {
    return !this.hasChangedStartDate() && this.areChangeFormsValid();
  }

  canUpdateBonus() {
    return this.hasChangedStartDate() && this.areChangeFormsValid();
  }

  canDecline() {
    let are_forms_valid = this.areDeclineFormsValid();
    let is_decline_operation_allowed = this.lodash.get(this.bonus, 'validations.administration.validations.are_operations_allowed.validations.can_decline_result.overall');
    return  are_forms_valid && is_decline_operation_allowed;
  }

  hasOrderProcess() {
    let response = this.lodash.has(this.original_basket, 'order_process');
    return response;
  }

  isOrderProcessPreventingChangesToBasket() {
    return this.hasOrderProcess() && this.original_basket.order_process.state_id !== this.ORDER_STATUSES_BY_NAME['order_created_in_retailer_with_changes']['id'];
  }

  canUpdateBasket() {
    let canUpdateBasket = this.areEditBasketFormsValid() && !this.over_sum_limit;
    if (!this.hasOrderProcess()) {
      return canUpdateBasket;
    }
    return canUpdateBasket && !this.isOrderProcessPreventingChangesToBasket();
  }


  bonusIsEditable() {
    return this.bonus ? this.BONUSES_WITH_EDITABLE_BEGIN_DATE.includes(this.bonus_component_name) : false;
  }

  cancelBonus() {
    if (!this.areCancelFormsValid()) {
      return;
    }

    this.$timeout(() => {
      this.loading.cancel = true;
    });


    return this.BonusService.administrationBonusCancel({
      bonus_id: this.bonus._id,
      comment: this.models.cancel.cancel_comment,
      new_end_date: this.models.cancel.new_end_date
    })
      .then(() => {
      }, e => {
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.cancel = false;
        });
      });
  }

  async declineBonus() {
    if (!this.canDecline()) {
      return;
    }

    this.$timeout(() => {
      this.loading.decline = true;
    });

    let bonus_id = this.bonus._id;
    let comment = this.models.decline.decline_comment;

    let promises = [];

    promises.push(
      this.BonusService.administrationBonusDecline(bonus_id, comment)
    );

    return this.$q.all(promises)
      .then(() => {
        this.$scope.confirm({});
      })
      .catch((e) => {
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      }).finally(() => {
        this.$timeout(() => {
          this.loading.decline = true;
        });
      });
  }

  getBonusComponentsMapByName() {
    return this.BonusService.getAvailableBonusComponents().then((res) => {
      let BONUS_COMPONENTS_BY_NAME = res.reduce((acc, field) => {
        acc[field.name] = field;
        return acc;
      }, {});

      return BONUS_COMPONENTS_BY_NAME;
    });
  }

  async getBonusLastDeclinedApprovalComment(bonus) {
    let bonus_component_name = this.BonusService.getBonusComponentName(bonus)
    let entity_id;
    let empty_comment_response = '';
    // depending on the bonus type the entity id for the approval process can either be bonus_id or basket_id
    // if bonus is PC get the basket_id from the checkout config
    // otherwise use the bonus id
    if (PC_BONUS_COMPONENT_NAMES.includes(bonus_component_name)) {
      let basket_id = this.lodash.get(bonus, 'checkout_config.cart._id');
      if (!basket_id) {
        this.NotificationService.error('Fehlgeschlagen - Ablehnungsgrund fehlt');
        return empty_comment_response;
      }
      entity_id = basket_id;
    } else {
      entity_id = bonus._id;
    }

    return this.administrationService.getApprovalProcessLog({entity_id: entity_id, only_last: true}).then(
      (res) => {
        return (res && res.comment) ? res.comment : empty_comment_response;
      }
    ).catch((e) => {
      this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      return empty_comment_response;
    });
  }

  formatDate(date, tstamp = false) {
    let dbDate = new Date(tstamp ? date * 1000 : date);

    return ('0' + dbDate.getDate()).slice(-2) + '.' + ('0' + (dbDate.getMonth() + 1)).slice(-2) + '.' + dbDate.getFullYear();
  }

  async exit() {
    this.$scope.confirm();
  }

  async updateBonusSameDate() {
    if (!this.canUpdateBonusSameDate()) {
      return;
    }

    this.$timeout(() => {
      this.loading.update_bonus = true;
    });

    return this.BonusService.updateBonusStartDate(this.bonus._id, this.models.change.new_begin_date, this.models.change.comment, true)
      .then((bonus) => {
        this.bonus = bonus;
        this.$scope.confirm();
      })
      .catch((e) => {
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.update_bonus = false;
        });
      });
  }

  async updateBonus() {

    if (!this.canUpdateBonus()) {
      return;
    }

    this.$timeout(() => {
      this.loading.update_bonus = true;
    });

    return this.BonusService.updateBonusStartDate(this.bonus._id, this.models.change.new_begin_date, this.models.change.comment)
      .then((bonus) => {
        this.bonus = bonus;
        this.$scope.confirm();
      })
      .catch((e) => {
        this.NotificationService.error(e.error && e.error.message || 'Fehlgeschlagen');
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.update_bonus = false;
        });
      });
  }
}
BonusEditController.$inject = $inject;
