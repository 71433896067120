export const BONUS_EXPIRATION_ACTIONS = [
  {
    id: 1,
    name: 'expires',
    display: 'Expires',
    description: 'The bonus expires'
  },
  {
    id: 2,
    name: 'added',
    display: 'Added to gross',
    description: 'The bonus is added to gross'
  }
];
export const BONUS_EXPIRATION_ACTIONS_BY_NAME = BONUS_EXPIRATION_ACTIONS.reduce((acc, bonus_expiration_action) => {
  acc[bonus_expiration_action.name] = bonus_expiration_action;
  return acc;
}, {});
export const DEFAULT_BONUS_EXPIRATION_ACTION = BONUS_EXPIRATION_ACTIONS_BY_NAME['added'];
