const filesize = [function () {
  return function (bytes, precision) {

    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
      return '-';
    }

    if (typeof precision === 'undefined') {
      precision = 1;
    }

    let number = Math.floor(Math.log(bytes) / Math.log(1024)),
      units = [
        'b',
        'kB',
        'MB',
        'GB',
        'TB',
        'PB'
      ];

    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
  };
}]

export { filesize }
