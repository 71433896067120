<h2 mat-dialog-title>
    Bestellung stornieren
</h2>

<mat-dialog-content>
    <p>
        Sie markieren diese Bestellung als storniert.
        Bitte hinterlassen Sie eine Begründung dafür:
    </p>

    <form [formGroup]="formGroup">
        <vn-input formControlName='comment'
                  key="vn.directBonus.backofficeAdministration.cancelOrderDialog.inputReason"
                  label="Kommentar">
        </vn-input>

        <p>
            Fügen Sie bitte in das untere Eingabefeld auch die Nummer des auftragsbezogenen OTRS-Tickets ein:
        </p>

        <vn-input formControlName='otrsTicket'
                  key="vn.directBonus.backofficeAdministration.cancelOrderDialog.inputOTRSTicket"
                  label="OTRS Ticket">
        </vn-input>

    </form>

    <p>
        Nach dem Klick auf “Bestätigen“ wird dieser Auftrag aus dem Bestellprozess für immer entfernt.
    </p>

    <p>
        Für bezahlte oder fakturierte Bestellungen wird eine Datei im CSV-Format für Ihr Fakturierungsprogramm
        heruntergeladen.
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        Abbrechen
    </button>

    <button class='create-button'
            data-cy='vn.directBonus.backofficeAdministration.cancelOrderDialog.buttonSubmit'
            [disabled]="!formGroup.valid"
            mat-stroked-button
            (click)="cancelOrder()">
        Bestätigen
    </button>
</mat-dialog-actions>