var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (bonus_project, fieldsetCounter, globals, id) {
      







































































































































































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};






































































pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_mixins["bonus_projects_tile"] = pug_interp = function(bonus_project){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"baustein\"\u003E\u003Cdiv class=\"bausteinContainer\"\u003E\u003Ca class=\"imgWrapper\" ng-click=\"$bonus_list_ctrl.selectProject(bonus_project._id)\" data-cy=\"bonus.preCheckout.{{bonus_project._id}}.bonusDashboard.cardBonusProject\"\u003E\u003Cdiv class=\"imgContainer\" ng-style=\"{&quot;background-image&quot;:&quot;url(\u002Fassets\u002Fimg\u002Fbonus_project.png)&quot;,&quot;background-size&quot;: &quot;contain&quot;}\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"title\" data-cy=\"bonus.preCheckout.{{bonus_project._id}}.bonusDashboard.textProjectName\"\u003E{{bonus_project.name}}\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btns clearfix\"\u003E\u003Ca ng-click=\"$bonus_list_ctrl.selectProject(bonus_project._id)\"\u003E\u003Ci class=\"font-icon font-icon-alt icon-plus\"\u003E\u003C\u002Fi\u003E Öffnen\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv\u003EBonusprojekt auswählen.\u003C\u002Fdiv\u003E\u003Cdiv overlay=\"$bonus_list_ctrl.loading.main\"\u003E\u003Cdiv class=\"xsmall-12 columns scrollable\"\u003E\u003Cdiv class=\"row bausteineRow\"\u003E\u003Cdiv class=\"xsmall-12 small-6 large-4 columns\" ng-repeat=\"bonus_project in $bonus_list_ctrl.bonus_projects\" random-animate\u003E\u003Cdiv class=\"bausteinWrapper\"\u003E";
pug_mixins["bonus_projects_tile"].call({
attributes: {"bonus_project": pug.escape(bonus_project)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "bonus_project" in locals_for_with ?
        locals_for_with.bonus_project :
        typeof bonus_project !== 'undefined' ? bonus_project : undefined, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined));
    ;;return pug_html;};
module.exports = template;