const FIRST_GROUP = /^([4-9]|\d{2})$/;
const SECOND_GROUP = /^\d{1,2}\.([2-9]|\d{2})$/;
const FIRST_GROUP_NEEDS_PADDING = /^\d{1}\./;
const SECOND_GROUP_NEEDS_PADDING = /^\d{2}\.\d{1}\./;
const IGNORE_KEY_CODES = [
  8, // Backspace
  46 //'Delete'
];

angular
  .module('VSPApp')
  .directive('vnDateHelper', [function () {
    return {
      restrict: 'A',
      scope: {
        ngModel: '='
      },
      link: function (scope, element) {

        element.on('keyup', event => {

          if (-1 !== IGNORE_KEY_CODES.indexOf(event.keyCode)) {
            return;
          }

          let apply = false;

          if (FIRST_GROUP.test(scope.ngModel) || SECOND_GROUP.test(scope.ngModel)) {
            scope.ngModel = scope.ngModel + '.';
            apply = true;
          }

          if (FIRST_GROUP_NEEDS_PADDING.test(scope.ngModel)) {
            scope.ngModel = '0' + scope.ngModel;
            apply = true;
          }

          if (SECOND_GROUP_NEEDS_PADDING.test(scope.ngModel)) {
            scope.ngModel = scope.ngModel.replace('.', '.0');
            apply = true;
          }

          if (apply) {
            scope.$applyAsync();
          }

        });

      }
    };
  }]);
