const $inject = [
  '$scope',
  '$timeout',
  'authenticationService',
  'NotificationService',
  'administrationService',
  'Made'
];
export default class DocumentAdministrationController {

  constructor(
    $scope,
    $timeout,
    authenticationService,
    NotificationService,
    administrationService,
    Made
  ) {

    Object.assign(this, {
      $scope,
      $timeout,
      authenticationService,
      NotificationService,
      administrationService,
      Made
    });

    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.loading = false;
  }
}
DocumentAdministrationController.$inject = $inject;
