import header from '../../helpers/header.main.pug';
import vorsorge_template from '../../modules/vorsorge/vorsorge.pug';
import vorsorge_home_template from '../../modules/vorsorge/home/vorsorge_home.pug';

angular
  .module('VSPApp')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('inApp.vorsorge', {
        url: '/vorsorge',
        views: {
          main: {
            template: vorsorge_template
          },
          header: {
            template: header
          }
        }
      })
      .state('inApp.vorsorge.home', {
        url: '/home',
        views: {
          vorsorgeMain: {
            template: vorsorge_home_template,
            controller: 'VorsorgeHomeController',
            controllerAs: 'vorsorge'
          }
        }
      });

  }]);
